(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports);
    } else {
        // Browser globals
        factory(root.IBAN = {});
    }
}(this, function(exports){

    // Array.prototype.map polyfill
    // code from https://developer.mozilla.org/en-US/docs/JavaScript/Reference/Global_Objects/Array/map
    if (!Array.prototype.map){
        Array.prototype.map = function(fun /*, thisArg */){
            "use strict";

            if (this === void 0 || this === null)
                throw new TypeError();

            var t = Object(this);
            var len = t.length >>> 0;
            if (typeof fun !== "function")
                throw new TypeError();

            var res = new Array(len);
            var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
            for (var i = 0; i < len; i++)
            {
                // NOTE: Absolute correctness would demand Object.defineProperty
                //       be used.  But this method is fairly new, and failure is
                //       possible only if Object.prototype or Array.prototype
                //       has a property |i| (very unlikely), so use a less-correct
                //       but more portable alternative.
                if (i in t)
                    res[i] = fun.call(thisArg, t[i], i, t);
            }

            return res;
        };
    }

    var A = 'A'.charCodeAt(0),
        Z = 'Z'.charCodeAt(0);

    /**
     * Prepare an IBAN for mod 97 computation by moving the first 4 chars to the end and transforming the letters to
     * numbers (A = 10, B = 11, ..., Z = 35), as specified in ISO13616.
     *
     * @param {string} iban the IBAN
     * @returns {string} the prepared IBAN
     */
    function iso13616Prepare(iban) {
        iban = iban.toUpperCase();
        iban = iban.substr(4) + iban.substr(0,4);

        return iban.split('').map(function(n){
            var code = n.charCodeAt(0);
            if (code >= A && code <= Z){
                // A = 10, B = 11, ... Z = 35
                return code - A + 10;
            } else {
                return n;
            }
        }).join('');
    }

    /**
     * Calculates the MOD 97 10 of the passed IBAN as specified in ISO7064.
     *
     * @param iban
     * @returns {number}
     */
    function iso7064Mod97_10(iban) {
        var remainder = iban,
            block;

        while (remainder.length > 2){
            block = remainder.slice(0, 9);
            remainder = parseInt(block, 10) % 97 + remainder.slice(block.length);
        }

        return parseInt(remainder, 10) % 97;
    }

    /**
     * Parse the BBAN structure used to configure each IBAN Specification and returns a matching regular expression.
     * A structure is composed of blocks of 3 characters (one letter and 2 digits). Each block represents
     * a logical group in the typical representation of the BBAN. For each group, the letter indicates which characters
     * are allowed in this group and the following 2-digits number tells the length of the group.
     *
     * @param {string} structure the structure to parse
     * @returns {RegExp}
     */
    function parseStructure(structure){
        // split in blocks of 3 chars
        var regex = structure.match(/(.{3})/g).map(function(block){

            // parse each structure block (1-char + 2-digits)
            var format,
                pattern = block.slice(0, 1),
                repeats = parseInt(block.slice(1), 10);

            switch (pattern){
                case "A": format = "0-9A-Za-z"; break;
                case "B": format = "0-9A-Z"; break;
                case "C": format = "A-Za-z"; break;
                case "F": format = "0-9"; break;
                case "L": format = "a-z"; break;
                case "U": format = "A-Z"; break;
                case "W": format = "0-9a-z"; break;
            }

            return '([' + format + ']{' + repeats + '})';
        });

        return new RegExp('^' + regex.join('') + '$');
    }

    /**
     *
     * @param iban
     * @returns {string}
     */
    function electronicFormat(iban){
        return iban.replace(NON_ALPHANUM, '').toUpperCase();
    }


    /**
     * Create a new Specification for a valid IBAN number.
     *
     * @param countryCode the code of the country
     * @param length the length of the IBAN
     * @param structure the structure of the underlying BBAN (for validation and formatting)
     * @param example an example valid IBAN
     * @constructor
     */
    function Specification(countryCode, length, structure, example){

        this.countryCode = countryCode;
        this.length = length;
        this.structure = structure;
        this.example = example;
    }

    /**
     * Lazy-loaded regex (parse the structure and construct the regular expression the first time we need it for validation)
     */
    Specification.prototype._regex = function(){
        return this._cachedRegex || (this._cachedRegex = parseStructure(this.structure))
    };

    /**
     * Check if the passed iban is valid according to this specification.
     *
     * @param {String} iban the iban to validate
     * @returns {boolean} true if valid, false otherwise
     */
    Specification.prototype.isValid = function(iban){
        return this.length == iban.length
            && this.countryCode === iban.slice(0,2)
            && this._regex().test(iban.slice(4))
            && iso7064Mod97_10(iso13616Prepare(iban)) == 1;
    };

    /**
     * Convert the passed IBAN to a country-specific BBAN.
     *
     * @param iban the IBAN to convert
     * @param separator the separator to use between BBAN blocks
     * @returns {string} the BBAN
     */
    Specification.prototype.toBBAN = function(iban, separator) {
        return this._regex().exec(iban.slice(4)).slice(1).join(separator);
    };

    /**
     * Convert the passed BBAN to an IBAN for this country specification.
     * Please note that <i>"generation of the IBAN shall be the exclusive responsibility of the bank/branch servicing the account"</i>.
     * This method implements the preferred algorithm described in http://en.wikipedia.org/wiki/International_Bank_Account_Number#Generating_IBAN_check_digits
     *
     * @param bban the BBAN to convert to IBAN
     * @returns {string} the IBAN
     */
    Specification.prototype.fromBBAN = function(bban) {
        if (!this.isValidBBAN(bban)){
            throw new Error('Invalid BBAN');
        }

        var remainder = iso7064Mod97_10(iso13616Prepare(this.countryCode + '00' + bban)),
            checkDigit = ('0' + (98 - remainder)).slice(-2);

        return this.countryCode + checkDigit + bban;
    };

    /**
     * Check of the passed BBAN is valid.
     * This function only checks the format of the BBAN (length and matching the letetr/number specs) but does not
     * verify the check digit.
     *
     * @param bban the BBAN to validate
     * @returns {boolean} true if the passed bban is a valid BBAN according to this specification, false otherwise
     */
    Specification.prototype.isValidBBAN = function(bban) {
        return this.length - 4 == bban.length
            && this._regex().test(bban);
    };

    var countries = {};

    function addSpecification(IBAN){
        countries[IBAN.countryCode] = IBAN;
    }

    addSpecification(new Specification("AD", 24, "F04F04A12",          "AD1200012030200359100100"));
    addSpecification(new Specification("AE", 23, "F03F16",             "AE070331234567890123456"));
    addSpecification(new Specification("AL", 28, "F08A16",             "AL47212110090000000235698741"));
    addSpecification(new Specification("AT", 20, "F05F11",             "AT611904300234573201"));
    addSpecification(new Specification("AZ", 28, "U04A20",             "AZ21NABZ00000000137010001944"));
    addSpecification(new Specification("BA", 20, "F03F03F08F02",       "BA391290079401028494"));
    addSpecification(new Specification("BE", 16, "F03F07F02",          "BE68539007547034"));
    addSpecification(new Specification("BG", 22, "U04F04F02A08",       "BG80BNBG96611020345678"));
    addSpecification(new Specification("BH", 22, "U04A14",             "BH67BMAG00001299123456"));
    addSpecification(new Specification("BR", 29, "F08F05F10U01A01",    "BR9700360305000010009795493P1"));
    addSpecification(new Specification("CH", 21, "F05A12",             "CH9300762011623852957"));
    addSpecification(new Specification("CR", 22, "F04F14",             "CR72012300000171549015"));
    addSpecification(new Specification("CY", 28, "F03F05A16",          "CY17002001280000001200527600"));
    addSpecification(new Specification("CZ", 24, "F04F06F10",          "CZ6508000000192000145399"));
    addSpecification(new Specification("DE", 22, "F08F10",             "DE89370400440532013000"));
    addSpecification(new Specification("DK", 18, "F04F09F01",          "DK5000400440116243"));
    addSpecification(new Specification("DO", 28, "U04F20",             "DO28BAGR00000001212453611324"));
    addSpecification(new Specification("EE", 20, "F02F02F11F01",       "EE382200221020145685"));
    addSpecification(new Specification("ES", 24, "F04F04F01F01F10",    "ES9121000418450200051332"));
    addSpecification(new Specification("FI", 18, "F06F07F01",          "FI2112345600000785"));
    addSpecification(new Specification("FO", 18, "F04F09F01",          "FO6264600001631634"));
    addSpecification(new Specification("FR", 27, "F05F05A11F02",       "FR1420041010050500013M02606"));
    addSpecification(new Specification("GB", 22, "U04F06F08",          "GB29NWBK60161331926819"));
    addSpecification(new Specification("GE", 22, "U02F16",             "GE29NB0000000101904917"));
    addSpecification(new Specification("GI", 23, "U04A15",             "GI75NWBK000000007099453"));
    addSpecification(new Specification("GL", 18, "F04F09F01",          "GL8964710001000206"));
    addSpecification(new Specification("GR", 27, "F03F04A16",          "GR1601101250000000012300695"));
    addSpecification(new Specification("GT", 28, "A04A20",             "GT82TRAJ01020000001210029690"));
    addSpecification(new Specification("HR", 21, "F07F10",             "HR1210010051863000160"));
    addSpecification(new Specification("HU", 28, "F03F04F01F15F01",    "HU42117730161111101800000000"));
    addSpecification(new Specification("IE", 22, "U04F06F08",          "IE29AIBK93115212345678"));
    addSpecification(new Specification("IL", 23, "F03F03F13",          "IL620108000000099999999"));
    addSpecification(new Specification("IS", 26, "F04F02F06F10",       "IS140159260076545510730339"));
    addSpecification(new Specification("IT", 27, "U01F05F05A12",       "IT60X0542811101000000123456"));
    addSpecification(new Specification("KW", 30, "U04A22",             "KW81CBKU0000000000001234560101"));
    addSpecification(new Specification("KZ", 20, "F03A13",             "KZ86125KZT5004100100"));
    addSpecification(new Specification("LB", 28, "F04A20",             "LB62099900000001001901229114"));
    addSpecification(new Specification("LC", 32, "U04F24",             "LC07HEMM000100010012001200013015"));
    addSpecification(new Specification("LI", 21, "F05A12",             "LI21088100002324013AA"));
    addSpecification(new Specification("LT", 20, "F05F11",             "LT121000011101001000"));
    addSpecification(new Specification("LU", 20, "F03A13",             "LU280019400644750000"));
    addSpecification(new Specification("LV", 21, "U04A13",             "LV80BANK0000435195001"));
    addSpecification(new Specification("MC", 27, "F05F05A11F02",       "MC5811222000010123456789030"));
    addSpecification(new Specification("MD", 24, "U02A18",             "MD24AG000225100013104168"));
    addSpecification(new Specification("ME", 22, "F03F13F02",          "ME25505000012345678951"));
    addSpecification(new Specification("MK", 19, "F03A10F02",          "MK07250120000058984"));
    addSpecification(new Specification("MR", 27, "F05F05F11F02",       "MR1300020001010000123456753"));
    addSpecification(new Specification("MT", 31, "U04F05A18",          "MT84MALT011000012345MTLCAST001S"));
    addSpecification(new Specification("MU", 30, "U04F02F02F12F03U03", "MU17BOMM0101101030300200000MUR"));
    addSpecification(new Specification("NL", 18, "U04F10",             "NL91ABNA0417164300"));
    addSpecification(new Specification("NO", 15, "F04F06F01",          "NO9386011117947"));
    addSpecification(new Specification("PK", 24, "U04A16",             "PK36SCBL0000001123456702"));
    addSpecification(new Specification("PL", 28, "F08F16",             "PL61109010140000071219812874"));
    addSpecification(new Specification("PS", 29, "U04A21",             "PS92PALS000000000400123456702"));
    addSpecification(new Specification("PT", 25, "F04F04F11F02",       "PT50000201231234567890154"));
    addSpecification(new Specification("RO", 24, "U04A16",             "RO49AAAA1B31007593840000"));
    addSpecification(new Specification("RS", 22, "F03F13F02",          "RS35260005601001611379"));
    addSpecification(new Specification("SA", 24, "F02A18",             "SA0380000000608010167519"));
    addSpecification(new Specification("SE", 24, "F03F16F01",          "SE4550000000058398257466"));
    addSpecification(new Specification("SI", 19, "F05F08F02",          "SI56263300012039086"));
    addSpecification(new Specification("SK", 24, "F04F06F10",          "SK3112000000198742637541"));
    addSpecification(new Specification("SM", 27, "U01F05F05A12",       "SM86U0322509800000000270100"));
    addSpecification(new Specification("ST", 25, "F08F11F02",          "ST68000100010051845310112"));
    addSpecification(new Specification("TL", 23, "F03F14F02",          "TL380080012345678910157"));
    addSpecification(new Specification("TN", 24, "F02F03F13F02",       "TN5910006035183598478831"));
    addSpecification(new Specification("TR", 26, "F05F01A16",          "TR330006100519786457841326"));
    addSpecification(new Specification("VG", 24, "U04F16",             "VG96VPVG0000012345678901"));
    addSpecification(new Specification("XK", 20, "F04F10F02",          "XK051212012345678906"));

    // Angola
    addSpecification(new Specification("AO", 25, "F21",                "AO69123456789012345678901"));
    // Burkina
    addSpecification(new Specification("BF", 27, "F23",                "BF2312345678901234567890123"));
    // Burundi
    addSpecification(new Specification("BI", 16, "F12",                "BI41123456789012"));
    // Benin
    addSpecification(new Specification("BJ", 28, "F24",                "BJ39123456789012345678901234"));
    // Ivory
    addSpecification(new Specification("CI", 28, "U01F23",             "CI17A12345678901234567890123"));
    // Cameron
    addSpecification(new Specification("CM", 27, "F23",                "CM9012345678901234567890123"));
    // Cape Verde
    addSpecification(new Specification("CV", 25, "F21",                "CV30123456789012345678901"));
    // Algeria
    addSpecification(new Specification("DZ", 24, "F20",                "DZ8612345678901234567890"));
    // Iran
    addSpecification(new Specification("IR", 26, "F22",                "IR861234568790123456789012"));
    // Jordan
    addSpecification(new Specification("JO", 30, "A04F22",             "JO15AAAA1234567890123456789012"));
    // Madagascar
    addSpecification(new Specification("MG", 27, "F23",                "MG1812345678901234567890123"));
    // Mali
    addSpecification(new Specification("ML", 28, "U01F23",             "ML15A12345678901234567890123"));
    // Mozambique
    addSpecification(new Specification("MZ", 25, "F21",                "MZ25123456789012345678901"));
    // Quatar
    addSpecification(new Specification("QA", 29, "U04A21",             "QA30AAAA123456789012345678901"));
    // Senegal
    addSpecification(new Specification("SN", 28, "U01F23",             "SN52A12345678901234567890123"));
    // Ukraine
    addSpecification(new Specification("UA", 29, "F25",                "UA511234567890123456789012345"));

    var NON_ALPHANUM = /[^a-zA-Z0-9]/g,
        EVERY_FOUR_CHARS =/(.{4})(?!$)/g;

    /**
     * Utility function to check if a variable is a String.
     *
     * @param v
     * @returns {boolean} true if the passed variable is a String, false otherwise.
     */
    function isString(v){
        return (typeof v == 'string' || v instanceof String);
    }

    /**
     * Check if an IBAN is valid.
     *
     * @param {String} iban the IBAN to validate.
     * @returns {boolean} true if the passed IBAN is valid, false otherwise
     */
    exports.isValid = function(iban){
        if (!isString(iban)){
            return false;
        }
        iban = electronicFormat(iban);
        var countryStructure = countries[iban.slice(0,2)];
        return !!countryStructure && countryStructure.isValid(iban);
    };

    /**
     * Convert an IBAN to a BBAN.
     *
     * @param iban
     * @param {String} [separator] the separator to use between the blocks of the BBAN, defaults to ' '
     * @returns {string|*}
     */
    exports.toBBAN = function(iban, separator){
        if (typeof separator == 'undefined'){
            separator = ' ';
        }
        iban = electronicFormat(iban);
        var countryStructure = countries[iban.slice(0,2)];
        if (!countryStructure) {
            throw new Error('No country with code ' + iban.slice(0,2));
        }
        return countryStructure.toBBAN(iban, separator);
    };

    /**
     * Convert the passed BBAN to an IBAN for this country specification.
     * Please note that <i>"generation of the IBAN shall be the exclusive responsibility of the bank/branch servicing the account"</i>.
     * This method implements the preferred algorithm described in http://en.wikipedia.org/wiki/International_Bank_Account_Number#Generating_IBAN_check_digits
     *
     * @param countryCode the country of the BBAN
     * @param bban the BBAN to convert to IBAN
     * @returns {string} the IBAN
     */
    exports.fromBBAN = function(countryCode, bban){
        var countryStructure = countries[countryCode];
        if (!countryStructure) {
            throw new Error('No country with code ' + countryCode);
        }
        return countryStructure.fromBBAN(electronicFormat(bban));
    };

    /**
     * Check the validity of the passed BBAN.
     *
     * @param countryCode the country of the BBAN
     * @param bban the BBAN to check the validity of
     */
    exports.isValidBBAN = function(countryCode, bban){
        if (!isString(bban)){
            return false;
        }
        var countryStructure = countries[countryCode];
        return countryStructure && countryStructure.isValidBBAN(electronicFormat(bban));
    };

    /**
     *
     * @param iban
     * @param separator
     * @returns {string}
     */
    exports.printFormat = function(iban, separator){
        if (typeof separator == 'undefined'){
            separator = ' ';
        }
        return electronicFormat(iban).replace(EVERY_FOUR_CHARS, "$1" + separator);
    };

    exports.electronicFormat = electronicFormat;
    /**
     * An object containing all the known IBAN specifications.
     */
    exports.countries = countries;

}));

// Polyfill for Array.prototype.reduce
// Production steps of ECMA-262, Edition 5, 15.4.4.21
// Reference: http://es5.github.io/#x15.4.4.21
if (!Array.prototype.reduce) {
  Array.prototype.reduce = function(callback /*, initialValue*/) {
    'use strict';
    if (this == null) {
      throw new TypeError('Array.prototype.reduce called on null or undefined');
    }
    if (typeof callback !== 'function') {
      throw new TypeError(callback + ' is not a function');
    }
    var t = Object(this),
      len = t.length >>> 0,
      k = 0,
      value;
    if (arguments.length == 2) {
      value = arguments[1];
    } else {
      while (k < len && !(k in t)) {
        k++;
      }
      if (k >= len) {
        throw new TypeError('Reduce of empty array with no initial value');
      }
      value = t[k++];
    }
    for (; k < len; k++) {
      if (k in t) {
        value = callback(value, t[k], k, t);
      }
    }
    return value;
  };
}

// Polyfill for Array.prototype.filter
if (!Array.prototype.filter) {
  Array.prototype.filter = function(fun /*, thisArg*/) {
    'use strict';

    if (this === void 0 || this === null) {
      throw new TypeError();
    }

    var t = Object(this);
    var len = t.length >>> 0;
    if (typeof fun !== 'function') {
      throw new TypeError();
    }

    var res = [];
    var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
    for (var i = 0; i < len; i++) {
      if (i in t) {
        var val = t[i];

        // NOTE: Technically this should Object.defineProperty at
        //       the next index, as push can be affected by
        //       properties on Object.prototype and Array.prototype.
        //       But that method's new, and collisions should be
        //       rare, so use the more-compatible alternative.
        if (fun.call(thisArg, val, i, t)) {
          res.push(val);
        }
      }
    }

    return res;
  };
}

// Polyfill for Array.prototype.forEach
// Production steps of ECMA-262, Edition 5, 15.4.4.18
// Reference: http://es5.github.io/#x15.4.4.18
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function(callback, thisArg) {
    var T, k;

    if (this == null) {
      throw new TypeError(' this is null or not defined');
    }

    // 1. Let O be the result of calling ToObject passing the |this| value as the argument.
    var O = Object(this);

    // 2. Let lenValue be the result of calling the Get internal method of O with the argument "length".
    // 3. Let len be ToUint32(lenValue).
    var len = O.length >>> 0;

    // 4. If IsCallable(callback) is false, throw a TypeError exception.
    // See: http://es5.github.com/#x9.11
    if (typeof callback !== 'function') {
      throw new TypeError(callback + ' is not a function');
    }

    // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
    if (arguments.length > 1) {
      T = thisArg;
    }

    // 6. Let k be 0
    k = 0;

    // 7. Repeat, while k < len
    while (k < len) {
      var kValue;

      // a. Let Pk be ToString(k).
      //   This is implicit for LHS operands of the in operator
      // b. Let kPresent be the result of calling the HasProperty internal method of O with argument Pk.
      //   This step can be combined with c
      // c. If kPresent is true, then
      if (k in O) {
        // i. Let kValue be the result of calling the Get internal method of O with argument Pk.
        kValue = O[k];

        // ii. Call the Call internal method of callback with T as the this value and
        // argument list containing kValue, k, and O.
        callback.call(T, kValue, k, O);
      }
      // d. Increase k by 1.
      k++;
    }
    // 8. return undefined
  };
}

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports'], factory);
  } else if (
    typeof exports === 'object' &&
    typeof exports.nodeName !== 'string'
  ) {
    // CommonJS
    factory(exports);
  } else {
    // Browser globals
    factory((root.BankData_Utils = {}));
  }
})(this, function(exports) {
  /**
   * An object containing all the functions.
   */
  exports.padDigits = padDigits;
  exports.checkLastCharsOfStringToBeChar = checkLastCharsOfStringToBeChar;
  exports.getBankDataByCountry = getBankDataByCountry;
  exports.maskString = maskString;
  exports.isArray = isArray;

  ///////////////

  function getBankDataByCountry(countryCode) {
    var bankDataArray = undefined;
    countryCode = countryCode.toUpperCase();

    switch (countryCode) {
      case 'AT':
        bankDataArray = Banks_AT;
        break;
      case 'DE':
        bankDataArray = Banks_DE;
        break;
      case 'CH':
      case 'LI':
        bankDataArray = Banks_CH;
        break;
      case 'GB':
      case 'UK':
        bankDataArray = ukBankData;
        break;
    }

    return bankDataArray;
  }

  /**
   * padDigits
   *
   * @param number
   * @param digits
   * @returns {string}
   */
  function padDigits(number, digits) {
    return (
      new Array(Math.max(digits - String(number).length + 1, 0)).join(0) +
      number
    );
  }

  /**
   * checkLastCharsOfStringToBeX
   *
   * returns true if all remaining chars are "X" and false if not.
   *
   * @param userBic
   * @param originalBic
   * @returns {boolean}
   */
  function checkLastCharsOfStringToBeChar(str, patternStr, char) {
    char = char ? char : 'X';

    if (patternStr.indexOf(str) === 0) {
      var lastChars = patternStr.substr(
        patternStr.length - (patternStr.length - str.length)
      );
      for (var i = 0; i < lastChars.length; i++) {
        if (lastChars[i] !== char) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  function maskString(value, mask, maskPatterns) {
    value = value || '';
    mask = mask || '';
    maskPatterns = maskPatterns || {};

    var maskedValue = '';
    // array representation of string under test
    var valueParts = value.split('');
    // array representation of the mask
    var maskParts = mask.split('');

    // as long as there are still characters left in
    // the original string, one must try to mask them
    while (valueParts.length > 0) {
      // take the first character and remove
      // it from the original string
      var unmaskedChar = valueParts.shift();

      // as long as the character has not been masked
      // one must try to find a masking character
      while (unmaskedChar !== null) {
        // take the first mask character from
        // the mask string
        var maskChar = maskParts.shift();

        // make sure the masking character exists
        // otherwise this means that the original string
        // exceeds the masking pattern length
        if (maskChar !== undefined) {
          // try to find a pattern for the particular
          // mask character
          var maskPattern = maskPatterns[maskChar.toUpperCase()];

          // if there is no pattern configured for
          // this particular mask character, assume
          // the mask character is a placeholder
          // that must be added to the string
          if (maskPattern !== undefined) {
            var check = false;

            // mask pattern can be either a function
            if (typeof maskPattern === 'function') {
              check = maskPattern(unmaskedChar);
            } else if (maskPattern instanceof RegExp) {
              // or a regex string
              check = maskPattern.test(unmaskedChar);
            }

            // if character has passed the mask check,
            // it can bee added to the final masked value
            if (check) {
              maskedValue += unmaskedChar;
            } else {
              // otherwise one must put the pattern back into
              // the array so the next character can try to
              // pass the mask check
              maskParts.unshift(maskChar);
            }

            unmaskedChar = null;
          } else {
            // mask character is a placeholder / formatting value
            // and must be added to the masked string
            maskedValue += maskChar;
          }
        } else {
          // no masking character has been found,
          // the original string is probably longer
          // then the mask pattern and therefore
          // the leftovers can be cut off
          // reset current character to continue the loop
          unmaskedChar = null;
        }
      }
    }

    return maskedValue;
  }

  function isArray(vArg) {
    return Object.prototype.toString.call(vArg) === '[object Array]';
  }
});

function isPaymentIntervalValidDir() {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isPaymentIntervalValidDir';
      var paymentIntervalValidTitleMapAttr = 'isPaymentIntervalValidTitleMap';
      var donationAmountAttr = 'isPaymentIntervalValidAmount';

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      var watchers = [];

      var isActive = false;

      var dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = value;
            if (isActive) {
              activateDir();
            } else {
              deactivateDir();
            }
          }
        }
      );

      ngModel.$formatters.push(function(paymentInterval) {
        validateInput(paymentInterval);
        return paymentInterval;
      });

      ngModel.$parsers.push(function(paymentInterval) {
        validateInput(paymentInterval);
        return paymentInterval;
      });

      ////////////////////////////////////////////

      function activateDir() {
        //watch payment interval field which changes dynamically
        watchers.push(
          scope.$watch(
            function() {
              return getFormFieldValue(donationAmountAttr);
            },
            function(value, prevVal) {
              if (value !== prevVal) {
                validateInput(ngModel.$modelValue);
              }
            }
          )
        );
      }

      function deactivateDir() {
        for (var i = 0; i < watchers.length; i++) {
          if (typeof watchers[i] === 'function') {
            watchers[i]();
          }
        }
      }

      function validateInput(newPaymentInterval) {
        var paymentInterval = newPaymentInterval; //newPaymentinterval || ngModel.$modelValue || ngModel.$viewValue;
        var isValid = true;
        var errorName = 'paymentIntervalValid';
        ngModel.$setValidity(errorName, isValid);

        var donationAmountValue = getFormFieldValue(donationAmountAttr);
        //if donationAmount is undefined (field is invalid) pass validation
        var amountIsGiven = !!donationAmountValue && donationAmountValue !== 0;

        if (isActive && amountIsGiven) {
          var controlTitleMap = [];
          try {
            controlTitleMap = JSON.parse(
              attrs[paymentIntervalValidTitleMapAttr]
            );
          } catch (e) {
            console.error('Could not parse payment interval data');
          }

          var min_amount;
          controlTitleMap.forEach(function(map) {
            if (map.value === paymentInterval) {
              min_amount = map.min_amount;
              if (donationAmountValue < min_amount) {
                isValid = false;
              }
            }
          });
          ngModel.$setValidity(errorName, isValid);
        }
      }

      function getFormFieldValue(attrNameWithFieldName) {
        var value = '';

        if (
          attrs[attrNameWithFieldName] &&
          form[attrs[attrNameWithFieldName]] &&
          '$modelValue' in form[attrs[attrNameWithFieldName]]
        ) {
          value = form[attrs[attrNameWithFieldName]].$modelValue;
        }

        return value;
      }
    }
  };
}

angular
  .module('isPaymentIntervalValid-dir', [])
  .directive('isPaymentIntervalValidDir', isPaymentIntervalValidDir);

function getBankNameDir() {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'getBankNameDir';
      var bicFieldName = 'getBankNameBic';

      var countryFieldName = 'getBankNameCountry';
      var paymentMethodFieldName = 'getBankNamePaymentMethod';

      var ibanFieldName = 'getBankNameIban';
      var bankCodeFieldName = 'getBankNameBankCode';
      var ukSortCodeFieldName = 'getBankNameUkSortCode';
      var postfinanceClearingNumberFieldName =
        'getBankNamePostfinanceClearingNumber';

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      var watchers = [];

      var isActive = false;

      var dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = value;
            if (isActive) {
              activateDir();
              updateBankName(countryFieldName);
            } else {
              deactivateDir();
            }
          }
        }
      );

      ////////////////////////////////////////////

      function activateDir() {
        //watch payment method field which changes dynamically
        watchers.push(
          scope.$watch(
            function() {
              return getFormFieldValue(paymentMethodFieldName);
            },
            function(value, prevVal) {
              if (!!value && value !== prevVal) {
                updateBankName(paymentMethodFieldName);
              }
            }
          )
        );

        //watch country field which changes dynamically
        watchers.push(
          scope.$watch(
            function() {
              return getFormFieldValue(countryFieldName);
            },
            function(value, prevVal) {
              if (!!value && value !== prevVal) {
                updateBankName(countryFieldName);
              }
            }
          )
        );

        //watch bic field which changes dynamically
        watchers.push(
          scope.$watch(
            function() {
              return getFormFieldValue(bicFieldName);
            },
            function(value, prevVal) {
              if (!!value && value !== prevVal) {
                updateBankName(bicFieldName);
              }
            }
          )
        );

        //watch iban field which changes dynamically
        watchers.push(
          scope.$watch(
            function() {
              return getFormFieldValue(ibanFieldName);
            },
            function(value, prevVal) {
              if (!!value && value !== prevVal) {
                updateBankName(ibanFieldName);
              }
            }
          )
        );

        //watch bank code field which changes dynamically
        watchers.push(
          scope.$watch(
            function() {
              return getFormFieldValue(bankCodeFieldName);
            },
            function(value, prevVal) {
              if (!!value && value !== prevVal) {
                updateBankName(bankCodeFieldName);
              }
            }
          )
        );

        //watch sort code field which changes dynamically
        watchers.push(
          scope.$watch(
            function() {
              return getFormFieldValue(ukSortCodeFieldName);
            },
            function(value, prevVal) {
              if (!!value && value !== prevVal) {
                updateBankName(ukSortCodeFieldName);
              }
            }
          )
        );

        //watch postfinance clearing number field which changes dynamically
        watchers.push(
          scope.$watch(
            function() {
              return getFormFieldValue(postfinanceClearingNumberFieldName);
            },
            function(value, prevVal) {
              if (!!value && value !== prevVal) {
                updateBankName(postfinanceClearingNumberFieldName);
              }
            }
          )
        );
      }

      function deactivateDir() {
        for (var i = 0; i < watchers.length; i++) {
          if (typeof watchers[i] === 'function') {
            watchers[i]();
          }
        }
      }

      function updateBankName(relevantChange) {
        var paymentMethod = getFormFieldValue(paymentMethodFieldName);

        if (isActive && paymentMethod !== 'donut-de-kontonummer') {
          var countryCode;
          var bankName = undefined;

          //get values
          var iban = getFormFieldValue(ibanFieldName);
          var ukSortCode = getFormFieldValue(ukSortCodeFieldName);
          var bic = getFormFieldValue(bicFieldName);
          var bankCode = getFormFieldValue(bankCodeFieldName);
          var clearingNumber = getFormFieldValue(
            postfinanceClearingNumberFieldName
          );

          if (iban) {
            countryCode = Sepa.getCountryFromIban(iban);
          } else {
            countryCode = getFormFieldValue(countryFieldName);
          }

          countryCode = countryCode ? countryCode.toUpperCase() : '';

          //use relevant Change to get bank name
          //use bic to get bank name
          if (relevantChange === bicFieldName) {
            bankName = Sepa.getBankNameByBic(bic, countryCode);
          } else if (relevantChange === bankCodeFieldName) {
            //use bank code to get bank name
            bankName = Sepa.getBankNameByBankCode(bankCode, countryCode);
          } else if (
            relevantChange === postfinanceClearingNumberFieldName &&
            countryCode === 'CH'
          ) {
            //use sort code to get bank name
            bankName = CH_Postfinance.getBankNameByClearingNumber(
              clearingNumber
            );
          } else if (
            relevantChange === ukSortCodeFieldName &&
            countryCode === 'GB'
          ) {
            //use sort code to get bank name only if country is GB
            bankName = UK_SortCode.getBankNameBySortCode(ukSortCode);
          } else if (
            relevantChange === countryFieldName ||
            relevantChange === paymentMethodFieldName
          ) {
            //if country or paymentMethod changes try everything
            if (
              paymentMethod == 'donut-uk-sort-codes' &&
              countryCode === 'GB'
            ) {
              bankName = UK_SortCode.getBankNameBySortCode(ukSortCode);
            } else if (
              paymentMethod == 'donut-post-finance' &&
              countryCode === 'CH'
            ) {
              bankName = CH_Postfinance.getBankNameByClearingNumber(
                clearingNumber
              );
            } else if (
              paymentMethod == 'donut-sepa' ||
              paymentMethod == 'postfinance_iban' ||
              paymentMethod == 'swiss_direct_debit'
            ) {
              bankName =
                Sepa.getBankNameByBic(bic, countryCode) ||
                Sepa.getBankNameByBankCode(bankCode, countryCode);
            }
          }

          if (bankName)
            // don't overwrite if bankName is ""
            ngModel.$setViewValue(bankName);
          //@TODO check why this triggers rerender in other fields => bic ... :-(
          ngModel.$render();

          if (!bankName) {
            ngModel.$setPristine();
          }
        }
      }

      function getFormFieldValue(attrNameWithFieldName) {
        var value = '';

        if (
          attrs[attrNameWithFieldName] &&
          form[attrs[attrNameWithFieldName]] &&
          '$modelValue' in form[attrs[attrNameWithFieldName]]
        ) {
          value = form[attrs[attrNameWithFieldName]].$modelValue;
        }

        return value;
      }
    }
  };
}

angular
  .module('getBankName-dir', [])
  .directive('getBankNameDir', getBankNameDir);

function isRequiredIfCountryDifferentDir() {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isRequiredIfCountryDifferentDir';
      var initialCountryFieldName =
        'isRequiredIfCountryDifferentInitialCountry';
      var countryFieldName = 'isRequiredIfCountryDifferentCountry';

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      var initialCountryWatch;
      var countryWatch;

      var isActive = false;

      var dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          isActive = value;

          if (isActive) {
            activateDir();
          } else {
            deactivateDir();
          }
        }
      );

      //For DOM -> model validation
      ngModel.$parsers.unshift(function(newValue) {
        validateInput(newValue);
        return newValue;
      });

      //ngModel.$formatters.unshift(function(newValue) { return newValue );

      ////////////////////////////////////////////

      function activateDir() {
        initialCountryWatch = scope.$watch(
          function() {
            return getFormFieldValue(initialCountryFieldName);
          },
          function(value, prevVal) {
            if (value !== prevVal) {
              validateInput(ngModel.$viewValue);
            }
          }
        );

        //watch country attribute which changes dynamically
        countryWatch = scope.$watch(
          function() {
            return getFormFieldValue(countryFieldName);
          },
          function(value, prevVal) {
            if (value !== prevVal) {
              validateInput(ngModel.$viewValue);
            }
          }
        );
      }

      function deactivateDir() {
        if (typeof countryWatch === 'function') {
          countryWatch();
        }
        if (typeof initialCountryWatch === 'function') {
          initialCountryWatch();
        }
      }

      function validateInput(newValue) {
        var errorName = 'requiredCountryDiffInitial';
        var valid = true;
        ngModel.$setValidity(errorName, valid);

        if (isActive) {
          if (!areCountriesEqual()) {
            valid = !!newValue;
            ngModel.$setValidity(errorName, valid);
          }
        }

        return valid;
      }

      function areCountriesEqual() {
        var countryCode = getFormFieldValue(countryFieldName)
          ? getFormFieldValue(countryFieldName).toUpperCase()
          : '';
        var initialCountry = attrs[initialCountryFieldName]
          ? attrs[initialCountryFieldName].toUpperCase()
          : '';

        var isEqual = true;

        if (countryCode !== initialCountry) {
          isEqual = false;
        }

        return isEqual;
      }

      function getFormFieldValue(fieldName) {
        var value = '';
        if (
          attrs[fieldName] &&
          form[attrs[fieldName]] &&
          '$modelValue' in form[attrs[fieldName]] &&
          form[attrs[fieldName]].$modelValue
        ) {
          value = form[attrs[fieldName]].$modelValue;
        }
        return value;
      }
    }
  };
}

angular
  .module('isRequiredIfCountryDifferent-dir', [])
  .directive(
    'isRequiredIfCountryDifferentDir',
    isRequiredIfCountryDifferentDir
  );

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports'], factory);
  } else if (
    typeof exports === 'object' &&
    typeof exports.nodeName !== 'string'
  ) {
    // CommonJS
    factory(exports);
  } else {
    // Browser globals
    factory((root.Banks_AT = {}));
  }
})(this, function(exports) {
  /**
   * bankData in an array of bank name, bankCode || bankleitzahl, bic
   * Source: https://www.oenb.at/idakilz/kiverzeichnis?lang=de
   */

  var BANK_CODE_LENGHTS = 5;
  var BIC_NUMBER_LENGTHS = 11;
  var COUNTRY_CODE_UPPERCASE = 'AT';

  var bankData = [
    ['Oesterreichische Nationalbank', '00100', 'NABAATWWXXX'],
    ['Republik Österreich', '01000', 'BUNDATWWXXX'],
    [
      'Oesterreichische Kontrollbank Aktiengesellschaft',
      '10000',
      'OEKOATWWXXX'
    ],
    ['UniCredit Bank Austria AG', '11000', 'BKAUATWWXXX'],
    ['UniCredit Bank Austria AG', '12000', 'BKAUATWWXXX'],
    ['UniCredit Bank Austria AG', '12002', 'BKAUATWWXXX'],
    ['UniCredit Bank Austria AG', '12004', 'BKAUATWWXXX'],
    ['UniCredit Bank Austria AG', '12005', 'BKAUATWWXXX'],
    [
      'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse Aktiengesellschaft',
      '14000',
      'BAWAATWWXXX'
    ],
    [
      'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse Aktiengesellschaft',
      '14200',
      'BAWAATWWXXX'
    ],
    [
      'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse Aktiengesellschaft',
      '14210',
      'BAWAATWWXXX'
    ],
    [
      'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse Aktiengesellschaft',
      '14900',
      'BAWAATWWXXX'
    ],
    ['Oberbank AG', '15000', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15001', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15002', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15003', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15004', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15005', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15006', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15007', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15008', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15009', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15010', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15011', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15012', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15013', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15021', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15022', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15023', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15024', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15025', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15030', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15031', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15040', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15041', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15042', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15060', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15061', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15062', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15080', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15081', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15090', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15091', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15092', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15093', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15094', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15095', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15096', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15097', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15110', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15120', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15121', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15122', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15123', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15130', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15131', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15132', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15133', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15134', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15135', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15150', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15151', 'OBKLAT2LXXX'],
    ['Oberbank AG', '15899', 'OBKLAT2LXXX'],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16000',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16300',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16310',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16320',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16330',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16340',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16350',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16360',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16370',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16380',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16390',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16400',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16410',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16420',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16430',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16440',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16450',
      'BTVAAT22XXX'
    ],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '16460',
      'BTVAAT22XXX'
    ],
    ['BKS Bank AG', '17000', 'BFKKAT2KXXX'],
    ['BKS Bank AG', '17100', 'BFKKAT2KXXX'],
    ['BKS Bank AG', '17200', 'BFKKAT2KXXX'],
    ['BKS Bank AG', '17300', 'BFKKAT2KXXX'],
    ['BKS Bank AG', '17400', 'BFKKAT2KXXX'],
    ['BKS Bank AG', '17500', 'BFKKAT2KXXX'],
    ['BKS Bank AG', '17899', 'BFKKAT2KXXX'],
    ['BNP Paribas SA Niederlassung Österreich', '18100', 'GEBAATWWXXX'],
    ['paybox Bank AG', '18120', 'PABXATW1XXX'],
    ['Österreichische Ärzte- und Apothekerbank AG', '18130', 'BWFBATW1XXX'],
    ['Citibank Europe plc, Austria Branch', '18140', 'CITIATWXXXX'],
    ['Société Générale Zweigniederlassung Wien', '18150', 'SGABATWWXXX'],
    ['VOLKSBANK WIEN AG', '18160', 'VBOEATWWXXX'],
    ['KA Finanz AG', '18170', 'OKOMATWWXXX'],
    [
      'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse Aktiengesellschaft',
      '18190',
      'BAWAATWWXXX'
    ],
    ['UniCredit Bank AG, Zweigniederlassung Wien', '18250', 'CAIBATWWXXX'],
    ['Generali Bank AG', '18400', 'BGENATWWXXX'],
    ['Volkskreditbank AG', '18600', 'VKBLAT2LXXX'],
    ['Deutsche Bank Aktiengesellschaft Filiale Wien', '19100', 'DEUTATWWXXX'],
    ['bank99 AG', '19120', 'SPBAATWWXXX'],
    ['Bank Gutmann Aktiengesellschaft', '19140', 'GUTBATWWXXX'],
    ['Kathrein Privatbank Aktiengesellschaft', '19150', 'KTBKATWWXXX'],
    ['Wiener Privatbank SE', '19160', 'WIPBATWWXXX'],
    ['PARTNER BANK AKTIENGESELLSCHAFT', '19170', 'PABAAT2LXXX'],
    ['Schelhammer Capital Bank AG', '19190', 'BSSWATWWXXX'],
    ['Schoellerbank Aktiengesellschaft', '19200', 'SCHOATWWXXX'],
    ['bank99 AG', '19210', 'SPBAATWWB99'],
    ['Bank Winter & Co. AG', '19220', 'WISMATWWXXX'],
    [
      'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse Aktiengesellschaft',
      '19250',
      'DIRAAT2SXXX'
    ],
    [
      'European American Investment Bank Aktiengesellschaft',
      '19340',
      'EUAAATWWXXX'
    ],
    ['ING Austria Branch of ING Bank N.V.', '19360', 'INGBATWWXXX'],
    ['Bankhaus Denzel Aktiengesellschaft', '19380', 'DENEATW1XXX'],
    ['BMW Austria Bank GmbH', '19390', 'BMASAT21XXX'],
    ['Mercedes-Benz Bank GmbH', '19410', 'WELCATW1XXX'],
    ['RCI Banque SA, Niederlassung Österreich', '19420', 'RCNOATW1XXX'],
    ['Wiener Privatbank SE', '19440', 'WIPBATWWXXX'],
    ['Liechtensteinische Landesbank (Österreich) AG', '19450', 'COPRATWWXXX'],
    ['LGT Bank AG, Zweigniederlassung Österreich', '19460', 'BLFLATWWXXX'],
    ['flatexDEGIRO Bank AG', '19480', 'BIIWATWWXXX'],
    ['Mizuho Bank Europe N.V. Vienna Branch', '19490', 'MHCBATWWXXX'],
    ['Zürcher Kantonalbank Österreich AG', '19510', 'PIAGAT2SXXX'],
    ['Opel Bank S.A. Niederlassung Österreich', '19515', 'OPBAATW2XXX'],
    [
      'Steiermärkische Bank und Sparkassen Aktiengesellschaft',
      '19520',
      'STSPAT2GXXX'
    ],
    ['Bankhaus Carl Spängler & Co. Aktiengesellschaft', '19530', 'SPAEAT2SXXX'],
    [
      'Bank of China (Central and Eastern Europe) Limited Vienna Branch',
      '19540',
      'BKCHATWWXXX'
    ],
    ['Schelhammer Capital Bank AG', '19600', 'BSSWATWWXXX'],
    ['DenizBank AG', '19650', 'ESBKATWWXXX'],
    ['Kommunalkredit Austria AG', '19660', 'INVOATWWXXX'],
    ['Commerzbank AG Niederlassung Wien', '19675', 'COBAATWXXXX'],
    ['VakifBank International AG', '19690', 'TVBAATWWXXX'],
    ['Santander Consumer Bank GmbH', '19810', 'SANTATWWXXX'],
    ['Wüstenrot Bank AG', '19825', 'WUSNATWWXXX'],
    ['Ford Bank GmbH, Zweigniederlassung Österreich', '19835', 'FAZFAT21XXX'],
    ['Western Union International Bank GmbH', '19880', 'WUIBATWWXXX'],
    ['ICBC Austria Bank GmbH', '19888', 'ICBKATWWXXX'],
    ['Raiffeisen Digital Bank AG', '19930', 'CENBATWWXXX'],
    ['Raiffeisen Digital Bank AG', '19931', 'CENBATWWRDB'],
    ['Bausparkasse Wüstenrot Aktiengesellschaft', '19950', 'WUBUAT2SXXX'],
    ['Banco do Brasil Aktiengesellschaft', '19970', 'BRASATWWXXX'],
    ['Erste Group Bank AG', '20100', 'GIBAATWGXXX'],
    ['Erste Bank der oesterreichischen Sparkassen AG', '20111', 'GIBAATWWXXX'],
    ['Die Zweite Wiener Vereins-Sparcasse', '20112', 'GIBAAT21XXX'],
    ['Sparkasse der Stadt Amstetten AG', '20202', 'SPAMAT21XXX'],
    ['Sparkasse Baden', '20205', 'SPBDAT21XXX'],
    [
      'Sparkasse Hainburg-Bruck-Neusiedl Aktiengesellschaft',
      '20216',
      'SPHBAT21XXX'
    ],
    ['Sparkasse Haugsdorf', '20218', 'SPHAAT21XXX'],
    [
      'Sparkasse Herzogenburg-Neulengbach Bank Aktiengesellschaft',
      '20219',
      'SPHEAT21XXX'
    ],
    [
      'Sparkasse Horn-Ravelsbach-Kirchberg Aktiengesellschaft',
      '20221',
      'SPHNAT21XXX'
    ],
    ['Sparkasse Baden', '20225', 'SPBDAT21XXX'],
    ['Sparkasse Korneuburg AG', '20227', 'SSKOAT21XXX'],
    ['KREMSER BANK UND SPARKASSEN AKTIENGESELLSCHAFT', '20228', 'SPKDAT21XXX'],
    ['Sparkasse Langenlois', '20230', 'SPLSAT21XXX'],
    ['Sparkasse Neunkirchen', '20241', 'SPNGAT21XXX'],
    ['Sparkasse Pottenstein N.Ö.', '20245', 'SPPOAT21XXX'],
    ['Sparkasse Poysdorf AG', '20246', 'SPPDAT21XXX'],
    [
      'SPARKASSE NIEDERÖSTERREICH MITTE WEST AKTIENGESELLSCHAFT',
      '20256',
      'SPSPAT21XXX'
    ],
    ['Sparkasse Scheibbs AG', '20257', 'SPSBAT21XXX'],
    ['Waldviertler Sparkasse Bank AG', '20263', 'SPZWAT21XXX'],
    ['Wiener Neustädter Sparkasse', '20267', 'WINSATWNXXX'],
    ['Waldviertler Sparkasse Bank AG', '20272', 'SPZWAT21XXX'],
    ['SPARKASSE FRANKENMARKT AKTIENGESELLSCHAFT', '20306', 'SPFRAT21XXX'],
    ['Sparkasse Salzkammergut AG', '20314', 'SKBIAT21XXX'],
    ['Sparkasse Oberösterreich Bank AG', '20315', 'ASPKAT2LXXX'],
    ['Sparkasse Lambach Bank Aktiengesellschaft', '20317', 'SPLAAT21XXX'],
    ['Sparkasse Oberösterreich Bank AG', '20320', 'ASPKAT2LXXX'],
    ['Sparkasse Neuhofen Bank Aktiengesellschaft', '20326', 'SPNKAT21XXX'],
    ['Sparkasse Eferding-Peuerbach-Waizenkirchen', '20330', 'SPPBAT21XXX'],
    ['Sparkasse Pregarten - Unterweißenbach AG', '20331', 'SPPRAT21XXX'],
    ['Sparkasse Ried im Innkreis-Haag am Hausruck', '20333', 'SPRHAT21XXX'],
    [
      'Sparkasse Mühlviertel-West Bank Aktiengesellschaft',
      '20334',
      'SMWRAT21XXX'
    ],
    ['Sparkasse Mittersill Bank AG', '20402', 'SPMIAT21XXX'],
    ['Salzburger Sparkasse Bank Aktiengesellschaft', '20404', 'SBGSAT2SXXX'],
    ['Sparkasse Imst AG', '20502', 'SPIMAT21XXX'],
    [
      'Tiroler Sparkasse Bankaktiengesellschaft Innsbruck',
      '20503',
      'SPIHAT22XXX'
    ],
    ['Sparkasse der Stadt Kitzbühel', '20505', 'SPKIAT2KXXX'],
    ['Sparkasse Kufstein Tiroler Sparkasse von 1877', '20506', 'SPKUAT22XXX'],
    ['Lienzer Sparkasse AG', '20507', 'LISPAT21XXX'],
    ['Sparkasse Rattenberg Bank AG', '20508', 'SPRTAT21XXX'],
    ['Sparkasse Reutte AG', '20509', 'SPREAT21XXX'],
    ['Sparkasse Schwaz AG', '20510', 'SPSCAT22XXX'],
    ['Sparkasse Bregenz Bank Aktiengesellschaft', '20601', 'SPBRAT2BXXX'],
    ['Dornbirner Sparkasse Bank AG', '20602', 'DOSPAT2DXXX'],
    ['Sparkasse der Gemeinde Egg', '20603', 'SPEGAT21XXX'],
    ['Sparkasse der Stadt Feldkirch', '20604', 'SPFKAT2BXXX'],
    ['Sparkasse Bludenz Bank AG', '20607', 'SSBLAT21XXX'],
    ['Sparkasse Feldkirchen/Kärnten', '20702', 'SPFNAT21XXX'],
    ['Kärntner Sparkasse Aktiengesellschaft', '20706', 'KSPKAT2KXXX'],
    [
      'Steiermärkische Bank und Sparkassen Aktiengesellschaft',
      '20815',
      'STSPAT2GXXX'
    ],
    ['Sparkasse Mürzzuschlag Aktiengesellschaft', '20828', 'SPMZAT21XXX'],
    ['Sparkasse Pöllau AG', '20833', 'SPPLAT21XXX'],
    [
      'Sparkasse Voitsberg-Köflach Bankaktiengesellschaft',
      '20839',
      'SPVOAT21XXX'
    ],
    [
      'Bausparkasse der österreichischen Sparkassen Aktiengesellschaft',
      '24012',
      'BAOSATWWXXX'
    ],
    ['Raiffeisen Bank International AG', '30000', 'RZBAATWWXXX'],
    ['Raiffeisen Bank International AG', '31000', 'RZBAATWWXXX'],
    ['Raiffeisen Bank International AG', '31111', 'RZBAATWWVAM'],
    ['Raiffeisen Bank International AG', '31112', 'RZBAATWWREC'],
    ['Raiffeisen Bausparkasse Gesellschaft m.b.H.', '31303', 'RBSKATW1XXX'],
    ['NOTARTREUHANDBANK AG', '31500', 'NTBAATWWXXX'],
    ['RAIFFEISENLANDESBANK NIEDERÖSTERREICH-WIEN AG', '32000', 'RLNWATWWXXX'],
    ['Raiffeisenbank Wagram-Schmidatal eGen', '32002', 'RLNWATW1002'],
    ['Raiffeisenbank Region Amstetten eGen', '32025', 'RLNWATWWAMS'],
    ['Raiffeisenbank Region Amstetten eGen', '32033', 'RLNWATWWAMS'],
    ['Raiffeisenbank Wolkersdorf - Auersthal eGen', '32039', 'RLNWATWWWDF'],
    ['Raiffeisenbank Region Baden eGen', '32045', 'RLNWATWWBAD'],
    ['Raiffeisenkasse Blindenmarkt eGen', '32059', 'RLNWATW1059'],
    ['Raiffeisenbank Bruck-Carnuntum eGen', '32073', 'RLNWATWWBRL'],
    ['Raiffeisen-Regionalbank Gänserndorf eGen', '32092', 'RLNWATWWGAE'],
    ['Raiffeisenkasse Dobersberg-Waldkirchen eGen', '32099', 'RLNWATW1099'],
    ['Raiffeisenbank Region Baden eGen', '32118', 'RLNWATWWBAD'],
    ['Raiffeisenbank Eggenburg eGen', '32123', 'RLNWATWW123'],
    ['Raiffeisenbank Thayatal-Mitte eGen', '32127', 'RLNWATW1127'],
    ['Raiffeisenkasse Ernstbrunn eGen', '32145', 'RLNWATW1145'],
    ['Raiffeisenbank Region Wiener Alpen eGen', '32195', 'RLNWATWWASP'],
    ['Raiffeisenbank Hollabrunn eGen', '32198', 'RLNWATW1322'],
    ['Raiffeisenbank Weinviertel Nordost eGen', '32227', 'RLNWATWWZDF'],
    ['Raiffeisenbank Wagram-Schmidatal eGen', '32242', 'RLNWATW1002'],
    ['Raiffeisenbank Region Baden eGen', '32247', 'RLNWATWWBAD'],
    ['Raiffeisen Regionalbank Mödling eGen', '32250', 'RLNWATWWGTD'],
    ['Raiffeisenbank Seefeld-Hadres eGen', '32275', 'RLNWATW1275'],
    ['Raiffeisenkasse Haidershofen eGen', '32278', 'RLNWATWW278'],
    ['Raiffeisenkasse Heiligeneich eGen', '32286', 'RLNWATW1286'],
    ['Raiffeisenbank Wagram-Schmidatal eGen', '32291', 'RLNWATW1002'],
    ['Raiffeisenbank Hollabrunn eGen', '32322', 'RLNWATW1322'],
    ['Raiffeisenbank Region St. Pölten eGen', '32356', 'RLNWATWWOBG'],
    ['Raiffeisenbank Klosterneuburg eGen', '32367', 'RLNWATWW367'],
    ['Raiffeisenbank Region Schallaburg eGen', '32368', 'RLNWATW1477'],
    ['Raiffeisenbank Region Wiener Alpen eGen', '32374', 'RLNWATWWASP'],
    ['Raiffeisenbank Korneuburg eGen', '32395', 'RLNWATWWKOR'],
    ['Raiffeisenbank Krems eGen', '32397', 'RLNWATWWKRE'],
    ['Raiffeisenbank Laa/Thaya eGen', '32413', 'RLNWATWWLAA'],
    ['Raiffeisenbank Wienerwald eGen', '32414', 'RLNWATWWPRB'],
    ['Raiffeisenbank Oberes Waldviertel eGen', '32415', 'RLNWATWWOWS'],
    ['Raiffeisenbank Langenlois eGen', '32426', 'RLNWATWW426'],
    ['Raiffeisenbank Kreuzenstein eGen', '32438', 'RLNWATW1438'],
    ['Raiffeisenbank Traisen-Gölsental eGen', '32447', 'RLNWATWWLFD'],
    ['Raiffeisenbank Region Schallaburg eGen', '32455', 'RLNWATW1477'],
    ['Raiffeisenbank Region Schallaburg eGen', '32477', 'RLNWATW1477'],
    ['Raiffeisenbank Tulln eGen', '32497', 'RLNWATW1880'],
    ['Raiffeisenbank im Weinviertel eGen', '32501', 'RLNWATWWMIB'],
    ['Raiffeisenkasse Neusiedl a.d.Zaya eGen', '32551', 'RLNWATWWNSD'],
    ['Raiffeisenbank Region St. Pölten eGen', '32585', 'RLNWATWWOBG'],
    ['Raiffeisenbank Gross Gerungs eGen', '32589', 'RLNWATW1589'],
    ['Raiffeisenkasse Orth a.d. Donau eGen', '32614', 'RLNWATWWODO'],
    ['Raiffeisenbank Region Waldviertel Mitte eGen', '32615', 'RLNWATWWZWE'],
    ['Raiffeisenbank Region Wiener Alpen eGen', '32631', 'RLNWATWWASP'],
    ['Raiffeisen Regionalbank Mödling eGen', '32633', 'RLNWATWWGTD'],
    ['Raiffeisenbank Wr. Neustadt-Schneebergland eGen', '32642', 'RLNWATWWWRN'],
    ['Raiffeisenbank Region Wiener Alpen eGen', '32647', 'RLNWATWWASP'],
    ['Raiffeisenbank Mittleres Mostviertel eGen', '32651', 'RLNWATWW939'],
    [
      'Raiffeisenkasse Pottschach registrierte Genossenschaft mit beschränkter Haftung',
      '32660',
      'RLNWATW1660'
    ],
    ['Raiffeisenbank im Weinviertel eGen', '32663', 'RLNWATWWMIB'],
    ['Raiffeisenbank Wienerwald eGen', '32667', 'RLNWATWWPRB'],
    ['Raiffeisenbank Region Schallaburg eGen', '32679', 'RLNWATW1477'],
    ['Raiffeisenkasse Retz-Pulkautal eGen', '32715', 'RLNWATW1715'],
    ['Raiffeisenbank Kreuzenstein eGen', '32731', 'RLNWATW1438'],
    ['Raiffeisenbank Region St. Pölten eGen', '32769', 'RLNWATWWOBG'],
    ['Raiffeisenbank Region Amstetten eGen', '32778', 'RLNWATWWAMS'],
    ['Raiffeisenbank Region Amstetten eGen', '32789', 'RLNWATWWAMS'],
    ['Raiffeisenbank Region Schwechat eGen', '32823', 'RLNWATWW823'],
    ['Raiffeisenbank Stockerau eGen', '32842', 'RLNWATWWSTO'],
    ['Raiffeisenbank Wr. Neustadt-Schneebergland eGen', '32865', 'RLNWATWWWRN'],
    ['Raiffeisenbank Tulln eGen', '32880', 'RLNWATW1880'],
    ['Raiffeisenbank Vitis eGen', '32901', 'RLNWATW1901'],
    ['Raiffeisenbank Waidhofen a. d. Thaya eGen', '32904', 'RLNWATWWWTH'],
    ['Raiffeisenbank Ybbstal eGen', '32906', 'RLNWATWWWHY'],
    ['Raiffeisenbank Region Baden eGen', '32930', 'RLNWATWWBAD'],
    ['Raiffeisenbank Oberes Waldviertel eGen', '32936', 'RLNWATWWOWS'],
    ['Raiffeisenbank Wr. Neustadt-Schneebergland eGen', '32937', 'RLNWATWWWRN'],
    ['Raiffeisenbank Mittleres Mostviertel eGen', '32939', 'RLNWATWW939'],
    ['Raiffeisenbank Wr. Neustadt-Schneebergland eGen', '32940', 'RLNWATWWWRN'],
    ['Raiffeisenbank Traisen-Gölsental eGen', '32944', 'RLNWATWWLFD'],
    ['Raiffeisenbank Wolkersdorf - Auersthal eGen', '32951', 'RLNWATWWWDF'],
    ['Raiffeisenbank Hollabrunn eGen', '32982', 'RLNWATW1322'],
    ['Raiffeisenbank Weinviertel Nordost eGen', '32985', 'RLNWATWWZDF'],
    ['Raiffeisenbank Region Waldviertel Mitte eGen', '32990', 'RLNWATWWZWE'],
    [
      'Raiffeisenlandesbank Burgenland und Revisionsverband eGen',
      '33000',
      'RLBBAT2EXXX'
    ],
    ['Raiffeisenbank Seewinkel-Hansag eGen', '33002', 'RLBBAT2E002'],
    ['Raiffeisenbank Neusiedlersee-Seewinkel eGen', '33004', 'RLBBAT2E033'],
    [
      'Raiffeisenbank Region Deutschkreutz-Horitschon eGen',
      '33010',
      'RLBBAT2E010'
    ],
    ['Raiffeisenbank Neusiedlersee-Hügelland eGen', '33012', 'RLBBAT2E012'],
    [
      'Raiffeisenbank Draßmarkt-Kobersdorf-St. Martin eGen',
      '33014',
      'RLBBAT2E014'
    ],
    [
      'Raiffeisen Regionalbank Güssing-Jennersdorf eGen',
      '33027',
      'RLBBAT2E027'
    ],
    ['Raiffeisenbank Heideboden eGen', '33028', 'RLBBAT2E090'],
    [
      'Raiffeisenbank Region Deutschkreutz-Horitschon eGen',
      '33031',
      'RLBBAT2E010'
    ],
    ['Raiffeisenbank Neusiedlersee-Seewinkel eGen', '33033', 'RLBBAT2E033'],
    [
      'Raiffeisen Regionalbank Güssing-Jennersdorf eGen',
      '33034',
      'RLBBAT2E027'
    ],
    [
      'Raiffeisenlandesbank Burgenland und Revisionsverband eGen',
      '33035',
      'RLBBAT2EXXX'
    ],
    ['Raiffeisenbank Dreiländereck Bgld-Nord eGen', '33038', 'RLBBAT2E038'],
    [
      'Raiffeisen Regionalbank Güssing-Jennersdorf eGen',
      '33041',
      'RLBBAT2E027'
    ],
    ['Raiffeisenbank Burgenland Mitte eGen', '33046', 'RLBBAT2E065'],
    [
      'Raiffeisenbank Region Deutschkreutz-Horitschon eGen',
      '33048',
      'RLBBAT2E010'
    ],
    [
      'Raiffeisenbank Frauenkirchen-Mönchhof-Podersdorf am See eGen',
      '33054',
      'RLBBAT2E116'
    ],
    ['Raiffeisenbank Neusiedlersee-Hügelland eGen', '33055', 'RLBBAT2E012'],
    [
      'Raiffeisenbank Region Deutschkreutz-Horitschon eGen',
      '33058',
      'RLBBAT2E010'
    ],
    ['Raiffeisenbank Dreiländereck Bgld-Nord eGen', '33063', 'RLBBAT2E038'],
    ['Raiffeisenbank Burgenland Mitte eGen', '33065', 'RLBBAT2E065'],
    ['Raiffeisenbezirksbank Oberwart eGen', '33067', 'RLBBAT2E125'],
    ['Raiffeisenbank Neusiedlersee-Seewinkel eGen', '33071', 'RLBBAT2E033'],
    ['Raiffeisenbank Region Parndorf eGen', '33072', 'RLBBAT2E072'],
    [
      'Raiffeisenbank Frauenkirchen-Mönchhof-Podersdorf am See eGen',
      '33074',
      'RLBBAT2E116'
    ],
    ['Raiffeisenbank Purbach eGen', '33078', 'RLBBAT2E078'],
    ['Raiffeisenbank Neusiedlersee-Hügelland eGen', '33085', 'RLBBAT2E012'],
    ['Raiffeisenbank Heideboden eGen', '33090', 'RLBBAT2E090'],
    ['Raiffeisenbank Neusiedlersee-Hügelland eGen', '33092', 'RLBBAT2E012'],
    ['Raiffeisenbank Seewinkel-Hansag eGen', '33104', 'RLBBAT2E002'],
    ['Raiffeisenbank Weiden am See eGen', '33106', 'RLBBAT2E106'],
    ['Raiffeisenbank Dreiländereck Bgld-Nord eGen', '33114', 'RLBBAT2E038'],
    [
      'Raiffeisenbank Frauenkirchen-Mönchhof-Podersdorf am See eGen',
      '33116',
      'RLBBAT2E116'
    ],
    ['Raiffeisenbank Seewinkel-Hansag eGen', '33118', 'RLBBAT2E002'],
    ['Raiffeisenbank Burgenland Mitte eGen', '33124', 'RLBBAT2E065'],
    ['Raiffeisenbezirksbank Oberwart eGen', '33125', 'RLBBAT2E125'],
    [
      'Raiffeisenlandesbank Burgenland und Revisionsverband eGen',
      '33135',
      'RLBBAT2EXXX'
    ],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '34000',
      'RZOOAT2LXXX'
    ],
    ['Raiffeisenbank Aspach-Wildenau eGen', '34016', 'RZOOAT2L016'],
    ['Raiffeisenbank Region Altheim eGen', '34030', 'RZOOAT2L030'],
    ['Raiffeisenbank Region Braunau eGen', '34060', 'RZOOAT2L060'],
    ['Raiffeisenbank Grein eGen', '34068', 'RZOOAT2L068'],
    ['Raiffeisenbank Donau-Ameisberg eGen', '34075', 'RZOOAT2L075'],
    ['Raiffeisenbank Ennstal eGen', '34080', 'RZOOAT2L080'],
    ['Raiffeisenbank Eberschwang-Pramet eGen', '34081', 'RZOOAT2L081'],
    ['Raiffeisenbank Edt-Lambach eGen', '34083', 'RZOOAT2L083'],
    ['Raiffeisenbank Feldkirchen-Goldwörth eGen', '34100', 'RZOOAT2L100'],
    ['Raiffeisenbank Region Freistadt eGen', '34110', 'RZOOAT2L110'],
    ['Raiffeisenbank Region Gallneukirchen eGen', '34111', 'RZOOAT2L111'],
    ['Raiffeisenbank Gampern eGen', '34113', 'RZOOAT2L113'],
    ['Raiffeisenbank Steyr eGen', '34114', 'RZOOAT2L114'],
    ['Raiffeisenbank Geretsberg eGen', '34118', 'RZOOAT2L118'],
    ['Raiffeisenbank Ennstal eGen', '34126', 'RZOOAT2L080'],
    ['Raiffeisenbank Almtal eGen', '34127', 'RZOOAT2L127'],
    ['Raiffeisenbank Gunskirchen eGen', '34129', 'RZOOAT2L129'],
    ['Raiffeisenbank Gramastetten eGen', '34135', 'RZOOAT2L135'],
    [
      'Raiffeisenbank Handenberg - St. Georgen a. F. eGen',
      '34155',
      'RZOOAT2L155'
    ],
    ['Raiffeisenbank Region Sierning-Enns eGen', '34157', 'RZOOAT2L560'],
    ['Raiffeisenbank Region Rohrbach eGen', '34160', 'RZOOAT2L410'],
    ['Raiffeisenbank Region Gallneukirchen eGen', '34161', 'RZOOAT2L111'],
    [
      'Raiffeisenbank Hinterstoder und Vorderstoder eGen',
      '34165',
      'RZOOAT2L165'
    ],
    ['Raiffeisenbank Linz-Land West eGen', '34170', 'RZOOAT2L276'],
    ['Raiffeisenbank Region Eferding eGen', '34180', 'RZOOAT2L180'],
    ['Raiffeisenbank Innkreis Mitte eGen', '34200', 'RZOOAT2L200'],
    ['Raiffeisenbank Kematen-Neuhofen eGen', '34214', 'RZOOAT2L214'],
    ['Raiffeisenbank Kleinmünchen/Linz eGen', '34226', 'RZOOAT2L226'],
    ['Raiffeisenbank Kollerschlag eGen', '34231', 'RZOOAT2L231'],
    ['Raiffeisenbank Region Kirchdorf eGen', '34233', 'RZOOAT2L380'],
    ['Raiffeisenbank Wels eGen', '34234', 'RZOOAT2L680'],
    ['Raiffeisenbank Region Hausruck eGen', '34250', 'RZOOAT2L250'],
    ['Raiffeisenbank Linz-Land West eGen', '34276', 'RZOOAT2L276'],
    ['Raiffeisenbank Region Bad Leonfelden eGen', '34277', 'RZOOAT2L277'],
    ['Raiffeisenbank Region Ried i.I. eGen', '34284', 'RZOOAT2L450'],
    ['Raiffeisenbank Lochen am See eGen', '34290', 'RZOOAT2L290'],
    ['Raiffeisenbank Region Neufelden eGen', '34300', 'RZOOAT2L300'],
    ['Raiffeisenbank Mattigtal eGen', '34303', 'RZOOAT2L303'],
    ['Raiffeisenbank Maria Schmolln-St. Johann eGen', '34312', 'RZOOAT2L312'],
    ['Raiffeisenbank Meggenhofen-Kematen eGen', '34313', 'RZOOAT2L313'],
    ['Raiffeisenbank Region Kirchdorf eGen', '34318', 'RZOOAT2L380'],
    ['Raiffeisenbank Region Kirchdorf eGen', '34321', 'RZOOAT2L380'],
    ['Raiffeisenbank Mondseeland eGen', '34322', 'RZOOAT2L322'],
    ['Raiffeisenbank Mühlviertler Alm eGen', '34330', 'RZOOAT2L330'],
    ['Raiffeisenbank Neukirchen an der Vöckla eGen', '34356', 'RZOOAT2L356'],
    ['Raiffeisenbank Region Neufelden eGen', '34361', 'RZOOAT2L300'],
    ['Raiffeisenbank Region Kirchdorf eGen', '34362', 'RZOOAT2L380'],
    ['Raiffeisenbank Attersee-Süd eGen', '34363', 'RZOOAT2L363'],
    ['Raiffeisenbank Oberes Innviertel eGen', '34370', 'RZOOAT2L370'],
    ['Raiffeisenbank Region Kirchdorf eGen', '34380', 'RZOOAT2L380'],
    ['Raiffeisenbank Mittleres Rodltal eGen', '34383', 'RZOOAT2L383'],
    ['Raiffeisenbank Ohlsdorf eGen', '34390', 'RZOOAT2L390'],
    ['Raiffeisenbank Region Schwanenstadt eGen', '34400', 'RZOOAT2L630'],
    ['Raiffeisenbank Region Rohrbach eGen', '34410', 'RZOOAT2L410'],
    ['Raiffeisenbank Mühlviertler Alm eGen', '34420', 'RZOOAT2L330'],
    ['Raiffeisenbank Region Kirchdorf eGen', '34427', 'RZOOAT2L380'],
    ['Raiffeisenbank Gunskirchen eGen', '34430', 'RZOOAT2L129'],
    ['Raiffeisenbank Pöndorf - Frankenmarkt eGen', '34434', 'RZOOAT2L434'],
    ['Raiffeisenbank Prambachkirchen eGen', '34437', 'RZOOAT2L437'],
    ['Raiffeisenbank Eberschwang-Pramet eGen', '34438', 'RZOOAT2L081'],
    ['Raiffeisenbank Peuerbach eGen', '34442', 'RZOOAT2L442'],
    ['Raiffeisenbank Region Ried i.I. eGen', '34450', 'RZOOAT2L450'],
    ['Raiffeisenbank Region Schärding eGen', '34455', 'RZOOAT2L455'],
    ['Raiffeisenbank Aist eGen', '34460', 'RZOOAT2L460'],
    ['Raiffeisenbank Region Gallneukirchen eGen', '34475', 'RZOOAT2L111'],
    ['Raiffeisenbank Region Kirchdorf eGen', '34481', 'RZOOAT2L380'],
    ['Raiffeisenbank Windischgarsten eGen', '34491', 'RZOOAT2L491'],
    ['Raiffeisenbank Salzkammergut eGen', '34510', 'RZOOAT2L510'],
    ['Raiffeisenbank St. Agatha eGen', '34521', 'RZOOAT2L521'],
    ['Raiffeisenbank St.Florian am Inn eGen', '34522', 'RZOOAT2L522'],
    ['Raiffeisenbank Attergau eGen', '34523', 'RZOOAT2L523'],
    ['Raiffeisenbank St. Marien eGen', '34530', 'RZOOAT2L530'],
    ['Raiffeisenbank Region Neufelden eGen', '34535', 'RZOOAT2L300'],
    ['Raiffeisenbank St. Roman eGen', '34540', 'RZOOAT2L540'],
    ['Raiffeisenbank Steyr eGen', '34543', 'RZOOAT2L114'],
    ['Raiffeisenbank Inneres Salzkammergut eGen', '34545', 'RZOOAT2L545'],
    ['Raiffeisenbank Region Sierning-Enns eGen', '34560', 'RZOOAT2L560'],
    ['Raiffeisenbank Region Kirchdorf eGen', '34606', 'RZOOAT2L380'],
    ['Raiffeisenbank Attersee-Nord eGen', '34608', 'RZOOAT2L608'],
    ['Raiffeisenbank Aist eGen', '34613', 'RZOOAT2L460'],
    ['Raiffeisenbank Region Schwanenstadt eGen', '34630', 'RZOOAT2L630'],
    ['Raiffeisenbank Steinbach-Grünburg eGen', '34633', 'RZOOAT2L633'],
    ['Raiffeisenbank Perg eGen', '34637', 'RZOOAT2L777'],
    ['Raiffeisenbank Region Braunau eGen', '34661', 'RZOOAT2L060'],
    [
      'Raiffeisenbank Timelkam-Lenzing-Puchkirchen eGen',
      '34669',
      'RZOOAT2L669'
    ],
    ['Raiffeisenbank Wels eGen', '34680', 'RZOOAT2L680'],
    ['Raiffeisenbank Region Vöcklabruck eGen', '34710', 'RZOOAT2L710'],
    ['Raiffeisenbank Walding-Ottensheim eGen', '34732', 'RZOOAT2L732'],
    ['Raiffeisenbank Region Ried i.I. eGen', '34735', 'RZOOAT2L450'],
    ['Raiffeisenbank Region Grieskirchen eGen', '34736', 'RZOOAT2L736'],
    ['Raiffeisenbank Wartberg an der Krems eGen', '34738', 'RZOOAT2L738'],
    ['Raiffeisenbank Weißkirchen a.d. Traun eGen', '34741', 'RZOOAT2L741'],
    ['Raiffeisenbank Weyer eGen', '34747', 'RZOOAT2L747'],
    ['Raiffeisenbank Bad Wimsbach-Neydharting eGen', '34750', 'RZOOAT2L750'],
    ['Raiffeisenbank Wels Süd eGen', '34770', 'RZOOAT2L770'],
    ['Raiffeisenbank Perg eGen', '34777', 'RZOOAT2L777'],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '34796',
      'RZOOAT2LXXX'
    ],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '34920',
      'RZOOAT2LXXX'
    ],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '34921',
      'RZOOAT2LXXX'
    ],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '34922',
      'RZOOAT2LXXX'
    ],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '34923',
      'RZOOAT2LXXX'
    ],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '34950',
      'RZOOAT2LXXX'
    ],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '34955',
      'RZOOAT2LXXX'
    ],
    ['Raiffeisenverband Salzburg eGen', '35000', 'RVSAAT2SXXX'],
    ['Raiffeisenbank Lammertal eGen', '35001', 'RVSAAT2S001'],
    ['Raiffeisenbank Pinzgau Mitte eGen', '35003', 'RVSAAT2S053'],
    ['Raiffeisenbank Altenmarkt-Flachau-Eben eGen', '35004', 'RVSAAT2S004'],
    ['Raiffeisenbank Untersberg eGen', '35005', 'RVSAAT2S018'],
    ['Raiffeisenbank Lammertal eGen', '35006', 'RVSAAT2S001'],
    ['Raiffeisenbank Anthering - Elixhausen eGen', '35007', 'RVSAAT2S007'],
    ['Raiffeisenbank Flachgau Mitte eGen', '35008', 'RVSAAT2S015'],
    ['Raiffeisenbank Salzburger Seenland eGen', '35009', 'RVSAAT2S047'],
    ['Raiffeisenbank Pongau Mitte eGen', '35010', 'RVSAAT2S055'],
    ['Raiffeisenbank Oberpinzgau eGen', '35011', 'RVSAAT2S039'],
    ['Raiffeisenbank Hohe Tauern eGen', '35012', 'RVSAAT2S012'],
    ['Raiffeisenbank Pinzgau Mitte eGen', '35013', 'RVSAAT2S053'],
    ['Raiffeisenbank Anthering - Elixhausen eGen', '35014', 'RVSAAT2S007'],
    ['Raiffeisenbank Flachgau Mitte eGen', '35015', 'RVSAAT2S015'],
    ['Raiffeisenbank Faistenau-Hintersee eGen', '35016', 'RVSAAT2S016'],
    [
      'Raiffeisenbank Golling-Scheffau-Kellau registrierte Genossenschaft mit beschränkter Haftung',
      '35017',
      'RVSAAT2S017'
    ],
    ['Raiffeisenbank Untersberg eGen', '35018', 'RVSAAT2S018'],
    ['Raiffeisenbank Großarl-Hüttschlag eGen', '35019', 'RVSAAT2S019'],
    ['Raiffeisenbank Großgmain eGen', '35020', 'RVSAAT2S020'],
    ['Raiffeisenbank Wallersee eGen', '35021', 'RVSAAT2S021'],
    ['Raiffeisenbank Hallein-Oberalm eGen', '35022', 'RVSAAT2S022'],
    ['Raiffeisenbank Flachgau Mitte eGen', '35023', 'RVSAAT2S015'],
    ['Raiffeisenbank Wallersee eGen', '35024', 'RVSAAT2S021'],
    ['Raiffeisenbank Fuschlsee West eGen', '35025', 'RVSAAT2S065'],
    ['Raiffeisenbank Gastein eGen', '35026', 'RVSAAT2S026'],
    [
      'Raiffeisenbank Hüttau-St. Martin-Niedernfritz registrierte Genossenschaft mit beschränkter Haftung',
      '35027',
      'RVSAAT2S027'
    ],
    ['Raiffeisenbank Oberpinzgau eGen', '35028', 'RVSAAT2S039'],
    ['Raiffeisenbank Kuchl-St. Koloman eGen', '35029', 'RVSAAT2S029'],
    ['Raiffeisenbank Flachgau Nord eGen', '35030', 'RVSAAT2S030'],
    ['Raiffeisenbank Pinzgau Mitte eGen', '35031', 'RVSAAT2S053'],
    [
      'Raiffeisenbank Salzburg Liefering-Maxglan-Siezenheim eGen',
      '35034',
      'RVSAAT2S034'
    ],
    ['Raiffeisenbank Pinzgau Mitte eGen', '35035', 'RVSAAT2S053'],
    ['Raiffeisenbank Lungau eGen', '35036', 'RVSAAT2S063'],
    ['Raiffeisenbank Lungau eGen', '35037', 'RVSAAT2S063'],
    ['Raiffeisenbank Flachgau Nord eGen', '35038', 'RVSAAT2S030'],
    ['Raiffeisenbank Oberpinzgau eGen', '35039', 'RVSAAT2S039'],
    ['Raiffeisenbank Oberpinzgau eGen', '35042', 'RVSAAT2S039'],
    ['Raiffeisenbank Hohe Tauern eGen', '35043', 'RVSAAT2S012'],
    ['Raiffeisenbank Flachgau Nord eGen', '35044', 'RVSAAT2S030'],
    ['Raiffeisenbank Hallein-Oberalm eGen', '35045', 'RVSAAT2S022'],
    ['Raiffeisenbank Flachgau Nord eGen', '35046', 'RVSAAT2S030'],
    ['Raiffeisenbank Salzburger Seenland eGen', '35047', 'RVSAAT2S047'],
    ['Raiffeisenbank Hohe Tauern eGen', '35048', 'RVSAAT2S012'],
    [
      'Raiffeisenbank Radstadt-Untertauern-Filzmoos-Forstau registrierte Genossenschaft mit beschränkter Haftung',
      '35049',
      'RVSAAT2S049'
    ],
    ['Raiffeisenbank Lungau eGen', '35050', 'RVSAAT2S063'],
    ['Raiffeisenbank Rauris-Bucheben eGen', '35051', 'RVSAAT2S051'],
    [
      'Raiffeisenbank Saalbach-Hinterglemm-Viehhofen eGen',
      '35052',
      'RVSAAT2S052'
    ],
    ['Raiffeisenbank Pinzgau Mitte eGen', '35053', 'RVSAAT2S053'],
    [
      'Raiffeisenbank Salzburg Liefering-Maxglan-Siezenheim eGen',
      '35054',
      'RVSAAT2S034'
    ],
    ['Raiffeisenbank Pongau Mitte eGen', '35055', 'RVSAAT2S055'],
    ['Raiffeisenbank St.Gilgen-Fuschl-Strobl eGen', '35056', 'RVSAAT2S056'],
    ['Raiffeisenbank St. Johann-Wagrain-Kleinarl eGen', '35057', 'RVSAAT2S069'],
    ['Raiffeisenbank Kuchl-St. Koloman eGen', '35058', 'RVSAAT2S029'],
    ['Raiffeisenbank St. Martin-Lofer-Weißbach eGen', '35060', 'RVSAAT2S060'],
    ['Raiffeisenbank Lungau eGen', '35061', 'RVSAAT2S063'],
    ['Raiffeisenbank Straßwalchen eGen', '35062', 'RVSAAT2S062'],
    ['Raiffeisenbank Lungau eGen', '35063', 'RVSAAT2S063'],
    ['Raiffeisenbank Taxenbach eGen', '35064', 'RVSAAT2S064'],
    ['Raiffeisenbank Fuschlsee West eGen', '35065', 'RVSAAT2S065'],
    ['Raiffeisenbank Unken eGen', '35066', 'RVSAAT2S066'],
    ['Raiffeisenbank Oberpinzgau eGen', '35068', 'RVSAAT2S039'],
    ['Raiffeisenbank St. Johann-Wagrain-Kleinarl eGen', '35069', 'RVSAAT2S069'],
    ['Raiffeisenbank Oberpinzgau eGen', '35070', 'RVSAAT2S039'],
    [
      'Raiffeisenbank Wals-Himmelreich registrierte Genossenschaft mit beschränkter Haftung',
      '35071',
      'RVSAAT2S071'
    ],
    ['Raiffeisenbank Pongau Mitte eGen', '35072', 'RVSAAT2S055'],
    ['Raiffeisenbank Salzburger Seenland eGen', '35075', 'RVSAAT2S047'],
    ['Raiffeisenbank Lammertal eGen', '35101', 'RVSAAT2S001'],
    ['Raiffeisenbank Altenmarkt-Flachau-Eben eGen', '35104', 'RVSAAT2S004'],
    ['Raiffeisenbank Untersberg eGen', '35105', 'RVSAAT2S018'],
    ['Raiffeisenbank Flachgau Mitte eGen', '35108', 'RVSAAT2S015'],
    ['Raiffeisenbank Salzburger Seenland eGen', '35109', 'RVSAAT2S047'],
    ['Raiffeisenbank Pongau Mitte eGen', '35110', 'RVSAAT2S055'],
    ['Raiffeisenbank Oberpinzgau eGen', '35111', 'RVSAAT2S039'],
    ['Raiffeisenbank Hohe Tauern eGen', '35112', 'RVSAAT2S012'],
    ['Raiffeisenbank Flachgau Mitte eGen', '35115', 'RVSAAT2S015'],
    ['Raiffeisenbank Untersberg eGen', '35118', 'RVSAAT2S018'],
    ['Raiffeisenbank Großarl-Hüttschlag eGen', '35119', 'RVSAAT2S019'],
    ['Raiffeisenbank Wallersee eGen', '35121', 'RVSAAT2S021'],
    ['Raiffeisenbank Hallein-Oberalm eGen', '35122', 'RVSAAT2S022'],
    ['Raiffeisenbank Fuschlsee West eGen', '35125', 'RVSAAT2S065'],
    ['Raiffeisenbank Gastein eGen', '35126', 'RVSAAT2S026'],
    [
      'Raiffeisenbank Hüttau-St. Martin-Niedernfritz registrierte Genossenschaft mit beschränkter Haftung',
      '35127',
      'RVSAAT2S027'
    ],
    ['Raiffeisenbank Flachgau Nord eGen', '35130', 'RVSAAT2S030'],
    ['Raiffeisenbank Oberpinzgau eGen', '35139', 'RVSAAT2S039'],
    ['Raiffeisenbank Salzburger Seenland eGen', '35147', 'RVSAAT2S047'],
    [
      'Raiffeisenbank Radstadt-Untertauern-Filzmoos-Forstau registrierte Genossenschaft mit beschränkter Haftung',
      '35149',
      'RVSAAT2S049'
    ],
    [
      'Raiffeisenbank Saalbach-Hinterglemm-Viehhofen eGen',
      '35152',
      'RVSAAT2S052'
    ],
    [
      'Raiffeisenbank Salzburg Liefering-Maxglan-Siezenheim eGen',
      '35154',
      'RVSAAT2S034'
    ],
    ['Raiffeisenbank Pongau Mitte eGen', '35155', 'RVSAAT2S055'],
    ['Raiffeisenbank St.Gilgen-Fuschl-Strobl eGen', '35156', 'RVSAAT2S056'],
    ['Raiffeisenbank St. Martin-Lofer-Weißbach eGen', '35160', 'RVSAAT2S060'],
    ['Raiffeisenbank Lungau eGen', '35161', 'RVSAAT2S063'],
    ['Raiffeisenbank Lungau eGen', '35163', 'RVSAAT2S063'],
    ['Raiffeisenbank Taxenbach eGen', '35164', 'RVSAAT2S064'],
    ['Raiffeisenbank St. Johann-Wagrain-Kleinarl eGen', '35169', 'RVSAAT2S069'],
    ['Raiffeisenbank Oberpinzgau eGen', '35170', 'RVSAAT2S039'],
    ['Raiffeisenbank Pongau Mitte eGen', '35172', 'RVSAAT2S055'],
    ['Raiffeisenbank Altenmarkt-Flachau-Eben eGen', '35204', 'RVSAAT2S004'],
    ['Raiffeisenbank Hohe Tauern eGen', '35212', 'RVSAAT2S012'],
    ['Raiffeisenbank Flachgau Mitte eGen', '35215', 'RVSAAT2S015'],
    ['Raiffeisenbank Wallersee eGen', '35221', 'RVSAAT2S021'],
    ['Raiffeisenbank Fuschlsee West eGen', '35225', 'RVSAAT2S065'],
    ['Raiffeisenbank Gastein eGen', '35226', 'RVSAAT2S026'],
    ['Raiffeisenbank Oberpinzgau eGen', '35239', 'RVSAAT2S039'],
    [
      'Raiffeisenbank Radstadt-Untertauern-Filzmoos-Forstau registrierte Genossenschaft mit beschränkter Haftung',
      '35249',
      'RVSAAT2S049'
    ],
    ['Raiffeisenbank Pongau Mitte eGen', '35255', 'RVSAAT2S055'],
    ['Raiffeisenbank St.Gilgen-Fuschl-Strobl eGen', '35256', 'RVSAAT2S056'],
    ['Raiffeisenbank Lungau eGen', '35261', 'RVSAAT2S063'],
    ['Raiffeisenbank Lungau eGen', '35263', 'RVSAAT2S063'],
    ['Raiffeisenbank Pongau Mitte eGen', '35272', 'RVSAAT2S055'],
    ['Raiffeisenbank Pongau Mitte eGen', '35310', 'RVSAAT2S055'],
    ['Raiffeisenbank Hohe Tauern eGen', '35312', 'RVSAAT2S012'],
    ['Raiffeisenbank Hallein-Oberalm eGen', '35322', 'RVSAAT2S022'],
    ['Raiffeisenbank Oberpinzgau eGen', '35339', 'RVSAAT2S039'],
    [
      'Raiffeisenbank Saalbach-Hinterglemm-Viehhofen eGen',
      '35352',
      'RVSAAT2S052'
    ],
    ['Raiffeisenbank Lungau eGen', '35361', 'RVSAAT2S063'],
    [
      'Raiffeisenbank Wals-Himmelreich registrierte Genossenschaft mit beschränkter Haftung',
      '35371',
      'RVSAAT2S071'
    ],
    ['Raiffeisenbank Altenmarkt-Flachau-Eben eGen', '35404', 'RVSAAT2S004'],
    ['Raiffeisenbank Hallein-Oberalm eGen', '35422', 'RVSAAT2S022'],
    [
      'Raiffeisenbank Saalbach-Hinterglemm-Viehhofen eGen',
      '35452',
      'RVSAAT2S052'
    ],
    ['Raiffeisenbank Hallein-Oberalm eGen', '35522', 'RVSAAT2S022'],
    ['Raiffeisen-Landesbank Tirol AG', '36000', 'RZTIAT22XXX'],
    ['Raiffeisenbank Absam-Thaur eGen', '36200', 'RZTIAT22200'],
    ['Raiffeisen Regionalbank Achensee eGen', '36201', 'RZTIAT22218'],
    ['Raiffeisenbank Alpbachtal eGen', '36203', 'RZTIAT22203'],
    [
      'Raiffeisenbank Arzl im Pitztal und Imsterberg registrierte Genossenschaft mit beschränkter Haftung',
      '36207',
      'RZTIAT22207'
    ],
    ['Raiffeisenbank Tirol Mitte West eGen', '36209', 'RZTIAT22336'],
    ['Raiffeisen Bezirksbank Kufstein eGen', '36213', 'RZTIAT22358'],
    ['Raiffeisenbank Brixen im Thale eGen', '36215', 'RZTIAT22215'],
    ['Raiffeisen Bezirksbank Kufstein eGen', '36216', 'RZTIAT22358'],
    ['Raiffeisen Regionalbank Achensee eGen', '36218', 'RZTIAT22218'],
    ['Raiffeisenbank Ehrwald-Lermoos-Biberwier eGen', '36219', 'RZTIAT22219'],
    ['Raiffeisenbank Oberland-Reutte eGen', '36220', 'RBRTAT22XXX'],
    ['Raiffeisenbank Erl eGen', '36223', 'RZTIAT22223'],
    [
      'Raiffeisen Regionalbank Fügen-Kaltenbach-Zell eGen',
      '36229',
      'RZTIAT22229'
    ],
    ['Raiffeisenbank Wipptal - Stubaital Mitte eGen', '36230', 'RZTIAT22329'],
    ['RaiffeisenBank Going eGen', '36232', 'RZTIAT22232'],
    ['Raiffeisen Bezirksbank Kufstein eGen', '36236', 'RZTIAT22358'],
    ['Raiffeisenkasse Hart eGen', '36240', 'RZTIAT22240'],
    ['Raiffeisenbank Hippach und Umgebung eGen', '36241', 'RZTIAT22241'],
    [
      'Raiffeisenbank Wilder Kaiser - Brixental West eGen',
      '36245',
      'RZTIAT22245'
    ],
    ['Raiffeisenbank Arlberg Silvretta eGen', '36248', 'RZTIAT22248'],
    ['Raiffeisen Regionalbank Achensee eGen', '36249', 'RZTIAT22218'],
    ['Raiffeisenbank Arlberg Silvretta eGen', '36252', 'RZTIAT22248'],
    ['Raiffeisenbank Kitzbühel - St. Johann eGen', '36254', 'RZTIAT22263'],
    ['Raiffeisenbank Tirol Mitte West eGen', '36260', 'RZTIAT22336'],
    [
      'Raiffeisenbank Kirchdorf Tirol registrierte Genossenschaft mit beschränkter Haftung',
      '36262',
      'RZTIAT22262'
    ],
    ['Raiffeisenbank Kitzbühel - St. Johann eGen', '36263', 'RZTIAT22263'],
    ['Raiffeisenbank Kössen-Schwendt eGen', '36264', 'RZTIAT22264'],
    ['Raiffeisen Regionalbank Schwaz eGen', '36265', 'RZTIAT22322'],
    ['Raiffeisenbank Kundl-Münster eGen', '36267', 'RZTIAT22267'],
    ['Raiffeisenbank Längenfeld eGen', '36268', 'RZTIAT22268'],
    ['Raiffeisenbank Langkampfen-Thiersee eGen', '36269', 'RZTIAT22339'],
    ['Raiffeisen Regionalbank Schwaz eGen', '36272', 'RZTIAT22322'],
    [
      'Raiffeisenbank Matrei am Brenner und Umgebung eGen',
      '36273',
      'RZTIAT22273'
    ],
    ['Raiffeisenbank Mayrhofen und Umgebung eGen', '36274', 'RZTIAT22274'],
    [
      'Raiffeisenbank Neustift-Mieders-Schönberg im Stubaital eGen',
      '36275',
      'RZTIAT22285'
    ],
    ['Raiffeisenbank Tirol Mitte West eGen', '36276', 'RZTIAT22336'],
    ['Raiffeisenbank Kundl-Münster eGen', '36279', 'RZTIAT22267'],
    [
      'Raiffeisenkasse für Mutters, Natters und Kreith registrierte Genossenschaft mit beschränkter Haftung',
      '36281',
      'RZTIAT22281'
    ],
    ['Raiffeisenbank Nauders eGen', '36283', 'RZTIAT22283'],
    [
      'Raiffeisenbank Neustift-Mieders-Schönberg im Stubaital eGen',
      '36285',
      'RZTIAT22285'
    ],
    ['Raiffeisenbank Vorderes Ötztal eGen', '36291', 'RZTIAT22291'],
    ['Raiffeisenbank Alpbachtal eGen', '36303', 'RZTIAT22203'],
    ['Raiffeisenbank Oberland-Reutte eGen', '36305', 'RBRTAT22XXX'],
    ['Raiffeisenbank Serfaus-Fiss-Ried eGen', '36306', 'RZTIAT22315'],
    ['Raiffeisenkasse Rum-Innsbruck/Arzl eGen', '36310', 'RZTIAT22310'],
    [
      'Raiffeisenbank Seefeld-Leutasch-Reith-Scharnitz registrierte Genossenschaft mit beschränkter Haftung',
      '36314',
      'RZTIAT22314'
    ],
    ['Raiffeisenbank Serfaus-Fiss-Ried eGen', '36315', 'RZTIAT22315'],
    ['Raiffeisenbank Silz-Haiming und Umgebung eGen', '36316', 'RZTIAT22316'],
    [
      'Raiffeisenbank Wilder Kaiser - Brixental West eGen',
      '36318',
      'RZTIAT22245'
    ],
    [
      'Raiffeisenkasse Schlitters, Bruck und Straß registrierte Genossenschaft mit beschränkter Haftung',
      '36320',
      'RZTIAT22320'
    ],
    ['Raiffeisen Regionalbank Schwaz eGen', '36322', 'RZTIAT22322'],
    ['Raiffeisenbank Sölden eGen', '36324', 'RZTIAT22324'],
    ['Raiffeisenbank Wipptal - Stubaital Mitte eGen', '36329', 'RZTIAT22329'],
    [
      'Raiffeisenkasse Stumm, Stummerberg und Umgebung registrierte Genossenschaft mit beschränkter Haftung',
      '36332',
      'RZTIAT22332'
    ],
    ['Raiffeisenbank Tannheimertal eGen', '36333', 'RZTIAT22333'],
    ['Raiffeisenbank Tirol Mitte West eGen', '36336', 'RZTIAT22336'],
    ['Raiffeisenbank Absam-Thaur eGen', '36337', 'RZTIAT22200'],
    ['Raiffeisenbank Langkampfen-Thiersee eGen', '36339', 'RZTIAT22339'],
    ['Raiffeisenbank Tux eGen', '36342', 'RZTIAT22342'],
    ['Raiffeisenbank Oberland-Reutte eGen', '36345', 'RBRTAT22XXX'],
    [
      'Raiffeisenkasse Volders und Umgebung registrierte Genossenschaft mit beschränkter Haftung',
      '36347',
      'RZTIAT22347'
    ],
    ['RaiffeisenBank St. Ulrich - Waidring eGen', '36349', 'RZTIAT22349'],
    [
      'Raiffeisenbank Wattens und Umgebung, registrierte Genossenschaft mit beschränkter Haftung',
      '36351',
      'RZTIAT22351'
    ],
    [
      'Raiffeisenkasse Weerberg registrierte Genossenschaft mit beschränkter Haftung',
      '36352',
      'RZTIAT22352'
    ],
    ['Raiffeisenbank Pitztal eGen', '36353', 'RZTIAT22353'],
    [
      'Raiffeisenbank Wilder Kaiser - Brixental West eGen',
      '36354',
      'RZTIAT22245'
    ],
    ['Raiffeisenbank Wildschönau eGen', '36357', 'RZTIAT22357'],
    ['Raiffeisen Bezirksbank Kufstein eGen', '36358', 'RZTIAT22358'],
    ['Raiffeisenbank Oberland-Reutte eGen', '36359', 'RBRTAT22XXX'],
    [
      'Raiffeisen Regionalbank Fügen-Kaltenbach-Zell eGen',
      '36360',
      'RZTIAT22229'
    ],
    ['Raiffeisen Regionalbank Hall in Tirol eGen', '36362', 'RZTIAT22362'],
    ['Raiffeisen Regionalbank Matrei i.O. eGen', '36367', 'RZTIAT22378'],
    ['Raiffeisenbank Sillian eGen', '36368', 'RZTIAT22368'],
    ['Raiffeisenkasse Lienzer Talboden eGen', '36373', 'RZTIAT22373'],
    ['Raiffeisenbank Sillian eGen', '36374', 'RZTIAT22368'],
    ['Raiffeisen Regionalbank Matrei i.O. eGen', '36378', 'RZTIAT22378'],
    ['Raiffeisenkasse Villgratental eGen', '36386', 'RZTIAT22386'],
    ['Raiffeisenbank Sillian eGen', '36387', 'RZTIAT22368'],
    ['Alpen Privatbank Aktiengesellschaft', '36791', 'ALPEAT22XXX'],
    ['Raiffeisenbank Oberland-Reutte eGen', '36990', 'RBRTAT22XXX'],
    [
      'Raiffeisen Landesbank Vorarlberg mit Revisionsverband eGen',
      '37000',
      'RVVGAT2BXXX'
    ],
    ['Raiffeisenbank Alberschwende eGen', '37401', 'RVVGAT2B401'],
    ['Raiffeisenbank Montfort eGen', '37402', 'RVVGAT2B422'],
    [
      'Raiffeisenbank Mittel- und Hinterbregenzerwald eGen',
      '37405',
      'RVVGAT2B423'
    ],
    ['Raiffeisenbank Bezau-Mellau-Bizau eGen', '37406', 'RVVGAT2B406'],
    ['Raiffeisenbank Bludenz-Montafon eGen', '37410', 'RVVGAT2B468'],
    ['Raiffeisenbank Im Rheintal eGen', '37420', 'RVVGAT2B420'],
    ['Raiffeisenbank Montfort eGen', '37422', 'RVVGAT2B422'],
    [
      'Raiffeisenbank Mittel- und Hinterbregenzerwald eGen',
      '37423',
      'RVVGAT2B423'
    ],
    ['RAIFFEISENBANK im Walgau eGen', '37424', 'RVVGAT2B458'],
    ['Raiffeisenbank Montfort eGen', '37429', 'RVVGAT2B422'],
    ['Raiffeisenbank Bodensee-Leiblachtal eGen', '37431', 'RVVGAT2B431'],
    ['Walser Raiffeisen Bank Aktiengesellschaft', '37434', 'RANJAT2BXXX'],
    ['Raiffeisenbank Vorderbregenzerwald eGen', '37435', 'RVVGAT2B435'],
    ['RAIFFEISENBANK HOHENEMS eGen', '37438', 'RANMAT21XXX'],
    ['Raiffeisenbank Bodensee-Leiblachtal eGen', '37439', 'RVVGAT2B431'],
    ['Raiffeisenbank Weissachtal eGen', '37445', 'RVVGAT2B474'],
    ['Raiffeisenbank Lech am Arlberg eGen', '37449', 'RVVGAT2B449'],
    ['Raiffeisenbank Im Rheintal eGen', '37453', 'RVVGAT2B420'],
    ['RAIFFEISENBANK im Walgau eGen', '37458', 'RVVGAT2B458'],
    ['Raiffeisenbank Montfort eGen', '37461', 'RVVGAT2B422'],
    ['Raiffeisenbank Bludenz-Montafon eGen', '37468', 'RVVGAT2B468'],
    ['Raiffeisenbank Weissachtal eGen', '37474', 'RVVGAT2B474'],
    ['Raiffeisenbank Vorderland eGen', '37475', 'RVVGAT2B475'],
    ['Raiffeisenbank am Hofsteig eGen', '37482', 'RVVGAT2B482'],
    ['Raiffeisen-Landesbank Steiermark AG', '38000', 'RZSTAT2GXXX'],
    ['Raiffeisenbank Admont eGen', '38001', 'RZSTAT2G001'],
    ['Raiffeisenbank Weiz-Anger eGen', '38010', 'RZSTAT2G187'],
    ['Raiffeisenbank Oststeiermark Nord eGen', '38023', 'RZSTAT2G023'],
    ['Raiffeisenbank Passail eGen', '38026', 'RZSTAT2G282'],
    ['Raiffeisenbank Oststeiermark Nord eGen', '38041', 'RZSTAT2G023'],
    ['Raiffeisenbank Schilcherland eGen', '38043', 'RZSTAT2G043'],
    ['Raiffeisenbank Lipizzanerheimat eGen', '38053', 'RZSTAT2G487'],
    ['Raiffeisenbank Nestelbach-Eggersdorf eGen', '38055', 'RZSTAT2G252'],
    ['Raiffeisenbank Süd-Weststeiermark eGen', '38056', 'RZSTAT2G056'],
    ['Raiffeisenbank Region Fehring eGen', '38071', 'RZSTAT2G071'],
    ['Raiffeisenbank Region Graz-Thalerhof eGen', '38072', 'RZSTAT2G477'],
    ['Raiffeisenbank Mittlere Südoststeiermark eGen', '38075', 'RZSTAT2G075'],
    ['Raiffeisenbank Aichfeld eGen', '38076', 'RZSTAT2G346'],
    ['Raiffeisenbank Region Fürstenfeld eGen', '38077', 'RZSTAT2G077'],
    ['Raiffeisenbank Gamlitz eGen', '38094', 'RZSTAT2G094'],
    [
      'Raiffeisenbank Gleinstätten-Leutschach-Groß St. Florian eGen',
      '38102',
      'RZSTAT2G102'
    ],
    [
      'Raiffeisenbank Region Gleisdorf-Pischelsdorf eGen',
      '38103',
      'RZSTAT2G103'
    ],
    ['Raiffeisenbank Region Feldbach eGen', '38104', 'RZSTAT2G497'],
    ['Raiffeisenbank Oststeiermark Nord eGen', '38108', 'RZSTAT2G023'],
    ['Raiffeisenbank Gratkorn eGen', '38111', 'RZSTAT2G111'],
    ['Raiffeisenbank Gratwein-Hitzendorf eGen', '38112', 'RZSTAT2G138'],
    ['Raiffeisenbank Schladming-Gröbming eGen', '38113', 'RZSTAT2G113'],
    [
      'Raiffeisenbank Gleinstätten-Leutschach-Groß St. Florian eGen',
      '38118',
      'RZSTAT2G102'
    ],
    [
      'Raiffeisenbank Ilz-Großsteinbach-Riegersburg eGen',
      '38120',
      'RZSTAT2G151'
    ],
    ['Raiffeisenbank Region Fürstenfeld eGen', '38122', 'RZSTAT2G077'],
    ['Raiffeisenbank Halbenrain-Tieschen eGen', '38128', 'RZSTAT2G128'],
    ['Raiffeisenbank Mariazellerland eGen', '38129', 'RZSTAT2G129'],
    ['Raiffeisenbank Region Feldbach eGen', '38131', 'RZSTAT2G497'],
    ['Raiffeisenbank Region Fehring eGen', '38132', 'RZSTAT2G071'],
    ['Raiffeisenbank Hausmannstätten eGen', '38133', 'RZSTAT2G133'],
    ['Raiffeisenbank Gratwein-Hitzendorf eGen', '38138', 'RZSTAT2G138'],
    [
      'Raiffeisenbank Ilz-Großsteinbach-Riegersburg eGen',
      '38151',
      'RZSTAT2G151'
    ],
    [
      'Raiffeisenbank Heiligenkreuz-Kirchbach -St. Georgen eGen',
      '38170',
      'RZSTAT2G170'
    ],
    ['Raiffeisenbank Mittlere Südoststeiermark eGen', '38171', 'RZSTAT2G075'],
    ['Raiffeisenbank Mürztal eGen', '38186', 'RZSTAT2G186'],
    ['Raiffeisenbank Weiz-Anger eGen', '38187', 'RZSTAT2G187'],
    ['Raiffeisenbank Leibnitz eGen', '38206', 'RZSTAT2G206'],
    ['Raiffeisenbank Lieboch-Stainz eGen', '38210', 'RZSTAT2G210'],
    ['Raiffeisenbank Lipizzanerheimat eGen', '38211', 'RZSTAT2G487'],
    ['Raiffeisenbank Liezen-Rottenmann-Trieben eGen', '38215', 'RZSTAT2G215'],
    ['Raiffeisenbank Region Graz Nord eGen', '38223', 'RZSTAT2G377'],
    ['Raiffeisenbank Liesingtal-St. Stefan eGen', '38227', 'RZSTAT2G227'],
    ['Raiffeisenbank Lipizzanerheimat eGen', '38235', 'RZSTAT2G487'],
    ['Raiffeisenbank Murau eGen', '38238', 'RZSTAT2G402'],
    ['Raiffeisenbank Mürztal eGen', '38240', 'RZSTAT2G186'],
    [
      'Raiffeisenbank Steirisches Salzkammergut-Öblarn eGen',
      '38249',
      'RZSTAT2G249'
    ],
    ['Raiffeisenbank Nestelbach-Eggersdorf eGen', '38252', 'RZSTAT2G252'],
    ['Raiffeisenbank Zirbenland eGen', '38261', 'RZSTAT2G368'],
    [
      'Raiffeisenbank Gleinstätten-Leutschach-Groß St. Florian eGen',
      '38262',
      'RZSTAT2G102'
    ],
    [
      'Raiffeisenbank Steirisches Salzkammergut-Öblarn eGen',
      '38266',
      'RZSTAT2G249'
    ],
    ['Raiffeisenbank Passail eGen', '38282', 'RZSTAT2G282'],
    [
      'Raiffeisenbank Region Gleisdorf-Pischelsdorf eGen',
      '38285',
      'RZSTAT2G103'
    ],
    ['Raiffeisenbank Wildon-Preding eGen', '38292', 'RZSTAT2G499'],
    ['Raiffeisenbank Oststeiermark Nord eGen', '38311', 'RZSTAT2G023'],
    ['Raiffeisenbank Bad Radkersburg-Klöch eGen', '38312', 'RZSTAT2G312'],
    [
      'Raiffeisenbank Ilz-Großsteinbach-Riegersburg eGen',
      '38326',
      'RZSTAT2G151'
    ],
    ['Raiffeisenbank Gratwein-Hitzendorf eGen', '38333', 'RZSTAT2G138'],
    [
      'Raiffeisenbank Heiligenkreuz-Kirchbach -St. Georgen eGen',
      '38335',
      'RZSTAT2G170'
    ],
    ['Raiffeisenbank Aichfeld eGen', '38346', 'RZSTAT2G346'],
    ['Raiffeisenbank Turnau-St. Lorenzen eGen', '38347', 'RZSTAT2G347'],
    ['Raiffeisenbank Liezen-Rottenmann-Trieben eGen', '38348', 'RZSTAT2G215'],
    ['Raiffeisenbank Graz-St. Peter eGen', '38367', 'RZSTAT2G367'],
    ['Raiffeisenbank Zirbenland eGen', '38368', 'RZSTAT2G368'],
    ['Raiffeisenbank Mureck eGen', '38370', 'RZSTAT2G370'],
    [
      'Raiffeisenbank Region Gleisdorf-Pischelsdorf eGen',
      '38372',
      'RZSTAT2G103'
    ],
    ['Raiffeisenbank Liesingtal-St. Stefan eGen', '38373', 'RZSTAT2G227'],
    ['Raiffeisenbank Mittlere Südoststeiermark eGen', '38374', 'RZSTAT2G075'],
    ['Raiffeisenbank Schilcherland eGen', '38375', 'RZSTAT2G043'],
    ['Raiffeisenbank Region Graz Nord eGen', '38377', 'RZSTAT2G377'],
    ['Raiffeisenbank Murau eGen', '38402', 'RZSTAT2G402'],
    ['Raiffeisenbank Region Hartberg eGen', '38403', 'RZSTAT2G403'],
    ['Raiffeisenbank Süd-Weststeiermark eGen', '38407', 'RZSTAT2G056'],
    ['Raiffeisenbank Region Hartberg eGen', '38411', 'RZSTAT2G403'],
    ['Raiffeisenbank Straß-Spielfeld eGen', '38420', 'RZSTAT2G420'],
    ['Raiffeisenbank Stallhofen eGen', '38433', 'RZSTAT2G433'],
    ['Raiffeisenbank Straden eGen', '38436', 'RZSTAT2G436'],
    ['Raiffeisenbank Graz-Straßgang eGen', '38439', 'RZSTAT2G439'],
    ['Raiffeisenbank Murau eGen', '38452', 'RZSTAT2G402'],
    ['Raiffeisenbank Leoben-Bruck eGen', '38460', 'RZSTAT2G460'],
    ['Raiffeisenbank Turnau-St. Lorenzen eGen', '38462', 'RZSTAT2G347'],
    ['Raiffeisenbank Region Graz-Thalerhof eGen', '38477', 'RZSTAT2G477'],
    ['Raiffeisenbank Schladming-Gröbming eGen', '38481', 'RZSTAT2G113'],
    ['Raiffeisenbank Lipizzanerheimat eGen', '38487', 'RZSTAT2G487'],
    ['Raiffeisenbank Region Feldbach eGen', '38497', 'RZSTAT2G497'],
    ['Raiffeisenbank Wildon-Preding eGen', '38499', 'RZSTAT2G499'],
    ['Raiffeisenbank Süd-Weststeiermark eGen', '38503', 'RZSTAT2G056'],
    [
      'Raiffeisenlandesbank Kärnten - Rechenzentrum und Revisionsverband, registrierte Genossenschaft mit beschränkter Haftung',
      '39000',
      'RZKTAT2KXXX'
    ],
    ['Posojilnica Bank eGen', '39100', 'VSGKAT2KXXX'],
    ['Posojilnica Bank eGen', '39101', 'VSGKAT2KXXX'],
    ['Posojilnica Bank eGen', '39102', 'VSGKAT2KXXX'],
    ['Posojilnica Bank eGen', '39104', 'VSGKAT2KXXX'],
    ['Posojilnica Bank eGen', '39109', 'VSGKAT2KXXX'],
    ['Posojilnica Bank eGen', '39116', 'VSGKAT2KXXX'],
    ['Posojilnica Bank eGen', '39117', 'VSGKAT2KXXX'],
    ['Posojilnica Bank eGen', '39130', 'VSGKAT2KXXX'],
    [
      'Raiffeisen-Bezirksbank St. Veit a.d. Glan-Feldkirchen, registrierte Genossenschaft mit beschränkter Haftung',
      '39254',
      'RZKTAT2K475'
    ],
    ['Raiffeisenbank Mittelkärnten eG', '39255', 'RZKTAT2K511'],
    ['Raiffeisenbank Arnoldstein-Fürnitz eG', '39257', 'RZKTAT2K257'],
    [
      'Raiffeisen-Bezirksbank Spittal/Drau, registrierte Genossenschaft mit beschränkter Haftung',
      '39267',
      'RZKTAT2K267'
    ],
    [
      'Raiffeisenbank Brückl-Eberstein-Klein St. Paul-Waisenberg, registrierte Genossenschaft mit beschränkter Haftung',
      '39271',
      'RZKTAT2K271'
    ],
    [
      'Raiffeisenbank Bleiburg registrierte Genossenschaft mit beschränkter Haftung',
      '39272',
      'RZKTAT2K272'
    ],
    [
      'Raiffeisenbank Eberndorf registrierte Genossenschaft mit beschränkter Haftung',
      '39288',
      'RZKTAT2K288'
    ],
    ['Raiffeisenbank Unteres Lavanttal eGen', '39292', 'RZKTAT2K292'],
    ['Raiffeisenbank Arnoldstein-Fürnitz eG', '39305', 'RZKTAT2K257'],
    [
      'Raiffeisenbank Grafenstein-Magdalensberg und Umgebung, registrierte Genossenschaft mit beschränkter Haftung',
      '39320',
      'RZKTAT2K320'
    ],
    [
      'Raiffeisenbank Oberdrautal-Weissensee, registrierte Genossenschaft mit beschränkter Haftung',
      '39322',
      'RZKTAT2K322'
    ],
    ['Raiffeisenbank Mittelkärnten eG', '39340', 'RZKTAT2K511'],
    ['Raiffeisenbank Region Wörthersee eG', '39355', 'RZKTAT2K390'],
    [
      'Raiffeisenlandesbank Kärnten - Rechenzentrum und Revisionsverband, registrierte Genossenschaft mit beschränkter Haftung',
      '39358',
      'RZKTAT2KXXX'
    ],
    [
      'Raiffeisenbank Kötschach - Mauthen, registrierte Genossenschaft mit beschränkter Haftung',
      '39364',
      'RZKTAT2K364'
    ],
    [
      'Raiffeisenbank Landskron - Gegendtal, registrierte Genossenschaft mit beschränkter Haftung',
      '39381',
      'RZKTAT2K381'
    ],
    [
      'Raiffeisen Bank Villach registrierte Genossenschaft mit beschränkter Haftung',
      '39383',
      'RZKTAT2K496'
    ],
    ['Raiffeisenbank Mittelkärnten eG', '39384', 'RZKTAT2K511'],
    ['Raiffeisenbank Region Wörthersee eG', '39390', 'RZKTAT2K390'],
    ['Raiffeisenbank Lieser-Maltatal eG', '39400', 'RZKTAT2K464'],
    [
      'Raiffeisenbank Drautal registrierte Genossenschaft mit beschränkter Haftung',
      '39404',
      'RZKTAT2K442'
    ],
    [
      'Raiffeisen-Bezirksbank St. Veit a.d. Glan-Feldkirchen, registrierte Genossenschaft mit beschränkter Haftung',
      '39407',
      'RZKTAT2K475'
    ],
    [
      'Raiffeisenbank Moosburg-Tigring, registrierte Genossenschaft mit beschränkter Haftung',
      '39411',
      'RZKTAT2K411'
    ],
    ['Raiffeisen Bank Lurnfeld-Mölltal eGen', '39412', 'RZKTAT2K412'],
    ['Raiffeisenbank Oberes Mölltal-Oberdrauburg eGen', '39431', 'RZKTAT2K561'],
    ['Raiffeisen Bank Lurnfeld-Mölltal eGen', '39436', 'RZKTAT2K412'],
    [
      'Raiffeisenbank Drautal registrierte Genossenschaft mit beschränkter Haftung',
      '39442',
      'RZKTAT2K442'
    ],
    ['Raiffeisenbank Nockberge eGen', '39457', 'RZKTAT2K457'],
    ['Raiffeisenbank Nockberge eGen', '39461', 'RZKTAT2K457'],
    ['Raiffeisenbank Lieser-Maltatal eG', '39464', 'RZKTAT2K464'],
    ['Raiffeisenbank Mittleres Lavanttal eGen', '39465', 'RZKTAT2K481'],
    [
      'Raiffeisen-Bezirksbank St. Veit a.d. Glan-Feldkirchen, registrierte Genossenschaft mit beschränkter Haftung',
      '39475',
      'RZKTAT2K475'
    ],
    ['Raiffeisenbank Millstättersee eG', '39479', 'RZKTAT2K479'],
    ['Raiffeisenbank Mittleres Lavanttal eGen', '39481', 'RZKTAT2K481'],
    [
      'Raiffeisen Bank Villach registrierte Genossenschaft mit beschränkter Haftung',
      '39483',
      'RZKTAT2K496'
    ],
    [
      'Raiffeisenbank Rosental, registrierte Genossenschaft mit beschränkter Haftung',
      '39487',
      'RZKTAT2K487'
    ],
    [
      'Raiffeisenbank Oberes Lavanttal registrierte Genossenschaft mit beschränkter Haftung',
      '39491',
      'RZKTAT2K491'
    ],
    [
      'Raiffeisen Bank Villach registrierte Genossenschaft mit beschränkter Haftung',
      '39496',
      'RZKTAT2K496'
    ],
    ['Raiffeisenbank St. Paul im Lavanttal eGen', '39500', 'RZKTAT2K500'],
    [
      'Raiffeisenbank Friesach-Metnitztal, registrierte Genossenschaft mit beschränkter Haftung',
      '39501',
      'RZKTAT2K501'
    ],
    [
      'Raiffeisen-Bezirksbank St. Veit a.d. Glan-Feldkirchen, registrierte Genossenschaft mit beschränkter Haftung',
      '39505',
      'RZKTAT2K475'
    ],
    ['Raiffeisenbank Ossiacher See eG', '39510', 'RZKTAT2K510'],
    ['Raiffeisenbank Mittelkärnten eG', '39511', 'RZKTAT2K511'],
    ['Raiffeisenbank Hermagor eG', '39543', 'RZKTAT2K543'],
    [
      'Raiffeisenbank Völkermarkt, registrierte Genossenschaft mit beschränkter Haftung',
      '39546',
      'RZKTAT2K546'
    ],
    [
      'Raiffeisenbank Wernberg, registrierte Genossenschaft mit beschränkter Haftung',
      '39559',
      'RZKTAT2K559'
    ],
    ['Raiffeisenbank Oberes Mölltal-Oberdrauburg eGen', '39561', 'RZKTAT2K561'],
    ['VOLKSBANK WIEN AG', '40000', 'VBOEATWWXXX'],
    ['VOLKSBANK WIEN AG', '40100', 'VBOEATWWXXX'],
    ['Volksbank Niederösterreich AG', '40170', 'VBOEATWWNOM'],
    ['VOLKSBANK WIEN AG', '40430', 'VBOEATWWXXX'],
    ['Volksbank Niederösterreich AG', '40630', 'VBOEATWWNOM'],
    ['Volksbank Salzburg eG', '40720', 'VBOEATWWSAL'],
    ['DolomitenBank Osttirol-Westkärnten eG', '40730', 'OVLIAT21XXX'],
    ['Volksbank Niederösterreich AG', '40850', 'VBOEATWWNOM'],
    ['Volksbank Niederösterreich AG', '41050', 'VBOEATWWNOM'],
    ['Volksbank Niederösterreich AG', '41210', 'VBOEATWWNOM'],
    ['VOLKSBANK WIEN AG', '41600', 'VBOEATWWXXX'],
    ['Marchfelder Bank eG', '42110', 'MVOGAT22XXX'],
    ['Volksbank Kärnten eG', '42130', 'VBOEATWWKLA'],
    ['Volksbank Steiermark AG', '42320', 'VBOEATWWGRA'],
    ['Volksbank Tirol AG', '42390', 'VBOEATWWINN'],
    ['Volksbank Oberösterreich AG', '42550', 'VBOEATWWOOE'],
    ['Volksbank Kärnten eG', '42600', 'VBOEATWWKLA'],
    ['Volksbank Oberösterreich AG', '42630', 'VBOEATWWOOE'],
    ['Volksbank Salzburg eG', '42740', 'VBOEATWWSAL'],
    ['VOLKSBANK WIEN AG', '42750', 'VBOEATWWXXX'],
    ['Volksbank Kärnten eG', '42820', 'VBOEATWWKLA'],
    ['Volksbank Oberösterreich AG', '42830', 'VBOEATWWOOE'],
    ['VOLKSBANK WIEN AG', '43000', 'VBOEATWWXXX'],
    ['Volksbank Steiermark AG', '43030', 'VBOEATWWGRA'],
    ['Volksbank Oberösterreich AG', '43180', 'VBOEATWWOOE'],
    ['Volksbank Oberösterreich AG', '43210', 'VBOEATWWOOE'],
    ['DolomitenBank Osttirol-Westkärnten eG', '43380', 'OVLIAT21XXX'],
    ['start:bausparkasse AG', '43510', 'ABVRATW1XXX'],
    ['Volksbank Niederösterreich AG', '43530', 'VBOEATWWNOM'],
    ['VOLKSBANK WIEN AG', '43600', 'VBOEATWWXXX'],
    ['VOLKSBANK WIEN AG', '43610', 'VBOEATWWXXX'],
    ['VOLKSBANK WIEN AG', '43690', 'VBOEATWWXXX'],
    ['Volksbank Tirol AG', '43770', 'VBOEATWWINN'],
    ['Volksbank Oberösterreich AG', '43830', 'VBOEATWWOOE'],
    ['Volksbank Niederösterreich AG', '43920', 'VBOEATWWNOM'],
    ['Volksbank Salzburg eG', '44020', 'VBOEATWWSAL'],
    ['Österreichische Ärzte- und Apothekerbank AG', '44220', 'BWFBATW1XXX'],
    ['Volksbank Salzburg eG', '44300', 'VBOEATWWSAL'],
    ['DolomitenBank Osttirol-Westkärnten eG', '44400', 'OVLIAT21XXX'],
    ['VOLKSBANK WIEN AG', '44430', 'VBOEATWWXXX'],
    ['Volksbank Salzburg eG', '44480', 'VBOEATWWSAL'],
    ['VOLKSBANK WIEN AG', '44570', 'VBOEATWWXXX'],
    ['Volksbank Steiermark AG', '44770', 'VBOEATWWGRA'],
    ['Volksbank Steiermark AG', '44780', 'VBOEATWWGRA'],
    ['Volksbank Oberösterreich AG', '44790', 'VBOEATWWOOE'],
    ['Volksbank Oberösterreich AG', '44800', 'VBOEATWWOOE'],
    ['Volksbank Steiermark AG', '44810', 'VBOEATWWGRA'],
    ['Volksbank Niederösterreich AG', '44820', 'VBOEATWWNOM'],
    ['WSK Bank AG', '44960', 'WSPKATW1XXX'],
    ['Volksbank Salzburg eG', '45010', 'VBOEATWWSAL'],
    ['Volksbank Oberösterreich AG', '45330', 'VBOEATWWOOE'],
    ['VOLKSBANK VORARLBERG e. Gen.', '45710', 'VBOEATWWVBG'],
    ['Volksbank Tirol AG', '45850', 'VBOEATWWINN'],
    ['Volksbank Steiermark AG', '46590', 'VBOEATWWGRA'],
    ['VOLKSBANK WIEN AG', '46660', 'VBOEATWWXXX'],
    ['Volksbank Kärnten eG', '46900', 'VBOEATWWKLA'],
    ['Volksbank Niederösterreich AG', '47150', 'VBOEATWWNOM'],
    ['Volksbank Steiermark AG', '48150', 'VBOEATWWGRA'],
    ['VOLKSBANK WIEN AG', '49220', 'VBOEATWWXXX'],
    ['VOLKSBANK WIEN AG', '49460', 'VBOEATWWXXX'],
    ['HYPO-BANK BURGENLAND Aktiengesellschaft', '51000', 'EHBBAT2EXXX'],
    ['Austrian Anadi Bank AG', '52000', 'HAABAT2KXXX'],
    ['Addiko Bank AG', '52300', 'HSEEAT2KXXX'],
    [
      'HYPO NOE Landesbank für Niederösterreich und Wien AG',
      '53000',
      'HYPNATWWXXX'
    ],
    [
      'HYPO NOE Landesbank für Niederösterreich und Wien AG',
      '53100',
      'HYPNATWWLDS'
    ],
    [
      'Oberösterreichische Landesbank Aktiengesellschaft',
      '54000',
      'OBLAAT2LXXX'
    ],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '55000',
      'SLHYAT2SXXX'
    ],
    [
      'Raiffeisenlandesbank Oberösterreich Aktiengesellschaft',
      '55500',
      'SLHYAT2S500'
    ],
    ['Raiffeisen-Landesbank Steiermark AG', '56000', 'RZSTAT2GXXX'],
    ['HYPO TIROL BANK AG', '57000', 'HYPTAT22XXX'],
    ['HYPO TIROL BANK AG', '57100', 'HYPTAT22XXX'],
    ['Hypo Vorarlberg Bank AG', '58000', 'HYPVAT2BXXX'],
    [
      'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse Aktiengesellschaft',
      '60000',
      'BAWAATWWXXX'
    ],
    ['LEASFINANZ Bank GmbH', '73800', 'LEGMATW1XXX'],
    ['SPAR-FINANZ BANK AG', '74200', 'SFIVATW1XXX'],
    [
      'State Street Bank International GmbH Filiale Wien',
      '75100',
      'SBOSATWWXXX'
    ],
    ['Porsche Bank Aktiengesellschaft', '76000', 'PORCAT21XXX'],
    ['card complete Service Bank AG', '76022', 'VIAUATW1XXX'],
    [
      'TeamBank Österreich - Niederlassung der TeamBank AG Nürnberg',
      '76032',
      'TEAMDE71TAT'
    ],
    ['card complete Service Bank AG', '76040', 'VIAUATW1XXX'],
    ['Liechtensteinische Landesbank (Österreich) AG', '76520', 'COPRATWWXXX'],
    [
      'Viva Payment Services S.A. Zweigniederlassung Wien/Österreich',
      '94648',
      'VPAYATW2XXX'
    ]
  ];

  /**
   * An object containing all the AT bank data.
   */
  exports.data = bankData;
  exports.bankCodeLength = BANK_CODE_LENGHTS;
  exports.bicNumberLength = BIC_NUMBER_LENGTHS;
  exports.countryCode = COUNTRY_CODE_UPPERCASE;
});

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports'], factory);
  } else if (
    typeof exports === 'object' &&
    typeof exports.nodeName !== 'string'
  ) {
    // CommonJS
    factory(exports);
  } else {
    // Browser globals
    factory((root.Banks_DE = {}));
  }
})(this, function(exports) {
  var BANK_CODE_LENGHTS = 8;
  var BIC_NUMBER_LENGTHS = 10;
  var COUNTRY_CODE_UPPERCASE = 'DE';

  /**
   * bankData in an array of bank name, bankleitzahl, bic
   * Source: NOT SHURE!!! => http://www.bundesbank.de/Redaktion/DE/Standardartikel/Aufgaben/Unbarer_Zahlungsverkehr/bankleitzahlen_download.html?nsc=true
   */
  var bankData = [
    ['BBk Berlin', '10000000', 'MARKDEF1100'],
    ['Postbank Ndl Deutsche Bank', '10010010', 'PBNKDEFFXXX'],
    ['Olinda, Berlin', '10010123', 'QNTODEB2XXX'],
    ['Revolut Bank, Berlin', '10010178', 'REVODEB2XXX'],
    ['Treezor Berlin', '10010200', 'TRZODEB2XXX'],
    ['Klarna Bank, Berlin', '10010300', 'KLRNDEBEXXX'],
    ['Aareal Bank', '10010424', 'AARBDE5W100'],
    ['Noelse Pay, Berlin', '10010500', 'AFOPDEB2XXX'],
    ['FPE Deutschland', '10010700', 'FPEGDEB2XXX'],
    ['N26 Bank Berlin', '10011001', 'NTSBDEB1XXX'],
    ['Swan Zndl Deutschland', '10014000', 'SWNBDEBBXXX'],
    ['Swan Zndl Deutschland', '10014001', 'SWNBDEBBXXX'],
    ['Holvi - Berlin', '10017997', 'HOLVDEB1XXX'],
    ['BHF-BANK Berlin', '10020200', 'BHFBDEFF100'],
    ['Bank für Sozialwirtschaft', '10020500', 'BFSWDE33BER'],
    ['Sozialbank Berlin', '10020510', 'BFSWDE33BER'],
    ['Sozialbank Berlin', '10020520', 'BFSWDE33BER'],
    ['UniCredit Bank-HypoVereinbk', '10020890', 'HYVEDEMM488'],
    ['Berlin Hyp', '10030200', 'BHYPDEB2XXX'],
    ['ABK Bank Berlin', '10030400', 'ABKBDEB1XXX'],
    ['M.M. Warburg (Löbbecke)', '10030500', 'LOEBDEBBXXX'],
    ['North Channel Bank Mainz', '10030600', 'GENODEF1OGK'],
    ['Eurocity Bank', '10030700', 'DLGHDEB1XXX'],
    ['Eurocity Bank Gf GAA', '10030730', 'DLGHDEB1XXX'],
    ['EIS Bank Berlin', '10031000', 'EIEGDEB1XXX'],
    ['Santander Bank Berlin', '10033300', 'SCFBDE33XXX'],
    ['Commerzbank Fil. Berlin 1', '10040000', 'COBADEBBXXX'],
    ['Commerzbank Fil. Berlin 3', '10040005', 'COBADEBBXXX'],
    ['Commerzbank CC SP, Berlin', '10040010', 'COBADEFFXXX'],
    ['Commerzbank BER GF-B48', '10040048', 'COBADEFFXXX'],
    ['Commerzbank Gf 160 Berlin', '10040060', 'COBADEFFXXX'],
    ['Commerzbank Gf 161 Berlin', '10040061', 'COBADEFFXXX'],
    ['Commerzbank CC Berlin', '10040062', 'COBADEFFXXX'],
    ['Commerzbank CC Berlin', '10040063', 'COBADEFFXXX'],
    ['Commerzbank Gf WK, Berlin', '10040085', 'COBADEFFXXX'],
    ['Commerzbank Service-BZ', '10045050', 'COBADEFFXXX'],
    ['LBB - Berliner Sparkasse', '10050000', 'BELADEBEXXX'],
    ['S-Kreditpartner, Berlin', '10050020', 'SKPADEB1XXX'],
    ['LBS Ost Berlin', '10050500', 'LBSODEB1BLN'],
    ['DekaBank', '10050999', 'DGZFDEFFBER'],
    ['Pax-Bank Berlin', '10060198', 'GENODED1PA6'],
    ['KD-Bank Berlin', '10061006', 'GENODED1KDB'],
    ['Deutsche Bank', '10070000', 'DEUTDEBBXXX'],
    ['Deutsche Bank', '10070024', 'DEUTDEDBBER'],
    ['Deutsche Bank', '10070100', 'DEUTDEBB101'],
    ['Deutsche Bank', '10070124', 'DEUTDEDB101'],
    ['Postbank Ndl Deutsche Bank', '10070324', 'DEUTDEDBP30'],
    ['Postbank Ndl Deutsche Bank', '10070397', 'DEUTDEBBP30'],
    ['Postbank Ndl Deutsche Bank', '10070398', 'DEUTDEBBP31'],
    ['Postbank Ndl Deutsche Bank', '10070399', 'DEUTDEBBP32'],
    ['Deutsche Bank', '10070848', 'DEUTDEDB110'],
    ['Postbank Ndl Deutsche Bank', '10071324', 'DEUTDEDBP31'],
    ['Postbank Ndl Deutsche Bank', '10072324', 'DEUTDEDBP32'],
    ['norisbank', '10077777', 'NORSDE51XXX'],
    ['Commerzbank Fil I Berlin', '10080000', 'DRESDEFF100'],
    ['Commerzbank Berlin Zw A', '10080005', 'DRESDEFFI26'],
    ['Commerzbank Berlin Zw B', '10080006', 'DRESDEFFXXX'],
    ['Commerzbank Zw 55 Berlin', '10080055', 'DRESDEFF112'],
    ['Commerzbk ZW 57 Berlin', '10080057', 'DRESDEFF114'],
    ['Commerzbank ITKG3 Berlin', '10080085', 'DRESDEFFI53'],
    ['Commerzbank ITGK4 Berlin', '10080086', 'DRESDEFFI71'],
    ['Commerzbank ITGK5 Berlin', '10080087', 'DRESDEFFI72'],
    ['Commerzbank IBLZ Berlin', '10080088', 'DRESDEFFXXX'],
    ['Commerzbank ITGK6 Berlin', '10080089', 'DRESDEFFI73'],
    ['Commerzbk Fil III Berlin', '10080900', 'DRESDEFF199'],
    ['Commerzbank ITGK Berlin', '10089260', 'DRESDEFFI14'],
    ['Commerzbank ITGK 2 Berlin', '10089999', 'DRESDEFFI99'],
    ['Berliner VB Berlin', '10090000', 'BEVODEBBXXX'],
    ['Bk f Schiffahrt BFS Berlin', '10090300', 'GENODEF1BSB'],
    ['PSD Bank Berlin', '10090900', 'GENODEF1P01'],
    ['Westend Bank', '10110300', 'MACODEB1XXX'],
    ['Investitionsbank Berlin', '10110400', 'IBBBDEBBXXX'],
    ['Quirin Privatbank Berlin', '10110600', 'QUBKDEBBXXX'],
    ['Weberbank', '10120100', 'WELADED1WBB'],
    ['Isbank Berlin', '10130600', 'ISBKDEFXXXX'],
    ['flatex Bank, Frankfurt', '10130800', 'BIWBDE33XXX'],
    ['Lloyds Bank', '10220500', 'BOFSDEB1XXX'],
    ['Sydbank Berlin', '10220600', 'SYBKDE22BER'],
    ['Tradegate Berlin', '10310600', 'TRDADEB1PBK'],
    ['Tradegate GF DIR', '10310666', 'TRDADEBBDIR'],
    ['solarisBank', '11010100', 'SOBKDEBBXXX'],
    ['solarisBank Gf (S)', '11010101', 'SOBKDEB2XXX'],
    ['solarisBank Gf (PNTA)', '11010111', 'PNTADEBBXXX'],
    ['KfW Berlin', '12016836', 'KFWIDEFF100'],
    ['Deutsche Kreditbank Berlin', '12030000', 'BYLADEM1001'],
    ['Merck Finck', '12030900', 'MEFIDEMM100'],
    ['Commerzbank Fil. Berlin 2', '12040000', 'COBADEBB120'],
    ['ZVA NORD LB Gf SA', '12050555', 'NOLADE21DVS'],
    ['DZ BANK', '12060000', 'GENODEFF120'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB120'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB121'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB122'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB123'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB124'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB125'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB126'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB127'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB160'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB161'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB162'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB163'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB164'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB165'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB166'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB167'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB168'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB169'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB170'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB171'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB172'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB173'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB174'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB175'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB176'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB177'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB178'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB180'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB181'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB182'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB183'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB184'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB185'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB186'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB187'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB188'],
    ['Deutsche Bank', '12070000', 'DEUTDEBB189'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB120'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB121'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB122'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB123'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB124'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB125'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB126'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB127'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB160'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB161'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB162'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB163'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB164'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB165'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB166'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB167'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB168'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB169'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB170'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB171'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB172'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB173'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB174'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB175'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB176'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB177'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB178'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB180'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB181'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB182'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB183'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB184'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB185'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB186'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB187'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB188'],
    ['Deutsche Bank', '12070024', 'DEUTDEDB189'],
    ['Deutsche Bank', '12070070', 'DEUTDEFFVAC'],
    ['Deutsche Bank', '12070088', 'DEUTDEDBPAL'],
    ['Postbank Ndl Deutsche Bank', '12070400', 'DEUTDEBBP33'],
    ['Postbank Ndl Deutsche Bank', '12070424', 'DEUTDEDBP33'],
    ['Commerzbank Fil II Berlin', '12080000', 'DRESDEFF120'],
    ['Sparda-Bank Berlin', '12096597', 'GENODEF1S10'],
    ['BBk Rostock', '13000000', 'MARKDEF1130'],
    ['Commerzbank Rostock', '13040000', 'COBADEFFXXX'],
    ['Ostseesparkasse Rostock', '13050000', 'NOLADE21ROS'],
    ['Spk Vorpommern auf Rügen', '13051042', 'NOLADE21RUE'],
    ['Volksbank Wolgast -alt-', '13061008', 'GENODEF1WOG'],
    ['VB u Raiffbk', '13061078', 'GENODEF1HWI'],
    ['VB u Raiffbk -alt-', '13061088', 'GENODEF1HWR'],
    ['Raiffeisenbank Bad Doberan', '13061128', 'GENODEF1DBR'],
    ['Deutsche Bank', '13070000', 'DEUTDEBRXXX'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB130'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB131'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB132'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB140'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB141'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB142'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB143'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB144'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB145'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB146'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB147'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB148'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB149'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB150'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB151'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB152'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB153'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB154'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB155'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB156'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB157'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB158'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB159'],
    ['Deutsche Bank', '13070000', 'DEUTDEBB179'],
    ['Deutsche Bank', '13070000', 'DEUTDEBR131'],
    ['Deutsche Bank', '13070000', 'DEUTDEBR132'],
    ['Deutsche Bank', '13070000', 'DEUTDEBR133'],
    ['Deutsche Bank', '13070000', 'DEUTDEBR134'],
    ['Deutsche Bank', '13070000', 'DEUTDEBR135'],
    ['Deutsche Bank', '13070000', 'DEUTDEBR136'],
    ['Deutsche Bank', '13070000', 'DEUTDEBR137'],
    ['Deutsche Bank', '13070000', 'DEUTDEBR138'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB128'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB130'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB131'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB132'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB133'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB134'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB135'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB136'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB137'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB138'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB140'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB141'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB142'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB143'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB144'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB145'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB146'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB147'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB148'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB149'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB150'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB151'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB152'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB153'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB154'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB155'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB156'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB157'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB158'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB159'],
    ['Deutsche Bank', '13070024', 'DEUTDEDB179'],
    ['Deutsche Bank', '13070024', 'DEUTDEDBROS'],
    ['Postbank Ndl Deutsche Bank', '13070405', 'DEUTDEBBP35'],
    ['Postbank Ndl Deutsche Bank', '13070424', 'DEUTDEDBP35'],
    ['Commerzbank Rostock', '13080000', 'DRESDEFF130'],
    ['Rostocker VR Bank -alt-', '13090000', 'GENODEF1HR1'],
    ['Pommersche Volksbank', '13091054', 'GENODEF1HST'],
    ['VB u Raiffbk -alt-', '13091084', 'GENODEF1HWV'],
    ['Commerzbank Schwerin', '14040000', 'COBADEFFXXX'],
    ['Spk Mecklenburg-Nordwest', '14051000', 'NOLADE21WIS'],
    ['Sparkasse Parchim-Lübz', '14051362', 'NOLADE21PCH'],
    ['Sparkasse Schwerin -alt-', '14051462', 'NOLADE21SNS'],
    ['Spk Mecklenburg-Schwerin', '14052000', 'NOLADE21LWL'],
    ['VR Bank Mecklenburg', '14061308', 'GENODEF1GUE'],
    ['Raiffeisen-VB Gadebusch-alt', '14061438', 'GENODEF1GDB'],
    ['Commerzbank Schwerin', '14080000', 'DRESDEFF140'],
    ['Commerzbank Schwerin Zw W', '14080011', 'DRESDEFFI27'],
    ['VR-Bank Schwerin -alt-', '14091464', 'GENODEF1SN1'],
    ['BBk Neubrandenburg', '15000000', 'MARKDEF1150'],
    ['Commerzbank Neubrandenburg', '15040068', 'COBADEFFXXX'],
    ['Müritz-Sparkasse Waren', '15050100', 'NOLADE21WRN'],
    ['Spk Neubrandenburg-Demmin', '15050200', 'NOLADE21NBS'],
    ['Sparkasse Uecker-Randow', '15050400', 'NOLADE21PSW'],
    ['Spk Vorpommern', '15050500', 'NOLADE21GRW'],
    ['Spk Mecklenburg-Strelitz', '15051732', 'NOLADE21MST'],
    ['Raiffbk Mecklenb Seenplatte', '15061618', 'GENODEF1WRN'],
    ['VB Vorpommern', '15061638', 'GENODEF1ANK'],
    ['Raiffeisenbank Malchin-alt-', '15061698', 'GENODEF1MAL'],
    ['Commerzbank Neubrandenbg', '15080000', 'DRESDEFF150'],
    ['Volksbank Demmin', '15091674', 'GENODEF1DM1'],
    ['VR-Bank Uckermark-Randow', '15091704', 'GENODEF1PZ1'],
    ['Investitionsbk Potsdam', '16010300', 'ILBXDE8XXXX'],
    ['UniCredit Bank-HypoVereinbk', '16020086', 'HYVEDEMM470'],
    ['Commerzbank Potsdam', '16040000', 'COBADEFFXXX'],
    ['Mittelbrandenbg Sparkasse', '16050000', 'WELADED1PMB'],
    ['Sparkasse Prignitz', '16050101', 'WELADED1PRP'],
    ['Spk Ostprignitz-Ruppin', '16050202', 'WELADED1OPR'],
    ['LBS Ost Potsdam', '16050500', 'LBSODEB1XXX'],
    ['Volks- u Raiffbk Prignitz', '16060122', 'GENODEF1PER'],
    ['Raiffeisenbank Ostpr-Ruppin', '16061938', 'GENODEF1NPP'],
    ['VR-Bank Fläming-Elsterland', '16062008', 'GENODEF1LUK'],
    ['Brandenburger Bank', '16062073', 'GENODEF1BRB'],
    ['Commerzbank Potsdam', '16080000', 'DRESDEFF160'],
    ['Volksbank Rathenow', '16091994', 'GENODEF1RN1'],
    ['UniCredit Bank-HypoVereinbk', '17020086', 'HYVEDEMM471'],
    ['Commerzbank Frankfurt Oder', '17040000', 'COBADEFFXXX'],
    ['Sparkasse Barnim Eberswalde', '17052000', 'WELADED1GZE'],
    ['St Spk Schwedt', '17052302', 'WELADED1UMX'],
    ['Spk Märkisch-Oderland', '17054040', 'WELADED1MOL'],
    ['Sparkasse Oder-Spree', '17055050', 'WELADED1LOS'],
    ['Spk Uckermark Prenzlau', '17056060', 'WELADED1UMP'],
    ['Raiff-VB Oder-Spree Beeskow', '17062428', 'GENODEF1BKW'],
    ['Commerzbank Frankfurt, O', '17080000', 'DRESDEFF170'],
    ['VR Bank Fürstenwalde', '17092404', 'GENODEF1FW1'],
    ['UniCredit Bank-HypoVereinbk', '18020086', 'HYVEDEMM472'],
    ['Commerzbank Cottbus', '18040000', 'COBADEFFXXX'],
    ['Sparkasse Spree-Neiße', '18050000', 'WELADED1CBN'],
    ['Sparkasse Elbe-Elster', '18051000', 'WELADED1EES'],
    ['Sparkasse Niederlausitz', '18055000', 'WELADED1OSL'],
    ['VR Bank Lausitz', '18062678', 'GENODEF1FWA'],
    ['VR Bank Forst -alt-', '18062758', 'GENODEF1FOR'],
    ['Commerzbank Cottbus', '18080000', 'DRESDEFF180'],
    ['Spreewaldbank Lübben', '18092684', 'GENODEF1LN1'],
    ['Volksbank Spree-Neiße', '18092744', 'GENODEF1SPM'],
    ['BBk Hamburg', '20000000', 'MARKDEF1200'],
    ['Postbank Ndl Deutsche Bank', '20010020', 'PBNKDEFFXXX'],
    ['Aareal Bank Wiesbaden', '20010424', 'AARBDE5W200'],
    ['SEB, Hamburg', '20020200', 'ESSEDEFFHAM'],
    ['Jyske Bank Hamburg', '20020500', 'JYBADEHHXXX'],
    ['Signal Iduna Bauspar', '20020900', 'SIBSDEHHXXX'],
    ['UniCredit Bank-HypoVereinbk', '20030000', 'HYVEDEMM300'],
    ['Varengold Bank', '20030133', 'VGAGDEHHXXX'],
    ['DONNER & REUSCHEL', '20030300', 'CHDBDEHHXXX'],
    ['Donner + Reuschel GF NL LUX', '20030301', 'CHDBDEHHXXX'],
    ['Marcardbank Hamburg', '20030400', 'MCRDDEHHXXX'],
    ['Sydbank Hamburg', '20030600', 'SYBKDE22HAM'],
    ['Merck Finck', '20030700', 'MEFIDEMM200'],
    ['Commerzbank Hamburg', '20040000', 'COBADEHHXXX'],
    ['Commerzbank Fil. Hamburg 2', '20040005', 'COBADEHHXXX'],
    ['Commerzbank CC SP, Hamburg', '20040020', 'COBADEFFXXX'],
    ['Commerzbank GF RME, Hamburg', '20040040', 'COBADEFFXXX'],
    ['Commerzbank HBG GF-H48', '20040048', 'COBADEFFXXX'],
    ['Commerzbank GF COC', '20040050', 'COBADEFFXXX'],
    ['Commerzbank Gf 260 Hamburg', '20040060', 'COBADEFFXXX'],
    ['Commerzbank Gf 261 Hamburg', '20040061', 'COBADEFFXXX'],
    ['Commerzbank CC Hamburg', '20040062', 'COBADEFFXXX'],
    ['Commerzbank CC Hamburg', '20040063', 'COBADEFFXXX'],
    ['Commerzbank - GF comdirect', '20041111', 'COBADEHDXXX'],
    ['Commerzbank - GF comdirect', '20041133', 'COBADEHD001'],
    ['Commerzbank - GF comdirect', '20041144', 'COBADEHD044'],
    ['Commerzbank - GF comdirect', '20041155', 'COBADEHD055'],
    ['Commerzbank - GF comdirect', '20041166', 'COBADEHD066'],
    ['Commerzbank - GF comdirect', '20041177', 'COBADEHD077'],
    ['Commerzbank - GF comdirect', '20041188', 'COBADEHD088'],
    ['Commerzbank - GF comdirect', '20041199', 'COBADEHD099'],
    ['Hamburg Commercial Bank', '20050000', 'HSHNDEHHXXX'],
    ['Haspa Hamburg', '20050550', 'HASPDEHHXXX'],
    ['DZ BANK', '20060000', 'GENODEFF200'],
    ['Norderstedter Bank -alt-', '20069111', 'GENODEF1NDR'],
    ['Kaltenkirchener Bank -alt-', '20069125', 'GENODEF1KLK'],
    ['Raiffbk -alt-', '20069130', 'GENODEF1BBR'],
    ['Raiffeisenbank Seestermühe', '20069144', 'GENODEF1SST'],
    ['Raiffbk Südstormarn Mölln', '20069177', 'GENODEF1GRS'],
    ['Raiffbk Struvenhütten -alt-', '20069232', 'GENODEF1STV'],
    ['Raiffeisenbank Owschlag', '20069641', 'GENODEF1OWS'],
    ['Volksbank Ahlerstedt', '20069780', 'GENODEF1AST'],
    ['Volksbank Geest', '20069782', 'GENODEF1APE'],
    ['VB Kehdingen', '20069786', 'GENODEF1DRO'],
    ['Spar- und Kreditbank Hammah', '20069800', 'GENODEF1HAA'],
    ['Volksbank Fredenbeck', '20069812', 'GENODEF1FRB'],
    ['Volksbank Oldendorf', '20069815', 'GENODEF1815'],
    ['Raiffbk Ratzeburg -alt-', '20069861', 'GENODEF1RRZ'],
    ['Raiffbk Travemünde -alt-', '20069882', 'GENODEF1RLT'],
    ['VB Winsener Marsch', '20069965', 'GENODEF1WIM'],
    ['Volksbank Wulfsen -alt-', '20069989', 'GENODEF1WUL'],
    ['Deutsche Bank', '20070000', 'DEUTDEHHXXX'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH200'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH201'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH202'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH203'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH204'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH205'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH206'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH207'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH209'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH211'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH213'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH219'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH221'],
    ['Deutsche Bank', '20070000', 'DEUTDEHH241'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB200'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB201'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB202'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB203'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB204'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB205'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB206'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB207'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB209'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB211'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB213'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB219'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB221'],
    ['Deutsche Bank', '20070024', 'DEUTDEDB241'],
    ['Deutsche Bank', '20070024', 'DEUTDEDBHAM'],
    ['Postbank Ndl Deutsche Bank', '20070404', 'DEUTDEHHP34'],
    ['Postbank Ndl Deutsche Bank', '20070424', 'DEUTDEDBP34'],
    ['Commerzbank Hamburg', '20080000', 'DRESDEFF200'],
    ['Commerzbank Zw 55 Hamburg', '20080055', 'DRESDEFF207'],
    ['Commerzbk ZW 57 Hamburg', '20080057', 'DRESDEFF208'],
    ['Commerzbank ITKG2 Hamburg', '20080085', 'DRESDEFFI56'],
    ['Commerzbank ITGK 3', '20080086', 'DRESDEFFI63'],
    ['Commerzbank ITGK4 Hamburg', '20080087', 'DRESDEFFI64'],
    ['Commerzbank ITGK5 Hamburg', '20080088', 'DRESDEFFI74'],
    ['Commerzbank ITGK6 Hamburg', '20080089', 'DRESDEFFI75'],
    ['Commerzbank ITGK 7', '20080091', 'DRESDEFFJ33'],
    ['Commerzbank ITGK 8', '20080092', 'DRESDEFFJ34'],
    ['Commerzbank ITGK 9', '20080093', 'DRESDEFFJ35'],
    ['Commerzbank ITGK 10', '20080094', 'DRESDEFFJ36'],
    ['Commerzbank ITGK 11', '20080095', 'DRESDEFFJ37'],
    ['Commerzbank ITGK Hamburg', '20089200', 'DRESDEFFI06'],
    ['DZ HYP Hamburg', '20090400', 'DGHYDEH1XXX'],
    ['Edekabank Hamburg', '20090700', 'EDEKDEHHXXX'],
    ['EBANK Gf Cash Hamburg', '20090745', 'EDEKDEHHXXX'],
    ['PSD Bank Nord Hamburg', '20090900', 'GENODEF1P08'],
    ['Postbank Ndl Deutsche Bank', '20110022', 'PBNKDEFFXXX'],
    ['MUFG Bank', '20110700', 'BOTKDEH1XXX'],
    ['Bank of China Hamburg', '20110800', 'BKCHDEFFHMB'],
    ['Berenberg, Hamburg', '20120000', 'BEGODEHHXXX'],
    ['M.M. Warburg Bank', '20120100', 'WBWCDEHHXXX'],
    ['BHF-BANK Hamburg', '20120200', 'BHFBDEFF200'],
    ['start:bausparkasse', '20120400', 'DRBKDEH1XXX'],
    ['Sozialbank Hamburg', '20120520', 'BFSWDE33HAN'],
    ['Goyer & Göppel Hamburg', '20120600', 'GOGODEH1XXX'],
    ['Hanseatic Bank Hamburg', '20120700', 'HSTBDEHHXXX'],
    ['GRENKE BANK', '20130400', 'GREBDEH1XXX'],
    ['Barclays Bank Hamburg', '20130600', 'BARCDEHAXXX'],
    ['Santander Bank Hamburg', '20133300', 'SCFBDE33XXX'],
    ['Hamburger Volksbank', '20190003', 'GENODEF1HH2'],
    ['Hamburger Volksbank Gf GAA', '20190077', 'GENODEF1HH2'],
    ['Volksbank Raiffeisenbank', '20190109', 'GENODEF1HH4'],
    ['VB Hamburg Ost-West -alt-', '20190206', 'GENODEF1HH1'],
    ['Vierländer VB Hamburg -alt-', '20190301', 'GENODEF1HH3'],
    ['MKB Hamburg', '20190800', 'GENODEF1MKB'],
    ['Bank Melli Iran Hamburg', '20210200', 'MELIDEHHXXX'],
    ['Bank Saderat Iran Hamburg', '20210300', 'SIHRDEH1HAM'],
    ['DNB Bank', '20220100', 'DNBADEHXXXX'],
    ['M.M. Warburg Hyp Hamburg', '20220400', 'MMWHDEH1XXX'],
    ['Banking Circle Germany', '20220800', 'SXPYDEHHXXX'],
    ['Schröderbank Hamburg', '20230300', 'OSCBDEH1XXX'],
    ['Isbank Hamburg', '20230600', 'ISBKDEFXXXX'],
    ['Sutor Bank Hamburg', '20230800', 'MHSBDEHBXXX'],
    ['Europ-Iran Handelsbk Hambg', '20310300', 'EIHBDEHHXXX'],
    ['Sparda-Bank Hamburg', '20690500', 'GENODEF1S11'],
    ['UniCredit Bk Settlemt EAC01', '20730001', 'HYVEDEMME01'],
    ['UniCredit Bk Settlemt EAC02', '20730002', 'HYVEDEMME02'],
    ['UniCredit Bk Settlemt EAC03', '20730003', 'HYVEDEMME03'],
    ['UniCredit Bk Settlemt EAC04', '20730004', 'HYVEDEMME04'],
    ['UniCredit Bk Settlemt EAC05', '20730005', 'HYVEDEMME05'],
    ['UniCredit Bk Settlemt EAC06', '20730006', 'HYVEDEMME06'],
    ['UniCredit Bk Settlemt EAC07', '20730007', 'HYVEDEMME07'],
    ['UniCredit Bk Settlemt EAC08', '20730008', 'HYVEDEMME08'],
    ['UniCredit Bk Settlemt EAC09', '20730009', 'HYVEDEMME09'],
    ['UniCredit Bk Settlemt EAC10', '20730010', 'HYVEDEMME10'],
    ['UniCredit Bk Settlemt EAC11', '20730011', 'HYVEDEMME11'],
    ['UniCredit Bk Settlemt EAC12', '20730012', 'HYVEDEMME12'],
    ['UniCredit Bk Settlemt EAC13', '20730013', 'HYVEDEMME13'],
    ['UniCredit Bk Settlemt EAC14', '20730014', 'HYVEDEMME14'],
    ['UniCredit Bk Settlemt EAC15', '20730015', 'HYVEDEMME15'],
    ['UniCredit Bk Settlemt EAC16', '20730016', 'HYVEDEMME16'],
    ['UniCredit Bk Settlemt EAC17', '20730017', 'HYVEDEMME17'],
    ['UniCredit Bk Settlemt EAC18', '20730018', 'HYVEDEMME18'],
    ['UniCredit Bk Settlemt EAC19', '20730019', 'HYVEDEMME19'],
    ['UniCredit Bk Settlemt EAC20', '20730020', 'HYVEDEMME20'],
    ['UniCredit Bk Settlemt EAC21', '20730021', 'HYVEDEMME21'],
    ['UniCredit Bk Settlemt EAC22', '20730022', 'HYVEDEMME22'],
    ['UniCredit Bk Settlemt EAC23', '20730023', 'HYVEDEMME23'],
    ['UniCredit Bk Settlemt EAC24', '20730024', 'HYVEDEMME24'],
    ['UniCredit Bk Settlemt EAC25', '20730025', 'HYVEDEMME25'],
    ['UniCredit Bk Settlemt EAC26', '20730026', 'HYVEDEMME26'],
    ['UniCredit Bk Settlemt EAC27', '20730027', 'HYVEDEMME27'],
    ['UniCredit Bk Settlemt EAC28', '20730028', 'HYVEDEMME28'],
    ['UniCredit Bk Settlemt EAC29', '20730029', 'HYVEDEMME29'],
    ['UniCredit Bk Settlemt EAC30', '20730030', 'HYVEDEMME30'],
    ['UniCredit Bk Settlemt EAC31', '20730031', 'HYVEDEMME31'],
    ['UniCredit Bk Settlemt EAC32', '20730032', 'HYVEDEMME32'],
    ['UniCredit Bk Settlemt EAC33', '20730033', 'HYVEDEMME33'],
    ['UniCredit Bk Settlemt EAC34', '20730034', 'HYVEDEMME34'],
    ['UniCredit Bk Settlemt EAC35', '20730035', 'HYVEDEMME35'],
    ['UniCredit Bk Settlemt EAC36', '20730036', 'HYVEDEMME36'],
    ['UniCredit Bk Settlemt EAC37', '20730037', 'HYVEDEMME37'],
    ['UniCredit Bk Settlemt EAC38', '20730038', 'HYVEDEMME38'],
    ['UniCredit Bk Settlemt EAC39', '20730039', 'HYVEDEMME39'],
    ['UniCredit Bk Settlemt EAC40', '20730040', 'HYVEDEMME40'],
    ['UniCredit Bk Settlemt EAC41', '20730041', 'HYVEDEMME41'],
    ['UniCredit Bk Settlemt EAC42', '20730042', 'HYVEDEMME42'],
    ['UniCredit Bk Settlemt EAC43', '20730043', 'HYVEDEMME43'],
    ['UniCredit Bk Settlemt EAC44', '20730044', 'HYVEDEMME44'],
    ['UniCredit Bk Settlemt EAC45', '20730045', 'HYVEDEMME45'],
    ['UniCredit Bk Settlemt EAC46', '20730046', 'HYVEDEMME46'],
    ['UniCredit Bk Settlemt EAC47', '20730047', 'HYVEDEMME47'],
    ['UniCredit Bk Settlemt EAC48', '20730048', 'HYVEDEMME48'],
    ['UniCredit Bk Settlemt EAC49', '20730049', 'HYVEDEMME49'],
    ['UniCredit Bk Settlemt EAC50', '20730050', 'HYVEDEMME50'],
    ['UniCredit Bk Settlemt EAC52', '20730052', 'HYVEDEMME52'],
    ['UniCredit Bk Settlemt EAC55', '20730055', 'HYVEDEMME55'],
    ['UniCredit Bk Settlemt EAC56', '20730056', 'HYVEDEMME56'],
    ['UniCredit Bk Settlemt EAC57', '20730057', 'HYVEDEMME57'],
    ['UniCredit Bk Settlemt EAC58', '20730058', 'HYVEDEMME58'],
    ['UniCredit Bk Settlemt EAC59', '20730059', 'HYVEDEMME59'],
    ['UniCredit Bk Settlemt EAC60', '20730060', 'HYVEDEMME60'],
    ['UniCredit Bk Settlemt EAC61', '20730061', 'HYVEDEMME61'],
    ['UniCredit Bk Settlemt EAC62', '20730062', 'HYVEDEMME62'],
    ['UniCredit Bk Settlemt EAC63', '20730063', 'HYVEDEMME63'],
    ['UniCredit Bk Settlemt EAC64', '20730064', 'HYVEDEMME64'],
    ['UniCredit Bk Settlemt EAC65', '20730065', 'HYVEDEMME65'],
    ['UniCredit Bk Settlemt EAC66', '20730066', 'HYVEDEMME66'],
    ['UniCredit Bk Settlemt EAC67', '20730067', 'HYVEDEMME67'],
    ['UniCredit Bk Settlemt EAC68', '20730068', 'HYVEDEMME68'],
    ['UniCredit Bk Settlemt EAC69', '20730069', 'HYVEDEMME69'],
    ['UniCredit Bk Settlemt EAC70', '20730070', 'HYVEDEMME70'],
    ['UniCredit Bk Settlemt EAC71', '20730071', 'HYVEDEMME71'],
    ['UniCredit Bk Settlemt EAC72', '20730072', 'HYVEDEMME72'],
    ['UniCredit Bk Settlemt EAC73', '20730073', 'HYVEDEMME73'],
    ['UniCredit Bk Settlemt EAC74', '20730074', 'HYVEDEMME74'],
    ['UniCredit Bk Settlemt EAC75', '20730075', 'HYVEDEMME75'],
    ['UniCredit Bk Settlemt EAC76', '20730076', 'HYVEDEMME76'],
    ['UniCredit Bk Settlemt EAC77', '20730077', 'HYVEDEMME77'],
    ['UniCredit Bk Settlemt EAC78', '20730078', 'HYVEDEMME78'],
    ['UniCredit Bk Settlemt EAC79', '20730079', 'HYVEDEMME79'],
    ['UniCredit Bk Settlemt EAC80', '20730080', 'HYVEDEMME80'],
    ['UniCredit Bk Settlemt EAC81', '20730081', 'HYVEDEMME81'],
    ['UniCredit Bk Settlemt EAC82', '20730082', 'HYVEDEMME82'],
    ['UniCredit Bk Settlemt EAC83', '20730083', 'HYVEDEMME83'],
    ['UniCredit Bk Settlemt EAC84', '20730084', 'HYVEDEMME84'],
    ['UniCredit Bk Settlemt EAC85', '20730085', 'HYVEDEMME85'],
    ['UniCredit Bk Settlemt EAC86', '20730086', 'HYVEDEMME86'],
    ['UniCredit Bk Settlemt EAC87', '20730087', 'HYVEDEMME87'],
    ['UniCredit Bk Settlemt EAC88', '20730088', 'HYVEDEMME88'],
    ['UniCredit Bk Settlemt EAC89', '20730089', 'HYVEDEMME89'],
    ['UniCredit Bk Settlemt EAC90', '20730090', 'HYVEDEMME90'],
    ['UniCredit Bk Settlemt EAC91', '20730091', 'HYVEDEMME91'],
    ['UniCredit Bk Settlemt EAC92', '20730092', 'HYVEDEMME92'],
    ['UniCredit Bk Settlemt EAC93', '20730093', 'HYVEDEMME93'],
    ['UniCredit Bk Settlemt EAC94', '20730094', 'HYVEDEMME94'],
    ['UniCredit Bk Settlemt EAC95', '20730095', 'HYVEDEMME95'],
    ['UniCredit Bk Settlemt EAC96', '20730096', 'HYVEDEMME96'],
    ['UniCredit Bk Settlemt EAC97', '20730097', 'HYVEDEMME97'],
    ['UniCredit Bk Settlemt EAC98', '20730098', 'HYVEDEMME98'],
    ['UniCredit Bk Settlemt EAC99', '20730099', 'HYVEDEMME99'],
    ['Spk Harburg-Buxtehude', '20750000', 'NOLADE21HAM'],
    ['BBk Hamburg eh Kiel', '21000000', 'MARKDEF1210'],
    ['Sydbank Fil. Kiel', '21020600', 'SYBKDE22KIE'],
    ['Commerzbank Kiel', '21040010', 'COBADEFFXXX'],
    ['Commerzbank Eckernförde', '21042076', 'COBADEFFXXX'],
    ['Hamburg Commercial Bank', '21050000', 'HSHNDEHHXXX'],
    ['Förde Sparkasse', '21050170', 'NOLADE21KIE'],
    ['Bordesholmer Sparkasse', '21051275', 'NOLADE21BOR'],
    ['Spk Kreis Plön -alt-', '21051580', 'NOLADE21PLN'],
    ['Sparkasse Eckernförde -alt-', '21052090', 'NOLADE21ECK'],
    ['Deutsche Bank', '21070020', 'DEUTDEHH210'],
    ['Deutsche Bank', '21070020', 'DEUTDEHH214'],
    ['Deutsche Bank', '21070024', 'DEUTDEDB210'],
    ['Deutsche Bank', '21070024', 'DEUTDEDB214'],
    ['Commerzbank Kiel', '21080050', 'DRESDEFF210'],
    ['Commerzbank ITGK Kiel', '21089201', 'DRESDEFFI07'],
    ['Kieler Volksbank', '21090007', 'GENODEF1KIL'],
    ['Kieler Volksbank (Gf GAA)', '21090099', 'GENODEF1KIL'],
    ['PSD Bank Kiel', '21090900', 'GENODEF1P11'],
    ['Eckernförder Bank VRB', '21092023', 'GENODEF1EFO'],
    ['Commerzbank Neumünster', '21240040', 'COBADEFFXXX'],
    ['Commerzbank Bad Bramstedt', '21241540', 'COBADEFFXXX'],
    ['Raiffeisenbank Wasbek -alt-', '21261089', 'GENODEF1WAS'],
    ['Raiffbk Kl-Kummerfeld -alt-', '21261227', 'GENODEF1BOO'],
    ['Deutsche Bank', '21270020', 'DEUTDEHH212'],
    ['Deutsche Bank', '21270024', 'DEUTDEDB212'],
    ['Commerzbank Neumünster', '21280002', 'DRESDEFF212'],
    ['VR Bank Neumünster -alt-', '21290016', 'GENODEF1NMS'],
    ['Commerzbank Neustadt Holst', '21340010', 'COBADEFFXXX'],
    ['Spk Holstein Eutin', '21352240', 'NOLADE21HOL'],
    ['VR Bank zdM, Neumünster', '21390008', 'GENODEF1NSH'],
    ['Volksbank Eutin', '21392218', 'GENODEF1EUT'],
    ['Commerzbank Rendsburg', '21440045', 'COBADEFFXXX'],
    ['Spk Mittelholstein', '21450000', 'NOLADE21RDB'],
    ['Sparkasse Büdelsdorf -alt-', '21451205', 'NOLADE21BDF'],
    ['Spk Hohenwestedt -alt-', '21452030', 'NOLADE21HWS'],
    ['VB-Raiffbk i Kr Rendsb-alt-', '21463603', 'GENODEF1NTO'],
    ['Raiffbk Todenbüttel', '21464671', 'GENODEF1TOB'],
    ['Commerzbank Rendsburg', '21480003', 'DRESDEFF214'],
    ['Sydbank Fil. Flensburg', '21510600', 'SYBKDE22XXX'],
    ['Union-Bank Flensburg', '21520100', 'UNBNDE21XXX'],
    ['Commerzbank Flensburg', '21540060', 'COBADEFFXXX'],
    ['Raiffbk Handewitt -alt-', '21565316', 'GENODEF1HDW'],
    ['Deutsche Bank', '21570011', 'DEUTDEHH215'],
    ['Deutsche Bank', '21570011', 'DEUTDEHH216'],
    ['Deutsche Bank', '21570024', 'DEUTDEDB215'],
    ['Deutsche Bank', '21570024', 'DEUTDEDB216'],
    ['Postbank Ndl Deutsche Bank', '21570202', 'DEUTDEHHP01'],
    ['Postbank Ndl Deutsche Bank', '21570224', 'DEUTDEDBP01'],
    ['Commerzbank Flensburg', '21580000', 'DRESDEFF215'],
    ['VR Bank Flensburg-Sch.-alt-', '21661719', 'GENODEF1RSL'],
    ['VR Bank SL MH', '21690020', 'GENODEF1SLW'],
    ['Commerzbank Husum Nordsee', '21740043', 'COBADEFFXXX'],
    ['Commerzbank Niebüll', '21741674', 'COBADEFFXXX'],
    ['Commerzbank Westerland', '21741825', 'COBADEFFXXX'],
    ['Nord-Ostsee Spk Schleswig', '21750000', 'NOLADE21NOS'],
    ['Spk Bredstedt -alt-', '21751230', 'NOLADE21BRD'],
    ['VR Bank Westküste', '21762550', 'GENODEF1HUM'],
    ['VR Bank Nord', '21763542', 'GENODEF1BDS'],
    ['Deutsche Bank', '21770011', 'DEUTDEHH217'],
    ['Deutsche Bank', '21770024', 'DEUTDEDB217'],
    ['Sylter Bank', '21791805', 'GENODEF1SYL'],
    ['Vereinigte VR Bank', '21791906', 'GENODEF1WYK'],
    ['Commerzbank Heide Holst', '21840078', 'COBADEFFXXX'],
    ['Commerzbank Brunsbüttel', '21841328', 'COBADEFFXXX'],
    ['Spk Hennst.-Wesselb. -alt-', '21852310', 'NOLADE21WEB'],
    ['Raiffeisenbank Heide -alt-', '21860418', 'GENODEF1RHE'],
    ['Dithmarscher VB Heide', '21890022', 'GENODEF1DVR'],
    ['Commerzbank Elmshorn', '22140028', 'COBADEFFXXX'],
    ['Commerzbank Kaltenkirchen', '22141028', 'COBADEFFXXX'],
    ['Commerzbank Pinneberg', '22141428', 'COBADEFFXXX'],
    ['Commerzbank Uetersen', '22141628', 'COBADEFFXXX'],
    ['Spk Elmshorn', '22150000', 'NOLADE21ELH'],
    ['St Spk Wedel', '22151730', 'NOLADE21WED'],
    ['Raiffbk Elbmarsch Heist', '22163114', 'GENODEF1HTE'],
    ['Commerzbank Elmshorn', '22180000', 'DRESDEFF221'],
    ['Commerzbank Pinneberg', '22181400', 'DRESDEFF206'],
    ['Volksbank Elmshorn -alt-', '22190030', 'GENODEF1ELM'],
    ['VR Bank in Holstein', '22191405', 'GENODEF1PIN'],
    ['Commerzbank Itzehoe', '22240073', 'COBADEFFXXX'],
    ['Spk Westholstein', '22250020', 'NOLADE21WHO'],
    ['Commerzbank Itzehoe', '22280000', 'DRESDEFF201'],
    ['VR Itzehoe Norderstedt-alt-', '22290031', 'GENODEF1VIT'],
    ['BBk Hamburg eh Lübeck', '23000000', 'MARKDEF1230'],
    ['Commerzbank Lübeck', '23040022', 'COBADEFFXXX'],
    ['Hamburg Commercial Bank', '23050000', 'HSHNDEHH230'],
    ['Sparkasse zu Lübeck', '23050101', 'NOLADE21SPL'],
    ['Spk Südholstein Neumünster', '23051030', 'NOLADE21SHO'],
    ['Kr Spk Herzogtum Lauenburg', '23052750', 'NOLADE21RZB'],
    ['Raiffeisenbank Leezen', '23061220', 'GENODEF1LZN'],
    ['Raiffbk Bargteheide -alt-', '23062124', 'GENODEF1BAR'],
    ['Raiffeisenbank Lauenburg', '23063129', 'GENODEF1RLB'],
    ['Raiffeisenbank Büchen', '23064107', 'GENODEF1BCH'],
    ['Postbank Ndl Deutsche Bank', '23070203', 'DEUTDEHHP02'],
    ['Postbank Ndl Deutsche Bank', '23070224', 'DEUTDEDBP02'],
    ['Deutsche Bank', '23070700', 'DEUTDEDB237'],
    ['Deutsche Bank', '23070710', 'DEUTDEHH222'],
    ['Commerzbank Lübeck', '23080040', 'DRESDEFF230'],
    ['Commerzbank ITGK Lübeck', '23089201', 'DRESDEFFI08'],
    ['Volksbank Lübeck', '23090142', 'GENODEF1HLU'],
    ['Commerzbank Lüneburg', '24040000', 'COBADEFFXXX'],
    ['Spk Lüneburg', '24050110', 'NOLADE21LBG'],
    ['VB Lüneburger Heide', '24060300', 'GENODEF1NBU'],
    ['VB Bleckede-Dahlenbg -alt-', '24061392', 'GENODEF1DAB'],
    ['Deutsche Bank', '24070024', 'DEUTDEDB240'],
    ['Deutsche Bank', '24070024', 'DEUTDEDB242'],
    ['Deutsche Bank', '24070075', 'DEUTDE2H240'],
    ['Deutsche Bank', '24070075', 'DEUTDE2H241'],
    ['Postbank Ndl Deutsche Bank', '24070324', 'DEUTDEDBP22'],
    ['Postbank Ndl Deutsche Bank', '24070368', 'DEUTDE2HP22'],
    ['Commerzbank Lüneburg', '24080000', 'DRESDEFF240'],
    ['VB Lüneburg -alt-', '24090041', 'GENODEF1LUE'],
    ['Ritter Kredit Stade', '24121000', 'GENODED1RKI'],
    ['Commerzbank Cuxhaven', '24140041', 'COBADEFFXXX'],
    ['St Spk Cuxhaven', '24150001', 'BRLADE21CUX'],
    ['Spk Stade-Altes Land', '24151005', 'NOLADE21STS'],
    ['Kreissparkasse Stade', '24151116', 'NOLADE21STK'],
    ['Spk Rotenburg Osterholz', '24151235', 'BRLADE21ROB'],
    ['Zevener Volksbank -alt-', '24161594', 'GENODEF1SIT'],
    ['Spar- u Darlehnskasse Börde', '24162898', 'GENODEF1LAS'],
    ['Commerzbank Otterndorf', '24180000', 'DRESDEFF242'],
    ['Commerzbank Cuxhaven', '24180001', 'DRESDEFF241'],
    ['Volksbank Stade-Cuxhaven', '24191015', 'GENODEF1SDE'],
    ['BBk Hannover', '25000000', 'MARKDEF1250'],
    ['Postbank Ndl Deutsche Bank', '25010030', 'PBNKDEFFXXX'],
    ['Aareal Bank Wiesbaden', '25010424', 'AARBDE5W250'],
    ['NORD/LB Deut. Hypo Hannover', '25010600', 'DEHYDE2HXXX'],
    ['Calenbg Kreditver Hannover', '25010900', 'CKVHDE21XXX'],
    ['BHF-BANK Hannover', '25020200', 'BHFBDEFF250'],
    ['Santander Consumer Bank', '25020600', 'SCFBDE33XXX'],
    ['Commerzbank CC Hannover', '25040060', 'COBADEFFXXX'],
    ['Commerzbank CC Hannover', '25040061', 'COBADEFFXXX'],
    ['Commerzbank Hannover', '25040066', 'COBADEFFXXX'],
    ['Nord LB Hannover', '25050000', 'NOLADE2HXXX'],
    ['ZVA Nord LB SH', '25050055', 'NOLADE21CSH'],
    ['ZVA NORD LB Gf MV', '25050066', 'NOLADE21CMV'],
    ['Sparkasse Hannover', '25050180', 'SPKHDE2HXXX'],
    ['LBS-Nord, Hannover', '25055500', 'NOLADE21LBS'],
    ['DZ BANK', '25060000', 'GENODEFF250'],
    ['DZ BANK Hannover', '25060000', 'GENODEFF280'],
    ['M.M. Warburg (Hallbaum)', '25060180', 'HALLDE2HXXX'],
    ['VB u RB Leinebgld Del.-alt-', '25069168', 'GENODEF1DES'],
    ['Raiff-VB Neustadt', '25069262', 'GENODEF1NST'],
    ['Volksbank Aller-Oker', '25069270', 'GENODEF1MUA'],
    ['VB Diepholz-Barnstorf -alt-', '25069503', 'GENODEF1BNT'],
    ['Deutsche Bank', '25070024', 'DEUTDEDB243'],
    ['Deutsche Bank', '25070024', 'DEUTDEDB250'],
    ['Deutsche Bank', '25070024', 'DEUTDEDB251'],
    ['Deutsche Bank', '25070024', 'DEUTDEDB252'],
    ['Deutsche Bank', '25070024', 'DEUTDEDB256'],
    ['Deutsche Bank', '25070024', 'DEUTDEDB258'],
    ['Deutsche Bank', '25070024', 'DEUTDEDB931'],
    ['Deutsche Bank', '25070024', 'DEUTDEDB932'],
    ['Deutsche Bank', '25070024', 'DEUTDEDB933'],
    ['Deutsche Bank', '25070024', 'DEUTDEDBHAN'],
    ['Deutsche Bank', '25070066', 'DEUTDE2H265'],
    ['Deutsche Bank', '25070070', 'DEUTDE2HXXX'],
    ['Deutsche Bank', '25070070', 'DEUTDE2H250'],
    ['Deutsche Bank', '25070070', 'DEUTDE2H252'],
    ['Deutsche Bank', '25070070', 'DEUTDE2H282'],
    ['Deutsche Bank', '25070070', 'DEUTDE2H283'],
    ['Deutsche Bank', '25070077', 'DEUTDE2H256'],
    ['Deutsche Bank', '25070084', 'DEUTDE2H258'],
    ['Deutsche Bank', '25070084', 'DEUTDE2H284'],
    ['Deutsche Bank', '25070086', 'DEUTDE2H251'],
    ['Postbank Ndl Deutsche Bank', '25070324', 'DEUTDEDBP24'],
    ['Postbank Ndl Deutsche Bank', '25070370', 'DEUTDE2HP24'],
    ['Commerzbank Hannover', '25080020', 'DRESDEFF250'],
    ['Commerzbank ITGK2 Hannov', '25080085', 'DRESDEFFI65'],
    ['Commerzbank ITGK Hannover', '25089220', 'DRESDEFFI09'],
    ['Bk f Schiffahrt Hannover', '25090300', 'GENODEF1BFS'],
    ['Sparda-Bank Hannover', '25090500', 'GENODEF1S09'],
    ['PSD Bank Hannover', '25090900', 'GENODEF1P09'],
    ['Bank für Sozialwirtschaft', '25120510', 'BFSWDE33HAN'],
    ['St Spk Barsinghausen', '25151270', 'NOLADE21BAH'],
    ['Stadtsparkasse Burgdorf', '25151371', 'NOLADE21BUF'],
    ['Kr Spk Fallingbostel', '25152375', 'NOLADE21WAL'],
    ['St Spk Wunstorf', '25152490', 'NOLADE21WST'],
    ['Hannoversche Volksbank', '25190001', 'VOHADE2HXXX'],
    ['HanVB - GS nur für GAA', '25190088', 'VOHADE2HXXX'],
    ['Volksbank', '25193331', 'GENODEF1PAT'],
    ['Kreissparkasse Peine -alt-', '25250001', 'NOLADE21PEI'],
    ['Volksbank Peine -alt-', '25260010', 'GENODEF1PEV'],
    ['BHW Bauspk Hameln', '25410200', 'BHWBDE2HXXX'],
    ['Commerzbank Hameln', '25440047', 'COBADEFFXXX'],
    ['St Spk Hameln -alt-', '25450001', 'NOLADE21HMS'],
    ['Spk Hameln-Weserbergland', '25450110', 'NOLADE21SWB'],
    ['St Spk Bad Pyrmont', '25451345', 'NOLADE21PMT'],
    ['VB Hameln-Stadthagen', '25462160', 'GENODEF1HMP'],
    ['VB im Wesertal Coppenbrügge', '25462680', 'GENODEF1COP'],
    ['Deutsche Bank', '25470024', 'DEUTDEDB254'],
    ['Deutsche Bank', '25470024', 'DEUTDEDB255'],
    ['Deutsche Bank', '25470073', 'DEUTDE2H254'],
    ['Deutsche Bank', '25471024', 'DEUTDEDB264'],
    ['Deutsche Bank', '25471073', 'DEUTDE2H264'],
    ['Commerzbank Hameln', '25480021', 'DRESDEFF254'],
    ['Volksbank Aerzen -alt-', '25491273', 'GENODED1AEZ'],
    ['Commerzbank Bückeburg', '25541426', 'COBADEFFXXX'],
    ['Sparkasse Schaumburg', '25551480', 'NOLADE21SHG'],
    ['VB in Schaumburg & Nienburg', '25591413', 'GENODEF1BCK'],
    ['Oldb Landesbank Diepholz', '25621327', 'OLBODEH2XXX'],
    ['Commerzbank Diepholz', '25641302', 'COBADEFFXXX'],
    ['Sparkasse Nienburg', '25650106', 'NOLADE21NIB'],
    ['Kr Spk Diepholz', '25651325', 'BRLADE21DHZ'],
    ['Volksbank Steyerberg -alt-', '25662540', 'GENODEF1STY'],
    ['Volksbank Aller-Weser -alt-', '25663584', 'GENODEF1HOY'],
    ['Volksbank Nienburg -alt-', '25690009', 'GENODEF1NIN'],
    ['VB Niedersachsen-Mitte', '25691633', 'GENODEF1SUL'],
    ['VB Niedersachsen-Mitte', '25691699', 'GENODEF1SUL'],
    ['Commerzbank Celle', '25740061', 'COBADEFFXXX'],
    ['Sparkasse Celle -alt-', '25750001', 'NOLADE21CEL'],
    ['VB Wittingen-Klötze -alt-', '25761894', 'GENODEF1WIK'],
    ['Deutsche Bank', '25770024', 'DEUTDEDB257'],
    ['Deutsche Bank', '25770069', 'DEUTDE2H257'],
    ['Commerzbank Celle', '25780022', 'DRESDEFF257'],
    ['Volksbank Hankensbüttel-alt', '25791516', 'GENODEF1HKB'],
    ['VB Südh.-Isenh.L.-Altm', '25791635', 'GENODEF1HMN'],
    ['Commerzbank Uelzen', '25840048', 'COBADEFFXXX'],
    ['Commerzbank Lüchow', '25841403', 'COBADEFFXXX'],
    ['Commerzbank Schneverdingen', '25841708', 'COBADEFFXXX'],
    ['Sparkasse Uelzen Lüchow-Dbg', '25850110', 'NOLADE21UEL'],
    ['Kr Spk Soltau', '25851660', 'NOLADE21SOL'],
    ['VB Clenze-Hitzacker -alt-', '25861990', 'GENODEF1CLZ'],
    ['Volksbank Uelzen-Salzwedel', '25862292', 'GENODEF1EUB'],
    ['VR PLUS Altmark-Wendland', '25863489', 'GENODEF1WOT'],
    ['VB Lüneburger Heide -alt-', '25891636', 'GENODEF1SOL'],
    ['Commerzbank Hildesheim', '25940033', 'COBADEFFXXX'],
    ['St Spk Hildesheim -alt-', '25950001', 'NOLADE21HIS'],
    ['Sparkasse HGP', '25950130', 'NOLADE21HIK'],
    ['Deutsche Bank', '25970024', 'DEUTDEDB259'],
    ['Deutsche Bank', '25970074', 'DEUTDE2H259'],
    ['Deutsche Bank', '25971024', 'DEUTDEDB253'],
    ['Deutsche Bank', '25971024', 'DEUTDEDB261'],
    ['Deutsche Bank', '25971071', 'DEUTDE2H253'],
    ['Deutsche Bank', '25971071', 'DEUTDE2H261'],
    ['Commerzbank Hildesheim', '25980027', 'DRESDEFF259'],
    ['Volksbank Hildesheim', '25990011', 'GENODEF1HIH'],
    ['VB Hildesheimer Börde -alt-', '25991528', 'GENODEF1SLD'],
    ['BBk Göttingen', '26000000', 'MARKDEF1260'],
    ['Commerzbank Göttingen', '26040030', 'COBADEFFXXX'],
    ['Sparkasse Göttingen', '26050001', 'NOLADE21GOE'],
    ['Sparkasse Duderstadt', '26051260', 'NOLADE21DUD'],
    ['Volksbank Mitte -alt-', '26061291', 'GENODEF1DUD'],
    ['Volksbank Adelebsen', '26061556', 'GENODEF1ADE'],
    ['VR-Bank in Südniedersachsen', '26062433', 'GENODEF1DRA'],
    ['Deutsche Bank', '26070024', 'DEUTDEDB260'],
    ['Deutsche Bank', '26070024', 'DEUTDEDB263'],
    ['Deutsche Bank', '26070072', 'DEUTDE2H260'],
    ['Deutsche Bank', '26070072', 'DEUTDE2H263'],
    ['Commerzbank Göttingen', '26080024', 'DRESDEFF260'],
    ['Volksbank Göttingen -alt-', '26090050', 'GENODEF1GOE'],
    ['Commerzbank Northeim Han', '26240039', 'COBADEFFXXX'],
    ['Kr Spk Northeim', '26250001', 'NOLADE21NOM'],
    ['Sparkasse Einbeck', '26251425', 'NOLADE21EIN'],
    ['Volksbank Einbeck  -alt-', '26261492', 'GENODEF1EIN'],
    ['Volksbank Solling Hardegsen', '26261693', 'GENODEF1HDG'],
    ['Deutsche Bank', '26271424', 'DEUTDEDB262'],
    ['Deutsche Bank', '26271471', 'DEUTDE2H262'],
    ['Commerzbank Northeim Han', '26280020', 'DRESDEFF261'],
    ['Commerzbank Einbeck', '26281420', 'DRESDEFF262'],
    ['Commerzbank Osterode Harz', '26340056', 'COBADEFFXXX'],
    ['Commerzbank Herzberg Harz', '26341072', 'COBADEFFXXX'],
    ['St Spk Osterode -alt-', '26350001', 'NOLADE21OHA'],
    ['Sparkasse Osterode am Harz', '26351015', 'NOLADE21HZB'],
    ['St Spk Bad Sachsa -alt-', '26351445', 'NOLADE21SAC'],
    ['BBk Osnabrück', '26500000', 'MARKDEF1265'],
    ['Oldb Landesbank Osnabrück', '26520017', 'OLBODEH2XXX'],
    ['Oldb Landesbank Bramsche', '26521703', 'OLBODEH2XXX'],
    ['Oldb Landesbank Quakenbrück', '26522319', 'OLBODEH2XXX'],
    ['Commerzbank Osnabrück', '26540070', 'COBADEFFXXX'],
    ['Sparkasse Osnabrück', '26550105', 'NOLADE22XXX'],
    ['Kr Spk Bersenbrück', '26551540', 'NOLADE21BEB'],
    ['Kreissparkasse Melle', '26552286', 'NOLADE21MEL'],
    ['VB Laer-Borgl-Hilter-Melle', '26562490', 'GENODEF1HTR'],
    ['VB Bramgau-Wittlage -alt-', '26563960', 'GENODEF1WHO'],
    ['VB GMHütte-Hagen-Bissendorf', '26565928', 'GENODEF1HGM'],
    ['VB Osnabrücker Nd -alt-', '26566939', 'GENODEF1MRZ'],
    ['VR-Bank Osnabrücker Nordl.', '26567943', 'GENODEF1NOP'],
    ['Deutsche Bank', '26570024', 'DEUTDEDB265'],
    ['Deutsche Bank', '26570024', 'DEUTDEDB266'],
    ['Deutsche Bank', '26570024', 'DEUTDEDB921'],
    ['Deutsche Bank', '26570024', 'DEUTDEDB922'],
    ['Deutsche Bank', '26570024', 'DEUTDEDB923'],
    ['Deutsche Bank', '26570024', 'DEUTDEDB924'],
    ['Deutsche Bank', '26570024', 'DEUTDEDB925'],
    ['Deutsche Bank', '26570090', 'DEUTDE3B265'],
    ['Deutsche Bank', '26570090', 'DEUTDE3B266'],
    ['Deutsche Bank', '26570090', 'DEUTDE3B268'],
    ['Deutsche Bank', '26570090', 'DEUTDE3B269'],
    ['Deutsche Bank', '26570090', 'DEUTDE3B270'],
    ['Deutsche Bank', '26570090', 'DEUTDE3B271'],
    ['Deutsche Bank', '26570090', 'DEUTDE3B272'],
    ['Commerzbank Osnabrück', '26580070', 'DRESDEFF265'],
    ['Commerzbk ITGK Osnabrück', '26589210', 'DRESDEFFI10'],
    ['Vereinigte Volksbank', '26590025', 'GENODEF1OSV'],
    ['Oldb Landesbank Lingen', '26620010', 'OLBODEH2XXX'],
    ['Oldb Landesbank Meppen', '26621413', 'OLBODEH2XXX'],
    ['Commerzbank Lingen Ems', '26640049', 'COBADEFFXXX'],
    ['Spk Emsland', '26650001', 'NOLADE21EMS'],
    ['Emsländische Volksbank', '26660060', 'GENODEF1LIG'],
    ['Volksbank Haselünne', '26661380', 'GENODEF1HLN'],
    ['Emsländische VB Meppen-alt-', '26661494', 'GENODEF1MEP'],
    ['Volksbank Lengerich', '26662932', 'GENODEF1LEN'],
    ['Volksbank Haren', '26691213', 'GENODEF1HAR'],
    ['Oldb Landesbank Nordhorn', '26720028', 'OLBODEH2XXX'],
    ['Commerzbank Nordhorn', '26740044', 'COBADEFFXXX'],
    ['Kr Spk Nordhorn', '26750001', 'NOLADE21NOH'],
    ['Raiff- u VB Nordhorn -alt-', '26760005', 'GENODEF1NDH'],
    ['Deutsche Bank', '26770024', 'DEUTDEDB267'],
    ['Deutsche Bank', '26770024', 'DEUTDEDB926'],
    ['Deutsche Bank', '26770024', 'DEUTDEDB927'],
    ['Deutsche Bank', '26770024', 'DEUTDEDB928'],
    ['Deutsche Bank', '26770095', 'DEUTDE3B267'],
    ['Deutsche Bank', '26770095', 'DEUTDE3B273'],
    ['Deutsche Bank', '26770095', 'DEUTDE3B274'],
    ['Deutsche Bank', '26770095', 'DEUTDE3B275'],
    ['Commerzbank Goslar', '26840032', 'COBADEFFXXX'],
    ['Sparkasse Goslar/Harz -alt-', '26850001', 'NOLADE21GSL'],
    ['Kr Spk Clausthal-Zell.-alt-', '26851410', 'NOLADE21CLZ'],
    ['Sparkasse Salzgitter -alt-', '26851620', 'NOLADE21SZG'],
    ['Deutsche Bank', '26870024', 'DEUTDEDB268'],
    ['Deutsche Bank', '26870024', 'DEUTDEDB929'],
    ['Deutsche Bank', '26870024', 'DEUTDEDB934'],
    ['Deutsche Bank', '26870032', 'DEUTDE2H268'],
    ['Deutsche Bank', '26870032', 'DEUTDE2H280'],
    ['Deutsche Bank', '26870032', 'DEUTDE2H285'],
    ['Commerzbank Goslar', '26880063', 'DRESDEFF268'],
    ['Volksbank Nordharz', '26890019', 'GENODEF1VNH'],
    ['Volksbank im Harz', '26891484', 'GENODEF1OHA'],
    ['Commerzbank Wolfsburg', '26941053', 'COBADEFFXXX'],
    ['Spk Celle-Gifhorn-Wolfsburg', '26951311', 'NOLADE21GFW'],
    ['Deutsche Bank', '26971024', 'DEUTDEDB269'],
    ['Deutsche Bank', '26971038', 'DEUTDE2H269'],
    ['Commerzbank Wolfsburg', '26981062', 'DRESDEFF269'],
    ['Commerzbk ITGK Wolfsburg', '26989221', 'DRESDEFFI11'],
    ['Volksbank BRAWO', '26991066', 'GENODEF1WOB'],
    ['Volkswagen Bank Braunschwg', '27020000', 'VOWADE2BXXX'],
    ['Audi Bank Braunschweig', '27020001', 'AUDFDE21XXX'],
    ['Skoda Bank', '27020003', 'SKODDE21XXX'],
    ['AutoEuropa Bank', '27020004', 'ECBKDE21XXX'],
    ['Seat Bank Braunschweig', '27020800', 'SEATDE21XXX'],
    ['Seeligerbank Wolfenbüttel', '27032500', 'BCLSDE21XXX'],
    ['Commerzbank Braunschweig', '27040080', 'COBADEFFXXX'],
    ['Volksbank Börßum-Hornburg', '27062290', 'GENODEF1BOH'],
    ['Deutsche Bank', '27070024', 'DEUTDEDB270'],
    ['Deutsche Bank', '27070024', 'DEUTDEDB271'],
    ['Deutsche Bank', '27070024', 'DEUTDEDB272'],
    ['Deutsche Bank', '27070024', 'DEUTDEDB273'],
    ['Deutsche Bank', '27070024', 'DEUTDEDB274'],
    ['Deutsche Bank', '27070024', 'DEUTDEDB275'],
    ['Deutsche Bank', '27070024', 'DEUTDEDB278'],
    ['Deutsche Bank', '27070024', 'DEUTDEDB279'],
    ['Deutsche Bank', '27070024', 'DEUTDEDB930'],
    ['Deutsche Bank', '27070030', 'DEUTDE2H270'],
    ['Deutsche Bank', '27070031', 'DEUTDE2H271'],
    ['Deutsche Bank', '27070034', 'DEUTDE2H274'],
    ['Deutsche Bank', '27070034', 'DEUTDE2H278'],
    ['Deutsche Bank', '27070034', 'DEUTDE2H281'],
    ['Deutsche Bank', '27070041', 'DEUTDE2H279'],
    ['Deutsche Bank', '27070042', 'DEUTDE2H272'],
    ['Deutsche Bank', '27070043', 'DEUTDE2H273'],
    ['Deutsche Bank', '27070079', 'DEUTDE2H275'],
    ['Postbank Ndl Deutsche Bank', '27070324', 'DEUTDEDBP23'],
    ['Postbank Ndl Deutsche Bank', '27070369', 'DEUTDE2HP23'],
    ['Deutsche Bank', '27072524', 'DEUTDEDB277'],
    ['Deutsche Bank', '27072537', 'DEUTDE2H277'],
    ['Deutsche Bank', '27072724', 'DEUTDEDB276'],
    ['Deutsche Bank', '27072736', 'DEUTDE2H276'],
    ['Commerzbank Braunschweig', '27080060', 'DRESDEFF270'],
    ['Commerzbk ITGK Braunschwg', '27089221', 'DRESDEFFI12'],
    ['PSD Bank Braunschweig', '27090900', 'GENODEF1P02'],
    ['Volksbank', '27092555', 'GENODEF1WFV'],
    ['Rautenschlein Schöningen', '27131300', 'GENODEF1RTS'],
    ['Commerzbank Holzminden', '27240004', 'COBADEFFXXX'],
    ['VB Weserbergland Holz.-alt-', '27290087', 'GENODEF1HMV'],
    ['Volksbank Braunlage', '27893359', 'GENODEF1BLG'],
    ['Volksbank Seesen', '27893760', 'GENODEF1SES'],
    ['BBk Oldenburg', '28000000', 'MARKDEF1280'],
    ['Oldb Landesbank Oldenburg', '28020050', 'OLBODEH2XXX'],
    ['Oldb Landesbank Brake', '28021002', 'OLBODEH2XXX'],
    ['Oldb Ldbank Bad Zwischenahn', '28021301', 'OLBODEH2XXX'],
    ['Oldb Landesbank Cloppenburg', '28021504', 'OLBODEH2XXX'],
    ['Oldb Landesbank Damme', '28021623', 'OLBODEH2XXX'],
    ['Oldb Landesbank Delmenhorst', '28021705', 'OLBODEH2XXX'],
    ['Oldb Landesbank Elsfleth', '28021906', 'OLBODEH2XXX'],
    ['Oldb Landesbank Nordenham', '28022015', 'OLBODEH2XXX'],
    ['Oldb Landesbank Löningen', '28022412', 'OLBODEH2XXX'],
    ['Oldb Landesbank Lohne', '28022511', 'OLBODEH2XXX'],
    ['Oldb Landesbank Rastede', '28022620', 'OLBODEH2XXX'],
    ['Oldb Landesbank Vechta', '28022822', 'OLBODEH2XXX'],
    ['Oldb Landesbank Westerstede', '28023224', 'OLBODEH2XXX'],
    ['Oldb Ldbank Wildeshausen', '28023325', 'OLBODEH2XXX'],
    ['OLB, Oldenburg', '28030300', 'FORTDEH4XXX'],
    ['Commerzbank Oldenburg', '28040046', 'COBADEFFXXX'],
    ['Commerzbank Vechta', '28042865', 'COBADEFFXXX'],
    ['Landessparkasse Oldenburg', '28050100', 'SLZODE22XXX'],
    ['Raiffbk Oldenburg -alt-', '28060228', 'GENODEF1OL2'],
    ['Raiffbk Wesermarsch-Süd', '28061410', 'GENODEF1BRN'],
    ['VR-Bank Südoldenburg', '28061501', 'GENODEF1CLP'],
    ['Volksbank Dammer Berge', '28061679', 'GENODEF1DAM'],
    ['Oldenburger Volksbank', '28061822', 'GENODEF1EDE'],
    ['Raiffeisenbank Rastede', '28062165', 'GENODEF1RSE'],
    ['Vereinigte Volksbank', '28062249', 'GENODEF1HUD'],
    ['Vereinigte Volksbank GAA', '28062299', 'GENODEF1HUD'],
    ['VB Lohne-Dinkl-Steinf-Mühl', '28062560', 'GENODEF1LON'],
    ['VB Bookholzberg-Lemwer.-alt', '28062740', 'GENODEF1GBH'],
    ['Volksbank Bösel -alt-', '28062913', 'GENODEF1BSL'],
    ['Volksbank Westerstede', '28063253', 'GENODEF1WRE'],
    ['VB Essen-Cappeln', '28063526', 'GENODEF1ESO'],
    ['Volksbank Bakum -alt-', '28063607', 'GENODEF1BAM'],
    ['Volksbank Vechta', '28064179', 'GENODEF1VEC'],
    ['Raiff-VB Varel-Nordenham', '28064241', 'GENODEF1NHE'],
    ['Volksbank Löningen', '28065061', 'GENODEF1LOG'],
    ['VR-Bank Dinkl.-Steinf.-alt-', '28065108', 'GENODEF1DIK'],
    ['Raiffeisenbank Scharrel', '28065286', 'GENODEF1SAN'],
    ['Volksbank Visbek', '28066103', 'GENODEF1VIS'],
    ['VB Oldenb.-Land Delmenhorst', '28066214', 'GENODEF1WDH'],
    ['Volksbank', '28066620', 'GENODEF1FOY'],
    ['VB Neuenkirchen-Vörden-alt-', '28067068', 'GENODEF1NEO'],
    ['VB Delmenhorst -alt-', '28067170', 'GENODEF1GSC'],
    ['Volksbank Lastrup', '28067257', 'GENODEF1LAP'],
    ['Raiffbk Butjadingen-Abbehsn', '28068218', 'GENODEF1BUT'],
    ['Raiffbk Strückl.-Idafehn', '28069052', 'GENODEF1ORF'],
    ['VR Bank Oldenburg Land -alt', '28069092', 'GENODEF1HAT'],
    ['Volksbank Emstek', '28069109', 'GENODEF1EMK'],
    ['Raiffeisenbank Garrel -alt-', '28069128', 'GENODEF1GRR'],
    ['VR Bank Oldenburg Land -alt', '28069138', 'GENODEF1VAG'],
    ['VB Obergrafschaft -alt-', '28069293', 'GENODEF1BBH'],
    ['Hümmlinger Volksbank', '28069381', 'GENODEF1WLT'],
    ['Volksbank Nordhümmling', '28069706', 'GENODEF1BOG'],
    ['Raiffeisenbank Oldersum', '28069755', 'GENODEF1MLO'],
    ['Raiffbk Wiesederm-Wiesede-M', '28069773', 'GENODEF1WWM'],
    ['Raiffeisenbank Ems-Vechte', '28069878', 'GENODEF1KBL'],
    ['VB Niedergrafschaft', '28069926', 'GENODEF1HOO'],
    ['Raiffeisenbank Lorup', '28069935', 'GENODEF1LRU'],
    ['Grafschafter Volksbank', '28069956', 'GENODEF1NEV'],
    ['Volksbank Emstal', '28069991', 'GENODEF1LTH'],
    ['Volksbank Süd-Emsland', '28069994', 'GENODEF1SPL'],
    ['Deutsche Bank', '28070024', 'DEUTDEDB280'],
    ['Deutsche Bank', '28070024', 'DEUTDEDB281'],
    ['Deutsche Bank', '28070057', 'DEUTDEHB280'],
    ['Deutsche Bank', '28070057', 'DEUTDEHB281'],
    ['Oldb Ldbank Wilhelmshaven', '28220026', 'OLBODEH2XXX'],
    ['Oldb Landesbank Jever', '28222208', 'OLBODEH2XXX'],
    ['Oldb Landesbank Varel', '28222621', 'OLBODEH2XXX'],
    ['Commerzbank Wilhelmshaven', '28240023', 'COBADEFFXXX'],
    ['Sparkasse Wilhelmshaven', '28250110', 'BRLADE21WHV'],
    ['Kr Spk Wittmund', '28252760', 'BRLADE21WTM'],
    ['Volksbank Jever', '28262254', 'GENODEF1JEV'],
    ['RVB Varel-Nordenham', '28262673', 'GENODEF1VAR'],
    ['Deutsche Bank', '28270024', 'DEUTDEDB282'],
    ['Deutsche Bank', '28270024', 'DEUTDEDB283'],
    ['Deutsche Bank', '28270056', 'DEUTDEHB282'],
    ['Deutsche Bank', '28270056', 'DEUTDEHB283'],
    ['Commerzbank Wilhelmshaven', '28280012', 'DRESDEFF282'],
    ['Volksbank Wilhelmshaven', '28290063', 'GENODEF1WHV'],
    ['VB Esens', '28291551', 'GENODEF1ESE'],
    ['Oldb Landesbank Norden', '28320014', 'OLBODEH2XXX'],
    ['Oldb Landesbank Norderney', '28321816', 'OLBODEH2XXX'],
    ['Spk Aurich-Norden', '28350000', 'BRLADE21ANO'],
    ['Raiff-VB Fresena', '28361592', 'GENODEF1MAR'],
    ['Oldb Landesbank Emden', '28420007', 'OLBODEH2XXX'],
    ['Oldb Landesbank Aurich', '28421030', 'OLBODEH2XXX'],
    ['Commerzbank Emden', '28440037', 'COBADEFFXXX'],
    ['Sparkasse Emden', '28450000', 'BRLADE21EMD'],
    ['Deutsche Bank', '28470024', 'DEUTDEDB284'],
    ['Deutsche Bank', '28470024', 'DEUTDEDB286'],
    ['Deutsche Bank', '28470024', 'DEUTDEDB289'],
    ['Deutsche Bank', '28470024', 'DEUTDEDB298'],
    ['Deutsche Bank', '28470091', 'DEUTDEHB284'],
    ['Deutsche Bank', '28470091', 'DEUTDEHB286'],
    ['Deutsche Bank', '28470091', 'DEUTDEHB289'],
    ['Deutsche Bank', '28470091', 'DEUTDEHB298'],
    ['Oldb Landesbank Leer', '28520009', 'OLBODEH2XXX'],
    ['Oldb Landesbank Papenburg', '28521518', 'OLBODEH2XXX'],
    ['Commerzbank Leer Ostfriesld', '28540034', 'COBADEFFXXX'],
    ['Sparkasse LeerWittmund', '28550000', 'BRLADE21LER'],
    ['RVB Aurich', '28562297', 'GENODEF1UPL'],
    ['Raiffbk Flachsmeer Westover', '28562716', 'GENODEF1WEF'],
    ['Raiffeisenbank Moormerland', '28562863', 'GENODEF1HTL'],
    ['Raiffeisenbank Moormerland', '28563749', 'GENODEF1MML'],
    ['Deutsche Bank', '28570024', 'DEUTDEDB285'],
    ['Deutsche Bank', '28570024', 'DEUTDEDB287'],
    ['Deutsche Bank', '28570024', 'DEUTDEDB288'],
    ['Deutsche Bank', '28570092', 'DEUTDEHB285'],
    ['Deutsche Bank', '28570092', 'DEUTDEHB287'],
    ['Deutsche Bank', '28570092', 'DEUTDEHB288'],
    ['Ostfriesische VB Leer', '28590075', 'GENODEF1LER'],
    ['Volksbank Papenburg', '28591579', 'GENODEF1PAP'],
    ['Volksbank Westrhauderfehn', '28591654', 'GENODEF1WRH'],
    ['BBk Hannover eh Bremen', '29000000', 'MARKDEF1290'],
    ['Bankhaus Neelmeyer Bremen', '29020000', 'NEELDE22XXX'],
    ['Greensill Bank Bremen', '29020200', 'NFHBDE21XXX'],
    ['M.M. Warburg (Plump)', '29030400', 'PLUMDE29XXX'],
    ['Commerzbank CC Bremen', '29040060', 'COBADEFFXXX'],
    ['Commerzbank CC Bremen', '29040061', 'COBADEFFXXX'],
    ['Commerzbank Bremen', '29040090', 'COBADEFFXXX'],
    ['Nord LB Bremen', '29050000', 'BRLADE22XXX'],
    ['Nord LB Oldenburg', '29050000', 'BRLADE22OLD'],
    ['Spk Bremen', '29050101', 'SBREDE22XXX'],
    ['Deutsche Bank', '29070024', 'DEUTDEDB290'],
    ['Deutsche Bank', '29070024', 'DEUTDEDB292'],
    ['Deutsche Bank', '29070024', 'DEUTDEDB293'],
    ['Deutsche Bank', '29070024', 'DEUTDEDB294'],
    ['Deutsche Bank', '29070024', 'DEUTDEDB295'],
    ['Deutsche Bank', '29070024', 'DEUTDEDB296'],
    ['Deutsche Bank', '29070024', 'DEUTDEDB297'],
    ['Deutsche Bank', '29070024', 'DEUTDEDBBRE'],
    ['Deutsche Bank', '29070050', 'DEUTDEHBXXX'],
    ['Deutsche Bank', '29070050', 'DEUTDEHB295'],
    ['Deutsche Bank', '29070050', 'DEUTDEHB297'],
    ['Deutsche Bank', '29070051', 'DEUTDEHB292'],
    ['Deutsche Bank', '29070052', 'DEUTDEHB294'],
    ['Deutsche Bank', '29070058', 'DEUTDEHB293'],
    ['Deutsche Bank', '29070059', 'DEUTDEHB290'],
    ['Deutsche Bank', '29070059', 'DEUTDEHB296'],
    ['Postbank Ndl Deutsche Bank', '29070324', 'DEUTDEDBP21'],
    ['Postbank Ndl Deutsche Bank', '29070367', 'DEUTDEHBP21'],
    ['Commerzbank Bremen', '29080010', 'DRESDEFF290'],
    ['Commerzbank ITGK Bremen', '29089210', 'DRESDEFFI13'],
    ['PSD Bank Nord Bremen', '29090900', 'GENODEF1P03'],
    ['Oldb Landesbank Syke', '29121731', 'OLBODEH2XXX'],
    ['Kreissparkasse Syke', '29151700', 'BRLADE21SYK'],
    ['Kr Spk Osterholz -alt-', '29152300', 'BRLADE21OHZ'],
    ['Spk Scheeßel', '29152550', 'BRLADE21SHL'],
    ['Kr Spk Verden', '29152670', 'BRLADE21VER'],
    ['Volksbank', '29162394', 'GENODEF1OHZ'],
    ['Volksbank Schwanewede', '29162453', 'GENODEF1SWW'],
    ['Volksbank Aller-Weser -alt-', '29162697', 'GENODEF1VER'],
    ['Volksbank Oyten', '29165545', 'GENODEF1OYT'],
    ['Volksbank Sottrum', '29165681', 'GENODEF1SUM'],
    ['Volksbank Worpswede', '29166568', 'GENODEF1WOP'],
    ['Volksbank Syke', '29167624', 'GENODEF1SHR'],
    ['Deutsche Bank', '29172624', 'DEUTDEDB291'],
    ['Deutsche Bank', '29172655', 'DEUTDEHB291'],
    ['Bremische Volksbank', '29190024', 'GENODEF1HB1'],
    ['Volksbank Bremen-Nord', '29190330', 'GENODEF1HB2'],
    ['Volksbank Bremen-Nord', '29190399', 'GENODEF1HB2'],
    ['Commerzbank Bremerhaven', '29240024', 'COBADEFFXXX'],
    ['Weser-Elbe Sparkasse, BHV', '29250000', 'BRLADE21BRS'],
    ['Kr Spk Wesermünde-Hadeln', '29250150', 'BRLADE21BRK'],
    ['Volksbank Geeste-Nord', '29262722', 'GENODEF1BRV'],
    ['VB im Elbe-Weser-Dreieck', '29265747', 'GENODEF1BEV'],
    ['Commerzbank Bremerhaven', '29280011', 'DRESDEFF292'],
    ['VB Bremerh-Wesermünde -alt-', '29290034', 'GENODEF1HBV'],
    ['BBk eh Düsseldorf', '30000000', 'MARKDEF1300'],
    ['IKB Düsseldorf', '30010400', 'IKBDDEDDXXX'],
    ['IKB Privatkunden Düsseldorf', '30010444', 'IKBDDEDDDIR'],
    ['MUFG Bank', '30010700', 'BOTKDEDXXXX'],
    ['VIVA Payments', '30018800', 'VPAYDE32XXX'],
    ['BHF-BANK Düsseldorf', '30020500', 'BHFBDEFF300'],
    ['Mizuho Bank Düsseldorf', '30020700', 'MHCBDEDDXXX'],
    ['TARGOBANK Düsseldorf', '30020900', 'CMCIDEDDXXX'],
    ['NRW.BANK Düsseldorf', '30022000', 'NRWBDEDMXXX'],
    ['S Broker Wiesbaden', '30030100', 'PULSDEDDXXX'],
    ['Bank11direkt Neuss', '30030500', 'CUABDED1XXX'],
    ['ETRIS Bank Wuppertal', '30030600', 'ETRIDE31XXX'],
    ['HSBC Germany, Düsseldorf', '30030880', 'TUBDDEDDXXX'],
    ['HSBC Germany, Düsseldorf', '30030889', 'TUBDDEDDXXX'],
    ['Merck Finck', '30030900', 'MEFIDEMM300'],
    ['Commerzbank Düsseldorf', '30040000', 'COBADEDDXXX'],
    ['Commerzbank Fil. Düsseld 2', '30040005', 'COBADEDDXXX'],
    ['Commerzbank DDF GF-D48', '30040048', 'COBADEFFXXX'],
    ['Commerzbank Gf 660 Düsseldf', '30040060', 'COBADEFFXXX'],
    ['Commerzbank Gf 661 Düsseldf', '30040061', 'COBADEFFXXX'],
    ['Commerzbank CC Düsseldorf', '30040062', 'COBADEFFXXX'],
    ['Commerzbank CC Düsseldorf', '30040063', 'COBADEFFXXX'],
    ['Commerzbank Gf Comp.Center', '30040099', 'COBADEFFSTS'],
    ['Ld Bk Hess-Thür, Gz, Dus', '30050000', 'WELADEDDXXX'],
    ['St Spk Düsseldorf', '30050110', 'DUSSDEDDXXX'],
    ['DZ BANK Düsseldorf', '30060010', 'GENODEDDXXX'],
    ['apoBank Düsseldorf', '30060601', 'DAAEDEDDXXX'],
    ['PSD Bank Rhein-Ruhr', '30060992', 'GENODEF1P05'],
    ['Deutsche Bank', '30070010', 'DEUTDEDDXXX'],
    ['Deutsche Bank', '30070010', 'DEUTDEDD300'],
    ['Deutsche Bank', '30070010', 'DEUTDEDD301'],
    ['Deutsche Bank', '30070010', 'DEUTDEDD302'],
    ['Deutsche Bank', '30070010', 'DEUTDEDD303'],
    ['Deutsche Bank', '30070010', 'DEUTDEDD304'],
    ['Deutsche Bank', '30070010', 'DEUTDEDD305'],
    ['Deutsche Bank', '30070010', 'DEUTDEDD306'],
    ['Deutsche Bank', '30070010', 'DEUTDEDD307'],
    ['Deutsche Bank', '30070024', 'DEUTDEDB300'],
    ['Deutsche Bank', '30070024', 'DEUTDEDB301'],
    ['Deutsche Bank', '30070024', 'DEUTDEDB302'],
    ['Deutsche Bank', '30070024', 'DEUTDEDB303'],
    ['Deutsche Bank', '30070024', 'DEUTDEDB304'],
    ['Deutsche Bank', '30070024', 'DEUTDEDB305'],
    ['Deutsche Bank', '30070024', 'DEUTDEDB306'],
    ['Deutsche Bank', '30070024', 'DEUTDEDB307'],
    ['Deutsche Bank', '30070024', 'DEUTDEDBDUE'],
    ['Postbank Ndl Deutsche Bank', '30070207', 'DEUTDEDDP06'],
    ['Postbank Ndl Deutsche Bank', '30070224', 'DEUTDEDBP06'],
    ['Commerzbank Düsseldorf', '30080000', 'DRESDEFF300'],
    ['Commerzbank Düsseldorf 05', '30080005', 'DRESDEFFXXX'],
    ['Commerzbank Düsseldorf 22', '30080022', 'DRESDEFFI28'],
    ['Commerzbank Düsseldorf 38', '30080038', 'DRESDEFFXXX'],
    ['Commerzbank Düsseldorf 41', '30080041', 'DRESDEFFI29'],
    ['Commerzbank Düsseldorf 53', '30080053', 'DRESDEFFI30'],
    ['Commerzbk Zw55 Düsseldorf', '30080055', 'DRESDEFF309'],
    ['Commerzbk ZW57 Düsseldorf', '30080057', 'DRESDEFF316'],
    ['Commerzbank Düsseldorf 61', '30080061', 'DRESDEFFI31'],
    ['Commerzbank Düsseldorf 74', '30080074', 'DRESDEFFI32'],
    ['Commerzbank ITGK3 Ddrf', '30080080', 'DRESDEFFI76'],
    ['Commerzbank ITGK4 Ddrf', '30080081', 'DRESDEFFI77'],
    ['Commerzbank ITGK5 Ddrf', '30080082', 'DRESDEFFI78'],
    ['Commerzbank ITGK6 Ddrf', '30080083', 'DRESDEFFI79'],
    ['Commerzbank ITGK7 Ddrf', '30080084', 'DRESDEFFI80'],
    ['Commerzbank ITGK8 Ddrf', '30080085', 'DRESDEFFI81'],
    ['Commerzbank ITGK9 Ddrf', '30080086', 'DRESDEFFI82'],
    ['Commerzbank ITGK10 Ddrf', '30080087', 'DRESDEFFI83'],
    ['Commerzbank ITGK11 Ddrf', '30080088', 'DRESDEFFI84'],
    ['Commerzbank ITGK12 Ddrf', '30080089', 'DRESDEFFI85'],
    ['Commerzbank Düsseldorf 95', '30080095', 'DRESDEFFI33'],
    ['Commerzbk ITGK I Düsseldf', '30089300', 'DRESDEFFI02'],
    ['Commerzbk ITGK II Düsseld', '30089302', 'DRESDEFFI03'],
    ['SMBC Düsseldorf', '30110300', 'SMBCDEDDXXX'],
    ['DHB Bank, Düsseldorf', '30130100', 'DHBNDEDDXXX'],
    ['GarantiBank Int Düsseldorf', '30130200', 'UGBIDEDDXXX'],
    ['Isbank Düsseldorf', '30130600', 'ISBKDEFXDUS'],
    ['Kr Spk Düsseldorf', '30150200', 'WELADED1KSD'],
    ['VB Düsseldorf Neuss', '30160213', 'GENODED1DNE'],
    ['VB Düsseldorf Neuss', '30160266', 'GENODED1DNE'],
    ['UniCredit Bank-HypoVereinbk', '30220190', 'HYVEDEMM414'],
    ['FTX Bank, Frankfurt', '30330800', 'BIWBDE33303'],
    ['St Spk Haan', '30351220', 'WELADED1HAA'],
    ['RCI Banque Ndl Deutschland', '30520000', 'RCIDDE3NXXX'],
    ['RCI Banque Direkt', '30520037', 'RCIDDE3NPAY'],
    ['KBC Bank Düsseldorf', '30524400', 'KREDDEDDXXX'],
    ['Bankhaus Werhahn', '30530000', 'WERHDED1XXX'],
    ['Bank11 Neuss', '30530500', 'WEFZDED1XXX'],
    ['Sparkasse Neuss', '30550000', 'WELADEDNXXX'],
    ['St Spk Kaarst Büttgen -alt-', '30551240', 'WELADED1KST'],
    ['Volksbank Neuss -alt-', '30560090', 'GENODED1NSS'],
    ['VR Bank Monheim am Rhein', '30560548', 'GENODED1NLD'],
    ['VR Bank Gf GAA', '30560591', 'GENODED1NLD'],
    ['Santander Consumer Bank MG', '31010833', 'SCFBDE33XXX'],
    ['Commerzbank Mönchengladbach', '31040015', 'COBADEFFXXX'],
    ['Commerzbank CC Mgladbach', '31040060', 'COBADEFFXXX'],
    ['Commerzbank CC Mgladbach', '31040061', 'COBADEFFXXX'],
    ['St Spk Mönchengladbach', '31050000', 'MGLSDE33XXX'],
    ['Gladbacher Bank von 1922', '31060181', 'GENODED1GBM'],
    ['VB Mönchengladbach', '31060517', 'GENODED1MRB'],
    ['Volksbank Brüggen-Nettetal', '31062154', 'GENODED1KBN'],
    ['Volksbank Schwalmtal', '31062553', 'GENODED1NKR'],
    ['Deutsche Bank', '31070001', 'DEUTDEDD310'],
    ['Deutsche Bank', '31070001', 'DEUTDEDD317'],
    ['Deutsche Bank', '31070001', 'DEUTDEDD318'],
    ['Deutsche Bank', '31070001', 'DEUTDEDD319'],
    ['Deutsche Bank', '31070024', 'DEUTDEDB310'],
    ['Deutsche Bank', '31070024', 'DEUTDEDB317'],
    ['Deutsche Bank', '31070024', 'DEUTDEDB318'],
    ['Deutsche Bank', '31070024', 'DEUTDEDB319'],
    ['Postbank Ndl Deutsche Bank', '31070206', 'DEUTDEDDP05'],
    ['Postbank Ndl Deutsche Bank', '31070224', 'DEUTDEDBP05'],
    ['Commerzbank Mönchengladb', '31080015', 'DRESDEFF310'],
    ['Commerzbank Mgladbach 61', '31080061', 'DRESDEFFI34'],
    ['Kr Spk Heinsberg Erkelenz', '31251220', 'WELADED1ERK'],
    ['Volksbank Erkelenz', '31261282', 'GENODED1EHE'],
    ['Raiffbk Erkelenz -alt-', '31263359', 'GENODED1LOE'],
    ['Volksbank Viersen', '31460290', 'GENODED1VSN'],
    ['Deutsche Bank', '31470004', 'DEUTDEDD314'],
    ['Deutsche Bank', '31470004', 'DEUTDEDD315'],
    ['Deutsche Bank', '31470004', 'DEUTDEDD316'],
    ['Deutsche Bank', '31470024', 'DEUTDEDB314'],
    ['Deutsche Bank', '31470024', 'DEUTDEDB315'],
    ['Deutsche Bank', '31470024', 'DEUTDEDB316'],
    ['Commerzbank Krefeld', '32040024', 'COBADEFFXXX'],
    ['Sparkasse Krefeld', '32050000', 'SPKRDE33XXX'],
    ['Sparkasse Straelen -alt-', '32051996', 'WELADED1STR'],
    ['Volksbank Krefeld', '32060362', 'GENODED1HTK'],
    ['Volksbank an der Niers', '32061384', 'GENODED1GDL'],
    ['Volksbank Kempen-Grefrath', '32061414', 'GENODED1KMP'],
    ['Deutsche Bank', '32070024', 'DEUTDEDB320'],
    ['Deutsche Bank', '32070024', 'DEUTDEDB321'],
    ['Deutsche Bank', '32070024', 'DEUTDEDB322'],
    ['Deutsche Bank', '32070024', 'DEUTDEDB323'],
    ['Deutsche Bank', '32070024', 'DEUTDEDB327'],
    ['Deutsche Bank', '32070024', 'DEUTDEDB328'],
    ['Deutsche Bank', '32070024', 'DEUTDEDB329'],
    ['Deutsche Bank', '32070024', 'DEUTDEDB936'],
    ['Deutsche Bank', '32070080', 'DEUTDEDD320'],
    ['Deutsche Bank', '32070080', 'DEUTDEDD321'],
    ['Deutsche Bank', '32070080', 'DEUTDEDD322'],
    ['Deutsche Bank', '32070080', 'DEUTDEDD323'],
    ['Deutsche Bank', '32070080', 'DEUTDEDD327'],
    ['Deutsche Bank', '32070080', 'DEUTDEDD328'],
    ['Deutsche Bank', '32070080', 'DEUTDEDD329'],
    ['Deutsche Bank', '32070080', 'DEUTDEDD331'],
    ['Commerzbank Krefeld', '32080010', 'DRESDEFF320'],
    ['Verb Spk Goch -alt-', '32250050', 'WELADED1GOC'],
    ['Commerzbank Kleve Niederrh', '32440023', 'COBADEFFXXX'],
    ['Sparkasse Rhein-Maas', '32450000', 'WELADED1KLE'],
    ['Volksbank Kleverland', '32460422', 'GENODED1KLL'],
    ['Deutsche Bank', '32470024', 'DEUTDEDB324'],
    ['Deutsche Bank', '32470024', 'DEUTDEDB325'],
    ['Deutsche Bank', '32470024', 'DEUTDEDB326'],
    ['Deutsche Bank', '32470077', 'DEUTDEDD324'],
    ['Deutsche Bank', '32470077', 'DEUTDEDD325'],
    ['Deutsche Bank', '32470077', 'DEUTDEDD326'],
    ['akf bank Wuppertal', '33020000', 'AKFBDE31XXX'],
    ['UniCredit Bank-HypoVereinbk', '33020190', 'HYVEDEMM809'],
    ['GEFA BANK Wuppertal', '33030000', 'GGABDE31XXX'],
    ['Commerzbank Wuppertal', '33040001', 'COBADEFFXXX'],
    ['Commerzbank Zw 117', '33040310', 'COBADEDHXXX'],
    ['St Spk Wuppertal', '33050000', 'WUPSDE33XXX'],
    ['Credit- u VB Wuppertal-alt-', '33060098', 'GENODED1CVW'],
    ['Sparda-Bank West', '33060592', 'GENODED1SPW'],
    ['Deutsche Bank', '33070024', 'DEUTDEDB330'],
    ['Deutsche Bank', '33070024', 'DEUTDEDB331'],
    ['Deutsche Bank', '33070024', 'DEUTDEDB332'],
    ['Deutsche Bank', '33070024', 'DEUTDEDB333'],
    ['Deutsche Bank', '33070024', 'DEUTDEDB334'],
    ['Deutsche Bank', '33070024', 'DEUTDEDB335'],
    ['Deutsche Bank', '33070024', 'DEUTDEDBWUP'],
    ['Deutsche Bank', '33070090', 'DEUTDEDWXXX'],
    ['Deutsche Bank', '33070090', 'DEUTDEDW330'],
    ['Deutsche Bank', '33070090', 'DEUTDEDW331'],
    ['Deutsche Bank', '33070090', 'DEUTDEDW332'],
    ['Deutsche Bank', '33070090', 'DEUTDEDW333'],
    ['Deutsche Bank', '33070090', 'DEUTDEDW334'],
    ['Deutsche Bank', '33070090', 'DEUTDEDW335'],
    ['Commerzbank ITGK1 Wuppertal', '33080001', 'DRESDEFFI86'],
    ['Commerzbank Wuppertal', '33080030', 'DRESDEFF332'],
    ['Commerzbank ITGK2 Wuppertal', '33080085', 'DRESDEFFI87'],
    ['Commerzbank ITGK3 Wuppertal', '33080086', 'DRESDEFFI88'],
    ['Commerzbank ITGK4 Wuppertal', '33080087', 'DRESDEFFI89'],
    ['Commerzbank ITGK5 Wuppertal', '33080088', 'DRESDEFFI90'],
    ['Commerzbank Velbert', '33440035', 'COBADEFFXXX'],
    ['Sparkasse HRV', '33450000', 'WELADED1VEL'],
    ['Spk Heiligenhaus -alt-', '33451220', 'WELADED1HGH'],
    ['Commerzbank Remscheid', '34040049', 'COBADEFFXXX'],
    ['St Spk Remscheid', '34050000', 'WELADEDRXXX'],
    ['Spk Radevormwald-Hücke.-alt', '34051350', 'WELADED1RVW'],
    ['St Spk Wermelskirchen', '34051570', 'WELADED1WMK'],
    ['Volksbank i.Bergischen Land', '34060094', 'VBRSDE33XXX'],
    ['Volksbank i.Bergischen Land', '34060094', 'VBRSDE33305'],
    ['Volksbank i.Bergischen Land', '34060094', 'VBRSDE33330'],
    ['Volksbank i.Bergischen Land', '34060094', 'VBRSDE33341'],
    ['Volksbank i.Bergischen Land', '34060094', 'VBRSDE33342'],
    ['Volksbank i.Bergischen Land', '34060094', 'VBRSDE33343'],
    ['Volksbank i.Bergischen Land', '34060094', 'VBRSDE33345'],
    ['Volksbank i.Bergischen Land', '34060094', 'VBRSDE33346'],
    ['Volksbank i.Bergischen Land', '34060094', 'VBRSDE33347'],
    ['Deutsche Bank', '34070024', 'DEUTDEDB340'],
    ['Deutsche Bank', '34070024', 'DEUTDEDB341'],
    ['Deutsche Bank', '34070024', 'DEUTDEDB344'],
    ['Deutsche Bank', '34070024', 'DEUTDEDB345'],
    ['Deutsche Bank', '34070024', 'DEUTDEDB346'],
    ['Deutsche Bank', '34070093', 'DEUTDEDW340'],
    ['Deutsche Bank', '34070093', 'DEUTDEDW341'],
    ['Deutsche Bank', '34070093', 'DEUTDEDW344'],
    ['Deutsche Bank', '34070093', 'DEUTDEDW345'],
    ['Deutsche Bank', '34070093', 'DEUTDEDW346'],
    ['Commerzbank Remscheid', '34080031', 'DRESDEFF340'],
    ['Commerzbank Solingen', '34240050', 'COBADEFFXXX'],
    ['St Spk Solingen', '34250000', 'SOLSDE33XXX'],
    ['Deutsche Bank', '34270024', 'DEUTDEDB342'],
    ['Deutsche Bank', '34270024', 'DEUTDEDB343'],
    ['Deutsche Bank', '34270094', 'DEUTDEDW342'],
    ['Deutsche Bank', '34270094', 'DEUTDEDW343'],
    ['Commerzbank Solingen', '34280032', 'DRESDEFF342'],
    ['Commerzbank Duisburg', '35040038', 'COBADEFFXXX'],
    ['Commerzbank Gf WK, Duisburg', '35040085', 'COBADEFFXXX'],
    ['Spk Duisburg', '35050000', 'DUISDE33XXX'],
    ['Bank für Kirche u Diakonie', '35060190', 'GENODED1DKD'],
    ['KD-Bank (Gf GAA RB)Dortmund', '35060199', 'GENODED1DKD'],
    ['VB Rhein-Ruhr Duisburg', '35060386', 'GENODED1VRR'],
    ['Deutsche Bank', '35070024', 'DEUTDEDB350'],
    ['Deutsche Bank', '35070024', 'DEUTDEDB351'],
    ['Deutsche Bank', '35070024', 'DEUTDEDB352'],
    ['Deutsche Bank', '35070024', 'DEUTDEDB354'],
    ['Deutsche Bank', '35070024', 'DEUTDEDB356'],
    ['Deutsche Bank', '35070030', 'DEUTDEDE350'],
    ['Deutsche Bank', '35070030', 'DEUTDEDE351'],
    ['Deutsche Bank', '35070030', 'DEUTDEDE352'],
    ['Deutsche Bank', '35070030', 'DEUTDEDE354'],
    ['Deutsche Bank', '35070030', 'DEUTDEDE356'],
    ['Commerzbank Duisburg', '35080070', 'DRESDEFF350'],
    ['Commerzbank ITGK1 Duisburg', '35080085', 'DRESDEFFI91'],
    ['Commerzbank ITGK2 Duisburg', '35080086', 'DRESDEFFI92'],
    ['Commerzbank ITGK3 Duisburg', '35080087', 'DRESDEFFI93'],
    ['Commerzbank ITGK4 Duisburg', '35080088', 'DRESDEFFI94'],
    ['Commerzbank ITGK5 Duisburg', '35080089', 'DRESDEFFI95'],
    ['Bk f Schiffahrt Duisburg', '35090300', 'GENODEF1BSD'],
    ['Spk Dinslaken-Voerde-Hünxe', '35251000', 'WELADED1DIN'],
    ['Volksbank Dinslaken', '35261248', 'GENODED1DLK'],
    ['Sparkasse am Niederrhein', '35450000', 'WELADED1MOR'],
    ['Spk Neukirchen-Vluyn -alt-', '35451460', 'WELADED1NVL'],
    ['Spk Rheinberg -alt-', '35451775', 'WELADED1RHB'],
    ['Volksbank Niederrhein', '35461106', 'GENODED1NRH'],
    ['Commerzbank Wesel', '35640064', 'COBADEFFXXX'],
    ['NISPA', '35650000', 'WELADED1WES'],
    ['Volksbank Rhein-Lippe (GAA)', '35660501', 'GENODED1RLW'],
    ['Volksbank Rhein-Lippe', '35660599', 'GENODED1RLW'],
    ['St Spk Emmerich-Rees -alt-', '35850000', 'WELADED1EMR'],
    ['Volksbank Emmerich-Rees', '35860245', 'GENODED1EMR'],
    ['BBk eh Essen', '36000000', 'MARKDEF1360'],
    ['Postbank Ndl Deutsche Bank', '36010043', 'PBNKDEFFXXX'],
    ['Aareal Bank Essen', '36010424', 'AARBDE5W360'],
    ['National-Bank Essen', '36020030', 'NBAGDE3EXXX'],
    ['UniCredit Bank-HypoVereinbk', '36020186', 'HYVEDEMM360'],
    ['Santander Bank Essen', '36033300', 'SCFBDE33XXX'],
    ['Commerzbank Essen', '36040039', 'COBADEFFXXX'],
    ['Commerzbank CC Essen', '36040060', 'COBADEFFXXX'],
    ['Commerzbank CC Essen', '36040061', 'COBADEFFXXX'],
    ['Commerzbank Gf WK, Essen', '36040085', 'COBADEFFXXX'],
    ['Sparkasse Essen', '36050105', 'SPESDE3EXXX'],
    ['Pax-Bank Essen', '36060192', 'GENODED1PA2'],
    ['Bank im Bistum Essen', '36060295', 'GENODED1BBE'],
    ['GENO BANK ESSEN', '36060488', 'GENODEM1GBE'],
    ['Sparda-Bank West', '36060591', 'GENODED1SPE'],
    ['Deutsche Bank', '36070024', 'DEUTDEDBESS'],
    ['Deutsche Bank', '36070050', 'DEUTDEDEXXX'],
    ['Postbank Ndl Deutsche Bank', '36070208', 'DEUTDEDEP07'],
    ['Postbank Ndl Deutsche Bank', '36070224', 'DEUTDEDBP07'],
    ['Commerzbank Essen', '36080080', 'DRESDEFF360'],
    ['Commerzbank ITGK2 Essen', '36080085', 'DRESDEFFI66'],
    ['Commerzbank ITGK Essen', '36089321', 'DRESDEFFI17'],
    ['Commerzbank Mülheim Ruhr', '36240045', 'COBADEFFXXX'],
    ['Spk Mülheim an der Ruhr', '36250000', 'SPMHDE3EXXX'],
    ['Deutsche Bank', '36270024', 'DEUTDEDB362'],
    ['Deutsche Bank', '36270048', 'DEUTDEDE362'],
    ['Commerzbank Mülheim Ruhr', '36280071', 'DRESDEFF362'],
    ['Commerzbank Oberhausen', '36540046', 'COBADEFFXXX'],
    ['St Spk Oberhausen', '36550000', 'WELADED1OBH'],
    ['Deutsche Bank', '36570024', 'DEUTDEDB365'],
    ['Deutsche Bank', '36570049', 'DEUTDEDE365'],
    ['Commerzbank Oberhausen', '36580072', 'DRESDEFF365'],
    ['BBk Köln', '37000000', 'MARKDEF1370'],
    ['Postbank Ndl Deutsche Bank', '37010050', 'PBNKDEFFXXX'],
    ['BNP Paribas Ndl Deutschland', '37010600', 'BNPADEFFXXX'],
    ['BNP Paribas Ndl Deutschland', '37010699', 'BNPADEFFXXX'],
    ['Postbank Ndl Deutsche Bank', '37011000', 'PBNKDEFFXXX'],
    ['bunq, Koeln', '37019000', 'BUNQDE82XXX'],
    ['UniCredit Bank-HypoVereinbk', '37020090', 'HYVEDEMM429'],
    ['Fondsdepot Bank', '37020200', 'AXABDE31XXX'],
    ['TOYOTA Kreditbank Köln', '37020400', 'TOBADE33XXX'],
    ['Bank für Sozialwirtschaft', '37020500', 'BFSWDE33XXX'],
    ['Santander Consumer Bank', '37020600', 'SCFBDE33XXX'],
    ['Ford Bank Köln', '37020900', 'FDBADE3KXXX'],
    ['Ford Bank, Köln', '37021500', 'FDBADE8FXXX'],
    ['Ford Bank (GF EG), Köln', '37021548', 'LRFSDE31XXX'],
    ['Santander Consumer Bank', '37027000', 'SCFBDE33XXX'],
    ['abcbank Köln', '37030700', 'WWBADE3AXXX'],
    ['Isbank Köln', '37030800', 'ISBKDEFXKOL'],
    ['Commerzbank CC SP, Köln', '37040037', 'COBADEFFXXX'],
    ['Commerzbank Köln', '37040044', 'COBADEFFXXX'],
    ['Commerzbank KOE GF-K48', '37040048', 'COBADEFFXXX'],
    ['Commerzbank CC Köln', '37040060', 'COBADEFFXXX'],
    ['Commerzbank CC Köln', '37040061', 'COBADEFFXXX'],
    ['Sparkasse KölnBonn', '37050198', 'COLSDE33XXX'],
    ['Kreissparkasse Köln', '37050299', 'COKSDE33XXX'],
    ['Pax-Bank Gf MHD', '37060120', 'GENODED1PA7'],
    ['Pax-Bank Köln', '37060193', 'GENODED1PAX'],
    ['Pax-Bank Köln', '37060194', 'GENODED1PAX'],
    ['Sparda-Bank West', '37060590', 'GENODED1SPK'],
    ['PSD Bank West', '37060993', 'GENODEF1P13'],
    ['Bensberger Bank', '37062124', 'GENODED1BGL'],
    ['VB Rhein-Erft-Köln', '37062365', 'GENODED1FHH'],
    ['VR Bank Berg.Gladbach-Lever', '37062600', 'GENODED1PAF'],
    ['RB Fischenich-Kende -alt-', '37063367', 'GENODED1FKH'],
    ['Spar-u Darlehnskasse -alt-', '37069101', 'GENODED1AEG'],
    ['Raiffeisenbank Aldenhoven', '37069103', 'GENODED1ALD'],
    ['Volksbank Berg', '37069125', 'GENODED1RKO'],
    ['Spar-u Darlehnskasse -alt-', '37069153', 'GENODED1HCK'],
    ['Volksbank Meerbusch -alt-', '37069164', 'GENODED1MBU'],
    ['Volksbank Erft Elsdorf', '37069252', 'GENODED1ERE'],
    ['Raiffbk Geilenkirchen -alt-', '37069302', 'GENODED1GLK'],
    ['Volksbank Gemünd-Kall -alt-', '37069303', 'GENODED1GKK'],
    ['Raiffbk Grevenbroich -alt-', '37069306', 'GENODED1GRB'],
    ['Raiffeisenbank Gymnich', '37069322', 'GENODED1EGY'],
    ['Volksbank Haaren', '37069330', 'GENODED1HAW'],
    ['Raiffeisenbank von 1895', '37069331', 'GENODED1KHO'],
    ['Volksbank Heimbach', '37069342', 'GENODED1HMB'],
    ['Raiffbk Selfkant -alt-', '37069354', 'GENODED1SEG'],
    ['Spar-u Darlehnskasse -alt-', '37069355', 'GENODED1AHO'],
    ['VR-Bank Rur-Wurm -alt-', '37069381', 'GENODED1IMM'],
    ['Raiffbk Junkersdorf -alt-', '37069401', 'GENODED1JUK'],
    ['Raiffeisenbank Kaarst', '37069405', 'GENODED1KAA'],
    ['Volksbank Heinsberg', '37069412', 'GENODED1HRB'],
    ['Volksbank Dünnwald-Holweide', '37069427', 'GENODED1DHK'],
    ['Volksbank Köln-Nord -alt-', '37069429', 'GENODED1KNL'],
    ['Raiffeisenbk Erftstadt-alt-', '37069472', 'GENODED1ERF'],
    ['VR-Bank Bonn Rhein-Sieg', '37069520', 'GENODED1RST'],
    ['Raiffbk Rhein-Berg -alt-', '37069521', 'GENODED1MNH'],
    ['Raiffbk Much-Ruppicht.-alt-', '37069524', 'GENODED1MUC'],
    ['Raiffbk Voreifel, Rheinbach', '37069627', 'GENODED1RBC'],
    ['Raiffbk Rosbach -alt-', '37069639', 'GENODED1WND'],
    ['Raiffbk Eifel Simmerath', '37069642', 'GENODED1SMR'],
    ['Raiffbk Sankt Augustin-alt-', '37069707', 'GENODED1SAM'],
    ['VR-Bank Nordeifel Schleiden', '37069720', 'GENODED1SLE'],
    ['Volksbank Wachtberg -alt-', '37069805', 'GENODED1WVI'],
    ['Raiffeisenbk Wesseling-alt-', '37069833', 'GENODED1WSL'],
    ['VB Wipperfürth-Lindlar', '37069840', 'GENODED1WPF'],
    ['Brühler Bank, Brühl', '37069991', 'GENODED1BRL'],
    ['Deutsche Bank', '37070000', 'DEUTDEDK402'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB353'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB355'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB357'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB358'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB360'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB370'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB371'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB372'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB373'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB379'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB386'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB938'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB939'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB940'],
    ['Deutsche Bank', '37070024', 'DEUTDEDB941'],
    ['Deutsche Bank', '37070024', 'DEUTDEDBKOE'],
    ['Deutsche Bank', '37070060', 'DEUTDEDKXXX'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK351'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK352'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK353'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK354'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK355'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK356'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK357'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK358'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK360'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK370'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK371'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK372'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK373'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK379'],
    ['Deutsche Bank', '37070060', 'DEUTDEDK386'],
    ['Postbank Ndl Deutsche Bank', '37070209', 'DEUTDEDKP08'],
    ['Postbank Ndl Deutsche Bank', '37070224', 'DEUTDEDBP08'],
    ['Commerzbank Köln', '37080040', 'DRESDEFF370'],
    ['Commerzbank ITKG1 Köln', '37080085', 'DRESDEFFI51'],
    ['Commerzbank ITGK4 Köln', '37080086', 'DRESDEFFI67'],
    ['Commerzbank ITGK5 Köln', '37080087', 'DRESDEFFI96'],
    ['Commerzbank ITGK6 Köln', '37080088', 'DRESDEFFI97'],
    ['Commerzbank ITGK7 Köln', '37080089', 'DRESDEFFI98'],
    ['Commerzbank ITGK8 Köln', '37080090', 'DRESDEFFJ01'],
    ['Commerzbank ITGK9 Köln', '37080091', 'DRESDEFFJ02'],
    ['Commerzbank ITGK10 Köln', '37080092', 'DRESDEFFJ03'],
    ['Commerzbank ITGK11 Köln', '37080093', 'DRESDEFFJ04'],
    ['Commerzbank ITGK12 Köln', '37080094', 'DRESDEFFJ05'],
    ['Commerzbank ITGK13 Köln', '37080095', 'DRESDEFFJ06'],
    ['Commerzbank Zw 96 Köln', '37080096', 'DRESDEFFXXX'],
    ['Commerzbank Zw 97 Köln', '37080097', 'DRESDEFFXXX'],
    ['Commerzbank ITGK14 Köln', '37080098', 'DRESDEFFJ07'],
    ['Commerzbank Zw 99 Köln', '37080099', 'DRESDEFFI36'],
    ['Commerzbank ITGK I Köln', '37089340', 'DRESDEFFI04'],
    ['Commerzbank ITGK II Köln', '37089342', 'DRESDEFFI05'],
    ['Kölner Bank -alt-', '37160087', 'GENODED1CGN'],
    ['VR-Bank Rhein-Erft -alt-', '37161289', 'GENODED1BRH'],
    ['Commerzbank Leverkusen', '37540050', 'COBADEFFXXX'],
    ['St Spk Leichlingen Rh.-alt-', '37551020', 'WELADED1LEI'],
    ['Sparkasse Leverkusen', '37551440', 'WELADEDLLEV'],
    ['St Spk Langenfeld', '37551780', 'WELADED1LAF'],
    ['Volksbank Rhein-Wupper-alt-', '37560092', 'GENODED1RWL'],
    ['Deutsche Bank', '37570024', 'DEUTDEDB375'],
    ['Deutsche Bank', '37570024', 'DEUTDEDB377'],
    ['Deutsche Bank', '37570024', 'DEUTDEDB378'],
    ['Deutsche Bank', '37570064', 'DEUTDEDK375'],
    ['Deutsche Bank', '37570064', 'DEUTDEDK377'],
    ['Deutsche Bank', '37570064', 'DEUTDEDK378'],
    ['Postbank Ndl Deutsche Bank', '38010053', 'PBNKDEFF380'],
    ['DSL Bank Ndl Deutsche Bank', '38010700', 'PBNKDEFFDSL'],
    ['KfW Bonn', '38010900', 'DTABDED1XXX'],
    ['KfW Ausbildungsförderung', '38010999', 'DTABDED1AUS'],
    ['VÖB-ZVD Bonn', '38011000', 'VZVDDED1XXX'],
    ['VÖB-ZVD Bonn', '38011001', 'VZVDDED1001'],
    ['VÖB-ZVD Bonn', '38011002', 'VZVDDED1002'],
    ['VÖB-ZVD Bonn', '38011003', 'VZVDDED1003'],
    ['VÖB-ZVD Bonn', '38011004', 'VZVDDED1004'],
    ['VÖB-ZVD Bonn', '38011005', 'VZVDDED1005'],
    ['VÖB-ZVD Bonn', '38011006', 'VZVDDED1006'],
    ['VÖB-ZVD Bonn', '38011007', 'VZVDDED1007'],
    ['VÖB-ZVD Bonn', '38011008', 'VZVDDED1008'],
    ['UniCredit Bank-HypoVereinbk', '38020090', 'HYVEDEMM402'],
    ['Commerzbank Bonn', '38040007', 'COBADEFFXXX'],
    ['Sparkasse Bonn -alt-', '38050000', 'COLSDE33BON'],
    ['St Spk Bad Honnef -alt-', '38051290', 'WELADED1HON'],
    ['Volksbank Köln Bonn', '38060186', 'GENODED1BRS'],
    ['Deutsche Bank', '38070024', 'DEUTDEDB380'],
    ['Deutsche Bank', '38070024', 'DEUTDEDB942'],
    ['Deutsche Bank', '38070024', 'DEUTDEDB943'],
    ['Deutsche Bank', '38070024', 'DEUTDEDB944'],
    ['Deutsche Bank', '38070024', 'DEUTDEDB945'],
    ['Deutsche Bank', '38070024', 'DEUTDEDB946'],
    ['Deutsche Bank', '38070059', 'DEUTDEDK380'],
    ['Deutsche Bank', '38070059', 'DEUTDEDK384'],
    ['Deutsche Bank', '38070059', 'DEUTDEDK385'],
    ['Deutsche Bank', '38070059', 'DEUTDEDK387'],
    ['Deutsche Bank', '38070059', 'DEUTDEDK388'],
    ['Deutsche Bank', '38070059', 'DEUTDEDK389'],
    ['Postbank Ndl Deutsche Bank', '38070408', 'DEUTDEDKP38'],
    ['Postbank Ndl Deutsche Bank', '38070424', 'DEUTDEDBP38'],
    ['Deutsche Bank', '38070724', 'DEUTDEDBXXX'],
    ['Deutsche Bank', '38077724', 'DEUTDEDB383'],
    ['Commerzbank Bonn', '38080055', 'DRESDEFF380'],
    ['VR-Bank Bonn -alt-', '38160220', 'GENODED1HBO'],
    ['Kreissparkasse Euskirchen', '38250110', 'WELADED1EUS'],
    ['Volksbank Euskirchen', '38260082', 'GENODED1EVB'],
    ['Commerzbank Gummersbach', '38440016', 'COBADEFFXXX'],
    ['Spk Gummersbach', '38450000', 'WELADED1GMB'],
    ['Sparkasse Wiehl -alt-', '38452490', 'WELADED1WIE'],
    ['Volksbank Oberberg', '38462135', 'GENODED1WIL'],
    ['Deutsche Bank', '38470024', 'DEUTDEDB384'],
    ['Deutsche Bank', '38470024', 'DEUTDEDB385'],
    ['Deutsche Bank', '38470024', 'DEUTDEDB387'],
    ['Deutsche Bank', '38470024', 'DEUTDEDB388'],
    ['Deutsche Bank', '38470024', 'DEUTDEDB389'],
    ['Deutsche Bank', '38470091', 'DEUTDEDW384'],
    ['Deutsche Bank', '38470091', 'DEUTDEDW385'],
    ['Deutsche Bank', '38470091', 'DEUTDEDW387'],
    ['Deutsche Bank', '38470091', 'DEUTDEDW388'],
    ['Deutsche Bank', '38470091', 'DEUTDEDW389'],
    ['Steyler Bank', '38621500', 'GENODED1STB'],
    ['Kr Spk Siegburg -alt-', '38650000', 'WELADED1SGB'],
    ['Sparkasse Hennef -alt-', '38651390', 'WELADED1HEN'],
    ['Aachener Bauspk Aachen', '39020000', 'AABSDE31XXX'],
    ['Commerzbank Aachen', '39040013', 'COBADEFFXXX'],
    ['Sparkasse Aachen', '39050000', 'AACSDE33XXX'],
    ['Aachener Bank', '39060180', 'GENODED1AAC'],
    ['Heinsberger Volksbank -alt-', '39061981', 'GENODED1HNB'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK390'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK391'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK392'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK393'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK394'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK397'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK398'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK399'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK400'],
    ['Deutsche Bank', '39070020', 'DEUTDEDK401'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB390'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB391'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB392'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB393'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB394'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB397'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB398'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB399'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB947'],
    ['Deutsche Bank', '39070024', 'DEUTDEDB948'],
    ['Postbank Ndl Deutsche Bank', '39070210', 'DEUTDEDKP09'],
    ['Postbank Ndl Deutsche Bank', '39070224', 'DEUTDEDBP09'],
    ['Commerzbank Aachen', '39080005', 'DRESDEFF390'],
    ['Commerzbank Zw 98 Aachen', '39080098', 'DRESDEFFI37'],
    ['Commerzbank Zw 99 Aachen', '39080099', 'DRESDEFFI38'],
    ['Pax-Bank Aachen', '39160191', 'GENODED1PA1'],
    ['Volksbank Aachen Süd', '39161490', 'GENODED1AAS'],
    ['VR-Bank Würselen', '39162980', 'GENODED1WUR'],
    ['Raiffeisen-Bank Eschweiler', '39362254', 'GENODED1RSC'],
    ['Commerzbank Düren', '39540052', 'COBADEFFXXX'],
    ['Sparkasse Düren', '39550110', 'SDUEDE33XXX'],
    ['Volksbank Düren -alt-', '39560201', 'GENODED1DUE'],
    ['Deutsche Bank', '39570024', 'DEUTDEDB395'],
    ['Deutsche Bank', '39570024', 'DEUTDEDB396'],
    ['Deutsche Bank', '39570061', 'DEUTDEDK395'],
    ['Deutsche Bank', '39570061', 'DEUTDEDK396'],
    ['Commerzbank Düren', '39580041', 'DRESDEFF395'],
    ['NRW.BANK Münster', '40022000', 'NRWBDEDMMST'],
    ['Münsterländische Bk Münster', '40030000', 'MLBKDEH1MUE'],
    ['Commerzbank Münster Westf', '40040028', 'COBADEFFXXX'],
    ['Ld Bk Hess-Thür, Gz, Dus', '40050000', 'WELADE3MXXX'],
    ['Spk Münsterland Ost', '40050150', 'WELADED1MST'],
    ['LBS West Münster', '40055555', 'LBSWDE31XXX'],
    ['DZ BANK Münster', '40060000', 'GENODEMSXXX'],
    ['DKM Darlehnskasse Münster', '40060265', 'GENODEM1DKM'],
    ['DZ HYP - Münster', '40060300', 'GENODEM1WLM'],
    ['Sparda-Bank West', '40060560', 'GENODEF1S08'],
    ['Volksbank Greven -alt-', '40061238', 'GENODEM1GRV'],
    ['Volksbank Lette-Darup-Rorup', '40069226', 'GENODEM1CND'],
    ['VB im Hochsauerland', '40069266', 'GENODEM1MAS'],
    ['Volksbank Schlangen', '40069283', 'GENODEM1SLN'],
    ['Volksbank Medebach -alt-', '40069348', 'GENODEM1MDB'],
    ['Volksbank Saerbeck -alt-', '40069362', 'GENODEM1SAE'],
    ['Volksbank Schermbeck', '40069363', 'GENODEM1SMB'],
    ['Volksbank Thülen -alt-', '40069371', 'GENODEM1BTH'],
    ['Volksbank Baumberge', '40069408', 'GENODEM1BAU'],
    ['Volksbank Wulfen -alt-', '40069477', 'GENODEM1DWU'],
    ['Volksbank Senden', '40069546', 'GENODEM1SDN'],
    ['Volksbank Amelsbüren -alt-', '40069600', 'GENODEM1MAB'],
    ['Volksbank Ascheberg-Herbern', '40069601', 'GENODEM1CAN'],
    ['Volksbank Erle -alt-', '40069606', 'GENODEM1ERR'],
    ['Volksbank Seppenrade', '40069622', 'GENODEM1LSP'],
    ['Volksbank Hohen Mark, Reken', '40069709', 'GENODEM1DLR'],
    ['Volksbank Nordkirchen', '40069716', 'GENODEM1SCN'],
    ['Deutsche Bank', '40070024', 'DEUTDEDB400'],
    ['Deutsche Bank', '40070024', 'DEUTDEDB404'],
    ['Deutsche Bank', '40070024', 'DEUTDEDB949'],
    ['Deutsche Bank', '40070024', 'DEUTDEDB950'],
    ['Deutsche Bank', '40070024', 'DEUTDEDB951'],
    ['Deutsche Bank', '40070024', 'DEUTDEDB952'],
    ['Deutsche Bank', '40070080', 'DEUTDE3B400'],
    ['Deutsche Bank', '40070080', 'DEUTDE3B404'],
    ['Deutsche Bank', '40070080', 'DEUTDE3B440'],
    ['Deutsche Bank', '40070080', 'DEUTDE3B441'],
    ['Deutsche Bank', '40070080', 'DEUTDE3B442'],
    ['Deutsche Bank', '40070080', 'DEUTDE3B443'],
    ['Postbank Ndl Deutsche Bank', '40070211', 'DEUTDE3BP10'],
    ['Postbank Ndl Deutsche Bank', '40070224', 'DEUTDEDBP10'],
    ['Commerzbank Münster', '40080040', 'DRESDEFF400'],
    ['Commerzbank ITGK1 Münster', '40080085', 'DRESDEFFI68'],
    ['VR Bank Westfalen-Lippe', '40090900', 'GENODEF1P15'],
    ['Sparkasse Emsdetten Ochtrup', '40153768', 'WELADED1EMS'],
    ['Spk Gronau -alt-', '40154006', 'WELADED1GRO'],
    ['St Spk Lengerich', '40154476', 'WELADED1LEN'],
    ['Sparkasse Westmünsterland', '40154530', 'WELADE3WXXX'],
    ['St Spk Stadtlohn -alt-', '40154702', 'WELADED1STL'],
    ['Vereinigte VB Münster -alt-', '40160050', 'GENODEM1MSC'],
    ['VB Nordmünsterland Rheine', '40163720', 'GENODEM1SEE'],
    ['Volksbank Gronau-Ahaus', '40164024', 'GENODEM1GRN'],
    ['VB Laer-Horstmar-Leer -alt-', '40164256', 'GENODEM1LAE'],
    ['Volksbank Nottuln', '40164352', 'GENODEM1CNO'],
    ['VB Südmünsterland-Mitte', '40164528', 'GENODEM1LHN'],
    ['Volksbank Ochtrup-Laer', '40164618', 'GENODEM1OTR'],
    ['Volksbank Gescher', '40164901', 'GENODEM1GE1'],
    ['Volksbank Selm-Bork', '40165366', 'GENODEM1SEM'],
    ['VB Lengerich/Lotte -alt-', '40166439', 'GENODEM1LLE'],
    ['Volksbank Buldern -alt-', '40166800', 'GENODEM1BUL'],
    ['Commerzbank Rheine Westf', '40340030', 'COBADEFFXXX'],
    ['St Spk Rheine', '40350005', 'WELADED1RHN'],
    ['Kreissparkasse Steinfurt', '40351060', 'WELADED1STF'],
    ['Sparkasse Steinfurt -alt-', '40351220', 'WELADED1IBB'],
    ['VB Westerkappeln-Saerbeck', '40361627', 'GENODEM1WKP'],
    ['Volksbank Münsterland Nord', '40361906', 'GENODEM1IBB'],
    ['Volksbank Hörstel -alt-', '40363433', 'GENODEM1HRL'],
    ['Deutsche Bank', '40370024', 'DEUTDEDB401'],
    ['Deutsche Bank', '40370024', 'DEUTDEDB403'],
    ['Deutsche Bank', '40370024', 'DEUTDEDB405'],
    ['Deutsche Bank', '40370024', 'DEUTDEDB406'],
    ['Deutsche Bank', '40370024', 'DEUTDEDB407'],
    ['Deutsche Bank', '40370024', 'DEUTDEDB408'],
    ['Deutsche Bank', '40370024', 'DEUTDEDB409'],
    ['Deutsche Bank', '40370079', 'DEUTDE3B401'],
    ['Deutsche Bank', '40370079', 'DEUTDE3B403'],
    ['Deutsche Bank', '40370079', 'DEUTDE3B405'],
    ['Deutsche Bank', '40370079', 'DEUTDE3B406'],
    ['Deutsche Bank', '40370079', 'DEUTDE3B407'],
    ['Deutsche Bank', '40370079', 'DEUTDE3B408'],
    ['Deutsche Bank', '40370079', 'DEUTDE3B409'],
    ['Commerzbank Hamm Westf', '41040018', 'COBADEFFXXX'],
    ['ZTB der Commerzbank', '41041000', 'COBADEFFXXX'],
    ['Sparkasse Hamm', '41050095', 'WELADED1HAM'],
    ['St Spk Werne -alt-', '41051605', 'WELADED1WRN'],
    ['Spk Bergkamen-Bönen', '41051845', 'WELADED1BGK'],
    ['Spar-u Darlehnskasse', '41061011', 'GENODEM1HBH'],
    ['BAG Bankaktienges Hamm', '41061903', 'GENODEM1BAG'],
    ['Volksbank Bönen', '41062215', 'GENODEM1BO1'],
    ['Deutsche Bank', '41070024', 'DEUTDEDB410'],
    ['Deutsche Bank', '41070024', 'DEUTDEDB412'],
    ['Deutsche Bank', '41070049', 'DEUTDEDE410'],
    ['Deutsche Bank', '41070049', 'DEUTDEDE412'],
    ['Commerzbank BE F-B48', '41240048', 'COBADEFFXXX'],
    ['Spk Beckum-Wadersloh', '41250035', 'WELADED1BEK'],
    ['Volksbank Beckum -alt-', '41260006', 'GENODEM1BEK'],
    ['VB Enniger-Ostenfelde-Westk', '41261324', 'GENODEM1EOW'],
    ['Volksbank -alt-', '41261419', 'GENODEM1OEN'],
    ['Volksbank', '41262501', 'GENODEM1AHL'],
    ['Commerzbank Beckum Westf', '41280043', 'DRESDEFF413'],
    ['Commerzbank Soest Westf', '41440018', 'COBADEFFXXX'],
    ['Sparkasse SoestWerl', '41450075', 'WELADED1SOS'],
    ['Sparkasse Werl -alt-', '41451750', 'WELADED1WRL'],
    ['Volksbank Hellweg', '41460116', 'GENODEM1SOE'],
    ['Volksbank Wickede -alt-', '41462295', 'GENODEM1WRU'],
    ['Spk Lippstadt', '41650001', 'WELADED1LIP'],
    ['Spk Hochsauerland Brilon', '41651770', 'WELADED1HSL'],
    ['Spk Erwitte-Anröchte -alt-', '41651815', 'WELADED1ERW'],
    ['Sparkasse Geseke', '41651965', 'WELADED1GES'],
    ['Volksbank Beckum-Lippstadt', '41660124', 'GENODEM1LPS'],
    ['Volksbank Anröchte', '41661206', 'GENODEM1ANR'],
    ['VB Benninghausen -alt-', '41661504', 'GENODEM1LBH'],
    ['Volksbank Brilon -alt-', '41661719', 'GENODEM1BRI'],
    ['VB Störmede-Hörste, Geseke', '41662465', 'GENODEM1SGE'],
    ['VB Warstein-Belecke -alt-', '41662557', 'GENODEM1WST'],
    ['Volksbank Hörste -alt-', '41663335', 'GENODEM1HOE'],
    ['Deutsche Bank', '41670024', 'DEUTDEDB414'],
    ['Deutsche Bank', '41670024', 'DEUTDEDB416'],
    ['Deutsche Bank', '41670024', 'DEUTDEDB417'],
    ['Deutsche Bank', '41670024', 'DEUTDEDB418'],
    ['Deutsche Bank', '41670027', 'DEUTDE3B416'],
    ['Deutsche Bank', '41670028', 'DEUTDE3B417'],
    ['Deutsche Bank', '41670029', 'DEUTDE3B414'],
    ['Deutsche Bank', '41670030', 'DEUTDE3B418'],
    ['Isbank Gelsenkirchen', '42030600', 'ISBKDEFXGEL'],
    ['Commerzbank Gelsenkirchen', '42040040', 'COBADEFFXXX'],
    ['Sparkasse Gelsenkirchen', '42050001', 'WELADED1GEK'],
    ['Deutsche Bank', '42070024', 'DEUTDEDB366'],
    ['Deutsche Bank', '42070024', 'DEUTDEDB420'],
    ['Deutsche Bank', '42070024', 'DEUTDEDB421'],
    ['Deutsche Bank', '42070024', 'DEUTDEDB422'],
    ['Deutsche Bank', '42070024', 'DEUTDEDB423'],
    ['Deutsche Bank', '42070024', 'DEUTDEDB424'],
    ['Deutsche Bank', '42070024', 'DEUTDEDB425'],
    ['Deutsche Bank', '42070024', 'DEUTDEDB426'],
    ['Deutsche Bank', '42070062', 'DEUTDEDE384'],
    ['Deutsche Bank', '42070062', 'DEUTDEDE420'],
    ['Deutsche Bank', '42070062', 'DEUTDEDE421'],
    ['Deutsche Bank', '42070062', 'DEUTDEDE422'],
    ['Deutsche Bank', '42070062', 'DEUTDEDE423'],
    ['Deutsche Bank', '42070062', 'DEUTDEDE424'],
    ['Deutsche Bank', '42070062', 'DEUTDEDE425'],
    ['Deutsche Bank', '42070062', 'DEUTDEDE426'],
    ['Commerzbank Gelsenkirchen', '42080082', 'DRESDEFF420'],
    ['VB Ruhr Mitte Gelsenkirchen', '42260001', 'GENODEM1GBU'],
    ['St Spk Gladbeck', '42450040', 'WELADED1GLA'],
    ['Spk Bottrop', '42451220', 'WELADED1BOT'],
    ['Vereinte Volksbank', '42461435', 'GENODEM1KIH'],
    ['Commerzbank Recklinghausen', '42640048', 'COBADEFFXXX'],
    ['Spk Recklinghausen', '42650150', 'WELADED1REK'],
    ['St Spk Haltern', '42651315', 'WELADED1HAT'],
    ['VB Marl-Recklinghausen', '42661008', 'GENODEM1MRL'],
    ['VB Marl-Recklinghausen', '42661088', 'GENODEM1MRL'],
    ['Volksbank Haltern -alt-', '42661330', 'GENODEM1HLT'],
    ['Volksbank Waltrop -alt-', '42661717', 'GENODEM1WLW'],
    ['Volksbank Dorsten -alt-', '42662320', 'GENODEM1DST'],
    ['Commerzbank Recklinghsn', '42680081', 'DRESDEFF426'],
    ['Commerzbank Bocholt', '42840005', 'COBADEFFXXX'],
    ['Stadtsparkasse Bocholt', '42850035', 'WELADED1BOH'],
    ['Volksbank Bocholt', '42860003', 'GENODEM1BOH'],
    ['Spar-u Darlehnsk.Reken-alt-', '42861239', 'GENODEM1RKN'],
    ['VR-Bank Westmünsterland', '42861387', 'GENODEM1BOB'],
    ['Volksbank Gemen', '42861515', 'GENODEM1BOG'],
    ['Volksbank Heiden', '42861608', 'GENODEM1HEI'],
    ['Volksbank Rhede', '42861814', 'GENODEM1RHD'],
    ['Volksbank Raesfeld und Erle', '42862451', 'GENODEM1RAE'],
    ['Deutsche Bank', '42870024', 'DEUTDEDB428'],
    ['Deutsche Bank', '42870024', 'DEUTDEDB429'],
    ['Deutsche Bank', '42870077', 'DEUTDE3B428'],
    ['Deutsche Bank', '42870077', 'DEUTDE3B429'],
    ['BBk eh Bochum', '43000000', 'MARKDEF1430'],
    ['Commerzbank Bochum', '43040036', 'COBADEFFXXX'],
    ['Sparkasse Bochum', '43050001', 'WELADED1BOC'],
    ['Sparkasse Hattingen', '43051040', 'WELADED1HTG'],
    ['Volksbank Bochum Witten', '43060129', 'GENODEM1BOC'],
    ['GLS Gemeinschaftsbk Bochum', '43060967', 'GENODEM1GLS'],
    ['GLS Bank in Bochum (GAA)', '43060988', 'GENODEM1GLS'],
    ['Deutsche Bank', '43070024', 'DEUTDEDB430'],
    ['Deutsche Bank', '43070024', 'DEUTDEDB431'],
    ['Deutsche Bank', '43070024', 'DEUTDEDB432'],
    ['Deutsche Bank', '43070024', 'DEUTDEDB433'],
    ['Deutsche Bank', '43070024', 'DEUTDEDB434'],
    ['Deutsche Bank', '43070061', 'DEUTDEDE430'],
    ['Deutsche Bank', '43070061', 'DEUTDEDE431'],
    ['Deutsche Bank', '43070061', 'DEUTDEDE432'],
    ['Deutsche Bank', '43070061', 'DEUTDEDE433'],
    ['Deutsche Bank', '43070061', 'DEUTDEDE434'],
    ['Commerzbank Bochum', '43080083', 'DRESDEFF430'],
    ['Herner Sparkasse', '43250030', 'WELADED1HRN'],
    ['BBk eh Dortmund -alt-', '44000000', 'MARKDEF1440'],
    ['Postbank Ndl Deutsche Bank', '44010046', 'PBNKDEFFXXX'],
    ['UniCredit Bank-HypoVereinbk', '44020090', 'HYVEDEMM808'],
    ['Commerzbank Dortmund', '44040037', 'COBADEFFXXX'],
    ['Commerzbank CC Dortmund', '44040060', 'COBADEFFXXX'],
    ['Commerzbank CC Dortmund', '44040061', 'COBADEFFXXX'],
    ['Commerzbank Gf WK, Dortmund', '44040085', 'COBADEFFXXX'],
    ['Ld Bk Hess-Thür, Gz, Dus', '44050000', 'WELADE3DXXX'],
    ['Sparkasse Dortmund', '44050199', 'DORTDE33XXX'],
    ['Volksbank Dortmund-Nordwest', '44060122', 'GENODEM1DNW'],
    ['KD-Bank Dortmund', '44064406', 'GENODED1KDD'],
    ['Deutsche Bank', '44070024', 'DEUTDEDB440'],
    ['Deutsche Bank', '44070024', 'DEUTDEDB441'],
    ['Deutsche Bank', '44070024', 'DEUTDEDB442'],
    ['Deutsche Bank', '44070024', 'DEUTDEDB443'],
    ['Deutsche Bank', '44070024', 'DEUTDEDB444'],
    ['Deutsche Bank', '44070024', 'DEUTDEDB447'],
    ['Deutsche Bank', '44070024', 'DEUTDEDB448'],
    ['Deutsche Bank', '44070050', 'DEUTDEDE440'],
    ['Deutsche Bank', '44070050', 'DEUTDEDE441'],
    ['Deutsche Bank', '44070050', 'DEUTDEDE442'],
    ['Deutsche Bank', '44070050', 'DEUTDEDE443'],
    ['Deutsche Bank', '44070050', 'DEUTDEDE444'],
    ['Deutsche Bank', '44070050', 'DEUTDEDE447'],
    ['Deutsche Bank', '44070050', 'DEUTDEDE448'],
    ['Commerzbank Dortmund', '44080050', 'DRESDEFF440'],
    ['Commerzbk Zw55 Dortmund', '44080055', 'DRESDEFF446'],
    ['Commerzbk ZW 57 Dortmund', '44080057', 'DRESDEFF447'],
    ['Commerzbank ITGK2 Dortmnd', '44080085', 'DRESDEFFI69'],
    ['Commerzbank ITGK Dortmund', '44089320', 'DRESDEFFI18'],
    ['PSD Bank Dortmund -alt-', '44090920', 'GENODEF1P04'],
    ['Sparkasse an der Lippe', '44152370', 'WELADED1LUN'],
    ['Sparkasse Schwerte -alt-', '44152490', 'WELADED1SWT'],
    ['Dortmunder Volksbank', '44160014', 'GENODEM1DOR'],
    ['Commerzbank Unna', '44340037', 'COBADEFFXXX'],
    ['Sparkasse UnnaKamen', '44350060', 'WELADED1UNN'],
    ['Sparkasse Kamen -alt-', '44351380', 'WELADED1KAM'],
    ['Sparkasse Fröndenberg -alt-', '44351740', 'WELADED1FRN'],
    ['VB Kamen-Werne -alt-', '44361342', 'GENODEM1KWK'],
    ['Commerzbank Iserlohn', '44540022', 'COBADEFFXXX'],
    ['Spk der Stadt Iserlohn', '44550045', 'WELADED1ISL'],
    ['Spk Märkisches Sauerland', '44551210', 'WELADED1HEM'],
    ['Deutsche Bank', '44570004', 'DEUTDEDW443'],
    ['Deutsche Bank', '44570004', 'DEUTDEDW444'],
    ['Deutsche Bank', '44570004', 'DEUTDEDW445'],
    ['Deutsche Bank', '44570004', 'DEUTDEDW446'],
    ['Deutsche Bank', '44570004', 'DEUTDEDW447'],
    ['Deutsche Bank', '44570004', 'DEUTDEDW448'],
    ['Deutsche Bank', '44570004', 'DEUTDEDW449'],
    ['Deutsche Bank', '44570024', 'DEUTDEDB445'],
    ['Deutsche Bank', '44570024', 'DEUTDEDB446'],
    ['Deutsche Bank', '44570024', 'DEUTDEDB449'],
    ['Deutsche Bank', '44570024', 'DEUTDEDB953'],
    ['Deutsche Bank', '44570024', 'DEUTDEDB954'],
    ['Deutsche Bank', '44570024', 'DEUTDEDB955'],
    ['Deutsche Bank', '44570024', 'DEUTDEDB956'],
    ['Commerzbank Iserlohn', '44580070', 'DRESDEFF445'],
    ['Commerzbank ITGK1 Iserlhn', '44580085', 'DRESDEFFI70'],
    ['Mendener Bank', '44761312', 'GENODEM1MEN'],
    ['VB in Südwestfalen', '44761534', 'GENODEM1NRD'],
    ['BBk eh Hagen', '45000000', 'MARKDEF1450'],
    ['Commerzbank Hagen Westf', '45040042', 'COBADEFFXXX'],
    ['Sparkasse an Volme und Ruhr', '45050001', 'WELADE3HXXX'],
    ['St Spk Herdecke -alt-', '45051485', 'WELADED1HER'],
    ['Märkische Bank Hagen', '45060009', 'GENODEM1HGN'],
    ['Volksbank Hohenlimburg', '45061524', 'GENODEM1HLH'],
    ['Deutsche Bank', '45070002', 'DEUTDEDW450'],
    ['Deutsche Bank', '45070002', 'DEUTDEDW451'],
    ['Deutsche Bank', '45070002', 'DEUTDEDW453'],
    ['Deutsche Bank', '45070002', 'DEUTDEDW454'],
    ['Deutsche Bank', '45070002', 'DEUTDEDW456'],
    ['Deutsche Bank', '45070024', 'DEUTDEDB450'],
    ['Deutsche Bank', '45070024', 'DEUTDEDB451'],
    ['Deutsche Bank', '45070024', 'DEUTDEDB453'],
    ['Deutsche Bank', '45070024', 'DEUTDEDB454'],
    ['Deutsche Bank', '45070024', 'DEUTDEDB456'],
    ['Commerzbank Hagen', '45080060', 'DRESDEFF450'],
    ['Commerzbank Witten', '45240056', 'COBADEFFXXX'],
    ['Sparkasse Witten', '45250035', 'WELADED1WTN'],
    ['St Spk Wetter Ruhr -alt-', '45251480', 'WELADED1WET'],
    ['St Spk Sprockhövel -alt-', '45251515', 'SPSHDE31XXX'],
    ['Volksbank Witten -alt-', '45260041', 'GENODEM1WTN'],
    ['Spar- und Kreditbank', '45260475', 'GENODEM1BFG'],
    ['Volksbank Sprockhövel', '45261547', 'GENODEM1SPO'],
    ['Spk an Ennepe und Ruhr', '45450050', 'WELADED1GEV'],
    ['Spk Ennepetal-Brecker.-alt-', '45451060', 'WELADED1ENE'],
    ['Spk Schwelm-Sprockhövel', '45451555', 'WELADED1SLM'],
    ['Volksbank Altena -alt-', '45660029', 'GENODEM1ALA'],
    ['Commerzbank Lüdenscheid', '45840026', 'COBADEFFXXX'],
    ['Commerzbank Plettenberg', '45841031', 'COBADEFFXXX'],
    ['Spk Lüdenscheid -alt-', '45850005', 'WELADED1LSD'],
    ['Ver Spk Plettenberg', '45851020', 'WELADED1PLB'],
    ['Spk Kierspe-Meinerzhagen', '45851665', 'WELADED1KMZ'],
    ['Volksbank Lüdenscheid -alt-', '45860033', 'GENODEM1LHA'],
    ['Volksbank Kierspe', '45861434', 'GENODEM1KIE'],
    ['Volksbank Meinerzhagen -alt', '45861617', 'GENODEM1MOM'],
    ['Commerzbank Siegen Westf', '46040033', 'COBADEFFXXX'],
    ['Spk Siegen', '46050001', 'WELADED1SIE'],
    ['Spk Burbach-Neunkirchen', '46051240', 'WELADED1BUB'],
    ['St Spk Freudenberg -alt-', '46051733', 'WELADED1FRE'],
    ['St Spk Hilchenbach -alt-', '46051875', 'WELADED1HIL'],
    ['St Spk Schmallenberg -alt-', '46052855', 'WELADED1SMB'],
    ['Sparkasse Wittgenstein', '46053480', 'WELADED1BEB'],
    ['VB Siegerland Siegen-alt-', '46060040', 'GENODEM1SNS'],
    ['VR-Bank Freudenb.-Niederfi.', '46061724', 'GENODEM1FRF'],
    ['Volksbank Sauerland', '46062817', 'GENODEM1SMA'],
    ['Volksbank Wittgenstein', '46063405', 'GENODEM1BB1'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB460'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB461'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB462'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB463'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB464'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB465'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB466'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB469'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB470'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB471'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB516'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB962'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB963'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB964'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB965'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB966'],
    ['Deutsche Bank', '46070024', 'DEUTDEDB967'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK460'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK461'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK462'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK463'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK464'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK465'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK466'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK467'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK468'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK469'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK470'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK471'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK472'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK473'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK474'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK475'],
    ['Deutsche Bank', '46070090', 'DEUTDEDK516'],
    ['Commerzbank Siegen', '46080010', 'DRESDEFF460'],
    ['Commerzbank Olpe Biggesee', '46240016', 'COBADEFFXXX'],
    ['Spk Olpe-Drolshagen-Wenden', '46250049', 'WELADED1OPE'],
    ['Spk Finnentrop -alt-', '46251590', 'WELADED1FTR'],
    ['Spk Attend-Lennest-Kirchhdm', '46251630', 'WELADED1ALK'],
    ['Volksbank Olpe -alt-', '46260023', 'GENODEM1OLP'],
    ['Volksbank Grevenbrück -alt-', '46261607', 'GENODEM1GLG'],
    ['VB Olpe-Wenden-Drolshagen', '46261822', 'GENODEM1WDD'],
    ['Volksbank Bigge-Lenne -alt-', '46262456', 'GENODEM1HUL'],
    ['Commerzbank Meschede', '46441003', 'COBADEFFXXX'],
    ['Spk Mitten im Sauerland', '46451012', 'WELADED1MES'],
    ['Spk Bestwig -alt-', '46451250', 'WELADED1BST'],
    ['Volksbank Sauerland -alt-', '46461126', 'GENODEM1SRL'],
    ['SpDK Oeventrop -alt-', '46462271', 'GENODEM1ANO'],
    ['VB Reiste-Eslohe -alt-', '46464453', 'GENODEM1RET'],
    ['Commerzbank Arnsberg-Neheim', '46640018', 'COBADEFFXXX'],
    ['Spk Arnsberg-Sundern', '46650005', 'WELADED1ARN'],
    ['Volksbank Sauerland -alt-', '46660022', 'GENODEM1NEH'],
    ['Deutsche Bank', '46670007', 'DEUTDEDW466'],
    ['Deutsche Bank', '46670007', 'DEUTDEDW467'],
    ['Deutsche Bank', '46670007', 'DEUTDEDW468'],
    ['Deutsche Bank', '46670024', 'DEUTDEDB467'],
    ['Deutsche Bank', '46670024', 'DEUTDEDB468'],
    ['Deutsche Bank', '46670024', 'DEUTDEDB961'],
    ['Postbank Ndl Deutsche Bank', '46670204', 'DEUTDEDWP03'],
    ['Postbank Ndl Deutsche Bank', '46670224', 'DEUTDEDBP03'],
    ['BBk Dortmund', '47000000', 'MARKDEF1470'],
    ['Commerzbank Paderborn', '47240047', 'COBADEFFXXX'],
    ['Sparkasse Paderborn -alt-', '47250101', 'WELADED1PBN'],
    ['Spk Höxter Brakel -alt-', '47251550', 'WELADED1HXB'],
    ['St Spk Delbrück -alt-', '47251740', 'WELADED1DEL'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MXXX'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MALT'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MBRA'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MBUR'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MDEL'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MDRI'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MDTM'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MEPW'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MHBM'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MHOV'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MHOX'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MLAG'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MLEM'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MLIP'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MMND'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MOER'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MSAL'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MSTE'],
    ['VerbundVolksbank OWL', '47260121', 'DGPBDE3MWAR'],
    ['VB Elsen-Wewer-Borchen', '47260234', 'GENODEM1EWB'],
    ['Bank für Kirche und Caritas', '47260307', 'GENODEM1BKC'],
    ['Volksbank Haaren -alt-', '47261429', 'GENODEM1WNH'],
    ['VB Brilon-Büren-Salzk.-alt-', '47261603', 'GENODEM1BUS'],
    ['Volksbank Westenholz', '47262626', 'GENODEM1WDE'],
    ['VB Delbrück-Hövelhof', '47262703', 'GENODEM1DLB'],
    ['Vereinigte Volksbank', '47264367', 'GENODEM1STM'],
    ['VB Wewelsburg-Ahden -alt-', '47265383', 'GENODEM1WAH'],
    ['Volksbank Borgentreich-alt-', '47267216', 'GENODEM1BOT'],
    ['Deutsche Bank', '47270024', 'DEUTDEDB472'],
    ['Deutsche Bank', '47270024', 'DEUTDEDB473'],
    ['Deutsche Bank', '47270024', 'DEUTDEDB474'],
    ['Deutsche Bank', '47270024', 'DEUTDEDB475'],
    ['Deutsche Bank', '47270024', 'DEUTDEDB958'],
    ['Deutsche Bank', '47270029', 'DEUTDE3B451'],
    ['Deutsche Bank', '47270029', 'DEUTDE3B472'],
    ['Deutsche Bank', '47270029', 'DEUTDE3B473'],
    ['Deutsche Bank', '47270029', 'DEUTDE3B474'],
    ['Deutsche Bank', '47270029', 'DEUTDE3B475'],
    ['VB Warburger Land -alt-', '47460028', 'GENODEM1WBG'],
    ['Commerzbank Detmold', '47640051', 'COBADEFFXXX'],
    ['Spk Paderborn-Detmold-Höxt.', '47650130', 'WELADE3LXXX'],
    ['St Spk Blomberg -alt-', '47651225', 'WELADED1BLO'],
    ['Deutsche Bank', '47670023', 'DEUTDE3B450'],
    ['Deutsche Bank', '47670023', 'DEUTDE3B452'],
    ['Deutsche Bank', '47670023', 'DEUTDE3B453'],
    ['Deutsche Bank', '47670023', 'DEUTDE3B476'],
    ['Deutsche Bank', '47670023', 'DEUTDE3B477'],
    ['Deutsche Bank', '47670023', 'DEUTDE3B478'],
    ['Deutsche Bank', '47670024', 'DEUTDEDB476'],
    ['Deutsche Bank', '47670024', 'DEUTDEDB477'],
    ['Deutsche Bank', '47670024', 'DEUTDEDB478'],
    ['Deutsche Bank', '47670024', 'DEUTDEDB957'],
    ['Deutsche Bank', '47670024', 'DEUTDEDB959'],
    ['Deutsche Bank', '47670024', 'DEUTDEDB960'],
    ['Postbank Ndl Deutsche Bank', '47670205', 'DEUTDE3BP04'],
    ['Postbank Ndl Deutsche Bank', '47670224', 'DEUTDEDBP04'],
    ['Volksbank Ostlippe', '47691200', 'GENODEM1OLB'],
    ['Commerzbank Gütersloh', '47840065', 'COBADEFFXXX'],
    ['Commerzbank Zw 80', '47840080', 'COBADEFFXXX'],
    ['Spk.Gütersl.-Rietb.-Versmo.', '47850065', 'WELADED1GTL'],
    ['Spk Rietberg -alt-', '47852760', 'WELADED1RTG'],
    ['St Spk Versmold -alt-', '47853355', 'WELADED1VSM'],
    ['Kreissparkasse Wiedenbrück', '47853520', 'WELADED1WDB'],
    ['VB Bielefeld-Gütersloh', '47860125', 'VBGTDE3MXXX'],
    ['VB Bielefeld-Gütersloh', '47860125', 'GENODEM1GTL'],
    ['VB im Ostmünsterland -alt-', '47861317', 'GENODEM1CLL'],
    ['Volksbank Harsewinkel -alt-', '47861518', 'GENODEM1HWI'],
    ['Volksbank Marienfeld -alt-', '47862261', 'GENODEM1MFD'],
    ['Volksbank Rietberg', '47862447', 'GENODEM1RNE'],
    ['Volksbank Versmold', '47863373', 'GENODEM1VMD'],
    ['Commerzbank Gütersloh', '47880031', 'DRESDEFF478'],
    ['BBk Bielefeld', '48000000', 'MARKDEF1480'],
    ['UniCredit Bank-HypoVereinbk', '48020086', 'HYVEDEMM344'],
    ['Hauck Aufhäuser Lampe', '48020151', 'LAMPDEDDXXX'],
    ['Hauck Aufhäuser Lampe', '48020151', 'HAUKDEFFXXX'],
    ['Bankv.Werther ZwNdl VV OWL', '48021900', 'DGPBDE3MBVW'],
    ['Commerzbank Bielefeld', '48040035', 'COBADEFFXXX'],
    ['Commerzbank CC Bielefeld', '48040060', 'COBADEFFXXX'],
    ['Commerzbank CC Bielefeld', '48040061', 'COBADEFFXXX'],
    ['Spk Bielefeld', '48050161', 'SPBIDE3BXXX'],
    ['Kreissparkasse Halle', '48051580', 'WELADED1HAW'],
    ['Bielefelder Volksbank -alt-', '48060036', 'GENODEM1BIE'],
    ['Volksbank Halle/Westf', '48062051', 'GENODEM1HLW'],
    ['Spar-u Darlehnskasse -alt-', '48062466', 'GENODEM1SHS'],
    ['Deutsche Bank', '48070020', 'DEUTDE3BXXX'],
    ['Deutsche Bank', '48070020', 'DEUTDE3B483'],
    ['Deutsche Bank', '48070020', 'DEUTDE3B486'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB413'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB480'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB481'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB483'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB484'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB485'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB486'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB487'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB488'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB489'],
    ['Deutsche Bank', '48070024', 'DEUTDEDB492'],
    ['Deutsche Bank', '48070024', 'DEUTDEDBBIE'],
    ['Deutsche Bank', '48070040', 'DEUTDE3B480'],
    ['Deutsche Bank', '48070042', 'DEUTDE3B484'],
    ['Deutsche Bank', '48070043', 'DEUTDE3B489'],
    ['Deutsche Bank', '48070044', 'DEUTDE3B487'],
    ['Deutsche Bank', '48070045', 'DEUTDE3B413'],
    ['Deutsche Bank', '48070050', 'DEUTDE3B481'],
    ['Deutsche Bank', '48070052', 'DEUTDE3B492'],
    ['Commerzbank Bielefeld', '48080020', 'DRESDEFF480'],
    ['Commerzbk ITGK Bielefeld', '48089350', 'DRESDEFFI19'],
    ['Sparkasse Lemgo', '48250110', 'WELADED1LEM'],
    ['Volksbank Bad Salzuflen', '48291490', 'GENODEM1BSU'],
    ['Commerzbank Minden Westf', '49040043', 'COBADEFFXXX'],
    ['Sparkasse Minden-Lübbecke', '49050101', 'WELADED1MIN'],
    ['Stadtsparkasse Rahden', '49051065', 'WELADED1RHD'],
    ['Spk Bad Oeynh.-Porta Westf.', '49051285', 'WELADED1OEH'],
    ['St Spk Porta Westfalica-alt', '49051990', 'WELADED1PWF'],
    ['VB Mindener Land -alt-', '49060127', 'GENODEM1MPW'],
    ['Volksbank Minden -alt-', '49060392', 'GENODEM1MND'],
    ['VB Stemweder Berg -alt-', '49061470', 'GENODEM1STR'],
    ['Volksbank Eisbergen -alt-', '49061510', 'GENODEM1EPW'],
    ['Deutsche Bank', '49070024', 'DEUTDEDB490'],
    ['Deutsche Bank', '49070024', 'DEUTDEDB491'],
    ['Deutsche Bank', '49070024', 'DEUTDEDB493'],
    ['Deutsche Bank', '49070024', 'DEUTDEDB494'],
    ['Deutsche Bank', '49070024', 'DEUTDEDB495'],
    ['Deutsche Bank', '49070028', 'DEUTDE3B490'],
    ['Deutsche Bank', '49070028', 'DEUTDE3B491'],
    ['Deutsche Bank', '49070028', 'DEUTDE3B493'],
    ['Deutsche Bank', '49070028', 'DEUTDE3B494'],
    ['Deutsche Bank', '49070028', 'DEUTDE3B495'],
    ['Commerzbank Minden Westf', '49080025', 'DRESDEFF491'],
    ['Volksbank Lübbecker Land', '49092650', 'GENODEM1LUB'],
    ['Commerzbank Bünde Westf', '49240096', 'COBADEFFXXX'],
    ['Volksbank Schnathorst-alt-', '49262364', 'GENODEM1SNA'],
    ['Commerzbank Herford', '49440043', 'COBADEFFXXX'],
    ['Sparkasse Herford', '49450120', 'WLAHDE44XXX'],
    ['VB Herford-Mindener Land', '49490070', 'GENODEM1HFV'],
    ['BBk Filiale Frankfurt Main', '50000000', 'MARKDEF1500'],
    ['Postbank Ndl Deutsche Bank', '50010060', 'PBNKDEFFXXX'],
    ['AKBANK', '50010200', 'AKBKDEFFXXX'],
    ['First Commercial Bank, FFM', '50010300', 'FCBKDEFFXXX'],
    ['Aareal Bank Wiesbaden', '50010424', 'AARBDE5W500'],
    ['ING-DiBa Frankfurt am Main', '50010517', 'INGDDEFFXXX'],
    ['Degussa Bank Frankfurt Main', '50010700', 'DEGUDEFFXXX'],
    ['Bank of America Europe', '50010900', 'BOFADEFXXXX'],
    ['Bank of America Europe', '50010910', 'BOFADEFXVAM'],
    ['ALTE LEIPZIGER Bauspar', '50012800', 'ALTEDEFAXXX'],
    ['J&T Direktbank, Frankfurt', '50015001', 'JTBPDEFFXXX'],
    ['WUIB - Branch Germany', '50016600', 'WUIDDEF1XXX'],
    ['ODDO BHF Frankfurt Main', '50020200', 'BHFBDEFF500'],
    ['KfW Frankfurt', '50020400', 'KFWIDEFFXXX'],
    ['Landwirtschaftl Rentenbank', '50020500', 'LAREDEFFXXX'],
    ['Credit Europe Bank Ffm', '50020700', 'FBHLDEFFXXX'],
    ['Intesa Sanpaolo Frankfurt', '50020800', 'BCITDEFFXXX'],
    ['ING Bank, Frankfurt', '50021000', 'INGBDEFFXXX'],
    ['FIL Fondsbank Frankfurt', '50021100', 'FFBKDEFFKRN'],
    ['FIL Fondsbank Kronberg', '50021120', 'FFBKDEFFTHK'],
    ['HCBE Frankfurt am Main', '50022200', 'HCSEDEF1XXX'],
    ['C24 Bank', '50024024', 'DEFFDEFFXXX'],
    ['Opel Bank', '50025000', 'GMGGDE51XXX'],
    ['Unlimint Frankfurt', '50026600', 'CARDDEFFXXX'],
    ['PSA Bank Neu-Isenburg', '50030000', 'PSADDEF1XXX'],
    ['PSA Bank Neu-Isenburg', '50030010', 'BPNDDE52XXX'],
    ['BNP Paribas NDL Deutschland', '50030500', 'PARBDEFFXXX'],
    ['dwpbank', '50030600', 'DWPBDEFFXXX'],
    ['DenizBank Frankfurt', '50030700', 'ESBKDEFFXXX'],
    ['Triodos Bank Deutschland', '50031000', 'TRODDEF1XXX'],
    ['Santander Bank Frankfurt', '50033300', 'SCFBDE33XXX'],
    ['Agricultural Bank China FRA', '50038800', 'ABOCDEFFXXX'],
    ['Commerzbank Frankfurt Main', '50040000', 'COBADEFFXXX'],
    ['Commerzbank Fil. FFM 2', '50040005', 'COBADEFFXXX'],
    ['Commerzbank (IPC PARIS)FFM', '50040010', 'COBADEFFPAR'],
    ['Commerzbank (IPC VIENNA)FFM', '50040011', 'COBADEFFVIE'],
    ['Commerzbank (IPC MILANO)FFM', '50040012', 'COBADEFFMIL'],
    ['Commerzbank(AMSTERDAM)FFM', '50040013', 'COBADEFFAMS'],
    ['Commerzbank(IPC BRUSSEL)FFM', '50040014', 'COBADEFFBRU'],
    ['Commerzbank (IPC MADRID)FFM', '50040015', 'COBADEFFMAD'],
    ['Commerzbank(CLB New YorkFFM', '50040016', 'COBADEFFNYC'],
    ['CommerzbankCLB SingaporeFFM', '50040017', 'COBADEFFSGP'],
    ['Commerzbank (CLB London)FFM', '50040018', 'COBADEFFLDN'],
    ['Commerzbank (CLB Zürich)FFM', '50040019', 'COBADEFFZUR'],
    ['Commerzbank (CLB Prag) FFM', '50040020', 'COBADEFFPRA'],
    ['Commerzbank (CLB Moskau)FFM', '50040021', 'COBADEFFMOS'],
    ['Commerzbank(CLB Hamburg)FFM', '50040022', 'COBADEFFHBG'],
    ['Commerzbank Gf BRS Ffm', '50040033', 'COBADEF1BRS'],
    ['Commerzbank MBP, Frankfurt', '50040038', 'COBADEFFXXX'],
    ['Commerzbank ZRK Frankfurt', '50040040', 'COBADEFFXXX'],
    ['Commerzbank FFM GF-F48', '50040048', 'COBADEFFXXX'],
    ['Commerzbank CC SP, Ffm', '50040050', 'COBADEFFXXX'],
    ['Commerzbank FFM GM-F A 51', '50040051', 'COBADEFFXXX'],
    ['Commerzbank Service - BZ', '50040052', 'COBADEFFXXX'],
    ['Commerzbank Gf 460 Ffm', '50040060', 'COBADEFFXXX'],
    ['Commerzbank Gf 461 Ffm', '50040061', 'COBADEFFXXX'],
    ['Commerzbank CC Ffm', '50040062', 'COBADEFFXXX'],
    ['Commerzbank CC Ffm', '50040063', 'COBADEFFXXX'],
    ['Commerzbank Gf ZCM Ffm', '50040075', 'COBADEFFXXX'],
    ['Commerzbank GF WK CMTS2 FFM', '50040084', 'COBADEFFXXX'],
    ['Commerzbank Gf WK, Ffm', '50040085', 'COBADEFFXXX'],
    ['Commerzbank GF WK CMTS, FFM', '50040086', 'COBADEFFXXX'],
    ['Commerzbank Gf WK CMTS3 Ffm', '50040087', 'COBADEFFXXX'],
    ['Commerzbank INT 1 Ffm', '50040088', 'COBADEFFXXX'],
    ['Commerzbank INT Ffm', '50040099', 'COBADEFFXXX'],
    ['Commerzbank Frankfurt', '50042500', 'COBADEFFXXX'],
    ['Commerzbank Vermverw Ffm', '50044444', 'COBADEFFXXX'],
    ['Commerzbank Service - BZ', '50047010', 'COBADEFFXXX'],
    ['Ld Bk Hess-Thür Gz Ffm', '50050000', 'HELADEFFXXX'],
    ['Frankfurter Spk Frankfurt', '50050201', 'HELADEF1822'],
    ['Frankfurter Spk 1822direkt', '50050222', 'HELADEF1822'],
    ['DekaBank Frankfurt', '50050999', 'DGZFDEFFXXX'],
    ['DZ Bank', '50060000', 'GENODE55XXX'],
    ['DZ BANK', '50060400', 'GENODEFFXXX'],
    ['DZ BANK GF DZH', '50060412', 'GENODEF1VK1'],
    ['DZ BANK GF ODA', '50060413', 'GENODEF1VK2'],
    ['DZ BANK für BSH', '50060414', 'GENODEF1VK3'],
    ['DZ BANK für BSH', '50060415', 'GENODEF1VK4'],
    ['DZ BANK GF TSG', '50060416', 'GENODEF1VK6'],
    ['DZ BANK GF BMT', '50060417', 'GENODEF1VK7'],
    ['DZ BANK GF BMT', '50060418', 'GENODEF1VK8'],
    ['DZ BANK GF VRF', '50060419', 'GENODEF1VK9'],
    ['DZ BANK GF BSH', '50060420', 'GENODEF1V20'],
    ['DZ BANK GF BSH', '50060421', 'GENODEF1V21'],
    ['DZ BANK GF GRX', '50060422', 'GENODEF1V22'],
    ['DZ BANK GF GRX', '50060423', 'GENODEF1V23'],
    ['DZ BANK GF DEV', '50060424', 'GENODEF1V24'],
    ['DZ BANK GF BSH', '50060425', 'GENODEF1V25'],
    ['DZ BANK', '50060474', 'GENODEFFBRO'],
    ['RB im Hochtaunus Bad Hom', '50061741', 'GENODE51OBU'],
    ['VRB Alzey-Land-Schwabenheim', '50069126', 'GENODE51ABO'],
    ['Volksbank Grebenhain', '50069146', 'GENODE51GRC'],
    ['Volksbank Egelsbach -alt-', '50069187', 'GENODE51EGE'],
    ['Raika Erbes-Büdesheim -alt-', '50069241', 'GENODE51ERB'],
    ['Raiffbk Grävenwiesbach', '50069345', 'GENODE51GWB'],
    ['Hüttenberger Bk Hüttenberg', '50069455', 'GENODE51HUT'],
    ['Raiffeisenbank Kirtorf', '50069477', 'GENODE51KIF'],
    ['Raiffeisenbank Bad Homburg', '50069693', 'GENODE51BH1'],
    ['Raiffeisen-VB Schwabenheim', '50069842', 'GENODE51SWB'],
    ['Volksbank Wißmar', '50069976', 'GENODE51WWI'],
    ['Deutsche Bank', '50070010', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070010', 'DEUTDEFF500'],
    ['Deutsche Bank', '50070010', 'DEUTDEFF503'],
    ['Deutsche Bank', '50070010', 'DEUTDEFF504'],
    ['Deutsche Bank', '50070010', 'DEUTDEFF540'],
    ['Deutsche Bank', '50070010', 'DEUTDEFF541'],
    ['Deutsche Bank', '50070010', 'DEUTDEFF542'],
    ['Deutsche Bank', '50070011', 'DEUTDEFFSIP'],
    ['Deutsche Bank', '50070024', 'DEUTDEDB500'],
    ['Deutsche Bank', '50070024', 'DEUTDEDB503'],
    ['Deutsche Bank', '50070024', 'DEUTDEDB504'],
    ['Deutsche Bank', '50070024', 'DEUTDEDB535'],
    ['Deutsche Bank', '50070024', 'DEUTDEDB536'],
    ['Deutsche Bank', '50070024', 'DEUTDEDBFRA'],
    ['Deutsche Bank', '50070050', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070051', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070052', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070053', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070054', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070055', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070056', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070057', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070058', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070059', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070060', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070061', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070062', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070063', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070064', 'DEUTDEFFXXX'],
    ['Deutsche Bank', '50070065', 'DEUTDEFFXXX'],
    ['Postbank Ndl Deutsche Bank', '50070324', 'DEUTDEDBP25'],
    ['Postbank Ndl Deutsche Bank', '50070371', 'DEUTDEFFS25'],
    ['Deutsche Bank', '50070435', 'DEUTDEDBEW1'],
    ['Deutsche Bank', '50070436', 'DEUTDEDBEW2'],
    ['Deutsche Bank', '50070437', 'DEUTDEDBEW3'],
    ['Deutsche Bank', '50070438', 'DEUTDEDBEW4'],
    ['Deutsche Bank', '50070439', 'DEUTDEDBEW5'],
    ['Deutsche Bank', '50073019', 'DEUTDEFF502'],
    ['Deutsche Bank', '50073019', 'DEUTDEFF543'],
    ['Deutsche Bank', '50073024', 'DEUTDEDB502'],
    ['Deutsche Bank', '50073024', 'DEUTDEDB537'],
    ['Deutsche Bank Europe', '50073081', 'DEUTDE5XXXX'],
    ['Commerzbank Frankfurt', '50080000', 'DRESDEFFXXX'],
    ['Commerzbank Zw 15 Ffm', '50080015', 'DRESDEFFI39'],
    ['Commerzbank Zw 25 Ffm', '50080025', 'DRESDEFFXXX'],
    ['Commerzbank Zw 35 Ffm', '50080035', 'DRESDEFFI40'],
    ['Commerzbk Zw 55 Frankfurt', '50080055', 'DRESDEFF516'],
    ['Commerzbk ZW 57 Frankfurt', '50080057', 'DRESDEFF522'],
    ['Commerzbank Gf DrKW Ffm', '50080060', 'DRESDEFFXXX'],
    ['Commerzbank Gf DrKWSL Ffm', '50080061', 'DRESDEFFLDG'],
    ['Commerzbk WBSPK, Frankfurt', '50080077', 'DRESDEFFBSP'],
    ['Commerzbank ESOP, Frankfurt', '50080079', 'DRESDEFFXXX'],
    ['Commerzbank Bs 80 Ffm', '50080080', 'DRESDEFFI41'],
    ['Commerzbk Gf AVB Ffm', '50080082', 'DRESDEFFAVB'],
    ['Commerzbk ITGK Frankfurt', '50080086', 'DRESDEFFI49'],
    ['Commerzbank ITGK4 FFM', '50080087', 'DRESDEFFJ08'],
    ['Commerzbank ITGK5 FFM', '50080088', 'DRESDEFFJ09'],
    ['Commerzbank ITGK6 FFM', '50080089', 'DRESDEFFJ10'],
    ['Commerzbank ITGK7 FFM', '50080091', 'DRESDEFFJ11'],
    ['Commerzbk FCO Frankfurt', '50080092', 'DRESDEFFFCO'],
    ['Commerzbk Zw 99 Frankfurt', '50080099', 'DRESDEFFI42'],
    ['Commerzbank Priv Banking', '50080300', 'DRESDEFF500'],
    ['Commerzbank Rüsselsheim', '50083007', 'DRESDEFF502'],
    ['Commerzbank Ffm MBP', '50083838', 'DRESDEFFMBP'],
    ['Commerzbank Ffm ITGK', '50089400', 'DRESDEFFI01'],
    ['Sparda-Bank Hessen', '50090500', 'GENODEF1S12'],
    ['PSD Bank Hessen-Thüringen', '50090900', 'GENODEF1P06'],
    ['Spar- u Kreditbk Bad Hombg', '50092100', 'GENODE51BH2'],
    ['Volksbank Main-Taunus -alt-', '50092200', 'GENODE51KEL'],
    ['Volksbank Usinger Land', '50092900', 'GENODE51USI'],
    ['Rüsselsheimer Volksb. -alt-', '50093000', 'GENODE51RUS'],
    ['VB Rüsselsheim GAA -alt-', '50093010', 'GENODE51GAA'],
    ['Volksbank Kelsterbach', '50093400', 'GENODE51KBH'],
    ['ICBC Frankfurt, Main', '50110200', 'ICBKDEFFXXX'],
    ['AKA Ausfuhrkredit Frankfurt', '50110400', 'AUSKDEFFXXX'],
    ['NATIXIS Frankfurt am Main', '50110500', 'NATXDEFFXXX'],
    ['SCB FFM', '50110636', 'SCBLDEFXXXX'],
    ['FBG (D)', '50110700', 'FBGADEF1XXX'],
    ['J.P. Morgan Frankfurt', '50110800', 'CHASDEFXXXX'],
    ['J.P. Morgan Frankfurt', '50110801', 'CHASDEFXVR1'],
    ['JPMSE Frankfurt', '50110855', 'JPMGDEFFXXX'],
    ['Bank of America N.A. Mil Bk', '50110900', 'MNBIDEF1XXX'],
    ['Stifel Europe Bank', '50120000', 'MAIFDEFFXXX'],
    ['ICICI Bank Eschborn', '50120100', 'ICICDEFFXXX'],
    ['ABN AMRO Bank Ffm', '50120383', 'DELBDE33XXX'],
    ['CSD Frankfurt Main', '50120500', 'CRESDE55XXX'],
    ['Bank of Communications', '50120600', 'COMMDEFFXXX'],
    ['AION Bank Frankfurt', '50120800', 'BMPBDEF2XXX'],
    ['VakifBank Köln', '50120900', 'TVBADEFFXXX'],
    ['VTB Direktbank', '50123400', 'DOBADEF1XXX'],
    ['PKO Bank Polski Frankfurt', '50127000', 'BPKODEFFXXX'],
    ['Naba Frankfurt Main', '50130000', 'NBPADEFFXXX'],
    ['Merck Finck', '50130400', 'MEFIDEMM501'],
    ['UBS Europe', '50130600', 'UBSWDEFFXXX'],
    ['VietinBank Frankfurt', '50131000', 'ICBVDEFFXXX'],
    ['Frankfurter Voba Rhein-Main', '50190000', 'FFVBDEFFXXX'],
    ['VB Höchst a.M.Frankfurt alt', '50190300', 'GENODE51FHC'],
    ['VB Griesheim Ffm -alt-', '50190400', 'GENODE51FGH'],
    ['Rabobank Frankfurt Main', '50210200', 'RABODEFFTAR'],
    ['Rabobank International CMS', '50210295', 'RABODEFFXXX'],
    ['ProCredit Bank, Frankfurt', '50210800', 'PRCBDEFFXXX'],
    ['CEP Germany Branch', '50210900', 'CITIDEFFXXX'],
    ['UBS Europe', '50220085', 'SMHBDEFFXXX'],
    ['Kommunalkredit Deutschland', '50220101', 'INVODEF2XXX'],
    ['WOORI BANK', '50220300', 'HVBKDEFFXXX'],
    ['Lloyds Bank', '50220500', 'BOFSDEF1XXX'],
    ['CAIXABANK Zndl Frankfurt', '50220707', 'CAIXDEFFXXX'],
    ['Hauck Aufhäuser Lampe Ffm', '50220900', 'HAUKDEFFXXX'],
    ['ABC ZNL Frankfurt', '50230000', 'ABCADEFFXXX'],
    ['Isbank Frankfurt Main', '50230600', 'ISBKDEFXXXX'],
    ['Bankhaus Metzler Frankfurt', '50230700', 'METZDEFFXXX'],
    ['Ikano Wiesbaden', '50230800', 'PLFGDE5AXXX'],
    ['Ikano Wiesbaden', '50230888', 'PLFGDE5AIKB'],
    ['KT Bank Frankfurt', '50234500', 'KTAGDEFFXXX'],
    ['DL Finance Bad Homburg', '50250200', 'DLFGDE51XXX'],
    ['Barclays Bank IE, Frankfurt', '50310400', 'BARCDEFFXXX'],
    ['CCB Frankfurt', '50310900', 'PCBCDEFFXXX'],
    ['VTB Bank (Europe) Ffm', '50320000', 'OWHBDEFFXXX'],
    ['UniCredit Bank-HypoVereinbk', '50320191', 'HYVEDEMM430'],
    ['Banco Santander Ffm', '50320500', 'BSCHDEFFXXX'],
    ['Attijariwafa bank Frankfurt', '50320600', 'BCMADEFFXXX'],
    ['Pictet & Cie Europe Ffm', '50320900', 'PICTDEFFXXX'],
    ['ABN AMRO Bank', '50324000', 'FTSBDEFAXXX'],
    ['State Bk of India Frankfurt', '50330000', 'SBINDEFFXXX'],
    ['Raisin Bank Frankfurt', '50330200', 'MHBFDEFFXXX'],
    ['Raisin Bank', '50330201', 'RAISDEFFXXX'],
    ['The Bank of New York Mellon', '50330300', 'IRVTDEFXXXX'],
    ['CHAABI BANK FRANKFURT', '50330500', 'BCDMDEF1XXX'],
    ['Bank Sepah Frankfurt, Main', '50330600', 'SEPBDEFFXXX'],
    ['BNY Mellon NL FFM', '50334400', 'BNYMDEF1XXX'],
    ['Unifiedpost Payments', '50339900', 'PANXDEF2XXX'],
    ['BBk Zentrale Frankfurt Main', '50400000', 'MARKDEFFXXX'],
    ['SMBC Bank EU - FFM a.M.', '50510300', 'SMBCDEFFXXX'],
    ['UniCredit Bank-HypoVereinbk', '50520190', 'HYVEDEMM467'],
    ['FIDOR Bank Frankfurt', '50522222', 'FDDODEMMXXX'],
    ['Cronbank', '50530000', 'GENODE51CRO'],
    ['Commerzbank Offenbach Main', '50540028', 'COBADEFFXXX'],
    ['Städtische Spk Offenbach', '50550020', 'HELADEF1OFF'],
    ['Raiffbk Offenbach -alt-', '50560102', 'GENODE51OF2'],
    ['Ver VB Maingau -alt-', '50561315', 'GENODE51OBH'],
    ['Deutsche Bank', '50570018', 'DEUTDEFF505'],
    ['Deutsche Bank', '50570018', 'DEUTDEFF507'],
    ['Deutsche Bank', '50570018', 'DEUTDEFF544'],
    ['Deutsche Bank', '50570018', 'DEUTDEFF545'],
    ['Deutsche Bank', '50570018', 'DEUTDEFF546'],
    ['Deutsche Bank', '50570018', 'DEUTDEFF547'],
    ['Deutsche Bank', '50570018', 'DEUTDEFF548'],
    ['Deutsche Bank', '50570018', 'DEUTDEFF549'],
    ['Deutsche Bank', '50570018', 'DEUTDEFF550'],
    ['Deutsche Bank', '50570024', 'DEUTDEDB505'],
    ['Deutsche Bank', '50570024', 'DEUTDEDB507'],
    ['Deutsche Bank', '50570024', 'DEUTDEDB525'],
    ['Deutsche Bank', '50570024', 'DEUTDEDB526'],
    ['Deutsche Bank', '50570024', 'DEUTDEDB527'],
    ['Deutsche Bank', '50570024', 'DEUTDEDB528'],
    ['Deutsche Bank', '50570024', 'DEUTDEDB529'],
    ['Deutsche Bank', '50570024', 'DEUTDEDB538'],
    ['Deutsche Bank', '50570024', 'DEUTDEDB539'],
    ['Commerzbank Offenbach', '50580005', 'DRESDEFF505'],
    ['Commerzbank ITGK1 Offenbc', '50580085', 'DRESDEFFJ12'],
    ['Offenbacher Volksbank -alt-', '50590000', 'GENODE51OF1'],
    ['VR Bank Dreieich-Offenbach', '50592200', 'GENODE51DRE'],
    ['Commerzbank Hanau Main', '50640015', 'COBADEFFXXX'],
    ['SPARKASSE HANAU', '50650023', 'HELADEF1HAN'],
    ['Spk Langen-Seligenstadt', '50652124', 'HELADEF1SLS'],
    ['VR Bk Main-Kinzig-Büdingen', '50661639', 'GENODEF1LSR'],
    ['Volksbank Heldenbergen', '50661816', 'GENODE51NIH'],
    ['Raiffbk Bruchköbel -alt-', '50662299', 'GENODEF1BKO'],
    ['Frankfurter Volksbank', '50662669', 'RBMFDEF1XXX'],
    ['Raiffbk Rodenbach Hanau', '50663699', 'GENODEF1RDB'],
    ['Deutsche Bank', '50670009', 'DEUTDEFF506'],
    ['Deutsche Bank', '50670024', 'DEUTDEDB506'],
    ['Commerzbank Hanau Main', '50680002', 'DRESDEFF506'],
    ['Commerzbank ITGK1 Hanau', '50680085', 'DRESDEFFJ13'],
    ['Frankfurter Volksbank', '50690000', 'GENODEF1HUV'],
    ['DZB BANK Mainhausen', '50691300', 'DZBMDEF1XXX'],
    ['Volksbank Seligenstadt', '50692100', 'GENODE51SEL'],
    ['Commerzbank GH F-G48', '50740048', 'COBADEFFXXX'],
    ['Kreissparkasse Gelnhausen', '50750094', 'HELADEF1GEL'],
    ['Volksbank Büdingen -alt-', '50761333', 'GENODE51BUE'],
    ['Raiffbk Vogelsberg Birstein', '50763319', 'GENODEF1BIR'],
    ['Commerzbank Gelnhausen', '50780006', 'DRESDEFF524'],
    ['VR Bank Bad Orb-Gelnhausen', '50790000', 'GENODE51GEL'],
    ['Birsteiner Volksbank -alt-', '50793300', 'GENODE51BIV'],
    ['VR Bank Wächtersbach -alt-', '50794300', 'GENODE51WBH'],
    ['DBS Badenia eh. DBS Bauspar', '50810900', 'BBSPDE6KXXX'],
    ['UniCredit Bank-HypoVereinbk', '50820292', 'HYVEDEMM487'],
    ['MCE Bank Flörsheim', '50835800', 'MKGMDE51XXX'],
    ['Commerzbank Darmstadt', '50840005', 'COBADEFFXXX'],
    ['LdBk Hess-Thür Gz Darmstadt', '50850049', 'HELADEFF508'],
    ['St u Kr Spk Darmstadt', '50850150', 'HELADEF1DAS'],
    ['Spk Odenwaldkreis Erbach', '50851952', 'HELADEF1ERB'],
    ['Kr Spk Groß-Gerau', '50852553', 'HELADEF1GRG'],
    ['Sparkasse Dieburg', '50852651', 'HELADEF1DIE'],
    ['SpDK Zell -alt-', '50861393', 'GENODE51BKZ'],
    ['Raiffbk Nördliche Bergstr', '50861501', 'GENODE51ABH'],
    ['VB Gräfenhausen -alt-', '50862311', 'GENODE51WGH'],
    ['Ver VB Griesh-Weiterst -alt', '50862408', 'GENODE51GRI'],
    ['VB Gersprenztal-Otzberg', '50862703', 'GENODE51REI'],
    ['Raiffeisenbank Schaafheim', '50862835', 'GENODE51SHM'],
    ['Volksbank Mainspitze', '50862903', 'GENODE51GIN'],
    ['Vereinigte VB RB', '50863513', 'GENODE51MIC'],
    ['Volksbank Mörfelden-Walldf', '50865224', 'GENODE51MWA'],
    ['VB Eppertshausen -alt-', '50865503', 'GENODE51EPT'],
    ['Deutsche Bank', '50870005', 'DEUTDEFF508'],
    ['Deutsche Bank', '50870005', 'DEUTDEFF551'],
    ['Deutsche Bank', '50870005', 'DEUTDEFF552'],
    ['Deutsche Bank', '50870024', 'DEUTDEDB508'],
    ['Deutsche Bank', '50870024', 'DEUTDEDB554'],
    ['Deutsche Bank', '50870024', 'DEUTDEDB555'],
    ['Postbank Ndl Deutsche Bank', '50870324', 'DEUTDEDBP26'],
    ['Postbank Ndl Deutsche Bank', '50870393', 'DEUTDEFFS26'],
    ['Commerzbank Darmstadt', '50880050', 'DRESDEFF508'],
    ['Commerzbank ITGK1 Darmstd', '50880085', 'DRESDEFFJ14'],
    ['Commerzbank ITGK2 Darmstd', '50880086', 'DRESDEFFJ15'],
    ['VB Darmstadt - Südhessen', '50890000', 'GENODEF1VBD'],
    ['Sparkasse Bensheim', '50950068', 'HELADEF1BEN'],
    ['Sparkasse Starkenburg', '50951469', 'HELADEF1HEP'],
    ['VR Bank Ried-Überwald, Bürs', '50961206', 'GENODE51RBU'],
    ['Raiffbk Groß-Rohrheim', '50961312', 'GENODE51GRM'],
    ['Volksbank Weschnitztal', '50961592', 'GENODE51FHO'],
    ['VB Überwald-Gorxheim -alt-', '50961685', 'GENODE51ABT'],
    ['Deutsche Bank', '50970004', 'DEUTDEFF509'],
    ['Deutsche Bank', '50970004', 'DEUTDEFF519'],
    ['Deutsche Bank', '50970024', 'DEUTDEDB509'],
    ['Deutsche Bank', '50970024', 'DEUTDEDB519'],
    ['Aareal Bank', '51010400', 'AARBDE5WXXX'],
    ['BHF-BANK Wiesbaden', '51020000', 'BHFBDEFF510'],
    ['UniCredit Bank-HypoVereinbk', '51020186', 'HYVEDEMM478'],
    ['Commerzbank Wiesbaden', '51040038', 'COBADEFFXXX'],
    ['Nass Spk Wiesbaden', '51050015', 'NASSDE55XXX'],
    ['S Broker Wiesbaden', '51051000', 'PULSDE5WXXX'],
    ['Deutsche Bank', '51070021', 'DEUTDEFF510'],
    ['Deutsche Bank', '51070021', 'DEUTDEFF512'],
    ['Deutsche Bank', '51070021', 'DEUTDEFF514'],
    ['Deutsche Bank', '51070024', 'DEUTDEDB510'],
    ['Deutsche Bank', '51070024', 'DEUTDEDB512'],
    ['Deutsche Bank', '51070024', 'DEUTDEDB514'],
    ['Commerzbank Wiesbaden', '51080060', 'DRESDEFF510'],
    ['Commerzbank ITGK1 Wiesbdn', '51080085', 'DRESDEFFJ16'],
    ['Commerzbank ITGK2 Wiesbdn', '51080086', 'DRESDEFFJ17'],
    ['Commerzbank Wiesbad. ITGK', '51089410', 'DRESDEFFI20'],
    ['Wiesbadener Volksbank', '51090000', 'WIBADE5WXXX'],
    ['Rheingauer Volksbank', '51091500', 'GENODE51RGG'],
    ['Rheingauer Voba (Gf GAA)', '51091501', 'GENODE51RGG'],
    ['vr bank Untertaunus -alt-', '51091700', 'VRBUDE51XXX'],
    ['vr bank Untertaunus -alt-', '51091711', 'VRBUDE51XXX'],
    ['Commerzbank Limburg Lahn', '51140029', 'COBADEFFXXX'],
    ['Kr Spk Limburg', '51150018', 'HELADEF1LIM'],
    ['Kr Spk Weilburg', '51151919', 'HELADEF1WEI'],
    ['Volksbank Langendernbach', '51161606', 'GENODE51LDD'],
    ['Deutsche Bank', '51170010', 'DEUTDEFF511'],
    ['Deutsche Bank', '51170024', 'DEUTDEDB511'],
    ['Commerzbank Limburg Lahn', '51180041', 'DRESDEFF511'],
    ['Ver Volksbank Limburg -alt-', '51190000', 'GENODE51LIM'],
    ['Volksbank Schupbach', '51191800', 'GENODE51SBH'],
    ['VuR-Bank Weilmünster -alt-', '51192200', 'GENODE51WEM'],
    ['BNP PARIBAS Ndl Deutschland', '51210600', 'BNPADEFFXXX'],
    ['BNP PARIBAS Frankfurt', '51210606', 'BNPADEFFXXX'],
    ['BNP PARIBAS Ndl Deutschland', '51210699', 'BNPADEFFXXX'],
    ['NIBC Bank Frankfurt am Main', '51210700', 'NZFMDEF1XXX'],
    ['SOGEBANK Frankfurt Main', '51210800', 'SOGEDEFFXXX'],
    ['Societe Generale', '51210801', 'SGSSDEFFXXX'],
    ['Societe Generale', '51210802', 'SOGEDEFFXXX'],
    ['Societe Generale', '51210803', 'SOGEDEFFXXX'],
    ['NATIXIS Pfandbriefbank Ffm', '51211000', 'NATXDEFPXXX'],
    ['SEB, Frankfurt', '51220200', 'ESSEDEFFXXX'],
    ['Bank Saderat Iran Frankfurt', '51220400', 'SIHRDEH1FFM'],
    ['ZIRAAT BANK', '51220700', 'TCZBDEFFXXX'],
    ['Banco do Brasil Frankfurt', '51220800', 'BRASDEFFXXX'],
    ['Morgan Stanley Bank', '51220900', 'MSFFDEFPXXX'],
    ['Morgan Stanley Bank', '51220910', 'MSFFDEFXCND'],
    ['SCB Ffm', '51230500', 'SCBLDEFXXXX'],
    ['SCB FFM', '51230502', 'SCBLDEFXXXX'],
    ['Ikano Wiesbaden', '51230555', 'PLFGDE5AIKB'],
    ['Europe ARAB Bank Frankfurt', '51230600', 'ARABDEFFXXX'],
    ['Taunus-Sparkasse Bad Hombg', '51250000', 'HELADEF1TSK'],
    ['BBk Frankfurt eh Gießen', '51300000', 'MARKDEF1513'],
    ['Commerzbank Gießen', '51340013', 'COBADEFFXXX'],
    ['Commerzbank Alsfeld', '51343224', 'COBADEFFXXX'],
    ['Sparkasse Gießen', '51350025', 'SKGIDE5FXXX'],
    ['Sparkasse Grünberg', '51351526', 'HELADEF1GRU'],
    ['Sparkasse Laubach-Hungen', '51352227', 'HELADEF1LAU'],
    ['Volksbank Heuchelheim', '51361021', 'GENODE51HHE'],
    ['Deutsche Bank', '51370008', 'DEUTDEFF513'],
    ['Deutsche Bank', '51370024', 'DEUTDEDB513'],
    ['Commerzbank Gießen', '51380040', 'DRESDEFF513'],
    ['Commerzbank ITGK1 Gießen', '51380085', 'DRESDEFFJ18'],
    ['VB Mittelhessen', '51390000', 'VBMHDE5FXXX'],
    ['Bank of America Frankfurt', '51410600', 'BOFADEFFXXX'],
    ['Bank of China Frankfurt', '51410700', 'BKCHDEFFXXX'],
    ['Commerzbank - GF onvista', '51410800', 'BOURDEFFXXX'],
    ['Misr Bank-Europe Ffm', '51420200', 'MIBEDEFFXXX'],
    ['Bank Julius Bär Frankfurt', '51420300', 'BAERDEF1XXX'],
    ['Goldman Sachs Frankfurt', '51430400', 'GOLDDEFFXXX'],
    ['Goldman Sachs Frankfurt', '51430410', 'GOLDDEFBXXX'],
    ['Saman Bank Frankfurt', '51432100', 'SABCDEFFXXX'],
    ['Commerzbank Wetzlar', '51540037', 'COBADEFFXXX'],
    ['Sparkasse Wetzlar', '51550035', 'HELADEF1WET'],
    ['Deutsche Bank', '51570008', 'DEUTDEFF515'],
    ['Deutsche Bank', '51570024', 'DEUTDEDB515'],
    ['Commerzbank Wetzlar', '51580044', 'DRESDEFF515'],
    ['Volksbank Brandoberndorf', '51591300', 'GENODE51WBO'],
    ['Commerzbank Dillenburg', '51640043', 'COBADEFFXXX'],
    ['Spk Dillenburg', '51650045', 'HELADEF1DIL'],
    ['Volksbank Dill -alt-', '51690000', 'GENODE51DIL'],
    ['Voba Herborn-Eschenbg -alt-', '51691500', 'GENODE51HER'],
    ['Sparkasse Battenberg', '51752267', 'HELADEF1BAT'],
    ['VR Bank Lahn-Dill', '51762434', 'GENODE51BIK'],
    ['Spk Oberhessen', '51850079', 'HELADEF1FRI'],
    ['BVB Volksbank', '51861325', 'GENODEF1BVB'],
    ['Volksbank Butzbach', '51861403', 'GENODE51BUT'],
    ['LdBk Horlofftal Reichelshei', '51861616', 'GENODE51REW'],
    ['Volksbank Ober-Mörlen', '51861806', 'GENODE51OBM'],
    ['Volksbank Ulrichstein', '51961023', 'GENODE51ULR'],
    ['Spar-u Darlehnskasse -alt-', '51961515', 'GENODE51HSH'],
    ['Volksbank Feldatal', '51961801', 'GENODE51FEL'],
    ['Volksbank Lauterbach-Schl', '51990000', 'GENODE51LB1'],
    ['Commerzbank Kassel', '52040021', 'COBADEFFXXX'],
    ['Landeskreditkasse Kassel', '52050000', 'HELADEFF520'],
    ['Kasseler Sparkasse', '52050353', 'HELADEF1KAS'],
    ['St Spk Borken -alt-', '52051373', 'HELADEF1BOR'],
    ['St Spk Felsberg -alt-', '52051555', 'HELADEF1FEL'],
    ['St Spk Grebenstein', '52051877', 'HELADEF1GRE'],
    ['Kreissparkasse Schwalm-Eder', '52052154', 'HELADEF1MEG'],
    ['Spk Borken-Schwalmst,Borken', '52053458', 'HELADEF1SWA'],
    ['DZ BANK', '52060000', 'GENODEFF520'],
    ['Kurhessische Landbk Kassel', '52060208', 'GENODEF1KS2'],
    ['Evangelische Bank Kassel', '52060410', 'GENODEF1EK1'],
    ['Evangelische Bank', '52060420', 'GENODEF1EK1'],
    ['RB Borken Nordhessen -alt-', '52061303', 'GENODEF1BOR'],
    ['VR-Bank Chattengau -alt-', '52062200', 'GENODEF1GUB'],
    ['VR PartnerBank', '52062601', 'GENODEF1HRV'],
    ['VR-Bank Spangenbg-Morschen', '52063369', 'GENODEF1SPB'],
    ['Raiffeisenbank HessenNord', '52063550', 'GENODEF1WOH'],
    ['Raiffeisenbank Baunatal', '52064156', 'GENODEF1BTA'],
    ['RB Burghaun -alt-', '52069013', 'GENODEF1BHN'],
    ['Spar-u Kredit-Bank Gemünden', '52069029', 'GENODEF1GMD'],
    ['Raiffbk Langenschwarz -alt-', '52069065', 'GENODEF1BUR'],
    ['Raiffbk Volkmarsen -alt-', '52069149', 'GENODEF1VLM'],
    ['Frankenberger Bank', '52069519', 'GENODEF1FBK'],
    ['Deutsche Bank', '52070012', 'DEUTDEFF520'],
    ['Deutsche Bank', '52070012', 'DEUTDEFF523'],
    ['Deutsche Bank', '52070012', 'DEUTDEFF524'],
    ['Deutsche Bank', '52070024', 'DEUTDEDB520'],
    ['Deutsche Bank', '52070024', 'DEUTDEDB523'],
    ['Deutsche Bank', '52070024', 'DEUTDEDB524'],
    ['Deutsche Bank', '52071212', 'DEUTDEFF521'],
    ['Deutsche Bank', '52071224', 'DEUTDEDB521'],
    ['Commerzbank Kassel', '52080080', 'DRESDEFF520'],
    ['Commerzbank ITGK1 Kassel', '52080085', 'DRESDEFFJ19'],
    ['VB Kassel Göttingen', '52090000', 'GENODE51KS1'],
    ['Commerzbank Eschwege', '52240006', 'COBADEFFXXX'],
    ['Sparkasse Werra-Meißner', '52250030', 'HELADEF1ESW'],
    ['VR-Bank Mitte', '52260385', 'GENODEF1ESW'],
    ['Deutsche Bank', '52270012', 'DEUTDEFF522'],
    ['Deutsche Bank', '52270024', 'DEUTDEDB522'],
    ['Spk Waldeck-Frankenberg', '52350005', 'HELADEF1KOR'],
    ['Waldecker Bank Korbach', '52360059', 'GENODEF1KBW'],
    ['ReiseBank Frankfurt am Main', '52410300', 'RBAGDEF1XXX'],
    ['ReiseBank Gf2 Frankfurt', '52410310', 'RBAGDEF1CMI'],
    ['KEB Hana Bank (Deutschland)', '52410400', 'KOEXDEFAXXX'],
    ['ReiseBank vorm.Cash Express', '52411000', 'RBAGDEF1CMI'],
    ['ReiseBank vorm.Cash Express', '52411010', 'RBAGDEF1CMI'],
    ['Credit Agricole Deutschland', '52420000', 'BSUIDEFFXXX'],
    ['SHINHAN BANK EUROPE Ffm', '52420300', 'SHBKDEFFXXX'],
    ['Piraeus Bank Frankfurt', '52420600', 'ABGRDEFFXXX'],
    ['SECB Frankfurt Main', '52420700', 'SECGDEFFXXX'],
    ['TARGOBANK CIB Frankfurt', '52430000', 'CMCIDEF1XXX'],
    ['Commerzbank Fulda', '53040012', 'COBADEFFXXX'],
    ['Sparkasse Fulda', '53050180', 'HELADEF1FDS'],
    ['Kreissparkasse Schlüchtern', '53051396', 'HELADEF1SLU'],
    ['VR Bank Fulda', '53060180', 'GENODE51FUL'],
    ['VR-Bank NordRhön Hünfeld', '53061230', 'GENODEF1HUE'],
    ['VR Bk Schlü.-Birstein -alt-', '53061313', 'GENODE51SLU'],
    ['RB im Fuldaer LandGrl.', '53062035', 'GENODEF1GLU'],
    ['Raiffbk Biebergrd-Petersbg', '53062350', 'GENODEF1PBG'],
    ['Raiffeisenbank Flieden-alt-', '53064023', 'GENODEF1FLN'],
    ['Deutsche Bank', '53070007', 'DEUTDEFF530'],
    ['Deutsche Bank', '53070007', 'DEUTDEFF531'],
    ['Deutsche Bank', '53070007', 'DEUTDEFF534'],
    ['Deutsche Bank', '53070024', 'DEUTDEDB530'],
    ['Deutsche Bank', '53070024', 'DEUTDEDB531'],
    ['Deutsche Bank', '53070024', 'DEUTDEDB534'],
    ['Postbank Ndl Deutsche Bank', '53070324', 'DEUTDEDBP27'],
    ['Postbank Ndl Deutsche Bank', '53070394', 'DEUTDEFFS27'],
    ['Commerzbank Fulda', '53080030', 'DRESDEFF530'],
    ['VR Bank HessenLand', '53093200', 'GENODE51ALS'],
    ['AgrarBank Alsfeld', '53093255', 'GENODE51AGR'],
    ['Commerzbank Bad Hersfeld', '53240048', 'COBADEFFXXX'],
    ['Spk Bad Hersfeld-Rotenburg', '53250000', 'HELADEF1HER'],
    ['Raiffbk Asbach-Sorga -alt-', '53260145', 'GENODEF1HFA'],
    ['Bankverein Bebra -alt-', '53261202', 'GENODEF1BEB'],
    ['Raiffbk Werratal-Landeck', '53261342', 'GENODEF1RAW'],
    ['Raiffbk Haunetal -alt-', '53262073', 'GENODEF1HNT'],
    ['Raiffbk Ronshausen -alt-', '53262455', 'GENODEF1ROH'],
    ['Deutsche Bank', '53270012', 'DEUTDEFF532'],
    ['Deutsche Bank', '53270024', 'DEUTDEDB518'],
    ['Commerzbank Bad Hersfeld', '53280081', 'DRESDEFF532'],
    ['VR-Bankverein Bad Hersfeld', '53290000', 'GENODE51BHE'],
    ['Commerzbank Marburg Lahn', '53340024', 'COBADEFFXXX'],
    ['Spk Marburg-Biedenkopf', '53350000', 'HELADEF1MAR'],
    ['Raiffbk Ebsdorfergrund-alt-', '53361724', 'GENODEF1EBG'],
    ['Deutsche Bank', '53370008', 'DEUTDEFF533'],
    ['Deutsche Bank', '53370024', 'DEUTDEDB533'],
    ['Commerzbank Marburg Lahn', '53380042', 'DRESDEFF533'],
    ['Commerzbank Stadtallendf', '53381843', 'DRESDEFF568'],
    ['UniCredit Bank-HypoVereinbk', '54020090', 'HYVEDEMM482'],
    ['Service Credit Union', '54030011', 'SCRUDE51XXX'],
    ['Commerzbank Kaiserslautern', '54040042', 'COBADEFFXXX'],
    ['St Spk Kaiserslautern -alt-', '54050110', 'MALADE51KLS'],
    ['Spk Kaiserslautern', '54050220', 'MALADE51KLK'],
    ['Kr Spk Kusel', '54051550', 'MALADE51KUS'],
    ['St Spk Landstuhl -alt-', '54051660', 'MALADE51LAS'],
    ['Sparkasse Donnersberg', '54051990', 'MALADE51ROK'],
    ['VR-Bank Westpfalz -alt-', '54061650', 'GENODE61LAN'],
    ['Raiffbk Albisheim -alt-', '54062027', 'GENODE61ALB'],
    ['Deutsche Bank', '54070024', 'DEUTDEDB540'],
    ['Deutsche Bank', '54070024', 'DEUTDEDB541'],
    ['Deutsche Bank', '54070092', 'DEUTDESM540'],
    ['Deutsche Bank', '54070092', 'DEUTDESM541'],
    ['Commerzbk Kaiserslautern', '54080021', 'DRESDEFF540'],
    ['Voba Kaiserslautern', '54090000', 'GENODE61KL1'],
    ['Volksbank Lauterecken', '54091700', 'GENODE61LEK'],
    ['VR Bank Nordwestpfalz -alt-', '54091800', 'GENODE61OB1'],
    ['Volksbank Glan-Münchweiler', '54092400', 'GENODE61GLM'],
    ['UniCredit Bank-HypoVereinbk', '54220091', 'HYVEDEMM485'],
    ['Commerzbank Pirmasens', '54240032', 'COBADEFFXXX'],
    ['Spk Südwestpfalz Pirmasens', '54250010', 'MALADE51SWP'],
    ['VR-Bank Südwestpfalz', '54261700', 'GENODE61ROA'],
    ['Deutsche Bank', '54270024', 'DEUTDEDB542'],
    ['Deutsche Bank', '54270024', 'DEUTDEDB543'],
    ['Deutsche Bank', '54270096', 'DEUTDESM542'],
    ['Deutsche Bank', '54270096', 'DEUTDESM543'],
    ['Commerzbank Pirmasens', '54280023', 'DRESDEFF542'],
    ['Raiffbk u Volksbk Dahn-alt-', '54291200', 'GENODE61DAH'],
    ['BBk Ludwigshafen am Rhein', '54500000', 'MARKDEF1545'],
    ['Postbank Ndl Deutsche Bank', '54510067', 'PBNKDEFFXXX'],
    ['UniCredit Bank-HypoVereinbk', '54520194', 'HYVEDEMM483'],
    ['Commerzbank Ludwigshafen Rh', '54540033', 'COBADEFFXXX'],
    ['Sparkasse Vorderpfalz', '54550010', 'LUHSDE6AXXX'],
    ['Kreissparkasse Rhein-Pfalz', '54550120', 'MALADE51LUH'],
    ['RV Bank Rhein-Haardt -alt-', '54561310', 'GENODE61LBS'],
    ['Deutsche Bank', '54570024', 'DEUTDEDB544'],
    ['Deutsche Bank', '54570024', 'DEUTDEDB545'],
    ['Deutsche Bank', '54570024', 'DEUTDEDB547'],
    ['Deutsche Bank', '54570024', 'DEUTDEDB549'],
    ['Deutsche Bank', '54570024', 'DEUTDEDB550'],
    ['Deutsche Bank', '54570024', 'DEUTDEDB553'],
    ['Deutsche Bank', '54570094', 'DEUTDESM544'],
    ['Deutsche Bank', '54570094', 'DEUTDESM545'],
    ['Deutsche Bank', '54570094', 'DEUTDESM547'],
    ['Deutsche Bank', '54570094', 'DEUTDESM549'],
    ['Deutsche Bank', '54570094', 'DEUTDESM550'],
    ['Deutsche Bank', '54570094', 'DEUTDESM553'],
    ['Commerzbank Ludwigshafen', '54580020', 'DRESDEFF545'],
    ['UniCredit Bank-HypoVereinbk', '54620093', 'HYVEDEMM620'],
    ['Commerzbank Neustadt Weinst', '54640035', 'COBADEFFXXX'],
    ['Spk Rhein-Haardt', '54651240', 'MALADE51DKH'],
    ['Raiffbk Freinsheim -alt-', '54661800', 'GENODE61FSH'],
    ['Raiffbk Friedelsheim -alt-', '54663270', 'GENODE61FHR'],
    ['Deutsche Bank', '54670024', 'DEUTDEDB546'],
    ['Deutsche Bank', '54670024', 'DEUTDEDB548'],
    ['Deutsche Bank', '54670024', 'DEUTDEDB552'],
    ['Deutsche Bank', '54670095', 'DEUTDESM546'],
    ['Deutsche Bank', '54670095', 'DEUTDESM548'],
    ['Deutsche Bank', '54670095', 'DEUTDESM552'],
    ['Commerzbank Neustadt', '54680022', 'DRESDEFF546'],
    ['VR Bank Mittelhaardt', '54691200', 'GENODE61DUW'],
    ['Kr u St Spk Speyer', '54750010', 'MALADE51SPY'],
    ['VerVR Bank Kur-u Rheinpfalz', '54790000', 'GENODE61SPE'],
    ['Sparkasse Südpfalz', '54850010', 'SOLADES1SUW'],
    ['Spk Kandel Pfalz', '54851440', 'MALADE51KAD'],
    ['Raiffbk Oberhaardt-Gäu-alt-', '54861190', 'GENODE61EDH'],
    ['Raiffbk Herxheim -alt-', '54862390', 'GENODE61HXH'],
    ['VR Bank Südpfalz', '54862500', 'GENODE61SUW'],
    ['VR Bank Südl Weinstr-Wasgau', '54891300', 'GENODE61BZA'],
    ['BBk Mainz', '55000000', 'MARKDEF1550'],
    ['Aareal Bank GF - BK01 -', '55010400', 'AARBDE5WDOM'],
    ['Aareal Bank Wiesbaden', '55010424', 'AARBDE5W550'],
    ['Aareal Clearing Wiesbaden', '55010625', 'AARBDE5WCLE'],
    ['ISB RP', '55010800', 'ISBRDE55XXX'],
    ['BHF-BANK Mainz', '55020000', 'BHFBDEFF550'],
    ['Bausparkasse Mainz', '55020100', 'BKMZDE51XXX'],
    ['UniCredit Bank-HypoVereinbk', '55020486', 'HYVEDEMM486'],
    ['Bank für Sozialwirtschaft', '55020500', 'BFSWDE33MNZ'],
    ['Sozialbank Mainz', '55020555', 'BFSWDE33MNZ'],
    ['Aareal Bank', '55020600', 'IMMODE5MXXX'],
    ['SWK-Bank Bingen', '55020700', 'SUFGDE51XXX'],
    ['TARGOBANK Gf FG', '55030500', 'CPLADE55XXX'],
    ['Santander Bank Mainz', '55033300', 'SCFBDE33XXX'],
    ['Commerzbank Mainz', '55040022', 'COBADEFFXXX'],
    ['Commerzbank CC Mainz', '55040060', 'COBADEFFXXX'],
    ['Commerzbank CC Mainz', '55040061', 'COBADEFFXXX'],
    ['ZV LBBW Mainz', '55050000', 'SOLADEST550'],
    ['Sparkasse Mainz -alt-', '55050120', 'MALADE51MNZ'],
    ['VR-Bank Mainz -alt-', '55060321', 'GENODE51MZ4'],
    ['VR-Bank Mainz -alt-', '55060417', 'GENODE51MZ2'],
    ['Genobank Mainz', '55060611', 'GENODE51MZ6'],
    ['Budenheimer Volksbank', '55061303', 'GENODE51BUD'],
    ['VR-Bank Mainz -alt-', '55061507', 'GENODE51HDS'],
    ['Volksbank Rhein-Selz -alt-', '55061907', 'GENODE51NIS'],
    ['Deutsche Bank', '55070024', 'DEUTDEDB551'],
    ['Deutsche Bank', '55070024', 'DEUTDEDB561'],
    ['Deutsche Bank', '55070024', 'DEUTDEDB563'],
    ['Deutsche Bank', '55070024', 'DEUTDEDBMAI'],
    ['Deutsche Bank', '55070040', 'DEUTDE5MXXX'],
    ['Deutsche Bank', '55070040', 'DEUTDE5M550'],
    ['Deutsche Bank', '55070040', 'DEUTDE5M551'],
    ['Deutsche Bank', '55070040', 'DEUTDE5M552'],
    ['Postbank Ndl Deutsche Bank', '55070324', 'DEUTDEDBP29'],
    ['Postbank Ndl Deutsche Bank', '55070396', 'DEUTDE5MP29'],
    ['CommerzBk TF MZ 1, Mainz', '55080044', 'COBADEFFXXX'],
    ['Commerzbank Mainz', '55080065', 'DRESDEFF550'],
    ['Commerzbank ITGK1 Mainz', '55080085', 'DRESDEFFJ20'],
    ['Commerzbank ITGK2 Mainz', '55080086', 'DRESDEFFJ21'],
    ['CommerzBk TF MZ 2, Mainz', '55080088', 'DRESDEFF555'],
    ['Sparda-Bank Südwest', '55090500', 'GENODEF1S01'],
    ['Volksbank Alzey-Worms', '55091200', 'GENODE61AZY'],
    ['Clearingkonto LRP-SI', '55150098', 'MALADE51EMZ'],
    ['Pax-Bank Mainz', '55160195', 'GENODED1PA4'],
    ['Mainzer Volksbank Mainz', '55190000', 'MVBMDE55XXX'],
    ['Commerzbank Worms', '55340041', 'COBADEFFXXX'],
    ['Rheinhessen Sparkasse', '55350010', 'MALADE51WOR'],
    ['VR Bank', '55361202', 'GENODE51AHM'],
    ['Volksbank Bechtheim -alt-', '55362071', 'GENODE51BEC'],
    ['VB Worms-Wonnegau -alt-', '55390000', 'GENODE61WO1'],
    ['UniCredit Bank-HypoVereinbk', '56020086', 'HYVEDEMM515'],
    ['Sparkasse Rhein-Nahe', '56050180', 'MALADE51KRE'],
    ['Kr Spk Rhein-Hunsrück', '56051790', 'MALADE51SIM'],
    ['Raiffeisenbank Kastellaun', '56061151', 'GENODED1KSL'],
    ['VVR-Bank Simmern', '56061472', 'GENODED1KHK'],
    ['Volksbank Rheinböllen', '56062227', 'GENODED1RBO'],
    ['Deutsche Bank', '56070024', 'DEUTDEDB560'],
    ['Deutsche Bank', '56070040', 'DEUTDE5M560'],
    ['VB Rhein-Nahe-Hunsrück', '56090000', 'GENODE51KRE'],
    ['Commerzbank Idar-Oberstein', '56240050', 'COBADEFFXXX'],
    ['Kr Spk Birkenfeld', '56250030', 'BILADE55XXX'],
    ['Raiffeisenbank Nahe', '56261735', 'GENODED1FIN'],
    ['Deutsche Bank', '56270024', 'DEUTDEDB562'],
    ['Deutsche Bank', '56270044', 'DEUTDE5M562'],
    ['VB-Raiffbk Naheland -alt-', '56290000', 'GENODE51IDO'],
    ['BBk Koblenz', '57000000', 'MARKDEF1570'],
    ['UniCredit Bank-HypoVereinbk', '57020086', 'HYVEDEMM401'],
    ['MMV Bank Koblenz', '57020301', 'MKBKDE51XXX'],
    ['Oyak Anker Bank', '57020500', 'OYAKDE5KXXX'],
    ['Debeka Bauspk Koblenz', '57020600', 'DEBKDE51XXX'],
    ['Commerzbank Koblenz', '57040044', 'COBADEFFXXX'],
    ['Sparkasse Koblenz', '57050120', 'MALADE51KOB'],
    ['Kr Spk Westerwald Montabaur', '57051001', 'MALADE51BMB'],
    ['Kr Spk Cochem-Zell -alt-', '57051870', 'MALADE51COC'],
    ['DZ BANK Koblenz', '57060000', 'GENODEDD570'],
    ['Raiffbk Niederwallmenach', '57062675', 'GENODE51NWA'],
    ['Volksbank Mülheim-K. -alt-', '57064221', 'GENODED1MKA'],
    ['Raiffbk Lutzerather-Höhe', '57069067', 'GENODED1LUH'],
    ['Raiffbk Moselkrampen -alt-', '57069081', 'GENODED1MOK'],
    ['Raiffbk Eifeltor', '57069144', 'GENODED1KAI'],
    ['Raiffbk Neustadt', '57069238', 'GENODED1ASN'],
    ['Raiffbk Untermosel -alt-', '57069257', 'GENODED1UMO'],
    ['Raiffbk Straßenhaus -alt-', '57069315', 'GENODED1SRH'],
    ['Raiffeisenbank Welling', '57069361', 'GENODED1WLG'],
    ['Raiffeisenbank Irrel -alt-', '57069727', 'GENODED1IRR'],
    ['VR-Bank Hunsrück-Mosel-alt-', '57069806', 'GENODED1MBA'],
    ['Deutsche Bank', '57070024', 'DEUTDEDB570'],
    ['Deutsche Bank', '57070024', 'DEUTDEDB571'],
    ['Deutsche Bank', '57070024', 'DEUTDEDB572'],
    ['Deutsche Bank', '57070024', 'DEUTDEDB573'],
    ['Deutsche Bank', '57070024', 'DEUTDEDB577'],
    ['Deutsche Bank', '57070024', 'DEUTDEDB578'],
    ['Deutsche Bank', '57070045', 'DEUTDE5M570'],
    ['Deutsche Bank', '57070045', 'DEUTDE5M571'],
    ['Deutsche Bank', '57070045', 'DEUTDE5M572'],
    ['Deutsche Bank', '57070045', 'DEUTDE5M573'],
    ['Deutsche Bank', '57070045', 'DEUTDE5M577'],
    ['Deutsche Bank', '57070045', 'DEUTDE5M578'],
    ['Postbank Ndl Deutsche Bank', '57070324', 'DEUTDEDBP28'],
    ['Postbank Ndl Deutsche Bank', '57070395', 'DEUTDE5MP28'],
    ['Commerzbank Koblenz', '57080070', 'DRESDEFF570'],
    ['VB Koblenz Mittelrhein-alt-', '57090000', 'GENODE51KOB'],
    ['VB Koblenz Mittelrhein-alt-', '57090010', 'GENODE51KOB'],
    ['PSD Bank Koblenz', '57090900', 'GENODEF1P12'],
    ['VB Mtb.-Höhr-Grenzh. -alt-', '57091000', 'GENODE51MON'],
    ['VB Höhr-Grenzhausen -alt-', '57091100', 'GENODE51HGR'],
    ['VB Rhein-Lahn-Limburg', '57092800', 'GENODE51DIE'],
    ['Raiffeisenbank Arzbach', '57263015', 'GENODE51ARZ'],
    ['Sparkasse Westerwald-Sieg', '57351030', 'MALADE51AKI'],
    ['Volksbank Gebhardshain', '57361476', 'GENODED1GBS'],
    ['Raiffbk Niederfischb. -alt-', '57363243', 'GENODED1NFB'],
    ['Volksbank Daaden', '57391200', 'GENODE51DAA'],
    ['VB Hamm/Sieg Hamm (Sieg)', '57391500', 'GENODE51HAM'],
    ['Westerwald Bank', '57391800', 'GENODE51WW1'],
    ['Sparkasse Neuwied', '57450120', 'MALADE51NWD'],
    ['VR Bank Rhein-Mosel -alt-', '57460117', 'GENODED1NWD'],
    ['Raiffbk Mittelrhein -alt-', '57461759', 'GENODED1MRW'],
    ['Deutsche Bank', '57470024', 'DEUTDEDB574'],
    ['Deutsche Bank', '57470024', 'DEUTDEDB575'],
    ['Deutsche Bank', '57470024', 'DEUTDEDB576'],
    ['Deutsche Bank', '57470024', 'DEUTDEDB579'],
    ['Deutsche Bank', '57470047', 'DEUTDE5M574'],
    ['Deutsche Bank', '57470047', 'DEUTDE5M575'],
    ['Deutsche Bank', '57470047', 'DEUTDE5M576'],
    ['Deutsche Bank', '57470047', 'DEUTDE5M579'],
    ['Kr Spk Mayen', '57650010', 'MALADE51MYN'],
    ['Raiffeisenbank Kehrig -alt-', '57661253', 'GENODED1KEH'],
    ['VR Bank Rhein-Mosel -alt-', '57662263', 'GENODED1MPO'],
    ['Kr Spk Ahrweiler', '57751310', 'MALADE51AHR'],
    ['VR Bank RheinAhrEifel', '57761591', 'GENODED1BNA'],
    ['Voba RheinAhrEifel GAA', '57761599', 'GENODED1BNA'],
    ['Raiffbk Grafschaft-Wachtbg', '57762265', 'GENODED1GRO'],
    ['UniCredit Bank-HypoVereinbk', '58520086', 'HYVEDEMM437'],
    ['Commerzbank Trier', '58540035', 'COBADEFFXXX'],
    ['Sparkasse Trier', '58550130', 'TRISDE55XXX'],
    ['Volksbank Trier', '58560103', 'GENODED1TVB'],
    ['Pax-Bank Trier', '58560294', 'GENODED1PA3'],
    ['Volksbank Saarburg -alt-', '58561626', 'GENODED1SRB'],
    ['Raiffbk Mehring-Leiwen', '58561771', 'GENODED1MLW'],
    ['VB Hochwald-Saarburg -alt-', '58564788', 'GENODED1HWM'],
    ['Deutsche Bank', '58570024', 'DEUTDEDB580'],
    ['Deutsche Bank', '58570024', 'DEUTDEDB585'],
    ['Deutsche Bank', '58570024', 'DEUTDEDB586'],
    ['Deutsche Bank', '58570048', 'DEUTDE5M585'],
    ['Deutsche Bank', '58570048', 'DEUTDE5M586'],
    ['Deutsche Bank', '58570048', 'DEUTDE5M590'],
    ['Commerzbank Trier', '58580074', 'DRESDEFF585'],
    ['PSD Bank Trier', '58590900', 'GENODEF1P21'],
    ['Kr Spk Bitburg-Prüm', '58650030', 'MALADE51BIT'],
    ['Kreissparkasse Vulkaneifel', '58651240', 'MALADE51DAU'],
    ['Volksbank Eifel', '58660101', 'GENODED1BIT'],
    ['Raiffeisenbank Westeifel', '58661901', 'GENODED1WSC'],
    ['Raiffbk östl Südeifel -alt-', '58662653', 'GENODED1OSE'],
    ['Volksbank Eifel Mitte -alt-', '58691500', 'GENODED1PRU'],
    ['Spk Mittelmosel EMH', '58751230', 'MALADE51BKS'],
    ['VVR-Bank Wittlich -alt-', '58760954', 'GENODED1WTL'],
    ['Raiffbk Zeller Land -alt-', '58761343', 'GENODED1BPU'],
    ['Deutsche Bank', '58771224', 'DEUTDEDB587'],
    ['Deutsche Bank', '58771224', 'DEUTDEDB588'],
    ['Deutsche Bank', '58771224', 'DEUTDEDB589'],
    ['Deutsche Bank', '58771242', 'DEUTDE5M587'],
    ['Deutsche Bank', '58771242', 'DEUTDE5M588'],
    ['Deutsche Bank', '58771242', 'DEUTDE5M589'],
    ['BBk Saarbrücken', '59000000', 'MARKDEF1590'],
    ['Postbank Ndl Deutsche Bank', '59010011', 'PBNKDEFF011'],
    ['Postbank Ndl Deutsche Bank', '59010012', 'PBNKDEFF012'],
    ['Postbank Ndl Deutsche Bank', '59010013', 'PBNKDEFF013'],
    ['Postbank Ndl Deutsche Bank', '59010014', 'PBNKDEFF014'],
    ['Postbank Ndl Deutsche Bank', '59010015', 'PBNKDEFF015'],
    ['Postbank Ndl Deutsche Bank', '59010016', 'PBNKDEFF016'],
    ['Postbank Ndl Deutsche Bank', '59010017', 'PBNKDEFF017'],
    ['Postbank Ndl Deutsche Bank', '59010018', 'PBNKDEFF018'],
    ['Postbank Ndl Deutsche Bank', '59010019', 'PBNKDEFF019'],
    ['Postbank Ndl Deutsche Bank', '59010021', 'PBNKDEFF021'],
    ['Postbank Ndl Deutsche Bank', '59010022', 'PBNKDEFF022'],
    ['Postbank Ndl Deutsche Bank', '59010023', 'PBNKDEFF023'],
    ['Postbank Ndl Deutsche Bank', '59010024', 'PBNKDEFF024'],
    ['Postbank Ndl Deutsche Bank', '59010025', 'PBNKDEFF025'],
    ['Postbank Ndl Deutsche Bank', '59010026', 'PBNKDEFF026'],
    ['Postbank Ndl Deutsche Bank', '59010027', 'PBNKDEFF027'],
    ['Postbank Ndl Deutsche Bank', '59010028', 'PBNKDEFF028'],
    ['Postbank Ndl Deutsche Bank', '59010029', 'PBNKDEFF029'],
    ['Postbank Ndl Deutsche Bank', '59010031', 'PBNKDEFF031'],
    ['Postbank Ndl Deutsche Bank', '59010032', 'PBNKDEFF032'],
    ['Postbank Ndl Deutsche Bank', '59010033', 'PBNKDEFF033'],
    ['Postbank Ndl Deutsche Bank', '59010034', 'PBNKDEFF034'],
    ['Postbank Ndl Deutsche Bank', '59010035', 'PBNKDEFF035'],
    ['Postbank Ndl Deutsche Bank', '59010036', 'PBNKDEFF036'],
    ['Postbank Ndl Deutsche Bank', '59010037', 'PBNKDEFF037'],
    ['Postbank Ndl Deutsche Bank', '59010038', 'PBNKDEFF038'],
    ['Postbank Ndl Deutsche Bank', '59010039', 'PBNKDEFF039'],
    ['Postbank Ndl Deutsche Bank', '59010040', 'PBNKDEFF040'],
    ['Postbank Ndl Deutsche Bank', '59010041', 'PBNKDEFF041'],
    ['Postbank Ndl Deutsche Bank', '59010042', 'PBNKDEFF042'],
    ['Postbank Ndl Deutsche Bank', '59010044', 'PBNKDEFF044'],
    ['Postbank Ndl Deutsche Bank', '59010045', 'PBNKDEFF045'],
    ['Postbank Ndl Deutsche Bank', '59010047', 'PBNKDEFF047'],
    ['Postbank Ndl Deutsche Bank', '59010048', 'PBNKDEFF048'],
    ['Postbank Ndl Deutsche Bank', '59010049', 'PBNKDEFF049'],
    ['Postbank Ndl Deutsche Bank', '59010051', 'PBNKDEFF051'],
    ['Postbank Ndl Deutsche Bank', '59010052', 'PBNKDEFF052'],
    ['Postbank Ndl Deutsche Bank', '59010053', 'PBNKDEFF053'],
    ['Postbank Ndl Deutsche Bank', '59010054', 'PBNKDEFF054'],
    ['Postbank Ndl Deutsche Bank', '59010055', 'PBNKDEFF055'],
    ['Postbank Ndl Deutsche Bank', '59010056', 'PBNKDEFF056'],
    ['Postbank Ndl Deutsche Bank', '59010057', 'PBNKDEFF057'],
    ['Postbank Ndl Deutsche Bank', '59010058', 'PBNKDEFF058'],
    ['Postbank Ndl Deutsche Bank', '59010059', 'PBNKDEFF059'],
    ['Postbank Ndl Deutsche Bank', '59010061', 'PBNKDEFF061'],
    ['Postbank Ndl Deutsche Bank', '59010062', 'PBNKDEFF062'],
    ['Postbank Ndl Deutsche Bank', '59010063', 'PBNKDEFF063'],
    ['Postbank Ndl Deutsche Bank', '59010064', 'PBNKDEFF064'],
    ['Postbank Ndl Deutsche Bank', '59010065', 'PBNKDEFF065'],
    ['Postbank Ndl Deutsche Bank', '59010066', 'PBNKDEFFXXX'],
    ['Postbank Ndl Deutsche Bank', '59010068', 'PBNKDEFF068'],
    ['Postbank Ndl Deutsche Bank', '59010069', 'PBNKDEFF069'],
    ['Postbank Ndl Deutsche Bank', '59010071', 'PBNKDEFF071'],
    ['Postbank Ndl Deutsche Bank', '59010072', 'PBNKDEFF072'],
    ['Postbank Ndl Deutsche Bank', '59010073', 'PBNKDEFF073'],
    ['Postbank Ndl Deutsche Bank', '59010074', 'PBNKDEFF074'],
    ['SIKB Saarbrücken', '59010400', 'SIKBDE55XXX'],
    ['UniCredit Bank-HypoVereinbk', '59020090', 'HYVEDEMM432'],
    ['mediserv Bank Saarbrücken', '59020400', 'MEGHDE81XXX'],
    ['Commerzbank Saarbrücken', '59040000', 'COBADEFFXXX'],
    ['Landesbank Saar Saarbrücken', '59050000', 'SALADE55XXX'],
    ['Sparkasse Saarbrücken', '59050101', 'SAKSDE55XXX'],
    ['St Spk Völklingen -alt-', '59051090', 'SALADE51VKS'],
    ['Deutsche Bank', '59070000', 'DEUTDE5M555'],
    ['Deutsche Bank', '59070070', 'DEUTDEDB590'],
    ['Deutsche Bank', '59070070', 'DEUTDEDB595'],
    ['Commerzbank Saarbrücken', '59080090', 'DRESDEFF590'],
    ['PSD Bank RheinNeckarSaar', '59090900', 'GENODEF1P19'],
    ['Vereinigte Volksbank', '59092000', 'GENODE51SB2'],
    ['VB Nahe-Schaumberg -alt-', '59099550', 'GENODE51NOH'],
    ['Bank 1 Saar', '59190000', 'SABADE5SXXX'],
    ['Voba Westliche Saar -alt-', '59190200', 'GENODE51SLS'],
    ['Kr Spk St. Wendel', '59251020', 'SALADE51WND'],
    ['Sparkasse Neunkirchen', '59252046', 'SALADE51NKS'],
    ['Unsere VB St. Wendel -alt-', '59291000', 'GENODE51WEN'],
    ['VR Bank Saarpfalz-alt-', '59291200', 'GENODE51BEX'],
    ['UniCredit Bank-HypoVereinbk', '59320087', 'HYVEDEMM838'],
    ['Kreissparkasse Saarlouis', '59350110', 'KRSADE55XXX'],
    ['Spk Merzig-Wadern', '59351040', 'MERZDE55XXX'],
    ['Volksbank Saarlouis -alt-', '59390100', 'GENODE51SLF'],
    ['Volksbank Überherrn', '59391200', 'GENODE51UBH'],
    ['Volksbank Dillingen -alt-', '59392000', 'GENODE51DSA'],
    ['Volksbank Untere Saar -alt-', '59392200', 'GENODE51LOS'],
    ['levoBank', '59393000', 'GENODE51LEB'],
    ['Kreissparkasse Saarpfalz', '59450010', 'SALADE51HOM'],
    ['VR Bank Saarpfalz -alt-', '59491300', 'GENODE51MBT'],
    ['BBk Stuttgart', '60000000', 'MARKDEF1600'],
    ['Postbank Ndl Deutsche Bank', '60010070', 'PBNKDEFFXXX'],
    ['Aareal Bank Wiesbaden', '60010424', 'AARBDE5W600'],
    ['L-Bank Stuttgart -alt-', '60010700', 'LKBWDE6K600'],
    ['BW Bank Stuttgart', '60020030', 'SOLADEST601'],
    ['M.M. Warburg (Schwäb. Bk.)', '60020100', 'SCHWDESSXXX'],
    ['UniCredit Bank-HypoVereinbk', '60020290', 'HYVEDEMM473'],
    ['Mercedes-Benz Bank', '60030000', 'MEBEDE6SDCB'],
    ['Bankhaus Bauer, Essen', '60030100', 'BHBADES1XXX'],
    ['Ellwabank Stuttgart', '60030200', 'ELGEDES1XXX'],
    ['CreditPlus Bank', '60030600', 'CPLUDES1XXX'],
    ['CreditPlus Bank', '60030666', 'CPLUDES1666'],
    ['AKTIVBANK Pforzheim', '60030700', 'AKBADES1XXX'],
    ['Isbank Stuttgart', '60030900', 'ISBKDEFXSTU'],
    ['TRUMPF Financial Ditzingen', '60031000', 'TRUFDE66XXX'],
    ['Wüstenrot Bausparkasse', '60033000', 'BSWLDE61XXX'],
    ['IBM Kreditbank Ehningen', '60035810', 'IBKBDES1XXX'],
    ['Commerzbank CC Stuttgart', '60040060', 'COBADEFFXXX'],
    ['Commerzbank CC Stuttgart', '60040061', 'COBADEFFXXX'],
    ['Commerzbank Stuttgart', '60040071', 'COBADEFFXXX'],
    ['Commerzbank Fil. Stuttgart2', '60040075', 'COBADEFFXXX'],
    ['Landesbank Baden-Württ', '60050000', 'SOLADESTXXX'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST428'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST437'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST447'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST454'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST457'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST484'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST487'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST490'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST493'],
    ['LBBW/BW-Bank Stuttgart', '60050101', 'SOLADEST600'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST829'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST836'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST864'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST880'],
    ['BW-Bank/LBBW Stuttgart', '60050101', 'SOLADEST896'],
    ['DZ BANK', '60060000', 'GENODESGXXX'],
    ['DZ PRIVATBANK Ndl Stuttgart', '60060202', 'GENODESTXXX'],
    ['Volksbank am Württemberg', '60060396', 'GENODES1UTV'],
    ['Echterdinger Bank', '60062775', 'GENODES1ECH'],
    ['Volksbank Strohgäu -alt-', '60062909', 'GENODES1MCH'],
    ['Raiffbk Dellmensingen -alt-', '60069017', 'GENODES1DMS'],
    ['Raiffeisenbank Niedere Alb', '60069066', 'GENODES1RBA'],
    ['Raiffbk Bühlertal', '60069075', 'GENODES1RVG'],
    ['Raiffbk Sondelfingen', '60069147', 'GENODES1RSF'],
    ['Raiffbk Steinheim', '60069158', 'GENODES1SAA'],
    ['Raiffeisenbank Aidlingen', '60069206', 'GENODES1AID'],
    ['Genossenschaftsbank Weil', '60069224', 'GENODES1GWS'],
    ['Bopfinger Bank Sechta-Ries', '60069239', 'GENODES1BPF'],
    ['Raiffbk Gruibingen', '60069242', 'GENODES1RGR'],
    ['Raiffbk Bühlertal', '60069245', 'GENODES1RVG'],
    ['Raiffbk Donau-Iller -alt-', '60069251', 'GENODES1RDI'],
    ['Raiffeisenbk Erlenmoos-alt-', '60069302', 'GENODES1ERM'],
    ['Raiffbk Bad Schussenried-Au', '60069303', 'GENODES1RBS'],
    ['Raiffbk Ingoldingen -alt-', '60069308', 'GENODES1RIN'],
    ['VB Freiberg und Umgebung', '60069315', 'GENODES1MDH'],
    ['Raiffbk Maitis', '60069336', 'GENODES1RMA'],
    ['Raiffeisenbank Rißtal -alt-', '60069343', 'GENODES1RRI'],
    ['VR-Bank Alb-Blau-Donau', '60069346', 'GENODES1REH'],
    ['Raiffbk Reute-Gaisbeuren', '60069350', 'GENODES1RRG'],
    ['VR-Bank Ehningen-Nufringen', '60069355', 'GENODES1EHN'],
    ['VB Dettenhausen', '60069378', 'GENODES1DEH'],
    ['Dettinger Bank', '60069387', 'GENODES1DBE'],
    ['Raiffbk Kirchheim-Walh.-alt', '60069417', 'GENODES1KIB'],
    ['Uhlbacher Bank -alt-', '60069419', 'GENODES1UHL'],
    ['Raiffbk Mittelbiberach -alt', '60069420', 'GENODES1MBI'],
    ['Raiffbk Oberessendorf -alt-', '60069431', 'GENODES1OED'],
    ['Raiffbk Frankenh-Stimpfach', '60069442', 'GENODES1RFS'],
    ['Raiffbk Vordersteinenb.-alt', '60069455', 'GENODES1RVS'],
    ['Raiffeisenbank Ottenbach', '60069457', 'GENODES1OTT'],
    ['Raiffbk Rottumtal -alt-', '60069461', 'GENODES1RRE'],
    ['Winterbacher Bank', '60069462', 'GENODES1WBB'],
    ['Raiffbk Geislingen-Rosenf', '60069463', 'GENODES1RKH'],
    ['RB Heidenheimer Alb -alt-', '60069476', 'GENODES1DEA'],
    ['Raiffbk Oberer Wald -alt-', '60069485', 'GENODES1ROW'],
    ['Volksbank Murgtal -alt-', '60069505', 'GENODES1VMT'],
    ['Scharnhauser Bank', '60069517', 'GENODES1SCA'],
    ['Volksbank Brenztal', '60069527', 'GENODES1RNS'],
    ['Löchgauer Bank -alt-', '60069538', 'GENODES1LOC'],
    ['Raiffeisenbank Westhausen', '60069544', 'GENODES1RWN'],
    ['Nufringer Bank -alt-', '60069545', 'GENODES1NUF'],
    ['Raiffbk Aichh-Hardt-Sulgen', '60069553', 'GENODES1HAR'],
    ['Raiffbk Vordere Alb -alt-', '60069564', 'GENODES1RVA'],
    ['Raiffbk Schrozberg-Rot', '60069595', 'GENODES1SBB'],
    ['Raiffbk Ingersheim -alt-', '60069639', 'GENODES1RIH'],
    ['Raiffeisenbank -alt-', '60069648', 'GENODES1EHZ'],
    ['Erligheimer Bank -alt-', '60069669', 'GENODES1EHB'],
    ['Abtsgmünder Bank', '60069673', 'GENODES1ABR'],
    ['Raiffbk Bretzfeld -alt-', '60069680', 'GENODES1BRZ'],
    ['Raiffeisenbank Wangen', '60069685', 'GENODES1RWA'],
    ['Raiffeisenbank Schlat -alt-', '60069705', 'GENODES1SLA'],
    ['Raiffbk Mehrstetten -alt-', '60069706', 'GENODES1MEH'],
    ['Raiffbk Gammesfeld', '60069710', 'GENODES1RGF'],
    ['Raiffbk Hohenloher Land', '60069714', 'GENODES1IBR'],
    ['Raiffbk Oberstenfeld -alt-', '60069727', 'GENODES1ROF'],
    ['VB Freiberg und Umgebung', '60069738', 'GENODES1FAN'],
    ['Volks- u Raiffbk Boll -alt-', '60069766', 'GENODES1BBO'],
    ['VB Freiberg und Umgebung', '60069795', 'GENODES1HHB'],
    ['Raiffeisenbank Horb -alt-', '60069798', 'GENODES1RHB'],
    ['Raiffbk Mötzingen', '60069817', 'GENODES1RMO'],
    ['Raiffbk Urbach -alt-', '60069832', 'GENODES1URB'],
    ['Darmsheimer Bank -alt-', '60069842', 'GENODES1DHB'],
    ['Enztalbank -alt-', '60069858', 'GENODES1VAI'],
    ['Federseebank -alt-', '60069860', 'GENODES1FED'],
    ['Raiffeisenbank Oberes Gäu', '60069876', 'GENODES1ROG'],
    ['VB Freiberg und Umgebung', '60069896', 'GENODES1PLE'],
    ['VR-Bank Alb -alt-', '60069904', 'GENODES1RUW'],
    ['Volksbank Remseck', '60069905', 'GENODES1REM'],
    ['Raiffbk Erlenbach', '60069911', 'GENODES1ERL'],
    ['VB Glatten-Wittendorf -alt-', '60069926', 'GENODES1VBG'],
    ['Berkheimer Bank -alt-', '60069927', 'GENODES1BHB'],
    ['Raiffbk Berghülen', '60069931', 'GENODES1BGH'],
    ['Raiffbk Tüngental', '60069950', 'GENODES1TUN'],
    ['Raiffbk Böllingertal', '60069976', 'GENODES1BOE'],
    ['Raiffbk Maselheim-Äpfingen', '60069980', 'GENODES1RMH'],
    ['Deutsche Bank', '60070024', 'DEUTDEDB602'],
    ['Deutsche Bank', '60070024', 'DEUTDEDB605'],
    ['Deutsche Bank', '60070024', 'DEUTDEDB609'],
    ['Deutsche Bank', '60070024', 'DEUTDEDB624'],
    ['Deutsche Bank', '60070024', 'DEUTDEDB647'],
    ['Deutsche Bank', '60070024', 'DEUTDEDBSTG'],
    ['Deutsche Bank', '60070070', 'DEUTDESSXXX'],
    ['Deutsche Bank', '60070070', 'DEUTDESS602'],
    ['Deutsche Bank', '60070070', 'DEUTDESS605'],
    ['Deutsche Bank', '60070070', 'DEUTDESS609'],
    ['Deutsche Bank', '60070070', 'DEUTDESS624'],
    ['Deutsche Bank', '60070070', 'DEUTDESS647'],
    ['Postbank Ndl Deutsche Bank', '60070214', 'DEUTDESSP13'],
    ['Postbank Ndl Deutsche Bank', '60070224', 'DEUTDEDBP13'],
    ['Commerzbank Stuttgart', '60080000', 'DRESDEFF600'],
    ['Commerzbk Zw 55 Stuttgart', '60080055', 'DRESDEFF608'],
    ['Commerzbk Zw 57 Stuttgart', '60080057', 'DRESDEFF609'],
    ['Commerzbk ITGK Stuttgart', '60080085', 'DRESDEFFI50'],
    ['Commerzbank ITKG3 Stutt.', '60080086', 'DRESDEFFI54'],
    ['Commerzbank ITGK4 Sttgrt', '60080087', 'DRESDEFFI57'],
    ['Commerzbank ITGK5 Sttgrt', '60080088', 'DRESDEFFI58'],
    ['Commerzbank ITGK Stgt', '60089450', 'DRESDEFFI21'],
    ['Volksbank Stuttgart', '60090100', 'VOBADESSXXX'],
    ['Volksbank Stuttgart GAA', '60090133', 'VOBADESSXXX'],
    ['VB Zuffenhausen', '60090300', 'GENODES1ZUF'],
    ['SÜDWESTBANK - BAWAG Ndl. DE', '60090700', 'SWBSDESSXXX'],
    ['Sparda-Bank Baden-Württemb', '60090800', 'GENODEF1S02'],
    ['PSD Bank RheinNeckarSaar', '60090900', 'GENODEF1P20'],
    ['BHF-BANK Stuttgart', '60120200', 'BHFBDEFF600'],
    ['Bank für Sozialwirtschaft', '60120500', 'BFSWDE33STG'],
    ['Santander Bank Stuttgart', '60133300', 'SCFBDE33XXX'],
    ['Commerzbank Backnang', '60241074', 'COBADEFFXXX'],
    ['Kr Spk Waiblingen', '60250010', 'SOLADES1WBN'],
    ['Fellbacher Bank', '60261329', 'GENODES1FBB'],
    ['VR-Bank Weinstadt -alt-', '60261622', 'GENODES1WNS'],
    ['Raiffbk Weissacher Tal -alt', '60261818', 'GENODES1RWT'],
    ['Korber Bank -alt-', '60262063', 'GENODES1KOR'],
    ['Kerner Volksbank -alt-', '60262693', 'GENODES1KRN'],
    ['Deutsche Bank', '60270024', 'DEUTDEDB606'],
    ['Deutsche Bank', '60270073', 'DEUTDESS606'],
    ['Volksbank Rems -alt-', '60290110', 'GENODES1VWN'],
    ['Volksbank Backnang', '60291120', 'GENODES1VBK'],
    ['UniCredit Bank-HypoVereinbk', '60320291', 'HYVEDEMM858'],
    ['Commerzbank Sindelfingen', '60340071', 'COBADEFFXXX'],
    ['Kr Spk Böblingen', '60350130', 'BBKRDE6BXXX'],
    ['Raiffbk Weissach -alt-', '60361923', 'GENODES1WES'],
    ['Commerzbank Böblingen', '60380002', 'DRESDEFF601'],
    ['Vereinigte Volksbanken', '60390000', 'GENODES1BBV'],
    ['Volksbank Leonberg-Strohgäu', '60390300', 'GENODES1LEO'],
    ['Volksbank in der Region', '60391310', 'GENODES1VBH'],
    ['VR-Bank Magstadt-Weissach', '60391420', 'GENODES1MAG'],
    ['OLB', '60420000', 'WBAGDE61XXX'],
    ['OLB', '60420020', 'WBAGDEA1XXX'],
    ['OLB', '60420021', 'WBAGDEA1XXX'],
    ['UniCredit Bank-HypoVereinbk', '60420186', 'HYVEDEMM860'],
    ['RSB-Bank Kornwestheim', '60422000', 'SABUDES1XXX'],
    ['Commerzbank Ludwigsburg', '60440073', 'COBADEFFXXX'],
    ['Kreissparkasse Ludwigsburg', '60450050', 'SOLADES1LBG'],
    ['VB Freiberg und Umgebung', '60460142', 'GENODES1EGL'],
    ['VR-Bank Asperg-Markg. -alt-', '60462808', 'GENODES1AMT'],
    ['Deutsche Bank', '60470024', 'DEUTDEDB604'],
    ['Deutsche Bank', '60470024', 'DEUTDEDB608'],
    ['Deutsche Bank', '60470024', 'DEUTDEDB617'],
    ['Deutsche Bank', '60470024', 'DEUTDEDB623'],
    ['Deutsche Bank', '60470024', 'DEUTDEDB635'],
    ['Deutsche Bank', '60470024', 'DEUTDEDB648'],
    ['Deutsche Bank', '60470082', 'DEUTDESS604'],
    ['Deutsche Bank', '60470082', 'DEUTDESS608'],
    ['Deutsche Bank', '60470082', 'DEUTDESS617'],
    ['Deutsche Bank', '60470082', 'DEUTDESS623'],
    ['Deutsche Bank', '60470082', 'DEUTDESS635'],
    ['Deutsche Bank', '60470082', 'DEUTDESS648'],
    ['Commerzbank Ludwigsburg', '60480008', 'DRESDEFF604'],
    ['Volksbank Ludwigsburg -alt-', '60490150', 'GENODES1LBG'],
    ['VR-Bank Ludwigsburg', '60491430', 'GENODES1GHB'],
    ['VR-Bank Ludwigsburg', '60491430', 'GENODES1VBB'],
    ['Raiffbk Wimsheim-Mönsheim', '60661906', 'GENODES1WIM'],
    ['Raiffbk Calw', '60663084', 'GENODES1RCW'],
    ['Deutsche Bank', '60670024', 'DEUTDEDB659'],
    ['Deutsche Bank', '60670070', 'DEUTDESS659'],
    ['Martinbank Göppingen', '61030000', 'MARBDE6GXXX'],
    ['Commerzbank Göppingen', '61040014', 'COBADEFFXXX'],
    ['Kr Spk Göppingen', '61050000', 'GOPSDE6GXXX'],
    ['Kr Spk Göppingen', '61050000', 'GOPSDE6G612'],
    ['Volksbank Göppingen', '61060500', 'GENODES1VGP'],
    ['Deutsche Bank', '61070024', 'DEUTDEDB610'],
    ['Deutsche Bank', '61070024', 'DEUTDEDB618'],
    ['Deutsche Bank', '61070024', 'DEUTDEDB627'],
    ['Deutsche Bank', '61070078', 'DEUTDESS610'],
    ['Deutsche Bank', '61070078', 'DEUTDESS618'],
    ['Deutsche Bank', '61070078', 'DEUTDESS627'],
    ['Commerzbank Göppingen', '61080006', 'DRESDEFF610'],
    ['VB-Raiffbk Deggingen -alt-', '61091200', 'GENODES1DGG'],
    ['UniCredit Bank-HypoVereinbk', '61120286', 'HYVEDEMM859'],
    ['Commerzbank Esslingen', '61140071', 'COBADEFFXXX'],
    ['Kr Spk Esslingen-Nürtingen', '61150020', 'ESSLDE66XXX'],
    ['VB Filder', '61161696', 'GENODES1NHB'],
    ['Deutsche Bank', '61170024', 'DEUTDEDB611'],
    ['Deutsche Bank', '61170024', 'DEUTDEDB612'],
    ['Deutsche Bank', '61170024', 'DEUTDEDB619'],
    ['Deutsche Bank', '61170024', 'DEUTDEDB626'],
    ['Deutsche Bank', '61170076', 'DEUTDESS611'],
    ['Deutsche Bank', '61170076', 'DEUTDESS612'],
    ['Deutsche Bank', '61170076', 'DEUTDESS619'],
    ['Deutsche Bank', '61170076', 'DEUTDESS626'],
    ['Commerzbank Esslingen', '61180004', 'DRESDEFF611'],
    ['Volksbank Esslingen -alt-', '61190110', 'GENODES1ESS'],
    ['Volksbank Plochingen', '61191310', 'GENODES1VBP'],
    ['Commerzbank NT F-N48', '61240048', 'COBADEFFXXX'],
    ['Raiffeisenbank Teck -alt-', '61261213', 'GENODES1TEC'],
    ['VR Bank Hohenneuffen-T.-alt', '61261339', 'GENODES1HON'],
    ['Genossenschaftsbank -alt-', '61262258', 'GENODES1WLF'],
    ['Bernhauser Bank', '61262345', 'GENODES1BBF'],
    ['Commerzbank Kirchheim', '61281007', 'DRESDEFF612'],
    ['Volksbank Mittlerer Neckar', '61290120', 'GENODES1NUE'],
    ['Commerzbank Schwäb Gmünd', '61340079', 'COBADEFFXXX'],
    ['Raiffeisenbank Rosenstein', '61361722', 'GENODES1HEU'],
    ['Raiffbk Mutlangen -alt-', '61361975', 'GENODES1RML'],
    ['Deutsche Bank', '61370024', 'DEUTDEDB613'],
    ['Deutsche Bank', '61370024', 'DEUTDEDB614'],
    ['Deutsche Bank', '61370024', 'DEUTDEDB615'],
    ['Deutsche Bank', '61370024', 'DEUTDEDB616'],
    ['Deutsche Bank', '61370024', 'DEUTDEDB633'],
    ['Deutsche Bank', '61370024', 'DEUTDEDB637'],
    ['Deutsche Bank', '61370024', 'DEUTDEDB638'],
    ['Deutsche Bank', '61370024', 'DEUTDEDB639'],
    ['Deutsche Bank', '61370086', 'DEUTDESS613'],
    ['Deutsche Bank', '61370086', 'DEUTDESS614'],
    ['Deutsche Bank', '61370086', 'DEUTDESS615'],
    ['Deutsche Bank', '61370086', 'DEUTDESS616'],
    ['Deutsche Bank', '61370086', 'DEUTDESS633'],
    ['Deutsche Bank', '61370086', 'DEUTDESS637'],
    ['Deutsche Bank', '61370086', 'DEUTDESS638'],
    ['Deutsche Bank', '61370086', 'DEUTDESS639'],
    ['VB Schwäbisch Gmünd -alt-', '61390140', 'GENODES1VGD'],
    ['VR Bank Schwäbischer Wald', '61391410', 'GENODES1WEL'],
    ['UniCredit Bank-HypoVereinbk', '61420086', 'HYVEDEMM272'],
    ['Commerzbank Aalen Württ', '61440086', 'COBADEFFXXX'],
    ['Kreissparkasse Ostalb', '61450050', 'OASPDE6AXXX'],
    ['Commerzbank Aalen Württ', '61480001', 'DRESDEFF614'],
    ['VR-Bank Ostalb', '61490150', 'GENODES1AAV'],
    ['VR-Bank Ellwangen', '61491010', 'GENODES1ELL'],
    ['Hoerner-Bank Heilbronn', '62020000', 'HOEBDE61XXX'],
    ['Commerzbank Heilbronn', '62040060', 'COBADEFFXXX'],
    ['Kr Spk Heilbronn', '62050000', 'HEISDE66XXX'],
    ['Volksbank Sulmtal', '62061991', 'GENODES1VOS'],
    ['VB Beilstein-Ilsfeld-Abstat', '62062215', 'GENODES1BIA'],
    ['Volksbank Flein-Talheim', '62062643', 'GENODES1VFT'],
    ['VBU Volksbank im Unterland', '62063263', 'GENODES1VLS'],
    ['Deutsche Bank', '62070024', 'DEUTDEDB620'],
    ['Deutsche Bank', '62070024', 'DEUTDEDB621'],
    ['Deutsche Bank', '62070024', 'DEUTDEDB622'],
    ['Deutsche Bank', '62070024', 'DEUTDEDB628'],
    ['Deutsche Bank', '62070024', 'DEUTDEDB629'],
    ['Deutsche Bank', '62070081', 'DEUTDESS620'],
    ['Deutsche Bank', '62070081', 'DEUTDESS621'],
    ['Deutsche Bank', '62070081', 'DEUTDESS622'],
    ['Deutsche Bank', '62070081', 'DEUTDESS628'],
    ['Deutsche Bank', '62070081', 'DEUTDESS629'],
    ['Commerzbank Heilbronn', '62080012', 'DRESDEFF620'],
    ['Volksbank Heilbronn -alt-', '62090100', 'GENODES1VHN'],
    ['VB Brackenh.-Güglingen-alt-', '62091400', 'GENODES1VBR'],
    ['Volksbank Möckmühl', '62091600', 'GENODES1VMN'],
    ['Volksbank Hohenlohe', '62091800', 'GENODES1VHL'],
    ['Bauspk Schwäbisch Hall', '62220000', 'BSHHDE61XXX'],
    ['Commerzbank SH F-S48', '62240048', 'COBADEFFXXX'],
    ['Sparkasse Schwäbisch Hall', '62250030', 'SOLADES1SHA'],
    ['Sparkasse Hohenlohekreis', '62251550', 'SOLADES1KUN'],
    ['Commerzbank Schwäb Hall', '62280012', 'DRESDEFF622'],
    ['VR Bk Heilbronn Schwäb.Hall', '62290110', 'GENODES1SHA'],
    ['Crailsheimer VB -alt-', '62291020', 'GENODES1CRV'],
    ['VB Vorbach-Tauber -alt-', '62391420', 'GENODES1VVT'],
    ['BBk Ulm, Donau', '63000000', 'MARKDEF1630'],
    ['UniCredit Bank-HypoVereinbk', '63020086', 'HYVEDEMM461'],
    ['Commerzbank Ulm Donau', '63040053', 'COBADEFFXXX'],
    ['Sparkasse Ulm', '63050000', 'SOLADES1ULM'],
    ['VR Langenau-Ulmer Alb -alt-', '63061486', 'GENODES1LBK'],
    ['Deutsche Bank', '63070024', 'DEUTDEDB630'],
    ['Deutsche Bank', '63070024', 'DEUTDEDB631'],
    ['Deutsche Bank', '63070024', 'DEUTDEDB632'],
    ['Deutsche Bank', '63070088', 'DEUTDESS630'],
    ['Deutsche Bank', '63070088', 'DEUTDESS631'],
    ['Deutsche Bank', '63070088', 'DEUTDESS632'],
    ['Commerzbank Ulm Donau', '63080015', 'DRESDEFF630'],
    ['Commerzbank ITGK1 Ulm', '63080085', 'DRESDEFFI59'],
    ['Volksbank Ulm-Biberach', '63090100', 'ULMVDE66XXX'],
    ['Donau-Iller Bank', '63091010', 'GENODES1EHI'],
    ['Volksbank Blaubeuren -alt-', '63091200', 'GENODES1BLA'],
    ['Volksbank Alb', '63091300', 'GENODES1LAI'],
    ['UniCredit Bank-HypoVereinbk', '63220090', 'HYVEDEMM271'],
    ['Commerzbank Heidenheim', '63240016', 'COBADEFFXXX'],
    ['Kr Spk Heidenheim', '63250030', 'SOLADES1HDH'],
    ['Heidenheimer Volksbank', '63290110', 'GENODES1HDH'],
    ['BBk Reutlingen', '64000000', 'MARKDEF1640'],
    ['UniCredit Bank-HypoVereinbk', '64020186', 'HYVEDEMM374'],
    ['Commerzbank Reutlingen', '64040033', 'COBADEFFXXX'],
    ['Commerzbank Metzingen Würt', '64040045', 'COBADEFFXXX'],
    ['Kr Spk Reutlingen', '64050000', 'SOLADES1REU'],
    ['VR Bank Tübingen -alt-', '64061854', 'GENODES1STW'],
    ['Deutsche Bank', '64070024', 'DEUTDEDB634'],
    ['Deutsche Bank', '64070024', 'DEUTDEDB636'],
    ['Deutsche Bank', '64070024', 'DEUTDEDB640'],
    ['Deutsche Bank', '64070024', 'DEUTDEDB641'],
    ['Deutsche Bank', '64070024', 'DEUTDEDB643'],
    ['Deutsche Bank', '64070024', 'DEUTDEDB645'],
    ['Deutsche Bank', '64070024', 'DEUTDEDB646'],
    ['Deutsche Bank', '64070085', 'DEUTDESS640'],
    ['Deutsche Bank', '64070085', 'DEUTDESS641'],
    ['Deutsche Bank', '64070085', 'DEUTDESS642'],
    ['Deutsche Bank', '64070085', 'DEUTDESS643'],
    ['Deutsche Bank', '64070085', 'DEUTDESS644'],
    ['Deutsche Bank', '64070085', 'DEUTDESS645'],
    ['Deutsche Bank', '64070085', 'DEUTDESS646'],
    ['Postbank Ndl Deutsche Bank', '64070215', 'DEUTDESSP14'],
    ['Postbank Ndl Deutsche Bank', '64070224', 'DEUTDEDBP14'],
    ['Commerzbank Reutlingen', '64080014', 'DRESDEFF640'],
    ['Volksbank Reutlingen -alt-', '64090100', 'VBRTDE6RXXX'],
    ['Volksbank Ermstal-Alb', '64091200', 'GENODES1MTZ'],
    ['Volksbank Münsingen', '64091300', 'GENODES1MUN'],
    ['Commerzbank Tübingen', '64140036', 'COBADEFFXXX'],
    ['Kr Spk Tübingen', '64150020', 'SOLADES1TUB'],
    ['Volksbank Ammerbuch', '64161397', 'GENODES1AMM'],
    ['Raiffbk Härten -alt-', '64161608', 'GENODES1RHK'],
    ['Volksbank Mössingen -alt-', '64161956', 'GENODES1VMO'],
    ['VB Hohenzollern-Balingen', '64163225', 'GENODES1VHZ'],
    ['Commerzbank Tübingen', '64180014', 'DRESDEFF641'],
    ['Volksbank Tübingen -alt-', '64190110', 'GENODES1TUE'],
    ['Volksbank Nagoldtal -alt-', '64191030', 'GENODES1NAG'],
    ['Volksbank Horb -alt-', '64191700', 'GENODES1HOR'],
    ['Commerzbank TR F-T48', '64240048', 'COBADEFFXXX'],
    ['Commerzbank Rottweil', '64240071', 'COBADEFFXXX'],
    ['Kr Spk Rottweil', '64250040', 'SOLADES1RWL'],
    ['Kr Spk Freudenstadt', '64251060', 'SOLADES1FDS'],
    ['VB Baiersbronn Murgtal-alt-', '64261363', 'GENODES1BAI'],
    ['Murgtalbank -alt-', '64261626', 'GENODES1MMO'],
    ['Volksbank Nordschwarzwald', '64261853', 'GENODES1PGW'],
    ['VR-Bank Dornstetten-Horb', '64262408', 'GENODES1VDS'],
    ['Volksbank Rottweil', '64290120', 'GENODES1VRW'],
    ['VB im Kreis Freudenstadt', '64291010', 'GENODES1FDS'],
    ['Volksbank Deisslingen', '64291420', 'GENODES1VDL'],
    ['VB Schwarzwald-Neckar -alt-', '64292020', 'GENODES1SBG'],
    ['Volksbank Trossingen', '64292310', 'GENODES1TRO'],
    ['Kr Spk Tuttlingen', '64350070', 'SOLADES1TUT'],
    ['Raiffbk Donau-Heuberg', '64361359', 'GENODES1RDH'],
    ['Commerzbank Tuttlingen', '64380011', 'DRESDEFF643'],
    ['VB Schwarzwald-Donau-Neckar', '64390130', 'GENODES1TUT'],
    ['UniCredit Bank-HypoVereinbk', '65020186', 'HYVEDEMM588'],
    ['Commerzbank Ravensburg', '65040073', 'COBADEFFXXX'],
    ['Kr Spk Ravensburg', '65050110', 'SOLADES1RVB'],
    ['Raiffbk Aulendorf -alt-', '65061219', 'GENODES1AUL'],
    ['VR Bank Ravensburg-Weing.', '65062577', 'GENODES1RRV'],
    ['Raiffeisenbank Bad Saulgau', '65063086', 'GENODES1SAG'],
    ['Deutsche Bank', '65070024', 'DEUTDEDB649'],
    ['Deutsche Bank', '65070024', 'DEUTDEDB650'],
    ['Deutsche Bank', '65070024', 'DEUTDEDB651'],
    ['Deutsche Bank', '65070024', 'DEUTDEDB652'],
    ['Deutsche Bank', '65070024', 'DEUTDEDB654'],
    ['Deutsche Bank', '65070024', 'DEUTDEDB657'],
    ['Deutsche Bank', '65070024', 'DEUTDEDB658'],
    ['Deutsche Bank', '65070084', 'DEUTDESS649'],
    ['Deutsche Bank', '65070084', 'DEUTDESS650'],
    ['Deutsche Bank', '65070084', 'DEUTDESS651'],
    ['Deutsche Bank', '65070084', 'DEUTDESS652'],
    ['Deutsche Bank', '65070084', 'DEUTDESS654'],
    ['Deutsche Bank', '65070084', 'DEUTDESS657'],
    ['Deutsche Bank', '65070084', 'DEUTDESS658'],
    ['Commerzbank Ravensburg', '65080009', 'DRESDEFF650'],
    ['Volksbank Ravensburg -alt-', '65090100', 'ULMVDE66XXX'],
    ['Voba Allgäu-Oberschwaben', '65091040', 'GENODES1LEU'],
    ['Bad Waldseer Bank -alt-', '65091300', 'GENODES1BWB'],
    ['Volksbank Weingarten -alt-', '65091600', 'GENODES1VWG'],
    ['VB Allgäu-West -alt-', '65092010', 'GENODES1WAN'],
    ['Volksbank Altshausen', '65092200', 'GENODES1VAH'],
    ['VB Bad Saulgau', '65093020', 'GENODES1SLG'],
    ['Int Bkhaus Bodensee', '65110200', 'IBBFDE81XXX'],
    ['Commerzbank Friedrichshafen', '65140072', 'COBADEFFXXX'],
    ['Genossenschaftsbank -alt-', '65161497', 'GENODES1GMB'],
    ['RB Oberteuringen-Meckenb.', '65162832', 'GENODES1OTE'],
    ['Commerzbank Friedrichshfn', '65180005', 'DRESDEFF651'],
    ['VB Friedrichshafen -alt-', '65190110', 'GENODES1VFN'],
    ['Volksbank FN-TT', '65191500', 'GENODES1TET'],
    ['Commerzbank Albstadt', '65340004', 'COBADEFFXXX'],
    ['Commerzbank Balingen', '65341204', 'COBADEFFXXX'],
    ['Ld Bk Kr Spk Sigmaringen', '65351050', 'SOLADES1SIG'],
    ['Spk Zollernalb', '65351260', 'SOLADES1BAL'],
    ['Volksbank Heuberg -alt-', '65361469', 'GENODES1HBM'],
    ['VR Bk Heuberg-Winterlingen', '65361898', 'GENODES1WLB'],
    ['Onstmettinger Bank', '65361989', 'GENODES1ONS'],
    ['Raiffbk Geislingen-Rosenf', '65362499', 'GENODES1GEI'],
    ['Deutsche Bank', '65370024', 'DEUTDEDB603'],
    ['Deutsche Bank', '65370024', 'DEUTDEDB653'],
    ['Deutsche Bank', '65370024', 'DEUTDEDB655'],
    ['Deutsche Bank', '65370075', 'DEUTDESS603'],
    ['Deutsche Bank', '65370075', 'DEUTDESS653'],
    ['Deutsche Bank', '65370075', 'DEUTDESS655'],
    ['Commerzbank Albstadt', '65380003', 'DRESDEFF653'],
    ['Volksbank Albstadt', '65390120', 'GENODES1EBI'],
    ['Volksbank Balingen -alt-', '65391210', 'GENODES1BAL'],
    ['Volksbank Tailfingen -alt-', '65392030', 'GENODES1TAI'],
    ['Commerzbank Biberach Riß', '65440087', 'COBADEFFXXX'],
    ['Kr Spk Biberach', '65450070', 'SBCRDE66XXX'],
    ['Raiffbk Biberach -alt-', '65461878', 'GENODES1WAR'],
    ['Raiffbk Illertal -alt-', '65462231', 'GENODES1ERO'],
    ['VR Laupheim-Illertal', '65491320', 'GENODES1VBL'],
    ['VR Bank Riedlingen-Federsee', '65491510', 'GENODES1VRR'],
    ['BBk Karlsruhe', '66000000', 'MARKDEF1660'],
    ['Postbank Ndl Deutsche Bank', '66010075', 'PBNKDEFFXXX'],
    ['Deutsche Bauspk Badenia', '66010200', 'BBSPDE6KXXX'],
    ['L-Bank', '66010700', 'LKBWDE6KXXX'],
    ['BW Bank Karlsruhe', '66020020', 'SOLADEST663'],
    ['UniCredit Bank-HypoVereinbk', '66020286', 'HYVEDEMM475'],
    ['Bank für Sozialwirtschaft', '66020500', 'BFSWDE33KRL'],
    ['Sozialbank Karlsruhe', '66020566', 'BFSWDE33KRL'],
    ['Isbank eh Filiale Mannheim', '66030600', 'ISBKDEFXXXX'],
    ['Commerzbank Karlsruhe', '66040018', 'COBADEFFXXX'],
    ['Commerzbank Karlsruhe', '66040026', 'COBADEFFXXX'],
    ['Landesbank Baden-Württ', '66050000', 'SOLADEST660'],
    ['Spk Karlsruhe', '66050101', 'KARSDE66XXX'],
    ['Sparkasse Ettlingen -alt-', '66051220', 'SOLADES1ETT'],
    ['DZ BANK', '66060000', 'GENODE6KXXX'],
    ['Spar- und Kreditbank -alt-', '66060300', 'GENODE61KA3'],
    ['Spar- u Kreditbk Rheinstett', '66061407', 'GENODE61RH2'],
    ['VB Stutensee-Weing. -alt-', '66061724', 'GENODE61WGA'],
    ['Spar- u Kreditbk Hardt-alt-', '66062138', 'GENODE61EGG'],
    ['Raiffbk Hardt-Bruhrain', '66062366', 'GENODE61DET'],
    ['Raiffeisenbank Elztal', '66069103', 'GENODE61ELZ'],
    ['Spar- u Kreditbk Dau. -alt-', '66069104', 'GENODE61DAC'],
    ['Volksbank Krautheim', '66069342', 'GENODE61KTH'],
    ['Deutsche Bank', '66070004', 'DEUTDESM660'],
    ['Deutsche Bank', '66070004', 'DEUTDESM661'],
    ['Deutsche Bank', '66070004', 'DEUTDESM663'],
    ['Deutsche Bank', '66070004', 'DEUTDESM664'],
    ['Deutsche Bank', '66070024', 'DEUTDEDB660'],
    ['Deutsche Bank', '66070024', 'DEUTDEDB661'],
    ['Deutsche Bank', '66070024', 'DEUTDEDB663'],
    ['Deutsche Bank', '66070024', 'DEUTDEDB664'],
    ['Postbank Ndl Deutsche Bank', '66070213', 'DEUTDESMP12'],
    ['Postbank Ndl Deutsche Bank', '66070224', 'DEUTDEDBP12'],
    ['Commerzbank Karlsruhe', '66080052', 'DRESDEFF660'],
    ['BBBank Karlsruhe', '66090800', 'GENODE61BBB'],
    ['PSD Bank Karlsruhe-Neustadt', '66090900', 'GENODEF1P10'],
    ['Volksbank Ettlingen', '66091200', 'GENODE61ETT'],
    ['Volksbank pur', '66190000', 'GENODE61KA1'],
    ['Commerzbank Baden-Baden', '66240002', 'COBADEFFXXX'],
    ['Spk Baden-Baden Gaggenau', '66250030', 'SOLADES1BAD'],
    ['Sparkasse Bühl', '66251434', 'SOLADES1BHL'],
    ['Spar-u Kreditbank Bühlertal', '66261092', 'GENODE61BHT'],
    ['Raiffeisenbank Altschweier', '66261416', 'GENODE61ALR'],
    ['Deutsche Bank', '66270001', 'DEUTDESM662'],
    ['Deutsche Bank', '66270001', 'DEUTDESM665'],
    ['Deutsche Bank', '66270001', 'DEUTDESM667'],
    ['Deutsche Bank', '66270001', 'DEUTDESM669'],
    ['Deutsche Bank', '66270001', 'DEUTDESM671'],
    ['Deutsche Bank', '66270024', 'DEUTDEDB662'],
    ['Deutsche Bank', '66270024', 'DEUTDEDB665'],
    ['Deutsche Bank', '66270024', 'DEUTDEDB667'],
    ['Deutsche Bank', '66270024', 'DEUTDEDB669'],
    ['Deutsche Bank', '66270024', 'DEUTDEDB671'],
    ['Commerzbank Baden-Baden', '66280053', 'DRESDEFF662'],
    ['VB Baden-Bdn Rastatt -alt-', '66290000', 'VBRADE6KXXX'],
    ['Volksbank Achern -alt-', '66291300', 'GENODE61ACH'],
    ['Volksbank Bühl', '66291400', 'GENODE61BHL'],
    ['Commerzbank Bruchsal', '66340018', 'COBADEFFXXX'],
    ['Spk Kraichgau', '66350036', 'BRUSDE66XXX'],
    ['VB Bruchsal-Bretten -alt-', '66391200', 'GENODE61BTT'],
    ['VB Bruhrain-Kraich-H. -alt-', '66391600', 'GENODE61ORH'],
    ['Faißtbank Wolfach', '66432700', 'FAITDE66XXX'],
    ['Commerzbank Offenburg', '66440084', 'COBADEFFXXX'],
    ['Sparkasse Offenburg/Ortenau', '66450050', 'SOLADES1OFG'],
    ['Spk Gengenbach -alt-', '66451346', 'SOLADES1GEB'],
    ['Spk Kinzigtal', '66451548', 'SOLADES1HAL'],
    ['Spk Hanauerland Kehl', '66451862', 'SOLADES1KEL'],
    ['Spk Wolfach', '66452776', 'SOLADES1WOF'],
    ['Deutsche Bank', '66470024', 'DEUTDEDB968'],
    ['Deutsche Bank', '66470024', 'DEUTDEDB969'],
    ['Deutsche Bank', '66470024', 'DEUTDEDB970'],
    ['Deutsche Bank', '66470024', 'DEUTDEDB971'],
    ['Deutsche Bank', '66470035', 'DEUTDE6F664'],
    ['Deutsche Bank', '66470035', 'DEUTDE6F665'],
    ['Deutsche Bank', '66470035', 'DEUTDE6F666'],
    ['Deutsche Bank', '66470035', 'DEUTDE6F667'],
    ['Volksbank', '66490000', 'GENODE61OG1'],
    ['Volksbank Bühl', '66491800', 'GENODE61BHL'],
    ['VB Appenweier -alt-', '66492600', 'GENODE61APP'],
    ['VB Mittlerer Schwarzwald', '66492700', 'GENODE61KZT'],
    ['Sparkasse Rastatt-Gernsbach', '66550070', 'SOLADES1RAS'],
    ['Rb Südhardt Durmersheim', '66562053', 'GENODE61DUR'],
    ['VR-Bank Mittelb Iffezheim', '66562300', 'GENODE61IFF'],
    ['Commerzbank Pforzheim', '66640035', 'COBADEFFXXX'],
    ['Sparkasse Pforzheim Calw', '66650085', 'PZHSDE66XXX'],
    ['Raiffbk Bauschlott -alt-', '66661244', 'GENODE61NBT'],
    ['Raiffeisenbank Kieselbronn', '66661329', 'GENODE61KBR'],
    ['VR Bank im Enzkreis -alt-', '66661454', 'GENODE61NFO'],
    ['Raiffeisenbank Ersingen', '66662155', 'GENODE61ERS'],
    ['VB Stein Eisingen -alt-', '66662220', 'GENODE61KBS'],
    ['Deutsche Bank', '66670006', 'DEUTDESM666'],
    ['Deutsche Bank', '66670006', 'DEUTDESM677'],
    ['Deutsche Bank', '66670024', 'DEUTDEDB666'],
    ['Deutsche Bank', '66670024', 'DEUTDEDB677'],
    ['Commerzbank Pforzheim', '66680013', 'DRESDEFF666'],
    ['Volksbank Pforzheim -alt-', '66690000', 'VBPFDE66XXX'],
    ['VR Bank Enz plus -alt-', '66692300', 'GENODE61WIR'],
    ['Raiffbk Kraichgau -alt-', '66762332', 'GENODE61KIR'],
    ['Raiffbk Neudenau -alt-', '66762433', 'GENODE61NEU'],
    ['UniCredit Bank-HypoVereinbk', '67020190', 'HYVEDEMM489'],
    ['Commerzbank Mannheim', '67040031', 'COBADEFFXXX'],
    ['Commerzbank CC Mannheim', '67040060', 'COBADEFFXXX'],
    ['Commerzbank CC Mannheim', '67040061', 'COBADEFFXXX'],
    ['Commerzbank Gf WK, Mannheim', '67040085', 'COBADEFFXXX'],
    ['Spk Rhein Neckar Nord', '67050505', 'MANSDE66XXX'],
    ['Spk Hockenheim', '67051203', 'SOLADES1HOC'],
    ['Volksbank Ma-Sandhofen', '67060031', 'GENODE61MA3'],
    ['Deutsche Bank', '67070010', 'DEUTDESMXXX'],
    ['Deutsche Bank', '67070010', 'DEUTDESM670'],
    ['Deutsche Bank', '67070010', 'DEUTDESM673'],
    ['Deutsche Bank', '67070010', 'DEUTDESM675'],
    ['Deutsche Bank', '67070010', 'DEUTDESM676'],
    ['Deutsche Bank', '67070024', 'DEUTDEDB670'],
    ['Deutsche Bank', '67070024', 'DEUTDEDB673'],
    ['Deutsche Bank', '67070024', 'DEUTDEDB675'],
    ['Deutsche Bank', '67070024', 'DEUTDEDB676'],
    ['Deutsche Bank', '67070024', 'DEUTDEDBMAN'],
    ['Commerzbank Mannheim', '67080050', 'DRESDEFF670'],
    ['Commerzbank ITGK2 Mannh', '67080085', 'DRESDEFFI60'],
    ['Commerzbank ITGK3 Mannh', '67080086', 'DRESDEFFI61'],
    ['Commerzbank ITGK Mannheim', '67089440', 'DRESDEFFI22'],
    ['VR Bank Rhein-Neckar', '67090000', 'GENODE61MA2'],
    ['Volksbank Kurpfalz', '67092300', 'GENODE61WNM'],
    ['UniCredit Bank-HypoVereinbk', '67220286', 'HYVEDEMM479'],
    ['MLP Bank', '67230000', 'MLPBDE61XXX'],
    ['MLP Bank Zw CS', '67230001', 'MLPBDE61001'],
    ['Commerzbank Heidelberg', '67240039', 'COBADEFFXXX'],
    ['Spk Heidelberg', '67250020', 'SOLADES1HDB'],
    ['Raiffeisenbank Baiertal', '67262243', 'GENODE61WIB'],
    ['Volksbank Rot St Leon-Rot', '67262550', 'GENODE61LRO'],
    ['Deutsche Bank', '67270003', 'DEUTDESM672'],
    ['Deutsche Bank', '67270003', 'DEUTDESM674'],
    ['Deutsche Bank', '67270003', 'DEUTDESM678'],
    ['Deutsche Bank', '67270024', 'DEUTDEDB672'],
    ['Deutsche Bank', '67270024', 'DEUTDEDB674'],
    ['Deutsche Bank', '67270024', 'DEUTDEDB678'],
    ['Commerzbank Heidelberg', '67280051', 'DRESDEFF672'],
    ['Heidelberger Volksbank', '67290000', 'GENODE61HD1'],
    ['Volksbank Kurpfalz -alt-', '67290100', 'GENODE61HD3'],
    ['Volksbank Neckartal', '67291700', 'GENODE61NGD'],
    ['Volksbank Kraichgau -alt-', '67291900', 'GENODE61SSH'],
    ['Volksbank Kraichgau', '67292200', 'GENODE61WIE'],
    ['Spk Tauberfranken', '67352565', 'SOLADES1TBB'],
    ['VB Main-Tauber', '67390000', 'GENODE61WTH'],
    ['Spk Neckartal-Odenwald', '67450048', 'SOLADES1MOS'],
    ['Volksbank Mosbach -alt-', '67460041', 'GENODE61MOS'],
    ['Volksbank Franken Buchen', '67461424', 'GENODE61BUC'],
    ['Volksbank Kirnau', '67461733', 'GENODE61RNG'],
    ['Volksbank Limbach', '67462368', 'GENODE61LMB'],
    ['BBk Freiburg im Breisgau', '68000000', 'MARKDEF1680'],
    ['UniCredit Bank-HypoVereinbk', '68020186', 'HYVEDEMM357'],
    ['Bankhaus E Mayer Freiburg B', '68030000', 'BKMADE61XXX'],
    ['Commerzbank Freiburg i Br', '68040007', 'COBADEFFXXX'],
    ['Spk Freiburg-Nördl Breisgau', '68050101', 'FRSPDE66XXX'],
    ['Spk Hochschwarzwald T-Neust', '68051004', 'SOLADES1HSW'],
    ['Spk Bonndorf-Stühlingen', '68051207', 'SOLADES1BND'],
    ['Spk St. Blasien', '68052230', 'SOLADES1STB'],
    ['Spk Staufen-Breisach', '68052328', 'SOLADES1STF'],
    ['Spk Schönau-Todtnau -alt-', '68052863', 'SOLADES1SCH'],
    ['VB Breisgau-Markgräflerland', '68061505', 'GENODE61IHR'],
    ['Raiffbk Denzlingen-Sexau', '68062105', 'GENODE61DEN'],
    ['Raiffbk Wyhl Kaiserstuhl', '68062730', 'GENODE61WYH'],
    ['Raiffeisenbank Kaiserstuhl', '68063479', 'GENODE61VOK'],
    ['Raiffeisenbank im Breisgau', '68064222', 'GENODE61GUN'],
    ['Deutsche Bank', '68070024', 'DEUTDEDB681'],
    ['Deutsche Bank', '68070024', 'DEUTDEDB685'],
    ['Deutsche Bank', '68070024', 'DEUTDEDB687'],
    ['Deutsche Bank', '68070024', 'DEUTDEDB689'],
    ['Deutsche Bank', '68070024', 'DEUTDEDBFRE'],
    ['Deutsche Bank', '68070030', 'DEUTDE6FXXX'],
    ['Deutsche Bank', '68070030', 'DEUTDE6F681'],
    ['Deutsche Bank', '68070030', 'DEUTDE6F685'],
    ['Deutsche Bank', '68070030', 'DEUTDE6F687'],
    ['Deutsche Bank', '68070030', 'DEUTDE6F689'],
    ['Postbank Ndl Deutsche Bank', '68070212', 'DEUTDE6FP11'],
    ['Postbank Ndl Deutsche Bank', '68070224', 'DEUTDEDBP11'],
    ['Commerzbank Freiburg', '68080030', 'DRESDEFF680'],
    ['Commerzbank Zw Ms Freibg', '68080031', 'DRESDEFFI44'],
    ['Commerzbank ITGK1 Freibrg', '68080085', 'DRESDEFFI62'],
    ['Commerzbank ITGK2 Freibrg', '68080086', 'DRESDEFFJ22'],
    ['Volksbank Freiburg', '68090000', 'GENODE61FR1'],
    ['PSD Bank RheinNeckarSaar', '68090900', 'GENODEF1P07'],
    ['Volksbank Müllheim -alt-', '68091900', 'GENODE61MHL'],
    ['VB Breisgau Nord', '68092000', 'GENODE61EMM'],
    ['Volksbank Staufen', '68092300', 'GENODE61STF'],
    ['Deutsche Bank', '68270024', 'DEUTDEDB682'],
    ['Deutsche Bank', '68270033', 'DEUTDE6F682'],
    ['Volksbank Lahr', '68290000', 'GENODE61LAH'],
    ['Commerzbank Lörrach', '68340058', 'COBADEFFXXX'],
    ['Sparkasse Lörrach-Rheinfeld', '68350048', 'SKLODE66XXX'],
    ['Sparkasse Wiesental', '68351557', 'SOLADES1SFH'],
    ['Sparkasse Markgräflerland', '68351865', 'SOLADES1MGL'],
    ['Deutsche Bank', '68370024', 'DEUTDEDB679'],
    ['Deutsche Bank', '68370024', 'DEUTDEDB680'],
    ['Deutsche Bank', '68370024', 'DEUTDEDB683'],
    ['Deutsche Bank', '68370024', 'DEUTDEDB684'],
    ['Deutsche Bank', '68370024', 'DEUTDEDB686'],
    ['Deutsche Bank', '68370024', 'DEUTDEDB688'],
    ['Deutsche Bank', '68370024', 'DEUTDEDB693'],
    ['Deutsche Bank', '68370034', 'DEUTDE6F678'],
    ['Deutsche Bank', '68370034', 'DEUTDE6F679'],
    ['Deutsche Bank', '68370034', 'DEUTDE6F683'],
    ['Deutsche Bank', '68370034', 'DEUTDE6F684'],
    ['Deutsche Bank', '68370034', 'DEUTDE6F686'],
    ['Deutsche Bank', '68370034', 'DEUTDE6F688'],
    ['Deutsche Bank', '68370034', 'DEUTDE6F693'],
    ['VB Dreiländereck Lörrach', '68390000', 'VOLODE66XXX'],
    ['VR Bank -alt-', '68391500', 'GENODE61SPF'],
    ['Sparkasse Hochrhein', '68452290', 'SKHRDE6WXXX'],
    ['VB Klettgau-Wutöschingen', '68462427', 'GENODE61WUT'],
    ['Volksbank Rhein-Wehra', '68490000', 'GENODE61BSK'],
    ['Volksbank Hochrhein', '68492200', 'GENODE61WT1'],
    ['UniCredit Bank-HypoVereinbk', '69020190', 'HYVEDEMM591'],
    ['Commerzbank Konstanz', '69040045', 'COBADEFFXXX'],
    ['Sparkasse Bodensee', '69050001', 'SOLADES1KNZ'],
    ['Bez Spk Reichenau', '69051410', 'SOLADES1REN'],
    ['Spk Pfullendorf-Meßkirch', '69051620', 'SOLADES1PFD'],
    ['Spk Salem-Heiligenberg', '69051725', 'SOLADES1SAL'],
    ['Volksbank Überlingen', '69061800', 'GENODE61UBE'],
    ['Deutsche Bank', '69070024', 'DEUTDEDB690'],
    ['Deutsche Bank', '69070024', 'DEUTDEDB691'],
    ['Deutsche Bank', '69070032', 'DEUTDE6F690'],
    ['Deutsche Bank', '69070032', 'DEUTDE6F691'],
    ['Hagnauer Volksbank', '69091200', 'GENODE61HAG'],
    ['Volksbank Pfullendorf', '69091600', 'GENODE61PFD'],
    ['UniCredit Bank-HypoVereinbk', '69220186', 'HYVEDEMM590'],
    ['Commerzbank Singen Hohentw', '69240075', 'COBADEFFXXX'],
    ['Sparkasse Hegau-Bodensee', '69250035', 'SOLADES1SNG'],
    ['Spk Engen-Gottmadingen', '69251445', 'SOLADES1ENG'],
    ['Sparkasse Stockach -alt-', '69251755', 'SOLADES1STO'],
    ['Deutsche Bank', '69270024', 'DEUTDEDB692'],
    ['Deutsche Bank', '69270024', 'DEUTDEDB696'],
    ['Deutsche Bank', '69270038', 'DEUTDE6F692'],
    ['Deutsche Bank', '69270038', 'DEUTDE6F696'],
    ['Commerzbank Singen', '69280035', 'DRESDEFF692'],
    ['Volksbank Hegau -alt-', '69290000', 'GENODE61SIN'],
    ['Volksbank Konstanz', '69291000', 'GENODE61RAD'],
    ['Volksbank Konstanz Gf GA', '69291099', 'GENODE61RAD'],
    ['Volksbank Meßkirch Raiffbk', '69362032', 'GENODE61MES'],
    ['BBk Villingen-Schwenningen', '69400000', 'MARKDEF1694'],
    ['Commerzbank Villingen-Schw', '69440007', 'COBADEFFXXX'],
    ['Commerzbank CC Villingen', '69440060', 'COBADEFFXXX'],
    ['Spk Schwarzwald-Baar', '69450065', 'SOLADES1VSS'],
    ['Deutsche Bank', '69470024', 'DEUTDEDB642'],
    ['Deutsche Bank', '69470024', 'DEUTDEDB644'],
    ['Deutsche Bank', '69470024', 'DEUTDEDB694'],
    ['Deutsche Bank', '69470024', 'DEUTDEDB697'],
    ['Deutsche Bank', '69470024', 'DEUTDEDB698'],
    ['Deutsche Bank', '69470024', 'DEUTDEDB699'],
    ['Deutsche Bank', '69470039', 'DEUTDE6F642'],
    ['Deutsche Bank', '69470039', 'DEUTDE6F644'],
    ['Deutsche Bank', '69470039', 'DEUTDE6F694'],
    ['Deutsche Bank', '69470039', 'DEUTDE6F697'],
    ['Deutsche Bank', '69470039', 'DEUTDE6F698'],
    ['Deutsche Bank', '69470039', 'DEUTDE6F699'],
    ['VB Schwarzwald Baar -alt-', '69490000', 'GENODE61VS1'],
    ['Volksbank Triberg -alt-', '69491700', 'GENODE61TRI'],
    ['BBk München', '70000000', 'MARKDEF1700'],
    ['Postbank Ndl Deutsche Bank', '70010080', 'PBNKDEFFXXX'],
    ['Aareal Bank Wiesbaden', '70010424', 'AARBDE5W700'],
    ['pbb, Garching', '70010500', 'REBMDEMMXXX'],
    ['pbb, Garching', '70010555', 'REBMDEMM555'],
    ['pbb, Garching', '70010570', 'REBMDE7CXXX'],
    ['pbb, Garching', '70010588', 'REBMDEMMSCA'],
    ['Middle East Bank, München', '70010800', 'KHMIDEMMXXX'],
    ['Bank Vontobel Europe', '70011200', 'VONTDEM1XXX'],
    ['BfW-Bank', '70011400', 'BFWODE71XXX'],
    ['SIEMENS BANK', '70011500', 'SIBADEMMXXX'],
    ['TEN31 Bank', '70011600', 'WEGBDE77XXX'],
    ['Bankhaus von der Heydt', '70011700', 'BVDHDEMMXXX'],
    ['InterCard Taufkirchen', '70011900', 'ICRDDE71XXX'],
    ['UCFIN MUENCHEN', '70012000', 'CLABDEM1XXX'],
    ['V-Bank München', '70012300', 'VBANDEMMXXX'],
    ['Bankhaus Obotritia München', '70012400', 'FLGMDE77XXX'],
    ['Südt. Sparkasse München', '70012600', 'SUSKDEM1XXX'],
    ['VZDB DE München', '70012700', 'DEPDDEMMXXX'],
    ['IBAN FIRST, München', '70012900', 'FXBBDEM2XXX'],
    ['ebase Aschheim', '70013000', 'EBSGDEMXXXX'],
    ['Ebase Aschheim', '70013010', 'EFSGDEM1XXX'],
    ['IC Cash, Taufkirchen', '70013400', 'CSHHDE71XXX'],
    ['Bankhaus Herzogpark München', '70013500', 'HERZDEM1XXX'],
    ['Transact Berlin', '70015000', 'TEZGDEB1XXX'],
    ['transact Berlin 001', '70015015', 'TEZGDEB1001'],
    ['transact Berlin 002', '70015025', 'TEZGDEB1002'],
    ['transact Berlin 003', '70015035', 'TEZGDEB1003'],
    ['PayCenter Freising', '70017000', 'PAGMDEM1XXX'],
    ['UniCredit Bank-HypoVereinbk', '70020270', 'HYVEDEMMXXX'],
    ['BNPParibas ex Commerzfinanz', '70020300', 'WKVBDEM1XXX'],
    ['Bank für Sozialwirtschaft', '70020500', 'BFSWDE33MUE'],
    ['Sozialbank München', '70020570', 'BFSWDE33MUE'],
    ['INTESA SANPAOLO München', '70020800', 'BCITDEFFMUC'],
    ['UniCredit Bank-HypoVereinbk', '70021180', 'HYVEDEMM418'],
    ['Fidor Bank München', '70022200', 'FDDODEMMXXX'],
    ['UniCredit Bank-HypoVereinbk', '70025175', 'HYVEDEMM643'],
    ['Fuggerbank Augsburg', '70030014', 'FUBKDE71MUC'],
    ['Bankhaus Reuschel & Co -alt', '70030300', 'CHDBDEHHXXX'],
    ['Merck Finck', '70030400', 'MEFIDEMMXXX'],
    ['Bankhaus Sperrer Freising', '70031000', 'BHLSDEM1XXX'],
    ['SGKB Deutschland, München', '70032500', 'GAKDDEM1XXX'],
    ['Baaderbank Unterschleißheim', '70033100', 'BDWBDEMMXXX'],
    ['OLB, Oldenburg', '70035000', 'OLBODEH2700'],
    ['Commerzbank München', '70040041', 'COBADEFFXXX'],
    ['Commerzbank Fil. München 2', '70040045', 'COBADEFFXXX'],
    ['Commerzbank MUE GF-M48', '70040048', 'COBADEFFXXX'],
    ['Commerzbank Gf 860 München', '70040060', 'COBADEFFXXX'],
    ['Commerzbank Gf 861 München', '70040061', 'COBADEFFXXX'],
    ['Commerzbank CC München', '70040062', 'COBADEFFXXX'],
    ['Commerzbank CC München', '70040063', 'COBADEFFXXX'],
    ['Commerzbank CC SP, München', '70040070', 'COBADEFFXXX'],
    ['Commerzbank Service-BZ', '70045050', 'COBADEFFXXX'],
    ['BayernLB München', '70050000', 'BYLADEMMXXX'],
    ['Sparkasse Freising Moosburg', '70051003', 'BYLADEM1FSI'],
    ['Sparkasse Dachau', '70051540', 'BYLADEM1DAH'],
    ['Spk Erding-Dorfen', '70051995', 'BYLADEM1ERD'],
    ['Spk Landsberg-Dießen', '70052060', 'BYLADEM1LLD'],
    ['Spk Fürstenfeldbruck', '70053070', 'BYLADEM1FFB'],
    ['Spk Bad Tölz-Wolfratshausen', '70054306', 'BYLADEM1WOR'],
    ['Deutsche Bank', '70070010', 'DEUTDEMMXXX'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM700'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM701'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM702'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM703'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM704'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM705'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM706'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM707'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM708'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM709'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM710'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM711'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM712'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM713'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM714'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM715'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM716'],
    ['Deutsche Bank', '70070010', 'DEUTDEMM717'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB700'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB701'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB702'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB703'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB704'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB705'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB706'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB707'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB708'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB709'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB710'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB711'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB712'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB713'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB714'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB715'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB716'],
    ['Deutsche Bank', '70070024', 'DEUTDEDB717'],
    ['Deutsche Bank', '70070024', 'DEUTDEDBMUC'],
    ['Postbank Ndl Deutsche Bank', '70070324', 'DEUTDEDBP16'],
    ['Postbank Ndl Deutsche Bank', '70070362', 'DEUTDEMMP16'],
    ['Commerzbank München', '70080000', 'DRESDEFF700'],
    ['Commerzbank Zw 56', '70080056', 'DRESDEFF714'],
    ['Commerzbk ZW 57 München', '70080057', 'DRESDEFF724'],
    ['Commerzbank ITKG3 München', '70080085', 'DRESDEFFI55'],
    ['Commerzbank ITGK4 München', '70080086', 'DRESDEFFJ23'],
    ['Commerzbank ITGK5 München', '70080087', 'DRESDEFFJ24'],
    ['Commerzbank ITGK6 München', '70080088', 'DRESDEFFJ25'],
    ['Commerzbank ITGK München', '70089470', 'DRESDEFFI23'],
    ['Commerzbank ITGK München2', '70089472', 'DRESDEFFI45'],
    ['Hausbank München', '70090100', 'GENODEF1M04'],
    ['Hausbank München Gf Wohnung', '70090124', 'GENODEF1MU4'],
    ['Sparda-Bank München', '70090500', 'GENODEF1S04'],
    ['VB Raiffbk Dachau', '70091500', 'GENODEF1DCA'],
    ['VR-Dachau', '70091510', 'GENODEF1DCA'],
    ['VR-Bank Landsberg-Ammersee', '70091600', 'GENODEF1DSS'],
    ['VR-Bank Erding -alt-', '70091900', 'GENODEF1EDV'],
    ['VR-Bank Starnberg-Hg-Lbg', '70093200', 'GENODEF1STH'],
    ['VR-Bank Ismaning Hallb Neuf', '70093400', 'GENODEF1ISV'],
    ['Postbank Ndl Deutsche Bank', '70110088', 'PBNKDEFFXXX'],
    ['Münch Hypoth Bank München', '70110500', 'MHYPDEMMXXX'],
    ['State Street Bk Int.München', '70120100', 'SBOSDEMXXXX'],
    ['BNP Paribas Deutschland', '70120400', 'DABBDEMMXXX'],
    ['CACEIS Bank, München', '70120500', 'FMBKDEMMXXX'],
    ['Airbus Bank', '70120600', 'AGBMDEMMXXX'],
    ['Oberbank München', '70120700', 'OBKLDEMXXXX'],
    ['MERKUR PRIVATBANK München', '70130800', 'GENODEF1M06'],
    ['Santander Bank München', '70133300', 'SCFBDE33XXX'],
    ['Stadtsparkasse München', '70150000', 'SSKMDEMMXXX'],
    ['DZ BANK München', '70160000', 'GENODEFF701'],
    ['VR-Bank Fürstenfeldbruck', '70163370', 'GENODEF1FFB'],
    ['VR Bank München Land', '70166486', 'GENODEF1OHC'],
    ['Raiffbk Griesstätt-Halfing', '70169132', 'GENODEF1HFG'],
    ['Raiffbk Chiemgau-Nord-Obing', '70169165', 'GENODEF1SBC'],
    ['VR-Bank Chiemgau-Süd -alt-', '70169168', 'GENODEF1RIW'],
    ['Raiffbk Pfaffenhofen Glonn', '70169186', 'GENODEF1ODZ'],
    ['Raiffbk Tattenh Großk.-alt-', '70169190', 'GENODEF1GKT'],
    ['Raiffbk Rupertiwinkel', '70169191', 'GENODEF1TEI'],
    ['Raiffbk Trostberg-Traun-alt', '70169195', 'GENODEF1TRU'],
    ['Alxinger Bank', '70169310', 'GENODEF1ALX'],
    ['Raiffbk sö Starnberg -alt-', '70169331', 'GENODEF1SSB'],
    ['Raiffbk Beuerberg-Eurasburg', '70169333', 'GENODEF1EUR'],
    ['Raiffeisenbank Lechrain', '70169351', 'GENODEF1ELB'],
    ['Raiffeisenbank Erding', '70169356', 'GENODEF1EDR'],
    ['Raiffeisenbank Gilching', '70169382', 'GENODEF1GIL'],
    ['Raiffeisenbank Gmund am Teg', '70169383', 'GENODEF1GMU'],
    ['Raiffbk Haag-Gars-Maitenb', '70169388', 'GENODEF1HMA'],
    ['Raiba Höhenkirchen u U-alt-', '70169402', 'GENODEF1HHK'],
    ['Raiffbk Holzkirchen-Otterf', '70169410', 'GENODEF1HZO'],
    ['Raiffbk Singoldtal', '70169413', 'GENODEF1HUA'],
    ['Raiff-VB Ebersberg', '70169450', 'GENODEF1ASG'],
    ['Raiffeisenbank Mittenwald', '70169459', 'GENODEF1MTW'],
    ['Raiffbk Westkreis', '70169460', 'GENODEF1MOO'],
    ['Genossenschaftsbank', '70169464', 'GENODEF1M07'],
    ['Raiffbk München-Nord', '70169465', 'GENODEF1M08'],
    ['Raiffeisenbank München-Süd', '70169466', 'GENODEF1M03'],
    ['Raiffeisenbank München-Süd', '70169470', 'GENODEF1GAA'],
    ['Raiffbk NSV-NBK -alt-', '70169474', 'GENODEF1NSV'],
    ['Raiffbk -alt-', '70169476', 'GENODEF1NBK'],
    ['Raiffbk Pfaffenwinkel', '70169509', 'GENODEF1PEI'],
    ['Raiffeisenbank Raisting', '70169521', 'GENODEF1RIG'],
    ['Bankhaus RSA', '70169524', 'GENODEF1RME'],
    ['Raiffbk Neumarkt-Reischach', '70169530', 'GENODEF1RWZ'],
    ['RB St Wolfgang-Schwind-alt-', '70169538', 'GENODEF1SWO'],
    ['Raiffbk Lech-Ammersee -alt-', '70169541', 'GENODEF1THG'],
    ['Raiffbk Isar-Loisachtal', '70169543', 'GENODEF1HHS'],
    ['Raiffeisenbank Steingaden', '70169558', 'GENODEF1SGA'],
    ['VR-Bank Taufkirchen-Dorfen', '70169566', 'GENODEF1TAV'],
    ['Raiffbk Taufk-Oberneukirch', '70169568', 'GENODEF1TAE'],
    ['Raiffbk Tölzer Land -alt-', '70169571', 'GENODEF1DTZ'],
    ['Raiffeisenbank Türkheim', '70169575', 'GENODEF1TRH'],
    ['Raiff-Volksbank Tüßling', '70169576', 'GENODEF1TUS'],
    ['Raiffbk Unterschleißh-H-alt', '70169585', 'GENODEF1UNS'],
    ['Raiffbk im Oberland', '70169598', 'GENODEF1MIB'],
    ['Raiffeisenbank Weil u Umgeb', '70169599', 'GENODEF1WEI'],
    ['Raiffeisenbank Weilheim alt', '70169602', 'GENODEF1WM1'],
    ['VR-Bank Erding', '70169605', 'GENODEF1ISE'],
    ['Freisinger Bank VB-Raiffbk', '70169614', 'GENODEF1FSR'],
    ['Raiffeisenbk Zorneding-alt-', '70169619', 'GENODEF1ZOR'],
    ['RB Aiglsbach -alt-', '70169653', 'GENODEF1AIG'],
    ['Raiffeisenbank Hallertau', '70169693', 'GENODEF1RHT'],
    ['Münchner Bank', '70190000', 'GENODEF1M01'],
    ['Münchner Bank', '70190010', 'GENODEF1M1Y'],
    ['LfA Förderbank München', '70220000', 'LFFBDEMMXXX'],
    ['BHF-BANK München', '70220200', 'BHFBDEFF700'],
    ['BMW Bank München', '70220300', 'BMWBDEMUXXX'],
    ['Wüstenrot Bausparkasse', '70220900', 'DRESDEFFBFC'],
    ['Isbank München', '70230600', 'ISBKDEFXMUN'],
    ['Kr Spk München Starnbg Ebbg', '70250150', 'BYLADEM1KMS'],
    ['UniCredit Bank-HypoVereinbk', '70320090', 'HYVEDEMM654'],
    ['UniCredit Bank-HypoVereinbk', '70321194', 'HYVEDEMM466'],
    ['UniCredit Bank-HypoVereinbk', '70322192', 'HYVEDEMM664'],
    ['Kr Spk Garmisch-Part. -alt-', '70350000', 'BYLADEM1GAP'],
    ['Sparkasse Oberland', '70351030', 'BYLADEM1WHM'],
    ['Raiffbk Wallgau-Krün', '70362595', 'GENODEF1WAK'],
    ['Commerzbank Garmisch-Par', '70380006', 'DRESDEFF703'],
    ['VR-Bank Werdenfels', '70390000', 'GENODEF1GAP'],
    ['VR-Bank Werdenfels', '70390010', 'GENODEF1GAP'],
    ['VB-Raiffbk Penzberg -alt-', '70391800', 'GENODEF1PZB'],
    ['UniCredit Bank-HypoVereinbk', '71020072', 'HYVEDEMM410'],
    ['UniCredit Bank-HypoVereinbk', '71021270', 'HYVEDEMM629'],
    ['UniCredit Bank-HypoVereinbk', '71022182', 'HYVEDEMM453'],
    ['UniCredit Bank-HypoVereinbk', '71023173', 'HYVEDEMM632'],
    ['Spk Berchtesgadener Land', '71050000', 'BYLADEM1BGL'],
    ['Kr Spk Altötting-Burgh-alt-', '71051010', 'BYLADEM1AOE'],
    ['Kr Spk Traunstein-Trostberg', '71052050', 'BYLADEM1TST'],
    ['VR meine Raiffeisenbk -alt-', '71061009', 'GENODEF1AOE'],
    ['Raiffeisenbank Anger', '71062802', 'GENODEF1AGE'],
    ['VB RB Oberbayern Südost', '71090000', 'GENODEF1BGL'],
    ['UniCredit Bank-HypoVereinbk', '71120077', 'HYVEDEMM448'],
    ['UniCredit Bank-HypoVereinbk', '71120078', 'HYVEDEMM644'],
    ['UniCredit Bank-HypoVereinbk', '71121176', 'HYVEDEMM438'],
    ['UniCredit Bank-HypoVereinbk', '71122183', 'HYVEDEMM457'],
    ['Commerzbank Rosenheim', '71140041', 'COBADEFFXXX'],
    ['Commerzbank Mühldorf Inn', '71141041', 'COBADEFFXXX'],
    ['Commerzbank Waldkraiburg', '71142041', 'COBADEFFXXX'],
    ['Spk Rosenheim-Bad Aibling', '71150000', 'BYLADEM1ROS'],
    ['Spk Altötting-Mühldorf', '71151020', 'BYLADEM1MDF'],
    ['Kr Spk Miesbach-Tegernsee', '71152570', 'BYLADEM1MIB'],
    ['Kr u St Spk Wasserburg', '71152680', 'BYLADEM1WSB'],
    ['meine Volksbank Raiffbk', '71160000', 'GENODEF1VRR'],
    ['VR Bank Rosenheim-Chiemsee', '71160161', 'GENODEF1ROR'],
    ['VB-Raiffbk Chiemsee -alt-', '71161964', 'GENODEF1PRV'],
    ['Raiffeisenbank Oberaudorf', '71162355', 'GENODEF1OBD'],
    ['Raiffbk Aschau-Samerberg', '71162804', 'GENODEF1ASU'],
    ['Raiffeisenbank Mangfal-alt-', '71165150', 'GENODEF1AIB'],
    ['Commerzbank Rosenheim', '71180005', 'DRESDEFF711'],
    ['Volksbank Rosenheim -alt-', '71190000', 'GENODEF1ROV'],
    ['VR-Bank Burgh-Mühldorf-alt', '71191000', 'GENODEF1MUL'],
    ['BBk Augsburg', '72000000', 'MARKDEF1720'],
    ['BTV Zndl Deutschland', '72012300', 'BTVADE61XXX'],
    ['UniCredit Bank-HypoVereinbk', '72020070', 'HYVEDEMM408'],
    ['UniCredit Bank-HypoVereinbk', '72021271', 'HYVEDEMM236'],
    ['UniCredit Bank-HypoVereinbk', '72021876', 'HYVEDEMM259'],
    ['Fürst Fugger Privatbk Augsb', '72030014', 'FUBKDE71XXX'],
    ['Bankhaus Anton Hafner', '72030227', 'ANHODE77XXX'],
    ['Bankhaus Anton Hafner', '72030260', 'ANHODE7AXXX'],
    ['Commerzbank Augsburg', '72040046', 'COBADEFFXXX'],
    ['St Spk Augsburg', '72050000', 'AUGSDE77XXX'],
    ['Kr Spk Augsburg -alt-', '72050101', 'BYLADEM1AUG'],
    ['Spk Aichach-Schrobenhausen', '72051210', 'BYLADEM1AIC'],
    ['Spk Günzburg-Krumbach', '72051840', 'BYLADEM1GZK'],
    ['VR-Bank HG-Bank', '72062152', 'GENODEF1MTG'],
    ['RB Adelzhausen-Sielenb-alt-', '72069002', 'GENODEF1ADZ'],
    ['Raiffeisenbank Aindling', '72069005', 'GENODEF1AIL'],
    ['Raiffeisenbank Bissingen', '72069034', 'GENODEF1BSI'],
    ['Raiffeisenbank Bobingen', '72069036', 'GENODEF1BOI'],
    ['VR-Bank Donau-Mindel', '72069043', 'GENODEF1GZ2'],
    ['Raiffeisenbank Hiltenfingen', '72069105', 'GENODEF1HTF'],
    ['Raiffeisenbank Aschberg', '72069113', 'GENODEF1HZH'],
    ['Raiffbk Holzheim -alt-', '72069114', 'GENODEF1HZR'],
    ['Raiffeisenbank Ichenhausen', '72069119', 'GENODEF1ICH'],
    ['Raiffbk Jettingen-Sche-alt-', '72069123', 'GENODEF1JET'],
    ['Raiffbk Mittelschwaben', '72069126', 'GENODEF1BBT'],
    ['Rbk Krumbach/Schwaben -alt-', '72069132', 'GENODEF1KRR'],
    ['Raiffbk Stauden -alt-', '72069135', 'GENODEF1LST'],
    ['RB Wittelsb. Land, Mering', '72069155', 'GENODEF1MRI'],
    ['Raiffbk Unteres Zusamtal', '72069179', 'GENODEF1BWI'],
    ['Raiffeisenbank Offingen', '72069181', 'GENODEF1OFF'],
    ['Raiffeisenbank Rehling', '72069193', 'GENODEF1RLI'],
    ['Raiffbk Roggenburg -alt-', '72069209', 'GENODEF1RGB'],
    ['RB Schwabmünchen-Stauden', '72069220', 'GENODEF1SMU'],
    ['Raiffeisenbank Thannhausen', '72069235', 'GENODEF1THS'],
    ['Raiffbk Wittislingen -alt-', '72069263', 'GENODEF1WTS'],
    ['Raiffbk Augsburger Ld West', '72069274', 'GENODEF1ZUS'],
    ['RVB Wemding', '72069308', 'GENODEF1WDN'],
    ['Raiffeisen-Volksbank Ries', '72069329', 'GENODEF1NOE'],
    ['Raiffbk Schwaben Mitte', '72069736', 'GENODEF1BLT'],
    ['Raiffbk Pfaffenhausen', '72069789', 'GENODEF1PFA'],
    ['Deutsche Bank', '72070001', 'DEUTDEMM720'],
    ['Deutsche Bank', '72070001', 'DEUTDEMM723'],
    ['Deutsche Bank', '72070001', 'DEUTDEMM724'],
    ['Deutsche Bank', '72070001', 'DEUTDEMM725'],
    ['Deutsche Bank', '72070001', 'DEUTDEMM726'],
    ['Deutsche Bank', '72070001', 'DEUTDEMM727'],
    ['Deutsche Bank', '72070024', 'DEUTDEDB720'],
    ['Deutsche Bank', '72070024', 'DEUTDEDB723'],
    ['Deutsche Bank', '72070024', 'DEUTDEDB724'],
    ['Deutsche Bank', '72070024', 'DEUTDEDB725'],
    ['Deutsche Bank', '72070024', 'DEUTDEDB726'],
    ['Deutsche Bank', '72070024', 'DEUTDEDB727'],
    ['Postbank Ndl Deutsche Bank', '72070324', 'DEUTDEDBP19'],
    ['Postbank Ndl Deutsche Bank', '72070365', 'DEUTDEMMP19'],
    ['Commerzbank Augsburg', '72080001', 'DRESDEFF720'],
    ['VR Bank Augsburg-Ostallgäu', '72090000', 'GENODEF1AUB'],
    ['Sparda-Bank Augsburg', '72090500', 'GENODEF1S03'],
    ['PSD Bank München Augsburg', '72090900', 'GENODEF1P14'],
    ['Volksbank Günzburg -alt-', '72091800', 'GENODEF1GZ1'],
    ['UniCredit Bank-HypoVereinbk', '72120078', 'HYVEDEMM426'],
    ['UniCredit Bank-HypoVereinbk', '72122181', 'HYVEDEMM665'],
    ['Commerzbank Ingolstadt', '72140052', 'COBADEFFXXX'],
    ['Spk Ingolstadt Eichstätt', '72150000', 'BYLADEM1ING'],
    ['Sparkasse Eichstätt -alt-', '72151340', 'BYLADEM1EIS'],
    ['Spk Pfaffenhofen', '72151650', 'BYLADEM1PAF'],
    ['Spk Aichach-Schrobenhausen', '72151880', 'BYLADEM1SSH'],
    ['Spk Neuburg-Rain', '72152070', 'BYLADEM1NEB'],
    ['VR Bank Bayern Mitte', '72160818', 'GENODEF1INP'],
    ['Raiffbk Aresing-Hörz-Schilt', '72169013', 'GENODEF1ARH'],
    ['Raiffbk Aresing-Gerolsbach', '72169080', 'GENODEF1GSB'],
    ['Schrobenhausener Bank', '72169218', 'GENODEF1SBN'],
    ['Raiffbk Schrobenhausener Ld', '72169246', 'GENODEF1WFN'],
    ['Raiffbk Beilngries -alt-', '72169380', 'GENODEF1BLN'],
    ['Raiffbk Ehekirchen-Oberhsn', '72169745', 'GENODEF1WDF'],
    ['VR Bank Neuburg-Rain', '72169756', 'GENODEF1ND2'],
    ['Raiffbk Donaumooser Ld.-alt', '72169764', 'GENODEF1WRI'],
    ['Raiffbk im Donautal', '72169812', 'GENODEF1GAH'],
    ['Raiffbk Riedenburg-L. -alt-', '72169831', 'GENODEF1RBL'],
    ['Deutsche Bank', '72170007', 'DEUTDEMM721'],
    ['Deutsche Bank', '72170007', 'DEUTDEMM722'],
    ['Deutsche Bank', '72170007', 'DEUTDEMM728'],
    ['Deutsche Bank', '72170007', 'DEUTDEMM729'],
    ['Deutsche Bank', '72170024', 'DEUTDEDB721'],
    ['Deutsche Bank', '72170024', 'DEUTDEDB722'],
    ['Deutsche Bank', '72170024', 'DEUTDEDB728'],
    ['Deutsche Bank', '72170024', 'DEUTDEDB729'],
    ['Postbank Ndl Deutsche Bank', '72170324', 'DEUTDEDBP17'],
    ['Postbank Ndl Deutsche Bank', '72170363', 'DEUTDEMMP17'],
    ['Commerzbank Ingolstadt', '72180002', 'DRESDEFF721'],
    ['Hallertauer Volksbank -alt-', '72191600', 'GENODEF1PFI'],
    ['UniCredit Bank-HypoVereinbk', '72220074', 'HYVEDEMM255'],
    ['UniCredit Bank-HypoVereinbk', '72223182', 'HYVEDEMM263'],
    ['Sparkasse Nördlingen -alt-', '72250000', 'BYLADEM1NLG'],
    ['Spk Donauwörth', '72250160', 'BYLADEM1DON'],
    ['Spk Dillingen-Nördlingen', '72251520', 'BYLADEM1DLG'],
    ['Raiffeisenbank Rain -alt-', '72261754', 'GENODEF1RLH'],
    ['Raiff-VB Dillingen -alt-', '72262401', 'GENODEF1DLG'],
    ['Raiff-VB Donauwörth', '72290100', 'GENODEF1DON'],
    ['Spk Neu-Ulm Illertissen', '73050000', 'BYLADEM1NUL'],
    ['VR-Bank Neu-Ulm', '73061191', 'GENODEF1NU1'],
    ['Volksbank Neu-Ulm -alt-', '73090000', 'GENODEF1NUV'],
    ['UniCredit Bank-HypoVereinbk', '73120075', 'HYVEDEMM436'],
    ['Commerzbank Memmingen', '73140046', 'COBADEFFXXX'],
    ['Spk Schwaben-Bodensee', '73150000', 'BYLADEM1MLM'],
    ['Genobank Unterallgäu', '73160000', 'GENODEF1MIR'],
    ['Commerzbank Memmingen', '73180011', 'DRESDEFF731'],
    ['VR-Bank Memmingen', '73190000', 'GENODEF1MM1'],
    ['Illertisser Bank -alt-', '73191500', 'ULMVDE66XXX'],
    ['UniCredit Bank-HypoVereinbk', '73320073', 'HYVEDEMM428'],
    ['UniCredit Bank-HypoVereinbk', '73321177', 'HYVEDEMM567'],
    ['UniCredit Bank-HypoVereinbk', '73322380', 'HYVEDEMM570'],
    ['Saliter-Bank Obergünzburg', '73331700', 'GABLDE71XXX'],
    ['Commerzbank Kempten Allgäu', '73340046', 'COBADEFFXXX'],
    ['Sparkasse Allgäu', '73350000', 'BYLADEM1ALG'],
    ['Raiffbk im Allg Land', '73369264', 'GENODEF1DTA'],
    ['BodenseeBank', '73369821', 'GENODEF1LBB'],
    ['Raiffeisenbank Westallgäu', '73369823', 'GENODEF1WWA'],
    ['Volksbank Lindenberg', '73369826', 'GENODEF1LIA'],
    ['Raiffbk Aitrang-Ruderatshfn', '73369851', 'GENODEF1AIT'],
    ['RB Fuchstal-Denklingen-alt-', '73369854', 'GENODEF1FCH'],
    ['Raiffeisenbank Bidingen', '73369859', 'GENODEF1BIN'],
    ['Raiffbk Baisweil-Eggent-Fr', '73369871', 'GENODEF1EGB'],
    ['Raiffeisenbank Kempten-alt-', '73369902', 'GENODEF1KM1'],
    ['Raiffeisenbank Kirchweihtal', '73369918', 'GENODEF1OKI'],
    ['Raiffbk Kempten-Oberallgäu', '73369920', 'GENODEF1SFO'],
    ['Raiffbk Südliches Ostallgäu', '73369933', 'GENODEF1RHP'],
    ['Raiffeisenbank Seeg -alt-', '73369936', 'GENODEF1SER'],
    ['Raiffbk Wald-Görisried', '73369954', 'GENODEF1WGO'],
    ['Deutsche Bank', '73370008', 'DEUTDEMM733'],
    ['Deutsche Bank', '73370008', 'DEUTDEMM734'],
    ['Deutsche Bank', '73370008', 'DEUTDEMM735'],
    ['Deutsche Bank', '73370008', 'DEUTDEMM736'],
    ['Deutsche Bank', '73370008', 'DEUTDEMM737'],
    ['Deutsche Bank', '73370024', 'DEUTDEDB733'],
    ['Deutsche Bank', '73370024', 'DEUTDEDB734'],
    ['Deutsche Bank', '73370024', 'DEUTDEDB735'],
    ['Deutsche Bank', '73370024', 'DEUTDEDB736'],
    ['Deutsche Bank', '73370024', 'DEUTDEDB737'],
    ['Commerzbank Kempten', '73380004', 'DRESDEFF733'],
    ['Allgäuer Volksbank', '73390000', 'GENODEF1KEV'],
    ['Volksbank Immenstadt', '73392000', 'GENODEF1IMV'],
    ['UniCredit Bank-HypoVereinbk', '73420071', 'HYVEDEMM427'],
    ['UniCredit Bank-HypoVereinbk', '73421478', 'HYVEDEMM666'],
    ['Commerzbank KB F-K48', '73440048', 'COBADEFFXXX'],
    ['Kr u St Spk Kaufbeuren -alt', '73450000', 'BYLADEM1KFB'],
    ['Kr Spk Schongau -alt-', '73451450', 'BYLADEM1SOG'],
    ['VR Bank Augsburg-Ostallgäu', '73460046', 'GENODEF1KFB'],
    ['Commerzbank Kaufbeuren', '73480013', 'DRESDEFF734'],
    ['UniCredit Bank-HypoVereinbk', '74020074', 'HYVEDEMM445'],
    ['RLB OÖ Zndl Süddeutschland', '74020100', 'RZOODE77XXX'],
    ['RLB OÖ Zndl Süddeutschland', '74020150', 'RZOODE77050'],
    ['Commerzbank Passau', '74040082', 'COBADEFFXXX'],
    ['Spk Passau', '74050000', 'BYLADEM1PAS'],
    ['Spk Freyung-Grafenau', '74051230', 'BYLADEM1FRG'],
    ['Raiffbk Gold Steig Dreisess', '74061101', 'GENODEF1RGS'],
    ['Raiffbk Unteres Inntal', '74061564', 'GENODEF1NUI'],
    ['Raiffbk Ortenburg-Kirchberg', '74061670', 'GENODEF1ORT'],
    ['VR-Bank Rottal-Inn', '74061813', 'GENODEF1PFK'],
    ['VR Rottal-Inn Gf GAA', '74061814', 'GENODEF1PFK'],
    ['VR Vilshofen-Pocking', '74062490', 'GENODEF1VIR'],
    ['Raiffbk i Lkr Passau-Nord', '74062786', 'GENODEF1TIE'],
    ['Raiffeisenbank Wegscheid', '74064593', 'GENODEF1WSD'],
    ['Raiffbk Salzweg-Thyrnau-alt', '74065782', 'GENODEF1SZT'],
    ['RB Südl Bayer Wald -alt-', '74066749', 'GENODEF1HZN'],
    ['Rottaler Raiffbk -alt-', '74067000', 'GENODEF1POC'],
    ['Raiffeisenbank Grainet', '74069744', 'GENODEF1GRT'],
    ['Raiffbk Hohenau-Mauth -alt-', '74069752', 'GENODEF1HHU'],
    ['Raiffbk Kirchberg', '74069758', 'GENODEF1TKI'],
    ['Raiffbk am Dreisessel -alt-', '74069768', 'GENODEF1NHD'],
    ['VR-Bank Passau', '74090000', 'GENODEF1PA1'],
    ['UniCredit Bank-HypoVereinbk', '74120071', 'HYVEDEMM415'],
    ['TEBA Kreditbank', '74131000', 'TEKRDE71XXX'],
    ['Commerzbank DE F-D48', '74140048', 'COBADEFFXXX'],
    ['Spk Deggendorf', '74150000', 'BYLADEM1DEG'],
    ['Sparkasse Regen-Viechtach', '74151450', 'BYLADEM1REG'],
    ['Raiba Deg.-Pla.-Sonnenw.', '74160025', 'GENODEF1DEG'],
    ['Raiffbk Hengersb-Schöllnach', '74161608', 'GENODEF1HBW'],
    ['VR-Bank -alt-', '74164149', 'GENODEF1RGE'],
    ['Raiffbk Sonnenwald -alt-', '74165013', 'GENODEF1AUS'],
    ['Commerzbank Deggendorf', '74180009', 'DRESDEFF741'],
    ['VR GenoBank DonauWald', '74190000', 'GENODEF1DGV'],
    ['VR-Bank Landau-Mengkofen', '74191000', 'GENODEF1LND'],
    ['UniCredit Bank-HypoVereinbk', '74220075', 'HYVEDEMM452'],
    ['UniCredit Bank-HypoVereinbk', '74221170', 'HYVEDEMM675'],
    ['Commerzbank Straubing', '74240062', 'COBADEFFXXX'],
    ['Spk Niederbayern-Mitte', '74250000', 'BYLADEM1SRG'],
    ['Spk im Landkreis Cham', '74251020', 'BYLADEM1CHM'],
    ['Raiffeisenbank Straubing', '74260110', 'GENODEF1SR2'],
    ['Raiffeisenbank Chamer Land', '74261024', 'GENODEF1CHA'],
    ['VR-Bank Ostbayern-Mitte', '74290000', 'GENODEF1SR1'],
    ['CB Bank Straubing', '74290100', 'CBSRDE71XXX'],
    ['UniCredit Bank-HypoVereinbk', '74320073', 'HYVEDEMM433'],
    ['Commerzbank Landshut', '74340077', 'COBADEFFXXX'],
    ['Spk Landshut', '74350000', 'BYLADEM1LAH'],
    ['Spk Rottal-Inn Eggenfelden', '74351430', 'BYLADEM1EGF'],
    ['St u Kr Spk Moosburg -alt-', '74351740', 'BYLADEM1MSB'],
    ['Raiffeisenbank Arnstorf', '74361211', 'GENODEF1ARF'],
    ['Raiffbk Landshuter Land', '74362663', 'GENODEF1ERG'],
    ['Raiffbk Pfeffenhausen -alt-', '74364689', 'GENODEF1PFF'],
    ['Raiffbk Geisenh. -alt-', '74366666', 'GENODEF1GSH'],
    ['Raiffbk Hofkirchen-Bayerbch', '74369068', 'GENODEF1LWE'],
    ['Raiffbk Geiselhöring-Pfabg', '74369088', 'GENODEF1GPF'],
    ['Raiffeisenbank Parkstetten', '74369130', 'GENODEF1PST'],
    ['Raiffbk Rattiszell-Konzell', '74369146', 'GENODEF1RZK'],
    ['Raiffbk Essenbach  -alt-', '74369656', 'GENODEF1ENA'],
    ['Raiffbk Buch-Eching', '74369662', 'GENODEF1EBV'],
    ['Raiffbk Mengk.-Loiching-alt', '74369704', 'GENODEF1MKO'],
    ['Commerzbank Landshut', '74380007', 'DRESDEFF743'],
    ['VR-Bank Landshut', '74390000', 'GENODEF1LH1'],
    ['VB-Raiffbk Dingolfing -alt-', '74391300', 'GENODEF1DGF'],
    ['VR-Bank Rottal-Inn', '74391400', 'GENODEF1EGR'],
    ['VR-Bk Isar-Vils Vilsbiburg', '74392300', 'GENODEF1VBV'],
    ['BBk Regensburg', '75000000', 'MARKDEF1750'],
    ['UniCredit Bank-HypoVereinbk', '75020073', 'HYVEDEMM447'],
    ['UniCredit Bank-HypoVereinbk', '75021174', 'HYVEDEMM804'],
    ['Commerzbank Regensburg', '75040062', 'COBADEFFXXX'],
    ['Spk Regensburg', '75050000', 'BYLADEM1RBG'],
    ['Spk im Landkreis Schwandorf', '75051040', 'BYLADEM1SAD'],
    ['Kreissparkasse Kelheim', '75051565', 'BYLADEM1KEH'],
    ['Raiffbk Regensburg-Wenzenb', '75060150', 'GENODEF1R02'],
    ['RB Schwandorf-Nittenau-alt-', '75061168', 'GENODEF1SWN'],
    ['Raiffeisenbank Regenstauf', '75061851', 'GENODEF1REF'],
    ['Raiffbk Oberpfalz Süd', '75062026', 'GENODEF1DST'],
    ['Raiffbk Kreis Kelheim', '75069014', 'GENODEF1ABS'],
    ['Raiffbk Bad Gögging -alt-', '75069015', 'GENODEF1NGG'],
    ['Raiffeisenbank Bruck -alt-', '75069020', 'GENODEF1BUK'],
    ['Raiffbk Falkenstein-Wörth', '75069038', 'GENODEF1FKS'],
    ['Raiffbk Grafenwöhr-K. -alt-', '75069050', 'GENODEF1GRW'],
    ['Raiffbk Alteglofshm-Hagelst', '75069055', 'GENODEF1HGA'],
    ['Raiffbk im Oberpf. Jura', '75069061', 'GENODEF1HEM'],
    ['Raiffeisenbank Sinzing', '75069078', 'GENODEF1SZV'],
    ['Raiffeisenbank Bad Kötzting', '75069081', 'GENODEF1KTZ'],
    ['Raiffbk Parsberg-Velb.-alt-', '75069094', 'GENODEF1PAR'],
    ['RB Eschlk-Lam-Lohb-Neukirch', '75069110', 'GENODEF1NKN'],
    ['VR Bank Mittlere Oberpfalz', '75069171', 'GENODEF1SWD'],
    ['Deutsche Bank', '75070013', 'DEUTDEMM741'],
    ['Deutsche Bank', '75070013', 'DEUTDEMM750'],
    ['Deutsche Bank', '75070013', 'DEUTDEMM751'],
    ['Deutsche Bank', '75070013', 'DEUTDEMM752'],
    ['Deutsche Bank', '75070024', 'DEUTDEDB741'],
    ['Deutsche Bank', '75070024', 'DEUTDEDB750'],
    ['Deutsche Bank', '75070024', 'DEUTDEDB751'],
    ['Deutsche Bank', '75070024', 'DEUTDEDB752'],
    ['Commerzbank Regensburg', '75080003', 'DRESDEFF750'],
    ['VB RB Regensburg-Schwandorf', '75090000', 'GENODEF1R01'],
    ['LIGA Bank Regensburg', '75090300', 'GENODEF1M05'],
    ['Sparda-Bank Ostbayern', '75090500', 'GENODEF1S05'],
    ['VR Bank Niederbayern-Oberpf', '75090900', 'GENODEF1P18'],
    ['VR Bank Burglengenfeld-alt-', '75091400', 'GENODEF1BLF'],
    ['UniCredit Bank-HypoVereinbk', '75220070', 'HYVEDEMM405'],
    ['Commerzbank Amberg Oberpf', '75240000', 'COBADEFFXXX'],
    ['Sparkasse Amberg-Sulzbach', '75250000', 'BYLADEM1ABG'],
    ['Raiffbk Sulzbach-Rose.-alt-', '75261700', 'GENODEF1SZH'],
    ['VR Bank Amberg-Sulzbach', '75290000', 'GENODEF1AMV'],
    ['UniCredit Bank-HypoVereinbk', '75320075', 'HYVEDEMM454'],
    ['Commerzbank Weiden Oberpf', '75340090', 'COBADEFFXXX'],
    ['Spk Oberpfalz Nord', '75350000', 'BYLADEM1WEN'],
    ['Ver Spk Eschenbach', '75351960', 'BYLADEM1ESB'],
    ['Raiffeisenbank Weiden -alt-', '75360011', 'GENODEF1WEO'],
    ['Raiffeisenbank Floß', '75362039', 'GENODEF1FLS'],
    ['RB Neustadt-Vohenstr -alt-', '75363189', 'GENODEF1NEW'],
    ['VR Nordoberpfalz', '75390000', 'GENODEF1WEV'],
    ['BBk Nürnberg', '76000000', 'MARKDEF1760'],
    ['Postbank Ndl Deutsche Bank', '76010085', 'PBNKDEFFXXX'],
    ['UniCredit Bank-HypoVereinbk', '76020070', 'HYVEDEMM460'],
    ['UniCredit Bank-HypoVereinbk', '76020099', 'HYVEDEMMCAR'],
    ['norisbank Berlin', '76026000', 'NORSDE71XXX'],
    ['BNP Paribas Deutschland', '76030080', 'CSDBDE71XXX'],
    ['Isbank Nürnberg', '76030600', 'ISBKDEFXNUR'],
    ['flatex Bank, Frankfurt', '76030800', 'BIWBDE33760'],
    ['TeamBank Nürnberg', '76032000', 'TEAMDE71XXX'],
    ['TeamBank Nürnberg GF -AT-', '76032001', 'TEAMDE71TAT'],
    ['UmweltBank Nürnberg', '76035000', 'UMWEDE7NXXX'],
    ['Commerzbank CC Nürnberg', '76040060', 'COBADEFFXXX'],
    ['Commerzbank Nürnberg', '76040061', 'COBADEFFXXX'],
    ['Commerzbank CC Nürnberg', '76040062', 'COBADEFFXXX'],
    ['Commerzbank Fil. Nürnberg 2', '76040065', 'COBADEFFXXX'],
    ['BayernLB Nürnberg', '76050000', 'BYLADEMMXXX'],
    ['Sparkasse Nürnberg', '76050101', 'SSKNDE77XXX'],
    ['Spk Neumarkt i d OPf-Parsbg', '76052080', 'BYLADEM1NMA'],
    ['DZ BANK', '76060000', 'GENODEFF760'],
    ['VR Bank Nürnberg -alt-', '76060618', 'GENODEF1N02'],
    ['VR Bank Nbg (Gf GAA)', '76060620', 'GENODEF1N02'],
    ['Raiffbk Spar+Kreditbk Lauf', '76061025', 'GENODEF1LAU'],
    ['Raiffbk im Nürnberger Land', '76061482', 'GENODEF1HSB'],
    ['Raiffbk Auerbach-Freihung', '76069369', 'GENODEF1AUO'],
    ['Raiffbk Bad Windsheim', '76069372', 'GENODEF1WDS'],
    ['Raiffeisenbank Bechhofen', '76069378', 'GENODEF1BEH'],
    ['Raiffbk Uehlfeld-Dachsbach', '76069404', 'GENODEF1DSB'],
    ['Raiffbk Dietenhofen -alt-', '76069409', 'GENODEF1DIH'],
    ['Raiffeisenbank Dietersheim', '76069410', 'GENODEF1DIM'],
    ['Raiffbk Altdorf-Feucht-alt-', '76069440', 'GENODEF1FEC'],
    ['VR-Bank Feuchtw.-Limes-alt-', '76069441', 'GENODEF1FEW'],
    ['Raiffbk Freudenberg -alt-', '76069448', 'GENODEF1FRD'],
    ['Raiffeisen - meine Bank', '76069449', 'GENODEF1FYS'],
    ['Raiffeisenbank Altmühl-Jura', '76069462', 'GENODEF1GDG'],
    ['Raiffbk Weißenburg-Gu.-alt-', '76069468', 'GENODEF1GU1'],
    ['Raiffbk Hirschau', '76069486', 'GENODEF1HSC'],
    ['Raiffbk Knoblauchsland', '76069512', 'GENODEF1N08'],
    ['Raiffeisenbank Neumarkt', '76069553', 'GENODEF1NM1'],
    ['VR Bank Metropolregion Nbg', '76069559', 'GENODEF1NEA'],
    ['Raiffbk Oberferrieden-Burgt', '76069564', 'GENODEF1BTO'],
    ['Raiffeisenbank Plankstetten', '76069576', 'GENODEF1BPL'],
    ['Raiffbk DreiFranken', '76069602', 'GENODEF1HSE'],
    ['Raiffbk Unteres Vilstal', '76069611', 'GENODEF1SDM'],
    ['RB Ursens-Ammerth-Hohen alt', '76069635', 'GENODEF1URS'],
    ['Raiffbk Heilsbr-Winds.-alt-', '76069663', 'GENODEF1WBA'],
    ['Raiffeisenbank Bibertgrund', '76069669', 'GENODEF1ZIR'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM760'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM761'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM762'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM763'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM764'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM765'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM766'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM767'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM768'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM769'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM771'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM772'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM773'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM774'],
    ['Deutsche Bank', '76070012', 'DEUTDEMM783'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB760'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB761'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB762'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB763'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB764'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB765'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB766'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB767'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB768'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB769'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB771'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB772'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB773'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB774'],
    ['Deutsche Bank', '76070024', 'DEUTDEDB783'],
    ['Postbank Ndl Deutsche Bank', '76070324', 'DEUTDEDBP15'],
    ['Postbank Ndl Deutsche Bank', '76070361', 'DEUTDEMMP15'],
    ['Commerzbank Nürnberg', '76080040', 'DRESDEFF760'],
    ['Commerzbank Zw 53', '76080053', 'DRESDEFFAGI'],
    ['Commerzbank Zw 55', '76080055', 'DRESDEFFI25'],
    ['Commerzbank ITGK1 Nürnbrg', '76080085', 'DRESDEFFJ26'],
    ['Commerzbank ITGK2 Nürnbrg', '76080086', 'DRESDEFFJ27'],
    ['Commerzbank ITGK Nürnberg', '76089480', 'DRESDEFFI24'],
    ['Commerzbank ITGK Nürnb. 2', '76089482', 'DRESDEFFI46'],
    ['Evenord-Bank Nürnberg', '76090400', 'GENODEF1N03'],
    ['Sparda-Bank Nürnberg', '76090500', 'GENODEF1S06'],
    ['PSD Bank Nürnberg', '76090900', 'GENODEF1P17'],
    ['CVW-Privatbank Wdf.', '76211900', 'GENODEF1WHD'],
    ['UniCredit Bank-HypoVereinbk', '76220073', 'HYVEDEMM419'],
    ['BSQ Bauspar Nürnberg', '76230000', 'QUBADE71XXX'],
    ['Commerzbank Fürth Bayern', '76240011', 'COBADEFFXXX'],
    ['Spk Fürth', '76250000', 'BYLADEM1SFU'],
    ['Sparkasse i. Lkr. NEA-BWI', '76251020', 'BYLADEM1NEA'],
    ['Raiffbk-Volksbank Fürth-alt', '76260451', 'GENODEF1FUE'],
    ['UniCredit Bank-HypoVereinbk', '76320072', 'HYVEDEMM417'],
    ['Commerzbank Erlangen', '76340061', 'COBADEFFXXX'],
    ['St u Kr Spk Erlangen', '76350000', 'BYLADEM1ERH'],
    ['Sparkasse Forchheim', '76351040', 'BYLADEM1FOR'],
    ['Kr Spk Höchstadt', '76351560', 'BYLADEM1HOS'],
    ['VR-Bank EHH -alt-', '76360033', 'GENODEF1ER1'],
    ['VR Bank Bamberg-Forchheim', '76391000', 'GENODEF1FOH'],
    ['UniCredit Bank-HypoVereinbk', '76420080', 'HYVEDEMM065'],
    ['Spk Mittelfranken-Süd', '76450000', 'BYLADEM1SRS'],
    ['Raiffbk Roth-Schwabach-alt-', '76460015', 'GENODEF1SWR'],
    ['Raiffbk am Rothsee -alt-', '76461485', 'GENODEF1HPN'],
    ['UniCredit Bank-HypoVereinbk', '76520071', 'HYVEDEMM406'],
    ['Sparkasse Ansbach', '76550000', 'BYLADEM1ANS'],
    ['Kr u St Spk Dinkelsbühl-alt', '76551020', 'BYLADEM1DKB'],
    ['Ver Spk Gunzenhausen', '76551540', 'BYLADEM1GUN'],
    ['St u Kr Spk Rothenburg -alt', '76551860', 'BYLADEM1ROT'],
    ['VR-Bank Mittelfranken Mitte', '76560060', 'GENODEF1ANS'],
    ['VR Bank im südl. Franken', '76591000', 'GENODEF1DKV'],
    ['UniCredit Bank-HypoVereinbk', '77020070', 'HYVEDEMM411'],
    ['Commerzbank Bamberg', '77040080', 'COBADEFFXXX'],
    ['Spk Bamberg', '77050000', 'BYLADEM1SKB'],
    ['VR Bank Bamberg -alt-', '77060100', 'GENODEF1BA2'],
    ['Raiffbk Obermain Nord', '77061004', 'GENODEF1ALK'],
    ['Raiff-Volksbk Ebern -alt-', '77061425', 'GENODEF1EBR'],
    ['RB Burgebrach-Stegaurach', '77062014', 'GENODEF1BGB'],
    ['Raiff-VB Bad Staffelstein', '77062139', 'GENODEF1SFF'],
    ['Raiffbk Küps-Mitwitz-Stockh', '77069044', 'GENODEF1KC2'],
    ['Raiffbk Heiligenstadt -alt-', '77069051', 'GENODEF1HIS'],
    ['Raiffbk Heroldsbach -alt-', '77069052', 'GENODEF1HOB'],
    ['Raiffbk Ebrachgrund -alt-', '77069091', 'GENODEF1SFD'],
    ['Vereinigte Raiffbk -alt-', '77069461', 'GENODEF1GBF'],
    ['Raiffbk Thurnauer Land', '77069739', 'GENODEF1THA'],
    ['Raiffbk Emtmannsberg -alt-', '77069746', 'GENODEF1ETB'],
    ['Raiffbk Oberpfalz NordWest', '77069764', 'GENODEF1KEM'],
    ['Raiffeisenbank am Kulm', '77069782', 'GENODEF1SPK'],
    ['Raiffbk Berg-Bad Steben-alt', '77069836', 'GENODEF1BGO'],
    ['Raiffeisenbank Oberland', '77069868', 'GENODEF1MGA'],
    ['Raiffbk Hochfranken West', '77069870', 'GENODEF1SZF'],
    ['Raiffbk Wüstenselbitz', '77069906', 'GENODEF1WSZ'],
    ['Raiffbk Sparn-Stammb-Zell', '77069908', 'GENODEF1ZSP'],
    ['VR-Bank Lichtenfels-Ebern', '77091800', 'GENODEF1LIF'],
    ['UniCredit Bank-HypoVereinbk', '77120073', 'HYVEDEMM289'],
    ['Commerzbank Kulmbach', '77140061', 'COBADEFFXXX'],
    ['Spk Kulmbach-Kronach', '77150000', 'BYLADEM1KUB'],
    ['VR Bank Oberfranken Mitte', '77190000', 'GENODEF1KU1'],
    ['BBk München eh Bayreuth', '77300000', 'MARKDEF1773'],
    ['UniCredit Bank-HypoVereinbk', '77320072', 'HYVEDEMM412'],
    ['Fondsdepot Bank', '77322200', 'FODBDE77XXX'],
    ['Commerzbank Bayreuth', '77340076', 'COBADEFFXXX'],
    ['Sparkasse Bayreuth', '77350110', 'BYLADEM1SBT'],
    ['Raiffeisenbank Gefrees-alt-', '77363749', 'GENODEF1GFS'],
    ['RB Fränkische Schweiz', '77365792', 'GENODEF1HWA'],
    ['UniCredit Bank-HypoVereinbk', '78020070', 'HYVEDEMM424'],
    ['Commerzbank Hof Saale', '78040081', 'COBADEFFXXX'],
    ['Spk Hochfranken', '78050000', 'BYLADEM1HOF'],
    ['Spk Hochfranken -alt-', '78055050', 'BYLADEM1FIG'],
    ['VR Bank Bayreuth-Hof', '78060896', 'GENODEF1HO1'],
    ['Commerzbank Tirschenreuth', '78140000', 'COBADEFFXXX'],
    ['VR-Bk Fichtelgeb.-Frankenw.', '78160069', 'GENODEF1MAK'],
    ['Raiffbk im Stiftland -alt-', '78161575', 'GENODEF1WSS'],
    ['UniCredit Bank-HypoVereinbk', '78320076', 'HYVEDEMM480'],
    ['Commerzbank Coburg', '78340091', 'COBADEFFXXX'],
    ['Spk Coburg-Lichtenfels', '78350000', 'BYLADEM1COB'],
    ['VR-Bank Coburg', '78360000', 'GENODEF1COS'],
    ['BBk Würzburg', '79000000', 'MARKDEF1790'],
    ['UniCredit Bank-HypoVereinbk', '79020076', 'HYVEDEMM455'],
    ['Fürstl. Castellsche Bank', '79030001', 'FUCEDE77XXX'],
    ['MERKUR PRIVATBANK', '79032038', 'BSHADE71XXX'],
    ['Commerzbank Würzburg', '79040047', 'COBADEFFXXX'],
    ['Spk Mainfranken Würzburg', '79050000', 'BYLADEM1SWU'],
    ['Raiffbk Estenfeld-B. -alt-', '79063060', 'GENODEF1EFD'],
    ['Raiffeisenbank Höchberg', '79063122', 'GENODEF1HBG'],
    ['VR-Bank Bad Kissingen', '79065028', 'GENODEF1BRK'],
    ['RB Mainschleife-Steigerwald', '79069001', 'GENODEF1WED'],
    ['VR-Bank Schweinfurt -alt-', '79069010', 'GENODEF1ATE'],
    ['Raiffbk Bütthard-Gaukönigsh', '79069031', 'GENODEF1BHD'],
    ['Raiffbk Main-Spessart', '79069150', 'GENODEF1GEM'],
    ['VR-Bank Main-Rhön', '79069165', 'GENODEF1MLV'],
    ['Raiffeisenbank Nüdlingen', '79069181', 'GENODEF1NDL'],
    ['Raiffeisenbank im Grabfeld', '79069188', 'GENODEF1SLZ'],
    ['Raiffeisenbank Maßbach', '79069213', 'GENODEF1RNM'],
    ['Deutsche Bank', '79070016', 'DEUTDEMM790'],
    ['Deutsche Bank', '79070016', 'DEUTDEMM791'],
    ['Deutsche Bank', '79070016', 'DEUTDEMM792'],
    ['Deutsche Bank', '79070024', 'DEUTDEDB790'],
    ['Deutsche Bank', '79070024', 'DEUTDEDB791'],
    ['Deutsche Bank', '79070024', 'DEUTDEDB792'],
    ['Commerzbank Würzburg', '79080052', 'DRESDEFF790'],
    ['Commerzbank ITGK1 Würzbrg', '79080085', 'DRESDEFFJ28'],
    ['VR-Bank Würzburg', '79090000', 'GENODEF1WU1'],
    ['Raiffbk Fränkis.Weinla-alt-', '79161058', 'GENODEF1ERN'],
    ['Raiffbk Kitzinger Land-alt-', '79161499', 'GENODEF1OBR'],
    ['VR Bank Kitzingen', '79190000', 'GENODEF1KT1'],
    ['UniCredit Bank-HypoVereinbk', '79320075', 'HYVEDEMM451'],
    ['FLESSABANK Schweinfurt', '79330111', 'FLESDEMMXXX'],
    ['Commerzbank Schweinfurt', '79340054', 'COBADEFFXXX'],
    ['Städt.Spk Schweinfurt -alt-', '79350000', 'BYLADEM1SSW'],
    ['Spk Schweinfurt-Haßberge', '79350101', 'BYLADEM1KSW'],
    ['Spk Bad Kissingen', '79351010', 'BYLADEM1KIS'],
    ['Spk Ostunterfranken -alt-', '79351730', 'BYLADEM1HAS'],
    ['Spk Bad Neustadt a d Saale', '79353090', 'BYLADEM1NES'],
    ['VR-MainBank', '79362081', 'GENODEF1GZH'],
    ['VR-Bank Rhön-Grabfeld -alt-', '79363016', 'GENODEF1NDT'],
    ['Raiff-VB Haßberge', '79363151', 'GENODEF1HAS'],
    ['Raiffbk Frankenwinheim-alt-', '79364069', 'GENODEF1FWH'],
    ['Commerzbank Schweinfurt', '79380051', 'DRESDEFF793'],
    ['UniCredit Bank-HypoVereinbk', '79520070', 'HYVEDEMM407'],
    ['Commerzbank Aschaffenburg', '79540049', 'COBADEFFXXX'],
    ['Spk Aschaffenburg Alzenau', '79550000', 'BYLADEM1ASA'],
    ['Raiffbk Bachgau -alt-', '79561348', 'GENODEF1BAG'],
    ['RV-Bank Aschaffenburg', '79562514', 'GENODEF1AB1'],
    ['Raiffeisenbank Waldaschaff', '79565568', 'GENODEF1WAA'],
    ['VR-Bank -alt-', '79567531', 'GENODEF1ALZ'],
    ['Raiffbk Haibach-Obernau', '79568518', 'GENODEF1HAC'],
    ['Deutsche Bank', '79570024', 'DEUTDEDB795'],
    ['Deutsche Bank', '79570024', 'DEUTDEDB796'],
    ['Deutsche Bank', '79570051', 'DEUTDEFF795'],
    ['Deutsche Bank', '79570051', 'DEUTDEFF796'],
    ['Postbank Ndl Deutsche Bank', '79570324', 'DEUTDEDBP18'],
    ['Postbank Ndl Deutsche Bank', '79570364', 'DEUTDEFFS18'],
    ['Commerzbank Aschaffenburg', '79580099', 'DRESDEFF795'],
    ['Commerzbank ITGK Aschaff.', '79589402', 'DRESDEFFI47'],
    ['VB Aschaffenburg -alt-', '79590000', 'GENODEF1AB2'],
    ['Spk Miltenberg-Obernburg', '79650000', 'BYLADEM1MIL'],
    ['Raiffbk Elsavatal', '79665540', 'GENODEF1EAU'],
    ['Raiffbk Großosth.-Ob. -alt-', '79666548', 'GENODEF1OBE'],
    ['Raiffbk Eichenbühl u U', '79668509', 'GENODEF1ENB'],
    ['RV-Bank Miltenberg -alt-', '79690000', 'GENODEF1MIL'],
    ['UniCredit Bank-HypoVereinbk', '80020086', 'HYVEDEMM440'],
    ['UniCredit Bank-HypoVereinbk', '80020087', 'HYVEDEMM462'],
    ['Commerzbank Halle', '80040000', 'COBADEFFXXX'],
    ['Kr Spk Mersebg-Querfurt alt', '80050500', 'NOLADE21MQU'],
    ['Spk Burgenlandkreis', '80053000', 'NOLADE21BLK'],
    ['St Spk Dessau', '80053572', 'NOLADE21DES'],
    ['Kr Spk Köthen -alt-', '80053622', 'NOLADE21KOT'],
    ['Kr Spk Anhalt-Bitterfeld', '80053722', 'NOLADE21BTF'],
    ['Saalesparkasse Halle', '80053762', 'NOLADE21HAL'],
    ['Kr Spk Weißenfels', '80054000', 'NOLADE21WSF'],
    ['Sparkasse Mansfeld-Südharz', '80055008', 'NOLADE21EIL'],
    ['Salzlandsparkasse Staßfurt', '80055500', 'NOLADE21SES'],
    ['Volksbank Elsterland -alt-', '80062608', 'GENODEF1JE1'],
    ['Harzer Volksbank', '80063508', 'GENODEF1QLB'],
    ['Volksbank Sangerhausen', '80063558', 'GENODEF1SGH'],
    ['Volksbank Wittenberg', '80063598', 'GENODEF1WB1'],
    ['Volksbank Köthen', '80063628', 'GENODEF1KOE'],
    ['Volks-Raiffbk Saale-Unstrut', '80063648', 'GENODEF1NMB'],
    ['VR-Bank Zeitz -alt-', '80063678', 'GENODEF1ZTZ'],
    ['V- u Raiffbk Eisleben -alt-', '80063718', 'GENODEF1EIL'],
    ['Commerzbank Halle', '80080000', 'DRESDEFF800'],
    ['Volksbank Dessau-Anhalt', '80093574', 'GENODEF1DS1'],
    ['VB Halle, Saale', '80093784', 'GENODEF1HAL'],
    ['Sparkasse Wittenberg', '80550101', 'NOLADE21WBL'],
    ['Kr Spk Anhalt-Zerbst -alt-', '80550200', 'NOLADE21ZER'],
    ['BBk Magdeburg', '81000000', 'MARKDEF1810'],
    ['Investitionsbk Sachsen-Anh.', '81010400', 'NOLADE21LSA'],
    ['Bank für Sozialwirtschaft', '81020500', 'BFSWDE33MAG'],
    ['Commerzbank Magdeburg', '81040000', 'COBADEFFXXX'],
    ['Kreissparkasse Stendal', '81050555', 'NOLADE21SDL'],
    ['Harzsparkasse', '81052000', 'NOLADE21HRZ'],
    ['Spk MDB', '81053272', 'NOLADE21MDG'],
    ['Spk Jerichower Land -alt-', '81054000', 'NOLADE21JEL'],
    ['Kreissparkasse Börde', '81055000', 'NOLADE21HDL'],
    ['Spk Altmark West Salzwedel', '81055555', 'NOLADE21SAW'],
    ['Raiffbk Kalbe-Bismark', '81063028', 'GENODEF1KAB'],
    ['VB Jerichower Land', '81063238', 'GENODEF1BRG'],
    ['KD-Bank Magdeburg', '81068106', 'GENODED1KDM'],
    ['Volksbank Börde-Bernburg', '81069052', 'GENODEF1WZL'],
    ['Deutsche Bank', '81070000', 'DEUTDE8MXXX'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M811'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M812'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M814'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M815'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M816'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M817'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M818'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M819'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M820'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M821'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M822'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M823'],
    ['Deutsche Bank', '81070000', 'DEUTDE8M825'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB801'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB802'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB803'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB804'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB806'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB811'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB812'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB814'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB815'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB816'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB817'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB818'],
    ['Deutsche Bank', '81070024', 'DEUTDEDB819'],
    ['Deutsche Bank', '81070024', 'DEUTDEDBMAG'],
    ['Commerzbank Magdeburg', '81080000', 'DRESDEFF810'],
    ['Volksbank Gardelegen', '81093034', 'GENODEF1GA1'],
    ['Volksbank Stendal', '81093054', 'GENODEF1SDL'],
    ['VB Magdeburg', '81093274', 'GENODEF1MD1'],
    ['BBk Erfurt', '82000000', 'MARKDEF1820'],
    ['UniCredit Bank-HypoVereinbk', '82020086', 'HYVEDEMM498'],
    ['UniCredit Bank-HypoVereinbk', '82020087', 'HYVEDEMM098'],
    ['UniCredit Bank-HypoVereinbk', '82020088', 'HYVEDEMM824'],
    ['Commerzbank Erfurt', '82040000', 'COBADEFFXXX'],
    ['Commerzbank Gf WK, Erfurt', '82040085', 'COBADEFFXXX'],
    ['Ld Bk Hess-Thür Gz Erfurt', '82050000', 'HELADEFF820'],
    ['Spk Mittelthüringen', '82051000', 'HELADEF1WEM'],
    ['Kr Spk Gotha', '82052020', 'HELADEF1GTH'],
    ['Kr Spk Nordhausen', '82054052', 'HELADEF1NOR'],
    ['Kyffhäuser Spk Sondershsn', '82055000', 'HELADEF1KYF'],
    ['Sparkasse Unstrut-Hainich', '82056060', 'HELADEF1MUE'],
    ['Kr Spk Eichsfeld', '82057070', 'HELADEF1EIC'],
    ['Pax-Bank Erfurt', '82060197', 'GENODED1PA5'],
    ['VR Bank Westthüringen', '82064038', 'GENODEF1MU2'],
    ['VR Bank Ihre Heimatbank', '82064088', 'GENODEF1ESA'],
    ['Raiffeisenbank Gotha -alt-', '82064168', 'GENODEF1GTH'],
    ['VR Bank Weimar', '82064188', 'GENODEF1WE1'],
    ['Erfurter Bank -alt-', '82064228', 'ERFBDE8EXXX'],
    ['Deutsche Bank', '82070000', 'DEUTDE8EXXX'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E820'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E822'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E823'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E824'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E825'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E826'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E827'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E828'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E829'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E830'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E831'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E832'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E833'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E835'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E836'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E840'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E841'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E842'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E843'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E844'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E845'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E846'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E847'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E848'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E849'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E850'],
    ['Deutsche Bank', '82070000', 'DEUTDE8E851'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB820'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB821'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB822'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB823'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB824'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB825'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB827'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB828'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB829'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB830'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB831'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB832'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB833'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB835'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB836'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB840'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB841'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB842'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB843'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB844'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB845'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB846'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB847'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB848'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB849'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB850'],
    ['Deutsche Bank', '82070024', 'DEUTDEDB851'],
    ['Deutsche Bank', '82070024', 'DEUTDEDBERF'],
    ['Postbank Ndl Deutsche Bank', '82070324', 'DEUTDEDBP20'],
    ['Postbank Ndl Deutsche Bank', '82070366', 'DEUTDE8EP20'],
    ['Commerzbank Weimar', '82080000', 'DRESDEFF827'],
    ['VB Heiligenstadt -alt-', '82094004', 'GENODEF1HIG'],
    ['Nordthüringer Volksbank', '82094054', 'GENODEF1NDS'],
    ['UniCredit Bank-HypoVereinbk', '83020086', 'HYVEDEMM468'],
    ['UniCredit Bank-HypoVereinbk', '83020087', 'HYVEDEMM463'],
    ['UniCredit Bank-HypoVereinbk', '83020088', 'HYVEDEMM484'],
    ['Commerzbank Gera', '83040000', 'COBADEFFXXX'],
    ['Spk Gera-Greiz', '83050000', 'HELADEF1GER'],
    ['Spk Altenburger Land', '83050200', 'HELADEF1ALT'],
    ['Kr Spk Saalfeld-Rudolstadt', '83050303', 'HELADEF1SAR'],
    ['Kr Spk Saale-Orla', '83050505', 'HELADEF1SOK'],
    ['Spk Jena-Saale-Holzland', '83053030', 'HELADEF1JEN'],
    ['Raiffbk-VB Hermsdorf', '83064488', 'GENODEF1HMF'],
    ['Geraer Bank -alt-', '83064568', 'GENODEF1GEV'],
    ['VR-Bank ABG-Land / Skatbank', '83065408', 'GENODEF1SLR'],
    ['Commerzbank Gera', '83080000', 'DRESDEFF830'],
    ['Raiff-VB Saale-Orla -alt-', '83094444', 'GENODEF1PN1'],
    ['VB Gera-Jena-Rudolstadt', '83094454', 'GENODEF1RUJ'],
    ['Volksbank Eisenberg', '83094494', 'GENODEF1ESN'],
    ['EthikBank Zndl Vb Eisenberg', '83094495', 'GENODEF1ETK'],
    ['UniCredit Bank-HypoVereinbk', '84020087', 'HYVEDEMM458'],
    ['Commerzbank Meiningen', '84040000', 'COBADEFFXXX'],
    ['Rhön-Rennsteig-Sparkasse', '84050000', 'HELADEF1RRS'],
    ['Sparkasse Arnstadt-Ilmenau', '84051010', 'HELADEF1ILK'],
    ['Kr Spk Hildburghausen', '84054040', 'HELADEF1HIL'],
    ['Sparkasse Sonneberg', '84054722', 'HELADEF1SON'],
    ['Wartburg-Sparkasse', '84055050', 'HELADEF1WAK'],
    ['Genobank Rhön-Grabfeld', '84064798', 'GENODEF1MLF'],
    ['Raiffbk Schleusingen -alt-', '84069065', 'GENODEF1SSG'],
    ['Commerzbank Meiningen', '84080000', 'DRESDEFF843'],
    ['VR-Bank Bad Salzungen', '84094754', 'GENODEF1SAL'],
    ['VR-Bank Bad Salzungen GAA', '84094755', 'GENODEF1SAL'],
    ['VB Thür. Mitte Erfurt', '84094814', 'GENODEF1SHL'],
    ['BBk Leipzig eh Dresden', '85000000', 'MARKDEF1850'],
    ['Sächsische Aufbaubank', '85010500', 'SABDDE81XXX'],
    ['UniCredit Bank-HypoVereinbk', '85020086', 'HYVEDEMM496'],
    ['Bank für Sozialwirtschaft', '85020500', 'BFSWDE33DRE'],
    ['Commerzbank Dresden', '85040000', 'COBADEFFXXX'],
    ['Commerzbank CC Dresden', '85040060', 'COBADEFFXXX'],
    ['Commerzbank CC Dresden', '85040061', 'COBADEFFXXX'],
    ['Spk Oberlausitz-Niederschl.', '85050100', 'WELADED1GRL'],
    ['Ostsächsische Spk Dresden', '85050300', 'OSDDDE81XXX'],
    ['Ostsächsische Spk Dresden', '85050300', 'OSDDDE81FTL'],
    ['Ostsächsische Spk Dresden', '85050300', 'OSDDDE81HDN'],
    ['Ostsächsische Spk Dresden', '85050300', 'OSDDDE81HOY'],
    ['Ostsächsische Spk Dresden', '85050300', 'OSDDDE81KMZ'],
    ['Ostsächsische Spk Dresden', '85050300', 'OSDDDE81PIR'],
    ['Ostsächsische Spk Dresden', '85050300', 'OSDDDE81RBG'],
    ['Ostsächsische Spk Dresden', '85050300', 'OSDDDE81SEB'],
    ['Spk Meißen', '85055000', 'SOLADES1MEI'],
    ['VB Pirna', '85060000', 'GENODEF1PR2'],
    ['Raiffbk Neustadt Sachs -alt', '85065028', 'GENODEF1SEB'],
    ['Commerzbank Dresden', '85080000', 'DRESDEFF850'],
    ['Commerzbank ITGK1 Dresden', '85080085', 'DRESDEFFJ29'],
    ['Commerzbank ITGK2 Dresden', '85080086', 'DRESDEFFJ30'],
    ['Commerzbank Hoyerswerda', '85080200', 'DRESDEFF857'],
    ['Commerzbank ITGK Dresden', '85089270', 'DRESDEFFI15'],
    ['VB Dresden-Bautzen', '85090000', 'GENODEF1DRS'],
    ['Volksbank Riesa', '85094984', 'GENODEF1RIE'],
    ['VB Raiffbk Meißen Großenh', '85095004', 'GENODEF1MEI'],
    ['Kreissparkasse Bautzen', '85550000', 'SOLADES1BAT'],
    ['Volksbank Bautzen -alt-', '85590000', 'GENODEF1BZV'],
    ['VB Löbau-Zittau', '85590100', 'GENODEF1NGS'],
    ['VB Raiffbk Niederschlesien', '85591000', 'GENODEF1GR1'],
    ['BBk Leipzig', '86000000', 'MARKDEF1860'],
    ['Postbank Ndl Deutsche Bank', '86010090', 'PBNKDEFFXXX'],
    ['Aareal Bank Wiesbaden', '86010424', 'AARBDE5W860'],
    ['UniCredit Bank-HypoVereinbk', '86020086', 'HYVEDEMM495'],
    ['Bank für Sozialwirtschaft', '86020500', 'BFSWDE33LPZ'],
    ['Santander Bank Leipzig', '86033300', 'SCFBDE33XXX'],
    ['Commerzbank Leipzig', '86040000', 'COBADEFFXXX'],
    ['Commerzbank CC Leipzig', '86040060', 'COBADEFFXXX'],
    ['Commerzbank CC Leipzig', '86040061', 'COBADEFFXXX'],
    ['ZV LBBW Leipzig', '86050000', 'SOLADEST861'],
    ['Spk Muldental', '86050200', 'SOLADES1GRM'],
    ['Kr Spk Torgau-Oschatz -alt-', '86050600', 'WELADED1TGU'],
    ['Spk Delitzsch-Eilenburg-alt', '86055002', 'SOLADES1DES'],
    ['Kr Spk Döbeln', '86055462', 'SOLADES1DLN'],
    ['St u Kr Spk Leipzig', '86055592', 'WELADE8LXXX'],
    ['VR Bank Leipziger Land', '86065448', 'GENODEF1BOA'],
    ['VR-Bank Mittelsachsen', '86065468', 'GENODEF1DL1'],
    ['Raiffeisenbank Grimma', '86065483', 'GENODEF1GMR'],
    ['Raiffeisenbank Torgau -alt-', '86069070', 'GENODEF1TGB'],
    ['Deutsche Bank', '86070000', 'DEUTDE8LXXX'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L860'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L861'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L862'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L863'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L864'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L865'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L866'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L867'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L868'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L869'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L870'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L871'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L872'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L873'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L874'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L875'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L877'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L878'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L879'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L880'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L881'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L882'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L883'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L884'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L885'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L887'],
    ['Deutsche Bank', '86070000', 'DEUTDE8L888'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB860'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB861'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB862'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB863'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB864'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB865'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB866'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB867'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB868'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB869'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB973'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB974'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB975'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB976'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB977'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB978'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB980'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB981'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB982'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB983'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB984'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB985'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB986'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB987'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB988'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB990'],
    ['Deutsche Bank', '86070024', 'DEUTDEDB991'],
    ['Deutsche Bank', '86070024', 'DEUTDEDBLEG'],
    ['Postbank Ndl Deutsche Bank', '86070407', 'DEUTDE8LP37'],
    ['Postbank Ndl Deutsche Bank', '86070424', 'DEUTDEDBP37'],
    ['Commerzbank Leipzig', '86080000', 'DRESDEFF860'],
    ['Commerzbank Zw 55 Leipzig', '86080055', 'DRESDEFF862'],
    ['Commerzbank ZW 57 Leipzig', '86080057', 'DRESDEFF867'],
    ['Commerzbank ITGK1 Leipzig', '86080085', 'DRESDEFFJ31'],
    ['Commerzbank ITGK2 Leipzig', '86080086', 'DRESDEFFJ32'],
    ['Commerzbank ITGK Leipzig', '86089280', 'DRESDEFFI16'],
    ['VR-Bank Muldental Grimma', '86095484', 'GENODEF1GMV'],
    ['Volksbank Delitzsch', '86095554', 'GENODEF1DZ1'],
    ['Leipziger Volksbank', '86095604', 'GENODEF1LVB'],
    ['BBk Chemnitz', '87000000', 'MARKDEF1870'],
    ['UniCredit Bank-HypoVereinbk', '87020086', 'HYVEDEMM497'],
    ['UniCredit Bank-HypoVereinbk', '87020087', 'HYVEDEMM481'],
    ['UniCredit Bank-HypoVereinbk', '87020088', 'HYVEDEMM441'],
    ['Commerzbank Chemnitz', '87040000', 'COBADEFFXXX'],
    ['Sparkasse Chemnitz', '87050000', 'CHEKDE81XXX'],
    ['Sparkasse Chemnitz', '87050000', 'CHEKDE81GLA'],
    ['Sparkasse Chemnitz', '87050000', 'CHEKDE81HOT'],
    ['Sparkasse Chemnitz', '87050000', 'CHEKDE81LIM'],
    ['Sparkasse Chemnitz', '87050000', 'CHEKDE81LTS'],
    ['Sparkasse Chemnitz', '87050000', 'CHEKDE81MRN'],
    ['Spk Mittelsachsen -alt-', '87051000', 'WELADED1MTW'],
    ['Spk Mittelsachsen', '87052000', 'WELADED1FGX'],
    ['Erzgebirgssparkasse', '87054000', 'WELADED1STB'],
    ['Sparkasse Zwickau', '87055000', 'WELADED1ZWI'],
    ['Sparkasse Vogtland', '87058000', 'WELADED1PLX'],
    ['VB Mittl Erzgebirge', '87069075', 'GENODEF1MBG'],
    ['Ver Raiffbk Burgstädt', '87069077', 'GENODEF1BST'],
    ['Deutsche Bank', '87070000', 'DEUTDE8CXXX'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C870'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C871'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C872'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C873'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C874'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C875'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C876'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C877'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C878'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C879'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C880'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C881'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C882'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C883'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C884'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C885'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C886'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C887'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C888'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C889'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C890'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C891'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C892'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C893'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C894'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C895'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C896'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C897'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C898'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C899'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C900'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C901'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C902'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C903'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C905'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C906'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C907'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C908'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C909'],
    ['Deutsche Bank', '87070000', 'DEUTDE8C910'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB870'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB871'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB872'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB873'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB874'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB875'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB876'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB877'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB878'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB879'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB880'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB881'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB882'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB883'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB884'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB885'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB886'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB887'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB888'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB889'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB890'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB891'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB892'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB893'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB894'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB895'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB896'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB897'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB898'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB899'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB900'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB901'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB902'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB903'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB905'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB906'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB907'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB908'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB909'],
    ['Deutsche Bank', '87070024', 'DEUTDEDB910'],
    ['Deutsche Bank', '87070024', 'DEUTDEDBCHE'],
    ['Postbank Ndl Deutsche Bank', '87070406', 'DEUTDE8CP36'],
    ['Postbank Ndl Deutsche Bank', '87070424', 'DEUTDEDBP36'],
    ['Commerzbank Chemnitz', '87080000', 'DRESDEFF870'],
    ['VB Vogtland-Saale-Orla', '87095824', 'GENODEF1PL1'],
    ['VB Vogtland GAA', '87095899', 'GENODEF1EXT'],
    ['Volksbank Zwickau', '87095934', 'GENODEF1Z01'],
    ['VB-RB Glauchau', '87095974', 'GENODEF1GC1'],
    ['Volksbank Mittweida', '87096124', 'GENODEF1MIW'],
    ['Volksbank Chemnitz', '87096214', 'GENODEF1CH1']
  ];

  /**
   * An object containing all the AT bank data.
   */
  exports.data = bankData;
  exports.bankCodeLength = BANK_CODE_LENGHTS;
  exports.bicNumberLength = BIC_NUMBER_LENGTHS;
  exports.countryCode = COUNTRY_CODE_UPPERCASE;
});

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports'], factory);
  } else if (
    typeof exports === 'object' &&
    typeof exports.nodeName !== 'string'
  ) {
    // CommonJS
    factory(exports);
  } else {
    // Browser globals
    factory((root.Sepa = {}));
  }
})(this, function(exports) {
  /**
   * An object containing all the functions.
   */
  exports.validateIBAN = validateIBAN;
  exports.validateBBAN = validateBBAN;
  exports.validateBicExists = validateBicExists;
  exports.getBankNameByBic = getBankNameByBic;
  exports.getBankNameByBankCode = getBankNameByBankCode;
  exports.getCountryFromIban = getCountryFromIban;
  exports.getBankCodeFromIban = getBankCodeFromIban;
  exports.formatIban = formatIban;

  ///////////////

  //i.e. RLBBAT2E
  function validateBicExists(bic, countryCode) {
    //format
    bic =
      typeof bic === 'string' || bic instanceof String
        ? bic.toString().toUpperCase()
        : '';
    countryCode =
      typeof countryCode === 'string' || countryCode instanceof String
        ? countryCode.toString().toUpperCase()
        : '';

    // if country is not in list return true because we cant validate
    if (['AT', 'DE', 'CH', 'GB', 'UK'].indexOf(countryCode) === -1) {
      return true;
    }
    //if bic not given return true
    if (bic === '') {
      return true;
    }

    return getBankNameByBic(bic, countryCode) !== '';
  }

  function validateIBAN(iban) {
    if (iban === undefined) {
      return false;
    }
    if (iban === '') {
      return false;
    }
    if (iban === false) {
      return false;
    }
    if (iban === true) {
      return false;
    }
    if (BankData_Utils.isArray(iban)) {
      return false;
    }
    if (typeof iban === 'object') {
      return false;
    }
    if (typeof iban === 'function') {
      return false;
    }

    iban = iban || '';

    iban =
      iban
        .replace(/ /g, '')
        .replace(/-/g, '')
        .replace(/_/g, '') || '';

    var isValidIban = true;

    if (iban && !IBAN.isValid(iban)) {
      isValidIban = false;
    }

    return isValidIban;
  }

  function validateBBAN(bic, accountNumber, countryCode) {
    var isValid = false;

    bic = bic || '';
    accountNumber = accountNumber || '';
    countryCode = countryCode || '';

    if (bic === undefined) {
      return isValid;
    }
    if (bic === '') {
      return isValid;
    }
    if (bic === false) {
      return isValid;
    }
    if (bic === true) {
      return isValid;
    }
    if (BankData_Utils.isArray(bic)) {
      return isValid;
    }
    if (typeof bic === 'object') {
      return isValid;
    }
    if (typeof bic === 'function') {
      return isValid;
    }

    var banksObj = BankData_Utils.getBankDataByCountry(countryCode);

    if (!banksObj) {
      return isValid;
    }

    bic =
      bic
        .replace(/ /g, '')
        .replace(/-/g, '')
        .replace(/_/g, '') || '';
    accountNumber =
      accountNumber
        .replace(/ /g, '')
        .replace(/-/g, '')
        .replace(/_/g, '') || '';

    bic = BankData_Utils.padDigits(bic, banksObj.bankCodeLength);
    accountNumber = BankData_Utils.padDigits(
      accountNumber,
      banksObj.bicNumberLength
    );

    var bban = bic + accountNumber;

    try {
      IBAN.fromBBAN(banksObj.countryCode, bban);
      isValid = true;
    } catch (err) {
      isValid = false;
    }

    return isValid;
  }

  function getBankNameByBic(bic, countryCode) {
    if (bic === '') {
      return '';
    }

    countryCode = countryCode.toUpperCase();

    var bankName = '';
    var bankObj = BankData_Utils.getBankDataByCountry(countryCode);

    if (!bankObj) {
      return '';
    }

    var bankData = bankObj.data;

    for (var i = 0; i < bankData.length; i++) {
      if (
        bankData[i][2] === bic ||
        BankData_Utils.checkLastCharsOfStringToBeChar(bic, bankData[i][2])
      ) {
        bankName = bankData[i][0];
        return bankName;
      }
    }

    return bankName;
  }

  function getBankNameByBankCode(bankCode, countryCode) {
    countryCode = countryCode.toUpperCase();
    var bankName = '';
    var bankObj = BankData_Utils.getBankDataByCountry(countryCode);

    if (!bankObj) {
      return '';
    }

    var bankData = bankObj.data;

    if (bankCode == '') {
      return bankName;
    }

    bankCode = BankData_Utils.padDigits(bankCode, bankObj.bankCodeLength);

    for (var i = 0; i < bankData.length; i++) {
      if (bankData[i][1] == bankCode) {
        bankName = bankData[i][0];
        return bankName;
      }
    }

    return bankName;
  }

  function getCountryFromIban(iban) {
    iban = iban || '';
    var country = '';

    //get char from pos 0 to 2 or less
    if (iban.length >= 2) {
      country = iban[0] + iban[1];
    } else {
      country = iban ? iban.substring(0, iban.length) : '';
    }

    country = country.toUpperCase();

    return country;
  }

  /**
   * If the IBAN is valid it will return the bank code
   * of country-specific length ('' otherwise)
   *
   * @param  {string} iban
   * @return {string} bankCode
   */
  function getBankCodeFromIban(iban) {
    iban = iban || '';
    var bankCode = '';
    if (IBAN.isValid(iban)) {
      // the split part is here because the toBBAN method joins the parts
      var parts = IBAN.toBBAN(iban, '-').split('-');
      if (parts.length > 0) {
        bankCode = parts[0];
      }
    }
    return bankCode;
  }

  function formatIban(iban) {
    var countryCode = getCountryFromIban(iban);
    //return unformatted if country not given
    if (!countryCode) {
      return iban;
    }

    iban = iban || '';
    iban = iban
      .replace(/ /g, '')
      .replace(/-/g, '')
      .replace(/_/g, '');
    iban = iban.toUpperCase();

    var mask = getIbanMask(countryCode);
    var maskPatterns = {
      S: /[0-9A-Za-z]/,
      '0': /[0-9]/
    };

    return BankData_Utils.maskString(iban, mask, maskPatterns);
  }

  function getIbanMask(countryCode) {
    var mask;

    var IBAN_MASKS = {
      default: 'SSSS SSSS SSSS SSSS SSSS SSSS SSSS SSSS',
      AD: 'SSSS SSSS SSSS SSSS SSSS SSSS',
      AL: 'SSSS SSSS SSSS SSSS SSSS SSSS SSSS',
      AT: 'SSSS SSSS SSSS SSSS SSSS',
      BA: 'SSSS SSSS SSSS SSSS SSSS',
      BE: 'SSSS SSSS SSSS SSSS',
      BG: 'SSSS SSSS SSSS SSSS SSSS SS',
      CH: 'SSSS SSSS SSSS SSSS SSSS S',
      CY: 'SSSS SSSS SSSS SSSS SSSS SSSS SSSS',
      CZ: 'SSSS SSSS SSSS SSSS SSSS SSSS',
      DE: 'SSSS SSSS SSSS SSSS SSSS SS',
      DK: 'SSSS SSSS SSSS SSSS SS',
      EE: 'SSSS SSSS SSSS SSSS SSSS',
      ES: 'SSSS SSSS SSSS SSSS SSSS SSSS',
      FI: 'SSSS SSSS SSSS SSSS SS',
      FO: 'SSSS SSSS SSSS SSSS SS',
      FR: 'SSSS SSSS SSSS SSSS SSSS SSSS SSS',
      GB: 'SSSS SSSS SSSS SSSS SSSS SS',
      GE: 'SSSS SSSS SSSS SSSS SSSS SS',
      GG: 'SSSS SSSS SSSS SSSS SSSS SS',
      GI: 'SSSS SSSS SSSS SSSS SSSS SSS',
      GL: 'SSSS SSSS SSSS SSSS SS',
      GR: 'SSSS SSSS SSSS SSSS SSSS SSSS SSS',
      HR: 'SSSS SSSS SSSS SSSS SSSS S',
      HU: 'SSSS SSSS SSSS SSSS SSSS SSSS SSSS',
      IE: 'SSSS SSSS SSSS SSSS SSSS SS',
      IS: 'SSSS SSSS SSSS SSSS SSSS SSSS SS',
      IT: 'SSSS SSSS SSSS SSSS SSSS SSSS SSS',
      LI: 'SSSS SSSS SSSS SSSS SSSS S',
      LT: 'SSSS SSSS SSSS SSSS SSSS',
      LU: 'SSSS SSSS SSSS SSSS SSSS',
      LV: 'SSSS SSSS SSSS SSSS SSSS S',
      MC: 'SSSS SSSS SSSS SSSS SSSS SSSS SSS',
      MD: 'SSSS SSSS SSSS SSSS SSSS SSSS',
      ME: 'SSSS SSSS SSSS SSSS SSSS SS',
      MK: 'SSSS SSSS SSSS SSSS SSS',
      MT: 'SSSS SSSS SSSS SSSS SSSS SSSS SSSS SSS',
      NL: 'SSSS SSSS SSSS SSSS SS',
      NO: 'SSSS SSSS SSSS SSS',
      PL: 'SSSS SSSS SSSS SSSS SSSS SSSS SSSS',
      PT: 'SSSS SSSS SSSS SSSS SSSS SSSS S',
      RO: 'SSSS SSSS SSSS SSSS SSSS SSSS',
      RS: 'SSSS SSSS SSSS SSSS SSSS SS',
      SE: 'SSSS SSSS SSSS SSSS SSSS SSSS',
      SI: 'SSSS SSSS SSSS SSSS SSS',
      SK: 'SSSS SSSS SSSS SSSS SSSS SSSS',
      SM: 'SSSS SSSS SSSS SSSS SSSS SSSS SSS',
      UA: 'SSSS SSSS SSSS SSSS SSSS SSSS SSSS S'
    };

    mask = IBAN_MASKS[countryCode] || IBAN_MASKS['default'];

    return mask;
  }
});

formatIbanDir.$inject = ['formHelperFactory'];
function formatIbanDir(formHelperFactory) {
  return {
    require: ['ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'formatIbanDir';

      var ngModel = controllers[0];

      var isActive;
      var dirActiveWatch;

      /*
        GBoard uses Composition Input, which breaks the current Value Transformation.
        After the compositionEnd, another `insertText` is added, where the content of the composition
        is added as data. Therefore the data is appended again to the field and appears duplicated.
        Manually setting the value of the element with this data of the `insertText` event solves this issue.
      */
      element.on('input', function(event) {
        const { data, inputType } = event.originalEvent;

        if (inputType === 'insertText' && data.length > 1) {
          ngModel.$setViewValue(data);
          ngModel.$render();

          formHelperFactory.focusEnd(attrs.id);
        }
      });

      //For DOM -> model validation
      ngModel.$parsers.unshift(parseFunction);

      ////////

      dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value, prevVal) {
          //detect change
          if (isActive !== value) {
            isActive = !!value;
          }
        }
      );

      ////////////////////////////////////////////

      //For DOM -> model validation
      function parseFunction(formattedIbanValue) {
        if (isActive) {
          var transformedInput = Sepa.formatIban(formattedIbanValue);

          //check for changes
          if (transformedInput !== formattedIbanValue) {
            ngModel.$setViewValue(transformedInput);
            ngModel.$render();

            /*cursor jump bugfix*/
            formHelperFactory.focusEnd(attrs.id);

            return transformedInput;
          }
        }

        return formattedIbanValue;
      }
    }
  };
}

angular.module('formatIban-dir', []).directive('formatIbanDir', formatIbanDir);

function isCountryOfIbanValidDir() {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isCountryOfIbanValidDir';
      var countryFieldName = 'isCountryOfIbanValidCountry';

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      var country = '';
      var countryWatch;

      var isActive = false;

      //For DOM -> model validation
      ngModel.$parsers.unshift(parseFunction);

      var dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = value;
            if (isActive) {
              activateDir();
            } else {
              deactivateDir();
            }
          }
        }
      );

      ////////////////////////////////////////////

      function activateDir() {
        //watch country attribute which changes dynamically
        countryWatch = scope.$watch(getCountryValue, function(country) {
          var value = ngModel.$modelValue || ngModel.$viewValue;
          validateInput(value);
        });
      }

      function deactivateDir() {
        if (typeof countryWatch === 'function') {
          countryWatch();
        }
      }

      function parseFunction(ibanValue) {
        validateInput(ibanValue);
        return ibanValue;
      }

      function getCountryValue() {
        var country = '';
        if (
          attrs[countryFieldName] &&
          form[attrs[countryFieldName]] &&
          '$modelValue' in form[attrs[countryFieldName]] &&
          form[attrs[countryFieldName]].$modelValue
        ) {
          country = form[attrs[countryFieldName]].$modelValue;
        }
        return country;
      }

      function validateInput(ibanValue) {
        var valid = true;
        var errorName = 'ibanCountry';
        ngModel.$setValidity(errorName, valid);

        if (isActive && ibanValue) {
          var selectedCountry = getCountryValue().toUpperCase();
          var ibanCountryCode = Sepa.getCountryFromIban(
            ibanValue
          ).toUpperCase();

          if (/^[a-zA-Z]+$/.test(ibanCountryCode)) {
            if (ibanCountryCode != selectedCountry) {
              valid = false;
            }
          } else {
            //country should be alpha only to get validated
            valid = true;
          }

          ngModel.$setValidity(errorName, valid);
        }

        return valid;
      }
    }
  };
}

angular
  .module('isCountryOfIbanValid-dir', [])
  .directive('isCountryOfIbanValidDir', isCountryOfIbanValidDir);

function isIbanValidDir() {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isIbanValidDir';

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      var isActive = false;

      //For DOM -> model validation
      ngModel.$parsers.unshift(parseFunction);

      //For model -> DOM validation
      //ngModel.$formatters.unshift(formatterFunction);

      var dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = value;
          }
        }
      );

      ////////////////////////////////////////////

      function parseFunction(ibanValue) {
        validateInput(ibanValue);
        return ibanValue;
      }

      function validateInput(ibanValue) {
        var valid = true;
        var errorName = 'ibanValid';

        ngModel.$setValidity(errorName, valid);

        //perform check only if there is no country error present on this field
        if (isActive && ibanValue) {
          valid = Sepa.validateIBAN(ibanValue);
          ngModel.$setValidity(errorName, valid);
        }

        return valid;
      }

      function modelHasIbanCountryError() {
        var hasError = false;

        if (ngModel.$error && 'ibanCountry' in ngModel.$error) {
          hasError = ngModel.$error['ibanCountry'];
        }
        return hasError;
      }
    }
  };
}

angular
  .module('isIbanValid-dir', [])
  .directive('isIbanValidDir', isIbanValidDir);

function isBicExistingValidDir() {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isBicExistingValidDir';
      var ibanFieldName = 'isBicExistingValidCountry';

      var watchers = [];

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      //
      var isActive = false;
      var dirActiveWatch;

      dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = value;
            activateDir();
          } else {
            deactivateDir();
          }
        }
      );

      //For DOM -> model validation
      ngModel.$parsers.unshift(function(bicValue) {
        validateInput(bicValue);
        return bicValue;
      });

      ////////////////////////////////////////////

      function activateDir() {
        //watch country attribute which changes dynamically
        watchers = scope.$watch(getCountryOfIbanValue, function(ibanCountry) {
          var value = ngModel.$modelValue || ngModel.$viewValue;
          validateInput(value);
        });
      }

      function deactivateDir() {
        for (var i = 0; i < watchers.length; i++) {
          if (typeof watchers[i] === 'function') {
            watchers[i]();
          }
        }
      }

      function getCountryOfIbanValue() {
        var ibanCountry = '';
        if (
          attrs[ibanFieldName] &&
          form[attrs[ibanFieldName]] &&
          '$modelValue' in form[attrs[ibanFieldName]] &&
          form[attrs[ibanFieldName]]
        ) {
          var ibanControl = form[attrs[ibanFieldName]];
          var iban = ibanControl.$modelValue || ibanControl.$viewValue;

          ibanCountry = Sepa.getCountryFromIban(iban);
        }
        return ibanCountry;
      }

      function validateInput(bicValue) {
        bicValue = bicValue || '';
        var isExisting = true;
        var errorNameExists = 'bicExisting';

        ngModel.$setValidity(errorNameExists, true);

        if (isActive && !!bicValue) {
          var ibanCountry = getCountryOfIbanValue();
          isExisting = Sepa.validateBicExists(bicValue, ibanCountry);

          if (!isExisting) {
            ngModel.$setValidity(errorNameExists, false);
          }
        }

        return isExisting;
      }
    }
  };
}

angular
  .module('isBicExistingValid-dir', [])
  .directive('isBicExistingValidDir', isBicExistingValidDir);

angular.module('bank-account.sepa', [
  'formatIban-dir',
  'isCountryOfIbanValid-dir',
  'isIbanValid-dir',
  'getBankName-dir',
  'isBicExistingValid-dir'
]);

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports'], factory);
  } else if (
    typeof exports === 'object' &&
    typeof exports.nodeName !== 'string'
  ) {
    // CommonJS
    factory(exports);
  } else {
    // Browser globals
    factory((root.ukBankData = {}));
  }
})(this, function(exports) {
  var BANK_DATA = [
    ['Lloyds Bank plc Worcester', '000097', 'NWBKGB2118R'],
    ['TEST BANK PLC PLC Worcester', '000098', 'NWBKGB2118R'],
    ['TEST BANK PLC PLC Worcester', '000099', 'NWBKGB2118R'],
    ['NATIONAL WESTMINSTER BANK PLC Accrington', '010004', 'NWBKGB2118R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Client Monies Service Ctr',
      '010008',
      'NWBKGB21CMS'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Wilmslow  (A)', '010013', 'NWBKGB2146V'],
    ['NATIONAL WESTMINSTER BANK PLC Ashton-U-Lyne', '010039', 'NWBKGB2113P'],
    ['NATIONAL WESTMINSTER BANK PLC Rawtenstall (A)', '010051', 'NWBKGB2140P'],
    ['NATIONAL WESTMINSTER BANK PLC Bamber Bridge', '010053', 'NWBKGB2115B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Barrow in Furness',
      '010061',
      'NWBKGB2133S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Congleton (A)', '010071', 'NWBKGB2148Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC Blackburn,35 King Wm St',
      '010085',
      'NWBKGB2118M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Halesowen (A)', '010090', 'NWBKGB2117T'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bramhall,21 Brmhall Ln Sth',
      '010114',
      'NWBKGB2110E'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Brighton 137 North Street',
      '010123',
      'NWBKGB2119S'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Burnley,Grimshaw Street',
      '010135',
      'NWBKGB2118N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Hanley (B)', '010138', 'NWBKGB2105L'],
    ['NATIONAL WESTMINSTER BANK PLC Bury', '010142', 'NWBKGB2119Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC Cardiff, Queen Street (A)',
      '010155',
      'NWBKGB2122T'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Morecambe, Central (A)',
      '010160',
      'NWBKGB2133V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Oldham (A)', '010166', 'NWBKGB2140S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Cheadle Stockport',
      '010175',
      'NWBKGB2108P'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Longton Staffs (A)',
      '010178',
      'NWBKGB2148K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Cheadle (A)', '010181', 'NWBKGB2146U'],
    ['NATIONAL WESTMINSTER BANK PLC Chorley', '010194', 'NWBKGB2116Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC Chorlton-C-H 438 Brlow Moor',
      '010197',
      'NWBKGB2135Y'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Manchester, City Centre (D)',
      '010202',
      'NWBKGB2135V'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Cleveleys,Victoria Square',
      '010211',
      'NWBKGB2129P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Clitheroe', '010214', 'NWBKGB2116E'],
    ['NATIONAL WESTMINSTER BANK PLC Workington (A)', '010217', 'NWBKGB2122D'],
    ['NATIONAL WESTMINSTER BANK PLC Burnley (B)', '010220', 'NWBKGB2118S'],
    ['NATIONAL WESTMINSTER BANK PLC Leigh, Lancs (B)', '010246', 'NWBKGB2153V'],
    ['NATIONAL WESTMINSTER BANK PLC Blackburn (A)', '010257', 'NWBKGB2118P'],
    [
      "NATIONAL WESTMINSTER BANK PLC Derby,St.Peter's Street",
      '010266',
      'NWBKGB2125U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Didsbury,Wilmslow Road',
      '010269',
      'NWBKGB2115G'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Ashton under Lyne (A)',
      '010277',
      'NWBKGB2136H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Monton (C)', '010296', 'NWBKGB2136C'],
    ['NATIONAL WESTMINSTER BANK PLC Farnworth', '010321', 'NWBKGB2119M'],
    ['NATIONAL WESTMINSTER BANK PLC Great Crosby (A)', '010325', 'NWBKGB2133C'],
    ['NATIONAL WESTMINSTER BANK PLC Fulwood', '010332', 'NWBKGB2142S'],
    ['NATIONAL WESTMINSTER BANK PLC Garstang', '010333', 'NWBKGB2142T'],
    ['NATIONAL WESTMINSTER BANK PLC Glossop', '010338', 'NWBKGB2146N'],
    ['NATIONAL WESTMINSTER BANK PLC Kendal (A)', '010346', 'NWBKGB2133Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC Hanley,Upper Market Sq',
      '010369',
      'NWBKGB2112W'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Hazel Grove,182 London Rd',
      '010387',
      'NWBKGB2106P'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Heald Green,260 Finney Ln',
      '010390',
      'NWBKGB2146T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Heaton Chapel', '010393', 'NWBKGB2146S'],
    ['NATIONAL WESTMINSTER BANK PLC Halifax (A)', '010399', 'NWBKGB2114Y'],
    ['NATIONAL WESTMINSTER BANK PLC Horwich', '010435', 'NWBKGB2119P'],
    ['NATIONAL WESTMINSTER BANK PLC Hyde', '010457', 'NWBKGB2108C'],
    ['NATIONAL WESTMINSTER BANK PLC Kendal', '010466', 'NWBKGB2133X'],
    ['NATIONAL WESTMINSTER BANK PLC Penrith (A)', '010469', 'NWBKGB2122C'],
    ['NATIONAL WESTMINSTER BANK PLC Kirkby Lonsdale', '010481', 'NWBKGB2133W'],
    ['NATIONAL WESTMINSTER BANK PLC Preston (B)', '010484', 'NWBKGB2142V'],
    ['NATIONAL WESTMINSTER BANK PLC Lancaster (A)', '010492', 'NWBKGB2133P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Leek,Derby Street',
      '010502',
      'NWBKGB2105H'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Heaton Chapel (A)',
      '010511',
      'NWBKGB2135X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Leyland', '010514', 'NWBKGB2114V'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bootle, New Strand (A)',
      '010515',
      'NWBKGB2132X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Preston (C)', '010524', 'NWBKGB2142U'],
    ['NATIONAL WESTMINSTER BANK PLC Longton,Staffs', '010527', 'NWBKGB2114R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Manchester Spinningfieldssq',
      '010531',
      'NWBKGB2136B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Macclesfield', '010541', 'NWBKGB2113S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Markt Draytn,42 Cheshire St',
      '010548',
      'NWBKGB2150P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Hazel Grove (B)', '010551', 'NWBKGB2115F'],
    ['NATIONAL WESTMINSTER BANK PLC Middleton', '010563', 'NWBKGB2115T'],
    ['NATIONAL WESTMINSTER BANK PLC Monton', '010575', 'NWBKGB2136D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Morecambe,262 Marine Road',
      '010578',
      'NWBKGB2133U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Burnley (A)', '010593', 'NWBKGB2118T'],
    ['NATIONAL WESTMINSTER BANK PLC Hazel Grove (A)', '010605', 'NWBKGB2146R'],
    ['NATIONAL WESTMINSTER BANK PLC Hollinwood (B)', '010608', 'NWBKGB2135T'],
    [
      "NATIONAL WESTMINSTER BANK PLC Norwich, Gentleman's Wlk(A)",
      '010628',
      'NWBKGB2139J'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Melton Mowbray (A)',
      '010634',
      'NWBKGB2133Z'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Oldham,10 Yorkshire Street',
      '010639',
      'NWBKGB2140N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ormskirk', '010645', 'NWBKGB2114P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Manchester, City Centre (B)',
      '010657',
      'NWBKGB2135U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Manchester, City Centre (A)',
      '010688',
      'NWBKGB2135Q'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Manchester,Portland Street',
      '010696',
      'NWBKGB2135S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Port Sunlight', '010702', 'NWBKGB2133H'],
    ['NATIONAL WESTMINSTER BANK PLC Cleveleys (B)', '010705', 'NWBKGB2129Q'],
    ['NATIONAL WESTMINSTER BANK PLC Macclesfield (A)', '010708', 'NWBKGB2146M'],
    ['NATIONAL WESTMINSTER BANK PLC Prestwich', '010720', 'NWBKGB2135Z'],
    ['NATIONAL WESTMINSTER BANK PLC Rawtenstall (B)', '010726', 'NWBKGB2119K'],
    ['NATIONAL WESTMINSTER BANK PLC Rawtenstall', '010729', 'NWBKGB2118Q'],
    ['NATIONAL WESTMINSTER BANK PLC Rochdale', '010744', 'NWBKGB2140Q'],
    ['NATIONAL WESTMINSTER BANK PLC Oldham (B)', '010750', 'NWBKGB2115M'],
    ['NATIONAL WESTMINSTER BANK PLC St Annes on Sea', '010765', 'NWBKGB2129S'],
    ['NATIONAL WESTMINSTER BANK PLC Sale', '010771', 'NWBKGB2105E'],
    ['NATIONAL WESTMINSTER BANK PLC Sandbach', '010780', 'NWBKGB2125K'],
    ['NATIONAL WESTMINSTER BANK PLC Skipton (A)', '010793', 'NWBKGB2118X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Slough, Trading Estate',
      '010815',
      'NWBKGB2146W'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Stafford,Greengate',
      '010832',
      'NWBKGB2108F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Stockport,Underbank Hall',
      '010838',
      'NWBKGB2113A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Stafford Greengate (B)',
      '010847',
      'NWBKGB2114Q'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Chorlton-Cum Hardy (A)',
      '010852',
      'NWBKGB2136E'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Trafford Park,Trafford Rd',
      '010881',
      'NWBKGB2135W'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Barrow-in-Furness (A)',
      '010893',
      'NWBKGB2133R'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Manchester, City Centre (C)',
      '010894',
      'NWBKGB2135R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Oldham (C)', '010899', 'NWBKGB2140R'],
    ['NATIONAL WESTMINSTER BANK PLC Urmston', '010902', 'NWBKGB2109N'],
    [
      'NATIONAL WESTMINSTER BANK PLC Warrington,Sankey Street',
      '010917',
      'NWBKGB2153T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC West Bromwich', '010931', 'NWBKGB2105Z'],
    ['NATIONAL WESTMINSTER BANK PLC Prestwich (A)', '010948', 'NWBKGB2136J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Blackpool, Corp St (A)',
      '010951',
      'NWBKGB2129M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Whitehaven', '010954', 'NWBKGB2122G'],
    ['NATIONAL WESTMINSTER BANK PLC Carlisle (A)', '010966', 'NWBKGB2122H'],
    ['NATIONAL WESTMINSTER BANK PLC Wilmslow', '010969', 'NWBKGB2107S'],
    ['NATIONAL WESTMINSTER BANK PLC Windermere', '010975', 'NWBKGB2133T'],
    [
      'NATIONAL WESTMINSTER BANK PLC Didsbury, Wilmslow Road (A)',
      '010978',
      'NWBKGB2109F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Manchester,City Centre',
      '011001',
      'NWBKGB2136P'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Manchester,City Centre (E)',
      '012015',
      'NWBKGB2160A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bolton', '013099', 'NWBKGB2119L'],
    [
      'NATIONAL WESTMINSTER BANK PLC Lancaster,Church Street',
      '015490',
      'NWBKGB2133N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Preston (A)', '016714', 'NWBKGB2142R'],
    ['BFC BANK LIMITED Bfc Bank', '040002', ''],
    ['MONZO BANK LIMITED Monzo', '040003', ''],
    ['MONZO BANK LIMITED Monzo', '040004', 'MONZGB2L'],
    ['MONZO BANK LIMITED Monzo', '040005', ''],
    ['MONZO BANK LIMITED Monzo', '040006', ''],
    ['STARLING BANK LIMITED Form 3 Re Moorgate', '040010', ''],
    ['STARLING BANK LIMITED Satabank', '040011', ''],
    ['STARLING BANK LIMITED Form3', '040013', ''],
    ['myPOS Europe Ltd Mypos', '040015', ''],
    ['POCKIT LIMITED Pockit 2', '040018', ''],
    ['VITESSE PSP LIMITED Vitesse Psp', '040020', ''],
    ['TRANSACT PAYMENTS LIMITED Transact Payments', '040021', ''],
    ['HOMESEND SCRL Homesend', '040022', ''],
    ['STARLING BANK LIMITED Starling Bank 11', '040023', ''],
    ['VIVA PAYMENTS SERVICES S.A. Viva Payments', '040024', ''],
    ['POCKIT LIMITED Pockit', '040029', ''],
    ['PAYMENTSENSE LIMITED Paymentsense', '040030', ''],
    ['DZING FINANCE LIMITED Dzing Finance', '040031', ''],
    ['PAYMENTSENSE LIMITED Paymentsense', '040033', ''],
    ['PAYMENTSENSE LIMITED Paymentsense', '040034', ''],
    ['STARLING BANK LIMITED Paymentsense', '040035', ''],
    ['PAYMENTSENSE LIMITED Paymentsense', '040039', ''],
    ['STARLING BANK LIMITED Starling Bank Limited', '040040', ''],
    ['PAYRNET LIMITED Railsbank 31', '040041', ''],
    ['STARLING BANK LIMITED Starling Bank 12', '040042', ''],
    ['TRANSACT PAYMENTS LIMITED Govcoin', '040043', ''],
    ['INCOME GROUP LIMITED Income Group Ltd', '040044', ''],
    ['INCOME GROUP LIMITED Income Group Limited', '040045', ''],
    ['TRANSACT PAYMENTS LIMITED Just Transact', '040046', ''],
    ['STARLING BANK LIMITED Starling Bank 14', '040047', ''],
    ['STARLING BANK LIMITED Raisin', '040048', ''],
    ['STARLING BANK LIMITED Ditto Bank', '040049', ''],
    ['STARLING BANK LIMITED Starling Bank1', '040050', ''],
    ['STARLING BANK LIMITED Starling Bank2', '040051', ''],
    ['PAYRNET LIMITED Payrnet', '040052', ''],
    ['PAYRNET LIMITED Payrnet Limited', '040053', ''],
    ['STARLING BANK LIMITED Starling Bank5', '040054', ''],
    ['STARLING BANK LIMITED Starling Bank6', '040055', ''],
    ['SOLDO FINANCIAL SERVICES LIMITED Soldo', '040056', ''],
    ['ARDOHR LIMITED Credec', '040057', ''],
    ['PAYRNET LIMITED Wagestream', '040058', ''],
    ['STARLING BANK LIMITED Starling Bank Limited', '040059', ''],
    ['ELAVON FINANCIAL SERVICES DAC Elavon', '040070', 'USBKGB33XXX'],
    ['MODULR FS LIMITED Modulr Fs Ltd', '040072', ''],
    ['MODULR FS LIMITED Modulr Fs Ltd', '040073', ''],
    ['MODULR FS LIMITED Modulr Fs Ltd', '040074', ''],
    ['MODULR FS LIMITED Revolut', '040075', 'REVOGB21'],
    ['LCH LIMITED Lch Ltd', '040076', 'LCHLGB2LXXX'],
    ['ELAVON FINANCIAL SERVICES DAC Elavon', '040078', ''],
    ['VIRGIN MONEY (CLYDESDALE BANK PLC) Head Office', '040079', 'NRNBGB2LXXX'],
    ['VIRGIN MONEY (CLYDESDALE BANK PLC) Head Office', '040080', 'NRNBGB2LRTN'],
    ['MODULR FS LIMITED Onepay', '040083', ''],
    ['MODULR FS LIMITED Modulr', '040084', ''],
    ['MODULR FS LIMITED Modulr', '040085', ''],
    ['MODULR FS LIMITED Modulr', '040086', ''],
    ['CASHPLUS BANK:ADVANCED PAYMENT SOLUTIONS Cashplus Bank', '040087', ''],
    ['EUROCLEAR BANK SA/NV Euroclear Bank', '040088', 'MGTCBEBEXXX'],
    ['CREDEC LIMITED Credec', '040190', ''],
    ['CREDEC LIMITED Credec', '040191', ''],
    ['CREDEC LIMITED Credec', '040192', ''],
    ['CREDEC LIMITED Credec', '040193', ''],
    ['CREDEC LIMITED Credec', '040194', ''],
    ['SPECTRUM PAYMENT SERVICES LTD Sps Ltd', '040195', ''],
    ['SPECTRUM PAYMENT SERVICES LTD Sps Ltd', '040196', ''],
    ['SPECTRUM PAYMENT SERVICES LTD Sps Ltd', '040197', ''],
    ['SPECTRUM PAYMENT SERVICES LTD Sps Ltd', '040198', ''],
    ['SPECTRUM PAYMENT SERVICES LTD Sps Ltd', '040199', ''],
    ['AS LHV PANK Lhv', '040300', 'LHVBGB2LXXX'],
    ['AS LHV PANK As Lhv Pank', '040301', ''],
    ['AS LHV PANK As Lhv Pank', '040302', ''],
    ['AS LHV PANK As Lhv Pank', '040303', ''],
    ['AS LHV PANK As Lhv Pank', '040304', ''],
    ['AS LHV PANK As Lhv Pank', '040305', ''],
    ['AS LHV PANK As Lhv Pank', '040306', ''],
    ['AS LHV PANK As Lhv Pank', '040307', ''],
    ['AS LHV PANK As Lhv Pank', '040308', ''],
    ['AS LHV PANK As Lhv Pank', '040309', ''],
    ['AS LHV PANK FF Lhv', '040315', ''],
    ['WIREPAYER LIMITED Wirepayer', '040329', ''],
    ['METTLE (TRADING NAME OF NATWEST) Mettle', '040333', ''],
    ['MODULR FS LIMITED Modulr', '040340', ''],
    ['PREPAID FINANCIAL SERVICES LTD Fp', '040343', ''],
    ['PAYRNET LIMITED Payrnet Limited', '040344', ''],
    ['SQUAREUP EUROPE LTD Squareup Europe Ltd', '040345', ''],
    ['SQUAREUP EUROPE LTD Squareup Europe Ltd', '040346', ''],
    ['SQUAREUP EUROPE LTD Squareup Europe Ltd', '040347', ''],
    ['SQUAREUP EUROPE LTD Squareup Europe Ltd', '040348', ''],
    ['SQUAREUP EUROPE LTD Squareup Europe Ltd', '040349', ''],
    ['PAYRNET LIMITED Railsbank 1', '040350', ''],
    ['PAYRNET LIMITED Ikigai', '040351', ''],
    ['PAYRNET LIMITED Cledara', '040352', ''],
    ['PAYRNET LIMITED Probitas', '040353', ''],
    ['PAYRNET LIMITED Lanistar', '040354', ''],
    ['PAYRNET LIMITED Railsbank 6', '040355', ''],
    ['PAYRNET LIMITED Plum', '040356', ''],
    ['PAYRNET LIMITED Railsbank 8', '040357', ''],
    ['PAYRNET LIMITED Railsbank 9', '040358', ''],
    ['PAYRNET LIMITED Railsbank 10', '040359', ''],
    ['PAYRNET LIMITED Railsbank 11', '040360', ''],
    ['PAYRNET LIMITED Railsbank 12', '040361', ''],
    ['PAYRNET LIMITED Railsbank 13', '040362', ''],
    ['PAYRNET LIMITED Railsbank 14', '040363', ''],
    ['PAYRNET LIMITED Railsbank 15', '040364', ''],
    ['PAYRNET LIMITED Railsbank 16', '040365', ''],
    ['PAYRNET LIMITED Railsbank 17', '040366', ''],
    ['PAYRNET LIMITED Railsbank 18', '040367', ''],
    ['PAYRNET LIMITED Railsbank 19', '040368', ''],
    ['PAYRNET LIMITED Railsbank 20', '040369', ''],
    ['PAYRNET LIMITED Anna Money', '040370', ''],
    ['PAYRNET LIMITED Railsbank 22', '040371', ''],
    ['PAYRNET LIMITED Railsbank 23', '040372', ''],
    ['PAYRNET LIMITED Railsbank 24', '040373', ''],
    ['PAYRNET LIMITED Railsbank 25', '040374', ''],
    ['PAYRNET LIMITED Railsbank 26', '040375', ''],
    ['PAYRNET LIMITED Railsbank 27', '040376', ''],
    ['PAYRNET LIMITED Railsbank 28', '040377', ''],
    ['PAYRNET LIMITED Railsbank 29', '040378', ''],
    ['PAYRNET LIMITED Lightningaid', '040379', ''],
    ['MODULR FS LIMITED U Account', '040390', ''],
    ['MODULR FS LIMITED Goodlord', '040391', ''],
    ['MODULR FS LIMITED Modulr Europe', '040392', ''],
    ['MODULR FS LIMITED Mypos', '040393', ''],
    [
      'ELAVON FINANCIAL SERVICES DAC Efs Dac - Corp Trust',
      '040398',
      'USBKGB22XXX'
    ],
    ['CLEARBANK LIMITED Clearbank Chaps', '040400', 'CLRBGB22XXX'],
    ['CLEARBANK LIMITED Clearbank Limited', '040403', ''],
    ['CLEARBANK LIMITED Clearbank 04', '040404', ''],
    ['CLEARBANK LIMITED Clearbank 05', '040405', ''],
    ['CLEARBANK LIMITED Clearbank 06 Bacs', '040406', ''],
    ['CLEARBANK LIMITED Clearbank Client T1', '040410', 'CLRBGB22001'],
    ['CLEARBANK LIMITED Clearbank Client T2', '040411', ''],
    ['CLEARBANK LIMITED Clearbank Client T3', '040412', 'CLRBGB22002'],
    ['CLEARBANK LIMITED Clearbank Client T4', '040413', ''],
    ['CLEARBANK LIMITED Clearbank Client T5', '040414', 'CLRBGB22003'],
    ['CLEARBANK LIMITED Clearbank Client T6', '040415', ''],
    ['CLEARBANK LIMITED Clearbank Client T7', '040416', 'CLRBGB22004'],
    ['CLEARBANK LIMITED Clearbank Client T8', '040417', ''],
    ['CLEARBANK LIMITED Clearbank Client T9', '040418', 'CLRBGB22005'],
    ['CLEARBANK LIMITED Clearbank Client T10', '040419', ''],
    ['CLEARBANK LIMITED Clearbank Client T11', '040420', 'CLRBGB22006'],
    ['CLEARBANK LIMITED Clearbank Client T12', '040421', ''],
    ['CLEARBANK LIMITED Clearbank Client T13', '040422', 'CLRBGB22007'],
    ['CLEARBANK LIMITED Clearbank Client T14', '040423', ''],
    ['CLEARBANK LIMITED Clearbank Client T15', '040424', 'CLRBGB22008'],
    ['CLEARBANK LIMITED Clearbank Client T16', '040425', ''],
    ['CLEARBANK LIMITED Clearbank Client T17', '040426', 'CLRBGB22009'],
    ['CLEARBANK LIMITED Clearbank Client T18', '040427', ''],
    ['CLEARBANK LIMITED Clearbank Client T19', '040428', 'CLRBGB22010'],
    ['CLEARBANK LIMITED Clearbank Client T20', '040429', ''],
    ['CLEARBANK LIMITED Clearbank Client T21', '040430', 'CLRBGB22011'],
    ['CLEARBANK LIMITED Clearbank Client T22', '040431', ''],
    ['CLEARBANK LIMITED Clearbank Client T23', '040432', 'CLRBGB22012'],
    ['CLEARBANK LIMITED Clearbank Client T24', '040433', ''],
    ['CLEARBANK LIMITED Clearbank Client T25', '040434', 'CLRBGB22013'],
    ['CLEARBANK LIMITED Clearbank Client T26', '040435', ''],
    ['CLEARBANK LIMITED Clearbank Client T27', '040436', 'CLRBGB22014'],
    ['CLEARBANK LIMITED Clearbank Client T28', '040437', ''],
    ['CLEARBANK LIMITED Clearbank Client T29', '040438', 'CLRBGB22015'],
    ['CLEARBANK LIMITED Clearbank Client T30', '040439', ''],
    ['CLEARBANK LIMITED Capital Ifx Limited', '040444', 'CLRBGB22018'],
    ['CLEARBANK LIMITED Capital Ifx Limited', '040445', ''],
    ['CLEARBANK LIMITED Jaja Finance Ltd', '040446', 'CLRBGB22020'],
    ['CLEARBANK LIMITED Jaja Finance Ltd', '040447', ''],
    ['CLEARBANK LIMITED Currency Matters Ltd', '040448', 'CLRBGB22021'],
    ['CLEARBANK LIMITED Currency Matters Ltd', '040449', ''],
    ['CLEARBANK LIMITED Covercy Europe Ltd', '040450', 'CLRBGB22022'],
    ['CLEARBANK LIMITED Covercy Europe Ltd', '040451', ''],
    ['CLEARBANK LIMITED Cfs Zipp Limited', '040452', 'CLRBGB22023'],
    ['CLEARBANK LIMITED Cfs Zipp Limited', '040453', ''],
    ['CLEARBANK LIMITED Allpay Limited', '040454', 'CLRBGB22024'],
    ['CLEARBANK LIMITED Sat Fx', '040455', ''],
    ['CLEARBANK LIMITED Sat Fx', '040456', 'CLRBGB22025'],
    ['CLEARBANK LIMITED Allpay Limited', '040457', ''],
    ['CLEARBANK LIMITED Cassava Remit Limited', '040458', 'CLRBGB22026'],
    ['CLEARBANK LIMITED Cassava Remit Limited', '040459', ''],
    ['CLEARBANK LIMITED Clearbank 60', '040460', 'CLRBGB22027'],
    ['CLEARBANK LIMITED Clearbank 61', '040461', ''],
    ['CLEARBANK LIMITED Inpay As', '040462', 'CLRBGB22028'],
    ['CLEARBANK LIMITED Inpay As', '040463', ''],
    ['CLEARBANK LIMITED Clearbank 64', '040464', 'CLRBGB22029'],
    ['CLEARBANK LIMITED Clearbank 65', '040465', ''],
    ['CLEARBANK LIMITED Trustpay As 01', '040466', 'CLRBGB22030'],
    ['CLEARBANK LIMITED Trustpay As 02', '040467', ''],
    ['CLEARBANK LIMITED Transactive Systems Ltd', '040468', 'CLRBGB22031'],
    ['CLEARBANK LIMITED Transactive Systems Ltd', '040469', ''],
    ['CLEARBANK LIMITED Freemarketfx Ltd', '040470', 'CLRBGB22032'],
    ['CLEARBANK LIMITED Freemarketfx Ltd', '040471', ''],
    ['CLEARBANK LIMITED Moorwand Ltd', '040472', 'CLRBGB22033'],
    ['CLEARBANK LIMITED Moorwand Ltd', '040473', ''],
    ['CLEARBANK LIMITED Transact Europe Ead', '040474', 'CLRBGB22034'],
    ['CLEARBANK LIMITED Transact Europe Ead', '040475', ''],
    ['ENUMIS LIMITED Enumis Limited', '040476', 'ENUMGB21XXX'],
    ['ENUMIS LIMITED Enumis Limited', '040477', ''],
    ['CLEARBANK LIMITED Frontier Global Consultants', '040478', 'CLRBGB22036'],
    ['CLEARBANK LIMITED Frontier Global Consultants', '040479', ''],
    ['CLEARBANK LIMITED Clearbank 80', '040480', 'CLRBGB22037'],
    ['CLEARBANK LIMITED Clearbank 81', '040481', ''],
    ['VIOLA MONEY (EUROPE) LTD Viola Black', '040482', 'MOUOGB22XXX'],
    ['VIOLA MONEY (EUROPE) LTD Viola Corporate', '040483', ''],
    ['CLEARBANK LIMITED Fast Encash Money Transfer', '040484', 'CLRBGB22039'],
    ['CLEARBANK LIMITED Fast Encash Money Transfer', '040485', ''],
    ['CLEARBANK LIMITED Mir Limited UK Ltd', '040486', 'CLRBGB22040'],
    ['CLEARBANK LIMITED Mir Limited UK Ltd', '040487', ''],
    ['CLEARBANK LIMITED Choice International Ltd', '040490', 'CLRBGB22042'],
    ['CLEARBANK LIMITED Choice International Ltd', '040491', ''],
    ['CLEARBANK LIMITED Clearbank 92', '040492', 'CLRBGB22043'],
    ['CLEARBANK LIMITED Clearbank 93', '040493', ''],
    ['CLEARBANK LIMITED Clearbank 94', '040494', 'CLRBGB22044'],
    ['CLEARBANK LIMITED Clearbank 95', '040495', ''],
    ['CLEARBANK LIMITED Clearbank 96', '040496', 'CLRBGB22045'],
    ['CLEARBANK LIMITED Shieldpay Ltd', '040500', 'CLRBGB22046'],
    ['CLEARBANK LIMITED Shieldpay Ltd', '040501', ''],
    ['CLEARBANK LIMITED Another Ops Ltd', '040502', 'CLRBGB22047'],
    ['CLEARBANK LIMITED Another Ops Ltd', '040503', ''],
    ['TALKREMIT LTD Talkremit Ltd 01', '040504', 'TALKGB22XXX'],
    ['TALKREMIT LTD Talkremit Ltd 02', '040505', ''],
    ['CLEARBANK LIMITED Clearbank Limited', '040506', 'CLRBGB22049'],
    ['CLEARBANK LIMITED Capital Ifx Limited', '040507', ''],
    ['CLEARBANK LIMITED Capital Ifx Limited', '040508', 'CLRBGB22050'],
    ['CLEARBANK LIMITED Settlego Solutions Limited', '040509', ''],
    ['CLEARBANK LIMITED Settlego Solutions Limited', '040510', 'CLRBGB22051'],
    ['CLEARBANK LIMITED Settlego Solns T/A Openpayd', '040511', ''],
    ['CLEARBANK LIMITED Clearbank Ltd 112', '040512', 'CLRBGB22052'],
    ['CLEARBANK LIMITED Clearbank Ltd 113', '040513', ''],
    ['CLEARBANK LIMITED Clearbank 114', '040514', 'CLRBGB22053'],
    ['CLEARBANK LIMITED Clearbank 115', '040515', ''],
    ['CLEARBANK LIMITED Clearbank 116', '040516', 'CLRBGB22054'],
    ['CLEARBANK LIMITED Clearbank 117', '040517', ''],
    ['CLEARBANK LIMITED Clearbank 118', '040518', 'CLRBGB22055'],
    ['CLEARBANK LIMITED Clearbank 119', '040519', ''],
    ['CLEARBANK LIMITED Currenxie UK Limited', '040520', 'CLRBGB22056'],
    ['CLEARBANK LIMITED Currenxie UK Limited', '040521', ''],
    ['CLEARBANK LIMITED Clearbank 122', '040522', 'CLRBGB22057'],
    ['CLEARBANK LIMITED Clearbank 123', '040523', ''],
    ['CLEARBANK LIMITED Clearbank 124', '040524', 'CLRBGB22058'],
    ['CLEARBANK LIMITED Clearbank 125', '040525', ''],
    ['CLEARBANK LIMITED Clearbank 126', '040526', 'CLRBGB22059'],
    ['CLEARBANK LIMITED Clearbank 127', '040527', ''],
    ['CLEARBANK LIMITED Paybase Ltd', '040528', 'CLRBGB22060'],
    ['CLEARBANK LIMITED Paybase Limited', '040529', ''],
    ['CLEARBANK LIMITED Monneo Ltd', '040530', 'CLRBGB22061'],
    ['CLEARBANK LIMITED Monneo Ltd', '040531', ''],
    ['CLEARBANK LIMITED Clearbank 132', '040532', 'CLRBGB22062'],
    ['CLEARBANK LIMITED Clearbank 133', '040533', ''],
    ['CLEARBANK LIMITED Clearbank 138', '040538', 'CLRBGB22065'],
    ['CLEARBANK LIMITED Clearbank 139', '040539', ''],
    ['BCB PAYMENTS LTD Bcb Payments Limited', '040540', 'BCPYGB22XXX'],
    ['BCB PAYMENTS LTD Bcb Payments Limited', '040541', ''],
    ['CLEARBANK LIMITED Clearbank 142', '040542', 'CLRBGB22067'],
    ['CLEARBANK LIMITED Clearbank 143', '040543', ''],
    ['CLEARBANK LIMITED Payen Limited', '040544', 'CLRBGB22068'],
    ['CLEARBANK LIMITED Payen Limited', '040545', ''],
    ['CLEARBANK LIMITED Tide Platform Ltd', '040546', 'CLRBGB22069'],
    ['CLEARBANK LIMITED Tide Platform Ltd', '040547', ''],
    ['CLEARBANK LIMITED Crossbarfx Limited', '040548', 'CLRBGB22070'],
    ['CLEARBANK LIMITED Crossbarfx Limited', '040549', ''],
    ['CLEARBANK LIMITED Financial House Limited', '040550', 'CLRBGB22071'],
    ['CLEARBANK LIMITED Financial House Limited', '040551', ''],
    ['LQID LIMITED Lqid Ltd', '040552', 'LQDDGB22XXX'],
    ['LQID LIMITED Lqid Ltd', '040553', ''],
    ['CLEARBANK LIMITED Clearbank Limited', '040554', 'CLRBGB22073'],
    ['CLEARBANK LIMITED Clearbank Limited', '040555', ''],
    ['CLEARBANK LIMITED Dna Payments Limited', '040558', 'CLRBGB22075'],
    ['CLEARBANK LIMITED Dna Payments Limited', '040559', ''],
    [
      'SAFENETPAY SERVICES COMPANY LTD Safenetpay Services Company',
      '040560',
      'SAEYGB22XXX'
    ],
    [
      'SAFENETPAY SERVICES COMPANY LTD Safenetpay Services Company',
      '040561',
      ''
    ],
    ['CLEARBANK LIMITED Bfc Bank Ltd 1', '040562', 'CLRBGB22077'],
    ['CLEARBANK LIMITED Bfc Bank Ltd 2', '040563', ''],
    ['CLEARBANK LIMITED Tigerwit Limited', '040564', 'CLRBGB22078'],
    ['CLEARBANK LIMITED Tigerwit Limited', '040565', ''],
    ['CLEARBANK LIMITED Clearbank 166', '040566', 'CLRBGB22079'],
    ['CLEARBANK LIMITED Clearbank 167', '040567', ''],
    ['CLEARBANK LIMITED Clearbank 168', '040568', 'CLRBGB22080'],
    ['CLEARBANK LIMITED Contis Financial Services', '040569', ''],
    ['CLEARBANK LIMITED Contis Financial Services', '040570', 'CLRBGB22081'],
    ['CLEARBANK LIMITED Clearbank 171', '040571', ''],
    ['CLEARBANK LIMITED Simplepay London Ltd', '040572', 'CLRBGB22082'],
    ['CLEARBANK LIMITED Simplepay London Ltd', '040573', ''],
    ['CLEARBANK LIMITED Clearbank 174', '040574', 'CLRBGB22083'],
    ['CLEARBANK LIMITED Clearbank 175', '040575', ''],
    ['CLEARBANK LIMITED Cps Transfers Limited', '040576', 'CLRBGB22084'],
    ['CLEARBANK LIMITED Cps Transfers Limited', '040577', ''],
    ['CLEARBANK LIMITED Archax Ltd', '040578', 'CLRBGB22085'],
    ['CLEARBANK LIMITED Archax Ltd', '040579', ''],
    ['CLEARBANK LIMITED Transfergo Ltd', '040580', 'CLRBGB22086'],
    ['CLEARBANK LIMITED Transfergo Ltd', '040581', ''],
    ['CLEARBANK LIMITED Clearbank 184', '040584', 'CLRBGB22088'],
    ['CLEARBANK LIMITED Clearbank 185', '040585', ''],
    ['CLEARBANK LIMITED NHS Credit Union Ltd', '040588', 'CLRBGB22171'],
    ['CLEARBANK LIMITED Clearbank 189', '040589', ''],
    ['CLEARBANK LIMITED Link Fx plc', '040590', 'CLRBGB22091'],
    ['CLEARBANK LIMITED Link Fx plc', '040591', ''],
    ['CLEARBANK LIMITED Clearbank 192', '040592', 'CLRBGB22092'],
    ['CLEARBANK LIMITED Clearbank 193', '040593', ''],
    ['CLEARBANK LIMITED Clearbank Limited', '040594', 'CLRBGB22093'],
    ['CLEARBANK LIMITED Clearbank Limited', '040595', ''],
    ['CLEARBANK LIMITED 3S Money Club Limited', '040596', 'CLRBGB22094'],
    ['CLEARBANK LIMITED 3S Money Club Limited', '040597', ''],
    ['CLEARBANK LIMITED Clearbank 198', '040598', 'CLRBGB22095'],
    ['CLEARBANK LIMITED Clearbank 199', '040599', ''],
    ['CLEARBANK LIMITED Xapo Bank Limited', '040600', 'CLRBGB22096'],
    ['CLEARBANK LIMITED Xapo Bank Limited', '040601', ''],
    ['CLEARBANK LIMITED Clearbank 202', '040602', 'CLRBGB22097'],
    ['CLEARBANK LIMITED Clearbank 203', '040603', ''],
    ['CLEARBANK LIMITED Clearbank 204', '040604', 'CLRBGB22098'],
    ['CLEARBANK LIMITED Tide Platform Ltd', '040605', ''],
    ['CLEARBANK LIMITED Prepay Technologies Ltd', '040606', 'CLRBGB22099'],
    ['CLEARBANK LIMITED Clearbank 207', '040607', ''],
    ['CLEARBANK LIMITED Clearbank Limited', '040608', 'CLRBGB22100'],
    ['CB PAYMENTS LTD Cb Payments Ltd', '040609', ''],
    ['CB PAYMENTS LTD Cb Payments Ltd', '040610', 'CBPAGB22XXX'],
    ['CLEARBANK LIMITED Clearbank 211', '040611', ''],
    ['CLEARBANK LIMITED Clearbank 212', '040612', 'CLRBGB22102'],
    ['CLEARBANK LIMITED Clearbank 213', '040613', 'CLRBGB22127'],
    ['CLEARBANK LIMITED Skrill Limited', '040614', 'CLRBGB22103'],
    ['CLEARBANK LIMITED Skrill Limited', '040615', ''],
    ['CLEARBANK LIMITED Paysafe Financial Services', '040616', 'CLRBGB22104'],
    ['CLEARBANK LIMITED Clearbank 217', '040617', 'CLRBGB22128'],
    ['CLEARBANK LIMITED Clearbank 218', '040618', 'CLRBGB22105'],
    ['CLEARBANK LIMITED Clearbank 219', '040619', ''],
    ['CLEARBANK LIMITED Pagofx UK Limited', '040620', 'CLRBGB22106'],
    ['CLEARBANK LIMITED Clearbank 221', '040621', 'CLRBGB22129'],
    ['CLEARBANK LIMITED Vertofx Ltd', '040622', 'CLRBGB22107'],
    ['CLEARBANK LIMITED Vertofx Ltd', '040623', ''],
    ['CLEARBANK LIMITED Torca Financial Limited', '040624', 'CLRBGB22108'],
    ['CLEARBANK LIMITED Chip Financial Ltd', '040625', 'CLRBGB22130'],
    ['CLEARBANK LIMITED Sedel Capital LLP', '040628', 'CLRBGB22110'],
    ['CLEARBANK LIMITED Sedel Capital LLP', '040629', ''],
    ['CLEARBANK LIMITED Finblocks Limited', '040631', 'CLRBGB22131'],
    ['CLEARBANK LIMITED Oxbury Fs plc', '040632', 'CLRBGB22112'],
    ['CLEARBANK LIMITED Clearbank233', '040633', ''],
    ['CLEARBANK LIMITED Clearbank234', '040634', 'CLRBGB22113'],
    ['CLEARBANK LIMITED Commercial and Northern Ltd', '040635', 'CLRBGB22132'],
    ['CLEARBANK LIMITED Clearbank236', '040636', 'CLRBGB22114'],
    ['CLEARBANK LIMITED Clearbank237', '040637', 'CLRBGB22133'],
    ['CLEARBANK LIMITED Clearbank238', '040638', 'CLRBGB22115'],
    ['CLEARBANK LIMITED Finlab 2000 Limited', '040639', 'CLRBGB22134'],
    ['CLEARBANK LIMITED Settlego Solutions Limited', '040640', 'CLRBGB22116'],
    ['CLEARBANK LIMITED Settlego Solutions Limited', '040641', ''],
    ['CLEARBANK LIMITED Clearbank242', '040642', 'CLRBGB22117'],
    ['CLEARBANK LIMITED Recognise Bank Limited', '040643', 'CLRBGB22135'],
    ['CLEARBANK LIMITED Link Fx', '040645', ''],
    ['PAY PERFORM LIMITED Pay Perform Limited', '040646', 'PAEFGB22XXX'],
    ['CLEARBANK LIMITED Link Fx', '040647', 'CLRBGB22137'],
    ['CLEARBANK LIMITED Clearbank248', '040648', 'CLRBGB22120'],
    ['CLEARBANK LIMITED Lanarkshire Credit Union', '040649', 'CLRBGB22138'],
    ['CLEARBANK LIMITED Clearbank250', '040650', 'CLRBGB22121'],
    ['CLEARBANK LIMITED Recognise Bank Limited', '040651', 'CLRBGB22139'],
    [
      'SENTENIAL LTD (TRADING AS NUAPAY) Sentenial Ltd T/A Nuapay',
      '040652',
      'SELNGB22XXX'
    ],
    ['CLEARBANK LIMITED Jnfx Limited', '040653', 'CLRBGB22140'],
    ['CLEARBANK LIMITED Magna Financial Limited', '040654', 'CLRBGB22123'],
    ['CLEARBANK LIMITED Clearbank255', '040655', 'CLRBGB22141'],
    ['CLEARBANK LIMITED Chip Financial Ltd', '040656', 'CLRBGB22124'],
    ['CLEARBANK LIMITED Chip Financial Ltd', '040657', 'CLRBGB22142'],
    ['CLEARBANK LIMITED Fgc', '040658', 'CLRBGB22125'],
    ['CLEARBANK LIMITED Clearbank259', '040659', 'CLRBGB22143'],
    ['CLEARBANK LIMITED Sync.Money UK Ltd', '040660', 'CLRBGB22126'],
    ['CLEARBANK LIMITED Clearbank261', '040661', 'CLRBGB22144'],
    ['CLEARBANK LIMITED Clearbank262', '040662', 'CLRBGB22145'],
    ['CLEARBANK LIMITED Pivotal Merchant', '040663', 'CLRBGB22146'],
    ['CLEARBANK LIMITED Hoot Credit Union', '040665', 'CLRBGB22148'],
    ['CLEARBANK LIMITED Clearbank266', '040666', 'CLRBGB22149'],
    ['CLEARBANK LIMITED Fxguard', '040667', 'CLRBGB22150'],
    ['CLEARBANK LIMITED Scottish Police Credit Unio', '040668', 'CLRBGB22151'],
    ['CLEARBANK LIMITED Teapayment Ltd', '040669', 'CLRBGB22152'],
    ['CLEARBANK LIMITED Aave Limited', '040670', 'CLRBGB22153'],
    ['CLEARBANK LIMITED Partners Credit Union Ltd', '040671', 'CLRBGB22154'],
    ['CLEARBANK LIMITED Clearbank272', '040672', 'CLRBGB22155'],
    ['CLEARBANK LIMITED Cu For SE Northumberland', '040673', 'CLRBGB22156'],
    ['CLEARBANK LIMITED Manchester Credit Union Ltd', '040674', 'CLRBGB22157'],
    ['CLEARBANK LIMITED Edex Payments Limited', '040675', 'CLRBGB22158'],
    ['CLEARBANK LIMITED Vertofx Limited', '040676', 'CLRBGB22159'],
    ['CLEARBANK LIMITED Gemini Europe Ltd', '040677', 'CLRBGB22160'],
    ['TRUELAYER LIMITED Truelayer Limited', '040678', 'TRYEGB22XXX'],
    ['CLEARBANK LIMITED Openpayd Fin Svs Malta Ltd', '040679', 'CLRBGB22162'],
    ['CLEARBANK LIMITED Nium Fintech Limited', '040680', 'CLRBGB22163'],
    ['CLEARBANK LIMITED Incuto Limited', '040681', 'CLRBGB22164'],
    ['CLEARBANK LIMITED Fintecom Sp. Z O.O.', '040682', 'CLRBGB22165'],
    ['CLEARBANK LIMITED Transact Payments Limited', '040683', 'CLRBGB22166'],
    ['CLEARBANK LIMITED Purple Eye Ltd', '040684', 'CLRBGB22167'],
    ['CLEARBANK LIMITED Clearbank285', '040685', 'CLRBGB22168'],
    ['CLEARBANK LIMITED Aftab Currency Exchange Ltd', '040686', 'CLRBGB22169'],
    ['CLEARBANK LIMITED Clearbank287', '040687', 'CLRBGB22170'],
    ['CLEARBANK LIMITED Choice Forex Limited&#8201;', '040688', 'CLRBGB22090'],
    ['CLEARBANK LIMITED Northern Provident', '040689', 'CLRBGB22172'],
    ['CLEARBANK LIMITED Leatherback Ltd', '040691', 'CLRBGB22174'],
    ['CLEARBANK LIMITED Clearbank292', '040692', 'CLRBGB22175'],
    ['CLEARBANK LIMITED Gcc Exchange UK Ltd', '040693', 'CLRBGB22176'],
    ['CLEARBANK LIMITED Global Currency', '040694', 'CLRBGB22177'],
    ['CLEARBANK LIMITED Clearbank295', '040695', 'CLRBGB22178'],
    ['CLEARBANK LIMITED Alico (UK) Limited', '040696', 'CLRBGB22179'],
    ['CLEARBANK LIMITED 1st Class Credit Union Ltd', '040697', 'CLRBGB22180'],
    ['CLEARBANK LIMITED Fund Ourselves Ltd', '040698', 'CLRBGB22181'],
    ['CLEARBANK LIMITED Iremit Global Remittance Lt', '040699', 'CLRBGB22182'],
    ['CLEARBANK LIMITED Wecollect (London) Limited', '040700', 'CLRBGB22183'],
    ['CLEARBANK LIMITED Am-Mn Holdings Limited', '040701', 'CLRBGB22184'],
    ['CLEARBANK LIMITED The Model T Finance Company', '040702', 'CLRBGB22185'],
    ['CLEARBANK LIMITED Foenix Partners Limited', '040703', 'CLRBGB22186'],
    ['CLEARBANK LIMITED Axius Global Ltd', '040704', 'CLRBGB22187'],
    ['CLEARBANK LIMITED Clearbank 0705', '040705', 'CLRBGB22188'],
    ['OMEGA BAAP LIMITED Omega Baap Limited', '040706', 'OMBPGB22XXX'],
    ['CLEARBANK LIMITED Syncom (UK) Limited', '040707', 'CLRBGB22190'],
    ['KROO LTD Kroo Ltd', '040709', 'KROOGB22XXX'],
    ['CLEARBANK LIMITED Brac Saajan Exchange Ltd', '040710', 'CLRBGB22193'],
    ['CLEARBANK LIMITED Clearbank 0711', '040711', 'CLRBGB22194'],
    ['CLEARBANK LIMITED Altras International Ltd', '040712', 'CLRBGB22195'],
    ['CLEARBANK LIMITED W1tty Global Ltd', '040713', 'CLRBGB22196'],
    ['CLEARBANK LIMITED Lycamoney Financial Ltd', '040714', 'CLRBGB22197'],
    ['CLEARBANK LIMITED Moneyto Limited', '040715', 'CLRBGB22198'],
    ['CLEARBANK LIMITED Clearbank 0716', '040716', 'CLRBGB22199'],
    ['CLEARBANK LIMITED Clearbank 0717', '040717', 'CLRBGB22200'],
    ['CLEARBANK LIMITED Clearbank 0718', '040718', 'CLRBGB22201'],
    ['CLEARBANK LIMITED Clearbank 0719', '040719', 'CLRBGB22202'],
    ['CLEARBANK LIMITED Clearbank 0720', '040720', 'CLRBGB22203'],
    ['ACE UNION LIMITED Ace Union Limited', '040721', 'ACUIGB22XXX'],
    ['FROST MONEY LTD Frost Money Ltd', '040722', 'FRMEGB22XXX'],
    ['CLEARBANK LIMITED Nowpay Limited', '040723', 'CLRBGB22206'],
    ['CLEARBANK LIMITED Clearbank 0724', '040724', 'CLRBGB22207'],
    ['CLEARBANK LIMITED Etoro (UK) Limited', '040725', 'CLRBGB22208'],
    ['CLEARBANK LIMITED Paypoint Payment Services', '040726', 'CLRBGB22209'],
    ['CLEARBANK LIMITED Dt&T Corporation Limited', '040727', 'CLRBGB22210'],
    ['CLEARBANK LIMITED Trustly Group Ab', '040728', 'CLRBGB22211'],
    ['CLEARBANK LIMITED Cb Group Development Ltd', '040729', 'CLRBGB22212'],
    ['CLEARBANK LIMITED Clearbank 0730', '040730', 'CLRBGB22213'],
    ['CLEARBANK LIMITED Clearbank 0731', '040731', 'CLRBGB22214'],
    ['CLEARBANK LIMITED Clearbank 0732', '040732', 'CLRBGB22215'],
    ['CLEARBANK LIMITED Safecharge Limited', '040733', 'CLRBGB22216'],
    ['CLEARBANK LIMITED Clearbank 0734', '040734', 'CLRBGB22217'],
    ['CLEARBANK LIMITED Greyfoxx Limited', '040735', 'CLRBGB22218'],
    ['CLEARBANK LIMITED Clearbank 0736', '040736', 'CLRBGB22219'],
    ['CLEARBANK LIMITED Hce Service Limited', '040737', 'CLRBGB22220'],
    ['CLEARBANK LIMITED Paynetics Ad', '040738', 'CLRBGB22221'],
    ['CLEARBANK LIMITED Clearbank 0739', '040739', 'CLRBGB22222'],
    ['CLEARBANK LIMITED Clearbank 0740', '040740', 'CLRBGB22223'],
    ['CLEARBANK LIMITED Clearbank 0741', '040741', 'CLRBGB22224'],
    ['CLEARBANK LIMITED Prepaid Services Company', '040742', 'CLRBGB22225'],
    ['CLEARBANK LIMITED Clearbank 0743', '040743', 'CLRBGB22226'],
    ['CLEARBANK LIMITED Clearbank 0744', '040744', 'CLRBGB22227'],
    ['CLEARBANK LIMITED Authpay Limited', '040745', 'CLRBGB22228'],
    ['CLEARBANK LIMITED Squareup Europe Ltd', '040746', 'CLRBGB22229'],
    ['CLEARBANK LIMITED Clearbank 0747', '040747', 'CLRBGB22230'],
    ['CLEARBANK LIMITED The Payment Firm Ltd', '040748', 'CLRBGB22231'],
    ['CLEARBANK LIMITED Clearbank 0749', '040749', 'CLRBGB22232'],
    ['CLEARBANK LIMITED Clearbank 0750', '040750', 'CLRBGB22233'],
    ['CLEARBANK LIMITED Clearbank 0751', '040751', 'CLRBGB22234'],
    ['CLEARBANK LIMITED Clearbank 0752', '040752', 'CLRBGB22235'],
    ['CLEARBANK LIMITED Clearbank 0753', '040753', 'CLRBGB22236'],
    ['CLEARBANK LIMITED Psi-Pay Ltd', '040754', 'CLRBGB22237'],
    ['CLEARBANK LIMITED Clearbank 0755', '040755', 'CLRBGB22238'],
    ['CLEARBANK LIMITED Clearbank 0756', '040756', 'CLRBGB22239'],
    ['CLEARBANK LIMITED Andaria Financial Services', '040757', 'CLRBGB22240'],
    ['CLEARBANK LIMITED Bazar Money Transfer Ltd', '040758', 'CLRBGB22241'],
    ['CLEARBANK LIMITED Clearbank 0759', '040759', 'CLRBGB22242'],
    ['CLEARBANK LIMITED Clearbank 0760', '040760', 'CLRBGB22243'],
    ['CLEARBANK LIMITED Clearbank 0761', '040761', 'CLRBGB22244'],
    ['CLEARBANK LIMITED Clearbank 0762', '040762', 'CLRBGB22245'],
    ['CLEARBANK LIMITED Clearbank 0763', '040763', 'CLRBGB22246'],
    ['CLEARBANK LIMITED Clearbank 0764', '040764', 'CLRBGB22247'],
    ['CLEARBANK LIMITED Clearbank 0765', '040765', 'CLRBGB22248'],
    ['CLEARBANK LIMITED Clearbank 0766', '040766', 'CLRBGB22249'],
    ['CLEARBANK LIMITED Clearbank 0767', '040767', 'CLRBGB22250'],
    ['CLEARBANK LIMITED Clearbank 0768', '040768', 'CLRBGB22251'],
    ['CLEARBANK LIMITED Clearbank 0769', '040769', 'CLRBGB22252'],
    ['CLEARBANK LIMITED Clearbank 0770', '040770', 'CLRBGB22253'],
    ['CLEARBANK LIMITED Clearbank 0771', '040771', 'CLRBGB22254'],
    ['CLEARBANK LIMITED Clearbank 0772', '040772', 'CLRBGB22255'],
    ['CLEARBANK LIMITED Clearbank 0773', '040773', 'CLRBGB22256'],
    ['CLEARBANK LIMITED Clearbank 0774', '040774', 'CLRBGB22257'],
    ['CLEARBANK LIMITED Clearbank 0775', '040775', 'CLRBGB22258'],
    ['CLEARBANK LIMITED Clearbank 0776', '040776', 'CLRBGB22259'],
    ['CLEARBANK LIMITED Clearbank 0777', '040777', 'CLRBGB22260'],
    [
      'MIDPOINT & TRANSFER LTD Midpoint & Transfer Ltd',
      '041300',
      'MITFGB21XXX'
    ],
    ['MIDPOINT & TRANSFER LTD Midpoint & Transfer Ltd', '041301', ''],
    ['BILDERLINGS PAY LTD Bilderlings Pay Limited', '041302', 'BIYSGB2LXXX'],
    ['BILDERLINGS PAY LTD Bilderlings Pay Limited', '041303', ''],
    [
      'ECOLOGY BUILDING SOCIETY Ecology Building Society',
      '041304',
      'ECOLGB21XXX'
    ],
    ['ECOLOGY BUILDING SOCIETY Ecology Building Society', '041305', ''],
    ['ALLPAY LIMITED Allpay Limited', '041306', 'ALYPGB21XXX'],
    ['CLEAR JUNCTION LIMITED Clearjunction Ltd', '041307', 'CLJUGB21XXX'],
    ['CLEAR JUNCTION LIMITED Clearjunction Ltd 1', '041308', ''],
    ['MODULR FS LIMITED Modulr', '041312', 'MODRGB21XXX'],
    [
      'DOZENS (A TRADING NAME OF PROJECT IMAGINE LTD) Project Imagine Ltd',
      '041313',
      'POIAGB21XXX'
    ],
    ['DOZENS (A TRADING NAME OF PROJECT IMAGINE LTD) Dozens', '041314', ''],
    [
      'UNIVERSAL SECURITIES AND INVESTMENT LTD Universal Securities & Inv.',
      '041315',
      'UNENGB21XXX'
    ],
    [
      'UNIVERSAL SECURITIES AND INVESTMENT LTD Universal Securities & Inv.',
      '041316',
      ''
    ],
    [
      'CONTIS FINANCIAL SERVICES LIMITED Contis Financial Services L',
      '041317',
      ''
    ],
    [
      'CONTIS FINANCIAL SERVICES LIMITED Contis Financial Services L',
      '041318',
      ''
    ],
    [
      'CONTIS FINANCIAL SERVICES LIMITED Contis Financial Services L',
      '041319',
      ''
    ],
    ['SAESCADA LIMITED Saescada Ltd 1', '041320', 'SEEDGB21XXX'],
    ['SAESCADA LIMITED Saescada Ltd 2', '041321', ''],
    ['OAKNORTH BANK PLC Oaknorth Bank plc 1', '041322', ''],
    ['OAKNORTH BANK PLC Oaknorth Bank plc 2', '041323', ''],
    ['VIOLA MONEY (EUROPE) LTD Viola Absolute', '041326', ''],
    ['VIOLA MONEY (EUROPE) LTD Viola Pay', '041327', ''],
    [
      'EURO EXCHANGE SECURITIES UK LIMITED Euro Exchange Securities UK',
      '041330',
      ''
    ],
    [
      'EURO EXCHANGE SECURITIES UK LIMITED Euro Exchange Securities UK',
      '041331',
      ''
    ],
    ['JN BANK UK LIMITED Jn Bank UK Ltd', '041332', 'JANAGB22XXX'],
    ['JN BANK UK LIMITED Jn Bank UK Ltd', '041333', ''],
    ['ETORO MONEY UK LIMITED Etoro Money UK Limited', '041334', 'MRMIGB22XXX'],
    ['ETORO MONEY UK LIMITED Etoro Money UK Limited', '041335', ''],
    ['DUESDAY LTD Duesday Limited', '041342', 'CFGUGB22XXX'],
    ['WIRECARD CARD SOLUTIONS LTD Wirecard Card Solutions Ltd', '041343', ''],
    ['WIRECARD CARD SOLUTIONS LTD Wirecard Card Solutions Ltd', '041344', ''],
    ['NVAYO LIMITED Nvayo Limited', '041345', 'NVAYGB21XXX'],
    ['OXBURY BANK PLC Oxbury Bank plc', '041346', 'OXBRGB22XXX'],
    [
      'GHANA INTERNATIONAL BANK PLC Ghana International Bank',
      '041347',
      'GIRHGB22XXX'
    ],
    ['TRUEVO PAYMENTS LTD Truevo Payments Ltd', '041348', 'TRPEMTM1XXX'],
    ['CASHDASH UK LIMITED Cashdash UK Limited', '041349', 'CAHDGB21XXX'],
    ['BLACKTHORN FINANCE LTD Blackthorn Finance Ltd', '041350', 'BLFNGB21XXX'],
    ['XACE LIMITED Xace Limited', '041351', 'ACEIGB22XXX'],
    ['OXBURY BANK PLC Oxbury Bank plc', '041352', ''],
    ['ECOMMBX LIMITED Ecommbx Limited', '041353', 'ECBXGB22XXX'],
    ['BABB REMIT LTD Babb Remit Ltd', '041354', 'BBRMGB22XXX'],
    ['DYNAPAY LIMITED Dynapay Limited', '041355', 'DNNAGB22XXX'],
    ['GUAVAPAY LIMITED Guavapay Limited', '041356', 'GUAVGB2AXXX'],
    ['CREDIS ACC LTD Credis Acc Ltd', '041357', 'RRCCGB22XXX'],
    [
      'GLOBAL EXCHANGE LIMITED Global Exchange Limited',
      '041358',
      'GLEXGB22XXX'
    ],
    [
      'OPTIMUS CARDS UK LIMITED Optimus Cards UK Limited',
      '041359',
      'OPCDGB22XXX'
    ],
    ['VIVA PAYMENTS SERVICES S.A. Viva Payment Services S.A.', '041360', ''],
    ['BLABLA CONNECT LIMITED Blabla Connect Limited', '041361', ''],
    ['FONDY LIMITED Fondy Limited', '041362', 'FONYGB22XXX'],
    [
      'RATIONAL FOREIGN EXCHANGE LIMITED Rational Foreign Exchange',
      '041363',
      'RFXLGB22XXX'
    ],
    ['PREMFINA LIMITED Premfina Limited', '041364', 'PRNFGB22XXX'],
    ['KIYANPAY LTD Kiyanpay Ltd', '041365', 'KIYAGB22XXX'],
    [
      'VIVA PAYMENTS SERVICES S.A. Viva Payment Services S.A.',
      '041366',
      'VPAYGRA3XXX'
    ],
    ['MONUMENT BANK LIMITED Monument Bank Ltd', '041367', 'MNMTGB22XXX'],
    ['BANK OF LONDON AND THE MIDDLE EAST PLC Blme', '041368', 'BLMEGB22XXX'],
    ['FCMB BANK (UK) LIMITED Fcmb Bank (UK) Limited', '041369', 'FCMBGB22XXX'],
    ['CLEAR JUNCTION LIMITED Clearjunction Limted', '041370', ''],
    ['PRIVALGO LIMITED Privalgo Limited', '041371', 'PRGVGB22XXX'],
    [
      'IFIC MONEY TRANSFER (UK) LIMITED Ific Money Transfer UK Ltd',
      '041372',
      'IFMRGB22XXX'
    ],
    ['TANGOPAY LIMITED Tangopay Limited', '041373', 'TANOGB22XXX'],
    ['SWAPMONEY LTD Swapmoney Ltd', '041374', 'SWAEGB22XXX'],
    ['PRIVAT 3 MONEY LTD Privat 3 Money', '041375', ''],
    ['ALLICA BANK LIMITED Allica Bank Limited', '041376', 'CIIVGB2AXXX'],
    ['CREDIS ACC LTD Credis Acc Ltd', '041377', ''],
    ['MONAVATE LIMITED Monavate Limited', '041378', ''],
    ['NOVATUM TECH LIMITED Novatum Tech Limited', '041379', 'NOEHGB22XXX'],
    ['TOMPAY LIMITED Tompay Limited', '041380', 'TOMPGB22XXX'],
    ['ZIGLU LIMITED Ziglu Limited', '041388', 'ZIGLGB22XXX'],
    ['WEALTHKERNEL LTD Wealthkernel Ltd', '041390', 'WELKGB22XXX'],
    ['STARLING BANK LIMITED Goji Financial Services Ltd', '041400', ''],
    ['STARLING BANK LIMITED Starling Bank 13', '041401', ''],
    ['THE CURRENCY CLOUD LIMITED Currency Cloud', '041404', ''],
    ['PAYRNET LIMITED Bofin', '041405', ''],
    ['VIVE (A TRADING NAME OF GH BANK LIMITED) Vive', '041406', ''],
    ['CLOCKWISE CREDIT UNION LIMITED Clockwise Credit Union', '041407', ''],
    ['OMNIO EMI LIMITED Vox Money', '041408', ''],
    ['OMNIO EMI LIMITED Monmouthshire Building Soc', '041409', ''],
    ['STARLING BANK LIMITED Moneybox', '041410', ''],
    ['STARLING BANK LIMITED Starling Ff', '041899', ''],
    ['CLEARBANK LIMITED Clearbank Limited', '041901', ''],
    ['CLEARBANK LIMITED Vertofx Ltd', '042701', ''],
    ['CLEARBANK LIMITED Freemarketfx Limited', '042702', ''],
    ['PAY PERFORM LIMITED Pay Perform Limited', '042704', ''],
    ['BLACKTHORN FINANCE LTD Blackthorn Finance Ltd', '042706', ''],
    ['MODULR FS LIMITED Intuit Limited', '042901', ''],
    ['MODULR FS LIMITED Wirex', '042902', ''],
    ['MODULR FS LIMITED Novatum Tech Limited', '042903', ''],
    ['MODULR FS LIMITED Modulr Fs Limited', '042904', ''],
    ['MODULR FS LIMITED Hodge Bank', '042905', ''],
    ['JP MORGAN CHASE BANK, N.A. Jpmorgan Chase Bk', '042920', ''],
    ['PAYRNET LIMITED Railsbank 32', '042927', ''],
    ['PAYRNET LIMITED Railsbank 33', '042928', ''],
    ['PAYRNET LIMITED Railsbank 34', '042929', ''],
    ['PAYRNET LIMITED Railsbank 35', '042930', ''],
    ['PAYRNET LIMITED A & B General (UK) Limited', '042931', ''],
    ['PAYRNET LIMITED Railsbank 37', '042932', ''],
    ['PAYRNET LIMITED Railsbank 38', '042933', ''],
    ['PAYRNET LIMITED Railsbank 39', '042934', ''],
    ['PAYRNET LIMITED Railsbank 40', '042935', ''],
    ['PAYRNET LIMITED Railsbank 41', '042936', ''],
    ['PAYRNET LIMITED Railsbank 42', '042937', ''],
    ['PAYRNET LIMITED Railsbank 43', '042938', ''],
    ['PAYRNET LIMITED Railsbank 44', '042939', ''],
    ['PAYRNET LIMITED Railsbank 45', '042940', ''],
    ['PAYRNET LIMITED Railsbank 46', '042941', ''],
    ['PAYRNET LIMITED Railsbank 47', '042942', ''],
    ['PAYRNET LIMITED Railsbank 48', '042943', ''],
    ['PAYRNET LIMITED Railsbank 49', '042944', ''],
    ['PAYRNET LIMITED Railsbank 50', '042945', ''],
    ['PAYRNET LIMITED Railsbank 51', '042946', ''],
    ['PAYRNET LIMITED Railsbank 52', '042947', ''],
    ['PAYRNET LIMITED Railsbank 53', '042948', ''],
    ['PAYRNET LIMITED Railsbank 54', '042949', ''],
    ['PAYRNET LIMITED Railsbank 55', '042950', ''],
    ['PAYRNET LIMITED Railsbank 56', '042951', ''],
    ['PAYRNET LIMITED Railsbank 57', '042952', ''],
    ['PAYRNET LIMITED Railsbank 58', '042953', ''],
    ['PAYRNET LIMITED Railsbank 59', '042954', ''],
    ['PAYRNET LIMITED Railsbank 60', '042955', ''],
    ['PAYRNET LIMITED Railsbank 61', '042956', ''],
    ['GOLDMAN SACHS BANK USA Gs Bank USA London', '042962', 'GSLDGB2LXXX'],
    ['GOLDMAN SACHS BANK USA Gs Bank USA London', '042963', 'GSLDGB2LRTN'],
    ['ATOM  BANK  PLC Atom Bank plc', '044000', ''],
    ['ATOM  BANK  PLC Atom Bank', '044001', ''],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) C&Cp Customer Delivery',
      '050001',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) H/O Instalment Loans',
      '050002',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Human Resources/Ybhl',
      '050003',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Asset Finance Department',
      '050004',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Direct Banking Yb',
      '050005',
      'YORKGB21005'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Financial Care Team',
      '050006',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Home Loans',
      '050007',
      'YORKGB21007'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) UK Finance Other Items A/C',
      '050008',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Payment Operations Leeds',
      '050010',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cash Centre',
      '050012',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Y B Custom Card',
      '050013',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Visa Services Division',
      '050014',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cb plc(T/A Cb/Yb Asset Fin)',
      '050015',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Post Office',
      '050016',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Telephone Banking',
      '050017',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Central',
      '050020',
      'YORKGB21020'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Payment Operations Yb',
      '050021',
      'YORKGB22XXX'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Direct Account Unit',
      '050022',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Business Banking',
      '050024',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Armley',
      '050030',
      'YORKGB21030'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Direct Banking Mortgages Yb',
      '050031',
      'YORKGB21031'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bramley Leeds',
      '050035',
      'YORKGB21035'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Chapel Allerton',
      '050040',
      'YORKGB21040'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Crossgates',
      '050050',
      'YORKGB21050'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Dewsbury Road',
      '050055',
      'YORKGB21055'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Merrion Centre',
      '050058',
      'YORKGB21058'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Guiseley',
      '050060',
      'YORKGB21060'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Harehills Lane',
      '050064',
      'YORKGB21064'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Harehills Lane Hp',
      '050065',
      'YORKGB21065'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Harehills Road Hd',
      '050070',
      'YORKGB21070'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Headingley',
      '050075',
      'YORKGB21075'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds City',
      '050080',
      'YORKGB21080'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Horsforth',
      '050085',
      'YORKGB21085'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Hunslet',
      '050090',
      'YORKGB21090'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Gold Card Services',
      '050095',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Ccu Retail Services',
      '050097',
      'YORKGB21097'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Payment Ops Yb',
      '050098',
      'YORKGB21098'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Kirkgate',
      '050106',
      'YORKGB21106'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Woodsley Rd Kd',
      '050111',
      'YORKGB21111'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Kirkgate  Market',
      '050116',
      'YORKGB21116'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Meanwood',
      '050121',
      'YORKGB21121'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Middleton',
      '050123',
      'YORKGB21123'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Moortown',
      '050126',
      'YORKGB21126'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Morley Leeds',
      '050131',
      'YORKGB21131'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Armley New Wortley',
      '050136',
      'YORKGB21136'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Head Office Yb Direct Cash',
      '050141',
      'YORKGB21141'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Seacroft',
      '050150',
      'YORKGB21150'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Moortown Street Lane',
      '050156',
      'YORKGB21156'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Woodsley Road',
      '050161',
      'YORKGB21161'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds Yeadon',
      '050166',
      'YORKGB21166'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds York Road',
      '050171',
      'YORKGB21171'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Aberdeen Principal Yb',
      '050172',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Aberdeen St Nicholas',
      '050173',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Airdrie',
      '050174',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Ayr Alloway Street',
      '050175',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Baillieston',
      '050176',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Banchory',
      '050177',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Broughty Ferry',
      '050178',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Campbeltown Yb',
      '050179',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Carlisle Yb',
      '050180',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Clydebank Central',
      '050181',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cumbernauld',
      '050182',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Dumfries High Street',
      '050183',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) High Street Dundee',
      '050184',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Dunfermline',
      '050185',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) East Kilbride Princes Sq',
      '050186',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Edinburgh Yb',
      '050187',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Elgin',
      '050188',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Ellon',
      '050189',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Falkirk',
      '050190',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Fort William',
      '050191',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Galashiels',
      '050192',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Glasgow Head Office',
      '050193',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Glasgow Hillhead',
      '050194',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Glasgow Shawlands',
      '050195',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Glenrothes Yb',
      '050196',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Greenock West End',
      '050197',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hamilton Almada Street',
      '050198',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Inverness Academy St',
      '050199',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) London Piccadilly Circus',
      '050200',
      'YORKGB21200'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Abingdon',
      '050205',
      'YORKGB21205'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Alfreton',
      '050211',
      'YORKGB21211'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Accrington',
      '050212',
      'YORKGB21212'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Altrincham',
      '050213',
      'YORKGB21213'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Ashton under Lyne',
      '050214',
      'YORKGB21214'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Banbury',
      '050216',
      'YORKGB21216'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Barnard Castle',
      '050217',
      'YORKGB21217'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Barnoldswick',
      '050222',
      'YORKGB21222'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Barnsley  Market Hill',
      '050227',
      'YORKGB21227'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) New Street  Barnsley',
      '050229',
      'YORKGB21229'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Barnsley',
      '050230',
      'YORKGB21230'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Batley',
      '050232',
      'YORKGB21232'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bedford',
      '050235',
      'YORKGB21235'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Beverley',
      '050237',
      'YORKGB21237'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Billingham',
      '050240',
      'YORKGB21240'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bingley',
      '050242',
      'YORKGB21242'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Birkenhead',
      '050245',
      'YORKGB21245'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 2',
      '050246',
      'YORKGB21246'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bishop Auckland',
      '050247',
      'YORKGB21247'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Blackburn',
      '050252',
      'YORKGB21252'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Blackpool Abingdon St',
      '050257',
      'YORKGB21257'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Blackpool Central Drive',
      '050262',
      'YORKGB21262'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cleveleys',
      '050263',
      'YORKGB21263'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Blackpool Layton',
      '050267',
      'YORKGB21267'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Blackpool Abingdon St (SS)',
      '050272',
      'YORKGB21272'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Blackpool Abingdon St (Wjd)',
      '050274',
      'YORKGB21274'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bolton',
      '050277',
      'YORKGB21277'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bradford',
      '050303',
      'YORKGB21303'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bradford North Parade',
      '050308',
      'YORKGB21308'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bradford Great Horton',
      '050313',
      'YORKGB21313'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bradford Idle',
      '050318',
      'YORKGB21318'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bradford Laisterdyke',
      '050323',
      'YORKGB21323'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bradford Otley Road',
      '050328',
      'YORKGB21328'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Toller Lane Bradford',
      '050333',
      'YORKGB21333'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bradford West Bowling',
      '050338',
      'YORKGB21338'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bridlington',
      '050343',
      'YORKGB21343'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Brighouse',
      '050348',
      'YORKGB21348'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 1',
      '050349',
      'YORKGB21349'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bromsgrove',
      '050350',
      'YORKGB21350'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Burnley',
      '050353',
      'YORKGB21353'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Burton upon Trent',
      '050354',
      'YORKGB21354'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bury',
      '050355',
      'YORKGB21355'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Peterborough  Cambridge',
      '050356',
      'YORKGB21356'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cannock',
      '050357',
      'YORKGB21357'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Castleford',
      '050358',
      'YORKGB21358'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Chadderton',
      '050363',
      'YORKGB21363'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cheltenham Yb',
      '050364',
      'YORKGB21364'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Chester',
      '050366',
      'YORKGB21366'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Chesterfield',
      '050368',
      'YORKGB21368'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Chorley',
      '050370',
      'YORKGB21370'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cleckheaton',
      '050373',
      'YORKGB21373'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Clitheroe',
      '050378',
      'YORKGB21378'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Coalville',
      '050381',
      'YORKGB21381'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Colne',
      '050383',
      'YORKGB21383'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kettering Corby',
      '050385',
      'YORKGB21385'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Coventry',
      '050387',
      'YORKGB21387'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Crosshills',
      '050388',
      'YORKGB21388'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) 41 Victoria St Crewe',
      '050389',
      'YORKGB21389'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 4',
      '050390',
      'YORKGB21390'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Darlington',
      '050404',
      'YORKGB21404'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Derby',
      '050407',
      'YORKGB21407'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Dewsbury',
      '050409',
      'YORKGB21409'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) St.Sepulchre Gate,Doncaster',
      '050414',
      'YORKGB21414'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) St Sepulchre Gate (Linked)',
      '050416',
      'YORKGB21416'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Driffield',
      '050419',
      'YORKGB21419'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Dudley',
      '050421',
      'YORKGB21421'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Elland',
      '050424',
      'YORKGB21424'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Ellesmere Port',
      '050425',
      'YORKGB21425'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Eston Middlesbrough',
      '050426',
      'YORKGB21426'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Enfield',
      '050427',
      'YORKGB21427'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Featherstone',
      '050429',
      'YORKGB21429'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Filey',
      '050434',
      'YORKGB21434'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 3',
      '050435',
      'YORKGB21435'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Gateshead',
      '050436',
      'YORKGB21436'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Gloucester',
      '050437',
      'YORKGB21437'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Goldthorpe Rotherham',
      '050438',
      'YORKGB21438'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Goole',
      '050439',
      'YORKGB21439'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Grantham',
      '050442',
      'YORKGB21442'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Grimsby Cleethorpe Rd',
      '050444',
      'YORKGB21444'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bethlehem Street Grimsby',
      '050445',
      'YORKGB21445'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Guisborough',
      '050446',
      'YORKGB21446'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Halifax',
      '050449',
      'YORKGB21449'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Merry Hill',
      '050451',
      'YORKGB21451'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hanley',
      '050452',
      'YORKGB21452'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Harrogate',
      '050454',
      'YORKGB21454'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hebden Bridge',
      '050459',
      'YORKGB21459'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Heckmondwike',
      '050460',
      'YORKGB21460'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hemel Hempstead',
      '050461',
      'YORKGB21461'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kirkbymoorside (Linked)',
      '050462',
      'YORKGB21462'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hereford',
      '050463',
      'YORKGB21463'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Heywood',
      '050464',
      'YORKGB21464'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hinckley',
      '050465',
      'YORKGB21465'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hitchin',
      '050466',
      'YORKGB21466'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Holmfirth',
      '050467',
      'YORKGB21467'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hucknall',
      '050468',
      'YORKGB21468'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Huddersfield',
      '050469',
      'YORKGB21469'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) High Wycombe',
      '050470',
      'YORKGB21470'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Inverurie',
      '050471',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Irvine High Street',
      '050472',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Keith',
      '050473',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kilmarnock Foregate',
      '050474',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kirkintilloch Yb',
      '050475',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kirkwall',
      '050476',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Lerwick',
      '050477',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Livingston',
      '050478',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Lochgilphead',
      '050479',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Milngavie',
      '050480',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Montrose',
      '050481',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Motherwell',
      '050482',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Musselburgh',
      '050483',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Glasgow  Mearns Cross Yb',
      '050484',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Newton Stewart',
      '050485',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Oban Yb',
      '050486',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Paisley Dunn Square',
      '050487',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Perth',
      '050488',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Peterhead',
      '050489',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Portree Yb',
      '050490',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Rutherglen',
      '050491',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stenhousemuir Yb',
      '050492',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stirling Yb',
      '050493',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stornoway',
      '050494',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Tobermory',
      '050495',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Turriff',
      '050496',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Wick',
      '050497',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Glasgow (Lounge) Yb',
      '050498',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Golders Green',
      '050499',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) London Haymarket',
      '050500',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Wolverhampton',
      '050502',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hull Central Qvs',
      '050505',
      'YORKGB21505'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bransholme Hull',
      '050508',
      'YORKGB21508'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hull Central Beverley Road',
      '050510',
      'YORKGB21510'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hull Hessle Road',
      '050515',
      'YORKGB21515'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hull Holderness Road',
      '050520',
      'YORKGB21520'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hull Princes Avenue',
      '050525',
      'YORKGB21525'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hull Central',
      '050527',
      'YORKGB21527'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hyde',
      '050529',
      'YORKGB21529'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Ilkley',
      '050530',
      'YORKGB21530'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) 88 To 92 Bath St. Ilkeston',
      '050531',
      'YORKGB21531'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Immingham',
      '050532',
      'YORKGB21532'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Peterborough Ipswich',
      '050534',
      'YORKGB21534'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Keighley',
      '050535',
      'YORKGB21535'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kendal',
      '050540',
      'YORKGB21540'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kettering',
      '050542',
      'YORKGB21542'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Pickering Kirkbymoorside',
      '050543',
      'YORKGB21543'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kidderminster',
      '050544',
      'YORKGB21544'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Knaresborough',
      '050545',
      'YORKGB21545'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kings Lynn',
      '050546',
      'YORKGB21546'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Knottingley',
      '050550',
      'YORKGB21550'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Link / Switch Help Desk',
      '050551',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Lancaster',
      '050555',
      'YORKGB21555'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leamington Spa',
      '050558',
      'YORKGB21558'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leicester',
      '050560',
      'YORKGB21560'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leicester Fsc',
      '050561',
      'YORKGB21561'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leigh',
      '050562',
      'YORKGB21562'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Lincoln',
      '050563',
      'YORKGB21563'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Lichfield',
      '050564',
      'YORKGB21564'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Littleborough',
      '050565',
      'YORKGB21565'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Luton',
      '050566',
      'YORKGB21566'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Loughborough',
      '050567',
      'YORKGB21567'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Macclesfield',
      '050568',
      'YORKGB21568'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 5',
      '050569',
      'YORKGB21569'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Malton',
      '050570',
      'YORKGB21570'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Manchester B',
      '050573',
      'YORKGB21573'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Mansfield',
      '050575',
      'YORKGB21575'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Melton Mowbray',
      '050578',
      'YORKGB21578'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Mexborough',
      '050580',
      'YORKGB21580'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Norwich Lounge',
      '050581',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Oxford',
      '050582',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cardiff (Lounge)',
      '050584',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Brighton',
      '050585',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Liverpool',
      '050586',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Newcastle Northumberland St',
      '050587',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cramlington',
      '050588',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Gosforth Centre',
      '050589',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Newcastle  The Street',
      '050590',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) South Shields Fowler Street',
      '050592',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cambridge',
      '050593',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Southampton',
      '050594',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hexham',
      '050595',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bromley',
      '050596',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Croydon',
      '050597',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Guildford',
      '050598',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Kingston',
      '050599',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Chester',
      '050600',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Middlesbrough Central',
      '050601',
      'YORKGB21601'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Middlesbrough Berwick Hills',
      '050603',
      'YORKGB21603'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Middlesbrough Gresham',
      '050606',
      'YORKGB21606'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Middlesbrough Oxford Road',
      '050611',
      'YORKGB21611'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Heywood  Middleton',
      '050613',
      'YORKGB21613'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 6',
      '050614',
      'YORKGB21614'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Morecambe',
      '050616',
      'YORKGB21616'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Nelson',
      '050621',
      'YORKGB21621'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Newark',
      '050622',
      'YORKGB21622'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Newcastle Pilgrim Street',
      '050623',
      'YORKGB21623'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stafford Hanley',
      '050624',
      'YORKGB21624'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) High Wycombe Newbury',
      '050625',
      'YORKGB21625'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Normanton',
      '050626',
      'YORKGB21626'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Northallerton',
      '050631',
      'YORKGB21631'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Northampton',
      '050633',
      'YORKGB21633'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Northwich',
      '050634',
      'YORKGB21634'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Norton on Tees',
      '050636',
      'YORKGB21636'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Peterborough  Norwich',
      '050637',
      'YORKGB21637'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Long Eaton Nottingham',
      '050638',
      'YORKGB21638'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Arnold',
      '050639',
      'YORKGB21639'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Beeston Nottingham',
      '050640',
      'YORKGB21640'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Nottingham',
      '050641',
      'YORKGB21641'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bulwell  Nottingham',
      '050642',
      'YORKGB21642'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Nuneaton',
      '050643',
      'YORKGB21643'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Oldham',
      '050646',
      'YORKGB21646'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Oldham Greenacres',
      '050651',
      'YORKGB21651'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Ossett',
      '050656',
      'YORKGB21656'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Otley',
      '050661',
      'YORKGB21661'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Parkgate',
      '050666',
      'YORKGB21666'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Peterborough',
      '050667',
      'YORKGB21667'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Peterlee',
      '050668',
      'YORKGB21668'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Pickering',
      '050669',
      'YORKGB21669'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Pontefract',
      '050671',
      'YORKGB21671'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Preston',
      '050674',
      'YORKGB21674'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Pudsey',
      '050676',
      'YORKGB21676'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Queensbury Bradford',
      '050681',
      'YORKGB21681'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bristol',
      '050682',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Cheltenham',
      '050683',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Swindon',
      '050684',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Chelmsford',
      '050685',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Enfield',
      '050686',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Harrow',
      '050687',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) St Albans',
      '050688',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Milton Keynes',
      '050689',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Reading',
      '050690',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Durham',
      '050691',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Washington',
      '050692',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Bournemouth',
      '050693',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Exeter',
      '050694',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Belfast',
      '050695',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Leeds White Rose',
      '050696',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Meadowhall',
      '050697',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) High Wycombe Reading',
      '050701',
      'YORKGB21701'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Redcar',
      '050702',
      'YORKGB21702'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Redditch',
      '050704',
      'YORKGB21704'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Retford',
      '050707',
      'YORKGB21707'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Richmond',
      '050712',
      'YORKGB21712'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Ripley',
      '050715',
      'YORKGB21715'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Ripon',
      '050717',
      'YORKGB21717'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Rochdale',
      '050722',
      'YORKGB21722'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Rochdale Drake Street',
      '050727',
      'YORKGB21727'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Rotherham',
      '050732',
      'YORKGB21732'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Frederick Street Rotherham',
      '050734',
      'YORKGB21734'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Royton',
      '050737',
      'YORKGB21737'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Rugby',
      '050740',
      'YORKGB21740'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) St Annes on Sea',
      '050742',
      'YORKGB21742'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) St Albans',
      '050743',
      'YORKGB21743'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) St Helens',
      '050744',
      'YORKGB21744'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Scarborough',
      '050747',
      'YORKGB21747'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Scunthorpe High Street',
      '050752',
      'YORKGB21752'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Scunthorpe Ashby',
      '050757',
      'YORKGB21757'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Selby',
      '050762',
      'YORKGB21762'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Shaw',
      '050767',
      'YORKGB21767'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Fargate',
      '050803',
      'YORKGB21803'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Abbeydale Road',
      '050808',
      'YORKGB21808'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Haymarket Attercliffe',
      '050813',
      'YORKGB21813'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Broomhill',
      '050818',
      'YORKGB21818'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Chapeltown',
      '050823',
      'YORKGB21823'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Darnall',
      '050828',
      'YORKGB21828'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Firth Park',
      '050833',
      'YORKGB21833'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Haymarket',
      '050838',
      'YORKGB21838'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Moor Heeley',
      '050843',
      'YORKGB21843'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Hillsborough',
      '050848',
      'YORKGB21848'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield  Hunters Bar',
      '050853',
      'YORKGB21853'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Sheffield Moor',
      '050858',
      'YORKGB21858'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hillsborough Walkley',
      '050863',
      'YORKGB21863'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sheffield Woodseats',
      '050868',
      'YORKGB21868'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 7',
      '050880',
      'YORKGB21880'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 8',
      '050881',
      'YORKGB21881'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 9',
      '050882',
      'YORKGB21882'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 10',
      '050883',
      'YORKGB21883'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 11',
      '050884',
      'YORKGB21884'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 12',
      '050885',
      'YORKGB21885'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 13',
      '050886',
      'YORKGB21886'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 14',
      '050887',
      'YORKGB21887'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) International Trade & Fin',
      '050892',
      'YORKGB21892'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 15',
      '050893',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Business Card',
      '050894',
      'YORKGB21894'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Third Party Distribution',
      '050896',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Shipley',
      '050904',
      'YORKGB21904'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Shrewsbury',
      '050906',
      'YORKGB21906'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Skipton',
      '050909',
      'YORKGB21909'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) High Wycombe Slough',
      '050910',
      'YORKGB21910'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Solihull',
      '050911',
      'YORKGB21911'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) South Bank',
      '050914',
      'YORKGB21914'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Southport',
      '050919',
      'YORKGB21919'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) South Shields',
      '050921',
      'YORKGB21921'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Halifax Sowerby Bridge',
      '050924',
      'YORKGB21924'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stafford',
      '050926',
      'YORKGB21926'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stalybridge',
      '050929',
      'YORKGB21929'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stevenage',
      '050931',
      'YORKGB21931'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stockport',
      '050933',
      'YORKGB21933'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stockton',
      '050934',
      'YORKGB21934'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stockton Yarm Lane',
      '050936',
      'YORKGB21936'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sunderland',
      '050937',
      'YORKGB21937'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sutton in Ashfield',
      '050938',
      'YORKGB21938'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Swinton',
      '050939',
      'YORKGB21939'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Sutton Coldfield',
      '050940',
      'YORKGB21940'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Stourbridge',
      '050941',
      'YORKGB21941'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Tamworth',
      '050942',
      'YORKGB21942'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Telford',
      '050943',
      'YORKGB21943'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Thirsk',
      '050944',
      'YORKGB21944'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Thornaby   Mitchell Avenue',
      '050950',
      'YORKGB21950'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Thorne',
      '050954',
      'YORKGB21954'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Todmorden',
      '050959',
      'YORKGB21959'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Northgate Wakefield',
      '050962',
      'YORKGB21962'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Wakefield',
      '050964',
      'YORKGB21964'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Birkenhead (Wallesey)',
      '050965',
      'YORKGB21965'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Walsall',
      '050966',
      'YORKGB21966'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Warrington',
      '050967',
      'YORKGB21967'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Wath upon Dearne',
      '050969',
      'YORKGB21969'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Wellingborough',
      '050972',
      'YORKGB21972'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) West Bromwich',
      '050973',
      'YORKGB21973'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hartlepool Church Road',
      '050974',
      'YORKGB21974'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Hartlepool',
      '050975',
      'YORKGB21975'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Whitby',
      '050979',
      'YORKGB21979'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Widnes',
      '050980',
      'YORKGB21980'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Wigan',
      '050981',
      'YORKGB21981'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Wolverhampton',
      '050983',
      'YORKGB21983'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Wombwell',
      '050984',
      'YORKGB21984'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Yorkshire Bank Bft 16',
      '050986',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Worksop',
      '050989',
      'YORKGB21989'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) York',
      '050994',
      'YORKGB21994'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb01',
      '059801',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb02',
      '059802',
      'YORKGB21802'
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb03',
      '059803',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb04',
      '059804',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb05',
      '059805',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb06',
      '059806',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb07',
      '059807',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb08',
      '059808',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb09',
      '059809',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury Operations Yb10',
      '059810',
      ''
    ],
    [
      'YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC) Treasury London',
      '059900',
      ''
    ],
    [
      'NATIONWIDE BUILDING SOCIETY Nationwide Receipts 10',
      '070010',
      'NAIAGB21XXX'
    ],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Receipts 20', '070020', ''],
    ['NATIONWIDE BUILDING SOCIETY Cashbuilder', '070030', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Building Soceity', '070040', ''],
    ['NATIONWIDE BUILDING SOCIETY Business Investor A/C', '070055', ''],
    ['NATIONWIDE BUILDING SOCIETY Portfolio Investor A/C', '070066', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Rbs Pers Loans', '070070', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Building Society', '070092', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Building Society', '070093', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Receipts 94', '070094', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Receipts 99', '070099', ''],
    ['NATIONWIDE BUILDING SOCIETY Flexaccount (Original)', '070116', ''],
    ['NATIONWIDE BUILDING SOCIETY Flex Account 2007', '070246', ''],
    ['NATIONWIDE BUILDING SOCIETY Current Account 2014', '070436', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Ics', '070777', ''],
    ['NATIONWIDE BUILDING SOCIETY Current Account 2018', '070806', ''],
    ['NATIONWIDE BUILDING SOCIETY Savings Accounts', '071040', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Savings', '071120', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Savings', '071310', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Savings', '071350', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Savings', '071490', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Savings', '071520', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Savings', '071660', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Contra Two', '072500', ''],
    ['NATIONWIDE BUILDING SOCIETY Branch and Head Office Chqs', '072507', ''],
    ['NATIONWIDE BUILDING SOCIETY Nbs Treasury', '072600', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Out Clearing', '072737', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Relocation', '072828', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Relocation', '072888', ''],
    ['NATIONWIDE BUILDING SOCIETY Nationwide Chaps', '072999', ''],
    ['NATIONWIDE BUILDING SOCIETY Nbs Commercial', '073003', ''],
    ['NATIONWIDE BUILDING SOCIETY Tsys', '073012', ''],
    ['NATIONWIDE BUILDING SOCIETY Commercial Lending', '073013', ''],
    ['NATIONWIDE BUILDING SOCIETY N/Wide General Insurance', '073014', ''],
    ['NATIONWIDE BUILDING SOCIETY Ucb Homeloans Corp Ltd', '073015', ''],
    ['NATIONWIDE BUILDING SOCIETY Flexaccount (Ngc)', '074456', ''],
    ['THE CO-OPERATIVE BANK PLC Payment Operations', '080050', 'CPBKGB21CLG'],
    ['THE CO-OPERATIVE BANK PLC Payment Operations', '080051', 'CPBKGB21LCS'],
    [
      'THE CO-OPERATIVE BANK PLC Sterling Forex Money Market',
      '080100',
      'CPBKGB22XXX'
    ],
    [
      'THE CO-OPERATIVE BANK PLC International Department',
      '080211',
      'CPBKGB22OFS'
    ],
    ['THE CO-OPERATIVE BANK PLC City Office', '080228', 'CPBKGB21CIT'],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Savings Chaps',
      '080265',
      'NRNBGB2LSCH'
    ],
    ['THE CO-OPERATIVE BANK PLC Chaps Repair Code', '080299', 'CPBKGB22REP'],
    ['CITIBANK NA Citibank UK Limited', '083000', 'CITIGB2LLON'],
    ['BANKING CIRCLE S.A. Saxopay', '083002', 'SAPYGB2LXXX'],
    ['National Savings and Investments Nat Savings & Inv Pb', '083115', ''],
    ['National Savings and Investments Nat Savings & Inv Isa', '083120', ''],
    ['National Savings and Investments Nat Savings & Inv Invac', '083130', ''],
    [
      'National Savings and Investments Nat Savings & Inv Leginvac',
      '083131',
      ''
    ],
    [
      'National Savings and Investments Nat Savings & Inv Instantac',
      '083135',
      ''
    ],
    ['GOVERNMENT BANKING Hmrc Indirect Taxes', '083200', ''],
    ['GOVERNMENT BANKING Hmrc Direct Taxes', '083210', ''],
    ['GOVERNMENT BANKING Hmrc Nico', '083220', ''],
    ['GOVERNMENT BANKING Hmrc Child Benefit', '083230', ''],
    ['GOVERNMENT BANKING Hmrc Vote', '083240', ''],
    ['CITIBANK NA Govt Banking Service', '083300', ''],
    ['THE CO-OPERATIVE BANK PLC Ho Collection Account', '085501', ''],
    ['THE CO-OPERATIVE BANK PLC Visa/Collections/Manchester', '085505', ''],
    ['THE CO-OPERATIVE BANK PLC Ho Collection Account', '085531', ''],
    ['THE CO-OPERATIVE BANK PLC Co-Op Visa', '085532', ''],
    ['UNITY TRUST BANK PLC Birmingham', '086001', ''],
    ['THE CO-OPERATIVE BANK PLC Customer Service', '086020', 'CPBKGB21GUI'],
    ['CITIBANK NA Citibank Ctc', '086062', ''],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Savings Gosforth (C/A)',
      '086064',
      'NRNBGB2LSAV'
    ],
    ['CITIBANK NA Citibank Vtc', '086070', ''],
    ['YORKSHIRE BUILDING SOCIETY Banking & Investments', '086081', ''],
    ['YORKSHIRE BUILDING SOCIETY Branch Banking & C Unpaids', '086083', ''],
    ['NATIONWIDE BUILDING SOCIETY Swindon', '086086', ''],
    ['NATIONWIDE BUILDING SOCIETY Nabs Central Remittances', '086089', ''],
    ['VIRGIN MONEY (CLYDESDALE BANK PLC) Operations', '086115', 'NRNBGB2LVOP'],
    ['YORKSHIRE BUILDING SOCIETY Lbs Direct Debits', '086118', ''],
    ['LEEDS BUILDING SOCIETY Leeds', '086119', 'LEEDGB2AXXX'],
    ['MBNA LIMITED Credit Card Accounts', '086130', ''],
    ['YORKSHIRE BUILDING SOCIETY Astra Mortgages', '086142', ''],
    ['YORKSHIRE BUILDING SOCIETY Astra Mortgage Direct Debit', '086144', ''],
    ['ADVANCED PAYMENT SOLUTIONS LTD Aps Limited', '087199', ''],
    ['THE CO-OPERATIVE BANK PLC Manchester', '087701', ''],
    ['THE CO-OPERATIVE BANK PLC Manchester', '089000', 'CPBKGB21MAN'],
    ['THE CO-OPERATIVE BANK PLC Birmingham', '089001', 'CPBKGB21BIR'],
    ['THE CO-OPERATIVE BANK PLC Bristol', '089002', 'CPBKGB21BRI'],
    ['THE CO-OPERATIVE BANK PLC Cardiff', '089003', 'CPBKGB21CAR'],
    ['THE CO-OPERATIVE BANK PLC Chelmsford', '089004', 'CPBKGB21CHE'],
    ['THE CO-OPERATIVE BANK PLC Derby', '089005', 'CPBKGB21DER'],
    ['THE CO-OPERATIVE BANK PLC Newcastle upon Tyne', '089006', 'CPBKGB21NEW'],
    ['THE CO-OPERATIVE BANK PLC Newport Gwent', '089007', 'CPBKGB21NEP'],
    ['THE CO-OPERATIVE BANK PLC Stafford', '089008', 'CPBKGB21STA'],
    ['THE CO-OPERATIVE BANK PLC Hanley', '089009', 'CPBKGB21HAN'],
    ['THE CO-OPERATIVE BANK PLC Coventry', '089010', 'CPBKGB21COV'],
    ['THE CO-OPERATIVE BANK PLC Romford', '089011', 'CPBKGB21ROM'],
    ['THE CO-OPERATIVE BANK PLC Swindon', '089012', 'CPBKGB21SWI'],
    ['THE CO-OPERATIVE BANK PLC Luton', '089013', 'CPBKGB21LUT'],
    ['THE CO-OPERATIVE BANK PLC Norwich', '089014', 'CPBKGB21NOW'],
    ['THE CO-OPERATIVE BANK PLC Preston', '089015', 'CPBKGB21PRE'],
    ['THE CO-OPERATIVE BANK PLC Reading', '089016', 'CPBKGB21REA'],
    ['THE CO-OPERATIVE BANK PLC Southend-on-Sea', '089017', 'CPBKGB21SOS'],
    ['THE CO-OPERATIVE BANK PLC Bolton.', '089018', 'CPBKGB21BOL'],
    ['THE CO-OPERATIVE BANK PLC Wolverhampton', '089019', 'CPBKGB21WOL'],
    ['THE CO-OPERATIVE BANK PLC Wakefield', '089020', 'CPBKGB21WAK'],
    ['THE CO-OPERATIVE BANK PLC Plymouth', '089021', 'CPBKGB21PLY'],
    ['THE CO-OPERATIVE BANK PLC Sunderland', '089022', 'CPBKGB21SUN'],
    ['THE CO-OPERATIVE BANK PLC Chatham', '089023', 'CPBKGB21CHM'],
    ['THE CO-OPERATIVE BANK PLC Stockport', '089024', 'CPBKGB21STO'],
    ['THE CO-OPERATIVE BANK PLC Brighton', '089025', 'CPBKGB21BRG'],
    ['THE CO-OPERATIVE BANK PLC Southampton', '089026', 'CPBKGB21SOU'],
    ['THE CO-OPERATIVE BANK PLC Exeter', '089027', 'CPBKGB21EXE'],
    ['THE CO-OPERATIVE BANK PLC Bedford', '089028', 'CPBKGB21BED'],
    ['THE CO-OPERATIVE BANK PLC Lewisham', '089029', 'CPBKGB21LEW'],
    ['THE CO-OPERATIVE BANK PLC Walthamstow', '089030', 'CPBKGB21WAL'],
    ['THE CO-OPERATIVE BANK PLC Colchester', '089031', 'CPBKGB21COL'],
    ['THE CO-OPERATIVE BANK PLC Lincoln', '089032', 'CPBKGB21LIN'],
    ['THE CO-OPERATIVE BANK PLC Islington', '089033', 'CPBKGB21ISL'],
    ['THE CO-OPERATIVE BANK PLC Kingston upon Thames', '089034', 'CPBKGB21KIN'],
    ['THE CO-OPERATIVE BANK PLC Guildford', '089035', 'CPBKGB21CCS'],
    ['THE CO-OPERATIVE BANK PLC Dartford', '089036', 'CPBKGB21DRT'],
    ['THE CO-OPERATIVE BANK PLC Wood Green', '089037', 'CPBKGB21WGR'],
    ['THE CO-OPERATIVE BANK PLC Oxford', '089038', 'CPBKGB21OXF'],
    ['THE CO-OPERATIVE BANK PLC Chesterfield', '089039', 'CPBKGB21CHS'],
    ['THE CO-OPERATIVE BANK PLC Peterborough', '089040', 'CPBKGB21PET'],
    ['THE CO-OPERATIVE BANK PLC Gloucester', '089041', 'CPBKGB21GLO'],
    ['THE CO-OPERATIVE BANK PLC Darlington', '089042', 'CPBKGB21DAR'],
    ['THE CO-OPERATIVE BANK PLC Cambridge', '089043', 'CPBKGB21CAM'],
    ['THE CO-OPERATIVE BANK PLC Mansfield', '089044', 'CPBKGB21MAF'],
    ['THE CO-OPERATIVE BANK PLC Nuneaton', '089045', 'CPBKGB21NUN'],
    ['THE CO-OPERATIVE BANK PLC West Bromwich', '089046', 'CPBKGB21WBR'],
    ['THE CO-OPERATIVE BANK PLC St Helens', '089047', 'CPBKGB21HEL'],
    ['THE CO-OPERATIVE BANK PLC Dudley', '089048', 'CPBKGB21DUD'],
    ['THE CO-OPERATIVE BANK PLC Bridgend', '089049', 'CPBKGB21BRD'],
    ['THE CO-OPERATIVE BANK PLC Blackburn', '089050', 'CPBKGB21BLN'],
    ['THE CO-OPERATIVE BANK PLC Hartlepool', '089051', 'CPBKGB21HAR'],
    ['THE CO-OPERATIVE BANK PLC Rochdale', '089052', 'CPBKGB21ROC'],
    ['THE CO-OPERATIVE BANK PLC Warrington', '089053', 'CPBKGB21WAR'],
    ['THE CO-OPERATIVE BANK PLC Gloucester', '089054', 'CPBKGB21GLC'],
    ['THE CO-OPERATIVE BANK PLC Basingstoke', '089055', 'CPBKGB21BAS'],
    ['THE CO-OPERATIVE BANK PLC Burton on Trent', '089056', 'CPBKGB21BUR'],
    ['THE CO-OPERATIVE BANK PLC Crewe', '089057', 'CPBKGB21CRE'],
    ['THE CO-OPERATIVE BANK PLC Wigan', '089058', 'CPBKGB21WIG'],
    ['THE CO-OPERATIVE BANK PLC Aberdare', '089059', 'CPBKGB21ABE'],
    ['THE CO-OPERATIVE BANK PLC Guernsey', '089060', 'CPBKGGS1XXX'],
    ['THE CO-OPERATIVE BANK PLC Holborn', '089061', 'CPBKGB21HOL'],
    ['THE CO-OPERATIVE BANK PLC Newcastle under Lyme', '089062', 'CPBKGB21NUL'],
    ['THE CO-OPERATIVE BANK PLC Chester-le-Street', '089063', 'CPBKGB21CST'],
    ['THE CO-OPERATIVE BANK PLC Formby', '089066', 'CPBKGB21FOR'],
    ['THE CO-OPERATIVE BANK PLC Huddersfield', '089067', 'CPBKGB21HUD'],
    ['THE CO-OPERATIVE BANK PLC Macclesfield', '089068', 'CPBKGB21MAC'],
    ['THE CO-OPERATIVE BANK PLC Durham', '089070', 'CPBKGB21DUR'],
    ['THE CO-OPERATIVE BANK PLC Grimsby', '089071', 'CPBKGB21GRI'],
    ['THE CO-OPERATIVE BANK PLC Leeds', '089072', 'CPBKGB21LEE'],
    ['THE CO-OPERATIVE BANK PLC Northampton', '089073', 'CPBKGB21NOR'],
    ['THE CO-OPERATIVE BANK PLC Nottingham', '089074', 'CPBKGB21NOT'],
    ['THE CO-OPERATIVE BANK PLC Sheffield', '089075', 'CPBKGB21SHE'],
    ['THE CO-OPERATIVE BANK PLC Stratford', '089076', 'CPBKGB21STR'],
    ['THE CO-OPERATIVE BANK PLC Leicester', '089077', 'CPBKGB21LEI'],
    ['THE CO-OPERATIVE BANK PLC Watford', '089078', 'CPBKGB21WAT'],
    ['THE CO-OPERATIVE BANK PLC Croydon', '089079', 'CPBKGB21CRO'],
    ['THE CO-OPERATIVE BANK PLC Ealing', '089080', 'CPBKGB21EAL'],
    ['THE CO-OPERATIVE BANK PLC Portsmouth', '089081', 'CPBKGB21POR'],
    ['THE CO-OPERATIVE BANK PLC Doncaster', '089082', 'CPBKGB21DON'],
    ['THE CO-OPERATIVE BANK PLC Ipswich', '089083', 'CPBKGB21IPS'],
    ['THE CO-OPERATIVE BANK PLC Hull', '089084', 'CPBKGB21HUL'],
    ['THE CO-OPERATIVE BANK PLC Swansea', '089085', 'CPBKGB21SWA'],
    ['THE CO-OPERATIVE BANK PLC Liverpool', '089086', 'CPBKGB21LIV'],
    ['THE CO-OPERATIVE BANK PLC Rotherham', '089087', 'CPBKGB21ROT'],
    ['THE CO-OPERATIVE BANK PLC Barnsley', '089088', 'CPBKGB21BAR'],
    ['THE CO-OPERATIVE BANK PLC Middlesborough', '089089', 'CPBKGB21MID'],
    ['THE CO-OPERATIVE BANK PLC Corporate Recoveries', '089092', 'CPBKGB21SEC'],
    ['THE CO-OPERATIVE BANK PLC Handycard Services', '089093', 'CPBKGB21HCS'],
    [
      'THE CO-OPERATIVE BANK PLC Amc - Central Accounts',
      '089094',
      'CPBKGB21CID'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Customer Services Bureau',
      '089095',
      'CPBKGB21CSB'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Manchester Service Centre',
      '089096',
      'CPBKGB21MPC'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Central Deposit Branch',
      '089097',
      'CPBKGB21MCD'
    ],
    ['THE CO-OPERATIVE BANK PLC Visa Skelmersdale', '089098', 'CPBKGB21VIS'],
    ['THE CO-OPERATIVE BANK PLC Head Office', '089099', 'CPBKGB21HOM'],
    ['THE CO-OPERATIVE BANK PLC Unpaids Section', '089100', 'CPBKGB21LPC'],
    [
      'THE CO-OPERATIVE BANK PLC Customer Services 089101',
      '089101',
      'CPBKGB21MCS'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Customer Services 089102',
      '089102',
      'CPBKGB21MFF'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Linked Savings Accounts',
      '089103',
      'CPBKGB21PSA'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Central Customer Services',
      '089104',
      'CPBKGB21SSS'
    ],
    ['THE CO-OPERATIVE BANK PLC Agency Unpaids Branch', '089105', ''],
    ['THE CO-OPERATIVE BANK PLC Britannia Clearing', '089106', 'CPBKGB21BCL'],
    ['THE CO-OPERATIVE BANK PLC Sme Banking', '089115', 'CPBKGB21MAS'],
    [
      'THE CO-OPERATIVE BANK PLC Central Customer Services',
      '089122',
      'CPBKGB21CEN'
    ],
    ['THE CO-OPERATIVE BANK PLC Central Customer Services', '089123', ''],
    ['THE CO-OPERATIVE BANK PLC Britannia Clearing - Paper', '089151', ''],
    ['THE CO-OPERATIVE BANK PLC Fastline', '089202', 'CPBKGB21FAS'],
    ['THE CO-OPERATIVE BANK PLC Asset Management', '089203', 'CPBKGB21LEA'],
    ['THE CO-OPERATIVE BANK PLC Sme Banking', '089204', 'CPBKGB21FCF'],
    [
      'THE CO-OPERATIVE BANK PLC Insurance Products Team',
      '089205',
      'CPBKGB21INS'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Insurance Products Team',
      '089209',
      'CPBKGB21FAM'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Co-Op Bank Tc Visa Centre',
      '089212',
      'CPBKGB21TCV'
    ],
    ['THE CO-OPERATIVE BANK PLC Group Collections', '089215', 'CPBKGB21MAL'],
    ['THE CO-OPERATIVE BANK PLC Cent Dep Bch - Rspb', '089216', 'CPBKGB21RSP'],
    ['THE CO-OPERATIVE BANK PLC Central Unpaids', '089218', 'CPBKGB21NRP'],
    ['THE CO-OPERATIVE BANK PLC Payment Operations', '089219', 'CPBKGB21SEA'],
    [
      'THE CO-OPERATIVE BANK PLC Cardiff Service Centre',
      '089221',
      'CPBKGB21CRP'
    ],
    ['THE CO-OPERATIVE BANK PLC Cent Dep Bch - Lab Pty', '089222', ''],
    ['THE CO-OPERATIVE BANK PLC Overseas Branch', '089223', ''],
    [
      'THE CO-OPERATIVE BANK PLC Central Investment Branch',
      '089224',
      'CPBKGB21SCI'
    ],
    ['THE CO-OPERATIVE BANK PLC Audit Control', '089225', 'CPBKGB21AUD'],
    ['THE CO-OPERATIVE BANK PLC Business Admin Centre', '089228', ''],
    ['THE CO-OPERATIVE BANK PLC Bradford', '089229', 'CPBKGB21BRA'],
    ['THE CO-OPERATIVE BANK PLC Bury', '089230', 'CPBKGB21SBU'],
    ['THE CO-OPERATIVE BANK PLC Handsworth', '089231', 'CPBKGB21BRO'],
    ['THE CO-OPERATIVE BANK PLC Ashton under Lyne', '089232', 'CPBKGB21ASH'],
    ['THE CO-OPERATIVE BANK PLC Oldham', '089233', 'CPBKGB21OLD'],
    ['THE CO-OPERATIVE BANK PLC Fraud Operations', '089235', 'CPBKGB21FRA'],
    ['THE CO-OPERATIVE BANK PLC York', '089241', 'CPBKGB21YOR'],
    ['THE CO-OPERATIVE BANK PLC Customer Services 089243', '089243', ''],
    ['THE CO-OPERATIVE BANK PLC Woolwich', '089244', ''],
    ['THE CO-OPERATIVE BANK PLC Tamworth Bankpoint', '089246', 'CPBKGB21TAM'],
    ['THE CO-OPERATIVE BANK PLC Personal Finance', '089248', 'CPBKGB21CPF'],
    [
      'THE CO-OPERATIVE BANK PLC Customer Services 089249',
      '089249',
      'CPBKGB21SEV'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Central Commercial Branch',
      '089250',
      'CPBKGB21CCB'
    ],
    [
      'THE CO-OPERATIVE BANK PLC Customer Services 089252',
      '089252',
      'CPBKGB21BEL'
    ],
    ['THE CO-OPERATIVE BANK PLC Solihull', '089253', 'CPBKGB21SOL'],
    ['THE CO-OPERATIVE BANK PLC Cbfa', '089255', 'CPBKGB21CBF'],
    ['THE CO-OPERATIVE BANK PLC Walsall', '089256', 'CPBKGB21WAS'],
    ['THE CO-OPERATIVE BANK PLC Bankline', '089270', 'CPBKGB21BAN'],
    [
      'THE CO-OPERATIVE BANK PLC Customer Service Centre',
      '089273',
      'CPBKGB21SCU'
    ],
    ['THE CO-OPERATIVE BANK PLC Chester', '089274', 'CPBKGB21CBP'],
    ['THE CO-OPERATIVE BANK PLC Direct Loans', '089275', 'CPBKGB21MDL'],
    ['THE CO-OPERATIVE BANK PLC Co-Op Savings', '089276', 'CPBKGB21SAV'],
    [
      'THE CO-OPERATIVE BANK PLC Account Management Centre',
      '089280',
      'CPBKGB21MAM'
    ],
    ['THE CO-OPERATIVE BANK PLC Bank Cash Isa', '089281', 'CPBKGB21MCI'],
    ['THE CO-OPERATIVE BANK PLC Smile Isa', '089282', 'CPBKGB21ISA'],
    ['THE CO-OPERATIVE BANK PLC Co-Operative Savings', '089283', 'CPBKGB21CWS'],
    ['THE CO-OPERATIVE BANK PLC Smile - Current', '089286', 'CPBKGB21SMC'],
    ['THE CO-OPERATIVE BANK PLC Smile - Student', '089287', 'CPBKGB21SMS'],
    ['THE CO-OPERATIVE BANK PLC Smile - Savings', '089288', 'CPBKGB21SMA'],
    ['THE CO-OPERATIVE BANK PLC Smile - Loan', '089289', 'CPBKGB21SML'],
    [
      'THE CO-OPERATIVE BANK PLC Central Corporate Branch',
      '089290',
      'CPBKGB21SCC'
    ],
    ['THE CO-OPERATIVE BANK PLC Central Liaison Team', '089291', 'CPBKGB21CLT'],
    [
      'THE CO-OPERATIVE BANK PLC Cis Instant Access Account',
      '089292',
      'CPBKGB21CIS'
    ],
    ['THE CO-OPERATIVE BANK PLC Caerphilly', '089294', 'CPBKGB21CAE'],
    ['THE CO-OPERATIVE BANK PLC Cwmbran', '089295', 'CPBKGB21CWM'],
    [
      'THE CO-OPERATIVE BANK PLC Newcastle U Tyne,Grey St.',
      '089298',
      'CPBKGB21NUT'
    ],
    ['THE CO-OPERATIVE BANK PLC Business Direct', '089299', 'CPBKGB21SBD'],
    [
      'THE CO-OPERATIVE BANK PLC Central Customer Services',
      '089300',
      'CPBKGB21SCS'
    ],
    ['THE CO-OPERATIVE BANK PLC Electronic Banking Rem Atm', '089301', ''],
    ['THE CO-OPERATIVE BANK PLC Mortgage Services', '089302', 'CPBKGB21MSS'],
    [
      'THE CO-OPERATIVE BANK PLC Clarity Business Banking',
      '089303',
      'CPBKGB21CBB'
    ],
    ['THE CO-OPERATIVE BANK PLC Financial Manager', '089304', 'CPBKGB21FUN'],
    ['THE CO-OPERATIVE BANK PLC Leek Derby Street', '089666', ''],
    ['THE CO-OPERATIVE BANK PLC Harrogate', '089683', ''],
    ['THE CO-OPERATIVE BANK PLC Halesowen', '089719', ''],
    ['THE CO-OPERATIVE BANK PLC Truro', '089749', ''],
    ['THE CO-OPERATIVE BANK PLC Westbury-on-Trym', '089756', ''],
    ['THE CO-OPERATIVE BANK PLC Chichester', '089774', ''],
    ['THE CO-OPERATIVE BANK PLC Ilford', '089787', ''],
    ['THE CO-OPERATIVE BANK PLC Plymouth Old Town Street', '089795', ''],
    ['THE CO-OPERATIVE BANK PLC R&R Project Payments', '089851', ''],
    ['Santander UK plc Head Office Receipts', '090000', ''],
    ['Santander UK plc San UK Retail (Nbs)', '090001', ''],
    ['Santander UK plc Financial Mkt Chaps', '090009', 'ABBYGB3EXXX'],
    ['Santander UK plc Santander UK plc (Alm)', '090013', 'ANTSGB2LTSY'],
    ['Santander UK plc Santander UK plc (Alm) 1', '090014', 'ANTSGB2LGBM'],
    ['Santander UK plc Head Office Chaps', '090020', ''],
    ['Santander UK plc Treasury Svcs plc', '090021', 'ANTSGB2LXXX'],
    ['Santander UK plc Treasury International Ltd', '090022', ''],
    ['Santander UK plc An Treasury-Services Jersey', '090023', ''],
    ['Santander UK plc Chaps Redemptions', '090024', ''],
    [
      'ABBEY NATIONAL TREASURY SERVICES PLC Abbey Nat (Overseas) W/Sale',
      '090025',
      'ANILJESHTSY'
    ],
    ['Santander UK plc Returned Advances', '090028', ''],
    ['Santander UK plc Head Office Services', '090029', ''],
    ['Santander UK plc Tamar Secured', '090030', ''],
    ['Santander UK plc Rlmis Retail', '090031', ''],
    ['Santander UK plc Share Holderservices Cl1', '090032', ''],
    ['Santander UK plc Phoenix Life Limited', '090033', ''],
    ['Santander UK plc Shortfall Recovery', '090034', ''],
    ['Santander UK plc Abbey Nat Resourcing Servs', '090035', ''],
    ['Santander UK plc Forex Commission', '090036', ''],
    ['Santander UK plc Accounts Payable', '090037', ''],
    ['Santander UK plc Westcot Bank Account', '090040', ''],
    ['Santander UK plc Santander Direct Saver', '090042', ''],
    ['Santander UK plc Spm UK Ltd', '090044', ''],
    ['Santander UK plc An plc Spm UK Ltd', '090045', ''],
    ['Santander UK plc Head Office Chaps 2', '090046', ''],
    ['Santander UK plc Santander E Saver', '090047', ''],
    ['Santander UK plc Group Debt Management Ops', '090048', ''],
    ['Santander UK plc Bacs Payment Services', '090049', ''],
    ['Santander UK plc Abbey National Credit Card', '090050', ''],
    ['Santander UK plc Head Office Payments', '090052', ''],
    ['Santander UK plc Trustees', '090053', ''],
    ['Santander UK plc Santander Link Disputes', '090057', ''],
    ['Santander UK plc Investor 60', '090058', ''],
    ['Santander UK plc Central Accounting Services', '090060', ''],
    ['Santander UK plc An Introducer Fee', '090061', ''],
    ['Santander UK plc Anfis Accounts Payable', '090063', ''],
    ['Santander UK plc An Asset Manager Ltd', '090064', ''],
    ['Santander UK plc First National Wagon Financ', '090066', ''],
    ['Santander UK plc Atm Services', '090067', ''],
    ['Santander UK plc First National Motorfinance', '090068', ''],
    ['Santander UK plc Manual Cash Advances', '090070', ''],
    ['Santander UK plc Direct Investments', '090071', ''],
    ['Santander UK plc Bus Mortgage', '090072', ''],
    ['Santander UK plc Dmo', '090073', ''],
    ['Santander UK plc Abbey Nat Premises Dept', '090075', ''],
    ['Santander UK plc Prudential Commission', '090076', ''],
    ['Santander UK plc Santander Business 1', '090077', ''],
    ['Santander UK plc Atm Rates Payments', '090078', ''],
    ['Santander UK plc Retail Fin-Lms Fee Income', '090079', ''],
    ['Santander UK plc Santander Flexible Saver', '090081', ''],
    ['Santander UK plc Mortgage Finance 1', '090082', ''],
    ['Santander UK plc Mortgage Finance 2', '090083', ''],
    ['Santander UK plc Mortgage Finance 3', '090084', ''],
    ['Santander UK plc Mortgage Finance 4', '090085', ''],
    ['Santander UK plc Samuk Multimanager', '090086', ''],
    ['Santander UK plc Santander UK', '090087', ''],
    ['Santander UK plc Santander', '090088', ''],
    ['Santander UK plc Phoenix Life Ltd Ppla', '090089', ''],
    ['Santander UK plc Gift Cheques', '090090', ''],
    ['Santander UK plc The Mortgage Alliance', '090091', ''],
    ['Santander UK plc Santander Consumer UK plc', '090092', ''],
    ['Santander UK plc Spanish Mortgages', '090093', ''],
    ['Santander UK plc Secretariat', '090094', ''],
    ['Santander UK plc Afm Chaps Returns', '090095', 'ABBYGB3ERTN'],
    ['Santander UK plc Scf (UK) Wholesale', '090096', ''],
    ['Santander UK plc Financial Markets', '090097', ''],
    ['Santander UK plc Csc Payments', '090098', ''],
    ['Santander UK plc Santander UK plc', '090099', ''],
    ['Santander UK plc Head Office Icr', '090100', ''],
    ['Santander UK plc Protectioncashback', '090101', ''],
    ['Santander UK plc Adeptra Bank A/C', '090102', ''],
    ['Santander UK plc Sbb Commission Payments', '090103', ''],
    ['Santander UK plc Fga Capital', '090104', ''],
    ['Santander UK plc Santander Retail Savings', '090105', ''],
    ['Santander UK plc Santander Cards UK Ltd', '090109', ''],
    ['Santander UK plc Bank Account 1', '090126', ''],
    ['Santander UK plc Santander Bank Account', '090127', ''],
    ['Santander UK plc Bank Account 3', '090128', ''],
    ['Santander UK plc Bank Account 4', '090129', ''],
    ['Santander UK plc Santander', '090130', ''],
    ['Santander UK plc Santander Ca1', '090131', ''],
    ['Santander UK plc Santander Ca2', '090132', ''],
    ['Santander UK plc Santander Ca3', '090133', ''],
    ['Santander UK plc Santander Ca4', '090134', ''],
    ['Santander UK plc Santander Ca5', '090135', ''],
    ['Santander UK plc Santander Ca6', '090136', ''],
    ['Santander UK plc Santander', '090137', ''],
    ['Santander UK plc Abbey Migration C16', '090138', ''],
    ['Santander UK plc Basic Cash', '090139', ''],
    ['Santander UK plc Santander Isa 6', '090140', ''],
    ['Santander UK plc Santander Isa 7', '090141', ''],
    ['Santander UK plc Santander Isa 8', '090142', ''],
    ['Santander UK plc Santander Isa 9', '090143', ''],
    ['Santander UK plc Santander Isa 10', '090144', ''],
    ['Santander UK plc Santander Isa 11', '090145', ''],
    ['Santander UK plc Santander Isa 12', '090146', ''],
    ['Santander UK plc Santander Isa 13', '090147', ''],
    ['Santander UK plc Santander Isa 14', '090148', ''],
    ['Santander UK plc Santander Isa15', '090149', ''],
    ['Santander UK plc Santander Re A&L 0', '090150', ''],
    ['Santander UK plc Santander Re A&L 1', '090151', ''],
    ['Santander UK plc Santander Re A&L 2', '090152', ''],
    ['Santander UK plc Santander Re A&L 3', '090153', ''],
    ['Santander UK plc Santander Re A&L 4', '090154', ''],
    ['Santander UK plc Santander Re A&L 5', '090155', ''],
    ['Santander UK plc Santander Re A&L 6', '090156', ''],
    ['Santander UK plc Internal Use Only', '090157', ''],
    ['Santander UK plc Santander- Int Use Only', '090158', ''],
    ['Santander UK plc Sme Santander Loans', '090159', ''],
    ['Santander UK plc San UK Commercial 1', '090175', ''],
    ['Santander UK plc San UK Commercial 2', '090176', ''],
    ['Santander UK plc San UK Commercial 3', '090177', ''],
    ['Santander UK plc San UK Commercial 4', '090178', ''],
    ['Santander UK plc San UK Commercial 5', '090179', ''],
    ['Santander UK plc San UK Unsecured 1', '090180', ''],
    ['Santander UK plc San UK Unsecured 2', '090181', ''],
    ['Santander UK plc San UK Unsecured 3', '090182', ''],
    ['Santander UK plc San UK Unsecured 4', '090183', ''],
    ['Santander UK plc San UK Unsecured 5', '090184', ''],
    ['Santander UK plc San UK Unsecured 6', '090185', ''],
    ['Santander UK plc San UK Unsecured 7', '090186', ''],
    ['Santander UK plc San UK Unsecured 8', '090187', ''],
    ['Santander UK plc San UK Unsecured 9', '090188', ''],
    ['Santander UK plc San UK Unsecured 10', '090189', ''],
    ['Santander UK plc San UK Secured 1', '090190', ''],
    ['Santander UK plc San UK Secured 2', '090191', ''],
    ['Santander UK plc San UK Secured 3', '090192', ''],
    ['Santander UK plc San UK Secured 4', '090193', ''],
    ['Santander UK plc San UK Secured 5', '090194', ''],
    ['Santander UK plc San UK Secured 6', '090195', ''],
    ['Santander UK plc San UK Secured 7', '090196', ''],
    ['Santander UK plc San UK Secured 8', '090197', ''],
    ['Santander UK plc San UK Secured 9', '090198', ''],
    ['Santander UK plc San UK Secured 10', '090199', ''],
    ['Santander UK plc Branches Department', '090201', ''],
    ['Santander UK plc Debit Vouching Local Agents', '090202', ''],
    ['Santander UK plc Personal Finance', '090203', ''],
    ['Santander UK plc Insurance Services', '090204', ''],
    ['Santander UK plc Abbey Homes', '090205', ''],
    ['Santander UK plc Bankers Payments', '090207', ''],
    ['Santander UK plc Online Expenses', '090209', ''],
    ['Santander UK plc Treasury Services', '090211', ''],
    ['Santander UK plc Insurance Mc', '090212', ''],
    ['Santander UK plc Bankers Draft 1', '090215', ''],
    ['Santander UK plc Rlmis Retail Pymt', '090216', ''],
    ['Santander UK plc Cuul (Liverpool)', '090217', ''],
    ['Santander UK plc Santander Int Use', '090218', ''],
    ['Santander UK plc Cater Allen Cheque Imaging', '090221', ''],
    ['Santander UK plc San UK Corporate New', '090222', ''],
    ['Santander UK plc Cgu Underwriting Ltd', '090230', ''],
    ['Santander UK plc Abbey National plc', '090231', ''],
    ['Santander UK plc Santander Cguul', '090232', ''],
    ['Santander UK plc Hyundai Capital UK Ltd', '090236', ''],
    ['Santander UK plc Banking Services', '090240', ''],
    ['Santander UK plc Insurance Cash Back A/C', '090242', ''],
    ['Santander UK plc Sfi Mortgage Commission', '090250', ''],
    ['Santander UK plc Sfi Mortgage Com Returns', '090251', ''],
    ['Santander UK plc San UK Neptune', '090259', ''],
    ['BANCO SANTANDER S.A. Banco Santander S.A London', '090260', ''],
    ['BANCO SANTANDER S.A. Banco Santander S.A London', '090261', ''],
    ['BANCO SANTANDER S.A. Banco Santander S.A London', '090262', ''],
    ['BANCO SANTANDER S.A. Banco Santander SA', '090268', 'BSCHGB22XXX'],
    ['BANCO SANTANDER S.A. Banco Santander SA', '090269', 'BSCHGB22TRN'],
    ['Santander UK plc Cater Allen Private Bank', '090295', ''],
    ['Santander UK plc Santander', '090298', ''],
    ['Santander UK plc Special Presentations', '090300', ''],
    ['Santander UK plc Internal Use Only', '090302', ''],
    ['Santander UK plc Internal Use Only', '090303', ''],
    ['Santander UK plc Internal Use Only', '090304', ''],
    ['Santander UK plc Internal Use Only', '090305', ''],
    ['Santander UK plc Internal Use Only', '090306', ''],
    ['Santander UK plc Internal Use Only', '090307', ''],
    ['Santander UK plc Internal Use Only', '090308', ''],
    ['Santander UK plc Internal Use Only', '090309', ''],
    ['Santander UK plc Business Banking Agency Cle', '090319', ''],
    ['Santander UK plc Inscape', '090320', ''],
    ['Santander UK plc Internal Use Only', '090321', ''],
    ['Santander UK plc Internal Use Only', '090322', ''],
    ['Santander UK plc Business Banking', '090323', ''],
    ['Santander UK plc Santander Consumerwholesale', '090325', ''],
    ['Santander UK plc Santander Consumer Retail', '090326', ''],
    ['Santander UK plc An Pep & Isa', '090327', ''],
    ['Santander UK plc Anam', '090328', ''],
    ['Santander UK plc Anutm', '090329', ''],
    ['Santander UK plc Pla Ltd', '090330', ''],
    ['Santander UK plc Sma', '090331', ''],
    ['Santander UK plc Smim', '090332', ''],
    ['Santander UK plc An Ins Inv', '090333', ''],
    ['Santander UK plc Scot Mut Pep& Isa Mgrs', '090334', ''],
    ['Santander UK plc Rms (Glasgow) Ltd', '090335', ''],
    ['Santander UK plc Scot Prov Pens Trus Ltd', '090336', ''],
    ['Santander UK plc Sth Glas Ret Park Ltd', '090337', ''],
    ['Santander UK plc Santander', '090338', ''],
    ['Santander UK plc Santander', '090339', ''],
    ['Santander UK plc Scot Prov Ltd', '090340', ''],
    ['Santander UK plc Scot Mut Pens Funds Inv', '090341', ''],
    ['Santander UK plc An Sma Holdings Ltd', '090342', ''],
    ['Santander UK plc Bus Outsourcing Serv', '090343', ''],
    ['Santander UK plc Pro Holdings Ltd', '090344', ''],
    ['Santander UK plc Pro Prop (Kent) Ltd', '090345', ''],
    ['Santander UK plc Internal Use Only', '090347', ''],
    ['Santander UK plc Internal Use Only', '090348', ''],
    ['Santander UK plc Internal Use Only', '090349', ''],
    ['Santander UK plc Internal Use Only', '090350', ''],
    ['Santander UK plc Internal Use Only', '090351', ''],
    ['Santander UK plc Internal Use Only', '090352', ''],
    ['Santander UK plc Internal Use Only', '090353', ''],
    ['Santander UK plc Internal Use Only', '090355', ''],
    ['Santander UK plc Secured Loans', '090356', ''],
    ['Santander UK plc Santander Loans Pul', '090357', ''],
    ['Santander UK plc Unsecured Loans', '090358', ''],
    ['Santander UK plc Do Not Use Cpo - Afm', '090360', ''],
    ['Santander UK plc Santander Loans 2', '090361', ''],
    ['Santander UK plc Do Not Use Cpo Pin Inc Rep', '090362', ''],
    ['Santander UK plc Do Not Use Cpo- Grp Prpty', '090363', ''],
    ['Santander UK plc Do Not Use Atm Diff Sus', '090364', ''],
    ['Santander UK plc Internal Use Only', '090368', ''],
    ['Santander UK plc Internal Use Only', '090373', ''],
    ['Santander UK plc Scl Cards Non Posting', '090374', ''],
    ['Santander UK plc San UK Consumer Do Not Use', '090375', ''],
    ['Santander UK plc Atm Centralisation', '090377', ''],
    ['Santander UK plc Out Clearing Department', '090395', ''],
    ['Santander UK plc Santander  Rd Chq Agcy', '090397', ''],
    ['Santander UK plc Santander - Iatapay', '090399', ''],
    ['Santander UK plc Santander Pep Mgrs', '090403', 'ABFIGB21XXX'],
    ['Santander UK plc Commercial Union Credit', '090405', ''],
    ['Santander UK plc Sam Unit Trust Managers UK', '090406', ''],
    ['Santander UK plc Commercial Union Creditor', '090407', ''],
    ['Santander UK plc Smim', '090409', ''],
    ['Santander UK plc Rlmis Pegasus', '090410', ''],
    ['Santander UK plc Aitken Campbell', '090413', ''],
    ['Santander UK plc Sm Pep & Isa Mgr', '090415', ''],
    ['Santander UK plc Phoenix Life Ltd Pplm', '090418', ''],
    ['Santander UK plc Phoenix Life Ltd', '090419', ''],
    ['Santander UK plc Scottish Mutual P & I Mgr', '090421', ''],
    ['Santander UK plc Anfis Commission', '090422', ''],
    ['Santander UK plc South Glasgow Retail Park L', '090431', ''],
    ['Santander UK plc Scottish Mutual Pension Fun', '090432', ''],
    ['Santander UK plc Scottish Provident Holding', '090433', ''],
    ['Santander UK plc Scotprov Ltd', '090434', ''],
    ['Santander UK plc Scottish Provident Trustees', '090435', ''],
    ['Santander UK plc Prolific Holdings Ltd', '090436', ''],
    ['Santander UK plc Prolific Property Dev', '090437', ''],
    ['Santander UK plc Spi Finance plc', '090438', ''],
    ['Santander UK plc Scottish Provident Pension', '090439', ''],
    ['Santander UK plc Scottish Provident 1', '090447', ''],
    ['Santander UK plc Scottish Provident 2', '090448', ''],
    ['Santander UK plc Sharleholder Services Cl2', '090467', ''],
    ['Santander UK plc Shareholder Services Cl3', '090468', ''],
    ['Santander UK plc Shareholder Services Cl4', '090469', ''],
    ['Santander UK plc Shareholder Services Cl5', '090470', ''],
    ['Santander UK plc Shareholder Services Cl6', '090471', ''],
    ['Santander UK plc Shareholder Services Cl7', '090472', ''],
    ['Santander UK plc Shareholder Services Cl8', '090486', ''],
    ['Santander UK plc Santander', '090487', ''],
    ['Santander UK plc Santander', '090489', ''],
    ["Santander UK plc S'holder Servreplacementdiv", '090498', ''],
    ['Santander UK plc Secured Lending Colln Acs0', '090500', ''],
    ['Santander UK plc Secured Lending Colln Acs1', '090501', ''],
    ['Santander UK plc Secured Lending Colln Acs2', '090502', ''],
    ['Santander UK plc Secured Lending Colln Acs3', '090503', ''],
    ['Santander UK plc Secured Lending Colln Acs4', '090504', ''],
    ['Santander UK plc Secured Lending Colln Acs5', '090505', ''],
    ['Santander UK plc Secured Lending Colln Acs6', '090506', ''],
    ['Santander UK plc Secured Lending Colln Acs7', '090507', ''],
    ['Santander UK plc Secured Lending Colln Acs8', '090508', ''],
    ['Santander UK plc Secured Lending Colln Acs9', '090509', ''],
    ['Santander UK plc Secured Lending Colln Acs10', '090510', ''],
    ['Santander UK plc Secured Lending Colln Acs11', '090511', ''],
    ['Santander UK plc Secured Lending Colln Acs12', '090512', ''],
    ['Santander UK plc Secured Lending Colln Acs13', '090513', ''],
    ['Santander UK plc Secured Lending Colln Acs14', '090514', ''],
    ['Santander UK plc Secured Lending Colln Acs15', '090515', ''],
    ['Santander UK plc Secured Lending Colln Acs16', '090516', ''],
    ['Santander UK plc Secured Lending Colln Acs17', '090517', ''],
    ['Santander UK plc Secured Lending Colln Acs18', '090518', ''],
    ['Santander UK plc Secured Lending Colln Acs19', '090519', ''],
    ['Santander UK plc Secured Lending Colln Acs20', '090520', ''],
    ['Santander UK plc Secured Lending Colln Acs21', '090521', ''],
    ['Santander UK plc Secured Lending Colln Acs22', '090522', ''],
    ['Santander UK plc Secured Lending Colln Acs23', '090523', ''],
    ['Santander UK plc Secured Lending Colln Acs24', '090524', ''],
    ['Santander UK plc Secured Lending Colln Acs25', '090525', ''],
    ['Santander UK plc Secured Lending Colln Acs26', '090526', ''],
    ['Santander UK plc Secured Lending Colln Acs27', '090527', ''],
    ['Santander UK plc Secured Lending Colln Acs28', '090528', ''],
    ['Santander UK plc Secured Lending Colln Acs29', '090529', ''],
    ['Santander UK plc Secured Lending Colln Acs30', '090530', ''],
    ['Santander UK plc Secured Lending Colln Acs31', '090531', ''],
    ['Santander UK plc Secured Lending Colln Acs32', '090532', ''],
    ['Santander UK plc Secured Lending Colln Acs33', '090533', ''],
    ['Santander UK plc Secured Lending Colln Acs34', '090534', ''],
    ['Santander UK plc Secured Lending Colln Acs35', '090535', ''],
    ['Santander UK plc Secured Lending Colln Acs36', '090536', ''],
    ['Santander UK plc Secured Lending Colln Acs37', '090537', ''],
    ['Santander UK plc Secured Lending Colln Acs38', '090538', ''],
    ['Santander UK plc Secured Lending Colln Acs39', '090539', ''],
    ['Santander UK plc Secured Lending Colln Acs40', '090540', ''],
    ['Santander UK plc Secured Lending Colln Acs41', '090541', ''],
    ['Santander UK plc Secured Lending Colln Acs42', '090542', ''],
    ['Santander UK plc Secured Lending Colln Acs43', '090543', ''],
    ['Santander UK plc Secured Lending Colln Acs44', '090544', ''],
    ['Santander UK plc Secured Lending Colln Acs45', '090545', ''],
    ['Santander UK plc Secured Lending Colln Acs46', '090546', ''],
    ['Santander UK plc Secured Lending Colln Acs47', '090547', ''],
    ['Santander UK plc Secured Lending Colln Acs48', '090548', ''],
    ['Santander UK plc Secured Lending Colln Acs49', '090549', ''],
    ['Santander UK plc Secured Lending Colln Acs50', '090550', ''],
    ['Santander UK plc Secured Lending Colln Acs51', '090551', ''],
    ['Santander UK plc Secured Lending Colln Acs52', '090552', ''],
    ['Santander UK plc Secured Lending Colln Acs53', '090553', ''],
    ['Santander UK plc Secured Lending Colln Acs54', '090554', ''],
    ['Santander UK plc Secured Lending Colln Acs55', '090555', ''],
    ['Santander UK plc Secured Lending Colln Acs56', '090556', ''],
    ['Santander UK plc Secured Lending Colln Acs57', '090557', ''],
    ['Santander UK plc Secured Lending Colln Acs58', '090558', ''],
    ['Santander UK plc Secured Lending Colln Acs59', '090559', ''],
    ['Santander UK plc Secured Lending Colln Acs60', '090560', ''],
    ['Santander UK plc Secured Lending Colln Acs61', '090561', ''],
    ['Santander UK plc Secured Lending Colln Acs62', '090562', ''],
    ['Santander UK plc Secured Lending Colln Acs63', '090563', ''],
    ['Santander UK plc Secured Lending Colln Acs64', '090564', ''],
    ['Santander UK plc Secured Lending Colln Acs65', '090565', ''],
    ['Santander UK plc Secured Lending Colln Acs66', '090566', ''],
    ['Santander UK plc Secured Lending Colln Acs67', '090567', ''],
    ['Santander UK plc Secured Lending Colln Acs68', '090568', ''],
    ['Santander UK plc Secured Lending Colln Acs69', '090569', ''],
    ['Santander UK plc Secured Lending Colln Acs70', '090570', ''],
    ['Santander UK plc Secured Lending Colln Acs71', '090571', ''],
    ['Santander UK plc Secured Lending Colln Acs72', '090572', ''],
    ['Santander UK plc Secured Lending Colln Acs73', '090573', ''],
    ['Santander UK plc Secured Lending Colln Acs74', '090574', ''],
    ['Santander UK plc Secured Lending Colln Acs75', '090575', ''],
    ['Santander UK plc Secured Lending Colln Acs76', '090576', ''],
    ['Santander UK plc Secured Lending Colln Acs77', '090577', ''],
    ['Santander UK plc Secured Lending Colln Acs78', '090578', ''],
    ['Santander UK plc Secured Lending Colln Acs79', '090579', ''],
    ['Santander UK plc Secured Lending Colln Acs80', '090580', ''],
    ['Santander UK plc Secured Lending Colln Acs81', '090581', ''],
    ['Santander UK plc Secured Lending Colln Acs82', '090582', ''],
    ['Santander UK plc Secured Lending Colln Acs83', '090583', ''],
    ['Santander UK plc Secured Lending Colln Acs84', '090584', ''],
    ['Santander UK plc Secured Lending Colln Acs85', '090585', ''],
    ['Santander UK plc Secured Lending Colln Acs86', '090586', ''],
    ['Santander UK plc Secured Lending Colln Acs87', '090587', ''],
    ['Santander UK plc Secured Lending Colln Acs88', '090588', ''],
    ['Santander UK plc Secured Lending Colln Acs89', '090589', ''],
    ['Santander UK plc Secured Lending Colln Acs90', '090590', ''],
    ['Santander UK plc Secured Lending Colln Acs91', '090591', ''],
    ['Santander UK plc Secured Lending Colln Acs92', '090592', ''],
    ['Santander UK plc Secured Lending Colln Acs93', '090593', ''],
    ['Santander UK plc Secured Lending Colln Acs94', '090594', ''],
    ['Santander UK plc Secured Lending Colln Acs95', '090595', ''],
    ['Santander UK plc Secured Lending Colln Acs96', '090596', ''],
    ['Santander UK plc Secured Lending Colln Acs97', '090597', ''],
    ['Santander UK plc Secured Lending Colln Acs98', '090598', ''],
    ['Santander UK plc Secured Lending Colln Acs99', '090599', ''],
    ['Santander UK plc Sls Securitisation', '090600', ''],
    ['Santander UK plc Cahoot', '090640', ''],
    ['Santander UK plc Cahoot Credit Card', '090641', ''],
    ['Santander UK plc Cahoot Saving & Investments', '090642', ''],
    ['Santander UK plc Cahoot Savings Account', '090643', ''],
    ['Santander UK plc Cahoot Fxed Rate Loan', '090644', ''],
    ['Santander UK plc Cahoot Contact Centre', '090650', ''],
    ['Santander UK plc Santander Business Banking', '090666', ''],
    ['Santander UK plc Santander Business 2', '090667', ''],
    ['Santander UK plc Santander Business A', '090670', ''],
    ['Santander UK plc Santander Consumer', '090680', ''],
    ['Santander UK plc Hmcts Court Fees', '090690', ''],
    ['Santander UK plc Income Fees', '090695', ''],
    ['Santander UK plc Commission Income', '090696', ''],
    ['Santander UK plc Internal Use Only', '090700', ''],
    ['Santander UK plc San UK Corporate Loans Iq', '090704', ''],
    ['Santander UK plc San UK Corporate Bank Trade', '090705', 'ABBYGB2LDOC'],
    ['Santander UK plc Santander Currency A/Cs', '090710', ''],
    ['Santander UK plc San UK Corporate Currency', '090715', ''],
    ['Santander UK plc San UK Scb Mig 0', '090720', ''],
    ['Santander UK plc San UK Scb Mig 1', '090721', ''],
    ['Santander UK plc San UK Scb Mig 2', '090722', ''],
    ['Santander UK plc San UK Scb Mig 3', '090723', ''],
    ['Santander UK plc San UK Scb Mig 4', '090724', ''],
    ['Santander UK plc San UK Scb Mig 5', '090725', ''],
    ['Santander UK plc San UK Scb Mig 6', '090726', ''],
    ['Santander UK plc San UK Scb Mig 7', '090727', ''],
    ['Santander UK plc San UK Scb Mig 8', '090728', ''],
    ['Santander UK plc San UK Scb Mig 9', '090729', ''],
    ['Santander UK plc San UK Scb Mig 10', '090730', ''],
    ['Santander UK plc San UK Scb Mig 11', '090731', ''],
    ['Santander UK plc San UK Scb Mig 12', '090732', ''],
    ['Santander UK plc San UK Scb Mig 13', '090733', ''],
    ['Santander UK plc San UK Scb Mig 14', '090734', ''],
    ['Santander UK plc San UK Scb Mig 15', '090735', ''],
    ['Santander UK plc San UK Scb Mig 16', '090736', ''],
    ['Santander UK plc San UK Scb Mig 17', '090737', ''],
    ['Santander UK plc San UK Scb Mig 18', '090738', ''],
    ['Santander UK plc San UK Scb Mig 19', '090739', ''],
    ['Santander UK plc San UK  Bankers Draft Count', '090741', ''],
    ['Santander UK plc San UK Bankers Draft Bank', '090742', ''],
    ['Santander UK plc San UK Corporate Fx Drafts', '090743', ''],
    ['Santander UK plc Santander Corporate Loans', '090790', ''],
    ['Santander UK plc Branches Department', '090801', ''],
    ['Santander UK plc Bankers Draft', '090802', ''],
    ['Santander UK plc Domestic Payments', '090803', ''],
    ['Santander UK plc Ho Bacs (Internal Only)', '090806', ''],
    ['Santander UK plc Cards Walker Internal Only', '090807', ''],
    ['Santander UK plc Clearingwalker Internalonly', '090808', ''],
    ['Santander UK plc Cash Walker (Internal Only)', '090809', ''],
    ['Santander UK plc Misc Walker (Internal Only)', '090810', ''],
    ['Santander UK plc Partenon Caos', '090811', ''],
    ['Santander UK plc Internal Use Only', '090813', ''],
    ['Santander UK plc Partenon F & F Chaps', '090991', ''],
    ['Santander UK plc Dp Model Office', '090992', ''],
    ['Santander UK plc Shareholder Issue 15', '091001', ''],
    ['Santander UK plc Shareholder18', '091004', ''],
    ['Santander UK plc Shareholder 19', '091005', ''],
    ['Santander UK plc Shareholder Issue 20', '091006', ''],
    ['Santander UK plc Shareholder 21', '091007', ''],
    ['Santander UK plc Shareholder Services 22', '091008', ''],
    ['Santander UK plc Shareholder Services 23', '091009', ''],
    ['Santander UK plc Equiniti Ltd Santander 36', '091022', ''],
    ['Santander UK plc Poo Returns Globalpay', '091100', ''],
    ['Santander UK plc Poo Returns Speedy', '091113', ''],
    ['Santander UK plc Mortgage Finance 5', '091586', ''],
    ['Santander UK plc Iom Banking', '091600', ''],
    ['Santander UK plc San UK Corporate Loan 1', '091740', ''],
    ['Santander UK plc San UK Corporate Loan 2', '091741', ''],
    ['Santander UK plc San UK Corporate Loan 3', '091742', ''],
    ['Santander UK plc San UK Corporate Loan 4', '091743', ''],
    ['Santander UK plc San UK Corporate Loan 4', '091744', ''],
    ['Santander UK plc San UK Corporate Loan 4', '091745', ''],
    ['Santander UK plc San UK Corp Bank Account', '091800', ''],
    ['Santander UK plc San UK Corp Bank A/C 1', '091801', ''],
    ['Santander UK plc San UK Corp Bank A/C 2', '091802', ''],
    ['Santander UK plc San UK Corp Bank A/C 3', '091803', ''],
    ['Santander UK plc San UK Corp Bank A/C 4', '091804', ''],
    ['Santander UK plc San UK Corp Bank A/C 5', '091805', ''],
    ['Santander UK plc San UK Corp Bank A/C 6', '091806', ''],
    ['Santander UK plc San UK Corp Bank A/C 7', '091807', ''],
    ['Santander UK plc San UK Corp Bank A/C 8', '091808', ''],
    ['Santander UK plc San UK Corp Bank A/C 9', '091809', ''],
    ['Santander UK plc Currency Accounts (Ex Ibis)', '091810', ''],
    ['Santander UK plc San UK Corp Bank A/C 11', '091811', ''],
    ['Santander UK plc San UK Corp Bank A/C 12', '091812', ''],
    ['Santander UK plc San UK Corp Bank A/C 13', '091813', ''],
    ['Santander UK plc San UK Corp Bank A/C 14', '091814', ''],
    ['Santander UK plc San UK Corp Bank A/C 15', '091815', ''],
    ['Santander UK plc San UK Corp Bank A/C 16', '091816', ''],
    ['Santander UK plc San UK Corp Bank A/C 17', '091817', ''],
    ['Santander UK plc San UK Corp Bank A/C 18', '091818', ''],
    ['Santander UK plc San UK Corp Bank A/C 19', '091819', ''],
    ['Santander UK plc San UK Corp Bank A/C 20', '091820', ''],
    ['Santander UK plc San UK Corp Bank A/C 21', '091821', ''],
    ['Santander UK plc San UK Corp Bank A/C 22', '091822', ''],
    ['Santander UK plc San UK Corp Bank A/C 23', '091823', ''],
    ['Santander UK plc San UK Corp Bank A/C 24', '091824', ''],
    ['Santander UK plc San UK Corp Bank A/C 25', '091825', ''],
    ['Santander UK plc San UK Corp Bank A/C 26', '091826', ''],
    ['Santander UK plc San UK Corp Bank A/C 27', '091827', ''],
    ['Santander UK plc San UK Corp Bank A/C 28', '091828', ''],
    ['Santander UK plc San UK Corp Bank A/C 29', '091829', ''],
    ['Santander UK plc San UK Corp Bank A/C 30', '091830', ''],
    ['Santander UK plc San UK Corp Bank A/C 31', '091831', ''],
    ['Santander UK plc San UK Corp Bank A/C 32', '091832', ''],
    ['Santander UK plc San UK Corp Bank A/C 33', '091833', ''],
    ['Santander UK plc San UK Corp Bank A/C 34', '091834', ''],
    ['Santander UK plc San UK Corp Bank A/C 35', '091835', ''],
    ['Santander UK plc San UK Corp Bank A/C 36', '091836', ''],
    ['Santander UK plc San UK Corp Bank A/C 37', '091837', ''],
    ['Santander UK plc San UK Corp Bank A/C 38', '091838', ''],
    ['Santander UK plc San UK Corp Bank A/C 39', '091839', ''],
    ['Santander UK plc San UK Corp Bank A/C 40', '091840', ''],
    ['Santander UK plc San UK Corp Bank A/C 41', '091841', ''],
    ['Santander UK plc San UK Corp Bank A/C 42', '091842', ''],
    ['Santander UK plc San UK Corp Bank A/C 43', '091843', ''],
    ['Santander UK plc San UK Corp Bank A/C 44', '091844', ''],
    ['Santander UK plc San UK Corp Bank A/C 45', '091845', ''],
    ['Santander UK plc San UK Corp Bank A/C 46', '091846', ''],
    ['Santander UK plc San UK Corp Bank A/C 47', '091847', ''],
    ['Santander UK plc San UK Corp Bank A/C 48', '091848', ''],
    ['Santander UK plc San UK Corp Bank A/C 49', '091849', ''],
    ['Santander UK plc San UK Corp Bank A/C 50', '091850', ''],
    ['Santander UK plc San UK Corp Bank A/C 51', '091851', ''],
    ['Santander UK plc San UK Corp Bank A/C 52', '091852', ''],
    ['Santander UK plc San UK Corp Bank A/C 53', '091853', ''],
    ['Santander UK plc San UK Corp Bank A/C 54', '091854', ''],
    ['Santander UK plc San UK Corp Bank A/C 55', '091855', ''],
    ['Santander UK plc San UK Corp Bank A/C 56', '091856', ''],
    ['Santander UK plc San UK Corp Bank A/C 57', '091857', ''],
    ['Santander UK plc San UK Corp Bank A/C 58', '091858', ''],
    ['Santander UK plc San UK Corp Bank A/C 59', '091859', ''],
    ['Santander UK plc San UK Corp Bank A/C 60', '091860', ''],
    ['Santander UK plc San UK Corp Bank A/C 61', '091861', ''],
    ['Santander UK plc San UK Corp Bank A/C 62', '091862', ''],
    ['Santander UK plc San UK Corp Bank A/C 63', '091863', ''],
    ['Santander UK plc San UK Corp Bank A/C 64', '091864', ''],
    ['Santander UK plc San UK Corp Bank A/C 65', '091865', ''],
    ['Santander UK plc Santander Cards Corporate', '091910', ''],
    ['Santander UK plc Santander Loans 1', '091951', ''],
    ['Santander UK plc Santander Loans 2', '091952', ''],
    ['Santander UK plc Santander Loans 3', '091953', ''],
    ['Santander UK plc Santander Loans 4', '091954', ''],
    ['Santander UK plc Santander Loans 5', '091955', ''],
    ['Santander UK plc Santander Loans 6', '091956', ''],
    ['Santander UK plc Santander Loans 7', '091957', ''],
    ['Santander UK plc Santander Loans 8', '091958', ''],
    ['Santander UK plc Santander Loans 9', '091959', ''],
    ['Santander UK plc Santander Loans 10', '091960', ''],
    ['Santander UK plc Santander Loans 11', '091961', ''],
    ['Santander UK plc Santander Business 1', '091970', ''],
    ['Santander UK plc Santander Business 2', '091971', ''],
    ['Santander UK plc Santander Business 3', '091972', ''],
    ['Santander UK plc Ics Default', '091989', ''],
    ['Santander UK plc Redirected Receipts', '091990', ''],
    ['Santander UK plc Partenon C&Cc', '091999', ''],
    ['BANCO SANTANDER S.A. London Branch-Main', '096001', ''],
    ['BANCO SANTANDER S.A. London Branch-Gbp Clearing', '096002', ''],
    ['BANCO SANTANDER S.A. London Branch-Virtual Acc', '096003', ''],
    ['BANCO SANTANDER S.A. London Branch-Spare', '096004', ''],
    ['BANCO SANTANDER S.A. London Branch-Vostro', '096005', ''],
    ['BANK OF ENGLAND Head Office', '100000', 'BKENGB33XXX'],
    ['BANK OF ENGLAND Chaps Repair Sc Only', '100013', 'BKENGB33RTN'],
    ['BANK OF ENGLAND Debt Management Office', '101800', ''],
    ['BANK OF ENGLAND Debt Management Office', '101829', ''],
    ['CLS BANK INTERNATIONAL Cls Bank International', '104400', 'CLSBUS33XXX'],
    ['BANK OF ENGLAND Rtgs Repair', '109990', ''],
    ['BANK OF ENGLAND Rtgs', '109999', 'BKENGB54XXX'],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Commercial Street',
      '110001',
      'HLFXGB21F08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberystwyth',
      '110003',
      'HLFXGB21A21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdare',
      '110004',
      'HLFXGB21A01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Abingdon High Street',
      '110005',
      'HLFXGB21A22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Abergavenny',
      '110006',
      'HLFXGB21A19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Armley',
      '110007',
      'HLFXGB21A58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Accrington',
      '110008',
      'HLFXGB21A24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Acomb',
      '110009',
      'HLFXGB21A28'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Acocks Green 1117 W'ck Rd",
      '110010',
      'HLFXGB21A27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aldershot',
      '110011',
      'HLFXGB21A37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Alnwick',
      '110012',
      'HLFXGB21A45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Altrincham',
      '110013',
      'HLFXGB21A47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Andover',
      '110014',
      'HLFXGB21A51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aylesbury',
      '110015',
      'HLFXGB21A78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashford Castle St (Kent)',
      '110016',
      'HLFXGB21A66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Balham',
      '110017',
      'HLFXGB21A87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banbury',
      '110018',
      'HLFXGB21A92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bangor (North Wales)',
      '110019',
      'HLFXGB21A98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barnet',
      '110020',
      'HLFXGB21B11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashington',
      '110021',
      'HLFXGB21A68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barking',
      '110022',
      'HLFXGB21B07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barnsley',
      '110023',
      'HLFXGB21B12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barnsley May Day Green',
      '110024',
      'HLFXGB21B15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barnstaple',
      '110025',
      'HLFXGB21B16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barkingside',
      '110026',
      'HLFXGB21B09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hastings (110027)',
      '110027',
      'HLFXGB21B74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barrow in Furness',
      '110028',
      'HLFXGB21B19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barry Holton Road',
      '110029',
      'HLFXGB21B20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bransholme',
      '110030',
      'HLFXGB21C77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackwood',
      '110031',
      'HLFXGB21C28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basildon',
      '110032',
      'HLFXGB21B22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basingstoke (110033)',
      '110033',
      'HLFXGB21B24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walsall (110034)',
      '110034',
      'HLFXGB21B86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bath (110035)',
      '110035',
      'HLFXGB21B31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bath (110036)',
      '110036',
      'HLFXGB21B29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Batley',
      '110037',
      'HLFXGB21B33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chelmsley Wood',
      '110038',
      'HLFXGB21E31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basildon (110039)',
      '110039',
      'HLFXGB21B83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hemel Hempstead',
      '110040',
      'HLFXGB21B67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Beccles',
      '110041',
      'HLFXGB21B39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Biggleswade',
      '110042',
      'HLFXGB21B82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham The Bull Ring',
      '110043',
      'HLFXGB21C05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bedford',
      '110044',
      'HLFXGB21B41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol (110045)',
      '110045',
      'HLFXGB21B45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Beeston',
      '110046',
      'HLFXGB21B47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Berwick upon Tweed',
      '110047',
      'HLFXGB21B68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bethnal Green',
      '110048',
      'HLFXGB21B70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Beverley',
      '110049',
      'HLFXGB21B71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Colmore Row',
      '110050',
      'HLFXGB21B96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bexleyheath',
      '110051',
      'HLFXGB21B76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Billingham',
      '110052',
      'HLFXGB21B84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bideford High Street',
      '110053',
      'HLFXGB21B81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birkenhead',
      '110054',
      'HLFXGB21B90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birkenhead Charing Cross',
      '110055',
      'HLFXGB21B91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bicester',
      '110056',
      'HLFXGB21B80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham The Bull Ring',
      '110058',
      'HLFXGB21C04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham The Bull Ring',
      '110059',
      'HLFXGB21B95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Agencies 1',
      '110060',
      'HLFXGB21B93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bishop Auckland',
      '110061',
      'HLFXGB21C08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackburn',
      '110062',
      'HLFXGB21C20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bishops Stortford',
      '110063',
      'HLFXGB21C11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackpool Lytham Road',
      '110064',
      'HLFXGB21C25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackpool Church Street',
      '110065',
      'HLFXGB21C23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham The Bull Ring',
      '110066',
      'HLFXGB21C07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bletchley',
      '110067',
      'HLFXGB21C32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham The Bull Ring',
      '110068',
      'HLFXGB21C01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Bearwood Road',
      '110069',
      'HLFXGB21B94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bognor Regis',
      '110070',
      'HLFXGB21C36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blyth',
      '110071',
      'HLFXGB21C33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Handsworth',
      '110072',
      'HLFXGB21B99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bolton',
      '110073',
      'HLFXGB21C39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Borehamwood',
      '110074',
      'HLFXGB21C52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bootle',
      '110075',
      'HLFXGB21C49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bournemouth (110076)',
      '110076',
      'HLFXGB21C54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Boston',
      '110077',
      'HLFXGB21C55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham The Bull Ring',
      '110078',
      'HLFXGB21C06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bournemouth',
      '110079',
      'HLFXGB21C58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sth & West Midlands Agy 1',
      '110080',
      'HLFXGB21U34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bracknell',
      '110081',
      'HLFXGB21C60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '110082',
      'HLFXGB21C64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '110083',
      'HLFXGB21C71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '110084',
      'HLFXGB21C67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Braintree',
      '110086',
      'HLFXGB21C74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bootle Stanley Road',
      '110087',
      'HLFXGB21C50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bodmin',
      '110088',
      'HLFXGB21C35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brentwood',
      '110089',
      'HLFXGB21C80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '110090',
      'HLFXGB21J48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bridgend',
      '110091',
      'HLFXGB21C84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bridgwater',
      '110092',
      'HLFXGB21C88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bridlington',
      '110093',
      'HLFXGB21C90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brent Cross',
      '110094',
      'HLFXGB21C79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '110095',
      'HLFXGB21C70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton (110096)',
      '110096',
      'HLFXGB21D02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton (110097)',
      '110097',
      'HLFXGB21C98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Broadmead',
      '110098',
      'HLFXGB21D05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighouse',
      '110099',
      'HLFXGB21C93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Penn Street',
      '110100',
      'HLFXGB21D13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Mortgage Admin',
      '110101',
      'HLFXGB21D11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton (110102)',
      '110102',
      'HLFXGB21E10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Whiteladies Road',
      '110103',
      'HLFXGB21D15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Clare Street',
      '110104',
      'HLFXGB21D07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Business Centre',
      '110105',
      'HLFXGB21D06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brixton',
      '110107',
      'HLFXGB21D16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Avon Agencies',
      '110108',
      'HLFXGB21A77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South Wilts & Poole Bay Ag',
      '110109',
      'HLFXGB21T85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromley (110110)',
      '110110',
      'HLFXGB21D24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromsgrove (110111)',
      '110111',
      'HLFXGB21D27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromley (110112)',
      '110112',
      'HLFXGB21D21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '110113',
      'HLFXGB21J80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burgess Hill 11 Church Road',
      '110114',
      'HLFXGB21D36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burnley',
      '110115',
      'HLFXGB21D39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edgware (110116)',
      '110116',
      'HLFXGB21D42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burton on Trent Dame P Wk',
      '110118',
      'HLFXGB21D44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bury The Rock (110119)',
      '110119',
      'HLFXGB21D52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bury The Rock (110120)',
      '110120',
      'HLFXGB21D48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackpool Service Centre',
      '110121',
      'HLFXGB21C26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Fishergate(110122)',
      '110122',
      'HLFXGB21D92'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bury St Edmunds 58 C'hill",
      '110123',
      'HLFXGB21D50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromley (110124)',
      '110124',
      'HLFXGB21B40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Buxton High St',
      '110125',
      'HLFXGB21D55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Buxton',
      '110126',
      'HLFXGB21D54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Haymkt (110127)',
      '110127',
      'HLFXGB21D56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromley Service Centre',
      '110128',
      'HLFXGB21D25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromley High St (110129)',
      '110129',
      'HLFXGB21D23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashton under Lyne',
      '110130',
      'HLFXGB21A71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Camberley',
      '110131',
      'HLFXGB21D63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Camborne',
      '110132',
      'HLFXGB21D66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cambridge Petty Cury',
      '110133',
      'HLFXGB21D70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cambridge Fitzroy Street',
      '110134',
      'HLFXGB21D69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burton on Trent',
      '110135',
      'HLFXGB21D43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cb Logica',
      '110136',
      'HLFXGB21E07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bury The Rock (110137)',
      '110137',
      'HLFXGB21D49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bury Agencies',
      '110138',
      'HLFXGB21D47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Canterbury',
      '110139',
      'HLFXGB21D76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Canvey Island',
      '110140',
      'HLFXGB21D79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Albany Road',
      '110141',
      'HLFXGB21D82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Queens Arcade',
      '110142',
      'HLFXGB21D87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Queens Arcade',
      '110144',
      'HLFXGB21D83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Queens Arcade',
      '110145',
      'HLFXGB21D86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Agencies 1',
      '110146',
      'HLFXGB21D81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Methyr Tydfil (110147)',
      '110147',
      'HLFXGB21T79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess London 4',
      '110148',
      'HLFXGB21H88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Carlisle',
      '110149',
      'HLFXGB21D90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Castleford',
      '110150',
      'HLFXGB21D99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Carmarthen',
      '110151',
      'HLFXGB21D94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Catford',
      '110152',
      'HLFXGB21E02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess East',
      '110153',
      'HLFXGB21H85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess Midlands',
      '110154',
      'HLFXGB21H89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess North',
      '110155',
      'HLFXGB21H91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess N West',
      '110156',
      'HLFXGB21H90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess SW & West',
      '110157',
      'HLFXGB21H95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess S East',
      '110158',
      'HLFXGB21H93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess London 1',
      '110159',
      'HLFXGB21H86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Kent Business Centre',
      '110160',
      'HLFXGB21W48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chatham',
      '110161',
      'HLFXGB21E22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chatham 248 High Street',
      '110162',
      'HLFXGB21E24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kent Agencies',
      '110163',
      'HLFXGB21M07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess London 2',
      '110164',
      'HLFXGB21H87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cb - London 3',
      '110165',
      'HLFXGB21E06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chelmsford',
      '110166',
      'HLFXGB21E28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chelmsford High Street',
      '110167',
      'HLFXGB21E30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess BT',
      '110168',
      'HLFXGB21H83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess Spec Prod',
      '110169',
      'HLFXGB21H94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess S &  Ni',
      '110170',
      'HLFXGB21H92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheltenham 110171',
      '110171',
      'HLFXGB21E32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheltenham 110172',
      '110172',
      'HLFXGB21E34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Frodsham St(110173)',
      '110173',
      'HLFXGB21E45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester & Nth Wales Agy 1',
      '110174',
      'HLFXGB21E42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hemel Hempstead (110175)',
      '110175',
      'HLFXGB21E36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Frodshamst(110176)',
      '110176',
      'HLFXGB21E47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Frodsham St(110177)',
      '110177',
      'HLFXGB21E52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Frodsham St(110178)',
      '110178',
      'HLFXGB21E53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chingford Mount Road',
      '110179',
      'HLFXGB21E64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chesterfieldknifesmithgate',
      '110180',
      'HLFXGB21E57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chesterfield Cent Pavement',
      '110181',
      'HLFXGB21E56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Le Street',
      '110182',
      'HLFXGB21E49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chichester',
      '110183',
      'HLFXGB21E60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chippenham',
      '110184',
      'HLFXGB21E65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chiswick',
      '110185',
      'HLFXGB21E67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester & Nth Wales Agy 2',
      '110186',
      'HLFXGB21E43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chorley Market Street',
      '110187',
      'HLFXGB21E70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cirencester',
      '110189',
      'HLFXGB21E74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clacton on Sea',
      '110190',
      'HLFXGB21E77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clapham Junction (110192)',
      '110192',
      'HLFXGB21B34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Central Referral Unit',
      '110193',
      'HLFXGB21E18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cleckheaton',
      '110194',
      'HLFXGB21E81'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Central Midlands Agy's 1",
      '110195',
      'HLFXGB21E16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cleveleys',
      '110196',
      'HLFXGB21E84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coventry Service Centre',
      '110197',
      'HLFXGB21F33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coalville',
      '110198',
      'HLFXGB21E92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South London Property Unit',
      '110199',
      'HLFXGB21T74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Colchester Business Centre',
      '110200',
      'HLFXGB21E96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Colchester Head Street',
      '110201',
      'HLFXGB21E99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Colchester Eld Lane',
      '110202',
      'HLFXGB21E98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Essex Agencies 1',
      '110203',
      'HLFXGB21H96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cambridge Agencies 1',
      '110204',
      'HLFXGB21D67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crouch End The Broadway',
      '110205',
      'HLFXGB21F48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Llandudno (110206)',
      '110206',
      'HLFXGB21F06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coventry High St (110207)',
      '110207',
      'HLFXGB21F26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coventry Hight St 110208',
      '110208',
      'HLFXGB21F25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Corby',
      '110209',
      'HLFXGB21F11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cosham',
      '110210',
      'HLFXGB21F12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coventry High St (110211)',
      '110211',
      'HLFXGB21F31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coventry High St (110212)',
      '110212',
      'HLFXGB21F28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon (110213)',
      '110213',
      'HLFXGB21F58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crawley (110216)',
      '110216',
      'HLFXGB21F37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon (110217)',
      '110217',
      'HLFXGB21T72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crewe',
      '110218',
      'HLFXGB21F40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon North End 110219',
      '110219',
      'HLFXGB21F53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon Mortgage Desk',
      '110220',
      'HLFXGB21F55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon (110221)',
      '110221',
      'HLFXGB21F52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon North End 110222',
      '110222',
      'HLFXGB21F57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon (110223)',
      '110223',
      'HLFXGB21F59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cwmbran',
      '110224',
      'HLFXGB21F72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dagenham Heathway',
      '110225',
      'HLFXGB21F75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dalston',
      '110226',
      'HLFXGB21F82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Darlington',
      '110227',
      'HLFXGB21F85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dartford High Street',
      '110228',
      'HLFXGB21F91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walsall (110229)',
      '110229',
      'HLFXGB21F84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Denbigh',
      '110230',
      'HLFXGB21F97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Devizes',
      '110231',
      'HLFXGB21G08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Deal',
      '110232',
      'HLFXGB21F94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Derby East Street',
      '110233',
      'HLFXGB21G05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Darlington Queen Street',
      '110234',
      'HLFXGB21F88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Daventry',
      '110235',
      'HLFXGB21F93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dewsbury (110236)',
      '110236',
      'HLFXGB21G10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Diss',
      '110237',
      'HLFXGB21G17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Direct Marketing',
      '110238',
      'HLFXGB21G16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Doncaster St Sepulchregate',
      '110239',
      'HLFXGB21G24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Doncaster Market Place',
      '110240',
      'HLFXGB21G21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Doncaster St Sepulchregate',
      '110241',
      'HLFXGB21G19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dorchester',
      '110242',
      'HLFXGB21G25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Doncaster St Sepulchregate',
      '110243',
      'HLFXGB21G23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dorking (110244)',
      '110244',
      'HLFXGB21G26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromsgrove (110245)',
      '110245',
      'HLFXGB21G34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Plymouth George St',
      '110246',
      'HLFXGB21G09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dudley Castle Street',
      '110247',
      'HLFXGB21G37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dudley High Street',
      '110248',
      'HLFXGB21G38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dudley Merry Hill',
      '110249',
      'HLFXGB21G40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunstable',
      '110250',
      'HLFXGB21G63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dover',
      '110251',
      'HLFXGB21G30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Durham',
      '110253',
      'HLFXGB21G65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Durham  North Road',
      '110254',
      'HLFXGB21G66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Denton',
      '110255',
      'HLFXGB21F99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ealing (110256)',
      '110256',
      'HLFXGB21G69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ealing (110257)',
      '110257',
      'HLFXGB21W46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eastbourne Arndale Centre',
      '110258',
      'HLFXGB21G91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eastbourne Cornfield Road',
      '110259',
      'HLFXGB21G92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eastleigh',
      '110260',
      'HLFXGB21G96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eastleigh (Asda)',
      '110261',
      'HLFXGB21G97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dereham',
      '110262',
      'HLFXGB21G07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Grinstead',
      '110263',
      'HLFXGB21G70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Ham',
      '110264',
      'HLFXGB21G73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Ham 82 High St North',
      '110265',
      'HLFXGB21G75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Doncaster St Sepulchregate',
      '110266',
      'HLFXGB21G22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edgware (110269)',
      '110269',
      'HLFXGB21H04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edmonton',
      '110270',
      'HLFXGB21H58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Commercial Street',
      '110275',
      'HLFXGB21H64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Direct',
      '110276',
      'HLFXGB21J81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milton Keynes Business Ctr',
      '110277',
      'HLFXGB21P12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bedfords & Bucks Agys 1',
      '110278',
      'HLFXGB21B43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ilkley',
      '110279',
      'HLFXGB21L62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ellesmere Port',
      '110280',
      'HLFXGB21H65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eltham High Street',
      '110285',
      'HLFXGB21H68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Finance Accounting Services',
      '110286',
      'HLFXGB21I34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Finance Accounts Payable',
      '110287',
      'HLFXGB21I35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Enfield',
      '110288',
      'HLFXGB21H71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edgware (110289)',
      '110289',
      'HLFXGB21N83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nth West London Agys 1',
      '110290',
      'HLFXGB21Q45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Epsom (110291)',
      '110291',
      'HLFXGB21H75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Exeter Bedford Street',
      '110292',
      'HLFXGB21I05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Erdington 257 High Street',
      '110293',
      'HLFXGB21H79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Exeter & Somerset Agys',
      '110294',
      'HLFXGB21I04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Evesham',
      '110295',
      'HLFXGB21I01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Exeter Business Centre',
      '110296',
      'HLFXGB21I06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Exeter High Street',
      '110297',
      'HLFXGB21I08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Exeter Roman Gate (110298)',
      '110298',
      'HLFXGB21I07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Exmouth',
      '110299',
      'HLFXGB21I11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fareham',
      '110300',
      'HLFXGB21I19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Falmouth',
      '110301',
      'HLFXGB21I18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Feltham',
      '110302',
      'HLFXGB21I32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Felixstowe Hamilton Road',
      '110303',
      'HLFXGB21I30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Finchley Central',
      '110305',
      'HLFXGB21I36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Farnborough (Hants)',
      '110306',
      'HLFXGB21I22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fleetwood',
      '110307',
      'HLFXGB21I38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aldershot (110308)',
      '110308',
      'HLFXGB21I28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Folkestone',
      '110309',
      'HLFXGB21I40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fulham',
      '110311',
      'HLFXGB21I52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Frome',
      '110312',
      'HLFXGB21I51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Thames Valley Agencies 2',
      '110313',
      'HLFXGB21V36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gainsborough',
      '110314',
      'HLFXGB21I55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Commercial Street',
      '110315',
      'HLFXGB21I68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gateshead High Street',
      '110316',
      'HLFXGB21I65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gateshead Metro (110317)',
      '110317',
      'HLFXGB21I66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gillingham High St (Kent)',
      '110318',
      'HLFXGB21I72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mortgage Collections',
      '110319',
      'HLFXGB21P37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gloucester',
      '110320',
      'HLFXGB21J21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gloucester Southgate Street',
      '110321',
      'HLFXGB21J22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Guildford (110322)',
      '110322',
      'HLFXGB21J24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Edgware Rd (110323)',
      '110323',
      'HLFXGB21N92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Golders Green',
      '110325',
      'HLFXGB21J25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leicester Humberstone',
      '110326',
      'HLFXGB21N03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harlow Harvey Centre',
      '110327',
      'HLFXGB21K11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Goole',
      '110328',
      'HLFXGB21J27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Gosforth',
      '110329',
      'HLFXGB21P66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gosport',
      '110330',
      'HLFXGB21J33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harlow Agencies',
      '110331',
      'HLFXGB21K09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basingstoke (110332)',
      '110332',
      'HLFXGB21I23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grantham High Street',
      '110333',
      'HLFXGB21J41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Guildford 72/74 High Street',
      '110334',
      'HLFXGB21J67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gravesend',
      '110335',
      'HLFXGB21J43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gravesend',
      '110336',
      'HLFXGB21J44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grays',
      '110337',
      'HLFXGB21J47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Haywards Heath',
      '110338',
      'HLFXGB21K43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North London Property Unit',
      '110339',
      'HLFXGB21Q06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Romford (110340)',
      '110340',
      'HLFXGB21K13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Great Yarmouth',
      '110341',
      'HLFXGB21J49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Malvern',
      '110342',
      'HLFXGB21O52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Greenford 17 The Broadway',
      '110343',
      'HLFXGB21J51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hayes 61 Station Road',
      '110344',
      'HLFXGB21K42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Holloway',
      '110345',
      'HLFXGB21K97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grimsby Freeman Street',
      '110346',
      'HLFXGB21J58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grimsby Old Market Place',
      '110347',
      'HLFXGB21J59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Loans Ltd',
      '110349',
      'HLFXGB21J90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Guildford',
      '110350',
      'HLFXGB21J64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hackney',
      '110351',
      'HLFXGB21J73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Fin Services Ltd',
      '110352',
      'HLFXGB21J83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hx Independent Fin Ad Ltd',
      '110353',
      'HLFXGB21L45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halesowen',
      '110354',
      'HLFXGB21J77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hammersmith',
      '110355',
      'HLFXGB21K03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harlesden',
      '110356',
      'HLFXGB21K07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harrogate',
      '110357',
      'HLFXGB21K15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harborne',
      '110358',
      'HLFXGB21K05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harlow Harvey Centre',
      '110359',
      'HLFXGB21K10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harrow (110360)',
      '110360',
      'HLFXGB21K18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hartlepool',
      '110361',
      'HLFXGB21K23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harwich',
      '110362',
      'HLFXGB21K26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hastings (110363)',
      '110363',
      'HLFXGB21K30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Havant',
      '110364',
      'HLFXGB21K34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Haverfordwest',
      '110365',
      'HLFXGB21K37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) High Wycombe (110366)',
      '110366',
      'HLFXGB21K79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hemel Hempstead',
      '110367',
      'HLFXGB21K77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bramley (110369)',
      '110369',
      'HLFXGB21K65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hereford',
      '110370',
      'HLFXGB21K80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Herne Bay',
      '110371',
      'HLFXGB21K81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hexham',
      '110372',
      'HLFXGB21K87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harlow Harvey Centre',
      '110373',
      'HLFXGB21K83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dewsbury (110374)',
      '110374',
      'HLFXGB21K70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) High Wycombe',
      '110375',
      'HLFXGB21K89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Woolshops',
      '110376',
      'HLFXGB21J96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nuneaton (110377)',
      '110377',
      'HLFXGB21K92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harlow Harvey Centre',
      '110378',
      'HLFXGB21K08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Albans (110379)',
      '110379',
      'HLFXGB21K14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Horsham',
      '110380',
      'HLFXGB21L06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hatfield 56 Town Centre',
      '110381',
      'HLFXGB21K33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hitchin',
      '110382',
      'HLFXGB21K94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harrow Agencies',
      '110383',
      'HLFXGB21K20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hounslow Central',
      '110384',
      'HLFXGB21L10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hounslow City (110385)',
      '110385',
      'HLFXGB21G76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hove',
      '110386',
      'HLFXGB21L15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Housing Dept (Admin)',
      '110387',
      'HLFXGB21L14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Region Property Unit',
      '110388',
      'HLFXGB21G85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huddersfield (110389)',
      '110389',
      'HLFXGB21L23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huddersfield (110390)',
      '110390',
      'HLFXGB21L27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huddersfield (110391)',
      '110391',
      'HLFXGB21L24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huddersfield (110392)',
      '110392',
      'HLFXGB21L26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hounslow Service Centre',
      '110393',
      'HLFXGB21L13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (110394)',
      '110394',
      'HLFXGB21L33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (110395)',
      '110395',
      'HLFXGB21L30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huntingdon',
      '110396',
      'HLFXGB21L38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hyde Market Place',
      '110397',
      'HLFXGB21L46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huyton',
      '110398',
      'HLFXGB21L41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hounslow Agencies',
      '110399',
      'HLFXGB21L09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ilford',
      '110400',
      'HLFXGB21L58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ilford 100 High Road',
      '110401',
      'HLFXGB21L59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Midlands Agencies',
      '110402',
      'HLFXGB21G84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Premier Mortgage Services',
      '110403',
      'HLFXGB21R64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ipswich',
      '110405',
      'HLFXGB21L76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ipswich Buttermarket',
      '110406',
      'HLFXGB21L78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kensington Business Centre',
      '110407',
      'HLFXGB21M06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Islington (110408)',
      '110408',
      'HLFXGB21L85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Keighley',
      '110410',
      'HLFXGB21L93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London New Oxford Street',
      '110411',
      'HLFXGB21N96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kendal Highgate',
      '110412',
      'HLFXGB21L98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leamington Spa (110413)',
      '110413',
      'HLFXGB21M01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London New Oxford St 110414',
      '110414',
      'HLFXGB21N97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kensington',
      '110415',
      'HLFXGB21M04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Edgware Road',
      '110416',
      'HLFXGB21N85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingsbury',
      '110417',
      'HLFXGB21M32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kensington (110418)',
      '110418',
      'HLFXGB21M56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Keynsham',
      '110419',
      'HLFXGB21M10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kettering',
      '110420',
      'HLFXGB21M09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kidderminster',
      '110421',
      'HLFXGB21M13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kilburn',
      '110422',
      'HLFXGB21M15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kings Heath High Street',
      '110423',
      'HLFXGB21M27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leicester Humberstone',
      '110424',
      'HLFXGB21M97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kings Lynn',
      '110425',
      'HLFXGB21M28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kings Norton',
      '110426',
      'HLFXGB21M30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northampton (110427)',
      '110427',
      'HLFXGB21M35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingston upon Thames',
      '110428',
      'HLFXGB21M36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingstanding King Road',
      '110429',
      'HLFXGB21M34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingswood',
      '110430',
      'HLFXGB21M38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkby',
      '110431',
      'HLFXGB21M45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harrogate (110432)',
      '110432',
      'HLFXGB21M55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Launceston',
      '110433',
      'HLFXGB21M73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leicester Humberstone',
      '110434',
      'HLFXGB21N04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lancaster',
      '110435',
      'HLFXGB21M63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Agencies',
      '110436',
      'HLFXGB21M79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cntrl London Property Unit',
      '110437',
      'HLFXGB21E91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leamington Spa The Parade',
      '110438',
      'HLFXGB21M76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dorking (110439)',
      '110439',
      'HLFXGB21M77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Business Centre',
      '110440',
      'HLFXGB21M82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Crossgates Centre',
      '110441',
      'HLFXGB21M84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Albion Street',
      '110442',
      'HLFXGB21M80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leek',
      '110444',
      'HLFXGB21M95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Vicar Lane',
      '110445',
      'HLFXGB21M93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leicester Humberstone',
      '110446',
      'HLFXGB21N01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leicester Humberstone',
      '110447',
      'HLFXGB21M99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Commercial Street',
      '110448',
      'HLFXGB21M83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leominster',
      '110449',
      'HLFXGB21N10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leigh',
      '110450',
      'HLFXGB21N05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leighton Buzzard',
      '110451',
      'HLFXGB21N08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Letchworth',
      '110452',
      'HLFXGB21N13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lewisham',
      '110453',
      'HLFXGB21N19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leyton (110454)',
      '110454',
      'HLFXGB21N24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leyland',
      '110455',
      'HLFXGB21N21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leytonstone',
      '110456',
      'HLFXGB21N25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Merseyside Agencies 1',
      '110457',
      'HLFXGB21O87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Allerton Road',
      '110458',
      'HLFXGB21N43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Paradisest 110459',
      '110459',
      'HLFXGB21N50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lincoln Cornhill',
      '110460',
      'HLFXGB21N32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lincoln High Street',
      '110461',
      'HLFXGB21N33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Littlehampton Surrey St',
      '110462',
      'HLFXGB21N41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Belle Vale',
      '110463',
      'HLFXGB21N44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Business Centre',
      '110464',
      'HLFXGB21N46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Paradise Street',
      '110465',
      'HLFXGB21N52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Paradisest 110466',
      '110466',
      'HLFXGB21N45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Tarleton Street',
      '110467',
      'HLFXGB21N53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Old Swan',
      '110468',
      'HLFXGB21N51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Llandudno Mostyn St',
      '110469',
      'HLFXGB21N57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Llanelli',
      '110470',
      'HLFXGB21N58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London City Service Centre',
      '110471',
      'HLFXGB21N82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Edgware Rd (110472)',
      '110472',
      'HLFXGB21N74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London New Oxford Street',
      '110473',
      'HLFXGB21N89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Wall',
      '110474',
      'HLFXGB21O07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London West End Agencies',
      '110475',
      'HLFXGB21O08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Artillery Row',
      '110476',
      'HLFXGB21N73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London High Holborn',
      '110477',
      'HLFXGB21N90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Cornhill',
      '110479',
      'HLFXGB21N84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Cheapside',
      '110480',
      'HLFXGB21N79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London New Oxford Street',
      '110481',
      'HLFXGB21N98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Moorgate',
      '110482',
      'HLFXGB21N95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London New Oxford Street',
      '110483',
      'HLFXGB21N94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Camden (110484)',
      '110484',
      'HLFXGB21N76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London High Holborn110486',
      '110486',
      'HLFXGB21N88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cannon St - Mortgages Only',
      '110487',
      'HLFXGB21N77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London City Business Centre',
      '110488',
      'HLFXGB21N81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Long Eaton',
      '110489',
      'HLFXGB21O13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Loughborough',
      '110490',
      'HLFXGB21O17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Loughton',
      '110491',
      'HLFXGB21O19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Louth',
      '110492',
      'HLFXGB21O20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London City Agencies',
      '110493',
      'HLFXGB21N80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Longton (Staffs)',
      '110494',
      'HLFXGB21O15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lowestoft',
      '110495',
      'HLFXGB21O22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Luton Arndale Centre',
      '110496',
      'HLFXGB21O28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Luton Dunstable Rd',
      '110497',
      'HLFXGB21O29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Luton',
      '110498',
      'HLFXGB21O27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Luton Mortgage Admin',
      '110499',
      'HLFXGB21O31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Edgware Rd(110500)',
      '110500',
      'HLFXGB21N99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lymington',
      '110501',
      'HLFXGB21O33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Tottenham Court Road',
      '110502',
      'HLFXGB21O04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Victoria',
      '110503',
      'HLFXGB21O05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Macclesfield',
      '110504',
      'HLFXGB21O36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London 51/55 Strand',
      '110505',
      'HLFXGB21N72'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) L'dn West End Service Ctr",
      '110506',
      'HLFXGB21M74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) March',
      '110507',
      'HLFXGB21O73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Maidenhead',
      '110508',
      'HLFXGB21O42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) High Wycombe (110509)',
      '110509',
      'HLFXGB21O78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Maidstone Week Street',
      '110510',
      'HLFXGB21O46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Maldon High Street',
      '110511',
      'HLFXGB21O47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Malton',
      '110512',
      'HLFXGB21O51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North Manchester Agencies',
      '110513',
      'HLFXGB21Q07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manchester Agencies',
      '110514',
      'HLFXGB21O54'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Man'ster Piccadilly110515",
      '110515',
      'HLFXGB21O56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manchester Piccadilly',
      '110516',
      'HLFXGB21O62'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Man'ster Piccadilly110517",
      '110517',
      'HLFXGB21O58'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Man'ster Piccadilly110518",
      '110518',
      'HLFXGB21O59'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Man'ster Piccadilly110519",
      '110519',
      'HLFXGB21O55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheetham Hill',
      '110520',
      'HLFXGB21E26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mansfield Stockwell Gate',
      '110521',
      'HLFXGB21O70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mansfield Stockwell Gate',
      '110522',
      'HLFXGB21O71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Margate',
      '110523',
      'HLFXGB21O74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Market Harborough',
      '110524',
      'HLFXGB21O76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Merthyr Tydfil',
      '110525',
      'HLFXGB21O90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mansfield Regent Street',
      '110526',
      'HLFXGB21O69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Melton Mowbray',
      '110527',
      'HLFXGB21O85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Middlesborough',
      '110528',
      'HLFXGB21O94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mexborough 14 High Street',
      '110529',
      'HLFXGB21O93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Middleton',
      '110530',
      'HLFXGB21O98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Midland Property Unit',
      '110531',
      'HLFXGB21P02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milton Keynes (110532)',
      '110532',
      'HLFXGB21P17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North West Property Unit',
      '110533',
      'HLFXGB21Q14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mirfield',
      '110534',
      'HLFXGB21P18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mold',
      '110535',
      'HLFXGB21P21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morecambe',
      '110536',
      'HLFXGB21P28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northumbria Agencies',
      '110537',
      'HLFXGB21Q24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morley',
      '110538',
      'HLFXGB21P31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morriston',
      '110539',
      'HLFXGB21P36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morpeth',
      '110540',
      'HLFXGB21P34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Csc',
      '110541',
      'HLFXGB21P65'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Muswell Hill 400 M'hill B",
      '110542',
      'HLFXGB21P49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Neath',
      '110543',
      'HLFXGB21P54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nelson',
      '110544',
      'HLFXGB21P56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newark',
      '110545',
      'HLFXGB21P62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North East Midlands Agy 1',
      '110546',
      'HLFXGB21P97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newbury',
      '110547',
      'HLFXGB21P64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North East Midlands Agy 2',
      '110548',
      'HLFXGB21P98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle under Lyme',
      '110549',
      'HLFXGB21P74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worcester Park (110550)',
      '110550',
      'HLFXGB21P58'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle North'land Road",
      '110551',
      'HLFXGB21P71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Haymkt (110554)',
      '110554',
      'HLFXGB21P70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Haymkt (110555)',
      '110555',
      'HLFXGB21P68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newmarket',
      '110556',
      'HLFXGB21P78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newport (I O W )',
      '110557',
      'HLFXGB21P80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newport Llanarth Street',
      '110558',
      'HLFXGB21P83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newport (Gwent)',
      '110559',
      'HLFXGB21P79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newton Abbot (110560)',
      '110560',
      'HLFXGB21P84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) New Milton',
      '110561',
      'HLFXGB21P59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Norbury',
      '110562',
      'HLFXGB21P92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newton Aycliffe',
      '110563',
      'HLFXGB21P85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northallerton',
      '110564',
      'HLFXGB21Q15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northampton',
      '110565',
      'HLFXGB21Q16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portsmouth North End',
      '110566',
      'HLFXGB21R57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northfield',
      '110567',
      'HLFXGB21Q22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North Finchley',
      '110568',
      'HLFXGB21Q01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North Shields',
      '110569',
      'HLFXGB21Q10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northwich Witton Street',
      '110570',
      'HLFXGB21Q25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham Business Centre',
      '110571',
      'HLFXGB21Q36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Norwich Gentlemans Walk',
      '110572',
      'HLFXGB21Q29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Norwich Bank Plain',
      '110573',
      'HLFXGB21Q28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hfx Commercial St 110574',
      '110574',
      'HLFXGB21J72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham City (110575)',
      '110575',
      'HLFXGB21Q41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham Low Pavement',
      '110576',
      'HLFXGB21Q40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham Friar Lane',
      '110577',
      'HLFXGB21Q38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nuneaton',
      '110578',
      'HLFXGB21Q47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oxford (110579)',
      '110579',
      'HLFXGB21Q67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oldham Yorkshire Street',
      '110580',
      'HLFXGB21Q54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oldham Curzon Street',
      '110581',
      'HLFXGB21Q52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ormskirk',
      '110582',
      'HLFXGB21Q56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Orpington',
      '110583',
      'HLFXGB21Q58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oswestry',
      '110584',
      'HLFXGB21Q62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Otley',
      '110585',
      'HLFXGB21Q63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ossett',
      '110586',
      'HLFXGB21Q60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oxford (110587)',
      '110587',
      'HLFXGB21Q66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Help Desk',
      '110588',
      'HLFXGB21J85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paignton',
      '110589',
      'HLFXGB21Q70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grp Recs 110590',
      '110590',
      'HLFXGB21Q90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peckham',
      '110591',
      'HLFXGB21Q97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grp Recs 110592',
      '110592',
      'HLFXGB21Q92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Clearing Centre',
      '110593',
      'HLFXGB21Q91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Penzance Market Jew St',
      '110594',
      'HLFXGB21R05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Perry Barr',
      '110595',
      'HLFXGB21R06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peterborough (110597)',
      '110597',
      'HLFXGB21R13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peterlee',
      '110598',
      'HLFXGB21R18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Group Security',
      '110599',
      'HLFXGB21J61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Central Midlands Agy S 3',
      '110600',
      'HLFXGB21E15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Plymouth St Andrews Cross',
      '110601',
      'HLFXGB21R31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Plymouth George St',
      '110602',
      'HLFXGB21R28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Plymouth George St',
      '110603',
      'HLFXGB21R29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Plymouth George St',
      '110604',
      'HLFXGB21R27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pontefract',
      '110605',
      'HLFXGB21R36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pontypridd',
      '110606',
      'HLFXGB21R37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Penrith',
      '110607',
      'HLFXGB21R03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Poole',
      '110608',
      'HLFXGB21R40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Poole Kingland Crescent',
      '110609',
      'HLFXGB21R42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oadby',
      '110610',
      'HLFXGB21Q48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Port Talbot',
      '110611',
      'HLFXGB21R46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Porthcawl',
      '110612',
      'HLFXGB21R51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portsmouth Agencies',
      '110613',
      'HLFXGB21R54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portsmouth Service Centre',
      '110614',
      'HLFXGB21R58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portsmouth Commercial Road',
      '110615',
      'HLFXGB21R55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southampton (110616)',
      '110616',
      'HLFXGB21R60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston & Blackburn Agy 1',
      '110617',
      'HLFXGB21R67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Fishergate(110618)',
      '110618',
      'HLFXGB21R71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Fishergate (110620)',
      '110620',
      'HLFXGB21R75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Fishergate(110621)',
      '110621',
      'HLFXGB21R70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Prestwich',
      '110622',
      'HLFXGB21R76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Parkstone',
      '110623',
      'HLFXGB21Q83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pudsey',
      '110624',
      'HLFXGB21R82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon (110625)',
      '110625',
      'HLFXGB21R86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Putney',
      '110626',
      'HLFXGB21R88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ramsgate',
      '110627',
      'HLFXGB21R94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rayleigh',
      '110628',
      'HLFXGB21R96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading Oxford Road',
      '110629',
      'HLFXGB21S05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading Broad Street',
      '110630',
      'HLFXGB21S01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading Service Centre',
      '110631',
      'HLFXGB21S07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Thames Valley Agencies I',
      '110632',
      'HLFXGB21V37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redcar 8 High Street',
      '110633',
      'HLFXGB21S09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redditch Royal Square',
      '110634',
      'HLFXGB21S11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redhill 31 High Street',
      '110635',
      'HLFXGB21S13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Retford',
      '110636',
      'HLFXGB21S23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rhyl',
      '110637',
      'HLFXGB21S27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Richmond (Surrey)',
      '110638',
      'HLFXGB21S28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ripon',
      '110639',
      'HLFXGB21S31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rochdale  108 Yorkshire St',
      '110640',
      'HLFXGB21S36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rochdale',
      '110641',
      'HLFXGB21S35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading Broad St (110642)',
      '110642',
      'HLFXGB21S03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bury The Rock (110643)',
      '110643',
      'HLFXGB21R92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rawtenstall',
      '110644',
      'HLFXGB21R95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redruth',
      '110645',
      'HLFXGB21S19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Romford (110646)',
      '110646',
      'HLFXGB21S43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Romford (110647)',
      '110647',
      'HLFXGB21S46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reigate',
      '110648',
      'HLFXGB21S20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Palmers Green',
      '110649',
      'HLFXGB21Q79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pinner',
      '110650',
      'HLFXGB21R21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rotheram',
      '110651',
      'HLFXGB21S48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rotherham Howard Street',
      '110652',
      'HLFXGB21S51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rotherham Upper Millgate',
      '110653',
      'HLFXGB21S53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rotherham Service Centre',
      '110654',
      'HLFXGB21S52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rugeley',
      '110655',
      'HLFXGB21S61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rugby',
      '110656',
      'HLFXGB21S60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ruislip High Street',
      '110657',
      'HLFXGB21S62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Runcorn',
      '110658',
      'HLFXGB21S64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ryde',
      '110659',
      'HLFXGB21S69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Arrears (Leeds)',
      '110660',
      'HLFXGB21B04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Plymouth George St',
      '110661',
      'HLFXGB21R30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Plymouth & Cornwall Agys',
      '110662',
      'HLFXGB21R25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Albans',
      '110663',
      'HLFXGB21U08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Neots',
      '110664',
      'HLFXGB21U18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Annes',
      '110665',
      'HLFXGB21U12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Austell',
      '110666',
      'HLFXGB21U13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Helens Church Square',
      '110667',
      'HLFXGB21U16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manchester Piccadilly',
      '110668',
      'HLFXGB21O65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Helens   Hardshaw Street',
      '110669',
      'HLFXGB21U14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Saffron Walden',
      '110670',
      'HLFXGB21S73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sale',
      '110671',
      'HLFXGB21S74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Salford',
      '110672',
      'HLFXGB21S75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Salisbury',
      '110673',
      'HLFXGB21S76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North Property Unit',
      '110674',
      'HLFXGB21Q09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mortgage Processing Unit',
      '110675',
      'HLFXGB21P40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redhill Service Centre',
      '110676',
      'HLFXGB21S18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sevenoaks',
      '110677',
      'HLFXGB21T02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Scarborough',
      '110678',
      'HLFXGB21S82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Scarborough Newborough',
      '110679',
      'HLFXGB21S84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Selby',
      '110680',
      'HLFXGB21S96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Scunthorpe',
      '110681',
      'HLFXGB21S88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Scunthorpe    Asda',
      '110682',
      'HLFXGB21S89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield Meadowhall',
      '110683',
      'HLFXGB21T16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield Haymarket',
      '110684',
      'HLFXGB21T11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Seaford (110685)',
      '110685',
      'HLFXGB21S91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield High Street',
      '110686',
      'HLFXGB21T19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ess Corporate Business',
      '110687',
      'HLFXGB21H84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield Haymarket',
      '110688',
      'HLFXGB21T12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield High Street',
      '110689',
      'HLFXGB21T13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield Business Centre',
      '110690',
      'HLFXGB21T09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield High Street',
      '110691',
      'HLFXGB21T10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield The Moor',
      '110692',
      'HLFXGB21T20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South Yorks Agy 2',
      '110693',
      'HLFXGB21T87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheldon',
      '110694',
      'HLFXGB21T21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South Yorks Agy 1',
      '110695',
      'HLFXGB21T86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shipley',
      '110696',
      'HLFXGB21T28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shirley (Southampton)',
      '110697',
      'HLFXGB21T33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shipley Wellcroft',
      '110698',
      'HLFXGB21T30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shirley   West Midlands',
      '110699',
      'HLFXGB21T32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shepherds Bush',
      '110700',
      'HLFXGB21T24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shrewsbury',
      '110701',
      'HLFXGB21T38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sittingbourne',
      '110702',
      'HLFXGB21T42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redhill Agencies 1',
      '110703',
      'HLFXGB21S14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Skelmersdale Concourse',
      '110704',
      'HLFXGB21T54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) S & I Processing Unit',
      '110705',
      'HLFXGB21S72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Skipton',
      '110706',
      'HLFXGB21T56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sleaford',
      '110707',
      'HLFXGB21T57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Slough',
      '110708',
      'HLFXGB21T59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Slough Farnham Road',
      '110709',
      'HLFXGB21T63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sidcup',
      '110710',
      'HLFXGB21T39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Poole Service Centre',
      '110711',
      'HLFXGB21R44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Poole Agencies',
      '110712',
      'HLFXGB21R41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Solihull (110713)',
      '110713',
      'HLFXGB21T69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Solihull Mortgage Admin',
      '110714',
      'HLFXGB21T68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southall',
      '110715',
      'HLFXGB21T88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southampton 110716',
      '110716',
      'HLFXGB21T93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southampton 110717',
      '110717',
      'HLFXGB21T90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Solent Agencies',
      '110718',
      'HLFXGB21T64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southend 18/22 London Rd',
      '110719',
      'HLFXGB21T95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southgate 55 Chaseside',
      '110720',
      'HLFXGB21T97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southend High Street',
      '110721',
      'HLFXGB21T96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southport',
      '110722',
      'HLFXGB21T98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portsmouth Comm Rd 110723',
      '110723',
      'HLFXGB21U02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South Shields',
      '110724',
      'HLFXGB21T76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South Ockendon',
      '110725',
      'HLFXGB21T75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South East Property Unit',
      '110726',
      'HLFXGB21T71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Romford (110727)',
      '110727',
      'HLFXGB21S40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Commercial 110728',
      '110728',
      'HLFXGB21U04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Spalding Bridge Street',
      '110729',
      'HLFXGB21U05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stafford',
      '110730',
      'HLFXGB21U21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Staines',
      '110731',
      'HLFXGB21U23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Romford Mortgage Desk',
      '110732',
      'HLFXGB21S44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edgware (110733)',
      '110733',
      'HLFXGB21U28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stamford',
      '110734',
      'HLFXGB21U26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lewes Service Centre',
      '110735',
      'HLFXGB21N18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stretford',
      '110736',
      'HLFXGB21U74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stevenage Queensway',
      '110737',
      'HLFXGB21U31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stockport Chestergate',
      '110738',
      'HLFXGB21U48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stockport',
      '110739',
      'HLFXGB21U47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lewes Agencies',
      '110740',
      'HLFXGB21N17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stockton on Tees',
      '110741',
      'HLFXGB21U51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Moorgate (110742)',
      '110742',
      'HLFXGB21N75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stoke Campbell Place',
      '110743',
      'HLFXGB21U54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stoke-on-Trent',
      '110744',
      'HLFXGB21U56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stourbridge',
      '110745',
      'HLFXGB21U63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stowmarket',
      '110746',
      'HLFXGB21U64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford (110747)',
      '110747',
      'HLFXGB21U69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford upon Avon',
      '110748',
      'HLFXGB21U70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sth Manchester Service Ctr',
      '110749',
      'HLFXGB21U36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheshire Agencies 3',
      '110750',
      'HLFXGB21E38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Streatham',
      '110751',
      'HLFXGB21U72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stroud',
      '110753',
      'HLFXGB21U78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sudbury',
      '110754',
      'HLFXGB21U80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sunderland',
      '110756',
      'HLFXGB21U82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Surbiton',
      '110757',
      'HLFXGB21U88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swiss Cottage',
      '110758',
      'HLFXGB21V16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swindon Bridge Street',
      '110759',
      'HLFXGB21V13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swindon Bridge Street',
      '110760',
      'HLFXGB21V14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sutton (110761)',
      '110761',
      'HLFXGB21U92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheshire Agencies 4',
      '110762',
      'HLFXGB21E39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sutton (110763)',
      '110763',
      'HLFXGB21U96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sutton Coldfield',
      '110764',
      'HLFXGB21U94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Wales Agencies 2',
      '110765',
      'HLFXGB21W56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sutton in Ashfield',
      '110766',
      'HLFXGB21U97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swansea (110767)',
      '110767',
      'HLFXGB21V06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swansea Agencies 1',
      '110768',
      'HLFXGB21V04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swansea (110769)',
      '110769',
      'HLFXGB21V02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swansea (110770)',
      '110770',
      'HLFXGB21V05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swinton (Lancs)',
      '110771',
      'HLFXGB21V15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swindon Bridge Street',
      '110772',
      'HLFXGB21V07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tamworth',
      '110773',
      'HLFXGB21V22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Taunton East Street',
      '110774',
      'HLFXGB21V28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telford',
      '110775',
      'HLFXGB21V32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tewkesbury',
      '110776',
      'HLFXGB21V34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Thetford',
      '110777',
      'HLFXGB21V39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tiverton',
      '110778',
      'HLFXGB21V46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Todmorden',
      '110779',
      'HLFXGB21V47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Thurrock',
      '110780',
      'HLFXGB21V43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tooting',
      '110781',
      'HLFXGB21V50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Thorne',
      '110782',
      'HLFXGB21V41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Thornaby',
      '110783',
      'HLFXGB21V40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Torquay',
      '110784',
      'HLFXGB21V52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tottenham',
      '110785',
      'HLFXGB21V54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Totton',
      '110786',
      'HLFXGB21V56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Trowbridge',
      '110787',
      'HLFXGB21V58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) T0nbridge',
      '110788',
      'HLFXGB21V18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Truro',
      '110789',
      'HLFXGB21V61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tunbridge Wells (110790)',
      '110790',
      'HLFXGB21V65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tunbridge Wells (110791)',
      '110791',
      'HLFXGB21V62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Richmond (Surrey)(110792)',
      '110792',
      'HLFXGB21V67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hornchurch',
      '110793',
      'HLFXGB21L02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Upminster',
      '110794',
      'HLFXGB21V74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Upton Park',
      '110795',
      'HLFXGB21V75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Uxbridge',
      '110796',
      'HLFXGB21V78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wakefield Service Centre',
      '110797',
      'HLFXGB21V86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wakefield Agencies 1',
      '110798',
      'HLFXGB21V83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wakefield Westgate',
      '110799',
      'HLFXGB21V88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wakefield Kirkgate',
      '110800',
      'HLFXGB21V85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sutton (110801)',
      '110801',
      'HLFXGB21V94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sth Yorkshire Agencies 3',
      '110802',
      'HLFXGB21U39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wakefield Durkar  Asda',
      '110803',
      'HLFXGB21V84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hemel Hempstead Bus Ctr',
      '110805',
      'HLFXGB21K75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walkden',
      '110806',
      'HLFXGB21V92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walsall (110807)',
      '110807',
      'HLFXGB21V96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walton-on-Thames Dist',
      '110808',
      'HLFXGB21W06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walthamstow',
      '110809',
      'HLFXGB21W01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wandsworth',
      '110810',
      'HLFXGB21W10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Waltham Cross',
      '110811',
      'HLFXGB21V99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walworth',
      '110812',
      'HLFXGB21W08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warminster',
      '110813',
      'HLFXGB21W11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warrington',
      '110814',
      'HLFXGB21W13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warrington Old Mkt Place',
      '110815',
      'HLFXGB21W18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Washington',
      '110816',
      'HLFXGB21W21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warrington Service Centre',
      '110817',
      'HLFXGB21W19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Waterlooville',
      '110818',
      'HLFXGB21W23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Watford (110819)',
      '110819',
      'HLFXGB21W25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Watford (110820)',
      '110820',
      'HLFXGB21W27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wellingborough',
      '110821',
      'HLFXGB21W31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wellington (Shropshire)',
      '110822',
      'HLFXGB21W33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walsall (110823)',
      '110823',
      'HLFXGB21W29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Welwyn Garden City',
      '110824',
      'HLFXGB21W35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warrington Agencies 1',
      '110825',
      'HLFXGB21W16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warrington Agencies 2',
      '110826',
      'HLFXGB21W17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wembley',
      '110827',
      'HLFXGB21W36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harrow',
      '110828',
      'HLFXGB21W28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Bromwich',
      '110829',
      'HLFXGB21W39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southend High St (110830)',
      '110830',
      'HLFXGB21W60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harrow Business Centre',
      '110831',
      'HLFXGB21K21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bedfords & Bucks Agys 2',
      '110832',
      'HLFXGB21B44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Weston Super Mare',
      '110833',
      'HLFXGB21W62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milton Keynes (110834)',
      '110834',
      'HLFXGB21P15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston & Blackburn Agy 2',
      '110835',
      'HLFXGB21R68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South West Scotland Agy',
      '110836',
      'HLFXGB21T84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wickford',
      '110837',
      'HLFXGB21W79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Weymouth',
      '110838',
      'HLFXGB21W65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Whitehaven',
      '110839',
      'HLFXGB21W72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Whitley Bay',
      '110840',
      'HLFXGB21W73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Whitchurch (Shropshire)',
      '110841',
      'HLFXGB21W71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol (110842)',
      '110842',
      'HLFXGB21W69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rotherham Upper Millgate',
      '110843',
      'HLFXGB21S54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wigan  Crompton Street',
      '110844',
      'HLFXGB21W83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wigan',
      '110845',
      'HLFXGB21W82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Call Service Team',
      '110846',
      'HLFXGB21D60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newport (Gwent) 110848',
      '110848',
      'HLFXGB21T80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Windsor',
      '110849',
      'HLFXGB21W95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wilmslow',
      '110850',
      'HLFXGB21W89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southampton 110851',
      '110851',
      'HLFXGB21W93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wimbledon',
      '110852',
      'HLFXGB21W90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Visa',
      '110853',
      'HLFXGB21J95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Winchester',
      '110854',
      'HLFXGB21W91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Winton',
      '110855',
      'HLFXGB21W99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wisbech',
      '110856',
      'HLFXGB21Y02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Witham',
      '110857',
      'HLFXGB21Y06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Woking',
      '110858',
      'HLFXGB21Y09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Witney',
      '110859',
      'HLFXGB21Y08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wokingham',
      '110862',
      'HLFXGB21Y11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (110863)',
      '110863',
      'HLFXGB21Y13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (110864)',
      '110864',
      'HLFXGB21Y16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wythenshawe',
      '110866',
      'HLFXGB21Y38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wood Green',
      '110867',
      'HLFXGB21Y18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Woolwich',
      '110868',
      'HLFXGB21Y22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worcester',
      '110869',
      'HLFXGB21Y23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) York Parliament St (110870)',
      '110870',
      'HLFXGB21Y48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Workington',
      '110871',
      'HLFXGB21Y26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worksop',
      '110872',
      'HLFXGB21Y27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) York Parliament St (110873)',
      '110873',
      'HLFXGB21Y47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Canary Wharf',
      '110874',
      'HLFXGB21D73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worthing',
      '110875',
      'HLFXGB21Y30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wrexham',
      '110876',
      'HLFXGB21Y36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Yate North Parade',
      '110877',
      'HLFXGB21Y43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Yeovil',
      '110879',
      'HLFXGB21Y45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) York Parliament St (110880)',
      '110880',
      'HLFXGB21Y49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) York Stonebow',
      '110881',
      'HLFXGB21Y51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grp Recs 110888',
      '110888',
      'HLFXGB21K46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mortgage Dept. (H.O.)',
      '110889',
      'HLFXGB21P39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen 201 Union St',
      '110891',
      'HLFXGB21A05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen Bon Accord Centre',
      '110892',
      'HLFXGB21A06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen 395 Union Street',
      '110893',
      'HLFXGB21A04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Airdrie',
      '110894',
      'HLFXGB21A34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Alloa',
      '110895',
      'HLFXGB21A42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Arbroath',
      '110896',
      'HLFXGB21A56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ayr',
      '110897',
      'HLFXGB21A79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coatbridge',
      '110898',
      'HLFXGB21E93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clydebank',
      '110899',
      'HLFXGB21E88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dumbarton',
      '110900',
      'HLFXGB21G42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dumfries',
      '110901',
      'HLFXGB21G45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cumbernauld',
      '110902',
      'HLFXGB21F64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Didcot',
      '110903',
      'HLFXGB21DID'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walton-on-Thames Dist',
      '110904',
      'HLFXGB21WEY'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dalkeith',
      '110905',
      'HLFXGB21F78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Monmouth',
      '110906',
      'HLFXGB21MON'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Intermediary Online A/C',
      '110907',
      'HLFXGB21L67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dundee',
      '110909',
      'HLFXGB21G49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunfermline',
      '110910',
      'HLFXGB21G56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Kilbride',
      '110913',
      'HLFXGB21G77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Gyle Centre',
      '110914',
      'HLFXGB21H12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Elgin High St (110915)',
      '110915',
      'HLFXGB21H62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh George St',
      '110916',
      'HLFXGB21H09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Shandwick',
      '110917',
      'HLFXGB21H19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Cameron Toll',
      '110919',
      'HLFXGB21H08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Shandwick',
      '110920',
      'HLFXGB21H07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Falkirk',
      '110921',
      'HLFXGB21I14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Morningside',
      '110922',
      'HLFXGB21H14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morningside 110923',
      '110923',
      'HLFXGB21H20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Galashiels (110924)',
      '110924',
      'HLFXGB21I57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leith',
      '110925',
      'HLFXGB21N09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Newcraighall',
      '110926',
      'HLFXGB21H15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edin Lothian & Borders Agy',
      '110927',
      'HLFXGB21H05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Moorgate (110928)',
      '110928',
      'HLFXGB21OBS'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Argyle St',
      '110929',
      'HLFXGB21I81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Argyle St',
      '110930',
      'HLFXGB21I79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Queen St',
      '110931',
      'HLFXGB21I84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Argyle St 110932',
      '110932',
      'HLFXGB21I78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Victoria Road',
      '110933',
      'HLFXGB21I85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow 54 Sauchie (110934)',
      '110934',
      'HLFXGB21I77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Greenock',
      '110935',
      'HLFXGB21J52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow 54 Sauchie (110936)',
      '110936',
      'HLFXGB21I76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hamilton',
      '110937',
      'HLFXGB21J98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gtr Glasgow & Argyll Agy',
      '110938',
      'HLFXGB21J63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hawick',
      '110939',
      'HLFXGB21K38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grangemouth',
      '110940',
      'HLFXGB21J38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverness',
      '110941',
      'HLFXGB21L71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Tottenham Crt Rd',
      '110942',
      'HLFXGB21BLO'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Irvine',
      '110943',
      'HLFXGB21L80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glenrothes',
      '110944',
      'HLFXGB21J16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Johnstone (110946)',
      '110946',
      'HLFXGB21L89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kilmarnock',
      '110947',
      'HLFXGB21M20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kilmarnock King Street',
      '110948',
      'HLFXGB21M23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkaldy',
      '110949',
      'HLFXGB21M44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lanark',
      '110950',
      'HLFXGB21M61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Livingston',
      '110951',
      'HLFXGB21N55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leven',
      '110952',
      'HLFXGB21N14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Motherwell',
      '110953',
      'HLFXGB21P42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Montrose',
      '110954',
      'HLFXGB21P24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Musselburgh',
      '110955',
      'HLFXGB21P45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oban',
      '110956',
      'HLFXGB21Q50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paisley The Cross',
      '110957',
      'HLFXGB21Q71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paisley The Cross (110958)',
      '110958',
      'HLFXGB21Q72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Perth',
      '110959',
      'HLFXGB21R09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Christchurch',
      '110960',
      'HLFXGB21CHC'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basingstoke Wote St',
      '110961',
      'HLFXGB21BAS'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peterhead',
      '110962',
      'HLFXGB21R16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swansea (110963)',
      '110963',
      'HLFXGB21SWN'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basingstoke (110964)',
      '110964',
      'HLFXGB31ALT'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rutherglen',
      '110965',
      'HLFXGB21S68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Saltcoats',
      '110966',
      'HLFXGB21S77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Andrews',
      '110967',
      'HLFXGB21U10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Londonderry',
      '110968',
      'HLFXGB21O11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wishaw',
      '110969',
      'HLFXGB21Y03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Larne',
      '110970',
      'HLFXGB21M70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lurgan',
      '110971',
      'HLFXGB21O26'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northern Ireland Agy's 1",
      '110972',
      'HLFXGB21Q19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Business Centre',
      '110973',
      'HLFXGB21B53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Connswater',
      '110974',
      'HLFXGB21B55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stirling',
      '110975',
      'HLFXGB21U40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Castlecourt',
      '110976',
      'HLFXGB21B54'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northern Ireland Agy's 2",
      '110977',
      'HLFXGB21Q20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stranraer',
      '110978',
      'HLFXGB21U65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Londonderry Richmond Cntr',
      '110983',
      'HLFXGB21O12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Omagh',
      '110987',
      'HLFXGB21Q55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ballymena',
      '110988',
      'HLFXGB21A89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Shaftesbury Sq',
      '110989',
      'HLFXGB21B63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Donegall Sq North',
      '110990',
      'HLFXGB21B58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Arthur Street',
      '110991',
      'HLFXGB21B51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coleraine The Diamond',
      '110993',
      'HLFXGB21F05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Enniskillen High Street',
      '110994',
      'HLFXGB21H73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lisburn',
      '110995',
      'HLFXGB21N38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portadown High Street',
      '110996',
      'HLFXGB21R49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bangor (Northern Ireland)',
      '110997',
      'HLFXGB21A99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Donegall Place',
      '111001',
      'HLFXGB21B57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Colmore Row',
      '111002',
      'HLFXGB21B97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackpool 20 Clifton St',
      '111003',
      'HLFXGB21C22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bolton Oxford Street',
      '111004',
      'HLFXGB21C44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '111006',
      'HLFXGB21C66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Baldwin Street',
      '111007',
      'HLFXGB21D04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds White Rose Centre',
      '111008',
      'HLFXGB21M94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Queens Arcade',
      '111009',
      'HLFXGB21D88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheltenham 111010',
      '111010',
      'HLFXGB21E33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coventry High St (111011)',
      '111011',
      'HLFXGB21F27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Derby Cornmarket',
      '111012',
      'HLFXGB21G04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Doncaster High Street',
      '111013',
      'HLFXGB21G20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh George St',
      '111014',
      'HLFXGB21H06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Argyle St',
      '111015',
      'HLFXGB21I74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Guildford 172 High Street',
      '111016',
      'HLFXGB21J66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harrogate Vic Gdn Shpg Ctr',
      '111017',
      'HLFXGB21K17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huddersfield (111018)',
      '111018',
      'HLFXGB21L25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (111019)',
      '111019',
      'HLFXGB21L34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Keighley Low Street',
      '111020',
      'HLFXGB21L95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leicester Gallowtree Gate',
      '111021',
      'HLFXGB21M98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Lord Street',
      '111022',
      'HLFXGB21N48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London New Oxford Street',
      '111023',
      'HLFXGB21O01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Luton George Street',
      '111025',
      'HLFXGB21O30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Maidstone High Street',
      '111026',
      'HLFXGB21O45'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Man'ster Piccadilly111027",
      '111027',
      'HLFXGB21O63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Middlesborough Albert Rd',
      '111028',
      'HLFXGB21O95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Haymkt (111029)',
      '111029',
      'HLFXGB21P72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Norwich London Street',
      '111030',
      'HLFXGB21Q30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham Clumber Street',
      '111031',
      'HLFXGB21Q37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading Queen Victoria St',
      '111032',
      'HLFXGB21S06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield Norfolk Row',
      '111034',
      'HLFXGB21T18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southampton 111035',
      '111035',
      'HLFXGB21T92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southport Hoghton Street',
      '111036',
      'HLFXGB21U01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stockport Great Underbank',
      '111037',
      'HLFXGB21U49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wakefield The Springs',
      '111038',
      'HLFXGB21V87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (111039)',
      '111039',
      'HLFXGB21Y15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) York Parliament Street',
      '111040',
      'HLFXGB21Y50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds The Headrow',
      '111041',
      'HLFXGB21M92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bank Section Return',
      '111042',
      'HLFXGB21B02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Roundhay 269 Roundhay Rd',
      '111043',
      'HLFXGB21S58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen 201 Union St',
      '111044',
      'HLFXGB21A03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dundee Reform Street',
      '111045',
      'HLFXGB21G50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peterborough (111046)',
      '111046',
      'HLFXGB21R15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Teesside Agencies',
      '111047',
      'HLFXGB21V30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portsmouth Edinburgh Road',
      '111048',
      'HLFXGB21R56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingston-U-Thames Fife Rd',
      '111050',
      'HLFXGB21M37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Plymouth Armada Way',
      '111051',
      'HLFXGB21R26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Exeter Post Office Street',
      '111052',
      'HLFXGB21I10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worthing 47 Chapel Road',
      '111053',
      'HLFXGB21Y31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton (111054)',
      '111054',
      'HLFXGB21C99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eastbourne Terminus Road',
      '111056',
      'HLFXGB21G94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walsall (111057)',
      '111057',
      'HLFXGB21V98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bath (111058)',
      '111058',
      'HLFXGB21B30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Frodshamst(111059)',
      '111059',
      'HLFXGB21E46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Fenchurch Street',
      '111060',
      'HLFXGB21N86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Fishergate (111061)',
      '111061',
      'HLFXGB21R73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swansea (111063)',
      '111063',
      'HLFXGB21V03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ayr 177 High Street',
      '111064',
      'HLFXGB21A80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hamilton Regent Way',
      '111065',
      'HLFXGB21J99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kensington 175 Ken H St',
      '111066',
      'HLFXGB21M05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swindon Bridge Street',
      '111067',
      'HLFXGB21V11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ipswich Westgate Street',
      '111068',
      'HLFXGB21L79'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warrington 12 Butt'mkt St",
      '111069',
      'HLFXGB21W15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London 50 High Holborn',
      '111071',
      'HLFXGB21N71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Erdington',
      '111072',
      'HLFXGB21H77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Watford (111073)',
      '111073',
      'HLFXGB21W26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Crossgates Centre',
      '111074',
      'HLFXGB21M85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northampton Abington St',
      '111075',
      'HLFXGB21Q17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sutton (111076)',
      '111076',
      'HLFXGB21U93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bedford High Street',
      '111077',
      'HLFXGB21B42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Solihull (111078)',
      '111078',
      'HLFXGB21T67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Torquay 53 Union Street',
      '111079',
      'HLFXGB21V53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dudley High Street',
      '111080',
      'HLFXGB21G39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grimsby Victoria Street',
      '111081',
      'HLFXGB21J60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lincoln High Street',
      '111082',
      'HLFXGB21N34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London 122 Cheapside',
      '111083',
      'HLFXGB21N70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southend',
      '111084',
      'HLFXGB21T94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sunderland Market Square',
      '111085',
      'HLFXGB21U84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kings Heath',
      '111086',
      'HLFXGB21M26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worksop Bridge Place',
      '111088',
      'HLFXGB21Y29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ealing (111091)',
      '111091',
      'HLFXGB21W47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stoke-on-Trent Market Sq',
      '111092',
      'HLFXGB21U57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Darlington High Row',
      '111093',
      'HLFXGB21F87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Motherwell Muir Street',
      '111094',
      'HLFXGB21P44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Perth 175 High Street',
      '111095',
      'HLFXGB21R10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kings Lynn 46 High Street',
      '111096',
      'HLFXGB21M29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gloucester Westgate St',
      '111097',
      'HLFXGB21J23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burton-on-Trent High St',
      '111098',
      'HLFXGB21D45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Lovell Park Road',
      '111099',
      'HLFXGB21M89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hounslow City (111100)',
      '111100',
      'HLFXGB21L11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ilford 76 High Road',
      '111101',
      'HLFXGB21L60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Beeston 44 High Road',
      '111103',
      'HLFXGB21B49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkcaldy 128 High Street',
      '111105',
      'HLFXGB21M48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redhill 25 High Street',
      '111106',
      'HLFXGB21S12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Acocks Green',
      '111107',
      'HLFXGB21A26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tunbridge Wells (111108)',
      '111108',
      'HLFXGB21V64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wood Green 41 High Road',
      '111109',
      'HLFXGB21Y19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ruislip (111111)',
      '111111',
      'HLFXGB21G95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackburn King William St',
      '111113',
      'HLFXGB21C21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burgess Hill',
      '111114',
      'HLFXGB21D34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chesterfieldknifesmithgate',
      '111115',
      'HLFXGB21E58'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chiswick 244 Ch'k High Rd",
      '111116',
      'HLFXGB21E68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paisley The Cross',
      '111117',
      'HLFXGB21Q73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barnsley Cheapside',
      '111118',
      'HLFXGB21B14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Palmers Green 329 Grn Lns',
      '111119',
      'HLFXGB21Q80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chatham 248 High Street',
      '111120',
      'HLFXGB21E23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kidderminster Vicar St',
      '111121',
      'HLFXGB21M14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Edgware Rd (111122)',
      '111122',
      'HLFXGB21N93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromsgrove (111123)',
      '111123',
      'HLFXGB21D28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chelmsford Exchange Way',
      '111124',
      'HLFXGB21E29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Deal 38 High Street',
      '111125',
      'HLFXGB21F96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds King Edward Street',
      '111126',
      'HLFXGB21M88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dalston 45 Kingsland H St',
      '111127',
      'HLFXGB21F83'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northfield 820 Bri'l Rd S",
      '111128',
      'HLFXGB21Q23'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ellesmere Port Riv'ton Rd",
      '111129',
      'HLFXGB21H66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheldon 2230 Coventry Rd',
      '111130',
      'HLFXGB21T22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingsbury',
      '111131',
      'HLFXGB21M31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Castleford 66 Carlton St',
      '111132',
      'HLFXGB21E01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wigan Mesnes Street',
      '111133',
      'HLFXGB21W85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redditch',
      '111134',
      'HLFXGB21S10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bexleyheath Broadway',
      '111135',
      'HLFXGB21B77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bridlington King Street',
      '111136',
      'HLFXGB21C91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hfx Commercial St 111137',
      '111137',
      'HLFXGB21J84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Ham 121 High St Nth',
      '111138',
      'HLFXGB21G74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pinner 55 Bridge Street',
      '111139',
      'HLFXGB21R22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Greenford',
      '111140',
      'HLFXGB21J50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fareham 57 West Street',
      '111142',
      'HLFXGB21I21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hastings (111143)',
      '111143',
      'HLFXGB21K31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle U Lyme High St',
      '111145',
      'HLFXGB21P73'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burnley St James's Street",
      '111146',
      'HLFXGB21D40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basingstoke (111147)',
      '111147',
      'HLFXGB21B26'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North Finchley 794 Hi' Rd",
      '111148',
      'HLFXGB21Q02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walthamstow 133 High St',
      '111149',
      'HLFXGB21W03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Merchant Street',
      '111150',
      'HLFXGB21D10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crouch End',
      '111151',
      'HLFXGB21F47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Penge',
      '111152',
      'HLFXGB21Q99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Frodshamst(111153)',
      '111153',
      'HLFXGB21E54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tottenham 520 High Road',
      '111155',
      'HLFXGB21V55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Airdrie Bank Street',
      '111156',
      'HLFXGB21A36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Catford 122 Rushey Green',
      '111157',
      'HLFXGB21E04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Camberley 56A High Street',
      '111158',
      'HLFXGB21D65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Beverley Market Place',
      '111159',
      'HLFXGB21B73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Retford',
      '111160',
      'HLFXGB21S24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bridgwater Cornhill',
      '111161',
      'HLFXGB21C89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London 110 Moorgate',
      '111162',
      'HLFXGB21N69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Garforth',
      '111164',
      'HLFXGB21I60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Quinton 534 Hagley Rd Wst',
      '111165',
      'HLFXGB21R91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bramley (West Yorkshire)',
      '111166',
      'HLFXGB21C75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clifton (Avon)',
      '111167',
      'HLFXGB21E85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheadle (Cheshire)',
      '111168',
      'HLFXGB21E25'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lowestoft 106 L'don Rd Nt",
      '111169',
      'HLFXGB21O23'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Greenock 42 Wst B'hall St",
      '111171',
      'HLFXGB21J54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashton-U-Lyne 65 Old St',
      '111172',
      'HLFXGB21A72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kilmarnock 73 King Street',
      '111173',
      'HLFXGB21M22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Deptford',
      '111174',
      'HLFXGB21G03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dover Biggin Street',
      '111176',
      'HLFXGB21G31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Great Barr',
      '111177',
      'HLFXGB21B98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morley Queen Street',
      '111178',
      'HLFXGB21P33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Scarborough Huntriss Row',
      '111180',
      'HLFXGB21S83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eastleigh Market Street',
      '111181',
      'HLFXGB21G98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Altrincham 47 George St',
      '111182',
      'HLFXGB21A48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford (111183)',
      '111183',
      'HLFXGB21U68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Kilbride Southgate',
      '111185',
      'HLFXGB21G80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Farnborough Queensmead',
      '111186',
      'HLFXGB21I25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon (111188)',
      '111188',
      'HLFXGB21F51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Havant 1 West St',
      '111189',
      'HLFXGB21K36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cambridge St Andrews St',
      '111190',
      'HLFXGB21D71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gosport 19 High St',
      '111191',
      'HLFXGB21J35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Camden (111192)',
      '111192',
      'HLFXGB21N68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crewe Victoria Street',
      '111193',
      'HLFXGB21F42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Horsforth Town Street',
      '111194',
      'HLFXGB21L05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glenrothes Lyon Square',
      '111196',
      'HLFXGB21J18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edmonton 154 Fore Street',
      '111197',
      'HLFXGB21H59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ripon Fishergate',
      '111198',
      'HLFXGB21S33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stockton on Tees High St',
      '111199',
      'HLFXGB21U53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dumbarton',
      '111200',
      'HLFXGB21G44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brixton Electric Avenue',
      '111201',
      'HLFXGB21D17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leigh 9 Bradshawgate',
      '111202',
      'HLFXGB21N06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bognor Regis 19 London Rd',
      '111204',
      'HLFXGB21C38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gt Yarmouth 27 Market Pl',
      '111205',
      'HLFXGB21J62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Widnes',
      '111206',
      'HLFXGB21W80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Newington Road',
      '111207',
      'HLFXGB21H16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harrow (111208)',
      '111208',
      'HLFXGB21K19'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Merthyr Tydfil 132 Hi'st",
      '111209',
      'HLFXGB21O91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) High Wycombe White Hart',
      '111210',
      'HLFXGB21K90'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newport 37 Comm'cial St",
      '111211',
      'HLFXGB21P82'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swiss Cottage  N'thways Pd",
      '111212',
      'HLFXGB21V17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Woking 24 Commercial Way',
      '111213',
      'HLFXGB21Y10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wetherby 49 Market Place',
      '111214',
      'HLFXGB21W64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Winton 405 Wimbourne Road',
      '111215',
      'HLFXGB21Y01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dewsbury Corporation St',
      '111216',
      'HLFXGB21G12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hunslet',
      '111217',
      'HLFXGB21L37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Maidenhead 116 High St',
      '111218',
      'HLFXGB21O43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edgware (111220)',
      '111220',
      'HLFXGB21P07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gosforth 157 High St',
      '111221',
      'HLFXGB21J32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Selby Gowthorpe',
      '111222',
      'HLFXGB21S98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dartford Spital Street',
      '111223',
      'HLFXGB21F92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Harehills Lane',
      '111224',
      'HLFXGB21M86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stretford 108 Arndale Ctr',
      '111225',
      'HLFXGB21U75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barrow 200 Dalton Road',
      '111226',
      'HLFXGB21B18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sale (111228)',
      '111228',
      'HLFXGB21V76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Musselburgh 103 High St',
      '111229',
      'HLFXGB21P47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Byres Road (111230)',
      '111230',
      'HLFXGB21I80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shepherds Bush',
      '111231',
      'HLFXGB21T25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newry',
      '111233',
      'HLFXGB21NRY'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wrexham 53 Hope Street',
      '111234',
      'HLFXGB21Y37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Holloway Road',
      '111235',
      'HLFXGB21N91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bangor (Ni) 63 Main St',
      '111236',
      'HLFXGB21A97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wallasey',
      '111237',
      'HLFXGB21V93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hartlepool York Road',
      '111238',
      'HLFXGB21K25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oxford Sales & Advice Cntr',
      '111239',
      'HLFXGB31Y54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunfermline 92 High Street',
      '111241',
      'HLFXGB21G57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wishaw Main Street',
      '111242',
      'HLFXGB21Y05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Billingham Queensway',
      '111243',
      'HLFXGB21B85'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tooting 48 Tooting Hi' St",
      '111244',
      'HLFXGB21V51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bramley (111245)',
      '111245',
      'HLFXGB21K66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Irvine Bridgegate',
      '111247',
      'HLFXGB21L82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Byres Road (111248)',
      '111248',
      'HLFXGB21A55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Armley',
      '111249',
      'HLFXGB21A59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Moortown',
      '111250',
      'HLFXGB21P26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basildon 36 East Walk',
      '111251',
      'HLFXGB21B23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Margate 51 High Street',
      '111252',
      'HLFXGB21O75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Whitby',
      '111253',
      'HLFXGB21W68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coatbridge 67/69 Main St',
      '111254',
      'HLFXGB21E95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stirling Thistle Centre',
      '111255',
      'HLFXGB21U42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Beaconsfield',
      '111259',
      'HLFXGB21B36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Boar Lane',
      '111260',
      'HLFXGB21M81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lewisham 46 Riverdale',
      '111261',
      'HLFXGB21N20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barking Ripple Road',
      '111262',
      'HLFXGB21B08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mansfield 5 Regent Street',
      '111264',
      'HLFXGB21O68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Muswell Hill',
      '111265',
      'HLFXGB21P48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wellingborough Silver St',
      '111266',
      'HLFXGB21W32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cwmbran South Walk',
      '111267',
      'HLFXGB21F74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Victoria Road',
      '111268',
      'HLFXGB21I86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Boston Strait Bargate',
      '111269',
      'HLFXGB21C56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield High Street',
      '111271',
      'HLFXGB21T14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bracknell 15 High Street',
      '111272',
      'HLFXGB21C62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow The Forge(111273)',
      '111273',
      'HLFXGB21I63'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walton Vale 57 Wal'n Vale",
      '111274',
      'HLFXGB21W05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hoddesdon (111275)',
      '111275',
      'HLFXGB21K96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hastings (111276)',
      '111276',
      'HLFXGB21B75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Neath 4   Queen Street',
      '111277',
      'HLFXGB21P55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Helens Ormskirk Street',
      '111279',
      'HLFXGB21U17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Arnold',
      '111280',
      'HLFXGB21A61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bury The Rock (111281)',
      '111281',
      'HLFXGB21D53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paddington',
      '111282',
      'HLFXGB21Q69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Colchester Culver St West',
      '111283',
      'HLFXGB21E97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newark Market Place',
      '111284',
      'HLFXGB21P63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Comely Bank',
      '111285',
      'HLFXGB21U46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Macclesfield Mill Street',
      '111286',
      'HLFXGB21O38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Weymouth 29 St Mary St',
      '111287',
      'HLFXGB21W66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newhaven 9 Newhaven Sq',
      '111288',
      'HLFXGB21P77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walton-on-Thames Dist',
      '111290',
      'HLFXGB21H82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Orpington High Street',
      '111291',
      'HLFXGB21Q59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southall 59 The Broadway',
      '111293',
      'HLFXGB21T89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingstanding King Road',
      '111295',
      'HLFXGB21M33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackwood 95 High Street',
      '111296',
      'HLFXGB21C29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Wester Hailes',
      '111297',
      'HLFXGB21H21'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kilburn 52 K'burn High Rd",
      '111298',
      'HLFXGB21M16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newport (I O W) 64 High St',
      '111299',
      'HLFXGB21P81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newtownabbey',
      '111301',
      'HLFXGB21P89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pudsey Market Place',
      '111302',
      'HLFXGB21R84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Norwich City (111304)',
      '111304',
      'HLFXGB21Q32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hove Blatchington Road',
      '111305',
      'HLFXGB21L16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rotherham Effingham St',
      '111306',
      'HLFXGB21S50'
    ],
    ['LLOYDS BANK PLC Fps Live Proving Test', '111307', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hornchurch 108 High St',
      '111308',
      'HLFXGB21L03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harlow Harvey Centre',
      '111309',
      'HLFXGB21K84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Grinstead High St',
      '111310',
      'HLFXGB21G72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Trowbridge 18 Silver St',
      '111311',
      'HLFXGB21V60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sidcup 66 Sidcup High St',
      '111312',
      'HLFXGB21T41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunstable 36A High St Nth',
      '111314',
      'HLFXGB21G64'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shirley 40 Shirley Hi'st",
      '111315',
      'HLFXGB21T34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) White City',
      '111316',
      'HLFXGB21WST'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheerness',
      '111317',
      'HLFXGB21T06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worcester Park (111319)',
      '111319',
      'HLFXGB21Y24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Yate',
      '111320',
      'HLFXGB21Y42'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sutton Coldfield B'ham Rd",
      '111321',
      'HLFXGB21U95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Bromwich 172 High St',
      '111322',
      'HLFXGB21W41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lichfield',
      '111324',
      'HLFXGB21N27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hackney',
      '111325',
      'HLFXGB21J74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bonnyrigg',
      '111327',
      'HLFXGB21C48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milngavie',
      '111328',
      'HLFXGB21P09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Didsbury',
      '111331',
      'HLFXGB21G13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Penicuik 17 John Street',
      '111332',
      'HLFXGB21R02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pontypridd Taff Street',
      '111334',
      'HLFXGB21R39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingswood Regent St',
      '111336',
      'HLFXGB21M40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Richmond (Surrey)(111337)',
      '111337',
      'HLFXGB21V68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hammersmith 101 King St',
      '111338',
      'HLFXGB21K04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stevenage 17 Market Place',
      '111339',
      'HLFXGB21U29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huntingdon High Street',
      '111341',
      'HLFXGB21L39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Pollok',
      '111342',
      'HLFXGB21I83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walworth',
      '111344',
      'HLFXGB21W09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bellshill',
      '111345',
      'HLFXGB21B64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newtownards High Street',
      '111346',
      'HLFXGB21P91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Normanton',
      '111348',
      'HLFXGB21P94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oxford (111349)',
      '111349',
      'HLFXGB21Q65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wellington New Street',
      '111350',
      'HLFXGB21W34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Strood',
      '111352',
      'HLFXGB21U77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chingford',
      '111353',
      'HLFXGB21E63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leyton (111356)',
      '111356',
      'HLFXGB21N26'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Westcliff 166 H'let Ct Rd",
      '111358',
      'HLFXGB21W59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harlow Harvey Centre',
      '111359',
      'HLFXGB21K12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Linlithgow',
      '111360',
      'HLFXGB21N35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sleaford 34 Southgate',
      '111362',
      'HLFXGB21T58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Enfield Church Street',
      '111363',
      'HLFXGB21H72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stafford (111366)',
      '111366',
      'HLFXGB21E35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheetham Hill Bury Old Rd',
      '111367',
      'HLFXGB21E27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stockton on Tees (111368)',
      '111368',
      'HLFXGB21Y40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromley High St (111369)',
      '111369',
      'HLFXGB21D22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hounslow City (111371)',
      '111371',
      'HLFXGB21W76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Uxbridge Chequers Square',
      '111373',
      'HLFXGB21V81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crawley High Street',
      '111376',
      'HLFXGB21F39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bridgnorth High Street',
      '111377',
      'HLFXGB21C87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Andover Bridge Street',
      '111379',
      'HLFXGB21A53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southend High St (111380)',
      '111380',
      'HLFXGB21N07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nelson 19 Manchester Road',
      '111381',
      'HLFXGB21P57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cannock',
      '111382',
      'HLFXGB21D74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banbury Bridge Street',
      '111383',
      'HLFXGB21A93'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cirencester 28 C'lade St",
      '111385',
      'HLFXGB21E76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Slough 70 High Street',
      '111386',
      'HLFXGB21T62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barnet (111389)',
      '111389',
      'HLFXGB21R62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Bridgford',
      '111390',
      'HLFXGB21W37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gravesend High Street',
      '111392',
      'HLFXGB21J46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Epsom (111394)',
      '111394',
      'HLFXGB21H76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Surbiton 6 Victoria Road',
      '111395',
      'HLFXGB21U89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield Hillsborough',
      '111399',
      'HLFXGB21T15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rochdale 15 Yorkshire St',
      '111400',
      'HLFXGB21S38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mexborough',
      '111401',
      'HLFXGB21O92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bitterne',
      '111403',
      'HLFXGB21C16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huyton 64 Derby Road',
      '111404',
      'HLFXGB21L42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manchester Piccadilly',
      '111406',
      'HLFXGB21P41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Staines 50 High Street',
      '111407',
      'HLFXGB21U25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Baintree Market Place',
      '111410',
      'HLFXGB21A84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milton Keynes (111411)',
      '111411',
      'HLFXGB21P11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ossett Bank Street',
      '111412',
      'HLFXGB21Q61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton (111413)',
      '111413',
      'HLFXGB21Y53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Albans Victoria Street',
      '111414',
      'HLFXGB21U09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leyland 16 Hough Lane',
      '111418',
      'HLFXGB21N22'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) B'ham 541 Bearwood Road",
      '111420',
      'HLFXGB21B79'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sparkhill 806 Straft'd Rd",
      '111421',
      'HLFXGB21U06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bulwell',
      '111422',
      'HLFXGB21D32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Purley',
      '111423',
      'HLFXGB21R87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chorlton-C-Hardy 579 Bmr',
      '111425',
      'HLFXGB21E72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Llanelli Vaughan Street',
      '111427',
      'HLFXGB21N59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cumbernauld 7 Forth Walk',
      '111428',
      'HLFXGB21F66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clapham Junction (111429)',
      '111429',
      'HLFXGB21E79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South Shields Fowler St',
      '111430',
      'HLFXGB21T78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Finsbury Park',
      '111431',
      'HLFXGB21N87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mitcham',
      '111432',
      'HLFXGB21P19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Putney 136 Putney High St',
      '111434',
      'HLFXGB21R89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingsland (111435)',
      '111435',
      'HLFXGB21U55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brentwood 89 High Street',
      '111436',
      'HLFXGB21C81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gorleston 147 High Street',
      '111437',
      'HLFXGB21J30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Prestwich Bury New Road',
      '111438',
      'HLFXGB21R77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bootle (111439)',
      '111439',
      'HLFXGB21F45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leigh (111441)',
      '111441',
      'HLFXGB21A73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tadcaster Kirkgate',
      '111442',
      'HLFXGB21V20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Romford (111443)',
      '111443',
      'HLFXGB21S39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Moreton 217 Hoylake Road',
      '111445',
      'HLFXGB21P30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eccles Church Street',
      '111446',
      'HLFXGB21H03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sidcup',
      '111447',
      'HLFXGB21U99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Richmond 21 The Quadrant',
      '111449',
      'HLFXGB21S29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Camden (111452)',
      '111452',
      'HLFXGB21M08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swindon Bridge Street',
      '111454',
      'HLFXGB21V08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '111456',
      'HLFXGB21C65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Epsom (111457)',
      '111457',
      'HLFXGB21B06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Teddington',
      '111458',
      'HLFXGB21V29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hatfield',
      '111460',
      'HLFXGB21K32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Loughborough High Street',
      '111461',
      'HLFXGB21O18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Midsomer Norton',
      '111464',
      'HLFXGB21P05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halesowen Hagley Street',
      '111466',
      'HLFXGB21J78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Farnworth 58 Brackley St',
      '111467',
      'HLFXGB21I29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hayes',
      '111468',
      'HLFXGB21K41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tonbridge Angel Walk',
      '111469',
      'HLFXGB21V49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Islington (111472)',
      '111472',
      'HLFXGB21L84'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Middleton 1 Midd'ton Gdns",
      '111473',
      'HLFXGB21O99'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Canvey Island 50 F'W'k Rd",
      '111474',
      'HLFXGB21D80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Denton 31 Manchester Road',
      '111475',
      'HLFXGB21G02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (111476)',
      '111476',
      'HLFXGB21L29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worcester The Cross',
      '111479',
      'HLFXGB21Y25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Feltham',
      '111480',
      'HLFXGB21I31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Maghull Westway',
      '111481',
      'HLFXGB21O41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Farnborough (111482)',
      '111482',
      'HLFXGB31Y52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walton-on-Thames Dist',
      '111483',
      'HLFXGB21W07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shipley Well Croft',
      '111484',
      'HLFXGB21T29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rayleigh 102 High Street',
      '111485',
      'HLFXGB21R97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Yeadon',
      '111486',
      'HLFXGB21Y44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hessle The Square',
      '111487',
      'HLFXGB21K86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pudsey Cash Office',
      '111488',
      'HLFXGB21R83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) UK Mortgages - 111489',
      '111489',
      'HLFXIMD1001'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashford Park Mall (Kent)',
      '111491',
      'HLFXGB21A67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Canterbury The Parade',
      '111492',
      'HLFXGB21D78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Yeadon (111493)',
      '111493',
      'HLFXGB21J69'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bury St Edmunds 59 C'hill",
      '111494',
      'HLFXGB21D51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bishops Stortford Sth St',
      '111495',
      'HLFXGB21C12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oldham Market Place',
      '111497',
      'HLFXGB21Q53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bordon',
      '111504',
      'HLFXGB21C51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burgess Hill 32 Church Rd',
      '111506',
      'HLFXGB21D37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chichester South Street',
      '111507',
      'HLFXGB21E62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crowborough',
      '111508',
      'HLFXGB21F49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rye',
      '111511',
      'HLFXGB21S70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eastbourne Hampden Park',
      '111512',
      'HLFXGB21G93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eastbourne Albert Parade',
      '111513',
      'HLFXGB21G90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aldershot (111515)',
      '111515',
      'HLFXGB21I27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hailsham',
      '111517',
      'HLFXGB21J76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Guildford (111518)',
      '111518',
      'HLFXGB21K27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Haywards Heath 86 Sth Rd',
      '111520',
      'HLFXGB21K44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crawley (111521)',
      '111521',
      'HLFXGB21L01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lewes',
      '111524',
      'HLFXGB21N16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Littlehampton',
      '111525',
      'HLFXGB21N40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fareham (111526)',
      '111526',
      'HLFXGB21N67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Midhurst',
      '111527',
      'HLFXGB21P01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Seaford (111530)',
      '111530',
      'HLFXGB21Q96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Polegate',
      '111531',
      'HLFXGB21R33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Littlehampton (111532)',
      '111532',
      'HLFXGB21S66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Seaford 40 Broad Street',
      '111533',
      'HLFXGB21S93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worthing (111534)',
      '111534',
      'HLFXGB21T35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Steyning',
      '111535',
      'HLFXGB21U33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Uckfield',
      '111537',
      'HLFXGB21V69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton (111540)',
      '111540',
      'HLFXGB21C95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hmsl Cardiff',
      '111542',
      'HLFXGB21K95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Trinity Court',
      '111543',
      'HLFXGB21D89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Arrears (Agents)',
      '111545',
      'HLFXGB21B03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Arrears (Leics)',
      '111547',
      'HLFXGB21B05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glos & North Wilts Agy 1',
      '111550',
      'HLFXGB21J19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glos & Nth Wilts Agy 2',
      '111551',
      'HLFXGB21J20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shareholder Services',
      '111552',
      'HLFXGB21T04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hfs Call Centre',
      '111553',
      'HLFXGB21K88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cambridge Agencies 2',
      '111554',
      'HLFXGB21D68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Heal Aip Citrix Trial',
      '111555',
      'HLFXGB21K67'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Central Midlands Agy's 2",
      '111557',
      'HLFXGB21E17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Life (Cashiering)',
      '111558',
      'HLFXGB21J87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheshire Agencies 2',
      '111559',
      'HLFXGB21E37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redhill Agencies 2',
      '111561',
      'HLFXGB21S15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South London Agencies',
      '111562',
      'HLFXGB21T73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Yorkshire Agencies 1',
      '111564',
      'HLFXGB21G86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Central & East Scot Agy',
      '111565',
      'HLFXGB21E09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nth East Scotland Agy 1',
      '111566',
      'HLFXGB21Q43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sth & West Midlands Agy 2',
      '111567',
      'HLFXGB21U35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peterborough (111569)',
      '111569',
      'HLFXGB21N30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Norwich Agencies',
      '111570',
      'HLFXGB21Q27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North & West Midlands Agy',
      '111574',
      'HLFXGB21P95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North London Agencies',
      '111575',
      'HLFXGB21Q05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North West London Agencies',
      '111576',
      'HLFXGB21Q12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South West London Agencies',
      '111578',
      'HLFXGB21T82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sussex Coast Agencies',
      '111579',
      'HLFXGB21U91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Customer A/C Lending Servs',
      '111580',
      'HLFXGB21F70'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) P'book Queries Ctop",
      '111585',
      'HLFXGB21Q89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Insurance Admin',
      '111587',
      'HLFXGB21L64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Procis Support Unit',
      '111588',
      'HLFXGB21R79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lpr Ground Floor Moneybox',
      '111593',
      'HLFXGB21O25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Partners Atms 2',
      '111595',
      'HLFXGB21Q85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Partners Atms 3',
      '111596',
      'HLFXGB21Q86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Matrix',
      '111597',
      'HLFXGB21O80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Matrix (Su)',
      '111598',
      'HLFXGB21O81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Central Cash Tchqs',
      '111599',
      'HLFXGB21E12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Essex Agencies 2',
      '111600',
      'HLFXGB21H97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Direct Admin Unit',
      '111601',
      'HLFXGB21J82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Showcase',
      '111612',
      'HLFXGB21O02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) N.E. Midlands Agencies',
      '111615',
      'HLFXGB21P51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lincoln Agencies 2',
      '111617',
      'HLFXGB21N31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (111618)',
      '111618',
      'HLFXGB21G87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Arndale Centre Eastbourne',
      '111619',
      'HLFXGB21A60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pay & Save',
      '111621',
      'HLFXGB21Q87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Moneycard Desk (Man)',
      '111622',
      'HLFXGB21P22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telepost Cash',
      '111623',
      'HLFXGB21V31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Postal Account',
      '111624',
      'HLFXGB21R61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Central Cash',
      '111625',
      'HLFXGB21E11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Savings Direct',
      '111626',
      'HLFXGB21J91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Payment Clearing Services',
      '111627',
      'HLFXGB21Q88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen 201 Union St',
      '111634',
      'HLFXGB21A09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Countrywide (Scotland)',
      '111635',
      'HLFXGB21F19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hps - Hxd Mortgages',
      '111636',
      'HLFXGB21L20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Countrywide (North West)',
      '111637',
      'HLFXGB21F16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northern Ireland Mtg Desk',
      '111638',
      'HLFXGB21Q21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nth East Scotland Agy 2',
      '111639',
      'HLFXGB21Q44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paisley Mtg Desk',
      '111640',
      'HLFXGB21Q74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Mtg Desk',
      '111641',
      'HLFXGB21M90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Central Scotland Mtg Desk',
      '111642',
      'HLFXGB21E19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Mtg Desk',
      '111643',
      'HLFXGB21C68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Countrywide (North)',
      '111644',
      'HLFXGB21F17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nth West London Agys 2',
      '111645',
      'HLFXGB21Q46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield Mtg Desk',
      '111646',
      'HLFXGB21T17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Mtg Desk',
      '111647',
      'HLFXGB21M91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Middlesborough Mtg Desk',
      '111648',
      'HLFXGB21O96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sunderland Mtg Desk',
      '111649',
      'HLFXGB21U85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Haymkt (111650)',
      '111650',
      'HLFXGB21P69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Mtg Desk',
      '111651',
      'HLFXGB21L36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Mtg Desk',
      '111652',
      'HLFXGB21R74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Yorkshire Agencies 3',
      '111653',
      'HLFXGB21G88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Mtg Desk',
      '111654',
      'HLFXGB21N49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manchester Mtg Desk',
      '111655',
      'HLFXGB21O61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Paradisest 111656',
      '111656',
      'HLFXGB21O88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Frodsham St(111657)',
      '111657',
      'HLFXGB21E51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston & Blackburn Agy 3',
      '111658',
      'HLFXGB21R69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Kilbride Mtg Desk',
      '111659',
      'HLFXGB21G79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Fishergate(111660)',
      '111660',
      'HLFXGB21O89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Scotland Mtg Desk',
      '111661',
      'HLFXGB21W55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) N E Scotland Mtg Desk',
      '111662',
      'HLFXGB21P50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Countrywide (Mids & East)',
      '111663',
      'HLFXGB21F15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Colchester Mtg Desk',
      '111664',
      'HLFXGB21F02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hemel Hempstead Mtg Desk',
      '111665',
      'HLFXGB21K78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Mtg Desk',
      '111666',
      'HLFXGB21D12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peterborough Mtg Desk',
      '111667',
      'HLFXGB21R14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Norwich City (111668)',
      '111668',
      'HLFXGB21Q31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Essex Agencies 3',
      '111669',
      'HLFXGB21H98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milton Keynes Mtg Desk',
      '111670',
      'HLFXGB21P16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stevenage Mtg Desk',
      '111671',
      'HLFXGB21U30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Mtg Desk',
      '111672',
      'HLFXGB21C03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coventry Mtg Desk',
      '111673',
      'HLFXGB21F32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham Mtg Desk',
      '111674',
      'HLFXGB21Q42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wales Mortgage Desk',
      '111675',
      'HLFXGB21V91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Country Mtge Desk',
      '111676',
      'HLFXGB21W42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Poole Mtg Desk',
      '111677',
      'HLFXGB21R43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Thames Valley Mtge Desk',
      '111678',
      'HLFXGB21V38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mids & E Anglia New Build',
      '111679',
      'HLFXGB21P04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mids & E Anglia Key A/Cs',
      '111680',
      'HLFXGB21P03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sth Wales & Wst New Build',
      '111681',
      'HLFXGB21U38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Countrywide (S W & West)',
      '111682',
      'HLFXGB21F18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Merthyr Tydfil (111683)',
      '111683',
      'HLFXGB21T81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North New Build',
      '111684',
      'HLFXGB21Q08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North West New Build',
      '111685',
      'HLFXGB21Q13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South West Mortgage Desk',
      '111686',
      'HLFXGB21T83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cheshire Agencies 5',
      '111687',
      'HLFXGB21E40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North Key Accounts',
      '111688',
      'HLFXGB21Q04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East London Agencies 2',
      '111689',
      'HLFXGB21G83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Countrywide (London & SE)',
      '111690',
      'HLFXGB21F14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Romford Mtg Desk',
      '111691',
      'HLFXGB21S45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London Central Mtg Desk',
      '111692',
      'HLFXGB21N78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon Mtg Desk',
      '111693',
      'HLFXGB21F56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London New Oxford Street',
      '111694',
      'HLFXGB21L12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading Mtg Desk',
      '111695',
      'HLFXGB21S04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South Coast Mortgage Desk',
      '111696',
      'HLFXGB21T70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Scotland & Ni New Build',
      '111697',
      'HLFXGB21S87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Kent Mtg Desk',
      '111698',
      'HLFXGB21W51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Kent Key Accounts',
      '111699',
      'HLFXGB21W50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redhill Mtg Desk',
      '111700',
      'HLFXGB21S17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Kent New Build',
      '111701',
      'HLFXGB21W52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manchester Trafford Ctre',
      '111702',
      'HLFXGB21O64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford (111703)',
      '111703',
      'HLFXGB21E13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford (111704)',
      '111704',
      'HLFXGB21G82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Solent Agencies 2',
      '111705',
      'HLFXGB21T65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West London Agencies',
      '111707',
      'HLFXGB21W54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Central London Agencies 2',
      '111708',
      'HLFXGB21E14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dartford Bluewater Park',
      '111709',
      'HLFXGB21F90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mortgage Collections Doc',
      '111710',
      'HLFXGB21P38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Premier Mortgage Services',
      '111711',
      'HLFXGB21R65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Emersons Green (111712)',
      '111712',
      'HLFXGB21H70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Surrey Quays (111713)',
      '111713',
      'HLFXGB21U90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Scotland & Ni Key A/Cs',
      '111781',
      'HLFXGB21S86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sth Wales & West Key A/Cs',
      '111782',
      'HLFXGB21U37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North West Key A/Cs',
      '111783',
      'HLFXGB21Q11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Epping',
      '111788',
      'HLFXGB21H74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Caerphilly',
      '111789',
      'HLFXGB21D57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gerrards Cross',
      '111790',
      'HLFXGB21I69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hx General Ins Direct',
      '111791',
      'HLFXGB21L43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chesthunt',
      '111792',
      'HLFXGB21E59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bridgnorth',
      '111793',
      'HLFXGB21C86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hx General Ins Mortgages',
      '111795',
      'HLFXGB21L44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Personal Loans (Bm)',
      '111802',
      'HLFXGB21R08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Customer Relations',
      '111803',
      'HLFXGB21F71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Secured Loan Operations',
      '111805',
      'HLFXGB21S95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham Aats Unit',
      '111806',
      'HLFXGB21Q35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Savings',
      '111811',
      'HLFXGB21L68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Services',
      '111812',
      'HLFXGB21L69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Tlms Mortgages',
      '111860',
      'HLFXGB21K64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Ibs Insurance',
      '111861',
      'HLFXGB21K47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) New Mortgage Platform',
      '111863',
      'HLFXGB21P60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Kent Hxd',
      '111884',
      'HLFXGB21W49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Hxd',
      '111885',
      'HLFXGB21C02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Hxd',
      '111886',
      'HLFXGB21D08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Hxd',
      '111887',
      'HLFXGB21D85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Hxd',
      '111888',
      'HLFXGB21E48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coventry Hxd',
      '111889',
      'HLFXGB21F30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Colchester Hxd',
      '111890',
      'HLFXGB21F01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Croydon Hxd',
      '111891',
      'HLFXGB21F54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milton Keynes Hxd',
      '111892',
      'HLFXGB21P14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Exeter Hxd',
      '111894',
      'HLFXGB21I09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Farnborough Hxd',
      '111895',
      'HLFXGB21I24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London St James Hxd',
      '111896',
      'HLFXGB21O03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Hxd',
      '111897',
      'HLFXGB21M87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Hxd',
      '111898',
      'HLFXGB21N47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manchester Hxd',
      '111899',
      'HLFXGB21O60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Hxd',
      '111900',
      'HLFXGB21P67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham Hxd',
      '111901',
      'HLFXGB21Q39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Hxd',
      '111902',
      'HLFXGB21R72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading Hxd',
      '111903',
      'HLFXGB21S02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Romford Hxd',
      '111904',
      'HLFXGB21S42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hemel Hempstead Hxd',
      '111905',
      'HLFXGB21K76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh George St',
      '111907',
      'HLFXGB21H13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Hxd',
      '111908',
      'HLFXGB21I82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Hxd',
      '111909',
      'HLFXGB21B60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Romford Countrywide Dpu',
      '111914',
      'HLFXGB21S41'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milton Keynes C'wide Dpu",
      '111915',
      'HLFXGB21P13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Countrywide Wellingborough',
      '111917',
      'HLFXGB21F22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Countrywide Basingstoke',
      '111918',
      'HLFXGB21F21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Countrywide Basildon',
      '111919',
      'HLFXGB21F20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mastercard Cheques',
      '111921',
      'HLFXGB21MAS'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Share Dealing Ltd',
      '111924',
      'HLFXGB21J92'
    ],
    ['HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sc', '113007', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Epay',
      '113332',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool-Mossley Hill',
      '113704',
      'HLFXGB21MSH'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pendeford Migration Unit',
      '113705',
      'HLFXGB21PMU'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burnham on Sea',
      '113706',
      'HLFXGB21BUR'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newton Abbot (113707)',
      '113707',
      'HLFXGB21NEW'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Castle Bromwich',
      '113708',
      'HLFXGB21CBR'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walsall (113709)',
      '113709',
      'HLFXGB21WAL'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Solihull (113710)',
      '113710',
      'HLFXGB21SOL'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (113711)',
      '113711',
      'HLFXGB21WOL'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worcester',
      '113712',
      'HLFXGB21WOR'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walsall (113713)',
      '113713',
      'HLFXGB21DRL'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Alvechurch',
      '113714',
      'HLFXGB21ALV'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sedgley',
      '113715',
      'HLFXGB21SDG'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (113716)',
      '113716',
      'HLFXGB21BIL'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (113717)',
      '113717',
      'HLFXGB21MEH'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (113718)',
      '113718',
      'HLFXGB21BIB'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Yardley',
      '113719',
      'HLFXGB21YAR'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ward End',
      '113726',
      'HLFXGB21WRD'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Alcester',
      '113727',
      'HLFXGB21ALC'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walsall (113728)',
      '113728',
      'HLFXGB21BLX'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen Bon Accord Centre',
      '114501',
      'HLFXGB21A07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen Bridge of Don',
      '114502',
      'HLFXGB21A08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen Upperkirkgat',
      '114504',
      'HLFXGB21A10'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen Queen's Cross (R)",
      '114505',
      'HLFXGB21A11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen, Queens Cross',
      '114506',
      'HLFXGB21A12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen Upperkirkgate',
      '114507',
      'HLFXGB21T23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen 201 Union St',
      '114508',
      'HLFXGB21A16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen Upperkirkgate',
      '114509',
      'HLFXGB21A13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen 201 Union St',
      '114511',
      'HLFXGB21A02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen, Upperkirkgate',
      '114512',
      'HLFXGB21A14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen, West End',
      '114513',
      'HLFXGB21A15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pitlochry (R)',
      '114514',
      'HLFXGB21A17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberfoyle',
      '114515',
      'HLFXGB21A18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Elgin High St (114516)',
      '114516',
      'HLFXGB21A20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Airdrie',
      '114517',
      'HLFXGB21A35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Alexandria',
      '114518',
      'HLFXGB21A39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Alloa',
      '114519',
      'HLFXGB21A43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Alness',
      '114521',
      'HLFXGB21A44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Alloa Mar St (114522)',
      '114522',
      'HLFXGB21A49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Annan',
      '114523',
      'HLFXGB21A54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Arbroath',
      '114524',
      'HLFXGB21A57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Perth (114525)',
      '114525',
      'HLFXGB21A74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Auchtermuchty',
      '114526',
      'HLFXGB21A75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aviemore (114527)',
      '114527',
      'HLFXGB21A76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ayr, High Street',
      '114528',
      'HLFXGB21A81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ayr, Newton',
      '114529',
      'HLFXGB21A82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milngavie Station 114531',
      '114531',
      'HLFXGB21A86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ballater',
      '114532',
      'HLFXGB21A88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banchory (114533)',
      '114533',
      'HLFXGB21A94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banff',
      '114534',
      'HLFXGB21A95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barrhead',
      '114535',
      'HLFXGB21B17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bathgate',
      '114536',
      'HLFXGB21B32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bearsden',
      '114537',
      'HLFXGB21B37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverness City (114538)',
      '114538',
      'HLFXGB21B38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Johnstone (114539)',
      '114539',
      'HLFXGB21B50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bellshill',
      '114540',
      'HLFXGB21B65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Benbecula',
      '114541',
      'HLFXGB21B66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Berwick upon Tweed',
      '114542',
      'HLFXGB21B69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham',
      '114543',
      'HLFXGB21B92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bishopbriggs',
      '114544',
      'HLFXGB21C10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Erskine (114545)',
      '114545',
      'HLFXGB21C15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blairgowrie',
      '114547',
      'HLFXGB21C31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Linlithgow (114548)',
      '114548',
      'HLFXGB21C46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tain (114549)',
      '114549',
      'HLFXGB21C45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bonnyrigg',
      '114551',
      'HLFXGB21C47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Uddingston (114552)',
      '114552',
      'HLFXGB21C57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bowmore',
      '114553',
      'HLFXGB21C59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Braemar',
      '114554',
      'HLFXGB21C73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brechin',
      '114555',
      'HLFXGB21C78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stirling Port St',
      '114556',
      'HLFXGB21C82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bridge of Weir',
      '114557',
      'HLFXGB21C83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton',
      '114558',
      'HLFXGB21C94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol',
      '114559',
      'HLFXGB21D03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kyle (114560)',
      '114560',
      'HLFXGB21D18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brodick',
      '114561',
      'HLFXGB21D19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Broughty Ferry',
      '114562',
      'HLFXGB21D29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Broxburn',
      '114563',
      'HLFXGB21D30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Buckie',
      '114564',
      'HLFXGB21D31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Elgin High St (114565)',
      '114565',
      'HLFXGB21D38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rutherglen (114566)',
      '114566',
      'HLFXGB21D41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Callander',
      '114567',
      'HLFXGB21D61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Campbeltown',
      '114568',
      'HLFXGB21D72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Carlisle',
      '114569',
      'HLFXGB21D91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Carluke',
      '114571',
      'HLFXGB21D93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Arbroath (114572)',
      '114572',
      'HLFXGB21D96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Castle Douglas (114573)',
      '114573',
      'HLFXGB21D98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester',
      '114575',
      'HLFXGB21E41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clarkston (114576)',
      '114576',
      'HLFXGB21E80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clydebank',
      '114577',
      'HLFXGB21E89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coatbridge',
      '114578',
      'HLFXGB21E94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milngavie Station 114759',
      '114579',
      'HLFXGB21F03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blairgowrie (114581)',
      '114581',
      'HLFXGB21F23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cowdenbeath',
      '114583',
      'HLFXGB21F34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crieff',
      '114584',
      'HLFXGB21F43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Buckie (114585)',
      '114585',
      'HLFXGB21F62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cults',
      '114586',
      'HLFXGB21F63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cumbernauld',
      '114587',
      'HLFXGB21F65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cumnock',
      '114588',
      'HLFXGB21F67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cupar',
      '114589',
      'HLFXGB21F68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Gyle Centre',
      '114591',
      'HLFXGB21F69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Castle Douglas (114592)',
      '114592',
      'HLFXGB21F76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dalgety Bay',
      '114593',
      'HLFXGB21F77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dalkeith',
      '114594',
      'HLFXGB21F79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clydebank (114595)',
      '114595',
      'HLFXGB21F80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Castle Douglas (114596)',
      '114596',
      'HLFXGB21F81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Falkirk High St 114597',
      '114597',
      'HLFXGB21F98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dingwall',
      '114598',
      'HLFXGB21G14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tain (114599)',
      '114599',
      'HLFXGB21G28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Doune',
      '114601',
      'HLFXGB21G29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverness City (114602)',
      '114602',
      'HLFXGB21G35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dumbarton',
      '114603',
      'HLFXGB21G43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dumfries',
      '114604',
      'HLFXGB21G46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunbar',
      '114605',
      'HLFXGB21G47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunblane',
      '114606',
      'HLFXGB21G48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dundee Fairmuir (114607)',
      '114607',
      'HLFXGB21G51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dundee, Fairmuir',
      '114608',
      'HLFXGB21G52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dundee,Murraygate(Personal)',
      '114609',
      'HLFXGB21G55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dundee, Overgate Centre',
      '114611',
      'HLFXGB21G53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dundee, Students Branch',
      '114612',
      'HLFXGB21G54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunfermline,Bothwell Street',
      '114613',
      'HLFXGB21G59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunfermline,11-13 East Port',
      '114614',
      'HLFXGB21G58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunkeld',
      '114615',
      'HLFXGB21G60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dunoon',
      '114616',
      'HLFXGB21G61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Duns',
      '114617',
      'HLFXGB21G62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dyce',
      '114618',
      'HLFXGB21G68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Kilbride',
      '114619',
      'HLFXGB21G78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Kilbride,Motorola Ltd',
      '114621',
      'HLFXGB21G81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Barnton',
      '114622',
      'HLFXGB21H22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Comely Bank',
      '114623',
      'HLFXGB21H23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Royal Mile',
      '114624',
      'HLFXGB21H24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morningside 114625',
      '114625',
      'HLFXGB21H25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Royal Mile',
      '114626',
      'HLFXGB21H26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Comely Bank',
      '114627',
      'HLFXGB21H27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Corstophine West',
      '114628',
      'HLFXGB21H28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Corstorphine Wes',
      '114629',
      'HLFXGB21H29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Shandwick114631',
      '114631',
      'HLFXGB21H30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Morningside',
      '114632',
      'HLFXGB21H31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Financial Ad Cen',
      '114633',
      'HLFXGB21H32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh George St',
      '114634',
      'HLFXGB21H33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh George St',
      '114635',
      'HLFXGB21H10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Comely Bank',
      '114636',
      'HLFXGB21H34'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) E'burgh George St 114637",
      '114637',
      'HLFXGB21H35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Royal Mile',
      '114638',
      'HLFXGB21H36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Kinnaird Park',
      '114639',
      'HLFXGB21H37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Leith,Leith Walk',
      '114641',
      'HLFXGB21H38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Leith,North',
      '114642',
      'HLFXGB21H39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Leith,Victoria H',
      '114643',
      'HLFXGB21H40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cameron Toll 114644',
      '114644',
      'HLFXGB21H41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Cameron Toll',
      '114645',
      'HLFXGB21H42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Mayfield',
      '114646',
      'HLFXGB21H43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Morningside',
      '114647',
      'HLFXGB21H44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Shandwick',
      '114648',
      'HLFXGB21H45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Newington',
      '114649',
      'HLFXGB21H46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Morningside',
      '114651',
      'HLFXGB21H47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Portobello 114652',
      '114652',
      'HLFXGB21H48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Portobello',
      '114653',
      'HLFXGB21H49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh,Rose St',
      '114654',
      'HLFXGB21H50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Shandwick',
      '114655',
      'HLFXGB21H51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Wester Hailes',
      '114656',
      'HLFXGB21H52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh George St',
      '114657',
      'HLFXGB21H53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Colleague Banking',
      '114658',
      'HLFXGB21H54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Comely Bank',
      '114659',
      'HLFXGB21H55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morningside (114661)',
      '114661',
      'HLFXGB21H56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Newington 114662',
      '114662',
      'HLFXGB21H57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brechin (114663)',
      '114663',
      'HLFXGB21H60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Elgin High St (114664)',
      '114664',
      'HLFXGB21H63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ellon',
      '114665',
      'HLFXGB21H67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Errol',
      '114666',
      'HLFXGB21H80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Erskine',
      '114667',
      'HLFXGB21H81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Exeter',
      '114668',
      'HLFXGB21I03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eyemouth',
      '114669',
      'HLFXGB21I13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Falkirk High Street',
      '114671',
      'HLFXGB21I15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Falkirk,Vicar Street',
      '114672',
      'HLFXGB21I16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Falkland',
      '114673',
      'HLFXGB21I17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Elgin High St (114674)',
      '114674',
      'HLFXGB21I39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Forfar',
      '114676',
      'HLFXGB21I43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Forres',
      '114677',
      'HLFXGB21I45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fort Augustus',
      '114678',
      'HLFXGB21I46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fort William',
      '114679',
      'HLFXGB21I47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverness City (114681)',
      '114681',
      'HLFXGB21I48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fraserburgh',
      '114682',
      'HLFXGB21I49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gairloch',
      '114683',
      'HLFXGB21I56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Galashiels (114684)',
      '114684',
      'HLFXGB21I58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kilmarnock (114686)',
      '114686',
      'HLFXGB21I59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Garrowhill',
      '114687',
      'HLFXGB21I62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Castle Douglas (114688)',
      '114688',
      'HLFXGB21I64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clarkston',
      '114689',
      'HLFXGB21I70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Girvan',
      '114690',
      'HLFXGB21I73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,Anniesland',
      '114691',
      'HLFXGB21I87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow 54 Sauchie (114692)',
      '114692',
      'HLFXGB21I88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Partick (114694)',
      '114694',
      'HLFXGB21I89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Byres Road (114695)',
      '114695',
      'HLFXGB21I90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,Cardonald',
      '114696',
      'HLFXGB21I91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Argyle St',
      '114697',
      'HLFXGB21J06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Argyle St 114699',
      '114699',
      'HLFXGB21I92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,Govan',
      '114700',
      'HLFXGB21I93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Byres Road (114701)',
      '114701',
      'HLFXGB21I94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,Hyndland',
      '114702',
      'HLFXGB21I95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Govan',
      '114703',
      'HLFXGB21I96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Argyle St 114704',
      '114704',
      'HLFXGB21I97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Victoria Road (R)',
      '114705',
      'HLFXGB21I98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clarkston (114706)',
      '114706',
      'HLFXGB21I99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow, Shawlands',
      '114707',
      'HLFXGB21J01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow 54 Sauchie (114708)',
      '114708',
      'HLFXGB21J02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Partick (114709)',
      '114709',
      'HLFXGB21J03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,Pollokshields',
      '114711',
      'HLFXGB21J04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Argyle St 114712',
      '114712',
      'HLFXGB21J05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,Riddrie',
      '114713',
      'HLFXGB21J07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow 54 Sauchie (114714)',
      '114714',
      'HLFXGB21J08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,Scotstoun',
      '114715',
      'HLFXGB21J09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,Shawlands',
      '114716',
      'HLFXGB21J10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,St Enoch Centre',
      '114717',
      'HLFXGB21J11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow 54 Sauchie (114718)',
      '114718',
      'HLFXGB21J12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow The Forge(114719)',
      '114719',
      'HLFXGB21J13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow,Victoria Road',
      '114721',
      'HLFXGB21J14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glenrothes',
      '114722',
      'HLFXGB21J17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Golspie',
      '114723',
      'HLFXGB21J26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dalkeith High St',
      '114724',
      'HLFXGB21J29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Greenock',
      '114725',
      'HLFXGB21J36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grangemouth',
      '114726',
      'HLFXGB21J39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aviemore (114727)',
      '114727',
      'HLFXGB21J42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Greenock',
      '114728',
      'HLFXGB21J53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Greenock',
      '114729',
      'HLFXGB21J56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Greenock,IBM UK Ltd',
      '114731',
      'HLFXGB21J55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North Berwick (114732)',
      '114732',
      'HLFXGB21J70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Haddington',
      '114733',
      'HLFXGB21J75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hamilton,Cadzow Street',
      '114734',
      'HLFXGB21K01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hamilton,New Cross',
      '114735',
      'HLFXGB21K02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hawick',
      '114736',
      'HLFXGB21K39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Helensburgh',
      '114737',
      'HLFXGB21K72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Golspie (114738)',
      '114738',
      'HLFXGB21K74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull',
      '114739',
      'HLFXGB21L28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverurie (114741)',
      '114741',
      'HLFXGB21L40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Innerleithen',
      '114742',
      'HLFXGB21L63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lochgilphead (114743)',
      '114743',
      'HLFXGB21L70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverness City (114745)',
      '114745',
      'HLFXGB21L74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverness,Business',
      '114746',
      'HLFXGB21L72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverness,Longman',
      '114747',
      'HLFXGB21L73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverurie (114748)',
      '114748',
      'HLFXGB21L75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ipswich',
      '114749',
      'HLFXGB21L77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Irvine',
      '114750',
      'HLFXGB21L81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Jedburgh',
      '114752',
      'HLFXGB21L86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Johnstone (114755)',
      '114755',
      'HLFXGB21L90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Buckie (114756)',
      '114756',
      'HLFXGB21L96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kelso',
      '114757',
      'HLFXGB21L97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kilcreggan',
      '114758',
      'HLFXGB21M17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Balfron (114759)',
      '114759',
      'HLFXGB21M18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Callandar (114760)',
      '114760',
      'HLFXGB21M19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kilmarnock',
      '114761',
      'HLFXGB21M21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkcaldy High St (114764)',
      '114764',
      'HLFXGB21M25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aviemore (114765)',
      '114765',
      'HLFXGB21M41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Perth (114766)',
      '114766',
      'HLFXGB21M42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkcaldy',
      '114767',
      'HLFXGB21M47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkcaldy,Mitchelston',
      '114768',
      'HLFXGB21M49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkcaldy,Victoria Hosp',
      '114769',
      'HLFXGB21M50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkcudbright',
      '114770',
      'HLFXGB21M51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkintilloch',
      '114771',
      'HLFXGB21M52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkwall',
      '114772',
      'HLFXGB21M53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Forfar (114773)',
      '114773',
      'HLFXGB21M54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kyle',
      '114774',
      'HLFXGB21M57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Golspie (114775)',
      '114775',
      'HLFXGB21M60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lanark',
      '114776',
      'HLFXGB21M62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Langholm',
      '114777',
      'HLFXGB21M65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Largs',
      '114778',
      'HLFXGB21M69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Larkhall',
      '114779',
      'HLFXGB21L91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Galashiels (114780)',
      '114780',
      'HLFXGB21M72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds',
      '114781',
      'HLFXGB21M78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leicester',
      '114782',
      'HLFXGB21M96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lerwick',
      '114783',
      'HLFXGB21N11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lesmahagow',
      '114784',
      'HLFXGB21N12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leven',
      '114785',
      'HLFXGB21N15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Linlithgow',
      '114787',
      'HLFXGB21N36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool',
      '114788',
      'HLFXGB21N42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Livingston (114789)',
      '114789',
      'HLFXGB21N56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dalkeith High St (114790)',
      '114790',
      'HLFXGB21N60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kyle (114791)',
      '114791',
      'HLFXGB21N61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cowdenbeath 114792',
      '114792',
      'HLFXGB21N62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lochgilphead',
      '114793',
      'HLFXGB21N63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lochmaddy',
      '114794',
      'HLFXGB21N64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cowdenbeath 114795',
      '114795',
      'HLFXGB21N65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lockerbie',
      '114796',
      'HLFXGB21N66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London,Chief Office',
      '114797',
      'HLFXGB21O09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) London,West End',
      '114798',
      'HLFXGB21O10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Elgin High St (114799)',
      '114799',
      'HLFXGB21O16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banff (114801)',
      '114801',
      'HLFXGB21O39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mallaig',
      '114802',
      'HLFXGB21O48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manchester',
      '114803',
      'HLFXGB21O53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ayr High Street',
      '114804',
      'HLFXGB21O82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Galashiels (114805)',
      '114805',
      'HLFXGB21O84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Millport',
      '114806',
      'HLFXGB21P08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Milngavie',
      '114807',
      'HLFXGB21P10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Moffat',
      '114808',
      'HLFXGB21P20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Montrose',
      '114809',
      'HLFXGB21P25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Motherwell',
      '114810',
      'HLFXGB21P43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Musselburgh',
      '114811',
      'HLFXGB21P46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nairn',
      '114812',
      'HLFXGB21P52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fraserburgh',
      '114813',
      'HLFXGB21P61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle upon Tyne',
      '114814',
      'HLFXGB21P75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastleton',
      '114815',
      'HLFXGB21P76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newton Mearns',
      '114816',
      'HLFXGB21P86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newton Stewart (114817)',
      '114817',
      'HLFXGB21P88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Galashiels (114818)',
      '114818',
      'HLFXGB21P87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North Berwick (114819)',
      '114819',
      'HLFXGB21P96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Norwich',
      '114820',
      'HLFXGB21Q26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham',
      '114821',
      'HLFXGB21Q33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oban',
      '114822',
      'HLFXGB21Q51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oxford',
      '114823',
      'HLFXGB21Q64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paisley The Cross',
      '114824',
      'HLFXGB21Q76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paisley The Cross (114826)',
      '114826',
      'HLFXGB21Q77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paisley The Cross',
      '114827',
      'HLFXGB21Q78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peebles',
      '114828',
      'HLFXGB21Q98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Penicuik',
      '114829',
      'HLFXGB21R01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Perth,St John Street',
      '114831',
      'HLFXGB21R11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Perth (114832)',
      '114832',
      'HLFXGB21R12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peterhead',
      '114833',
      'HLFXGB21R17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pitlochry',
      '114834',
      'HLFXGB21R23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Falkirk High Street',
      '114835',
      'HLFXGB21R35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Port Glasgow',
      '114836',
      'HLFXGB21R45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newton Stewart (114837)',
      '114837',
      'HLFXGB21R47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portree',
      '114838',
      'HLFXGB21R53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Buckie (114839)',
      '114839',
      'HLFXGB21R59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ayr High Street (114840)',
      '114840',
      'HLFXGB21R78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading',
      '114841',
      'HLFXGB21R98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Renfrew',
      '114842',
      'HLFXGB21S22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rothesay',
      '114843',
      'HLFXGB21S55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rutherglen',
      '114844',
      'HLFXGB21S67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Saltcoats',
      '114845',
      'HLFXGB21S78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sanquhar',
      '114847',
      'HLFXGB21S81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Perth (114848)',
      '114848',
      'HLFXGB21S85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Selkirk',
      '114849',
      'HLFXGB21S99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield',
      '114850',
      'HLFXGB21T07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shotts',
      '114851',
      'HLFXGB21T37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southampton',
      '114852',
      'HLFXGB21T91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Andrews',
      '114853',
      'HLFXGB21U11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kilmarnock (114855)',
      '114855',
      'HLFXGB21U32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stirling Port Street',
      '114856',
      'HLFXGB21U41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stirling,Financial Ad Cent',
      '114857',
      'HLFXGB21U43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stirling Port St 114858',
      '114858',
      'HLFXGB21U44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stirling,University',
      '114859',
      'HLFXGB21U45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stonehaven',
      '114860',
      'HLFXGB21U59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Kilbride (114861)',
      '114861',
      'HLFXGB21U60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stornoway',
      '114862',
      'HLFXGB21U62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stranraer',
      '114863',
      'HLFXGB21U66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Strathaven',
      '114864',
      'HLFXGB21U71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stromness',
      '114865',
      'HLFXGB21U76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tain (114866)',
      '114866',
      'HLFXGB21V21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tarbert (Argyll)',
      '114867',
      'HLFXGB21V24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tarbert (Isle of Harris)',
      '114868',
      'HLFXGB21V25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Inverurie (114869)',
      '114869',
      'HLFXGB21V35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Thornhill',
      '114870',
      'HLFXGB21V42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Thurso',
      '114871',
      'HLFXGB21V44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Troon',
      '114872',
      'HLFXGB21V57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banff (114873)',
      '114873',
      'HLFXGB21V66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Uddingston',
      '114874',
      'HLFXGB21V71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ullapool',
      '114875',
      'HLFXGB21V72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Uxbridge',
      '114876',
      'HLFXGB21V79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Penicuik (114877)',
      '114877',
      'HLFXGB21W53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wick',
      '114879',
      'HLFXGB21W78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newton Stewart (114880)',
      '114880',
      'HLFXGB21W87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wishaw',
      '114881',
      'HLFXGB21Y04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Berwick upon Tweed',
      '114882',
      'HLFXGB21Y20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Paisley The Paisley Centre',
      '114883',
      'HLFXGB21Q75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leith (114884)',
      '114884',
      'HLFXGB21H17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Gorgie Road',
      '114885',
      'HLFXGB21H11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen 201 Union St',
      '114886',
      'HLFXGB21H18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Cameron Toll',
      '114887',
      'HLFXGB21CMT'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Wester Hailes',
      '114888',
      'HLFXGB21WPZ'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow The Forge(114889)',
      '114889',
      'HLFXGB21DNS'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Livingston',
      '114891',
      'HLFXGB21LVT'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Pollok',
      '114892',
      'HLFXGB21PLK'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow 137 Buchanan St',
      '114893',
      'HLFXGB21I75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Bnkg Channel Supp',
      '114920',
      'BOFSGBS1225'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Bristol',
      '114926',
      'BOFSGBS1213'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Edinburgh',
      '114927',
      'BOFSGBS1215'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Aberdeen',
      '114928',
      'BOFSGBS1211'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pb Direct Leeds (114929)',
      '114929',
      'BOFSGBS1221'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Glasgow',
      '114930',
      'BOFSGBS1216'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Newcastle',
      '114931',
      'BOFSGBS1220'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Leeds',
      '114932',
      'BOFSGBS1218'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Bkng Manchester',
      '114933',
      'BOFSGBS1223'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Chester',
      '114934',
      'HLFXGB21Y66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Birmingham',
      '114935',
      'BOFSGBS1212'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Ipswich',
      '114936',
      'BOFSGBS1217'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Abingdon',
      '114937',
      'BOFSGBS1222'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Bkng Southampton',
      '114938',
      'BOFSGBS1224'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking London',
      '114939',
      'BOFSGBS1219'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leith 4 Leith Walk',
      '114941',
      'HLFXGB21LWK'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Specialist Banking',
      '114943',
      'HLFXGB21Y67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Inverness',
      '114944',
      'HLFXGB21INV'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Maidstone',
      '114945',
      'HLFXGB21MAD'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking Edinburgh',
      '114955',
      'HLFXGB31Y55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Private Banking London',
      '114956',
      'HLFXGB21Y68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Argyle St 114957',
      '114957',
      'HLFXGB21ARG'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lt Bristol Ag16',
      '114979',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Berwick-upon-Tweed',
      '114988',
      ''
    ],
    ['Bank of Scotland plc Cash Supp (Ew&Ni)(115124)', '115124', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Moneycard Desk (Man)',
      '115186',
      'HLFXGB21Y72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Postal Account',
      '115189',
      'HLFXGB21Y73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telepost Cash',
      '115193',
      'HLFXGB21Y74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Copley Atm Test 115204',
      '115204',
      'HLFXGB21TT3'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield The Moor',
      '115205',
      'HLFXGB21Y69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Direct',
      '115207',
      'HLFXGB21Y61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rtl Lab-Tr1 Testing115209',
      '115209',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Skegness',
      '115214',
      'HLFXGB31SKG'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grp It Adm Digital Banking',
      '115219',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Edinburgh',
      '115226',
      'HLFXGB21Y77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Biab Test (115228)',
      '115228',
      'HLFXGB21Z12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rba - Shared Svcs (115236)',
      '115236',
      'HLFXGB21TT4'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Glasgow',
      '115237',
      'HLFXGB21Y76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Copley Atm Test 115240',
      '115240',
      'HLFXGB21TT5'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aberdeen 52-54 Union St',
      '115250',
      'HLFXGB21Y75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Heswall',
      '115264',
      'HLFXGB21Y54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) New Branch 4',
      '115265',
      'HLFXGB21Y55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rtl Lab-Tr1 Testing115275',
      '115275',
      'HLFXGB21TT6'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '115293',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '115294',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '115295',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '115509',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) New Branch 2',
      '115520',
      'HLFXGB21Y57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Heswall',
      '115527',
      'HLFXGB21Y56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rtl Lab-Tr1 Testing115534',
      '115534',
      'HLFXGB21TT8'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gtc (Non Ics Trans)',
      '115593',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Test Branch (Wmh 115698)',
      '115698',
      'HLFXGB21TT9'
    ],
    ['Bank of Scotland plc Test Branch (Wmh 115699)', '115699', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Acklam (Hea)',
      '116005',
      'HLFXGB21A25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) York Parliament St (116007)',
      '116007',
      'HLFXGB21A29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ainsdale (Hps)',
      '116011',
      'HLFXGB21A33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Allerton (Hea)',
      '116020',
      'HLFXGB21A41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basingstoke (116026)',
      '116026',
      'HLFXGB21A46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Andersonstown (Hps)',
      '116034',
      'HLFXGB21A50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Andover (Hps)',
      '116035',
      'HLFXGB21A52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ascot (Hps)',
      '116046',
      'HLFXGB21A62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashford (Kent) (Hps)',
      '116051',
      'HLFXGB21A64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashford 32 Church Rd Hps',
      '116052',
      'HLFXGB21A65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashton U Lyne (Hea)',
      '116056',
      'HLFXGB21A70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bramley (116071)',
      '116071',
      'HLFXGB21A30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Alfreton (Hps)',
      '116072',
      'HLFXGB21A40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashton in Makerfield (Hps)',
      '116073',
      'HLFXGB21A69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ashford (Gale & Power)',
      '116080',
      'HLFXGB21A63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aldershot (Hps)',
      '116085',
      'HLFXGB21A38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bakewell (Hps)',
      '116151',
      'HLFXGB21A85'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Ballyh'more (Hea)",
      '116156',
      'HLFXGB21B52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bangor Ballyholme (Hea)',
      '116157',
      'HLFXGB21B01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ballymena (Hps)',
      '116158',
      'HLFXGB21A90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bamberbridge (Hps)',
      '116160',
      'HLFXGB21A91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bangor (Hps)',
      '116164',
      'HLFXGB21A96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barnehurst (Hps)',
      '116172',
      'HLFXGB21B10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Barnsley (Hps)',
      '116174',
      'HLFXGB21B13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (116178)',
      '116178',
      'HLFXGB21B21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Basingstoke (116180)',
      '116180',
      'HLFXGB21B25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bath (116182)',
      '116182',
      'HLFXGB21B27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clapham Junction (116184)',
      '116184',
      'HLFXGB21B35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bedworth Hps',
      '116194',
      'HLFXGB21B46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast New Homes (Hea)',
      '116198',
      'HLFXGB21B61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Cregagh Rd (Hps)',
      '116201',
      'HLFXGB21B56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Finaghy (Hps)',
      '116202',
      'HLFXGB21B59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Belfast Ormeau Road (Hps)',
      '116204',
      'HLFXGB21B62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Beverley  (Hps)',
      '116211',
      'HLFXGB21B72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bexleyheath Broadway',
      '116213',
      'HLFXGB21B78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bingham (Hps)',
      '116220',
      'HLFXGB21B87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bingley (Hps)',
      '116221',
      'HLFXGB21B88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bishop Auckland (Hps)',
      '116227',
      'HLFXGB21C09'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bishop's Waltham Hps",
      '116229',
      'HLFXGB21C14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southampton 116236',
      '116236',
      'HLFXGB21C17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blaby Hps',
      '116237',
      'HLFXGB21C19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackpool Highfield (Hea)',
      '116240',
      'HLFXGB21C24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blyth (Hps)',
      '116247',
      'HLFXGB21C34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bognor Regis (Hps)',
      '116248',
      'HLFXGB21C37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bolton Bradshawgate',
      '116251',
      'HLFXGB21C40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Boroughbridge (Hps)',
      '116255',
      'HLFXGB21C53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bracknell (Hps)',
      '116260',
      'HLFXGB21C61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '116261',
      'HLFXGB21C63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford on Avon (Hps)',
      '116266',
      'HLFXGB21C69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradley Stoke (Hps)',
      '116267',
      'HLFXGB21C72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brigg (Hps)',
      '116280',
      'HLFXGB21C92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromley (Hps)',
      '116289',
      'HLFXGB21D20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham City (116303)',
      '116303',
      'HLFXGB21D33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Burgess Hill (Hps)',
      '116304',
      'HLFXGB21D35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bury The Rock (116311)',
      '116311',
      'HLFXGB21D46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birchington (Hps)',
      '116316',
      'HLFXGB21B89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hfx Commercial St 116319',
      '116319',
      'HLFXGB21C85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton (116320)',
      '116320',
      'HLFXGB21D01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Whiteladies (Hps)',
      '116321',
      'HLFXGB21D14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bromsgrove (116322)',
      '116322',
      'HLFXGB21D26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Beeston (Hps)',
      '116327',
      'HLFXGB21B48'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Blackpool W'gate Hps",
      '116328',
      'HLFXGB21C27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bitterne (Hps)',
      '116338',
      'HLFXGB21C18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Baildon (Hps)',
      '116341',
      'HLFXGB21A83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bishops Waltham (Hea)',
      '116344',
      'HLFXGB21C13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sunningdale (Hps)',
      '116345',
      'HLFXGB21U86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton Fiveways (Hea)',
      '116357',
      'HLFXGB21C96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Brighton (116361)',
      '116361',
      'HLFXGB21C97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bristol Kings House (Hps)',
      '116370',
      'HLFXGB21D09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ib Halifax',
      '116414',
      'HLFXGB21Y65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crc - Halifax Cheque',
      '116422',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking Halifax',
      '116432',
      'HLFXGB21Z05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking Halifax',
      '116433',
      'HLFXGB21Z07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 116434',
      '116434',
      'HLFXGB21Z16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking Halifax',
      '116435',
      'HLFXGB21Z15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking Halifax',
      '116436',
      'HLFXGB21Z09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking Halifax',
      '116457',
      'HLFXGB21Z01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking Halifax',
      '116458',
      'HLFXGB21Z04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking Halifax',
      '116459',
      'HLFXGB21Z03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ib 116460',
      '116460',
      'HLFXGB21Z21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ib 116463',
      '116463',
      'HLFXGB21Z22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 116466',
      '116466',
      'HLFXGB21Z26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 116467',
      '116467',
      'HLFXGB21Z27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking  116468',
      '116468',
      'HLFXGB21Z28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 116469',
      '116469',
      'HLFXGB21Z29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 116470',
      '116470',
      'HLFXGB21Z30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 116471',
      '116471',
      'HLFXGB21Z31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking Halifax',
      '116477',
      'HLFXGB21Z06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 116478',
      '116478',
      'HLFXGB21Z17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 116479',
      '116479',
      'HLFXGB21Z18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 116480',
      '116480',
      'HLFXGB21Z19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Caerphilly (Hps)',
      '116493',
      'HLFXGB21D58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Caldicot (Hea)',
      '116495',
      'HLFXGB21D59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Calne (Hps)',
      '116497',
      'HLFXGB21D62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Canterbury (Hea)',
      '116503',
      'HLFXGB21D77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Carnforth (Hps)',
      '116513',
      'HLFXGB21D95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Carrickfergus (Hps)',
      '116515',
      'HLFXGB21D97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Catford (Hps)',
      '116522',
      'HLFXGB21E03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Frodsham St(116541)',
      '116541',
      'HLFXGB21E44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Le Street (Hps)',
      '116542',
      'HLFXGB21E50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chesterfield (Hps)',
      '116543',
      'HLFXGB21E55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chichester (Hps)',
      '116544',
      'HLFXGB21E61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chippenham (Hps)',
      '116547',
      'HLFXGB21E66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chorley (Hps)',
      '116552',
      'HLFXGB21E69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chorlton (Hea)',
      '116554',
      'HLFXGB21E71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clapham Junction (116559)',
      '116559',
      'HLFXGB21E78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cleckheaton (Hps)',
      '116566',
      'HLFXGB21E82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clevedon (Hps)',
      '116569',
      'HLFXGB21E83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clifton (Notts) (Hps)',
      '116570',
      'HLFXGB21E87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Clifton (Bristol) Hps',
      '116571',
      'HLFXGB21E86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coleford (Hps)',
      '116583',
      'HLFXGB21F04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Comber (Hps)',
      '116589',
      'HLFXGB21F07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Congleton (Hps)',
      '116590',
      'HLFXGB21F09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Consett (Hps)',
      '116594',
      'HLFXGB21F10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (116598)',
      '116598',
      'HLFXGB21F13'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Coventry High St (116600)',
      '116600',
      'HLFXGB21F29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cramlington (Hps)',
      '116605',
      'HLFXGB21F35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crawcrook (Hps)',
      '116609',
      'HLFXGB21F36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crawley High Street',
      '116610',
      'HLFXGB21F38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crewe (Hps)',
      '116613',
      'HLFXGB21F41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bootle (116622)',
      '116622',
      'HLFXGB21F44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tunbridge Wells (116626)',
      '116626',
      'HLFXGB21F50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crystal Palace (Hps)',
      '116630',
      'HLFXGB21F60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crystal Peaks (Hps)',
      '116631',
      'HLFXGB21F61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Camberley (Hps)',
      '116638',
      'HLFXGB21D64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cwmbran (Hps)',
      '116639',
      'HLFXGB21F73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading Broad St (116640)',
      '116640',
      'HLFXGB21E05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield High Street',
      '116643',
      'HLFXGB21E20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cove (Hps)',
      '116645',
      'HLFXGB21F24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crossgates (Hea)',
      '116646',
      'HLFXGB21F46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cippenham (Hea)',
      '116659',
      'HLFXGB21E73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cirencester (Hea)',
      '116660',
      'HLFXGB21E75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cannock (Hps)',
      '116663',
      'HLFXGB21D75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Queens Arcade',
      '116670',
      'HLFXGB21D84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) PO Box 548 (116687)',
      '116687',
      'HLFXGB21Z10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Commercial St (1166',
      '116688',
      'HLFXGB21Z13'
    ],
    ['Bank of Scotland plc Test Branch (Wmh 116701)', '116701', 'BOFSGBS1TTT'],
    ['Bank of Scotland plc Test Branch 116705', '116705', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Savings',
      '116722',
      'HLFXGB21Y64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '116729',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '116730',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '116731',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '116733',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '116749',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Ph Epayments',
      '116750',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Estuary House 1',
      '116758',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rdp / Offshore',
      '116763',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '116776',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '116785',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portland Street 7',
      '116786',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '116787',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '116788',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Et Digital Uat (116795)',
      '116795',
      'HLFXGB21Z24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dartford (Hps)',
      '116805',
      'HLFXGB21F89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Deal (Hps)',
      '116810',
      'HLFXGB21F95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Denton (Hea)',
      '116812',
      'HLFXGB21G01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Derby Hps',
      '116813',
      'HLFXGB21G06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dewsbury (Hps)',
      '116817',
      'HLFXGB21G11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dinnington (Hps)',
      '116821',
      'HLFXGB21G15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Doncaster (Hps)',
      '116826',
      'HLFXGB21G18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dorking (116828)',
      '116828',
      'HLFXGB21G27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Emersons Green (116829)',
      '116829',
      'HLFXGB21G32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dulwich (Hps)',
      '116837',
      'HLFXGB21G41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Durham City (Hps)',
      '116844',
      'HLFXGB21G67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Darlington (Hps)',
      '116847',
      'HLFXGB21F86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Driffield (Hps)',
      '116850',
      'HLFXGB21G33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Dudley (Hps)',
      '116853',
      'HLFXGB21G36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Erdington (Hps)',
      '116858',
      'HLFXGB21H78'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Feltham (Gale & Power)',
      '116863',
      'HLFXGB21I33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Earlestown - Hea',
      '116896',
      'HLFXGB21ERL'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) East Grinstead (Hps)',
      '116901',
      'HLFXGB21G71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham City (116907)',
      '116907',
      'HLFXGB21G99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ebbw Vale (Hps)',
      '116908',
      'HLFXGB21H01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eccles (Hea)',
      '116909',
      'HLFXGB21H02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Egham (Gale & Power)',
      '116919',
      'HLFXGB21H61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Evesham High Street',
      '116934',
      'HLFXGB21I02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Eastbourne (Hps)',
      '116937',
      'HLFXGB21G89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Emersons Green (116945)',
      '116945',
      'HLFXGB21H69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fareham (Hps)',
      '116988',
      'HLFXGB21I20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aldershot (116990)',
      '116990',
      'HLFXGB21I26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bolton Farnworth (Hea)',
      '116992',
      'HLFXGB21C41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Farnborough (117008)',
      '117008',
      'HLFXGB21I37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford (117010)',
      '117010',
      'HLFXGB21I42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Formby (Hps)',
      '117015',
      'HLFXGB21I44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Fulwell (Hps)',
      '117025',
      'HLFXGB21I53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Fishergate(117030)',
      '117030',
      'HLFXGB21I54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Folkstone (Hps)',
      '117032',
      'HLFXGB21I41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Frodsham (Hps)',
      '117034',
      'HLFXGB21I50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Garforth (Hea)',
      '117076',
      'HLFXGB21I61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gillingham (Hps)',
      '117085',
      'HLFXGB21I71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glengormley (Hps)',
      '117090',
      'HLFXGB21J15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Goole (Hps)',
      '117100',
      'HLFXGB21J28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gosforth (Hps)',
      '117103',
      'HLFXGB21J31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gosport (Hps)',
      '117104',
      'HLFXGB21J34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grange Over Sands (Hps)',
      '117107',
      'HLFXGB21J37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grantham (Hps)',
      '117109',
      'HLFXGB21J40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gravesend (Hps)',
      '117111',
      'HLFXGB21J45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Grimsby (Hps)',
      '117120',
      'HLFXGB21J57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Guisborough (Hps)',
      '117124',
      'HLFXGB21J68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Guildford (Hps)',
      '117128',
      'HLFXGB21J65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Woodhouse Branch',
      '117139',
      'HLFXGB21WOD'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chester Frodshamst(117141)',
      '117141',
      'HLFXGB21HEA'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ib Halifax 117180',
      '117180',
      'HLFXGB21Z02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harrogate (Hps)',
      '117209',
      'HLFXGB21K16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hartlepool (Hps)',
      '117210',
      'HLFXGB21K24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Havant (Hps)',
      '117233',
      'HLFXGB21K35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hebburn (Hps)',
      '117249',
      'HLFXGB21K68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hessle (Hps)',
      '117262',
      'HLFXGB21K85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hindley (Hps)',
      '117277',
      'HLFXGB21K93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Horley (Hps)',
      '117294',
      'HLFXGB21K99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bolton Horwich (Hea)',
      '117298',
      'HLFXGB21C43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Houghton Le Spring (Hps)',
      '117299',
      'HLFXGB21L08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hove Central (Hea)',
      '117300',
      'HLFXGB21L17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hucknall (Hps)',
      '117305',
      'HLFXGB21L21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (117306)',
      '117306',
      'HLFXGB21L31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (117310)',
      '117310',
      'HLFXGB21L32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hfx Commercial St 117319',
      '117319',
      'HLFXGB21J79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Horsham (Hps)',
      '117323',
      'HLFXGB21L07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Solihull (117329)',
      '117329',
      'HLFXGB21J97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Haxby (Hps)',
      '117330',
      'HLFXGB21K40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hornsea (Hps)',
      '117332',
      'HLFXGB21L04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Harborne (Hps)',
      '117335',
      'HLFXGB21K06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hebden Bridge (Hps)',
      '117336',
      'HLFXGB21K69'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huddersfield (117338)',
      '117338',
      'HLFXGB21K98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Huddersfield (117340)',
      '117340',
      'HLFXGB21L22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hastings (117346)',
      '117346',
      'HLFXGB21K29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hastings (117347)',
      '117347',
      'HLFXGB21K28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Herne Bay (Hps)',
      '117348',
      'HLFXGB21K82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hove Church Road',
      '117349',
      'HLFXGB21L18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hove East (Hea)',
      '117350',
      'HLFXGB21L19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Lowergate (Hps)',
      '117351',
      'HLFXGB21L35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield High Street',
      '117354',
      'HLFXGB21K91'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Halifax',
      '117361',
      'HLFXGB21Y62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) P.O. Box 548',
      '117362',
      'HLFXGB21Y63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kegworth (Hps)',
      '117391',
      'HLFXGB21L92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Edinburgh Ettrick',
      '117401',
      ''
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Andover S'vce Ctr Hbos1",
      '117402',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117403',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '117423',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rfu - Non- P Halifax 3',
      '117425',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Ph Epayments',
      '117433',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Ph Epayments',
      '117434',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (117439)',
      '117439',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (117440)',
      '117440',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (117442)',
      '117442',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sunderland 117443',
      '117443',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (117444)',
      '117444',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ct Swansea 117445',
      '117445',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ctb Swansea 117447',
      '117447',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Customer Service (117448)',
      '117448',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony-Support (117454)',
      '117454',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117457',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham City (117461)',
      '117461',
      'HLFXGB21L61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 5',
      '117472',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Ops Offshore Team',
      '117473',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 26',
      '117474',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 27',
      '117483',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kendall (Hps)',
      '117487',
      'HLFXGB21L99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leamington Spa (117489)',
      '117489',
      'HLFXGB21M02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Surrey Quays (117490)',
      '117490',
      'HLFXGB21M03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Keynsham (Hps)',
      '117493',
      'HLFXGB21M11'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Keyworth (Hps)',
      '117494',
      'HLFXGB21M12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham City (117498)',
      '117498',
      'HLFXGB21M24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kingswood (Bristol) (Hps)',
      '117502',
      'HLFXGB21M39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kippax (Hps)',
      '117503',
      'HLFXGB21M43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Kirkby in Ashfield (Hps)',
      '117507',
      'HLFXGB21M46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Keighley (Hps)',
      '117517',
      'HLFXGB21L94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Ops Offshore Team',
      '117530',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Ops Offshore Team',
      '117532',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lancaster (Hps)',
      '117547',
      'HLFXGB21M64'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Langney Point (Hps)',
      '117548',
      'HLFXGB21M68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Larne (Hps)',
      '117553',
      'HLFXGB21M71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leamington Spa (Hps)',
      '117556',
      'HLFXGB21M75'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leicester Humberstone',
      '117561',
      'HLFXGB21N02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leyton (Hps)',
      '117571',
      'HLFXGB21N23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lincoln (Hps)',
      '117574',
      'HLFXGB21N29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Linthorpe (Hps)',
      '117577',
      'HLFXGB21N37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lisburn (Hps)',
      '117579',
      'HLFXGB21N39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Derby (Hea)',
      '117585',
      'HLFXGB21W44'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) L'derry Shipquay St (Hps)",
      '117607',
      'HLFXGB21M58'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) L'derry Spencer Rd (Hps)",
      '117608',
      'HLFXGB21M59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Long Eaton (Hps)',
      '117610',
      'HLFXGB21O14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Louth (Hea)',
      '117618',
      'HLFXGB21O21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lowfell (Hps)',
      '117619',
      'HLFXGB21O24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lydney (Hps)',
      '117627',
      'HLFXGB21O32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lymm (Hps)',
      '117630',
      'HLFXGB21O34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lytham St Annes (Hps)',
      '117633',
      'HLFXGB21O35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Langley (Hea)',
      '117640',
      'HLFXGB21M66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Lightwater (Hps)',
      '117641',
      'HLFXGB21N28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hastings (117648)',
      '117648',
      'HLFXGB21M67'
    ],
    ['HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax', '117654', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Group Customer Services',
      '117655',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ccfd Halifax 117659',
      '117659',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portland Street6 (117662)',
      '117662',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portland Street1 (117663)',
      '117663',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117664',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117665',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117666',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117667',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117668',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portland Street1 (117669)',
      '117669',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Motherwell - R (117672)',
      '117672',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rba - Shared Svcs (117678)',
      '117678',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Connect Fraud 2 117685',
      '117685',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Debit Cards',
      '117686',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manchester City Ctre Hps',
      '117724',
      'HLFXGB21O57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Macclesfield (Hps)',
      '117726',
      'HLFXGB21O37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Maghull (Hps)',
      '117730',
      'HLFXGB21O40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Malmesbury (Hea)',
      '117731',
      'HLFXGB21O49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Doncaster St Sepulchregate',
      '117732',
      'HLFXGB21O50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Manor Park (Hps)',
      '117736',
      'HLFXGB21O66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mapperley (Hps)',
      '117739',
      'HLFXGB21O72'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chesterfieldknifesmithgate',
      '117756',
      'HLFXGB21O79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Melksham (Hps)',
      '117761',
      'HLFXGB21O83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Middlesbrough (Hps)',
      '117764',
      'HLFXGB21O97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Midsomer Norton (Hps)',
      '117768',
      'HLFXGB21P06'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morpeth (Hps)',
      '117788',
      'HLFXGB21P35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Mansfield (Hps)',
      '117792',
      'HLFXGB21O67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Moortown (Hps)',
      '117796',
      'HLFXGB21P27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morecombe (Hps)',
      '117797',
      'HLFXGB21P29'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Morley (Hps)',
      '117798',
      'HLFXGB21P32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Market Rasen (Hps)',
      '117803',
      'HLFXGB21O77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Maidstone (Hps)',
      '117811',
      'HLFXGB21O44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Melton Mowbray (Hps)',
      '117813',
      'HLFXGB21O86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nantwich  (Hps)',
      '117878',
      'HLFXGB21P53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newtownards (Hps)',
      '117912',
      'HLFXGB21P90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Normanby (Hps)',
      '117915',
      'HLFXGB21P93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North End (Hps)',
      '117918',
      'HLFXGB21P99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northenden (Hea)',
      '117924',
      'HLFXGB21Q18'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Nottingham City (117935)',
      '117935',
      'HLFXGB21Q34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) North Hykeham (Hps)',
      '117937',
      'HLFXGB21Q03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Et Digital Uat (117940)',
      '117940',
      'HLFXGB21NAI'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117942',
      ''
    ],
    ['HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rosyth', '117944', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Epay',
      '117948',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117973',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117974',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117975',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117976',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '117977',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rfu - Non-P Halifax 1',
      '117978',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '117983',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Prescot (117986)',
      '117986',
      'HLFXGB21Y58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hunts Cross',
      '117991',
      'HLFXGB21Y70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford (117996)',
      '117996',
      'HLFXGB21Y71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walton Vale',
      '118016',
      'HLFXGB31WVL'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '118017',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Glasgow Ph Epayments',
      '118018',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Oadby Hps',
      '118021',
      'HLFXGB21Q49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Runcorn (Hea)',
      '118027',
      'HLFXGB21S65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bath (118038)',
      '118038',
      'HLFXGB21B28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ormskirk (Hps)',
      '118040',
      'HLFXGB21Q57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (118042)',
      '118042',
      'HLFXGB21Q68'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Epay',
      '118046',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Park Gate (Hea)',
      '118064',
      'HLFXGB21Q81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Preston Fishergate (118074)',
      '118074',
      'HLFXGB21R04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pershore (Hps)',
      '118075',
      'HLFXGB21R07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Peterlee (Hps)',
      '118077',
      'HLFXGB21R19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford (118080)',
      '118080',
      'HLFXGB21R24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford (118085)',
      '118085',
      'HLFXGB21R32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portadown (Hps)',
      '118093',
      'HLFXGB21R48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portchester (Hps)',
      '118094',
      'HLFXGB21R50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portishead (Hps)',
      '118097',
      'HLFXGB21R52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Poulton Le Fylde (Hps)',
      '118102',
      'HLFXGB21R63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Prescot (Hps)',
      '118105',
      'HLFXGB21R66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gateshead Metro (118112)',
      '118112',
      'HLFXGB21R81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pimlico (Hps)',
      '118116',
      'HLFXGB21R20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Park Gate (Wellar Eggar)',
      '118123',
      'HLFXGB21Q82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pontypridd (Hea)',
      '118129',
      'HLFXGB21R38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 13',
      '118138',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 14',
      '118139',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 17',
      '118142',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Ops Offshore Team',
      '118145',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 40',
      '118147',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Ops Offshore Team',
      '118148',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '118149',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Copley Atm Test 118159',
      '118159',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '118162',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '118166',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '118174',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Savings Direct',
      '118175',
      'HLFXGB21Y60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Motherwell - R (118176)',
      '118176',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Polegate (Hps)',
      '118193',
      'HLFXGB21R34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Quinton (Hps)',
      '118195',
      'HLFXGB21R90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Radcliffe on Trent (Hps)',
      '118201',
      'HLFXGB21R93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redcar (Hps)',
      '118212',
      'HLFXGB21S08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reigate (Hps)',
      '118214',
      'HLFXGB21S21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ripon (Hps)',
      '118225',
      'HLFXGB21S32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ross on Wye  (Hps)',
      '118231',
      'HLFXGB21S47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rotherham (Hps)',
      '118234',
      'HLFXGB21S49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rothwell (Hea)',
      '118236',
      'HLFXGB21S56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Roundhay (Hps)',
      '118237',
      'HLFXGB21S57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gateshead Metro (118238)',
      '118238',
      'HLFXGB21S59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Queens Arcade',
      '118245',
      'HLFXGB21S63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Reading (Hea)',
      '118251',
      'HLFXGB21R99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Queens Arcade',
      '118255',
      'HLFXGB21S34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ripley (Hps)',
      '118260',
      'HLFXGB21S30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Redhill Hps',
      '118261',
      'HLFXGB21S16'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Queens Arcade',
      '118264',
      'HLFXGB21S26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Retford (Hps)',
      '118265',
      'HLFXGB21S25'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hastings (118267)',
      '118267',
      'HLFXGB21S71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rochdale (Hps)',
      '118269',
      'HLFXGB21S37'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Epay',
      '118277',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118278)',
      '118278',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118279)',
      '118279',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118280)',
      '118280',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118282)',
      '118282',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118283)',
      '118283',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118284)',
      '118284',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sandbach (Hps)',
      '118318',
      'HLFXGB21S79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sandwich (Hps)',
      '118321',
      'HLFXGB21S80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Scunthorpe (Hps)',
      '118324',
      'HLFXGB21S90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Seaham (Hps)',
      '118325',
      'HLFXGB21S94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Selby (Hps)',
      '118329',
      'HLFXGB21S97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shaw (Hps)',
      '118336',
      'HLFXGB21T05'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shipton on Stour',
      '118352',
      'HLFXGB21T31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sidcup (Hps)',
      '118358',
      'HLFXGB21T40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Skelton in Cleveland (Hps)',
      '118365',
      'HLFXGB21T55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) South Shields (Hps)',
      '118378',
      'HLFXGB21T77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southampton 118382',
      '118382',
      'HLFXGB21K71'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southsea (Hps)',
      '118385',
      'HLFXGB21U03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Spennymoor (Hps)',
      '118390',
      'HLFXGB21U07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) St Helens (Hps)',
      '118394',
      'HLFXGB21U15'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stanley (Hps)',
      '118406',
      'HLFXGB21U27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stockton Heath (Hps)',
      '118415',
      'HLFXGB21U50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stockton on Tees (Hps)',
      '118416',
      'HLFXGB21U52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stokesley',
      '118418',
      'HLFXGB21U58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stonehouse (Hps)',
      '118421',
      'HLFXGB21U61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stratford (118424)',
      '118424',
      'HLFXGB21U67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Streatham (Hps)',
      '118426',
      'HLFXGB21U73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stroud (Hps)',
      '118431',
      'HLFXGB21U79'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sunderland (Hps)',
      '118436',
      'HLFXGB21U83'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sunningdale (Hps)',
      '118438',
      'HLFXGB21U87'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swindon Bridge Street',
      '118444',
      'HLFXGB21V10'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sheffield (Hps)',
      '118449',
      'HLFXGB21T08'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Slough (Hps)',
      '118451',
      'HLFXGB21T61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sutton-on-Sea (Hps)',
      '118457',
      'HLFXGB21U98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swanley (Hps)',
      '118458',
      'HLFXGB21V01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swindon Bridge Street',
      '118459',
      'HLFXGB21V09'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Swindon Bridge Street',
      '118461',
      'HLFXGB21V12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Selly Oak (Hps)',
      '118467',
      'HLFXGB21T01'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walton-on-Thames Dist',
      '118470',
      'HLFXGB21U81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walton-on-Thames Dist',
      '118473',
      'HLFXGB21T26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Slough (Hea)',
      '118475',
      'HLFXGB21T60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Solihull (118476)',
      '118476',
      'HLFXGB21T66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Southport (Hps)',
      '118477',
      'HLFXGB21T99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Staines (Gale & Power)',
      '118479',
      'HLFXGB21U24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Stafford (Hps)',
      '118482',
      'HLFXGB21U22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sherwood Nottingham (Hps)',
      '118487',
      'HLFXGB21T27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Seaford (Hps)',
      '118488',
      'HLFXGB21S92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Shoreham (Hps)',
      '118489',
      'HLFXGB21T36'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Epay',
      '118515',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Connect Fraud 2 118516',
      '118516',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gtc (Non Ics Trans)',
      '118537',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gtc (Non Ics Trans)',
      '118541',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 7',
      '118542',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 11',
      '118547',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gops Oppi Server 12',
      '118548',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Ops Offshore Team',
      '118549',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '118552',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Ops Offshore Team',
      '118554',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '118563',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Portland Street2 (118564)',
      '118564',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '118565',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '118569',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '118572',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rfu - App Hfx1 - L',
      '118576',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Onboarding Test 118577',
      '118577',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham Epay',
      '118590',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118591)',
      '118591',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Connect Newport (118592)',
      '118592',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118594)',
      '118594',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118595)',
      '118595',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118596)',
      '118596',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118597)',
      '118597',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (118599)',
      '118599',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tadcaster',
      '118630',
      'HLFXGB21V19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tarporley (Hps)',
      '118635',
      'HLFXGB21V27'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tarporley',
      '118636',
      'HLFXGB21V26'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tenterden (Hps)',
      '118640',
      'HLFXGB21V33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Trowbridge (Hps)',
      '118675',
      'HLFXGB21V59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tunbridge Wells (118676)',
      '118676',
      'HLFXGB21V63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bolton Harwood (Hea)',
      '118678',
      'HLFXGB21C42'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tamworth (Hps)',
      '118682',
      'HLFXGB21V23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Tilehurst (Hps)',
      '118684',
      'HLFXGB21V45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Todmorden (Hps)',
      '118688',
      'HLFXGB21V48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Northallerton',
      '118696',
      'HLFXGB21WAS'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Uckfield (Hps)',
      '118749',
      'HLFXGB21V70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ulverston (Hps)',
      '118751',
      'HLFXGB21V73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sale (118756)',
      '118756',
      'HLFXGB21V77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wakefield (Hps)',
      '118784',
      'HLFXGB21V82'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walderslade',
      '118785',
      'HLFXGB21V89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walderslade (Hps)',
      '118786',
      'HLFXGB21V90'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Haymkt (118791)',
      '118791',
      'HLFXGB21V95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walthamstow (Hps)',
      '118794',
      'HLFXGB21W02'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walton Vale (Hea)',
      '118797',
      'HLFXGB21W04'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warminster (Hps)',
      '118801',
      'HLFXGB21W12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warrington (Hps)',
      '118804',
      'HLFXGB21W14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Warwick (Hps)',
      '118806',
      'HLFXGB21W20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Washington (Hps)',
      '118807',
      'HLFXGB21W22'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Liverpool Waterloo (Hps)',
      '118809',
      'HLFXGB21N54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Waterlooville (Hps)',
      '118810',
      'HLFXGB21W24'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Bridgford (Hps)',
      '118825',
      'HLFXGB21W38'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Newcastle Haymkt (118828)',
      '118828',
      'HLFXGB21W43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Westbury (Hps)',
      '118838',
      'HLFXGB21W57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Westbury-on-Trym',
      '118839',
      'HLFXGB21W58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Westhoughton (Hea)',
      '118841',
      'HLFXGB21W61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wetherby (Hps)',
      '118843',
      'HLFXGB21W63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gateshead Metro (118847)',
      '118847',
      'HLFXGB21W67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Cardiff Queens Arcade',
      '118850',
      'HLFXGB21W70'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Whitley Bay Park View',
      '118854',
      'HLFXGB21W74'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Bradford Bank St',
      '118860',
      'HLFXGB21W77'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Widnes (Hps)',
      '118862',
      'HLFXGB21W81'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wigan (Hps)',
      '118863',
      'HLFXGB21W84'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wigston Hps',
      '118864',
      'HLFXGB21W86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hull Jameson St (118868)',
      '118868',
      'HLFXGB21W88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Winchester (Hps)',
      '118873',
      'HLFXGB21W92'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Windsor (Hps)',
      '118875',
      'HLFXGB21W96'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gateshead Metro (118877)',
      '118877',
      'HLFXGB21W97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Winsford (Hps)',
      '118879',
      'HLFXGB21W98'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Withington (Hea)',
      '118884',
      'HLFXGB21Y07'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wollaton (Hps)',
      '118887',
      'HLFXGB21Y12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wombwell',
      '118889',
      'HLFXGB21Y17'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worksop (Hps)',
      '118904',
      'HLFXGB21Y28'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wotton under Edge (Hps)',
      '118907',
      'HLFXGB21Y35'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Windlesham (Hps)',
      '118915',
      'HLFXGB21W94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Woolton (Hea)',
      '118916',
      'HLFXGB21Y21'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worthing Broadwater (Hea)',
      '118917',
      'HLFXGB21Y32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Uxbridge (Frank Farr)',
      '118924',
      'HLFXGB21V80'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Walsall (118926)',
      '118926',
      'HLFXGB21V97'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Bromwich (Hps)',
      '118927',
      'HLFXGB21W40'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) West Drayton (Frank Farr)',
      '118928',
      'HLFXGB21W45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Yardley (Hps)',
      '118930',
      'HLFXGB21Y39'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (118931)',
      '118931',
      'HLFXGB21W30'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Wolverhampton (118932)',
      '118932',
      'HLFXGB21Y14'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worthing Central (Hea)',
      '118936',
      'HLFXGB21Y33'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Whitstable (Hea)',
      '118938',
      'HLFXGB21W75'
    ],
    [
      "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Worthing L'hampton (Hps)",
      '118939',
      'HLFXGB21Y34'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Yarm (Hps)',
      '118979',
      'HLFXGB21Y41'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) York (Hps)',
      '118985',
      'HLFXGB21Y46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) If Current Account',
      '119100',
      'HLFXGB21L57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Current Accounts',
      '119109',
      'HLFXGB21T47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) I F Savings',
      '119110',
      'HLFXGB21L54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Savings Plans',
      '119114',
      'HLFXGB21T53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) I F Savings A/C',
      '119115',
      'HLFXGB21L55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Savings Accounts',
      '119119',
      'HLFXGB21T52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) I F Mortgages',
      '119120',
      'HLFXGB21L53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Mortgages',
      '119124',
      'HLFXGB21T51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) I F Mortgage A/C',
      '119125',
      'HLFXGB21L52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Mortgage Accounts',
      '119129',
      'HLFXGB21T50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) I F Loans',
      '119130',
      'HLFXGB21L51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Loans',
      '119134',
      'HLFXGB21T49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) I F Loan A/C',
      '119135',
      'HLFXGB21L50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Loan Accounts',
      '119139',
      'HLFXGB21T48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) I F Credit Cards',
      '119140',
      'HLFXGB21L49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Credit Cards',
      '119144',
      'HLFXGB21T46'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) I F Asu',
      '119145',
      'HLFXGB21L47'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Asu',
      '119146',
      'HLFXGB21T43'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) If Asu Sub Accounts',
      '119147',
      'HLFXGB21L56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Asu Sub Accounts',
      '119149',
      'HLFXGB21T44'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) I F Commission',
      '119150',
      'HLFXGB21L48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Sjpb Commission',
      '119154',
      'HLFXGB21T45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) S R O',
      '119201',
      'HLFXGB21H99'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Unit Trust',
      '119281',
      'HLFXGB21J94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Life Limited',
      '119284',
      'HLFXGB21J88'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Telephony (119501)',
      '119501',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '119520',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '119529',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '119531',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '119532',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '119610',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Andover Sc (119612)',
      '119612',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Leeds Lovell Park',
      '119614',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '119615',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Internet Banking 119616',
      '119616',
      'HLFXGB21Z20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Customer Financial Assistan',
      '119625',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '119627',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '119628',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '119629',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Et Digital Uat (119633)',
      '119633',
      'HLFXGB21Z23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Banking Operations',
      '119635',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Rfu - Non-P Halifax 2',
      '119643',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Credit Operations',
      '119647',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Andover Svc Cen (119660)',
      '119660',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Birmingham BP Epay',
      '119662',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chelmsford Sc Halifax 1',
      '119680',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Clearing Centre',
      '119691',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Postal Credit Solution',
      '119694',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Ipsl - Cri',
      '119695',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Branch Suspense',
      '119700',
      'HLFXGB21C76'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Interest Remit Cheques',
      '119701',
      'HLFXGB21L65'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Advance Suspense',
      '119702',
      'HLFXGB21A31'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 3',
      '119703',
      'HLFXGB21K57'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 4',
      '119704',
      'HLFXGB21K58'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 5',
      '119705',
      'HLFXGB21K59'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 6',
      '119706',
      'HLFXGB21K60'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 7',
      '119707',
      'HLFXGB21K61'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 8',
      '119708',
      'HLFXGB21K62'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 9',
      '119709',
      'HLFXGB21K63'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 10',
      '119710',
      'HLFXGB21K48'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 11',
      '119711',
      'HLFXGB21K49'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 12',
      '119712',
      'HLFXGB21K50'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 13',
      '119713',
      'HLFXGB21K51'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 14',
      '119714',
      'HLFXGB21K52'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 15',
      '119715',
      'HLFXGB21K53'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 16',
      '119716',
      'HLFXGB21K54'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 17',
      '119717',
      'HLFXGB21K55'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Purchase Ledger',
      '119718',
      'HLFXGB21R85'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Head Office Suspense A/C 19',
      '119719',
      'HLFXGB21K56'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Accounts Payable',
      '119720',
      'HLFXGB21A23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) General Insurance',
      '119721',
      'HLFXGB21I67'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Life',
      '119722',
      'HLFXGB21J86'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Helm',
      '119723',
      'HLFXGB21K73'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Loans',
      '119724',
      'HLFXGB21J89'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Share Dealing',
      '119725',
      'HLFXGB21T03'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pcs Lra',
      '119800',
      'HLFXGB21Q93'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pcs Lra 1',
      '119801',
      'HLFXGB21Q94'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Pcs Lra 2',
      '119802',
      'HLFXGB21Q95'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Aesop Contributions',
      '119810',
      'HLFXGB21A32'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) External Payments',
      '119901',
      'HLFXGB21I12'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Monthly Income',
      '119902',
      'HLFXGB21P23'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Interest Remits',
      '119903',
      'HLFXGB21L66'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Staff Salaries',
      '119904',
      'HLFXGB21U20'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Staff Pensions',
      '119905',
      'HLFXGB21U19'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chaps Payments (Banking)',
      '119906',
      'HLFXGB22XXX'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Chaps Payment Treasury',
      '119907',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Hbs Visa Payments',
      '119909',
      'HLFXGB21K45'
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Halifax Shr Sales',
      '119910',
      'HLFXGB21J93'
    ],
    ['Bank of Scotland plc London 38 Threadneedle St', '120103', 'BOFSGB21254'],
    ['Bank of Scotland plc Chaps - Repairs', '120104', 'BOFSGB31GBP'],
    ['Bank of Scotland plc New Issues Department', '120106', ''],
    ['Bank of Scotland plc Nws Preference Acc Centre', '120121', 'BOFSGB21252'],
    ['Bank of Scotland plc Nws Bank Pref Acc Centre', '120122', 'BOFSGB21251'],
    ['Bank of Scotland plc Chester Bos Preference', '120123', 'BOFSGB21379'],
    ['Bank of Scotland plc Chequemaster', '120124', 'BOFSGB21380'],
    ['Bank of Scotland plc Capital Bank Aa Motor Acc', '120125', 'BOFSGB21381'],
    ['Bank of Scotland plc Chester Rfs Pref Acc Cent', '120126', 'BOFSGB21382'],
    ['Bank of Scotland plc Chester Msd Pref Acc Cent', '120127', 'BOFSGB21383'],
    [
      'Bank of Scotland plc Chester Pref Gold Acc Cent',
      '120128',
      'BOFSGB21384'
    ],
    ['Bank of Scotland plc Chester Caravan Pref Acc', '120129', 'BOFSGB31XXX'],
    ['Bank of Scotland plc Chester I M Pref & Chaps', '120130', 'BOFSGB31001'],
    [
      'Bank of Scotland plc Chester Frf Pref Cent Chap',
      '120131',
      'BOFSGB31002'
    ],
    ['Bank of Scotland plc Preference Funding', '120132', 'BOFSGB21386'],
    ['Bank of Scotland plc Preference Funding', '120133', 'BOFSGB21387'],
    ['Bank of Scotland plc B O S Forthright Pref', '120134', 'BOFSGB21388'],
    ['Bank of Scotland plc Bos Inchcape Financial', '120135', 'BOFSGB21389'],
    [
      'Bank of Scotland plc Bank of Scotland Pref No.2',
      '120136',
      'BOFSGBS1PRF'
    ],
    ['Bank of Scotland plc Bos Daihatsu Financial', '120137', 'BOFSGB21390'],
    ['Bank of Scotland plc Preference Funding', '120138', 'BOFSGB21391'],
    ['Bank of Scotland plc Maxcar Preference', '120139', 'BOFSGB21392'],
    ['Bank of Scotland plc Garland Preference', '120140', 'BOFSGB21393'],
    ['Bank of Scotland plc Maranello Financial Serv', '120141', 'BOFSGB21394'],
    ['Bank of Scotland plc Gus Preference', '120143', 'BOFSGB21396'],
    [
      'Bank of Scotland plc Countryside Preference A/C',
      '120144',
      'BOFSGB21397'
    ],
    ['Bank of Scotland plc Nfu Vechicle Finance Pref', '120145', 'BOFSGB21398'],
    ['Bank of Scotland plc Preference Funding', '120146', 'BOFSGB21399'],
    ['Bank of Scotland plc Preference Funding', '120147', 'BOFSGB21439'],
    ['Bank of Scotland plc Chester Vision Account', '120148', ''],
    ['Bank of Scotland plc Preference Funding', '120150', 'BOFSGB21441'],
    ['Bank of Scotland plc Preference Funding', '120151', 'BOFSGB21442'],
    ['Bank of Scotland plc Auto By Tel', '120152', 'BOFSGBS1CBS'],
    ['Bank of Scotland plc Preference Funding', '120154', 'BOFSGBS1AAF'],
    ['Bank of Scotland plc Preference Account', '120155', 'BOFSGBS1BRP'],
    ['Bank of Scotland plc Mytravelco', '120156', 'BOFSGBS1BOC'],
    ['Bank of Scotland plc Preference Funding', '120157', 'BOFSGBS1HOC'],
    ['Bank of Scotland plc Preference Funding', '120158', 'BOFSGBS1AAC'],
    ['Bank of Scotland plc Private Banking London', '120159', 'BOFSGBS1PBE'],
    [
      'Bank of Scotland plc British Gas Reserve Funding',
      '120161',
      'BOFSGBS1BGP'
    ],
    ['Bank of Scotland plc M U Finance', '120162', 'BOFSGBS1MUF'],
    ['Bank of Scotland plc Preference Funding', '120163', 'BOFSGBS1LTG'],
    ['Bank of Scotland plc Ge Capital Preference', '120164', 'BOFSGBS1GEC'],
    ['Bank of Scotland plc Scottish Gas Reserve', '120165', 'BOFSGBS1SGR'],
    ['Bank of Scotland plc The Mortgage Business', '120166', 'BOFSGBS1MTG'],
    [
      'Bank of Scotland plc Bank of Scotland Pref(Hiil)',
      '120167',
      'BOFSGBS1HIL'
    ],
    ['Bank of Scotland plc Hspf Preference Nb', '120168', 'BOFSGBS1HSP'],
    [
      'Bank of Scotland plc H S P F  Ltd - Littlewoods',
      '120169',
      'BOFSGBS1LTW'
    ],
    ['Bank of Scotland plc A A Budget Account', '120215', 'BOFSGB21250'],
    ['Bank of Scotland plc Head Office', '120217', 'BOFSGB31003'],
    ['Bank of Scotland plc Sfu Libor Corp England', '120256', 'BOFSGBS1SFC'],
    ['Bank of Scotland plc Sfu Libor Bb', '120257', 'BOFSGBS1SFB'],
    ['Bank of Scotland plc Edin Royal Mile(120565)', '120565', 'BOFSGB21255'],
    ['Bank of Scotland plc Edin Royal Mile (120577)', '120577', 'BOFSGB21260'],
    ['Bank of Scotland plc Edin Royal Mile (120594)', '120594', 'BOFSGB21259'],
    ['Bank of Scotland plc Edin Royal Mile 12-08-81', '120881', 'BOFSGB21258'],
    ['Bank of Scotland plc Edin Royal Mile (120883)', '120883', 'BOFSGB21257'],
    ['Bank of Scotland plc Edin Royal Mile (120895)', '120895', 'BOFSGB21256'],
    ['Bank of Scotland plc Edin Royal Mile (120919)', '120919', 'BOFSGB21249'],
    ['Bank of Scotland plc Edin Royal Mile (120925)', '120925', 'BOFSGB21242'],
    ['Bank of Scotland plc Edin Royal Mile (120926)', '120926', 'BOFSGB21241'],
    ['Bank of Scotland plc Edin Royal Mile (120949)', '120949', 'BOFSGB21240'],
    ['Bank of Scotland plc Edin Royal Mile (120961)', '120961', 'BOFSGB21239'],
    ['Bank of Scotland plc Edin Royal Mile (120969)', '120969', 'BOFSGBS1CBE'],
    ['Bank of Scotland plc London, Treasury', '121101', 'BOFSGB22XXX'],
    ['Bank of Scotland plc London Oxford Street', '121102', ''],
    ['Bank of Scotland plc Edin Royal Mile (121103)', '121103', 'BOFSGB21238'],
    ['Bank of Scotland plc London Haymarket', '121205', ''],
    ['Bank of Scotland plc Berwick upon Tweed', '121257', 'BOFSGB21243'],
    ['Bank of Scotland plc Edin Royal Mile 12-12-68', '121268', 'BOFSGB21248'],
    ['Bank of Scotland plc Edin Royal Mile 12-12-82', '121282', 'BOFSGB21247'],
    ['Bank of Scotland plc Agriculture Branch', '121283', 'BOFSGBS1ABB'],
    ['Bank of Scotland plc Edin Royal Mile (121285)', '121285', 'BOFSGB21246'],
    ['Bank of Scotland plc Nfu Mutual Finance', '121286', 'BOFSGBS1NFM'],
    ['Bank of Scotland plc Nfu M Account', '121287', 'BOFSGBS1NFA'],
    ['Bank of Scotland plc Edin Royal Mile (121311)', '121311', 'BOFSGB21245'],
    ['LLOYDS BANK PLC Fps Live Proving Test', '121318', ''],
    ['Bank of Scotland plc Edin Royal Mile (121355)', '121355', 'BOFSGB21244'],
    ['Bank of Scotland plc Edin Royal Mile (121630)', '121630', 'BOFSGB21277'],
    ['Bank of Scotland plc Edin Royal Mile (121639)', '121639', 'BOFSGB21276'],
    ['Bank of Scotland plc Edin Royal Mile (121735)', '121735', 'BOFSGB21275'],
    ['Bank of Scotland plc Edin Royal Mile (121740)', '121740', 'BOFSGB21274'],
    ['Bank of Scotland plc Edin Royal Mile (121750)', '121750', 'BOFSGB21273'],
    ['Bank of Scotland plc Edin Royal Mile (121805)', '121805', 'BOFSGB21378'],
    ['Bank of Scotland plc Edin Royal Mile (121868)', '121868', 'BOFSGB21278'],
    ['Bank of Scotland plc Berwick upon Tweed', '121946', 'BOFSGB21283'],
    [
      'Bank of Scotland plc Pymnt &Int Serv London Euro',
      '122010',
      'BOFSGB21EUL'
    ],
    ['Bank of Scotland plc Payment & Int Serv London', '122012', 'BOFSGB2LXXX'],
    [
      'Bank of Scotland plc Isle of Man (122014) Closed',
      '122014',
      'BOFSIM21XXX'
    ],
    ['Bank of Scotland plc Money Market Cheque A/C', '122026', 'BOFSGB21282'],
    [
      'Bank of Scotland plc Central Banking Branch Hobs',
      '122029',
      'BOFSGB21281'
    ],
    ['Bank of Scotland plc Share Centre', '122137', 'BOFSGB21280'],
    ['Bank of Scotland plc Charles Stanley & Co Ltd', '122139', 'BOFSGB21272'],
    ['Bank of Scotland plc Brewin Dolphin Ltd (E)', '122153', 'BOFSGB21263'],
    ['Bank of Scotland plc Efg Harris Allday', '122154', 'BOFSGB21262'],
    ['Bank of Scotland plc Investec W & I Ltd', '122181', 'BOFSGB21261'],
    ['Bank of Scotland plc Charles Schwab Europe', '122182', 'BOFSGB21266'],
    ['Bank of Scotland plc Giant', '122183', 'BOFSGB21271'],
    ['Bank of Scotland plc Kingston Smith Branch', '122184', 'BOFSGB21270'],
    ['Bank of Scotland plc Tilney & Co', '122186', 'BOFSGB21268'],
    ['Bank of Scotland plc James Brearley & Sons', '122187', 'BOFSGB21267'],
    ['Bank of Scotland plc Citigroup Quilter', '122191', 'BOFSGB21206'],
    ['Bank of Scotland plc Crabtree Property', '122192', 'BOFSGB21205'],
    ['Bank of Scotland plc Arnold Stansby & Co', '122194', 'BOFSGB21203'],
    ['Bank of Scotland plc Private Client Banking', '122206', 'BOFSGB21202'],
    ['Bank of Scotland plc Private Client Banking', '122207', 'BOFSGB21195'],
    ['Bank of Scotland plc S P Angel & Co', '122210', 'BOFSGB21192'],
    ['Bank of Scotland plc Pershing Securities Ltd', '122227', 'BOFSGB21196'],
    ['Bank of Scotland plc Hargreaves Lansdown', '122230', 'BOFSGB21199'],
    ['Bank of Scotland plc Mercater Capital Mgmt Ltd', '122231', 'BOFSGB21198'],
    ['Bank of Scotland plc W H Ireland Ltd', '122233', 'BOFSGB21230'],
    [
      'Bank of Scotland plc Maunby Investment Mgmt Ltd',
      '122236',
      'BOFSGB21227'
    ],
    ['Bank of Scotland plc Fyshe Horton Finney Ltd', '122237', 'BOFSGB21226'],
    ['Bank of Scotland plc M D Barnard & Co Ltd', '122238', 'BOFSGB21231'],
    ['Bank of Scotland plc Jlt', '122241', 'BOFSGB21404'],
    ['Bank of Scotland plc Bos Redmayne Bentley', '122242', 'BOFSGB21405'],
    ['Bank of Scotland plc Brittanic Group Services', '122245', 'BOFSGB21408'],
    ['Bank of Scotland plc I C A E W', '122246', 'BOFSGB21409'],
    ['Bank of Scotland plc Private Client Banking', '122247', 'BOFSGB21410'],
    ['Bank of Scotland plc Brewin Dolphin Ltd (P)', '122248', 'BOFSGB21411'],
    ['Bank of Scotland plc M & G Group plc (1)', '122250', 'BOFSGB21444'],
    ['Bank of Scotland plc Royal & Sun Alliance', '122253', 'BOFSGB21447'],
    ['Bank of Scotland plc Aviva Deposits UK Ltd', '122254', 'BOFSGB21448'],
    ['Bank of Scotland plc Towry (122255)', '122255', 'BOFSGB21449'],
    ['Bank of Scotland plc Royal London Man Servs', '122256', 'BOFSGB21450'],
    ['Bank of Scotland plc Resi Morts 122261', '122261', ''],
    ['Bank of Scotland plc Resi Morts 122262', '122262', 'BOFSGBZ1001'],
    ['Bank of Scotland plc American Express Ltd', '122264', 'BOFSGBS1CBA'],
    ['Bank of Scotland plc Man Securities Ltd Branch', '122265', 'BOFSGBS1WSL'],
    ['Bank of Scotland plc Tdwcs LLP', '122267', 'BOFSGBS1CBB'],
    ['Bank of Scotland plc Talos Securities Ltd', '122269', 'BOFSGBS1STC'],
    ['Bank of Scotland plc G H C Capital Markets', '122276', 'BOFSGBS1GHC'],
    ['Bank of Scotland plc Greyfriars Asset Manage', '122283', 'BOFSGBS1GAM'],
    ['Bank of Scotland plc Specialist Banking', '122291', 'BOFSGB21236'],
    ['Bank of Scotland plc Cardiff', '122300', ''],
    ['Bank of Scotland plc Chief Office (122311)', '122311', 'BOFSGB21BWS'],
    ['Bank of Scotland plc Bank of Wales-Euro', '122330', ''],
    ['Bank of Scotland plc Midway Cash Centre', '122399', ''],
    [
      'Bank of Scotland plc Corporate Processing Centre',
      '122441',
      'BOFSGBS1CPC'
    ],
    ['Bank of Scotland plc Private Banking London', '122443', 'BOFSGB21BSH'],
    ['Bank of Scotland plc Vam', '122445', 'BOFSGBS1BBS'],
    ['Bank of Scotland plc Mobile Phone Banking', '122450', 'BOFSGBS1MPB'],
    ['Bank of Scotland plc Jigsaw Banking', '122451', 'BOFSGBS1JIG'],
    ['Bank of Scotland plc Saga', '122453', 'BOFSGBS1SAG'],
    ['Bank of Scotland plc Mound Funding', '122454', 'BOFSGBS1MOF'],
    ['Bank of Scotland plc Private Banking Leeds', '122455', 'BOFSGBS1PSL'],
    [
      'Bank of Scotland plc Private Banking Manchester',
      '122456',
      'BOFSGBS1PSM'
    ],
    [
      'Bank of Scotland plc Private Banking Southampton',
      '122457',
      'BOFSGBS1IPO'
    ],
    ['Bank of Scotland plc Edin Royal Mile (122458)', '122458', 'BOFSGB21463'],
    ['Bank of Scotland plc Cbs Pilot Branch England', '122459', ''],
    ['Bank of Scotland plc Saga Branch No 2', '122471', 'BOFSGB21SG2'],
    ['Bank of Scotland plc Melrose Finance Structure', '122472', 'BOFSGBS1MFS'],
    [
      'Bank of Scotland plc The Direct Business Bank 1',
      '122481',
      'BOFSGBS1BBL'
    ],
    [
      'Bank of Scotland plc The Direct Business Bank 2',
      '122482',
      'BOFSGBS1BBB'
    ],
    [
      'Bank of Scotland plc Flexible Equity Re Mortgage',
      '122484',
      'BOFSGBS1FR2'
    ],
    [
      'Bank of Scotland plc Private Banking Birmingham',
      '122491',
      'BOFSGBS1IPM'
    ],
    ['Bank of Scotland plc Private Banking Bristol', '122492', 'BOFSGBS1IPB'],
    [
      'Bank of Scotland plc Private Banking Manchester',
      '122493',
      'BOFSGBS1IPC'
    ],
    ['Bank of Scotland plc Private Banking Newcastle', '122494', 'BOFSGBS1IPN'],
    ['Bank of Scotland plc Private Banking Ipswich', '122496', 'BOFSGBS1IPS'],
    ['Bank of Scotland plc Private Banking Abingdon', '122497', 'BOFSGBS1IPR'],
    ['Bank of Scotland plc Suffolk Life', '122606', 'BOFSGBS1SUL'],
    ['Bank of Scotland plc Pearson Jones plc', '122608', 'BOFSGBS1PJA'],
    ['Bank of Scotland plc Peverel Group Ltd', '122609', 'BOFSGBS1RCG'],
    ['Bank of Scotland plc Close Wealth Management', '122610', 'BOFSGBS1CWM'],
    [
      'Bank of Scotland plc Merrill Lynch Intl Prvt Cl',
      '122611',
      'BOFSGBS1MLI'
    ],
    ['Bank of Scotland plc B & S Financial Services', '122711', 'BOFSGBS1BSF'],
    ['Bank of Scotland plc Brs Treasury Services', '122712', 'BOFSGBS1BRS'],
    ['Bank of Scotland plc Royal & Sun Alliance', '122713', 'BOFSGBS1RLF'],
    ['Bank of Scotland plc Infrastructure Finance', '122714', 'BOFSGBS1CCB'],
    ['Bank of Scotland plc Towry (122715)', '122715', 'BOFSGBS1JSP'],
    ['Bank of Scotland plc Talos Securities Ltd', '122716', 'BOFSGBS1SEL'],
    ['Bank of Scotland plc Deloitte LLP T&Rs', '122717', 'BOFSGBS1ASD'],
    ['Bank of Scotland plc R F Trustee Co Ltd', '122718', 'BOFSGBS1RFT'],
    ['Bank of Scotland plc Private Client Banking', '122720', 'BOFSGB21BMA'],
    [
      'Bank of Scotland plc Canaccord Genuity Wlth Ltd',
      '122721',
      'BOFSGBS1CSB'
    ],
    [
      'Bank of Scotland plc Client Banking No 4 Branch',
      '122723',
      'BOFSGBS1LAW'
    ],
    ['Bank of Scotland plc Williams De Broe', '122724', 'BOFSGBS1WDB'],
    [
      'Bank of Scotland plc Residential Managemnt Group',
      '122725',
      'BOFSGBS1CIB'
    ],
    ['Bank of Scotland plc Selftrade Uk3', '122726', 'BOFSGBS1SE2'],
    ['Bank of Scotland plc Client Banking Branch 1', '122727', 'BOFSGBS1CL1'],
    ['Bank of Scotland plc Client Banking Branch 2', '122728', 'BOFSGBS1CL2'],
    ['Bank of Scotland plc Client Banking Branch 3', '122729', 'BOFSGBS1CL3'],
    ['Bank of Scotland plc Warr & Co Branch', '122731', 'BOFSGB21WAR'],
    ['Bank of Scotland plc I P M Branch', '122732', 'BOFSGB21525'],
    ['Bank of Scotland plc Insight Liquidity Funds', '122733', 'BOFSGBS1EDJ'],
    ['Bank of Scotland plc Aj Bell Branch', '122734', 'BOFSGBS1SIP'],
    [
      'Bank of Scotland plc Ubs Sterling Offshore Brnch',
      '122735',
      'BOFSGB21UB1'
    ],
    [
      'Bank of Scotland plc Ubs Sterling Offshore Brnch',
      '122736',
      'BOFSGB21UB2'
    ],
    ['Bank of Scotland plc Client Banking Branch 5', '122737', 'BOFSGBS1CL5'],
    ['Bank of Scotland plc Client Banking Branch 6', '122738', 'BOFSGBS1CL6'],
    ['Bank of Scotland plc Client Banking Branch 7', '122739', 'BOFSGBS1CL7'],
    ['Bank of Scotland plc Private Client Banking', '122740', 'BOFSGBS1BML'],
    ['Bank of Scotland plc Private Client Banking', '122741', 'BOFSGBS1OBL'],
    ['Bank of Scotland plc Client Banking Branch 8', '122742', 'BOFSGBS1CL8'],
    ['Bank of Scotland plc Client Banking Branch 9', '122743', 'BOFSGBS1CL9'],
    ['Bank of Scotland plc Sicav Investment Ser Ltd', '122745', 'BOFSGBS1ALM'],
    [
      'Bank of Scotland plc Solitaire Property Mgmt Ltd',
      '122746',
      'BOFSGBS1SLG'
    ],
    ['Bank of Scotland plc Client Banking Branch 12', '122748', 'BOFSGBS1C12'],
    [
      'Bank of Scotland plc Bankhall Inves Finance Ltd',
      '122749',
      'BOFSGBS1C13'
    ],
    ['Bank of Scotland plc Private Investor Capital', '122750', 'BOFSGB21C14'],
    ['Bank of Scotland plc Client Banking Branch 15', '122751', 'BOFSGB21C15'],
    ['Bank of Scotland plc Alpha', '122752', 'BOFSGB21C16'],
    ['Bank of Scotland plc Genesis Wealth Mgmt Ltd', '122753', 'BOFSGB21GWM'],
    ['Bank of Scotland plc David Booler & Co', '122754', 'BOFSGB21DBO'],
    ['Bank of Scotland plc Barratt & Cooke Branch', '122755', 'BOFSGB21BCO'],
    ['Bank of Scotland plc Childrens Mutual', '122756', ''],
    ['Bank of Scotland plc Private Client Banking', '122758', 'BOFSGB21SPW'],
    ['Bank of Scotland plc Hornbuckle Mitchell', '122759', 'BOFSGBS1HOR'],
    [
      'Bank of Scotland plc Broadstone Pensions & Inves',
      '122760',
      'BOFSGBS1BDO'
    ],
    ['Bank of Scotland plc Momentum Pensions Ltd', '122761', 'BOFSGBS1CFM'],
    ['Bank of Scotland plc Robinson Gear', '122762', 'BOFSGBS1PSD'],
    ['Bank of Scotland plc Private Client Banking', '122763', 'BOFSGBS1OAK'],
    ['Bank of Scotland plc Origen', '122764', 'BOFSGBS1ABS'],
    ['Bank of Scotland plc Altus Invest Mgmnt', '122765', 'BOFSGBS1ALT'],
    [
      'Bank of Scotland plc Central Financial Planning',
      '122766',
      'BOFSGB21CFP'
    ],
    ['Bank of Scotland plc E P M L  Branch', '122767', 'BOFSGBS1EPM'],
    ['Bank of Scotland plc East Lancs Moneyline', '122768', 'BOFSGBS1ELM'],
    ['Bank of Scotland plc London York Branch', '122771', 'BOFSGBS1LY1'],
    ['Bank of Scotland plc London York 11 Branch', '122772', 'BOFSGBS1LY2'],
    ['Bank of Scotland plc Killik & Co', '122773', 'BOFSGBS1KLK'],
    [
      'Bank of Scotland plc Xafinity Sipp Services Ltd',
      '122774',
      'BOFSGBS1HZL'
    ],
    ['Bank of Scotland plc Dundas & Wilson Cs LLP', '122775', 'BOFSGBS1IMP'],
    ['Bank of Scotland plc Legal & General Ssip', '122776', 'BOFSGBS1LGP'],
    ['Bank of Scotland plc Legal & General Ssas', '122777', 'BOFSGBS1LGS'],
    [
      'Bank of Scotland plc Mattioli Woods Pc Ltd Branc',
      '122778',
      'BOFSGBS1MTW'
    ],
    ['Bank of Scotland plc Private Client Banking', '122779', 'BOFSGBS1ACC'],
    ['Bank of Scotland plc Quilter Cheviot Ltd', '122780', 'BOFSGBS1SCT'],
    ['Bank of Scotland plc Computershare I S plc', '122782', 'BOFSGBS1NTH'],
    ['Bank of Scotland plc Private Client Banking', '122783', 'BOFSGBS1EXC'],
    [
      'Bank of Scotland plc UK Portfolio Management Ltd',
      '122784',
      'BOFSGBS1YIG'
    ],
    ['Bank of Scotland plc Aviva Administration Ltd', '122785', 'BOFSGBS1AXA'],
    ['Bank of Scotland plc Pal Partnership', '122786', 'BOFSGBS1PAL'],
    ['Bank of Scotland plc Suffolk Life', '122787', 'BOFSGBS1PSB'],
    ['Bank of Scotland plc Suffolk Life', '122788', 'BOFSGBS1SIF'],
    ['Bank of Scotland plc Suffolk Life', '122789', 'BOFSGBS1TIP'],
    ['Bank of Scotland plc Yig Cash Management', '122790', 'BOFSGBS1YCM'],
    [
      'Bank of Scotland plc Corporate Banking Carlisle',
      '122916',
      'BOFSGBS1CBC'
    ],
    ['Bank of Scotland plc Corporate Bk Newcastle', '122917', 'BOFSGBS1CBN'],
    ['Bank of Scotland plc 123493', '123493', ''],
    ['Bank of Scotland plc Telephony (123614)', '123614', ''],
    ['Bank of Scotland plc Telephony (123615)', '123615', ''],
    ["Bank of Scotland plc Sainsbury's Contact Centre", '123902', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gtc (Non Ics Trans)',
      '124107',
      ''
    ],
    ['Bank of Scotland plc Banking Operations', '124109', ''],
    ['Bank of Scotland plc Gops Oppi Server 21', '124112', ''],
    ['Bank of Scotland plc Gops Oppi Server 43', '124113', ''],
    ['Bank of Scotland plc Banking Ops Offshore Team', '124114', ''],
    ['Bank of Scotland plc Berwick upon Tweed', '124542', 'BOFSGBS1001'],
    ['Bank of Scotland plc Birmingham (R)', '124543', 'BOFSGBS1002'],
    ['Bank of Scotland plc Brighton (R)', '124558', 'BOFSGBS1003'],
    ['Bank of Scotland plc Bristol (R)', '124559', 'BOFSGBS1004'],
    ['Bank of Scotland plc Carlisle (R)', '124569', 'BOFSGBS1005'],
    ['Bank of Scotland plc Chester (R)', '124575', 'BOFSGBS1006'],
    ['Bank of Scotland plc Exeter (R)', '124668', 'BOFSGBS1008'],
    ['Bank of Scotland plc Hull (R)', '124739', 'BOFSGBS1009'],
    ['Bank of Scotland plc Ipswich (R)', '124749', 'BOFSGBS1010'],
    ['Bank of Scotland plc Douglas (Iom) (R)', '124751', 'BOFSIM21007'],
    ['Bank of Scotland plc Jersey (R)', '124753', 'BOFSJES1011'],
    [
      'Bank of Scotland plc Jersey Money Mkt A/Ctre (R)',
      '124754',
      'BOFSJES1012'
    ],
    ['Bank of Scotland plc Leeds (R)', '124781', 'BOFSGBS1013'],
    ['Bank of Scotland plc Leicester (R)', '124782', 'BOFSGBS1014'],
    ['Bank of Scotland plc Liverpool (R)', '124788', 'BOFSGBS1015'],
    ['Bank of Scotland plc London Chief Officer (R)', '124797', 'BOFSGBS1016'],
    ['Bank of Scotland plc London West End (R)', '124798', 'BOFSGBS1017'],
    ['Bank of Scotland plc Manchester (R)', '124803', 'BOFSGBS1018'],
    ['Bank of Scotland plc Newcastle upon Tyne (R)', '124814', 'BOFSGBS1019'],
    ['Bank of Scotland plc Norwich (R)', '124820', 'BOFSGBS1020'],
    ['Bank of Scotland plc Nottingham (R)', '124821', 'BOFSGBS1021'],
    ['Bank of Scotland plc Oxford (R)', '124823', 'BOFSGBS1022'],
    ['Bank of Scotland plc Reading (R)', '124841', 'BOFSGBS1023'],
    ['Bank of Scotland plc Sheffield (R)', '124850', 'BOFSGBS1024'],
    ['Bank of Scotland plc Southampton (R)', '124852', 'BOFSGBS1025'],
    ['Bank of Scotland plc Uxbridge (R)', '124876', 'BOFSGBS1026'],
    [
      'Bank of Scotland plc The Mortgage Business Team1',
      '124914',
      'BOFSGB21MBT'
    ],
    [
      'Bank of Scotland plc The Mortgage Business Team2',
      '124915',
      'BOFSGB21MBM'
    ],
    ['Bank of Scotland plc Private Banking Bristol', '124926', 'BOFSGB21BTL'],
    ['Bank of Scotland plc Private Banking Newcastle', '124931', 'BOFSGB21NEW'],
    ['Bank of Scotland plc Private Banking Leeds', '124932', 'BOFSGB21PBL'],
    [
      'Bank of Scotland plc Private Banking Manchester',
      '124933',
      'BOFSGB21MAN'
    ],
    [
      'Bank of Scotland plc Private Banking Manchester',
      '124934',
      'BOFSGBS1MCH'
    ],
    [
      'Bank of Scotland plc Private Banking Birmingham',
      '124935',
      'BOFSGB21BIR'
    ],
    ['Bank of Scotland plc Private Banking Ipswich', '124936', 'BOFSGB21IPW'],
    ['Bank of Scotland plc Private Banking Abingdon', '124937', 'BOFSGB21RDN'],
    [
      'Bank of Scotland plc Private Banking Southampton',
      '124938',
      'BOFSGB21SHM'
    ],
    ['Bank of Scotland plc Private Banking London', '124939', 'BOFSGB21LDN'],
    ['Bank of Scotland plc Private Banking Maidstone', '124945', 'BOFSGBS1MAD'],
    ['Bank of Scotland plc Specialist Banking Branch', '124951', 'BOFSGBS1SBB'],
    ['Bank of Scotland plc Private Banking London', '124956', 'BOFSGB21211'],
    ['Bank of Scotland plc Telephony (125003)', '125003', ''],
    ['Bank of Scotland plc Telephony (125004)', '125004', ''],
    ['Bank of Scotland plc Telephony (125005)', '125005', ''],
    ['Bank of Scotland plc Bos Bulk Tills (125006)', '125006', ''],
    ['Bank of Scotland plc Customer Service (125010)', '125010', ''],
    ['Bank of Scotland plc Glasgow Call Ctr 5', '125011', ''],
    ['Bank of Scotland plc Glasgow Call Ctr 2', '125015', ''],
    ['Bank of Scotland plc Ltsb Telephone Bk', '125016', ''],
    ['Bank of Scotland plc Glasgow Call Ctr 4', '125017', ''],
    ['Bank of Scotland plc Telephony (125018)', '125018', ''],
    ['Bank of Scotland plc Telephony (125019)', '125019', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gtc (Non Ics Trans)',
      '125021',
      ''
    ],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Gtc (Non Ics Trans)',
      '125022',
      ''
    ],
    ['Bank of Scotland plc Portland Street 4', '125050', ''],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Sav/Loans",
      '126000',
      'SANBGB21500'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 2",
      '126001',
      'SANBGB21501'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 3",
      '126002',
      'SANBGB21502'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 4",
      '126003',
      'SANBGB21503'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 5",
      '126004',
      'SANBGB21504'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 6",
      '126005',
      'SANBGB21505'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 7",
      '126006',
      'SANBGB21506'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 8",
      '126007',
      'SANBGB21507'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 9",
      '126008',
      'SANBGB21508'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 10",
      '126009',
      'SANBGB21509'
    ],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank 6010", '126010', 'SANBGB21517'],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Visa Clear",
      '126011',
      'SANBGB21518'
    ],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank Visa Gld", '126012', 'SANBGB21519'],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank Visa Chq", '126014', ''],
    ["SAINSBURY'S BANK PLC Edinburgh Park", '126015', ''],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Mortgage 1",
      '126020',
      'SANBGB21520'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Mortgage 2",
      '126021',
      'SANBGB21521'
    ],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank Savings", '126031', 'SANBGB21523'],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank Atm 5", '126034', ''],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank 6035", '126035', 'SANBGB21513'],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank 6036", '126036', 'SANBGB21514'],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank 6037", '126037', 'SANBGB21515'],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank Savings 11", '126038', ''],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 13",
      '126040',
      'SANBGB21510'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 14",
      '126041',
      'SANBGB21527'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 15",
      '126042',
      'SANBGB21516'
    ],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank Savings", '126046', ''],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 17",
      '126050',
      'SANBGB21050'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 18",
      '126051',
      'SANBGB21051'
    ],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 20",
      '126053',
      'SANBGB21525'
    ],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank Loans", '126054', 'SANBGB21526'],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank 6055", '126055', ''],
    [
      "SAINSBURY'S BANK PLC Sainsbury's Bank Savings 22",
      '126060',
      'SANBGB21528'
    ],
    ["SAINSBURY'S BANK PLC Sainsbury's Bank 6070", '126070', ''],
    ['Santander UK plc N & P Loan A/C (12 Digit)', '133000', ''],
    ['Santander UK plc Santander Mortgage', '133990', ''],
    ['Santander UK plc N & P Loan A/C (9 Digit)', '133997', ''],
    ['Santander UK plc Auto Credits Receivable', '134000', ''],
    ['BRADFORD & BINGLEY LIMITED Bacs Input Standing Orders', '134001', ''],
    ['BRADFORD & BINGLEY LIMITED Bradford & Bingley', '134012', ''],
    ['BRADFORD & BINGLEY LIMITED Auto Credits Receivable 1', '134013', ''],
    ['BRADFORD & BINGLEY LIMITED Auto Credits Receivable 2', '134014', ''],
    ['BRADFORD & BINGLEY LIMITED Incoming Standing Orders', '134015', ''],
    ['BRADFORD & BINGLEY LIMITED Auto Credits Receivable 4', '134016', ''],
    ['BRADFORD & BINGLEY LIMITED Auto Credits Receivable 5', '134017', ''],
    ['BRADFORD & BINGLEY LIMITED Scot Life Home Loans No 2', '134998', ''],
    ['BRADFORD & BINGLEY LIMITED B & B Mortgage Express No.2', '134999', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Financial Markets Acc Serv',
      '150000',
      'RBOSGB2106G'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Bishopsgate (C)',
      '151000',
      'RBOSGB21031'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC International & W/Sale Pyt',
      '151999',
      'RBOSGB2LXXX'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Bishopsgate (B)',
      '152025',
      'RBOSGB21070'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London Victoria A',
      '153000',
      'RBOSGB21020'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Childs Branch', '158000', 'RBOSGB21008'],
    ['C HOARE & CO 32 Lowndes St London Sw1', '159800', ''],
    ['C HOARE & CO Lowndes Street Branch', '159801', ''],
    ['C HOARE & CO Fleet Street', '159900', 'HOABGB2LXXX'],
    ['C HOARE & CO Fleet Street', '159901', ''],
    ['C HOARE & CO Credit Card Repayments Only', '159910', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Do)',
      '160001',
      'RBOSGB21059'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Manchester,St Ann Square(B)',
      '160002',
      'RBOSGB21056'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Manchester, St Ann Sq (A)',
      '160003',
      'RBOSGB2108K'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Manchester Private Bank',
      '160005',
      'RBOSGB21606'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Bolton Group', '160006', 'RBOSGB21402'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Du)',
      '160007',
      'RBOSGB21276'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Sheffield, Church Street',
      '160008',
      'RBOSGB21260'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Wigan', '160009', 'RBOSGB21176'],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Offset (R)', '160010', 'RBOSGB2109S'],
    ['THE ROYAL BANK OF SCOTLAND PLC Fleet Street', '160011', 'RBOSGB21576'],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Bishopsgate', '160015', 'RBOSGB2109U'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (G)',
      '160016',
      'RBOSGB21106'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Manchester, St Ann Square',
      '160018',
      'RBOSGB2109V'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (N)',
      '160019',
      'RBOSGB21114'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bl)',
      '160021',
      'RBOSGB2109W'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Aj)',
      '160023',
      'RBOSGB21121'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (O)',
      '160028',
      'RBOSGB21122'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London Drummonds (C)',
      '160030',
      'RBOSGB21120'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bh)',
      '160032',
      'RBOSGB21119'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Bank Customer Service',
      '160034',
      'RBOSGB2LCBB'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (H)',
      '160035',
      'RBOSGB21117'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Structured Finance',
      '160036',
      'RBOSGB2109B'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Childs Branch (B)',
      '160037',
      'RBOSGB21115'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London Drummonds',
      '160038',
      'RBOSGB21118'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fn)',
      '160039',
      'RBOSGB2109X'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gw)',
      '160042',
      'RBOSGB21023'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Lndn Kensington High St (A)',
      '160048',
      'RBOSGB21116'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Private Bank Drummonds',
      '160050',
      'RBOSGB21598'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Childs Branch (A)',
      '160053',
      'RBOSGB21025'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Abt Finance', '160054', 'RBOSGB2107K'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Southern Corporate Office',
      '160055',
      'RBOSGB21602'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London,Islington',
      '160058',
      'RBOSGB21024'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London Drummonds (E)',
      '160063',
      'RBOSGB21022'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Divest Branch (Rf)',
      '160076',
      'RBOSGB2111E'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cm)',
      '160079',
      'RBOSGB21021'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Private Bank Childs',
      '160080',
      'RBOSGB21604'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hj)',
      '160082',
      'RBOSGB21019'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dd)',
      '160083',
      'RBOSGB21026'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London Drummonds (B)',
      '160084',
      'RBOSGB21034'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (I)',
      '160093',
      'RBOSGB21032'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Private Bank London',
      '160100',
      'RBOSGB21599'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Chatham (Shipping)',
      '160101',
      'RBOSGB21374'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ax)',
      '160102',
      'RBOSGB21030'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC London Victoria', '160109', 'RBOSGB21029'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (De)',
      '160123',
      'RBOSGB21018'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London Drummonds (D)',
      '160129',
      'RBOSGB21028'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dt)',
      '160150',
      'RBOSGB21027'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Propay Chaps Payments Only',
      '160160',
      'RBOSGB55XXX'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Specialised Lending London',
      '160170',
      'RBOSGB2106L'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Centralised Remote Atm Unit',
      '160200',
      'RBOSGB2109G'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Centralised Remote Atm Unt',
      '160201',
      'RBOSGB2106T'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Bolton Customer Serv Ctr',
      '160400',
      'RBOSGB21375'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC H O Collection A/C', '160401', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H O Collection A/C', '160402', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Client Monies Services (R)',
      '160435',
      'RBOSGB2110B'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Cust Financ Supp Serv (T)', '160440', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160805',
      'RBOSGB2103V'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160806',
      'RBOSGB2103A'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160807',
      'RBOSGB2103B'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160808',
      'RBOSGB2103C'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160809',
      'RBOSGB2103W'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160810',
      'RBOSGB2103X'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160811',
      'RBOSGB2103Y'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160812',
      'RBOSGB2103Z'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160813',
      'RBOSGB2104A'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160882',
      'RBOSGB2102X'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160883',
      'RBOSGB2104B'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160884',
      'RBOSGB2104C'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160885',
      'RBOSGB2104D'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160886',
      'RBOSGB2104E'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160887',
      'RBOSGB2104F'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160888',
      'RBOSGB2104G'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160889',
      'RBOSGB2104H'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160890',
      'RBOSGB2104J'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160891',
      'RBOSGB2104K'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160892',
      'RBOSGB2104L'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160893',
      'RBOSGB2104M'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160894',
      'RBOSGB2104N'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Vouch Ser Ltd',
      '160895',
      'RBOSGB2104P'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '160897',
      'RBOSGB2104R'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ew)',
      '161015',
      'RBOSGB21238'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Croydon, Park Street',
      '161016',
      'RBOSGB21319'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cv)',
      '161017',
      'RBOSGB2108Q'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ae)',
      '161018',
      'RBOSGB2108P'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Richmond', '161027', 'RBOSGB21271'],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD St.Helier,Jersey',
      '161028',
      'RBOSJES1028'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Bishopsgate (A)',
      '161029',
      'RBOSGB21033'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Rbsi Luxembourg',
      '161030',
      ''
    ],
    [
      'NEDBANK PRIVATE WEALTH LIMITED Isle of Man - Euro',
      '161043',
      'RFLCIMDXXXX'
    ],
    ['NEDBANK PRIVATE WEALTH LIMITED Jersey - Euro', '161044', 'RFLCJESXXXX'],
    [
      'SHAWBROOK BANK LIMITED Whiteaway Laidlaw - Euro',
      '161048',
      'WWLLGB21XXX'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Comptrshre Inv Serv plc Eur',
      '161049',
      'RBOSGB21609'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Clg Cont Unit - Eng Euro',
      '161050',
      'RBOSGB21562'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Int & Whsle Pyt Lndn  Euro',
      '161055',
      'RBOSGB21563'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Shipping Business Cnt Euro',
      '161060',
      'RBOSGB21564'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Corporate Bkg Off Euro',
      '161065',
      'RBOSGB21565'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London Srv Cent Euro 1',
      '161070',
      'RBOSGB21566'
    ],
    ['NEDBANK PRIVATE WEALTH LIMITED London Office Euro', '161074', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Corrspdnt Bkg Brnch Euro',
      '161075',
      'RBOSGB21567'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Intl & Whsl Paynt Man Euro',
      '161080',
      'RBOSGB21568'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Manchstr Srv Cent Euros',
      '161085',
      'RBOSGB21569'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London Srv Cent Euros  2',
      '161090',
      'RBOSGB21570'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bz)',
      '161114',
      'RBOSGB21384'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fq)',
      '161119',
      'RBOSGB21383'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fl)',
      '161129',
      'RBOSGB21381'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Af)',
      '161130',
      'RBOSGB21359'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dn)',
      '161131',
      'RBOSGB21514'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (GD)',
      '161211',
      'RBOSGB21358'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gg)',
      '161215',
      'RBOSGB21351'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (A)',
      '161217',
      'RBOSGB21367'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ee)',
      '161218',
      'RBOSGB21394'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gh)',
      '161219',
      'RBOSGB21366'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Sheffield,Church Street (B)',
      '161221',
      'RBOSGB21261'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (C)',
      '161224',
      'RBOSGB21363'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hf)',
      '161225',
      'RBOSGB21362'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Da)',
      '161226',
      'RBOSGB21410'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (D)',
      '161227',
      'RBOSGB21408'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (E)',
      '161230',
      'RBOSGB21407'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gn)',
      '161233',
      'RBOSGB21406'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ct)',
      '161235',
      'RBOSGB21405'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Bath', '161253', 'RBOSGB21404'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Berwick upon Tweed',
      '161285',
      'RBOSGB21416'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Birmingham, New Street',
      '161305',
      'RBOSGB2108L'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fc)',
      '161307',
      'RBOSGB21419'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Offset (Rw)', '161309', 'RBOSGB2110T'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gq)',
      '161311',
      'RBOSGB21417'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Offset Service Team',
      '161314',
      'RBOSGB2107X'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Birmingham New Street (A)',
      '161318',
      'RBOSGB21414'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cb)',
      '161319',
      'RBOSGB21500'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dm)',
      '161322',
      'RBOSGB21392'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ef)',
      '161326',
      'RBOSGB21412'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hu)',
      '161329',
      'RBOSGB21389'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (L)',
      '161412',
      'RBOSGB21390'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gu)',
      '161416',
      'RBOSGB21399'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Bradford', '161417', 'RBOSGB21397'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (El)',
      '161418',
      'RBOSGB21190'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (R)',
      '161420',
      'RBOSGB21396'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Brighton & Hove', '161424', 'RBOSGB21350'],
    ['THE ROYAL BANK OF SCOTLAND PLC Bristol', '161425', 'RBOSGB21305'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ca)',
      '161428',
      'RBOSGB21304'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bd)',
      '161429',
      'RBOSGB21302'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (En)',
      '161430',
      'RBOSGB21301'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fa)',
      '161432',
      'RBOSGB21298'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gi)',
      '161434',
      'RBOSGB21314'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (V)',
      '161470',
      'RBOSGB21313'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (W)',
      '161498',
      'RBOSGB21311'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Es)',
      '161512',
      'RBOSGB21312'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hn)',
      '161515',
      'RBOSGB21309'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hm)',
      '161517',
      'RBOSGB21310'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Cambridge Trinity',
      '161519',
      'RBOSGB21307'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ce)',
      '161520',
      'RBOSGB21287'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Cardiff City', '161521', 'RBOSGB21285'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Eu)',
      '161523',
      'RBOSGB21284'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (X)',
      '161525',
      'RBOSGB21281'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gv)',
      '161528',
      'RBOSGB21292'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Aq)',
      '161531',
      'RBOSGB21291'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dh)',
      '161533',
      'RBOSGB21290'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fd)',
      '161547',
      'RBOSGB21282'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (P)',
      '161611',
      'RBOSGB21014'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Chelmsford', '161612', 'RBOSGB21315'],
    ['THE ROYAL BANK OF SCOTLAND PLC Cheltenham', '161613', 'RBOSGB21340'],
    ['THE ROYAL BANK OF SCOTLAND PLC Chester', '161614', 'RBOSGB21339'],
    ['THE ROYAL BANK OF SCOTLAND PLC Chesterfield', '161618', 'RBOSGB21338'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (S)',
      '161620',
      'RBOSGB21337'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Et)',
      '161622',
      'RBOSGB21336'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccms Think Money Ltd',
      '161623',
      'RBOSGB21597'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bq)',
      '161625',
      'RBOSGB21013'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ag)',
      '161629',
      'RBOSGB21334'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Colchester', '161631', 'RBOSGB21349'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Q)',
      '161633',
      'RBOSGB21348'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ge)',
      '161712',
      'RBOSGB21346'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fo)',
      '161718',
      'RBOSGB21345'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (F)',
      '161725',
      'RBOSGB21322'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Coventry', '161726', 'RBOSGB21343'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hl)',
      '161730',
      'RBOSGB21328'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gr)',
      '161731',
      'RBOSGB21327'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cu)',
      '161732',
      'RBOSGB21326'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ed)',
      '161733',
      'RBOSGB21325'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fy)',
      '161741',
      'RBOSGB21320'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dl)',
      '161815',
      'RBOSGB21515'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Derby,Cornmarket',
      '161818',
      'RBOSGB21513'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gs)',
      '161822',
      'RBOSGB21511'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fk)',
      '161826',
      'RBOSGB21272'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Doncaster', '161830', 'RBOSGB21510'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gf)',
      '161834',
      'RBOSGB21508'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fv)',
      '161841',
      'RBOSGB21524'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hp)',
      '161913',
      'RBOSGB21012'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gp)',
      '161915',
      'RBOSGB21502'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fb)',
      '161917',
      'RBOSGB21501'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ft)',
      '161918',
      'RBOSGB21259'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ec)',
      '161920',
      'RBOSGB21167'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hg)',
      '161922',
      'RBOSGB21527'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Aw)',
      '161923',
      'RBOSGB21528'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Exeter', '161925', 'RBOSGB21526'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Holts Branch Farnborough',
      '161926',
      'RBOSGB21469'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ah)',
      '161927',
      'RBOSGB21540'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Al)',
      '161928',
      'RBOSGB21541'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cd)',
      '161930',
      'RBOSGB2108W'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fr)',
      '161931',
      'RBOSGB21539'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cy)',
      '161934',
      'RBOSGB21577'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (M)',
      '161970',
      'RBOSGB21538'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gt)',
      '162012',
      'RBOSGB21536'
    ],
    ['THINK MONEY LIMITED Think Money M', '162015', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Di)',
      '162016',
      'RBOSGB21443'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Be)',
      '162018',
      'RBOSGB21441'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Y)',
      '162020',
      'RBOSGB21479'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hi)',
      '162022',
      'RBOSGB21478'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dr)',
      '162027',
      'RBOSGB21011'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (T)',
      '162028',
      'RBOSGB21481'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Guernsey',
      '162029',
      'RBOSGGS1029'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Guildford', '162030', 'RBOSGB21489'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dv)',
      '162031',
      'RBOSGB21487'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Z)',
      '162032',
      'RBOSGB21486'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dz)',
      '162033',
      'RBOSGB21483'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ai)',
      '162035',
      'RBOSGB21482'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ar)',
      '162036',
      'RBOSGB21463'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Harrow', '162038', 'RBOSGB21462'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Cards Atm Settlement',
      '162050',
      'RBOSGB2106W'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gj)',
      '162110',
      'RBOSGB21460'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Prvt Bnkg Wst Yorkshr Rcsc',
      '162112',
      'RBOSGB2109C'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gk)',
      '162113',
      'RBOSGB21458'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ck)',
      '162117',
      'RBOSGB21457'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (As)',
      '162120',
      'RBOSGB21472'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (BV)',
      '162122',
      'RBOSGB21464'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hq)',
      '162125',
      'RBOSGB21010'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hh)',
      '162128',
      'RBOSGB21470'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Aa)',
      '162129',
      'RBOSGB21471'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bf)',
      '162132',
      'RBOSGB21468'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Huddersfield', '162204', 'RBOSGB21467'],
    ['THE ROYAL BANK OF SCOTLAND PLC Hull', '162211', 'RBOSGB21466'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fs)',
      '162217',
      'RBOSGB21089'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cl)',
      '162219',
      'RBOSGB21280'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dk)',
      '162220',
      'RBOSGB21094'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bg)',
      '162221',
      'RBOSGB21095'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cq)',
      '162223',
      'RBOSGB21100'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fz)',
      '162224',
      'RBOSGB21075'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gy)',
      '162225',
      'RBOSGB21071'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bo)',
      '162226',
      'RBOSGB21099'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bk)',
      '162227',
      'RBOSGB21085'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Db)',
      '162234',
      'RBOSGB21081'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Blackpool Talbot Square (A)',
      '162313',
      'RBOSGB21391'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fi)',
      '162315',
      'RBOSGB21128'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Leeds, Park Row (A)',
      '162317',
      'RBOSGB21127'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Leicester, Market St (A)',
      '162320',
      'RBOSGB21125'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Leicester Market Street',
      '162321',
      'RBOSGB21124'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ho)',
      '162325',
      'RBOSGB21123'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dx)',
      '162328',
      'RBOSGB21035'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fe)',
      '162331',
      'RBOSGB21136'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (AM)',
      '162332',
      'RBOSGB21135'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Leeds Park Row', '162337', 'RBOSGB21126'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Leeds, Private Bank',
      '162340',
      'RBOSGB21607'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Liverpool Dale Street',
      '162406',
      'RBOSGB21133'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dc)',
      '162414',
      'RBOSGB21113'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cw)',
      '162421',
      'RBOSGB21007'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (BP)',
      '162423',
      'RBOSGB21005'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dw)',
      '162424',
      'RBOSGB21060'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bc)',
      '162428',
      'RBOSGB21002'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ab)',
      '162430',
      'RBOSGB21004'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Macclesfield, Chestergate',
      '162432',
      'RBOSGB21001'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (At)',
      '162448',
      'RBOSGB21016'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Au)',
      '162464',
      'RBOSGB21015'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking', '162481', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking', '162485', 'RBOSGB2108A'],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking', '162486', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking', '162490', 'RBOSGB2108B'],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking', '162491', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking', '162494', 'RBOSGB2108C'],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking', '162495', 'RBOSGB2108D'],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking', '162496', 'RBOSGB2108E'],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking', '162499', 'RBOSGB2106Y'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gl)',
      '162510',
      'RBOSGB21017'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dq)',
      '162514',
      'RBOSGB21054'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Eo)',
      '162516',
      'RBOSGB21243'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cn)',
      '162517',
      'RBOSGB21067'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gm)',
      '162518',
      'RBOSGB21066'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hk)',
      '162521',
      'RBOSGB21065'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (An)',
      '162524',
      'RBOSGB21042'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Df)',
      '162525',
      'RBOSGB21041'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Milton Keynes', '162527', 'RBOSGB21039'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (B)',
      '162532',
      'RBOSGB21037'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (J)',
      '162614',
      'RBOSGB21050'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fh)',
      '162618',
      'RBOSGB21045'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ac)',
      '162619',
      'RBOSGB21235'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Newcastle upon Tyne',
      '162621',
      'RBOSGB21140'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bj)',
      '162622',
      'RBOSGB2108Y'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Co)',
      '162624',
      'RBOSGB21233'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fp)',
      '162625',
      'RBOSGB21058'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ev)',
      '162627',
      'RBOSGB21228'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Norwich', '162630', 'RBOSGB21236'],
    ['THE ROYAL BANK OF SCOTLAND PLC Nottingham', '162632', 'RBOSGB21244'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Cheques Direct Int. Rbs',
      '162633',
      'RBOSGB2112E'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bw)',
      '162634',
      'RBOSGB2108U'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (U)',
      '162636',
      'RBOSGB2109D'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Cb Cheque Scan Mob App -Rbs',
      '162637',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ad)',
      '162711',
      'RBOSGB21241'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Br)',
      '162714',
      'RBOSGB21578'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ff)',
      '162722',
      'RBOSGB21240'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hw)',
      '162723',
      'RBOSGB21239'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gz)',
      '162725',
      'RBOSGB21218'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ha)',
      '162729',
      'RBOSGB21217'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hv)',
      '162805',
      'RBOSGB21215'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Eq)',
      '162811',
      'RBOSGB21579'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fj)',
      '162814',
      'RBOSGB21253'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ea)',
      '162816',
      'RBOSGB21213'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Peterborough', '162817', 'RBOSGB21211'],
    ['THE ROYAL BANK OF SCOTLAND PLC Plymouth', '162819', 'RBOSGB21226'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ez)',
      '162820',
      'RBOSGB21225'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hr)',
      '162822',
      'RBOSGB21222'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Er)',
      '162823',
      'RBOSGB21221'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Eg)',
      '162824',
      'RBOSGB21223'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hb)',
      '162826',
      'RBOSGB21220'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fw)',
      '162829',
      'RBOSGB21245'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (HC)',
      '162830',
      'RBOSGB21270'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Preston, Fishergate',
      '162833',
      'RBOSGB21269'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ga)',
      '162920',
      'RBOSGB21266'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gx)',
      '162923',
      'RBOSGB21265'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC London Drummonds (A)',
      '162925',
      'RBOSGB21264'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dj)',
      '162932',
      'RBOSGB21580'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Go)',
      '162933',
      'RBOSGB21283'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ay)',
      '162934',
      'RBOSGB21279'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ak)',
      '163010',
      'RBOSGB21278'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (GB)',
      '163013',
      'RBOSGB21277'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dp)',
      '163015',
      'RBOSGB21057'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ao)',
      '163019',
      'RBOSGB21274'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ba)',
      '163024',
      'RBOSGB21160'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bb)',
      '163025',
      'RBOSGB21158'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC St Helens', '163028', 'RBOSGB21166'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cf)',
      '163032',
      'RBOSGB21273'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fm)',
      '163112',
      'RBOSGB21252'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Az)',
      '163113',
      'RBOSGB21250'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (BT)',
      '163114',
      'RBOSGB21248'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bm)',
      '163115',
      'RBOSGB21246'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hd)',
      '163117',
      'RBOSGB21262'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Sheffield Church Street (C)',
      '163119',
      'RBOSGB21062'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Sheffield Church Street (A)',
      '163120',
      'RBOSGB21258'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bx)',
      '163123',
      'RBOSGB21256'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bn)',
      '163126',
      'RBOSGB21210'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bu)',
      '163127',
      'RBOSGB21255'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Southampton, High Street',
      '163128',
      'RBOSGB21163'
    ],
    ['THINK MONEY LIMITED Think Money', '163130', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Hs)',
      '163132',
      'RBOSGB21165'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Southampton High Street (A)',
      '163137',
      'RBOSGB21164'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ei)',
      '163150',
      'RBOSGB21162'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Southport,Lord Street',
      '163210',
      'RBOSGB21161'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (He)',
      '163217',
      'RBOSGB21173'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Eb)',
      '163221',
      'RBOSGB21148'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Manch. Cheetham Hill (A)',
      '163225',
      'RBOSGB21581'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bs)',
      '163228',
      'RBOSGB21055'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Macclesfield,Chestergate(A)',
      '163232',
      'RBOSGB21009'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cg)',
      '163236',
      'RBOSGB21141'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cr)',
      '163241',
      'RBOSGB21174'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (By)',
      '163245',
      'RBOSGB21149'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ch)',
      '163251',
      'RBOSGB21157'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC National Account Centre',
      '163264',
      'RBOSGB2101W'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Fa UK Gms Mortgages',
      '163285',
      'RBOSGB2109E'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fx)',
      '163305',
      'RBOSGB21156'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ht)',
      '163309',
      'RBOSGB21155'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Swindon', '163311', 'RBOSGB21154'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ep)',
      '163314',
      'RBOSGB21153'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Av)',
      '163316',
      'RBOSGB21150'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Eh)',
      '163317',
      'RBOSGB21175'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Bi)',
      '163320',
      'RBOSGB21193'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cx)',
      '163322',
      'RBOSGB21053'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cs)',
      '163324',
      'RBOSGB21207'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ap)',
      '163326',
      'RBOSGB21208'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (CP)',
      '163327',
      'RBOSGB21206'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fg)',
      '163330',
      'RBOSGB21205'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dg)',
      '163331',
      'RBOSGB21204'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Em)',
      '163333',
      'RBOSGB21203'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Gc)',
      '163345',
      'RBOSGB21202'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Dy)',
      '163361',
      'RBOSGB21196'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ek)',
      '163416',
      'RBOSGB21061'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ci)',
      '163422',
      'RBOSGB21177'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Wilmslow, Grove Street',
      '163424',
      'RBOSGB21184'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cj)',
      '163425',
      'RBOSGB21192'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ex)',
      '163427',
      'RBOSGB21069'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ey)',
      '163428',
      'RBOSGB21068'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Fu)',
      '163431',
      'RBOSGB21189'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cz)',
      '163433',
      'RBOSGB21188'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Ej)',
      '163439',
      'RBOSGB21187'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (K)',
      '163444',
      'RBOSGB21186'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Drummond House (Cc)',
      '163458',
      'RBOSGB21475'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Leeds, Park Row (B)',
      '163480',
      'RBOSGB21185'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Clearing Control Unit-Eng',
      '164200',
      'RBOSGB2105A'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Goodmans Fields Vpc', '164244', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Nsp Default Rbs', '164266', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Delivery Rbs', '164277', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Centralised Unpaids Unit', '164546', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Royal Bank Lms Unit',
      '164951',
      'RBOSGB2LLIQ'
    ],
    [
      'SANTANDER FINANCIAL SERVICES PLC Santander Fin Svcs',
      '165044',
      'SFSXGB2LXXX'
    ],
    ['BANK OF AMERICA, NA London Branch', '165050', 'BOFAGB22XXX'],
    ['BANK OF AMERICA, NA Boa Chaps Returns', '165061', 'BOFAGB22RTN'],
    ['BANK OF CHINA LIMITED London Branch', '165101', 'BKCHGB2LXXX'],
    [
      'THE CHORLEY & DISTRICT BUILDING SOCIETY Chorley & District Bs',
      '165160',
      ''
    ],
    ['CATER ALLEN LTD Cater Allen Private Bank 4', '165169', ''],
    [
      'SANTANDER FINANCIAL SERVICES PLC JERSEY BRANCH T/A SANTANDER INTL Santander Private Banking',
      '165171',
      'ANILJESHXXX'
    ],
    ['CATER ALLEN LTD Cater Allen Private Bank 3', '165172', ''],
    ['CLOSE BROTHERS LIMITED 10 Crown Place  London', '165187', 'CBRLGB2LXXX'],
    ['6 TOWNS CREDIT UNION LIMITED 6 Towns Credit Union Ltd', '165192', ''],
    [
      'CLOSE BROTHERS LIMITED Close Bros Cent Ap Pymnts',
      '165197',
      'CBRLGB2LTSY'
    ],
    [
      'CREDIT AGRICOLE CORPORATE & INVESTMENT BANK 84 Queen Victoria St Ec4',
      '165209',
      ''
    ],
    ['CUMBERLAND BUILDING SOCIETY Cumberland Bs', '165221', 'CMBSGB21XXX'],
    ['TANDEM BANK LIMITED Knightsbridge London Sw1', '165345', 'HLBAGB21XXX'],
    ['KBC Bank NV London', '165487', 'KREDGB2XXXX'],
    ['BANCO SANTANDER S.A. London', '165501', 'BSCHGB2LXXX'],
    [
      'THE BANK OF NEW YORK MELLON London, Chaps Returns',
      '165562',
      'IRVTGB2XRTN'
    ],
    ['MBNA LIMITED Credit Cards', '165570', ''],
    ['UNION BANCAIRE PRIVEE UBP SA London Branch', '165599', 'UBPGGB2XXXX'],
    [
      'UNION BANCAIRE PRIVEE UBP SA Un Bancaire Privee Ubp SA J',
      '165600',
      'UBPGJESXXXX'
    ],
    ['Wells Fargo Bank NA 30 Fenchurch Street', '165671', ''],
    ['NEDBANK PRIVATE WEALTH LIMITED London Office', '165706', 'FBLNGB2LXXX'],
    ['NEDBANK PRIVATE WEALTH LIMITED Jersey', '165707', ''],
    ['CATER ALLEN LTD Cater Allen Private Bank 1', '165709', 'RFSEGB21XXX'],
    ['CATER ALLEN LTD Cater Allen Private Bank 2', '165710', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Shell Visa', '165725', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Visa Platnm', '165726', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Dlf',
      '165727',
      'RBOSGB2102G'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Visa', '165728', ''],
    ["THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Visa P'card", '165729', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Access', '165730', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Affinity', '165732', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Roysct Univ', '165734', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Visa Gold', '165735', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Mint Plat McArd Conv Chq', '165736', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Mintclassic McArd Conv Chqs',
      '165737',
      ''
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Chqlne Rb Adv Visa Clc', '165738', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Mint Gold Visa Conv Chqs', '165739', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Chqline American Airlne',
      '165740',
      ''
    ],
    ['NEDBANK PRIVATE WEALTH LIMITED Isle of Man', '165741', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Chqline Lambeth B S', '165742', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Chequeline Scottish Widows', '165745', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Chequeline Rbs/Tesco V Cls', '165746', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Advanta Visa Platinum', '165747', ''],
    ["THE ROYAL BANK OF SCOTLAND PLC Mint Gold M'card Conv Chqs", '165748', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Chequeline Vpf',
      '165749',
      'RBOSGB2102H'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Platinum Card Conv Chq', '165753', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Tpf Master Card Conv Chqs', '165754', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Credit Card Conv Chqs', '165755', ''],
    ["THE ROYAL BANK OF SCOTLAND PLC Norwich Un M'card Conv Chq", '165756', ''],
    [
      'SMITH & WILLIAMSON INVESTMENT SERVICES LIMITED 25 Moorgate',
      '165760',
      'SMIWGB2LXXX'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Tpf Platinum Visa BT Chqs', '165761', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Tpf Platinum McArd BT Chqs', '165762', ''],
    [
      'STATE STREET BANK AND TRUST COMPANY State St Bk & Trust Primary',
      '165766',
      'SBOSGB2XXXX'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Tpf Busn Card M/C Con Chqs', '165769', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Tpf Bonus Cc M/C Con Chqs', '165771', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Tpf Bonus Cc Visa Con Chqs', '165772', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Tpf Club Card M/C Con Chqs', '165773', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Tpf Club Crd Visa Con Chqs', '165774', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Busines Purch Conv Chq', '165775', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Corp Card Conv Chq', '165776', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Busns Mastcrd Conv Chq', '165777', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Style Financial Services 1', '165783', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Style Financial Services 2', '165784', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Style Financial Services 3', '165785', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Style Financial Services 4', '165786', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Style Financial Services 5', '165787', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Style Financial Services 6', '165788', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs One Card M/C Conv Chqs', '165789', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Bus Card Visa Conv Chq', '165790', ''],
    ['SHAWBROOK BANK LIMITED Manchester Queens Court', '165802', ''],
    ['TRIODOS BANK UK LTD Bristol', '165810', 'TRIOGB22XXX'],
    [
      'SANTANDER FINANCIAL SERVICES PLC JERSEY BRANCH T/A SANTANDER INTL Santander Private Bank No 2',
      '165820',
      ''
    ],
    ['SHAWBROOK BANK LIMITED Queens Court, Manchester', '165873', ''],
    ['THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Isle of Man', '165880', ''],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Isle of Man Douglas Euro',
      '165883',
      'RBOSIMDXXXX'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Rbsi Guernsey',
      '165886',
      'RBOSGGS1886'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ibos Generated Payment Only',
      '165889',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD 22 Hill St, St Helier',
      '165891',
      'RBOSJES1891'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Rbsi Obc Jersey',
      '165893',
      'RBOSJES1893'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Sandpiper House', '165908', 'RBOSGB2105U'],
    ['THE ROYAL BANK OF SCOTLAND PLC Falcon House', '165909', 'RBOSGB2105V'],
    ['THE ROYAL BANK OF SCOTLAND PLC Kestrel House', '165910', 'RBOSGB2105W'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Camberley Cash Centre',
      '165912',
      'RBOSGB2105Y'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Haydock Coin Centre',
      '165913',
      'RBOSGB2105Z'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Rbsi London',
      '165914',
      'RBSIGB2LXXX'
    ],
    ['THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Rbsiuk', '165915', ''],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Rbsi One Card',
      '165916',
      ''
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Dry Run Bank', '165995', ''],
    ['RBS ONE ACCOUNT The One Account', '166050', 'RBOSGB2103P'],
    ['RBS ONE ACCOUNT The One Account B', '166051', 'RBOSGB2106D'],
    ['RBS ONE ACCOUNT First Active', '166052', 'RBOSGB2106E'],
    ['RBS ONE ACCOUNT Direct Line (Mortages)', '166053', 'RBOSGB2106F'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Overseas Student Centre',
      '166666',
      'RBOSGB2108R'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Chatham Waterside',
      '167001',
      'RBOSGB2109T'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dmfo Fraud Ops Rbs (R) Sold',
      '167021',
      'RBOSGB2110C'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Gt Tower St Corp Ser Ct (R)',
      '167023',
      'RBOSGB2110A'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Liverpool Csc', '167125', 'RBOSGB2103T'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Manchester Service Centre',
      '167138',
      'RBOSGB2102E'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Chatham Csc', '167145', 'RBOSGB2106A'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC North of England Csc',
      '167150',
      'RBOSGB2105Q'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Bristol Csc', '167152', 'RBOSGB2107P'],
    ['THE ROYAL BANK OF SCOTLAND PLC Chatham Csc', '167153', 'RBOSGB2107Q'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Fraud Operations',
      '167155',
      'RBOSGB2107S'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Leeds Csc', '167156', 'RBOSGB2107T'],
    ['THE ROYAL BANK OF SCOTLAND PLC Leicester Csc', '167157', 'RBOSGB2107U'],
    ['THE ROYAL BANK OF SCOTLAND PLC Southampton Csc', '167159', 'RBOSGB2107W'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Bolton Customer Serv Ctr',
      '167167',
      'RBOSGB2102F'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Manchester, International',
      '167500',
      'RBOSGB2MXXX'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Chaps Only Treasurers Dept',
      '167575',
      'RBOSGB2LTSY'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Global Lending, London',
      '167576',
      'RBOSGB2LGLO'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Fin Mrkt Fxd Inc & Int', '167580', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Chaps Control Office London',
      '167777',
      'RBOSGB21RTN'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Group Treasury Services', '168620', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Credit Card Operations',
      '168950',
      'RBOSGB2107H'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Retail Cards (Rw)',
      '168956',
      'RBOSGB2111L'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Retail Cards (R)',
      '168957',
      'RBOSGB2111P'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Commercial Cards (Rw)',
      '168958',
      'RBOSGB2111N'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Commercial Cards (R)',
      '168959',
      'RBOSGB2111M'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Mastercard Gold',
      '168964',
      'RBOSGB2106X'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Credit Card Centre No 1', '168971', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Visa Credit Card Dept',
      '168972',
      'RBOSGB2107J'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Credit Card', '168974', 'RBOSGB2107B'],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Black Card', '168975', 'RBOSGB2107A'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Platinum Card',
      '168976',
      'RBOSGB2106Z'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Royal Bank of Scot Onecard',
      '168977',
      'RBOSGB2106H'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Norwich Union Mastercard',
      '168978',
      'RBOSGB2106C'
    ],
    [
      "THE ROYAL BANK OF SCOTLAND PLC Ccc Scotish Power M'card",
      '168979',
      'RBOSGB2104Y'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Visa Infinite',
      '168980',
      'RBOSGB2104S'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Shell Visa', '168981', 'RBOSGB2104Z'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc - Virgin Personal F S',
      '168982',
      'RBOSGB2103Q'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Visa Business/Visa Crp',
      '168983',
      'RBOSGB2105B'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Visa Platinum',
      '168984',
      'RBOSGB2105C'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs/Royscot Gold',
      '168985',
      'RBOSGB2106N'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Scottish Widows',
      '168986',
      'RBOSGB2105D'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ccc Affinity', '168987', 'RBOSGB2102J'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc - Direct Line F S',
      '168988',
      'RBOSGB2103R'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Credit Cc - Purchasing Cds',
      '168989',
      'RBOSGB2103E'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Credit Card Centre-Co Cards',
      '168990',
      'RBOSGB2107G'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Adam & Co Visa',
      '168991',
      'RBOSGB2103F'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ccc Adam & Co Gold',
      '168992',
      'RBOSGB2103G'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Mint Classic Mastercard',
      '168993',
      'RBOSGB2103H'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Mint Platinum Mastercard',
      '168994',
      'RBOSGB2103J'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Cr Card Centre - Visa Gold',
      '168995',
      'RBOSGB2103K'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Mint Classic Visa',
      '168996',
      'RBOSGB2107F'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Mint Gold Mastercard',
      '168997',
      'RBOSGB2105J'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Mint Gold Visa', '168998', 'RBOSGB2103L'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Cr Card-Convenience Chqs',
      '168999',
      'RBOSGB2103M'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC H O Collection Account', '169023', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Coll A/Cs Lon', '169033', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Coll A/Cs Lon', '169041', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Collection Account', '169073', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H.O.Collection A/C', '169119', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Collection Account', '169177', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H O Collection Account', '169198', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '169275', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '169283', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H O Collection Account', '169376', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H.O Collection Account', '169387', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H.O. Collection Account', '169392', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '169461', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H.O. Collection Account', '169465', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H.O. Collection Account', '169473', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '169476', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection Acc', '169499', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Collection Account', '169666', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H.O Collection Account', '169694', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office-Collection A/C', '169769', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H O Collection Account', '169872', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '169960', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H O Collection Acount', '169964', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H.O.Collection Account', '169966', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H.O.Collection Account', '169977', ''],
    ['COUTTS & CO Strand', '180002', ''],
    ['COUTTS & CO Coutts Targeting Clearing', '180038', ''],
    ['COUTTS & CO Coutts & Co Int Payments', '180091', ''],
    ['COUTTS & CO Securities Department', '180092', ''],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL T/A COUTTS CROWN DEPENDENCIES Rbsi T/A Coutts',
      '180300',
      'RBOSJESJXXX'
    ],
    ['COUTTS & CO 143 Cannon Street Ec4', '185001', ''],
    ['CITIBANK NA Citi Pb London Na', '185002', ''],
    ['CITIBANK NA Citi UK Consumer Bank', '185004', ''],
    ['CITIBANK NA Citibank Repair Sort Code', '185005', 'CITIGB2LCHP'],
    ['CITIBANK NA London,  Canary Wharf E14', '185008', 'CITIGB2LXXX'],
    ['CITIBANK NA Citi Pb plc UK', '185010', ''],
    ['CITIBANK NA Citi Pb Jersey Na', '185026', 'CITIJESXXXX'],
    ['COUTTS & CO London', '185050', 'COUTGB22XXX'],
    ['BARCLAYS BANK PLC 1 Churchill  Place', '200000', ''],
    ['BARCLAYS BANK PLC Automated Atm Transactions', '200006', ''],
    ['BARCLAYS BANK PLC Stationery Department', '200007', ''],
    ['BARCLAYS BANK PLC Electronic Products Dept', '200010', ''],
    ['BARCLAYS BANK PLC Regist & New Issues Dept', '200025', ''],
    ['BARCLAYS BANK UK PLC Fps  & Bacs Head Office', '200026', ''],
    ['BARCLAYS BANK PLC Travellers Cheques 0031', '200031', ''],
    ['BARCLAYS BANK PLC Travellers Cheques 0033', '200033', ''],
    ['BARCLAYS BANK PLC Loans Operations', '200034', ''],
    ['BARCLAYS BANK UK PLC Grcb Technology Suppliers', '200037', ''],
    ['BARCLAYS BANK PLC Bb plc Default For Cheque', '200043', ''],
    ['BARCLAYS BANK PLC Head Office', '200050', ''],
    ['BARCLAYS BANK PLC Global Treasury Settlement', '200052', 'BARCGB5GXXX'],
    ['BARCLAYS BANK PLC Dividend Clearance Centre', '200053', ''],
    ['BARCLAYS BANK UK PLC Gtsl Sterling', '200060', ''],
    ['BARCLAYS BANK PLC Chaps Repair Code', '200062', 'BARCGB21CHP'],
    ['BARCLAYS BANK UK PLC Chaps Repair Code', '200070', 'BUKBGB21CHP'],
    ["BARCLAYS BANK UK PLC Application Service D'very", '200073', ''],
    ['BARCLAYS BANK UK PLC Aberdeen', '200077', ''],
    ['BARCLAYS BANK PLC Data Capture Team', '200078', ''],
    ['BARCLAYS BANK PLC Escrow Corporate', '200084', ''],
    ['BARCLAYS BANK UK PLC Abergavenny', '200085', ''],
    ['BARCLAYS BANK UK PLC Prime Account Office', '200087', ''],
    ['BARCLAYS BANK UK PLC Loan Operations 9', '200091', ''],
    ['BARCLAYS BANK UK PLC Domestic Bank Ac 9', '200094', ''],
    ['BARCLAYS BANK UK PLC Pension Fund Accounts', '200098', ''],
    ['BARCLAYS BANK UK PLC Abingdon Marcham Road', '200109', ''],
    ['BARCLAYS BANK PLC Sth Oxon Abingdo 8', '200125', ''],
    ['BARCLAYS BANK PLC Sudbury Suffolk 9', '200126', ''],
    ['BARCLAYS BANK PLC Scarborough 9', '200142', ''],
    ['BARCLAYS BANK PLC SE Wales Abergav 9', '200143', ''],
    ['BARCLAYS BANK UK PLC Abingdon 2', '200148', ''],
    ['BARCLAYS BANK PLC Sth Oxon Abing 8', '200155', ''],
    ['BARCLAYS BANK UK PLC 1 Churchill Plce 9', '200158', ''],
    ['BARCLAYS BANK PLC Chiltern Amersh 9', '200172', ''],
    ['BARCLAYS BANK UK PLC Alperton', '200189', ''],
    ['BARCLAYS BANK UK PLC Altrincham', '200196', ''],
    ['BARCLAYS BANK UK PLC Buk Currency Accounting', '200198', ''],
    ['BARCLAYS BANK UK PLC Dummy (2)', '200199', ''],
    ['BARCLAYS BANK UK PLC Dummy (3)', '200203', ''],
    ['BARCLAYS BANK UK PLC Amersham on the Hill', '200206', ''],
    ['BARCLAYS BANK PLC Mid Wessex Group 9', '200212', ''],
    ['BARCLAYS BANK UK PLC Andover', '200225', ''],
    ['BARCLAYS BANK UK PLC Canary Wharf 3', '200243', ''],
    ['BARCLAYS BANK UK PLC Canary Wharf 2', '200252', ''],
    ['BARCLAYS BANK UK PLC Ascot', '200253', ''],
    ['BARCLAYS BANK UK PLC Dummy (13)', '200254', ''],
    ['BARCLAYS BANK UK PLC Dummy (14)', '200257', ''],
    ['BARCLAYS BANK UK PLC Ashford Kent', '200262', ''],
    ['BARCLAYS BANK UK PLC Andover 2', '200264', ''],
    ['BARCLAYS BANK UK PLC East Kent 3', '200275', ''],
    ['BARCLAYS BANK UK PLC Ashton under Lyne', '200277', ''],
    ['BARCLAYS BANK PLC Manch Sth Altr 9', '200279', ''],
    ['BARCLAYS BANK PLC Basildon 9', '200280', ''],
    ['BARCLAYS BANK PLC Kingsland & Holl 9', '200283', ''],
    ['BARCLAYS BANK UK PLC Int Money Orders 9', '200285', ''],
    ['BARCLAYS BANK PLC Canary Wharf 8', '200286', ''],
    ['BARCLAYS BANK PLC Ascot Group 9', '200287', ''],
    ['BARCLAYS BANK PLC Ashford Group 9', '200296', ''],
    ['BARCLAYS BANK PLC Winchester 8', '200298', ''],
    ['BARCLAYS BANK PLC Winchester Andov 9', '200308', ''],
    ['BARCLAYS BANK UK PLC Buckinghamshire 3', '200317', ''],
    ['BARCLAYS BANK UK PLC Aylesbury Market Sq', '200318', ''],
    ['BARCLAYS BANK UK PLC Aylsham and North Norfolk', '200326', ''],
    ['BARCLAYS BANK PLC Aylsham 9', '200342', ''],
    ['BARCLAYS BANK PLC Nth Oxon Banbury 9', '200350', ''],
    ['BARCLAYS BANK PLC Baker Street', '200353', ''],
    ['BARCLAYS BANK PLC East and South East Reg 1', '200362', ''],
    ['BARCLAYS BANK PLC Thetford 9', '200368', ''],
    ['BARCLAYS BANK PLC Trade Unit Finance(Pizzaro)', '200378', ''],
    ['BARCLAYS BANK UK PLC 93 Baker Street 9', '200379', ''],
    ['BARCLAYS BANK PLC Private Bk - Stanhope Gate', '200380', ''],
    ['BARCLAYS BANK UK PLC Consumer Lending Bpb', '200382', ''],
    ['BARCLAYS BANK PLC Consumer Lending Bpb C.I.', '200383', ''],
    ['BARCLAYS BANK UK PLC Banbury', '200384', ''],
    ['BARCLAYS BANK PLC Consumer Lending Bpb Iom', '200387', ''],
    ['BARCLAYS BANK PLC Pers Loans From Bcard', '200395', ''],
    ["BARCLAYS BANK UK PLC Barclaycard Centre N'pton", '200401', ''],
    ['BARCLAYS BANK PLC Company Barclaycard', '200406', ''],
    ['BARCLAYS BANK PLC Barclays Private Bnk Jersey', '200407', ''],
    ['BARCLAYS BANK PLC Barclays Tessa 1', '200408', ''],
    ['BARCLAYS BANK UK PLC Barclays Tessa 2', '200409', ''],
    ['BARCLAYS BANK PLC Barclaycard Kirkby', '200413', ''],
    ["BARCLAYS BANK UK PLC Barclaybank B'card N'pton", '200415', ''],
    ['BARCLAYS BANK PLC Barclaycard International', '200423', ''],
    ['BARCLAYS BANK PLC Barclays Direct Loans', '200424', ''],
    ['BARCLAYS BANK PLC Canary Wharf 9', '200425', ''],
    ['BARCLAYS BANK UK PLC Career Development Loans', '200432', ''],
    ['BARCLAYS BANK UK PLC Centralised Bonds', '200433', ''],
    ['BARCLAYS BANK UK PLC Centralised Bonds 2', '200434', ''],
    ['BARCLAYS BANK UK PLC Barclays Direct Mortgages 2', '200448', ''],
    ['BARCLAYS BANK UK PLC Barnsley', '200450', ''],
    ['BARCLAYS BANK UK PLC Bnrc Wythenshawe', '200454', ''],
    ['BARCLAYS BANK UK PLC Barnstaple', '200459', ''],
    ['BARCLAYS BANK UK PLC Mayfair - Stanhope Gate', '200463', ''],
    ['BARCLAYS BANK UK PLC Barclayloan Select', '200465', ''],
    ['BARCLAYS BANK PLC Gbs', '200466', ''],
    ['BARCLAYS BANK PLC North Devon Grp9', '200467', ''],
    ['BARCLAYS BANK UK PLC Barrow in Furness', '200468', ''],
    ['BARCLAYS BANK UK PLC Dudley Centre 2', '200481', ''],
    ['BARCLAYS BANK UK PLC Dudley Centre', '200484', ''],
    ['BARCLAYS BANK UK PLC Glasgow Processing Centre', '200490', ''],
    ['BARCLAYS BANK UK PLC Barclays Service Centre', '200492', ''],
    ['BARCLAYS BANK UK PLC Basildon', '200496', ''],
    ['BARCLAYS BANK PLC Barclays Milan', '200498', ''],
    ['BARCLAYS BANK UK PLC Basingstoke', '200500', ''],
    ['BARCLAYS BANK PLC W Hert St Albans 9', '200503', ''],
    ['BARCLAYS BANK UK PLC Bath Milsom Street', '200506', ''],
    ['BARCLAYS BANK PLC Ashton-Undr-Lyne 9', '200509', ''],
    ['BARCLAYS BANK UK PLC Bath Milsom Street 2', '200512', ''],
    ['BARCLAYS BANK PLC Hmrc Indirect Tax', '200517', ''],
    ['BARCLAYS BANK PLC Stockport (9)', '200525', ''],
    ['BARCLAYS BANK PLC North Hampshire 9', '200526', ''],
    ['BARCLAYS BANK PLC Bath Milsom St 9', '200538', ''],
    ['BARCLAYS BANK PLC Barrow in Furnss 9', '200542', ''],
    ['BARCLAYS BANK PLC East Kent (9)', '200556', ''],
    ['BARCLAYS BANK UK PLC Beckenham', '200557', ''],
    ['BARCLAYS BANK UK PLC Bedford 3', '200573', ''],
    ['BARCLAYS BANK UK PLC Bedford', '200574', ''],
    ['BARCLAYS BANK PLC Bedford Sq', '200575', ''],
    ['BARCLAYS BANK UK PLC Bedford 4', '200577', ''],
    ['BARCLAYS BANK PLC Midlands 1', '200585', ''],
    ['BARCLAYS BANK PLC Belfast', '200594', ''],
    ['BARCLAYS BANK PLC Beckenham  9', '200599', ''],
    ['BARCLAYS BANK UK PLC Belfast 9', '200602', ''],
    ['BARCLAYS BANK PLC Pall Mall', '200605', ''],
    ['BARCLAYS BANK UK PLC Bedford Sq Bus C 9', '200609', ''],
    ['BARCLAYS BANK PLC East and South East Reg 3', '200610', ''],
    ['BARCLAYS BANK UK PLC Belgravia 9', '200613', ''],
    ['BARCLAYS BANK PLC Aylesbury Group 9', '200615', ''],
    ['BARCLAYS BANK PLC Bedford 7', '200623', ''],
    ['BARCLAYS BANK PLC Bedford High St 9', '200624', ''],
    ['BARCLAYS BANK UK PLC East Anglia 3', '200670', ''],
    ['BARCLAYS BANK UK PLC Bexleyheath', '200672', ''],
    ['BARCLAYS BANK UK PLC Bicester', '200675', ''],
    ['BARCLAYS BANK PLC Bexleyheth Bus C 9', '200680', ''],
    ['BARCLAYS BANK PLC East Anglia 7', '200688', ''],
    ['BARCLAYS BANK PLC Nth Oxon Biceste 9', '200691', ''],
    ['BARCLAYS BANK PLC Tmo Italy', '200702', ''],
    ['BARCLAYS BANK PLC Tmo France', '200722', ''],
    ['BARCLAYS BANK PLC Supplier Finance', '200753', ''],
    ['BARCLAYS BANK PLC South West and Wales Corp 2', '200758', ''],
    ['BARCLAYS BANK PLC Tmo Portugal', '200763', ''],
    ['BARCLAYS BANK PLC Singapore Tmo', '200769', ''],
    ['BARCLAYS BANK PLC Hong Kong Tmo', '200770', ''],
    ['BARCLAYS BANK PLC Birmingham Colmore Row', '200771', ''],
    ['BARCLAYS BANK UK PLC Birmingham Colmore 2', '200774', ''],
    ['BARCLAYS BANK PLC Northern Corp 2', '200775', ''],
    ['BARCLAYS BANK UK PLC Birmingham Edgbaston', '200782', ''],
    ['BARCLAYS BANK UK PLC Birmingham Erdington', '200784', ''],
    ['BARCLAYS BANK UK PLC Birmnghm Colmore 8', '200789', ''],
    ['BARCLAYS BANK PLC Birmnghm Clmre 9', '200790', ''],
    ['BARCLAYS BANK PLC UK Trade Services Centre', '200791', ''],
    ['BARCLAYS BANK PLC Germany Tmo', '200793', ''],
    ['BARCLAYS BANK UK PLC Branch of the Future', '200794', ''],
    ['BARCLAYS BANK PLC China Tmo', '200796', ''],
    ['BARCLAYS BANK PLC USA Tmo', '200797', ''],
    ['BARCLAYS BANK PLC Birmingham Erdin 9', '200800', ''],
    ['BARCLAYS BANK PLC Barnsley 9', '200807', ''],
    ['BARCLAYS BANK PLC Birminghm Edgbsn 9', '200816', ''],
    ['BARCLAYS BANK PLC Tamworth 9', '200817', ''],
    ['BARCLAYS BANK UK PLC Birmingham Kings Heath', '200844', ''],
    ['BARCLAYS BANK PLC Solihull Bhamsth9', '200860', ''],
    ['BARCLAYS BANK UK PLC Birmingham Kings Heath 3', '200864', ''],
    ['BARCLAYS BANK PLC Solihull Bhamsth 9', '200869', ''],
    ['BARCLAYS BANK PLC Birmingham Kh 7', '200880', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 2', '200889', ''],
    ['BARCLAYS BANK UK PLC Pingit Club 2', '200890', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 13', '200891', ''],
    ['BARCLAYS BANK UK PLC Birmingham Small Heath', '200898', ''],
    ['BARCLAYS BANK UK PLC Birmingham Soho Road', '200903', ''],
    ['BARCLAYS BANK PLC Stourbridge 9', '200913', ''],
    ['BARCLAYS BANK PLC Birmnghm Soho Rd9', '200929', ''],
    ['BARCLAYS BANK PLC Birmnghm S Heath 9', '200930', ''],
    ["BARCLAYS BANK UK PLC Birmingham Dartmouth M'way", '200937', ''],
    ['BARCLAYS BANK UK PLC Bishop Auckland Jenkins Hse', '200944', ''],
    ['BARCLAYS BANK PLC Aberdeen 9', '200952', ''],
    ['BARCLAYS BANK UK PLC Blackburn', '200972', ''],
    ['BARCLAYS BANK UK PLC Blackburn 2', '200976', ''],
    ['BARCLAYS BANK PLC Blackburn 9', '200980', ''],
    ['BARCLAYS BANK PLC Blackburn 8', '200992', ''],
    ['BARCLAYS BANK UK PLC Blackpool', '201003', ''],
    ['BARCLAYS BANK PLC Blackpool 9', '201011', ''],
    ['BARCLAYS BANK UK PLC Blackwood', '201026', ''],
    ['BARCLAYS BANK PLC Rhymney & Islwyn9', '201042', ''],
    ['BARCLAYS BANK UK PLC Bedford Row', '201053', ''],
    ['BARCLAYS BANK UK PLC Bedford Row 2', '201062', ''],
    ['BARCLAYS BANK UK PLC Bolton Wellsprings', '201071', ''],
    ['BARCLAYS BANK PLC Bloomsbury Chanc9', '201079', ''],
    ['BARCLAYS BANK UK PLC Bootle Merseyside', '201084', ''],
    ['BARCLAYS BANK PLC Bolton  9', '201089', ''],
    ['BARCLAYS BANK PLC Liverpool North 9', '201092', ''],
    ['BARCLAYS BANK UK PLC Liverpool North 2', '201099', ''],
    ['BARCLAYS BANK PLC Liverpool Nor 8', '201107', ''],
    ['BARCLAYS BANK UK PLC Boston', '201113', ''],
    ['BARCLAYS BANK UK PLC Bournemouth Town', '201139', ''],
    ['BARCLAYS BANK UK PLC Bournemouth Town 2', '201143', ''],
    ['BARCLAYS BANK PLC Hmrc Direct Taxes', '201147', ''],
    ['BARCLAYS BANK UK PLC Bournemouth Town 3', '201150', ''],
    ['SECURE TRUST BANK PLC Banking Services', '201160', ''],
    ['BARCLAYS BANK PLC Boston  9', '201170', ''],
    ['BARCLAYS BANK UK PLC Bracknell', '201174', ''],
    ['BARCLAYS BANK UK PLC Bradford Market Street', '201181', ''],
    ['BARCLAYS BANK PLC Bracknell Group 9', '201182', ''],
    ['BARCLAYS BANK UK PLC Bradford Market Street 2', '201188', ''],
    ['BARCLAYS BANK PLC Bradford 9', '201199', ''],
    ['BARCLAYS BANK PLC Bournemouth Town 9', '201204', ''],
    ['BARCLAYS BANK UK PLC Risk Operations Centre', '201205', ''],
    ['BARCLAYS BANK PLC Bradford 8', '201212', ''],
    ['BARCLAYS BANK PLC Bournemouth 9', '201218', ''],
    ['BARCLAYS BANK UK PLC Brentwood', '201221', ''],
    ['BARCLAYS BANK UK PLC Bridgend', '201225', ''],
    ['BARCLAYS BANK UK PLC Bromley 3', '201226', ''],
    ['BARCLAYS BANK PLC Brentwood 9', '201239', ''],
    ['BARCLAYS BANK PLC Bromley 8', '201242', ''],
    ['BARCLAYS BANK PLC Bran Recovy Unit 9', '201254', ''],
    ['BARCLAYS BANK PLC Bridgend and Vale 9', '201258', ''],
    ['BARCLAYS BANK UK PLC Brighton North Street', '201275', ''],
    ['BARCLAYS BANK UK PLC Brighton North Street 2', '201280', ''],
    ['BARCLAYS BANK PLC South Coast Grp 9', '201283', ''],
    ['BARCLAYS BANK PLC South Coast Grp 8', '201298', ''],
    ['BARCLAYS BANK UK PLC Bristol Barrow Road', '201308', ''],
    ['BARCLAYS BANK UK PLC Bristol Clifton Queens Rd', '201334', ''],
    ['BARCLAYS BANK UK PLC South West 3', '201335', ''],
    ['BARCLAYS BANK PLC Bristol Queens Sq Rms', '201342', ''],
    ['BARCLAYS BANK UK PLC Bristol Queens Sq Rms 2', '201345', ''],
    ['BARCLAYS BANK PLC Bristol Clifton 9', '201359', ''],
    ['BARCLAYS BANK UK PLC Bristol Corn St 9', '201367', ''],
    ["BARCLAYS BANK UK PLC Barclays S'brokers Pep 4", '201372', ''],
    ['BARCLAYS BANK PLC Bristol & North Somerset 9', '201394', ''],
    ['BARCLAYS BANK UK PLC Bromley', '201433', ''],
    ['BARCLAYS BANK UK PLC South Wales 3', '201434', ''],
    ['BARCLAYS BANK PLC Bromley 9', '201458', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 3', '201513', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 14', '201517', ''],
    ['BARCLAYS BANK UK PLC Barclays Cash Management', '201523', ''],
    ['BARCLAYS BANK UK PLC Pingit Club 3', '201526', ''],
    ['BARCLAYS BANK UK PLC Burnley', '201570', ''],
    ['BARCLAYS BANK UK PLC Burton-on-Trent', '201596', ''],
    ['BARCLAYS BANK UK PLC Burton-on-Trent 2', '201599', ''],
    ['BARCLAYS BANK PLC Burton Trnt 9', '201607', ''],
    ['BARCLAYS BANK UK PLC Bury', '201608', ''],
    ['BARCLAYS BANK UK PLC Bury St Edmunds', '201612', ''],
    ['BARCLAYS BANK PLC Burnley Group  9', '201620', ''],
    ['BARCLAYS BANK PLC Bury  9', '201624', ''],
    ['BARCLAYS BANK UK PLC Business Banking Wyth', '201626', ''],
    ['BARCLAYS BANK UK PLC Business Banking Glos', '201630', ''],
    ['BARCLAYS BANK PLC Burton on Trent 9', '201638', ''],
    ['BARCLAYS BANK PLC Bury St Edmonds 9', '201646', ''],
    ['BARCLAYS BANK UK PLC Camberley', '201699', ''],
    ['BARCLAYS BANK PLC Camberley Bwater 9', '201707', ''],
    ["BARCLAYS BANK UK PLC Cambridge Bene't Street", '201719', ''],
    ['BARCLAYS BANK UK PLC Cambridge City3', '201720', ''],
    ["BARCLAYS BANK UK PLC Cambridge  Bene't Street 2", '201722', ''],
    ['BARCLAYS BANK PLC South West and Wales Corp 3', '201727', ''],
    ['BARCLAYS BANK UK PLC Cambridge Chesterton', '201735', ''],
    ['BARCLAYS BANK PLC Cambridge City 7', '201746', ''],
    ['BARCLAYS BANK PLC Camb Benet St 9', '201755', ''],
    ["BARCLAYS BANK PLC Cambridge B't St 9", '201768', ''],
    ['BARCLAYS BANK PLC Cambridge Chs Rd 9', '201776', ''],
    ['BARCLAYS BANK UK PLC Canterbury', '201792', ''],
    ['BARCLAYS BANK UK PLC Canterbury 2', '201794', ''],
    ['BARCLAYS BANK PLC East Kent Gp  9', '201800', ''],
    ['BARCLAYS BANK PLC Canterbury 9', '201810', ''],
    ['BARCLAYS BANK UK PLC Cardiff Queen St', '201815', ''],
    ['BARCLAYS BANK UK PLC Cardiff Queen St 2', '201817', ''],
    ['BARCLAYS BANK PLC Cardiff Group 9', '201823', ''],
    ['BARCLAYS BANK UK PLC Barry Broad St', '201827', ''],
    ['BARCLAYS BANK PLC Barclays Marine Finance', '201836', ''],
    ['BARCLAYS BANK PLC Consumer Lending UK Marine', '201837', ''],
    ['BARCLAYS BANK UK PLC Cardigan', '201841', ''],
    ['BARCLAYS BANK UK PLC Carlisle', '201847', ''],
    ['BARCLAYS BANK UK PLC Carmarthen', '201854', ''],
    ['BARCLAYS BANK PLC Cardiff 9', '201858', ''],
    ['BARCLAYS BANK PLC Carlisle 9', '201862', ''],
    ['BARCLAYS BANK PLC Ceredigion Group9', '201874', ''],
    ['BARCLAYS BANK PLC Cardiff Grp West9', '201876', ''],
    ['BARCLAYS BANK UK PLC North Kent 3', '201893', ''],
    ['BARCLAYS BANK PLC Carmarthen Group9', '201904', ''],
    ['BARCLAYS BANK UK PLC Barclays Direct Mortgages', '201914', ''],
    ['BARCLAYS BANK UK PLC Barclays Direct Mortgages 1', '201918', ''],
    ['BARCLAYS BANK PLC North Kent 9', '201919', ''],
    ['BARCLAYS BANK UK PLC Liverpool Contact Centre', '201924', ''],
    ['BARCLAYS BANK UK PLC Barclaycall', '201928', ''],
    ['BARCLAYS BANK UK PLC Account Servicing Kiosk', '201929', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 4', '201952', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 15', '201954', ''],
    ['BARCLAYS BANK PLC Broadgate', '201990', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 5', '201991', ''],
    ['BARCLAYS BANK UK PLC Chelmsford High St', '201995', ''],
    ['BARCLAYS BANK UK PLC Universal Bank 4', '201996', ''],
    ['BARCLAYS BANK UK PLC Chelmsford 2', '201997', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 16', '201999', ''],
    ['BARCLAYS BANK UK PLC Universal Bank 5', '202003', ''],
    ['BARCLAYS BANK UK PLC Cheapside 9', '202014', ''],
    ['BARCLAYS BANK UK PLC Cheltenham', '202015', ''],
    ['BARCLAYS BANK UK PLC Universal Banking 1', '202020', ''],
    ['BARCLAYS BANK PLC Cheltenham 9', '202023', ''],
    ['BARCLAYS BANK PLC Chelmsford 9', '202035', ''],
    ['BARCLAYS BANK UK PLC Cheshunt', '202037', ''],
    ['BARCLAYS BANK UK PLC Cheshunt 2', '202042', ''],
    ['BARCLAYS BANK PLC Chester Marks and Spencer', '202044', ''],
    ['BARCLAYS BANK PLC Lea Valley Grp 9', '202045', ''],
    ['BARCLAYS BANK UK PLC Chester', '202046', ''],
    ['BARCLAYS BANK PLC Hmrc Nic', '202048', ''],
    ['BARCLAYS BANK UK PLC Chesterfield', '202050', ''],
    ['BARCLAYS BANK PLC Chester 9', '202053', ''],
    ['BARCLAYS BANK PLC Lea Valley Grp 8', '202059', ''],
    ['BARCLAYS BANK UK PLC Chichester', '202062', ''],
    ['BARCLAYS BANK UK PLC Chichester 2', '202065', ''],
    ['BARCLAYS BANK PLC Nth Derbyshire 9', '202068', ''],
    ['BARCLAYS BANK UK PLC Chester M and S 9', '202069', ''],
    ['BARCLAYS BANK PLC West Sussex Grp 9', '202070', ''],
    ['BARCLAYS BANK UK PLC Chichester 3', '202071', ''],
    ['BARCLAYS BANK PLC Chichester 8', '202073', ''],
    ['BARCLAYS BANK PLC Chichester 7', '202089', ''],
    ['BARCLAYS BANK UK PLC Clacton on Sea', '202173', ''],
    ['BARCLAYS BANK UK PLC Clapham 3', '202177', ''],
    ['BARCLAYS BANK UK PLC Clapham Common', '202178', ''],
    ['BARCLAYS BANK UK PLC Clapham Common 2', '202180', ''],
    ['BARCLAYS BANK PLC Clapham 7', '202185', ''],
    ['BARCLAYS BANK UK PLC Wos Operations Centre', '202186', ''],
    ['BARCLAYS BANK PLC Clapham 8', '202198', ''],
    ['BARCLAYS BANK PLC Clacton on Sea 9', '202199', ''],
    ['BARCLAYS BANK PLC Clapham Common 9', '202210', ''],
    ['BARCLAYS BANK UK PLC Clearing Diffs Glos', '202223', ''],
    ['BARCLAYS BANK UK PLC E Savings 2', '202225', ''],
    ['BARCLAYS BANK UK PLC Colchester', '202267', ''],
    ['BARCLAYS BANK UK PLC East Essex 3', '202268', ''],
    ['BARCLAYS BANK UK PLC Colchester 2', '202269', ''],
    ['BARCLAYS BANK UK PLC Eastbourne 3', '202273', ''],
    ['BARCLAYS BANK PLC Colchester 9', '202275', ''],
    ['BARCLAYS BANK PLC Colchester 8', '202277', ''],
    ['BARCLAYS BANK PLC East Essex 7', '202284', ''],
    ['BARCLAYS BANK UK PLC Ranch  Mls', '202299', ''],
    ['BARCLAYS BANK UK PLC South Essex 3', '202330', ''],
    ['BARCLAYS BANK PLC South Essex 7', '202348', ''],
    ['BARCLAYS BANK UK PLC Coventry', '202355', ''],
    ['BARCLAYS BANK UK PLC Coventry 2', '202360', ''],
    ['BARCLAYS BANK PLC Coventry 9', '202371', ''],
    ['BARCLAYS BANK PLC South East Sus 7', '202372', ''],
    ['BARCLAYS BANK PLC Coventry 8', '202378', ''],
    ['BARCLAYS BANK UK PLC Cramlington', '202381', ''],
    ['BARCLAYS BANK UK PLC Crawley', '202397', ''],
    ['BARCLAYS BANK UK PLC Crawley 3', '202398', ''],
    ['BARCLAYS BANK UK PLC Crawley 2', '202400', ''],
    ['BARCLAYS BANK PLC The Gatwick Grp 9', '202405', ''],
    ['BARCLAYS BANK PLC Crawley 7', '202406', ''],
    ['BARCLAYS BANK PLC SE Northumberlnd 9', '202407', ''],
    ['BARCLAYS BANK UK PLC Crewe Market Street', '202409', ''],
    ['BARCLAYS BANK PLC Crawley 8', '202418', ''],
    ['BARCLAYS BANK UK PLC Crewe Market Street 2', '202423', ''],
    ['BARCLAYS BANK UK PLC Mid/Sth Cheshire 3', '202439', ''],
    ['BARCLAYS BANK PLC Mid/Sth Cheshire 9', '202441', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 4', '202456', ''],
    ['BARCLAYS BANK UK PLC Croydon', '202461', ''],
    ['BARCLAYS BANK UK PLC Croydon 2', '202464', ''],
    ['BARCLAYS BANK UK PLC Kingston 3', '202476', ''],
    ['BARCLAYS BANK UK PLC Kingston 4', '202478', ''],
    ['BARCLAYS BANK PLC Cyprus', '202480', ''],
    ['BARCLAYS BANK PLC Consumer Lending Ib Cyprus', '202481', ''],
    ['BARCLAYS BANK PLC Kingston 6', '202486', ''],
    ['BARCLAYS BANK PLC Croydon 9', '202487', ''],
    ['BARCLAYS BANK PLC Croydon 8', '202498', ''],
    ['BARCLAYS BANK UK PLC Romford 3', '202519', ''],
    ['BARCLAYS BANK UK PLC Romford 4', '202521', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 7', '202524', ''],
    ['BARCLAYS BANK UK PLC Direct Banking', '202525', ''],
    ['BARCLAYS BANK UK PLC Online Ao3', '202528', ''],
    ['BARCLAYS BANK UK PLC Darlington', '202529', ''],
    ['BARCLAYS BANK UK PLC Online Ao 5', '202530', ''],
    ['BARCLAYS BANK UK PLC Darlington 2', '202531', ''],
    ['BARCLAYS BANK PLC Romford 7', '202535', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 2', '202536', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 8', '202538', ''],
    ['BARCLAYS BANK UK PLC Dartford', '202542', ''],
    ['BARCLAYS BANK UK PLC Dartford 3', '202543', ''],
    ['BARCLAYS BANK UK PLC Data Protection Team', '202546', ''],
    ['BARCLAYS BANK PLC Darlington 8', '202549', ''],
    ['BARCLAYS BANK PLC Darlington 9', '202552', ''],
    ['BARCLAYS BANK PLC Dartford Grp 9', '202559', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 6', '202560', ''],
    ['BARCLAYS BANK PLC Dartford 7', '202568', ''],
    ['BARCLAYS BANK UK PLC Flint', '202569', ''],
    ['BARCLAYS BANK UK PLC Rhyl', '202576', ''],
    ['BARCLAYS BANK PLC NE Wales Group 9', '202577', ''],
    ['BARCLAYS BANK PLC Rhyl 9', '202584', ''],
    ['BARCLAYS BANK UK PLC Derby Sir Frank Whittle', '202585', ''],
    ['BARCLAYS BANK UK PLC Derby 3', '202586', ''],
    ['BARCLAYS BANK PLC Derby 9', '202593', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 3', '202595', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 9', '202596', ''],
    ['BARCLAYS BANK UK PLC Dewsbury', '202608', ''],
    ['BARCLAYS BANK PLC Dewsbury 9', '202616', ''],
    ['BARCLAYS BANK UK PLC Didsbury', '202620', ''],
    ['BARCLAYS BANK UK PLC Online Ao 1', '202621', ''],
    ['BARCLAYS BANK UK PLC Online Ao 6', '202622', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 4', '202623', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 10', '202624', ''],
    ['BARCLAYS BANK UK PLC Diss', '202634', ''],
    ['BARCLAYS BANK PLC Sth Didsbury 9', '202638', ''],
    ['BARCLAYS BANK PLC Diss 9', '202642', ''],
    ['BARCLAYS BANK UK PLC Dockland', '202646', ''],
    ['BARCLAYS BANK PLC Hmrc Child Benft', '202648', ''],
    ['BARCLAYS BANK PLC Dockland 9', '202653', ''],
    ['BARCLAYS BANK UK PLC Doncaster', '202655', ''],
    ['BARCLAYS BANK UK PLC Dorchester', '202662', ''],
    ['BARCLAYS BANK PLC Doncaster 9', '202663', ''],
    ['BARCLAYS BANK UK PLC The Dormancy Admin Centre', '202665', ''],
    ['BARCLAYS BANK PLC Consumer Lending Iom', '202670', ''],
    ['BARCLAYS BANK PLC Barclays Private Bkg Iom', '202671', ''],
    ['BARCLAYS BANK PLC Consumer Lending Bpci Iom', '202672', ''],
    ['BARCLAYS BANK PLC Bpci Un Sanctions', '202673', ''],
    ['BARCLAYS BANK PLC Iom Intl Banking Centre', '202674', ''],
    ['BARCLAYS BANK PLC Iom Intl Pers Banking', '202675', ''],
    ['BARCLAYS BANK PLC Barclays Bank plc Iom', '202676', ''],
    ['BARCLAYS BANK PLC Iom Intl Pers Banking A-F', '202677', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 5', '202678', ''],
    ['BARCLAYS BANK PLC Iom Intl Pers Banking   G-M', '202680', ''],
    ['BARCLAYS BANK PLC Iom Intl Banking Centre (K)', '202681', ''],
    ['BARCLAYS BANK UK PLC Online Ao2', '202682', ''],
    ['BARCLAYS BANK PLC Woolwich Iom', '202685', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 11', '202686', ''],
    ['BARCLAYS BANK PLC Iom Intl Pers Banking N-Z', '202688', ''],
    ['BARCLAYS BANK UK PLC Online Ao 7', '202689', ''],
    ['BARCLAYS BANK UK PLC Doxford A Call Centre', '202693', ''],
    ['BARCLAYS BANK PLC Dublin', '202699', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 6', '202705', ''],
    ['BARCLAYS BANK UK PLC Direct Banking 12', '202706', ''],
    ['BARCLAYS BANK PLC West Dorset 9', '202712', ''],
    ['BARCLAYS BANK UK PLC Dudley', '202717', ''],
    ['BARCLAYS BANK UK PLC Dudley 2', '202720', ''],
    ['BARCLAYS BANK UK PLC Online Ao4', '202726', ''],
    ['BARCLAYS BANK UK PLC Online Ao 8', '202728', ''],
    ['BARCLAYS BANK PLC Dudley 9', '202733', ''],
    ['BARCLAYS BANK PLC Dudley 8', '202738', ''],
    ['BARCLAYS BANK UK PLC Durham', '202741', ''],
    ['BARCLAYS BANK UK PLC Durham 2', '202743', ''],
    ['BARCLAYS BANK UK PLC Ealing', '202748', ''],
    ['BARCLAYS BANK UK PLC Ealing Broadway 3', '202749', ''],
    ['BARCLAYS BANK UK PLC Durham 3', '202752', ''],
    ['BARCLAYS BANK PLC Ealing 9', '202755', ''],
    ['BARCLAYS BANK PLC Ealing 7', '202756', ''],
    ['BARCLAYS BANK PLC Durham 7', '202760', ''],
    ['BARCLAYS BANK PLC Durham 9', '202766', ''],
    ['BARCLAYS BANK PLC Durham 8', '202768', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 7', '202787', ''],
    ['BARCLAYS BANK UK PLC Eastbourne', '202791', ''],
    ['BARCLAYS BANK UK PLC Sussex Coast 3', '202792', ''],
    ['BARCLAYS BANK UK PLC Eastbourne 2', '202794', ''],
    ['BARCLAYS BANK PLC South East Sussx 9', '202817', ''],
    ['BARCLAYS BANK UK PLC East Dereham', '202820', ''],
    ['BARCLAYS BANK PLC South East Sus 8', '202828', ''],
    ['BARCLAYS BANK PLC East Dereham  9', '202838', ''],
    ['BARCLAYS BANK UK PLC Edinburgh', '202923', ''],
    ['BARCLAYS BANK UK PLC Edinburgh 3', '202924', ''],
    ['BARCLAYS BANK UK PLC Edgware', '202937', ''],
    ['BARCLAYS BANK UK PLC Edgware 2', '202941', ''],
    ['BARCLAYS BANK UK PLC Edgware 3', '202947', ''],
    ['BARCLAYS BANK PLC East of Scotland 9', '202949', ''],
    ['BARCLAYS BANK UK PLC Ellesmere Port', '202950', ''],
    ['BARCLAYS BANK PLC Edgware 7', '202954', ''],
    ['BARCLAYS BANK PLC Edgware Group 9', '202960', ''],
    ['BARCLAYS BANK UK PLC South London 3', '202963', ''],
    ['BARCLAYS BANK UK PLC Ely', '202968', ''],
    ['BARCLAYS BANK UK PLC South London 4', '202971', ''],
    ['BARCLAYS BANK PLC Edgware 8', '202974', ''],
    ['BARCLAYS BANK PLC South Wirral Grp 9', '202976', ''],
    ['BARCLAYS BANK UK PLC Enfield', '202977', ''],
    ['BARCLAYS BANK UK PLC Enfield Chase 3', '202979', ''],
    ['BARCLAYS BANK UK PLC Enfield 2', '202981', ''],
    ['BARCLAYS BANK PLC Ely 9', '202984', ''],
    ['BARCLAYS BANK PLC Enfield 9', '202985', ''],
    ['BARCLAYS BANK UK PLC Epping', '202986', ''],
    ['BARCLAYS BANK PLC Enfield Chase 7', '202987', ''],
    ['BARCLAYS BANK PLC South London 7', '202989', ''],
    ['BARCLAYS BANK UK PLC Epsom', '202990', ''],
    ['BARCLAYS BANK PLC Epping 9', '202994', ''],
    ['BARCLAYS BANK PLC South London 6', '202997', ''],
    ['BARCLAYS BANK UK PLC E Savings 4', '203002', ''],
    ['BARCLAYS BANK UK PLC E Savings 5', '203004', ''],
    ['BARCLAYS BANK PLC Epsom Group 9', '203006', ''],
    ['BARCLAYS BANK UK PLC Barclays Assist Loan', '203007', ''],
    ['BARCLAYS BANK UK PLC E Savings 7', '203009', ''],
    ['BARCLAYS BANK UK PLC E Savings 8', '203012', ''],
    ['BARCLAYS BANK UK PLC E Savings 9', '203013', ''],
    ['BARCLAYS BANK UK PLC E Savings 10', '203014', ''],
    ['BARCLAYS BANK UK PLC E Savings 11', '203017', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 12', '203018', ''],
    ['BARCLAYS BANK PLC City Intl Ops Payments', '203019', ''],
    ['BARCLAYS BANK PLC Enfield 8', '203021', ''],
    ['BARCLAYS BANK UK PLC Pingit Club 1', '203031', ''],
    ['BARCLAYS BANK UK PLC Eu Residents', '203037', ''],
    ['BARCLAYS BANK UK PLC Exeter', '203047', ''],
    ['BARCLAYS BANK UK PLC Dudley Centre 3', '203048', ''],
    ['BARCLAYS BANK UK PLC Dudley Centre 4', '203050', ''],
    ['BARCLAYS BANK UK PLC Exeter 2', '203051', ''],
    ['BARCLAYS BANK PLC Exeter Group 9', '203054', ''],
    ['BARCLAYS BANK PLC Exeter 8', '203069', ''],
    ['BARCLAYS BANK UK PLC Fakenham', '203081', ''],
    ['BARCLAYS BANK UK PLC Fareham', '203089', ''],
    ['BARCLAYS BANK UK PLC Solent 3', '203090', ''],
    ['BARCLAYS BANK PLC Fakenham 9', '203099', ''],
    ['BARCLAYS BANK PLC Portsdown Grp 9', '203105', ''],
    ['BARCLAYS BANK UK PLC Farnham', '203106', ''],
    ['BARCLAYS BANK PLC Farnham Peterfld 9', '203114', ''],
    ['BARCLAYS BANK UK PLC Fenchurch St', '203152', ''],
    ['BARCLAYS BANK PLC 114 Fenchurch St 9', '203160', ''],
    ['BARCLAYS BANK UK PLC Moorgate', '203200', ''],
    ['BARCLAYS BANK UK PLC Moorgate 2', '203206', ''],
    ['BARCLAYS BANK PLC Fleet Street', '203229', ''],
    ['BARCLAYS BANK PLC Moorgate 8', '203230', ''],
    ['BARCLAYS BANK UK PLC Strand Crp Fleet 9', '203237', ''],
    ['BARCLAYS BANK PLC Moorgate Group 9', '203242', ''],
    ['BARCLAYS BANK PLC Northern Corp 1', '203248', ''],
    ['BARCLAYS BANK PLC Correspondent Banking', '203253', 'BARCGB22XXX'],
    ['BARCLAYS BANK UK PLC Correspondent Banking', '203287', 'BUKBGB22XXX'],
    ['BARCLAYS BANK PLC Correspondant Bk 8', '203303', ''],
    ['BARCLAYS BANK UK PLC Garforth', '203342', ''],
    ['BARCLAYS BANK UK PLC Gateshead High Street', '203351', ''],
    ['BARCLAYS BANK UK PLC Gateshead High Street 2', '203353', ''],
    ['BARCLAYS BANK PLC Garforth 9', '203359', ''],
    ['BARCLAYS BANK PLC Glasgow', '203370', ''],
    ['BARCLAYS BANK UK PLC Gloucester', '203383', ''],
    ['BARCLAYS BANK PLC Gateshead 9', '203385', ''],
    ['BARCLAYS BANK PLC Gloucester 9', '203391', ''],
    ['BARCLAYS BANK UK PLC Gloucester 2', '203394', ''],
    ['BARCLAYS BANK UK PLC Glasgow 9', '203396', ''],
    ['BARCLAYS BANK UK PLC Grantham', '203460', ''],
    ['BARCLAYS BANK PLC Gravesend Reg Per Lending', '203468', ''],
    ['BARCLAYS BANK UK PLC Thurrock', '203469', ''],
    ['BARCLAYS BANK PLC Grantham 9', '203486', ''],
    ['BARCLAYS BANK PLC Grays 9', '203501', ''],
    ['BARCLAYS BANK PLC Guernsey Intl Banking', '203522', ''],
    ['BARCLAYS BANK PLC Consumer Lending Guernsey', '203523', ''],
    ['BARCLAYS BANK PLC Consumer Lending Bpci Gsy', '203525', ''],
    ['BARCLAYS BANK PLC Woolwich Guernsey', '203526', ''],
    ['BARCLAYS BANK UK PLC Grimsby Victoria St', '203527', ''],
    ['BARCLAYS BANK PLC Guernsey Intl Banking', '203532', ''],
    ['BARCLAYS BANK UK PLC Guildford Friary', '203535', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 4', '203542', ''],
    ['BARCLAYS BANK PLC Grimsby 9', '203543', ''],
    ['BARCLAYS BANK UK PLC Bangor', '203547', ''],
    ['BARCLAYS BANK PLC West Surrey 9', '203550', ''],
    ['BARCLAYS BANK PLC Gwynedd Ynys Mon 9', '203570', ''],
    ['BARCLAYS BANK PLC Halifax (Re Halifax 203581)', '203581', ''],
    ['BARCLAYS BANK UK PLC Halifax', '203584', ''],
    ['BARCLAYS BANK UK PLC Hammersmith', '203590', ''],
    ['BARCLAYS BANK PLC Halifax 9', '203592', ''],
    ['BARCLAYS BANK UK PLC Hammersmith 2', '203593', ''],
    ['BARCLAYS BANK UK PLC Halifax - Hbs 9', '203599', ''],
    ['BARCLAYS BANK PLC Hammersmith 8', '203601', ''],
    ['BARCLAYS BANK PLC Hammersmith 9', '203608', ''],
    ['BARCLAYS BANK UK PLC Hampstead', '203616', ''],
    ['BARCLAYS BANK PLC Hampstd Highgate 9', '203632', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 8', '203639', ''],
    ['BARCLAYS BANK UK PLC Hanley', '203643', ''],
    ['BARCLAYS BANK UK PLC Hanley 3', '203644', ''],
    ['BARCLAYS BANK PLC Hanover Square', '203647', ''],
    ['BARCLAYS BANK PLC Staffs Hanley 9', '203650', ''],
    ['BARCLAYS BANK UK PLC Hanley 2', '203652', ''],
    ['BARCLAYS BANK PLC Midlands 3', '203656', ''],
    ['BARCLAYS BANK UK PLC Hanley 4', '203662', ''],
    ['BARCLAYS BANK UK PLC Hanover Square 9', '203688', ''],
    ['BARCLAYS BANK PLC Hanley 6', '203696', ''],
    ['BARCLAYS BANK UK PLC Harlow Terminus Street', '203698', ''],
    ['BARCLAYS BANK PLC Harlow 9', '203706', ''],
    ['BARCLAYS BANK UK PLC Harrogate', '203713', ''],
    ['BARCLAYS BANK UK PLC Harrow 3', '203715', ''],
    ['BARCLAYS BANK UK PLC Harrow', '203716', ''],
    ['BARCLAYS BANK UK PLC Harrow 4', '203719', ''],
    ['BARCLAYS BANK UK PLC Harrow 2', '203721', ''],
    ['BARCLAYS BANK PLC Harrow 6', '203727', ''],
    ['BARCLAYS BANK PLC Harrow 9', '203732', ''],
    ['BARCLAYS BANK PLC Harrogate 9', '203739', ''],
    ['BARCLAYS BANK PLC Harrow 8', '203747', ''],
    ['BARCLAYS BANK PLC Commercial Bank Basingstoke', '203763', ''],
    ['BARCLAYS BANK PLC Hatton Garden', '203775', ''],
    ['BARCLAYS BANK UK PLC Haverfordwest', '203782', ''],
    ['BARCLAYS BANK UK PLC Strand Corp Hatt 9', '203783', ''],
    ['BARCLAYS BANK PLC Pembrokeshire 9', '203790', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 5', '203877', ''],
    ['BARCLAYS BANK PLC Heathrow (British Airways)', '203881', ''],
    ['BARCLAYS BANK UK PLC Heathrow Airport', '203883', ''],
    ['BARCLAYS BANK UK PLC Heathrow Hattn C 9', '203899', ''],
    ['BARCLAYS BANK UK PLC Hemel Hempstead', '203907', ''],
    ['BARCLAYS BANK PLC Heathrow Airport 9', '203909', ''],
    ['BARCLAYS BANK PLC W Herts Dacorum 9', '203915', ''],
    ['BARCLAYS BANK UK PLC Henley-on-Thames', '203953', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 9', '203961', ''],
    ['BARCLAYS BANK UK PLC Hereford', '203964', ''],
    ['BARCLAYS BANK PLC Herefordshire 9', '203972', ''],
    ['BARCLAYS BANK PLC Midthames Henley 9', '203979', ''],
    ['BARCLAYS BANK UK PLC Hexham', '204009', ''],
    ['BARCLAYS BANK PLC Imagepay Operations Centre', '204018', ''],
    ['BARCLAYS BANK UK PLC Consumer Lending UK Retail', '204030', ''],
    ['BARCLAYS BANK PLC Hexham 9', '204041', ''],
    ['BARCLAYS BANK UK PLC High Wycombe', '204071', ''],
    ['BARCLAYS BANK UK PLC High Wycombe 3', '204073', ''],
    ['BARCLAYS BANK PLC S Chilt High Wyc 9', '204089', ''],
    ['BARCLAYS BANK UK PLC Hitchin', '204112', ''],
    ['BARCLAYS BANK UK PLC North Herts Grp 2', '204115', ''],
    ['BARCLAYS BANK UK PLC North Herts Grp 3', '204120', ''],
    ['BARCLAYS BANK PLC North Hrts Grp 8', '204131', ''],
    ['BARCLAYS BANK PLC North Herts Grp 9', '204138', ''],
    ['BARCLAYS BANK UK PLC Holborn', '204141', ''],
    ['BARCLAYS BANK UK PLC Holborn 2', '204150', ''],
    ['BARCLAYS BANK PLC North Hrts Grp 7', '204153', ''],
    ['BARCLAYS BANK PLC Chancery Gosling 9', '204158', ''],
    ['BARCLAYS BANK PLC Chancery Gling 8', '204168', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 10', '204170', ''],
    ['BARCLAYS BANK UK PLC Horsham', '204258', ''],
    ['BARCLAYS BANK PLC Horsham 9', '204266', ''],
    ['BARCLAYS BANK UK PLC Hounslow', '204273', ''],
    ['BARCLAYS BANK UK PLC Hounslow 2', '204276', ''],
    ['BARCLAYS BANK PLC Hounslow Southal 9', '204281', ''],
    ['BARCLAYS BANK PLC Hounslow 8', '204284', ''],
    ['BARCLAYS BANK UK PLC Huddersfield', '204304', ''],
    ['BARCLAYS BANK UK PLC Cl Business Banking', '204308', ''],
    ['BARCLAYS BANK PLC Huddersfield 9', '204312', ''],
    ['BARCLAYS BANK UK PLC Hull', '204347', ''],
    ['BARCLAYS BANK UK PLC Hull 2', '204349', ''],
    ['BARCLAYS BANK PLC Hull 8', '204356', ''],
    ['BARCLAYS BANK UK PLC Huntingdon', '204363', ''],
    ['BARCLAYS BANK UK PLC Huntingdon Town 3', '204364', ''],
    ['BARCLAYS BANK PLC Huntingdon 9', '204371', ''],
    ['BARCLAYS BANK PLC London Corp 3', '204374', ''],
    ['BARCLAYS BANK PLC Hull 9', '204388', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 24', '204401', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 25', '204402', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 26', '204403', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 27', '204405', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 28', '204406', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 29', '204407', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 86', '204409', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 87', '204410', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 88', '204411', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 30', '204412', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 89', '204414', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 31', '204416', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 32', '204417', ''],
    ['BARCLAYS BANK UK PLC Cash Isa 8', '204419', ''],
    ['BARCLAYS BANK UK PLC Cash Isa 7', '204420', ''],
    ['BARCLAYS BANK UK PLC Hainault', '204422', ''],
    ['BARCLAYS BANK UK PLC Cash Isa 6', '204423', ''],
    ['BARCLAYS BANK UK PLC Cash Isa 5', '204424', ''],
    ['BARCLAYS BANK UK PLC Cash Isa 4', '204426', ''],
    ['BARCLAYS BANK UK PLC Cash Isa 3', '204427', ''],
    ['BARCLAYS BANK UK PLC Cash Isa 2', '204428', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 18', '204430', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 19', '204431', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 20', '204433', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 21', '204434', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 22', '204437', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 23', '204439', ''],
    ['BARCLAYS BANK PLC Interpayment Services', '204440', ''],
    ['BARCLAYS BANK UK PLC Cash Isa 1', '204441', ''],
    ['BARCLAYS BANK PLC Transpay Intl Drafts', '204444', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 33', '204447', ''],
    ['BARCLAYS BANK UK PLC Fraud Prevention Grp Audit', '204449', ''],
    ['BARCLAYS BANK UK PLC Ilford 2', '204450', ''],
    ['BARCLAYS BANK UK PLC Ipswich', '204451', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 6', '204452', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 7', '204455', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 50', '204458', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 51', '204459', ''],
    ['BARCLAYS BANK UK PLC Int Money Orders 9', '204460', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 8', '204461', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 9', '204463', ''],
    ['BARCLAYS BANK PLC Insp Dpt Fraud 9', '204464', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 10', '204466', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 52', '204471', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account', '204475', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 90', '204476', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 11', '204479', ''],
    ['BARCLAYS BANK PLC Knightsbridge Intl', '204480', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 12', '204481', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 13', '204483', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 3', '204484', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 14', '204485', ''],
    ['BARCLAYS BANK UK PLC Camden', '204486', ''],
    ['BARCLAYS BANK UK PLC Isa 53 (Three)', '204487', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 15', '204488', ''],
    ['BARCLAYS BANK UK PLC Isa 54 (Three)', '204489', ''],
    ['BARCLAYS BANK PLC Jersey Intl Pers Banking', '204490', ''],
    ['BARCLAYS BANK UK PLC Islington 2', '204491', ''],
    ['BARCLAYS BANK PLC Jersey Int Per Banking A-J', '204493', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 39', '204497', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 40', '204499', ''],
    ['BARCLAYS BANK PLC Jersey Intl Banking Centre', '204505', ''],
    ['BARCLAYS BANK PLC Jersey Int Per Banking K-Z', '204507', ''],
    ['BARCLAYS BANK PLC Jersey', '204509', ''],
    ['BARCLAYS BANK PLC Consumer Lending Bpci Gib', '204513', ''],
    ['BARCLAYS BANK UK PLC Keighley', '204514', ''],
    ['BARCLAYS BANK PLC Gibraltar', '204515', ''],
    ['BARCLAYS BANK PLC Consumer Lending Jersey', '204516', ''],
    ['BARCLAYS BANK PLC Consumer Lending Bpci Jsy', '204517', ''],
    ['BARCLAYS BANK UK PLC Kelso and Borders', '204518', ''],
    ['BARCLAYS BANK PLC Gibraltar', '204521', ''],
    ['BARCLAYS BANK PLC Woolwich Jersey', '204523', ''],
    ['BARCLAYS BANK PLC Consumer Lending Ib Gib', '204524', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 41', '204527', ''],
    ['BARCLAYS BANK UK PLC Kendal', '204528', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 42', '204529', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 43', '204531', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 34', '204532', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 35', '204533', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 36', '204535', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 37', '204537', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 38', '204541', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 16', '204542', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 44', '204543', ''],
    ['BARCLAYS BANK UK PLC Business Direct', '204545', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 45', '204547', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 91', '204549', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 46', '204551', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 47', '204552', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 48', '204554', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 49', '204557', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 50', '204559', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 51', '204564', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 52', '204567', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 53', '204571', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 57', '204572', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 58', '204573', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 59', '204575', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 60', '204576', ''],
    ['BARCLAYS BANK UK PLC Kettering', '204577', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 62', '204578', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 54', '204579', ''],
    ['BARCLAYS BANK UK PLC Kettering 2', '204581', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 55', '204583', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 56', '204584', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 61', '204585', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 63', '204586', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 92', '204587', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 64', '204588', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 65', '204590', ''],
    ['BARCLAYS BANK PLC Islington 9', '204593', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 72', '204594', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 73', '204596', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 74', '204598', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 75', '204599', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 76', '204601', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 77', '204603', ''],
    ['BARCLAYS BANK UK PLC Sanction Ac Unit 9', '204605', ''],
    ['BARCLAYS BANK UK PLC Kidderminster', '204606', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 66', '204607', ''],
    ['BARCLAYS BANK PLC Kelso 9', '204609', ''],
    ['BARCLAYS BANK PLC Ilford 9', '204612', ''],
    ['BARCLAYS BANK PLC Kidderminster 9', '204614', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 67', '204615', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 93', '204617', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 68', '204619', ''],
    ['BARCLAYS BANK PLC Keighley 9', '204621', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 69', '204622', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 94', '204625', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 70', '204628', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 95', '204629', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 85', '204631', ''],
    ['BARCLAYS BANK UK PLC Kilmarnock Cash Centre', '204632', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 78', '204637', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 96', '204639', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 80', '204641', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 79', '204643', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 97', '204644', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 81', '204649', ''],
    ['BARCLAYS BANK PLC East Northants 9', '204650', ''],
    ['BARCLAYS BANK PLC Kendal Cumbria 9', '204651', ''],
    ['BARCLAYS BANK PLC Kettering 8', '204656', ''],
    ['BARCLAYS BANK UK PLC Kingsland', '204657', ''],
    ['BARCLAYS BANK UK PLC Kingsland 2', '204660', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 82', '204663', ''],
    ['BARCLAYS BANK UK PLC Kings Lynn', '204665', ''],
    ['BARCLAYS BANK PLC Ipswich 9', '204667', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 83', '204672', ''],
    ['BARCLAYS BANK UK PLC Kingston', '204673', ''],
    ['BARCLAYS BANK UK PLC Kingston 2', '204676', ''],
    ['BARCLAYS BANK PLC Islington 8', '204681', ''],
    ['BARCLAYS BANK UK PLC Barclays Isa 84', '204683', ''],
    ['BARCLAYS BANK PLC Holloway & King 8', '204686', ''],
    ['BARCLAYS BANK PLC Kingston 8', '204692', ''],
    ['BARCLAYS BANK PLC Bath 9', '204706', ''],
    ['BARCLAYS BANK PLC Kings Lynn 9', '204715', ''],
    ['BARCLAYS BANK PLC Kendal Cumbria 9', '204718', ''],
    ['BARCLAYS BANK UK PLC Business Direct 2', '204720', ''],
    ['BARCLAYS BANK PLC Kingston 9', '204723', ''],
    ['BARCLAYS BANK UK PLC Notting Hill Gate', '204734', ''],
    ['BARCLAYS BANK PLC Intermediaries London', '204735', ''],
    ['BARCLAYS BANK UK PLC Notting Hill Gate 2', '204739', ''],
    ['BARCLAYS BANK UK PLC International Banking 1', '204742', ''],
    ['BARCLAYS BANK UK PLC International Banking 2', '204744', ''],
    ['BARCLAYS BANK UK PLC Consumer Lending Wim London', '204746', ''],
    ['BARCLAYS BANK UK PLC Mayfair - 1 Stanhope Gate', '204747', ''],
    ['BARCLAYS BANK PLC Kensington Chels 8', '204754', ''],
    ['BARCLAYS BANK UK PLC Lancaster', '204761', ''],
    ['BARCLAYS BANK UK PLC Cyprus London', '204765', ''],
    ['BARCLAYS BANK PLC Kensington Chels 9', '204767', ''],
    ['BARCLAYS BANK UK PLC Knightsbridge Int 9', '204768', ''],
    ['BARCLAYS BANK PLC Kbridge Hnw Open 9', '204770', ''],
    ['BARCLAYS BANK PLC Knightbr Nor A-L 9', '204783', ''],
    ['BARCLAYS BANK PLC Knightbr Nor M-Z 9', '204785', ''],
    ['BARCLAYS BANK PLC Lancaster 9', '204787', ''],
    ['BARCLAYS BANK UK PLC Leamington Spa', '204808', ''],
    ['BARCLAYS BANK UK PLC Barclays Pingit 11', '204810', ''],
    ['BARCLAYS BANK PLC Leamington Spa 9', '204816', ''],
    ['BARCLAYS BANK PLC Private Bk -1 Stanhope Gate', '204820', ''],
    ['BARCLAYS BANK UK PLC Leeds 2', '204842', ''],
    ['BARCLAYS BANK PLC Leeds', '204846', ''],
    ['BARCLAYS BANK PLC Leeds No:8', '204867', ''],
    ['BARCLAYS BANK UK PLC Leeds Cash Centre', '204869', ''],
    ['BARCLAYS BANK UK PLC Leek', '204870', ''],
    ['BARCLAYS BANK PLC Staffs Leek 9', '204888', ''],
    ['BARCLAYS BANK UK PLC Leeds 9', '204895', ''],
    ['BARCLAYS BANK UK PLC Cl Corporate Banking', '204902', ''],
    ['BARCLAYS BANK UK PLC North Midlands 3', '204907', ''],
    ['BARCLAYS BANK UK PLC Leicester Narborough Road', '204908', ''],
    ['BARCLAYS BANK UK PLC Leicester Town Hall Square', '204911', ''],
    ['BARCLAYS BANK PLC South Leicester 9', '204916', ''],
    ['BARCLAYS BANK UK PLC Leicester Town Hall Sq 2', '204917', ''],
    ['BARCLAYS BANK PLC North Midlands 7', '204923', ''],
    ['BARCLAYS BANK PLC Leicester Cent 8', '204925', ''],
    ['BARCLAYS BANK UK PLC Leigh-on-Sea', '204929', ''],
    ['BARCLAYS BANK PLC Leicester Centra 9', '204937', ''],
    ['BARCLAYS BANK PLC Leigh-on-Sea 9', '204945', ''],
    ['BARCLAYS BANK UK PLC Lewes', '204976', ''],
    ['BARCLAYS BANK UK PLC Lewes 2', '204980', ''],
    ['BARCLAYS BANK UK PLC Lewisham', '204981', ''],
    ['BARCLAYS BANK PLC Lewes Group 9', '204984', ''],
    ['BARCLAYS BANK UK PLC Lewisham 2', '204986', ''],
    ['BARCLAYS BANK PLC Lewes 8', '204998', ''],
    ['BARCLAYS BANK PLC Lewisham 9', '204999', ''],
    ['BARCLAYS BANK PLC Lewisham 8', '205009', ''],
    ['BARCLAYS BANK UK PLC Lincoln', '205021', ''],
    ['BARCLAYS BANK UK PLC Lincoln 3', '205022', ''],
    ['BARCLAYS BANK UK PLC Liscard', '205036', ''],
    ['BARCLAYS BANK PLC Lincoln Grp 9', '205039', ''],
    ['BARCLAYS BANK UK PLC Liskeard', '205040', ''],
    ['BARCLAYS BANK PLC Hmrc Resource', '205046', ''],
    ['BARCLAYS BANK PLC Nth Wirral Lisca 9', '205051', ''],
    ['BARCLAYS BANK UK PLC Liverpool South', '205082', ''],
    ['BARCLAYS BANK PLC Liverpool South 9', '205090', ''],
    ['BARCLAYS BANK UK PLC Liverpool South 2', '205094', ''],
    ['BARCLAYS BANK PLC Liverpool Lord Street', '205101', ''],
    ['BARCLAYS BANK PLC Liverp Sth 8', '205102', ''],
    ['BARCLAYS BANK UK PLC Liverpool City Business 2', '205108', ''],
    ['BARCLAYS BANK PLC South West and Wales Corp 1', '205114', ''],
    ['BARCLAYS BANK UK PLC Llandudno', '205123', ''],
    ['BARCLAYS BANK PLC Tamar Group 9', '205131', ''],
    ['BARCLAYS BANK UK PLC Llanelli', '205132', ''],
    ['BARCLAYS BANK PLC Hmrc Cash Mgt', '205134', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 01', '205135', ''],
    ['BARCLAYS BANK PLC Carmarth Llanell 9', '205140', ''],
    ['BARCLAYS BANK UK PLC Lpool City Bus 9', '205143', ''],
    ['BARCLAYS BANK PLC N Wales C Lland 9', '205149', ''],
    ['BARCLAYS BANK PLC London Loan Servicing Cen', '205232', ''],
    ['BARCLAYS BANK UK PLC Belfast Cash Centre', '205240', ''],
    ['BARCLAYS BANK UK PLC Loughborough Bishop Meadow', '205269', ''],
    ['BARCLAYS BANK UK PLC Loughton', '205274', ''],
    ['BARCLAYS BANK PLC Charnwood Grp 9', '205277', ''],
    ['BARCLAYS BANK UK PLC Louth', '205278', ''],
    ['BARCLAYS BANK PLC Loughton 9', '205282', ''],
    ['BARCLAYS BANK PLC Louth Grp 9', '205286', ''],
    ['BARCLAYS BANK UK PLC Tottenham', '205300', ''],
    ['BARCLAYS BANK UK PLC Tottenham 2', '205304', ''],
    ['BARCLAYS BANK UK PLC Lowestoft', '205306', ''],
    ['BARCLAYS BANK PLC N.E.L.B.C. 8', '205312', ''],
    ['BARCLAYS BANK PLC Lowestoft 9', '205314', ''],
    ['BARCLAYS BANK PLC NE London Grp  9', '205318', ''],
    ['BARCLAYS BANK UK PLC Ludlow', '205322', ''],
    ['BARCLAYS BANK PLC Luton George Street', '205330', ''],
    ['BARCLAYS BANK UK PLC Luton 3', '205331', ''],
    ['BARCLAYS BANK UK PLC Luton George Street 2', '205333', ''],
    ['BARCLAYS BANK PLC Luton (7)', '205349', ''],
    ['BARCLAYS BANK UK PLC Lymington', '205353', ''],
    ['BARCLAYS BANK PLC Ludlow 9', '205363', ''],
    ['BARCLAYS BANK PLC Luton Dunst 8', '205366', ''],
    ['BARCLAYS BANK UK PLC Macclesfield', '205377', ''],
    ['BARCLAYS BANK PLC New Forest Lym 9', '205379', ''],
    ['BARCLAYS BANK PLC Macclesfield 9', '205385', ''],
    ['BARCLAYS BANK UK PLC Luton Dunstable 9', '205397', ''],
    ['BARCLAYS BANK UK PLC Maidstone', '205411', ''],
    ['BARCLAYS BANK UK PLC Maidstone 3', '205412', ''],
    ['BARCLAYS BANK UK PLC Leicester Service Centre', '205415', ''],
    ['BARCLAYS BANK UK PLC Leicester Service Centre 2', '205422', ''],
    ['BARCLAYS BANK UK PLC Maidstone 2', '205425', ''],
    ['BARCLAYS BANK PLC North Kent Group 9', '205429', ''],
    ['BARCLAYS BANK UK PLC Maldon', '205430', ''],
    ['BARCLAYS BANK PLC North Kent 8', '205433', ''],
    ['BARCLAYS BANK PLC Malta', '205444', ''],
    ['BARCLAYS BANK PLC Maidstone 7', '205446', ''],
    ['BARCLAYS BANK PLC Maldon 9', '205455', ''],
    ['BARCLAYS BANK UK PLC Manchester Cheetham', '205458', ''],
    ['BARCLAYS BANK PLC Cheetham Salford 9', '205466', ''],
    ['BARCLAYS BANK PLC Northern Intl Ops Payments', '205478', ''],
    ['BARCLAYS BANK UK PLC E Savings 3', '205489', ''],
    ['BARCLAYS BANK UK PLC Manchester Universities', '205513', ''],
    ['BARCLAYS BANK PLC Manchester City Office', '205534', ''],
    ['BARCLAYS BANK PLC Manchester Univ 9', '205539', ''],
    ['BARCLAYS BANK PLC Scotland and Ni Corp 1', '205540', ''],
    ['BARCLAYS BANK UK PLC Manchester City 2', '205541', ''],
    ['BARCLAYS BANK PLC London Corp 1', '205552', ''],
    ['BARCLAYS BANK PLC Manchester City 8', '205558', ''],
    ['BARCLAYS BANK UK PLC Manchester City 9', '205559', ''],
    ['BARCLAYS BANK UK PLC Mansfield', '205562', ''],
    ['BARCLAYS BANK UK PLC Mapperley and Arnold', '205568', ''],
    ['BARCLAYS BANK PLC Sherwood Group 9', '205570', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 02', '205571', ''],
    ['BARCLAYS BANK UK PLC Sherwood Group 2', '205576', ''],
    ['BARCLAYS BANK PLC Mapperley 9', '205584', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 6', '205585', ''],
    ['BARCLAYS BANK UK PLC Pers Loans Bcard Afts', '205639', ''],
    ['BARCLAYS BANK UK PLC Mercantile Credit Afts', '205652', ''],
    ['BARCLAYS BANK UK PLC Merthyr Tydfil', '205656', ''],
    ['BARCLAYS BANK PLC S Wales Vall Mer 9', '205664', ''],
    ['BARCLAYS BANK UK PLC Middlesbrough Albert Road', '205674', ''],
    ['BARCLAYS BANK UK PLC Middlesbrough Albert Rd 2', '205678', ''],
    ['BARCLAYS BANK PLC Middlesbrough 9', '205690', ''],
    ['BARCLAYS BANK PLC Middlesbrough 8', '205694', ''],
    ['BARCLAYS BANK UK PLC Mile End', '205706', ''],
    ['BARCLAYS BANK UK PLC Wealth Centre', '205707', ''],
    ['BARCLAYS BANK UK PLC Mile End and Bow 2', '205722', ''],
    ['BARCLAYS BANK PLC Wealth Centre  9', '205723', ''],
    ['BARCLAYS BANK PLC Mile End and Bow 9', '205730', ''],
    ['BARCLAYS BANK UK PLC Milton Keynes', '205740', ''],
    ['BARCLAYS BANK UK PLC Milton Keynes 2', '205744', ''],
    ['BARCLAYS BANK PLC Milton Keynes 9', '205757', ''],
    ['BARCLAYS BANK PLC Milton Keynes 8', '205769', ''],
    ['BARCLAYS BANK UK PLC Wimbledon 3', '205776', ''],
    ['BARCLAYS BANK PLC Wimbledon 7', '205784', ''],
    ['BARCLAYS BANK UK PLC Morpeth', '205817', ''],
    ['BARCLAYS BANK PLC Morpeth 9', '205825', ''],
    ['BARCLAYS BANK UK PLC Muswell Hill', '205851', ''],
    ['BARCLAYS BANK UK PLC Muswell Hill 3', '205852', ''],
    ['BARCLAYS BANK PLC Muswell Hill 9', '205869', ''],
    ['BARCLAYS BANK UK PLC Neath', '205872', ''],
    ['BARCLAYS BANK UK PLC National Records Centre', '205877', ''],
    ['BARCLAYS BANK PLC Muswell Hill 7', '205878', ''],
    ['BARCLAYS BANK PLC W Glamorg Neath 9', '205898', ''],
    ['BARCLAYS BANK UK PLC Newbury', '205914', ''],
    ['BARCLAYS BANK PLC West Berkshire 9', '205922', ''],
    ['BARCLAYS BANK UK PLC Newcastle Staffs', '205923', ''],
    ['BARCLAYS BANK UK PLC UK Bk Customer Relations', '205933', ''],
    ['BARCLAYS BANK UK PLC Doxford B Call Centre', '205937', ''],
    ['BARCLAYS BANK PLC Newcastle City', '205942', ''],
    ['BARCLAYS BANK UK PLC North East Counties 3', '205943', ''],
    ['BARCLAYS BANK PLC Staffs Newcastle 9', '205949', ''],
    ['BARCLAYS BANK PLC Newcastle City 2', '205950', ''],
    ['BARCLAYS BANK UK PLC Newcastle City 9', '205959', ''],
    ['BARCLAYS BANK UK PLC Newcastle U Tyne Gosforth', '205961', ''],
    ['BARCLAYS BANK UK PLC Newcastle City 8', '205976', ''],
    ['BARCLAYS BANK PLC Newcastle North 9', '205979', ''],
    ['BARCLAYS BANK UK PLC Newcastle U Tyne Wingrove', '205997', ''],
    ['BARCLAYS BANK PLC Newcastle West 9', '206003', ''],
    ['BARCLAYS BANK UK PLC Newmarket', '206038', ''],
    ['BARCLAYS BANK PLC Newmarket 9', '206053', ''],
    ['BARCLAYS BANK UK PLC Newport Isle of Wight', '206055', ''],
    ['BARCLAYS BANK UK PLC Newport Commercial St', '206058', ''],
    ['BARCLAYS BANK UK PLC Newport Commercial St 2', '206064', ''],
    ['BARCLAYS BANK PLC SE Wales Newport 9', '206066', ''],
    ['BARCLAYS BANK PLC Newport Gwent 8', '206072', ''],
    ['BARCLAYS BANK PLC Dvla', '206082', ''],
    ['BARCLAYS BANK UK PLC Newton Abbot', '206088', ''],
    ['BARCLAYS BANK PLC Isle of Wight Gp 9', '206089', ''],
    ['BARCLAYS BANK UK PLC Cyprus UK Support', '206093', ''],
    ['BARCLAYS BANK PLC South Devon Grp 9', '206096', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 7', '206097', ''],
    ['BARCLAYS BANK UK PLC Newtown', '206108', ''],
    ['BARCLAYS BANK PLC Mid Wales Grp 9', '206124', ''],
    ['BARCLAYS BANK UK PLC Northallerton', '206146', ''],
    ["BARCLAYS BANK UK PLC N'thampton Wellinborough Rd", '206151', ''],
    ['BARCLAYS BANK UK PLC East Midlands 3', '206152', ''],
    ['BARCLAYS BANK PLC Northallerton 9', '206153', ''],
    ['BARCLAYS BANK UK PLC Northampton Wellingborough2', '206155', ''],
    ['BARCLAYS BANK PLC Northampton Grp 9', '206169', ''],
    ['BARCLAYS BANK PLC Northampton G 8', '206171', ''],
    ['BARCLAYS BANK UK PLC North Farnborough', '206182', ''],
    ['BARCLAYS BANK PLC Farnboro Blwater 9', '206190', ''],
    ['BARCLAYS BANK UK PLC South Devon Grp 2', '206201', ''],
    ['BARCLAYS BANK UK PLC North Scotland 3', '206208', ''],
    ['BARCLAYS BANK UK PLC North Shields', '206209', ''],
    ['BARCLAYS BANK UK PLC Belfast Castle Place', '206223', ''],
    ['BARCLAYS BANK UK PLC Belfast Castle Place 2', '206227', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 03', '206237', ''],
    ['BARCLAYS BANK PLC North Shields 9', '206241', ''],
    ['BARCLAYS BANK PLC Belfst Cstl Pl 8', '206243', ''],
    ['BARCLAYS BANK UK PLC Norwich', '206253', ''],
    ['BARCLAYS BANK PLC Belfast Cstl Pl 9', '206256', ''],
    ['BARCLAYS BANK UK PLC Norwich 2', '206257', ''],
    ['BARCLAYS BANK PLC Norwich Bank Pln 9', '206261', ''],
    ['BARCLAYS BANK PLC Nwich Bank Pln 8', '206265', ''],
    ['BARCLAYS BANK UK PLC Norwich Magdalen Street', '206268', ''],
    ['BARCLAYS BANK UK PLC Bexleyheath 3', '206269', ''],
    ['BARCLAYS BANK PLC Norwich Magdalen 9', '206276', ''],
    ['BARCLAYS BANK PLC Bexleyheath 7', '206277', ''],
    ['BARCLAYS BANK UK PLC Nottingham High Street', '206325', ''],
    ['BARCLAYS BANK UK PLC Nottingham 2', '206328', ''],
    ['BARCLAYS BANK PLC Nottingham 9', '206333', ''],
    ['BARCLAYS BANK PLC Nottingham 8', '206336', ''],
    ['BARCLAYS BANK UK PLC Oakham', '206366', ''],
    ['BARCLAYS BANK PLC Rutland Melton 9', '206374', ''],
    ['BARCLAYS BANK UK PLC Oldham', '206412', ''],
    ['BARCLAYS BANK PLC Oldham 9', '206420', ''],
    ['BARCLAYS BANK UK PLC Oxford City', '206518', ''],
    ['BARCLAYS BANK UK PLC Oxford City 2', '206520', ''],
    ['BARCLAYS BANK UK PLC Cowley', '206521', ''],
    ['BARCLAYS BANK PLC Oxford Grp City 9', '206526', ''],
    ['BARCLAYS BANK PLC Oxford Gp Cowley 9', '206539', ''],
    ['BARCLAYS BANK PLC Oxford City 8', '206546', ''],
    ['BARCLAYS BANK PLC Edgware Road', '206563', ''],
    ['BARCLAYS BANK PLC East and South East Reg 2', '206568', ''],
    ['BARCLAYS BANK PLC Pall Mall 2', '206582', ''],
    ['BARCLAYS BANK UK PLC Edgware Road 9', '206589', ''],
    ['BARCLAYS BANK UK PLC Pall Mall Corp 9', '206590', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 8', '206598', ''],
    ['BARCLAYS BANK UK PLC Mayfair', '206611', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 04', '206615', ''],
    ['BARCLAYS BANK PLC Mayfair 9', '206637', ''],
    ['BARCLAYS BANK UK PLC Peckham Rye', '206651', ''],
    ['BARCLAYS BANK UK PLC Peckham Rye 2', '206655', ''],
    ['BARCLAYS BANK PLC Dulwich 8', '206663', ''],
    ['BARCLAYS BANK PLC Dulwich Group 9', '206669', ''],
    ['BARCLAYS BANK UK PLC Central Unpaids in', '206670', ''],
    ['BARCLAYS BANK PLC Woolwich', '206681', ''],
    ["BARCLAYS BANK UK PLC Barclays S'brokers B Share", '206690', ''],
    ['BARCLAYS BANK PLC Cent Unpaids in 9', '206696', ''],
    ['BARCLAYS BANK UK PLC Penrith', '206697', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 9', '206705', ''],
    ['BARCLAYS BANK UK PLC Penzance', '206719', ''],
    ['BARCLAYS BANK PLC Edenvall Nlakes 9', '206721', ''],
    ['BARCLAYS BANK PLC Cornwall Penzanc 9', '206735', ''],
    ['BARCLAYS BANK UK PLC Peterborough Church Street', '206737', ''],
    ['BARCLAYS BANK UK PLC Peterborough Church St 2', '206740', ''],
    ['BARCLAYS BANK PLC Peterborough 9', '206745', ''],
    ['BARCLAYS BANK UK PLC Petersfield', '206749', ''],
    ['BARCLAYS BANK PLC Peterborough 8', '206757', ''],
    ['BARCLAYS BANK PLC Piccadilly', '206759', ''],
    ['BARCLAYS BANK PLC Petersfield 9', '206764', ''],
    ['BARCLAYS BANK UK PLC Malton', '206775', ''],
    ['BARCLAYS BANK PLC Midlands 2', '206776', ''],
    ['BARCLAYS BANK UK PLC Piccadilly 9', '206783', ''],
    ['BARCLAYS BANK UK PLC Plaistow', '206788', ''],
    ['BARCLAYS BANK UK PLC Plaistow 2', '206790', ''],
    ['BARCLAYS BANK PLC Ryedale Group 9', '206791', ''],
    ['BARCLAYS BANK PLC Plaistow        9', '206796', ''],
    ['BARCLAYS BANK UK PLC Woolwich 3', '206804', ''],
    ['BARCLAYS BANK PLC Newham Group 8', '206808', ''],
    ['BARCLAYS BANK UK PLC Plymouth', '206810', ''],
    ['BARCLAYS BANK UK PLC Devon & Cornwall 3', '206811', ''],
    ['BARCLAYS BANK UK PLC Plymouth 2', '206815', ''],
    ['BARCLAYS BANK PLC Plymouth 8', '206823', ''],
    ['BARCLAYS BANK PLC Plymouth  9', '206836', ''],
    ['BARCLAYS BANK UK PLC Poole 3', '206872', ''],
    ['BARCLAYS BANK PLC Clmp', '206874', ''],
    ['BARCLAYS BANK PLC Poole Bills Payable Drafts', '206875', ''],
    ['BARCLAYS BANK UK PLC Pontypridd', '206876', ''],
    ['BARCLAYS BANK PLC Poole Operations Centre', '206877', ''],
    ['BARCLAYS BANK UK PLC Poole', '206879', ''],
    ['BARCLAYS BANK UK PLC Staff Loans', '206884', ''],
    ['BARCLAYS BANK UK PLC Barclays House', '206885', ''],
    ['BARCLAYS BANK UK PLC Pontypridd 2', '206887', ''],
    ['BARCLAYS BANK PLC S Wales Val Pont 9', '206892', ''],
    ['BARCLAYS BANK PLC Poole Group 9', '206895', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 10', '206912', ''],
    ['BARCLAYS BANK UK PLC Portman Square 2', '206915', ''],
    ['BARCLAYS BANK UK PLC Portman Square', '206917', ''],
    ['BARCLAYS BANK PLC Portman Square  9', '206925', ''],
    ['BARCLAYS BANK PLC Staff Accs Poole 9', '206926', ''],
    ['BARCLAYS BANK UK PLC Portman Square 3', '206929', ''],
    ['BARCLAYS BANK UK PLC Portsmouth', '206934', ''],
    ['BARCLAYS BANK UK PLC Portsmouth 2', '206940', ''],
    ['BARCLAYS BANK UK PLC Postal Accounts Business', '206946', ''],
    ['BARCLAYS BANK UK PLC Coventry Postal Credits', '206950', ''],
    ['BARCLAYS BANK UK PLC Postal Accounts Personal', '206954', ''],
    ['BARCLAYS BANK PLC Portman Square 8', '206956', ''],
    ['BARCLAYS BANK PLC Portsmouth Cit 8', '206957', ''],
    ['BARCLAYS BANK PLC Portsdown Group 9', '206959', ''],
    ['BARCLAYS BANK UK PLC Preston', '206985', ''],
    ['BARCLAYS BANK UK PLC North West 3', '206987', ''],
    ['BARCLAYS BANK PLC Preston Lancs   9', '206993', ''],
    ['BARCLAYS BANK UK PLC Croydon 3', '207015', ''],
    ['BARCLAYS BANK PLC Croydon 7', '207023', ''],
    ['BARCLAYS BANK PLC Central Finance', '207041', ''],
    ['BARCLAYS BANK UK PLC Radbroke Hall', '207046', ''],
    ['BARCLAYS BANK UK PLC Currency Service9', '207058', ''],
    ['BARCLAYS BANK UK PLC Universal Banking 2', '207070', ''],
    ['BARCLAYS BANK UK PLC Rayleigh', '207093', ''],
    ['BARCLAYS BANK UK PLC Universal Bank 3', '207094', ''],
    ['BARCLAYS BANK PLC Rayleigh Castlep 9', '207101', ''],
    ['BARCLAYS BANK PLC Southern Intl Ops Payments', '207102', ''],
    ['BARCLAYS BANK UK PLC Reading', '207103', ''],
    ['BARCLAYS BANK UK PLC Reading 3', '207104', ''],
    ['BARCLAYS BANK UK PLC Reading 2', '207106', ''],
    ['BARCLAYS BANK UK PLC Mid Thames Readi2', '207108', ''],
    ['BARCLAYS BANK PLC Reading 8', '207114', ''],
    ['BARCLAYS BANK PLC Mid Thames Readi 9', '207129', ''],
    ['BARCLAYS BANK UK PLC E Savings 1', '207135', ''],
    ['BARCLAYS BANK UK PLC Redditch', '207145', ''],
    ['BARCLAYS BANK PLC Solihull Redditc 9', '207152', ''],
    ['BARCLAYS BANK UK PLC Regent Street 27', '207164', ''],
    ['NATIONWIDE BUILDING SOCIETY Bournemouth Treasury', '207172', ''],
    ['BARCLAYS BANK UK PLC Regent Street 212', '207174', ''],
    ['BARCLAYS BANK UK PLC Regent Street  27.  2', '207175', ''],
    ['BARCLAYS BANK UK PLC Mayfair212regent2', '207182', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 1', '207186', ''],
    ['BARCLAYS BANK PLC Mayfair 27Regent 9', '207198', ''],
    ['BARCLAYS BANK PLC Mayfair 27Reg 8', '207209', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 05', '207214', ''],
    ['BARCLAYS BANK PLC Mayfair212regent 9', '207216', ''],
    ['BARCLAYS BANK PLC Richmond', '207217', ''],
    ['BARCLAYS BANK UK PLC Richmond Twicken 2', '207230', ''],
    ['BARCLAYS BANK PLC Mfair212regent 8', '207232', ''],
    ['BARCLAYS BANK UK PLC Richmond Twicken 9', '207233', ''],
    ['BARCLAYS BANK UK PLC Ringwood', '207237', ''],
    ['BARCLAYS BANK UK PLC Risk Services Unit', '207244', ''],
    ['BARCLAYS BANK PLC New Forest Ringw 9', '207245', ''],
    ['BARCLAYS BANK UK PLC Risk Management Unit Bstoke', '207254', ''],
    ['BARCLAYS BANK UK PLC Rochdale', '207267', ''],
    ['BARCLAYS BANK PLC Voa', '207269', ''],
    ['BARCLAYS BANK PLC Rochdale 9', '207275', ''],
    ['BARCLAYS BANK UK PLC Romford', '207289', ''],
    ['BARCLAYS BANK UK PLC Romford 2', '207291', ''],
    ['BARCLAYS BANK PLC Romford Group   9', '207305', ''],
    ['BARCLAYS BANK PLC Romford 8', '207325', ''],
    ['BARCLAYS BANK UK PLC Royston Herts', '207326', ''],
    ['BARCLAYS BANK PLC Royston Herts 9', '207334', ''],
    ['BARCLAYS BANK UK PLC Rugby', '207348', ''],
    ['BARCLAYS BANK UK PLC Ruislip', '207353', ''],
    ['BARCLAYS BANK PLC Harrow Ruislip  9', '207361', ''],
    ['BARCLAYS BANK PLC Rugby North St 9', '207363', ''],
    ['BARCLAYS BANK UK PLC Saffron Walden', '207405', ''],
    ['BARCLAYS BANK UK PLC St Albans', '207409', ''],
    ['BARCLAYS BANK UK PLC St Albans 2', '207412', ''],
    ['BARCLAYS BANK UK PLC St Austell', '207420', ''],
    ['BARCLAYS BANK PLC Saffron Walden  9', '207421', ''],
    ['BARCLAYS BANK PLC St Albans 8', '207438', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 06', '207439', ''],
    ['BARCLAYS BANK UK PLC St Helens Merseyside', '207445', ''],
    ['BARCLAYS BANK PLC Mid Cornwall Grp 9', '207446', ''],
    ['BARCLAYS BANK PLC St Helens  9', '207452', ''],
    ['BARCLAYS BANK UK PLC Swiss Cottage', '207463', ''],
    ['BARCLAYS BANK PLC St Johns Wood 9', '207471', ''],
    ['BARCLAYS BANK UK PLC St Neots', '207481', ''],
    ['BARCLAYS BANK PLC St Neots 9', '207499', ''],
    ['BARCLAYS BANK UK PLC Salisbury', '207501', ''],
    ['BARCLAYS BANK PLC Salisbury 9', '207519', ''],
    ['BARCLAYS BANK UK PLC Scarborough', '207592', ''],
    ['BARCLAYS BANK UK PLC Bhs (Sam) Leeds', '207607', ''],
    ['BARCLAYS BANK UK PLC Scunthorpe', '207614', ''],
    ['BARCLAYS BANK PLC Scunthorpe  9', '207630', ''],
    ['BARCLAYS BANK UK PLC Sevenoaks', '207655', ''],
    ['BARCLAYS BANK PLC Sevenoaks Group 9', '207663', ''],
    ['BARCLAYS BANK UK PLC S Scotland and Glasgow 3', '207676', ''],
    ['BARCLAYS BANK PLC Northern Corp 3', '207687', ''],
    ['BARCLAYS BANK UK PLC Sheffield City', '207689', ''],
    ['BARCLAYS BANK UK PLC Walthamstow 3', '207690', ''],
    ['BARCLAYS BANK UK PLC Sheffield City 2', '207692', ''],
    ['BARCLAYS BANK PLC Sheffield Rother 9', '207697', ''],
    ['BARCLAYS BANK PLC Walthamstow 7', '207716', ''],
    ['BARCLAYS BANK PLC Sheffield 8', '207718', ''],
    ['BARCLAYS BANK UK PLC Shirley', '207762', ''],
    ['BARCLAYS BANK PLC Broadgate 2', '207767', ''],
    ['BARCLAYS BANK PLC Solihull Shirley 9', '207770', ''],
    ['BARCLAYS BANK UK PLC Broadgate 9', '207775', ''],
    ['BARCLAYS BANK UK PLC Shrewsbury', '207785', ''],
    ['BARCLAYS BANK PLC Shrewsbury 9', '207801', ''],
    ['BARCLAYS BANK UK PLC Skipton', '207842', ''],
    ['BARCLAYS BANK PLC Corporate 7', '207846', ''],
    ['BARCLAYS BANK UK PLC Slough Queensmere', '207858', ''],
    ['BARCLAYS BANK UK PLC E Berks Slough 2', '207861', ''],
    ['BARCLAYS BANK PLC E Berks Slough  9', '207866', ''],
    ['BARCLAYS BANK PLC E Berks Slough 8', '207887', ''],
    ['BARCLAYS BANK PLC Skipton 9', '207891', ''],
    ['BARCLAYS BANK PLC Soho Sq', '207898', ''],
    ['BARCLAYS BANK UK PLC Soho Square 9', '207906', ''],
    ['BARCLAYS BANK UK PLC Southampton City', '207925', ''],
    ['BARCLAYS BANK UK PLC Southampton City 2', '207929', ''],
    ['BARCLAYS BANK UK PLC Southampton City 3', '207931', ''],
    ['BARCLAYS BANK PLC Southampton 8', '207937', ''],
    ['BARCLAYS BANK PLC Southampton City 9', '207941', ''],
    ['BARCLAYS BANK PLC Southampton 7', '207949', ''],
    ['BARCLAYS BANK UK PLC Southend-on-Sea', '207973', ''],
    ['BARCLAYS BANK PLC Southend on Sea 9', '207981', ''],
    ['BARCLAYS BANK UK PLC Chelsea', '208014', ''],
    ['BARCLAYS BANK PLC Kensingtonchels 9', '208022', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 2', '208026', ''],
    ['BARCLAYS BANK UK PLC Southport Lord Street', '208033', ''],
    ['BARCLAYS BANK PLC Dvla Non Fps', '208045', ''],
    ['BARCLAYS BANK UK PLC South Shields', '208047', ''],
    ['BARCLAYS BANK UK PLC S C M Kings Cross', '208053', ''],
    ['BARCLAYS BANK PLC South Tyneside 9', '208054', ''],
    ['BARCLAYS BANK UK PLC Southwark', '208057', ''],
    ['BARCLAYS BANK UK PLC Southwark 2', '208071', ''],
    ['BARCLAYS BANK UK PLC Spalding', '208078', ''],
    ['BARCLAYS BANK UK PLC UK Bk Customer Relations 1', '208080', ''],
    ['BARCLAYS BANK PLC Southwark 9', '208081', ''],
    ['BARCLAYS BANK UK PLC UK Bk Customer Relations 2', '208082', ''],
    ['BARCLAYS BANK PLC Southwark 8', '208089', ''],
    ['BARCLAYS BANK PLC Southport Wlancs 9', '208090', ''],
    ['BARCLAYS BANK PLC Rb Cust Reltns 9', '208098', ''],
    ['BARCLAYS BANK UK PLC Stafford', '208100', ''],
    ['BARCLAYS BANK PLC Sth Lincolnshire 9', '208110', ''],
    ['BARCLAYS BANK UK PLC Staines', '208111', ''],
    ['BARCLAYS BANK PLC Staffs Stafford 9', '208118', ''],
    ['BARCLAYS BANK UK PLC Stamford', '208120', ''],
    ['BARCLAYS BANK UK PLC Stamford Hill 3', '208121', ''],
    ['BARCLAYS BANK PLC Staines 9', '208129', ''],
    ['BARCLAYS BANK PLC Stamford Hill 7', '208139', ''],
    ['BARCLAYS BANK PLC Stamford 9', '208153', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 07', '208188', ''],
    ['BARCLAYS BANK UK PLC Stockport 3', '208213', ''],
    ['BARCLAYS BANK UK PLC Stockport', '208214', ''],
    ['BARCLAYS BANK UK PLC Stockton-on-Tees', '208218', ''],
    ['BARCLAYS BANK UK PLC Stockton-on-Tees 2', '208223', ''],
    ['BARCLAYS BANK PLC Stockton 9', '208226', ''],
    ['BARCLAYS BANK PLC Stockton 8', '208231', ''],
    ['BARCLAYS BANK PLC Stockport 9', '208248', ''],
    ['BARCLAYS BANK UK PLC Stourbridge', '208270', ''],
    ['BARCLAYS BANK UK PLC Stowmarket', '208275', ''],
    ['BARCLAYS BANK PLC Stowmkt Needham 9', '208283', ''],
    ['BARCLAYS BANK PLC Global Transactional Bkg 1', '208288', ''],
    ['BARCLAYS BANK PLC Strand', '208294', ''],
    ['BARCLAYS BANK UK PLC Strand Corp 9', '208302', ''],
    ['BARCLAYS BANK PLC Stratford upon Avon Nfu', '208308', ''],
    ['BARCLAYS BANK PLC Global Transactional Bkg 2', '208310', ''],
    ['BARCLAYS BANK UK PLC Stratford Up Avn 9', '208316', ''],
    ['BARCLAYS BANK UK PLC Sudbury Suffolk', '208350', ''],
    ['BARCLAYS BANK UK PLC Sunderland', '208369', ''],
    ['BARCLAYS BANK UK PLC Sunderland 2', '208373', ''],
    ['BARCLAYS BANK PLC Support Services', '208384', ''],
    ['BARCLAYS BANK UK PLC Testing Services', '208385', ''],
    ['BARCLAYS BANK PLC W Glamorg Swanse 9', '208391', ''],
    ['BARCLAYS BANK UK PLC Sutton Coldfield', '208413', ''],
    ['BARCLAYS BANK UK PLC Sutton', '208417', ''],
    ['BARCLAYS BANK UK PLC Sutton 2', '208420', ''],
    ['BARCLAYS BANK UK PLC Swansea Kingsway', '208441', ''],
    ['BARCLAYS BANK UK PLC Swansea Kingsway 2', '208443', ''],
    ['BARCLAYS BANK UK PLC Swindon Regent Street', '208458', ''],
    ['BARCLAYS BANK UK PLC Swindon Regent Street 2', '208461', ''],
    ['BARCLAYS BANK PLC Sutton 9', '208508', ''],
    ['BARCLAYS BANK UK PLC Tamworth', '208513', ''],
    ['BARCLAYS BANK PLC Wearside Group 8', '208514', ''],
    ['BARCLAYS BANK UK PLC Taunton', '208526', ''],
    ['BARCLAYS BANK UK PLC Taunton 2', '208531', ''],
    ['BARCLAYS BANK UK PLC Wellington Telford', '208546', ''],
    ['BARCLAYS BANK PLC Sunderland 9', '208559', ''],
    ['BARCLAYS BANK UK PLC Thame', '208573', ''],
    ['BARCLAYS BANK UK PLC Thetford', '208593', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 08', '208609', ''],
    ['BARCLAYS BANK PLC Sutton 8', '208651', ''],
    ['BARCLAYS BANK PLC Sutton Coldfield 9', '208652', ''],
    ['BARCLAYS BANK UK PLC Thurrock Grays 3', '208654', ''],
    ['BARCLAYS BANK PLC Wellington Tlfrd 9', '208686', ''],
    ['BARCLAYS BANK UK PLC Tonbridge Medway House', '208696', ''],
    ['BARCLAYS BANK PLC Taunton 9', '208724', ''],
    ['BARCLAYS BANK UK PLC London Intl Ops Trade', '208743', ''],
    ['BARCLAYS BANK PLC Trade Finance Unit', '208746', ''],
    ['BARCLAYS BANK PLC Swindon 8', '208750', ''],
    ['BARCLAYS BANK PLC UK Guarantees Centre', '208754', ''],
    ['BARCLAYS BANK UK PLC Truro', '208794', ''],
    ['BARCLAYS BANK UK PLC Trust Company', '208798', ''],
    ['BARCLAYS BANK UK PLC Tunbridge Wells', '208813', ''],
    ['BARCLAYS BANK UK PLC Account Centre North', '208840', ''],
    ['BARCLAYS BANK PLC Cornwall Truro  9', '208844', ''],
    ['BARCLAYS BANK UK PLC Account Centre East', '208856', ''],
    ['BARCLAYS BANK PLC Account Centre West', '208870', ''],
    ['BARCLAYS BANK UK PLC Upton Park 3', '208915', ''],
    ['BARCLAYS BANK UK PLC Uxbridge', '208916', ''],
    ['BARCLAYS BANK UK PLC Blsc Birmingham G', '208952', ''],
    ['BARCLAYS BANK UK PLC Upton Park 4', '208956', ''],
    ['BARCLAYS BANK UK PLC Wakefield', '208968', ''],
    ['BARCLAYS BANK UK PLC Walsall The Bridge', '209008', ''],
    ['BARCLAYS BANK UK PLC Yorkshire 3', '209026', ''],
    ['BARCLAYS BANK PLC Trust Co Banking 9', '209028', ''],
    ['BARCLAYS BANK PLC Bishop Aukland 9', '209051', ''],
    ['BARCLAYS BANK PLC Swansea Kings 8', '209052', ''],
    ['BARCLAYS BANK PLC Uxbridge 9', '209054', ''],
    ['BARCLAYS BANK UK PLC Walton-on-Thames', '209056', ''],
    ['BARCLAYS BANK UK PLC Walton on Thames 2', '209057', ''],
    ['BARCLAYS BANK PLC Wakefield 9', '209065', ''],
    ['BARCLAYS BANK UK PLC Wandsworth', '209069', ''],
    ['BARCLAYS BANK PLC Walsall Group 9', '209073', ''],
    ['BARCLAYS BANK UK PLC Wandsworth 2', '209074', ''],
    ['BARCLAYS BANK UK PLC Wantage', '209091', ''],
    ['BARCLAYS BANK PLC Upton Park 7', '209111', ''],
    ['BARCLAYS BANK PLC Swindon 9', '209134', ''],
    ['BARCLAYS BANK UK PLC Warrington', '209148', ''],
    ['BARCLAYS BANK PLC Upton Park 6', '209160', ''],
    ['BARCLAYS BANK UK PLC Washington Springwell Hse', '209166', ''],
    ['BARCLAYS BANK PLC Wandsworth Grp 9', '209168', ''],
    ['BARCLAYS BANK UK PLC Watford', '209179', ''],
    ['BARCLAYS BANK UK PLC Watford 2', '209180', ''],
    ['BARCLAYS BANK PLC South Chilterns 9', '209183', ''],
    ['BARCLAYS BANK UK PLC Waveney Valley', '209208', ''],
    ['BARCLAYS BANK UK PLC Welwyn Garden City', '209254', ''],
    ['BARCLAYS BANK UK PLC Welwyn  Garden City 3', '209255', ''],
    ['BARCLAYS BANK UK PLC Welling 3', '209256', ''],
    ['BARCLAYS BANK UK PLC Wembley and Park Royal', '209260', ''],
    ['BARCLAYS BANK UK PLC Wembley & Park Royal 2', '209263', ''],
    ['BARCLAYS BANK PLC Sth Oxfrd Wantag 9', '209265', ''],
    ['BARCLAYS BANK UK PLC Wembley Cash Centre', '209271', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 09', '209275', ''],
    ['BARCLAYS BANK PLC Chiltern Watford 9', '209294', ''],
    ['BARCLAYS BANK PLC Walton on Thames 9', '209296', ''],
    ['BARCLAYS BANK PLC Wandsworth 8', '209298', ''],
    ['BARCLAYS BANK PLC Wembley 9', '209302', ''],
    ['BARCLAYS BANK PLC Warrington 9', '209304', ''],
    ['BARCLAYS BANK PLC Welwyn Gdn City 9', '209312', ''],
    ['BARCLAYS BANK PLC Walton Thames 8', '209313', ''],
    ['BARCLAYS BANK UK PLC West Bromwich', '209315', ''],
    ['BARCLAYS BANK PLC Tunbridge Group 9', '209332', ''],
    ['BARCLAYS BANK PLC Watford 8', '209347', ''],
    ['BARCLAYS BANK PLC Waveney Valley 9', '209349', ''],
    ['BARCLAYS BANK PLC Wembley 8', '209354', ''],
    ['BARCLAYS BANK PLC Dudley West Brom 9', '209356', ''],
    ['BARCLAYS BANK UK PLC Barclays Blue Rewards 3', '209365', ''],
    ['BARCLAYS BANK UK PLC Blsc Birmingham W', '209375', ''],
    ['BARCLAYS BANK UK PLC Bb Processing(Tate) Camdn', '209404', ''],
    ['BARCLAYS BANK UK PLC Westminster Abbey', '209448', ''],
    ['BARCLAYS BANK PLC Westminster Abby 9', '209463', ''],
    ['BARCLAYS BANK UK PLC West Norwood', '209467', ''],
    ['BARCLAYS BANK UK PLC Weston Super Mare', '209474', ''],
    ['BARCLAYS BANK PLC Norwood Gp 9', '209475', ''],
    ['BARCLAYS BANK PLC North Somerset 9', '209508', ''],
    ['BARCLAYS BANK UK PLC Whetstone', '209561', ''],
    ['BARCLAYS BANK UK PLC Whetstone 2', '209572', ''],
    ['BARCLAYS BANK PLC Whetstone 9', '209587', ''],
    ['BARCLAYS BANK UK PLC Wigan', '209637', ''],
    ['BARCLAYS BANK PLC Wigan 9', '209645', ''],
    ['BARCLAYS BANK UK PLC Scrubbs Lane', '209655', ''],
    ['BARCLAYS BANK UK PLC Scrubbs Lane 2', '209660', ''],
    ['BARCLAYS BANK PLC Willesden 9', '209663', ''],
    ['BARCLAYS BANK UK PLC Blue-R Wallet 10', '209673', ''],
    ['BARCLAYS BANK PLC Willesden 8', '209686', ''],
    ['BARCLAYS BANK UK PLC Wimbledon', '209689', ''],
    ['BARCLAYS BANK UK PLC Wimborne', '209696', ''],
    ['BARCLAYS BANK PLC Wimbledon Group 9', '209697', ''],
    ['BARCLAYS BANK UK PLC Winchester Coventry House', '209698', ''],
    ['BARCLAYS BANK UK PLC Winchester', '209701', ''],
    ['BARCLAYS BANK PLC Wimborne 9', '209704', ''],
    ['BARCLAYS BANK UK PLC Windsor', '209709', ''],
    ['BARCLAYS BANK PLC E Berks Windsor 9', '209717', ''],
    ['BARCLAYS BANK PLC Winchester 9', '209719', ''],
    ['BARCLAYS BANK UK PLC Wisbech', '209734', ''],
    ['BARCLAYS BANK UK PLC Witham', '209740', ''],
    ['BARCLAYS BANK UK PLC Witham Stour House', '209742', ''],
    ['BARCLAYS BANK UK PLC Witney', '209748', ''],
    ['BARCLAYS BANK PLC W Oxford Witney 9', '209755', ''],
    ['BARCLAYS BANK UK PLC Woking', '209758', ''],
    ['BARCLAYS BANK PLC Wisbech 9', '209759', ''],
    ['BARCLAYS BANK UK PLC Woking 3', '209760', ''],
    ['BARCLAYS BANK PLC Witham 9', '209765', ''],
    ['BARCLAYS BANK UK PLC Loans Sales Team Cardiff', '209770', ''],
    ['BARCLAYS BANK PLC Woking Grp 9', '209774', ''],
    ['BARCLAYS BANK UK PLC Wolverhampton Queens Sq', '209778', ''],
    ['BARCLAYS BANK UK PLC West Midlands 3', '209779', ''],
    ['BARCLAYS BANK PLC Wolverhampton 9', '209786', ''],
    ['BARCLAYS BANK PLC Sold Business Services', '209787', ''],
    ['BARCLAYS BANK PLC Ukbs Treasury Systems', '209788', ''],
    ['BARCLAYS BANK UK PLC Wolverhampton Queen Sq 2', '209790', ''],
    ['BARCLAYS BANK PLC Woking 7', '209794', ''],
    ['BARCLAYS BANK UK PLC Woodbridge', '209807', ''],
    ['BARCLAYS BANK PLC Wolverhampton 8', '209808', ''],
    ['BARCLAYS BANK PLC Suffolk Coastal 9', '209815', ''],
    ['BARCLAYS BANK UK PLC Wood Green', '209821', ''],
    ['BARCLAYS BANK PLC Wood Green Bus C 9', '209847', ''],
    ['BARCLAYS BANK UK PLC Woodstock', '209848', ''],
    ['BARCLAYS BANK UK PLC Woolston Kingsland Grange', '209855', ''],
    ['BARCLAYS BANK UK PLC Woolwich', '209857', ''],
    ['BARCLAYS BANK UK PLC Worcester', '209861', ''],
    ['BARCLAYS BANK UK PLC Worcester 2', '209868', ''],
    ['BARCLAYS BANK PLC Woolwich 9', '209873', ''],
    ['BARCLAYS BANK UK PLC Worthing', '209874', ''],
    ['BARCLAYS BANK UK PLC West Sussex 3', '209875', ''],
    ['BARCLAYS BANK PLC Worcester 8', '209884', ''],
    ['BARCLAYS BANK PLC Worcestershire 9', '209887', ''],
    ['BARCLAYS BANK PLC Worthing Group 9', '209890', ''],
    ['BARCLAYS BANK PLC Retail Help 9', '209894', ''],
    ['BARCLAYS BANK UK PLC York 2', '209898', ''],
    ['BARCLAYS BANK UK PLC Wymondham', '209908', ''],
    ['BARCLAYS BANK UK PLC Wythenshawe Help Desk', '209912', ''],
    ['BARCLAYS BANK PLC York 9', '209915', ''],
    ['BARCLAYS BANK UK PLC W Midlands Reg Office', '209918', ''],
    ['BARCLAYS BANK UK PLC Great Yarmouth', '209921', ''],
    ['BARCLAYS BANK PLC York 8', '209922', ''],
    ['BARCLAYS BANK PLC Wymondham 9', '209924', ''],
    ['BARCLAYS BANK UK PLC Wales/S West Med Business', '209925', ''],
    ['BARCLAYS BANK UK PLC East Anglia Reg Office', '209926', ''],
    ['BARCLAYS BANK UK PLC Isa Savings Account 5', '209927', ''],
    ['BARCLAYS BANK UK PLC Bof Northampton', '209928', ''],
    ['BARCLAYS BANK UK PLC Yorkshire/Humberside Ro', '209933', ''],
    ['BARCLAYS BANK PLC W Oxford Woodstk 9', '209939', ''],
    ['BARCLAYS BANK UK PLC Yeovil', '209940', ''],
    ['BARCLAYS BANK PLC Great Yarmouth 9', '209947', ''],
    ['BARCLAYS BANK UK PLC York', '209956', ''],
    ['BARCLAYS BANK PLC Alperton 9', '209958', ''],
    ['BARCLAYS BANK PLC Barclays Securities Cen', '209959', ''],
    ['BARCLAYS BANK UK PLC Next Gen Banking', '209967', ''],
    ['BARCLAYS BANK UK PLC Self Service Operations', '209993', ''],
    ['BARCLAYS BANK PLC Barclays Virtual Account 1', '225473', ''],
    ['BARCLAYS BANK PLC Barclays Virtual Account 2', '225474', ''],
    ['BARCLAYS BANK PLC Barclays Virtual Account 3', '225475', ''],
    ['BARCLAYS BANK PLC Barclays Virtual Account 4', '225477', ''],
    ['BARCLAYS BANK PLC Barclays Virtual Account 5', '225479', ''],
    ['BARCLAYS BANK PLC Barclays Virtual Account 6', '225481', ''],
    ['BARCLAYS BANK UK PLC Barclays Virtual Account 7', '225482', ''],
    ['BARCLAYS BANK UK PLC Barclays Virtual Account 8', '225484', ''],
    ['BARCLAYS BANK UK PLC Barclays Virtual Account 9', '225485', ''],
    ['VFX FINANCIAL PLC Vfx Financial plc', '230088', ''],
    ['SOCIETE GENERALE Securities Services', '230089', 'SOGEGB33XXX'],
    ['PCF BANK LIMITED Pcf Bank Limited', '230108', ''],
    [
      'SHANGHAI PUDONG DEVELOPMENT BANK COMPANY LIMITED Shanghai Pudong Dev Bnk Ltd',
      '230116',
      ''
    ],
    ['REVOLUT LTD Revolut Ltd', '230120', ''],
    ['STRIPE PAYMENTS UK LTD Stripe Payments UK Ltd', '230121', ''],
    ['BMCE BANK INTERNATIONAL PLC Bmce Bank Intl plc', '230123', 'MEDTGB2LXXX'],
    ['DEUTSCHE BANK AG Financial Institutions', '230176', ''],
    ['DEPARTMENT OF TRANSPORT (DVLA) Dvla', '230324', ''],
    ["BARCLAYS BANK UK PLC Con Ldg UK Retail B'loan 1", '230338', ''],
    ['PREPAY TECHNOLOGIES LTD Prepay Technologies Ltd', '230363', ''],
    ['PREPAY TECHNOLOGIES LTD Prepay Technologies Ltd', '230364', ''],
    ['PREPAY TECHNOLOGIES LTD Prepay Technologies Ltd', '230365', ''],
    ['PREPAY TECHNOLOGIES LTD Prepay Technologies', '230366', ''],
    ['PREPAY TECHNOLOGIES LTD Prepay Technologies Ltd', '230367', ''],
    ['FIRE FINANCIAL SERVICES LTD Fire Financial Services Eu', '230372', ''],
    ['STRIPE PAYMENTS UK LTD Stripe Payments UK Ltd', '230505', ''],
    ['METRO BANK PLC London', '230580', 'MYMBGB2LXXX'],
    ["BARCLAYS BANK UK PLC Con Ldg UK Retail B'loan 2", '230614', ''],
    ['DEPARTMENT OF TRANSPORT (DVLA) Dvla', '230699', ''],
    ["BARCLAYS BANK UK PLC Con Ldg UK R B'loan +1", '230709', ''],
    ['TRANSFERWISE LIMITED Transferwise Ltd', '230801', ''],
    ["BARCLAYS BANK UK PLC Con Ldg UK R B'loan +2", '230872', ''],
    ['BARCLAYS BANK PLC Lct Agency Client', '230907', ''],
    ['BARCLAYS BANK UK PLC Con Ldg UK Retail Resolve', '230933', ''],
    ["Her Majesty's Revenue and Customs Hmrc", '230955', ''],
    ['BARCLAYS BANK UK PLC Con Ldg UK R Barclays P L', '231018', ''],
    ['DEUTSCHE BANK AG Corporate Customers', '231048', ''],
    ['EFG PRIVATE BANK LTD St Helier Jersey', '231134', 'EFGBJESHXXX'],
    ['PAYRNET LIMITED Anna Money', '231185', ''],
    ["BARCLAYS BANK UK PLC Con Ldg UK R W'drawn Prod", '231213', ''],
    ['EBURY PARTNERS UK LIMITED Ebury Partners Iban', '231228', 'EBURGBA1XXX'],
    ['BARCLAYS BANK UK PLC Con Ldg UK R Masterloan', '231354', ''],
    ['MASTHAVEN BANK LIMITED Masthaven Bank Limited', '231451', ''],
    ['BARCLAYS BANK UK PLC Con Ldg UK R Car Dev Loan', '231469', ''],
    ['TRANSFERWISE LIMITED Transferwise', '231470', ''],
    [
      'PAYONEER EUROPE LIMITED Payoneer Europe Limited',
      '231486',
      'PAYNGIG1XXX'
    ],
    ['ALPHA FX LIMITED Alpha Fx Ltd', '231536', 'APAHGB2LXXX'],
    ['BARCLAYS BANK UK PLC Con Ldg UK R Grad Loan', '231558', ''],
    ["Her Majesty's Revenue and Customs Hmrc Vat Payable Orders", '231610', ''],
    [
      'EBURY PARTNERS UK LIMITED Ebury Partners UK Ltd',
      '231618',
      'EBURGBB1XXX'
    ],
    ['BARCLAYS BANK UK PLC Con Ldg UK R Mercantile', '231679', ''],
    ['BARCLAYS BANK UK PLC Con Ldg UK Retail Select', '231843', ''],
    ['SPECTRUM PAYMENT SERVICES LTD Equals Money', '231884', ''],
    ['UNION BANCAIRE PRIVEE UBP SA St James Square', '231935', ''],
    ['BYBLOS BANK EUROPE SA London', '231976', 'BYBBGB2LXXX'],
    ['BARCLAYS BANK UK PLC Con Ldg UK Retail Advance', '231985', ''],
    ['DEUTSCHE BANK AG London', '232072', ''],
    ['BARCLAYS BANK UK PLC Next Gen Credit Cards', '232111', ''],
    ['FIRE FINANCIAL SERVICES LTD Fire Financial Services UK', '232116', ''],
    ['BARCLAYS BANK PLC Con Ldg UK Retail Marine', '232130', ''],
    ['BARCLAYS BANK PLC Postal Orders', '232194', ''],
    ['FIRE FINANCIAL SERVICES LTD Fire Financial Services', '232221', ''],
    ['FIRE FINANCIAL SERVICES LTD Fire Financial Services Lim', '232222', ''],
    ['SOCIETE GENERALE Societe Generale', '232263', 'SOGEFRPPXXX'],
    ['BARCLAYS BANK PLC Con Lending Jsy Barclayloan', '232279', ''],
    [
      'IFX PAYMENTS (A TRADING NAME OF IFX UK LIMITED) Ifx (UK) Ltd',
      '232290',
      'IFXSGB2LXXX'
    ],
    ['BARCLAYS BANK PLC Company Barclaycard', '232293', ''],
    ['UBS AG Gbp Clearing London', '232320', 'UBSWGB55XXX'],
    ['UBS AG 100 Liverpool St London', '232323', 'UBSWGB2LXXX'],
    ['UBS AG St Helier Jersey', '232326', 'UBSWJESHXXX'],
    ['UBS AG Bacl Up Sort Code 1', '232328', ''],
    ['UBS AG Back Up Sort Code 2', '232329', ''],
    ["BARCLAYS BANK PLC Con Lending Jsy B'layloan +", '232445', ''],
    ['PREPAY TECHNOLOGIES LTD Prepay Technologies Ltd', '232507', ''],
    ['BARCLAYS BANK PLC Con Lending Jsy Resolve', '232571', ''],
    ['BARCLAYS BANK PLC Con Lending Jsy Per Loan', '232636', ''],
    ['BARCLAYS BANK UK PLC Retail Loan 2704', '232704', ''],
    ['BARCLAYS BANK PLC Con Lending Jsy Withdrawn', '232725', ''],
    [
      'BANK OF THE PHILIPPINE ISLANDS (EUROPE) PLC Threadneedle St London',
      '232732',
      ''
    ],
    ['BARCLAYS BANK PLC Con Lending Jsy Br Advance', '232813', ''],
    ['THE NORTHERN TRUST COMPANY Chaps Returns', '232862', 'CNORGB22RTN'],
    [
      'THE NORTHERN TRUST COMPANY The Northern Trust Company',
      '232863',
      'CNORGB22XXX'
    ],
    [
      'NORTHERN TRUST GLOBAL SERVICES SE Northern Trust Global',
      '232866',
      'CNORGB2XXXX'
    ],
    [
      "Her Majesty's Revenue and Customs Hmrc Non Vat Payable Orders",
      '232916',
      ''
    ],
    ['BARCLAYS BANK PLC Con Lending Gsy Barclayloan', '232939', ''],
    ['BANK OF AMERICA, NA Bofa Austria Iban', '232975', ''],
    ["BARCLAYS BANK PLC Con Lending Gsy B'layloan+", '233080', ''],
    ['GLOBAL REACH PARTNERS LTD Global Reach Partners Ltd', '233142', ''],
    ['BARCLAYS BANK PLC Con Lending Gsy Resolve', '233171', ''],
    ['BARCLAYS BANK UK PLC Con Ldg UK R Cdl Lsc Int Py', '233188', ''],
    ['BARCLAYS BANK PLC Con Lending Gsy Per Loan', '233231', ''],
    ['PAYRNET LIMITED Pockit', '233272', ''],
    ['ALDERMORE BANK PLC Aldermore Bank plc', '233343', ''],
    ['BARCLAYS BANK PLC Con Lending Gsy Withdrawn', '233344', ''],
    ['BANK OF AMERICA, NA Bofa Portugal Iban', '233420', ''],
    ['BARCLAYS BANK PLC Con Lending Gsy Advance', '233438', ''],
    ['SECURE TRUST BANK PLC 1 Arleston Way, Solihull', '233483', ''],
    ['BARCLAYS BANK PLC Barclays Prvte Clients Intl', '233491', ''],
    ['UNITED OVERSEAS BANK LTD London', '233504', 'UOVBGB2LXXX'],
    ['BARCLAYS BANK PLC Con Lending Gsy Withdrawn', '233525', ''],
    ['BARCLAYS BANK PLC Con Lending Iom Barclayloan', '233556', ''],
    ['SOCIETE GENERALE Sg Securities', '233604', 'SOGEGB22XXX'],
    ['EFG PRIVATE BANK LTD Guernsey St Peter Port', '233673', 'EFGBGGSPXXX'],
    ["BARCLAYS BANK PLC Con Lending Iom B'layloan+", '233693', ''],
    ['BARCLAYS BANK PLC Con Lending Iom Resolve', '233752', ''],
    ['ING BANK NV Ing Bank Nv Amsterdam', '233789', 'INGBNL2AXXX'],
    ['BANK OF AMERICA, NA Alconbury', '234079', ''],
    ['BARCLAYS BANK PLC Con Lending Iom Per Loan', '234081', ''],
    ['BARCLAYS BANK PLC Con Lending Iom Withdrawn', '234193', ''],
    ['DEUTSCHE BANK AG Deutsche Bank Ag London Br', '234238', ''],
    ['METRO BANK PLC Metro (Bacs)', '234245', ''],
    ['BARCLAYS BANK PLC Con Lending Iom Advance', '234252', ''],
    ['SOCIETE GENERALE Sgoe', '234329', 'SGOEFRPPXXX'],
    ['BARCLAYS BANK PLC Con Lending Jer Barclayloan', '234377', ''],
    ['IPSWICH BUILDING SOCIETY Freehold House Ipswich', '234448', ''],
    ['BNP PARIBAS S.A. JERSEY BRANCH St.Helier,Jersey', '234504', ''],
    ["BARCLAYS BANK PLC Con Lending Jer B'layloan+", '234570', ''],
    ['BNP PARIBAS London Branch (Sterling)', '234635', 'BNPAGB22XXX'],
    ['BARCLAYS BANK PLC Con Lending Jer Resolve', '234666', ''],
    ['BARCLAYS BANK PLC Camelot Cheques', '234701', ''],
    [
      'BRITISH ARAB COMMERCIAL BANK LTD British Arab Commercial Bk',
      '234712',
      ''
    ],
    ['BANCO BILBAO-VIZCAYA ARGENTARIA SA Canary Wharf N.2', '234736', ''],
    ['BARCLAYS BANK PLC Con Lending Jer Per Loan', '234779', ''],
    ['BARCLAYS BANK PLC Con Lending Jer Withdrawn', '234828', ''],
    ['BARCLAYS BANK UK PLC Con Ldg UK R Reconciliation', '234985', ''],
    ['BARCLAYS BANK PLC Con Lending Jer Advance', '235054', ''],
    ['BARCLAYS BANK PLC Con Lending Gsy Barclayloan', '235164', ''],
    ['CUMBERLAND BUILDING SOCIETY Cumberland Building Society', '235221', ''],
    ['PAYPAL (EUROPE) SARL ET CIE SCA Paypal', '235262', ''],
    ["BARCLAYS BANK PLC Con Lending Gsy B'layloan +", '235323', ''],
    ["Her Majesty's Revenue and Customs Hmrc Payable Orders", '235358', ''],
    ['SOCIETE GENERALE Sg Funds Return', '235376', 'SOGEFRPPRTN'],
    ['BARCLAYS BANK PLC Post Office Limited', '235389', ''],
    ['COMMERZBANK TRADING AS DRESDNER BANK London', '235405', ''],
    [
      "BANK OF THE PHILIPPINE ISLANDS (EUROPE) PLC Earl's Court",
      '235441',
      'BOPIGB2LXXX'
    ],
    ['BARCLAYS BANK PLC Con Lending Gsy Resolve', '235459', ''],
    ['BARCLAYS BANK PLC Con Lending Gsy Per Loan', '235519', ''],
    [
      'THE BANK OF NEW YORK MELLON INTERNATIONAL LTD Global Payments & Tsy Svs',
      '235555',
      ''
    ],
    [
      'MARKS AND SPENCER FINANCIAL SERVICES PLC Marks and Spencer Financial',
      '235614',
      ''
    ],
    [
      'UNION BANK OF INDIA (UK) LIMITED Union Bank of India',
      '235626',
      'UBINGB2LXXX'
    ],
    ['BARCLAYS BANK PLC Con Lending Gsy Withdrawn', '235676', ''],
    ['MERCANTILE CREDIT COMPANY LIMITED Great Queen Street', '235714', ''],
    ['BARCLAYS BANK PLC Con Lending Gsy Advance', '235756', ''],
    ['QATAR NATIONAL BANK (Q.P.S.C.) Qatar National Bank', '235785', ''],
    ['UNION BANK UK PLC Union Bank UK plc', '235797', 'UBNIGB2LXXX'],
    ['EMIRATES NBD PJSC Knightsbridge', '235863', 'EBILGB2LXXX'],
    ['IPAGOO LLP Orwel Union Partners LLP', '235889', 'ORUNGB3LXXX'],
    ['DEUTSCHE BANK AG Db Mortgages', '235906', ''],
    [
      'BANCO BILBAO-VIZCAYA ARGENTARIA SA Canary Wharf N.1',
      '235911',
      'BBVAGB2LXXX'
    ],
    ['DB UK BANK LIMITED Db UK Bank Limited', '235940', ''],
    ['BARCLAYS BANK PLC Con Lending Iom Barclayloan', '235945', ''],
    ['NEWCASTLE BUILDING SOCIETY Newcastle upon Tyne', '235954', ''],
    ['BANCO BILBAO-VIZCAYA ARGENTARIA SA Canary Wharf', '236001', ''],
    ["BARCLAYS BANK PLC Con Lending Iom B'layloan +", '236006', ''],
    ['BANK OF AMERICA, NA Bofa Finland Iban', '236069', ''],
    ['AXIS BANK UK LTD Axis Bank UK Ltd', '236078', 'AXISGB2LXXX'],
    ['DEUTSCHE BANK AG London 3', '236083', ''],
    ['BARCLAYS BANK PLC Con Lending Iom Resolve', '236119', ''],
    ['BARCLAYS BANK PLC Barclaycard 6136', '236136', ''],
    ['BARCLAYS BANK PLC Con Lending Iom Pers Loan', '236233', ''],
    ['HAMPSHIRE TRUST BANK PLC Hampshire Trust Bank plc', '236247', ''],
    ['BARCLAYS BANK UK PLC Retail Loan 6293', '236293', ''],
    ['SOCIETE GENERALE Global Transaction Banking', '236391', 'SOGEGB2LLON'],
    ['BARCLAYS BANK PLC Con Lending Iom Withdrawn', '236422', ''],
    ['BARCLAYS BANK PLC Con Lending Iom Advance', '236527', ''],
    ['SWANSEA BUILDING SOCIETY Swansea Bldg Soc', '236532', ''],
    ['BARCLAYS BANK UK PLC Business Lending 6538', '236538', ''],
    ['SOCIETE GENERALE Sg Capital Market', '236553', 'SOGEGB44XXX'],
    ["BARCLAYS BANK UK PLC Consumer Lend'g Kibc B'loan", '236643', ''],
    ["BARCLAYS BANK UK PLC Con Lend'g Kibc B'loan Plus", '236761', ''],
    ['YORKSHIRE BUILDING SOCIETY Norwich and Peterborough', '236799', ''],
    ['STRIPE PAYMENTS UK LTD Stripe Payments UK Ltd', '236802', ''],
    ['WORLD FIRST UK LTD World First UK Ltd', '236824', ''],
    ['WORLD FIRST UK LTD World First UK Ltd', '236825', ''],
    ['PROGRESSIVE BUILDING SOCIETY Belfast Treasury', '236838', ''],
    ['BARCLAYS BANK UK PLC Con Lending Kibc Resolve', '236907', ''],
    ['PREPAY TECHNOLOGIES LTD Prepay Technologies Ltd', '236972', ''],
    ['EFG PRIVATE BANK LTD London', '237000', 'EFGBGB2LXXX'],
    ["BARCLAYS BANK UK PLC Con Lend'g Kibc Bpl", '237130', ''],
    ["BARCLAYS BANK UK PLC Con Lending Kibc W'drawn PR", '237265', ''],
    ['SOCIETE GENERALE Sg Head of Capital Market', '237345', 'SOGEFRPPHCM'],
    ['BARCLAYS BANK UK PLC Con Lending Kibc Advance', '237355', ''],
    ["Her Majesty's Revenue and Customs Hmrc Resource Payments", '237389', ''],
    ['BANK OF AMERICA, NA Bofa Slovakia Iban', '237413', ''],
    ['BARCLAYS BANK UK PLC Business Lending 7423', '237423', ''],
    ["BARCLAYS BANK UK PLC Con Lending Bpb B'loan", '237427', ''],
    ['PROGRESSIVE BUILDING SOCIETY Belfast Retail', '237448', ''],
    ['DEUTSCHE BANK AG P R S', '237470', ''],
    ['PREPAID FINANCIAL SERVICES LTD Prepaid Financial', '237524', ''],
    ["BARCLAYS BANK UK PLC Con Lending Bpb B'loan Plus", '237563', ''],
    ["BARCLAYS BANK UK PLC Con Lending Bpb B'psnl Loan", '237622', ''],
    ['BARCLAYS BANK PLC Post Office Limited', '237715', ''],
    ['ALLPAY LIMITED Allpay', '237724', ''],
    ["BARCLAYS BANK UK PLC Con Lending Bpb W'drawn Pro", '237728', ''],
    ['SKIPTON BUILDING SOCIETY Principal Office', '237844', ''],
    ["BARCLAYS BANK PLC Con Lending Bpb Ci B'loan", '237873', ''],
    ['BNP PARIBAS Bnp Paribas', '237877', 'BNPAGB22RTN'],
    ['BARCLAYS BANK UK PLC Business Lending 8020', '238020', ''],
    ["BARCLAYS BANK PLC Con Lending Bpb Ci B'loan +", '238043', ''],
    ['BARCLAYS BANK PLC Con Lending Bpb Ci Resolve', '238051', ''],
    ['BARCLAYS BANK PLC Con Lending Bpb Ci Per Loan', '238175', ''],
    ["BARCLAYS BANK PLC Con Lending Bpb Ci W'drawn", '238257', ''],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Retail Banking Operations',
      '238392',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Birmingham Business Centre',
      '238393',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) West London Business Centre',
      '238394',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) North London Business Cent',
      '238395',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Manchester Business Centre',
      '238396',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Mayfair Business Centre',
      '238397',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) North London Business Cent',
      '238398',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Birmingham Business Centre',
      '238399',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) West London Business Centre',
      '238400',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Birmingham Business Centre',
      '238401',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Leeds Business Office',
      '238402',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Liverpool Business Office',
      '238403',
      ''
    ],
    ["BARCLAYS BANK PLC Con Lending Bpb Iom B'loan", '238432', ''],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Bristol Business Office',
      '238480',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Mayfair Business Centre',
      '238481',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) City Business Centre',
      '238482',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Nottingham Business Office',
      '238483',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Mayfair Business Office',
      '238484',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) West London Business Centre',
      '238485',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Mayfair Business Office',
      '238486',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Birmingham Business Centre',
      '238487',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) North London Business Cent',
      '238488',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Nottingham Business Office',
      '238489',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) City Business Centre',
      '238581',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Birmingham Business Centre',
      '238582',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) West London Business Centre',
      '238583',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Birmingham Business Centre',
      '238585',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Cardiff Business Centre',
      '238586',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Nottingham Business Office',
      '238587',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Staff Business Unit',
      '238588',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Mayfair Business Centre',
      '238589',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) International Operations',
      '238590',
      ''
    ],
    ["BARCLAYS BANK PLC Con Lending Bpb Iom B'loan+", '238599', ''],
    ['BARCLAYS BANK PLC Con Lending Bpb Iom Resolve', '238613', ''],
    ["BARCLAYS BANK PLC Con Lending Bpb Iom P'loan", '238672', ''],
    ["BARCLAYS BANK PLC Con Lending Bpb Iom W'drawn", '238717', ''],
    ['ING BANK NV London Pcm', '238859', 'INGBGB22XXX'],
    ['BARCLAYS BANK UK PLC Mortgage Account 8890', '238890', ''],
    ['BARCLAYS BANK UK PLC Mortgage Account 8891', '238891', ''],
    ['BARCLAYS BANK UK PLC Mortgage Account 8892', '238892', ''],
    ['BARCLAYS BANK UK PLC Mortgage Account 8893', '238893', ''],
    ['BARCLAYS BANK UK PLC Mortgage Account 8894', '238894', ''],
    ['BARCLAYS BANK UK PLC Mortgage Account 8895', '238895', ''],
    ['BARCLAYS BANK UK PLC Mortgage Account 8896', '238896', ''],
    ['BARCLAYS BANK UK PLC Mortgage Account 8997', '238897', ''],
    ['BARCLAYS BANK UK PLC Mortgage Account 8898', '238898', ''],
    ['BARCLAYS BANK UK PLC Mortgage Account 8899', '238899', ''],
    ['BARCLAYS BANK PLC Con Lending Gib Barclayloan', '238908', ''],
    ["BARCLAYS BANK PLC Con Lending Gib B'layloan +", '239071', ''],
    ['BARCLAYS BANK PLC Con Lending Gib Per Loan', '239126', ''],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Direct Banking',
      '239136',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Direct Banking',
      '239138',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Mayfair Business Centre',
      '239139',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Nama Operations',
      '239140',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Aib Savings Direct',
      '239141',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Financial Solutions Group',
      '239142',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Banking Support Services',
      '239143',
      ''
    ],
    ['AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Euro Branch', '239144', ''],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Corporate Banking Uxbridge',
      '239145',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Aib Savings Direct',
      '239281',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Newcastle Business Centre',
      '239282',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Customer Treasury Services',
      '239285',
      'AIBKGB2LXXX'
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) North London Business Cent',
      '239286',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Central Deposits',
      '239287',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Home Mortgage',
      '239288',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Business Services Centre',
      '239289',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) West London Business Centre',
      '239290',
      ''
    ],
    ['BARCLAYS BANK PLC Con Lending Gib Barclayloan', '239295', ''],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Visa Centre Britain',
      '239318',
      ''
    ],
    ['BARCLAYS BANK UK PLC Business Lending 9360', '239360', ''],
    ["BARCLAYS BANK PLC Con Lending Gib B'layloan+", '239380', ''],
    ['BARCLAYS BANK PLC Con Lending Gib Per Loan', '239435', ''],
    ['BARCLAYS BANK PLC Con Lending Cyp Barclayloan', '239525', ''],
    ["BARCLAYS BANK PLC Con Lending Cyp B'layloan+", '239642', ''],
    ['BANK OF AMERICA, NA Bofa Luxembourg Iban', '239692', ''],
    ['BARCLAYS BANK PLC Con Lending Cyp Per Loan', '239751', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250083', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250115', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250140', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250144', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250173', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250179', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250195', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250227', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250244', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250276', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250282', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250284', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250292', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250322', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250330', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250349', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250357', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250373', ''],
    ['BARCLAYS BANK UK PLC Head Office Collection A/C', '250398', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250420', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250477', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250540', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250553', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250554', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250566', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250579', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250635', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250639', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250642', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250673', ''],
    ['BARCLAYS BANK PLC H O Collection Account', '250674', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250677', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250698', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250701', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250764', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250776', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250788', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250812', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250868', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250876', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250982', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '250988', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251015', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251019', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251027', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251050', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251051', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251109', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251132', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251141', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251142', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251168', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251256', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251272', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251277', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251304', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251353', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251397', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251399', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251418', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251422', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251448', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251521', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251531', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251547', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251578', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251619', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251629', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251632', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251647', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251663', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251690', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251728', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251783', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251875', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '251906', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252038', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252077', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252086', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252133', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252145', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252248', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252550', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252627', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252723', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252886', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '252957', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253016', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253167', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253194', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253335', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253424', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253503', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253563', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253611', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253701', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '253838', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254212', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254268', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254415', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254465', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254560', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254561', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254580', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254608', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254624', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254633', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254634', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254642', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254668', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254716', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254819', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254962', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254967', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254978', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '254989', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255050', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255150', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255157', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255170', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255177', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255206', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255355', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255392', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255395', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255427', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255445', ''],
    ['BARCLAYS BANK PLC H O Collection Account', '255595', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255651', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255671', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255778', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255820', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255849', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255917', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '255983', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256061', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256085', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256121', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256186', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256322', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256353', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256373', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256421', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256482', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256602', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256658', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256910', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '256960', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257018', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257025', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257056', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257069', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257166', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257181', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257263', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257300', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257344', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257513', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257568', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257569', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257700', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257703', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257800', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257859', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257860', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257882', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '257920', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258008', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258290', ''],
    ['BARCLAYS BANK UK PLC Head Office Collection A/C', '258298', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258349', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258409', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258424', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258656', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258734', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258749', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258818', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '258867', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '259011', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '259047', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '259151', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '259201', ''],
    ['BARCLAYS BANK UK PLC Head Office Collection A/C', '259250', ''],
    ['BARCLAYS BANK PLC Head Office Collection A/C', '259678', ''],
    ['BARCLAYS BANK PLC Head Office', '279800', ''],
    ['BARCLAYS BANK PLC Head Office', '279900', ''],
    ['BARCLAYS BANK PLC Head Office', '279901', ''],
    ['BARCLAYS BANK UK PLC Head Office', '279902', ''],
    ['BARCLAYS BANK PLC Head Office', '279905', ''],
    ['LLOYDS BANK PLC Head Office', '300000', ''],
    ['LLOYDS BANK PLC Bristol City Ctr (300001)', '300001', 'LOYDGB21017'],
    ['LLOYDS BANK PLC Payments Ops North', '300002', 'LOYDGB2LCTY'],
    ['LLOYDS BANK PLC New St Birmingham', '300003', 'LOYDGB21003'],
    ['LLOYDS BANK PLC Holborn Circus', '300004', 'LOYDGB21019'],
    ['LLOYDS BANK PLC Leeds City Centre(300005)', '300005', 'LOYDGB21007'],
    ['LLOYDS BANK PLC New St Birmingham', '300006', 'LOYDGB21044'],
    ['LLOYDS BANK PLC Piccadilly (300008)', '300008', 'LOYDGB21012'],
    ['LLOYDS BANK PLC Threadneedle St (300009)', '300009', 'LOYDGB21013'],
    ['BANK LEUMI (UK) PLC Bank Leumi Uk-Manchester', '300027', ''],
    ['DEUTSCHE BANK AG Deutsche Bank Ag Dcc.', '300030', 'DEUTGB22DCC'],
    ['Wesleyan Bank Limited Wesleyan Bank Ltd', '300034', ''],
    ['CITIBANK UK LIMITED St Martins House', '300045', ''],
    ['LLOYDS BANK PLC Caf Branch', '300046', ''],
    ['LLOYDS BANK PLC City Office - Cms - Dbs', '300048', ''],
    ['LLOYDS BANK PLC City Office - Cms - Occ', '300049', ''],
    ['BANK OF IRELAND (UK) PLC South Kensington', '300050', ''],
    ['BANK OF IRELAND (UK) PLC Piccadilly', '300051', ''],
    ['DEUTSCHE BANK AG London Bacs', '300055', ''],
    ['DEUTSCHE BANK AG London Overseas Branches', '300056', ''],
    ['DEUTSCHE BANK AG London Banking Subsidiaries', '300057', ''],
    ['DEUTSCHE BANK AG London UK Oversea Customers', '300058', ''],
    ['NRAM LIMITED Financial Operations', '300059', 'NROKGB2LXXX'],
    [
      'ARBUTHNOT LATHAM AND CO LTD Arbuthnot Latham & Co Ltd',
      '300066',
      'ARBUGB2LAD1'
    ],
    ['LLOYDS BANK PLC City Office - Cms - Hil', '300069', ''],
    ['BANK OF EAST ASIA LTD Manchester Branch', '300076', ''],
    ['LLOYDS BANK PLC City Office-Cms-Eagle Star', '300078', ''],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Virgin Money (Lgs)',
      '300079',
      'NRNBGB2LLGS'
    ],
    ['LOUGHBOROUGH BUILDING SOCIETY Loughborough', '300080', ''],
    ['ICICI BANK UK PLC Knightsbridge Branch', '300081', ''],
    ['AL RAYAN BANK PLC Op Headquarters', '300083', 'ARAYGB22XXX'],
    ['CITIBANK NA JERSEY BRANCH Citi International Personal', '300084', ''],
    ['ICICI BANK UK PLC Birmingham Branch', '300085', ''],
    ['ICICI BANK UK PLC East Ham Branch', '300087', ''],
    ['ICICI BANK UK PLC Coventry Branch', '300088', ''],
    ['ICICI BANK UK PLC Leeds Branch', '300089', ''],
    ['LLOYDS BANK PLC City Office-Cms-Bbc', '300090', ''],
    ['LLOYDS BANK PLC City Office - Cms - Stm', '300091', ''],
    ['LLOYDS BANK PLC City Office -Cms-Wbs', '300092', ''],
    ['LLOYDS BANK PLC City Office-Cms Cgu Ins', '300093', ''],
    ['LLOYDS BANK PLC Treasury Direct', '300094', ''],
    ['BANK OF EAST ASIA LTD Birmingham Branch', '300097', ''],
    ['BANK OF EAST ASIA LTD London', '300098', 'BEASGB2LXXX'],
    ['LLOYDS BANK PLC City Office-Cms-Sterling', '300101', ''],
    ['BANK OF IRELAND (UK) PLC Bristol', '300103', ''],
    ['LLOYDS BANK PLC Loyd PR Ra Ch Halifax McArd', '300106', ''],
    ['LLOYDS BANK PLC Loyd PR Ra Ch H/Fax Student', '300107', ''],
    ['LLOYDS BANK PLC Loyd PR Ra Ch H/Fax Standar', '300108', ''],
    ['LLOYDS BANK PLC Loyd PR Ra Ch Cancer Res UK', '300109', ''],
    ['LLOYDS BANK PLC Loyd Promo Rate Ch Nspcc', '300110', ''],
    ['LLOYDS BANK PLC Loyd PR Ra Ch Bos Platinum', '300111', ''],
    ['LLOYDS BANK PLC Loyd PR Ra Ch Bos Student', '300112', ''],
    ['LLOYDS BANK PLC Loyd PR Ra Ch Bos Classic', '300113', ''],
    ['BANK OF IRELAND (UK) PLC Bow Bells House', '300114', ''],
    ['LLOYDS BANK PLC City Office Cms Adap', '300118', ''],
    ['HANLEY ECONOMIC BUILDING SOCIETY Hanley', '300120', ''],
    ['ICICI BANK UK PLC Leicester Branch', '300125', ''],
    ['ICICI BANK UK PLC Manchester Branch', '300127', ''],
    ['ICICI BANK UK PLC Southall Branch', '300128', ''],
    ['ICICI BANK UK PLC Wembley Branch', '300129', ''],
    ['LLOYDS BANK PLC Cr Cqs Trustcard Classic Mc', '300134', ''],
    ['LLOYDS BANK PLC Cr Cqs Amex', '300135', ''],
    ['LLOYDS BANK PLC Cr Cqs Platinum Mastercard', '300136', ''],
    ['LLOYDS BANK PLC Cr Chqs Matercard Gold', '300137', ''],
    ['LLOYDS BANK PLC Cr Chqs Business Banking Cc', '300138', ''],
    [
      'BANK OF LONDON AND THE MIDDLE EAST PLC Bk Ldn & Midd East plc Ldn',
      '300140',
      ''
    ],
    ['ICICI BANK UK PLC Slough Branch', '300142', ''],
    ['ICICI BANK UK PLC Harrow Branch', '300143', ''],
    ['ICICI BANK UK PLC Tower Hill Branch', '300144', ''],
    ['UNICREDIT S.P.A London Branch', '300146', ''],
    [
      'STATE STREET BANK AND TRUST COMPANY State St Bk & Tr Co',
      '300147',
      'SBOSGB2XRTN'
    ],
    ['LLOYDS BANK PLC Cr Chqs Asset Gold Visa', '300150', ''],
    ['UNITED TRUST BANK LIMITED United Trust Bank Ltd', '300151', ''],
    ['LLOYDS BANK PLC Cr Chqs Asset McArd', '300152', ''],
    ['LLOYDS BANK PLC Cr Chqs Asset Advance', '300153', ''],
    ['LLOYDS BANK PLC Cr Chqs Asset Visa', '300154', ''],
    ['LLOYDS BANK PLC Cr Chqs Asset Gold McArd', '300155', ''],
    ['LLOYDS BANK PLC Cr Chqs Classic Reserve', '300156', ''],
    ['LLOYDS BANK PLC Cr Chqs Asset Platinum Visa', '300157', ''],
    ['LLOYDS BANK PLC Cr Chqs Scottish Widows Std', '300158', ''],
    ['LLOYDS BANK PLC Cr Chqs Create', '300159', ''],
    ['LLOYDS BANK PLC Cr Promotional Cq Gold Visa', '300160', ''],
    ['LLOYDS BANK PLC Cr Promotional Cq Asset M/C', '300162', ''],
    ['LLOYDS BANK PLC Cr Promotional Cq Asset Adv', '300163', ''],
    ['LLOYDS BANK PLC Cr Promo Classic Visa', '300164', ''],
    ['LLOYDS BANK PLC Cr Promotional Cq Gold M/C', '300165', ''],
    ['LLOYDS BANK PLC Cr Promo Classic Reserve 1', '300166', ''],
    ['LLOYDS BANK PLC Cr Promo Platinum Visa', '300167', ''],
    ['LLOYDS BANK PLC Cr Promotional Cq S Widows', '300168', ''],
    ['LLOYDS BANK PLC Cr Promotional Cq Create', '300169', ''],
    ['LLOYDS BANK PLC Treasury Linked Products', '300170', ''],
    ['T C ZIRAAT BANKASI AS T C Ziraat Bankasi', '300173', 'TCZBGB2LXXX'],
    ['SANTANDER CARDS UK LIMITED Ge Capital Bk Ret Fin Serv', '300174', ''],
    [
      'LLOYDS BANK (INTERNATIONAL SERVICES) LIMITED Lloyds Bank Int 300175',
      '300175',
      'LOYDJE32XXX'
    ],
    ['LLOYDS BANK PLC Cr Cheques C&G Platinum M/C', '300178', ''],
    ['LLOYDS BANK PLC Cr Promo Chq C&G Plat M/C', '300179', ''],
    ['LLOYDS BANK PLC Cr Promo Chq Trust Card', '300180', ''],
    ['LLOYDS BANK PLC Cr Promo Chq C&G Plat M/C', '300181', ''],
    ['LLOYDS BANK PLC Cr Promo Platinum M/Card', '300182', ''],
    ['LLOYDS BANK PLC Cr Promo Premier M/Card', '300183', ''],
    ['LLOYDS BANK PLC Cr Promo Create Premier', '300184', ''],
    ['LLOYDS BANK PLC Cr Promo Airmiles Duo', '300185', ''],
    ['LLOYDS BANK PLC Cr Chqs Trustcard Plat M/C', '300186', ''],
    ['LLOYDS BANK PLC Cr Chqs Loyd Platinum M/C', '300187', ''],
    ['LLOYDS BANK PLC Cr Promo Create Premier 1', '300188', ''],
    ['LLOYDS BANK PLC Cr Chqs Loyd/Bmi No.2', '300189', ''],
    ['SANTANDER CARDS UK LIMITED G.E Capital Bank Consumer', '300190', ''],
    ['INVESTEC BANK PLC Investec Bank plc', '300195', ''],
    ['CIMB BANK BERHAD Knightsbridge No2', '300196', ''],
    ['MIRABAUD & CIE (EUROPE) S.A. UK Branch', '300197', 'MIRAGB4LXXX'],
    ['STANDARD BANK JERSEY LIMITED Jersey', '300198', ''],
    ['STANDARD BANK ISLE OF MAN LTD Isle of Man', '300199', ''],
    ['LLOYDS BANK PLC Cr Promo Trustcard Plat M/C', '300217', ''],
    ['LLOYDS BANK PLC Cr Promo Student Mastercard', '300218', ''],
    ['LLOYDS BANK PLC Cr Promo Airmiles Duo McArd', '300219', ''],
    ['LLOYDS BANK PLC Cr Promo Vantage Visa', '300220', ''],
    ['LLOYDS BANK PLC Cr Promo Classic Reserve 2', '300221', ''],
    ['LLOYDS BANK PLC Cr Promo Non-Uk Classic M/C', '300222', ''],
    ['LLOYDS BANK PLC Cr Promo Airmiles Prem Duo', '300223', ''],
    ['LLOYDS BANK PLC Cr Promo Private', '300224', ''],
    ['LLOYDS BANK PLC Cr Promo Airmiles Duo Prem', '300225', ''],
    ['BANK OF AMERICA, NA Bank of America N.A. Mtbs', '300231', 'BOFAGB22VAM'],
    ['LLOYDS BANK PLC Cr Rate Air Duo Amex 0232', '300232', ''],
    ['LLOYDS BANK PLC Cr Rate Air PR Duo Aex 0233', '300233', ''],
    ['LLOYDS BANK PLC Cr Rate Private 0234', '300234', ''],
    ['LLOYDS BANK PLC Cr Rate Premier M/Card 0235', '300235', ''],
    ['LLOYDS BANK PLC Cr Rate Classic Master 0236', '300236', ''],
    ['LLOYDS BANK PLC Cr Rate Non-Uk Clas Mc 0237', '300237', ''],
    ['LLOYDS BANK PLC Cr Rate Classic Reserv 0238', '300238', ''],
    ['LLOYDS BANK PLC Cr Rate Trustcard M/C 0239', '300239', ''],
    ['LLOYDS BANK PLC Cr Rate Tcard Plat Mc 0240', '300240', ''],
    ['LLOYDS BANK PLC Cr Rate Tcard Class Mc 0241', '300241', ''],
    ['LLOYDS BANK PLC Cr Rate Student M/Card 0242', '300242', ''],
    ['LLOYDS BANK PLC Cr Rate Create Premier 0243', '300243', ''],
    ['LLOYDS BANK PLC Cr Rate Create Premier 0244', '300244', ''],
    ['LLOYDS BANK PLC Cr Rate Airmile Duo Mc 0245', '300245', ''],
    ['LLOYDS BANK PLC Cr Rate Air Duo Pre Mc 0246', '300246', ''],
    ['FINECOBANK SPA Finecobank Spa', '300248', ''],
    [
      'BANK OF SCOTLAND TRADING AS BIRMINGHAM MIDSHIRES Bmm',
      '300250',
      'LOYDGB21F78'
    ],
    [
      'BANK OF SCOTLAND TRADING AS BIRMINGHAM MIDSHIRES Bms',
      '300251',
      'LOYDGB21F79'
    ],
    [
      'BANK OF SCOTLAND TRADING AS BIRMINGHAM MIDSHIRES Bmwl',
      '300252',
      'LOYDGB21F80'
    ],
    ['ARBUTHNOT LATHAM AND CO LTD Arbuthnot Latham Euro Only', '300308', ''],
    ['BANK OF AMERICA NA Bromley Kent', '300316', ''],
    [
      'BANK OF LONDON AND THE MIDDLE EAST PLC Bk Ldn & Midd East plc Ldn',
      '300333',
      ''
    ],
    ['UNICREDIT S.P.A London Wall (Euro Only)', '300354', ''],
    ['UNICREDIT BANK AG Moorgate (Euro Only)', '300363', 'HYVEGB2LXXX'],
    ['DANSKE BANK A/S London', '300381', ''],
    ['TSB BANK PLC TSB St Nicholas Street', '301001', ''],
    ['LLOYDS BANK PLC Ock St Abingdon (301004)', '301004', 'LOYDGB21305'],
    ['LLOYDS BANK PLC Card & Loan Services, Fssc', '301007', 'LOYDGB21134'],
    ['TSB BANK PLC Albrighton', '301012', ''],
    ['LLOYDS BANK PLC Amex T/Chq', '301015', ''],
    ['BANK OF IRELAND (UK) PLC Harrow', '301022', ''],
    ['BANK OF IRELAND (UK) PLC Direct Personal Banking', '301027', ''],
    ['LLOYDS BANK PLC Attleborough', '301044', ''],
    ['TSB BANK PLC Barnsley', '301047', ''],
    ['TSB BANK PLC Barton-le-Clay', '301049', ''],
    ['LLOYDS BANK PLC Basildon (301052)', '301052', 'LOYDGB21486'],
    ['LLOYDS BANK PLC Cheltenham Bath Rd', '301055', ''],
    ['BAYERISCHE LANDESBANK Moor House Ec2', '301058', 'BYLAGB22XXX'],
    ['UNICREDIT BANK AG London Branch', '301061', ''],
    ['LLOYDS BANK PLC Nuneaton (301064)', '301064', ''],
    ['LLOYDS BANK PLC Bexleyheath (301074)', '301074', ''],
    ['TSB BANK PLC Benfleet', '301075', ''],
    ['TSB BANK PLC Beverley', '301076', ''],
    ['LLOYDS BANK PLC Alcester (301078)', '301078', ''],
    ['LLOYDS BANK PLC Intl Sers Cen Bham (301087)', '301087', ''],
    ['LLOYDS BANK PLC Bristol Bishopsworth', '301091', ''],
    ['LLOYDS BANK PLC Chester-le-Street 301092', '301092', ''],
    ['LLOYDS BANK PLC Borehamwood', '301096', ''],
    ['LLOYDS BANK PLC Brackley (301108)', '301108', 'LOYDGB21450'],
    ['LLOYDS BANK PLC Ipswich Bramford Rd', '301110', ''],
    ['TSB BANK PLC Bridlington', '301112', ''],
    [
      'AUSTRALIA & NEW ZEALAND BANKING GROUP LTD Anz Banking Group',
      '301114',
      ''
    ],
    ['LLOYDS BANK PLC Gloucester Bristol Rd', '301122', ''],
    ['LLOYDS BANK PLC Carfax Oxford (301127)', '301127', 'LOYDGB21194'],
    ['LLOYDS BANK PLC Brockworth', '301129', ''],
    ['LLOYDS BANK PLC Bromyard', '301132', ''],
    ['ERSTE GROUP BANK AG Bishopsgate', '301139', 'GIBAGB2XXXX'],
    ['BANK OF IRELAND (UK) PLC Leeds', '301142', ''],
    [
      'PHILIPPINE NATIONAL BANK (EUROPE) PLC Philippine Nat Bk (Eu) plc',
      '301143',
      ''
    ],
    ['LLOYDS BANK PLC Burnley (301148)', '301148', 'LOYDGB21325'],
    ['LLOYDS BANK PLC Llanelli - Mobile Branch', '301150', ''],
    ['BANK OF IRELAND (UK) PLC South Region', '301154', ''],
    ['BANK OF IRELAND (UK) PLC Kingston upon Thames', '301155', ''],
    ['LLOYDS BANK PLC Caldicot', '301159', ''],
    ['LLOYDS BANK PLC Callington', '301160', ''],
    ['BANK OF IRELAND (UK) PLC Hounslow', '301166', ''],
    ['BANK OF IRELAND (UK) PLC Finchley', '301170', ''],
    ['LLOYDS BANK PLC High St Poole (301172)', '301172', ''],
    ['TSB BANK PLC Canvey Island', '301174', ''],
    ["LLOYDS BANK PLC Cox's and King's", '301175', 'LOYDGB21196'],
    ['LLOYDS BANK PLC Castle Bromwich', '301180', ''],
    ['LLOYDS BANK PLC Chandlers Ford', '301186', ''],
    ['LLOYDS BANK PLC Cedar Holdings', '301194', ''],
    ['LLOYDS BANK PLC Chaps Central Control', '301195', ''],
    ['LLOYDS BANK PLC Yate (301204)', '301204', 'LOYDGB21420'],
    ['LLOYDS BANK PLC Copy Statement Unit', '301209', ''],
    ['LLOYDS BANK PLC Chesham (301210)', '301210', 'LOYDGB21575'],
    ['LLOYDS BANK PLC Black Horse House 1215', '301215', 'LOYDGB21335'],
    ['LLOYDS BANK PLC Trade Services 301218', '301218', ''],
    ['LLOYDS BANK PLC Cleobury Mortimer (301220)', '301220', 'LOYDGB21640'],
    ['TSB BANK PLC Cockfosters', '301234', ''],
    ['COMMERZBANK AG London', '301242', 'COBAGB2XXXX'],
    ['LLOYDS BANK PLC Pontyclun (301248)', '301248', ''],
    ['LLOYDS BANK PLC Corringham', '301249', ''],
    ['LLOYDS BANK PLC Cowley (301251)', '301251', 'LOYDGB21317'],
    ['LLOYDS BANK PLC Cramlington', '301257', ''],
    ['LLOYDS BANK PLC Crewe', '301259', 'LOYDGB21521'],
    ['LLOYDS BANK PLC Royal Par Plymouth 301274', '301274', 'LOYDGB21337'],
    ['DBS BANK LTD Dbs Bank Ltd', '301275', 'DBSSGB2LXXX'],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Villiers House Iom',
      '301280',
      'LOYDIMD1001'
    ],
    ['DANSKE BANK A/S King William Street', '301281', 'DABAGB2LXXX'],
    ['LLOYDS BANK PLC Driffield (301286)', '301286', 'LOYDGB21620'],
    ['LLOYDS BANK PLC East Horsley', '301290', ''],
    ['TSB BANK PLC London, Elephant & Castle', '301295', ''],
    ['LLOYDS BANK PLC Ellesmere Port Marina Dr', '301296', 'LOYDGB21328'],
    ['LLOYDS BANK PLC Broadway Loughton(301298)', '301298', ''],
    ['LLOYDS BANK PLC Payments Ops South', '301299', 'LOYDGB21642'],
    ['TSB BANK PLC Edinburgh, Hanover Street', '301301', ''],
    ['LLOYDS BANK PLC Ams Centre (301305)', '301305', 'LLTPGB21XXX'],
    ['FIRST COMMERCIAL BANK London', '301309', 'FCBKGB2LXXX'],
    ['LLOYDS BANK PLC Banking Management', '301318', ''],
    ['LLOYDS BANK PLC Garston', '301327', ''],
    ['LLOYDS BANK PLC Beaconsfield  (301330)', '301330', 'LOYDGB21358'],
    ['LLOYDS BANK PLC Aylesbury Gatehouse', '301331', ''],
    ['LLOYDS BANK PLC Glossop', '301332', 'LOYDGB21605'],
    ['LLOYDS BANK PLC Brunel Swindon (301335)', '301335', 'LOYDGB21238'],
    ['TSB BANK PLC TSB Reading (301342)', '301342', ''],
    ['TSB BANK PLC Wantage, Grove', '301350', ''],
    ['LLOYDS BANK PLC Hadleigh', '301351', 'LOYDGB21612'],
    ['TSB BANK PLC Ipswich, Hadleigh', '301352', ''],
    ['TSB BANK PLC Glasgow, St. Vincent Street', '301353', ''],
    ['LLOYDS BANK PLC Canary Wharf (301354)', '301354', 'LOYDGB21537'],
    ['LLOYDS BANK PLC University of Cambridge', '301355', 'LOYDGB21035'],
    ['LLOYDS BANK PLC Hailsham', '301356', ''],
    ['LLOYDS BANK PLC Hainault & Chigwell', '301360', ''],
    ['LLOYDS BANK PLC Beccles (301364)', '301364', 'LOYDGB21571'],
    ['LLOYDS BANK PLC Bank St Braintree 301368', '301368', 'LOYDGB21599'],
    ['LLOYDS BANK PLC Kingswood (301371)', '301371', ''],
    ['TSB BANK PLC Harold Hill', '301376', ''],
    ['LLOYDS BANK PLC Hatfield', '301380', 'LOYDGB21527'],
    ['LLOYDS BANK PLC Hazlemere', '301387', ''],
    ['LLOYDS BANK PLC Penzance (301388)', '301388', 'LOYDGB21600'],
    ['LLOYDS BANK PLC Heald Green', '301390', 'LOYDGB21549'],
    ['LLOYDS BANK PLC Hailsham (301392)', '301392', ''],
    [
      'ARBUTHNOT LATHAM AND CO LTD Arbuthnot Latham & Co',
      '301393',
      'ARBUGB2LXXX'
    ],
    ['LLOYDS BANK PLC Hedge End Southampton', '301395', 'LOYDGB21614'],
    ['TSB BANK PLC Archway (301400)', '301400', ''],
    ['BLACK HORSE LIMITED Chartered Trust plc', '301409', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Hill Samuel',
      '301411',
      'HSAMJESHXXX'
    ],
    ['JORDAN INTERNATIONAL BANK PLC Moreau House', '301419', ''],
    ['LLOYDS BANK PLC Holyhead Boston St', '301420', ''],
    ['LLOYDS BANK PLC Hornchurch', '301424', 'LOYDGB21388'],
    ['LLOYDS BANK PLC Leeds City Centre 301426', '301426', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Jersey Centre',
      '301432',
      ''
    ],
    ['BANK OF IRELAND (UK) PLC Birmingham City', '301433', ''],
    ['BANK OF IRELAND (UK) PLC Sutton Coldfield', '301435', ''],
    ['LLOYDS BANK PLC Lpb Operations Centre', '301437', ''],
    ['LLOYDS BANK PLC High St Bedford (301438)', '301438', ''],
    ['BANK OF IRELAND (UK) PLC Birmingham', '301439', ''],
    ['LLOYDS BANK PLC Kendal', '301440', 'LOYDGB21374'],
    ['BANK OF IRELAND (UK) PLC Ilford', '301443', ''],
    ['LLOYDS BANK PLC Penrith (301444)', '301444', 'LOYDGB21631'],
    ['LLOYDS BANK PLC Abington (301447)', '301447', 'LOYDGB21560'],
    ['LLOYDS BANK PLC Kingsbury', '301448', ''],
    ['LLOYDS BANK PLC Kingswinford High St', '301451', 'LOYDGB21370'],
    ['LLOYDS BANK PLC Bournemouth Kinson', '301452', ''],
    ['TSB BANK PLC Pitsea (301456)', '301456', ''],
    ['BANK OF IRELAND (UK) PLC Liverpool', '301458', ''],
    ['LLOYDS BANK PLC Lancaster (301460)', '301460', 'LOYDGB21354'],
    ['LLOYDS BANK PLC Leagrave', '301462', ''],
    ['BANK OF IRELAND (UK) PLC Balham', '301463', ''],
    ['LLOYDS BANK PLC Brunel Centre Swin 301464', '301464', 'LOYDGB21624'],
    ['BANK OF IRELAND (UK) PLC Shepherds Bush', '301466', ''],
    ['TSB BANK PLC Leek', '301469', ''],
    ['LLOYDS BANK PLC Leighton Buzzard', '301471', 'LOYDGB21565'],
    ['KEB HANA BANK London Branch', '301473', 'KOEXGB2LXXX'],
    ['BANK OF IRELAND (UK) PLC Manchester', '301474', ''],
    ['LLOYDS BANK PLC Leyland', '301476', ''],
    ['LLOYDS BANK PLC Chesham (301477)', '301477', 'LOYDGB21616'],
    ['BANK OF IRELAND (UK) PLC Luton', '301482', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Isle of Man Centre',
      '301483',
      ''
    ],
    ['BANK OF IRELAND (UK) PLC Kilburn', '301485', ''],
    ['BANK OF IRELAND (UK) PLC Holloway', '301487', ''],
    ['LLOYDS BANK PLC Llandrindod Wells', '301488', ''],
    ['BANK LEUMI (UK) PLC London Branch', '301495', 'LUMIGB22WES'],
    ['LLOYDS BANK PLC Llangefni', '301496', ''],
    ['LLOYDS BANK PLC Llantwit Major', '301497', ''],
    ['LLOYDS BANK PLC Llanishen', '301498', ''],
    ['LLOYDS BANK PLC Long Eaton  (301504)', '301504', 'LOYDGB21481'],
    ['BANK OF IRELAND (UK) PLC Leicester', '301510', ''],
    ['BANK OF IRELAND (UK) PLC Manchester (Chorlton)', '301514', ''],
    ['BANK OF IRELAND (UK) PLC Cardiff', '301517', ''],
    ['BANK OF IRELAND (UK) PLC Global Markets Special Clg', '301519', ''],
    ['BANK OF IRELAND (UK) PLC Croydon', '301525', ''],
    ['LLOYDS BANK PLC Market Drayton (301539)', '301539', 'LOYDGB21625'],
    ['TSB BANK PLC Hayling Island', '301542', ''],
    ['LLOYDS BANK PLC Guildford (301549)', '301549', ''],
    ['LLOYDS BANK PLC Wirral', '301552', 'LOYDGB21240'],
    ['LLOYDS BANK PLC Milton Keynes (301553)', '301553', 'LOYDGB21052'],
    ['LLOYDS BANK PLC Financial Markets London', '301557', 'LOYDGB22TSY'],
    ['NEDBANK  LIMITED Lombard Street Ec3', '301561', 'NEDSGB2LXXX'],
    ['LLOYDS BANK PLC Nailsea', '301563', ''],
    ['LLOYDS BANK PLC Newcastle-upon-Tyne', '301572', ''],
    ['BANK OF IRELAND (UK) PLC Southampton', '301573', ''],
    ['LLOYDS BANK PLC Milton Keynes (301579)', '301579', ''],
    ['LLOYDS BANK PLC South Harrow (301593)', '301593', 'LOYDGB21606'],
    ['LLOYDS BANK PLC Watford (301595)', '301595', 'LOYDGB21476'],
    ['LLOYDS BANK PLC High St Leicester(301597)', '301597', 'LOYDGB21220'],
    ['LLOYDS BANK PLC Payment Ops North 301599', '301599', 'LOYDGB21021'],
    ['BANK OF IRELAND (UK) PLC London Region', '301607', ''],
    ['LLOYDS BANK PLC Intl Services Cen (301609)', '301609', ''],
    ['LLOYDS BANK PLC Patchway Bristol (301611)', '301611', 'LOYDGB21323'],
    ['LLOYDS BANK PLC Ics Default Sort Code', '301614', ''],
    ['LLOYDS BANK PLC Pembroke Dock', '301620', 'LOYDGB21538'],
    ['LLOYDS BANK PLC Monument Int Dealing Desk', '301622', ''],
    ['JULIAN HODGE BANK LIMITED Cardiff', '301627', ''],
    ['LLOYDS BANK PLC Penrith (301628)', '301628', 'LOYDGB21572'],
    ['JULIAN HODGE BANK LIMITED Cardiff/Instalment Finance', '301629', ''],
    ['CIMB BANK BERHAD 27 Knightsbridge London', '301631', 'CIBBGB2LXXX'],
    ['LLOYDS BANK PLC The Broadclose Peterlee', '301634', 'LOYDGB21539'],
    ['BANK OF AMERICA, NA Bromley Kent', '301635', ''],
    ['LLOYDS BANK PLC Plymstock', '301637', ''],
    ['LLOYDS BANK PLC Pontardawe', '301640', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) International Advisors Iom',
      '301641',
      'LOYDIMD1002'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Ibas Jersey',
      '301642',
      'LOYDJES1006'
    ],
    ['LLOYDS BANK PLC Pontefract', '301645', ''],
    ['LLOYDS BANK PLC Pontyclun', '301648', ''],
    ['LLOYDS BANK PLC Fareham (301651)', '301651', ''],
    ['LLOYDS BANK PLC Intl Services Cen (301653)', '301653', ''],
    ['SANTANDER CARDS UK LIMITED Leeds', '301655', ''],
    ['LLOYDS BANK PLC Portishead', '301656', ''],
    [
      'BANK OF IRELAND (UK) PLC Post Office Current Account',
      '301657',
      'BOFIGB22XXX'
    ],
    ['SWANSEA BUILDING SOCIETY Cradock Street', '301658', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Payments Centre (301663)',
      '301663',
      'LOYDJEH1XXX'
    ],
    ['TSB BANK PLC Potters Bar', '301664', ''],
    ['LLOYDS BANK PLC Presteigne', '301668', ''],
    ['LLOYDS BANK PLC Scunthorpe Mortage Ops', '301670', ''],
    ['LLOYDS BANK PLC Mayfair', '301674', 'LOYDGB21395'],
    ['LLOYDS BANK PLC Pwllheli', '301676', ''],
    ['LLOYDS BANK PLC 2nd Floor Whm (301679)', '301679', ''],
    ['LLOYDS BANK PLC Cardiff B&C Sc', '301683', ''],
    ['LLOYDS BANK PLC Harborne (301684)', '301684', 'LOYDGB21468'],
    ['LLOYDS BANK PLC Payment Operations (301685)', '301685', ''],
    ["LLOYDS BANK PLC High St G'ham (301686)", '301686', ''],
    ['LLOYDS BANK PLC Gatwick Cust Service Team', '301687', ''],
    ['TSB BANK PLC Redcar', '301696', ''],
    ['TSB BANK PLC Retford', '301700', ''],
    ['LLOYDS BANK PLC Cardnet', '301701', 'LOYDGBC1999'],
    ['LLOYDS BANK PLC Cardnet - Bacs', '301702', ''],
    ['LLOYDS BANK PLC Manch Est Settlement Unit', '301703', ''],
    [
      'BANK OF IRELAND (UK) PLC Bk of Ireland UK Tsy Bstol',
      '301705',
      'BIGTGB2XXXX'
    ],
    ['LLOYDS BANK PLC Rothbury (301712)', '301712', 'LOYDGB21634'],
    ['Bank of Scotland plc Hbos Ccc', '301713', 'BOFSGBS1LYD'],
    ['LLOYDS BANK PLC Rotherham (301716)', '301716', 'LOYDGB21532'],
    ['LLOYDS BANK PLC Cri (301717)', '301717', ''],
    ['Bank of Scotland plc Bos Corp - E & W', '301723', 'LOYDGB21Z52'],
    ['Bank of Scotland plc Bos Corp - Sco', '301725', 'LOYDGB21Z51'],
    ['LLOYDS BANK PLC Rustington', '301728', ''],
    ['Bank of Scotland plc Bos Corp - Loans', '301734', 'LOYDGB21Z50'],
    ['LLOYDS BANK PLC Hardshaw St St Helen 301748', '301748', 'LOYDGB21473'],
    ['LLOYDS BANK PLC Osc Dr - Cumbernauld', '301754', ''],
    ['TSB BANK PLC Cmcb Ctss Scot 301757', '301757', ''],
    ['LLOYDS BANK PLC St Neots', '301760', ''],
    ['LLOYDS BANK PLC Et Digital Uat (301763)', '301763', 'LOYDGB21Z86'],
    ['LLOYDS BANK PLC Carlisle', '301767', 'LOYDGB21Z88'],
    ['LLOYDS BANK PLC Saltash', '301772', ''],
    ['LLOYDS BANK PLC Pmnts Service Centre', '301773', ''],
    ['LLOYDS BANK PLC Et Digital Uat (301775)', '301775', 'LOYDGB21Z80'],
    ['TSB BANK PLC Sawbridgeworth', '301777', ''],
    ['LLOYDS BANK PLC Selby (301780)', '301780', 'LOYDGB21618'],
    ['LLOYDS BANK PLC Shanklin', '301784', ''],
    ['TSB BANK PLC Sheerness', '301785', ''],
    ['LLOYDS BANK PLC Cardiff Remediation Team', '301789', ''],
    ['Bank of Scotland plc Pay Ops Incidents (301790)', '301790', ''],
    ['Bank of Scotland plc Specialist Banking', '301793', 'LOYDGB21Z54'],
    ['TSB BANK PLC Glasgow Service Centre 4', '301795', ''],
    ['LLOYDS BANK PLC Bristol Shirehampton', '301797', ''],
    ['LLOYDS BANK PLC Shotton (301803)', '301803', 'LOYDGB21568'],
    ['LLOYDS BANK PLC Payment Ops North 301805', '301805', 'LOYDGB21Z59'],
    ['LLOYDS BANK PLC Sittingbourne (301806)', '301806', 'LOYDGB21464'],
    ['LLOYDS BANK PLC Andover Svce Ctr (301807)', '301807', ''],
    ['TSB BANK PLC Banking Services 301809', '301809', ''],
    ['TSB BANK PLC Somerton', '301816', ''],
    ['Bank of Scotland plc Specialist Banking', '301818', 'LOYDGB21Z57'],
    ['TSB BANK PLC TSB High Holborn', '301825', ''],
    ['LLOYDS BANK PLC Haywards Heath (301830)', '301830', 'LOYDGB21173'],
    ['LLOYDS BANK PLC Corringham (301834)', '301834', 'LOYDGB21556'],
    ['LLOYDS BANK PLC Swanwick', '301839', ''],
    ['LLOYDS BANK PLC Kilburn (301843)', '301843', 'LOYDGB21203'],
    ['LLOYDS BANK PLC Tadley', '301845', 'LOYDGB21415'],
    ['LLOYDS BANK PLC 113 Border Telford Shop Ct', '301855', 'LOYDGB21197'],
    ['LLOYDS BANK PLC Tettenhall Wolverhampton1', '301856', 'LOYDGB21378'],
    ['LLOYDS BANK PLC Sheffield The Moor', '301859', ''],
    ['LLOYDS BANK PLC Thatcham', '301861', ''],
    ['LLOYDS BANK PLC Thornbury Avon', '301862', ''],
    ['LLOYDS BANK PLC Thirsk', '301864', 'LOYDGB21586'],
    ['LLOYDS BANK PLC Bradford Thornbury', '301866', ''],
    ['TSB BANK PLC Leicester, The Roundway', '301869', ''],
    ['LLOYDS BANK PLC Tilehurst', '301871', ''],
    ['LLOYDS BANK PLC Tiptree', '301873', ''],
    ['LLOYDS BANK PLC Canterbury (301874)', '301874', 'LOYDGB21Y18'],
    ['LLOYDS BANK PLC Payment Ops North 301875', '301875', 'LOYDGB21Y19'],
    ['LLOYDS BANK PLC London Tottenham Court Rd', '301882', ''],
    ['LLOYDS BANK PLC Northampton (301883)', '301883', 'LOYDGB21340'],
    ['LLOYDS BANK PLC Thamesdown', '301886', ''],
    ['LLOYDS BANK PLC Ccm Cats E & W Brch (301887', '301887', 'LOYDGB21Z87'],
    ['LLOYDS BANK PLC Clevedon', '301888', 'LOYDGB21445'],
    ['LLOYDS BANK PLC Rtl Lab-Tr1 Testing301889', '301889', ''],
    ['LLOYDS BANK PLC Private Banking London', '301890', 'LOYDGB21Z21'],
    ['LLOYDS BANK PLC Universal Credit', '301894', ''],
    ['TSB BANK PLC Nottingham, West Bridgford', '301898', ''],
    ['AHLI United Bank (UK) Plc Portman Sq (301901)', '301901', ''],
    ['AHLI United Bank (UK) Plc Portman Sq (301902)', '301902', ''],
    ['AHLI United Bank (UK) Plc Portman Sq (301903)', '301903', 'UBKLGB2LXXX'],
    ["LLOYDS BANK PLC Univ of B'ham (301914)", '301914', 'LOYDGB21086'],
    ['LLOYDS BANK PLC Walton-on-Thames (301922)', '301922', 'LOYDGB21435'],
    ['LLOYDS BANK PLC Customer Serv Cen (301931)', '301931', 'LOYDGB21785'],
    ['LLOYDS BANK PLC Wednesfield (301940)', '301940', 'LOYDGB21608'],
    ['LLOYDS BANK PLC Wetherby', '301942', ''],
    ['LLOYDS BANK PLC Whickham (301954)', '301954', 'LOYDGB21381'],
    ['LLOYDS BANK PLC Wigan Lancs (301956)', '301956', 'LOYDGB21531'],
    ['LLOYDS BANK PLC Williton (301957)', '301957', 'LOYDGB21593'],
    ['LLOYDS BANK PLC Ystradgynlais', '301976', ''],
    ['LLOYDS BANK PLC Ystrad Mynach', '301978', ''],
    ['LLOYDS BANK PLC Carfax Oxford (301983)', '301983', 'LOYDGB21576'],
    ['LLOYDS BANK PLC Portland Street7 (301995)', '301995', 'LOYDGB21Y99'],
    ['LLOYDS BANK PLC Customer Serv Cen (301996)', '301996', 'LOYDGB21008'],
    ['LLOYDS BANK PLC Hexham (302135)', '302135', ''],
    ['LLOYDS BANK PLC Telephony Solent Tsc302500', '302500', ''],
    ['LLOYDS BANK PLC Manchester Service Centre 1', '302505', ''],
    ['LLOYDS BANK PLC Andover Bba 2 (302506)', '302506', ''],
    ['LLOYDS BANK PLC Cambridge Cash Centre', '302511', ''],
    ['LLOYDS BANK PLC Cash Operations Team', '302518', 'LOYDGB21F05'],
    ['LLOYDS BANK PLC Legg Street Osc', '302534', ''],
    ['LLOYDS BANK PLC Tonbridge Csc', '302536', ''],
    ['LLOYDS BANK PLC Test Branch (Wmh 302556)', '302556', 'LOYDGB21Z81'],
    ['LLOYDS BANK PLC Test Branch (Wmh 302571)', '302571', 'LOYDGB21F07'],
    ['LLOYDS BANK PLC Stp Settlement(302572)', '302572', ''],
    ['LLOYDS BANK PLC Phonebank Support', '302573', ''],
    ['LLOYDS BANK PLC Swansea Osc', '302575', ''],
    ['Bank of Scotland plc Bos Comm Loans', '302576', 'LOYDGB21Z55'],
    ['Bank of Scotland plc Bos Retail Tl', '302577', 'LOYDGB21Z56'],
    ['LLOYDS BANK PLC Andover Saga 4', '302580', 'LOYDGB21Y00'],
    ['TSB BANK PLC Edinburgh Business & Comm', '302581', ''],
    ['TSB BANK PLC Aberdeen Business & Commer', '302582', ''],
    ['TSB BANK PLC Glasgow North Bus & Commer', '302583', ''],
    ['TSB BANK PLC Stirling Business & Comm', '302584', ''],
    ['TSB BANK PLC Paisley Business & Commer', '302585', ''],
    ['TSB BANK PLC Falkirk Business & Commer', '302586', ''],
    ['TSB BANK PLC TSB Dundee (302587)', '302587', ''],
    ['TSB BANK PLC Ayr (302588)', '302588', ''],
    ['TSB BANK PLC Aberdeen, Rosehill (302589)', '302589', ''],
    ['LLOYDS BANK PLC Contact Centre Newcastle', '302593', ''],
    ['LLOYDS BANK PLC Glasgow Credit Maintenance', '302599', ''],
    ['LLOYDS BANK CORPORATE MARKETS PLC Jersey (30-26-92)', '302692', ''],
    ['LLOYDS BANK PLC Bch Tran Self Serv 302768', '302768', ''],
    ['LLOYDS BANK PLC Finance Bkg Mgt', '302771', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '302778', ''],
    ['LLOYDS BANK PLC Fraud Lloyds', '302779', ''],
    ['TSB BANK PLC Vag Test Branch', '302795', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '302825', ''],
    ['LLOYDS BANK PLC Rtl Lab-Tr1 Testing302830', '302830', ''],
    ['LLOYDS BANK PLC Private Banking Southampton', '302854', 'LOYDGB21Z31'],
    ['LLOYDS BANK PLC Pb Direct Leeds (302855)', '302855', 'LOYDGB21Z28'],
    ['LLOYDS BANK PLC Private Banking Exeter', '302857', 'LOYDGB21Z45'],
    ['TSB BANK PLC Birmingham, Priory (302870)', '302870', ''],
    ['TSB BANK PLC Birmingham, Priory (302873)', '302873', ''],
    ['TSB BANK PLC Birmingham, Priory (302874)', '302874', ''],
    ['TSB BANK PLC TSB (302875)', '302875', ''],
    ['TSB BANK PLC TSB Bank (302880)', '302880', ''],
    ['TSB BANK PLC TSB Payments Head Office', '302881', ''],
    ['TSB BANK PLC TSB Head Office (302884)', '302884', ''],
    ['TSB BANK PLC Chaps Routing', '302885', 'TSBSGB2AXXX'],
    ['TSB BANK PLC TSB Mortgage Ops', '302886', ''],
    ['TSB BANK PLC TSB Head Office (302888)', '302888', ''],
    ['TSB BANK PLC TSB Head Office (302890)', '302890', ''],
    ['TSB BANK PLC TSB Head Office (302892)', '302892', ''],
    ['TSB BANK PLC TSB Head Office (302894)', '302894', ''],
    ['TSB BANK PLC TSB Head Office (302919)', '302919', ''],
    ['TSB BANK PLC TSB Payments Head Office', '302920', ''],
    ['TSB BANK PLC TSB Payments Head Office', '302921', ''],
    ['TSB BANK PLC TSB Payments Head Office', '302922', ''],
    ['TSB BANK PLC TSB Head Office (302925)', '302925', ''],
    ['LLOYDS BANK PLC Lbg Gct Head Office Mld', '303262', ''],
    ['LLOYDS BANK PLC Cssc', '303270', ''],
    ['LLOYDS BANK PLC Electricity Priv / Chaps', '303272', ''],
    ['LLOYDS BANK PLC Kleinwort Benson Im Ltd', '303323', ''],
    ['LLOYDS BANK PLC Credit Operations', '303337', ''],
    ['LLOYDS BANK PLC Group Security', '303387', ''],
    ['LLOYDS BANK PLC Customer Supp Unit (303460)', '303460', ''],
    ['LLOYDS BANK PLC Customer Supp Unit (303461)', '303461', ''],
    ['LLOYDS BANK PLC Unclaimed Settlement', '303465', ''],
    ['Bank of Scotland plc Halifax Com Bank Back Offic', '303505', ''],
    ['Bank of Scotland plc Andover Hbos (303506)', '303506', ''],
    ['Bank of Scotland plc Fraud Halifax Fp', '303512', ''],
    [
      'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC) Crc - Halifax Epayments',
      '303513',
      ''
    ],
    ['Bank of Scotland plc Halifax Com Bank Back Offic', '303514', ''],
    ['Bank of Scotland plc Halifax Com Bank Back Offic', '303520', ''],
    ['Bank of Scotland plc Halifax Com Bank Back Offic', '303533', ''],
    ['Bank of Scotland plc Savings Halifax Fp', '303534', ''],
    ['Bank of Scotland plc Halifax Com Bank Back Offic', '303540', ''],
    ['Bank of Scotland plc Halifax Com Bank Back Offic', '303546', ''],
    ['Bank of Scotland plc Halifax Com Bank Back Offic', '303558', ''],
    ['Bank of Scotland plc Hifal Fp', '303559', ''],
    ['Bank of Scotland plc Comm Bank Hfx Fp', '303560', ''],
    ['Bank of Scotland plc Halifax Com Bank Back Offic', '303565', ''],
    ['Bank of Scotland plc Halifax Com Bank Back Offic', '303573', ''],
    ['LLOYDS BANK PLC Banking Operations', '303967', ''],
    ['CHINA MERCHANTS BANK CO., LTD London Branch', '303996', ''],
    ['TSB BANK PLC Mortgages TSB Redress', '304015', ''],
    ['LLOYDS BANK PLC Lloyds Bank plc', '304065', 'LOYDGB21F43'],
    ['LLOYDS BANK PLC Gtbcmp-2', '304066', 'LOYDGB21F44'],
    ['LLOYDS BANK PLC Gtbcmpfi-1', '304067', 'LOYDGB21F45'],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Gem Islands Jersey 304068',
      '304068',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Gem Islands Guernsey 304069',
      '304069',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Gem Islands Iom 304070',
      '304070',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Gem Islands Jersey Virtual',
      '304071',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Gem Islands Guernsey Virt',
      '304072',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Gem Islands Iom Virtual',
      '304073',
      ''
    ],
    ['LLOYDS BANK PLC Connect Fraud Dundonald', '304109', ''],
    ['LLOYDS BANK PLC Telephone Banking', '304130', ''],
    ['LLOYDS BANK PLC Internation Service Centre', '304151', ''],
    ['LLOYDS BANK PLC Stratford Westfield', '304157', 'LOYDGB21Z58'],
    ['LLOYDS BANK PLC Banking Operations', '304158', ''],
    ['TSB BANK PLC Retail Lt(304159)', '304159', ''],
    ['LLOYDS BANK PLC Rfu - App E&W 2-L', '304169', ''],
    ['LLOYDS BANK PLC Andover Svc Cen (304176)', '304176', ''],
    ['LLOYDS BANK PLC Group Customer Services', '304180', ''],
    ['LLOYDS BANK PLC Mbna Ltd (304207)', '304207', ''],
    ['LLOYDS BANK PLC Connect Fraud Belfast', '304380', ''],
    ['LLOYDS BANK PLC Citibank Edinburgh 4', '304425', ''],
    ["LLOYDS BANK PLC Vincent St G'gow 2", '304433', ''],
    ["LLOYDS BANK PLC Vincent St G'gow 3", '304434', ''],
    ['LLOYDS BANK PLC Birmingham BP Epay', '304435', ''],
    ['LLOYDS BANK PLC Telephony - 30-44-61', '304461', ''],
    ['LLOYDS BANK PLC Remediation Team (304496)', '304496', ''],
    ['LLOYDS BANK PLC Lloyds Private B', '304603', ''],
    ['LLOYDS BANK PLC Andover Service Centre 5', '304604', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304744', ''],
    ['LLOYDS BANK PLC Gops Oppi 14(304745', '304745', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304746', ''],
    ['LLOYDS BANK PLC Banking Operations', '304747', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 17', '304748', ''],
    ['LLOYDS BANK PLC Gops Oppi 18(304749)', '304749', ''],
    ['LLOYDS BANK PLC Share Dealing', '304756', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 38', '304757', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304758', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304759', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '304765', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 42', '304766', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 34', '304767', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 35', '304768', ''],
    ['LLOYDS BANK PLC Pendeford', '304778', ''],
    ['LLOYDS BANK PLC Pendeford', '304779', ''],
    ['LLOYDS BANK PLC Rbb Telephony Pend (304780)', '304780', ''],
    ['LLOYDS BANK PLC Rbb Telephony Pend (304781)', '304781', ''],
    ['LLOYDS BANK PLC Private Lending Centre', '304783', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 19', '304788', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304796', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304797', ''],
    ['LLOYDS BANK PLC 2nd Floor Whm (304835)', '304835', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304836', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304837', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304839', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 46', '304840', ''],
    ['LLOYDS BANK PLC Banking Operations', '304841', ''],
    ['LLOYDS BANK PLC Banking Operations', '304842', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '304845', ''],
    ['LLOYDS BANK PLC Internet Lloyds Bank', '304849', ''],
    ['LLOYDS BANK PLC Ias Fps 304852', '304852', ''],
    ['LLOYDS BANK PLC Westgate Huddersfield', '305189', 'LOYDGB21F91'],
    ['LLOYDS BANK PLC Csc (305368)', '305368', 'LOYDGB21F04'],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '305377', ''],
    ['LLOYDS BANK PLC Braddan Dr Site 2', '305588', ''],
    ['LLOYDS BANK PLC Lbg Mortgage Payment Sdv', '305593', ''],
    ['LLOYDS BANK PLC Banking Operations', '305734', ''],
    ['TSB BANK PLC Retail Lt (305735)', '305735', ''],
    ['LLOYDS BANK PLC Hustlergate Bradford 305742', '305742', 'LOYDGB21F82'],
    ['LLOYDS BANK PLC Connect Fraud Dundonald 2', '305751', ''],
    ['LLOYDS BANK PLC Rtl Lab-Tr1 Testing305763', '305763', ''],
    ['LLOYDS BANK PLC Fraud Operations (30-57-65)', '305765', ''],
    ["LLOYDS BANK PLC Int'l Payments", '305771', ''],
    ['LLOYDS BANK PLC Bch Tran Self Serv 305788', '305788', ''],
    ['LLOYDS BANK PLC Ccfd Pld Brighton', '305840', ''],
    ['LLOYDS BANK PLC Portland Street3 (305873)', '305873', ''],
    ['LLOYDS BANK PLC Portland Street2  (305874)', '305874', ''],
    ['LLOYDS BANK PLC Portland Street1 (305875)', '305875', ''],
    ['LLOYDS BANK PLC Bops (305876)', '305876', ''],
    ['LLOYDS BANK PLC Bops (305877)', '305877', ''],
    ['LLOYDS BANK PLC Portland Street4 (305878)', '305878', ''],
    ['LLOYDS BANK PLC Portland Street5(305879)', '305879', ''],
    ['LLOYDS BANK PLC Portland Street6 (305881)', '305881', ''],
    ['LLOYDS BANK PLC Portland Street7 (305882)', '305882', ''],
    ['LLOYDS BANK PLC Portland Street8 (305883)', '305883', ''],
    ['LLOYDS BANK PLC Bops (305884)', '305884', ''],
    ['LLOYDS BANK PLC Bops (305886)', '305886', ''],
    ['LLOYDS BANK PLC Bops (305887)', '305887', ''],
    ['LLOYDS BANK PLC Bops (305888)', '305888', ''],
    ['LLOYDS BANK PLC Bops (305889)', '305889', ''],
    ['LLOYDS BANK PLC Bops (305890)', '305890', ''],
    ['TSB BANK PLC TSB (305907)', '305907', ''],
    ['TSB BANK PLC TSB (305908)', '305908', ''],
    ['TSB BANK PLC TSB (305909)', '305909', ''],
    ['TSB BANK PLC TSB (305910)', '305910', ''],
    ['TSB BANK PLC TSB (305911)', '305911', ''],
    ['TSB BANK PLC TSB (305912)', '305912', ''],
    ['TSB BANK PLC TSB (305913)', '305913', ''],
    ['TSB BANK PLC TSB (305914)', '305914', ''],
    ['TSB BANK PLC TSB (305915)', '305915', ''],
    ['TSB BANK PLC TSB (305916)', '305916', ''],
    ['TSB BANK PLC TSB (305917)', '305917', ''],
    ['TSB BANK PLC TSB (305918)', '305918', ''],
    ['TSB BANK PLC TSB (305919)', '305919', ''],
    ['TSB BANK PLC TSB (305920)', '305920', ''],
    ['TSB BANK PLC TSB (305921)', '305921', ''],
    ['TSB BANK PLC TSB (305922)', '305922', ''],
    ['TSB BANK PLC TSB (305923)', '305923', ''],
    ['TSB BANK PLC TSB (305924)', '305924', ''],
    ['TSB BANK PLC TSB (305925)', '305925', ''],
    ['TSB BANK PLC TSB (305926)', '305926', ''],
    ['TSB BANK PLC TSB (305927)', '305927', ''],
    ['TSB BANK PLC TSB (305928)', '305928', ''],
    ['TSB BANK PLC TSB (305929)', '305929', ''],
    ['TSB BANK PLC TSB (305930)', '305930', ''],
    ['TSB BANK PLC TSB (305931)', '305931', ''],
    ['TSB BANK PLC TSB (305932)', '305932', ''],
    ['TSB BANK PLC TSB (305933)', '305933', ''],
    ['TSB BANK PLC TSB (305934)', '305934', ''],
    ['TSB BANK PLC TSB (305935)', '305935', ''],
    ['TSB BANK PLC TSB (305936)', '305936', ''],
    ['TSB BANK PLC TSB (305937)', '305937', ''],
    ['TSB BANK PLC TSB (305938)', '305938', ''],
    ['TSB BANK PLC TSB (305939)', '305939', ''],
    ['TSB BANK PLC TSB (305941)', '305941', ''],
    ['TSB BANK PLC TSB (305942)', '305942', ''],
    ['TSB BANK PLC TSB (305943)', '305943', ''],
    ['TSB BANK PLC TSB (305944)', '305944', ''],
    ['TSB BANK PLC TSB (305945)', '305945', ''],
    ['TSB BANK PLC TSB (305946)', '305946', ''],
    ['TSB BANK PLC TSB (305947)', '305947', ''],
    ['TSB BANK PLC TSB (305948)', '305948', ''],
    ['TSB BANK PLC TSB (305949)', '305949', ''],
    ['TSB BANK PLC TSB (305950)', '305950', ''],
    ['TSB BANK PLC TSB (305951)', '305951', ''],
    ['TSB BANK PLC TSB (305952)', '305952', ''],
    ['TSB BANK PLC TSB (305953)', '305953', ''],
    ['TSB BANK PLC TSB (305954)', '305954', ''],
    ['TSB BANK PLC TSB (305955)', '305955', ''],
    ['TSB BANK PLC TSB (305956)', '305956', ''],
    ['TSB BANK PLC TSB (305957)', '305957', ''],
    ['TSB BANK PLC TSB (305958)', '305958', ''],
    ['TSB BANK PLC TSB (305959)', '305959', ''],
    ['TSB BANK PLC TSB (305960)', '305960', ''],
    ['LLOYDS BANK PLC Savings 5962', '305962', ''],
    ['LLOYDS BANK PLC Savings 5963', '305963', ''],
    ['LLOYDS BANK PLC Savings 5964', '305964', ''],
    ['LLOYDS BANK PLC Savings 5965', '305965', ''],
    ['LLOYDS BANK PLC Savings 5966', '305966', ''],
    ['LLOYDS BANK PLC Savings 5967', '305967', ''],
    ['TSB BANK PLC Test L&U (305971)', '305971', ''],
    ['TSB BANK PLC TSB Payment Proc 305978', '305978', ''],
    ['TSB BANK PLC TSB (305982)', '305982', ''],
    ['TSB BANK PLC TSB (305984)', '305984', ''],
    ['TSB BANK PLC TSB (305985)', '305985', ''],
    ['TSB BANK PLC TSB (305986)', '305986', ''],
    ['TSB BANK PLC TSB Bank', '305988', ''],
    ['TSB BANK PLC TSB (305990)', '305990', ''],
    ['TSB BANK PLC TSB (305991)', '305991', ''],
    ['TSB BANK PLC TSB (305992)', '305992', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '306015', ''],
    ['LLOYDS BANK PLC Birmingham Sc (306019)', '306019', ''],
    ['LLOYDS BANK PLC Swansea Osc', '306027', ''],
    ['LLOYDS BANK PLC Andover Osc', '306034', ''],
    ['LLOYDS BANK PLC Contact Centre Sunderland', '306039', ''],
    ['LLOYDS BANK PLC Contact Centre Newcastle', '306040', ''],
    ['LLOYDS BANK PLC City Office  (306043)', '306043', ''],
    ['LLOYDS BANK PLC Milsom St Bath (306044)', '306044', ''],
    ['TSB BANK PLC Scotland Losc', '306045', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Isle of Man Intl Centre 2',
      '306049',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Intl Customer Services',
      '306050',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) International Credit',
      '306052',
      ''
    ],
    ['LLOYDS BANK PLC Andover Pca - (306097)', '306097', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '306117', ''],
    ['TSB BANK PLC TSB (306150)', '306150', ''],
    ['TSB BANK PLC TSB (306151)', '306151', ''],
    ['TSB BANK PLC B&S B A 306153', '306153', ''],
    ['TSB BANK PLC TSB (306154)', '306154', ''],
    ['TSB BANK PLC TSB (306155)', '306155', ''],
    ['TSB BANK PLC TSB (306181)', '306181', ''],
    ['TSB BANK PLC TSB  (306182)', '306182', ''],
    ['TSB BANK PLC TSB (306183)', '306183', ''],
    ['TSB BANK PLC Fraud Risk', '306196', ''],
    ['LLOYDS BANK PLC Cri/Epc   (306228)', '306228', ''],
    ['LLOYDS BANK PLC Cent Rtns in Pro Ng Closed', '306229', ''],
    ['LLOYDS BANK PLC Patchway Bristol (306232)', '306232', 'LOYDGB21643'],
    ['LLOYDS BANK PLC Portland Street4 (306241)', '306241', ''],
    ['LLOYDS BANK PLC Portland Street5 (306242)', '306242', ''],
    ['LLOYDS BANK PLC Hounslow', '306246', ''],
    ['LLOYDS BANK PLC Rothbury (306249)', '306249', 'LOYDGB21644'],
    ['LLOYDS BANK PLC Credit Operations', '306250', ''],
    ['LLOYDS BANK PLC Corp St Rotherham (306255)', '306255', 'LOYDGB21645'],
    ['LLOYDS BANK PLC 23 Hardshaw St St Helens', '306259', 'LOYDGB21646'],
    ['LLOYDS BANK PLC Holborn Circus', '306260', 'LOYDGB21647'],
    ['LLOYDS BANK PLC University of Bham (306261)', '306261', 'LOYDGB21648'],
    ['LLOYDS BANK PLC Wigan Lancs (306262)', '306262', 'LOYDGB21649'],
    ['LLOYDS BANK PLC Williton (306263)', '306263', 'LOYDGB21650'],
    ['LLOYDS BANK PLC Credit Operations', '306266', ''],
    ['LLOYDS BANK PLC Acqu 3 - 306268', '306268', ''],
    ['LLOYDS BANK PLC Colchester St Johns', '306269', ''],
    ['TSB BANK PLC TSB (306273)', '306273', ''],
    ['LLOYDS BANK PLC Manchester Csc (306276)', '306276', ''],
    ['LLOYDS BANK PLC Andover Bba 2  (306277)', '306277', ''],
    ['LLOYDS BANK PLC Oadby', '306280', ''],
    ['LLOYDS BANK PLC Fraud Operations (30-62-88)', '306288', ''],
    ['LLOYDS BANK PLC Bristol City Ctr (306296)', '306296', 'LOYDGB21651'],
    ['LLOYDS BANK PLC Savings Region', '306300', ''],
    ['LLOYDS BANK PLC Norbury', '306343', ''],
    ['LLOYDS BANK PLC Market Pl Boston (306347)', '306347', 'LOYDGB21652'],
    ['LLOYDS BANK PLC Central Returns in Closed', '306349', ''],
    ['LLOYDS BANK PLC High St Bracknell (306354)', '306354', 'LOYDGB21653'],
    ['LLOYDS BANK PLC Hustlergate Bradford 306355', '306355', 'LOYDGB21654'],
    ['LLOYDS BANK PLC Bank St Braintree (306357)', '306357', 'LOYDGB21655'],
    ['LLOYDS BANK PLC Brecon (306359)', '306359', 'LOYDGB21656'],
    ['LLOYDS BANK PLC Registrars Central Banking', '306360', ''],
    ['LLOYDS BANK PLC Alcester', '306362', ''],
    ['LLOYDS BANK PLC Pavement York (306364)', '306364', 'LOYDGB21657'],
    ['LLOYDS BANK PLC Andover Osc 3', '306383', ''],
    ['LLOYDS BANK PLC Shipston-on-Stour', '306388', ''],
    ['LLOYDS BANK PLC Bromsgrove (306394)', '306394', 'LOYDGB21658'],
    ['LLOYDS BANK PLC Buckingham (306397)', '306397', 'LOYDGB21659'],
    ['LLOYDS BANK PLC New St Birmingham', '306410', 'LOYDGB21660'],
    ['LLOYDS BANK PLC Strood', '306411', ''],
    ['TSB BANK PLC TSB (306412)', '306412', ''],
    ['LLOYDS BANK PLC Fareham (306413)', '306413', ''],
    ['TSB BANK PLC TSB (306414)', '306414', ''],
    ['LLOYDS BANK PLC Church Road Burgess Hill', '306415', 'LOYDGB21663'],
    ['LLOYDS BANK PLC High St Burton on Trent', '306418', 'LOYDGB21664'],
    ['LLOYDS BANK PLC Bury (306419)', '306419', 'LOYDGB21665'],
    ['LLOYDS BANK PLC Gateshead Team Valley', '306421', ''],
    ['LLOYDS BANK PLC Buttermkt Bury St Ed 306422', '306422', 'LOYDGB21666'],
    ['LLOYDS BANK PLC Derby Irongate (306424)', '306424', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) International Csc (Iom-R)',
      '306426',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Int Csc (G-R) 30-64-27',
      '306427',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) International Csc (Jsy-R)',
      '306428',
      ''
    ],
    ['LLOYDS BANK PLC Tumble', '306430', ''],
    ['TSB BANK PLC TSB (306414)', '306434', ''],
    ['LLOYDS BANK PLC Camberley (306437)', '306437', 'LOYDGB21668'],
    ['LLOYDS BANK PLC Camberwell Green (306438)', '306438', 'LOYDGB21669'],
    ['LLOYDS BANK PLC University of Cambridge', '306442', 'LOYDGB21670'],
    ['LLOYDS BANK PLC Camden Town (306443)', '306443', 'LOYDGB21671'],
    ['LLOYDS BANK PLC Cannock (306444)', '306444', 'LOYDGB21672'],
    ['LLOYDS BANK PLC Canterbury (306457)', '306457', 'LOYDGB21673'],
    ['LLOYDS BANK PLC Legg Street Osc (306464)', '306464', 'LOYDGB21Z94'],
    ['LLOYDS BANK PLC Walsall Bloxwich', '306467', ''],
    ['LLOYDS BANK PLC Chelsea (306472)', '306472', 'LOYDGB21674'],
    ['LLOYDS BANK PLC Wickersley', '306473', ''],
    ['LLOYDS BANK PLC Basildon (306474)', '306474', ''],
    ['LLOYDS BANK PLC Minster Place Ely (306479)', '306479', 'LOYDGB21675'],
    ['LLOYDS BANK PLC Worle', '306482', ''],
    ['LLOYDS BANK PLC Piccadilly (306497)', '306497', 'LOYDGB21676'],
    ['LLOYDS BANK PLC Norwich Aylsham Rd', '306498', ''],
    ['LLOYDS BANK PLC Gillingham Dorset (306521)', '306521', 'LOYDGB21677'],
    ['LLOYDS BANK PLC Leeds City Centre(306522)', '306522', 'LOYDGB21678'],
    [
      'Bank of Scotland plc Hbos Chaps Central Control',
      '306526',
      'BOFSGBS1402'
    ],
    ['Bank of Scotland plc Bos Gct Head Office Mld', '306527', ''],
    ['Bank of Scotland plc Wealth Hbos Fp', '306531', ''],
    ['Bank of Scotland plc Savings Bos Fp', '306532', ''],
    ['Bank of Scotland plc Fraud Bos Fp', '306533', ''],
    ['LLOYDS BANK PLC Wigston Magna (306535)', '306535', ''],
    ['LLOYDS BANK PLC Cliffe Lewes (306537)', '306537', 'LOYDGB21679'],
    ['LLOYDS BANK PLC Lewisham (306538)', '306538', 'LOYDGB21680'],
    ['TSB BANK PLC TSB (306539)', '306539', ''],
    ['LLOYDS BANK PLC Blackwood', '306540', ''],
    ['LLOYDS BANK PLC Threadneedle St (306541)', '306541', 'LOYDGB21682'],
    ['LLOYDS BANK PLC Brighouse', '306547', ''],
    ['LLOYDS BANK PLC New St Birmingham', '306549', 'LOYDGB21683'],
    ['LLOYDS BANK PLC UK Private Banking', '306551', ''],
    ['Bank of Scotland plc Bosas Fp', '306552', ''],
    ['Bank of Scotland plc Comm Bank Bos Fp', '306553', ''],
    ['LLOYDS BANK PLC Swindon Brunel Centre', '306560', ''],
    ["LLOYDS BANK PLC Church St Liverp'l 306562", '306562', 'LOYDGB21684'],
    ['LLOYDS BANK PLC Ammanford (306563)', '306563', 'LOYDGB21685'],
    ['LLOYDS BANK PLC Mostyn St Llandudno', '306564', 'LOYDGB21686'],
    ['LLOYDS BANK PLC Llanelli (306565)', '306565', 'LOYDGB21687'],
    ['LLOYDS BANK PLC Canary Wharf', '306567', ''],
    ['TSB BANK PLC TSB (306572)', '306572', ''],
    ['LLOYDS BANK PLC Newquay Chester Rd', '306579', ''],
    ['LLOYDS BANK PLC Chipping Campden', '306581', ''],
    ['LLOYDS BANK PLC Chester Christleton Rd', '306583', ''],
    ['LLOYDS BANK PLC High St Loughboro (306585)', '306585', 'LOYDGB21689'],
    ['LLOYDS BANK PLC Mercer Row Louth (306586)', '306586', 'LOYDGB21690'],
    ['LLOYDS BANK PLC Finchley Church End', '306588', ''],
    ['TSB BANK PLC TSB (306592)', '306592', ''],
    ['LLOYDS BANK PLC 16 Broad Ludlow (306593)', '306593', 'LOYDGB21692'],
    ['LLOYDS BANK PLC Personal Lending Decisions', '306675', 'LOYDGB21Z95'],
    ['LLOYDS BANK PLC George St Luton (306676)', '306676', 'LOYDGB21693'],
    ['LLOYDS BANK PLC Lydney (306677)', '306677', 'LOYDGB21694'],
    ['LLOYDS BANK PLC Alderney', '306679', ''],
    ['LLOYDS BANK PLC Villiers House Iom', '306680', ''],
    [
      'LLOYDS BANK CORPORATE MARKETS PLC Lloyds Bank Corp Mark plc',
      '306684',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) St Helier (30-66-85)',
      '306685',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) St Helier (30-66-87)',
      '306687',
      ''
    ],
    ['LLOYDS BANK PLC Stourbridge (306689)', '306689', 'LOYDGB21695'],
    ['LLOYDS BANK PLC Villiers House Iom', '306690', ''],
    ['LLOYDS BANK PLC Isle of Man - Ramsey', '306691', ''],
    ['LLOYDS BANK PLC Jersey - St Brelades', '306692', ''],
    ['LLOYDS BANK PLC Guernsey - St Martins', '306694', ''],
    ['TSB BANK PLC TSB (306695)', '306695', ''],
    ['LLOYDS BANK PLC Paddington (306696)', '306696', 'LOYDGB21697'],
    ['LLOYDS BANK PLC Guernsey - St Sampsons', '306697', ''],
    ['LLOYDS BANK PLC Jersey - Five Oaks', '306699', ''],
    ['LLOYDS BANK PLC Birmingham Acocks Green', '306731', ''],
    ['LLOYDS BANK PLC 47 High Street Brentwood', '306733', 'LOYDGB21698'],
    ['LLOYDS BANK PLC Wyndham St Brdgnd (306734)', '306734', 'LOYDGB21699'],
    ['LLOYDS BANK PLC Bridgnorth (306735)', '306735', 'LOYDGB21700'],
    ['LLOYDS BANK PLC Alresford', '306736', ''],
    ['LLOYDS BANK PLC Amesbury', '306737', ''],
    ['LLOYDS BANK PLC Atherstone', '306746', ''],
    ['LLOYDS BANK PLC Cornhill Bridgwater 306747', '306747', 'LOYDGB21701'],
    ['TSB BANK PLC TSB (306748)', '306748', ''],
    ['TSB BANK PLC TSB (306749)', '306749', ''],
    ['LLOYDS BANK PLC Blackwood (306751)', '306751', ''],
    ['LLOYDS BANK PLC Ock St Abingdon (306753)', '306753', 'LOYDGB21704'],
    ['LLOYDS BANK PLC Card & Loan Services, Fssc', '306756', 'LOYDGB21705'],
    ['LLOYDS BANK PLC Beckenham', '306758', ''],
    ['TSB BANK PLC TSB (306759)', '306759', ''],
    ['LLOYDS BANK PLC 31 Queen St Cardiff', '306764', 'LOYDGB21707'],
    ['LLOYDS BANK PLC Clearing Dep Bham (306766)', '306766', ''],
    ['LLOYDS BANK PLC Carlisle (306767)', '306767', 'LOYDGB21708'],
    ['LLOYDS BANK PLC King St Carmathen 306769', '306769', 'LOYDGB21709'],
    ['LLOYDS BANK PLC Black Horse House 6772', '306772', 'LOYDGB21710'],
    ['LLOYDS BANK PLC Trade Services 306775', '306775', ''],
    ['LLOYDS BANK PLC High Street Lincoln 306776', '306776', 'LOYDGB21711'],
    ['LLOYDS BANK PLC Lisk Eara (306779)', '306779', 'LOYDGB21712'],
    ['LLOYDS BANK PLC Littlehampton (306782)', '306782', 'LOYDGB21713'],
    ['LLOYDS BANK PLC Palace Av Paignton 306784', '306784', ''],
    ['LLOYDS BANK PLC Broadstone', '306786', ''],
    ['LLOYDS BANK PLC Cleobury Mortimer (306788)', '306788', 'LOYDGB21714'],
    ['TSB BANK PLC TSB (306789)', '306789', ''],
    ['LLOYDS BANK PLC Brynmawr', '306792', ''],
    ['LLOYDS BANK PLC Burnham-on-Sea', '306794', ''],
    ['LLOYDS BANK PLC Chippenham (306797)', '306797', ''],
    ['LLOYDS BANK PLC Broad St Reading (306799)', '306799', 'LOYDGB21716'],
    ['LLOYDS BANK PLC Cardiff Customer Services1', '306941', ''],
    ['LLOYDS BANK PLC Hoddesdon (307180)', '307180', ''],
    ['LLOYDS BANK PLC Mrkt Sq Pontypridd 307182', '307182', ''],
    ['TSB BANK PLC TSB (307184)', '307184', ''],
    ['LLOYDS BANK PLC Cinderford', '307185', ''],
    ['LLOYDS BANK PLC Ams Centre (307188)', '307188', ''],
    ['LLOYDS BANK PLC Cardigan (307189)', '307189', 'LOYDGB21718'],
    ['LLOYDS BANK PLC Kingston upon Thames (30719', '307195', ''],
    ['LLOYDS BANK PLC Coleford', '307196', ''],
    ['LLOYDS BANK PLC Lichfield (307198)', '307198', 'LOYDGB21719'],
    ['LLOYDS BANK PLC Credit Operations', '307242', ''],
    ['LLOYDS BANK PLC Credit Operations', '307244', ''],
    ['TSB BANK PLC TSB (307271)', '307271', ''],
    ['LLOYDS BANK PLC Portsmouth Cosham', '307279', ''],
    ['LLOYDS BANK PLC Horsham (307282)', '307282', ''],
    ['LLOYDS BANK PLC Crediton', '307283', ''],
    ['LLOYDS BANK PLC Crowborough', '307285', ''],
    ['LLOYDS BANK PLC Darlaston', '307288', ''],
    ['LLOYDS BANK PLC Dewsbury', '307293', ''],
    ['LLOYDS BANK PLC Rowcroft Stroud (307297)', '307297', ''],
    ['LLOYDS BANK PLC Oxted (307313)', '307313', ''],
    ['LLOYDS BANK PLC Feltham', '307322', ''],
    ['LLOYDS BANK PLC Bristol Fishponds', '307324', ''],
    ['LLOYDS BANK PLC Westbury-on-Trym Bristol', '307330', ''],
    ['LLOYDS BANK PLC Heswall', '307334', ''],
    ['LLOYDS BANK PLC Woking - (307339)', '307339', ''],
    ['LLOYDS BANK PLC Liphook', '307341', ''],
    ['LLOYDS BANK PLC Looe', '307346', ''],
    ['LLOYDS BANK PLC Lyndhurst', '307351', ''],
    ['LLOYDS BANK PLC Lytham', '307353', ''],
    ['LLOYDS BANK PLC Malpas', '307356', ''],
    ['LLOYDS BANK PLC Marlow', '307357', ''],
    ['LLOYDS BANK PLC Basingstoke Festival', '307358', ''],
    ['LLOYDS BANK PLC Melksham', '307359', ''],
    ['LLOYDS BANK PLC Leeds City Centre 307369', '307369', ''],
    ['LLOYDS BANK PLC Oxford Swansea (307373)', '307373', ''],
    ['LLOYDS BANK PLC Muswell Hill', '307374', ''],
    ['LLOYDS BANK PLC New Malden', '307378', ''],
    ['LLOYDS BANK PLC New Romney', '307380', ''],
    ['LLOYDS BANK PLC Oxted', '307391', ''],
    ['LLOYDS BANK PLC Andover Osc 7', '307393', ''],
    ['LLOYDS BANK PLC Palmers Green', '307394', ''],
    ['LLOYDS BANK PLC Pershore', '307440', ''],
    ['LLOYDS BANK PLC Ponteland', '307445', ''],
    ['LLOYDS BANK PLC 1 Gwent Sq Cwmbran 307446', '307446', ''],
    ['LLOYDS BANK PLC Porthcawl', '307448', ''],
    ['LLOYDS BANK PLC Portslade & West Hove', '307450', ''],
    ['LLOYDS BANK PLC Purley', '307453', ''],
    ['LLOYDS BANK PLC Wimbledon (307455)', '307455', ''],
    ['LLOYDS BANK PLC Redhill', '307456', ''],
    ['LLOYDS BANK PLC Reigate', '307458', ''],
    ['LLOYDS BANK PLC Ross-on-Wye', '307464', ''],
    ['LLOYDS BANK PLC Birmingham Rubery', '307467', ''],
    ['LLOYDS BANK PLC Roath Park Cardiff 307469', '307469', ''],
    ['LLOYDS BANK PLC Seaford', '307477', ''],
    ['LLOYDS BANK PLC Seaton', '307478', ''],
    ['LLOYDS BANK PLC Shaftesbury', '307480', ''],
    ['LLOYDS BANK PLC Shepton Mallet', '307481', ''],
    ['LLOYDS BANK PLC Sherborne', '307482', ''],
    ['LLOYDS BANK PLC Portslade & West Hove', '307485', ''],
    ['LLOYDS BANK PLC Sidcup', '307488', ''],
    ['LLOYDS BANK PLC Sidmouth', '307489', ''],
    ['LLOYDS BANK PLC Oxford St Swansea 307511', '307511', ''],
    ['LLOYDS BANK PLC Slaithwaite', '307512', ''],
    ['LLOYDS BANK PLC Slough Buckingham Ave', '307513', ''],
    ['LLOYDS BANK PLC South Molton', '307516', ''],
    ['LLOYDS BANK PLC Banking Operations', '307517', ''],
    ['LLOYDS BANK PLC Southam', '307519', ''],
    ['LLOYDS BANK PLC St Davids', '307530', ''],
    ['LLOYDS BANK PLC The Cross Worcester (307534', '307534', ''],
    ['LLOYDS BANK PLC St Marys Isle of Scilly', '307535', ''],
    ['LLOYDS BANK PLC Exeter St Thomas', '307536', ''],
    ['LLOYDS BANK PLC Edgware (307538)', '307538', ''],
    ['LLOYDS BANK PLC George V Avenue (307543)', '307543', ''],
    ['LLOYDS BANK PLC Stow-on-the-Wold', '307544', ''],
    ['LLOYDS BANK PLC Oxford Summertown', '307547', ''],
    ['LLOYDS BANK PLC Surbiton', '307548', ''],
    ['LLOYDS BANK PLC Swanage', '307550', ''],
    ['LLOYDS BANK PLC Tonypandy', '307557', ''],
    ['LLOYDS BANK PLC Tooting', '307559', ''],
    ['LLOYDS BANK PLC Tutbury', '307561', ''],
    ['LLOYDS BANK PLC Foregate Chester (307570)', '307570', ''],
    ['LLOYDS BANK PLC Verwood', '307573', ''],
    ['LLOYDS BANK PLC Hertford (307578)', '307578', ''],
    ['LLOYDS BANK PLC High St Doncaster 307637', '307637', ''],
    ['LLOYDS BANK PLC Fareham (307641)', '307641', ''],
    ['LLOYDS BANK PLC Queen Sq Wolverhampton', '307646', ''],
    ['LLOYDS BANK PLC Woodley', '307647', ''],
    ['LLOYDS BANK PLC Yateley', '307649', ''],
    ['LLOYDS BANK PLC Csc (307652)', '307652', ''],
    ['LLOYDS BANK PLC Csc (307653)', '307653', ''],
    ['LLOYDS BANK PLC Bristol Fishponds 307664', '307664', ''],
    ['LLOYDS BANK PLC Private Banking Csc', '307684', ''],
    ['LLOYDS BANK PLC Telephony Solent Tsc 307686', '307686', 'LOYDGB21Z89'],
    ['TSB BANK PLC TSB (307688)', '307688', ''],
    ['LLOYDS BANK PLC Manchester Sc(307691)', '307691', ''],
    ['LLOYDS BANK PLC Pendeford (307697)', '307697', 'LOYDGB21Z96'],
    ['LLOYDS BANK PLC Banking Operations', '307718', ''],
    ['LLOYDS BANK PLC Mail Processing Centre 2', '307721', ''],
    ['LLOYDS BANK PLC Atm Delivery (307727)', '307727', ''],
    ['LLOYDS BANK PLC Banking Operations', '307739', ''],
    ['LLOYDS BANK PLC Banking Operations', '307740', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '307741', ''],
    ['TSB BANK PLC TSB (307742)', '307742', ''],
    ['LLOYDS BANK PLC Recoveries Comm Banking', '307749', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Expatriate Dr Centre',
      '307760',
      ''
    ],
    ['LLOYDS BANK PLC Credit Operations', '307778', ''],
    ['LLOYDS BANK PLC 2nd Floor Whm (307809)', '307809', ''],
    ['LLOYDS BANK PLC Cash Operations Team', '307823', ''],
    ['LLOYDS BANK PLC Cs Birmingham', '307861', ''],
    ['LLOYDS BANK PLC Banking Operations', '307873', ''],
    ['LLOYDS BANK PLC Portland Street 3 (307881)', '307881', 'LOYDGB21Z97'],
    ['LLOYDS BANK PLC Cash & Travel Oversight', '307883', 'LOYDGB21F06'],
    ['LLOYDS BANK PLC Cardnet (307886)', '307886', ''],
    ['LLOYDS BANK PLC Payment Ops North 308012', '308012', 'LOYDGB21F09'],
    ['TSB BANK PLC TSB (308026)', '308026', ''],
    ['LLOYDS BANK PLC Palmerston Road Southsea', '308027', 'LOYDGB21721'],
    ['LLOYDS BANK PLC Epsom (308033)', '308033', 'LOYDGB21722'],
    ['LLOYDS BANK PLC High St Evesham (308034)', '308034', 'LOYDGB21723'],
    ['LLOYDS BANK PLC High Street Exeter (308037)', '308037', 'LOYDGB21724'],
    ['LLOYDS BANK PLC Fleet (308042)', '308042', 'LOYDGB21725'],
    ['LLOYDS BANK PLC Carfax Oxford (308045)', '308045', 'LOYDGB21726'],
    ['LLOYDS BANK PLC Market Pl Helston (308048)', '308048', 'LOYDGB21727'],
    ['LLOYDS BANK PLC Hemel Hempstead (308049)', '308049', 'LOYDGB21728'],
    ['LLOYDS BANK PLC Broad St Reading (308054)', '308054', 'LOYDGB21729'],
    ['LLOYDS BANK PLC High Twn Hereford (308055)', '308055', 'LOYDGB21730'],
    ['LLOYDS BANK PLC Lymington (308063)', '308063', 'LOYDGB21731'],
    ['LLOYDS BANK PLC Windsor Road Neath (308076)', '308076', 'LOYDGB21732'],
    ['LLOYDS BANK PLC Dudley (308077)', '308077', 'LOYDGB21733'],
    ['LLOYDS BANK PLC Aberdare (308082)', '308082', 'LOYDGB21734'],
    ['LLOYDS BANK PLC Abergavenny (308083)', '308083', 'LOYDGB21735'],
    ['TSB BANK PLC TSB (308085)', '308085', ''],
    ['TSB BANK PLC TSB (308087)', '308087', ''],
    ['LLOYDS BANK PLC 308/12 Chiswick High Road', '308088', 'LOYDGB21738'],
    ['LLOYDS BANK PLC Christchurch  (308089)', '308089', 'LOYDGB21739'],
    ['LLOYDS BANK PLC Cirencester (308095)', '308095', 'LOYDGB21740'],
    ['LLOYDS BANK PLC Roath Park Cardiff (308096)', '308096', 'LOYDGB21741'],
    ['TSB BANK PLC TSB (308097)', '308097', ''],
    ['Bank of Scotland plc Bos Community Bank Back Off', '308155', ''],
    ['Bank of Scotland plc Cr Ops Hbos Fp', '308167', ''],
    ['Bank of Scotland plc Credit Cards Hbos Fp', '308168', ''],
    ['Bank of Scotland plc Commercial Hbos Fp', '308169', ''],
    ['Bank of Scotland plc Credit Dec Hbos Fp', '308170', ''],
    ['Bank of Scotland plc Bancassurance Hbos Fp', '308183', ''],
    ['Bank of Scotland plc Bos Community Bank Back Off', '308184', ''],
    ['Bank of Scotland plc Mortgages Hbos Fp', '308185', ''],
    ['Bank of Scotland plc Pca Hbos Fp', '308186', ''],
    ['Bank of Scotland plc Sal Fp', '308187', ''],
    ['Bank of Scotland plc Loans Hbos Fp', '308188', ''],
    ['Bank of Scotland plc Tb Hbos Fp', '308189', ''],
    ['Bank of Scotland plc Bos Community Bank Back Off', '308190', ''],
    ['Bank of Scotland plc Bos Com Chq Fraud', '308192', ''],
    ['LLOYDS BANK PLC Pay Ops Incidents (308193)', '308193', ''],
    ['Bank of Scotland plc Bos Cash & Travel Oversight', '308195', ''],
    ['Bank of Scotland plc Lp&I Hbos Fp', '308196', ''],
    ['Bank of Scotland plc Cs Normanton Fp', '308197', ''],
    ['Bank of Scotland plc Crc - Bos Epayments', '308199', ''],
    ['LLOYDS BANK PLC Head Office - Finance (Ag7)', '308300', ''],
    ['LLOYDS BANK PLC Head Office - Finance (Ag8)', '308400', 'LOYDGB21Y98'],
    ['LLOYDS BANK PLC Clifton Bristol (308404)', '308404', 'LOYDGB21743'],
    ['LLOYDS BANK PLC Commercial Road (308412)', '308412', 'LOYDGB21744'],
    ['LLOYDS BANK PLC Yate (308420)', '308420', 'LOYDGB21745'],
    ['LLOYDS BANK PLC Cannon Street (308421)', '308421', 'LOYDGB21746'],
    ['TSB BANK PLC TSB (308422)', '308422', ''],
    ['LLOYDS BANK PLC Covent Garden (308423)', '308423', 'LOYDGB21748'],
    ['LLOYDS BANK PLC Chesham (308424)', '308424', 'LOYDGB21749'],
    ['LLOYDS BANK PLC Wednesfield (308425)', '308425', 'LOYDGB21750'],
    ['LLOYDS BANK PLC Market Sq Stafford (308426)', '308426', 'LOYDGB21751'],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Phonebank Douglas (308427)',
      '308427',
      ''
    ],
    ['LLOYDS BANK PLC Collection Centre Chester', '308431', ''],
    ['LLOYDS BANK PLC Credit Operations', '308432', ''],
    ['LLOYDS BANK PLC Erdington Bham (308433)', '308433', 'LOYDGB21752'],
    ['TSB BANK PLC TSB (308434)', '308434', ''],
    ['LLOYDS BANK PLC North St Brighton (308441)', '308441', 'LOYDGB21754'],
    ['LLOYDS BANK PLC Darlington (308443)', '308443', 'LOYDGB21755'],
    ['LLOYDS BANK PLC Long Eaton (308444)', '308444', 'LOYDGB21756'],
    ['LLOYDS BANK PLC Paddington (308445)', '308445', 'LOYDGB21757'],
    ['LLOYDS BANK PLC 120/24 High Street Dorking', '308446', 'LOYDGB21758'],
    ['TSB BANK PLC TSB (308448)', '308448', ''],
    ['LLOYDS BANK PLC 9 Mkt Sq Bromley (308451)', '308451', 'LOYDGB21760'],
    ['TSB BANK PLC TSB (308452)', '308452', ''],
    ['LLOYDS BANK PLC Piccadilly (308453)', '308453', 'LOYDGB21762'],
    ['LLOYDS BANK PLC 47 London Rd Nth Lowestoft', '308454', 'LOYDGB21763'],
    ['LLOYDS BANK PLC Richmond (308457)', '308457', 'LOYDGB21764'],
    ['LLOYDS BANK PLC The Broadway Ealing 308458', '308458', 'LOYDGB21765'],
    ['LLOYDS BANK PLC 42 Lom St Newport (308459)', '308459', 'LOYDGB21766'],
    ['LLOYDS BANK PLC Yorks St Rochdale (308462)', '308462', 'LOYDGB21767'],
    ['LLOYDS BANK PLC 142/6 High St Chatham', '308463', 'LOYDGB21768'],
    ['LLOYDS BANK PLC Courtenay St Newton Abt', '308467', 'LOYDGB21769'],
    ['LLOYDS BANK PLC Streatham High Rd (308468)', '308468', 'LOYDGB21770'],
    ['TSB BANK PLC TSB (30849)', '308469', ''],
    ['TSB BANK PLC TSB (308472)', '308472', ''],
    ['LLOYDS BANK PLC Victoria Road Consett', '308473', 'LOYDGB21773'],
    ['LLOYDS BANK PLC Fishponds (308475)', '308475', 'LOYDGB21774'],
    ['LLOYDS BANK PLC Edgware (308476)', '308476', 'LOYDGB21775'],
    ['LLOYDS BANK PLC Town Sq Stevenage (308477)', '308477', 'LOYDGB21776'],
    ['LLOYDS BANK PLC Covty St Nuneaton (308479)', '308479', 'LOYDGB21777'],
    ['LLOYDS BANK PLC Whickham (308482)', '308482', 'LOYDGB21778'],
    ['LLOYDS BANK PLC Stamford (308484)', '308484', 'LOYDGB21779'],
    ['LLOYDS BANK PLC Hertford (308485)', '308485', 'LOYDGB21780'],
    ['LLOYDS BANK PLC Watford (308486)', '308486', 'LOYDGB21781'],
    ['LLOYDS BANK PLC Ringwood (308487)', '308487', 'LOYDGB21782'],
    ['LLOYDS BANK PLC Grp Recs 308754', '308754', ''],
    ['LLOYDS BANK PLC King St South Shields', '308758', ''],
    ['LLOYDS BANK PLC Andover Saga ( 308760 )', '308760', 'LOYDGB21Y01'],
    ["LLOYDS BANK PLC Andover S'vce Ctr Hbos1", '308761', ''],
    ['LLOYDS BANK PLC Andover Sc', '308769', ''],
    ['LLOYDS BANK PLC Grp Recs 308778', '308778', ''],
    ['LLOYDS BANK PLC Andover Saga 1', '308784', 'LOYDGB21Y02'],
    ['LLOYDS BANK PLC Aqcu', '308786', ''],
    ['LLOYDS BANK PLC Banking Operations', '308788', ''],
    ['TSB BANK PLC TSB (308789)', '308789', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '308790', ''],
    ['LLOYDS BANK PLC Postal Credit Sol (308794)', '308794', ''],
    ['LLOYDS BANK PLC Portland Street5(308796)', '308796', ''],
    ['LLOYDS BANK PLC Banking Operations', '308799', ''],
    ['LLOYDS BANK PLC Palace Av Paignton 308804', '308804', 'LOYDGB21783'],
    ['LLOYDS BANK PLC 27 Fore Street Redruth', '308822', 'LOYDGB21784'],
    ['LLOYDS BANK PLC Fraud Operations (30-88-41)', '308841', ''],
    ['LLOYDS BANK PLC Lloyds Bank E&W  Isa Unit', '308889', ''],
    ['LLOYDS BANK PLC Credit Operations', '308946', ''],
    ['LLOYDS BANK PLC Credit Operations', '308947', ''],
    ['LLOYDS BANK PLC Credit Operations', '308948', ''],
    ['TSB BANK PLC Test L&U (308957)', '308957', ''],
    ['LLOYDS BANK PLC Aberdare (309001)', '309001', 'LOYDGB21621'],
    ['LLOYDS BANK PLC Abergavenny (309002)', '309002', 'LOYDGB21501'],
    ['TSB BANK PLC TSB (309004)', '309004', ''],
    ['TSB BANK PLC TSB (309006)', '309006', ''],
    ['LLOYDS BANK PLC Aldershot', '309009', 'LOYDGB21199'],
    ['LLOYDS BANK PLC Alnwick (309013)', '309013', 'LOYDGB21611'],
    ['TSB BANK PLC TSB (309015)', '309015', ''],
    ['LLOYDS BANK PLC Altrincham (309016)', '309016', 'LOYDGB21127'],
    ['TSB BANK PLC TSB (309018)', '309018', ''],
    ['LLOYDS BANK PLC High St Andover (309021)', '309021', 'LOYDGB21163'],
    ['TSB BANK PLC TSB (309024)', '309024', ''],
    ['LLOYDS BANK PLC Ashbourne (309025)', '309025', 'LOYDGB21449'],
    ['LLOYDS BANK PLC Ashford  High St', '309028', 'LOYDGB21138'],
    ['LLOYDS BANK PLC Above Bar St Southampton', '309034', 'LOYDGB21148'],
    ['LLOYDS BANK PLC Axminster (309037)', '309037', 'LOYDGB21275'],
    ['LLOYDS BANK PLC Market Sq Aylesbury 309038', '309038', 'LOYDGB21089'],
    ['LLOYDS BANK PLC Baker Street', '309039', 'LOYDGB21135'],
    ['LLOYDS BANK PLC Banbury (309042)', '309042', 'LOYDGB21005'],
    ['LLOYDS BANK PLC Bangor (309043)', '309043', 'LOYDGB21339'],
    ['LLOYDS BANK PLC Banstead', '309044', 'LOYDGB21405'],
    ['LLOYDS BANK PLC Barking', '309047', 'LOYDGB21451'],
    ['LLOYDS BANK PLC Cross St Barnstaple', '309049', 'LOYDGB21290'],
    ['TSB BANK PLC TSB (309050)', '309050', ''],
    ['LLOYDS BANK PLC Barrow in Furness (309051)', '309051', 'LOYDGB21564'],
    ['LLOYDS BANK PLC Barry Docks', '309052', 'LOYDGB21443'],
    ['LLOYDS BANK PLC Festival Place (309053)', '309053', 'LOYDGB21153'],
    ['LLOYDS BANK PLC Milsom Street Bath (309054)', '309054', 'LOYDGB21090'],
    ['LLOYDS BANK PLC Batley (309057)', '309057', 'LOYDGB21193'],
    ['TSB BANK PLC TSB (309059)', '309059', ''],
    ['TSB BANK PLC TSB (309062)', '309062', ''],
    ['LLOYDS BANK PLC High Street Bedford 309066', '309066', 'LOYDGB21204'],
    ['LLOYDS BANK PLC Victoria', '309069', 'LOYDGB21189'],
    ['LLOYDS BANK PLC Ldn Oxfd St115-117 309072', '309072', 'LOYDGB21172'],
    ['LLOYDS BANK PLC Berkhamsted', '309073', 'LOYDGB21253'],
    ['TSB BANK PLC TSB (309074)', '309074', ''],
    ['LLOYDS BANK PLC Bexleyheath', '309076', 'LOYDGB21283'],
    ['LLOYDS BANK PLC Bicester (309077)', '309077', 'LOYDGB21434'],
    ['LLOYDS BANK PLC Bideford (309078)', '309078', 'LOYDGB21424'],
    ['LLOYDS BANK PLC Biggleswade', '309079', 'LOYDGB21418'],
    ['LLOYDS BANK PLC Billericay', '309080', 'LOYDGB21291'],
    ['LLOYDS BANK PLC Bilston (309082)', '309082', 'LOYDGB21573'],
    ['LLOYDS BANK PLC Bishops Stortford', '309084', 'LOYDGB21210'],
    ['LLOYDS BANK PLC Bishops Waltham', '309085', 'LOYDGB21467'],
    ['LLOYDS BANK PLC Bitterne', '309086', 'LOYDGB21530'],
    ['LLOYDS BANK PLC Blackburn Church St', '309087', 'LOYDGB21064'],
    ['LLOYDS BANK PLC Birmingham Blackheath', '309088', 'LOYDGB21551'],
    ['LLOYDS BANK PLC Lewisham (309089)', '309089', 'LOYDGB21256'],
    ['LLOYDS BANK PLC Corporation St Blackpool', '309090', 'LOYDGB21363'],
    ['LLOYDS BANK PLC Caerphilly (309091)', '309091', 'LOYDGB21331'],
    ['LLOYDS BANK PLC Blandford (309092)', '309092', 'LOYDGB21343'],
    ['TSB BANK PLC TSB (309095)', '309095', ''],
    ['LLOYDS BANK PLC Bognor Regis', '309099', 'LOYDGB21368'],
    ['TSB BANK PLC TSB (309100)', '309100', ''],
    ['LLOYDS BANK PLC Bolton Hotel Street', '309101', 'LOYDGB21333'],
    ['LLOYDS BANK PLC Boston(309104)', '309104', 'LOYDGB21373'],
    ['LLOYDS BANK PLC Bournemouth (309108)', '309108', 'LOYDGB21045'],
    ['LLOYDS BANK PLC Bracknell (309111)', '309111', 'LOYDGB21059'],
    ['LLOYDS BANK PLC Hustlergate Bradford 309112', '309112', 'LOYDGB21016'],
    ['LLOYDS BANK PLC Bank St Braintree (309114)', '309114', 'LOYDGB21266'],
    ['LLOYDS BANK PLC Brecon (309116)', '309116', 'LOYDGB21559'],
    ['LLOYDS BANK PLC Brentwood (309117)', '309117', 'LOYDGB21252'],
    ['LLOYDS BANK PLC Wyndham St Brdgnd (309118)', '309118', 'LOYDGB21308'],
    ['LLOYDS BANK PLC Bridgnorth (309119)', '309119', 'LOYDGB21509'],
    ['LLOYDS BANK PLC Bridgewater (309120)', '309120', 'LOYDGB21234'],
    ['TSB BANK PLC TSB (309121)', '309121', ''],
    ['TSB BANK PLC TSB (309122)', '309122', ''],
    ['LLOYDS BANK PLC North St Brighton (309125)', '309125', 'LOYDGB21002'],
    ['LLOYDS BANK PLC Broad St Reading (309131)', '309131', 'LOYDGB21050'],
    ['LLOYDS BANK PLC 9 Mkt Sq Bromley (309135)', '309135', 'LOYDGB21038'],
    ['LLOYDS BANK PLC Bromsgrove (309136)', '309136', 'LOYDGB21178'],
    ['LLOYDS BANK PLC Ldn Oxfd St115-117 309137', '309137', 'LOYDGB21161'],
    ['LLOYDS BANK PLC Buckingham (309139)', '309139', 'LOYDGB21390'],
    ['TSB BANK PLC TSB (309141)', '309141', ''],
    ['TSB BANK PLC TSB (309143)', '309143', ''],
    ['LLOYDS BANK PLC Burgess Hill (309144)', '309144', 'LOYDGB21297'],
    ['LLOYDS BANK PLC Burton-on-Trent', '309147', 'LOYDGB21212'],
    ['LLOYDS BANK PLC Bury (309148)', '309148', 'LOYDGB21497'],
    ['LLOYDS BANK PLC Buttermkt Bury St Ed 309149', '309149', 'LOYDGB21200'],
    ['TSB BANK PLC TSB (309150)', '309150', ''],
    ['LLOYDS BANK PLC Camberley (309153)', '309153', 'LOYDGB21141'],
    ['LLOYDS BANK PLC Camberwell Green (309154)', '309154', 'LOYDGB21281'],
    ['LLOYDS BANK PLC University of Cambridge', '309156', 'LOYDGB21018'],
    ['LLOYDS BANK PLC Camden Town (309157)', '309157', 'LOYDGB21304'],
    ['LLOYDS BANK PLC Cannock (309158)', '309158', 'LOYDGB21257'],
    ['LLOYDS BANK PLC Cannon Street (309159)', '309159', 'LOYDGB21230'],
    ['LLOYDS BANK PLC Canterbury (309160)', '309160', 'LOYDGB21060'],
    ['TSB BANK PLC TSB (309162)', '309162', ''],
    ['LLOYDS BANK PLC 31 Queen St Cardiff', '309163', 'LOYDGB21143'],
    ['LLOYDS BANK PLC Cardigan (309165)', '309165', 'LOYDGB21602'],
    ['LLOYDS BANK PLC Carlisle (309166)', '309166', 'LOYDGB21427'],
    ['LLOYDS BANK PLC King St Carmathen (309168)', '309168', 'LOYDGB21437'],
    ['LLOYDS BANK PLC Station Ave Caterham', '309172', 'LOYDGB21151'],
    ['LLOYDS BANK PLC Catterick', '309173', 'LOYDGB21555'],
    ['LLOYDS BANK PLC Cambridge  Cattle Market', '309174', 'LOYDGB21206'],
    ['LLOYDS BANK PLC The Strand', '309179', 'LOYDGB21216'],
    ["LLOYDS BANK PLC St Paul's", '309183', 'LOYDGB21236'],
    ['LLOYDS BANK PLC Cheddar (309184)', '309184', 'LOYDGB21356'],
    ['LLOYDS BANK PLC Chelmsford 77/81 High St', '309185', 'LOYDGB21131'],
    ['LLOYDS BANK PLC Chelsea (309186)', '309186', 'LOYDGB21152'],
    ['LLOYDS BANK PLC High St Cheltenham (309187)', '309187', 'LOYDGB21054'],
    ['LLOYDS BANK PLC Chepstow (309189)', '309189', 'LOYDGB21470'],
    ['LLOYDS BANK PLC Chesham', '309191', 'LOYDGB21456'],
    ['LLOYDS BANK PLC Foregate St Chester 309192', '309192', 'LOYDGB21075'],
    ['TSB BANK PLC TSB (309193)', '309193', ''],
    ['LLOYDS BANK PLC Chester-le-St (309194)', '309194', 'LOYDGB21577'],
    ['LLOYDS BANK PLC Cambridge Chesterton Rd', '309195', ''],
    ['LLOYDS BANK PLC Coventry Cheylesmore', '309196', ''],
    ['LLOYDS BANK PLC Chichester (309197)', '309197', 'LOYDGB21233'],
    ['LLOYDS BANK PLC Chippenham (309199)', '309199', 'LOYDGB21207'],
    ['LLOYDS BANK PLC Chiswick (309201)', '309201', 'LOYDGB21336'],
    ['LLOYDS BANK PLC Christchurch (309202)', '309202', 'LOYDGB21188'],
    ['TSB BANK PLC TSB (309204)', '309204', ''],
    ['LLOYDS BANK PLC Cirencester (309206)', '309206', 'LOYDGB21102'],
    ['LLOYDS BANK PLC Roath Park Cardiff (309207)', '309207', 'LOYDGB21383'],
    ['LLOYDS BANK PLC West Avenue Clacton-on-Sea', '309209', 'LOYDGB21498'],
    ['TSB BANK PLC TSB (309210)', '309210', ''],
    ['LLOYDS BANK PLC Clifton Bristol (309213)', '309213', 'LOYDGB21159'],
    ['LLOYDS BANK PLC Coalville (309215)', '309215', 'LOYDGB21350'],
    ['LLOYDS BANK PLC High St Colchester', '309216', 'LOYDGB21115'],
    ['LLOYDS BANK PLC Chelmsley Wood Bhm (309218)', '309218', 'LOYDGB21619'],
    ['LLOYDS BANK PLC Commercial Road (309221)', '309221', 'LOYDGB21357'],
    ['LLOYDS BANK PLC Corby', '309226', 'LOYDGB21422'],
    ['LLOYDS BANK PLC Covent Garden (309232)', '309232', 'LOYDGB21048'],
    ['LLOYDS BANK PLC High St Coventry (309233)', '309233', 'LOYDGB21033'],
    ['LLOYDS BANK PLC Cradley Heath', '309235', 'LOYDGB21409'],
    ['LLOYDS BANK PLC Cranbrook (309236)', '309236', 'LOYDGB21523'],
    ['LLOYDS BANK PLC The Broadway Crawley', '309238', 'LOYDGB21162'],
    ['LLOYDS BANK PLC Crewkerne', '309240', 'LOYDGB21391'],
    ['TSB BANK PLC London, Crouch End', '309243', ''],
    ['LLOYDS BANK PLC Croydon Central (309245)', '309245', 'LOYDGB21049'],
    ['LLOYDS BANK PLC 1 Gwent Sq Cwmbran', '309249', 'LOYDGB21475'],
    ['LLOYDS BANK PLC Darlington (309252)', '309252', 'LOYDGB21254'],
    ['LLOYDS BANK PLC Dartford', '309253', 'LOYDGB21377'],
    ['LLOYDS BANK PLC Daventry (309255)', '309255', 'LOYDGB21487'],
    ['LLOYDS BANK PLC The Broadwy Loughton 309258', '309258', 'LOYDGB21392'],
    ['LLOYDS BANK PLC Derby St Peters Street', '309259', 'LOYDGB21107'],
    ['LLOYDS BANK PLC Devizes', '309263', 'LOYDGB21202'],
    ['LLOYDS BANK PLC Diss  (309267)', '309267', 'LOYDGB21432'],
    ['LLOYDS BANK PLC Doncaster 55 High St', '309268', 'LOYDGB21425'],
    ['LLOYDS BANK PLC Dorchester', '309269', 'LOYDGB21525'],
    ['LLOYDS BANK PLC Dorking 120/124 High St', '309270', 'LOYDGB21081'],
    ['TSB BANK PLC Dovercourt', '309272', ''],
    ['LLOYDS BANK PLC Dudley (309275)', '309275', 'LOYDGB21117'],
    ['TSB BANK PLC Dunmow', '309276', ''],
    ['LLOYDS BANK PLC Dunstable (309277)', '309277', 'LOYDGB21260'],
    ['LLOYDS BANK PLC Durham (309279)', '309279', 'LOYDGB21264'],
    ['LLOYDS BANK PLC Ealing The Broadway', '309282', 'LOYDGB21091'],
    ["LLOYDS BANK PLC Earl's Court", '309283', 'LOYDGB21303'],
    ['LLOYDS BANK PLC Terminus Rd Ebourne 309286', '309286', 'LOYDGB21092'],
    ['LLOYDS BANK PLC Leadenhall Street (309287)', '309287', 'LOYDGB21277'],
    ['LLOYDS BANK PLC Market Place Dereham', '309288', 'LOYDGB21384'],
    ['LLOYDS BANK PLC Camberwell Green (309289)', '309289', 'LOYDGB21393'],
    ['LLOYDS BANK PLC Eastern', '309290', 'LOYDGB21053'],
    ['LLOYDS BANK PLC East Grinstead London Rd', '309292', 'LOYDGB21174'],
    ['LLOYDS BANK PLC East Ham (309293)', '309293', 'LOYDGB21442'],
    ['LLOYDS BANK PLC Eastleigh', '309294', 'LOYDGB21209'],
    ['LLOYDS BANK PLC Ebbw Vale (309297)', '309297', 'LOYDGB21517'],
    ['LLOYDS BANK PLC Birmingham Edgbaston', '309299', 'LOYDGB21367'],
    ['TSB BANK PLC Ellesmere', '309303', ''],
    ['LLOYDS BANK PLC Palmerston Road Southsea', '309304', 'LOYDGB21361'],
    ['LLOYDS BANK PLC Minster Place Ely (309305)', '309305', 'LOYDGB21265'],
    ['LLOYDS BANK PLC Epsom (309308)', '309308', 'LOYDGB21123'],
    ["LLOYDS BANK PLC Erdington B'ham (309309)", '309309', 'LOYDGB21282'],
    ['LLOYDS BANK PLC High St Evesham (309311)', '309311', 'LOYDGB21164'],
    ['LLOYDS BANK PLC High St Exeter (309314)', '309314', 'LOYDGB21073'],
    ['TSB BANK PLC Exmouth', '309315', ''],
    ['LLOYDS BANK PLC Fareham', '309317', 'LOYDGB21177'],
    ['LLOYDS BANK PLC Brunel Centre Swin 309318', '309318', 'LOYDGB21590'],
    ['LLOYDS BANK PLC Farnham (309320)', '309320', 'LOYDGB21136'],
    ['LLOYDS BANK PLC Canterbury (309321)', '309321', ''],
    ['TSB BANK PLC Felixstowe', '309322', ''],
    ['LLOYDS BANK PLC Leadenhall Street (309323)', '309323', 'LOYDGB21009'],
    ['LLOYDS BANK PLC Ferndown (309325)', '309325', 'LOYDGB21270'],
    ['TSB BANK PLC London, Archway', '309329', ''],
    ['LLOYDS BANK PLC Fleet (309332)', '309332', 'LOYDGB21227'],
    ['LLOYDS BANK PLC Coventry Foleshill', '309333', ''],
    ['LLOYDS BANK PLC Sandgate Rd Flkston 309334', '309334', 'LOYDGB21109'],
    ['LLOYDS BANK PLC Sutton Coldfield', '309337', ''],
    ['TSB BANK PLC Frome', '309340', ''],
    ['TSB BANK PLC Gainsborough', '309341', ''],
    ['LLOYDS BANK PLC High Road Ilford (309342)', '309342', ''],
    ['LLOYDS BANK PLC Gateshead (309343)', '309343', 'LOYDGB21310'],
    ['TSB BANK PLC Romford, Gidea Park', '309344', ''],
    ['LLOYDS BANK PLC Gillingham Dorset (309345)', '309345', 'LOYDGB21284'],
    ['LLOYDS BANK PLC Eastgate St Glouc (309348)', '309348', 'LOYDGB21066'],
    ['LLOYDS BANK PLC Godalming (309349)', '309349', 'LOYDGB21195'],
    ['LLOYDS BANK PLC Golders Green', '309350', 'LOYDGB21249'],
    ['LLOYDS BANK PLC Worthing George V Ave', '309352', ''],
    ['LLOYDS BANK PLC Gorseinon (30-93-53)', '309353', 'LOYDGB21459'],
    ['LLOYDS BANK PLC High St Coventry (309354)', '309354', 'LOYDGB21326'],
    ['LLOYDS BANK PLC Gosforth (309355)', '309355', 'LOYDGB21307'],
    ['LLOYDS BANK PLC Gosport', '309356', 'LOYDGB21292'],
    ['LLOYDS BANK PLC Grantham (309358)', '309358', 'LOYDGB21155'],
    ['LLOYDS BANK PLC Et Digital Uat (309359)', '309359', 'LOYDGB21Z98'],
    ['LLOYDS BANK PLC Gravesend (309360)', '309360', 'LOYDGB21278'],
    ['LLOYDS BANK PLC Grays', '309361', 'LOYDGB21453'],
    ['LLOYDS BANK PLC Great Bridge', '309364', 'LOYDGB21633'],
    ['TSB BANK PLC Birmingham, Gt Hampton St', '309366', ''],
    ['TSB BANK PLC Great Missenden', '309367', ''],
    ['LLOYDS BANK PLC London Oxford St (309368)', '309368', 'LOYDGB21028'],
    ['TSB BANK PLC London, Greenwich', '309370', ''],
    ['LLOYDS BANK PLC Newcastle-upon-Tyne Grey St', '309371', 'LOYDGB21020'],
    ['TSB BANK PLC Grimsby, Old Market Place', '309372', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) International Csc (G-R)',
      '309373',
      'LOYDGGS1001'
    ],
    ['LLOYDS BANK PLC Guildford (309374)', '309374', 'LOYDGB21022'],
    ['TSB BANK PLC Halesowen', '309375', ''],
    ['LLOYDS BANK PLC Halifax 309376', '309376', 'LOYDGB21118'],
    ['LLOYDS BANK PLC Hammersmith (309379)', '309379', 'LOYDGB21058'],
    ['LLOYDS BANK PLC Camden Town (309380)', '309380', 'LOYDGB21065'],
    ['LLOYDS BANK PLC Kingston upon Tham 309381', '309381', ''],
    ['TSB BANK PLC Birmingham, Gt Hampton St', '309382', ''],
    ['LLOYDS BANK PLC Hanley     Fountain Sq', '309383', 'LOYDGB21080'],
    ['LLOYDS BANK PLC Ldn Oxford St 399 309384', '309384', 'LOYDGB21055'],
    ['TSB BANK PLC London, Harlesden', '309388', ''],
    ['LLOYDS BANK PLC East Gate Harlow 309389', '309389', 'LOYDGB21296'],
    ['LLOYDS BANK PLC Harpenden', '309390', 'LOYDGB21389'],
    ['LLOYDS BANK PLC Harrogate', '309391', 'LOYDGB21214'],
    ['LLOYDS BANK PLC Harrow   Station Rd', '309392', 'LOYDGB21129'],
    ['LLOYDS BANK PLC Didcot  (30-93-93)', '309393', 'LOYDGB21097'],
    ['LLOYDS BANK PLC Haslemere (309394)', '309394', 'LOYDGB21218'],
    ['LLOYDS BANK PLC Havant (309397)', '309397', 'LOYDGB21261'],
    ['LLOYDS BANK PLC Haverfordwest (309398)', '309398', 'LOYDGB21280'],
    ['LLOYDS BANK PLC Haverhill  8 High St', '309399', 'LOYDGB21347'],
    ['LLOYDS BANK PLC Carfax Oxford (309404)', '309404', 'LOYDGB21176'],
    ['LLOYDS BANK PLC Helston (309407)', '309407', 'LOYDGB21513'],
    ['LLOYDS BANK PLC Hemel Hempstead (309408)', '309408', 'LOYDGB21124'],
    ['LLOYDS BANK PLC Broad St Reading (309413)', '309413', 'LOYDGB21355'],
    ['LLOYDS BANK PLC Hereford (309414)', '309414', 'LOYDGB21201'],
    ['LLOYDS BANK PLC Hertford (309417)', '309417', 'LOYDGB21268'],
    ['LLOYDS BANK PLC Hexham (309419)', '309419', 'LOYDGB21554'],
    ['LLOYDS BANK PLC Highbury Corner', '309421', 'LOYDGB21180'],
    ['TSB BANK PLC London, Archway', '309424', ''],
    ['TSB BANK PLC TSB High Holborn', '309425', ''],
    ['TSB BANK PLC Leigh-on-Sea', '309426', ''],
    ['LLOYDS BANK PLC Whhtsthighwycombe', '309428', 'LOYDGB21010'],
    ['LLOYDS BANK PLC Hitchin (309430)', '309430', 'LOYDGB21142'],
    ['LLOYDS BANK PLC Holborn Circus', '309431', 'LOYDGB21037'],
    ["LLOYDS BANK PLC W'gate Huddersfield309432", '309432', ''],
    ['LLOYDS BANK PLC Launceston (309433)', '309433', ''],
    ['TSB BANK PLC Holt', '309434', ''],
    ['LLOYDS BANK PLC Handsworth (309435)', '309435', 'LOYDGB21617'],
    ['LLOYDS BANK PLC Honiton', '309436', 'LOYDGB21286'],
    ['LLOYDS BANK PLC Bristol Horfield', '309437', ''],
    ['LLOYDS BANK PLC Broadway Crawley (309438)', '309438', 'LOYDGB21042'],
    ['LLOYDS BANK PLC Horncastle (309439)', '309439', 'LOYDGB21461'],
    ['LLOYDS BANK PLC Horsham', '309441', 'LOYDGB21084'],
    ['LLOYDS BANK PLC Staines (309442)', '309442', 'LOYDGB21112'],
    ['LLOYDS BANK PLC Huddersfield Westgate', '309443', 'LOYDGB21026'],
    ['LLOYDS BANK PLC Hull City Centre', '309444', 'LOYDGB21149'],
    ['TSB BANK PLC Hungerford', '309446', ''],
    ['LLOYDS BANK PLC Huntingdon', '309447', 'LOYDGB21096'],
    ['LLOYDS BANK PLC Southampton Hythe', '309449', ''],
    ['LLOYDS BANK PLC Ilford', '309451', 'LOYDGB21318'],
    ['LLOYDS BANK PLC Ilfracombe (309452)', '309452', 'LOYDGB21534'],
    ['TSB BANK PLC Ilkley (309453)', '309453', ''],
    ['LLOYDS BANK PLC Ilminster', '309454', ''],
    ['LLOYDS BANK PLC Cornhill North Ipswich', '309455', 'LOYDGB21046'],
    ['LLOYDS BANK PLC Islington  19/20 Upper St', '309457', 'LOYDGB21158'],
    ['LLOYDS BANK PLC Ivybridge', '309458', 'LOYDGB21429'],
    ['TSB BANK PLC Jarrow', '309460', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) St Helier Jersey (309461)',
      '309461',
      'LOYDJES1001'
    ],
    ['LLOYDS BANK PLC Keighley', '309462', ''],
    ['LLOYDS BANK PLC North St Brighton 309463', '309463', 'LOYDGB21365'],
    ['LLOYDS BANK PLC Kenilworth', '309464', ''],
    ['LLOYDS BANK PLC Kensington High Street', '309465', 'LOYDGB21025'],
    ['LLOYDS BANK PLC Kentish Town', '309466', 'LOYDGB21484'],
    ['LLOYDS BANK PLC Kettering (309468)', '309468', 'LOYDGB21313'],
    ['LLOYDS BANK PLC Kidderminster  Vicar St', '309470', 'LOYDGB21099'],
    ['LLOYDS BANK PLC Kilburn (309471)', '309471', 'LOYDGB21457'],
    ['LLOYDS BANK PLC Kingsbridge', '309472', 'LOYDGB21430'],
    ['LLOYDS BANK PLC Kings Cross', '309473', 'LOYDGB21421'],
    ['LLOYDS BANK PLC Kings Heath', '309474', 'LOYDGB21295'],
    ['TSB BANK PLC Kings Lynn', '309475', ''],
    ['LLOYDS BANK PLC Kingston  U Thames (309477)', '309477', 'LOYDGB21057'],
    ['LLOYDS BANK PLC London Oxford St 115-117', '309479', 'LOYDGB21105'],
    ['LLOYDS BANK PLC Kingswood Regent St', '309480', 'LOYDGB21246'],
    ['LLOYDS BANK PLC Knightsbridge', '309481', 'LOYDGB21024'],
    ['LLOYDS BANK PLC Knowle Solihull', '309482', ''],
    ['LLOYDS BANK PLC Knowle Bristol', '309483', 'LOYDGB21408'],
    ['LLOYDS BANK PLC Lampeter (309485)', '309485', 'LOYDGB21595'],
    ['LLOYDS BANK PLC London Oxford St (309487)', '309487', 'LOYDGB21322'],
    ['TSB BANK PLC TSB Bournemouth (309490)', '309490', ''],
    ['LLOYDS BANK PLC Launceston (309491)', '309491', 'LOYDGB21569'],
    ['LLOYDS BANK PLC Leadenhall Street (309492)', '309492', 'LOYDGB21166'],
    ['LLOYDS BANK PLC Leamington Spa  (309493)', '309493', 'LOYDGB21137'],
    ['LLOYDS BANK PLC High St Leicester (309497)', '309497', 'LOYDGB21029'],
    ['LLOYDS BANK PLC Leominster (309499)', '309499', 'LOYDGB21499'],
    ['LLOYDS BANK PLC Cliffe Lewes (309501)', '309501', 'LOYDGB21103'],
    ['LLOYDS BANK PLC Lewisham (309502)', '309502', 'LOYDGB21570'],
    ['TSB BANK PLC London, Leytonstone', '309503', ''],
    ['LLOYDS BANK PLC Lichfield (309504)', '309504', 'LOYDGB21458'],
    ['LLOYDS BANK PLC High St Lincoln (309505)', '309505', 'LOYDGB21192'],
    ['LLOYDS BANK PLC Liskeard (309508)', '309508', 'LOYDGB21465'],
    ['LLOYDS BANK PLC Littlehampton (309509)', '309509', 'LOYDGB21309'],
    ["LLOYDS BANK PLC Church St Liverp'l 309511", '309511', 'LOYDGB21047'],
    ['LLOYDS BANK PLC Ammanford (309512)', '309512', 'LOYDGB21630'],
    ['LLOYDS BANK PLC Mostyn St Llandudno309513', '309513', 'LOYDGB21314'],
    ['LLOYDS BANK PLC Llanelli (309514)', '309514', 'LOYDGB21302'],
    ['TSB BANK PLC Longton', '309519', ''],
    ['LLOYDS BANK PLC High St Loughboro (309521)', '309521', 'LOYDGB21338'],
    ['LLOYDS BANK PLC Mercer Row Louth (309522)', '309522', 'LOYDGB21150'],
    ['LLOYDS BANK PLC Lowestoft, 47 London Rd Nth', '309524', 'LOYDGB21315'],
    ['TSB BANK PLC TSB High Holborn', '309526', ''],
    ['LLOYDS BANK PLC Ludlow (309527)', '309527', 'LOYDGB21316'],
    ['LLOYDS BANK PLC George St Luton (309528)', '309528', 'LOYDGB21067'],
    ['LLOYDS BANK PLC Lydney (309529)', '309529', 'LOYDGB21447'],
    ['LLOYDS BANK PLC Stourbridge (309530)', '309530', 'LOYDGB21448'],
    ['LLOYDS BANK PLC Lymington (309532)', '309532', 'LOYDGB21186'],
    ['TSB BANK PLC Maesteg', '309534', ''],
    ['LLOYDS BANK PLC Paddington (309535)', '309535', 'LOYDGB21466'],
    ['LLOYDS BANK PLC Maidenhead', '309536', 'LOYDGB21030'],
    ['LLOYDS BANK PLC Maidstone    Week St', '309537', 'LOYDGB21121'],
    ['LLOYDS BANK PLC Cirencester (309539)', '309539', ''],
    ['LLOYDS BANK PLC Belle Vue Terrace Malvern', '309541', 'LOYDGB21139'],
    ['LLOYDS BANK PLC Market St Manchester 309542', '309542', 'LOYDGB21004'],
    ['LLOYDS BANK PLC Mansfield (309543)', '309543', 'LOYDGB21410'],
    ['LLOYDS BANK PLC Cecil Square Margate', '309545', 'LOYDGB21433'],
    ['LLOYDS BANK PLC Oxford St  Swansea', '309546', 'LOYDGB21101'],
    ['LLOYDS BANK PLC Market Harborough (309547)', '309547', 'LOYDGB21441'],
    ['TSB BANK PLC Market Weighton', '309548', ''],
    ['LLOYDS BANK PLC Marlborough', '309549', ''],
    ['TSB BANK PLC Melton Mowbray', '309552', ''],
    ['LLOYDS BANK PLC Merthyr Tydfil (309555)', '309555', 'LOYDGB21548'],
    ['LLOYDS BANK PLC Middlesbrough (309556)', '309556', 'LOYDGB21128'],
    ['TSB BANK PLC Midsomer Norton', '309557', ''],
    ['LLOYDS BANK PLC Newmarket (309558)', '309558', 'LOYDGB21360'],
    ['TSB BANK PLC Minehead', '309566', ''],
    ['LLOYDS BANK PLC Leadenhall Street (309567)', '309567', 'LOYDGB21243'],
    ['LLOYDS BANK PLC Monmouth', '309571', 'LOYDGB21493'],
    ['LLOYDS BANK PLC High St Cheltenham (309572)', '309572', 'LOYDGB21108'],
    ['LLOYDS BANK PLC Cheapside (309574)', '309574', 'LOYDGB21027'],
    ['LLOYDS BANK PLC Moreton-in-Marsh', '309575', 'LOYDGB21385'],
    ['TSB BANK PLC Morpeth', '309576', ''],
    ['LLOYDS BANK PLC Windsor Rd Neath (309586)', '309586', 'LOYDGB21591'],
    ['LLOYDS BANK PLC Dudley (309587)', '309587', 'LOYDGB21550'],
    ['TSB BANK PLC Newark', '309588', ''],
    ['LLOYDS BANK PLC Newbury (309589)', '309589', 'LOYDGB21041'],
    ['LLOYDS BANK PLC Newcastle-U-Lyme 309591', '309591', 'LOYDGB21255'],
    ['LLOYDS BANK PLC Newmarket', '309596', 'LOYDGB21380'],
    ['LLOYDS BANK PLC Newport Isle of Wight', '309599', 'LOYDGB21641'],
    ['LLOYDS BANK PLC 42 Com St Newport (309600)', '309600', 'LOYDGB21145'],
    ['LLOYDS BANK PLC Newport Shropshire', '309602', 'LOYDGB21541'],
    ['LLOYDS BANK PLC Chester Rd Newquay', '309603', 'LOYDGB21607'],
    ['LLOYDS BANK PLC Newton Abbot (309606)', '309606', 'LOYDGB21078'],
    ['LLOYDS BANK PLC Balham (309607)', '309607', 'LOYDGB21175'],
    ['TSB BANK PLC Northallerton', '309608', ''],
    ['LLOYDS BANK PLC George Row Northmptn 309609', '309609', 'LOYDGB21071'],
    ['LLOYDS BANK PLC Portsmouth   North End', '309611', 'LOYDGB21154'],
    ['LLOYDS BANK PLC Northfield (309612)', '309612', 'LOYDGB21372'],
    ['LLOYDS BANK PLC North Shields 309615', '309615', 'LOYDGB21211'],
    ['LLOYDS BANK PLC North Walsham', '309616', 'LOYDGB21519'],
    ["LLOYDS BANK PLC Norwich  Gentleman's Walk", '309617', 'LOYDGB21076'],
    ['LLOYDS BANK PLC Parliament St (309618)', '309618', 'LOYDGB21069'],
    ['LLOYDS BANK PLC Notting Hill Gate', '309619', 'LOYDGB21111'],
    ['LLOYDS BANK PLC Nuneaton (309620)', '309620', 'LOYDGB21198'],
    ['LLOYDS BANK PLC Okehampton (309623)', '309623', 'LOYDGB21436'],
    ['LLOYDS BANK PLC Piccadilly  (309624)', '309624', 'LOYDGB21014'],
    ['TSB BANK PLC Oldbury', '309625', ''],
    ['LLOYDS BANK PLC Oldham', '309626', 'LOYDGB21446'],
    ['LLOYDS BANK PLC Blackheath', '309627', 'LOYDGB21639'],
    ['TSB BANK PLC Birmingham, Priory', '309628', ''],
    ['TSB BANK PLC London, Old Street', '309629', ''],
    ['LLOYDS BANK PLC Orpington (309631)', '309631', 'LOYDGB21015'],
    ['TSB BANK PLC Oswestry', '309633', ''],
    ['LLOYDS BANK PLC International Sers Office', '309634', 'LOYDGB2LXXX'],
    ['LLOYDS BANK PLC Carfax Oxford (309635)', '309635', 'LOYDGB21023'],
    ['LLOYDS BANK PLC 399 Oxford Street', '309638', 'LOYDGB21119'],
    ['LLOYDS BANK PLC Paddington (309640)', '309640', 'LOYDGB21062'],
    ['LLOYDS BANK PLC Palace Av Paignton 309641', '309641', 'LOYDGB21496'],
    ['LLOYDS BANK PLC Ldn Oxfdst 115-117 309648', '309648', 'LOYDGB21088'],
    ['LLOYDS BANK PLC High St Poole (309650)', '309650', 'LOYDGB21375'],
    ['LLOYDS BANK PLC Penarth', '309652', 'LOYDGB21553'],
    ['LLOYDS BANK PLC Beaconsfield', '309654', 'LOYDGB21224'],
    ['LLOYDS BANK PLC Penzance (309656)', '309656', 'LOYDGB21293'],
    ['TSB BANK PLC Peterborough, Long Causeway', '309660', ''],
    ['LLOYDS BANK PLC Petersfield', '309661', 'LOYDGB21276'],
    ['LLOYDS BANK PLC Piccadilly (309664)', '309664', 'LOYDGB21085'],
    ["TSB BANK PLC St Ann's Road Harrow", '309666', ''],
    ['TSB BANK PLC Pitsea (309667)', '309667', ''],
    ['LLOYDS BANK PLC Ryl Parade Plymouth 309668', '309668', 'LOYDGB21082'],
    ['LLOYDS BANK PLC Pontypridd   Market Sq', '309672', 'LOYDGB21397'],
    ['LLOYDS BANK PLC Poole   High St', '309673', 'LOYDGB21036'],
    ['LLOYDS BANK PLC Port Talbot (309682)', '309682', 'LOYDGB21413'],
    ['LLOYDS BANK PLC North St Brighton 309683', '309683', 'LOYDGB21219'],
    ['LLOYDS BANK PLC Preston    Fishergate', '309685', 'LOYDGB21165'],
    ['TSB BANK PLC Princes Risborough', '309686', ''],
    ['LLOYDS BANK PLC Putney (309688)', '309688', 'LOYDGB21312'],
    ['LLOYDS BANK PLC 31 Queen St Cardiff', '309691', 'LOYDGB21217'],
    ['LLOYDS BANK PLC Ramsgate (309693)', '309693', 'LOYDGB21239'],
    ['LLOYDS BANK PLC Rayleigh (309694)', '309694', 'LOYDGB21417'],
    ['LLOYDS BANK PLC Broad Street Reading', '309696', 'LOYDGB21011'],
    ['LLOYDS BANK PLC Redditch', '309697', 'LOYDGB21133'],
    ['LLOYDS BANK PLC Redruth (309700)', '309700', 'LOYDGB21298'],
    ['LLOYDS BANK PLC Piccadilly (309702)', '309702', 'LOYDGB21190'],
    ['LLOYDS BANK PLC Richmond (309706)', '309706', 'LOYDGB21110'],
    ['LLOYDS BANK PLC Watford (309707)', '309707', 'LOYDGB21522'],
    ['LLOYDS BANK PLC Ringwood (309708)', '309708', 'LOYDGB21182'],
    ['LLOYDS BANK PLC Yorks St Rochdale (309711)', '309711', 'LOYDGB21567'],
    ['LLOYDS BANK PLC Chatham (142-146 High St)', '309712', 'LOYDGB21120'],
    ['LLOYDS BANK PLC Market Pl Romford (309713)', '309713', 'LOYDGB21170'],
    ['TSB BANK PLC Romsey', '309714', ''],
    ['LLOYDS BANK PLC Royston (309716)', '309716', 'LOYDGB21184'],
    ['LLOYDS BANK PLC Rugby (309717)', '309717', 'LOYDGB21226'],
    ['LLOYDS BANK PLC Ruislip (309719)', '309719', 'LOYDGB21213'],
    ['TSB BANK PLC Rushden', '309720', ''],
    ['LLOYDS BANK PLC Ryde (309721)', '309721', 'LOYDGB21401'],
    ['LLOYDS BANK PLC Saffron Walden', '309724', 'LOYDGB21306'],
    ['LLOYDS BANK PLC St Albans (309725)', '309725', 'LOYDGB21185'],
    ['LLOYDS BANK PLC St Austell (309728)', '309728', 'LOYDGB21319'],
    ['TSB BANK PLC St Marychurch Torquay', '309735', ''],
    ['LLOYDS BANK PLC Salisbury', '309741', 'LOYDGB21063'],
    ['LLOYDS BANK PLC Shanklin', '309742', 'LOYDGB21596'],
    ['LLOYDS BANK PLC Scarborough (309743)', '309743', 'LOYDGB21440'],
    ['LLOYDS BANK PLC High St Scunthorpe 309744', '309744', 'LOYDGB21263'],
    ['LLOYDS BANK PLC Sevenoaks', '309749', 'LOYDGB21125'],
    ['LLOYDS BANK PLC High St Sheffield (309751)', '309751', 'LOYDGB21040'],
    ['TSB BANK PLC Nottingham, Sherwood', '309755', ''],
    ['LLOYDS BANK PLC Shirley West Mids (309757)', '309757', 'LOYDGB21414'],
    ['LLOYDS BANK PLC Shirley   Southampton', '309758', 'LOYDGB21535'],
    ['LLOYDS BANK PLC Pride Hill Shrsbury 309762', '309762', 'LOYDGB21301'],
    ['LLOYDS BANK PLC Hastings (309766)', '309766', 'LOYDGB21140'],
    ['LLOYDS BANK PLC Skegness  Lumley Rd', '309767', 'LOYDGB21471'],
    ['LLOYDS BANK PLC Sleaford (309770)', '309770', 'LOYDGB21557'],
    ['LLOYDS BANK PLC Chelsea (309771)', '309771', 'LOYDGB21181'],
    ['LLOYDS BANK PLC High St Slough', '309773', 'LOYDGB21087'],
    ['TSB BANK PLC Sparkhill Birmingham', '309776', ''],
    ['LLOYDS BANK PLC Smethwick', '309777', 'LOYDGB21604'],
    ['LLOYDS BANK PLC Poplar Rd.Solihull (309778)', '309778', 'LOYDGB21104'],
    ['TSB BANK PLC Southampton, High Street', '309780', ''],
    ['LLOYDS BANK PLC London Oxford St 115-117', '309781', 'LOYDGB21034'],
    ['LLOYDS BANK PLC Southend-on-Sea', '309784', 'LOYDGB21100'],
    ['LLOYDS BANK PLC South Harrow (309785)', '309785', 'LOYDGB21514'],
    ['LLOYDS BANK PLC South Kensington', '309786', 'LOYDGB21051'],
    ['LLOYDS BANK PLC Southport', '309788', 'LOYDGB21130'],
    ['LLOYDS BANK PLC South Shields  King St', '309789', 'LOYDGB21404'],
    ['LLOYDS BANK PLC London Bridge', '309790', 'LOYDGB21351'],
    ['LLOYDS BANK PLC Spalding (309795)', '309795', 'LOYDGB21288'],
    ['TSB BANK PLC Sparkhill Birmingham', '309797', ''],
    ['LLOYDS BANK PLC Stone (309800)', '309800', 'LOYDGB21221'],
    ['LLOYDS BANK PLC Stamford (309802)', '309802', 'LOYDGB21477'],
    ['TSB BANK PLC London, Stamford Hill', '309803', ''],
    ['LLOYDS BANK PLC Victoria Rd Consett 309804', '309804', 'LOYDGB21516'],
    ['LLOYDS BANK PLC Fishponds (309806)', '309806', 'LOYDGB21289'],
    ['LLOYDS BANK PLC Edgware (309807)', '309807', 'LOYDGB21068'],
    ['LLOYDS BANK PLC Town Sq Stevenage (309808)', '309808', 'LOYDGB21215'],
    ['LLOYDS BANK PLC Stockport (309812)', '309812', 'LOYDGB21394'],
    ['LLOYDS BANK PLC Stockton-O-Tees  27 High St', '309813', 'LOYDGB21329'],
    ['TSB BANK PLC Stamford Hill (309814)', '309814', ''],
    ['LLOYDS BANK PLC Stourbridge (309821)', '309821', 'LOYDGB21352'],
    ['TSB BANK PLC Stourport-on-Severn', '309822', ''],
    ['TSB BANK PLC Stowmarket', '309823', ''],
    ['LLOYDS BANK PLC Stratford U Avon (309826)', '309826', 'LOYDGB21093'],
    ['LLOYDS BANK PLC Street', '309828', 'LOYDGB21241'],
    ['LLOYDS BANK PLC Stroud        Rowcroft', '309829', 'LOYDGB21056'],
    ['LLOYDS BANK PLC Sudbury (309831)', '309831', 'LOYDGB21251'],
    ['LLOYDS BANK PLC Fawcett St Sunderland', '309834', 'LOYDGB21156'],
    ['LLOYDS BANK PLC High Street Sutton (309836)', '309836', 'LOYDGB21235'],
    ['LLOYDS BANK PLC Bham Rd Ston Coldfd 309837', '309837', 'LOYDGB21191'],
    ['LLOYDS BANK PLC Swindon   Old Town', '309841', 'LOYDGB21106'],
    ['LLOYDS BANK PLC Sydenham (309842)', '309842', 'LOYDGB21344'],
    ['TSB BANK PLC Tadworth', '309843', ''],
    ['LLOYDS BANK PLC Tamworth', '309844', 'LOYDGB21330'],
    ['LLOYDS BANK PLC Taunton  31 Fore St', '309845', 'LOYDGB21113'],
    ['TSB BANK PLC Tavistock', '309846', ''],
    ['TSB BANK PLC Archway (309849)', '309849', ''],
    ['TSB BANK PLC Tenbury Wells', '309852', ''],
    ['LLOYDS BANK PLC Thame', '309856', 'LOYDGB21228'],
    ['LLOYDS BANK PLC Falmouth (309857)', '309857', 'LOYDGB21362'],
    ['LLOYDS BANK PLC Thetford', '309858', 'LOYDGB21371'],
    ['LLOYDS BANK PLC Tiverton', '309861', 'LOYDGB21386'],
    ['LLOYDS BANK PLC Surbiton (309862)', '309862', 'LOYDGB21411'],
    ['LLOYDS BANK PLC Tonbridge (309863)', '309863', 'LOYDGB21332'],
    ['LLOYDS BANK PLC Fleet St Torquay (309867)', '309867', 'LOYDGB21353'],
    ['LLOYDS BANK PLC Totnes', '309869', 'LOYDGB21183'],
    ['TSB BANK PLC London, Tottenham', '309870', ''],
    ['LLOYDS BANK PLC London Oxford St 115-117', '309871', 'LOYDGB21006'],
    ['LLOYDS BANK PLC Southampton   Totton', '309873', 'LOYDGB21419'],
    ['LLOYDS BANK PLC Hove  (309874)', '309874', 'LOYDGB21146'],
    ['LLOYDS BANK PLC Trowbridge', '309875', 'LOYDGB21205'],
    ['LLOYDS BANK PLC Boscawen St Truro (309876)', '309876', 'LOYDGB21396'],
    ['LLOYDS BANK PLC Tunbridge Wells (309877)', '309877', 'LOYDGB21074'],
    ['LLOYDS BANK PLC Twickenham (309879)', '309879', 'LOYDGB21098'],
    ['LLOYDS BANK PLC Oxford St Swansea', '309885', 'LOYDGB21505'],
    ['LLOYDS BANK PLC Uttoxeter', '309890', 'LOYDGB21592'],
    ['LLOYDS BANK PLC Uxbridge', '309891', 'LOYDGB21094'],
    ['LLOYDS BANK PLC Leeds City Centre(309893)', '309893', 'LOYDGB21229'],
    ['LLOYDS BANK PLC Cardiff  Victoria Park', '309894', 'LOYDGB21454'],
    ['LLOYDS BANK PLC Victoria (309897)', '309897', 'LOYDGB21031'],
    ['LLOYDS BANK PLC Wadebridge', '309898', 'LOYDGB21583'],
    ['LLOYDS BANK PLC Wakefield  Westgate', '309901', 'LOYDGB21279'],
    ['LLOYDS BANK PLC Fulham', '309902', 'LOYDGB21334'],
    ['LLOYDS BANK PLC Wallingford', '309903', 'LOYDGB21311'],
    ['TSB BANK PLC Wallington', '309904', ''],
    ['LLOYDS BANK PLC The Bridge Walsall', '309906', 'LOYDGB21083'],
    ['LLOYDS BANK PLC Walthamstow (309908)', '309908', 'LOYDGB21244'],
    ['LLOYDS BANK PLC Walton-on-Thames (309909)', '309909', 'LOYDGB21187'],
    ['TSB BANK PLC London, Wandsworth', '309910', ''],
    ['TSB BANK PLC Wantage, Market Place', '309911', ''],
    ['LLOYDS BANK PLC Wareham', '309912', 'LOYDGB21402'],
    ['LLOYDS BANK PLC Warminster  Market Pl', '309913', 'LOYDGB21294'],
    ['LLOYDS BANK PLC Horsemarket Street (309914)', '309914', 'LOYDGB21095'],
    ['LLOYDS BANK PLC Warwick', '309915', 'LOYDGB21285'],
    ['TSB BANK PLC Liverpool, Waterloo', '309919', ''],
    ['LLOYDS BANK PLC Waterlooville', '309920', 'LOYDGB21271'],
    ['LLOYDS BANK PLC Watford (309921)', '309921', 'LOYDGB21147'],
    ['LLOYDS BANK PLC Wednesbury  Holyhead Rd', '309925', 'LOYDGB21455'],
    ['LLOYDS BANK PLC Wellingborough (309926)', '309926', 'LOYDGB21480'],
    ['TSB BANK PLC Wellington', '309927', ''],
    ['LLOYDS BANK PLC Wellington Somerset', '309928', ''],
    ['LLOYDS BANK PLC Wells', '309929', 'LOYDGB21398'],
    ['LLOYDS BANK PLC Welshpool', '309930', 'LOYDGB21609'],
    ['LLOYDS BANK PLC Welwyn Grdn City (309931)', '309931', 'LOYDGB21272'],
    ['LLOYDS BANK PLC Wembley (309932)', '309932', 'LOYDGB21346'],
    ['LLOYDS BANK PLC Bournemouth (309933)', '309933', ''],
    ['LLOYDS BANK PLC Hustlergate Bradford', '309934', ''],
    ['LLOYDS BANK PLC West Bromwich  293 High St', '309936', 'LOYDGB21416'],
    ['LLOYDS BANK PLC Westbury-on-Trym (309938)', '309938', 'LOYDGB21262'],
    ['LLOYDS BANK PLC West Byfleet', '309939', ''],
    ['LLOYDS BANK PLC West Drayton', '309942', ''],
    ['LLOYDS BANK PLC Hartlepool (309947)', '309947', 'LOYDGB21438'],
    ['LLOYDS BANK PLC Heswall (309949)', '309949', ''],
    ['LLOYDS BANK PLC Victoria (309950)', '309950', 'LOYDGB21287'],
    ['LLOYDS BANK PLC Weston-super-Mare', '309951', 'LOYDGB21232'],
    ['LLOYDS BANK PLC Holborn Circus', '309952', 'LOYDGB21462'],
    ['LLOYDS BANK PLC Christchurch (309953)', '309953', ''],
    ['LLOYDS BANK PLC West Wickham', '309954', ''],
    ['LLOYDS BANK PLC Weybridge (309955)', '309955', 'LOYDGB21171'],
    ['LLOYDS BANK PLC Weymouth', '309956', 'LOYDGB21348'],
    ['LLOYDS BANK PLC Cardiff Whitchurch', '309957', ''],
    ['TSB BANK PLC Whitchurch', '309958', ''],
    ['LLOYDS BANK PLC Whitstable  14 High St', '309961', 'LOYDGB21533'],
    ['LLOYDS BANK PLC London Oxford St (309962)', '309962', 'LOYDGB21327'],
    ['LLOYDS BANK PLC Willenhall    Market Place', '309963', 'LOYDGB21349'],
    ['LLOYDS BANK PLC Willesden Green', '309964', 'LOYDGB21169'],
    ['TSB BANK PLC Wilmslow', '309965', ''],
    ['LLOYDS BANK PLC Wimbledon  St Georges Rd', '309966', 'LOYDGB21070'],
    ['TSB BANK PLC Wimborne', '309968', ''],
    ['LLOYDS BANK PLC Winchester  High St', '309971', 'LOYDGB21179'],
    ['LLOYDS BANK PLC Windsor & Eton', '309972', 'LOYDGB21245'],
    ['TSB BANK PLC Winslow', '309974', ''],
    ['LLOYDS BANK PLC Wisbech  (309977)', '309977', 'LOYDGB21483'],
    ['LLOYDS BANK PLC Witney (309978)', '309978', 'LOYDGB21299'],
    ['LLOYDS BANK PLC Woking (309980)', '309980', 'LOYDGB21061'],
    ['LLOYDS BANK PLC Wokingham', '309981', ''],
    ['LLOYDS BANK PLC Queen Sq Wolverhamptn309983', '309983', 'LOYDGB21114'],
    ['LLOYDS BANK PLC Woodsbridge (309985)', '309985', 'LOYDGB21507'],
    ['LLOYDS BANK PLC North Middlesex Group', '309986', 'LOYDGB21043'],
    ["LLOYDS BANK PLC Above Bar St S'thamp", '309987', 'LOYDGB21376'],
    ['LLOYDS BANK PLC Woolwich (309988)', '309988', 'LOYDGB21582'],
    ['LLOYDS BANK PLC Wootton Bassett', '309989', ''],
    ['LLOYDS BANK PLC The Cross Worcester 309990', '309990', 'LOYDGB21126'],
    ['LLOYDS BANK PLC New Malden (309992)', '309992', ''],
    ['LLOYDS BANK PLC South Str Worthing 309993', '309993', 'LOYDGB21132'],
    ['LLOYDS BANK PLC Wotton-under-Edge', '309994', ''],
    ['LLOYDS BANK PLC Wrexham  Regent St', '309995', 'LOYDGB21423'],
    ['LLOYDS BANK PLC Great Yarmouth  (309997)', '309997', 'LOYDGB21247'],
    ['LLOYDS BANK PLC Yeovil', '309998', 'LOYDGB21208'],
    ['LLOYDS BANK PLC Pavement York (309999)', '309999', 'LOYDGB21225'],
    ['LLOYDS BANK PLC Scottish Water  Horizons', '390000', ''],
    ['LLOYDS BANK PLC City of Westminster', '390019', ''],
    ['LLOYDS BANK PLC Maidstone B/C', '390027', ''],
    ['LLOYDS BANK PLC East Devon Distict Council', '390043', ''],
    ['LLOYDS BANK PLC Lb Hackney Ctax Account', '390051', ''],
    ['LLOYDS BANK PLC Lb Hackney Lease Holders', '390078', ''],
    ['LLOYDS BANK PLC Lb Hackney Nndr', '390086', ''],
    ['LLOYDS BANK PLC Lb Hackney General Fund', '390094', ''],
    ['LLOYDS BANK PLC Cheshire West and Chester C', '390116', ''],
    ['LLOYDS BANK PLC Lbe Corporate Collection SE', '390124', ''],
    ['LLOYDS BANK PLC Lbe Adult Social Care', '390132', ''],
    ['LLOYDS BANK PLC Lbe Council Tax', '390140', ''],
    ['LLOYDS BANK PLC Lbe Housing Rents', '390167', ''],
    ['LLOYDS BANK PLC Lbe Leaseholder Services', '390175', ''],
    ['LLOYDS BANK PLC Derby City Council', '390183', ''],
    ['LLOYDS BANK PLC Children in Need Pudsey Sch', '390191', ''],
    ['LLOYDS BANK PLC Nottingham City Council', '390205', ''],
    ['LLOYDS BANK PLC Derbyshire C & C School Cas', '390221', ''],
    ['LLOYDS BANK PLC Derbyshire C & C County Fun', '390248', ''],
    ['LLOYDS BANK PLC Southampton City Council', '390256', ''],
    ['LLOYDS BANK PLC Gwalia Housing Society Ltd', '390261', ''],
    ['LLOYDS BANK PLC Slough Borough Council  Ren', '390272', ''],
    ['LLOYDS BANK PLC Slough Borough Council Soci', '390280', ''],
    ['LLOYDS BANK PLC Slough Borough Council  Cou', '390299', ''],
    ['LLOYDS BANK PLC Portsmouth Water', '390302', ''],
    ['LLOYDS BANK PLC Damartex UK Ltd', '390310', ''],
    ['LLOYDS BANK PLC Johnston Publishing Ltd', '390366', ''],
    ['LLOYDS BANK PLC Northern Electric plc', '390538', ''],
    ['LLOYDS BANK PLC Woking Borough Council', '390554', ''],
    ['LLOYDS BANK PLC Bradford & Northern Ita Ltd', '390562', ''],
    ['LLOYDS BANK PLC City of Westminster', '390570', ''],
    ['LLOYDS BANK PLC Severn Trent Water Limited', '390643', ''],
    ['LLOYDS BANK PLC London Borough of Merton', '390656', ''],
    ['LLOYDS BANK PLC Oxfordshire County Council', '390708', ''],
    ['LLOYDS BANK PLC Metropolitan Home Ownership', '390724', ''],
    ['LLOYDS BANK PLC Scottish Widows', '390732', ''],
    ['LLOYDS BANK PLC Ldn Boro of Merton- Cncl Ta', '390753', ''],
    ['LLOYDS BANK PLC West Lindsey District Counc', '390759', ''],
    ['LLOYDS BANK PLC Robinson Way Limited', '390791', ''],
    ['LLOYDS BANK PLC Robinson Way Limited', '390805', ''],
    ['LLOYDS BANK PLC Ideal Telco', '390813', ''],
    ['LLOYDS BANK PLC Power Relate Energy', '390821', ''],
    ['LLOYDS BANK PLC East Devon District Council', '390899', ''],
    ['LLOYDS BANK PLC Essex County Council', '391003', ''],
    ['LLOYDS BANK PLC Hypovereins Bank', '391038', ''],
    ['LLOYDS BANK PLC Scottish Water', '391046', ''],
    ['LLOYDS BANK PLC Universal Credit Ltd', '391555', ''],
    ['LLOYDS BANK PLC Ldn Bor Merton', '391652', ''],
    ['LLOYDS BANK PLC Eagle Star', '391759', ''],
    ['LLOYDS BANK PLC Sutton District Water', '392856', ''],
    ['LLOYDS BANK PLC Npower Northern Ltd Bgc', '392857', ''],
    ['LLOYDS BANK PLC Severn Trent Water Auth', '393163', ''],
    ['LLOYDS BANK PLC London Bor Merton Cc', '393655', ''],
    ['LLOYDS BANK PLC Oxfordshire County Council', '393752', ''],
    ['LLOYDS BANK PLC Woking Borough Council', '393755', ''],
    ['LLOYDS BANK PLC Anchor Housing Ass Rent A/C', '394069', ''],
    ['LLOYDS BANK PLC Cow-Council Tax', '394167', ''],
    ['LLOYDS BANK PLC Cotswold District Council', '394553', ''],
    ['LLOYDS BANK PLC London Bor of Merton Nndr', '394651', ''],
    ['LLOYDS BANK PLC Essex C.C Public A/C', '394751', ''],
    ['LLOYDS BANK PLC Sutton + East Surrey Water', '394859', ''],
    ['LLOYDS BANK PLC South Shropshire Dist Co', '394956', ''],
    ['LLOYDS BANK PLC Longhurst & Havelok Homes L', '394957', ''],
    ['LLOYDS BANK PLC Cow-Homeless Families', '395065', ''],
    ['LLOYDS BANK PLC Selwood Housing Society Ltd', '395163', ''],
    ['LLOYDS BANK PLC West Hampshire Water Co', '395754', ''],
    ['LLOYDS BANK PLC Essex C.C Estab Ac', '395758', ''],
    ['LLOYDS BANK PLC Cow-Housing Rents', '395835', ''],
    ['LLOYDS BANK PLC Calortex Ltd', '396061', ''],
    ['LLOYDS BANK PLC Cow-Nndr', '396062', ''],
    ['LLOYDS BANK PLC Severn Trent Water Auth', '396162', ''],
    ['LLOYDS BANK PLC Essex Cc Sundry A/C', '396754', ''],
    ['LLOYDS BANK PLC Cow-Sundry Debtor A', '397316', ''],
    ['LLOYDS BANK PLC South West Water Serv Ltd', '397455', ''],
    ['LLOYDS BANK PLC Christchurch B/C', '397757', ''],
    ['LLOYDS BANK PLC Royal & Sun All Ins -Dir 2', '397855', ''],
    ['LLOYDS BANK PLC Oxfordshire County Council', '397955', ''],
    ['LLOYDS BANK PLC Bournville Village Trust', '398165', ''],
    ['LLOYDS BANK PLC Semcorp Bournemounth Water', '398451', ''],
    ["LLOYDS BANK PLC Royal Bor W & M'head C.", '398559', ''],
    ["LLOYDS BANK PLC Royal Bor Windsor & M'head", '398656', ''],
    ['LLOYDS BANK PLC Windsor and Maidenhead U A', '399169', ''],
    ['LLOYDS BANK PLC Metropolitan Housing Trust', '399319', ''],
    ["LLOYDS BANK PLC Royal Bor W & M'head Nndr", '399555', ''],
    ['LLOYDS BANK PLC Calor Group', '399656', ''],
    ['HSBC BANK PLC Head Office', '400000', ''],
    ['HSBC BANK PLC Head Office Ec2', '400001', 'MIDLGB2115M'],
    ['HSBC UK BANK PLC Head Office Ec2', '400002', 'HBUKGB4169B'],
    ['HSBC BANK PLC Head Office Ec2', '400003', ''],
    ['HSBC BANK PLC Head Office Ec2', '400004', ''],
    ['HSBC BANK PLC Head Office Ec2', '400005', ''],
    ['HSBC BANK PLC Head Office Ec2', '400006', ''],
    ['HSBC BANK PLC Head Office Ec2', '400007', ''],
    ['HSBC BANK PLC Head Office Ec2', '400008', ''],
    ['HSBC BANK PLC Head Office Ec2', '400009', ''],
    ['HSBC BANK PLC Head Office Ec2', '400010', ''],
    ['HSBC BANK PLC Head Office Ec2', '400011', ''],
    ['HSBC BANK PLC Head Office Ec2', '400012', ''],
    ['HSBC UK BANK PLC Head Office Ec2', '400013', ''],
    ['HSBC UK BANK PLC Head Office Ec2', '400014', ''],
    ['HSBC BANK PLC Head Office Ec2', '400015', ''],
    ['HSBC BANK PLC Head Office Ec2', '400016', ''],
    ['HSBC UK BANK PLC Head Office Ec2', '400017', ''],
    ['HSBC BANK PLC Head Office Ec2', '400018', ''],
    ['HSBC BANK PLC Head Office Ec2', '400019', ''],
    ['HSBC BANK PLC Head Office Ec2', '400020', ''],
    ['HSBC BANK PLC Head Office Ec2', '400021', ''],
    ['HSBC BANK PLC Head Office Ec2', '400022', ''],
    ['HSBC BANK PLC Head Office Ec2', '400023', ''],
    ['HSBC BANK PLC Head Office Ec2', '400024', ''],
    ['HSBC BANK PLC Head Office Ec2', '400025', ''],
    ['HSBC BANK PLC Head Office Ec2', '400026', ''],
    ['HSBC BANK PLC Head Office Ec2', '400027', ''],
    ['HSBC BANK PLC Head Office Ec2', '400028', ''],
    ['HSBC BANK PLC Head Office Ec2', '400029', ''],
    ['HSBC BANK PLC Head Office Ec2', '400030', ''],
    ['HSBC BANK PLC Head Office Ec2', '400031', ''],
    ['HSBC BANK PLC Head Office Ec2', '400032', ''],
    ['HSBC BANK PLC Head Office Ec2', '400033', ''],
    ['HSBC BANK PLC Head Office Ec2', '400034', ''],
    ['HSBC BANK PLC Head Office Ec2', '400035', ''],
    ['HSBC BANK PLC Head Office Ec2', '400036', ''],
    ['HSBC BANK PLC Head Office Ec2', '400037', ''],
    ['HSBC BANK PLC Head Office Ec2', '400038', ''],
    ['HSBC BANK PLC Head Office Ec2', '400039', ''],
    ['HSBC BANK PLC Head Office Ec2', '400040', ''],
    ['HSBC BANK PLC Head Office Ec2', '400041', ''],
    ['HSBC BANK PLC Head Office Ec2', '400042', ''],
    ['HSBC BANK PLC Head Office Ec2', '400043', ''],
    ['HSBC BANK PLC Head Office Ec2', '400044', ''],
    ['HSBC BANK PLC Head Office Ec2', '400045', ''],
    ['HSBC BANK PLC Head Office Ec2', '400046', ''],
    ['HSBC BANK PLC Head Office Ec2', '400047', ''],
    ['HSBC BANK PLC Head Office Ec2', '400048', ''],
    ['HSBC BANK PLC Head Office Ec2', '400049', ''],
    ['HSBC BANK PLC Head Office Ec2', '400050', ''],
    ['HSBC BANK PLC Head Office Ec2', '400051', ''],
    ['HSBC BANK PLC Head Office Ec2', '400052', ''],
    ['HSBC BANK PLC Head Office Ec2', '400053', ''],
    ['HSBC BANK PLC Head Office Ec2', '400054', ''],
    ['HSBC BANK PLC Head Office Ec2', '400055', ''],
    ['HSBC BANK PLC Head Office Ec2', '400056', ''],
    ['HSBC BANK PLC Head Office Ec2', '400057', ''],
    ['HSBC BANK PLC Head Office Ec2', '400058', ''],
    ['HSBC BANK PLC Head Office Ec2', '400059', ''],
    ['HSBC BANK PLC Head Office Ec2', '400060', ''],
    ['HSBC BANK PLC Head Office Ec2', '400061', ''],
    ['HSBC BANK PLC Head Office Ec2', '400062', ''],
    ['HSBC BANK PLC Head Office Ec2', '400063', ''],
    ['HSBC BANK PLC Head Office Ec2', '400064', ''],
    ['HSBC BANK PLC Head Office Ec2', '400065', ''],
    ['HSBC BANK PLC Head Office Ec2', '400066', ''],
    ['HSBC BANK PLC Head Office Ec2', '400067', ''],
    ['HSBC BANK PLC Head Office Ec2', '400068', ''],
    ['HSBC UK BANK PLC Head Office Ec2', '400069', ''],
    ['HSBC BANK PLC Head Office Ec2', '400070', ''],
    ['HSBC BANK PLC Head Office Ec2', '400071', ''],
    ['HSBC BANK PLC Head Office Ec2', '400072', ''],
    ['HSBC BANK PLC Head Office Ec2', '400073', ''],
    ['HSBC BANK PLC Head Office Ec2', '400074', ''],
    ['HSBC BANK PLC Head Office Ec2', '400075', ''],
    ['HSBC BANK PLC Head Office Ec2', '400076', ''],
    ['HSBC BANK PLC Head Office Ec2', '400077', ''],
    ['HSBC BANK PLC Head Office Ec2', '400078', ''],
    ['HSBC BANK PLC Head Office Ec2', '400079', ''],
    ['HSBC BANK PLC Head Office Ec2', '400080', ''],
    ['HSBC BANK PLC Head Office Ec2', '400081', ''],
    ['HSBC BANK PLC Head Office Ec2', '400083', ''],
    ['HSBC BANK PLC Head Office Ec2', '400084', ''],
    ['HSBC BANK PLC Head Office Ec2', '400085', ''],
    ['HSBC BANK PLC Head Office Ec2', '400086', ''],
    ['HSBC BANK PLC Head Office Ec2', '400087', ''],
    ['HSBC BANK PLC Head Office Ec2', '400088', ''],
    ['HSBC BANK PLC Head Office Ec2', '400089', ''],
    ['HSBC BANK PLC Head Office Ec2', '400090', ''],
    ['HSBC BANK PLC Head Office Ec2', '400091', ''],
    ['HSBC BANK PLC Head Office Ec2', '400092', ''],
    ['HSBC BANK PLC Head Office Ec2', '400093', ''],
    ['HSBC BANK PLC Head Office Ec2', '400094', ''],
    ['HSBC BANK PLC Head Office Ec2', '400095', ''],
    ['HSBC BANK PLC Head Office Ec2', '400096', ''],
    ['HSBC BANK PLC Head Office Ec2', '400097', ''],
    ['HSBC BANK PLC Head Office Ec2', '400098', ''],
    ['HSBC BANK PLC Head Office Ec2', '400099', ''],
    ['HSBC UK BANK PLC HSBC', '400100', 'HBUKGB41COV'],
    ['HSBC UK BANK PLC Ealing Broadway', '400101', 'HBUKGB4139T'],
    ['HSBC UK BANK PLC Bishopsgate', '400104', 'HBUKGB4105E'],
    ['HSBC UK BANK PLC 90 Baker Street W1', '400106', 'HBUKGB4105F'],
    ['HSBC UK BANK PLC Balham Sw12', '400107', 'HBUKGB4105G'],
    ['HSBC UK BANK PLC London Bridge', '400108', 'HBUKGB4139U'],
    ['HSBC UK BANK PLC Putney', '400109', 'HBUKGB4139V'],
    ['HSBC UK BANK PLC Belgravia Sw1', '400113', 'HBUKGB4105K'],
    ['HSBC UK BANK PLC Hampstead High Street', '400115', 'HBUKGB4105L'],
    ['HSBC UK BANK PLC Bethnal Green E2', '400118', 'HBUKGB4105M'],
    ['HSBC UK BANK PLC London Bridge', '400121', 'HBUKGB4110R'],
    ['HSBC UK BANK PLC Brixton Road Sw9', '400122', 'HBUKGB4140A'],
    ['HSBC UK BANK PLC Knightsbridge', '400124', 'HBUKGB4105N'],
    ['HSBC UK BANK PLC Aberdeen', '400125', 'HBUKGB4115T'],
    ['HSBC UK BANK PLC Paddington', '400126', 'HBUKGB4192M'],
    ['HSBC UK BANK PLC Gallions Reach', '400127', 'HBUKGB4192L'],
    ['HSBC UK BANK PLC Kingston upon Thames', '400129', 'HBUKGB4192S'],
    ['HSBC BANK PLC Rsce Re Dwp Income Support', '400130', 'MIDLGB2188C'],
    ['HSBC BANK PLC Rsce Re Dwp Disab Liv All', '400131', 'MIDLGB2188D'],
    ['HSBC BANK PLC Rsce Re Dwp Attend Allow', '400132', 'MIDLGB2188E'],
    ['HSBC BANK PLC Rsce Re Dwp State Ret Pens', '400133', 'MIDLGB2188F'],
    ['HSBC BANK PLC Rsce Re Dwp Incapacity Ben', '400134', 'MIDLGB2188G'],
    ['HSBC BANK PLC Dwp Faster Payments', '400135', 'MIDLGB2188H'],
    ['HSBC BANK PLC Rsce Re Dwp Other Benefits', '400136', 'MIDLGB2188I'],
    ['HSBC BANK PLC Dwp Faster Payments', '400137', 'MIDLGB2192K'],
    ['HSBC BANK PLC Tempo', '400140', ''],
    ['HSBC BANK PLC Tempo', '400141', ''],
    ['HSBC UK BANK PLC HSBC Mortgages', '400188', 'HBUKGB4B71Y'],
    ['HSBC UK BANK PLC Amanah Home Fin Ser Centre', '400189', 'HBUKGB4186Y'],
    ['HSBC UK BANK PLC Pcm Receivables Sc1', '400194', 'HBUKGB4195U'],
    ['HSBC BANK PLC Pcm Receivables Sc2', '400195', 'MIDLGB2195V'],
    ['HSBC BANK PLC Pcm Receivables Sc3', '400196', 'MIDLGB2196J'],
    ['HSBC BANK PLC Card Services', '400199', ''],
    ['HSBC UK BANK PLC HSBC', '400200', 'HBUKGB4187Q'],
    ['HSBC UK BANK PLC Peckham', '400201', 'HBUKGB4140B'],
    ['HSBC UK BANK PLC Covent Garden', '400202', 'HBUKGB4105P'],
    ['HSBC UK BANK PLC Camden Town Nw1', '400203', 'HBUKGB4105R'],
    ['HSBC UK BANK PLC Catford Se6', '400205', 'HBUKGB4140C'],
    ['HSBC UK BANK PLC Belgravia', '400206', 'HBUKGB4110E'],
    ['HSBC UK BANK PLC Holborn Circus', '400207', 'HBUKGB4105S'],
    ['HSBC UK BANK PLC Covent Garden', '400208', 'HBUKGB4105T'],
    ['HSBC UK BANK PLC Fulham Broadway', '400210', 'HBUKGB4105U'],
    ['HSBC UK BANK PLC Fulham Broadway', '400211', 'HBUKGB4105V'],
    ['HSBC UK BANK PLC 281 Chiswick High Road', '400212', 'HBUKGB4105W'],
    ['HSBC UK BANK PLC Chiswick High Road', '400213', 'HBUKGB4105Y'],
    ['HSBC UK BANK PLC Clapham Junction Sw11', '400216', 'HBUKGB4115C'],
    ['HSBC UK BANK PLC Moorgate', '400217', 'HBUKGB4106A'],
    ['HSBC UK BANK PLC 25/31 Moorgate London Ec2r', '400218', 'HBUKGB4106B'],
    ['HSBC UK BANK PLC Bishopsgate', '400220', 'HBUKGB4106C'],
    ['HSBC UK BANK PLC Lewisham', '400225', 'HBUKGB4140E'],
    ['HSBC UK BANK PLC Ealing Broadway W5', '400226', 'HBUKGB4106F'],
    ['HSBC UK BANK PLC Bishopsgate', '400231', 'HBUKGB4106G'],
    ['HSBC UK BANK PLC Peckham', '400232', 'HBUKGB4140G'],
    ['HSBC UK BANK PLC Bishopsgate', '400233', 'HBUKGB4115A'],
    ['HSBC UK BANK PLC East Ham E6', '400234', 'HBUKGB4140H'],
    ['HSBC UK BANK PLC Richmond Surrey', '400235', 'HBUKGB4140J'],
    ['HSBC UK BANK PLC London Bridge', '400238', 'HBUKGB4140L'],
    ['HSBC UK BANK PLC Eltham', '400239', 'HBUKGB4140M'],
    ['HSBC UK BANK PLC Walthamstow', '400240', 'HBUKGB4140D'],
    ['HSBC UK BANK PLC Canary Wharf, Canada Place', '400244', 'HBUKGB4140F'],
    ['HSBC UK BANK PLC Canary Wharf Jubilee Place', '400245', 'HBUKGB4187M'],
    ['HSBC UK BANK PLC Group Hq', '400246', 'HBUKGB4173A'],
    ['HSBC UK BANK PLC Enfield The Town', '400248', 'HBUKGB4140K'],
    ['HSBC BANK PLC Regional Serv Centre Europe', '400250', 'MIDLGB2110C'],
    ['HSBC UK BANK PLC Clapham Junction', '400252', 'HBUKGB4181H'],
    ['HSBC UK BANK PLC M&S Bank', '400280', ''],
    ['HSBC UK BANK PLC M&S Bank', '400281', ''],
    ['HSBC UK BANK PLC M&S Bank', '400282', ''],
    ['HSBC UK BANK PLC M&S Bank', '400283', ''],
    ['HSBC UK BANK PLC M&S Bank', '400284', ''],
    ['HSBC UK BANK PLC M&S Bank', '400285', ''],
    ['HSBC UK BANK PLC M&S Bank', '400286', ''],
    ['HSBC UK BANK PLC M&S Bank', '400287', ''],
    ['HSBC UK BANK PLC HSBC Mortgages', '400288', 'HBUKGB4B71Z'],
    ['HSBC UK BANK PLC M&S Bank', '400289', ''],
    ['HSBC UK BANK PLC M&S Bank', '400291', ''],
    ['HSBC UK BANK PLC M&S Bank', '400292', ''],
    ['HSBC UK BANK PLC M&S Bank', '400293', ''],
    ['HSBC UK BANK PLC M&S Bank', '400294', ''],
    ['HSBC UK BANK PLC M&S Bank', '400295', ''],
    ['HSBC UK BANK PLC M&S Bank', '400296', ''],
    ['HSBC UK BANK PLC M&S Bank', '400297', ''],
    ['HSBC UK BANK PLC M&S Bank', '400298', ''],
    ['HSBC UK BANK PLC M&S Bank', '400299', ''],
    ['HSBC UK BANK PLC HSBC', '400300', 'HBUKGB4187P'],
    ['HSBC UK BANK PLC North Finchley', '400301', 'HBUKGB4140N'],
    ['HSBC UK BANK PLC Finchley Road 122 Nw3', '400302', 'HBUKGB4106H'],
    ['HSBC UK BANK PLC Finsbury Park N4', '400303', 'HBUKGB4140P'],
    ['HSBC UK BANK PLC Moorgate', '400304', 'HBUKGB4106J'],
    ['HSBC UK BANK PLC Holborn Circus', '400305', 'HBUKGB4106K'],
    ['HSBC UK BANK PLC Fulham Broadway', '400309', 'HBUKGB4106L'],
    ['HSBC UK BANK PLC Goldersgreen Cross Rds Nw11', '400311', 'HBUKGB4140R'],
    ['HSBC UK BANK PLC Oxford Circus', '400315', 'HBUKGB4106M'],
    ['HSBC UK BANK PLC Lewisham', '400316', 'HBUKGB4140T'],
    ['HSBC UK BANK PLC Belgravia', '400317', 'HBUKGB4106N'],
    ['HSBC UK BANK PLC Bethnal Green', '400319', 'HBUKGB4140U'],
    ['HSBC UK BANK PLC Hammersmith W 6', '400321', 'HBUKGB4106P'],
    ['HSBC UK BANK PLC Bond Street', '400322', 'HBUKGB4106R'],
    ['HSBC UK BANK PLC Golders Green', '400326', 'HBUKGB4114K'],
    ['HSBC UK BANK PLC Gerrard Street', '400327', 'HBUKGB4106T'],
    ['HSBC BANK PLC Holborn Circus (400328)', '400328', 'MIDLGB2106U'],
    ['HSBC UK BANK PLC Hammersmith', '400329', 'HBUKGB4107R'],
    ['HSBC UK BANK PLC Finsbury Park', '400330', 'HBUKGB4141A'],
    ['HSBC UK BANK PLC Angel Islington', '400333', 'HBUKGB4141B'],
    ['HSBC UK BANK PLC Hampstead High Street N W 3', '400336', 'HBUKGB4140V'],
    ['HSBC UK BANK PLC Gerrard Street', '400340', 'HBUKGB41GST'],
    ['HSBC UK BANK PLC Golders Green', '400385', 'HBUKGB4181I'],
    ['HSBC UK BANK PLC M&S Bank', '400386', ''],
    ['HSBC UK BANK PLC M&S Bank', '400387', ''],
    ['HSBC UK BANK PLC HSBC Mortgages', '400388', 'HBUKGB4B72A'],
    ['HSBC UK BANK PLC M&S Bank', '400389', ''],
    ['HSBC UK BANK PLC London Liverpool St', '400391', ''],
    ['HSBC UK BANK PLC M&S Bank', '400392', ''],
    ['HSBC UK BANK PLC M&S Bank', '400393', ''],
    ['HSBC UK BANK PLC M&S Bank', '400394', ''],
    ['HSBC UK BANK PLC M&S Bank', '400395', ''],
    ['HSBC UK BANK PLC M&S Bank', '400396', ''],
    ['HSBC UK BANK PLC M&S Bank', '400397', ''],
    ['HSBC UK BANK PLC M&S Bank', '400398', ''],
    ['HSBC UK BANK PLC M&S Bank', '400399', ''],
    ['HSBC UK BANK PLC HSBC', '400400', 'HBUKGB4173D'],
    ['HSBC UK BANK PLC Notting Hill Gate', '400401', 'HBUKGB4106V'],
    ['HSBC UK BANK PLC Camden Town', '400403', 'HBUKGB4141C'],
    ['HSBC UK BANK PLC Kilburn High Road Nw6', '400404', 'HBUKGB4141D'],
    ["HSBC UK BANK PLC Harrow St Ann's Road", '400406', 'HBUKGB4141E'],
    ['HSBC UK BANK PLC London, Kings Cross, Nw1', '400407', 'HBUKGB4106W'],
    ['HSBC UK BANK PLC Covent Garden Branch', '400409', 'HBUKGB4106D'],
    ['HSBC UK BANK PLC Knightsbridge', '400410', 'HBUKGB4106Y'],
    ['HSBC UK BANK PLC Bishopsgate', '400412', 'HBUKGB4110F'],
    ['HSBC UK BANK PLC Lewisham Se13', '400415', 'HBUKGB4141F'],
    ['HSBC UK BANK PLC Stratford', '400417', 'HBUKGB4141G'],
    ['HSBC UK BANK PLC Oxford Circus', '400424', 'HBUKGB4107B'],
    ['HSBC UK BANK PLC 90 Baker Street', '400425', 'HBUKGB4141H'],
    ['HSBC UK BANK PLC 90 Baker Street', '400426', 'HBUKGB4114R'],
    ['HSBC UK BANK PLC Edgware Station Road', '400428', 'HBUKGB4141J'],
    ['HSBC UK BANK PLC Muswell Hill N 10', '400437', 'HBUKGB4141K'],
    ['HSBC UK BANK PLC Pall Mall', '400440', 'HBUKGB4169C'],
    ['HSBC UK BANK PLC 8 Cork Street', '400442', ''],
    ['HSBC UK BANK PLC Head Office Accounts', '400443', ''],
    ['HSBC UK BANK PLC HSBC Mortgages', '400488', 'HBUKGB4B72C'],
    ['HSBC UK BANK PLC M&S Bank', '400499', ''],
    ['HSBC UK BANK PLC HSBC', '400500', 'HBUKGB4173E'],
    ['HSBC UK BANK PLC Bond St W1', '400501', 'HBUKGB4107C'],
    ['HSBC UK BANK PLC Holborn Circus', '400503', 'HBUKGB4115N'],
    ['HSBC UK BANK PLC Finsbury Park', '400505', 'HBUKGB4141L'],
    ['HSBC UK BANK PLC North Finchley N12', '400507', 'HBUKGB4141M'],
    ['HSBC UK BANK PLC Notting Hill Gate', '400508', 'HBUKGB4141P'],
    ['HSBC UK BANK PLC Notting Hill Gate W11', '400509', 'HBUKGB4141N'],
    ['HSBC UK BANK PLC Gloucester Road', '400514', 'HBUKGB4107D'],
    ['HSBC BANK PLC HSBC International Branch', '400515', 'MIDLGB22XXX'],
    ['HSBC UK BANK PLC Oxford Circus W1', '400516', 'HBUKGB4107E'],
    ['HSBC UK BANK PLC 39 Tottenham Court Road', '400517', 'HBUKGB4107F'],
    ['HSBC UK BANK PLC Oxford Circus', '400518', 'HBUKGB4107G'],
    ['HSBC UK BANK PLC Paddington Craven Road', '400519', 'HBUKGB4107H'],
    ['HSBC UK BANK PLC Pall Mall Sw1', '400520', 'HBUKGB4107J'],
    ['HSBC UK BANK PLC Palmers Green N13', '400521', 'HBUKGB4141T'],
    ['HSBC UK BANK PLC Pall Mall', '400522', 'HBUKGB4107K'],
    ['HSBC UK BANK PLC Ealing Broadway', '400523', 'HBUKGB4113D'],
    ['HSBC UK BANK PLC Peckham Rye Lane Se15', '400525', 'HBUKGB4141U'],
    ['HSBC UK BANK PLC 79 Piccadilly W1', '400526', 'HBUKGB4107L'],
    ['HSBC BANK PLC Pall Mall', '400527', 'MIDLGB2107M'],
    ['HSBC UK BANK PLC Oxford Circus', '400529', 'HBUKGB4169D'],
    ['HSBC BANK PLC City of London (400530)', '400530', 'MIDLGB2141W'],
    ['HSBC UK BANK PLC Putney Sw15', '400531', 'HBUKGB4142A'],
    ['HSBC UK BANK PLC Kilburn High Road', '400532', 'HBUKGB4142B'],
    ['HSBC UK BANK PLC Notting Hill Gate', '400533', 'HBUKGB4105J'],
    ['HSBC UK BANK PLC Beckenham', '400535', 'HBUKGB4141V'],
    ['HSBC UK BANK PLC 8 Cork Street', '400550', 'SMCOGB2PXXX'],
    ['HSBC UK BANK PLC HSBC Mortgages', '400588', 'HBUKGB4B72B'],
    ['HSBC UK BANK PLC Bond Street', '400602', 'HBUKGB4110H'],
    ['HSBC UK BANK PLC Belgravia', '400603', 'HBUKGB4142D'],
    ['HSBC UK BANK PLC 39 Tottenham Court Road', '400607', 'HBUKGB4142E'],
    ['HSBC UK BANK PLC 90 Baker Street', '400609', 'HBUKGB4142L'],
    ['HSBC UK BANK PLC Kings Road', '400615', 'HBUKGB4107S'],
    ['HSBC UK BANK PLC Ealing Broadway', '400616', 'HBUKGB4142F'],
    ['HSBC UK BANK PLC Wimbledon', '400617', 'HBUKGB4142H'],
    ['HSBC UK BANK PLC Palmers Green', '400618', 'HBUKGB4142J'],
    ['HSBC UK BANK PLC Gloucester Road', '400619', 'HBUKGB4111R'],
    ['HSBC UK BANK PLC Palmers Green', '400620', 'HBUKGB4142W'],
    ['HSBC UK BANK PLC London Bridge', '400621', 'HBUKGB4107T'],
    ['HSBC UK BANK PLC London Bridge', '400622', 'HBUKGB4142K'],
    ['HSBC UK BANK PLC Walthamstow', '400623', 'HBUKGB4142G'],
    ['HSBC UK BANK PLC Finsbury Park', '400625', 'HBUKGB4142N'],
    ['HSBC UK BANK PLC Finsbury Park', '400627', 'HBUKGB4142P'],
    ['HSBC UK BANK PLC Holborn Circus', '400629', 'HBUKGB4107P'],
    ['HSBC UK BANK PLC Stratford E15', '400630', 'HBUKGB4142R'],
    ['HSBC UK BANK PLC Balham', '400632', 'HBUKGB4142T'],
    ['HSBC UK BANK PLC Beckenham', '400634', 'HBUKGB4142U'],
    ['HSBC UK BANK PLC City of London', '400637', 'HBUKGB4169E'],
    ['HSBC UK BANK PLC City of London', '400638', 'HBUKGB4169F'],
    ['HSBC UK BANK PLC London Bridge', '400641', 'HBUKGB4181L'],
    ['HSBC UK BANK PLC HSBC Mortgages', '400688', 'HBUKGB4B72D'],
    ['HSBC UK BANK PLC Golders Green', '400701', 'HBUKGB4107U'],
    ['HSBC UK BANK PLC Tooting Broadway Sw17', '400704', 'HBUKGB4142V'],
    ['HSBC UK BANK PLC Tooting Junction Sw17', '400705', 'HBUKGB4169G'],
    ['HSBC UK BANK PLC 39 Tottenham Court Road', '400706', 'HBUKGB4107Y'],
    ['HSBC UK BANK PLC 39 Tottenham Court Road W1', '400707', 'HBUKGB4107W'],
    ['HSBC UK BANK PLC East Ham', '400712', 'HBUKGB4143A'],
    ['HSBC UK BANK PLC Belgravia', '400713', 'HBUKGB4110D'],
    ['HSBC UK BANK PLC Fulham Broadway', '400714', 'HBUKGB4108A'],
    ['HSBC UK BANK PLC Walthamstow E17', '400715', 'HBUKGB4111C'],
    ['HSBC UK BANK PLC Wandsworth Sw18', '400717', 'HBUKGB4143B'],
    ['HSBC UK BANK PLC Walthamstow', '400718', 'HBUKGB4143C'],
    ['HSBC UK BANK PLC 122 Finchley Road', '400722', 'HBUKGB4169A'],
    ['HSBC UK BANK PLC Holborn Circus Ec1', '400724', 'HBUKGB4108B'],
    ['HSBC UK BANK PLC Oxford Circus', '400725', 'HBUKGB4143F'],
    ['HSBC UK BANK PLC Kilburn High Road', '400727', 'HBUKGB4143G'],
    ['HSBC UK BANK PLC Kilburn High Road', '400728', 'HBUKGB4143H'],
    ['HSBC UK BANK PLC Wimbledon Hill Road Sw19', '400730', 'HBUKGB4143J'],
    ['HSBC UK BANK PLC Bishopsgate', '400731', 'HBUKGB4141R'],
    ['HSBC UK BANK PLC Enfield The Town', '400732', 'HBUKGB4143K'],
    ['HSBC UK BANK PLC Palmers Green', '400733', 'HBUKGB4143L'],
    ['HSBC UK BANK PLC Woolwich Se18', '400735', 'HBUKGB4143M'],
    ['HSBC UK BANK PLC North Finchley', '400737', 'HBUKGB4143E'],
    ['HSBC UK BANK PLC Wimbledon Village', '400739', 'HBUKGB4181M'],
    ['HSBC UK BANK PLC HSBC Mortgages', '400788', 'HBUKGB4B72E'],
    ['HSBC UK BANK PLC Aberystwyth', '400802', 'HBUKGB4115P'],
    ['HSBC UK BANK PLC Merthyr Tydfil', '400803', 'HBUKGB4115R'],
    ['HSBC UK BANK PLC Abergavenny', '400804', 'HBUKGB4115U'],
    ['HSBC UK BANK PLC Rhyl', '400805', 'HBUKGB4115V'],
    ['HSBC UK BANK PLC Aberystwyth', '400809', 'HBUKGB4115W'],
    ['HSBC UK BANK PLC Abingdon', '400810', 'HBUKGB4116A'],
    ['HSBC UK BANK PLC Burnley', '400811', 'HBUKGB4116B'],
    ['HSBC UK BANK PLC Farnham', '400813', 'HBUKGB4116D'],
    ['HSBC UK BANK PLC Aldridge', '400814', 'HBUKGB4161G'],
    ['HSBC UK BANK PLC Ripley', '400816', 'HBUKGB4116E'],
    ['HSBC UK BANK PLC Newcastle-upon-Tyne City Br', '400818', 'HBUKGB4116F'],
    ['HSBC UK BANK PLC Wembley High Road', '400819', 'HBUKGB4113J'],
    ['HSBC UK BANK PLC Penrith', '400820', 'HBUKGB4116H'],
    ['HSBC UK BANK PLC Alton Hampshire', '400821', 'HBUKGB4116J'],
    ['HSBC UK BANK PLC Altrincham', '400822', 'HBUKGB4116K'],
    ['HSBC UK BANK PLC Salisbury', '400825', 'HBUKGB4152U'],
    ['HSBC UK BANK PLC Llangefni', '400826', 'HBUKGB4116N'],
    ['HSBC UK BANK PLC Llanelli', '400827', 'HBUKGB4116P'],
    ['HSBC UK BANK PLC Andover', '400828', 'HBUKGB4102A'],
    ['HSBC UK BANK PLC Penrith', '400829', 'HBUKGB4116R'],
    ['HSBC UK BANK PLC Ashby-de-la-Zouch', '400831', 'HBUKGB4116V'],
    ['HSBC UK BANK PLC Ashford Kent', '400832', 'HBUKGB4115L'],
    ['HSBC UK BANK PLC Ashton-under-Lyne', '400833', 'HBUKGB4114C'],
    ['HSBC UK BANK PLC Carlisle City Office', '400834', 'HBUKGB4124P'],
    ['HSBC UK BANK PLC Nuneaton', '400835', 'HBUKGB4117C'],
    ['HSBC UK BANK PLC Taunton', '400838', 'HBUKGB4117E'],
    ['HSBC UK BANK PLC Aylesbury, Market Square', '400839', 'HBUKGB4102C'],
    ['HSBC UK BANK PLC Cramlington', '400840', 'HBUKGB4117A'],
    ['HSBC UK BANK PLC Amersham-on-the-Hill', '400841', 'HBUKGB4116M'],
    ['HSBC UK BANK PLC Windsor High Street', '400842', 'HBUKGB4116W'],
    ['HSBC UK BANK PLC Weybridge', '400843', 'HBUKGB4116C'],
    ['HSBC UK BANK PLC Epsom', '400844', 'HBUKGB4117B'],
    ['HSBC UK BANK PLC Arnold', '400846', 'HBUKGB4114L'],
    ['HSBC UK BANK PLC Norwich London Street', '400853', 'HBUKGB4117D'],
    ['HSBC UK BANK PLC Pwllheli', '400854', 'HBUKGB4183M'],
    ['HSBC UK BANK PLC Blackwood', '400855', 'HBUKGB4183N'],
    ['HSBC UK BANK PLC Stratford upon Avon', '400856', 'HBUKGB4174G'],
    ['HSBC UK BANK PLC Saxmundham', '400857', 'HBUKGB4174H'],
    ['HSBC BANK PLC International Car Loans', '400858', ''],
    ['HSBC UK BANK PLC Huddersfield, Cloth Hall St', '400859', 'HBUKGB4177B'],
    ['HSBC UK BANK PLC Wilmslow', '400860', 'HBUKGB4177C'],
    ['HSBC UK BANK PLC Kendal', '400861', 'HBUKGB4177D'],
    ['HSBC BANK PLC Alderney', '400862', ''],
    ['HSBC UK BANK PLC Beverley', '400863', 'HBUKGB4177E'],
    ['HSBC UK BANK PLC Leicester, Belgrave Road', '400864', 'HBUKGB4174I'],
    ['HSBC UK BANK PLC Doncaster High Street', '400865', 'HBUKGB4177F'],
    ['HSBC UK BANK PLC Scunthorpe', '400866', 'HBUKGB4177G'],
    ['HSBC UK BANK PLC Aylesbury,Market Square', '400868', 'HBUKGB4174J'],
    ['HSBC UK BANK PLC Maidstone', '400869', 'HBUKGB4181N'],
    ['HSBC UK BANK PLC Norwich London Street', '400870', 'HBUKGB4174K'],
    ['HSBC UK BANK PLC HSBC Mortgages', '400888', 'HBUKGB4B72F'],
    ['HSBC UK BANK PLC HSBC', '400900', 'HBUKGB4193E'],
    ['HSBC UK BANK PLC Bala', '400901', 'HBUKGB4117G'],
    ['HSBC UK BANK PLC Banbury', '400902', 'HBUKGB4102D'],
    ['HSBC UK BANK PLC Bangor', '400903', 'HBUKGB4117H'],
    ['HSBC UK BANK PLC Banstead', '400904', 'HBUKGB4117J'],
    ['HSBC UK BANK PLC Blackwood', '400905', 'HBUKGB4117K'],
    ['HSBC UK BANK PLC Barking', '400906', 'HBUKGB4102F'],
    ['HSBC UK BANK PLC Barkingside', '400907', 'HBUKGB4135K'],
    ['HSBC UK BANK PLC Darlington', '400909', 'HBUKGB4117L'],
    ['HSBC UK BANK PLC North Finchley', '400910', 'HBUKGB4117M'],
    ['HSBC UK BANK PLC Burnley', '400911', 'HBUKGB4126T'],
    ['HSBC UK BANK PLC Barnsley', '400912', 'HBUKGB4112M'],
    ['HSBC UK BANK PLC Barnstaple', '400913', 'HBUKGB4117N'],
    ['HSBC UK BANK PLC Barrow-in-Furness', '400914', 'HBUKGB4117P'],
    ['HSBC UK BANK PLC Penarth', '400915', 'HBUKGB4117R'],
    ['HSBC UK BANK PLC Grimsby', '400916', 'HBUKGB4117T'],
    ['HSBC UK BANK PLC Basildon Southernhay', '400917', 'HBUKGB4117U'],
    ['HSBC UK BANK PLC Basingstoke Town Centre', '400918', 'HBUKGB4111N'],
    ['HSBC UK BANK PLC Bath, Southgate', '400919', 'HBUKGB4102G'],
    ['HSBC UK BANK PLC Dewsbury', '400921', 'HBUKGB4118B'],
    ['HSBC UK BANK PLC Beccles', '400924', 'HBUKGB4118E'],
    ['HSBC UK BANK PLC Beckenham High Street', '400925', 'HBUKGB4118F'],
    ['HSBC UK BANK PLC Basingstoke Town Centre', '400926', 'HBUKGB4117V'],
    ['HSBC UK BANK PLC Hastings', '400927', 'HBUKGB4118C'],
    ['HSBC UK BANK PLC Beaconsfield', '400929', 'HBUKGB4118D'],
    ['HSBC UK BANK PLC Chesterfield', '400930', 'HBUKGB4117F'],
    ['HSBC UK BANK PLC Solihull', '400934', 'HBUKGB4127F'],
    ['HSBC UK BANK PLC Ballymena', '400935', 'HBUKGB4171V'],
    ['HSBC UK BANK PLC Portishead', '400936', 'HBUKGB4183O'],
    ['HSBC UK BANK PLC Shipley', '400937', 'HBUKGB4177H'],
    ['HSBC UK BANK PLC Bampton Oxford', '400938', ''],
    ['HSBC UK BANK PLC Exeter High Street', '400939', 'HBUKGB4183P'],
    ['HSBC UK BANK PLC Dolgellau', '400941', 'HBUKGB4183R'],
    ['HSBC UK BANK PLC Malvern', '400942', 'HBUKGB4174L'],
    ['HSBC UK BANK PLC Basingstoke London Street', '400943', 'HBUKGB4173F'],
    ['HSBC UK BANK PLC Bath Southgate', '400944', 'HBUKGB4173H'],
    ['HSBC UK BANK PLC Doncaster, High Street', '400946', 'HBUKGB4177I'],
    ['HSBC UK BANK PLC HSBC Mortgages', '400988', 'HBUKGB4B72G'],
    ['HSBC UK BANK PLC HSBC', '401000', 'HBUKGB4194M'],
    ['HSBC UK BANK PLC Thirsk', '401001', 'HBUKGB4118G'],
    ['HSBC UK BANK PLC Bedford', '401002', 'HBUKGB4102H'],
    ['HSBC UK BANK PLC Morpeth', '401003', 'HBUKGB4118H'],
    ['HSBC UK BANK PLC Bristol Cabot Circus', '401004', 'HBUKGB4122C'],
    ['HSBC UK BANK PLC Nuneaton', '401005', 'HBUKGB4118J'],
    ['HSBC UK BANK PLC Beeston Notts', '401006', 'HBUKGB4113R'],
    ['HSBC UK BANK PLC Ripley', '401007', 'HBUKGB4118K'],
    ['HSBC UK BANK PLC Settle', '401008', 'HBUKGB4136P'],
    ['HSBC UK BANK PLC Berkhamsted', '401009', 'HBUKGB4118L'],
    ['HSBC UK BANK PLC Llandudno Mostyn Street', '401011', 'HBUKGB4118M'],
    ['HSBC UK BANK PLC Beverley', '401012', 'HBUKGB4118N'],
    ['HSBC UK BANK PLC Kidderminster', '401013', 'HBUKGB4118P'],
    ['HSBC UK BANK PLC Bexhill-on-Sea', '401014', 'HBUKGB4118R'],
    ['HSBC UK BANK PLC Sidcup', '401015', 'HBUKGB4118T'],
    ['HSBC UK BANK PLC Bicester', '401016', 'HBUKGB4113H'],
    ['HSBC UK BANK PLC Bideford North Devon Area', '401017', 'HBUKGB4118U'],
    ['HSBC UK BANK PLC Birkenhead  Grange Road', '401022', 'HBUKGB4112N'],
    ['HSBC UK BANK PLC Birkenhead, Grange Road', '401026', 'HBUKGB4119A'],
    ['HSBC UK BANK PLC Billericay', '401027', 'HBUKGB4118W'],
    ['HSBC UK BANK PLC Bedford Allhallows', '401033', 'HBUKGB4118V'],
    ['HSBC UK BANK PLC Bridport', '401034', 'HBUKGB4173L'],
    ['HSBC UK BANK PLC Bangor', '401035', 'HBUKGB4183T'],
    ['HSBC UK BANK PLC Llangefni', '401036', 'HBUKGB4183U'],
    ['HSBC UK BANK PLC Bangor', '401037', 'HBUKGB4183V'],
    ['HSBC UK BANK PLC Stockton-On Tees', '401038', 'HBUKGB4177J'],
    ['HSBC UK BANK PLC Wolverhampton', '401039', 'HBUKGB4174M'],
    ['HSBC UK BANK PLC Harrogate', '401040', 'HBUKGB4177K'],
    ['HSBC UK BANK PLC Wolverhampton', '401041', 'HBUKGB4174N'],
    ['HSBC UK BANK PLC Keighley', '401042', 'HBUKGB4177L'],
    ['HSBC UK BANK PLC Westwood Cross', '401043', 'HBUKGB4181O'],
    ['HSBC UK BANK PLC Southport Lord Street', '401044', 'HBUKGB4177M'],
    ['HSBC UK BANK PLC Belfast, Royal Avenue', '401050', 'HBUKGB41BEL'],
    ['HSBC UK BANK PLC HSBC Private Bank(UK) Ltd', '401055', 'HBUKGB41PBG'],
    ['HSBC UK BANK PLC HSBC Mortgages', '401088', 'HBUKGB4B72H'],
    ['HSBC UK BANK PLC National Sort Code 10', '401100', 'HBUKGB4195T'],
    ['HSBC UK BANK PLC Solihull', '401101', 'HBUKGB4119B'],
    ['HSBC UK BANK PLC Birmingham New Street', '401102', 'HBUKGB4167B'],
    ['HSBC UK BANK PLC Birmingham, New Street', '401104', 'HBUKGB4102J'],
    ['HSBC UK BANK PLC Birmingham New Street', '401106', 'HBUKGB4119C'],
    ['HSBC UK BANK PLC Sutton Coldfield', '401107', 'HBUKGB4119D'],
    ['HSBC UK BANK PLC Birmingham, New Street', '401108', 'HBUKGB4119E'],
    ['HSBC UK BANK PLC West Bromwich', '401112', 'HBUKGB4119G'],
    ['HSBC UK BANK PLC Birmingham Harborne', '401113', 'HBUKGB4119H'],
    ['HSBC UK BANK PLC Birmingham Kings Heath', '401115', 'HBUKGB4119K'],
    ['HSBC UK BANK PLC Birmingham New Street', '401116', 'HBUKGB4119M'],
    ['HSBC UK BANK PLC Birmingham Kings Heath', '401117', 'HBUKGB4119J'],
    ['HSBC UK BANK PLC Birmingham New Street', '401118', 'HBUKGB4102K'],
    ['HSBC UK BANK PLC Birmingham,  Northfield', '401120', 'HBUKGB4119N'],
    ['HSBC UK BANK PLC Solihull', '401124', 'HBUKGB4119P'],
    ['HSBC UK BANK PLC Solihull', '401129', 'HBUKGB4119U'],
    ['HSBC UK BANK PLC Birmingham Jewelleryquarter', '401136', 'HBUKGB4119R'],
    ['HSBC UK BANK PLC Birmingham New Street', '401137', 'HBUKGB4119T'],
    ['HSBC UK BANK PLC Walsall', '401143', 'HBUKGB4119F'],
    ['HSBC UK BANK PLC Birmingham, Northfield', '401147', 'HBUKGB4119L'],
    ["HSBC UK BANK PLC Birmingham, King's Heath", '401154', 'HBUKGB4174O'],
    ['HSBC UK BANK PLC Merry Hill Shopping Centre', '401155', 'HBUKGB4192F'],
    ["HSBC UK BANK PLC Manchester St Ann's (401156", '401156', 'HBUKGB4195S'],
    ['HSBC UK BANK PLC New Street Birmingham', '401157', 'HBUKGB4173J'],
    ['HSBC UK BANK PLC Holborn Circus (401158)', '401158', 'HBUKGB4194P'],
    ['HSBC UK BANK PLC HSBC Banking', '401159', 'HBUKGB4194Q'],
    ['HSBC UK BANK PLC City of London (401160)', '401160', 'HBUKGB4194R'],
    ['HSBC UK BANK PLC Rbwm UK Banking', '401162', 'HBUKGB4194S'],
    ['HSBC BANK PLC HSBC Banking', '401163', 'MIDLGB2194T'],
    ['HSBC UK BANK PLC Waltham Cross', '401164', 'HBUKGB4194U'],
    ['HSBC UK BANK PLC Haywards Heath (401165)', '401165', 'HBUKGB4194V'],
    ['HSBC UK BANK PLC HSBC Banking', '401166', 'HBUKGB4194W'],
    ['HSBC UK BANK PLC HSBC Banking', '401167', 'HBUKGB4194X'],
    ['HSBC UK BANK PLC HSBC Banking', '401168', 'HBUKGB4194Y'],
    ['HSBC UK BANK PLC Sbb Bbl Region', '401169', 'HBUKGB4194Z'],
    ['HSBC BANK PLC HSBC Banking', '401170', 'MIDLGB2195A'],
    ['HSBC BANK PLC HSBC Banking', '401171', 'MIDLGB2195B'],
    ['HSBC UK BANK PLC HSBC Banking', '401172', 'HBUKGB4195C'],
    ['HSBC UK BANK PLC HSBC UK Bank plc', '401173', 'HBUKGB4195D'],
    ['HSBC UK BANK PLC HSBC Banking', '401174', 'HBUKGB4195E'],
    ['HSBC BANK PLC HSBC Nrfb Scotland', '401175', 'MIDLGB2195F'],
    ['HSBC UK BANK PLC HSBC Banking', '401176', 'HBUKGB4195G'],
    ['HSBC UK BANK PLC HSBC Banking', '401177', 'HBUKGB4195H'],
    ['HSBC UK BANK PLC HSBC Banking', '401178', 'HBUKGB4195I'],
    ['HSBC UK BANK PLC HSBC Banking', '401179', 'HBUKGB4195J'],
    ['HSBC UK BANK PLC HSBC Banking', '401180', 'HBUKGB4195K'],
    ['HSBC UK BANK PLC HSBC Banking', '401181', 'HBUKGB4195L'],
    ['HSBC UK BANK PLC HSBC Banking', '401182', 'HBUKGB4195M'],
    ['HSBC UK BANK PLC HSBC Banking', '401183', 'HBUKGB4195N'],
    ['HSBC UK BANK PLC HSBC UK Premier', '401184', 'HBUKGB4195O'],
    ['HSBC UK BANK PLC HSBC Banking', '401185', 'HBUKGB4195P'],
    ['HSBC UK BANK PLC HSBC Bank Centenary Square', '401186', 'HBUKGB4195Q'],
    ['HSBC UK BANK PLC HSBC Banking', '401187', 'HBUKGB4195R'],
    ['HSBC BANK PLC Jersey & Guernsey Mortgages', '401188', 'MIDLGB2272I'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401191', 'HBUKGB4195W'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401192', 'HBUKGB4195V'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401193', 'HBUKGB4195X'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401194', 'HBUKGB4195Y'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401195', 'HBUKGB4195Z'],
    ['HSBC UK BANK PLC HSBC UK Bank plc', '401199', 'HBUKGB41RWB'],
    ['HSBC UK BANK PLC Bishop Auckland', '401201', 'HBUKGB4120B'],
    ['HSBC UK BANK PLC Bishops Castle', '401202', 'HBUKGB4120D'],
    ['HSBC UK BANK PLC Bishops Stortford', '401203', 'HBUKGB4102N'],
    ['HSBC UK BANK PLC Blackburn', '401204', 'HBUKGB4102P'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '401206', 'HBUKGB4120F'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '401209', 'HBUKGB4120G'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '401213', 'HBUKGB4120H'],
    ['HSBC UK BANK PLC Blackwood', '401215', 'HBUKGB4120J'],
    ['HSBC UK BANK PLC Porthmadog', '401216', 'HBUKGB4120K'],
    ['HSBC UK BANK PLC Blandford Forum', '401218', 'HBUKGB4120L'],
    ['HSBC UK BANK PLC Milton Keynes Midsummer Pla', '401219', 'HBUKGB4146G'],
    ['HSBC UK BANK PLC Bloxwich', '401220', 'HBUKGB4120M'],
    ['HSBC UK BANK PLC Cramlington', '401221', 'HBUKGB4120N'],
    ['HSBC UK BANK PLC St Austell', '401222', 'HBUKGB4120P'],
    ['HSBC UK BANK PLC Bognor Regis High Street', '401223', 'HBUKGB4120R'],
    ['HSBC UK BANK PLC Sutton Coldfield', '401224', 'HBUKGB4158A'],
    ['HSBC UK BANK PLC Bolton Victoria Square', '401225', 'HBUKGB4102R'],
    ['HSBC UK BANK PLC Bootle Lancs', '401226', 'HBUKGB4138K'],
    ['HSBC UK BANK PLC Borehamwood', '401227', 'HBUKGB4120V'],
    ['HSBC UK BANK PLC Harrogate', '401228', 'HBUKGB4120W'],
    ['HSBC UK BANK PLC Boston Lincs', '401230', 'HBUKGB4121A'],
    ['HSBC UK BANK PLC Stamford', '401232', 'HBUKGB4121B'],
    ['HSBC UK BANK PLC Leicester, Clock Tower', '401235', 'HBUKGB4120E'],
    ['HSBC UK BANK PLC Halesowen', '401237', 'HBUKGB4174Q'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '401238', 'HBUKGB4177N'],
    ['HSBC UK BANK PLC Pontypool', '401239', 'HBUKGB4183W'],
    ['HSBC UK BANK PLC Bognor Regis, High Street', '401240', 'HBUKGB4181P'],
    ['HSBC UK BANK PLC Bolton, Victoria Square', '401241', 'HBUKGB4120T'],
    ['HSBC UK BANK PLC Alton', '401242', 'HBUKGB4181Q'],
    ['HSBC UK BANK PLC Wetherby', '401243', 'HBUKGB4177O'],
    ['HSBC UK BANK PLC Oxford, Cornmarket Street', '401244', 'HBUKGB4174R'],
    ['HSBC UK BANK PLC Bournemouth', '401245', 'HBUKGB4173K'],
    ['HSBC UK BANK PLC B/Mouth Old Christchurch Rd', '401246', 'HBUKGB4181R'],
    ['HSBC UK BANK PLC Newton Abbott', '401247', 'HBUKGB4183X'],
    ['HSBC UK BANK PLC Bluewater', '401248', 'HBUKGB4187V'],
    ['HSBC UK BANK PLC Hitchin', '401249', 'HBUKGB4193B'],
    ['HSBC UK BANK PLC Brighton North Street', '401250', 'HBUKGB4193A'],
    ['HSBC UK BANK PLC Bromley High Street', '401251', 'HBUKGB4192X'],
    ['HSBC UK BANK PLC Cheltenham Promenade', '401252', 'HBUKGB4192Y'],
    ['HSBC UK BANK PLC Worthing Warwick Street', '401253', 'HBUKGB4192Z'],
    ['HSBC UK BANK PLC M&S Bank - Everyday Saver', '401254', 'HBUKGB41MS1'],
    ['HSBC UK BANK PLC M&S Bank - Current Account', '401255', 'HBUKGB41MS2'],
    ['HSBC UK BANK PLC M&S Bank - Regular Saver', '401256', 'HBUKGB41MS3'],
    ['HSBC UK BANK PLC M&S Bank - Settlement', '401257', 'HBUKGB4194K'],
    ['HSBC UK BANK PLC M&S Bank', '401258', 'HBUKGB4196F'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401261', 'HBUKGB4196A'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401262', 'HBUKGB4196B'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401263', 'HBUKGB4196C'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401264', 'HBUKGB4196D'],
    ['HSBC UK BANK PLC HSBC UK Banking', '401265', 'HBUKGB4196E'],
    ['HSBC UK BANK PLC First Direct', '401266', 'HBUKGB41FDL'],
    ['HSBC UK BANK PLC HSBC UK Bank plc', '401276', 'HBUKGB41CM1'],
    ['HSBC UK BANK PLC HSBC UK Bank plc', '401277', 'HBUKGB41CM2'],
    ['HSBC UK BANK PLC HSBC UK Bank plc', '401278', 'HBUKGB41CM3'],
    ['HSBC BANK PLC Isle of Man Mortgages', '401288', 'MIDLGB2272J'],
    ['HSBC UK BANK PLC Bournemouth Winton', '401306', 'HBUKGB4121C'],
    ['HSBC UK BANK PLC B/Mouth Old Christchurch Rd', '401307', 'HBUKGB4102T'],
    ['HSBC UK BANK PLC Bournemouth Old Christchurc', '401308', 'HBUKGB4121F'],
    ['HSBC UK BANK PLC Bracknell', '401310', 'HBUKGB4102U'],
    ['HSBC UK BANK PLC Bradford Market Street', '401315', 'HBUKGB4102W'],
    ['HSBC UK BANK PLC Trowbridge', '401317', 'HBUKGB4121G'],
    ['HSBC UK BANK PLC Braintree', '401318', 'HBUKGB4121H'],
    ['HSBC UK BANK PLC Carlisle City Office', '401319', 'HBUKGB4121J'],
    ['HSBC UK BANK PLC Brecon', '401320', 'HBUKGB4121K'],
    ['HSBC UK BANK PLC Hounslow High Street', '401321', 'HBUKGB4168P'],
    ['HSBC UK BANK PLC Brentwood', '401322', 'HBUKGB4121L'],
    ['HSBC UK BANK PLC Bridgend', '401323', 'HBUKGB4113A'],
    ['HSBC UK BANK PLC Bridgnorth', '401324', 'HBUKGB4121M'],
    ['HSBC UK BANK PLC Bridgwater', '401325', 'HBUKGB4121N'],
    ['HSBC UK BANK PLC Bridlington King Street', '401327', 'HBUKGB4121P'],
    ['HSBC UK BANK PLC Bridport', '401328', 'HBUKGB4121R'],
    ['HSBC UK BANK PLC Stourbridge', '401329', 'HBUKGB4121T'],
    ['HSBC UK BANK PLC Scunthorpe High Street', '401330', 'HBUKGB4121U'],
    ['HSBC UK BANK PLC Weston-super-Mare', '401332', 'HBUKGB4123L'],
    ['HSBC UK BANK PLC Stockport Great Underbank', '401333', 'HBUKGB4156V'],
    ['HSBC UK BANK PLC Bradford, Market Street', '401340', 'HBUKGB4173Z'],
    ['HSBC UK BANK PLC Bradford, Market Street', '401341', 'HBUKGB4177P'],
    ['HSBC UK BANK PLC Bradford Market St', '401342', 'HBUKGB4177Q'],
    ['HSBC UK BANK PLC Bradford Market Street', '401343', 'HBUKGB4177R'],
    ['HSBC UK BANK PLC Bradford, Market Street', '401344', 'HBUKGB4177S'],
    ['HSBC UK BANK PLC Barnstaple', '401345', 'HBUKGB4183Y'],
    ['HSBC UK BANK PLC Bridgend Dunraven Place', '401346', 'HBUKGB4183Z'],
    ['HSBC UK BANK PLC Bridlington King Street', '401347', 'HBUKGB4177T'],
    ['HSBC UK BANK PLC Brighouse', '401348', 'HBUKGB4173S'],
    ['HSBC UK BANK PLC Bristol, Filton', '401400', 'HBUKGB4184A'],
    ['HSBC UK BANK PLC Brighton North Street', '401401', 'HBUKGB4121V'],
    ['HSBC UK BANK PLC Brighton, North Street', '401402', 'HBUKGB4103A'],
    ['HSBC UK BANK PLC Brighton North Street', '401403', 'HBUKGB4111V'],
    ['HSBC UK BANK PLC Brighton North Street', '401404', 'HBUKGB4122A'],
    ['HSBC UK BANK PLC Keynsham', '401406', 'HBUKGB4122D'],
    ['HSBC UK BANK PLC Bristol Cabot Circus', '401407', 'HBUKGB4122B'],
    ['HSBC UK BANK PLC Bristol Whiteladies Road', '401409', 'HBUKGB4115H'],
    ['HSBC UK BANK PLC Bristol Cabot Circus', '401412', 'HBUKGB4103C'],
    ['HSBC UK BANK PLC Bristol Cabot Circus', '401413', 'HBUKGB4103B'],
    ['HSBC UK BANK PLC Bristol Filton', '401414', 'HBUKGB4122G'],
    ['HSBC UK BANK PLC Bristol Kingswood', '401415', 'HBUKGB4122H'],
    ['HSBC UK BANK PLC Bristol Kingswood', '401418', 'HBUKGB4122J'],
    ['HSBC UK BANK PLC Bristol Downend', '401420', 'HBUKGB4122L'],
    ['HSBC UK BANK PLC Bristol Downend', '401421', 'HBUKGB4122F'],
    ['HSBC UK BANK PLC Bristol Westbury-on-Trym', '401424', 'HBUKGB4122M'],
    ['HSBC UK BANK PLC Brighton, North Street', '401428', 'HBUKGB4121W'],
    ['HSBC UK BANK PLC Bristol, Filton', '401431', 'HBUKGB4184B'],
    ['HSBC UK BANK PLC Bristol City Office', '401432', 'HBUKGB4173O'],
    ['HSBC UK BANK PLC Keynsham', '401433', 'HBUKGB4184C'],
    ['HSBC UK BANK PLC Westbury on Trym', '401434', 'HBUKGB4184D'],
    ['HSBC UK BANK PLC Bristol Kingswood', '401435', 'HBUKGB4184E'],
    ['HSBC UK BANK PLC Bristol Cribbs Causeway', '401436', 'HBUKGB4187U'],
    ['HSBC UK BANK PLC Paignton Palace Avenue', '401501', 'HBUKGB4122V'],
    ['HSBC UK BANK PLC Westwood Cross', '401502', 'HBUKGB4122W'],
    ['HSBC UK BANK PLC Bromborough', '401504', 'HBUKGB4103D'],
    ['HSBC UK BANK PLC Bromley High Street', '401505', 'HBUKGB4103E'],
    ['HSBC UK BANK PLC Bromley Hill', '401506', 'HBUKGB4123C'],
    ['HSBC UK BANK PLC Bromsgrove', '401507', 'HBUKGB4123D'],
    ['HSBC UK BANK PLC Worcester, Broad Street', '401508', 'HBUKGB4123E'],
    ['HSBC UK BANK PLC Lichfield', '401509', 'HBUKGB4161H'],
    ['HSBC UK BANK PLC Mold', '401512', 'HBUKGB4123G'],
    ['HSBC UK BANK PLC Llandrindod Wells', '401514', 'HBUKGB4123H'],
    ['HSBC UK BANK PLC Beccles', '401515', 'HBUKGB4123J'],
    ['HSBC UK BANK PLC Burgess Hill', '401516', 'HBUKGB4123K'],
    ['HSBC UK BANK PLC Burnley', '401517', 'HBUKGB4114H'],
    ['HSBC UK BANK PLC Burton-on-Trent High St', '401520', 'HBUKGB4103G'],
    ['HSBC UK BANK PLC Bury (Lancashire)', '401521', 'HBUKGB4123N'],
    ['HSBC UK BANK PLC Bury St Edmunds', '401522', 'HBUKGB4103H'],
    ['HSBC UK BANK PLC Watford High Street', '401523', 'HBUKGB4148K'],
    ['HSBC UK BANK PLC Stockport Great Underbank', '401524', 'HBUKGB4123P'],
    ['HSBC UK BANK PLC Lymington', '401526', 'HBUKGB4123B'],
    ['HSBC UK BANK PLC Poole', '401527', 'HBUKGB4123A'],
    ['HSBC UK BANK PLC High Street Burton on Trent', '401531', 'HBUKGB4123M'],
    ['HSBC UK BANK PLC Milton Keynes Midsummer Pla', '401533', 'HBUKGB4123F'],
    ['HSBC UK BANK PLC Evesham', '401535', 'HBUKGB4174S'],
    ['HSBC UK BANK PLC Beverley', '401537', 'HBUKGB4177U'],
    ['HSBC UK BANK PLC Wells', '401538', 'HBUKGB4181T'],
    ['HSBC UK BANK PLC Uxbridge', '401539', 'HBUKGB4181U'],
    ['HSBC UK BANK PLC Merthyr Tydfil', '401540', 'HBUKGB4184F'],
    ['HSBC UK BANK PLC Sidmouth', '401541', 'HBUKGB4184G'],
    ['HSBC UK BANK PLC Guildford', '401542', 'HBUKGB4181V'],
    ['HSBC UK BANK PLC Nuneaton', '401543', 'HBUKGB4174T'],
    ['HSBC UK BANK PLC Llanelli', '401544', 'HBUKGB4184H'],
    ['HSBC UK BANK PLC Bury St Edmunds', '401545', 'HBUKGB4174U'],
    ['HSBC UK BANK PLC Wrexham', '401601', 'HBUKGB4165A'],
    ['HSBC UK BANK PLC Caernarvon', '401602', 'HBUKGB4123R'],
    ['HSBC UK BANK PLC Caerphilly', '401603', 'HBUKGB4123T'],
    ['HSBC UK BANK PLC Chippenham', '401604', 'HBUKGB4123U'],
    ['HSBC UK BANK PLC Camberley', '401605', 'HBUKGB4123V'],
    ['HSBC UK BANK PLC Truro', '401606', 'HBUKGB4123W'],
    ['HSBC UK BANK PLC Cambridge City Office', '401607', 'HBUKGB4124A'],
    ['HSBC UK BANK PLC Cambridge City', '401608', 'HBUKGB4103J'],
    ['HSBC UK BANK PLC Cannock', '401610', 'HBUKGB4124D'],
    ['HSBC UK BANK PLC Canterbury', '401611', 'HBUKGB4113W'],
    ['HSBC UK BANK PLC Cardiff Canton', '401612', 'HBUKGB4124G'],
    ['HSBC UK BANK PLC Cardiff Queen Street', '401613', 'HBUKGB4124H'],
    ['HSBC UK BANK PLC Cardiff Queen Street', '401615', 'HBUKGB4103K'],
    ['HSBC UK BANK PLC Cardiff Albany Road', '401617', 'HBUKGB4124L'],
    ['HSBC UK BANK PLC Cardiff, Queen Street', '401618', 'HBUKGB4110M'],
    ['HSBC UK BANK PLC Cardigan', '401620', 'HBUKGB4124N'],
    ['HSBC UK BANK PLC Carlisle English Street', '401622', 'HBUKGB4113L'],
    ['HSBC UK BANK PLC Carmarthen', '401623', 'HBUKGB4113T'],
    ['HSBC UK BANK PLC Sutton Coldfield', '401625', 'HBUKGB4119V'],
    ['HSBC UK BANK PLC Derby St Peters Street', '401626', 'HBUKGB4125A'],
    ['HSBC UK BANK PLC Castleford', '401627', 'HBUKGB4125B'],
    ['HSBC UK BANK PLC Croydon, North End', '401628', 'HBUKGB4125C'],
    ['HSBC UK BANK PLC Rhyd-y-Penau', '401630', 'HBUKGB4124K'],
    ['HSBC UK BANK PLC Reading Broad Street', '401633', 'HBUKGB4171F'],
    ['HSBC UK BANK PLC Cardiff   Albany Road', '401635', 'HBUKGB4124F'],
    ['HSBC UK BANK PLC Chepstow', '401642', 'HBUKGB4166M'],
    ['HSBC UK BANK PLC Retail Iplementation', '401644', ''],
    ['HSBC UK BANK PLC Production Orders Formerly', '401645', ''],
    ['HSBC UK BANK PLC Witney', '401646', 'HBUKGB4124W'],
    ['HSBC UK BANK PLC Cwmbran', '401647', 'HBUKGB4184I'],
    ['HSBC UK BANK PLC Cambridge, Cherry Hinton', '401648', ''],
    ['HSBC UK BANK PLC Canterbury', '401649', 'HBUKGB4181W'],
    ['HSBC UK BANK PLC Penzance', '401650', 'HBUKGB4184J'],
    ['HSBC UK BANK PLC Cardiff, Queen Street', '401651', 'HBUKGB4184K'],
    ['HSBC UK BANK PLC Carlisle City Office', '401653', 'HBUKGB4177V'],
    ['HSBC UK BANK PLC Carlisle', '401654', 'HBUKGB4177W'],
    ['HSBC UK BANK PLC Darlington', '401655', 'HBUKGB4177X'],
    ['HSBC UK BANK PLC Cambridge Hills Road', '401657', 'HBUKGB4124C'],
    ['HSBC UK BANK PLC Cambridge City Office', '401659', 'HBUKGB4187S'],
    ['HSBC UK BANK PLC First Direct Mortgages', '401688', 'HBUKGB4B72K'],
    ['HSBC UK BANK PLC National Sort Code 8', '401700', 'HBUKGB4194N'],
    ['HSBC UK BANK PLC Romford Market Place', '401703', 'HBUKGB4125D'],
    ['HSBC UK BANK PLC Sheffield City', '401704', 'HBUKGB4153R'],
    ['HSBC UK BANK PLC Chatham', '401705', 'HBUKGB4114E'],
    ['HSBC UK BANK PLC Stockport, Great Underbank', '401706', 'HBUKGB4125H'],
    ['HSBC UK BANK PLC Sutton Town Square', '401707', 'HBUKGB4125J'],
    ['HSBC UK BANK PLC Chelmsford City', '401708', 'HBUKGB4103M'],
    ['HSBC UK BANK PLC Cheltenham Promenade', '401709', 'HBUKGB4125K'],
    ['HSBC UK BANK PLC Cheltenham Promenade', '401710', 'HBUKGB4103P'],
    ['HSBC UK BANK PLC Chepstow', '401711', 'HBUKGB4125L'],
    ['HSBC UK BANK PLC Amersham on the Hill', '401712', 'HBUKGB4125M'],
    ['HSBC BANK PLC Cheshunt (401713)', '401713', 'MIDLGB2125N'],
    ['HSBC UK BANK PLC Chester', '401714', 'HBUKGB4103R'],
    ['HSBC UK BANK PLC Chesterfield', '401715', 'HBUKGB4113G'],
    ['HSBC UK BANK PLC Chichester', '401716', 'HBUKGB4103S'],
    ['HSBC UK BANK PLC Chippenham', '401717', 'HBUKGB4125U'],
    ['HSBC UK BANK PLC Evesham', '401718', 'HBUKGB4125V'],
    ['HSBC UK BANK PLC Banbury', '401719', 'HBUKGB4125W'],
    ['HSBC UK BANK PLC Wrexham', '401720', 'HBUKGB4165B'],
    ['HSBC UK BANK PLC Leyland', '401721', 'HBUKGB4126B'],
    ['HSBC UK BANK PLC Christchurch', '401722', 'HBUKGB4126C'],
    ['HSBC UK BANK PLC Shrewsbury High Street', '401723', 'HBUKGB4126D'],
    ['HSBC UK BANK PLC Gloucester The Cross', '401724', 'HBUKGB4126E'],
    ['HSBC UK BANK PLC Cirencester', '401725', 'HBUKGB4126F'],
    ['HSBC UK BANK PLC Clacton-on-Sea', '401726', 'HBUKGB4126G'],
    ['HSBC UK BANK PLC Dewsbury', '401727', 'HBUKGB4103T'],
    ['HSBC UK BANK PLC Grimsby', '401728', 'HBUKGB4126H'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '401731', 'HBUKGB4126K'],
    ['HSBC UK BANK PLC Bristol Whiteladies Road', '401732', 'HBUKGB4122K'],
    ['HSBC UK BANK PLC Westwood Cross', '401734', 'HBUKGB4145D'],
    ['HSBC UK BANK PLC Clitheroe', '401735', 'HBUKGB4126L'],
    ['HSBC UK BANK PLC Taunton', '401738', 'HBUKGB4125F'],
    ['HSBC UK BANK PLC Kingston on Thames, Eden St', '401739', 'HBUKGB4125P'],
    ['HSBC UK BANK PLC Sunderland The Bridge', '401741', 'HBUKGB4125R'],
    ['HSBC UK BANK PLC Eastleigh', '401742', 'HBUKGB4129W'],
    ['HSBC UK BANK PLC Chelmsford City', '401745', 'HBUKGB4115B'],
    ['HSBC UK BANK PLC Newcastle under Lyme', '401746', 'HBUKGB4157D'],
    ['HSBC UK BANK PLC Barkingside', '401747', 'HBUKGB4125T'],
    ['HSBC UK BANK PLC Stockport Great Underbank', '401748', 'HBUKGB4171M'],
    ['HSBC UK BANK PLC Keynsham', '401749', 'HBUKGB4122N'],
    ['HSBC UK BANK PLC Portishead', '401750', 'HBUKGB4126J'],
    ['HSBC UK BANK PLC Bromley High Street', '401751', 'HBUKGB4126A'],
    ['HSBC UK BANK PLC Cheltenham Promenade', '401753', 'HBUKGB4103N'],
    ['HSBC UK BANK PLC Ruthin', '401756', 'HBUKGB4184M'],
    ['HSBC UK BANK PLC Chatham, 231 High Street', '401757', 'HBUKGB4181X'],
    ['HSBC UK BANK PLC Chester, Eastgate Street', '401758', 'HBUKGB4177Y'],
    ['HSBC UK BANK PLC Gloucester, The Cross', '401759', 'HBUKGB4173R'],
    ['HSBC UK BANK PLC Cannock', '401760', 'HBUKGB4161J'],
    ['HSBC UK BANK PLC Bristol Whiteladies Road', '401761', 'HBUKGB4173N'],
    ['HSBC UK BANK PLC Portland Street Swansea', '401762', 'HBUKGB4184N'],
    ['HSBC UK BANK PLC Chelmsford City', '401763', 'HBUKGB4174W'],
    ['HSBC UK BANK PLC Cardiff, Queen Street', '401764', 'HBUKGB4192B'],
    ['HSBC BANK PLC Staff Hbeu', '401765', 'MIDLGB2195Z'],
    ['HSBC BANK PLC Mortgage Services Hbeu', '401766', 'MIDLGB2196A'],
    ['HSBC BANK PLC Risk Hbeu', '401767', 'MIDLGB2196B'],
    ['HSBC BANK PLC Central Charges Unit Hbeu', '401768', 'MIDLGB2196C'],
    ['HSBC UK BANK PLC Beaconsfield', '401769', 'HBUKGB4125E'],
    ['HSBC BANK PLC Leicester Chaps Hbeu', '401771', 'MIDLGB2196E'],
    ['HSBC BANK PLC Coventry Ppi Hbeu', '401773', 'MIDLGB2196G'],
    ['HSBC BANK PLC Mortgage Service Centre', '401774', 'MIDLGB2196H'],
    ['HSBC BANK PLC Banking Operations Hbeu', '401775', 'MIDLGB2196I'],
    ['HSBC BANK PLC Ukcc Sheffield Customer Ser', '401776', 'MIDLGB2196F'],
    ['HSBC BANK PLC Head Office Hbeu', '401780', 'MIDLGB2195X'],
    ['HSBC UK BANK PLC First Direct Mortgages', '401788', 'HBUKGB4B72L'],
    ['HSBC BANK PLC Head Office Hbeu', '401790', 'MIDLGB2195Y'],
    ['HSBC UK BANK PLC Cmb Sme Digital Banking UK', '401800', 'HBUKGB4194O'],
    ['HSBC UK BANK PLC Coalville', '401801', 'HBUKGB4137M'],
    ['HSBC UK BANK PLC Weybridge', '401802', 'HBUKGB4110J'],
    ['HSBC UK BANK PLC Workington', '401803', 'HBUKGB4126N'],
    ['HSBC UK BANK PLC Colchester', '401804', 'HBUKGB4103U'],
    ['HSBC UK BANK PLC Gloucester The Cross', '401805', 'HBUKGB4126R'],
    ['HSBC UK BANK PLC Sutton Coldfield', '401806', 'HBUKGB4119W'],
    ['HSBC UK BANK PLC Colwyn Bay', '401808', 'HBUKGB4126U'],
    ['HSBC UK BANK PLC Macclesfield', '401809', 'HBUKGB4126W'],
    ['HSBC UK BANK PLC Durham', '401810', 'HBUKGB4127A'],
    ['HSBC UK BANK PLC Corby Town Centre', '401812', 'HBUKGB4111H'],
    ['HSBC UK BANK PLC Chippenham', '401813', 'HBUKGB4127B'],
    ['HSBC UK BANK PLC Wrexham', '401814', 'HBUKGB4127C'],
    ['HSBC UK BANK PLC Waterlooville', '401815', 'HBUKGB4149U'],
    ['HSBC UK BANK PLC Beverley', '401816', 'HBUKGB4127D'],
    ['HSBC UK BANK PLC Coventry, Cathedral Lanes', '401817', 'HBUKGB4103V'],
    ['HSBC UK BANK PLC Cowbridge Glam', '401818', 'HBUKGB4127P'],
    ['HSBC UK BANK PLC Crawley Boulevard', '401822', 'HBUKGB4167F'],
    ['HSBC UK BANK PLC Exeter High Street', '401823', 'HBUKGB4127V'],
    ['HSBC UK BANK PLC Crewe Market Street', '401824', 'HBUKGB4127W'],
    ['HSBC UK BANK PLC Yeovil', '401825', 'HBUKGB4128B'],
    ['HSBC UK BANK PLC Croydon, North End', '401830', 'HBUKGB4128F'],
    ['HSBC UK BANK PLC Exeter High Street', '401831', 'HBUKGB4128H'],
    ['HSBC UK BANK PLC Enfield The Town', '401835', 'HBUKGB4126P'],
    ['HSBC UK BANK PLC Cwmbran Gwent Square', '401836', 'HBUKGB4128J'],
    ['HSBC UK BANK PLC Coventry, Cathedral Lanes', '401837', 'HBUKGB4127M'],
    ['HSBC UK BANK PLC Coventry Cheylesmore', '401838', 'HBUKGB4127G'],
    ['HSBC UK BANK PLC Tunbridge Wells Mount Pleas', '401839', 'HBUKGB4167H'],
    ['HSBC UK BANK PLC Coventry, Cathedral Lanes', '401840', 'HBUKGB4127L'],
    ['HSBC UK BANK PLC Croydon, North End', '401841', 'HBUKGB4103W'],
    ['HSBC UK BANK PLC Cromer', '401842', 'HBUKGB4167G'],
    ['HSBC UK BANK PLC Coventry, Cathedral Lanes', '401843', 'HBUKGB4127J'],
    ['HSBC UK BANK PLC Croydon, North End', '401844', 'HBUKGB4127E'],
    ['HSBC UK BANK PLC Wokingham', '401846', 'HBUKGB4128E'],
    ['HSBC UK BANK PLC Ashford Kent', '401848', 'HBUKGB4127T'],
    ['HSBC UK BANK PLC Guildford', '401850', 'HBUKGB4127U'],
    ['HSBC UK BANK PLC Colchester High Street', '401851', 'HBUKGB4110U'],
    ['HSBC UK BANK PLC Cramlington', '401852', 'HBUKGB4127R'],
    ['HSBC UK BANK PLC Boston', '401853', 'HBUKGB4174X'],
    ['HSBC UK BANK PLC Shotton', '401854', 'HBUKGB4184O'],
    ['HSBC UK BANK PLC Llandudno Mostyn Street', '401855', 'HBUKGB4184P'],
    ['HSBC UK BANK PLC Production Orders Departmen', '401856', 'HBUKGB4187O'],
    ['HSBC UK BANK PLC Coventry Cathedral Lanes', '401857', 'HBUKGB4173P'],
    ['HSBC UK BANK PLC Birmingham,  Northfield', '401858', 'HBUKGB4174Y'],
    ['HSBC UK BANK PLC Coventry, Cathedral Lanes', '401859', 'HBUKGB4127N'],
    ['HSBC UK BANK PLC Coleraine', '401860', 'HBUKGB4171T'],
    ['HSBC UK BANK PLC Cmau (Cent Mgd Accs Unit)', '401861', 'HBUKGB4171S'],
    ['HSBC UK BANK PLC Coventry, Cathedral Lanes', '401862', 'HBUKGB4174Z'],
    ['HSBC UK BANK PLC Newport Iow', '401863', 'HBUKGB4181Y'],
    ['HSBC UK BANK PLC Waterlooville', '401864', 'HBUKGB4181Z'],
    ['HSBC UK BANK PLC Llandudno, Mostyn Road', '401865', 'HBUKGB4173T'],
    ['HSBC UK BANK PLC Halesowen', '401866', 'HBUKGB4175A'],
    ['HSBC UK BANK PLC Leominster', '401867', 'HBUKGB4175B'],
    ['HSBC UK BANK PLC Crawley', '401868', 'HBUKGB4182A'],
    ['HSBC UK BANK PLC Porthmadog', '401869', 'HBUKGB4184Q'],
    ['HSBC BANK PLC Compton Research Station', '401870', ''],
    ['HSBC UK BANK PLC Barnsley', '401872', 'HBUKGB4177Z'],
    ['HSBC UK BANK PLC Port Talbot', '401873', 'HBUKGB4173U'],
    ['HSBC UK BANK PLC HSBC Payments Iba Accounts', '401900', 'HBUKGB4BXXX'],
    ['HSBC UK BANK PLC West Bromwich', '401902', 'HBUKGB4112W'],
    ['HSBC UK BANK PLC Darlington', '401903', 'HBUKGB4104A'],
    ['HSBC UK BANK PLC Dartford', '401904', 'HBUKGB4128K'],
    ['HSBC UK BANK PLC Kingsbridge', '401905', 'HBUKGB4128L'],
    ['HSBC UK BANK PLC Blackburn', '401906', 'HBUKGB4128M'],
    ['HSBC UK BANK PLC Daventry', '401907', 'HBUKGB4128N'],
    ['HSBC UK BANK PLC Dover', '401910', 'HBUKGB4128P'],
    ['HSBC UK BANK PLC Denbigh', '401912', 'HBUKGB4128R'],
    ['HSBC UK BANK PLC Derby   St Peters Street', '401915', 'HBUKGB4104B'],
    ['HSBC UK BANK PLC Devizes', '401916', 'HBUKGB4128V'],
    ['HSBC UK BANK PLC Dewsbury', '401917', 'HBUKGB4128W'],
    ['HSBC UK BANK PLC Diss', '401918', 'HBUKGB4129B'],
    ['HSBC UK BANK PLC Dolgellau', '401919', 'HBUKGB4129C'],
    ['HSBC UK BANK PLC Doncaster', '401920', 'HBUKGB4111E'],
    ['HSBC UK BANK PLC Dorchester', '401921', 'HBUKGB4129F'],
    ['HSBC UK BANK PLC Leatherhead', '401922', 'HBUKGB4129G'],
    ['HSBC UK BANK PLC Dover', '401923', 'HBUKGB4129J'],
    ['HSBC UK BANK PLC Kings Lynn', '401925', 'HBUKGB4129L'],
    ['HSBC UK BANK PLC Driffield Yorks', '401926', 'HBUKGB4129M'],
    ['HSBC UK BANK PLC Droitwich', '401927', 'HBUKGB4129N'],
    ['HSBC UK BANK PLC Dudley', '401928', 'HBUKGB4114D'],
    ['HSBC UK BANK PLC Luton George Street', '401930', 'HBUKGB4129P'],
    ['HSBC UK BANK PLC Durham Saddler Street', '401931', 'HBUKGB4114B'],
    ['HSBC UK BANK PLC Stroud', '401932', 'HBUKGB4129R'],
    ['HSBC UK BANK PLC Solihul', '401934', 'HBUKGB4155D'],
    ['HSBC UK BANK PLC Didcot', '401935', 'HBUKGB4129A'],
    ['HSBC UK BANK PLC Derby St Peters Street', '401937', 'HBUKGB4128T'],
    ['HSBC BANK PLC Douglas', '401938', 'MIDLIM21XXX'],
    ['HSBC UK BANK PLC Colchester High Street', '401943', 'HBUKGB4129K'],
    ['HSBC UK BANK PLC HSBC UK Bank plc', '401950', 'HBUKGB4BRTN'],
    ['HSBC UK BANK PLC Chesterfield', '401951', 'HBUKGB4153T'],
    ['HSBC UK BANK PLC Newton Abbot', '401952', 'HBUKGB4184R'],
    ['HSBC UK BANK PLC Darlington', '401953', 'HBUKGB4178A'],
    ['HSBC UK BANK PLC Huddersfield, Cloth Hall St', '401954', 'HBUKGB4178B'],
    ['HSBC UK BANK PLC Denham', '401955', ''],
    ['HSBC UK BANK PLC Ashton under Lyne', '401956', 'HBUKGB4178C'],
    ['HSBC UK BANK PLC Derby St Peters Street', '401958', 'HBUKGB4175C'],
    ['HSBC UK BANK PLC Rotherham College Street', '401963', 'HBUKGB4178D'],
    ['HSBC UK BANK PLC Ashton under Lyne', '401965', 'HBUKGB4178E'],
    ['HSBC UK BANK PLC Ripley', '401966', 'HBUKGB4175E'],
    ['HSBC UK BANK PLC Durham', '401967', 'HBUKGB4178F'],
    ['HSBC UK BANK PLC Duxford', '401969', ''],
    ['HSBC UK BANK PLC Birmingham City Comm Centre', '401989', ''],
    ['HSBC UK BANK PLC Bournemouth & Poole Comm', '401990', ''],
    ['HSBC UK BANK PLC Bradford Commercial Centre', '401991', ''],
    ['HSBC UK BANK PLC Brighton Commercial Centre', '401992', ''],
    ['HSBC UK BANK PLC Bristol Corporate Centre', '401993', 'HBUKGB4189D'],
    ['HSBC UK BANK PLC Bromley Commercial Centre', '401994', ''],
    ['HSBC UK BANK PLC Caernarfon Comm Centre', '401995', ''],
    ['HSBC UK BANK PLC Cardiff Commercial Centre', '401996', ''],
    ['HSBC UK BANK PLC Carmarthen Comm Centre', '401997', ''],
    ['HSBC UK BANK PLC Shotton', '401998', 'HBUKGB4188K'],
    ['HSBC UK BANK PLC Risk Retail Credit Services', '402000', 'HBUKGB4193F'],
    ['HSBC UK BANK PLC Hinckley', '402001', 'HBUKGB4129T'],
    ['HSBC UK BANK PLC York Parliament Street', '402002', 'HBUKGB4165K'],
    ['HSBC UK BANK PLC Terminus Road, Eastbourne', '402005', 'HBUKGB4129U'],
    ['HSBC UK BANK PLC Eastbourne Terminus Road', '402006', 'HBUKGB4104C'],
    ['HSBC UK BANK PLC Ruislip', '402007', 'HBUKGB4129V'],
    ['HSBC UK BANK PLC East Dereham', '402008', 'HBUKGB4128U'],
    ['HSBC UK BANK PLC East Grinstead', '402009', 'HBUKGB4111W'],
    ['HSBC UK BANK PLC Eastleigh', '402010', 'HBUKGB4130A'],
    ['HSBC UK BANK PLC Eastwood Notts', '402012', 'HBUKGB4130B'],
    ['HSBC UK BANK PLC Merthyr Tydfil', '402013', 'HBUKGB4130C'],
    ["HSBC UK BANK PLC Manchester St Ann's (401156", '402014', 'HBUKGB4130D'],
    ['HSBC UK BANK PLC Edgware Burnt Oak', '402015', 'HBUKGB4167K'],
    ['HSBC UK BANK PLC Edgware Station Road', '402016', 'HBUKGB4104D'],
    ['HSBC UK BANK PLC Whitehaven', '402017', 'HBUKGB4130H'],
    ['HSBC UK BANK PLC Oswestry', '402019', 'HBUKGB4130K'],
    ['HSBC UK BANK PLC Ellesmere Port', '402020', 'HBUKGB4130J'],
    ['HSBC UK BANK PLC Enfield The Town', '402022', 'HBUKGB4130M'],
    ['HSBC UK BANK PLC Enfield The Town', '402023', 'HBUKGB4104E'],
    ['HSBC UK BANK PLC Epsom', '402024', 'HBUKGB4112G'],
    ['HSBC UK BANK PLC Dartford', '402025', 'HBUKGB4130R'],
    ['HSBC UK BANK PLC Weybridge', '402026', 'HBUKGB4112K'],
    ['HSBC UK BANK PLC Evesham', '402027', 'HBUKGB4130T'],
    ['HSBC UK BANK PLC Epsom', '402028', 'HBUKGB4130P'],
    ['HSBC UK BANK PLC Exeter High Street', '402029', 'HBUKGB4130U'],
    ['HSBC UK BANK PLC Exeter High Street', '402030', 'HBUKGB4104F'],
    ['HSBC UK BANK PLC Sidmouth', '402032', 'HBUKGB4130V'],
    ['HSBC UK BANK PLC Diss', '402033', 'HBUKGB4130W'],
    ['HSBC UK BANK PLC Windsor High Street', '402034', 'HBUKGB4130G'],
    ['HSBC UK BANK PLC East Grinstead', '402035', 'HBUKGB4130F'],
    ['HSBC UK BANK PLC Harlow', '402037', 'HBUKGB4130N'],
    ['HSBC UK BANK PLC Newmarket', '402038', 'HBUKGB4130L'],
    ['HSBC UK BANK PLC Epworth', '402039', 'HBUKGB4129D'],
    ['HSBC UK BANK PLC Stafford', '402041', 'HBUKGB4130E'],
    ['HSBC UK BANK PLC Croydon, North End', '402042', 'HBUKGB4128G'],
    ['HSBC UK BANK PLC Edinburgh, Hanover Street', '402044', 'HBUKGB4111M'],
    ['HSBC UK BANK PLC Guildford', '402048', 'HBUKGB4182D'],
    ['HSBC UK BANK PLC Weybridge', '402049', 'HBUKGB4182E'],
    ['HSBC UK BANK PLC Chichester', '402050', 'HBUKGB4182F'],
    ['HSBC UK BANK PLC Southend-on-Sea', '402051', 'HBUKGB4175F'],
    ['HSBC UK BANK PLC Norwich London Street', '402052', 'HBUKGB4175G'],
    ['HSBC UK BANK PLC Exeter, High Street', '402053', 'HBUKGB4184S'],
    ['HSBC UK BANK PLC Edinburgh, Hanover Street', '402054', 'HBUKGB4188B'],
    ['HSBC UK BANK PLC Belfast Royal Avenue', '402055', 'HBUKGB4193U'],
    ['HSBC UK BANK PLC City of London Comm Centre', '402067', ''],
    ['HSBC UK BANK PLC Coventry & N. Warwickshire', '402068', ''],
    ['HSBC UK BANK PLC Eastbourne Terminus Road', '402069', 'HBUKGB4189Q'],
    ['HSBC UK BANK PLC Enfield Commercial Centre', '402070', ''],
    ['HSBC UK BANK PLC Gloucester Comm Centre', '402071', ''],
    ['HSBC UK BANK PLC Greater Bristol Comm Centre', '402072', ''],
    ['HSBC UK BANK PLC Hammersmith Comm Centre', '402073', ''],
    ['HSBC UK BANK PLC Harborne Commercial Centre', '402074', ''],
    ['HSBC UK BANK PLC Harrow & Watford Comm Centr', '402075', ''],
    ['HSBC UK BANK PLC Heathrow Commercial Centre', '402076', 'HBUKGB4188L'],
    ['HSBC UK BANK PLC High Wycombe Comm Centre', '402077', ''],
    ['HSBC UK BANK PLC Leeds Commercial Centre', '402078', ''],
    ['HSBC UK BANK PLC Llandudno Commercial Centre', '402079', ''],
    ["HSBC UK BANK PLC Manchester St Ann's (401156", '402080', 'HBUKGB4190J'],
    ['HSBC UK BANK PLC Mid Essex Commercial Centre', '402081', ''],
    ['HSBC UK BANK PLC Newport Commercial Centre', '402082', ''],
    ['HSBC UK BANK PLC North Nottingham & N Derby', '402083', ''],
    ['HSBC UK BANK PLC Northamptonshire Comm Centr', '402084', ''],
    ['HSBC UK BANK PLC Norwich London Street', '402085', 'HBUKGB4188M'],
    ['HSBC UK BANK PLC Nottingham Comm Centre', '402086', 'HBUKGB4188N'],
    ['HSBC UK BANK PLC Fakenham', '402101', 'HBUKGB4131A'],
    ['HSBC UK BANK PLC Falmouth', '402102', 'HBUKGB4131B'],
    ['HSBC UK BANK PLC Fareham Hants', '402103', 'HBUKGB4131C'],
    ['HSBC UK BANK PLC Farnham Surrey', '402105', 'HBUKGB4112U'],
    ['HSBC UK BANK PLC Woodbridge', '402106', 'HBUKGB4131D'],
    ['HSBC UK BANK PLC Scarborough, St Nicholas St', '402110', 'HBUKGB4131F'],
    ['HSBC UK BANK PLC Haverfordwest', '402111', 'HBUKGB4131G'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '402112', 'HBUKGB4131J'],
    ['HSBC UK BANK PLC Shotton', '402113', 'HBUKGB4131K'],
    ['HSBC UK BANK PLC Coventry, Cathedral Lanes', '402114', 'HBUKGB4127K'],
    ['HSBC UK BANK PLC Folkestone Sandgate Road', '402115', 'HBUKGB4131M'],
    ['HSBC UK BANK PLC Salisbury', '402116', 'HBUKGB4131N'],
    ['HSBC UK BANK PLC Sutton Coldfield', '402117', 'HBUKGB4158B'],
    ['HSBC UK BANK PLC Frome', '402119', 'HBUKGB4131R'],
    ['HSBC UK BANK PLC Southport Lord Street', '402120', 'HBUKGB4138L'],
    ['HSBC UK BANK PLC Ringwood', '402121', 'HBUKGB4163P'],
    ['HSBC UK BANK PLC Hounslow High Street', '402122', 'HBUKGB4131E'],
    ['HSBC UK BANK PLC Clacton on Sea', '402125', 'HBUKGB4131P'],
    ['HSBC UK BANK PLC Bolton Victoria Square', '402126', 'HBUKGB4120U'],
    ['HSBC UK BANK PLC Farnborough', '402127', 'HBUKGB4131H'],
    ['HSBC UK BANK PLC Chester Eastgate Street', '402128', 'HBUKGB4161V'],
    ['HSBC UK BANK PLC Folkestone Sandgate Road', '402129', 'HBUKGB4131L'],
    ['HSBC UK BANK PLC Saxmundham', '402130', 'HBUKGB4164K'],
    ['HSBC UK BANK PLC Eastleigh', '402133', 'HBUKGB4182G'],
    ['HSBC UK BANK PLC Scarborough, St Nicholas St', '402134', 'HBUKGB4178G'],
    ['HSBC UK BANK PLC Canterbury', '402135', 'HBUKGB4182H'],
    ['HSBC UK BANK PLC Bognor Regis High Street', '402136', 'HBUKGB4182I'],
    ['HSBC UK BANK PLC Caerphilly', '402137', 'HBUKGB4184T'],
    ['HSBC UK BANK PLC Portland Street Swansea', '402138', 'HBUKGB4184U'],
    ['HSBC UK BANK PLC High Wycombe Corn Market', '402140', 'HBUKGB4175H'],
    ['HSBC UK BANK PLC East Grinstead', '402141', 'HBUKGB4182K'],
    ['HSBC UK BANK PLC St Austell', '402142', 'HBUKGB4184V'],
    ['HSBC UK BANK PLC Newport, Isle of Wight', '402143', 'HBUKGB4182L'],
    ['HSBC UK BANK PLC Camberley', '402144', 'HBUKGB4182M'],
    ['HSBC UK BANK PLC Preston Fishergate', '402145', 'HBUKGB4173W'],
    ['HSBC UK BANK PLC Gainsborough', '402201', 'HBUKGB4131T'],
    ['HSBC UK BANK PLC Preston, Fishergate', '402202', 'HBUKGB4150D'],
    ['HSBC UK BANK PLC Newcastle-upon-Tyne City', '402204', 'HBUKGB4131U'],
    ['HSBC UK BANK PLC Beaconsfield', '402205', 'HBUKGB4104G'],
    ['HSBC UK BANK PLC Romford Market Place', '402206', 'HBUKGB4131V'],
    ['HSBC UK BANK PLC Street', '402207', 'HBUKGB4131W'],
    ['HSBC UK BANK PLC Stockport Great Underbank', '402208', 'HBUKGB4132A'],
    ['HSBC UK BANK PLC Gloucester The Cross', '402209', 'HBUKGB4104H'],
    ['HSBC UK BANK PLC Guildford', '402212', 'HBUKGB4132B'],
    ['HSBC UK BANK PLC Selby', '402213', 'HBUKGB4132C'],
    ['HSBC UK BANK PLC Reading Broad Street', '402214', 'HBUKGB4150U'],
    ['HSBC UK BANK PLC Llanelli', '402215', 'HBUKGB4158H'],
    ['HSBC UK BANK PLC Whitehaven', '402216', 'HBUKGB4132F'],
    ['HSBC UK BANK PLC Gosport', '402217', 'HBUKGB4132G'],
    ['HSBC UK BANK PLC Barrow in Furness', '402218', 'HBUKGB4132H'],
    ['HSBC UK BANK PLC Grantham', '402219', 'HBUKGB4104J'],
    ['HSBC UK BANK PLC Gravesend', '402220', 'HBUKGB4132J'],
    ['HSBC UK BANK PLC Basildon', '402221', 'HBUKGB4132K'],
    ['HSBC UK BANK PLC Great Yarmouth', '402222', 'HBUKGB4104K'],
    ['HSBC UK BANK PLC Grimsby', '402224', 'HBUKGB4112D'],
    ['HSBC BANK PLC Guernsey , Channel Islands', '402225', 'MIDLGGS1XXX'],
    ['HSBC UK BANK PLC Guildford', '402226', 'HBUKGB4104L'],
    ['HSBC UK BANK PLC Guisborough', '402227', 'HBUKGB4132P'],
    ['HSBC UK BANK PLC Leeds City Office', '402228', 'HBUKGB4137A'],
    ['HSBC UK BANK PLC Ealing Broadway', '402230', 'HBUKGB4132M'],
    ['HSBC UK BANK PLC Worthing Warwick Street', '402233', 'HBUKGB4164U'],
    ['HSBC UK BANK PLC Great Yarmouth', '402234', 'HBUKGB4132E'],
    ['HSBC UK BANK PLC Chatham, 231 High Street', '402235', 'HBUKGB4168M'],
    ['HSBC UK BANK PLC Chelmsford City', '402246', 'HBUKGB4132L'],
    ['HSBC UK BANK PLC Glasgow City Office', '402247', 'HBUKGB4112A'],
    ['HSBC UK BANK PLC Paignton Palace Avenue', '402254', 'HBUKGB4184Z'],
    ['HSBC UK BANK PLC Sunderland, The Bridges', '402255', 'HBUKGB4178H'],
    ['HSBC UK BANK PLC Leatherhead', '402257', 'HBUKGB4182N'],
    ['HSBC UK BANK PLC Private Clients', '402258', ''],
    ['HSBC UK BANK PLC Amersham on the Hill', '402260', 'HBUKGB4175I'],
    ['HSBC UK BANK PLC Guilford High Street', '402261', 'HBUKGB4182O'],
    ['HSBC BANK PLC Guernsey St Martin', '402262', ''],
    ['HSBC BANK PLC Guernsey St Sampson', '402263', ''],
    ['HSBC UK BANK PLC Perth High Street', '402264', 'HBUKGB4192Q'],
    ['HSBC UK BANK PLC Reading Broad Street', '402265', 'HBUKGB4192R'],
    ['HSBC UK BANK PLC Inverness', '402266', 'HBUKGB4193D'],
    ['HSBC UK BANK PLC Nottingham Clumber Street', '402267', 'HBUKGB4193S'],
    ['HSBC UK BANK PLC Sunderland The Bridges', '402269', 'HBUKGB4193O'],
    ['HSBC UK BANK PLC Witney', '402270', 'HBUKGB4193I'],
    ['HSBC UK BANK PLC Eastbourne Terminus Road', '402271', 'HBUKGB4193H'],
    ['HSBC UK BANK PLC Street', '402272', 'HBUKGB4193N'],
    ['HSBC UK BANK PLC Leominster', '402273', 'HBUKGB4193L'],
    ['HSBC UK BANK PLC Porthmadog', '402274', 'HBUKGB4193J'],
    ['HSBC UK BANK PLC Llangefni', '402275', 'HBUKGB4193M'],
    ['HSBC UK BANK PLC Wrexham', '402276', 'HBUKGB4193K'],
    ['HSBC UK BANK PLC Wrexham', '402277', 'HBUKGB4193P'],
    ['HSBC UK BANK PLC Oswestry', '402278', 'HBUKGB4193Q'],
    ['HSBC UK BANK PLC Cambridge City', '402281', 'HBUKGB4193R'],
    ['HSBC UK BANK PLC Eastbourne Terminus Road', '402301', 'HBUKGB4132T'],
    ['HSBC UK BANK PLC Altrincham', '402302', 'HBUKGB4116L'],
    ['HSBC UK BANK PLC Halesowen', '402303', 'HBUKGB4112T'],
    ['HSBC UK BANK PLC Beccles', '402304', 'HBUKGB4132U'],
    ['HSBC UK BANK PLC Halifax', '402305', 'HBUKGB4112H'],
    ['HSBC UK BANK PLC Hexham', '402306', 'HBUKGB4132W'],
    ['HSBC UK BANK PLC Hanley', '402307', 'HBUKGB4104M'],
    ['HSBC UK BANK PLC Diss', '402309', 'HBUKGB4133A'],
    ['HSBC UK BANK PLC Harlow', '402310', 'HBUKGB4104N'],
    ['HSBC UK BANK PLC Harpenden', '402311', 'HBUKGB4133B'],
    ['HSBC UK BANK PLC Harrogate', '402312', 'HBUKGB4104P'],
    ["HSBC UK BANK PLC Harrow St Ann's Road", '402313', 'HBUKGB4133E'],
    ['HSBC UK BANK PLC Guildford', '402315', 'HBUKGB4133F'],
    ['HSBC UK BANK PLC Burgess Hill', '402317', 'HBUKGB4133G'],
    ['HSBC UK BANK PLC Hastings', '402318', 'HBUKGB4114A'],
    ['HSBC UK BANK PLC Welwyn Garden City Howardsg', '402319', 'HBUKGB4114W'],
    ['HSBC UK BANK PLC Waterlooville', '402320', 'HBUKGB4133H'],
    ['HSBC UK BANK PLC Haverfordwest', '402321', 'HBUKGB4133J'],
    ['HSBC UK BANK PLC Shotton', '402322', 'HBUKGB4133M'],
    ['HSBC UK BANK PLC Settle', '402323', 'HBUKGB4133N'],
    ['HSBC UK BANK PLC Brecon', '402324', 'HBUKGB4133P'],
    ['HSBC UK BANK PLC Hayes Middx', '402326', 'HBUKGB4104R'],
    ['HSBC BANK PLC Haywards Heath (402327)', '402327', 'MIDLGB2110V'],
    ['HSBC UK BANK PLC Kingston upon Thames', '402329', 'HBUKGB4136G'],
    ['HSBC UK BANK PLC Bury St Edmunds', '402331', 'HBUKGB4133L'],
    ['HSBC UK BANK PLC Braintree', '402332', 'HBUKGB4132V'],
    ['HSBC UK BANK PLC Southend-on-Sea', '402333', 'HBUKGB4132R'],
    ['HSBC UK BANK PLC Stockport Great Underbank', '402334', 'HBUKGB4156W'],
    ['HSBC UK BANK PLC Porthmadog', '402336', 'HBUKGB4185A'],
    ['HSBC UK BANK PLC Shrewsbury High Street', '402337', 'HBUKGB4175J'],
    ['HSBC UK BANK PLC Hartlepool, York Road', '402338', 'HBUKGB4178I'],
    ['HSBC UK BANK PLC Burnley', '402339', 'HBUKGB4178J'],
    ['HSBC UK BANK PLC High Wycombe,Cornmarket', '402341', 'HBUKGB4175K'],
    ['HSBC UK BANK PLC Hexham', '402342', 'HBUKGB4178K'],
    ['HSBC UK BANK PLC Northampton Abington Street', '402344', 'HBUKGB4193G'],
    ['HSBC UK BANK PLC Hartlepool 112 York Road', '402348', 'HBUKGB4115D'],
    ['HSBC BANK PLC Jersey, Halkett Street', '402353', 'MIDLJEH105A'],
    ['HSBC BANK PLC Jersey, Five Oaks', '402354', 'MIDLJEH106A'],
    ['HSBC BANK PLC Guernsey High St Premier', '402355', 'MIDLGGS101A'],
    ['HSBC BANK PLC Guernsey St Martin', '402356', 'MIDLGGS101B'],
    ['HSBC BANK PLC Guernsey St Sampson', '402357', 'MIDLGGS101C'],
    ['HSBC BANK PLC Sark, Channel Islands', '402358', 'MIDLGGS101D'],
    ['HSBC BANK PLC Alderney, Channel Islands', '402359', 'MIDLGGS101E'],
    ['HSBC UK BANK PLC Commercial Direct-Swansea', '402360', 'HBUKGB4193V'],
    ['HSBC UK BANK PLC Plymouth City Centre', '402364', 'HBUKGB4190T'],
    ['HSBC UK BANK PLC Portsmouth Comm Centre', '402365', ''],
    ['HSBC UK BANK PLC Preston Fishergate', '402366', 'HBUKGB4190X'],
    ['HSBC UK BANK PLC Sheffield City', '402367', 'HBUKGB4190Z'],
    ['HSBC UK BANK PLC Solihull Commercial Centre', '402368', ''],
    ['HSBC UK BANK PLC South Essex Comm Centre', '402369', ''],
    ['HSBC UK BANK PLC Stourbridge & Nyre Forest', '402371', ''],
    ['HSBC UK BANK PLC Stratford Commercial Centre', '402372', ''],
    ['HSBC UK BANK PLC Team Valley Comm Centre', '402373', ''],
    ['HSBC UK BANK PLC Oxford Cornmarket Street', '402401', 'HBUKGB4133R'],
    ['HSBC UK BANK PLC Iikeston', '402402', 'HBUKGB4133T'],
    ['HSBC UK BANK PLC Dewsbury', '402403', 'HBUKGB4133U'],
    ['HSBC UK BANK PLC Cannock', '402404', 'HBUKGB4124E'],
    ['HSBC UK BANK PLC York Parliament', '402405', 'HBUKGB4165L'],
    ['HSBC UK BANK PLC Falmouth', '402406', 'HBUKGB4133V'],
    ['HSBC UK BANK PLC Berkhamsted', '402407', 'HBUKGB4115F'],
    ['HSBC UK BANK PLC Henley-on-Thames', '402410', 'HBUKGB4113V'],
    ['HSBC UK BANK PLC Hereford', '402411', 'HBUKGB4133W'],
    ['HSBC UK BANK PLC Herne Bay', '402412', 'HBUKGB4134A'],
    ['HSBC UK BANK PLC Hertford', '402413', 'HBUKGB4134B'],
    ['HSBC UK BANK PLC Preston Fishergate', '402414', 'HBUKGB4150E'],
    ['HSBC UK BANK PLC Bromborough', '402415', 'HBUKGB4164C'],
    ['HSBC UK BANK PLC Hexham', '402416', 'HBUKGB4134D'],
    ['HSBC UK BANK PLC High Wycombe Corn Mkt', '402417', 'HBUKGB4104S'],
    ['HSBC UK BANK PLC Uxbridge', '402418', 'HBUKGB4134F'],
    ['HSBC UK BANK PLC Hinckley', '402419', 'HBUKGB4111K'],
    ['HSBC UK BANK PLC Hitchin', '402420', 'HBUKGB4104T'],
    ['HSBC UK BANK PLC Hoddesdon', '402421', 'HBUKGB4134G'],
    ['HSBC UK BANK PLC Spalding', '402422', 'HBUKGB4156E'],
    ['HSBC UK BANK PLC Huddersfield Cloth Hall Str', '402424', 'HBUKGB4134V'],
    ['HSBC UK BANK PLC Holsworthy', '402425', 'HBUKGB4134H'],
    ['HSBC UK BANK PLC Llangefni', '402426', 'HBUKGB4134K'],
    ['HSBC UK BANK PLC Mold', '402427', 'HBUKGB4134L'],
    ['HSBC UK BANK PLC Honiton', '402428', 'HBUKGB4134M'],
    ['HSBC UK BANK PLC Lincoln Stonebow', '402429', 'HBUKGB4134P'],
    ['HSBC UK BANK PLC Hornchurch', '402430', 'HBUKGB4134R'],
    ['HSBC UK BANK PLC Bridlington, King Street', '402431', 'HBUKGB4134T'],
    ['HSBC UK BANK PLC Horsforth', '402432', 'HBUKGB4137G'],
    ['HSBC UK BANK PLC Horsham', '402433', 'HBUKGB4114G'],
    ['HSBC UK BANK PLC Hull , Merit House', '402434', 'HBUKGB4134C'],
    ['HSBC UK BANK PLC Hull Jameson Street', '402436', 'HBUKGB4134W'],
    ['HSBC UK BANK PLC Crawley', '402437', 'HBUKGB4134N'],
    ['HSBC UK BANK PLC High Wycombe Corn Market', '402438', 'HBUKGB4134E'],
    ['HSBC UK BANK PLC Bromborough', '402440', 'HBUKGB4164D'],
    ['HSBC UK BANK PLC Macclesfield', '402441', 'HBUKGB4128A'],
    ['HSBC UK BANK PLC Cromer', '402443', 'HBUKGB4134J'],
    ['HSBC UK BANK PLC Chatham, 231 High Street', '402444', 'HBUKGB4182Q'],
    ['HSBC UK BANK PLC Stratford upon Avon', '402445', 'HBUKGB4175L'],
    ['HSBC UK BANK PLC Barnsley', '402446', 'HBUKGB4178L'],
    ['HSBC UK BANK PLC Rushden', '402447', 'HBUKGB4175M'],
    ['HSBC UK BANK PLC New Milton', '402448', 'HBUKGB4182R'],
    ['HSBC UK BANK PLC Rochdale', '402449', 'HBUKGB4178M'],
    ['HSBC UK BANK PLC Hounslow High Street', '402501', 'HBUKGB4115J'],
    ['HSBC UK BANK PLC Hounslow High Street', '402502', 'HBUKGB4111F'],
    ['HSBC UK BANK PLC Hove Church Road', '402503', 'HBUKGB4112R'],
    ['HSBC UK BANK PLC Hove, Church Road', '402506', 'HBUKGB4113U'],
    ['HSBC UK BANK PLC Selby', '402507', 'HBUKGB4132D'],
    ['HSBC UK BANK PLC Bromborough', '402508', 'HBUKGB4164E'],
    ['HSBC UK BANK PLC Nottingham Clumber Street', '402509', 'HBUKGB4134U'],
    ['HSBC UK BANK PLC Huddersfield Cloth Hall St', '402510', 'HBUKGB4104U'],
    ['HSBC UK BANK PLC Hull , Merit House', '402514', 'HBUKGB4135A'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '402515', 'HBUKGB4135B'],
    ['HSBC UK BANK PLC Hull Jameson Street', '402516', 'HBUKGB4135C'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '402518', 'HBUKGB4111B'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '402519', 'HBUKGB4135D'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '402520', 'HBUKGB4110T'],
    ['HSBC UK BANK PLC St Ives, Cambridgeshire', '402522', 'HBUKGB4135E'],
    ['HSBC UK BANK PLC Stockport Great Underbank', '402523', 'HBUKGB4135G'],
    ['HSBC UK BANK PLC Hythe Hants', '402524', 'HBUKGB4135H'],
    ['HSBC UK BANK PLC Folkestone Sandgate Road', '402525', 'HBUKGB4135J'],
    ['HSBC UK BANK PLC Ilford High Road', '402527', 'HBUKGB4104V'],
    ['HSBC UK BANK PLC Ilkeston', '402529', 'HBUKGB4135L'],
    ['HSBC UK BANK PLC Ilkley', '402530', 'HBUKGB4135M'],
    ['HSBC UK BANK PLC Ipswich Tavern Street', '402531', 'HBUKGB4114U'],
    ['HSBC BANK PLC St Helier', '402533', 'MIDLJEH104A'],
    ['HSBC BANK PLC Halkett Street, Jersey', '402534', 'MIDLJEH1XXX'],
    ['HSBC UK BANK PLC Ilford High Road', '402536', 'HBUKGB4168R'],
    ['HSBC BANK PLC Jersey St Brelade', '402541', 'MIDLJEJ1XXX'],
    ['HSBC UK BANK PLC Ipswich Tavern Street', '402546', 'HBUKGB4135N'],
    ['HSBC UK BANK PLC Beverley', '402548', 'HBUKGB4178N'],
    ['HSBC UK BANK PLC Hull Jameson Street', '402549', 'HBUKGB4187W'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '402550', 'HBUKGB4178O'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '402551', 'HBUKGB4178P'],
    ['HSBC UK BANK PLC Wellinborough', '402552', 'HBUKGB4187C'],
    ['HSBC UK BANK PLC Slough High Street', '402553', 'HBUKGB4182U'],
    ['HSBC UK BANK PLC Hull, 7-8 Whitefriar', '402557', 'HBUKGB4187Y'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '402559', 'HBUKGB4187Z'],
    ['HSBC BANK PLC Jersey Fiveoaks', '402561', ''],
    ['HSBC BANK PLC Jersey St Helier Halkett St', '402562', ''],
    ['HSBC UK BANK PLC Keighley', '402601', 'HBUKGB4135T'],
    ['HSBC UK BANK PLC Kendal', '402602', 'HBUKGB4135V'],
    ['HSBC UK BANK PLC Kenilworth The Square', '402604', 'HBUKGB4104W'],
    ['HSBC UK BANK PLC Penrith', '402606', 'HBUKGB4135W'],
    ['HSBC UK BANK PLC Kettering', '402607', 'HBUKGB4136A'],
    ['HSBC UK BANK PLC Kidderminster Church St', '402608', 'HBUKGB4136C'],
    ['HSBC UK BANK PLC Kingsbridge', '402610', 'HBUKGB4136F'],
    ['HSBC UK BANK PLC Kings Lynn', '402611', 'HBUKGB4136E'],
    ['HSBC UK BANK PLC Kingston upon Thames', '402612', 'HBUKGB4111G'],
    ['HSBC UK BANK PLC Kingswinford', '402613', 'HBUKGB4136H'],
    ['HSBC UK BANK PLC York Parliament', '402615', 'HBUKGB4165M'],
    ['HSBC UK BANK PLC Lancaster', '402617', 'HBUKGB4124V'],
    ['HSBC UK BANK PLC Kendal', '402618', 'HBUKGB4136J'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '402619', 'HBUKGB4150F'],
    ['HSBC UK BANK PLC Harrogate', '402620', 'HBUKGB4136K'],
    ['HSBC UK BANK PLC Newtown', '402621', 'HBUKGB4136L'],
    ['HSBC UK BANK PLC Solihull', '402622', 'HBUKGB4155E'],
    ['HSBC UK BANK PLC Walsall', '402623', 'HBUKGB4120A'],
    ['HSBC UK BANK PLC Knutsford', '402625', 'HBUKGB4136M'],
    ['HSBC UK BANK PLC Keynsham', '402627', 'HBUKGB4122P'],
    ['HSBC UK BANK PLC Oxford Cornmarket Street', '402631', 'HBUKGB4136D'],
    ['HSBC UK BANK PLC Bedford Allhallows', '402632', 'HBUKGB4135U'],
    ['HSBC UK BANK PLC Porthcawl', '402633', 'HBUKGB4185B'],
    ['HSBC UK BANK PLC Nottingham West Bridgford', '402634', 'HBUKGB4175N'],
    ['HSBC UK BANK PLC Market Harborough', '402635', 'HBUKGB4175O'],
    ['HSBC UK BANK PLC Stratford upon Avon', '402638', 'HBUKGB4175P'],
    ['HSBC UK BANK PLC Kings Lynn', '402639', 'HBUKGB4175Q'],
    ['HSBC UK BANK PLC Hereford', '402640', 'HBUKGB4175R'],
    ['HSBC UK BANK PLC Rhyl', '402641', 'HBUKGB4185C'],
    ['HSBC UK BANK PLC Huddersfield Cloth,', '402642', 'HBUKGB4178R'],
    ['HSBC UK BANK PLC Kendal', '402643', 'HBUKGB4186X'],
    ['HSBC UK BANK PLC Woking', '402644', 'HBUKGB4182V'],
    ['HSBC UK BANK PLC Scunthorpe High Street', '402645', 'HBUKGB4173M'],
    ['HSBC UK BANK PLC Tees Valley Comm Centre', '402657', ''],
    ['HSBC UK BANK PLC Wakefield & Densbury Comm', '402658', ''],
    ['HSBC UK BANK PLC Walsall Commercial Centre', '402659', ''],
    ['HSBC UK BANK PLC Walthamstow & Romford Comm', '402660', ''],
    ['HSBC UK BANK PLC West & Central Lincolnshire', '402661', ''],
    ['HSBC UK BANK PLC West Hertfordshire Comm', '402662', ''],
    ['HSBC UK BANK PLC West Kent Commercial Centre', '402663', ''],
    ['HSBC UK BANK PLC The Black Country & Shrops', '402664', 'HBUKGB4191V'],
    ['HSBC UK BANK PLC York Commercial Centre', '402665', ''],
    ['HSBC UK BANK PLC Aberystworth Comm Centre', '402667', ''],
    ['HSBC UK BANK PLC Ashford Commercial Centre', '402668', ''],
    ['HSBC UK BANK PLC Banbury&South Warwickshire', '402669', ''],
    ['HSBC UK BANK PLC Barnstaple Comm Centre', '402670', ''],
    ['HSBC UK BANK PLC Belfast&Colerane Comm Ctb', '402671', ''],
    ['HSBC UK BANK PLC Birmingham Comm Centre', '402672', ''],
    ['HSBC UK BANK PLC Bridgend Commercial Centre', '402673', ''],
    ['HSBC UK BANK PLC Canary Wharf Comm Centre', '402674', ''],
    ['HSBC UK BANK PLC Canterbury Comm Centre', '402675', ''],
    ['HSBC UK BANK PLC Chichester Comm Centre', '402676', ''],
    ['HSBC UK BANK PLC Lampeter', '402701', 'HBUKGB4136N'],
    ['HSBC UK BANK PLC Lancaster', '402702', 'HBUKGB4136R'],
    ['HSBC UK BANK PLC Worthing Warwick Street', '402703', 'HBUKGB4136T'],
    ['HSBC UK BANK PLC Launceston', '402704', 'HBUKGB4136U'],
    ['HSBC UK BANK PLC Leamington Spa', '402706', 'HBUKGB4105A'],
    ['HSBC UK BANK PLC Leatherhead', '402707', 'HBUKGB4136W'],
    ['HSBC UK BANK PLC Leeds City Office', '402710', 'HBUKGB4137B'],
    ['HSBC UK BANK PLC Leeds City Office', '402714', 'HBUKGB4137C'],
    ['HSBC UK BANK PLC Leeds City Branch', '402715', 'HBUKGB4105B'],
    ['HSBC UK BANK PLC Leeds City Office', '402716', 'HBUKGB4137F'],
    ['HSBC UK BANK PLC Leeds City Office', '402729', 'HBUKGB4137H'],
    ['HSBC UK BANK PLC Leeds Cross Gates', '402733', 'HBUKGB4137E'],
    ['HSBC UK BANK PLC Slough High Street', '402734', 'HBUKGB4155C'],
    ['HSBC UK BANK PLC Lakeside Thurrock', '402738', 'HBUKGB4168W'],
    ['HSBC UK BANK PLC Leamington Spa', '402739', 'HBUKGB4175S'],
    ['HSBC UK BANK PLC Malvern', '402740', 'HBUKGB4175T'],
    ['HSBC UK BANK PLC Morley', '402741', 'HBUKGB4178S'],
    ['HSBC UK BANK PLC Leeds City Office', '402742', 'HBUKGB4178T'],
    ['HSBC UK BANK PLC Leeds City Office', '402743', 'HBUKGB4178U'],
    ['HSBC UK BANK PLC Leeds City', '402744', 'HBUKGB4178V'],
    ['HSBC UK BANK PLC Gosport, High Street', '402745', 'HBUKGB4182W'],
    ['HSBC UK BANK PLC Chilterns Commercial Centre', '402752', ''],
    ['HSBC UK BANK PLC Derby & Staffordshire Comm', '402753', ''],
    ['HSBC UK BANK PLC Edgeware Commercial Centre', '402755', ''],
    ['HSBC UK BANK PLC Exeter Commercial Centre', '402756', 'HBUKGB4188Q'],
    ['HSBC UK BANK PLC Grantham Commercial Centre', '402757', ''],
    ['HSBC UK BANK PLC Harrogate Comm Centre', '402758', ''],
    ['HSBC UK BANK PLC Ipswich Commercial Centre', '402759', ''],
    ['HSBC UK BANK PLC Jersey Commercial Centre', '402760', ''],
    ['HSBC UK BANK PLC Lichfield Commercial Centre', '402761', ''],
    ['HSBC UK BANK PLC London Bridge Comm Centre', '402762', ''],
    ['HSBC UK BANK PLC Luton Commercial Centre', '402763', ''],
    ['HSBC UK BANK PLC M1 Nottingham Comm Centre', '402764', ''],
    ['HSBC UK BANK PLC Maidstone Commercial Centre', '402765', ''],
    ['HSBC UK BANK PLC Muswell Hill Commercial Ctr', '402766', ''],
    ['HSBC UK BANK PLC North Kent Comm Centre', '402767', ''],
    ['HSBC UK BANK PLC Nth Lincolnshire & Doncastr', '402768', 'HBUKGB4190O'],
    ['HSBC UK BANK PLC North Somerset Comm Centre', '402769', ''],
    ['HSBC UK BANK PLC Peterborough Comm Centre', '402770', ''],
    ['HSBC UK BANK PLC Portadown Commercial Centre', '402771', ''],
    ['HSBC UK BANK PLC Powys Commercial Centre', '402772', ''],
    ['HSBC UK BANK PLC Midlands Dummy A', '402787', 'HBUKGB4187N'],
    ['HSBC UK BANK PLC Macclesfield', '402801', 'HBUKGB4137L'],
    ['HSBC UK BANK PLC Leicester Belgrave Road', '402803', 'HBUKGB4137P'],
    ['HSBC UK BANK PLC Leicester Clock Tower', '402804', 'HBUKGB4113P'],
    ['HSBC UK BANK PLC Leicester Clock Tower', '402806', 'HBUKGB4105C'],
    ['HSBC UK BANK PLC Leicester Clock Tower', '402808', 'HBUKGB4137R'],
    ['HSBC UK BANK PLC Wigan Grand Arcade', '402810', 'HBUKGB4137V'],
    ['HSBC UK BANK PLC Southend-on-Sea', '402811', 'HBUKGB4137W'],
    ['HSBC UK BANK PLC Leighton Buzzard', '402812', 'HBUKGB4138A'],
    ['HSBC UK BANK PLC Leominster', '402813', 'HBUKGB4138B'],
    ['HSBC UK BANK PLC Hitchin', '402814', 'HBUKGB4138C'],
    ['HSBC UK BANK PLC Lewes', '402815', 'HBUKGB4138D'],
    ['HSBC UK BANK PLC Thirsk', '402816', 'HBUKGB4138E'],
    ['HSBC UK BANK PLC Leyland   Hough Lane', '402817', 'HBUKGB4150G'],
    ['HSBC UK BANK PLC Lichfield', '402818', 'HBUKGB4138F'],
    ['HSBC UK BANK PLC Lincoln Stonebow', '402820', 'HBUKGB4111A'],
    ['HSBC UK BANK PLC Liskeard', '402822', 'HBUKGB4138H'],
    ['HSBC UK BANK PLC Worthing Goring Road', '402823', 'HBUKGB4138J'],
    ['HSBC UK BANK PLC Leicester 11 Hinckley Road', '402824', 'HBUKGB4137N'],
    ['HSBC UK BANK PLC Leicester, Clock Tower', '402826', 'HBUKGB4137T'],
    ['HSBC UK BANK PLC Guildford', '402829', 'HBUKGB4138G'],
    ['HSBC UK BANK PLC Leicester Clock Tower', '402831', 'HBUKGB4137U'],
    ['HSBC UK BANK PLC Saxmundham', '402833', 'HBUKGB4171G'],
    ['HSBC UK BANK PLC Card Processing Birmingham', '402836', 'HBUKGB4167D'],
    ['HSBC UK BANK PLC Leicester Clocktower', '402838', 'HBUKGB4175U'],
    ['HSBC UK BANK PLC Leicester Clocktower', '402839', 'HBUKGB4175V'],
    ['HSBC UK BANK PLC Leicester Clock Tower', '402840', 'HBUKGB4188A'],
    ['HSBC UK BANK PLC Lincoln, Stonebow', '402843', 'HBUKGB4175W'],
    ['HSBC UK BANK PLC Lincoln Stonebow', '402844', 'HBUKGB4175X'],
    ['HSBC UK BANK PLC Lisburn', '402845', ''],
    ['HSBC UK BANK PLC Scarborough Comm Centre', '402852', ''],
    ['HSBC UK BANK PLC South & East Lincs Comm Cen', '402853', ''],
    ['HSBC UK BANK PLC South Cambs Comm Centre', '402854', ''],
    ['HSBC UK BANK PLC Stanstead Commercial Centre', '402855', ''],
    ['HSBC UK BANK PLC Hanley', '402856', 'HBUKGB4188R'],
    ['HSBC UK BANK PLC Sutton Commercial Centre', '402857', ''],
    ['HSBC UK BANK PLC Taunton Commercial Centre', '402858', ''],
    ['HSBC UK BANK PLC Torquay Commercial Centre', '402859', ''],
    ['HSBC UK BANK PLC Trowbridge Comm Centre', '402860', ''],
    ['HSBC UK BANK PLC Tunbridge S Comm Centre', '402861', ''],
    ['HSBC UK BANK PLC Valleys Commercial Centre', '402862', ''],
    ['HSBC UK BANK PLC Weybridge & Walton Comm', '402863', ''],
    ['HSBC UK BANK PLC Worthing Commercial Centre', '402864', ''],
    ['HSBC UK BANK PLC Wrexham Commercial Centre', '402865', ''],
    ['HSBC UK BANK PLC Liverpool Lord Street', '402901', 'HBUKGB4138M'],
    ['HSBC UK BANK PLC Bootle', '402902', 'HBUKGB4138N'],
    ['HSBC UK BANK PLC Liverpool Allerton Road', '402903', 'HBUKGB4138P'],
    ['HSBC UK BANK PLC Liverpool City', '402905', 'HBUKGB4138R'],
    ['HSBC UK BANK PLC Liverpool Lord Street', '402908', 'HBUKGB4105D'],
    ['HSBC UK BANK PLC Liverpool London Road', '402912', 'HBUKGB4138V'],
    ['HSBC UK BANK PLC Liverpool Lord Street', '402917', 'HBUKGB4138T'],
    ['HSBC UK BANK PLC Liverpool Old Swan', '402926', 'HBUKGB4138W'],
    ['HSBC UK BANK PLC Liverpool, Lord Street', '402928', 'HBUKGB4139A'],
    ['HSBC UK BANK PLC Liverpool Lord Street', '402939', 'HBUKGB4139C'],
    ['HSBC UK BANK PLC Liverpool Lord Street', '402940', 'HBUKGB4139B'],
    ['HSBC UK BANK PLC Liverpool, Lord Street', '402942', 'HBUKGB4178X'],
    ['HSBC UK BANK PLC Liverpool , Old Swan', '402943', 'HBUKGB4178Y'],
    ['HSBC UK BANK PLC Liverpool Lord Street', '402944', 'HBUKGB4178Z'],
    ['HSBC UK BANK PLC Liverpool, City', '402945', 'HBUKGB4179A'],
    ['HSBC UK BANK PLC Liverpool Lord Street', '402946', 'HBUKGB4179B'],
    ['HSBC UK BANK PLC Liverpool Lord Street', '402948', 'HBUKGB4179C'],
    ['HSBC UK BANK PLC Liverpool, City', '402949', 'HBUKGB4179D'],
    ['HSBC UK BANK PLC Liverpool Paradise Street', '402950', ''],
    ['HSBC BANK PLC Liverpool Money Shop', '402951', ''],
    ['HSBC UK BANK PLC Carmarthen', '403003', 'HBUKGB4139D'],
    ['HSBC UK BANK PLC Llanelli', '403004', 'HBUKGB4139E'],
    ['HSBC UK BANK PLC Llandrindod Wells', '403005', 'HBUKGB4139F'],
    ['HSBC UK BANK PLC Llandudno Mostyn Street', '403007', 'HBUKGB4139G'],
    ['HSBC UK BANK PLC Carmarthen', '403009', 'HBUKGB4139H'],
    ['HSBC UK BANK PLC Llanelli', '403010', 'HBUKGB4139J'],
    ['HSBC UK BANK PLC Newtown', '403011', 'HBUKGB4162H'],
    ['HSBC UK BANK PLC Newtown', '403013', 'HBUKGB4139L'],
    ['HSBC UK BANK PLC Llangefni', '403014', 'HBUKGB4139M'],
    ['HSBC UK BANK PLC Wrexham', '403015', 'HBUKGB4139N'],
    ['HSBC UK BANK PLC Newtown', '403016', 'HBUKGB4139P'],
    ['HSBC UK BANK PLC Llandudno Mostyn Street', '403018', 'HBUKGB4139R'],
    ['HSBC UK BANK PLC Long Eaton', '403020', 'HBUKGB4143N'],
    ['HSBC UK BANK PLC Newcastle under Lyme', '403021', 'HBUKGB4157F'],
    ['HSBC UK BANK PLC Calisle, City Office', '403022', 'HBUKGB4124T'],
    ['HSBC UK BANK PLC Liskeard', '403023', 'HBUKGB4143P'],
    ['HSBC UK BANK PLC Loughborough Leics', '403024', 'HBUKGB4128C'],
    ['HSBC UK BANK PLC Walthamstow', '403025', 'HBUKGB4128D'],
    ['HSBC UK BANK PLC Louth', '403026', 'HBUKGB4143U'],
    ['HSBC UK BANK PLC Lowestoft', '403028', 'HBUKGB4143V'],
    ['HSBC UK BANK PLC Leominster', '403030', 'HBUKGB4143W'],
    ['HSBC UK BANK PLC Luton George Street', '403032', 'HBUKGB4144A'],
    ['HSBC UK BANK PLC Gloucester The Cross', '403033', 'HBUKGB4144D'],
    ['HSBC UK BANK PLC Lymington', '403036', 'HBUKGB4144E'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '403037', 'HBUKGB4144F'],
    ['HSBC UK BANK PLC Luton George Street', '403039', 'HBUKGB4144C'],
    ['HSBC UK BANK PLC Luton George Street', '403040', 'HBUKGB4144B'],
    ['HSBC UK BANK PLC Caernarfon', '403047', 'HBUKGB4185E'],
    ['HSBC UK BANK PLC Cardiff, Canton', '403048', 'HBUKGB4185F'],
    ['HSBC UK BANK PLC Bangor', '403049', 'HBUKGB4185G'],
    ['HSBC UK BANK PLC Cowbridge', '403052', 'HBUKGB4185J'],
    ['HSBC UK BANK PLC Lampeter', '403053', 'HBUKGB4185K'],
    ['HSBC UK BANK PLC Preston Fishergate', '403054', 'HBUKGB4179E'],
    ['HSBC UK BANK PLC Loughborough', '403055', 'HBUKGB4175Y'],
    ['HSBC UK BANK PLC Preston Fishergate', '403056', 'HBUKGB4179F'],
    ['HSBC UK BANK PLC Londonderry', '403057', 'HBUKGB4192D'],
    ['HSBC UK BANK PLC Bridport', '403058', 'HBUKGB4185L'],
    ['HSBC UK BANK PLC Macclesfield', '403102', 'HBUKGB4144G'],
    ['HSBC UK BANK PLC Aberystwyth', '403103', 'HBUKGB4144H'],
    ['HSBC UK BANK PLC Port Talbot', '403104', 'HBUKGB4144J'],
    ['HSBC UK BANK PLC Maidenhead', '403105', 'HBUKGB4110K'],
    ['HSBC UK BANK PLC Maidstone', '403106', 'HBUKGB4108E'],
    ['HSBC UK BANK PLC Cirencester', '403107', 'HBUKGB4144L'],
    ['HSBC UK BANK PLC Malton', '403108', 'HBUKGB4144M'],
    ['HSBC UK BANK PLC Malvern', '403109', 'HBUKGB4144N'],
    ["HSBC UK BANK PLC Manchester St Ann's (401156", '403114', 'HBUKGB4144R'],
    ['HSBC UK BANK PLC Manchester Didsbury', '403117', 'HBUKGB4144P'],
    ['HSBC UK BANK PLC Manchester Didsbury', '403120', 'HBUKGB4144T'],
    ["HSBC UK BANK PLC Manchester St Ann's", '403123', 'HBUKGB4144U'],
    ["HSBC BANK PLC Manchester St Ann's (403124", '403124', 'MIDLGB2102M'],
    ["HSBC UK BANK PLC Manchester St Ann's", '403128', 'HBUKGB4144V'],
    ["HSBC UK BANK PLC Manchester St Ann's", '403130', 'HBUKGB4144W'],
    ['HSBC UK BANK PLC Bootle Stanley Road', '403133', 'HBUKGB4138U'],
    ['HSBC UK BANK PLC Chelmsford City', '403135', 'HBUKGB4144K'],
    ['HSBC UK BANK PLC Manchester - Trafford Park', '403141', 'HBUKGB4173C'],
    ["HSBC UK BANK PLC Manchester St Ann's (401156", '403142', 'HBUKGB4174F'],
    ['HSBC UK BANK PLC Louth', '403143', 'HBUKGB4175Z'],
    ['HSBC UK BANK PLC Rotherham', '403144', 'HBUKGB4179G'],
    ['HSBC UK BANK PLC Malvern', '403145', 'HBUKGB4176A'],
    ["HSBC UK BANK PLC Manchester St Ann's (401156", '403146', 'HBUKGB4179H'],
    ['HSBC UK BANK PLC Stretford', '403147', 'HBUKGB4179I'],
    ["HSBC UK BANK PLC Manchester St Ann's (401156", '403148', 'HBUKGB4179J'],
    ['HSBC UK BANK PLC Manchester, University', '403149', 'HBUKGB4179K'],
    ['HSBC UK BANK PLC Harpenden', '403150', 'HBUKGB4187B'],
    ['HSBC UK BANK PLC Huddersfield Cloth Hall Str', '403151', 'HBUKGB4179L'],
    ['HSBC UK BANK PLC Middlesbrough Albert Road', '403153', 'HBUKGB4179M'],
    ['HSBC UK BANK PLC M & S Money', '403160', 'HBUKGB4187X'],
    ['HSBC UK BANK PLC Mansfield', '403201', 'HBUKGB4145B'],
    ['HSBC UK BANK PLC Westwood Cross', '403202', 'HBUKGB4145E'],
    ['HSBC UK BANK PLC Market Bosworth', '403203', 'HBUKGB4145F'],
    ['HSBC UK BANK PLC Market Harborough', '403204', 'HBUKGB4111U'],
    ['HSBC UK BANK PLC Lincoln Stonebow', '403205', 'HBUKGB4145J'],
    ['HSBC UK BANK PLC Beverley', '403206', 'HBUKGB4165N'],
    ['HSBC UK BANK PLC Swindon Canal Walk', '403207', 'HBUKGB4145K'],
    ['HSBC UK BANK PLC Workington', '403208', 'HBUKGB4145M'],
    ['HSBC UK BANK PLC Ripon', '403209', 'HBUKGB4151P'],
    ['HSBC UK BANK PLC Chesterfield', '403210', 'HBUKGB4145N'],
    ['HSBC UK BANK PLC Derby St Peters Street', '403212', 'HBUKGB4145P'],
    ['HSBC UK BANK PLC Trowbridge', '403213', 'HBUKGB4145R'],
    ['HSBC UK BANK PLC Melton Mowbray', '403214', 'HBUKGB4145T'],
    ['HSBC UK BANK PLC Bangor', '403215', 'HBUKGB4145U'],
    ['HSBC UK BANK PLC Merthyr Tydfil Glebeland St', '403216', 'HBUKGB4145V'],
    ['HSBC UK BANK PLC Peterborough, Cathedral   S', '403218', 'HBUKGB4145C'],
    ['HSBC UK BANK PLC Marlow', '403219', 'HBUKGB4145L'],
    ['HSBC UK BANK PLC Stafford', '403221', 'HBUKGB4145H'],
    ['HSBC UK BANK PLC Peterborough Catherdral Squ', '403224', 'HBUKGB4145G'],
    ['HSBC UK BANK PLC Stockport, Great Underbank', '403226', 'HBUKGB4157A'],
    ['HSBC UK BANK PLC Huddersfield Cloth Hall St', '403227', 'HBUKGB4179N'],
    ['HSBC UK BANK PLC Merry Hill', '403229', 'HBUKGB4176B'],
    ['HSBC BANK PLC Pcm Receivables Sc4', '403232', 'MIDLGB2196K'],
    ['HSBC UK BANK PLC Middlesbrough Albert Road', '403301', 'HBUKGB4108G'],
    ['HSBC UK BANK PLC Rochdale', '403303', 'HBUKGB4146C'],
    ['HSBC UK BANK PLC Haverfordwest', '403304', 'HBUKGB4146D'],
    ['HSBC UK BANK PLC Barrow in Furness', '403305', 'HBUKGB4146E'],
    ['HSBC UK BANK PLC Minehead', '403307', 'HBUKGB4146H'],
    ['HSBC UK BANK PLC Tooting Broadway', '403309', 'HBUKGB4146J'],
    ['HSBC UK BANK PLC Mold', '403310', 'HBUKGB4146K'],
    ['HSBC UK BANK PLC Monmouth', '403311', 'HBUKGB4146L'],
    ['HSBC UK BANK PLC Wimbledon', '403314', 'HBUKGB4146M'],
    ['HSBC UK BANK PLC Lancaster', '403315', 'HBUKGB4146N'],
    ['HSBC UK BANK PLC Evesham', '403318', 'HBUKGB4146P'],
    ['HSBC UK BANK PLC Morley', '403319', 'HBUKGB4137J'],
    ['HSBC UK BANK PLC Morpeth', '403320', 'HBUKGB4146R'],
    ['HSBC UK BANK PLC Portland Street Swansea', '403321', 'HBUKGB4158J'],
    ['HSBC UK BANK PLC Portland St Swansea', '403324', 'HBUKGB4158K'],
    ['HSBC UK BANK PLC Kendal', '403328', 'HBUKGB4146F'],
    ['HSBC UK BANK PLC Frome', '403329', 'HBUKGB4118A'],
    ['HSBC UK BANK PLC Derby St Peters Street', '403330', 'HBUKGB4145W'],
    ['HSBC UK BANK PLC Milton Keynes, Midsummer Pl', '403333', 'HBUKGB4111L'],
    ['HSBC UK BANK PLC Middlesbrough, Albert Road', '403342', 'HBUKGB4179O'],
    ['HSBC UK BANK PLC Lymington', '403343', 'HBUKGB4182X'],
    ['HSBC UK BANK PLC Dewsbury', '403344', 'HBUKGB4179P'],
    ['HSBC UK BANK PLC Huddersfield Cloth Hall St', '403345', 'HBUKGB4179Q'],
    ['HSBC UK BANK PLC Birkenhead, Grange Road', '403347', 'HBUKGB4179R'],
    ['HSBC UK BANK PLC St Austell', '403348', 'HBUKGB4185N'],
    ['HSBC UK BANK PLC Merthyr Tydfil', '403349', 'HBUKGB4185O'],
    ['HSBC UK BANK PLC Bridgnorth', '403350', 'HBUKGB4176C'],
    ['HSBC UK BANK PLC Milton Keynes, Midsummer Pl', '403351', 'HBUKGB4176D'],
    ['HSBC UK BANK PLC Stroud', '403401', 'HBUKGB4157P'],
    ['HSBC UK BANK PLC Tenby', '403402', 'HBUKGB4146U'],
    ['HSBC UK BANK PLC Neath', '403403', 'HBUKGB4146V'],
    ['HSBC UK BANK PLC Chester Eastgate Street', '403406', 'HBUKGB4164F'],
    ['HSBC UK BANK PLC Arnold', '403407', 'HBUKGB4147N'],
    ['HSBC UK BANK PLC Newark', '403408', 'HBUKGB4166B'],
    ['HSBC UK BANK PLC North Finchley', '403409', 'HBUKGB4167A'],
    ['HSBC UK BANK PLC Blackwood', '403410', 'HBUKGB4166N'],
    ['HSBC UK BANK PLC Newbury', '403412', 'HBUKGB4108H'],
    ['HSBC UK BANK PLC Newcastle-under-Lyme', '403413', 'HBUKGB4166J'],
    ['HSBC UK BANK PLC Cardigan', '403414', 'HBUKGB4166D'],
    ['HSBC UK BANK PLC Newcastle/Tyne Gosforth', '403416', 'HBUKGB4166F'],
    ['HSBC UK BANK PLC Newcastle/Tyne City Branch', '403418', 'HBUKGB4108J'],
    ['HSBC UK BANK PLC Newcastle-upon-Tyne City', '403421', 'HBUKGB4166G'],
    ['HSBC UK BANK PLC New Malden', '403424', 'HBUKGB4146W'],
    ['HSBC UK BANK PLC New Milton', '403425', 'HBUKGB4166A'],
    ['HSBC UK BANK PLC Newport I of W', '403426', 'HBUKGB4166T'],
    ['HSBC UK BANK PLC Newport Mon Bridge Street', '403427', 'HBUKGB4108K'],
    ['HSBC UK BANK PLC Milton Keynes Midsummer Pla', '403429', 'HBUKGB4166R'],
    ['HSBC UK BANK PLC Truro', '403430', 'HBUKGB4166V'],
    ['HSBC UK BANK PLC Newton Abbot Area Branch', '403431', 'HBUKGB4166W'],
    ['HSBC UK BANK PLC Bishop Auckland', '403432', 'HBUKGB4147A'],
    ['HSBC UK BANK PLC Newtown', '403433', 'HBUKGB4113N'],
    ['HSBC UK BANK PLC Newport Shropshire', '403434', 'HBUKGB4166U'],
    ['HSBC UK BANK PLC Seaford', '403435', 'HBUKGB4166K'],
    ['HSBC UK BANK PLC Newmarket', '403438', 'HBUKGB4166L'],
    ['HSBC UK BANK PLC Portishead', '403439', 'HBUKGB4122R'],
    ['HSBC UK BANK PLC Crewe Market Street', '403444', 'HBUKGB4146T'],
    ['HSBC UK BANK PLC Newcastle-upon-Tyne City Br', '403445', 'HBUKGB4168K'],
    ['HSBC UK BANK PLC Pwllheli', '403446', 'HBUKGB4185P'],
    ['HSBC UK BANK PLC Burnley', '403447', 'HBUKGB4179S'],
    ['HSBC UK BANK PLC Bromborough', '403448', 'HBUKGB4179T'],
    ['HSBC UK BANK PLC Newcastle-upon-Tyne Gosfort', '403449', 'HBUKGB4179U'],
    ['HSBC UK BANK PLC Newcastle-upon-Tyne City', '403450', 'HBUKGB4179V'],
    ['HSBC UK BANK PLC Newport, Bridge Street', '403452', 'HBUKGB4185R'],
    ['HSBC UK BANK PLC Newport, Bridge Street', '403453', 'HBUKGB4185S'],
    ['HSBC UK BANK PLC Croydon, North End', '403501', 'HBUKGB4159N'],
    ['HSBC UK BANK PLC Thirsk', '403503', 'HBUKGB4147E'],
    ['HSBC UK BANK PLC Northampton ,Abington St', '403504', 'HBUKGB4108L'],
    ['HSBC UK BANK PLC Newcastle-upon-Tyne City Br', '403505', 'HBUKGB4147C'],
    ['HSBC UK BANK PLC Norwich London Street', '403506', 'HBUKGB4147D'],
    ['HSBC UK BANK PLC Northwich', '403507', 'HBUKGB4147G'],
    ['HSBC UK BANK PLC Watford High Street', '403508', 'HBUKGB4147H'],
    ['HSBC UK BANK PLC Norwich London Street', '403509', 'HBUKGB4108M'],
    ['HSBC UK BANK PLC Norwich Prince of Wales Rd', '403510', 'HBUKGB4147L'],
    ['HSBC UK BANK PLC Nottingham Clumber Street', '403512', 'HBUKGB4147P'],
    ['HSBC UK BANK PLC Nottingham  Clumber Street', '403518', 'HBUKGB4108N'],
    ['HSBC UK BANK PLC Nottingham West Bridgford', '403519', 'HBUKGB4147R'],
    ['HSBC UK BANK PLC Nuneaton', '403520', 'HBUKGB4147T'],
    ['HSBC UK BANK PLC Oakham', '403521', 'HBUKGB4147V'],
    ['HSBC UK BANK PLC Brentwood', '403522', 'HBUKGB4148C'],
    ['HSBC UK BANK PLC Launceston', '403523', 'HBUKGB4147W'],
    ['HSBC UK BANK PLC Oldham', '403526', 'HBUKGB4148A'],
    ['HSBC UK BANK PLC Ormskirk', '403529', 'HBUKGB4148D'],
    ['HSBC UK BANK PLC Orpington', '403530', 'HBUKGB4148E'],
    ['HSBC UK BANK PLC Oswestry', '403532', 'HBUKGB4148G'],
    ['HSBC UK BANK PLC Horsforth', '403533', 'HBUKGB4148H'],
    ['HSBC UK BANK PLC Oxford Cornmarket Street', '403534', 'HBUKGB4108P'],
    ['HSBC UK BANK PLC Oxford Cornmarket Street', '403535', 'HBUKGB4148J'],
    ['HSBC UK BANK PLC Leicester, Clock Tower', '403537', 'HBUKGB4147U'],
    ['HSBC UK BANK PLC Oxted', '403540', 'HBUKGB4148L'],
    ['HSBC UK BANK PLC Windsor High Street', '403543', 'HBUKGB4163V'],
    ['HSBC UK BANK PLC Farnborough', '403545', 'HBUKGB4147B'],
    ['HSBC UK BANK PLC Northampton Abington Street', '403546', 'HBUKGB4147F'],
    ['HSBC UK BANK PLC Norwich, London Street', '403549', 'HBUKGB4147J'],
    ['HSBC UK BANK PLC Norwich Mile Cross Lane', '403550', 'HBUKGB4147K'],
    ['HSBC UK BANK PLC Middlesbrough Albert Rd', '403552', 'HBUKGB4179W'],
    ['HSBC UK BANK PLC Wakefield', '403553', 'HBUKGB4179X'],
    ['HSBC UK BANK PLC Oxford Botley', '403554', 'HBUKGB4192N'],
    ['HSBC UK BANK PLC Omagh', '403556', 'HBUKGB4171Q'],
    ['HSBC UK BANK PLC Northampton, Abington St', '403559', 'HBUKGB4176F'],
    ['HSBC UK BANK PLC Norwich, London Street', '403561', 'HBUKGB4176G'],
    ['HSBC UK BANK PLC Nottingham  Clumber Street', '403562', 'HBUKGB4176H'],
    ['HSBC UK BANK PLC Nottingham Clumber Street', '403563', 'HBUKGB4176I'],
    ['HSBC UK BANK PLC Nottingham  Clumber Street', '403564', ''],
    ['HSBC UK BANK PLC Nottingham, Arnold', '403565', 'HBUKGB4176K'],
    ['HSBC UK BANK PLC Nottingham  Clumber Street', '403566', 'HBUKGB4176L'],
    ['HSBC UK BANK PLC Bridgend', '403567', 'HBUKGB4185T'],
    ['HSBC UK BANK PLC Colwyn Bay', '403568', 'HBUKGB4185U'],
    ['HSBC UK BANK PLC Wakefield', '403570', 'HBUKGB4173Q'],
    ['HSBC UK BANK PLC Basingstoke Town Centre', '403572', 'HBUKGB4173G'],
    ['HSBC UK BANK PLC Birkenhead, Grange Road', '403574', 'HBUKGB4179Z'],
    ['HSBC UK BANK PLC Lowestoft', '403575', 'HBUKGB4176M'],
    ['HSBC UK BANK PLC Nottingham Clumber Street', '403576', 'HBUKGB4176N'],
    ['HSBC UK BANK PLC Trent University Clifton', '403577', ''],
    ['HSBC UK BANK PLC Beeston', '403578', 'HBUKGB4176O'],
    ['HSBC UK BANK PLC Paignton Palace Avenue', '403602', 'HBUKGB4148M'],
    ['HSBC UK BANK PLC Reading Broad Street', '403603', 'HBUKGB4150V'],
    ['HSBC UK BANK PLC Harrogate', '403605', 'HBUKGB4133D'],
    ['HSBC UK BANK PLC Penarth', '403606', 'HBUKGB4148P'],
    ['HSBC UK BANK PLC Barnsley', '403608', 'HBUKGB4148R'],
    ['HSBC UK BANK PLC Penrith', '403610', 'HBUKGB4148T'],
    ['HSBC UK BANK PLC Penzance', '403613', 'HBUKGB4148U'],
    ['HSBC UK BANK PLC Peterborough', '403615', 'HBUKGB4108R'],
    ['HSBC UK BANK PLC Petersfield', '403616', 'HBUKGB4149A'],
    ['HSBC UK BANK PLC Orpington', '403617', 'HBUKGB4148F'],
    ['HSBC UK BANK PLC Chichester', '403618', 'HBUKGB4149B'],
    ['HSBC UK BANK PLC Malton', '403620', 'HBUKGB4149C'],
    ['HSBC UK BANK PLC Pinner', '403621', 'HBUKGB4149D'],
    ['HSBC UK BANK PLC Plymouth City Centre', '403622', 'HBUKGB4110B'],
    ['HSBC UK BANK PLC Plymouth, City Centre', '403623', 'HBUKGB4149E'],
    ['HSBC UK BANK PLC Haverfordwest', '403624', 'HBUKGB4148N'],
    ['HSBC UK BANK PLC Plymouth City Centre', '403625', 'HBUKGB4149F'],
    ['HSBC UK BANK PLC Hartlepool York Road', '403631', 'HBUKGB4148W'],
    ['HSBC UK BANK PLC Park Gate Hants', '403633', 'HBUKGB4155P'],
    ['HSBC UK BANK PLC Peterborough Cathedral Sq', '403634', 'HBUKGB4148V'],
    ['HSBC UK BANK PLC Tunbridge Wells Mount Pleas', '403635', 'HBUKGB4159U'],
    ['HSBC UK BANK PLC Warrington, Bridge Street', '403637', 'HBUKGB4180A'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '403638', 'HBUKGB4180B'],
    ['HSBC UK BANK PLC Burnley', '403639', 'HBUKGB4180C'],
    ['HSBC UK BANK PLC Bridgend', '403641', 'HBUKGB4185V'],
    ['HSBC UK BANK PLC Truro', '403642', 'HBUKGB4185W'],
    ['HSBC UK BANK PLC Wolverhampton', '403643', 'HBUKGB4174C'],
    ['HSBC UK BANK PLC Porthmadog', '403644', 'HBUKGB4185X'],
    ['HSBC UK BANK PLC Caernarfon', '403645', 'HBUKGB4185Y'],
    ['HSBC UK BANK PLC Worcester Broad Street', '403646', 'HBUKGB4174D'],
    ['HSBC UK BANK PLC Wadebridge', '403648', 'HBUKGB4185Z'],
    ['HSBC UK BANK PLC Plymouth', '403649', 'HBUKGB4186A'],
    ['HSBC UK BANK PLC Plymouth City Centre', '403650', 'HBUKGB4186B'],
    ['HSBC UK BANK PLC Plymouth City Centre', '403651', 'HBUKGB4186C'],
    ['HSBC UK BANK PLC Haverfordwest', '403652', 'HBUKGB4186D'],
    ['HSBC UK BANK PLC Pocklington', '403701', 'HBUKGB4165P'],
    ['HSBC UK BANK PLC Pontefract', '403704', 'HBUKGB4149H'],
    ['HSBC UK BANK PLC Pontypool', '403707', 'HBUKGB4149K'],
    ['HSBC UK BANK PLC Caerphilly', '403708', 'HBUKGB4149L'],
    ['HSBC UK BANK PLC Caerphilly', '403710', 'HBUKGB4151G'],
    ['HSBC UK BANK PLC Bridgend Dunraven Place', '403711', 'HBUKGB4149P'],
    ['HSBC UK BANK PLC Portmadoc', '403713', 'HBUKGB4149R'],
    ['HSBC UK BANK PLC Brighton North Street', '403714', 'HBUKGB4149T'],
    ['HSBC UK BANK PLC Portsmouth Commercial Road', '403715', 'HBUKGB4149V'],
    ['HSBC UK BANK PLC Portsmouth Commercial Road', '403717', 'HBUKGB4149W'],
    ['HSBC UK BANK PLC Port Talbot', '403718', 'HBUKGB4149N'],
    ['HSBC UK BANK PLC St Albans', '403719', 'HBUKGB4150A'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '403720', 'HBUKGB4150B'],
    ['HSBC UK BANK PLC St Helens', '403721', 'HBUKGB4112E'],
    ['HSBC UK BANK PLC Rhyl', '403722', 'HBUKGB4150C'],
    ['HSBC UK BANK PLC Preston Fishergate', '403725', 'HBUKGB4108S'],
    ['HSBC UK BANK PLC Manchester St Anns', '403727', 'HBUKGB4169H'],
    ['HSBC UK BANK PLC Leeds Horsforth', '403728', 'HBUKGB4150H'],
    ['HSBC UK BANK PLC Croydon, North End', '403729', 'HBUKGB4150J'],
    ['HSBC UK BANK PLC Pwllheli', '403730', 'HBUKGB4150K'],
    ['HSBC UK BANK PLC Portishead', '403733', 'HBUKGB4122T'],
    ['HSBC UK BANK PLC Eastbourne, Terminus Road', '403735', 'HBUKGB4149G'],
    ['HSBC UK BANK PLC Poole   Longfleet', '403736', 'HBUKGB4111P'],
    ['HSBC UK BANK PLC Newcastle upon Tyne City Br', '403737', 'HBUKGB4166H'],
    ['HSBC UK BANK PLC Shotton', '403740', 'HBUKGB4150L'],
    ['HSBC UK BANK PLC Portadown', '403741', 'HBUKGB4171U'],
    ['HSBC UK BANK PLC Bridgend, Dunraven Place', '403742', 'HBUKGB4186E'],
    ['HSBC UK BANK PLC Llanelli', '403743', 'HBUKGB4186F'],
    ['HSBC UK BANK PLC Bournemouth Old Christchurc', '403744', 'HBUKGB4174B'],
    ['HSBC UK BANK PLC Poole, Longfleet', '403745', 'HBUKGB4182Z'],
    ['HSBC UK BANK PLC Poole, Longfleet', '403746', 'HBUKGB4173X'],
    ['HSBC UK BANK PLC Windsor High Street', '403748', 'HBUKGB4183B'],
    ['HSBC UK BANK PLC Newtown', '403750', 'HBUKGB4186H'],
    ['HSBC UK BANK PLC Preston Fishergate', '403751', 'HBUKGB4180D'],
    ['HSBC UK BANK PLC Portsmouth, Commercial Road', '403752', 'HBUKGB4183C'],
    ['HSBC UK BANK PLC Preston Fishergate', '403753', 'HBUKGB4180E'],
    ['HSBC UK BANK PLC Gloucester, The Cross', '403760', 'HBUKGB4187T'],
    ['HSBC UK BANK PLC Chatham 231 High Street', '403801', 'HBUKGB4112C'],
    ['HSBC UK BANK PLC Westwood Cross', '403802', 'HBUKGB4110L'],
    ['HSBC UK BANK PLC Burnley', '403803', 'HBUKGB4152B'],
    ['HSBC UK BANK PLC Reading Broad Street', '403804', 'HBUKGB4108T'],
    ['HSBC UK BANK PLC Middlesbrough Albert Road', '403806', 'HBUKGB4151B'],
    ['HSBC UK BANK PLC Redditch', '403807', 'HBUKGB4108U'],
    ['HSBC UK BANK PLC Reigate', '403808', 'HBUKGB4151C'],
    ['HSBC UK BANK PLC Truro', '403809', 'HBUKGB4151D'],
    ['HSBC UK BANK PLC Reigate', '403810', 'HBUKGB4108V'],
    ['HSBC UK BANK PLC Retford', '403811', 'HBUKGB4151E'],
    ['HSBC UK BANK PLC Llandrindod Wells', '403812', 'HBUKGB4151F'],
    ['HSBC UK BANK PLC Cardiff Rhyd & Penau', '403813', 'HBUKGB4124J'],
    ['HSBC UK BANK PLC Wrexham', '403814', 'HBUKGB4165C'],
    ['HSBC UK BANK PLC Llandudno, Mostyn Street', '403816', 'HBUKGB4126V'],
    ['HSBC UK BANK PLC Rhyl', '403817', 'HBUKGB4151J'],
    ['HSBC UK BANK PLC Richmond Surrey', '403818', 'HBUKGB4112V'],
    ['HSBC UK BANK PLC Darlington', '403819', 'HBUKGB4151K'],
    ['HSBC UK BANK PLC Watford High Street', '403820', 'HBUKGB4151L'],
    ['HSBC UK BANK PLC Ringwood', '403821', 'HBUKGB4151M'],
    ['HSBC UK BANK PLC Ripley Derbys', '403822', 'HBUKGB4151N'],
    ['HSBC UK BANK PLC Ripon', '403823', 'HBUKGB4151R'],
    ['HSBC UK BANK PLC Rayleigh', '403824', 'HBUKGB4150T'],
    ['HSBC UK BANK PLC Nottingham, West Bridgford', '403828', 'HBUKGB4150M'],
    ['HSBC UK BANK PLC St Helens', '403829', 'HBUKGB4150P'],
    ['HSBC BANK PLC Ramsey I.O.M.', '403832', 'MIDLGB2150R'],
    ['HSBC UK BANK PLC Norwich London Street', '403834', 'HBUKGB4176P'],
    ['HSBC UK BANK PLC Blackburn', '403835', 'HBUKGB4180F'],
    ['HSBC UK BANK PLC Reading Broad Street', '403836', 'HBUKGB4187K'],
    ['HSBC UK BANK PLC Redditch', '403837', ''],
    ['HSBC UK BANK PLC Newport Gwent, Bridge Stree', '403849', 'HBUKGB4166P'],
    ['HSBC UK BANK PLC Rochdale', '403901', 'HBUKGB4151T'],
    ['HSBC UK BANK PLC Strood', '403902', 'HBUKGB4151U'],
    ['HSBC UK BANK PLC Romford', '403904', 'HBUKGB4151V'],
    ['HSBC UK BANK PLC Southampton City', '403905', 'HBUKGB4151W'],
    ['HSBC UK BANK PLC Ross-on-Wye', '403906', 'HBUKGB4152A'],
    ['HSBC UK BANK PLC Rotherham College Street', '403907', 'HBUKGB4113B'],
    ['HSBC UK BANK PLC Oldham', '403909', 'HBUKGB4148B'],
    ['HSBC UK BANK PLC Wrexham', '403910', 'HBUKGB4165D'],
    ['HSBC UK BANK PLC Rugby', '403911', 'HBUKGB4108W'],
    ['HSBC UK BANK PLC Lichfield', '403912', 'HBUKGB4152G'],
    ['HSBC UK BANK PLC Ruislip', '403913', 'HBUKGB4152H'],
    ['HSBC UK BANK PLC Runcorn', '403914', 'HBUKGB4152J'],
    ['HSBC UK BANK PLC Rushden', '403915', 'HBUKGB4152K'],
    ['HSBC UK BANK PLC Ruthin', '403916', 'HBUKGB4152M'],
    ['HSBC UK BANK PLC Ryde', '403917', 'HBUKGB4152N'],
    ['HSBC UK BANK PLC Hastings', '403918', 'HBUKGB4152P'],
    ['HSBC UK BANK PLC Worthing Goring Road', '403920', 'HBUKGB4152L'],
    ['HSBC UK BANK PLC Leeds City Office', '403921', 'HBUKGB4152E'],
    ['HSBC UK BANK PLC Hitchin', '403922', 'HBUKGB4152F'],
    ['HSBC UK BANK PLC Nottingham West', '403925', 'HBUKGB4176Q'],
    ['HSBC UK BANK PLC Sleaford', '403926', 'HBUKGB4187A'],
    ['HSBC UK BANK PLC Interest Dept,Central Charg', '403939', ''],
    ['HSBC UK BANK PLC Centralised Rets In9db2222', '403940', ''],
    ['HSBC UK BANK PLC Research & Adjustment', '403972', 'HBUKGB4172N'],
    ['HSBC UK BANK PLC St Albans', '404001', 'HBUKGB4110W'],
    ['HSBC UK BANK PLC St Annes-on-Sea', '404002', 'HBUKGB4156H'],
    ['HSBC UK BANK PLC St Austell', '404004', 'HBUKGB4156J'],
    ['HSBC UK BANK PLC Haverfordwest', '404006', 'HBUKGB4133K'],
    ['HSBC UK BANK PLC St Helens Lancs', '404007', 'HBUKGB4156K'],
    ['HSBC UK BANK PLC Penzance', '404008', 'HBUKGB4156L'],
    ['HSBC UK BANK PLC Hastings', '404009', 'HBUKGB4156M'],
    ['HSBC UK BANK PLC St Neots', '404010', 'HBUKGB4114M'],
    ['HSBC UK BANK PLC Altrincham', '404012', 'HBUKGB4152T'],
    ['HSBC UK BANK PLC Salisbury', '404014', 'HBUKGB4152V'],
    ['HSBC UK BANK PLC Plymouth City Centre', '404015', 'HBUKGB4152W'],
    ['HSBC UK BANK PLC Crewe, Market Street', '404017', 'HBUKGB4153A'],
    ['HSBC UK BANK PLC Newport, Isle of Wight', '404019', 'HBUKGB4153B'],
    ['HSBC UK BANK PLC Saxmundham', '404020', 'HBUKGB4153D'],
    ['HSBC UK BANK PLC Scarborough St Nicholas St', '404022', 'HBUKGB4153E'],
    ['HSBC UK BANK PLC Scunthorpe High Street', '404024', 'HBUKGB4108Y'],
    ['HSBC UK BANK PLC Seaford', '404025', 'HBUKGB4153F'],
    ['HSBC UK BANK PLC Sidmouth', '404027', 'HBUKGB4153H'],
    ['HSBC UK BANK PLC Selby', '404029', 'HBUKGB4153J'],
    ['HSBC UK BANK PLC Croydon, North End', '404030', 'HBUKGB4153K'],
    ['HSBC UK BANK PLC Settle', '404031', 'HBUKGB4153L'],
    ['HSBC UK BANK PLC Sevenoaks', '404032', 'HBUKGB4109A'],
    ['HSBC UK BANK PLC Canterbury', '404034', 'HBUKGB4153C'],
    ['HSBC UK BANK PLC St Ives Hunts', '404035', 'HBUKGB4135F'],
    ['HSBC UK BANK PLC Cramlington', '404037', 'HBUKGB4153G'],
    ["HSBC UK BANK PLC Manchester St Ann's", '404039', 'HBUKGB4115K'],
    ['HSBC UK BANK PLC Bishops Stortford', '404045', 'HBUKGB4152R'],
    ['HSBC UK BANK PLC Kingsbridge', '404048', 'HBUKGB4186I'],
    ['HSBC UK BANK PLC Middlesbrough Albert Road', '404050', 'HBUKGB4180G'],
    ['HSBC UK BANK PLC Tenby', '404051', 'HBUKGB4186J'],
    ['HSBC UK BANK PLC Kendal', '404052', 'HBUKGB4180H'],
    ['HSBC UK BANK PLC Rhyl', '404053', 'HBUKGB4186K'],
    ['HSBC UK BANK PLC Carmarthen', '404054', 'HBUKGB4186L'],
    ['HSBC BANK PLC Sark', '404055', ''],
    ['HSBC UK BANK PLC Shaftesbury', '404101', 'HBUKGB4153M'],
    ['HSBC UK BANK PLC Newport Iow', '404102', 'HBUKGB4153N'],
    ['HSBC UK BANK PLC Sheffield City Branch', '404107', 'HBUKGB4109B'],
    ['HSBC UK BANK PLC Sheffield Meadowhall', '404108', 'HBUKGB4114N'],
    ['HSBC UK BANK PLC Sheffield City', '404111', 'HBUKGB4154C'],
    ['HSBC UK BANK PLC Sheffield City', '404113', 'HBUKGB4113C'],
    ['HSBC UK BANK PLC Sheffield City', '404114', 'HBUKGB4171H'],
    ['HSBC UK BANK PLC Sheffield City', '404118', 'HBUKGB4153V'],
    ['HSBC UK BANK PLC Sheffield City', '404120', 'HBUKGB4154E'],
    ['HSBC UK BANK PLC Brentwood', '404121', 'HBUKGB4154G'],
    ['HSBC UK BANK PLC Wells', '404122', 'HBUKGB4154H'],
    ['HSBC UK BANK PLC Yeovil', '404123', 'HBUKGB4154J'],
    ['HSBC UK BANK PLC Bishop Auckland', '404124', 'HBUKGB4154L'],
    ['HSBC UK BANK PLC Stratford upon Avon', '404126', 'HBUKGB4154N'],
    ['HSBC UK BANK PLC Shirley Warwicks', '404127', 'HBUKGB4155F'],
    ['HSBC UK BANK PLC Worthing Warwick Street', '404128', 'HBUKGB4154P'],
    ['HSBC UK BANK PLC Shotton', '404129', 'HBUKGB4154R'],
    ['HSBC UK BANK PLC Shrewsbury High Street', '404130', 'HBUKGB4154T'],
    ['HSBC UK BANK PLC Sheffield City', '404131', 'HBUKGB4154D'],
    ['HSBC UK BANK PLC Sheffield City', '404137', 'HBUKGB4153U'],
    ['HSBC UK BANK PLC Cromer', '404138', 'HBUKGB4154K'],
    ['HSBC UK BANK PLC Sittingbourne', '404141', 'HBUKGB4153P'],
    ['HSBC UK BANK PLC Mortgage Services', '404142', 'HBUKGB4171K'],
    ['HSBC UK BANK PLC Sheffield Crystal Peaks', '404143', 'HBUKGB4153W'],
    ['HSBC UK BANK PLC Shipley Service Branch', '404144', 'HBUKGB4154M'],
    ['HSBC UK BANK PLC Selby', '404145', 'HBUKGB4137K'],
    ['HSBC UK BANK PLC Sheffield City', '404146', 'HBUKGB4173B'],
    ['HSBC UK BANK PLC Sheffield City', '404150', 'HBUKGB4180I'],
    ['HSBC UK BANK PLC Sheffield City', '404151', 'HBUKGB4180J'],
    ['HSBC UK BANK PLC Sheffield Meadowhall', '404152', 'HBUKGB4180K'],
    ['HSBC UK BANK PLC Sheffield City Branch', '404153', 'HBUKGB4180L'],
    ['HSBC UK BANK PLC Lichfield', '404154', 'HBUKGB4187G'],
    ['HSBC UK BANK PLC Loughborough', '404155', 'HBUKGB4176R'],
    ['HSBC UK BANK PLC Telford', '404156', 'HBUKGB4187H'],
    ['HSBC UK BANK PLC Sheffield City', '404157', 'HBUKGB4192P'],
    ['HSBC UK BANK PLC Sheffield Collections', '404158', 'HBUKGB4193X'],
    ['HSBC UK BANK PLC Dundee', '404159', 'HBUKGB4194G'],
    ['HSBC UK BANK PLC Stirling', '404160', 'HBUKGB4194B'],
    ['HSBC UK BANK PLC Hamilton', '404163', 'HBUKGB4194E'],
    ['HSBC UK BANK PLC Stirling', '404164', 'HBUKGB4194D'],
    ['HSBC UK BANK PLC Dumfries', '404168', 'HBUKGB4194F'],
    ['HSBC BANK PLC Regional Service Centre Eur', '404170', 'MIDLGB2195W'],
    ['HSBC UK BANK PLC Mortgage Service Centre', '404188', 'HBUKGB4B72M'],
    ['HSBC UK BANK PLC Sidcup', '404201', 'HBUKGB4154U'],
    ['HSBC UK BANK PLC Sidmouth', '404202', 'HBUKGB4154V'],
    ['HSBC UK BANK PLC Carlisle City Office', '404203', 'HBUKGB4124U'],
    ['HSBC UK BANK PLC Sittingbourne', '404204', 'HBUKGB4154W'],
    ['HSBC UK BANK PLC Skegness', '404205', 'HBUKGB4155A'],
    ['HSBC UK BANK PLC Skipton', '404206', 'HBUKGB4155B'],
    ['HSBC UK BANK PLC Sleaford Lincs', '404207', 'HBUKGB4109D'],
    ['HSBC UK BANK PLC Slough High Street', '404208', 'HBUKGB4109E'],
    ['HSBC UK BANK PLC Slough High Street', '404209', 'HBUKGB4109F'],
    ['HSBC UK BANK PLC Harborne', '404210', 'HBUKGB4161R'],
    ['HSBC UK BANK PLC West Bromwich', '404211', 'HBUKGB4161T'],
    ['HSBC UK BANK PLC Solihull', '404212', 'HBUKGB4155G'],
    ['HSBC UK BANK PLC Hayes Middlesex', '404213', 'HBUKGB4155M'],
    ['HSBC UK BANK PLC Southall The Broadway', '404214', 'HBUKGB4155N'],
    ['HSBC UK BANK PLC Leamington Spa', '404215', 'HBUKGB4136V'],
    ['HSBC UK BANK PLC Southampton City', '404216', 'HBUKGB4109G'],
    ['HSBC UK BANK PLC Southampton City', '404217', 'HBUKGB4155R'],
    ['HSBC UK BANK PLC Southampton City', '404218', 'HBUKGB4110G'],
    ['HSBC UK BANK PLC Southampton City', '404219', 'HBUKGB4155U'],
    ['HSBC UK BANK PLC Southampton City', '404221', 'HBUKGB4155V'],
    ['HSBC UK BANK PLC Southampton City', '404222', 'HBUKGB4155W'],
    ['HSBC UK BANK PLC Beverley', '404225', 'HBUKGB4155H'],
    ['HSBC UK BANK PLC Southend-on-Sea High Street', '404227', 'HBUKGB4114T'],
    ["HSBC UK BANK PLC Harrow St Ann's Road", '404228', 'HBUKGB4155J'],
    ['HSBC UK BANK PLC Barnstaple', '404229', 'HBUKGB4155K'],
    ['HSBC UK BANK PLC Southport Lord Street', '404230', 'HBUKGB4156B'],
    ['HSBC UK BANK PLC Portsmouth, Commercial Road', '404231', 'HBUKGB4156C'],
    ['HSBC UK BANK PLC South Shields', '404234', 'HBUKGB4155L'],
    ['HSBC UK BANK PLC Newark', '404242', 'HBUKGB4156D'],
    ['HSBC UK BANK PLC Card Services', '404246', 'HBUKGB4167C'],
    ['HSBC UK BANK PLC Sheffield Meadowhall-Nnndc', '404249', 'HBUKGB4171J'],
    ['HSBC UK BANK PLC Neath', '404250', 'HBUKGB4186M'],
    ['HSBC UK BANK PLC Pc Mainstream', '404251', ''],
    ['HSBC UK BANK PLC Southport Lord Street', '404252', 'HBUKGB4180M'],
    ['HSBC UK BANK PLC Selby', '404253', 'HBUKGB4180N'],
    ['HSBC UK BANK PLC Southampton City', '404254', 'HBUKGB4183E'],
    ['HSBC UK BANK PLC Southampton City', '404258', 'HBUKGB4155T'],
    ['HSBC UK BANK PLC Spalding', '404301', 'HBUKGB4156F'],
    ['HSBC UK BANK PLC Skegness', '404302', 'HBUKGB4156G'],
    ['HSBC UK BANK PLC Stafford', '404303', 'HBUKGB4115G'],
    ['HSBC UK BANK PLC Windsor High Street', '404304', 'HBUKGB4109J'],
    ['HSBC UK BANK PLC Stamford', '404305', 'HBUKGB4156N'],
    ['HSBC UK BANK PLC Bishop Auckland', '404306', 'HBUKGB4120C'],
    ['HSBC UK BANK PLC Beeston', '404308', 'HBUKGB4156R'],
    ['HSBC UK BANK PLC Stockport', '404310', 'HBUKGB4112L'],
    ['HSBC UK BANK PLC Stockton-on-Tees', '404311', 'HBUKGB4157C'],
    ['HSBC UK BANK PLC Middlesbrough Albert Road', '404313', 'HBUKGB4146A'],
    ['HSBC UK BANK PLC Stafford', '404314', 'HBUKGB4157H'],
    ['HSBC UK BANK PLC Stourbridge 114 High St', '404317', 'HBUKGB4111T'],
    ['HSBC UK BANK PLC Stourport-on-Severn', '404318', 'HBUKGB4157L'],
    ['HSBC UK BANK PLC Stratford-on-Avon', '404319', 'HBUKGB4113M'],
    ['HSBC UK BANK PLC Trafford Park', '404320', 'HBUKGB4145A'],
    ['HSBC UK BANK PLC Stroud Glos', '404321', 'HBUKGB4114V'],
    ['HSBC UK BANK PLC Sudbury Suffolk', '404323', 'HBUKGB4157T'],
    ['HSBC UK BANK PLC Sunderland The Bridges', '404324', 'HBUKGB4112P'],
    ['HSBC UK BANK PLC Surbiton', '404325', 'HBUKGB4157U'],
    ['HSBC UK BANK PLC Sutton Town Square', '404326', 'HBUKGB4157V'],
    ['HSBC UK BANK PLC Sutton Town Square', '404327', 'HBUKGB4157W'],
    ['HSBC UK BANK PLC Sutton Coldfield', '404328', 'HBUKGB4158C'],
    ['HSBC UK BANK PLC Ashby De La Zouch', '404330', 'HBUKGB4158E'],
    ['HSBC UK BANK PLC Swansea Portland Street', '404331', 'HBUKGB4114F'],
    ['HSBC UK BANK PLC Portland Street, Swansea', '404332', 'HBUKGB4158L'],
    ['HSBC UK BANK PLC Swindon Canal Walk', '404334', 'HBUKGB4158N'],
    ['HSBC UK BANK PLC Swindon Canal Walk', '404335', 'HBUKGB4114P'],
    ['HSBC UK BANK PLC Stevenage Town Centre', '404336', 'HBUKGB4156T'],
    ['HSBC UK BANK PLC Stowmarket', '404337', 'HBUKGB4157M'],
    ['HSBC UK BANK PLC Poole', '404338', 'HBUKGB4158G'],
    ['HSBC UK BANK PLC Leicester, Clock Tower', '404339', 'HBUKGB4158T'],
    ['HSBC UK BANK PLC Swindon Canal Walk', '404341', 'HBUKGB4110N'],
    ['HSBC UK BANK PLC Stroud', '404342', 'HBUKGB4157J'],
    ['HSBC UK BANK PLC Strood', '404344', 'HBUKGB4157N'],
    ['HSBC UK BANK PLC Edgware Station Road', '404346', 'HBUKGB4156P'],
    ['HSBC UK BANK PLC Storrington', '404348', 'HBUKGB4156U'],
    ['HSBC UK BANK PLC Bracknell', '404350', 'HBUKGB4116U'],
    ['HSBC UK BANK PLC Storrington', '404351', 'HBUKGB4157K'],
    ['HSBC UK BANK PLC Barnsley', '404352', 'HBUKGB4154F'],
    ['HSBC UK BANK PLC Redditch', '404354', 'HBUKGB4157R'],
    ['HSBC UK BANK PLC Dereham', '404355', 'HBUKGB4158F'],
    ['HSBC UK BANK PLC Bolton, Victoria Square', '404356', 'HBUKGB4158R'],
    ['HSBC UK BANK PLC York Parliament Street', '404357', 'HBUKGB4180O'],
    ['HSBC UK BANK PLC Doncaster High Street', '404359', 'HBUKGB4180P'],
    ['HSBC UK BANK PLC Mansfield', '404360', 'HBUKGB4176S'],
    ['HSBC UK BANK PLC Wigan Grand Arcade', '404361', 'HBUKGB4180Q'],
    ['HSBC UK BANK PLC Sunderland The Bridges', '404362', 'HBUKGB4192J'],
    ['HSBC UK BANK PLC Warrington Bridge Street', '404363', 'HBUKGB4180R'],
    ['HSBC UK BANK PLC Hanley', '404364', 'HBUKGB4176U'],
    ['HSBC UK BANK PLC Stratford City', '404365', 'HBUKGB4194C'],
    ['HSBC UK BANK PLC Swansea Portland Street', '404367', 'HBUKGB4186N'],
    ['HSBC UK BANK PLC Swindon Canal Walk', '404369', 'HBUKGB4183F'],
    ['HSBC UK BANK PLC Canal Walk,Swindon', '404370', 'HBUKGB4183G'],
    ['HSBC UK BANK PLC Portland St Swansea', '404371', 'HBUKGB4188J'],
    ['HSBC UK BANK PLC HSBC UK Va', '404375', 'HBUKGB41VA1'],
    ['HSBC UK BANK PLC HSBC UK Bank plc', '404383', 'HBUKGB41LP1'],
    ['HSBC UK BANK PLC HSBC UK Bank plc', '404384', 'HBUKGB41LP2'],
    ['HSBC UK BANK PLC Wetherby', '404401', 'HBUKGB4158U'],
    ['HSBC UK BANK PLC Tamworth', '404402', 'HBUKGB4158V'],
    ['HSBC UK BANK PLC Chester Eastgate Street', '404403', 'HBUKGB4158W'],
    ['HSBC UK BANK PLC Taunton Area Branch', '404404', 'HBUKGB4112B'],
    ['HSBC UK BANK PLC Launceston', '404405', 'HBUKGB4159A'],
    ['HSBC UK BANK PLC Newton Abbot', '404406', 'HBUKGB4159C'],
    ['HSBC UK BANK PLC Tenby', '404407', 'HBUKGB4159F'],
    ['HSBC UK BANK PLC Ashford Kent', '404408', 'HBUKGB4159G'],
    ['HSBC UK BANK PLC Cheltenham Promenade', '404410', 'HBUKGB4159H'],
    ['HSBC UK BANK PLC Kingston upon Thames', '404411', 'HBUKGB4159K'],
    ['HSBC UK BANK PLC Thirsk', '404412', 'HBUKGB4159M'],
    ['HSBC UK BANK PLC Doncaster High Street', '404414', 'HBUKGB4129E'],
    ['HSBC UK BANK PLC Croydon, North End', '404415', 'HBUKGB4159P'],
    ['HSBC UK BANK PLC Kettering', '404416', 'HBUKGB4136B'],
    ['HSBC UK BANK PLC Exeter High Street', '404418', 'HBUKGB4159R'],
    ['HSBC UK BANK PLC Kingston upon Thames - Eden', '404419', 'HBUKGB4159T'],
    ['HSBC UK BANK PLC Tonbridge', '404420', 'HBUKGB4159V'],
    ['HSBC UK BANK PLC Caerphilly', '404421', 'HBUKGB4151H'],
    ['HSBC UK BANK PLC Paignton Palace Avenue', '404422', 'HBUKGB4160A'],
    ['HSBC UK BANK PLC Bideford', '404423', 'HBUKGB4160B'],
    ['HSBC UK BANK PLC Paignton Palace Avenue', '404424', 'HBUKGB4160C'],
    ['HSBC UK BANK PLC Southamton City', '404425', 'HBUKGB4156A'],
    ['HSBC UK BANK PLC Northampton Abington Street', '404426', 'HBUKGB4160D'],
    ['HSBC UK BANK PLC Dolgellau', '404427', 'HBUKGB4160M'],
    ['HSBC UK BANK PLC Caerphilly', '404430', 'HBUKGB4160E'],
    ['HSBC UK BANK PLC Caerphilly', '404431', 'HBUKGB4160F'],
    ['HSBC UK BANK PLC Aylesbury Market Square', '404432', 'HBUKGB4160G'],
    ['HSBC UK BANK PLC Trowbridge', '404433', 'HBUKGB4109K'],
    ['HSBC UK BANK PLC Truro Area Branch', '404434', 'HBUKGB4160H'],
    ['HSBC UK BANK PLC Llanelli', '404435', 'HBUKGB4139K'],
    ['HSBC UK BANK PLC Tunbridge Wells High Street', '404436', 'HBUKGB41TNW'],
    ['HSBC UK BANK PLC Royal Tunbridge Wells', '404437', 'HBUKGB4160J'],
    ['HSBC UK BANK PLC Hanley', '404438', 'HBUKGB4157G'],
    ['HSBC UK BANK PLC Twickenham', '404439', 'HBUKGB4160K'],
    ['HSBC UK BANK PLC Thornbury', '404441', 'HBUKGB4122U'],
    ['HSBC UK BANK PLC Bury St Edmunds', '404442', 'HBUKGB4159L'],
    ['HSBC UK BANK PLC Paignton Palace Avenue', '404443', 'HBUKGB4159W'],
    ['HSBC UK BANK PLC Canterbury', '404445', 'HBUKGB4163G'],
    ['HSBC UK BANK PLC Kingston upon Thames - Eden', '404446', 'HBUKGB4159B'],
    ['HSBC UK BANK PLC Cowbridge', '404448', 'HBUKGB4149J'],
    ['HSBC UK BANK PLC Reading Broad Street', '404449', 'HBUKGB4150W'],
    ['HSBC UK BANK PLC Telford', '404450', 'HBUKGB4159D'],
    ['HSBC UK BANK PLC Thame', '404451', 'HBUKGB4159J'],
    ['HSBC UK BANK PLC Newbury', '404452', 'HBUKGB4166C'],
    ['HSBC UK BANK PLC Basingstoke Town Centre', '404456', 'HBUKGB4117W'],
    ['HSBC UK BANK PLC It Operations', '404460', ''],
    ['HSBC UK BANK PLC Cirencester', '404466', 'HBUKGB4186O'],
    ['HSBC UK BANK PLC Blackpool Oxford Square', '404467', 'HBUKGB4180S'],
    ['HSBC UK BANK PLC Southend-on-Sea', '404468', 'HBUKGB4176V'],
    ['HSBC UK BANK PLC Rotherham College Street', '404469', 'HBUKGB4180T'],
    ['HSBC UK BANK PLC Doncaster High Street', '404470', 'HBUKGB4180U'],
    ['HSBC UK BANK PLC Dudley', '404471', 'HBUKGB4176W'],
    ['HSBC UK BANK PLC Lancaster', '404472', 'HBUKGB4180V'],
    ['HSBC UK BANK PLC Merthyr Tydfil', '404475', 'HBUKGB4186Q'],
    ['HSBC UK BANK PLC Caerphilly', '404476', 'HBUKGB4186R'],
    ['HSBC UK BANK PLC Truro', '404479', 'HBUKGB4186S'],
    ['HSBC UK BANK PLC Barrow in Furness', '404501', 'HBUKGB4160P'],
    ['HSBC UK BANK PLC Poole', '404502', 'HBUKGB4149M'],
    ['HSBC UK BANK PLC Oakham', '404503', 'HBUKGB4160T'],
    ['HSBC UK BANK PLC Malvern', '404505', 'HBUKGB4160U'],
    ['HSBC UK BANK PLC Altrincham', '404506', 'HBUKGB4160V'],
    ['HSBC UK BANK PLC Cwmbran', '404507', 'HBUKGB4160W'],
    ['HSBC UK BANK PLC Uxbridge', '404508', 'HBUKGB4109L'],
    ['HSBC UK BANK PLC Newport Iow', '404509', 'HBUKGB4161B'],
    ['HSBC UK BANK PLC Wadebridge', '404510', 'HBUKGB4161C'],
    ['HSBC UK BANK PLC Wakefield Westgate', '404511', 'HBUKGB4161D'],
    ['HSBC UK BANK PLC Birkenhead, Grange Road', '404514', 'HBUKGB4161E'],
    ['HSBC UK BANK PLC Wallington Surrey', '404517', 'HBUKGB4161F'],
    ['HSBC UK BANK PLC Walsall', '404519', 'HBUKGB4109M'],
    ['HSBC UK BANK PLC Hoddesdon', '404520', 'HBUKGB4161L'],
    ['HSBC UK BANK PLC Waltham Cross', '404521', 'HBUKGB4161M'],
    ['HSBC UK BANK PLC Weybridge', '404522', 'HBUKGB4115E'],
    ['HSBC UK BANK PLC Trowbridge', '404523', 'HBUKGB4161U'],
    ['HSBC UK BANK PLC Warrington', '404524', 'HBUKGB4112F'],
    ['HSBC UK BANK PLC Leamington Spa', '404525', 'HBUKGB4161W'],
    ['HSBC UK BANK PLC Waterlooville', '404526', 'HBUKGB4162B'],
    ['HSBC UK BANK PLC Watford High Street', '404527', 'HBUKGB4109N'],
    ['HSBC UK BANK PLC Watford High Street', '404528', 'HBUKGB4162C'],
    ['HSBC UK BANK PLC Rotherham College Street', '404529', 'HBUKGB4152D'],
    ['HSBC UK BANK PLC Hornchurch', '404531', 'HBUKGB4160R'],
    ['HSBC UK BANK PLC Uckfield', '404532', 'HBUKGB4160N'],
    ['HSBC UK BANK PLC Witney', '404536', 'HBUKGB4161N'],
    ['HSBC UK BANK PLC Burton on Trent High Street', '404538', 'HBUKGB4161A'],
    ['HSBC UK BANK PLC Sunderland The Bridge', '404539', 'HBUKGB4162A'],
    ['HSBC UK BANK PLC Hertford', '404542', 'HBUKGB4161P'],
    ['HSBC UK BANK PLC Ringwood', '404544', 'HBUKGB4183H'],
    ['HSBC UK BANK PLC Maidstone', '404550', 'HBUKGB4125G'],
    ['HSBC UK BANK PLC Reading, Broad Street', '404551', 'HBUKGB41TWY'],
    ['HSBC UK BANK PLC Wolverhampton 39 Queen St', '404582', ''],
    ['HSBC UK BANK PLC Wirrel Commercial Centre', '404583', ''],
    ['HSBC BANK PLC First Direct 4590', '404590', ''],
    ['HSBC BANK PLC First Direct 4591', '404591', ''],
    ['HSBC BANK PLC First Direct 4592', '404592', ''],
    ['HSBC UK BANK PLC Wolverhampton', '404593', 'HBUKGB4176X'],
    ['HSBC UK BANK PLC Stourbridge', '404595', 'HBUKGB4174A'],
    ['HSBC UK BANK PLC Bromborough', '404596', 'HBUKGB4174E'],
    ['HSBC UK BANK PLC Weymouth', '404597', 'HBUKGB4183I'],
    ['HSBC UK BANK PLC Weymouth', '404598', 'HBUKGB4183J'],
    ['HSBC UK BANK PLC Wednesbury', '404600', 'HBUKGB4187I'],
    ['HSBC UK BANK PLC Wellingborough', '404603', 'HBUKGB4162E'],
    ['HSBC UK BANK PLC Telford', '404604', 'HBUKGB4159E'],
    ['HSBC UK BANK PLC Taunton', '404605', 'HBUKGB4162F'],
    ['HSBC UK BANK PLC Wells  Somerset', '404606', 'HBUKGB4162G'],
    ['HSBC UK BANK PLC Newtown', '404607', 'HBUKGB4162J'],
    ['HSBC UK BANK PLC Welwyn Gdn City Howardsgate', '404608', 'HBUKGB4111D'],
    ['HSBC UK BANK PLC Wembley High Road', '404609', 'HBUKGB4162K'],
    ['HSBC UK BANK PLC Wembley High Road', '404610', 'HBUKGB4162L'],
    ['HSBC UK BANK PLC Westbourne', '404611', 'HBUKGB4121E'],
    ['HSBC UK BANK PLC West Bromwich High Street', '404613', 'HBUKGB4109P'],
    ['HSBC UK BANK PLC Southend on Sea', '404614', 'HBUKGB4162R'],
    ['HSBC UK BANK PLC Croydon North End', '404615', 'HBUKGB4162N'],
    ['HSBC UK BANK PLC Weston-super-Mare High St', '404618', 'HBUKGB4162T'],
    ['HSBC UK BANK PLC Christchurch', '404619', 'HBUKGB4121D'],
    ['HSBC UK BANK PLC Beckenham', '404620', 'HBUKGB4162P'],
    ['HSBC UK BANK PLC Wetherby', '404621', 'HBUKGB4162V'],
    ['HSBC UK BANK PLC Weybridge', '404622', 'HBUKGB4162W'],
    ['HSBC UK BANK PLC Weymouth', '404623', 'HBUKGB4163A'],
    ['HSBC UK BANK PLC Whitby', '404624', 'HBUKGB4163B'],
    ['HSBC UK BANK PLC Oswestry', '404625', 'HBUKGB4163C'],
    ['HSBC UK BANK PLC Whitehaven', '404626', 'HBUKGB4163D'],
    ['HSBC UK BANK PLC Tenby', '404627', 'HBUKGB4163E'],
    ['HSBC UK BANK PLC Whitley Bay', '404628', 'HBUKGB4163F'],
    ['HSBC UK BANK PLC Canterbury', '404629', 'HBUKGB4163H'],
    ['HSBC UK BANK PLC Twickenham', '404630', 'HBUKGB4160L'],
    ['HSBC UK BANK PLC Runcorn', '404631', 'HBUKGB4163K'],
    ['HSBC UK BANK PLC Wigan Grand Arcade', '404632', 'HBUKGB4113E'],
    ['HSBC UK BANK PLC Leicester, Clock Tower', '404633', 'HBUKGB4163L'],
    ['HSBC UK BANK PLC Carlisle City Office', '404634', 'HBUKGB4163M'],
    ['HSBC UK BANK PLC Wolverhampton', '404635', 'HBUKGB4109R'],
    ['HSBC UK BANK PLC Wilmslow', '404636', 'HBUKGB4163N'],
    ['HSBC UK BANK PLC Poole', '404637', 'HBUKGB4163R'],
    ['HSBC UK BANK PLC Yeovil', '404638', 'HBUKGB4163T'],
    ['HSBC UK BANK PLC Winchester', '404639', 'HBUKGB4113K'],
    ['HSBC UK BANK PLC Dartford', '404642', 'HBUKGB4162D'],
    ['HSBC UK BANK PLC Cardiff Albany Road', '404643', 'HBUKGB4124M'],
    ['HSBC UK BANK PLC Billericay', '404646', 'HBUKGB4163J'],
    ['HSBC UK BANK PLC Woking', '404648', 'HBUKGB4162M'],
    ['HSBC UK BANK PLC Sunderland The Bridges', '404650', 'HBUKGB4180W'],
    ['HSBC UK BANK PLC Andover', '404651', 'HBUKGB4183K'],
    ['HSBC UK BANK PLC Rotherham College Street', '404652', 'HBUKGB4180X'],
    ['HSBC UK BANK PLC Wigan, Grand Arcade', '404653', 'HBUKGB4180Y'],
    ['HSBC UK BANK PLC Chester Eastgate Street', '404654', 'HBUKGB4180Z'],
    ['HSBC UK BANK PLC Salisbury', '404655', 'HBUKGB4183L'],
    ['HSBC UK BANK PLC Scunthorpe, High Street', '404656', 'HBUKGB4181A'],
    ['HSBC UK BANK PLC Bradford Market Street', '404658', 'HBUKGB4169P'],
    ['HSBC UK BANK PLC Carlisle City Office', '404659', 'HBUKGB4169U'],
    ['HSBC UK BANK PLC Blackburn', '404660', 'HBUKGB4169M'],
    ['HSBC UK BANK PLC Darlington', '404661', 'HBUKGB4170A'],
    ['HSBC UK BANK PLC Rochdale', '404662', 'HBUKGB4170G'],
    ['HSBC UK BANK PLC Northampton Abington St', '404663', 'HBUKGB4170W'],
    ['HSBC UK BANK PLC Sheffield City', '404664', 'HBUKGB4170B'],
    ['HSBC UK BANK PLC Lincoln Stonebow', '404665', 'HBUKGB4170N'],
    ['HSBC UK BANK PLC Sheffield Crystal Peaks', '404666', 'HBUKGB4170E'],
    ['HSBC UK BANK PLC Rotherham College Street', '404667', 'HBUKGB4169T'],
    ['HSBC UK BANK PLC Banbury', '404668', 'HBUKGB4169K'],
    ['HSBC UK BANK PLC Middlesborough Linthorpe Rd', '404669', 'HBUKGB4170P'],
    ['HSBC UK BANK PLC Hitchin', '404670', 'HBUKGB4170M'],
    ['HSBC UK BANK PLC Bradford Market Street', '404671', 'HBUKGB4169R'],
    ['HSBC UK BANK PLC Whitley Bay', '404672', 'HBUKGB4171D'],
    ['HSBC UK BANK PLC Newcastle Gosforth', '404673', 'HBUKGB4170K'],
    ['HSBC UK BANK PLC Oldham Union Street', '404674', 'HBUKGB4171B'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '404675', 'HBUKGB4169J'],
    ['HSBC UK BANK PLC Hanley', '404676', 'HBUKGB4171C'],
    ['HSBC UK BANK PLC Telford', '404677', 'HBUKGB4171E'],
    ['HSBC UK BANK PLC Leeds City Office', '404678', 'HBUKGB4170J'],
    ['HSBC UK BANK PLC Newcastle-under-Lyme', '404679', 'HBUKGB4170T'],
    ['HSBC UK BANK PLC Burnley', '404680', 'HBUKGB4170R'],
    ['HSBC UK BANK PLC Norwich London Street', '404681', 'HBUKGB4171A'],
    ['HSBC UK BANK PLC Grantham', '404682', 'HBUKGB4170D'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '404683', 'HBUKGB4170H'],
    ['HSBC UK BANK PLC Gainsborough', '404684', 'HBUKGB4170C'],
    ['HSBC UK BANK PLC Darlington', '404685', 'HBUKGB4170U'],
    ['HSBC UK BANK PLC Walthamstow', '404686', 'HBUKGB4169W'],
    ['HSBC UK BANK PLC Harrogate', '404687', 'HBUKGB4169N'],
    ['HSBC UK BANK PLC Sunderland The Bridges', '404688', 'HBUKGB41SEA'],
    ['HSBC UK BANK PLC Dewsbury', '404689', 'HBUKGB4170F'],
    ['HSBC UK BANK PLC Leeds City Office', '404690', 'HBUKGB41KIR'],
    ['HSBC UK BANK PLC Bradford Market Street', '404691', 'HBUKGB41BRV'],
    ['HSBC UK BANK PLC Crewe Market Street', '404692', 'HBUKGB41CRW'],
    ['HSBC UK BANK PLC Kettering', '404693', 'HBUKGB41MOR'],
    ['HSBC UK BANK PLC Ipswich Tavern Street', '404694', 'HBUKGB41IPS'],
    ['HSBC UK BANK PLC Preston Fishergate', '404695', 'HBUKGB41PRS'],
    ['HSBC UK BANK PLC Wellingborough', '404696', 'HBUKGB41WEL'],
    ['HSBC UK BANK PLC Stockton-on-Tees', '404697', 'HBUKGB41STO'],
    ['HSBC UK BANK PLC Rochdale', '404698', 'HBUKGB41ROC'],
    ['HSBC UK BANK PLC York, Parliament Street', '404700', 'HBUKGB4181B'],
    ['HSBC UK BANK PLC Kendal', '404701', 'HBUKGB4163U'],
    ['HSBC UK BANK PLC Windsor High Street', '404702', 'HBUKGB4111J'],
    ['HSBC UK BANK PLC Wisbech', '404704', 'HBUKGB4164G'],
    ['HSBC UK BANK PLC Chelmsford City', '404705', 'HBUKGB4164H'],
    ['HSBC UK BANK PLC Hull, Jameson Street', '404706', 'HBUKGB4164J'],
    ['HSBC UK BANK PLC Witney', '404707', 'HBUKGB4114J'],
    ['HSBC UK BANK PLC Woking Commercial Road', '404708', 'HBUKGB4109S'],
    ['HSBC UK BANK PLC Wokingham', '404709', 'HBUKGB4113F'],
    ['HSBC UK BANK PLC Wolverhampton', '404711', 'HBUKGB4109T'],
    ['HSBC UK BANK PLC Wolverhampton', '404712', 'HBUKGB4171N'],
    ['HSBC UK BANK PLC Walthamstow', '404714', 'HBUKGB4164M'],
    ['HSBC UK BANK PLC Walthamstow', '404715', 'HBUKGB4164N'],
    ['HSBC UK BANK PLC Swindon Canal Walk', '404716', 'HBUKGB4158P'],
    ['HSBC UK BANK PLC Worcester Broad Street', '404717', 'HBUKGB4109U'],
    ['HSBC UK BANK PLC Weston-super-Mare', '404718', 'HBUKGB4162U'],
    ['HSBC UK BANK PLC Kingston upon Thames - Eden', '404719', 'HBUKGB4164P'],
    ['HSBC UK BANK PLC Workington', '404720', 'HBUKGB4164R'],
    ['HSBC UK BANK PLC Retford', '404721', 'HBUKGB4164T'],
    ['HSBC UK BANK PLC Worthing Warwick Street', '404722', 'HBUKGB4164V'],
    ['HSBC UK BANK PLC Worthing Goring Road', '404723', 'HBUKGB4164W'],
    ['HSBC UK BANK PLC Worthing Rowlands Road', '404724', 'HBUKGB4171P'],
    ['HSBC UK BANK PLC Worthing Warwick Street', '404725', 'HBUKGB4110P'],
    ['HSBC UK BANK PLC Wrexham', '404726', 'HBUKGB4109W'],
    ['HSBC UK BANK PLC Wymondham', '404727', 'HBUKGB4165E'],
    ['HSBC UK BANK PLC Yeovil Area Branch', '404728', 'HBUKGB4165H'],
    ['HSBC UK BANK PLC York Parliament Street', '404731', 'HBUKGB4109Y'],
    ['HSBC UK BANK PLC Neath', '404733', 'HBUKGB4158M'],
    ['HSBC UK BANK PLC Bristol Downend', '404734', 'HBUKGB4165G'],
    ['HSBC UK BANK PLC Uxbridge', '404736', 'HBUKGB4165J'],
    ['HSBC UK BANK PLC Windsor High Street', '404737', 'HBUKGB4163W'],
    ['HSBC UK BANK PLC Norwich London Street', '404738', 'HBUKGB4147M'],
    ['HSBC UK BANK PLC Reading Broad Street', '404739', 'HBUKGB4151A'],
    ['HSBC UK BANK PLC Sutton Coldfield', '404741', 'HBUKGB4158D'],
    ['HSBC UK BANK PLC Woodbridge', '404742', 'HBUKGB4164L'],
    ['HSBC UK BANK PLC York Parliament Street', '404745', 'HBUKGB4165R'],
    ['HSBC UK BANK PLC Weston-super-Mare', '404746', 'HBUKGB4164A'],
    ['HSBC UK BANK PLC Wellingborough', '404748', 'HBUKGB4187D'],
    ['HSBC UK BANK PLC Lincoln Stonebow', '404749', 'HBUKGB4176Z'],
    ['HSBC UK BANK PLC Barnstaple', '404750', 'HBUKGB4186U'],
    ['HSBC UK BANK PLC Middlesbrough Albert Road', '404751', 'HBUKGB4165F'],
    ['HSBC UK BANK PLC Worcester, St Johns', '404752', 'HBUKGB4177A'],
    ['HSBC UK BANK PLC Wrexham', '404753', 'HBUKGB4186V'],
    ['HSBC UK BANK PLC Launceston', '404754', 'HBUKGB4186W'],
    ['HSBC UK BANK PLC York, Parliament Street', '404755', 'HBUKGB4181C'],
    ['HSBC UK BANK PLC York Parliament Street', '404757', 'HBUKGB4181D'],
    ['HSBC UK BANK PLC Firstdirect 4758', '404758', 'HBUKGB41FDH'],
    ['HSBC UK BANK PLC Firstdirect 4759', '404759', 'HBUKGB41FDI'],
    ['HSBC UK BANK PLC Firstdirect 4760', '404760', 'HBUKGB41FDJ'],
    ['HSBC UK BANK PLC Firstdirect 4761', '404761', 'HBUKGB41FDK'],
    ['HSBC UK BANK PLC Firstdirect 4762', '404762', 'HBUKGB41FDN'],
    ['HSBC UK BANK PLC Firstdirect 4763', '404763', 'HBUKGB41FDO'],
    ['HSBC UK BANK PLC First Direct 4764', '404764', 'HBUKGB41FDP'],
    ['HSBC UK BANK PLC Firstdirect 4765', '404765', 'HBUKGB41FDQ'],
    ['HSBC UK BANK PLC Firstdirect 4766', '404766', 'HBUKGB41FDR'],
    ['HSBC UK BANK PLC Firstdirect 4767', '404767', 'HBUKGB41FDS'],
    ['HSBC UK BANK PLC Firstdirect 4768', '404768', 'HBUKGB41FDT'],
    ['HSBC UK BANK PLC Firstdirect 4769', '404769', 'HBUKGB41FDU'],
    ['HSBC UK BANK PLC First Direct', '404770', 'HBUKGB41FDV'],
    ['HSBC UK BANK PLC First Direct', '404771', 'HBUKGB41FDW'],
    ['HSBC UK BANK PLC First Direct, Leeds', '404772', 'HBUKGB41FD1'],
    ['HSBC UK BANK PLC First Direct, Leeds', '404773', 'HBUKGB41FD2'],
    ['HSBC UK BANK PLC First Direct, Leeds', '404774', 'HBUKGB41FD3'],
    ['HSBC UK BANK PLC First Direct, Leeds', '404775', 'HBUKGB41FD4'],
    ['HSBC UK BANK PLC First Direct', '404776', 'HBUKGB41FD5'],
    ['HSBC UK BANK PLC First Direct', '404777', 'HBUKGB41FD6'],
    ['HSBC UK BANK PLC First Direct', '404778', 'HBUKGB41FD7'],
    ['HSBC UK BANK PLC First Direct', '404779', 'HBUKGB41FD8'],
    ['HSBC UK BANK PLC First Direct Leeds', '404780', 'HBUKGB41FD9'],
    ['HSBC UK BANK PLC First Direct Leeds', '404781', 'HBUKGB41FDA'],
    ['HSBC UK BANK PLC First Direct Leeds', '404782', 'HBUKGB41FDB'],
    ['HSBC UK BANK PLC First Direct Leeds', '404783', 'HBUKGB41FDC'],
    ['HSBC UK BANK PLC Firstdirect Leeds', '404784', 'HBUKGB41FDD'],
    ['HSBC UK BANK PLC First Direct Leeds', '404785', 'HBUKGB41FDG'],
    ['HSBC UK BANK PLC First Direct Leeds', '404786', 'HBUKGB41FDE'],
    ['HSBC UK BANK PLC First Direct Leeds', '404787', 'HBUKGB41FDF'],
    ['HSBC UK BANK PLC Birmingham Cash Centre', '404790', ''],
    ['HSBC UK BANK PLC Bristol Cash Centre', '404791', ''],
    ['HSBC UK BANK PLC Leeds Cash Centre', '404792', ''],
    ['HSBC UK BANK PLC London Cash Centre', '404793', ''],
    ['HSBC UK BANK PLC Manchester Cash Centre', '404794', ''],
    ['HSBC UK BANK PLC Winchester Cash Centre', '404795', ''],
    ['HSBC UK BANK PLC Kilmarnock Cash Centre', '404796', ''],
    ['HSBC UK BANK PLC Colchester Cash Centre', '404799', ''],
    [
      'HSBC PRIVATE BANK (C.I.) LIMITED HSBC Private Bank (C.I) Ltd',
      '404801',
      'HSBCJESXXXX'
    ],
    [
      'HSBC PRIVATE BANK (UK) LTD 10 Lower Thames St London',
      '404805',
      'SMCOGB2LXXX'
    ],
    [
      'INVESTEC BANK CI  LTD St Peter Port Guernsey Ci',
      '404815',
      'GMGUGGSPXXX'
    ],
    ['LEEK UNITED BUILDING SOCIETY Leek United Building Soc.', '404823', ''],
    ['DEUTSCHE BANK AG 23 Great Winchester St Ec2', '404828', 'MGCOGB2LXXX'],
    [
      'STANDARD BANK ISLE OF MAN LTD Standard Bank Iom Ltd',
      '404852',
      'SBICIMDXXXX'
    ],
    ['TURKISH BANK (UK) LTD 84-86 Borough High Street', '404857', ''],
    [
      'SG KLEINWORT HAMBROS BANK LIMITED S G Hambros Bank& Trust Ltd',
      '404858',
      ''
    ],
    [
      'SG KLEINWORT HAMBROS BANK (CI) LIMITED - GUERNSEY BRANCH St Peter Port Guernsey Ci',
      '404860',
      'HAMBGGSPXXX'
    ],
    ['COVENTRY BUILDING SOCIETY PO Box 9 High St Coventry', '404864', ''],
    [
      'SKANDINAVISKA ENSKILDA BANKEN AB (PUBL) London Branch of S E Banken',
      '404865',
      'ESSEGB2LXXX'
    ],
    ['TURKISH BANK (UK) LTD 577-579 Green Lns Haringey', '404866', ''],
    [
      'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD 10 Lower Thames St. London',
      '404869',
      'HSBCGB2LXXX'
    ],
    ['ROYAL BANK OF CANADA St Peter Port Guernsey', '404875', ''],
    [
      'SG KLEINWORT HAMBROS BANK (CI) LIMITED St Helier Jersey',
      '404876',
      'HAMBJESXXXX'
    ],
    ['NORDEA BANK ABP Nordea Bank', '404878', 'NDEAGB2LXXX'],
    [
      'Northern Trust Guernsey Ltd Barings (Guernsey) Ltd.',
      '404884',
      'BBCOGGSPXXX'
    ],
    ['HSBC UK BANK PLC Ukcc Sheffield Customer Ser', '404914', ''],
    ['HSBC BANK PLC Gis Global Custody & Trust', '404919', ''],
    ['HSBC BANK PLC St Helier. Jersey C.I.', '404924', 'MIDLJESHXXX'],
    ['HSBC BANK PLC P C, Portfolio Ops', '404934', ''],
    ['HSBC BANK PLC P C, Trust Serv, Sheffield', '404940', ''],
    ['HSBC BANK PLC Pep Unit Sheffield', '404944', ''],
    ['HSBC BANK PLC P C, Trust Serv,Southampton', '404946', ''],
    ['HSBC BANK PLC Gis Global Custody & Trust', '404950', ''],
    ['HSBC BANK INTERNATIONAL LTD Isle of Man', '404985', ''],
    ['HSBC BANK PLC Exors Income Tax Dept W.C.2', '404998', ''],
    ['HSBC BANK PLC Overseas Travellers Cheques', '405000', ''],
    ['TURKIYE IS BANKASI AS Turkiye Is Bankasi As', '405033', 'ISBKGB2LXXX'],
    [
      'BANK OF CHINA (UK) LTD 90 Cannon St London Ec4n',
      '405037',
      'BKCHGB2UXXX'
    ],
    ['Intesa Sanpaolo Spa 90 Queen St London Ec4n', '405045', 'BCITGB2LXXX'],
    ['BANCO DO BRASIL SA 15/17 King St London Ec2', '405047', 'BRASGB2LXXX'],
    [
      'BANK OF CEYLON (UK) LIMITED 1 Devonshire Sq London Ec2',
      '405056',
      'BCEYGB2LXXX'
    ],
    ['Mizuho Corporate Bank Ltd River Plate House', '405069', 'MHCBGB2LXXX'],
    ['HSBC BANK PLC Thomas Cook Trav Cheq 5Gbp', '405070', ''],
    ['HSBC BANK PLC Thomas Cook Trav Cheq 10Gbp', '405071', ''],
    ['HSBC BANK PLC Thomas Cook Trav Cheq 20Gbp', '405072', ''],
    ['HSBC BANK PLC Thomas Cook Trav Cheq 50Gbp', '405073', ''],
    ['HSBC BANK PLC Thomas Cook Trav Chq 100Gbp', '405074', ''],
    ['HSBC BANK PLC Thomas Cook Trav Chq 200Gbp', '405075', ''],
    ['DEUTSCHE BANK AG Winchester House', '405081', 'DEUTGB2LXXX'],
    [
      'DZ Bank AG, Deutsche Zentral - Genossenschaftsbank D G Bank Deutsche Genossen',
      '405082',
      'GENOGB2LXXX'
    ],
    [
      'RABOBANK INTERNATIONAL(COOPERATIVE CENTRALE RAIFFEISEN BOERENLEENBANK) One Queenhithe London Ec4v',
      '405091',
      'RABOGB2LXXX'
    ],
    [
      'NATIONAL BANK OF EGYPT (UK) LTD 11 Waterloo Place',
      '405111',
      'NBEGGB2LXXX'
    ],
    ['DNB BANK ASA Dnb Nor Bank Asa', '405114', 'DNBAGB2LXXX'],
    [
      'OVERSEA-CHINESE BANKING CORPORATION LTD 62 Queen Street',
      '405119',
      'OCBCGB2LXXX'
    ],
    [
      'Sumitomo Mitsui Banking Corporation Europe Limited 99 Queen Victoria St Ec4',
      '405125',
      'SMBCGB2LXXX'
    ],
    [
      'THE SUMITOMO TRUST & BANKING CO LTD 155 Bishopsgate Ec2m 3Xu',
      '405130',
      'STBCGB2LXXX'
    ],
    [
      'BRITISH ARAB COMMERCIAL BANK LTD British Arab Commercial Bk',
      '405132',
      'BACMGB2LXXX'
    ],
    ['Wells Fargo Bank NA London Cheque Services', '405133', ''],
    ['CANARA BANK 10 Chiswell St London', '405143', 'CNRBGB2LXXX'],
    [
      'LANDESBANK HESSEN-THURINGEN GIROZENTRALE 95 Queen Victoria Street',
      '405145',
      ''
    ],
    ['T C ZIRAAT BANKASI AS T C Ziraat', '405153', ''],
    ['HANDELSBANKEN PLC Handelsbanken', '405162', 'HANDGB22XXX'],
    ['JYSKE BANK (GIBRALTAR) LTD Jyske Bank (Gibraltar) Ltd', '405178', ''],
    ['ARAB NATIONAL BANK 47 Seymour St London W1a', '405179', 'ARNBGB2LXXX'],
    [
      'NATIONAL BANK OF KUWAIT (INTERNATIONAL) PLC 13 George St London W1u 3Qj',
      '405188',
      'NBOKGB2LXXX'
    ],
    ['HSBC BANK PLC Gis Wholesale Clients', '405193', ''],
    ['HSBC BANK PLC Gis Retail Clients', '405194', ''],
    [
      'KOOKMIN BANK CO LTD, LONDON BRANCH Kookmin Bank International',
      '405195',
      ''
    ],
    ['TURKISH BANK (UK) LTD Turkish Bank UK Ltd', '405198', 'TUBAGB2LXXX'],
    ['HSBC BANK PLC Repair Sorting Code', '405199', 'MIDLGB22RTN'],
    ['HSBC BANK PLC Suspense Agency A/C Park St', '405200', ''],
    ['FBN BANK UK LTD 28 Finsbury Circus', '405204', 'FBNIGB2LXXX'],
    ['J P Morgan Europe Ltd J.P. Morgan Europe Ltd', '405206', 'CHASGB22XXX'],
    [
      'EUROBANK PRIVATE BANK LUXEMBOURG S.A., LONDON BRANCH 108 Wigmore St London W1h',
      '405208',
      'ERBKGB2LXXX'
    ],
    [
      'LANDESBANK BADEN-WURTTEMBERG Landesbank Baden-Wurttember',
      '405210',
      'SOLAGB2LXXX'
    ],
    [
      'MIZRAHI TEFAHOT BANK LIMITED 30 Old Broad Street',
      '405228',
      'MIZBGB2LXXX'
    ],
    [
      'STANDARD BANK JERSEY LIMITED PO Box 583 St Helier Jersey',
      '405233',
      'SBICJESHXXX'
    ],
    ['CAF BANK LIMITED Caf Bank Limited', '405240', ''],
    ['Kookmin Bank International Limited Princes Court London', '405257', ''],
    ['BANK LEUMI (UK) PLC Bank Leumi (UK) plc', '405259', ''],
    [
      'SG KLEINWORT HAMBROS BANK (GIBRALTAR) LIMITED Sg Hambros Bnk & Trst Gib',
      '405265',
      ''
    ],
    [
      'PT BANK NEGARA INDONESIA (PERSERO) TBK Pt Bank Negara Indonesia',
      '405266',
      ''
    ],
    ['HSBC BANK PLC Bank of America Bacs Euro', '405267', ''],
    ['NATIONAL BANK OF CANADA 95 Gresham St London Ec2v', '405268', ''],
    ['BANK MANDIRI (EUROPE) LTD Cardinal Court', '405276', 'BMRIGB2LXXX'],
    ['SCHRODER &CO LIMITED 31 Gresham St London Ec2v', '405278', 'SCPBGB2LXXX'],
    ['HSBC BANK PLC Intdocumentary Credits', '405305', ''],
    ['HSBC BANK PLC International Trade & Exprt', '405309', ''],
    [
      'NATIONAL COUNTIES BUILDING SOCIETY National Counties Building',
      '405336',
      ''
    ],
    ['UNITED NATIONAL BANK LIMITED 46 Mark Lane Ec3', '405356', ''],
    ['HSBC BANK INTERNATIONAL LTD 39 Athol St, Douglas Iom', '405357', ''],
    ['HANDELSBANKEN PLC London', '405359', ''],
    ['WEST BROMWICH BUILDING SOCIETY 374 High St West Bromwich', '405361', ''],
    ['HSBC BANK PLC Derivatives Product', '405371', ''],
    ['KOREA DEVELOPMENT BANK - LONDON BRANCH London', '405375', ''],
    ['HSBC BANK PLC Bills Dept Poultry Ec2', '405379', ''],
    ['LEEDS BUILDING SOCIETY 105 Albion St Leeds', '405383', ''],
    ['HSBC BANK PLC Glob Mkt Fx Sterling Recs', '405388', ''],
    [
      'KOREA DEVELOPMENT BANK - LONDON BRANCH 99 Bishopsgate London',
      '405400',
      ''
    ],
    ['SHINHAN BANK 51/55 Gresham St London', '405402', ''],
    ['PT BANK NEGARA INDONESIA (PERSERO) TBK Pinners Hall', '405410', ''],
    ['INVESTEC BANK PLC Investec Bank plc', '405413', ''],
    ['HSBC BANK PLC Pep Administration Dept', '405444', ''],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Group Treasury London',
      '405454',
      'AIBKGB2XTSY'
    ],
    ['HSBC BANK PLC Access Dept (Upd D/Ds)', '406094', ''],
    ['HSBC UK BANK PLC Gold M/Card (Upd D/Ds)', '406095', ''],
    ['HSBC BANK PLC Corp Card (Upd D/Ds)', '406096', ''],
    ['HSBC BANK PLC Access Dept', '406098', ''],
    ["HSBC BANK PLC P C,Portfolio Ops S'hampton", '406101', ''],
    ['HSBC BANK PLC Private Clients, Trust Serv', '406102', ''],
    [
      'BANK JULIUS BAER & CO LTD - GUERNSEY BRANCH PO Box 87 St Peter Port',
      '406103',
      ''
    ],
    ['SCHRODERS (CI) LTD Schroders (Ci) Ltd', '406105', ''],
    ['HSBC BANK PLC Schroder Invest Man Ltd', '406106', ''],
    ['HSBC UK BANK PLC HSBC Trust Co(UK),Sheffield', '406114', ''],
    ["HSBC BANK PLC HSBC Trust Co(UK)S'hampton", '406115', ''],
    ['TURKISH BANK (UK) LTD 121,Kingsland Highst London', '406130', ''],
    ['ANTHOS BANK B.V. Anthos Bank B.V', '406133', ''],
    ['HSBC UK BANK PLC 1 Warwick Street, Worthing', '406135', ''],
    ['HSBC BANK PLC Chartwell House', '406149', ''],
    ['HSBC BANK PLC Chartwell House', '406150', ''],
    ['HSBC BANK PLC Chartwell House', '406151', ''],
    ['HSBC BANK PLC Priv Clients,Pep Admin Dept', '406154', ''],
    ['HSBC BANK PLC Salford Quays Manchester', '406160', ''],
    ['HSBC BANK PLC Jersey, Chaps Payments', '406162', 'MIDLJESH01A'],
    ['BANK OF CHINA (UK) LTD Mosley Street Manchester', '406172', ''],
    ['HSBC BANK PLC Prudential Banking plc', '406173', ''],
    ['EGG BANKING PLC Prudential Banking plc', '406174', ''],
    ['HSBC BANK PLC Chartwell House', '406176', ''],
    ['HSBC BANK PLC Platinum Card', '406179', ''],
    ['HSBC BANK PLC Card Services Premier Card', '406180', ''],
    ['BANK OF IRELAND FSHARP LIMITED PO Box 322 Douglas Iom', '406182', ''],
    ['HSBC BANK PLC 11 Stanhope Gate London', '406189', ''],
    ['ROTHSCHILD & CO BANK LIMITED PO Box 58 St Peter Port Ci', '406192', ''],
    ['YORKSHIRE BUILDING SOCIETY Yorkshire House', '406199', ''],
    ['COMMERZBANK AG London Branch', '406201', ''],
    ['NATIONAL BANK OF GREECE SA King William St', '406204', ''],
    ['HSBC UK BANK PLC Investment Services No1', '406211', ''],
    ['HSBC UK BANK PLC Investment Services No2', '406212', ''],
    ['HSBC UK BANK PLC Investmentservicesno3', '406213', ''],
    ['HSBC UK BANK PLC Investmentservicesno4', '406214', ''],
    ['HSBC UK BANK PLC Investment Services No5', '406215', ''],
    ['HSBC UK BANK PLC Investment Services No6', '406216', ''],
    ['HSBC UK BANK PLC Investment Services No7', '406217', ''],
    ['HSBC UK BANK PLC Investment Services No8', '406218', ''],
    ['HSBC UK BANK PLC Investment Services No9', '406219', ''],
    ['HSBC UK BANK PLC Investment Services No10', '406220', ''],
    ['THE NORINCHUKIN BANK 155 Bishopsgate', '406231', ''],
    [
      'CREDIT SUISSE (GIBRALTAR) LIMITED Neptune House',
      '406237',
      'CRESGIGIXXX'
    ],
    ['CREDIT SUISSE(GUERNSEY) LTD Helvetia Court', '406239', 'CRESGGSPXXX'],
    ['ICBC (LONDON) PLC Icbc (London) Ltd', '406243', 'ICBKGB2LXXX'],
    ['THE ACCESS BANK UK LIMITED The Access Bank Limited', '406245', ''],
    ['ZENITH BANK (UK) LIMITED Zenith Bank (UK) Limited', '406247', ''],
    ['CREDIT SUISSE (UK) LIMITED Credit Suisse (UK) Ltd', '406248', ''],
    [
      'China Construction Bank (London) Limited China Cons Bk (London) Ltd',
      '406249',
      ''
    ],
    [
      'Mega International Commercial Bank Co. Ltd Mega Intl Comm Bnk',
      '406250',
      'ICBCGB2LXXX'
    ],
    ['ABN AMRO BANK N.V UK Branch', '406252', 'FTSBGB2LXXX'],
    [
      'RABOBANK INTERNATIONAL(COOPERATIVE CENTRALE RAIFFEISEN BOERENLEENBANK) Thames Court',
      '406253',
      ''
    ],
    ['HSBC BANK PLC St Peter Port', '406257', ''],
    ['QATAR NATIONAL BANK (Q.P.S.C.) London Branch', '406262', ''],
    [
      'Industrial Bank of Korea Industrial Bank of Korea',
      '406283',
      'IBKOGB2LXXX'
    ],
    ['COVENTRY BUILDING SOCIETY Coventry', '406301', ''],
    ['HSBC UK BANK PLC HSBC Online Card', '406302', ''],
    ['HSBC UK BANK PLC Camden House West', '406306', ''],
    ['VOLKSWAGEN BANK GMBH Volkswagen Bank GmbH', '406308', ''],
    ['HSBC BANK PLC HSBC Visa', '406321', ''],
    ['HSBC BANK PLC HSBC Visa Welsh', '406322', ''],
    ['HSBC BANK PLC HSBC Visa Welsh Student', '406323', ''],
    ['HSBC BANK PLC HSBC Visa Welsh Staff', '406324', ''],
    ['HSBC BANK PLC HSBC Visa Student', '406325', ''],
    ['HSBC BANK PLC HSBC Visa Staff', '406326', ''],
    ['HSBC BANK PLC HSBC Visa Card Morrisons', '406327', ''],
    ['HSBC BANK PLC HSBC Purchasing Card', '406328', ''],
    ['HSBC BANK PLC HSBC Premier Card', '406329', ''],
    ['HSBC BANK PLC HSBC Premier Card Staff', '406330', ''],
    ['HSBC BANK PLC HSBC Platinum Card No Ati', '406331', ''],
    ['HSBC BANK PLC HSBC Platinum Card With Ati', '406332', ''],
    ['HSBC BANK PLC Platinum Card Staff With At', '406333', ''],
    ['HSBC BANK PLC Platinum Card Staff No Ati', '406334', ''],
    ['HSBC BANK PLC Mastercard Welsh Student', '406335', ''],
    ['HSBC BANK PLC Mastercard Welsh Standard', '406336', ''],
    ['HSBC BANK PLC HSBC Mastercard Welsh Staff', '406337', ''],
    ['HSBC BANK PLC HSBC Mastercard Student', '406338', ''],
    ['HSBC BANK PLC HSBC Mastercard Standard', '406339', ''],
    ['HSBC BANK PLC HSBC Mastercard Staff', '406340', ''],
    ['HSBC BANK PLC HSBC Mastercard', '406341', ''],
    ['HSBC BANK PLC HSBC Mastercard Welsh', '406342', ''],
    ['HSBC BANK PLC HSBC Gold Credit Card Staff', '406343', ''],
    ['HSBC BANK PLC HSBC Gold Credit Card', '406344', ''],
    ['HSBC BANK PLC HSBC Mastercard Combined', '406345', ''],
    ['HSBC BANK PLC HSBC Mastercard Staff Combd', '406346', ''],
    ['HSBC BANK PLC HSBC M.Card Student Combd', '406347', ''],
    ['HSBC BANK PLC HSBC M.Card Welsh Combined', '406348', ''],
    ['HSBC BANK PLC HSBC M.Card Welsh.Staff Com', '406349', ''],
    ['HSBC BANK PLC HSBC M.Card Welsh.Stdnt Com', '406350', ''],
    ['HSBC UK BANK PLC HSBC Online Card', '406355', ''],
    ['HSBC UK BANK PLC Affinity Card', '406356', ''],
    ['HSBC BANK PLC HSBC Corporate Card', '406362', ''],
    ['HSBC BANK PLC HSBC Corp Card Package', '406363', ''],
    ['HSBC BANK PLC HSBC Corporate BT Card', '406364', ''],
    ['HSBC BANK PLC HSBC Commercial Card', '406365', ''],
    ['HSBC UK BANK PLC Core', '406366', ''],
    ['HSBC BANK PLC B&Q Fin Serv Card', '406367', ''],
    ['HSBC UK BANK PLC B&Q Fin Serv Store Card', '406368', ''],
    ['GODIVA MORTGAGES LIMITED Godiva Mortgages Ltd', '406369', ''],
    ['COVENTRY BUILDING SOCIETY Coventry Bs Collections', '406370', ''],
    ['CANARA BANK Leicester Branch', '406376', ''],
    ['CYNERGY BANK LIMITED Cynergy Bank Limited', '406377', 'BCYPGB2LXXX'],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Test Sort Code',
      '406379',
      'NRNBGB2LVTS'
    ],
    ['INVESTEC BANK CI  LTD Investec Bank Jersey', '406380', ''],
    ['WEST BROMWICH BUILDING SOCIETY 374 High Street', '406381', ''],
    ['COVENTRY BUILDING SOCIETY Coventry B/Society', '406383', ''],
    ['BNP PARIBAS London Branch', '406384', ''],
    ['GODIVA MORTGAGES LIMITED Godiva Mortgages Ltd - 1', '406385', ''],
    ['GODIVA MORTGAGES LIMITED Godiva Mortgages Ltd - 2', '406386', ''],
    ['COVENTRY BUILDING SOCIETY Coventry Building Society 1', '406388', ''],
    ['COVENTRY BUILDING SOCIETY Coventry Building Society 2', '406389', ''],
    ['HANDELSBANKEN PLC Chaps Return Office', '406390', 'HANDGB22RTN'],
    ['COVENTRY BUILDING SOCIETY Coventry Building Soc - Itl', '406393', ''],
    ['COVENTRY BUILDING SOCIETY Coventry Build Soc-Itl 2', '406394', ''],
    ['ALPHA BANK LONDON LTD Cannon Street', '406395', ''],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Chaps Mortgages Out',
      '406396',
      'NRNBGB2LMCO'
    ],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Chaps Commercial',
      '406397',
      'NRNBGB2LCCM'
    ],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Chaps Mortgages in ( Delay)',
      '406398',
      'NRNBGB2LMDC'
    ],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Chaps Mortgages in (Redem)',
      '406399',
      'NRNBGB2LMCI'
    ],
    ['TESCO PERSONAL FINANCE PLC Club Card Plus 1', '406400', ''],
    ['TESCO PERSONAL FINANCE PLC Club Card Plus 2', '406401', ''],
    ['TESCO PERSONAL FINANCE PLC Instant Saver 1', '406402', ''],
    ['TESCO PERSONAL FINANCE PLC Instant Saver 2', '406403', ''],
    ['TESCO PERSONAL FINANCE PLC Instant Saver 3', '406404', ''],
    ['TESCO PERSONAL FINANCE PLC Savings 1', '406405', ''],
    ['TESCO PERSONAL FINANCE PLC Internet Saver', '406406', ''],
    ['TESCO PERSONAL FINANCE PLC Instant Saver 4', '406407', ''],
    ['TESCO PERSONAL FINANCE PLC Tesco Bank-Isa', '406409', ''],
    ['TESCO PERSONAL FINANCE PLC Loan 2', '406410', ''],
    ['TESCO PERSONAL FINANCE PLC Loans', '406412', ''],
    ['TESCO PERSONAL FINANCE PLC Credit Card', '406415', 'TPFGGB2EXXX'],
    ['TESCO PERSONAL FINANCE PLC Tesco Personal Finance', '406416', ''],
    ['TESCO PERSONAL FINANCE PLC Tesco Bank Live Proving', '406418', ''],
    ['TESCO PERSONAL FINANCE PLC Personal Current Account', '406420', ''],
    [
      'INTESA SANPAOLO PRIVATE BANK (SUISSE) MORVAL S.A. LONDON BRANCH London Branch',
      '406423',
      'SPCHGB22XXX'
    ],
    ['QIB (UK) PLC Qib (UK)', '406424', ''],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Virgin Money Credit Card',
      '406425',
      'NRNBGB2LVCC'
    ],
    ['HSBC UK BANK PLC HSBC Legacy Card', '406426', ''],
    ['PICTET & CIE (EUROPE) S.A. London Branch', '406427', 'PICTGB5LXXX'],
    ['LANDESBANK HESSEN-THURINGEN GIROZENTRALE Gz London Branch', '406428', ''],
    ['CACEIS BANK UK Branch', '406429', ''],
    [
      'VIRGIN MONEY (CLYDESDALE BANK PLC) Virgin Money Sme',
      '406430',
      'NRNBGB2LSME'
    ],
    ['BNP PARIBAS London Va Branch', '406432', ''],
    ['HSBC UK BANK PLC Jlfs Loans', '406433', ''],
    ['INVESTEC BANK PLC Investec Bank plc', '406434', ''],
    [
      'China Construction Bank (London) Limited China Cons Bk Ltd',
      '406435',
      ''
    ],
    ['WEST BROMWICH BUILDING SOCIETY 2 Providence Place', '406436', ''],
    ['GOLDMAN SACHS INTERNATIONAL BANK Fleet Street', '406437', ''],
    ['LEEDS BUILDING SOCIETY Albion Street', '406438', 'LEEDGB23XXX'],
    ['ROTHSCHILD & CO BANK LIMITED Guernsey', '406439', ''],
    [
      'FIRSTRAND BANK LIMITED, GUERNSEY BRANCH La Plaiderie',
      '406440',
      'FIRNGGSPXXX'
    ],
    ['COVENTRY BUILDING SOCIETY Oakfield House', '406441', ''],
    ['HSBC BANK PLC Brexit Corporate Card', '406442', ''],
    ['GOLDMAN SACHS INTERNATIONAL BANK Goldman Sachs Intl', '406443', ''],
    ['INVESTEC BANK PLC Investec Bank plc', '406445', ''],
    [
      'BNP PARIBAS S.A. JERSEY BRANCH Bnp House, Jersey',
      '406446',
      'BNPAJESHXXX'
    ],
    ['INVESTEC BANK PLC Investec Bank plc', '406447', ''],
    ['INVESTEC BANK PLC Investec Bank plc', '406453', ''],
    ['INVESTEC BANK PLC Investec Bank plc', '406460', ''],
    ['YORKSHIRE BUILDING SOCIETY T/A Norwich&Peterboro B/S', '406500', ''],
    ['HSBC UK BANK PLC Ipsl Bradford', '407008', ''],
    ['HSBC UK BANK PLC Paps Management', '407010', ''],
    ['HSBC UK BANK PLC Ipsl Northampton', '407027', ''],
    ['HSBC UK BANK PLC Ipsl Bootle', '407028', ''],
    ['HSBC UK BANK PLC Ipsl Bristol', '407032', ''],
    ['HSBC UK BANK PLC Ipsl Northampton', '407036', ''],
    ['HSBC UK BANK PLC Coventry Dsc/Ipsl Camberley', '407037', ''],
    ['HSBC UK BANK PLC Ipsl Coventry', '407041', ''],
    ['HSBC UK BANK PLC Gsc Colombo B/H of  Isa/Tcr', '407044', ''],
    ['HSBC UK BANK PLC Swansea Dsc', '407058', ''],
    ['HSBC UK BANK PLC Leeds Customer Service Cen', '407062', ''],
    ['HSBC UK BANK PLC Swansea C S C', '407070', ''],
    ['HSBC UK BANK PLC Ppi Coventry', '407079', ''],
    ['HSBC UK BANK PLC Coventry Dsc', '407080', ''],
    ['HSBC UK BANK PLC Birmingham Dsc', '407082', ''],
    ['HSBC UK BANK PLC Coventry Dsc (Leeds)', '407083', ''],
    ['HSBC UK BANK PLC Brentwood Dsc', '407084', ''],
    ['HSBC UK BANK PLC Bops Sheff/Manc', '407085', ''],
    ['HSBC UK BANK PLC Manchester Dsc (Sheffield)', '407086', ''],
    ['HSBC UK BANK PLC Swansea C S C', '407102', ''],
    ['HSBC UK BANK PLC Edinburgh Cust Serv Centre', '407106', ''],
    ['HSBC UK BANK PLC Gps', '407109', ''],
    ['HSBC UK BANK PLC Collections Coventry', '407111', ''],
    ['HSBC UK BANK PLC Mortgage Collections Team', '407113', ''],
    ['HSBC UK BANK PLC Customer Credit Services Sh', '407114', ''],
    ['HSBC UK BANK PLC Internet Banking Technical', '407128', ''],
    ['HSBC UK BANK PLC Leicester Ccu', '407129', ''],
    ['HSBC UK BANK PLC Channel Experience', '407130', ''],
    ['HSBC BANK PLC Mortgages Direct', '407131', ''],
    ['HSBC UK BANK PLC Customer Credit Services', '407132', ''],
    ['HSBC UK BANK PLC Specialised Ind Group', '407133', ''],
    ['HSBC UK BANK PLC Non Bank Financial Inst', '407134', ''],
    ['HSBC UK BANK PLC Cag (Systems)', '407135', ''],
    ['HSBC UK BANK PLC Central Systems', '407136', ''],
    ['HSBC UK BANK PLC Home Banking', '407140', ''],
    ['HSBC UK BANK PLC Business Internet Banking', '407144', ''],
    ['HSBC UK BANK PLC HSBC Investment Funds Adm', '407166', ''],
    ['HSBC UK BANK PLC Ssuk  Losses', '407168', 'HBUKGB4171R'],
    ['HSBC UK BANK PLC Clearings Liaison Team', '407171', ''],
    ['HSBC UK BANK PLC Customer Solutions Team Ham', '407306', 'HBUKGB4192V'],
    ['HSBC UK BANK PLC Customer Solutions Team Hem', '407312', 'HBUKGB4192U'],
    ['HSBC UK BANK PLC Customer Solutions Team Lee', '407313', 'HBUKGB4193C'],
    ['HSBC UK BANK PLC Customer Solutions Team Swa', '407315', 'HBUKGB4192T'],
    ['HSBC UK BANK PLC HSBC Direct Customer Resol', '407323', 'HBUKGB4192W'],
    ['HSBC UK BANK PLC HSBC Bus Serv Centre', '407328', ''],
    ['HSBC UK BANK PLC M&S Bank', '407377', ''],
    ['HSBC UK BANK PLC West Hampshire Area Office', '407503', ''],
    ['HSBC UK BANK PLC Birmingham New St Area Off', '407537', ''],
    ['HSBC UK BANK PLC Bristol Kingswood', '407539', ''],
    ['HSBC UK BANK PLC Bournmouth & Poole A.O', '407540', ''],
    ['HSBC UK BANK PLC Manchester King Street', '407546', ''],
    ['HSBC UK BANK PLC North Thames Area Office', '407548', ''],
    ['HSBC UK BANK PLC Lincolnshire Area Office', '407557', ''],
    ['HSBC UK BANK PLC South West Midlands A.O', '407621', ''],
    ['HSBC UK BANK PLC Somerset & Wessex Area Off', '407625', ''],
    ["HSBC PRIVATE BANK (UK) LTD St James's Street", '409341', ''],
    ['HSBC UK BANK PLC Ctl Fin Board of Methodist', '426004', ''],
    ['HSBC UK BANK PLC Gloucestershire County C', '426065', ''],
    ['HSBC BANK PLC H/O Collection Account', '426080', ''],
    ['HSBC UK BANK PLC London Borough of Bromley', '426199', ''],
    ['HSBC UK BANK PLC London Borough of Bromley', '426219', ''],
    ['HSBC BANK PLC Gleeson Homes Ltd', '426340', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '426382', ''],
    ['HSBC BANK PLC H/O Collection Account', '426466', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '426471', ''],
    ['HSBC UK BANK PLC Charitable Appeal No 2 A/C', '426482', ''],
    ['HSBC UK BANK PLC Charitable Appeal No 3 A/C', '426490', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '426498', ''],
    ['HSBC UK BANK PLC Leeds Building Society', '426555', ''],
    ['HSBC BANK PLC Comtel Ltd (Stafford)', '426571', ''],
    ['HSBC BANK PLC Comtel Ltd (Oxford)', '426636', ''],
    ['HSBC BANK PLC Comtel Ltd (Andover)', '426644', ''],
    ['HSBC BANK PLC Comtel Ltd (Coventry)', '426652', ''],
    ['HSBC UK BANK PLC Apollo Direct Limited', '426660', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '426676', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '426684', ''],
    ['HSBC UK BANK PLC Ho Collection Account', '426706', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '426741', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '426781', ''],
    ['HSBC BANK PLC H O Collection Acct', '426786', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '426803', ''],
    ['HSBC BANK PLC H/O Collection Acct', '426806', ''],
    ['HSBC BANK PLC H/O Collection Acct', '426814', ''],
    ['HSBC BANK PLC H/O Collection Acct', '426822', ''],
    ['HSBC BANK PLC Prudential Assurance Co Ltd', '426867', ''],
    ['HSBC BANK PLC Opal Telecom Ltd', '426875', ''],
    ['HSBC UK BANK PLC Hsbc-Elite Mastercard', '426891', ''],
    ['HSBC BANK PLC Corporate Card -Hbeu', '426903', ''],
    ['HSBC BANK PLC Business Cc -Hbeu', '426948', ''],
    ['HSBC BANK PLC Mastercard Premier O/S', '426956', ''],
    ['HSBC BANK PLC HSBC Prem Wrld Elite Mc O/S', '426964', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '426978', ''],
    ['HSBC BANK PLC Visa O/S', '426980', ''],
    ['HSBC BANK PLC HSBC Bank', '427000', ''],
    ['HSBC BANK PLC Head Office Collections Acc', '427037', ''],
    ['HSBC BANK PLC Head Office Collection Acc', '427045', ''],
    ['HSBC BANK PLC H/O Col Account', '427053', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '427079', ''],
    ['HSBC BANK PLC HSBC Digital Business Cr', '427126', ''],
    ['HSBC BANK PLC UK Cards & Loans Business', '427134', ''],
    ['HSBC BANK PLC Head Office Collection A/C', '427150', ''],
    ['HSBC BANK PLC H/O Collection Account', '427273', ''],
    ['HSBC BANK PLC Head Office Collection Acc', '427373', ''],
    ['HSBC BANK PLC H/O Collection Account', '427381', ''],
    ['HSBC BANK PLC Head Office Collection Acc', '427470', ''],
    ['HSBC BANK PLC H/O Collection Account', '427486', ''],
    ['HSBC BANK PLC H/O Collection Account', '427499', ''],
    ['HSBC BANK PLC Head Office Coll A/C', '427543', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '427591', ''],
    ['HSBC BANK PLC H/O Collection Account', '427605', ''],
    ['HSBC BANK PLC H/O Collection Account', '427613', ''],
    ['HSBC BANK PLC Head Office Collection A/C', '427621', ''],
    ['HSBC BANK PLC H/O Collection Account', '427648', ''],
    ['HSBC BANK PLC H/O Collection Account', '427656', ''],
    ['HSBC BANK PLC H/O Collection Account', '427664', ''],
    ['HSBC BANK PLC H/O Collection Account', '427672', ''],
    ['HSBC BANK PLC H/O Collection Account', '427680', ''],
    ['HSBC BANK PLC H/O Collection Account', '427737', ''],
    ['HSBC BANK PLC H/O Collection Account', '427745', ''],
    ['HSBC BANK PLC H/O Collection Account', '427877', ''],
    ['HSBC UK BANK PLC HSBC - First Direct Visa', '427907', ''],
    ['HSBC UK BANK PLC HSBC - First Direct Prem', '427923', ''],
    ['HSBC UK BANK PLC Automotive Finacial Serv.', '427931', ''],
    ['HSBC UK BANK PLC HSBC Invoice Finance UK', '427974', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '428040', ''],
    ['HSBC BANK PLC Head Office Collection Acc', '428041', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '428059', ''],
    ['HSBC UK BANK PLC HSBC - National Trust C/H', '428393', ''],
    ['HSBC BANK PLC Gbp Corp- Ifc Nrfb', '428418', ''],
    ['HSBC UK BANK PLC Gbp Corp- Ifc', '428487', ''],
    ['HSBC UK BANK PLC HSBC UK Bank plc &Hireright', '428490', ''],
    ['HSBC BANK PLC H/O Collection Account', '428504', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '428547', ''],
    ['HSBC BANK PLC H/O Collection Account', '428725', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '428776', ''],
    ['HSBC BANK PLC H/O Collection Account', '428830', ''],
    ['HSBC BANK PLC H/O Collection Account', '428849', ''],
    ['HSBC BANK PLC H/O Collection Account', '428857', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '428881', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '428946', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '429012', ''],
    ['HSBC BANK PLC H/O Collection Account', '429039', ''],
    ['HSBC BANK PLC Head Office Collection Acc', '429047', ''],
    ['HSBC BANK PLC H/O Collection Account', '429144', ''],
    ['HSBC BANK PLC H/O Collection Account', '429187', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '429241', ''],
    ['HSBC BANK PLC H/O Collection Account', '429292', ''],
    ['HSBC BANK PLC H/O Collection Account', '429314', ''],
    ['HSBC BANK PLC Head Office Collection A/Cs', '429322', ''],
    ['HSBC BANK PLC H/O Collection Account', '429349', ''],
    ['HSBC BANK PLC H/O Collection Account', '429365', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '429373', ''],
    ['HSBC BANK PLC HSBC Bank', '429386', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '429403', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '429454', ''],
    ['HSBC BANK PLC H/O Collection Account', '429462', ''],
    ['HSBC BANK PLC H/O Collection Account', '429489', ''],
    ['HSBC BANK PLC H/O Collection Account', '429500', ''],
    ['HSBC BANK PLC HSBC Maidenhead', '429503', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '429543', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '429551', ''],
    ['HSBC BANK PLC H/O Collection Account', '429586', ''],
    ['HSBC BANK PLC H/O Collection Account', '429594', ''],
    ['HSBC BANK PLC H/O Collection Account', '429624', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '429640', ''],
    ['HSBC BANK PLC H/O Collection Account', '429667', ''],
    ['HSBC UK BANK PLC Revenues Management Serv', '429675', ''],
    ['HSBC BANK PLC H/O Collection Account', '429756', ''],
    ['HSBC UK BANK PLC Fmt Tcc Lodgement', '429780', ''],
    ['HSBC UK BANK PLC HSBC - Visa Purchasing C/H', '429799', ''],
    ['HSBC UK BANK PLC HSBC - Mastercard C/H', '429802', ''],
    ['HSBC UK BANK PLC HSBC - Retailer Card Plan', '429810', ''],
    ['HSBC UK BANK PLC HSBC - Gold Mastercard C/H', '429829', ''],
    ['HSBC UK BANK PLC Hcs Commercial Card', '429868', ''],
    ['HSBC UK BANK PLC HSBC - Visa Card C/H', '429934', ''],
    ['HSBC UK BANK PLC HSBC - Affinity Card C/H', '429942', ''],
    ['HSBC UK BANK PLC HSBC - Business Card C/H', '429950', ''],
    ['HSBC UK BANK PLC HSBC - Gold Visa C/H', '429969', ''],
    ['HSBC BANK PLC Hoover Ltd', '429981', ''],
    ['HSBC UK BANK PLC HSBC - Private Label C/H', '429985', ''],
    ['HSBC BANK PLC HSBC - Visa Corporate C/H', '429993', ''],
    ['HSBC BANK PLC H/O Collection Account', '430002', ''],
    ['HSBC BANK PLC Head Office Collection A/C', '430010', ''],
    ['HSBC BANK PLC H/O Collection', '430033', ''],
    ['HSBC UK BANK PLC H/O Coll Acc', '430045', ''],
    ['HSBC BANK PLC H/O Collection Acct', '430068', ''],
    ['HSBC BANK PLC H/O Collection Account', '430076', ''],
    ['HSBC BANK PLC H/O Collection Account', '430084', ''],
    ['HSBC BANK PLC H/O Collection Account', '430092', ''],
    ['HSBC BANK PLC H/O Collection Acct', '430106', ''],
    ['HSBC BANK PLC Ho Collection Account', '430114', ''],
    ['HSBC BANK PLC Gedling Borough Council', '430149', ''],
    ['HSBC BANK PLC West Oxfordshire D.C.', '430173', ''],
    ['HSBC BANK PLC West Oxford D/C Sundries', '430181', ''],
    ['HSBC BANK PLC The Book Fashion Ltd', '430246', ''],
    ['HSBC BANK PLC Virgin Energy Ltd', '430262', ''],
    ['HSBC UK BANK PLC Inner London Magistrates', '430319', ''],
    ['HSBC BANK PLC South Wales Electricity', '430424', ''],
    ['HSBC BANK PLC Surrey County Council', '430459', ''],
    ['HSBC UK BANK PLC H/O Collections Account', '430483', ''],
    ['HSBC UK BANK PLC Avon Cosmetics Limited', '430491', ''],
    ['HSBC BANK PLC Bristol Wessex Billing Svc', '430521', ''],
    ['HSBC BANK PLC One-Tel Telecommunications', '430572', ''],
    ['HSBC BANK PLC Aais Ltd Giro Collection', '430629', ''],
    ['HSBC BANK PLC H/O Collection Account', '430653', ''],
    ['HSBC BANK PLC Virgin Media Payments Ltd', '430661', ''],
    ['HSBC BANK PLC H/O Collection Account', '430688', ''],
    ['HSBC BANK PLC H/O Collection Account', '430777', ''],
    ['HSBC UK BANK PLC Bronsgrove District Council', '430823', ''],
    ['HSBC UK BANK PLC Willow Housing and Care Ltd', '430882', ''],
    ['HSBC UK BANK PLC Home Technology Finance Ltd', '430912', ''],
    ['HSBC BANK PLC British Gas Trading Ltd', '430947', ''],
    ['HSBC BANK PLC Head Office Collection Ac', '430971', ''],
    ['HSBC BANK PLC Head Office Collection A/C', '430998', ''],
    ['HSBC BANK PLC Virgin Media Payments Ltd', '431013', ''],
    ['HSBC BANK PLC Head Office Collection Acc', '431064', ''],
    ['HSBC BANK PLC Head Office Collection Acc', '431242', ''],
    ['HSBC BANK PLC H/O Collection Account', '431285', ''],
    ['HSBC UK BANK PLC H O Collection Account', '436016', ''],
    ['HSBC UK BANK PLC H O Collection Account', '436124', ''],
    ['HSBC UK BANK PLC Club 24-Commercial', '436322', ''],
    ['HSBC BANK PLC H O Collection Account', '436567', ''],
    ['HSBC BANK PLC H/O Collection Account', '436752', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '436765', ''],
    ['HSBC BANK PLC H/O Collection Account', '436768', ''],
    ['HSBC BANK PLC Ho Collection Account', '436788', ''],
    ['HSBC UK BANK PLC Tandridge District Council', '436958', ''],
    ['HSBC BANK PLC H/O Collection Account', '437063', ''],
    ['HSBC BANK PLC Head Office  Collection A/C', '437306', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '437365', ''],
    ['HSBC BANK PLC One Tel Ltd', '437415', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '437462', ''],
    ['HSBC UK BANK PLC Clanchatton Birmingham Ltd', '437523', ''],
    ['HSBC BANK PLC The Book Fashion', '437558', ''],
    ['HSBC BANK PLC H/O Collection Account', '437566', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '437567', ''],
    ['HSBC UK BANK PLC Outstanding Services Ltd', '437608', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '437667', ''],
    ['HSBC BANK PLC H/O  Collection Account', '437705', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '437856', ''],
    ['HSBC UK BANK PLC H O Collection Account', '437868', ''],
    ['HSBC BANK PLC H/O Collection Account', '438063', ''],
    ['HSBC UK BANK PLC Ho Collection Account', '438066', ''],
    ['HSBC UK BANK PLC H/O Collection A/C', '438124', ''],
    ['HSBC UK BANK PLC West Wiltshire District Cn', '438132', ''],
    ['HSBC UK BANK PLC H O Collection A/C', '438164', ''],
    ['HSBC UK BANK PLC West Wiltshire Dist Council', '438205', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '438213', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '438264', ''],
    ['HSBC UK BANK PLC Bridgnorth Dist Council', '438302', ''],
    ['HSBC UK BANK PLC Chester Waterworks Company', '438325', ''],
    ['HSBC BANK PLC H/O Collection Account', '438507', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '438519', ''],
    ['HSBC BANK PLC H/O Collection A/C', '438551', ''],
    ['HSBC BANK PLC H/O Collection Account', '438701', ''],
    ['HSBC UK BANK PLC H O Collection Account', '438759', ''],
    ['HSBC UK BANK PLC Ho Collection Account', '438767', ''],
    ['HSBC BANK PLC Associates Capital Corp.', '438825', ''],
    ['HSBC UK BANK PLC Screwfix Direct Ltd', '438844', ''],
    ['HSBC BANK PLC H/O Collection Account', '438857', ''],
    ['HSBC BANK PLC Headoffice Collection A/C', '438906', ''],
    ['HSBC BANK PLC H O Collection A/C', '438917', ''],
    ['HSBC UK BANK PLC H/O Collection A/C', '438941', ''],
    ['HSBC BANK PLC H/O Collection Account', '439015', ''],
    ['HSBC BANK PLC H/O Collection Account', '439167', ''],
    ['HSBC BANK PLC H/O Collection Account', '439201', ''],
    ['HSBC UK BANK PLC Leeds & Holbeck B/S Albion', '439310', ''],
    ['HSBC BANK PLC H/O Collection A/C', '439317', ''],
    ['HSBC UK BANK PLC Collection Agencies plc', '439321', ''],
    ['HSBC BANK PLC H/O Collection Account', '439365', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '439368', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '439406', ''],
    ['HSBC BANK PLC H/O Collection Account', '439414', ''],
    ['HSBC BANK PLC Volkswagen Fin Ser UK Ltd', '439418', ''],
    ['HSBC BANK PLC H/O Collection A/C', '439511', ''],
    ['HSBC BANK PLC H/O Collection Account', '439624', ''],
    ['HSBC BANK PLC H/O Collection Account', '439655', ''],
    ['HSBC UK BANK PLC Next plc', '439708', ''],
    ['HSBC BANK PLC H/O Collection Account', '439727', ''],
    ['HSBC UK BANK PLC Guilford Borough Council', '439805', ''],
    ['HSBC BANK PLC H/O Collection Account', '439821', ''],
    ['HSBC UK BANK PLC Leeds Building Society', '439840', ''],
    ['HSBC BANK PLC Head Office Collections Acc', '439928', ''],
    ['HSBC BANK PLC H/O Coll Acc', '439987', ''],
    ['HSBC BANK PLC Head Office Collection A/C', '439995', ''],
    ['HSBC BANK PLC HSBC Investment Bank Ltd', '440442', ''],
    ['HSBC BANK PLC H O Collection Account', '444863', ''],
    ['HSBC BANK PLC H.O. Collection Account', '444879', ''],
    ['HSBC BANK PLC H/O Collection Account', '446062', ''],
    ['HSBC UK BANK PLC London Borough of Enfield', '446836', ''],
    ['HSBC BANK PLC Capital One Bank (Europe)', '446852', ''],
    ['HSBC BANK PLC Capital One Bank Europe plc', '446860', ''],
    ['HSBC BANK PLC Capital One Bank (Europe)', '446879', ''],
    ['HSBC BANK PLC Capital One Bank (Europe)', '446887', ''],
    ['HSBC BANK PLC Capital One Bank (Europe)', '446895', ''],
    ['HSBC BANK PLC H/O Coll A/C', '446925', ''],
    ['HSBC BANK PLC H/O Collection Account', '446933', ''],
    ['HSBC BANK PLC H/O Coll A/C', '447107', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '447131', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '447182', ''],
    ['HSBC UK BANK PLC Head Office Collection A/C', '447212', ''],
    ['HSBC UK BANK PLC Clerk To Watford Justices', '447239', ''],
    ['HSBC UK BANK PLC Clerk To The Stevenage Jus', '447247', ''],
    ['HSBC UK BANK PLC London Borough of Enfield', '447263', ''],
    ['HSBC UK BANK PLC Westfield Cont Health Sc', '447271', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '447344', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '447379', ''],
    ['HSBC UK BANK PLC H/O Collections Account', '447409', ''],
    ['HSBC BANK PLC H/O Collection Account', '447433', ''],
    ['HSBC UK BANK PLC Kent Reliance', '447484', ''],
    ['HSBC BANK PLC United Biscuits UK Ltd', '447492', ''],
    ['HSBC UK BANK PLC School Book Fairs Ltd', '447506', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '447557', ''],
    ['HSBC UK BANK PLC H O Collection Account', '447565', ''],
    ['HSBC UK BANK PLC H/O Collection Account', '447573', ''],
    ['HSBC BANK PLC H O Collection A/C', '447674', ''],
    ['HSBC BANK PLC H/O Coll Acc', '447700', ''],
    ['HSBC BANK PLC Head Office Collection A/C', '447867', ''],
    ['HSBC BANK PLC Conway Invest Properties', '447883', ''],
    ['HSBC BANK PLC Surrey County Council', '447948', ''],
    ['HSBC BANK PLC Creation Fin Services Ltd', '447964', ''],
    ['HSBC BANK PLC Carphone Warehouse Services', '447972', ''],
    ['HSBC BANK PLC H/O Collection Account', '447980', ''],
    ['HSBC UK BANK PLC London Borough of Enfield', '448022', ''],
    ['HSBC BANK PLC Talktalk Telecom Limited', '448111', ''],
    ['HSBC BANK PLC H/O Coll Acc', '448219', ''],
    ['HSBC BANK PLC Capital One Bank Europe plc', '448294', ''],
    ['HSBC UK BANK PLC London Borough of Bromley', '448308', ''],
    ['HSBC BANK PLC Cap One Bank (Europe) Ltd', '448340', ''],
    ['HSBC BANK PLC H/O Collection Account', '448391', ''],
    ['HSBC BANK PLC HSBC Bank', '448405', ''],
    ['HSBC BANK PLC Capital One Bank', '448421', ''],
    ['HSBC UK BANK PLC Capital One Mastercard Gold', '448448', ''],
    ['HSBC BANK PLC HSBC Bank', '448456', ''],
    ['HSBC BANK PLC H/O Collection Acct', '448499', ''],
    ['HSBC BANK PLC Craigforth Services Ltd', '448510', ''],
    ['DEUTSCHE BANK AG Chaps Routing', '499990', 'DEUTGB3LXXX'],
    ['DEUTSCHE BANK AG Chaps', '499999', 'DEUTGB3LRTN'],
    [
      'NATIONAL WESTMINSTER BANK PLC Liverpool St Station (B)',
      '500000',
      'NWBKGB2102M'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bethnal Green Road',
      '501005',
      'NWBKGB2127Q'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Camberwell', '501029', 'NWBKGB2146C'],
    [
      'CREDIT AGRICOLE CORPORATE & INVESTMENT BANK Credit Agricole Cib',
      '501032',
      'CRLYGB2LXXX'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Clapham Junction', '502101', 'NWBKGB2148R'],
    ['NATIONAL WESTMINSTER BANK PLC Muswell Hill (A)', '502108', 'NWBKGB2137W'],
    ['NATIONAL WESTMINSTER BANK PLC Fleet Street', '502116', 'NWBKGB2128H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Swiss Cottage (A)',
      '503003',
      'NWBKGB2140E'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Hendon, Central Circus (A)',
      '503005',
      'NWBKGB2140J'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Islington Angel (A)',
      '503009',
      'NWBKGB2137L'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Kensington,Melbury Court',
      '503010',
      'NWBKGB2153R'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Liverpool Street Station',
      '503020',
      'NWBKGB2128C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Marylebone & Harley Street',
      '503025',
      'NWBKGB2152R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Mettle', '503125', 'NWBKGB2160L'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bm Support, Brighton (Nwb)',
      '503180',
      'NWBKGB2159J'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Menai Amc', '503231', ''],
    ['NATIONAL WESTMINSTER BANK PLC Clc Manchester', '503240', ''],
    ['NATIONAL WESTMINSTER BANK PLC Birmingham Collections Cntr', '503242', ''],
    ['NATIONAL WESTMINSTER BANK PLC Clc Bristol', '503274', ''],
    ['NATIONAL WESTMINSTER BANK PLC Nottingham Collections Ctr', '503464', ''],
    ['NATIONAL WESTMINSTER BANK PLC Pb Direct Legacy (Crawley)', '503539', ''],
    ['NATIONAL WESTMINSTER BANK PLC N W Copr Banking Office', '503567', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cpb Innovation', '503568', 'NWBKGB2156B'],
    ['NATIONAL WESTMINSTER BANK PLC Staff Loans-Legacy', '504000', ''],
    ['NATIONAL WESTMINSTER BANK PLC Carpl-Legacy', '504001', ''],
    ['NATIONAL WESTMINSTER BANK PLC Personal Loans Sec Legacy', '504002', ''],
    ['NATIONAL WESTMINSTER BANK PLC Personal Unsec Legacy 1', '504003', ''],
    ['NATIONAL WESTMINSTER BANK PLC Personal Unsec-Legacy 3', '504004', ''],
    ['NATIONAL WESTMINSTER BANK PLC Personal Unsec-Legacy 3', '504005', ''],
    ['NATIONAL WESTMINSTER BANK PLC Personal Unsec -Legacy 4', '504006', ''],
    ['NATIONAL WESTMINSTER BANK PLC Business Sec-Legacy 1', '504007', ''],
    ['NATIONAL WESTMINSTER BANK PLC Business Unsec Legacy 1', '504008', ''],
    ['NATIONAL WESTMINSTER BANK PLC Business Sec-Legacy 2', '504009', ''],
    ['NATIONAL WESTMINSTER BANK PLC Personal Unsec-Legacy 5', '504010', ''],
    ['NATIONAL WESTMINSTER BANK PLC Business Unsec-Legacy 2', '504011', ''],
    ['NATIONAL WESTMINSTER BANK PLC Personal Unsec-Legacy 6', '504012', ''],
    ['NATIONAL WESTMINSTER BANK PLC Personal Unsec-Legacy 7', '504013', ''],
    ['NATIONAL WESTMINSTER BANK PLC Unallocated Loans Legacy 1', '504014', ''],
    ['NATIONAL WESTMINSTER BANK PLC Unallocated Loans-Legacy 2', '504015', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Bus Loans A (R)', '504020', ''],
    ['NATIONAL WESTMINSTER BANK PLC Bo', '504023', 'NWBKGB2160H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Natwest Merchant Acquiring',
      '504024',
      'NWBKGB2160J'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Nwb Divest Branch 2 (Mo A)',
      '504027',
      'NWBKGB2159C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Personal Loans', '504030', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Car Loans', '504031', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Home Improve Loans', '504032', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Business Loans', '504033', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Pers Loans Telemktg', '504034', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Car Loan T/Marketing', '504035', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Home Imp Loans', '504036', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Business Loans', '504037', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Sales on Line Loans', '504038', ''],
    ['NATIONAL WESTMINSTER BANK PLC Natwest Direct Loans', '504039', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Pers Loans A', '504040', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Car Loans A', '504041', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Home Imp Ln A', '504042', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Bus Loans A', '504043', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Pers Loans Tele A', '504044', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Ln Teleph Cao', '504045', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Car Loans Telemrkt', '504046', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Home Imp Ln Telem A', '504048', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Home Imp Ln Tm Cao', '504049', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Pers Loans A (R)', '504050', ''],
    ['NATIONAL WESTMINSTER BANK PLC Nwb Direct Loans Cao (R)', '504051', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Sol A', '504052', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Nwb Home Imp Ln (R)', '504053', ''],
    ['NATIONAL WESTMINSTER BANK PLC Natwest Direct Loans A', '504054', ''],
    ['NATIONAL WESTMINSTER BANK PLC Natwest Direct Loans Cao', '504055', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Gt Tower St Corp Ser Ct (R)',
      '504062',
      'NWBKGB2158W'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Nwb Divest Branch (Rf)', '504070', ''],
    ['NATIONAL WESTMINSTER BANK PLC Natwest E-Savings Acc (Rf)', '504073', ''],
    ['NATIONAL WESTMINSTER BANK PLC Natwest Direct Loans', '504099', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Piccadily & New Bond Street',
      '504101',
      'NWBKGB2152V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Fleet Street (A)', '504102', 'NWBKGB2128J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Piccadilly & New Bond St(B)',
      '504106',
      'NWBKGB2153D'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Park Royal', '504110', 'NWBKGB2140B'],
    ['NATIONAL WESTMINSTER BANK PLC Park Royal (A)', '504120', 'NWBKGB2153L'],
    ['NATIONAL WESTMINSTER BANK PLC Solent Customer Telephony', '504204', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Wavertree Telephony Centre',
      '504206',
      'NWBKGB2160C'
    ],
    ["NATIONAL WESTMINSTER BANK PLC B'ham Customer Telephony C", '504207', ''],
    ['NATIONAL WESTMINSTER BANK PLC Chatham Csc', '504208', 'NWBKGB2158L'],
    ['NATIONAL WESTMINSTER BANK PLC Chatham RPO', '504209', ''],
    ['NATIONAL WESTMINSTER BANK PLC Leeds Account Mgmt Centre', '504210', ''],
    ['NATIONAL WESTMINSTER BANK PLC Leicester Amc', '504211', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Leicester Customer Tel Cen',
      '504212',
      'NWBKGB2160B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Birmingham Csc', '504213', ''],
    ['NATIONAL WESTMINSTER BANK PLC Birmingham (Stone) Act Mgt', '504214', ''],
    ['NATIONAL WESTMINSTER BANK PLC Chatham Customer Tele Ctr', '504217', ''],
    ['NATIONAL WESTMINSTER BANK PLC Bolton Csc 3', '504219', 'NWBKGB2160D'],
    ['NATIONAL WESTMINSTER BANK PLC Bradford Ctc', '504221', ''],
    ['NATIONAL WESTMINSTER BANK PLC Chatham Amc', '504224', ''],
    ['NATIONAL WESTMINSTER BANK PLC Swrcsc (B/Nw)', '504225', ''],
    ['NATIONAL WESTMINSTER BANK PLC Fraud Operations', '504226', 'NWBKGB2160K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bolton Custome Service Cntr',
      '504228',
      'NWBKGB2160E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Internet Branch,R-Save', '504231', ''],
    ['NATIONAL WESTMINSTER BANK PLC Internet Branch,E-Savings', '504234', ''],
    ['NATIONAL WESTMINSTER BANK PLC Internet Branch,E-Savings', '504236', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Natwest E-Savings',
      '504237',
      'NWBKGB2159M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Plymouth Telephone Centre', '504247', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC North of England Csc',
      '504248',
      'NWBKGB2160F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Glasgow Csc', '504252', ''],
    ['NATIONAL WESTMINSTER BANK PLC Manchester Cust Tel Cntr', '504255', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Scotland Corporate Svc Ctr',
      '504256',
      'NWBKGB2157U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Clearing Control Unit',
      '514200',
      'NWBKGB21CCU'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Southgate', '515000', 'NWBKGB2137R'],
    [
      'NATIONAL WESTMINSTER BANK PLC South Kensington Station',
      '515001',
      'NWBKGB2149N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Addiscombe (A)', '515002', 'NWBKGB2145Y'],
    ['NATIONAL WESTMINSTER BANK PLC London Bridge', '515003', 'NWBKGB2145S'],
    ['NATIONAL WESTMINSTER BANK PLC Swiss Cottage', '515010', 'NWBKGB2140G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Golders Green (C)',
      '515011',
      'NWBKGB2140C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Victoria', '515014', 'NWBKGB2149B'],
    [
      'NATIONAL WESTMINSTER BANK PLC London Bridge (B)',
      '515031',
      'NWBKGB2159Q'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Centralised Unpaids Unit', '515049', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Abergavenny Monmouth & Dist',
      '516102',
      'NWBKGB2109T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Aberystwyth', '516106', 'NWBKGB2149Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Winchester Old Bank (A)',
      '516109',
      'NWBKGB2147S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Altrincham', '516111', 'NWBKGB2104T'],
    ['NATIONAL WESTMINSTER BANK PLC Llangefni (B)', '516114', 'NWBKGB2134W'],
    ['NATIONAL WESTMINSTER BANK PLC Llanelli (A)', '516115', 'NWBKGB2145A'],
    ['NATIONAL WESTMINSTER BANK PLC Newton Abbot (A)', '516118', 'NWBKGB2151D'],
    ['NATIONAL WESTMINSTER BANK PLC Honiton (B)', '516123', 'NWBKGB2128V'],
    [
      'NATIONAL WESTMINSTER BANK PLC Solihull High St(C)',
      '516126',
      'NWBKGB2125G'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bangor', '516128', 'NWBKGB2134T'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bishop Auckland (A)',
      '516133',
      'NWBKGB2126K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Barnet', '516134', 'NWBKGB2128N'],
    ['NATIONAL WESTMINSTER BANK PLC Barnsley', '516135', 'NWBKGB2106C'],
    ['NATIONAL WESTMINSTER BANK PLC Barry Town Hall', '516140', 'NWBKGB2123G'],
    ['NATIONAL WESTMINSTER BANK PLC Lowestoft (A)', '517002', 'NWBKGB2139X'],
    ['NATIONAL WESTMINSTER BANK PLC Beeston', '517006', 'NWBKGB2115Q'],
    ['NATIONAL WESTMINSTER BANK PLC Thornbury (A)', '517008', 'NWBKGB2129U'],
    ['NATIONAL WESTMINSTER BANK PLC Bexhill-on-Sea', '517012', 'NWBKGB2109P'],
    ['NATIONAL WESTMINSTER BANK PLC Bexleyheath', '517014', 'NWBKGB2108T'],
    ['NATIONAL WESTMINSTER BANK PLC Bicester', '517015', 'NWBKGB2107Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Barnstaple High St (A)',
      '517016',
      'NWBKGB2109W'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Stockton-on-Tees (A)',
      '517017',
      'NWBKGB2151Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Keighley (A)', '517019', 'NWBKGB2111U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Harborne, Birmingham',
      '517032',
      'NWBKGB2117G'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bishop Auckland', '517043', 'NWBKGB2126L'],
    ['NATIONAL WESTMINSTER BANK PLC Maidstone (A)', '517055', 'NWBKGB2136V'],
    ['NATIONAL WESTMINSTER BANK PLC Beckton', '517057', 'NWBKGB2127U'],
    ['NATIONAL WESTMINSTER BANK PLC Blandford Forum', '518101', 'NWBKGB2115W'],
    ['NATIONAL WESTMINSTER BANK PLC West Malling (A)', '518107', 'NWBKGB2150S'],
    ['NATIONAL WESTMINSTER BANK PLC Boston', '518108', 'NWBKGB2107M'],
    ['NATIONAL WESTMINSTER BANK PLC Spalding (A)', '518110', 'NWBKGB2141F'],
    ['NATIONAL WESTMINSTER BANK PLC Bournemouth (A)', '518118', 'NWBKGB2105U'],
    ['NATIONAL WESTMINSTER BANK PLC Bournemouth (B)', '518120', 'NWBKGB2119J'],
    ['NATIONAL WESTMINSTER BANK PLC Newton Abbot (A)', '518121', 'NWBKGB2151F'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bracknell, High Street',
      '518122',
      'NWBKGB2142Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Brecon', '518127', 'NWBKGB2109A'],
    ['NATIONAL WESTMINSTER BANK PLC Bridgend', '518129', 'NWBKGB2108Q'],
    ['NATIONAL WESTMINSTER BANK PLC Scunthorpe(B)', '518134', 'NWBKGB2115L'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bournemouth Hants Centre',
      '518141',
      'NWBKGB2119H'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Ashton Gate, Bristol',
      '521000',
      'NWBKGB2121V'
    ],
    [
      "NATIONAL WESTMINSTER BANK PLC Bristol, Queen's Road (A)",
      '521003',
      'NWBKGB2121L'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Filton Bristol', '521005', 'NWBKGB2121E'],
    ['NATIONAL WESTMINSTER BANK PLC Bishopston (A)', '521007', 'NWBKGB2121R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bristol City Office (A)',
      '521014',
      'NWBKGB2121X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Paignton (A)', '521018', 'NWBKGB2151K'],
    ['NATIONAL WESTMINSTER BANK PLC Ramsgate (A)', '521019', 'NWBKGB2124M'],
    ['NATIONAL WESTMINSTER BANK PLC Broadstone', '521020', 'NWBKGB2119B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Worcester Cross (C)',
      '521023',
      'NWBKGB2131Q'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Exmouth (A)', '521026', 'NWBKGB2129K'],
    ['NATIONAL WESTMINSTER BANK PLC Brecon (A)', '521027', 'NWBKGB2133Y'],
    ['NATIONAL WESTMINSTER BANK PLC Lowestoft (B)', '521028', 'NWBKGB2139Y'],
    ['NATIONAL WESTMINSTER BANK PLC Wickford (B)', '521030', 'NWBKGB2123Q'],
    ['NATIONAL WESTMINSTER BANK PLC Edgware (A)', '521033', 'NWBKGB2130Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Burton upon Trent',
      '521035',
      'NWBKGB2106R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bangor (B)', '521039', 'NWBKGB2134S'],
    ['NATIONAL WESTMINSTER BANK PLC Caerphilly', '521040', 'NWBKGB2123M'],
    ['NATIONAL WESTMINSTER BANK PLC Launceston(D)', '521042', 'NWBKGB2141Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC NW Cambridge Petty Cury',
      '521046',
      'NWBKGB2122M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Launceston (A)', '521047', 'NWBKGB2142D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Kingswood Bristol (A)',
      '521048',
      'NWBKGB2121U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Yate (A)', '521051', 'NWBKGB2121K'],
    ['NATIONAL WESTMINSTER BANK PLC Cannock', '522100', 'NWBKGB2116G'],
    ['NATIONAL WESTMINSTER BANK PLC Canton Cardiff', '522103', 'NWBKGB2123F'],
    [
      'NATIONAL WESTMINSTER BANK PLC Cardiff, Queen Street',
      '522106',
      'NWBKGB2122S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Roath Cardiff', '522107', 'NWBKGB2122X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Cardiff, Queen Street (B)',
      '522108',
      'NWBKGB2122W'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Canton, Cardiff (A)',
      '522110',
      'NWBKGB2122V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Carmarthen (A)', '522111', 'NWBKGB2145H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Tavistock  (Devon) (B)',
      '522116',
      'NWBKGB2151E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Eastleigh (A)', '522118', 'NWBKGB2147Y'],
    ['NATIONAL WESTMINSTER BANK PLC Thornbury (B)', '522126', 'NWBKGB2114J'],
    ['NATIONAL WESTMINSTER BANK PLC Amersham (A)', '522127', 'NWBKGB2131K'],
    ['NATIONAL WESTMINSTER BANK PLC Chippenham', '522130', 'NWBKGB2106Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC Banbury Branch (A)',
      '522131',
      'NWBKGB2141C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Yate', '522132', 'NWBKGB2120X'],
    ['NATIONAL WESTMINSTER BANK PLC Christchurch', '522134', 'NWBKGB2105Y'],
    ['NATIONAL WESTMINSTER BANK PLC Cleethorpes', '522138', 'NWBKGB2126S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Clevedon, The Triangle',
      '522139',
      'NWBKGB2121B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Colwyn Bay', '522143', 'NWBKGB2134K'],
    ['NATIONAL WESTMINSTER BANK PLC Llandudno (B)', '522149', 'NWBKGB2134M'],
    ['NATIONAL WESTMINSTER BANK PLC Mold (C)', '522151', 'NWBKGB2134H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Willerby Kirk Ella (A)',
      '522152',
      'NWBKGB2131V'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Llanishen Cardiff',
      '522163',
      'NWBKGB2123D'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Coventry, Walsgrave Road',
      '523002',
      'NWBKGB2124Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bridgend (D)', '523003', 'NWBKGB2123J'],
    ['NATIONAL WESTMINSTER BANK PLC Newport Iow (A)', '523004', 'NWBKGB2142K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Exeter St Thomas (C)',
      '523006',
      'NWBKGB2128Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Cross Gates', '523011', 'NWBKGB2135C'],
    ['NATIONAL WESTMINSTER BANK PLC Tiverton (A)', '523017', 'NWBKGB2128X'],
    ['NATIONAL WESTMINSTER BANK PLC Darlington', '523018', 'NWBKGB2108W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Paignton Branch (D)',
      '523020',
      'NWBKGB2151L'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Rugby (B)', '523021', 'NWBKGB2107H'],
    ['NATIONAL WESTMINSTER BANK PLC Newton Abbot (D)', '523022', 'NWBKGB2129H'],
    ['NATIONAL WESTMINSTER BANK PLC Deal', '523023', 'NWBKGB2109K'],
    ['NATIONAL WESTMINSTER BANK PLC Mold (D)', '523025', 'NWBKGB2134D'],
    ['NATIONAL WESTMINSTER BANK PLC Devizes', '523027', 'NWBKGB2116H'],
    ['NATIONAL WESTMINSTER BANK PLC Dewsbury Csb', '523029', 'NWBKGB2107Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bury St Edmunds (B)',
      '523031',
      'NWBKGB2132M'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Aberystwyth Branch (A)',
      '523032',
      'NWBKGB2134P'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Worcester Cross (D)',
      '523040',
      'NWBKGB2154V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Minehead (A)', '523042', 'NWBKGB2150K'],
    ['NATIONAL WESTMINSTER BANK PLC Durham', '523044', 'NWBKGB2107J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Wickersley Branch (A)',
      '523050',
      'NWBKGB2144K'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Cwmbran & District',
      '523056',
      'NWBKGB2139H'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Eastbourne, Town Centre',
      '524100',
      'NWBKGB2109V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Eastleigh', '524107', 'NWBKGB2114S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Heads of the Valleys (B)',
      '524108',
      'NWBKGB2139G'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Stafford, Greengate (A)',
      '524110',
      'NWBKGB2148M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Oxted (A)', '524112', 'NWBKGB2151A'],
    ['NATIONAL WESTMINSTER BANK PLC Oswestry (A)', '524116', 'NWBKGB2149T'],
    ['NATIONAL WESTMINSTER BANK PLC Ely', '524119', 'NWBKGB2122P'],
    ['NATIONAL WESTMINSTER BANK PLC Havant (C)', '524120', 'NWBKGB2142F'],
    ['NATIONAL WESTMINSTER BANK PLC Exeter St Thomas', '524127', 'NWBKGB2129F'],
    ['NATIONAL WESTMINSTER BANK PLC Fakenham', '524129', 'NWBKGB2139P'],
    ['NATIONAL WESTMINSTER BANK PLC Falmouth', '524131', 'NWBKGB2115D'],
    ['NATIONAL WESTMINSTER BANK PLC Fareham', '524132', 'NWBKGB2106U'],
    ['NATIONAL WESTMINSTER BANK PLC Ringwood (A)', '524137', 'NWBKGB2116X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Durham Branch (A)',
      '524138',
      'NWBKGB2126M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Folkestone', '524142', 'NWBKGB2106G'],
    ['NATIONAL WESTMINSTER BANK PLC Scunthorpe(A)', '524146', 'NWBKGB2116L'],
    [
      'NATIONAL WESTMINSTER BANK PLC Farnborough North (A)',
      '524156',
      'NWBKGB2130F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Exeter, St. Thomas (B)',
      '524160',
      'NWBKGB2128S'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Great Yarmouth (A)',
      '535003',
      'NWBKGB2139V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Gosport', '535005', 'NWBKGB2142H'],
    ['NATIONAL WESTMINSTER BANK PLC Grimsby', '535010', 'NWBKGB2126T'],
    ['NATIONAL WESTMINSTER BANK PLC Middlesbrough(A)', '535015', 'NWBKGB2151W'],
    ['NATIONAL WESTMINSTER BANK PLC Harrogate', '535021', 'NWBKGB2104J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Tavistock (Devon) (C)',
      '535028',
      'NWBKGB2129A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Haverfordwest', '535030', 'NWBKGB2114E'],
    ['NATIONAL WESTMINSTER BANK PLC Brecon (B)', '535031', 'NWBKGB2131N'],
    ['NATIONAL WESTMINSTER BANK PLC Havant (B)', '535033', 'NWBKGB2142G'],
    ['NATIONAL WESTMINSTER BANK PLC Hedge End', '535036', 'NWBKGB2147T'],
    ['NATIONAL WESTMINSTER BANK PLC Falmouth (A)', '535037', 'NWBKGB2151P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Horsham Carfax (B)',
      '535039',
      'NWBKGB2120M'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Hereford, 12 Broad Street',
      '535041',
      'NWBKGB2104Y'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Newcastle City (D)',
      '535046',
      'NWBKGB2138K'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Wisbech, Market Place (A)',
      '535051',
      'NWBKGB2141H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Launceston(B)', '535052', 'NWBKGB2129B'],
    ['NATIONAL WESTMINSTER BANK PLC Llangefni (A)', '535053', 'NWBKGB2134V'],
    ['NATIONAL WESTMINSTER BANK PLC Mold (A)', '535054', 'NWBKGB2123P'],
    ['NATIONAL WESTMINSTER BANK PLC Honiton', '535055', 'NWBKGB2128W'],
    ['NATIONAL WESTMINSTER BANK PLC Beverley (C)', '535057', 'NWBKGB2131X'],
    ['NATIONAL WESTMINSTER BANK PLC Havant (A)', '535061', 'NWBKGB2142Q'],
    ['NATIONAL WESTMINSTER BANK PLC Cromer (B)', '535073', 'NWBKGB2139Q'],
    ['NATIONAL WESTMINSTER BANK PLC Hove Town Hall', '536102', 'NWBKGB2120J'],
    ['NATIONAL WESTMINSTER BANK PLC Barnsley (A)', '536104', 'NWBKGB2144U'],
    ['NATIONAL WESTMINSTER BANK PLC Huddersfield', '536107', 'NWBKGB2113U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Hull City Centre(A)',
      '536111',
      'NWBKGB2131Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Hull University', '536113', 'NWBKGB2131Z'],
    ["NATIONAL WESTMINSTER BANK PLC King's Lynn(A)", '536115', 'NWBKGB2141S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Barnstaple High St (B)',
      '536121',
      'NWBKGB2129D'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ilkley', '536123', 'NWBKGB2135J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Ipswich, Tavern Street',
      '536124',
      'NWBKGB2113G'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Keighley', '536130', 'NWBKGB2108G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Royal Leamington Spa (B)',
      '536131',
      'NWBKGB2125H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Kettering', '536133', 'NWBKGB2106W'],
    ['NATIONAL WESTMINSTER BANK PLC Paignton (C)', '536137', 'NWBKGB2151M'],
    [
      'NATIONAL WESTMINSTER BANK PLC Kings Lynn, Hardwick Road',
      '536138',
      'NWBKGB2115Z'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Solihull High St(B)',
      '536141',
      'NWBKGB2118C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Carmarthen (D)', '536142', 'NWBKGB2145K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Shoreham-by-Sea (A)',
      '536143',
      'NWBKGB2119Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Launceston', '536144', 'NWBKGB2115P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Hereford Broad St (A)',
      '536147',
      'NWBKGB2131R'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Holderness Road   Hull',
      '536154',
      'NWBKGB2132A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Larkfield', '536155', 'NWBKGB2136Z'],
    ['NATIONAL WESTMINSTER BANK PLC Grimsby (A)', '536156', 'NWBKGB2126U'],
    ['NATIONAL WESTMINSTER BANK PLC Plympton (A)', '536157', 'NWBKGB2142A'],
    ['NATIONAL WESTMINSTER BANK PLC Slough (A)', '536158', 'NWBKGB2146Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Leeds, City Office (A)',
      '537000',
      'NWBKGB2135D'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Leighton Buzzard', '537011', 'NWBKGB2108B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Hereford, Broad Street (B)',
      '537012',
      'NWBKGB2131P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Lichfield', '537015', 'NWBKGB2154W'],
    ['NATIONAL WESTMINSTER BANK PLC Launceston (C)', '537017', 'NWBKGB2141X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Aintree Black Bull',
      '537021',
      'NWBKGB2115U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Canton Cardiff (B)',
      '537030',
      'NWBKGB2122U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Carmarthen (G)', '537031', 'NWBKGB2145B'],
    ['NATIONAL WESTMINSTER BANK PLC Llanelli (B)', '537032', 'NWBKGB2145D'],
    ['NATIONAL WESTMINSTER BANK PLC Llandudno', '537033', 'NWBKGB2134L'],
    ['NATIONAL WESTMINSTER BANK PLC Carmarthen (B)', '537036', 'NWBKGB2145J'],
    ['NATIONAL WESTMINSTER BANK PLC Llanelli', '537037', 'NWBKGB2144Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Liverpool, City Office (A)',
      '537044',
      'NWBKGB2114N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Newtown (B)', '538100', 'NWBKGB2149X'],
    ['NATIONAL WESTMINSTER BANK PLC Llangefni', '538102', 'NWBKGB2134X'],
    ['NATIONAL WESTMINSTER BANK PLC Wrexham (A)', '538103', 'NWBKGB2134G'],
    ['NATIONAL WESTMINSTER BANK PLC Llandudno (A)', '538105', 'NWBKGB2134J'],
    ['NATIONAL WESTMINSTER BANK PLC Carmarthen (E)', '538107', 'NWBKGB2145G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Wisbech Market Place (B)',
      '538111',
      'NWBKGB2141J'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Louth', '538115', 'NWBKGB2134Y'],
    ['NATIONAL WESTMINSTER BANK PLC Lowestoft', '538116', 'NWBKGB2139W'],
    ['NATIONAL WESTMINSTER BANK PLC Ludlow', '538118', 'NWBKGB2150C'],
    ['NATIONAL WESTMINSTER BANK PLC Gloucester(A)', '538121', 'NWBKGB2129V'],
    ['NATIONAL WESTMINSTER BANK PLC Lymington', '538122', 'NWBKGB2147V'],
    [
      'NATIONAL WESTMINSTER BANK PLC Shirley Sthampton (B)',
      '538123',
      'NWBKGB2147W'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Aberystwyth Branch (B)',
      '538126',
      'NWBKGB2149W'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bridgend(B)', '538127', 'NWBKGB2122Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Worcester Cross (B)',
      '538133',
      'NWBKGB2115J'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Cheetham Hill Manchester',
      '538136',
      'NWBKGB2136Q'
    ],
    ['NATIONAL WESTMINSTER BANK PLC March', '538142', 'NWBKGB2141L'],
    [
      'NATIONAL WESTMINSTER BANK PLC Market Harborough',
      '538146',
      'NWBKGB2115R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Louth (A)', '538147', 'NWBKGB2135A'],
    [
      'NATIONAL WESTMINSTER BANK PLC Maidstone, Sutton Road',
      '538151',
      'NWBKGB2136W'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bangor (A)', '541001', 'NWBKGB2134U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Heads of the Valley',
      '541002',
      'NWBKGB2115Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Mexborough', '541003', 'NWBKGB2144Q'],
    ['NATIONAL WESTMINSTER BANK PLC Middlesbrough', '541004', 'NWBKGB2104P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Haverfordwest (C)',
      '541007',
      'NWBKGB2145R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Mold', '541010', 'NWBKGB2115V'],
    ['NATIONAL WESTMINSTER BANK PLC Nantwich', '541017', 'NWBKGB2125M'],
    [
      'NATIONAL WESTMINSTER BANK PLC Haverfordwest (D)',
      '541018',
      'NWBKGB2145M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Neath', '541019', 'NWBKGB2144X'],
    ['NATIONAL WESTMINSTER BANK PLC Newark', '541023', 'NWBKGB2107Y'],
    ['NATIONAL WESTMINSTER BANK PLC Carmarthen (C)', '541026', 'NWBKGB2145F'],
    [
      'NATIONAL WESTMINSTER BANK PLC Newcastle Staffs High St',
      '541027',
      'NWBKGB2107A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Newcastle City', '541031', 'NWBKGB2138P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Newport, Isle of Wight',
      '541034',
      'NWBKGB2142L'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Telford Town Cntr (A)',
      '541036',
      'NWBKGB2150N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Truro (A)', '541038', 'NWBKGB2115H'],
    ['NATIONAL WESTMINSTER BANK PLC Newton Abbot', '541039', 'NWBKGB2151C'],
    ['NATIONAL WESTMINSTER BANK PLC Newtown', '541040', 'NWBKGB2149V'],
    ['NATIONAL WESTMINSTER BANK PLC Northallerton', '541041', 'NWBKGB2126P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Northampton Drapery (B)',
      '541044',
      'NWBKGB2116Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Morpeth', '541045', 'NWBKGB2138M'],
    [
      'NATIONAL WESTMINSTER BANK PLC Northampton the Drapery (A)',
      '541053',
      'NWBKGB2139E'
    ],
    [
      "NATIONAL WESTMINSTER BANK PLC Derby St Peter's Street (F)",
      '541056',
      'NWBKGB2125W'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Denton Pk Newcastle U Tyne',
      '541058',
      'NWBKGB2138L'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bishop Auckland (B)',
      '541059',
      'NWBKGB2126N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Newcastle City (C)',
      '542100',
      'NWBKGB2138F'
    ],
    [
      "NATIONAL WESTMINSTER BANK PLC Norwich, Gentleman's Wlk(B)",
      '542106',
      'NWBKGB2139K'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Basford, Nottingham',
      '542107',
      'NWBKGB2109X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Nuneaton', '542113', 'NWBKGB2124W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Tavistock  (Devon) (A)',
      '542114',
      'NWBKGB2128Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Chiswick (B)', '542118', 'NWBKGB2152G'],
    ['NATIONAL WESTMINSTER BANK PLC Ilkley (A)', '542120', 'NWBKGB2135F'],
    ['NATIONAL WESTMINSTER BANK PLC Honiton (A)', '542121', 'NWBKGB2128T'],
    ['NATIONAL WESTMINSTER BANK PLC Corby (A)', '542122', 'NWBKGB2141V'],
    ['NATIONAL WESTMINSTER BANK PLC Oxford, Central', '542123', 'NWBKGB2140T'],
    ['NATIONAL WESTMINSTER BANK PLC Paddock Wood', '542125', 'NWBKGB2150R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Reading Oracle (A)',
      '542128',
      'NWBKGB2143G'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Upper Parkstone', '542130', 'NWBKGB2118Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Haverfordwest (A)',
      '542131',
      'NWBKGB2145Q'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Penarth', '542133', 'NWBKGB2123H'],
    ['NATIONAL WESTMINSTER BANK PLC Barnsley (C)', '542134', 'NWBKGB2144J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Heads of the Valleys (A)',
      '542136',
      'NWBKGB2123E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Peterborough', '542138', 'NWBKGB2113C'],
    [
      'NATIONAL WESTMINSTER BANK PLC Harrow Town Centre (B)',
      '542141',
      'NWBKGB2130V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC West Bridgford', '542147', 'NWBKGB2107R'],
    ['NATIONAL WESTMINSTER BANK PLC Oadby', '542150', 'NWBKGB2115X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Arnold,134 Front Street',
      '542151',
      'NWBKGB2109J'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Hartlepool (A)', '542154', 'NWBKGB2148B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Greengates, Bradford (A)',
      '542160',
      'NWBKGB2135E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Pontypridd', '543001', 'NWBKGB2123C'],
    [
      'NATIONAL WESTMINSTER BANK PLC Poole 151 High Street',
      '543003',
      'NWBKGB2119A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Fareham (A)', '543004', 'NWBKGB2142J'],
    ['NATIONAL WESTMINSTER BANK PLC Bridgend(A)', '543005', 'NWBKGB2123B'],
    ['NATIONAL WESTMINSTER BANK PLC Portishead', '543006', 'NWBKGB2121N'],
    ['NATIONAL WESTMINSTER BANK PLC Pwllheli (A)', '543007', 'NWBKGB2134Q'],
    ['NATIONAL WESTMINSTER BANK PLC Cosham', '543011', 'NWBKGB2104W'],
    ['NATIONAL WESTMINSTER BANK PLC Port Talbot', '543016', 'NWBKGB2144Y'],
    ['NATIONAL WESTMINSTER BANK PLC Pwllheli', '543021', 'NWBKGB2134R'],
    ['NATIONAL WESTMINSTER BANK PLC Deeside', '543023', 'NWBKGB2110D'],
    ['NATIONAL WESTMINSTER BANK PLC Huntingdon (A)', '543027', 'NWBKGB2141M'],
    ['NATIONAL WESTMINSTER BANK PLC Woodley Reading', '543033', 'NWBKGB2143E'],
    ['NATIONAL WESTMINSTER BANK PLC Redcar', '543034', 'NWBKGB2151U'],
    ['NATIONAL WESTMINSTER BANK PLC Redditch', '543035', 'NWBKGB2105W'],
    ['NATIONAL WESTMINSTER BANK PLC Redhill', '543036', 'NWBKGB2143J'],
    ['NATIONAL WESTMINSTER BANK PLC Camborne (A)', '543037', 'NWBKGB2151R'],
    ['NATIONAL WESTMINSTER BANK PLC Darlington (A)', '543043', 'NWBKGB2126J'],
    ['NATIONAL WESTMINSTER BANK PLC Ringwood', '543045', 'NWBKGB2114T'],
    ['NATIONAL WESTMINSTER BANK PLC Harrogate (B)', '543046', 'NWBKGB2131B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Hereford Broad St (C)',
      '543051',
      'NWBKGB2131S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Rayleigh', '543060', 'NWBKGB2148F'],
    ['NATIONAL WESTMINSTER BANK PLC Pontefract', '543064', 'NWBKGB2154N'],
    ['NATIONAL WESTMINSTER BANK PLC Rugby', '544100', 'NWBKGB2124Y'],
    ['NATIONAL WESTMINSTER BANK PLC Cannock (A)', '544102', 'NWBKGB2154X'],
    ['NATIONAL WESTMINSTER BANK PLC Rushden', '544105', 'NWBKGB2139C'],
    ['NATIONAL WESTMINSTER BANK PLC Mold (B)', '544106', 'NWBKGB2134C'],
    [
      'NATIONAL WESTMINSTER BANK PLC Ryde Isle of Wight',
      '544107',
      'NWBKGB2142M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Rhyl (A)', '544111', 'NWBKGB2134E'],
    ['NATIONAL WESTMINSTER BANK PLC St Austell', '544112', 'NWBKGB2116P'],
    ['NATIONAL WESTMINSTER BANK PLC Penzance (A)', '544116', 'NWBKGB2151T'],
    ['NATIONAL WESTMINSTER BANK PLC Salisbury', '544119', 'NWBKGB2106V'],
    ['NATIONAL WESTMINSTER BANK PLC Scarborough', '544124', 'NWBKGB2155E'],
    ['NATIONAL WESTMINSTER BANK PLC Scunthorpe Ashby', '544126', 'NWBKGB2106Z'],
    ['NATIONAL WESTMINSTER BANK PLC Goole (A)', '544128', 'NWBKGB2155N'],
    ['NATIONAL WESTMINSTER BANK PLC Shaftesbury', '544130', 'NWBKGB2147Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Newport, Isle of Wight (B)',
      '544131',
      'NWBKGB2142N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffld City Cntr (F)',
      '544134',
      'NWBKGB2144E'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffld City Cntr (B)',
      '544137',
      'NWBKGB2144M'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffld City Cntr (E)',
      '544139',
      'NWBKGB2144G'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffield,669 Ecclesall Rd',
      '544144',
      'NWBKGB2144F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffld City Cntr (C)',
      '544147',
      'NWBKGB2144T'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffield City Centre (A)',
      '544148',
      'NWBKGB2144C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffld City Cntr (D)',
      '544150',
      'NWBKGB2144V'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Walton on Thames (B)',
      '544151',
      'NWBKGB2114H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Rustington', '544154', 'NWBKGB2120A'],
    ['NATIONAL WESTMINSTER BANK PLC Honiton (E)', '544166', 'NWBKGB2128U'],
    ['NATIONAL WESTMINSTER BANK PLC Cromer (A)', '544167', 'NWBKGB2139R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Shrewsbury Mardol Head',
      '555005',
      'NWBKGB2105K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Honiton (D)', '555006', 'NWBKGB2115E'],
    ['NATIONAL WESTMINSTER BANK PLC Skegness', '555009', 'NWBKGB2141R'],
    ['NATIONAL WESTMINSTER BANK PLC Skipton', '555010', 'NWBKGB2118W'],
    ['NATIONAL WESTMINSTER BANK PLC Grantham (A)', '555011', 'NWBKGB2138Z'],
    ['NATIONAL WESTMINSTER BANK PLC Smethwick', '555014', 'NWBKGB2117U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Solihull, High Street',
      '555015',
      'NWBKGB2118B'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Southampton, High Street(C)',
      '555021',
      'NWBKGB2147L'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Shirley, Southampton',
      '555023',
      'NWBKGB2147M'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bitterne, Southampton (A)',
      '555026',
      'NWBKGB2147R'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Southend-on-Sea Network',
      '555028',
      'NWBKGB2148C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Barnstaple High St (C)',
      '555029',
      'NWBKGB2129E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC South Shields', '555034', 'NWBKGB2138H'],
    ['NATIONAL WESTMINSTER BANK PLC Spalding', '555036', 'NWBKGB2141G'],
    ['NATIONAL WESTMINSTER BANK PLC Staines', '555039', 'NWBKGB2104K'],
    ['NATIONAL WESTMINSTER BANK PLC Peterborough (B)', '555042', 'NWBKGB2141W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Stevenage Queensway',
      '555044',
      'NWBKGB2146E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Barnsley (B)', '555046', 'NWBKGB2144H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Allenton, Derby (A)',
      '555053',
      'NWBKGB2125Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Stockton on Tees', '556100', 'NWBKGB2113R'],
    ['NATIONAL WESTMINSTER BANK PLC Hanley (A)', '556101', 'NWBKGB2109E'],
    [
      'NATIONAL WESTMINSTER BANK PLC Middlesbrough (B)',
      '556102',
      'NWBKGB2114X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Stroud Glos', '556108', 'NWBKGB2129W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Blandford Forum (A)',
      '556109',
      'NWBKGB2126W'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Sunderland City', '556111', 'NWBKGB2104R'],
    ['NATIONAL WESTMINSTER BANK PLC Sutton & Kirkby', '556117', 'NWBKGB2138W'],
    ['NATIONAL WESTMINSTER BANK PLC East Dereham (A)', '556118', 'NWBKGB2141T'],
    ['NATIONAL WESTMINSTER BANK PLC Broadstone (B)', '556119', 'NWBKGB2119C'],
    [
      'NATIONAL WESTMINSTER BANK PLC Swansea, City Cnt',
      '556120',
      'NWBKGB2105N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Swansea City Centre (A)',
      '556121',
      'NWBKGB2145L'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Nantwich (A)', '556131', 'NWBKGB2125N'],
    [
      'NATIONAL WESTMINSTER BANK PLC Haverfordwest (B)',
      '556135',
      'NWBKGB2145P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Harrogate (C)', '556137', 'NWBKGB2155M'],
    ['NATIONAL WESTMINSTER BANK PLC Thornbury', '556138', 'NWBKGB2120W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Exeter, St. Thomas (A)',
      '556144',
      'NWBKGB2129C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Sutton Coldfield (A)',
      '556149',
      'NWBKGB2117X'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Pontypridd Csb (A)',
      '556150',
      'NWBKGB2123K'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Swansea City Cnt (C)',
      '556155',
      'NWBKGB2145E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Torquay', '557001', 'NWBKGB2151H'],
    ['NATIONAL WESTMINSTER BANK PLC Torquay (A)', '557002', 'NWBKGB2151B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Shirley Sthampton (A)',
      '557005',
      'NWBKGB2147U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Stony Stratford (B)',
      '557006',
      'NWBKGB2139D'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Aberystwyth Branch (C)',
      '557007',
      'NWBKGB2134N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Carmarthen (F)', '557009', 'NWBKGB2150A'],
    ['NATIONAL WESTMINSTER BANK PLC Berkhamsted (A)', '557010', 'NWBKGB2131G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Tunbdge Wells 89 Mt Pleasnt',
      '557013',
      'NWBKGB2112X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Wakefield City', '557023', 'NWBKGB2106E'],
    ['NATIONAL WESTMINSTER BANK PLC Caterham (A)', '557030', 'NWBKGB2124F'],
    ['NATIONAL WESTMINSTER BANK PLC Trowbridge (A)', '557031', 'NWBKGB2118G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Royal Leamington Spa (A)',
      '557033',
      'NWBKGB2125E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Waterlooville', '557034', 'NWBKGB2116C'],
    ['NATIONAL WESTMINSTER BANK PLC Wellingborough', '557037', 'NWBKGB2107W'],
    ['NATIONAL WESTMINSTER BANK PLC Newtown (A)', '557040', 'NWBKGB2149Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Shrewsbury, Mardol Head (A)',
      '557042',
      'NWBKGB2150B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC St.Austell (A)', '557045', 'NWBKGB2142B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Wednesfield,Wolverhampton',
      '557046',
      'NWBKGB2155A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Waltham Cross', '557049', 'NWBKGB2128Q'],
    ['NATIONAL WESTMINSTER BANK PLC Hartlepool', '558104', 'NWBKGB2151Z'],
    ['NATIONAL WESTMINSTER BANK PLC West Malling', '558107', 'NWBKGB2136X'],
    ['NATIONAL WESTMINSTER BANK PLC Wetherby', '558111', 'NWBKGB2108S'],
    ['NATIONAL WESTMINSTER BANK PLC Scarborough (A)', '558114', 'NWBKGB2155J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Market Drayton (A)',
      '558116',
      'NWBKGB2149U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Whitley Bay', '558119', 'NWBKGB2138E'],
    ['NATIONAL WESTMINSTER BANK PLC Peterborough (A)', '558120', 'NWBKGB2141U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Willerby and Kirk Ella',
      '558123',
      'NWBKGB2131U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Winchester Old Bank',
      '558126',
      'NWBKGB2104Q'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Wisbech', '558128', 'NWBKGB2141K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Oxford, Central (F)',
      '558135',
      'NWBKGB2140Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Worcester Cross', '558136', 'NWBKGB2107N'],
    ['NATIONAL WESTMINSTER BANK PLC Workington', '558137', 'NWBKGB2122E'],
    ['NATIONAL WESTMINSTER BANK PLC Wrexham', '558142', 'NWBKGB2107U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Stockton-on-Tees (B)',
      '558144',
      'NWBKGB2151X'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Grt Yarmouth, Market Place',
      '558145',
      'NWBKGB2139U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Caerphilly (A)', '558147', 'NWBKGB2123L'],
    ['NATIONAL WESTMINSTER BANK PLC Wickersley', '558156', 'NWBKGB2144S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Worcester Cross (A)',
      '558157',
      'NWBKGB2154U'
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED Iom Bank East Region',
      '559100',
      'RBOSIM41EST'
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED South Region',
      '559104',
      'RBOSIM41STH'
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED Isle of Man Bank',
      '559107',
      'RBOSIM41PEL'
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED Ramsey Branch',
      '559110',
      'RBOSIM41RAM'
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED Iom Mobile Data Capture',
      '559121',
      ''
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED Iom Bank Head Office',
      '559124',
      'RBOSIM41ATH'
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED Iomb Personal Unsec Loans',
      '559141',
      ''
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED Ho Iomb Personal Loan',
      '559150',
      ''
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED Ho Iomb Home Imp Loan',
      '559152',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC St James & Piccadilly',
      '560003',
      'NWBKGB2152X'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bristol City Office',
      '560005',
      'NWBKGB2122A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Hull City Centre', '560006', 'NWBKGB2131T'],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffield, City Centre',
      '560009',
      'NWBKGB2144B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Fleet Street (C)', '560013', 'NWBKGB2154C'],
    ['NATIONAL WESTMINSTER BANK PLC Baker Street', '560014', 'NWBKGB2152W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Sth Kensington Station (A)',
      '560017',
      'NWBKGB2149P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Fenchurch Street', '560018', 'NWBKGB2128G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Chancery Ln Holbrn (A)',
      '560020',
      'NWBKGB2127Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Moorgate', '560023', 'NWBKGB2128D'],
    ['NATIONAL WESTMINSTER BANK PLC Regent Street', '560027', 'NWBKGB2153C'],
    [
      'NATIONAL WESTMINSTER BANK PLC St. James & Piccadilly (A)',
      '560029',
      'NWBKGB2153B'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC 95 Tottenham Court Road',
      '560031',
      'NWBKGB2152Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Westminster', '560033', 'NWBKGB2149C'],
    ['NATIONAL WESTMINSTER BANK PLC Bath Old Bank', '560034', 'NWBKGB2118E'],
    ['NATIONAL WESTMINSTER BANK PLC Bournemouth', '560035', 'NWBKGB2118Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bradford Broadway',
      '560036',
      'NWBKGB2118U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Ashton Gate , Bristol (A)',
      '560040',
      'NWBKGB2121F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Roath Cardiff (A)',
      '560041',
      'NWBKGB2122R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Carmarthen', '560042', 'NWBKGB2114G'],
    ['NATIONAL WESTMINSTER BANK PLC Coventry City', '560045', 'NWBKGB2124V'],
    [
      'NATIONAL WESTMINSTER BANK PLC Croydon High Street',
      '560046',
      'NWBKGB2124G'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Chadwell Heath (A)',
      '560047',
      'NWBKGB2144A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Exeter Exeter Bank',
      '560049',
      'NWBKGB2112N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Faversham', '560051', 'NWBKGB2136T'],
    ['NATIONAL WESTMINSTER BANK PLC Grimsby Docks', '560052', 'NWBKGB2113Y'],
    ['NATIONAL WESTMINSTER BANK PLC Leeds Victoria', '560054', 'NWBKGB2106X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Leicester, Granby Street',
      '560055',
      'NWBKGB2112R'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Newport South Wales',
      '560059',
      'NWBKGB2105B'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Northampton, The Drapery',
      '560060',
      'NWBKGB2139B'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Nottingham, Smiths Bank',
      '560061',
      'NWBKGB2104A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Plymouth Business Centre',
      '560063',
      'NWBKGB2142E'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Portsmouth Commercial Rd',
      '560064',
      'NWBKGB2111E'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Rotherham Markets (A)',
      '560066',
      'NWBKGB2144P'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Southampton, High St',
      '560068',
      'NWBKGB2147K'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Wolverhampton Queen Square',
      '560069',
      'NWBKGB2104S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC York City Centre', '560070', 'NWBKGB2112T'],
    ['BANK HAPOALIM BM London', '560075', 'POALGB2LXXX'],
    ['NATIONAL WESTMINSTER BANK PLC Head Office', '570000', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570017', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570025', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570041', ''],
    ['YORKSHIRE BUILDING SOCIETY Yorkshire Building Society', '570044', ''],
    ['YORKSHIRE BUILDING SOCIETY Yorkshire Bs Collections 2', '570045', ''],
    ['NATIONAL WESTMINSTER BANK PLC Derbyshire Building Society', '570055', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O  Collection Account', '570062', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '570076', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570080', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570099', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570102', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570114', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570126', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570149', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570165', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570181', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570203', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570231', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570234', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570250', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570266', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570277', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570293', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570307', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570447', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570455', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570501', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570506', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570528', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570536', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '570544', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570552', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570561', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570595', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570609', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570633', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570749', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570765', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570773', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570803', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '570811', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570919', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '570959', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571028', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571044', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571048', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571060', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '571061', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office', '571070', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '571079', ''],
    ['NATIONAL WESTMINSTER BANK PLC H.O Collection Account', '571095', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571104', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571105', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571106', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571109', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '571176', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571184', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '571192', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '571214', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571254', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571272', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571275', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571278', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571304', ''],
    ['CO-OPERATIVE BANK T/A BRITANNIA Ho Collection Account', '571327', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571352', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571397', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571415', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Portsmouth Cmo Chaps No2',
      '571437',
      'NWBKGB2158T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571447', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571448', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571449', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571450', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571452', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571453', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571459', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '571494', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571501', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571516', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '571524', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571532', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571557', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '571603', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571609', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '571656', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571702', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571706', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571710', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571729', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571737', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571745', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571750', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '571757', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571803', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571818', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '571824', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571826', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571850', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571869', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '571877', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '571907', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '571915', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '571958', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '571974', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '572008', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572016', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '572024', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '572032', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '572040', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572064', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '572075', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '572083', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '572091', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572109', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '572113', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '572148', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '572156', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '572169', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '572172', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '572180', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acct', '572199', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572202', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572210', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572229', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572237', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572245', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572253', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572269', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572288', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572296', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '572307', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '572501', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '572605', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '572609', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572668', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572702', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572753', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '572854', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '573004', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '573012', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '573068', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '573125', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '573165', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '573230', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '573342', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '573366', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '573435', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '573454', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '573458', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '573504', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '573555', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '574067', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '574098', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '574099', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '574100', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '574137', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '574148', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '574218', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '574245', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '574262', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '574350', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '574500', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '574501', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '574504', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '574556', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '574736', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '574802', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '574857', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '574955', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '575060', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '575144', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '575168', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '575404', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '575604', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '575609', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '575651', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '575722', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '576067', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '576124', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '576214', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '576407', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '576419', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '576469', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '576507', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '576631', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '576752', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '576805', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '576911', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577063', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '577066', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577120', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577143', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577147', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '577261', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577264', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '577305', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577361', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '577406', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577415', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577458', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577607', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577638', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '577725', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '577735', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '577857', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '577954', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '578046', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '578116', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '578127', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '578135', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '578143', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '578213', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '578301', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '578305', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '578409', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '578454', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '578464', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '578506', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '578518', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '578731', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '579062', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '579112', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '579204', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '579267', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '579364', ''],
    ['NATIONAL WESTMINSTER BANK PLC H/O Collection A/C', '579406', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '579436', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '579468', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '579553', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '579554', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '579630', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '579651', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '579738', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '579751', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC City of London Office',
      '600001',
      'NWBKGB2102N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Overseas Branch (Control)',
      '600004',
      'NWBKGB2LXXX'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Watford Town Centre',
      '600008',
      'NWBKGB2154H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ulster Bank Ics Clearing', '600036', ''],
    ['NATIONAL WESTMINSTER BANK PLC Clearing Department 2', '600038', ''],
    ['NATIONAL WESTMINSTER BANK PLC Travel Cheques', '600041', ''],
    ['NATIONAL WESTMINSTER BANK PLC Travel Cheques', '600043', ''],
    ['NATIONAL WESTMINSTER BANK PLC Travel Cheques', '600044', ''],
    ['NATIONAL WESTMINSTER BANK PLC Travel Cheques', '600045', ''],
    ['NATIONAL WESTMINSTER BANK PLC Abingdon', '600101', 'NWBKGB2106M'],
    ['NATIONAL WESTMINSTER BANK PLC Ealing (B)', '600102', 'NWBKGB2153K'],
    ['NATIONAL WESTMINSTER BANK PLC Addiscombe', '600104', 'NWBKGB2124K'],
    ['NATIONAL WESTMINSTER BANK PLC Aldershot', '600108', 'NWBKGB2130A'],
    ['MUFG BANK, LTD London Branch', '600109', 'BOTKGB2LXXX'],
    ['NATIONAL WESTMINSTER BANK PLC Alfreton', '600110', 'NWBKGB2110B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Liverpool, Allerton Road',
      '600111',
      'NWBKGB2132V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Crewe (A)', '600112', 'NWBKGB2148P'],
    ['NATIONAL WESTMINSTER BANK PLC Alton', '600113', 'NWBKGB2109B'],
    ['NATIONAL WESTMINSTER BANK PLC Amersham', '600115', 'NWBKGB2131L'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bedford, 81 High Street (B)',
      '600116',
      'NWBKGB2137H'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Andover, Bridge Street',
      '600117',
      'NWBKGB2110C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Rustington (B)', '600118', 'NWBKGB2120C'],
    [
      "NATIONAL WESTMINSTER BANK PLC Derby, St Peter's Street(E)",
      '600119',
      'NWBKGB2126E'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Ashby De La Zouch',
      '600120',
      'NWBKGB2134B'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Ashford, Kent, High Street',
      '600121',
      'NWBKGB2113W'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Ashford Middlesex',
      '600122',
      'NWBKGB2152D'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Ashton-in-Marketfield',
      '600124',
      'NWBKGB2154Q'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Birmingham New Street (A)',
      '600127',
      'NWBKGB2117P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Leigh Lancs (A)', '600128', 'NWBKGB2136K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Westbury-on-Trym Branch (B)',
      '600129',
      'NWBKGB2121Q'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Aylesbury', '600131', 'NWBKGB2131F'],
    ['NATIONAL WESTMINSTER BANK PLC Bakewell', '600133', 'NWBKGB2126A'],
    ['NATIONAL WESTMINSTER BANK PLC Balham', '600134', 'NWBKGB2148S'],
    ['NATIONAL WESTMINSTER BANK PLC Banbury', '600135', 'NWBKGB2104X'],
    ['NATIONAL WESTMINSTER BANK PLC Barking', '600137', 'NWBKGB2132E'],
    ['NATIONAL WESTMINSTER BANK PLC Barkingside', '600138', 'NWBKGB2132G'],
    ['NATIONAL WESTMINSTER BANK PLC Putney (B)', '600139', 'NWBKGB2148T'],
    ['NATIONAL WESTMINSTER BANK PLC Aldridge', '600140', 'NWBKGB2154Y'],
    ['NATIONAL WESTMINSTER BANK PLC Allenton Derby', '600141', 'NWBKGB2125X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Acocks Green, Birmingham',
      '600146',
      'NWBKGB2117L'
    ],
    ['UBS AG Ubs Ag', '600155', ''],
    ['STATE BANK OF INDIA (UK) LIMITED London Main', '600159', 'SBOIGB2LXXX'],
    ['BROWN, SHIPLEY & CO LTD Lothbury Ec2', '600168', 'BSCOGB2LXXX'],
    ['RELIANCE BANK LTD Reliance Bank', '600173', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Barnstaple, High Street',
      '600203',
      'NWBKGB2105V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bath Stuckeys', '600205', 'NWBKGB2118D'],
    ['NATIONAL WESTMINSTER BANK PLC Hastings (A)', '600207', 'NWBKGB2150Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Notting Hill Gate (A)',
      '600208',
      'NWBKGB2153J'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Beaconsfield', '600209', 'NWBKGB2131M'],
    [
      'NATIONAL WESTMINSTER BANK PLC Harborne Bham (A)',
      '600210',
      'NWBKGB2117V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Beckenham', '600212', 'NWBKGB2120R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bedford, 81 High Street',
      '600213',
      'NWBKGB2113Q'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Leicester, Melton Turn',
      '600217',
      'NWBKGB2116J'
    ],
    [
      "NATIONAL WESTMINSTER BANK PLC Derby St Peter's Street (C)",
      '600218',
      'NWBKGB2126C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Piccadilly&New Bond St (A)',
      '600220',
      'NWBKGB2152T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Berkhamsted', '600221', 'NWBKGB2131J'],
    ['NATIONAL WESTMINSTER BANK PLC Beverley', '600223', 'NWBKGB2131W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bedford, 81 High Street (D)',
      '600229',
      'NWBKGB2114M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Billericay', '600230', 'NWBKGB2123R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Horsham Carfax (A)',
      '600231',
      'NWBKGB2143M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Margate (B)', '600232', 'NWBKGB2124S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Birmingham City Centre',
      '600235',
      'NWBKGB2104F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bishops Stortford',
      '600236',
      'NWBKGB2105J'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bishopston', '600238', 'NWBKGB2121J'],
    ['NATIONAL WESTMINSTER BANK PLC Basildon', '600239', 'NWBKGB2105G'],
    [
      'NATIONAL WESTMINSTER BANK PLC West Bridgford (A)',
      '600241',
      'NWBKGB2138T'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bedford, 81 High Street (A)',
      '600242',
      'NWBKGB2137F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Wolvrhmptn Queen(A)',
      '600246',
      'NWBKGB2155B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Oxted (B)', '600248', 'NWBKGB2150T'],
    [
      'NATIONAL WESTMINSTER BANK PLC Basingstoke, Old Market Sqr',
      '600249',
      'NWBKGB2112U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Doncaster (A)', '600250', 'NWBKGB2126Q'],
    ['NATIONAL WESTMINSTER BANK PLC Nuneaton (A)', '600253', 'NWBKGB2124X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Solihull High St(A)',
      '600255',
      'NWBKGB2117Q'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Cheques Direct Int. Nwb',
      '600256',
      'NWBKGB2160G'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Welcome Accounts Unit',
      '600257',
      'NWBKGB2158A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Nri Welcome Account',
      '600259',
      'NWBKGB2158C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Southall (A)', '600260', 'NWBKGB2157V'],
    ['NATIONAL WESTMINSTER BANK PLC Sydenham (A)', '600262', 'NWBKGB2157Y'],
    ['NATIONAL WESTMINSTER BANK PLC Whitechapel Road', '600263', 'NWBKGB2158B'],
    ['NATIONAL WESTMINSTER BANK PLC White City', '600264', 'NWBKGB2158F'],
    ['NATIONAL WESTMINSTER BANK PLC Warrington Voucher Proc Ct', '600285', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cb Cheque Scan Mob App - NW', '600286', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Blackpool  Corporation St',
      '600304',
      'NWBKGB2129L'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bognor Regis', '600308', 'NWBKGB2108M'],
    ['NATIONAL WESTMINSTER BANK PLC Borehamwood', '600312', 'NWBKGB2154L'],
    [
      'NATIONAL WESTMINSTER BANK PLC Brnmth Southbourne Grove (A',
      '600314',
      'NWBKGB2119G'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Stratford Broadway (B)',
      '600319',
      'NWBKGB2127R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Braintree', '600322', 'NWBKGB2123X'],
    ['NATIONAL WESTMINSTER BANK PLC Chiswick (A)', '600323', 'NWBKGB2152H'],
    ['NATIONAL WESTMINSTER BANK PLC Brentwood Essex', '600325', 'NWBKGB2112H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Telford Town Centre (B)',
      '600326',
      'NWBKGB2155C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bridgwater', '600327', 'NWBKGB2106S'],
    ['NATIONAL WESTMINSTER BANK PLC Bridlington', '600328', 'NWBKGB2155F'],
    ['NATIONAL WESTMINSTER BANK PLC Bridport', '600329', 'NWBKGB2126Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Merry Hill Centre',
      '600330',
      'NWBKGB2127A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Brighton Castle Sq (B)',
      '600332',
      'NWBKGB2120D'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Brighton Castle Sq (A)',
      '600333',
      'NWBKGB2119U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Brixton', '600336', 'NWBKGB2149R'],
    ['NATIONAL WESTMINSTER BANK PLC Broadwater', '600338', 'NWBKGB2119Y'],
    ['NATIONAL WESTMINSTER BANK PLC St.Austell(B)', '600354', 'NWBKGB2142C'],
    ['NATIONAL WESTMINSTER BANK PLC Morpeth (A)', '600355', 'NWBKGB2138D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Oxford, Central (A)',
      '600357',
      'NWBKGB2140X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bromley', '600402', 'NWBKGB2120Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC South Kensington Station (C',
      '600404',
      'NWBKGB2149G'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bromsgrove', '600405', 'NWBKGB2116K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Stony Stratford (A)',
      '600409',
      'NWBKGB2116U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bulwell and Hucknall',
      '600410',
      'NWBKGB2139A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Haywards Heath (A)',
      '600411',
      'NWBKGB2143N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Weston Super Mare (A)',
      '600412',
      'NWBKGB2150M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bury St Edmunds', '600416', 'NWBKGB2105C'],
    ['NATIONAL WESTMINSTER BANK PLC Buxton', '600418', 'NWBKGB2146Q'],
    ['NATIONAL WESTMINSTER BANK PLC Camberley', '600420', 'NWBKGB2130E'],
    ['NATIONAL WESTMINSTER BANK PLC Camborne', '600422', 'NWBKGB2151Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC NW Cambridge Petty Cury (A)',
      '600423',
      'NWBKGB2122N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Camden Town', '600424', 'NWBKGB2139Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Canterbury, City Centre',
      '600427',
      'NWBKGB2124L'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Carlisle', '600430', 'NWBKGB2106T'],
    ['NATIONAL WESTMINSTER BANK PLC Shaftesbury (C)', '600433', 'NWBKGB2118K'],
    ['NATIONAL WESTMINSTER BANK PLC Caterham', '600435', 'NWBKGB2124C'],
    ['NATIONAL WESTMINSTER BANK PLC Catford', '600436', 'NWBKGB2146D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Reading Oracle (B)',
      '600438',
      'NWBKGB2143C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Cambridge, Fitzroy Street',
      '600441',
      'NWBKGB2122J'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Basildon (C)', '600446', 'NWBKGB2148J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Slough Trading Estate (A)',
      '600453',
      'NWBKGB2146Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Goodmans Fields Voucher Pc', '600485', ''],
    ['NATIONAL WESTMINSTER BANK PLC Chadwell Heath', '600501', 'NWBKGB2143Z'],
    ['NATIONAL WESTMINSTER BANK PLC Ilminster (A)', '600506', 'NWBKGB2150H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Birkenhead, Charing Cross',
      '600507',
      'NWBKGB2133D'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Chatham', '600509', 'NWBKGB2137A'],
    ["NATIONAL WESTMINSTER BANK PLC St Paul's", '600511', 'NWBKGB2128F'],
    ['NATIONAL WESTMINSTER BANK PLC Wells (A)', '600512', 'NWBKGB2121D'],
    ['NATIONAL WESTMINSTER BANK PLC Chelmsford', '600513', 'NWBKGB2112D'],
    ['NATIONAL WESTMINSTER BANK PLC Chelsea', '600514', 'NWBKGB2149H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Cheltenham 118 High Street',
      '600516',
      'NWBKGB2129X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Weybridge (B)', '600517', 'NWBKGB2116N'],
    [
      'NATIONAL WESTMINSTER BANK PLC Waltham Cross (A)',
      '600519',
      'NWBKGB2128R'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Knowle, Bristol Branch (B)',
      '600522',
      'NWBKGB2120Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Chichester', '600524', 'NWBKGB2112Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Liverpool, Old Swan (A)',
      '600525',
      'NWBKGB2132U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Chingford', '600526', 'NWBKGB2127S'],
    ['NATIONAL WESTMINSTER BANK PLC Sidcup (A)', '600529', 'NWBKGB2120U'],
    ['NATIONAL WESTMINSTER BANK PLC Chiswick', '600530', 'NWBKGB2153M'],
    ['NATIONAL WESTMINSTER BANK PLC Clacton on Sea', '600533', 'NWBKGB2104V'],
    ['NATIONAL WESTMINSTER BANK PLC Clapham Common', '600534', 'NWBKGB2149J'],
    ['NATIONAL WESTMINSTER BANK PLC Barbican Branch', '600537', 'NWBKGB2127Y'],
    ['NATIONAL WESTMINSTER BANK PLC Cirencester', '600541', 'NWBKGB2129Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Gerrards Cross (A)',
      '600550',
      'NWBKGB2147F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Crawley Voucher Processing', '600585', ''],
    ['NATIONAL WESTMINSTER BANK PLC Margate (A)', '600601', 'NWBKGB2124U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Ashby-de-la-Zouch (A)',
      '600602',
      'NWBKGB2107K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Weybridge (A)', '600603', 'NWBKGB2132Q'],
    ['NATIONAL WESTMINSTER BANK PLC Colchester', '600606', 'NWBKGB2112B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Romford Town Centre (A)',
      '600608',
      'NWBKGB2143Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Congleton', '600610', 'NWBKGB2125L'],
    ['NATIONAL WESTMINSTER BANK PLC Corby', '600611', 'NWBKGB2108E'],
    ['NATIONAL WESTMINSTER BANK PLC Wallington (A)', '600614', 'NWBKGB2124E'],
    ['NATIONAL WESTMINSTER BANK PLC Cranbrook', '600618', 'NWBKGB2150U'],
    ['NATIONAL WESTMINSTER BANK PLC Godalming (A)', '600619', 'NWBKGB2130P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Crawley, The Boulevard',
      '600620',
      'NWBKGB2143K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Crewe', '600623', 'NWBKGB2125J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Ilminster Branch (B)',
      '600624',
      'NWBKGB2150F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Willesden Green (A)',
      '600625',
      'NWBKGB2140D'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Crouch End and Hornsey',
      '600626',
      'NWBKGB2138C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Tunbridge Wells(A)',
      '600627',
      'NWBKGB2150Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Dartford', '600633', 'NWBKGB2125Q'],
    ['NATIONAL WESTMINSTER BANK PLC Abingdon (B)', '600636', 'NWBKGB2140U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Grand Central, Birmingham',
      '600637',
      'NWBKGB2117S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Doncaster', '600639', 'NWBKGB2105X'],
    ['NATIONAL WESTMINSTER BANK PLC Cromer', '600654', 'NWBKGB2139S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Milton Keynes (B)',
      '600656',
      'NWBKGB2109H'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC NW Mortgage Services',
      '600657',
      'NWBKGB21NMS'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Gmc - Funds Release',
      '600658',
      'NWBKGB2156D'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Nsp Default Nwb', '600685', ''],
    ['NATIONAL WESTMINSTER BANK PLC Dorchester', '600701', 'NWBKGB2105Q'],
    ['NATIONAL WESTMINSTER BANK PLC Dorking', '600702', 'NWBKGB2105A'],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Natwest Int Iom Athol St',
      '600703',
      'RBOSIM31XXX'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Dover', '600704', 'NWBKGB2105T'],
    ['NATIONAL WESTMINSTER BANK PLC Beverley (B)', '600705', 'NWBKGB2155K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Dudley, Castle Street',
      '600706',
      'NWBKGB2127B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Dunstable', '600708', 'NWBKGB2135P'],
    ['NATIONAL WESTMINSTER BANK PLC Ealing', '600710', 'NWBKGB2153P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Sth Kensington STN (B)',
      '600714',
      'NWBKGB2149K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC East Grinstead', '600717', 'NWBKGB2143Q'],
    ['NATIONAL WESTMINSTER BANK PLC East Ham', '600718', 'NWBKGB2127T'],
    [
      'NATIONAL WESTMINSTER BANK PLC Richmond Surrey (A)',
      '600720',
      'NWBKGB2148U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Eastcote Middlesex',
      '600725',
      'NWBKGB2130U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Whitechapel Road (A)',
      '600726',
      'NWBKGB2127H'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Fishponds Bristol (A)',
      '600727',
      'NWBKGB2121Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ilkeston (B)', '600728', 'NWBKGB2138V'],
    ['NATIONAL WESTMINSTER BANK PLC Victoria (A)', '600729', 'NWBKGB2149E'],
    ['NATIONAL WESTMINSTER BANK PLC Edgware', '600731', 'NWBKGB2131A'],
    ['NATIONAL WESTMINSTER BANK PLC Upper Edmonton', '600732', 'NWBKGB2137U'],
    ['NATIONAL WESTMINSTER BANK PLC Egham', '600733', 'NWBKGB2105M'],
    ['NATIONAL WESTMINSTER BANK PLC Whitehaven (A)', '600734', 'NWBKGB2122F'],
    [
      'NATIONAL WESTMINSTER BANK PLC Ellesmere Port,Town Centre',
      '600735',
      'NWBKGB2133K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Eltham', '600737', 'NWBKGB2109G'],
    ['NATIONAL WESTMINSTER BANK PLC Enfield', '600738', 'NWBKGB2128L'],
    ['NATIONAL WESTMINSTER BANK PLC Epping', '600739', 'NWBKGB2123T'],
    [
      'NATIONAL WESTMINSTER BANK PLC Coventry, City (B)',
      '600740',
      'NWBKGB2125B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Edgbaston', '600741', 'NWBKGB2117F'],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '600742', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Eastbourne Twn Cnt (B)',
      '600744',
      'NWBKGB2120F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC East Dereham', '600747', 'NWBKGB2139M'],
    [
      "NATIONAL WESTMINSTER BANK PLC Derby St Peter's Street (D)",
      '600752',
      'NWBKGB2126D'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Iom (NW) Pers Sec Loans',
      '600760',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Iom (NW) Per Unsec Loans',
      '600761',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Iom (NW) Non Pers Sec Loans',
      '600762',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Iom (NW) Non Personal Unsec',
      '600763',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC Direct Delivery NW', '600785', ''],
    ['NATIONAL WESTMINSTER BANK PLC Epsom', '600801', 'NWBKGB2132S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Sutton Coldfield (B)',
      '600802',
      'NWBKGB2117J'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Walton on Thames (A)',
      '600804',
      'NWBKGB2108A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Exeter St Thomas (D)',
      '600806',
      'NWBKGB2129G'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Exmouth', '600809', 'NWBKGB2129J'],
    [
      'NATIONAL WESTMINSTER BANK PLC Farnborough North (B)',
      '600813',
      'NWBKGB2130C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Orpington (A)', '600814', 'NWBKGB2120T'],
    ['NATIONAL WESTMINSTER BANK PLC Farnham', '600815', 'NWBKGB2130R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Ipswich Tavern Street (B)',
      '600817',
      'NWBKGB2132J'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC North Finchley (A)',
      '600820',
      'NWBKGB2137Z'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Holloway Road (B)',
      '600822',
      'NWBKGB2138A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Moorgate (A)', '600823', 'NWBKGB2128B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Fishponds Bristol',
      '600824',
      'NWBKGB2121W'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Cleveleys (A)', '600826', 'NWBKGB2129R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Stratford Broadway (A)',
      '600828',
      'NWBKGB2127V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Runcorn (A)', '600830', 'NWBKGB2153Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Frome,4 Market Place',
      '600831',
      'NWBKGB2118F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Gerrards Cross', '600834', 'NWBKGB2147E'],
    ['NATIONAL WESTMINSTER BANK PLC Shaftesbury (A)', '600836', 'NWBKGB2148A'],
    ['NATIONAL WESTMINSTER BANK PLC Gillingham, Kent', '600837', 'NWBKGB2137B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Coventry City (A)',
      '600840',
      'NWBKGB2125C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Fleet Hants', '600842', 'NWBKGB2130B'],
    ['NATIONAL WESTMINSTER BANK PLC Evesham', '600844', 'NWBKGB2154T'],
    [
      'NATIONAL WESTMINSTER BANK PLC Gateshead, High Street',
      '600845',
      'NWBKGB2138N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Feltham', '600846', 'NWBKGB2152C'],
    [
      'NATIONAL WESTMINSTER BANK PLC Wolvrhmptn Queen(B)',
      '600849',
      'NWBKGB2154Z'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Gloucester, Hucclecote',
      '600902',
      'NWBKGB2129T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Godalming', '600904', 'NWBKGB2130Q'],
    ['NATIONAL WESTMINSTER BANK PLC Golders Green', '600905', 'NWBKGB2114C'],
    ['NATIONAL WESTMINSTER BANK PLC Goole', '600907', 'NWBKGB2126R'],
    ['NATIONAL WESTMINSTER BANK PLC Grantham', '600909', 'NWBKGB2138Y'],
    ['NATIONAL WESTMINSTER BANK PLC Gravesend', '600910', 'NWBKGB2106A'],
    ['NATIONAL WESTMINSTER BANK PLC Grays', '600911', 'NWBKGB2143W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Great Crosby Liverpool Road',
      '600913',
      'NWBKGB2132Y'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Marylebone & Harley St(A)',
      '600915',
      'NWBKGB2152S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Greenwich', '600916', 'NWBKGB2107E'],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Natwest Int Guernsey',
      '600920',
      'RBOSGG21XXX'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Guildford, High Street',
      '600921',
      'NWBKGB2129Z'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Hackney, Amhurst Road',
      '600923',
      'NWBKGB2127W'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Basildon (B)', '600924', 'NWBKGB2148G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Eastbourne Twn Cnt (A)',
      '600925',
      'NWBKGB2120H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Halifax', '600927', 'NWBKGB2132B'],
    ['NATIONAL WESTMINSTER BANK PLC Braintree (A)', '600929', 'NWBKGB2124B'],
    ['NATIONAL WESTMINSTER BANK PLC Perry Barr (A)', '600935', 'NWBKGB2117H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Kingswood Bristol (B)',
      '600937',
      'NWBKGB2121Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Halesowen', '600939', 'NWBKGB2110A'],
    [
      'NATIONAL WESTMINSTER BANK PLC Guildford High St (B)',
      '600940',
      'NWBKGB2130H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Basildon (A)', '600943', 'NWBKGB2148H'],
    ['NATIONAL WESTMINSTER BANK PLC Chelmsford (A)', '600945', 'NWBKGB2123V'],
    ['NATIONAL WESTMINSTER BANK PLC Gosforth', '600947', 'NWBKGB2138G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Guildford High Street (A)',
      '600950',
      'NWBKGB2130G'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Natwest Int Iom',
      '600953',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Guernsey Personal Sec Loans',
      '600960',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Guernsey Pers Unsec Loans',
      '600961',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Guernsey Non Pers Sec Loans',
      '600962',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Guernsey Nonpers Unsec Loan',
      '600963',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Harlow Town Centre',
      '601005',
      'NWBKGB2107B'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Harold Hill Essex',
      '601006',
      'NWBKGB2143X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC St Albans (A)', '601007', 'NWBKGB2116R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Harrow Town Centre',
      '601010',
      'NWBKGB2130S'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Harrow Town Centre (A)',
      '601012',
      'NWBKGB2130T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Fleet Hants (A)', '601013', 'NWBKGB2143B'],
    ['NATIONAL WESTMINSTER BANK PLC Farnham (A)', '601014', 'NWBKGB2130L'],
    ['NATIONAL WESTMINSTER BANK PLC Hastings', '601015', 'NWBKGB2107L'],
    ['NATIONAL WESTMINSTER BANK PLC Stanmore (A)', '601016', 'NWBKGB2130W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Welwyn Garden City (A)',
      '601018',
      'NWBKGB2117B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bakewell (A)', '601019', 'NWBKGB2144L'],
    ['NATIONAL WESTMINSTER BANK PLC Havant', '601020', 'NWBKGB2105D'],
    ['NATIONAL WESTMINSTER BANK PLC Newmarket (A)', '601021', 'NWBKGB2122Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC Swiss Cottage (B)',
      '601022',
      'NWBKGB2140F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Haywards Heath', '601026', 'NWBKGB2143P'],
    ['NATIONAL WESTMINSTER BANK PLC Headington', '601028', 'NWBKGB2140Z'],
    ['NATIONAL WESTMINSTER BANK PLC Ilkeston (A)', '601029', 'NWBKGB2126G'],
    ['NATIONAL WESTMINSTER BANK PLC Uckfield (A)', '601030', 'NWBKGB2109R'],
    ['NATIONAL WESTMINSTER BANK PLC Hemel Hempstead', '601033', 'NWBKGB2131D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Hendon, Central Circus',
      '601034',
      'NWBKGB2140H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Henley on Thames', '601035', 'NWBKGB2143H'],
    ['NATIONAL WESTMINSTER BANK PLC Herne Bay', '601037', 'NWBKGB2124R'],
    ['NATIONAL WESTMINSTER BANK PLC Hertford', '601039', 'NWBKGB2146H'],
    ['NATIONAL WESTMINSTER BANK PLC West Kirby (A)', '601041', 'NWBKGB2133G'],
    ['NATIONAL WESTMINSTER BANK PLC Hayes Middlesex', '601043', 'NWBKGB2104M'],
    ['NATIONAL WESTMINSTER BANK PLC High Wycombe', '601101', 'NWBKGB2131E'],
    ['NATIONAL WESTMINSTER BANK PLC Hinckley', '601106', 'NWBKGB2105P'],
    ['NATIONAL WESTMINSTER BANK PLC Farnham (B)', '601108', 'NWBKGB2130K'],
    ['NATIONAL WESTMINSTER BANK PLC Hitchin', '601110', 'NWBKGB2146J'],
    ['NATIONAL WESTMINSTER BANK PLC Hoddesdon', '601111', 'NWBKGB2128K'],
    ['NATIONAL WESTMINSTER BANK PLC Shepherds Bush', '601113', 'NWBKGB2152N'],
    ['NATIONAL WESTMINSTER BANK PLC Holloway Road', '601114', 'NWBKGB2138B'],
    ['NATIONAL WESTMINSTER BANK PLC Hornchurch', '601115', 'NWBKGB2112A'],
    ['NATIONAL WESTMINSTER BANK PLC Horsham Carfax', '601117', 'NWBKGB2143L'],
    ['NATIONAL WESTMINSTER BANK PLC Hounslow', '601118', 'NWBKGB2152E'],
    [
      'NATIONAL WESTMINSTER BANK PLC Newbury, Mkt Place (A)',
      '601128',
      'NWBKGB2142Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Huntingdon', '601130', 'NWBKGB2141P'],
    [
      "NATIONAL WESTMINSTER BANK PLC Hunt's Cross, Liverpool",
      '601131',
      'NWBKGB2132Z'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Old Swan Liverpool (B)',
      '601132',
      'NWBKGB2133B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Basford (A)', '601133', 'NWBKGB2138S'],
    ['NATIONAL WESTMINSTER BANK PLC Ilkeston', '601137', 'NWBKGB2116F'],
    ['NATIONAL WESTMINSTER BANK PLC Ilminster', '601138', 'NWBKGB2150G'],
    ['NATIONAL WESTMINSTER BANK PLC Hollinwood', '601140', 'NWBKGB2136F'],
    [
      'NATIONAL WESTMINSTER BANK PLC Crawley The Boulvd (A)',
      '601141',
      'NWBKGB2143T'
    ],
    [
      "NATIONAL WESTMINSTER BANK PLC Derby St Peter's Street (B)",
      '601201',
      'NWBKGB2125T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Islington Angel', '601202', 'NWBKGB2137K'],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Natwest Int Library Place',
      '601203',
      'RBOSJE41XXX'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Knowle, Bristol Branch (A)',
      '601211',
      'NWBKGB2120Y'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Kidderminster,High Street',
      '601212',
      'NWBKGB2127C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Kilburn High Road',
      '601213',
      'NWBKGB2109C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Tavistock Square (A) London',
      '601214',
      'NWBKGB2137M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Kingsbury', '601217', 'NWBKGB2116V'],
    [
      'NATIONAL WESTMINSTER BANK PLC Stoke Newington (A)',
      '601218',
      'NWBKGB2127X'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Kingswood Bristol',
      '601222',
      'NWBKGB2121H'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Aintree, Black Bull (A)',
      '601225',
      'NWBKGB2114Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Harrogate (A)', '601226', 'NWBKGB2131C'],
    ['NATIONAL WESTMINSTER BANK PLC Knowle Bristol', '601229', 'NWBKGB2121T'],
    ['NATIONAL WESTMINSTER BANK PLC Knutsford', '601230', 'NWBKGB2106Y'],
    ['NATIONAL WESTMINSTER BANK PLC Street (A)', '601234', 'NWBKGB2150D'],
    ['NATIONAL WESTMINSTER BANK PLC Leamington Spa', '601235', 'NWBKGB2125D'],
    ['NATIONAL WESTMINSTER BANK PLC Leatherhead', '601236', 'NWBKGB2108Y'],
    ['NATIONAL WESTMINSTER BANK PLC Headingley', '601239', 'NWBKGB2108Z'],
    ['NATIONAL WESTMINSTER BANK PLC Leagrave Luton', '601242', 'NWBKGB2135M'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bedford, 81 High Street (C)',
      '601245',
      'NWBKGB2137G'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Oxford, Central (E)',
      '601247',
      'NWBKGB2141B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Stourbridge (A)', '601249', 'NWBKGB2127E'],
    ['NATIONAL WESTMINSTER BANK PLC Kings Heath', '601251', 'NWBKGB2117E'],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Jersey Pers Sec Loans',
      '601260',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Jersey Personal Unsec Loans',
      '601261',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Jersey Non Pers Sec Loans',
      '601262',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Jersey Non Pers Unsec Loans',
      '601263',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Ho Nwo Personal Loans',
      '601265',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Ho Nwo Car Flex Loan',
      '601266',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Ho Nwo Home Imp Loans',
      '601267',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Ho Nwo Business Loan',
      '601268',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Nwi Mobile Data Capture',
      '601269',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC Leigh Lancs', '601304', 'NWBKGB2154R'],
    [
      'NATIONAL WESTMINSTER BANK PLC Letchworth Garden City',
      '601308',
      'NWBKGB2146K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Lewes', '601309', 'NWBKGB2120N'],
    ['NATIONAL WESTMINSTER BANK PLC Lewisham', '601310', 'NWBKGB2108X'],
    ['NATIONAL WESTMINSTER BANK PLC Wanstead (A)', '601312', 'NWBKGB2127K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Canary Wharf Crossrail (B)',
      '601314',
      'NWBKGB2127M'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Lincoln Smiths Bank',
      '601315',
      'NWBKGB2134Z'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Wallasey Precinct',
      '601316',
      'NWBKGB2133E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Rustington (A)', '601318', 'NWBKGB2120B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Liverpool. City Office',
      '601319',
      'NWBKGB2104B'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Liverpool, City Office (C)',
      '601320',
      'NWBKGB2133L'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Long Eaton', '601323', 'NWBKGB2106L'],
    ['NATIONAL WESTMINSTER BANK PLC Loughton', '601326', 'NWBKGB2132D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Luton,Market Hill',
      '601328',
      'NWBKGB2135K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Rugby (A)', '601330', 'NWBKGB2134A'],
    [
      'NATIONAL WESTMINSTER BANK PLC Kilburn High Road (B)',
      '601333',
      'NWBKGB2153G'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Kilburn High Road (A)',
      '601334',
      'NWBKGB2153S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Maidenhead', '601335', 'NWBKGB2147C'],
    ['NATIONAL WESTMINSTER BANK PLC Maldon Essex', '601337', 'NWBKGB2123Z'],
    ['NATIONAL WESTMINSTER BANK PLC Malton', '601338', 'NWBKGB2155G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Leicester Granby Street (A)',
      '601339',
      'NWBKGB2107C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Honiton (C)', '601357', 'NWBKGB2126Z'],
    ['NATIONAL WESTMINSTER BANK PLC Mansfield', '601403', 'NWBKGB2108K'],
    ['NATIONAL WESTMINSTER BANK PLC Margate', '601405', 'NWBKGB2124T'],
    ['NATIONAL WESTMINSTER BANK PLC Loughborough', '601410', 'NWBKGB2104G'],
    ['NATIONAL WESTMINSTER BANK PLC Marlow', '601412', 'NWBKGB2147D'],
    ['NATIONAL WESTMINSTER BANK PLC Bakewell (B)', '601415', 'NWBKGB2116T'],
    [
      'NATIONAL WESTMINSTER BANK PLC Allenton Derby (B)',
      '601419',
      'NWBKGB2126F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Melton Mowbray', '601420', 'NWBKGB2116A'],
    ['NATIONAL WESTMINSTER BANK PLC Petersfield (A)', '601422', 'NWBKGB2130M'],
    ['NATIONAL WESTMINSTER BANK PLC Midsomer Norton', '601424', 'NWBKGB2118H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Whitechapel Road (B)',
      '601426',
      'NWBKGB2127F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Edgware (B)', '601427', 'NWBKGB2140L'],
    ['NATIONAL WESTMINSTER BANK PLC Minehead', '601430', 'NWBKGB2109S'],
    ['NATIONAL WESTMINSTER BANK PLC Tooting (A)', '601431', 'NWBKGB2124D'],
    ['NATIONAL WESTMINSTER BANK PLC Morley', '601434', 'NWBKGB2135H'],
    ['NATIONAL WESTMINSTER BANK PLC Muswell Hill', '601437', 'NWBKGB2137N'],
    ['NATIONAL WESTMINSTER BANK PLC West Kirby (B)', '601439', 'NWBKGB2133J'],
    ['NATIONAL WESTMINSTER BANK PLC Wimbledon (B)', '601441', 'NWBKGB2147G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Clevedon Triangle (A)',
      '601449',
      'NWBKGB2121A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Milton Keynes', '601455', 'NWBKGB2111Y'],
    ['NATIONAL WESTMINSTER BANK PLC New Malden', '601503', 'NWBKGB2108N'],
    ['NATIONAL WESTMINSTER BANK PLC Lymington (A)', '601504', 'NWBKGB2119E'],
    [
      'NATIONAL WESTMINSTER BANK PLC Newbury, Market Place',
      '601507',
      'NWBKGB2113T'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Newcastle City (A)',
      '601508',
      'NWBKGB2138R'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Eastbourne Twn Cnt (D)',
      '601510',
      'NWBKGB2120P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Hollinwood (A)', '601515', 'NWBKGB2136G'],
    ['NATIONAL WESTMINSTER BANK PLC Norbury', '601516', 'NWBKGB2148W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Derby, St Peters Street (A)',
      '601517',
      'NWBKGB2125Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC North Finchley', '601520', 'NWBKGB2137P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Didsbury, Wilmslow Road (B)',
      '601525',
      'NWBKGB2109U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Birmingham, Northfield',
      '601526',
      'NWBKGB2117N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bexleyheath (A)', '601528', 'NWBKGB2125R'],
    ['NATIONAL WESTMINSTER BANK PLC Northwich', '601529', 'NWBKGB2125P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Rickmansworth (A)',
      '601530',
      'NWBKGB2130X'
    ],
    [
      "NATIONAL WESTMINSTER BANK PLC Norwich Gentleman's Walk",
      '601531',
      'NWBKGB2139N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Notting Hill Gate',
      '601533',
      'NWBKGB2152M'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Old Swan Liverpool',
      '601538',
      'NWBKGB2115C'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC West Bromwich (A)',
      '601539',
      'NWBKGB2104L'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Farnborough North',
      '601542',
      'NWBKGB2130D'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Newmarket', '601547', 'NWBKGB2115K'],
    ['NATIONAL WESTMINSTER BANK PLC North Evington', '601548', 'NWBKGB2109D'],
    ['NATIONAL WESTMINSTER BANK PLC Beeston (A)', '601549', 'NWBKGB2106H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Newcastle City (B)',
      '601551',
      'NWBKGB2138Q'
    ],
    [
      "NATIONAL WESTMINSTER BANK PLC Norwich Gentleman's Walk(C)",
      '601554',
      'NWBKGB2139T'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Northampton,Weston Favell',
      '601555',
      'NWBKGB2139F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Orpington', '601603', 'NWBKGB2107G'],
    ['NATIONAL WESTMINSTER BANK PLC Oswestry', '601604', 'NWBKGB2149S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Oxford, Central (C)',
      '601606',
      'NWBKGB2141A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Oxford Central (D)',
      '601607',
      'NWBKGB2140W'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Oxted', '601609', 'NWBKGB2143U'],
    ['NATIONAL WESTMINSTER BANK PLC Baker Street (A)', '601610', 'NWBKGB2153H'],
    ['NATIONAL WESTMINSTER BANK PLC Paignton', '601611', 'NWBKGB2151J'],
    ['NATIONAL WESTMINSTER BANK PLC Palmers Green', '601612', 'NWBKGB2137Q'],
    ['NATIONAL WESTMINSTER BANK PLC Peckham', '601616', 'NWBKGB2145V'],
    ['NATIONAL WESTMINSTER BANK PLC Monton (B)', '601619', 'NWBKGB2136M'],
    ['NATIONAL WESTMINSTER BANK PLC Penrith', '601621', 'NWBKGB2122B'],
    ['NATIONAL WESTMINSTER BANK PLC Penzance', '601622', 'NWBKGB2151S'],
    ['NATIONAL WESTMINSTER BANK PLC Perry Barr', '601624', 'NWBKGB2117R'],
    ['NATIONAL WESTMINSTER BANK PLC Petersfield', '601626', 'NWBKGB2105S'],
    ['NATIONAL WESTMINSTER BANK PLC Chichester (A)', '601627', 'NWBKGB2116D'],
    ['NATIONAL WESTMINSTER BANK PLC Malton (A)', '601630', 'NWBKGB2155H'],
    ['NATIONAL WESTMINSTER BANK PLC East Ham (A)', '601632', 'NWBKGB2127L'],
    ['NATIONAL WESTMINSTER BANK PLC Beverley (A)', '601635', 'NWBKGB2155L'],
    ['NATIONAL WESTMINSTER BANK PLC Baker Street (B)', '601639', 'NWBKGB2152Q'],
    [
      'NATIONAL WESTMINSTER BANK PLC Brighton Castle Square (C)',
      '601641',
      'NWBKGB2119V'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Milton Keynes (C)',
      '601645',
      'NWBKGB2137J'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Bridgend (C)', '601648', 'NWBKGB2123A'],
    [
      'NATIONAL WESTMINSTER BANK PLC Hove Town Hall (A)',
      '601701',
      'NWBKGB2120K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC St. Helens (A)', '601705', 'NWBKGB2133A'],
    [
      'NATIONAL WESTMINSTER BANK PLC Liverpool, City Office (B)',
      '601708',
      'NWBKGB2133M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Putney', '601711', 'NWBKGB2113M'],
    [
      "NATIONAL WESTMINSTER BANK PLC Bristol, Queen's Road",
      '601712',
      'NWBKGB2121G'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Borehamwood (A)', '601714', 'NWBKGB2154M'],
    [
      'NATIONAL WESTMINSTER BANK PLC Ramsgate, Grange Road',
      '601717',
      'NWBKGB2124N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Harrow Town Centre(C)',
      '601719',
      'NWBKGB2116Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Reading Oracle', '601721', 'NWBKGB2142X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bristol Queens Rd (B)',
      '601724',
      'NWBKGB2121P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Redhill (A)', '601727', 'NWBKGB2143R'],
    ['NATIONAL WESTMINSTER BANK PLC Retford', '601728', 'NWBKGB2108V'],
    ['NATIONAL WESTMINSTER BANK PLC Rhyl', '601730', 'NWBKGB2134F'],
    ['NATIONAL WESTMINSTER BANK PLC Richmond Surrey', '601731', 'NWBKGB2152J'],
    ['NATIONAL WESTMINSTER BANK PLC Rickmansworth', '601732', 'NWBKGB2154K'],
    ['NATIONAL WESTMINSTER BANK PLC Alfreton (A)', '601733', 'NWBKGB2126B'],
    ['NATIONAL WESTMINSTER BANK PLC Chatham (A)', '601736', 'NWBKGB2136R'],
    ['NATIONAL WESTMINSTER BANK PLC Rayleigh (A)', '601738', 'NWBKGB2148E'],
    [
      'NATIONAL WESTMINSTER BANK PLC Aylesbury Csb (A)',
      '601743',
      'NWBKGB2131H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Rainham Kent', '601744', 'NWBKGB2137C'],
    ['NATIONAL WESTMINSTER BANK PLC Potters Bar', '601749', 'NWBKGB2128P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Romford Town Centre',
      '601801',
      'NWBKGB2112V'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Rotherham Markets',
      '601802',
      'NWBKGB2144R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ruislip', '601805', 'NWBKGB2115S'],
    ['NATIONAL WESTMINSTER BANK PLC Runcorn', '601806', 'NWBKGB2106N'],
    ['NATIONAL WESTMINSTER BANK PLC Hastings (B)', '601809', 'NWBKGB2150X'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bishops Stortford (A)',
      '601810',
      'NWBKGB2122K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC St Albans', '601811', 'NWBKGB2117A'],
    ['NATIONAL WESTMINSTER BANK PLC Huntingdon (B)', '601817', 'NWBKGB2141N'],
    [
      'NATIONAL WESTMINSTER BANK PLC Swiss Cottage (C)',
      '601820',
      'NWBKGB2140M'
    ],
    ['NATIONAL WESTMINSTER BANK PLC St Neots', '601823', 'NWBKGB2141Q'],
    ['NATIONAL WESTMINSTER BANK PLC Monton (A)', '601828', 'NWBKGB2136L'],
    ['NATIONAL WESTMINSTER BANK PLC Deal (A)', '601834', 'NWBKGB2124P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Eastbourne Twn Cnt (C)',
      '601837',
      'NWBKGB2120G'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Letchworth Garden City (A)',
      '601842',
      'NWBKGB2146L'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Romsey Hants', '601846', 'NWBKGB2115N'],
    [
      'NATIONAL WESTMINSTER BANK PLC Brighton Castle Square (D)',
      '601847',
      'NWBKGB2120E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Camberley (A)', '601853', 'NWBKGB2130N'],
    [
      'NATIONAL WESTMINSTER BANK PLC Chadwell Heath (B)',
      '601901',
      'NWBKGB2132F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Sevenoaks', '601902', 'NWBKGB2104H'],
    ['NATIONAL WESTMINSTER BANK PLC Sheerness', '601904', 'NWBKGB2109Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffld City Cntr (G)',
      '601907',
      'NWBKGB2144D'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Acocks Green Bham (A)',
      '601908',
      'NWBKGB2117K'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Brentwood Essex (A)',
      '601909',
      'NWBKGB2123S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Frome (A)', '601911', 'NWBKGB2118J'],
    ['NATIONAL WESTMINSTER BANK PLC Yeovil (B)', '601912', 'NWBKGB2114W'],
    ['NATIONAL WESTMINSTER BANK PLC Shirley Solihull', '601914', 'NWBKGB2118A'],
    ['NATIONAL WESTMINSTER BANK PLC Shoreham By Sea', '601917', 'NWBKGB2120L'],
    ['NATIONAL WESTMINSTER BANK PLC Sidcup', '601919', 'NWBKGB2116S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Widnes Simms Cross',
      '601924',
      'NWBKGB2108U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Sittingbourne', '601925', 'NWBKGB2136S'],
    ['NATIONAL WESTMINSTER BANK PLC Sloane Square', '601926', 'NWBKGB2149D'],
    [
      'NATIONAL WESTMINSTER BANK PLC South Kensington Station (D',
      '601927',
      'NWBKGB2149F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Slough', '601928', 'NWBKGB2146X'],
    ['NATIONAL WESTMINSTER BANK PLC South Croydon', '601934', 'NWBKGB2124H'],
    ['NATIONAL WESTMINSTER BANK PLC Yeovil (A)', '601937', 'NWBKGB2150E'],
    ['NATIONAL WESTMINSTER BANK PLC Blackpool South', '601938', 'NWBKGB2129N'],
    ['NATIONAL WESTMINSTER BANK PLC Southall', '601939', 'NWBKGB2109L'],
    [
      'NATIONAL WESTMINSTER BANK PLC Sheffld City Cntr (H)',
      '601943',
      'NWBKGB2144N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Westbury-on-Trym Branch (A)',
      '601948',
      'NWBKGB2121S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ormskirk (A)', '601949', 'NWBKGB2154S'],
    ['NATIONAL WESTMINSTER BANK PLC Southampton (A)', '602003', 'NWBKGB2147P'],
    ['NATIONAL WESTMINSTER BANK PLC Putney (C)', '602009', 'NWBKGB2148Z'],
    ['NATIONAL WESTMINSTER BANK PLC Southport', '602011', 'NWBKGB2142W'],
    [
      'NATIONAL WESTMINSTER BANK PLC Stratford City 2012',
      '602012',
      'NWBKGB2158X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Newark (A)', '602015', 'NWBKGB2138X'],
    ['NATIONAL WESTMINSTER BANK PLC Isleworth', '602018', 'NWBKGB2152F'],
    ['NATIONAL WESTMINSTER BANK PLC Stalybridge', '602021', 'NWBKGB2146P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Stoke Newington (B)',
      '602022',
      'NWBKGB2137S'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bootle New Strand',
      '602023',
      'NWBKGB2132W'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Stanmore', '602024', 'NWBKGB2130Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Stevenage, Queensway (A)',
      '602026',
      'NWBKGB2146F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Stockton Heath', '602029', 'NWBKGB2153X'],
    ['NATIONAL WESTMINSTER BANK PLC Brixton (A)', '602031', 'NWBKGB2149Q'],
    ['NATIONAL WESTMINSTER BANK PLC Stoke Newington', '602032', 'NWBKGB2137T'],
    ['NATIONAL WESTMINSTER BANK PLC Stony Stratford', '602034', 'NWBKGB2137D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Worthing South St (B)',
      '602035',
      'NWBKGB2143S'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Stratford Broadway, E15',
      '602036',
      'NWBKGB2127N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Streatham', '602039', 'NWBKGB2148V'],
    [
      'NATIONAL WESTMINSTER BANK PLC Shirley Sthampton (C)',
      '602040',
      'NWBKGB2147X'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Stratford-upon-Avon',
      '602041',
      'NWBKGB2125F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Edgbaston (A)', '602042', 'NWBKGB2117D'],
    ['NATIONAL WESTMINSTER BANK PLC Southampton (B)', '602044', 'NWBKGB2147N'],
    [
      'NATIONAL WESTMINSTER BANK PLC Bitterne, Southampton',
      '602045',
      'NWBKGB2147Q'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Stourbridge', '602048', 'NWBKGB2108H'],
    [
      'NATIONAL WESTMINSTER BANK PLC Ipswich Tavern Street (A)',
      '602049',
      'NWBKGB2132L'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Kidderminster (A)',
      '602054',
      'NWBKGB2127D'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Wickford (A)', '602056', 'NWBKGB2123W'],
    ['NATIONAL WESTMINSTER BANK PLC Street', '602101', 'NWBKGB2109Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Strood,Kent,56 High St',
      '602102',
      'NWBKGB2136Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Sudbury', '602103', 'NWBKGB2124A'],
    ['NATIONAL WESTMINSTER BANK PLC Egham (A)', '602104', 'NWBKGB2147B'],
    ['NATIONAL WESTMINSTER BANK PLC Surbiton', '602105', 'NWBKGB2107F'],
    ['NATIONAL WESTMINSTER BANK PLC Sutton Coldfield', '602107', 'NWBKGB2117W'],
    ['NATIONAL WESTMINSTER BANK PLC Sutton Surrey', '602108', 'NWBKGB2104N'],
    ['NATIONAL WESTMINSTER BANK PLC Swanley Kent', '602110', 'NWBKGB2120V'],
    ['NATIONAL WESTMINSTER BANK PLC Sydenham', '602112', 'NWBKGB2145Z'],
    ['NATIONAL WESTMINSTER BANK PLC Wetherby (A)', '602113', 'NWBKGB2135G'],
    ['NATIONAL WESTMINSTER BANK PLC Twickenham (A)', '602117', 'NWBKGB2152B'],
    ['NATIONAL WESTMINSTER BANK PLC Tenterden', '602120', 'NWBKGB2150W'],
    ['NATIONAL WESTMINSTER BANK PLC Thame', '602121', 'NWBKGB2141E'],
    ['NATIONAL WESTMINSTER BANK PLC Doncaster (B)', '602123', 'NWBKGB2126V'],
    ['NATIONAL WESTMINSTER BANK PLC Tiverton', '602127', 'NWBKGB2105R'],
    ['NATIONAL WESTMINSTER BANK PLC Tonbridge', '602128', 'NWBKGB2106J'],
    ['NATIONAL WESTMINSTER BANK PLC Tooting', '602129', 'NWBKGB2148X'],
    [
      'NATIONAL WESTMINSTER BANK PLC London Bridge (A)',
      '602134',
      'NWBKGB2145T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Trowbridge', '602136', 'NWBKGB2116B'],
    ['NATIONAL WESTMINSTER BANK PLC Truro', '602137', 'NWBKGB2109M'],
    [
      'NATIONAL WESTMINSTER BANK PLC Swindon, Commercial Road',
      '602140',
      'NWBKGB2104D'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Swansea, City Centre (B)',
      '602141',
      'NWBKGB2145C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Newton Abbot (C)', '602147', 'NWBKGB2151G'],
    ['NATIONAL WESTMINSTER BANK PLC Paignton (B)', '602148', 'NWBKGB2151N'],
    [
      'NATIONAL WESTMINSTER BANK PLC Tavistock (Devon)',
      '602149',
      'NWBKGB2141Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Tamworth', '602150', 'NWBKGB2117Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Newbury, Mkt Place (B)',
      '602151',
      'NWBKGB2143A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bury St Edmunds (A)',
      '602155',
      'NWBKGB2132N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Telford Town Centre',
      '602157',
      'NWBKGB2107P'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Newcastle High St (A)',
      '602159',
      'NWBKGB2148N'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Twickenham', '602203', 'NWBKGB2152A'],
    ['NATIONAL WESTMINSTER BANK PLC Uckfield', '602205', 'NWBKGB2150V'],
    ['NATIONAL WESTMINSTER BANK PLC Hornchurch (A)', '602206', 'NWBKGB2143V'],
    [
      'NATIONAL WESTMINSTER BANK PLC Holloway Road (A)',
      '602208',
      'NWBKGB2137V'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Uttoxeter', '602209', 'NWBKGB2148L'],
    ['NATIONAL WESTMINSTER BANK PLC Uxbridge', '602210', 'NWBKGB2152L'],
    [
      'NATIONAL WESTMINSTER BANK PLC Tunbridge Wells (B)',
      '602215',
      'NWBKGB2114U'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Fulham Broadway', '602216', 'NWBKGB2149L'],
    ['NATIONAL WESTMINSTER BANK PLC Abingdon (C)', '602219', 'NWBKGB2116W'],
    ['NATIONAL WESTMINSTER BANK PLC Wallington', '602220', 'NWBKGB2147H'],
    ['NATIONAL WESTMINSTER BANK PLC Walsall', '602222', 'NWBKGB2106B'],
    [
      'NATIONAL WESTMINSTER BANK PLC Walthamstow Central',
      '602223',
      'NWBKGB2127P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Walton on Thames', '602225', 'NWBKGB2116M'],
    ['NATIONAL WESTMINSTER BANK PLC Camberwell (A)', '602227', 'NWBKGB2145W'],
    ['NATIONAL WESTMINSTER BANK PLC Putney (A)', '602228', 'NWBKGB2148Y'],
    ['NATIONAL WESTMINSTER BANK PLC Wanstead', '602230', 'NWBKGB2127J'],
    ['NATIONAL WESTMINSTER BANK PLC Abingdon (A)', '602231', 'NWBKGB2140V'],
    ['NATIONAL WESTMINSTER BANK PLC Hertford (A)', '602232', 'NWBKGB2146G'],
    ['NATIONAL WESTMINSTER BANK PLC Watford Junction', '602240', 'NWBKGB2154G'],
    ['NATIONAL WESTMINSTER BANK PLC Broadstone (A)', '602245', 'NWBKGB2119D'],
    ['NATIONAL WESTMINSTER BANK PLC Farnworth A', '602246', 'NWBKGB2136A'],
    [
      'NATIONAL WESTMINSTER BANK PLC Sutton Coldfield (C)',
      '602247',
      'NWBKGB2117Z'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Washington Town Centre',
      '602252',
      'NWBKGB2138J'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Birmingham, Attwood House',
      '602253',
      'NWBKGB2108R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Taunton Town (A)', '602305', 'NWBKGB2150J'],
    ['NATIONAL WESTMINSTER BANK PLC Wells', '602306', 'NWBKGB2108D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Welwyn Garden City',
      '602307',
      'NWBKGB2117C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Wembley', '602308', 'NWBKGB2107X'],
    ['NATIONAL WESTMINSTER BANK PLC Kingsbury (A)', '602309', 'NWBKGB2105F'],
    ['NATIONAL WESTMINSTER BANK PLC Ealing (A)', '602313', 'NWBKGB2152P'],
    ['NATIONAL WESTMINSTER BANK PLC West Kirby', '602317', 'NWBKGB2133F'],
    ['NATIONAL WESTMINSTER BANK PLC Streatham (A)', '602319', 'NWBKGB2146A'],
    [
      "NATIONAL WESTMINSTER BANK PLC B'mouth Southbourne Grove",
      '602320',
      'NWBKGB2119F'
    ],
    ['NATIONAL WESTMINSTER BANK PLC West Wickham', '602322', 'NWBKGB2120S'],
    ['NATIONAL WESTMINSTER BANK PLC Westbury on Trym', '602323', 'NWBKGB2121M'],
    ['NATIONAL WESTMINSTER BANK PLC Greenwich (A)', '602327', 'NWBKGB2146B'],
    ['NATIONAL WESTMINSTER BANK PLC Leigh, Lancs (C)', '602330', 'NWBKGB2119N'],
    [
      'NATIONAL WESTMINSTER BANK PLC Weston-super-Mare',
      '602332',
      'NWBKGB2121C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Weybridge', '602334', 'NWBKGB2107D'],
    ['NATIONAL WESTMINSTER BANK PLC Weymouth', '602335', 'NWBKGB2126X'],
    [
      'NATIONAL WESTMINSTER BANK PLC North Finchley (B)',
      '602336',
      'NWBKGB2137X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Herne Bay (A)', '602338', 'NWBKGB2124Q'],
    ['NATIONAL WESTMINSTER BANK PLC Wickford', '602339', 'NWBKGB2148D'],
    ['NATIONAL WESTMINSTER BANK PLC Woking (A)', '602340', 'NWBKGB2132R'],
    [
      'NATIONAL WESTMINSTER BANK PLC National Account Centre',
      '602371',
      'NWBKGB2157D'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Wigan', '602402', 'NWBKGB2154P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Willesden Green Station Pde',
      '602404',
      'NWBKGB2108L'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Minehead (B)', '602405', 'NWBKGB2150L'],
    ['NATIONAL WESTMINSTER BANK PLC Wimbledon', '602406', 'NWBKGB2104Z'],
    ['NATIONAL WESTMINSTER BANK PLC Wimbledon (A)', '602407', 'NWBKGB2107T'],
    ['NATIONAL WESTMINSTER BANK PLC Shaftesbury (B)', '602409', 'NWBKGB2118L'],
    ['NATIONAL WESTMINSTER BANK PLC Windsor & Eton', '602412', 'NWBKGB2147A'],
    ['NATIONAL WESTMINSTER BANK PLC Maldon Essex (A)', '602417', 'NWBKGB2123Y'],
    [
      'NATIONAL WESTMINSTER BANK PLC Milton Keynes (A)',
      '602419',
      'NWBKGB2137E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Woking', '602420', 'NWBKGB2130J'],
    ['NATIONAL WESTMINSTER BANK PLC Wokingham', '602421', 'NWBKGB2143D'],
    ['NATIONAL WESTMINSTER BANK PLC Wood Green', '602423', 'NWBKGB2137Y'],
    ['NATIONAL WESTMINSTER BANK PLC South Woodford', '602424', 'NWBKGB2106F'],
    [
      'NATIONAL WESTMINSTER BANK PLC South Woodford (A)',
      '602425',
      'NWBKGB2132H'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Woolwich', '602426', 'NWBKGB2145X'],
    ['NATIONAL WESTMINSTER BANK PLC Worcester Park', '602428', 'NWBKGB2132T'],
    ['NATIONAL WESTMINSTER BANK PLC Worksop', '602430', 'NWBKGB2106K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Worthing, South Street',
      '602431',
      'NWBKGB2119W'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Worthing South St (A)',
      '602432',
      'NWBKGB2119X'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Yeovil', '602437', 'NWBKGB2104U'],
    ['NATIONAL WESTMINSTER BANK PLC Wimborne', '602443', 'NWBKGB2114K'],
    [
      'NATIONAL WESTMINSTER BANK PLC Ipswich Tavern Street (C)',
      '602445',
      'NWBKGB2132K'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Wymondham', '602452', 'NWBKGB2139L'],
    ['NATIONAL WESTMINSTER BANK PLC Witney Oxon', '602460', 'NWBKGB2141D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Heslington York University',
      '602462',
      'NWBKGB2155D'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Gtms - Nwb Fmd', '602463', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Gtms - Nwb Travel',
      '602464',
      'NWBKGB2157B'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Centralised Remote Atm Unit',
      '602465',
      'NWBKGB2158J'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Bethnal Green Road (A)',
      '602466',
      'NWBKGB2157C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Abt Finance', '602467', 'NWBKGB2157G'],
    [
      'NATIONAL WESTMINSTER BANK PLC Birmingham City Centre(A)',
      '602468',
      'NWBKGB2157P'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Canary Wharf, Crossrail (A)',
      '602469',
      'NWBKGB2157T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Dundee', '602475', 'NWBKGB2125S'],
    [
      'NATIONAL WESTMINSTER BANK PLC Direct Business Banking',
      '602477',
      'NWBKGB2155R'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Farming & Agricultural Fin',
      '602478',
      'NWBKGB2155U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Natwest Savings Direct',
      '602479',
      'NWBKGB2155T'
    ],
    ['NATIONAL WESTMINSTER BANK PLC NW On-Line Banking Hgate', '602481', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Mtge Svcs Foundations, Bhm',
      '602482',
      'NWBKGB2155V'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Law Enforcement Operations',
      '602483',
      'NWBKGB2155X'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Retail Support - Tst Br Nwb',
      '602484',
      'NWBKGB2159S'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Retail Support -Tst Br Nwb2', '602485', ''],
    ['NATIONAL WESTMINSTER BANK PLC NW Group Treasury', '602487', ''],
    ['NATIONAL WESTMINSTER BANK PLC Direct Mortgages', '602489', ''],
    ['CHIBA BANK LTD Regina House London', '602610', 'CHBAGB2LXXX'],
    ['WYELANDS BANK PLC 24 Creechurch Lane London', '602613', ''],
    ['Hua Nan Commercial Bank Ltd 32 Lombard Street', '602618', ''],
    ['NATIONAL WESTMINSTER BANK PLC Bradford & Bingley', '602637', ''],
    ['NATIONAL WESTMINSTER BANK PLC Nwb Lms Unit', '602649', 'NWBKGB2LLIQ'],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '603001', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '603002', ''],
    ['NATIONAL WESTMINSTER BANK PLC Dean Street', '603003', 'NWBKGB2152U'],
    ['NATIONAL WESTMINSTER BANK PLC Bloomsbury Parrs', '603006', 'NWBKGB2154A'],
    [
      'NATIONAL WESTMINSTER BANK PLC Brighton, Castle Square',
      '603009',
      'NWBKGB2119R'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Lincoln Smiths Bank (A)',
      '603010',
      'NWBKGB2135B'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Trustee & Depositary Serv',
      '603011',
      'NWBKGB2158Y'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Edinburgh', '603020', 'NWBKGB2113X'],
    ['NATIONAL WESTMINSTER BANK PLC Glasgow', '603021', 'NWBKGB2113J'],
    ['NATIONAL WESTMINSTER BANK PLC Aberdeen', '603022', 'NWBKGB2106D'],
    ['NATIONAL WESTMINSTER BANK PLC Ayr', '603023', 'NWBKGB2132P'],
    [
      'NATIONAL WESTMINSTER BANK PLC Banking Direct Edinburgh',
      '603030',
      'NWBKGB2118V'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Regent Street (A)',
      '604002',
      'NWBKGB2153A'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Chancery Lane and Holborn',
      '604004',
      'NWBKGB2154B'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Strand, London', '604005', 'NWBKGB2154E'],
    ['NATIONAL WESTMINSTER BANK PLC Chester', '604008', 'NWBKGB2123N'],
    ['NATIONAL WESTMINSTER BANK PLC Chesterfield', '604009', 'NWBKGB2112Z'],
    [
      'NATIONAL WESTMINSTER BANK PLC Chaps Control-Repairs',
      '604020',
      'NWBKGB21RTN'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Electronic Banking Ops',
      '604070',
      'NWBKGB55XXX'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Covent Garden', '605000', 'NWBKGB2154D'],
    [
      'NATIONAL WESTMINSTER BANK PLC Croydon, Whitgift Centre',
      '605001',
      'NWBKGB2124J'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Tavistock Square (B)',
      '605003',
      'NWBKGB2140A'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Hammersmith', '605006', 'NWBKGB2153Q'],
    ['NATIONAL WESTMINSTER BANK PLC Ilford', '605009', 'NWBKGB2132C'],
    [
      'NATIONAL WESTMINSTER BANK PLC Debt Management Operations',
      '605012',
      'NWBKGB2157L'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Nat West Stockbrokers',
      '605013',
      'NWBKGB21NSB'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Chief Accountants Dept',
      '605040',
      'NWBKGB2155W'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Group Tax - Nwb plc', '605042', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Card Serv Dept Southend',
      '605050',
      'NWBKGB2155Q'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Cms Retail Rec-Telford M1', '605051', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cms Retail Rec-Telford M2', '605052', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cms Retail Rec-Telford M3', '605053', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cms Retail Rec-Telford M4', '605054', ''],
    ['NATIONAL WESTMINSTER BANK PLC Insolv & Debt Rec Telford', '605055', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cms Retail Rec-Telford M5', '605056', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cms Retail Rec-Telford', '605058', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cms Telford (R)', '605090', 'NWBKGB2158Q'],
    ['NATIONAL WESTMINSTER BANK PLC NW Retail Cards (R)', '605091', ''],
    ['NATIONAL WESTMINSTER BANK PLC NW Commercial Cards (R)', '605092', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Flex Mortgage Branch (R)',
      '605093',
      'NWBKGB2158N'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Nwb Cent Corp Banking (R)',
      '605094',
      'NWBKGB2158P'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Natwest Direct Svr Plus (R)',
      '605095',
      'NWBKGB2158S'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Natwest Esavings (R)',
      '605096',
      'NWBKGB2158R'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Savings Direct (R)', '605097', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Nat West Post Office',
      '605114',
      'NWBKGB2159Z'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Kingston Market Place',
      '606002',
      'NWBKGB2113F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC London Bridge (C)',
      '606004',
      'NWBKGB2145U'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Leeds City Office',
      '606005',
      'NWBKGB2111F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Leicester, Hinckley Road',
      '606006',
      'NWBKGB2104E'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Maidstone', '606008', 'NWBKGB2136U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Canary Wharf Crossrail',
      '606015',
      'NWBKGB2159Y'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Banking Direct Manchester',
      '606040',
      'NWBKGB2143F'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Natwest Flex Mort',
      '606050',
      'NWBKGB2157W'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Natwest Int Line Wall Rd',
      '606060',
      'RBOSGI21XXX'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Gibraltar Pers Sec Loans',
      '606062',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Gibraltar Pers Unsec Loans',
      '606063',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Gibraltar Non-Pers Secloans',
      '606064',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Gibraltar Nonper Unsec Loan',
      '606065',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Oxford, Central (B)',
      '607003',
      'NWBKGB2104C'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Park Royal (B)', '607005', 'NWBKGB2152K'],
    ['NATIONAL WESTMINSTER BANK PLC St Helens', '607008', 'NWBKGB2153U'],
    [
      'NATIONAL WESTMINSTER BANK PLC Natwest Insurance Services',
      '607070',
      'NWBKGB21NIS'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Gov Bnkg Serv Br', '607080', 'NWBKGB2158E'],
    ['NATIONAL WESTMINSTER BANK PLC Bcs (Euro Vostro)', '607200', ''],
    ['NATIONAL WESTMINSTER BANK PLC Birmingham Amc', '607201', ''],
    ['NATIONAL WESTMINSTER BANK PLC Bolton Amc', '607202', ''],
    ['NATIONAL WESTMINSTER BANK PLC Swrcsc (B/Euro)', '607203', ''],
    ['NATIONAL WESTMINSTER BANK PLC Chatham Amc', '607204', ''],
    ['NATIONAL WESTMINSTER BANK PLC Farnborough Amc', '607206', ''],
    ['NATIONAL WESTMINSTER BANK PLC High Wycombe Amc', '607207', ''],
    ['NATIONAL WESTMINSTER BANK PLC Leeds Amc', '607208', ''],
    ['NATIONAL WESTMINSTER BANK PLC Bolton Csc 2', '607210', ''],
    ['NATIONAL WESTMINSTER BANK PLC Milton Keynes Amc', '607211', ''],
    ['NATIONAL WESTMINSTER BANK PLC Plymouth Ctc Euro Sc', '607212', ''],
    ['NATIONAL WESTMINSTER BANK PLC RPO Southend (2)', '607213', ''],
    ['NATIONAL WESTMINSTER BANK PLC City of London Amu', '607214', ''],
    ['NATIONAL WESTMINSTER BANK PLC Menai Sc', '607215', ''],
    ['NATIONAL WESTMINSTER BANK PLC Nat West Savings Direct', '607218', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Southend Cards Testing',
      '607881',
      'NWBKGB2155P'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Manchester', '607931', 'NWBKGB21BMR'],
    ['NATIONAL WESTMINSTER BANK PLC Tamworth', '607932', 'NWBKGB21BTW'],
    [
      'NATIONAL WESTMINSTER BANK PLC Maidstone Cash Centre',
      '607944',
      'NWBKGB21BMS'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Camberley Coin Centre',
      '607955',
      'NWBKGB21CCO'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Haydock Cross Coin Centre',
      '607956',
      'NWBKGB21HCC'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Scotland Cash & Coin Centre', '607957', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Liverpool St Station (A)',
      '608001',
      'NWBKGB2127G'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Paddington', '608005', 'NWBKGB2153F'],
    [
      'NATIONAL WESTMINSTER BANK PLC Taunton Town Branch',
      '608006',
      'NWBKGB2113H'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC Tavistock Square (London)',
      '608007',
      'NWBKGB2153Z'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Fleet Street (B)', '608008', 'NWBKGB2154F'],
    ['NATIONAL WESTMINSTER BANK PLC Nottingham City', '608009', 'NWBKGB2138U'],
    ['NATIONAL WESTMINSTER BANK PLC Inland Payments Sterling', '608029', ''],
    ['NATIONAL WESTMINSTER BANK PLC City Ibc', '608030', 'NWBKGB2LCTY'],
    ['UNITY TRUST BANK PLC Birmingham', '608301', ''],
    ['ADVANCED PAYMENT SOLUTIONS LTD Aps Limited', '608302', ''],
    ['GUARANTY TRUST BANK (UK) LIMITED London Branch', '608303', ''],
    ['HBL BANK UK LIMITED T/A HBL BANK UK London Branch', '608304', ''],
    ['CONISTER BANK LTD Douglas', '608307', 'CNNIIMD2XXX'],
    ['Europe Arab Bank Plc London', '608309', 'ARABGB2LXXX'],
    ['ICBC (LONDON) PLC Icbc Ltd London Branch', '608310', 'ICBKGB3LXXX'],
    [
      'Cambridge & Counties Bank Limited Cambridge&Count Bk Ltd - Af',
      '608311',
      ''
    ],
    ['GIBRALTAR INTERNATIONAL BANK LIMITED Gibraltar', '608314', ''],
    ['VANQUIS BANK LIMITED Vanquis Bank Limited', '608315', ''],
    ['THE CHARITY BANK LTD The Charity Bank Ltd', '608316', ''],
    ['DUDLEY BUILDING SOCIETY Dudley Building Society', '608318', ''],
    ['OAKNORTH BANK PLC Oaknorth Bank Ltd', '608319', ''],
    [
      'VOYAGER ALLIANCE CREDIT UNION LIMITED Manchester University Union',
      '608336',
      ''
    ],
    ['STATE BANK OF INDIA (UK) LIMITED Ilford Branch', '608360', 'SBOIGB2LILF'],
    [
      'STATE BANK OF INDIA (UK) LIMITED Hounslow Branch',
      '608361',
      'SBOIGB2LHLW'
    ],
    ['CAIXABANK SA Caixabank SA', '608362', 'CAIXGB2LXXX'],
    ['TANDEM BANK LIMITED Tandem Bank Limited', '608363', ''],
    ['STATE BANK OF INDIA UK Branch', '608365', 'SBINGB2LXXX'],
    [
      'LONDON COMMUNITY CREDIT UNION LIMITED London Community Credit U',
      '608367',
      ''
    ],
    ['CROWN AGENTS BANK LIMITED Crown Agents Bank', '608368', 'CRASGB2LXXX'],
    [
      'CONTIS FINANCIAL SERVICES LIMITED Contis Financial Services',
      '608370',
      ''
    ],
    ['STARLING BANK LIMITED Starling Bank Limited', '608371', 'SRLGGB2LXXX'],
    ['YORKSHIRE BUILDING SOCIETY Ybs E Isa Transfers Only', '608372', ''],
    ['YORKSHIRE BUILDING SOCIETY Chelsea Bs E-Isa Trans Only', '608373', ''],
    ['Bank of Beirut (uk) Limited Bank of Beirut UK Ltd', '608375', ''],
    [
      'Bank of Communications Co. Limited Bk of Comms London Branch',
      '608376',
      'COMMGB3LXXX'
    ],
    ['BANKING CIRCLE S.A. UK Branch', '608382', ''],
    ['REDWOOD BANK LIMITED Redwood Bank', '608383', ''],
    [
      'CONTIS FINANCIAL SERVICES LIMITED Contis Fin Serv Ltd',
      '608384',
      'CNFVGB21XXX'
    ],
    ['ZOPA LIMITED Zopa Limited', '608385', ''],
    ['BANK OF BARODA City Road', '608386', 'BARBGB2LXXX'],
    ['CONTIS FINANCIAL SERVICES LIMITED Contis Fin Serv Ltd', '608387', ''],
    ['CONTIS FINANCIAL SERVICES LIMITED Contis Fin Serv Ltd', '608388', ''],
    ['CONTIS FINANCIAL SERVICES LIMITED Contis Fin Serv Ltd', '608389', ''],
    [
      'BUTTERFIELD BANK (JERSEY) LTD Butterfld Bk (Jr) Ltd',
      '608391',
      'BNTBJESHXXX'
    ],
    ['NATWEST MARKETS PLC Natwest Markets', '608394', 'RBOSGB2RTCM'],
    [
      'LONDON MUTUAL CREDIT UNION LIMITED London Mutual Credit Union',
      '608395',
      ''
    ],
    [
      'Butterfield Bank (Guernsey) Limited St Peter Port Branch',
      '608398',
      'BNTBGGSXXXX'
    ],
    [
      'CAYMAN NATIONAL BANK (ISLE OF MAN) LIMITED Cayman & Nat Bk & Trst Iom',
      '608399',
      'CNBTIMDDXXX'
    ],
    ['ZOPA BANK LIMITED Zopa Bank Limited', '608400', ''],
    ['ADVANCED PAYMENT SOLUTIONS LTD Aps Limited', '608401', ''],
    ['ADVANCED PAYMENT SOLUTIONS LTD Aps Limited', '608402', ''],
    ['ADVANCED PAYMENT SOLUTIONS LTD Aps Limited', '608403', ''],
    ['JP MORGAN BANK LUXEMBOURG S.A. Jp Morgan Bank Lux S.A.', '608404', ''],
    ['JP MORGAN AG Jp Morgan Ag', '608405', ''],
    ['JP MORGAN BANK LUXEMBOURG S.A. Dublin Branch', '608406', ''],
    ['CHASE (TRADING NAME OF JP MORGAN EUROPE LTD) Chase', '608407', ''],
    ['CASTLE TRUST CAPITAL PLC Castle Trust Capital', '608408', ''],
    ['DF CAPITAL BANK LIMITED Df Capital Bank Limited', '608410', ''],
    ['ALLICA BANK LIMITED Allica Bank Limited', '608411', ''],
    ['ALLICA BANK LIMITED Asset Finance', '608412', ''],
    ['TANDEM BANK LIMITED Tandem Bank Limited', '608413', ''],
    ['C HOARE & CO Fleet Street', '608414', ''],
    [
      'NATWEST MARKETS PLC Natwest Markets Fx Micropay',
      '608416',
      'RBOSGB2RFMP'
    ],
    ['ONESAVINGS BANK PLC Onesavings Bank plc', '608417', ''],
    ['ICICI BANK UK PLC Api Proving', '608418', ''],
    ['G-ROCK LIMITED G-Rock Limited', '608420', 'GSBNGIGIXXX'],
    ['HABIB BANK ZURICH PLC Proving One', '608423', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A NATWEST PREMIER Nwb plc T/A Natwest Premier',
      '608424',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A NATWEST PREMIER Nwb plc T/A Natwest Premier',
      '608425',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A NATWEST PREMIER Nwb plc T/A Natwest Premier',
      '608426',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A NATWEST PREMIER Nwb plc T/A Natwest Premier',
      '608427',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A NATWEST PREMIER Nwb plc T/A Natwest Premier',
      '608428',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A NATWEST PREMIER Nwb plc T/A Natwest Premier',
      '608429',
      ''
    ],
    ['OAKNORTH BANK PLC Oaknorth Bank plc', '608430', ''],
    ['DF CAPITAL BANK LIMITED Proving One', '608431', ''],
    ['STATE BANK OF INDIA (UK) LIMITED Proving One', '608432', ''],
    ['STATE BANK OF INDIA (UK) LIMITED Proving Two', '608433', ''],
    ['CIB LIMITED Cib Limited', '608460', 'CIBQIMDDXXX'],
    ['CHASE (TRADING NAME OF JP MORGAN EUROPE LTD) Chase', '608484', ''],
    [
      'National Savings and Investments Incme Bnds Cptl Bacs/Fp Out',
      '608907',
      ''
    ],
    [
      'National Savings and Investments Income Bnds Intrst Bacs Pts',
      '608908',
      ''
    ],
    ['National Savings and Investments Investment Bacs/Fp Out', '608910', ''],
    [
      'National Savings and Investments Premium Bonds Bacs Payments',
      '608913',
      ''
    ],
    [
      'National Savings and Investments Residual Account Bacs Pts',
      '608914',
      ''
    ],
    [
      'National Savings and Investments Svgs Certificates Bacs Pmts',
      '608915',
      ''
    ],
    ['National Savings and Investments Capital Bond Warrants', '608916', ''],
    [
      "National Savings and Investments Children's Bonus Bond Wrnts",
      '608917',
      ''
    ],
    ['National Savings and Investments Easa Warrants', '608918', ''],
    ['National Savings and Investments Fob Warrants', '608919', ''],
    ['National Savings and Investments Frsb Warrants', '608920', ''],
    ['National Savings and Investments Global Warrants', '608921', ''],
    ['National Savings and Investments Geb Warrants', '608922', ''],
    [
      "National Savings and Investments Income Bonds Cap' Warrants",
      '608923',
      ''
    ],
    [
      "National Savings and Investments Income Bonds Int' Warrants",
      '608924',
      ''
    ],
    ['National Savings and Investments Isa Warrants', '608925', ''],
    ['National Savings and Investments Investment Warrants', '608926', ''],
    ['National Savings and Investments Pgib Capital Warrants', '608927', ''],
    ['National Savings and Investments Pgib Interest Warrants', '608928', ''],
    [
      "National Savings and Investments Premium Bonds Cap' Warrants",
      '608929',
      ''
    ],
    [
      'National Savings and Investments Premium Bonds Prize Warrant',
      '608930',
      ''
    ],
    [
      'National Savings and Investments Residual Account Warrants',
      '608931',
      ''
    ],
    [
      'National Savings and Investments Savings Certificates Wrrnts',
      '608932',
      ''
    ],
    ['National Savings and Investments Disa Bacs/Fp Out', '608950', ''],
    ['National Savings and Investments Direct Saver Bacs/Fp Out', '608951', ''],
    ['National Savings and Investments Direct Isa Warrants', '608952', ''],
    ['National Savings and Investments Direct Saver Warrants', '608953', ''],
    ['National Savings and Investments Pb Prizes Bacs Out', '608954', ''],
    ['National Savings and Investments Ilsc Bacs Payments', '608957', ''],
    ['National Savings and Investments Fisc Bacs Payments', '608958', ''],
    ['National Savings and Investments Gib Bacs Payments', '608959', ''],
    ['National Savings and Investments Ggb Bacs Payments', '608960', ''],
    [
      'National Savings and Investments Childrens Bonds Bacs Pymnts',
      '608961',
      ''
    ],
    ['National Savings and Investments Ilsc Warrants', '608962', ''],
    ['National Savings and Investments Fisc Warrants', '608963', ''],
    ['National Savings and Investments Gib Warrants', '608964', ''],
    ['National Savings and Investments Ggb Warrants', '608965', ''],
    ['National Savings and Investments Childrens Bonds Warrants', '608966', ''],
    [
      'National Savings and Investments 65+Guaranteed Growth Bond',
      '608967',
      ''
    ],
    [
      'National Savings and Investments 65+Guaranteed Growth Bond',
      '608968',
      ''
    ],
    ['National Savings and Investments Ns&I Hmrc Ppb', '608969', ''],
    ['National Savings and Investments Ns&I Hmrc Eligibity', '608970', ''],
    [
      'National Savings and Investments Ns&I Tfc Customer Receipts',
      '608971',
      ''
    ],
    ['National Savings and Investments Ns&I Jisa Bacs Payments', '608972', ''],
    ['National Savings and Investments Ns&I', '608973', ''],
    ['National Savings and Investments Ns&I Gsb Bacs Payments', '608974', ''],
    ['National Savings and Investments Ns&I Gsb Warrants', '608975', ''],
    [
      'National Savings and Investments Ns&I Prem Bond Price Wrnts',
      '608976',
      ''
    ],
    [
      'National Savings and Investments Ns&I Help To Save Cus Rcpts',
      '608977',
      ''
    ],
    ['National Savings and Investments Ns&I Income Bonds', '608978', ''],
    [
      'National Savings and Investments Ns&I Hts Bacs Withdrawals',
      '608979',
      ''
    ],
    [
      'National Savings and Investments Ns&I Tfc Bacs Withdrawals',
      '608980',
      ''
    ],
    [
      'National Savings and Investments Ns&I Income Bonds Chq Rcpt',
      '608981',
      ''
    ],
    [
      'National Savings and Investments Ns&I Premium Bond Chq Rcpt',
      '608982',
      ''
    ],
    [
      'National Savings and Investments Ns&I Invstmnt Acc Chq Rcpt',
      '608983',
      ''
    ],
    ['National Savings and Investments Ns&I Isa Chq Receipts', '608984', ''],
    [
      'National Savings and Investments Ns&I Disa Cheque Receipts',
      '608985',
      ''
    ],
    [
      'National Savings and Investments Ns&I Direct Saver Chq Rcpt',
      '608986',
      ''
    ],
    [
      "National Savings and Investments Ns&I Chldrn's Bnd Chq Rcpt",
      '608987',
      ''
    ],
    ['National Savings and Investments Ns&I Gib Cheque Receipts', '608988', ''],
    ['National Savings and Investments Ns&I Gsb Cheque Receipts', '608989', ''],
    ['National Savings and Investments Ns&I Ggb Cheque Receipts', '608990', ''],
    [
      'National Savings and Investments Ns&I Ilsc Cheque Receipts',
      '608991',
      ''
    ],
    [
      'National Savings and Investments Ns&I Fisc Cheque Receipts',
      '608992',
      ''
    ],
    [
      'National Savings and Investments Ns&I 65+ Ggb Chq Receipts',
      '608993',
      ''
    ],
    ['National Savings and Investments Ns&I Junior Isa Chq Rcpt', '608994', ''],
    ['National Savings and Investments Ns&I Htb', '608995', ''],
    ['National Savings and Investments Ns&I Tfc', '608996', ''],
    ['National Savings and Investments Ns&I Htb', '608997', ''],
    ['National Savings and Investments Ns&I Htb', '608998', ''],
    ['STANDARD CHARTERED BANK London', '609104', 'SCBLGB2LXXX'],
    ['STANDARD CHARTERED BANK Trustee', '609107', ''],
    ['STANDARD CHARTERED BANK King William Street Drafts', '609109', ''],
    ['STANDARD CHARTERED BANK Personal Banking Services', '609111', ''],
    ['STANDARD CHARTERED BANK Regent Street', '609112', ''],
    ['STANDARD CHARTERED BANK King Wm St-Divd Warrants', '609113', ''],
    ['STANDARD CHARTERED BANK London', '609123', ''],
    [
      'STANDARD CHARTERED BANK Standard Chartered Int Div',
      '609131',
      'SCBLGB2LTSY'
    ],
    ['STANDARD CHARTERED BANK Chaps Repair', '609132', 'SCBLGB2LRTN'],
    ['HABIB BANK ZURICH PLC West End Branch', '609134', ''],
    ['SHANGHAI COMMERCIAL BANK LTD Cornhill Ec3v 3Nb', '609137', 'SCBKGB2XXXX'],
    ['HABIB BANK ZURICH PLC 160 Belgrave Rd Leicester', '609139', ''],
    ['THE NORINCHUKIN BANK 4 Broadgate London', '609140', ''],
    [
      'JPMORGAN CHASE BANK, N.A. Wss Chaseside, Bournemouth',
      '609141',
      'CHASGB2LENQ'
    ],
    ['HABIB BANK ZURICH PLC Habib Bank Ag Zurich', '609148', ''],
    ['BLOM BANK FRANCE Brompton Road Sw3 1Lz', '609155', 'BLOMGB2LXXX'],
    [
      'SG KLEINWORT HAMBROS BANK (CI) LIMITED Sg Kleinwort Hambros Bk Ci',
      '609161',
      'HAMBJESXOPS'
    ],
    ['UNITED NATIONAL BANK LIMITED Utd Nat Bank Birmingham', '609166', ''],
    ['UNITED NATIONAL BANK LIMITED Utd Nat Bank Bradford', '609167', ''],
    ['UNITED NATIONAL BANK LIMITED Utd Nat Bank London', '609170', ''],
    ['UNITED NATIONAL BANK LIMITED Utd Nat Bank Manchester', '609173', ''],
    ['WYELANDS BANK PLC Creechurch Lane, Ec3a 5Eh', '609182', 'FIRBGB2LXXX'],
    [
      'CHANG HWA COMMERCIAL BANK LTD Basinghall Street Ec2v 5De',
      '609189',
      'CCBCGB2LXXX'
    ],
    ['MALAYAN BANKING BERHAD 74Coleman St London Ec2r5bn', '609192', ''],
    ['HABIB BANK ZURICH PLC 42 Moorgate', '609194', ''],
    ['HABIB BANK ZURICH PLC Stevenson Hse Manchester M1', '609195', ''],
    ['HABIB BANK ZURICH PLC Habib Bank Ag Zurich', '609196', ''],
    [
      'STANDARD CHARTERED BANK, JERSEY BRANCH St Helier Jersey Ci',
      '609199',
      'SCBLJESHXXX'
    ],
    ['STANDARD CHARTERED BANK American Express', '609200', ''],
    ['YORKSHIRE BUILDING SOCIETY Yorkshire Bs Collections 1', '609204', ''],
    ['NATIONAL WESTMINSTER BANK PLC Nat West Corporate Conv Chq', '609226', ''],
    ['NATIONAL WESTMINSTER BANK PLC Nat West Business Conv Chq', '609228', ''],
    ['NATIONAL WESTMINSTER BANK PLC Nat West Purchasing Conv Ch', '609229', ''],
    ['BANK OF MONTREAL 9 Queen Victoria St Ec4', '609230', 'BOFMGB2XXXX'],
    ['PHILIPPINE NATIONAL BANK (EUROPE) PLC London', '609241', 'PNBMGB2LXXX'],
    [
      'JPMORGAN CHASE BANK, N.A. Emea Treasury Services Oper',
      '609242',
      'CHASGB2LXXX'
    ],
    ['OVERSEA-CHINESE BANKING CORPORATION LTD London', '609259', ''],
    [
      'GHANA INTERNATIONAL BANK PLC Cheapside Ec2p 2Bb',
      '609263',
      'GHIBGB2LXXX'
    ],
    ['BANCO DE SABADELL Leaenhall Bld122 Ldhall St', '609270', ''],
    [
      'AUSTRALIA & NEW ZEALAND BANKING GROUP LTD Minerva Hse Montague plc',
      '609275',
      ''
    ],
    ['GULF INTERNATIONAL BANK (UK) LTD London', '609280', 'SINTGB2LXXX'],
    ['STANDARD CHARTERED BANK Jersey', '609281', ''],
    ['RBC EUROPE LIMITED 2 Swan Lane', '609282', 'ROYCGB2FXXX'],
    ['BANK OF INDIA Bank of India', '609287', ''],
    ['BANK OF INDIA Barking Road E6 3Ba', '609288', ''],
    ['BANK OF INDIA Melton Turn Leicester.', '609289', ''],
    ['BANK OF INDIA Booth.St Manchester.2', '609291', ''],
    ['BANK OF INDIA 293.Harrow Rd.Wembley.Middx', '609293', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '609301', ''],
    ['WEATHERBYS BANK LTD Sanders Rd Wellingborough', '609303', 'WBYSGB22XXX'],
    [
      'STATE BANK OF INDIA (UK) LIMITED 630 Finchley Rd London',
      '609357',
      'SBOIGB2LGGR'
    ],
    ['STATE BANK OF INDIA (UK) LIMITED Southall', '609358', 'SBOIGB2LSAH'],
    ['FIRST ABU DHABI BANK PJSC 90 Bishopsgate London Ec2', '609359', ''],
    ['FIRST ABU DHABI BANK PJSC Knightsbridge, Sw1x 7Ly', '609360', ''],
    ['UNITED NATIONAL BANK LIMITED Brook Street', '609368', ''],
    ['STANDARD CHARTERED BANK American Express', '609369', ''],
    ['BANK OF BARODA (UK) LIMITED City Road', '609371', 'BARBGB22XXX'],
    [
      'BANK OF BARODA (UK) LIMITED Upper Tooting Rd,Sw17 7Tj',
      '609372',
      'BARBGB22TOO'
    ],
    ['BANK OF BARODA (UK) LIMITED Aldgate East', '609373', 'BARBGB22KIL'],
    [
      'BANK OF BARODA (UK) LIMITED 86 The Broadway  Southall',
      '609374',
      'BARBGB22SOU'
    ],
    [
      'BANK OF BARODA (UK) LIMITED 63-5 Mosley St Manchester',
      '609375',
      'BARBGB22MAN'
    ],
    [
      'BANK OF BARODA (UK) LIMITED 32 Ealing Rd Middx Ha0 4Ol',
      '609376',
      'BARBGB22WEM'
    ],
    [
      'QATAR NATIONAL BANK (Q.P.S.C.) Grosvenor Street W1k 3Hh',
      '609379',
      'QNBAGB2LXXX'
    ],
    [
      "BANK OF BARODA (UK) LIMITED 175 Soho Rd H'worth B'ham",
      '609384',
      'BARBGB22BIR'
    ],
    ['HABIB BANK ZURICH PLC Whitechapel High St E1 7Pe', '609386', ''],
    ['BANK OF INDIA Finsbury Circus, Ec2m 7Dj', '609390', 'BKIDGB2LXXX'],
    ['BANGKOK BANK PUBLIC COMPANY LIMITED St Mary Axe, Ec3a 8By', '609398', ''],
    ['J P Morgan Europe Ltd Post Office Card Account 1', '609463', ''],
    ['J P Morgan Europe Ltd Post Office Card Account 2', '609464', ''],
    ['UNITED NATIONAL BANK LIMITED Utd Nat Bank Glasgow', '609466', ''],
    ['J P Morgan Europe Ltd Post Office Card Account 3', '609467', ''],
    ['J P Morgan Europe Ltd Post Office Card Account 4', '609468', ''],
    ['STANDARD CHARTERED BANK London', '609470', ''],
    ['ICBC (LONDON) PLC Icbc (London) Ltd', '609473', ''],
    ['Nottingham Building Society Nottingham Sbs Bldg Society', '609474', ''],
    ['STATE BANK OF INDIA (UK) LIMITED Birmingham', '609475', 'SBOIGB2LBIR'],
    ['STATE BANK OF INDIA (UK) LIMITED Manchester', '609476', 'SBOIGB2LMAN'],
    ['STATE BANK OF INDIA (UK) LIMITED Leicester', '609477', 'SBOIGB2LLEI'],
    ['NATIONAL WESTMINSTER BANK PLC Advantage Visa Gold', '609478', ''],
    ['NATIONAL WESTMINSTER BANK PLC Dual Gold Visa', '609479', ''],
    ['NATIONAL WESTMINSTER BANK PLC Dual Gold M/Card', '609480', ''],
    ['NATIONAL WESTMINSTER BANK PLC Mastercard Classic', '609482', ''],
    ['NATIONAL WESTMINSTER BANK PLC Visa Classic', '609483', ''],
    ['NATIONAL WESTMINSTER BANK PLC Mastercard Platinum', '609485', ''],
    ['NATIONAL WESTMINSTER BANK PLC Advantage Premier', '609486', ''],
    ['NATIONAL WESTMINSTER BANK PLC Visa Primary', '609487', ''],
    ['NATIONAL WESTMINSTER BANK PLC Mastercard Loans', '609488', ''],
    ['NATIONAL WESTMINSTER BANK PLC Dual Visa', '609489', ''],
    ['NATIONAL WESTMINSTER BANK PLC Dual Visa M/C', '609490', ''],
    ['NATIONAL WESTMINSTER BANK PLC Black Card', '609491', ''],
    ['BANK OF TAIWAN London Branch', '609492', 'BKTWGB2LXXX'],
    ['NATIONAL WESTMINSTER BANK PLC Natwest One Card M/C Conv', '609493', ''],
    ['NATIONAL WESTMINSTER BANK PLC Natwest Co Card Visa Conv', '609494', ''],
    ['MARSDEN BUILDING SOCIETY Nelson Lancashire', '609495', ''],
    ['NATIONAL WESTMINSTER BANK PLC Airmiles Mastercrd Con Chq', '609496', ''],
    ['NATIONAL WESTMINSTER BANK PLC NW Visa Class Conv Chq', '609497', ''],
    ['BANK OF BARODA (UK) LIMITED Leicester', '609498', 'BARBGB22LEI'],
    ['HABIB BANK ZURICH PLC Harrow -on-the-Hill', '609499', ''],
    [
      'PUNJAB NATIONAL BANK (INTERNATIONAL) LTD Southall',
      '609500',
      'PUNBGB22XXX'
    ],
    [
      'BNP PARIBAS SECURITIES SERVICES S.C.A., JERSEY BRANCH Bnp Paribas Sec Serv Jsy',
      '609506',
      'PARBJESHXXX'
    ],
    ['HBL BANK UK LIMITED T/A HBL BANK UK London', '609511', ''],
    ['BANK OF INDIA Glasgow', '609515', ''],
    ['HABIB BANK ZURICH PLC Bradford', '609516', ''],
    ['UNITED NATIONAL BANK LIMITED Ilford', '609520', ''],
    ['NEDBANK  LIMITED Abchurch Lane, Ec4n 7Ad', '609523', ''],
    ['J P Morgan Europe Ltd Post Office Card Account 5', '609524', ''],
    ['TURKIYE IS BANKASI AS London Branch', '609528', ''],
    ['HABIB BANK ZURICH PLC Sparkhill', '609532', ''],
    ['HANDELSBANKEN PLC London', '609534', ''],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Jersey',
      '609541',
      'RBOSJESHXXX'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Guernsey',
      '609542',
      'RBOSGGSXXXX'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Isle of Man',
      '609543',
      'RBOSIMDDXXX'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND INTERNATIONAL LTD Gibraltar',
      '609544',
      'RBOSGIGIXXX'
    ],
    [
      'IOM BANK TRADING NAME OF ROYAL BANK OF SCOTLAND INTERNATIONAL LIMITED Douglas',
      '609545',
      'RBOSIMD2XXX'
    ],
    ['HABIB BANK ZURICH PLC Tooting', '609549', ''],
    ['BANK OF BARODA (UK) LIMITED Kenton Road', '609557', 'BARBGB22KEN'],
    ['STATE BANK OF INDIA (UK) LIMITED Harrow', '609558', 'SBOIGB2LHAR'],
    ['HABIB BANK ZURICH PLC Wigmore Street Branch', '609559', ''],
    ['JPMORGAN CHASE BANK, N.A. Jp Morgan Chase Na', '609561', 'CHASGB2LVR1'],
    ['JPMORGAN CHASE BANK, N.A. Jpm Chaps Returns', '609576', 'CHASGB2LRTN'],
    ['STATE BANK OF INDIA (UK) LIMITED East Ham', '609578', 'SBOIGB2LEHM'],
    ['BANK OF BARODA (UK) LIMITED Ilford Branch', '609580', 'BARBGB22ILF'],
    [
      'STATE BANK OF INDIA (UK) LIMITED Coventry Branch',
      '609581',
      'SBOIGB2LCOV'
    ],
    [
      'STATE BANK OF INDIA (UK) LIMITED Wolverhampton Branch',
      '609582',
      'SBOIGB2LWOL'
    ],
    ['NRAM LIMITED Nram Limited', '609583', ''],
    ['FURNESS BUILDING SOCIETY Furness Building Society', '609585', ''],
    [
      'Cambridge & Counties Bank Limited Cambridge & Counties Bk Ltd',
      '609586',
      ''
    ],
    ['BANQUE HAVILLAND SA City of London Office', '609587', 'HAVLGB2LXXX'],
    [
      'BNP PARIBAS SECURITIES SERVICES London (Clients)',
      '609588',
      'PARBGB2LXXX'
    ],
    ['BRADFORD & BINGLEY LIMITED Mortgage Express', '609589', ''],
    ['BRADFORD & BINGLEY LIMITED Bradford & Bingley Coll', '609590', ''],
    ['JP MORGAN BANK LUXEMBOURG S.A. Private Bank', '609591', 'JPMGGB2LXXX'],
    ['YORKSHIRE BUILDING SOCIETY Yorkshire Build Soc Br Np', '609592', ''],
    ['Nottingham Building Society Head Office', '609593', ''],
    ['VANQUIS BANK LIMITED Vanquis Visa Card', '609594', ''],
    ['YORKSHIRE BUILDING SOCIETY Chelsea Bs Coll 1', '609595', ''],
    ['NATIONAL WESTMINSTER BANK PLC Dry Run Bank', '609596', ''],
    ['NATIONAL WESTMINSTER BANK PLC Clearing Services', '609700', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '609721', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '609735', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '609742', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '609758', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '609767', ''],
    ['NATIONAL WESTMINSTER BANK PLC Charitable Appeal No 2', '609794', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cr/Clg Dept Coll A/C Sect', '620044', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cr/Clg Dept Coll A/C Sect', '620058', ''],
    ['NATIONAL WESTMINSTER BANK PLC Cr/Clg Dept Coll A/C Sect', '620059', ''],
    ['SILICON VALLEY BANK Silicon Valley Bank London', '621000', 'SVBKGB2LXXX'],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '621012', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '621037', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621058', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621109', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621112', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621113', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621118', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621128', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621129', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621130', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621131', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621132', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621133', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621134', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621143', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621151', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621161', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621169', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621179', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '621208', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621272', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621286', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621305', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621306', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '621328', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621335', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '621348', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '621350', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621352', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621705', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621706', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621707', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621708', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621709', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621710', ''],
    ['YORKSHIRE BUILDING SOCIETY Yorkshire Bs Collections 4', '621719', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621728', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hocollection Account', '621761', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621765', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '621797', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC NW Global Lending, London',
      '621843',
      'NWBKGB2LGLO'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621873', ''],
    ['NATIONAL WESTMINSTER BANK PLC H.O Collection Account', '621880', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621887', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621894', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621895', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '621908', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '621914', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '621970', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622005', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622006', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622009', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622013', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622017', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622050', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622051', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622052', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622083', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622085', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622094', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '622102', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '622103', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622104', ''],
    [
      'MITSUBISHI UFJ TRUST & BANKING CORPORATION 1st Fl 24 Lombard St Ec39aj',
      '622111',
      'MTBCGB2LXXX'
    ],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622139', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622145', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '622147', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622150', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622152', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '622155', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho.Collection Account', '622159', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622160', ''],
    ['YORKSHIRE BUILDING SOCIETY Yorks Hse Westgate Bradford', '622179', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622197', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622198', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622201', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622202', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622203', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622204', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622205', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622206', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622207', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622230', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622231', ''],
    ['NATIONAL WESTMINSTER BANK PLC H.O. Collection Account', '622232', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622250', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622251', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622255', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622259', ''],
    ['ABC INTERNATIONAL BANK PLC 1-5 Moorgate London Ec2r6ab', '622294', ''],
    ['RBC EUROPE LIMITED 2 Swan Lane', '622297', ''],
    ['YORKSHIRE BUILDING SOCIETY Yorkshire Bs Collections 3', '622337', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622357', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622359', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622368', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622370', ''],
    ['BLOM BANK FRANCE 193/195 Brompton Rd Sw3 1Lz', '622378', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622387', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622388', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622389', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622390', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622391', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Ac', '622393', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622395', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622400', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622411', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622413', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622436', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622442', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622448', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622457', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622476', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '622479', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622487', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622488', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '622491', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '622492', ''],
    ['NATIONAL WESTMINSTER BANK PLC H/O Collection A/C', '622494', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622497', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '622499', ''],
    ['NATIONAL WESTMINSTER BANK PLC H/O Collection A/C', '622501', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '622504', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '622505', ''],
    ['NATIONAL WESTMINSTER BANK PLC H/O Collection A/C', '622512', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '622513', ''],
    ['NATIONAL WESTMINSTER BANK PLC H/O Collection A/C', '622514', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622526', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection A/C', '622528', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622546', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622561', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622562', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622566', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622571', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622574', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622586', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622587', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622600', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622602', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622603', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622604', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622610', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622616', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622617', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '622618', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622620', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection  Account', '622623', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '622626', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622637', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622645', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622647', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622657', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622658', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622659', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622660', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622661', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622670', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622672', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622677', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622678', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622680', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622685', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622690', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622696', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622699', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622700', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622711', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622712', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622718', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622721', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622722', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622726', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622729', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622736', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622738', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622739', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622740', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622741', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622742', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622743', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622744', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622745', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622751', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622754', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622756', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622762', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622763', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622765', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622769', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622770', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622773', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622776', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622777', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622780', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622785', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622789', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622790', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622791', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622792', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622799', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622800', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622802', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622808', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622809', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622810', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622812', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622816', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622818', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622821', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622824', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622825', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622826', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622828', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622831', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622832', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622837', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622838', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622839', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622841', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '622844', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622848', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622849', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622850', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622859', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622860', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622862', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622864', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622865', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622866', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622867', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622868', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622871', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622872', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622874', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622880', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622884', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622885', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622886', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622888', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622889', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622890', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622899', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622900', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622905', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622907', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622908', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622909', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622910', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622911', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622912', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622913', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622914', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622917', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622919', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622927', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '622928', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '622929', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Colllection Account', '622932', ''],
    ['YORKSHIRE BUILDING SOCIETY Accord Mortgages', '622933', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622934', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622936', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622937', ''],
    ['NATIONAL WESTMINSTER BANK PLC H.O Collection Account', '622938', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622943', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622946', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622948', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622949', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '622951', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622953', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622954', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622967', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622968', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622973', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622975', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622976', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622978', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622979', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622980', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622981', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622982', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '622983', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622993', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622994', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622997', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '622999', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623003', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623004', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623006', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623012', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623016', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623017', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623019', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623020', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '623021', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '623022', ''],
    ['CO-OPERATIVE BANK T/A BRITANNIA Ho Collection Account', '623023', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623025', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '623026', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623028', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623029', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623030', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection A/C', '623031', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623034', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Coll Acc', '623035', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Coll Acc', '623036', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '623037', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623038', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acct', '623039', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623043', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623044', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623045', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623046', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623047', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623048', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623049', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acct', '623051', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623052', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623054', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623056', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623057', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623058', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623060', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623064', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623065', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623066', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623067', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623068', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623069', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623079', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623080', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection', '623083', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623085', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Coll A/C', '623087', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623088', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623089', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Coll A/C', '623090', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623092', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623093', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623094', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623095', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623098', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623099', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623100', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623101', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O  Collection Account', '623102', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623105', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623110', ''],
    ['YORKSHIRE BUILDING SOCIETY Yorkshire Bs Collections 5', '623111', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623112', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623113', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623114', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623115', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623116', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '623117', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623118', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623121', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623122', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623123', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623124', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623126', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623129', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623131', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623133', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623135', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623137', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623138', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623139', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623140', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623141', ''],
    ['NATIONAL WESTMINSTER BANK PLC Hoca', '623142', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623143', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '623144', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Acc', '623147', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623149', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623150', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623156', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623157', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623158', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623160', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623161', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623170', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623171', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623172', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623174', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623175', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623176', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623177', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623178', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623179', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623180', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623184', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623185', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623187', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623188', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623189', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623190', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623191', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623192', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623193', ''],
    ['NATIONAL WESTMINSTER BANK PLC H O Collection Account', '623194', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acct', '623195', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623196', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623198', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623199', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623200', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623201', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623246', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623247', ''],
    ['NATIONAL WESTMINSTER BANK PLC Head Office Collection Acc', '623248', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '623250', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '623251', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '623252', ''],
    ['NATIONAL WESTMINSTER BANK PLC Collection Account', '623253', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho  Collection Account', '624000', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '624001', ''],
    ['NATIONAL WESTMINSTER BANK PLC Ho Collection Account', '624002', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC Global Fin Markets (M&Ib)',
      '700119',
      'NWBKGB2LTSY'
    ],
    ['BANK OF CHINA LIMITED 8/10 Mansion Hse Place Ec4', '700204', ''],
    [
      'THE BANK OF NEW YORK MELLON Global Payments & Tsy Svs',
      '700225',
      'IRVTGB2XXXX'
    ],
    [
      'QATAR NATIONAL BANK (Q.P.S.C.) Qatar National Bank, London',
      '700240',
      ''
    ],
    ['HBL BANK UK LIMITED T/A HBL BANK UK London', '700319', 'HABBGB2LXXX'],
    [
      'THE BANK OF NEW YORK MELLON INTERNATIONAL LTD Global Payments & Tsy Svs',
      '700518',
      'SGWLGB22XXX'
    ],
    [
      'BANGKOK BANK PUBLIC COMPANY LIMITED London Gresham St',
      '700603',
      'BKKBGB2LXXX'
    ],
    ['VTB Capital Plc 24/32 King St Ec2', '700631', ''],
    [
      'MALAYAN BANKING BERHAD 74 Coleman St London Ec2r',
      '700633',
      'MBBEGB2LXXX'
    ],
    ['HABIB BANK ZURICH PLC Habib Bank Ag Zurich', '700672', 'HBZUGB2LXXX'],
    [
      'Credit Industriel et Commercial 125 Finsburypavement London',
      '700808',
      'CMCIGB2LXXX'
    ],
    ['ING BANK NV 2 Copthall Ave London Ec2', '701346', ''],
    ['UNITED NATIONAL BANK LIMITED Sparkhill, Birmingham', '701434', ''],
    ['UNITED NATIONAL BANK LIMITED Manchester', '701445', ''],
    ['UNITED NATIONAL BANK LIMITED Manchester', '701580', ''],
    ['WOORI BANK Iscd Apacs Control', '709014', ''],
    ['GULF INTERNATIONAL BANK BSC Iscd Apacs Control', '709015', ''],
    ['HSBC BANK PLC Iscd Apacs Control', '709020', ''],
    ['Santander UK plc Santander 720000', '720000', 'ALEIGB22XXX'],
    ['Santander UK plc Santander 720001', '720001', ''],
    ['Santander UK plc Santander 720002', '720002', ''],
    ['Santander UK plc Santander 720003', '720003', ''],
    ['Santander UK plc Santander 720004', '720004', ''],
    ['Santander UK plc Santander 720005', '720005', ''],
    ['Santander UK plc Santander 720006', '720006', ''],
    ['Santander UK plc Santander 720007', '720007', ''],
    ['Santander UK plc Santander 720008', '720008', ''],
    ['Santander UK plc Santander 720009', '720009', ''],
    ['Santander UK plc Santander 720100', '720100', 'ALEIGB22TSY'],
    ['Santander UK plc Santander 720200', '720200', ''],
    ['Santander UK plc Santander 720250', '720250', ''],
    ['Santander UK plc Santander 720344', '720344', ''],
    ['Santander UK plc Santander 720346', '720346', ''],
    ['Santander UK plc Santander 720354', '720354', ''],
    ['Santander UK plc Santander 720356', '720356', ''],
    ['Santander UK plc Santander 720600', '720600', ''],
    ['Santander UK plc Santander 720601', '720601', ''],
    ['Santander UK plc Santander 720602', '720602', ''],
    ['Santander UK plc Santander 720603', '720603', ''],
    ['Santander UK plc Santander 720604', '720604', ''],
    ['Santander UK plc Santander 720605', '720605', ''],
    ['Santander UK plc Santander 720606', '720606', ''],
    ['Santander UK plc Santander 720610', '720610', ''],
    ['Santander UK plc Santander 720611', '720611', ''],
    ['Santander UK plc Santander 720612', '720612', ''],
    ['Santander UK plc Santander 720613', '720613', ''],
    ['Santander UK plc Santander 720614', '720614', ''],
    ['Santander UK plc Santander 720615', '720615', ''],
    ['Santander UK plc Santander 720616', '720616', ''],
    ['Santander UK plc Santander 720622', '720622', ''],
    ['Santander UK plc Santander 720626', '720626', ''],
    ['Santander UK plc Santander 720656', '720656', ''],
    ['Santander UK plc Santander', '720662', ''],
    ['Santander UK plc Santander 720694', '720694', ''],
    ['Santander UK plc Santander 720696', '720696', ''],
    ['Santander UK plc Santander 721000', '721000', ''],
    ['Santander UK plc Santander 721001', '721001', ''],
    ['Santander UK plc Santander 721002', '721002', ''],
    ['Santander UK plc Santander 721003', '721003', ''],
    ['Santander UK plc Santander 721004', '721004', ''],
    ['Santander UK plc Santander 721005', '721005', ''],
    ['Santander UK plc Santander 721006', '721006', ''],
    ['Santander UK plc Santander 721050', '721050', ''],
    ['Santander UK plc Santander', '721200', ''],
    ['Santander UK plc Santander 721360', '721360', ''],
    ['Santander UK plc Santander 721370', '721370', ''],
    ['Santander UK plc Santander 721380', '721380', ''],
    ['Santander UK plc Santander 721400', '721400', ''],
    ['Santander UK plc Santander 721450', '721450', ''],
    ['Santander UK plc Santander 721451', '721451', ''],
    ['Santander UK plc Santander 721452', '721452', ''],
    ['Santander UK plc Santander 721453', '721453', ''],
    ['Santander UK plc Santander 721454', '721454', ''],
    ['Santander UK plc Santander 721455', '721455', ''],
    ['Santander UK plc Santander 721456', '721456', ''],
    ['Santander UK plc Santander 721457', '721457', ''],
    ['Santander UK plc Santander 721458', '721458', ''],
    ['Santander UK plc Santander 721459', '721459', ''],
    ['Santander UK plc Santander 721500', '721500', ''],
    ['Santander UK plc Santander 721551', '721551', ''],
    ['Santander UK plc Santander 721552', '721552', ''],
    ['Santander UK plc Santander 721553', '721553', ''],
    ['Santander UK plc Santander 721554', '721554', ''],
    ['Santander UK plc Santander 721555', '721555', ''],
    ['Santander UK plc Santander 721556', '721556', ''],
    ['Santander UK plc Santander 721557', '721557', ''],
    ['Santander UK plc Santander 721558', '721558', ''],
    ['Santander UK plc Santander 721559', '721559', ''],
    ['Santander UK plc Santander 721600', '721600', ''],
    ['Santander UK plc Santander 724300', '724300', ''],
    ['Santander UK plc Santander 724440', '724440', ''],
    ['Santander UK plc Santander 724444', '724444', ''],
    ['Santander UK plc Santander 725000', '725000', ''],
    ['Santander UK plc Santander 725001', '725001', ''],
    ['Santander UK plc Santander 725002', '725002', ''],
    ['Santander UK plc Santander 725003', '725003', ''],
    ['Santander UK plc Santander 725004', '725004', ''],
    ['Santander UK plc Santander 725005', '725005', ''],
    ['Santander UK plc Santander 725006', '725006', ''],
    ['Santander UK plc Santander 725252', '725252', 'ALEIGB22MTG'],
    ['Santander UK plc Santander 725611', '725611', ''],
    ['Santander UK plc Santander 725612', '725612', ''],
    ['Santander UK plc Santander 725613', '725613', ''],
    ['Santander UK plc Santander 725614', '725614', ''],
    ['Santander UK plc Santander 725615', '725615', ''],
    ['Santander UK plc Santander 725616', '725616', ''],
    ['Santander UK plc Santander 725810', '725810', ''],
    ['Santander UK plc Santander 725826', '725826', ''],
    ['Santander UK plc Santander 725920', '725920', ''],
    ['Santander UK plc Santander 725935', '725935', ''],
    ['Santander UK plc Santander 726000', '726000', ''],
    ['Santander UK plc Santander 726001', '726001', ''],
    ['Santander UK plc Santander 726002', '726002', ''],
    ['Santander UK plc Santander 726003', '726003', ''],
    ['Santander UK plc Santander 726004', '726004', ''],
    ['Santander UK plc Santander 726005', '726005', ''],
    ['Santander UK plc Santander 726006', '726006', ''],
    ['Santander UK plc Santander 726611', '726611', ''],
    ['Santander UK plc Santander 726612', '726612', ''],
    ['Santander UK plc Santander 726613', '726613', ''],
    ['Santander UK plc Santander 726614', '726614', ''],
    ['Santander UK plc Santander 726615', '726615', ''],
    ['Santander UK plc Santander 726616', '726616', ''],
    ['Santander UK plc Santander 728888', '728888', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770000', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770001', 'LOYDGB21T00'],
    ['LLOYDS BANK PLC Automated Clearings', '770002', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770003', ''],
    ['LLOYDS BANK PLC Chaps-Repair Sort Code', '770007', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770030', ''],
    ['LLOYDS BANK PLC Visa Travellers Cheque Dept', '770032', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770075', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770080', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770082', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770091', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770093', ''],
    ['LLOYDS BANK PLC Lloyds Bank Clearing Centre', '770097', ''],
    ['LLOYDS BANK PLC Ashton-under-Lyne (770101)', '770101', 'LOYDGB21T05'],
    ['LLOYDS BANK PLC Hyde', '770102', 'LOYDGB21T06'],
    ['LLOYDS BANK PLC Ashton-under-Lyne (770103)', '770103', 'LOYDGB21T07'],
    ['TSB BANK PLC Manchester, Denton', '770104', ''],
    ['LLOYDS BANK PLC Oldham (770105)', '770105', 'LOYDGB21P91'],
    ['LLOYDS BANK PLC Ashton-under-Lyne (770106)', '770106', 'LOYDGB21P92'],
    ['LLOYDS BANK PLC Glossop (770107)', '770107', 'LOYDGB21P93'],
    ['LLOYDS BANK PLC Oldham (770110)', '770110', 'LOYDGB21P94'],
    ['LLOYDS BANK PLC Rochdale  (770111)', '770111', 'LOYDGB21P95'],
    ['LLOYDS BANK PLC Oldham (770113)', '770113', 'LOYDGB21P96'],
    ['LLOYDS BANK PLC Oldham (770114)', '770114', 'LOYDGB21P97'],
    ['LLOYDS BANK PLC Bolton Hotel Street', '770201', 'LOYDGB21P98'],
    ['LLOYDS BANK PLC Bolton Hotel Street', '770202', 'LOYDGB21T08'],
    ['TSB BANK PLC Bolton, Daubhill', '770204', ''],
    ['LLOYDS BANK PLC Bolton Hotel Street', '770207', 'LOYDGB21T19'],
    ['LLOYDS BANK PLC Bolton Farnworth (770211)', '770209', 'LOYDGB21T20'],
    ['TSB BANK PLC Bolton, Horwich', '770210', ''],
    ['LLOYDS BANK PLC Bolton Farnworth (770211)', '770211', 'LOYDGB21T22'],
    ['LLOYDS BANK PLC Walkden (770212)', '770212', 'LOYDGB21T23'],
    ['LLOYDS BANK PLC Bolton  Westhoughton', '770213', 'LOYDGB21T24'],
    ['LLOYDS BANK PLC Bolton  Halliwell', '770214', 'LOYDGB21T25'],
    ['LLOYDS BANK PLC Walkden (770215)', '770215', 'LOYDGB21T26'],
    ['LLOYDS BANK PLC Lloyds Udt', '770318', ''],
    ['LLOYDS BANK PLC Bury (770401)', '770401', 'LOYDGB21T09'],
    ['LLOYDS BANK PLC Bury (770403)', '770403', 'LOYDGB21T10'],
    ['TSB BANK PLC Manchester, Radcliffe', '770404', ''],
    ['LLOYDS BANK PLC Bury (770405)', '770405', 'LOYDGB21T12'],
    ['LLOYDS BANK PLC Biab Test (770410)', '770410', ''],
    ['LLOYDS BANK PLC St Austell Hub (770411)', '770411', 'LOYDGB21F13'],
    ['LLOYDS BANK PLC Camarthen Hub (770412)', '770412', 'LOYDGB21F14'],
    ['LLOYDS BANK PLC Totnes Hub (770413)', '770413', 'LOYDGB21F15'],
    ['LLOYDS BANK PLC Ashford Hub (770414)', '770414', 'LOYDGB21F35'],
    ['LLOYDS BANK PLC Driffield Hub (770415)', '770415', 'LOYDGB21F36'],
    ['LLOYDS BANK PLC Witney Hub (770416)', '770416', 'LOYDGB21F37'],
    ['LLOYDS BANK PLC Alcester Hub (770417)', '770417', 'LOYDGB21F47'],
    ['LLOYDS BANK PLC Thetford Hub (770418)', '770418', 'LOYDGB21F48'],
    ['LLOYDS BANK PLC Colchester Hub (770419)', '770419', 'LOYDGB21F49'],
    ['LLOYDS BANK PLC Newton Abbot Hub (770420)', '770420', 'LOYDGB21F50'],
    ['LLOYDS BANK PLC Workington Hub (770421)', '770421', 'LOYDGB21F51'],
    ['LLOYDS BANK PLC Bognor Regis Hub (770422)', '770422', 'LOYDGB21F52'],
    ['LLOYDS BANK PLC Louth Hub (770423)', '770423', 'LOYDGB21F53'],
    ['LLOYDS BANK PLC Lloydscontingency3 (770424)', '770424', 'LOYDGB21F54'],
    ['LLOYDS BANK PLC Newport Iow Hub (770425)', '770425', 'LOYDGB21F16'],
    ['LLOYDS BANK PLC Ebbw Vale Hub (770426)', '770426', 'LOYDGB21F17'],
    ['LLOYDS BANK PLC Chester-le-St Hub (770427)', '770427', 'LOYDGB21F18'],
    ['LLOYDS BANK PLC Cirencester Hub (770428)', '770428', 'LOYDGB21F19'],
    ['LLOYDS BANK PLC Haverfordwest Hub (770429)', '770429', 'LOYDGB21F20'],
    ['LLOYDS BANK PLC Crc - Lloyds', '770430', ''],
    ['LLOYDS BANK PLC Mobile Branch 770431', '770431', 'LOYDGB21F71'],
    ['LLOYDS BANK PLC Mobile Branch 770432', '770432', 'LOYDGB21F72'],
    ['LLOYDS BANK PLC Mobile Branch 770433', '770433', 'LOYDGB21F73'],
    ['LLOYDS BANK PLC Mobile Branch 770434', '770434', 'LOYDGB21F74'],
    ['LLOYDS BANK PLC Mobile Branch 770436', '770436', 'LOYDGB21F75'],
    ['LLOYDS BANK PLC Mobile Branch 770437', '770437', 'LOYDGB21F76'],
    ['LLOYDS BANK PLC Lloyds Bank plc', '770438', 'LOYDGB21F81'],
    ['LLOYDS BANK PLC Ib 770439', '770439', 'LOYDGB21F87'],
    ['LLOYDS BANK PLC Ib 770440', '770440', 'LOYDGB21F88'],
    ['LLOYDS BANK PLC Barnstaple Hub (770441)', '770441', ''],
    ['LLOYDS BANK PLC Okehamptonhub (770442)', '770442', ''],
    ['LLOYDS BANK PLC Newquay Hub (770443)', '770443', ''],
    ['LLOYDS BANK PLC Cranbrook Hub (770457)', '770444', 'LOYDGB21F24'],
    ['LLOYDS BANK PLC Tunbridge Wells', '770445', 'LOYDGB21F60'],
    ['LLOYDS BANK PLC Devizes Hub (770446)', '770446', 'LOYDGB21F61'],
    ['LLOYDS BANK PLC Mobile Branch 770447', '770447', 'LOYDGB21F62'],
    ['LLOYDS BANK PLC Mobile Branch 770448', '770448', 'LOYDGB21F63'],
    ['LLOYDS BANK PLC Mobile Branch 770449', '770449', 'LOYDGB21F64'],
    ['LLOYDS BANK PLC Chesterfield Hub (770457)', '770457', 'LOYDGB21F25'],
    ['LLOYDS BANK PLC Prestonfulwood Hub (770458)', '770458', 'LOYDGB21F26'],
    ['LLOYDS BANK PLC Gillingham Hub (770459)', '770459', 'LOYDGB21F27'],
    ['LLOYDS BANK PLC Mobile Branch - Conting', '770460', ''],
    ['LLOYDS BANK PLC Moretoninmarsh Hub (770461)', '770461', 'LOYDGB21F28'],
    ['LLOYDS BANK PLC Univ of Cambridge (770502)', '770502', 'LOYDGB21T13'],
    ['LLOYDS BANK PLC Hitchin (770503)', '770503', 'LOYDGB21T14'],
    ['LLOYDS BANK PLC Letchworth (770504)', '770504', 'LOYDGB21T15'],
    ['LLOYDS BANK PLC Newmarket (770505)', '770505', 'LOYDGB21T16'],
    ['LLOYDS BANK PLC Biggleswade (770506)', '770506', 'LOYDGB21T17'],
    ['LLOYDS BANK PLC Haverhill  47 High St', '770507', 'LOYDGB21P62'],
    ['LLOYDS BANK PLC Saffron Walden  (770508)', '770508', 'LOYDGB21P63'],
    ['LLOYDS BANK PLC Welwyn Garden City (770509)', '770509', 'LOYDGB21P64'],
    ['LLOYDS BANK PLC Cambs Chesterton Rd 770510', '770510', 'LOYDGB21P65'],
    ['TSB BANK PLC Stevenage', '770511', ''],
    ['LLOYDS BANK PLC Bishops Stortford (770512)', '770512', 'LOYDGB21P67'],
    ['LLOYDS BANK PLC Hoddesdon (770513)', '770513', 'LOYDGB21P68'],
    ['LLOYDS BANK PLC Hoddesdon (770514)', '770514', 'LOYDGB21P69'],
    ['TSB BANK PLC Stevenage', '770515', ''],
    ['LLOYDS BANK PLC Royston (770517)', '770517', 'LOYDGB21P53'],
    ['LLOYDS BANK PLC University of Cambridge', '770518', 'LOYDGB21P54'],
    ['TSB BANK PLC Chorley', '770601', ''],
    ['TSB BANK PLC Leyland', '770605', ''],
    ['TSB BANK PLC Chorley', '770606', ''],
    ['LLOYDS BANK PLC High St Coventry (770701)', '770701', 'LOYDGB21P58'],
    ['TSB BANK PLC Bedworth', '770702', ''],
    ['TSB BANK PLC Coventry, Jubilee Crescent', '770703', ''],
    ['LLOYDS BANK PLC High St Coventry (770704)', '770704', 'LOYDGB21P61'],
    ['TSB BANK PLC Coventry, Walsgrave Road', '770705', ''],
    ['LLOYDS BANK PLC Rugby (770706)', '770706', 'LOYDGB21P81'],
    ['LLOYDS BANK PLC Nuneaton  (770707)', '770707', 'LOYDGB21P82'],
    ['LLOYDS BANK PLC Lloyds  Bank plc', '770708', 'LOYDGB21P83'],
    ['TSB BANK PLC Coventry, Jubilee Crescent', '770709', ''],
    ['LLOYDS BANK PLC Stratford-U-Avon (770710)', '770710', 'LOYDGB21P85'],
    ['LLOYDS BANK PLC High St Coventry (770713)', '770713', 'LOYDGB21P86'],
    ['LLOYDS BANK PLC Kenilworth (770714)', '770714', 'LOYDGB21P87'],
    ['LLOYDS BANK PLC Leamington Spa(770715)', '770715', 'LOYDGB21P88'],
    ['LLOYDS BANK PLC High St Coventry (770716)', '770716', 'LOYDGB21B98'],
    ['LLOYDS BANK PLC Derby St Peters Street', '770801', 'LOYDGB21P89'],
    ['LLOYDS BANK PLC Ashbourne 770802', '770802', 'LOYDGB21P72'],
    ['LLOYDS BANK PLC Rose Hill Chesterfield', '770803', 'LOYDGB21P73'],
    ['LLOYDS BANK PLC Belper', '770804', 'LOYDGB21P74'],
    ['LLOYDS BANK PLC Derby Easibank', '770805', 'LOYDGB21B96'],
    ['TSB BANK PLC Derby, Normanton Road', '770806', ''],
    ['LLOYDS BANK PLC Derby  Allenton', '770807', 'LOYDGB21P76'],
    ['LLOYDS BANK PLC Ilkeston', '770808', 'LOYDGB21P77'],
    ['LLOYDS BANK PLC Ripley', '770809', 'LOYDGB21P78'],
    ['TSB BANK PLC Burton-on-Trent', '770811', ''],
    ['LLOYDS BANK PLC Swadlincote (770812)', '770812', 'LOYDGB21P80'],
    ['LLOYDS BANK PLC Ripley (770813)', '770813', 'LOYDGB21T27'],
    ['LLOYDS BANK PLC Alfreton', '770814', 'LOYDGB21T74'],
    ['LLOYDS BANK PLC Matlock', '770815', 'LOYDGB21T75'],
    ['LLOYDS BANK PLC Derby St Peters Street', '770816', 'LOYDGB21T76'],
    ['LLOYDS BANK PLC Royal Par Plymouth 770901', '770901', 'LOYDGB21T77'],
    ['LLOYDS BANK PLC St Budeaux (770903)', '770903', 'LOYDGB21T78'],
    ['LLOYDS BANK PLC Royal Pde Plymouth (770904)', '770904', 'LOYDGB21T79'],
    ['TSB BANK PLC TSB Crownhill', '770905', ''],
    ['LLOYDS BANK PLC St Budeaux (770906)', '770906', 'LOYDGB21T81'],
    ['TSB BANK PLC Tavistock', '770907', ''],
    ['LLOYDS BANK PLC Launceston (770908)', '770908', 'LOYDGB21T65'],
    ['TSB BANK PLC Bude', '770909', ''],
    ['LLOYDS BANK PLC Bodmin (770910)', '770910', 'LOYDGB21T67'],
    ['LLOYDS BANK PLC St Austell (770911)', '770911', 'LOYDGB21T68'],
    ['LLOYDS BANK PLC Boscawen St. Truro (770912)', '770912', 'LOYDGB21T69'],
    ['LLOYDS BANK PLC Falmouth (770913)', '770913', 'LOYDGB21T70'],
    ['LLOYDS BANK PLC Camborne  Commercial St', '770914', 'LOYDGB21T71'],
    ['LLOYDS BANK PLC Redruth (770915)', '770915', 'LOYDGB21T72'],
    ['LLOYDS BANK PLC Helston 770916', '770916', 'LOYDGB21T73'],
    ['LLOYDS BANK PLC Penzance  Market Pl', '770917', 'LOYDGB21T83'],
    ['LLOYDS BANK PLC Royal Par Plymouth 770918', '770918', 'LOYDGB21T93'],
    ['TSB BANK PLC TSB Crownhill', '770919', ''],
    ['LLOYDS BANK PLC Royal Pde Plymouth (770920)', '770920', 'LOYDGB21T95'],
    ['LLOYDS BANK PLC Plympton (770921)', '770921', 'LOYDGB21T96'],
    ['LLOYDS BANK PLC Plymstock (770923)', '770923', 'LOYDGB21T97'],
    ['LLOYDS BANK PLC Kingsbridge (770924)', '770924', 'LOYDGB21T98'],
    ['LLOYDS BANK PLC Bodmin (770925)', '770925', 'LOYDGB21T99'],
    ['LLOYDS BANK PLC Exeter Bedford St', '771001', 'LOYDGB21W00'],
    ['LLOYDS BANK PLC Exeter Bedford St (771002)', '771002', 'LOYDGB21W01'],
    ['TSB BANK PLC Barnstaple', '771003', ''],
    ['LLOYDS BANK PLC Bideford (771004)', '771004', 'LOYDGB21T85'],
    ['TSB BANK PLC Exmouth', '771005', ''],
    ['LLOYDS BANK PLC Honiton (771006)', '771006', 'LOYDGB21T87'],
    ['LLOYDS BANK PLC Newton Abbot  (771007)', '771007', 'LOYDGB21T88'],
    ['LLOYDS BANK PLC Okehampton  East St', '771008', 'LOYDGB21T89'],
    ['LLOYDS BANK PLC Palace Av Paignton 771009', '771009', 'LOYDGB21T90'],
    ['LLOYDS BANK PLC Fleet St Torquay (771010)', '771010', 'LOYDGB21T91'],
    ['LLOYDS BANK PLC Sidmouth (771011)', '771011', 'LOYDGB21T92'],
    ['LLOYDS BANK PLC Sidmouth (771012)', '771012', 'LOYDGB21T37'],
    ['LLOYDS BANK PLC Teignmouth', '771013', 'LOYDGB21T38'],
    ['LLOYDS BANK PLC Tiverton (771014)', '771014', 'LOYDGB21T39'],
    ['LLOYDS BANK PLC Fleet St Torquay (771015)', '771015', 'LOYDGB21T40'],
    ['LLOYDS BANK PLC Chard 42 Fore St (771016)', '771016', 'LOYDGB21T41'],
    ['LLOYDS BANK PLC Exeter Bedford St (771019)', '771019', 'LOYDGB21T42'],
    ['LLOYDS BANK PLC Palace Av Paignton 771020', '771020', 'LOYDGB21T43'],
    ['LLOYDS BANK PLC Newton Abbot  (771021)', '771021', 'LOYDGB21T44'],
    ['LLOYDS BANK PLC Honiton (771022)', '771022', 'LOYDGB21T45'],
    ['LLOYDS BANK PLC Palace Av Paignton 771020', '771023', 'LOYDGB21T28'],
    ['TSB BANK PLC Cloth Hall St Huddersfield', '771101', ''],
    ['TSB BANK PLC Cloth Hall St Huddersfield', '771102', ''],
    ['LLOYDS BANK PLC Dewsbury (771103)', '771103', 'LOYDGB21T31'],
    ['TSB BANK PLC Bradford', '771104', ''],
    ['LLOYDS BANK PLC Hull City Centre', '771201', 'LOYDGB21T33'],
    ['TSB BANK PLC Hull, 87-91 Jameson Street', '771202', ''],
    ['LLOYDS BANK PLC South Newington (771205)', '771205', 'LOYDGB21T35'],
    ['LLOYDS BANK PLC Hull  Holderness Rd', '771206', 'LOYDGB21T36'],
    ['LLOYDS BANK PLC Newland University 771207', '771207', 'LOYDGB21T46'],
    ['LLOYDS BANK PLC South Newington (771208)', '771208', 'LOYDGB21T56'],
    ['LLOYDS BANK PLC Hull City Centre (771209)', '771209', 'LOYDGB21T57'],
    ['LLOYDS BANK PLC Hull City Centre', '771211', 'LOYDGB21T58'],
    ['LLOYDS BANK PLC Newland Avenue Hull', '771212', 'LOYDGB21T59'],
    ['LLOYDS BANK PLC Hull  Ings Road', '771213', 'LOYDGB21T60'],
    ['LLOYDS BANK PLC Hull Bilton Grange (T)', '771215', 'LOYDGB21T61'],
    ['LLOYDS BANK PLC Grimsby  Victoria Street', '771216', 'LOYDGB21T62'],
    ['LLOYDS BANK PLC Grimsby  Victoria Street', '771217', 'LOYDGB21T63'],
    ['TSB BANK PLC TSB Grimsby', '771218', ''],
    ['LLOYDS BANK PLC Grimsby Nunsthorpe (T)', '771219', 'LOYDGB21T47'],
    ['LLOYDS BANK PLC Cleethorpes', '771220', 'LOYDGB21T48'],
    ['TSB BANK PLC Hull, Hessle', '771221', ''],
    ['LLOYDS BANK PLC Hull City Centre (771222)', '771222', 'LOYDGB21T50'],
    ['LLOYDS BANK PLC Withernsea', '771223', 'LOYDGB21T51'],
    ['LLOYDS BANK PLC Cottingham', '771224', 'LOYDGB21T52'],
    ['LLOYDS BANK PLC Hedon', '771225', 'LOYDGB21T53'],
    ['LLOYDS BANK PLC Barton-on-Humber 6 Mkt Pl', '771226', 'LOYDGB21T54'],
    ['TSB BANK PLC Brough', '771227', ''],
    ['TSB BANK PLC Goole', '771228', ''],
    ['TSB BANK PLC Hull, Willerby', '771229', ''],
    ['LLOYDS BANK PLC Hull City Centre (771230)', '771230', 'LOYDGB21N50'],
    ['LLOYDS BANK PLC Immingham', '771232', 'LOYDGB21N51'],
    ['LLOYDS BANK PLC Ilford  High Rd', '771301', 'LOYDGB21N52'],
    ['TSB BANK PLC Barkingside', '771302', ''],
    ['LLOYDS BANK PLC Ilford High Road (771303)', '771303', 'LOYDGB21N54'],
    ['TSB BANK PLC Chelmsford, 88/89 High St', '771304', ''],
    ['LLOYDS BANK PLC Dagenham  The Heathway', '771305', 'LOYDGB21N56'],
    ['LLOYDS BANK PLC East Ham  (771306)', '771306', 'LOYDGB21N57'],
    ['LLOYDS BANK PLC Market Place Romford 771307', '771307', 'LOYDGB21N40'],
    ['LLOYDS BANK PLC Barking (771308)', '771308', 'LOYDGB21N41'],
    ['LLOYDS BANK PLC Brentwood (771309)', '771309', 'LOYDGB21N42'],
    ['LLOYDS BANK PLC East Gate Harlow 771310', '771310', 'LOYDGB21N43'],
    ['LLOYDS BANK PLC Basildon (771311)', '771311', 'LOYDGB21N44'],
    ['LLOYDS BANK PLC Hornchurch (771312)', '771312', 'LOYDGB21N45'],
    ['LLOYDS BANK PLC Loughton  High Road', '771313', 'LOYDGB21N46'],
    ['TSB BANK PLC Leytonstone', '771315', ''],
    ['LLOYDS BANK PLC Billericay (771316)', '771316', 'LOYDGB21N48'],
    ['LLOYDS BANK PLC Basildon (771317)', '771317', 'LOYDGB21N58'],
    ['LLOYDS BANK PLC East Gate Harlow 771318', '771318', 'LOYDGB21N68'],
    ['LLOYDS BANK PLC Maldon (771320)', '771320', 'LOYDGB21N69'],
    ['LLOYDS BANK PLC Grays (771321)', '771321', 'LOYDGB21N70'],
    ['LLOYDS BANK PLC Basildon (771322)', '771322', 'LOYDGB21N71'],
    ['LLOYDS BANK PLC Chingford (771323)', '771323', 'LOYDGB21N72'],
    ['LLOYDS BANK PLC Leeds City Centre(771401)', '771401', 'LOYDGB21N73'],
    ['LLOYDS BANK PLC Leeds City Centre(771402)', '771402', 'LOYDGB21N74'],
    ['LLOYDS BANK PLC Church St Hunslet Leeds', '771404', 'LOYDGB21N75'],
    ['TSB BANK PLC Leeds, Headingley', '771405', ''],
    ['LLOYDS BANK PLC Leeds Crossgate (771407)', '771407', 'LOYDGB21N59'],
    ['LLOYDS BANK PLC Leeds Armley (771408)', '771408', 'LOYDGB21N60'],
    ['LLOYDS BANK PLC Leeds City Centre(771409)', '771409', 'LOYDGB21N61'],
    ['LLOYDS BANK PLC Leeds  Harehills', '771410', 'LOYDGB21N62'],
    ['LLOYDS BANK PLC Leeds City Centre 771412', '771412', 'LOYDGB21N63'],
    ['TSB BANK PLC Ilkley (771413)', '771413', ''],
    ['TSB BANK PLC Leeds, Headingley', '771414', ''],
    ['LLOYDS BANK PLC Batley (771415)', '771415', 'LOYDGB21N66'],
    ['LLOYDS BANK PLC Leeds City Centre(771416)', '771416', 'LOYDGB21N67'],
    ['LLOYDS BANK PLC Leeds Crossgate (771417)', '771417', 'LOYDGB21N12'],
    ['LLOYDS BANK PLC Leeds City Centre(771418)', '771418', 'LOYDGB21N13'],
    ['LLOYDS BANK PLC Armley Leeds (771419)', '771419', 'LOYDGB21B84'],
    ['TSB BANK PLC Leicester, Town Hall Square', '771501', ''],
    ['LLOYDS BANK PLC Leicester Belgrave Rd (T)', '771502', 'LOYDGB21N15'],
    ['TSB BANK PLC Leicester, Cavendish Road', '771503', ''],
    ['LLOYDS BANK PLC High St Leicester', '771505', 'LOYDGB21N17'],
    ['LLOYDS BANK PLC High St Leicester (771506)', '771506', 'LOYDGB21N18'],
    ['LLOYDS BANK PLC Leicester  184 Melton Road', '771508', 'LOYDGB21N19'],
    ['LLOYDS BANK PLC High St Leicester (771509)', '771509', 'LOYDGB21N20'],
    ['LLOYDS BANK PLC Leicester Uppingham Rd (T)', '771510', 'LOYDGB21N03'],
    ['LLOYDS BANK PLC Leicester  263 Uppingham Rd', '771511', 'LOYDGB21N04'],
    ['LLOYDS BANK PLC Ashby De La Zouch 771513', '771513', 'LOYDGB21N05'],
    ['LLOYDS BANK PLC Coalville  771514', '771514', 'LOYDGB21N06'],
    ['LLOYDS BANK PLC Hinckley', '771515', 'LOYDGB21N07'],
    ['LLOYDS BANK PLC Loughborough  Devonshire Sq', '771516', 'LOYDGB21N08'],
    ['LLOYDS BANK PLC Market Harborough (771517)', '771517', 'LOYDGB21N09'],
    ['LLOYDS BANK PLC Melton Mowbray  Sherrard St', '771518', 'LOYDGB21N10'],
    ['LLOYDS BANK PLC Stamford (771519)', '771519', 'LOYDGB21N11'],
    ['LLOYDS BANK PLC Shepshed (T)', '771520', 'LOYDGB21N21'],
    ['LLOYDS BANK PLC Wigston Magna (771521)', '771521', 'LOYDGB21N31'],
    ['LLOYDS BANK PLC Coalville  771523', '771523', 'LOYDGB21N32'],
    ['LLOYDS BANK PLC Oadby (771525)', '771525', 'LOYDGB21N33'],
    ['LLOYDS BANK PLC Sileby (T)', '771526', 'LOYDGB21N34'],
    ['LLOYDS BANK PLC Lutterworth', '771527', 'LOYDGB21N35'],
    ['LLOYDS BANK PLC Wigston Magna (771528)', '771528', 'LOYDGB21N36'],
    ['LLOYDS BANK PLC High St Leicester (771533)', '771533', 'LOYDGB21C00'],
    ['TSB BANK PLC Lincoln, Bank Street', '771601', ''],
    ['LLOYDS BANK PLC Scunthorpe High St (771602)', '771602', 'LOYDGB21N38'],
    ['LLOYDS BANK PLC Brigg Wrawby St (771603)', '771603', 'LOYDGB21N39'],
    ['LLOYDS BANK PLC High St Lincoln (771605)', '771605', 'LOYDGB21N22'],
    ['LLOYDS BANK PLC Scunthorpe High St (771607)', '771607', 'LOYDGB21N23'],
    ['LLOYDS BANK PLC High St Scunthorpe 771608', '771608', 'LOYDGB21N24'],
    ['LLOYDS BANK PLC Lincoln  North Hykeham', '771611', 'LOYDGB21N25'],
    ['LLOYDS BANK PLC Brigg Wrawby St (771612)', '771612', 'LOYDGB21N26'],
    ['LLOYDS BANK PLC Liverpool  Breck Road', '771703', 'LOYDGB21N27'],
    ['TSB BANK PLC Liverpool, Tuebrook', '771706', ''],
    ['TSB BANK PLC Liverpool, Heathfield', '771707', ''],
    ['TSB BANK PLC Liverpool, Walton', '771708', ''],
    ['TSB BANK PLC TSB (771709)', '771709', ''],
    ['TSB BANK PLC TSB (771710)', '771710', ''],
    ['LLOYDS BANK PLC Liverpool  Broadway', '771711', 'LOYDGB21P25'],
    ['LLOYDS BANK PLC Liverpool Aintree (771712)', '771712', 'LOYDGB21P26'],
    ['LLOYDS BANK PLC Birkenhead (771713)', '771713', 'LOYDGB21P27'],
    ['TSB BANK PLC TSB (771714)', '771714', ''],
    ['TSB BANK PLC TSB (771716)', '771716', ''],
    ['LLOYDS BANK PLC Liverpool Derby Rd (771717)', '771717', 'LOYDGB21P30'],
    ['LLOYDS BANK PLC Birkenhead (771718)', '771718', 'LOYDGB21P31'],
    ['LLOYDS BANK PLC Heswall 771719', '771719', 'LOYDGB21P32'],
    ['LLOYDS BANK PLC Heswall 771720', '771720', 'LOYDGB21P15'],
    ['LLOYDS BANK PLC Birkenhead (771721)', '771721', 'LOYDGB21P16'],
    ['LLOYDS BANK PLC Birkenhead (771722)', '771722', 'LOYDGB21P17'],
    ['TSB BANK PLC TSB (771723)', '771723', ''],
    ['LLOYDS BANK PLC Wallasey (771724)', '771724', 'LOYDGB21P19'],
    ['LLOYDS BANK PLC Birkenhead (771725)', '771725', 'LOYDGB21P20'],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Villiers House Iom',
      '771726',
      'LOYDIMD1003'
    ],
    ['LLOYDS BANK PLC Liverpool  Kirkby', '771730', 'LOYDGB21P21'],
    ['LLOYDS BANK PLC Prescot (771731)', '771731', 'LOYDGB21P22'],
    ['LLOYDS BANK PLC Bromborough', '771732', 'LOYDGB21P23'],
    ['TSB BANK PLC TSB (771733)', '771733', ''],
    ['LLOYDS BANK PLC Heswall 771736', '771736', 'LOYDGB21P43'],
    ['LLOYDS BANK PLC Liverpool Derby Rd 771737', '771737', 'LOYDGB21P44'],
    ['LLOYDS BANK PLC Prescot (771738)', '771738', 'LOYDGB21P45'],
    ['LLOYDS BANK PLC Liverpool  Woolton', '771740', 'LOYDGB21P46'],
    ['TSB BANK PLC TSB (771741)', '771741', ''],
    ['LLOYDS BANK PLC Liverpool Aintree (771742)', '771742', 'LOYDGB21P48'],
    ['TSB BANK PLC TSB (771745)', '771745', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Ramsey Isle of Man',
      '771746',
      'LOYDIMD1004'
    ],
    ['LLOYDS BANK PLC Liverpool West Derby (T)', '771748', 'LOYDGB21P50'],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Villiers House Iom',
      '771749',
      'LOYDIMD1005'
    ],
    ['LLOYDS BANK PLC Liverpool  Church Street', '771750', 'LOYDGB21P51'],
    ['LLOYDS BANK PLC Liverpool Castle St  (T)', '771752', 'LOYDGB21P34'],
    ['LLOYDS BANK PLC The Cross Worcester', '771790', 'LOYDGB21Y94'],
    ['LLOYDS BANK PLC New St Birmingham', '771791', 'LOYDGB21Y84'],
    ['LLOYDS BANK PLC Scottish Widows', '771800', ''],
    ['LLOYDS BANK PLC Loyd Trust Co-Endowment', '771801', ''],
    ['LLOYDS BANK PLC Scottish Widows', '771811', ''],
    ['LLOYDS BANK PLC Loyd Trust Company Ltd Dd', '771812', ''],
    ['LLOYDS BANK PLC TSB Insurance Services', '771815', ''],
    ['LLOYDS BANK PLC Loyd General Insurance Ltd', '771816', ''],
    ['LLOYDS BANK PLC Loyd Gi Services Ltd-Motor', '771817', ''],
    ['LLOYDS BANK PLC Scottish Widows', '771818', ''],
    ['LLOYDS BANK PLC Scottish Widows', '771819', ''],
    ['LLOYDS BANK PLC Scottish Widows', '771820', ''],
    ['LLOYDS BANK PLC Lloyds Bank Gi Ltd', '771821', ''],
    ['LLOYDS BANK PLC Scottish Widows', '771830', ''],
    ['LLOYDS BANK PLC Scottish Widows', '771832', ''],
    ['LLOYDS BANK PLC Lloyds Bank Unit Trusts', '771842', ''],
    ['LLOYDS BANK PLC Lloyds Bank Unit Trusts', '771843', ''],
    ['LLOYDS BANK PLC Loyd Gen Ins  C & G Mort', '771845', ''],
    ['LLOYDS BANK PLC Galleri Washington', '771877', 'LOYDGB21Y88'],
    ['LLOYDS BANK PLC Manchester Region', '771900', ''],
    ['LLOYDS BANK PLC Market St Manchester 771901', '771901', 'LOYDGB21P35'],
    ['TSB BANK PLC TSB (771909)', '771909', ''],
    ['TSB BANK PLC TSB (771910)', '771910', ''],
    ['LLOYDS BANK PLC Salford Shopping Centre', '771912', 'LOYDGB21P38'],
    ['TSB BANK PLC TSB (771913)', '771913', ''],
    ['LLOYDS BANK PLC Altrincham (771914)', '771914', 'LOYDGB21P40'],
    ['TSB BANK PLC TSB (771915)', '771915', ''],
    ['LLOYDS BANK PLC Manchester  Newton Heath', '771916', 'LOYDGB21P42'],
    ['TSB BANK PLC TSB (771918)', '771918', ''],
    ['LLOYDS BANK PLC Stretford', '771919', 'LOYDGB21N88'],
    ['LLOYDS BANK PLC Ashton-under-Lyne (771920)', '771920', 'LOYDGB21N89'],
    ['LLOYDS BANK PLC Sale  (771923)', '771923', 'LOYDGB21N90'],
    ['LLOYDS BANK PLC Swinton', '771924', 'LOYDGB21N91'],
    ['TSB BANK PLC TSB (771925)', '771925', ''],
    ['LLOYDS BANK PLC Manchester  Withington', '771926', 'LOYDGB21N93'],
    ['TSB BANK PLC TSB (771927)', '771927', ''],
    ['LLOYDS BANK PLC Sale  (771932)', '771932', 'LOYDGB21N95'],
    ['LLOYDS BANK PLC Market St Manchester 771934', '771934', 'LOYDGB21N78'],
    ['TSB BANK PLC TSB (771937)', '771937', ''],
    ['LLOYDS BANK PLC Moston Manchester (771938)', '771938', 'LOYDGB21N80'],
    ['TSB BANK PLC TSB (771941)', '771941', ''],
    ['TSB BANK PLC TSB (771946)', '771946', ''],
    ['LLOYDS BANK PLC Manchester Didsbury (T)', '771947', 'LOYDGB21N82'],
    ['LLOYDS BANK PLC Moston Manchester (771949)', '771949', 'LOYDGB21N83'],
    ['LLOYDS BANK PLC Moston Manchester (771950)', '771952', 'LOYDGB21N84'],
    ['LLOYDS BANK PLC Altrincham (771953)', '771953', 'LOYDGB21N85'],
    ['TSB BANK PLC TSB (771955)', '771955', ''],
    ['TSB BANK PLC TSB (771956)', '771956', ''],
    ['TSB BANK PLC TSB (771957)', '771957', ''],
    ['LLOYDS BANK PLC Salford Irlams Oth Heightt', '771958', 'LOYDGB21P07'],
    ['LLOYDS BANK PLC Altrincham (771959)', '771959', 'LOYDGB21P08'],
    ['LLOYDS BANK PLC Market St Manchester 771960', '771960', 'LOYDGB21P09'],
    ['LLOYDS BANK PLC Credit Operations', '771961', ''],
    ['LLOYDS BANK PLC Credit Operations', '771962', ''],
    ['LLOYDS BANK PLC Credit Operations', '771963', ''],
    ['LLOYDS BANK PLC Credit Operations', '771964', ''],
    ['LLOYDS BANK PLC Credit Operations', '771965', ''],
    ['LLOYDS BANK PLC Credit Operations', '771966', ''],
    ['LLOYDS BANK PLC Credit Operations', '771967', ''],
    ['LLOYDS BANK PLC Credit Operations', '771968', ''],
    ['LLOYDS BANK PLC Newcastle Grey St (772001)', '772001', 'LOYDGB21P12'],
    ['LLOYDS BANK PLC Alnwick (772003)', '772003', 'LOYDGB21P13'],
    ['TSB BANK PLC TSB (772004)', '772004', ''],
    ['TSB BANK PLC TSB (772005)', '772005', ''],
    ['LLOYDS BANK PLC Ashington  3 Station Road', '772006', 'LOYDGB21N98'],
    ['TSB BANK PLC TSB (772007)', '772007', ''],
    ['TSB BANK PLC TSB (772009)', '772009', ''],
    ['TSB BANK PLC TSB (772010)', '772010', ''],
    ['LLOYDS BANK PLC Bishop Auckland (77-20-11)', '772011', 'LOYDGB21P02'],
    ['LLOYDS BANK PLC Blaydon on Tyne  Wesley Ct', '772012', 'LOYDGB21P03'],
    ['LLOYDS BANK PLC Blyth (772013)', '772013', 'LOYDGB21P04'],
    ['LLOYDS BANK PLC Chester Le Street  (772014)', '772014', 'LOYDGB21P05'],
    ['LLOYDS BANK PLC Consett  (772015)', '772015', 'LOYDGB21W02'],
    ['TSB BANK PLC TSB (772016)', '772016', ''],
    ['TSB BANK PLC TSB (772017)', '772017', ''],
    ['LLOYDS BANK PLC Newcastle upon Tyne  Benton', '772019', 'LOYDGB21A91'],
    ['TSB BANK PLC TSB (772020)', '772020', ''],
    ['LLOYDS BANK PLC Durham (772021)', '772021', 'LOYDGB21A92'],
    ['TSB BANK PLC TSB (772022)', '772022', ''],
    ['LLOYDS BANK PLC Spennymoor (772023)', '772023', 'LOYDGB21A94'],
    ['TSB BANK PLC TSB (772024)', '772024', ''],
    ['LLOYDS BANK PLC Gosforth (772025)', '772025', 'LOYDGB21A96'],
    ['LLOYDS BANK PLC Grey St Newcastle 772026', '772026', 'LOYDGB21A88'],
    ['LLOYDS BANK PLC Heaton 772027', '772027', 'LOYDGB21A80'],
    ['LLOYDS BANK PLC Hexham  (772028)', '772028', 'LOYDGB21A81'],
    ['LLOYDS BANK PLC Newcastle Grey St (772030)', '772030', 'LOYDGB21Y04'],
    ['LLOYDS BANK PLC Gateshead (772031)', '772031', 'LOYDGB21A82'],
    ['TSB BANK PLC TSB (772033)', '772033', ''],
    ['LLOYDS BANK PLC Whitley Bay (772034)', '772034', 'LOYDGB21Y05'],
    ['TSB BANK PLC TSB (772035)', '772035', ''],
    ['TSB BANK PLC TSB (772036)', '772036', ''],
    ['TSB BANK PLC TSB (772037)', '772037', ''],
    ['LLOYDS BANK PLC Prudhoe', '772039', 'LOYDGB21A87'],
    ['LLOYDS BANK PLC Spennymoor  (772040)', '772040', 'LOYDGB21B06'],
    ['LLOYDS BANK PLC Front Street Stanley 772043', '772043', 'LOYDGB21B07'],
    ['LLOYDS BANK PLC Stockton-on-Tees (772044)', '772044', 'LOYDGB21B08'],
    ['LLOYDS BANK PLC North Shields 772045', '772045', 'LOYDGB21B09'],
    ['LLOYDS BANK PLC Wallsend  High Street East', '772049', 'LOYDGB21B10'],
    ['LLOYDS BANK PLC Hartlepool (772050)', '772050', 'LOYDGB21B11'],
    ['LLOYDS BANK PLC Whitley Bay (772051)', '772051', 'LOYDGB21B12'],
    ['LLOYDS BANK PLC Willington Quay', '772052', 'LOYDGB21Y06'],
    ['TSB BANK PLC TSB (772053)', '772053', ''],
    ['TSB BANK PLC TSB (772054)', '772054', ''],
    ['TSB BANK PLC TSB (772055)', '772055', ''],
    ['TSB BANK PLC TSB (772057)', '772057', ''],
    ['TSB BANK PLC TSB (772058)', '772058', ''],
    ['TSB BANK PLC TSB (772060)', '772060', ''],
    ['LLOYDS BANK PLC Fraud Prevention (772064)', '772064', ''],
    ['LLOYDS BANK PLC George Row Northmptn 772101', '772101', 'LOYDGB21B01'],
    ['LLOYDS BANK PLC Abington (772102)', '772102', 'LOYDGB21B02'],
    ['LLOYDS BANK PLC Northampton (772103)', '772103', 'LOYDGB21B03'],
    ['LLOYDS BANK PLC Corby (772104)', '772104', 'LOYDGB21B04'],
    ['LLOYDS BANK PLC Kettering 3/4 Market Street', '772105', 'LOYDGB21A79'],
    ['LLOYDS BANK PLC Rushden  37/39 High Street', '772106', 'LOYDGB21A54'],
    ['LLOYDS BANK PLC Wellingborough  (772107)', '772107', 'LOYDGB21A55'],
    ['LLOYDS BANK PLC Bletchley  Queensway', '772108', 'LOYDGB21A56'],
    ['LLOYDS BANK PLC Milton Keynes (772109)', '772109', 'LOYDGB21A57'],
    ['LLOYDS BANK PLC Milton Keynes (772110)', '772110', 'LOYDGB21A58'],
    ['LLOYDS BANK PLC Daventry (772111)', '772111', 'LOYDGB21A59'],
    ['LLOYDS BANK PLC Abington (772112)', '772112', 'LOYDGB21A60'],
    ['TSB BANK PLC TSB (772114)', '772114', ''],
    ['LLOYDS BANK PLC Milton Keynes (77-21-15)', '772115', 'LOYDGB21A53'],
    ['LLOYDS BANK PLC Leighton Buzzard (772116)', '772116', 'LOYDGB21A45'],
    ['TSB BANK PLC TSB (772201)', '772201', ''],
    ['LLOYDS BANK PLC Bulwell (772204)', '772204', 'LOYDGB21A47'],
    ['LLOYDS BANK PLC Nottingam  Bilborough', '772205', 'LOYDGB21A48'],
    ['LLOYDS BANK PLC Nottingham  Carlton', '772207', 'LOYDGB21A49'],
    ['TSB BANK PLC TSB (772208)', '772208', ''],
    ['LLOYDS BANK PLC Bulwell (772209)', '772209', 'LOYDGB21A51'],
    ['TSB BANK PLC TSB (772210)', '772210', ''],
    ['LLOYDS BANK PLC Nottingam  Hyson Green', '772212', 'LOYDGB21A71'],
    ['TSB BANK PLC TSB (772214)', '772214', ''],
    ['TSB BANK PLC TSB (772216)', '772216', ''],
    ['LLOYDS BANK PLC Arnold', '772217', 'LOYDGB21A74'],
    ['LLOYDS BANK PLC Beeston (772218)', '772218', 'LOYDGB21A75'],
    ['TSB BANK PLC TSB (772219)', '772219', ''],
    ['LLOYDS BANK PLC Worksop (772220)', '772220', 'LOYDGB21A77'],
    ['TSB BANK PLC TSB (772222)', '772222', ''],
    ['LLOYDS BANK PLC Hucknall', '772223', 'LOYDGB21A70'],
    ['TSB BANK PLC TSB (772224)', '772224', ''],
    ['LLOYDS BANK PLC Kirkby-in-Ashfield', '772225', 'LOYDGB21A63'],
    ['LLOYDS BANK PLC Long Eaton  (772226)', '772226', 'LOYDGB21A64'],
    ['LLOYDS BANK PLC Mansfield (772228)', '772228', 'LOYDGB21A65'],
    ['TSB BANK PLC TSB (772229)', '772229', ''],
    ['LLOYDS BANK PLC Shirebrook', '772231', 'LOYDGB21A67'],
    ['LLOYDS BANK PLC Long Eaton (772232)', '772232', 'LOYDGB21A68'],
    ['LLOYDS BANK PLC Sutton-in-Ashfld (772233)', '772233', 'LOYDGB21A69'],
    ['LLOYDS BANK PLC Shirebrook (772234)', '772234', 'LOYDGB21B14'],
    ['TSB BANK PLC TSB (772235)', '772235', ''],
    ['LLOYDS BANK PLC Nottingham Parliament St', '772237', 'LOYDGB21B60'],
    ['LLOYDS BANK PLC New Ollerton', '772238', 'LOYDGB21B61'],
    ['LLOYDS BANK PLC Grantham (772239)', '772239', 'LOYDGB21B62'],
    ['TSB BANK PLC TSB (772241)', '772241', ''],
    ['LLOYDS BANK PLC Mansfield (772242)', '772242', 'LOYDGB21B63'],
    ['TSB BANK PLC TSB (772243)', '772243', ''],
    ['LLOYDS BANK PLC Mansfield (772244)', '772244', 'LOYDGB21B86'],
    ['LLOYDS BANK PLC Carfax Oxford (772301)', '772301', 'LOYDGB21B65'],
    ['LLOYDS BANK PLC Carfax Oxford (772302)', '772302', 'LOYDGB21B66'],
    ['LLOYDS BANK PLC Banbury  23 High Street', '772303', 'LOYDGB21B58'],
    ['LLOYDS BANK PLC Witney (772304)', '772304', 'LOYDGB21B50'],
    ['TSB BANK PLC TSB (772305)', '772305', ''],
    ['LLOYDS BANK PLC Brackley (772306)', '772306', 'LOYDGB21B53'],
    ['LLOYDS BANK PLC Cowley (772307)', '772307', 'LOYDGB21B54'],
    ['LLOYDS BANK PLC Carfax Oxford (772308)', '772308', 'LOYDGB21B55'],
    ['LLOYDS BANK PLC Kidlington (772309)', '772309', 'LOYDGB21B56'],
    ['LLOYDS BANK PLC Bicester (772310)', '772310', 'LOYDGB21B57'],
    ['LLOYDS BANK PLC Cowley (772312)', '772312', 'LOYDGB21B76'],
    ['LLOYDS BANK PLC Carfax Oxford (772313)', '772313', 'LOYDGB21B77'],
    ['LLOYDS BANK PLC Petrborough Region', '772400', ''],
    ['LLOYDS BANK PLC Credit Operations', '772404', ''],
    ['LLOYDS BANK PLC North End Portsmouth 2501', '772501', 'LOYDGB21B79'],
    ['LLOYDS BANK PLC Portsmouth Cosham 772502', '772502', 'LOYDGB21B80'],
    ['LLOYDS BANK PLC Portsmouth Commercial Rd', '772503', 'LOYDGB21B82'],
    ['LLOYDS BANK PLC North End Portsmouth', '772504', 'LOYDGB21B75'],
    ['LLOYDS BANK PLC Southsea (Colo 309304)', '772505', 'LOYDGB21B67'],
    ['TSB BANK PLC TSB (772506)', '772506', ''],
    ['LLOYDS BANK PLC Bognor Regis (772507)', '772507', 'LOYDGB21B69'],
    ['LLOYDS BANK PLC Chichester 772508', '772508', 'LOYDGB21B70'],
    ['LLOYDS BANK PLC Fareham (772509)', '772509', 'LOYDGB21B71'],
    ['LLOYDS BANK PLC Gosport (772510)', '772510', 'LOYDGB21B72'],
    ['LLOYDS BANK PLC Havant (772511)', '772511', 'LOYDGB21B73'],
    ['LLOYDS BANK PLC Petersfield (772512)', '772512', 'LOYDGB21B74'],
    ['LLOYDS BANK PLC Fareham (772513)', '772513', 'LOYDGB21B49'],
    ['LLOYDS BANK PLC Ryde (772514)', '772514', 'LOYDGB21B24'],
    ['LLOYDS BANK PLC Winchester High St (772515)', '772515', 'LOYDGB21B43'],
    ['LLOYDS BANK PLC Newport I.O.W (772516)', '772516', 'LOYDGB21B44'],
    ['LLOYDS BANK PLC Eastleigh (772517)', '772517', 'LOYDGB21B45'],
    ['LLOYDS BANK PLC Waterlooville (772518)', '772518', 'LOYDGB21B46'],
    ['LLOYDS BANK PLC Portsmouth  Commercial Road', '772519', 'LOYDGB21B47'],
    ['LLOYDS BANK PLC Portsmouth (Colo 309304)', '772520', 'LOYDGB21B48'],
    ['TSB BANK PLC TSB (772601)', '772601', ''],
    ['TSB BANK PLC TSB (772603)', '772603', ''],
    ['LLOYDS BANK PLC Barrow in Furness (772604)', '772604', 'LOYDGB21B33'],
    ['TSB BANK PLC TSB (772605)', '772605', ''],
    ['TSB BANK PLC TSB (772606)', '772606', ''],
    ['TSB BANK PLC TSB (772608)', '772608', ''],
    ['LLOYDS BANK PLC Barrow in Furness (772609)', '772609', 'LOYDGB21B37'],
    ['TSB BANK PLC TSB (772610)', '772610', ''],
    ['LLOYDS BANK PLC Lancaster (772611)', '772611', 'LOYDGB21B39'],
    ['LLOYDS BANK PLC Lytham (772612)', '772612', 'LOYDGB21B42'],
    ['LLOYDS BANK PLC Lancaster (772613)', '772613', 'LOYDGB21B41'],
    ['TSB BANK PLC TSB (772614)', '772614', ''],
    ['LLOYDS BANK PLC Fishergate Preston', '772615', 'LOYDGB21B21'],
    ['TSB BANK PLC TSB (772616)', '772616', ''],
    ['TSB BANK PLC TSB (772617)', '772617', ''],
    ['LLOYDS BANK PLC Fishergate Preston 772618', '772618', 'LOYDGB21B18'],
    ['LLOYDS BANK PLC Poulton-le-Fylde', '772619', 'LOYDGB21B17'],
    ['TSB BANK PLC TSB (772620)', '772620', ''],
    ['LLOYDS BANK PLC Corporation St Blackpool', '772621', 'LOYDGB21B15'],
    ['LLOYDS BANK PLC Corporation St Blackpool', '772622', 'LOYDGB21B23'],
    ['LLOYDS BANK PLC Preston  Fulwood', '772624', 'LOYDGB21B31'],
    ['LLOYDS BANK PLC Fishergate Preston 772625', '772625', 'LOYDGB21B30'],
    ['TSB BANK PLC TSB (772626)', '772626', ''],
    ['TSB BANK PLC TSB (772627)', '772627', ''],
    ['TSB BANK PLC TSB (772628)', '772628', ''],
    ['LLOYDS BANK PLC Kendal (772629)', '772629', 'LOYDGB21B26'],
    ['TSB BANK PLC TSB (772630)', '772630', ''],
    ['LLOYDS BANK PLC Pride Hill Shrewsbury772701', '772701', 'LOYDGB21B83'],
    ['TSB BANK PLC TSB (772702)', '772702', ''],
    ['LLOYDS BANK PLC Brecon', '772703', 'LOYDGB21B51'],
    ['LLOYDS BANK PLC Bridgnorth (Colo 309119)', '772704', 'LOYDGB21W48'],
    ['LLOYDS BANK PLC Bromsgrove (Colo 309136)', '772705', 'LOYDGB21W49'],
    ['LLOYDS BANK PLC Cheltenham  Pittville St', '772706', 'LOYDGB21W50'],
    ['LLOYDS BANK PLC Cirencester  Cricklade St', '772707', 'LOYDGB21W51'],
    ['TSB BANK PLC TSB (772708)', '772708', ''],
    ['LLOYDS BANK PLC Gloucester  Southgate St', '772709', 'LOYDGB21W53'],
    ['TSB BANK PLC TSB (772710)', '772710', ''],
    ['LLOYDS BANK PLC Hereford (772711)', '772711', 'LOYDGB21W55'],
    ['LLOYDS BANK PLC Kidderminster  Coventry St', '772712', 'LOYDGB21W47'],
    ['LLOYDS BANK PLC Leominster (772714)', '772714', 'LOYDGB21W39'],
    ['LLOYDS BANK PLC Ludlow  (772715)', '772715', 'LOYDGB21W40'],
    ['LLOYDS BANK PLC Market Drayton (772716)', '772716', 'LOYDGB21W41'],
    ['LLOYDS BANK PLC Newport Shrops (772717)', '772717', 'LOYDGB21W42'],
    ['LLOYDS BANK PLC Newtown', '772718', 'LOYDGB21W43'],
    ['TSB BANK PLC TSB (772719)', '772719', ''],
    ['LLOYDS BANK PLC Redditch  (772720)', '772720', 'LOYDGB21W45'],
    ['LLOYDS BANK PLC Market Sq Stafford (772721)', '772721', 'LOYDGB21W46'],
    ['LLOYDS BANK PLC Stroud  High Street', '772722', 'LOYDGB21W56'],
    ['LLOYDS BANK PLC Tewkesbury 772723', '772723', 'LOYDGB21W66'],
    ['LLOYDS BANK PLC Uttoxeter (772724)', '772724', 'LOYDGB21W67'],
    ['TSB BANK PLC TSB (772725)', '772725', ''],
    ['LLOYDS BANK PLC Welshpool (772726)', '772726', 'LOYDGB21W69'],
    ['TSB BANK PLC TSB (772727)', '772727', ''],
    ['LLOYDS BANK PLC Worcester Broad St (772728)', '772728', 'LOYDGB21W71'],
    ['LLOYDS BANK PLC High St Evesham (772729)', '772729', 'LOYDGB21W72'],
    ['TSB BANK PLC TSB (772730)', '772730', ''],
    ['LLOYDS BANK PLC Worcester Broad St (772731)', '772731', 'LOYDGB21W65'],
    ['LLOYDS BANK PLC High St, Cheltenham/ 772730', '772732', 'LOYDGB21W57'],
    ['LLOYDS BANK PLC Droitwich 772735', '772735', 'LOYDGB21W58'],
    ['LLOYDS BANK PLC Malvern   Worcester Rd', '772737', 'LOYDGB21W59'],
    ['LLOYDS BANK PLC Shrewsbury   Mount Pleasant', '772738', 'LOYDGB21W60'],
    ['LLOYDS BANK PLC Market Sq Stafford (772745)', '772745', 'LOYDGB21B88'],
    ['LLOYDS BANK PLC Norwichaylshamrdhub(772775)', '772775', 'LOYDGB21F30'],
    ['LLOYDS BANK PLC Honiton Hub (772776)', '772776', 'LOYDGB21F31'],
    ['LLOYDS BANK PLC Card Ops Ukrb (772800)', '772800', ''],
    ['LLOYDS BANK PLC Et Digital Uat (772801)', '772801', 'LOYDGB21Z84'],
    ['LLOYDS BANK PLC Card Operations Dd (772802)', '772802', ''],
    ['LLOYDS BANK PLC Rtl Lab-Tr1 Testing772806', '772806', ''],
    ['LLOYDS BANK PLC Burnley Hub (772808)', '772808', 'LOYDGB21F56'],
    ['LLOYDS BANK PLC Buttermkt(772813)', '772813', 'LOYDGB21Y85'],
    ['LLOYDS BANK PLC High St Leicester(772814)', '772814', 'LOYDGB21Y82'],
    ['LLOYDS BANK PLC 399 Oxford Street', '772815', 'LOYDGB21Y90'],
    ['LLOYDS BANK PLC Hemel Hempstead Marlowes', '772816', 'LOYDGB21Y92'],
    ['LLOYDS BANK PLC Tunbridge Wells', '772817', 'LOYDGB21Y91'],
    ['LLOYDS BANK PLC Loyd Card Services(Postpay)', '772828', ''],
    ['LLOYDS BANK PLC Consumer Debt Recovery', '772850', ''],
    ['LLOYDS BANK PLC Card Operations Ukrb 772900', '772900', ''],
    ['TSB BANK PLC TSB (772901)', '772901', ''],
    ['LLOYDS BANK PLC Fawcett St Sunderland', '772902', 'LOYDGB21W62'],
    ['TSB BANK PLC TSB (772903)', '772903', ''],
    ['LLOYDS BANK PLC Fulwell   (772904)', '772904', 'LOYDGB21W64'],
    ['TSB BANK PLC TSB (772905)', '772905', ''],
    ['LLOYDS BANK PLC Galleries Washington 772906', '772906', 'LOYDGB21W12'],
    ['TSB BANK PLC TSB (772907)', '772907', ''],
    ['LLOYDS BANK PLC Seaham', '772908', 'LOYDGB21W14'],
    ['LLOYDS BANK PLC Peterlee (772909)', '772909', 'LOYDGB21W15'],
    ['TSB BANK PLC TSB (772910)', '772910', ''],
    ['TSB BANK PLC TSB (772911)', '772911', ''],
    ['LLOYDS BANK PLC Houghton Le Spring', '772913', 'LOYDGB21W18'],
    ['LLOYDS BANK PLC Peterlee (772914)', '772914', 'LOYDGB21W19'],
    ['LLOYDS BANK PLC Peterlee (772915)', '772915', 'LOYDGB21W11'],
    ['LLOYDS BANK PLC Fawcett St Sunderland', '772916', 'LOYDGB21W03'],
    ['LLOYDS BANK PLC Galleries Washington 772918', '772918', 'LOYDGB21W04'],
    ['TSB BANK PLC TSB (772929)', '772929', ''],
    ['TSB BANK PLC Tsa Epayments (772931)', '772931', ''],
    ['TSB BANK PLC B A B&S Ag09 (772932)', '772932', ''],
    ['TSB BANK PLC Vtb Test Branch', '772933', ''],
    ['TSB BANK PLC TSB Retail L& U', '772934', ''],
    ['TSB BANK PLC TSB (772936)', '772936', ''],
    ['TSB BANK PLC TSB Barnwood', '772944', ''],
    ['TSB BANK PLC TSB (772953)', '772953', ''],
    ['TSB BANK PLC Intl Serv 772962', '772962', ''],
    ['TSB BANK PLC Bas E&W', '772978', ''],
    ['TSB BANK PLC Collections Centre', '772979', ''],
    ['TSB BANK PLC Tsa Epayments (772995)', '772995', ''],
    ['TSB BANK PLC Cash Ops', '772996', ''],
    ['TSB BANK PLC Bas E& W', '772997', ''],
    ['TSB BANK PLC TSB (772998)', '772998', ''],
    ['LLOYDS BANK PLC Croydon Central (773001)', '773001', 'LOYDGB21W05'],
    ['TSB BANK PLC TSB', '773002', ''],
    ['LLOYDS BANK PLC High Street, Sutton 773003', '773003', 'LOYDGB21W07'],
    ['LLOYDS BANK PLC Croydon Central (773004)', '773004', 'LOYDGB21W08'],
    ['LLOYDS BANK PLC Leatherhead   Church St', '773005', 'LOYDGB21W09'],
    ['LLOYDS BANK PLC Kingston upon Thames 773006', '773006', 'LOYDGB21W10'],
    ['LLOYDS BANK PLC Mitcham  Majestic Way', '773007', 'LOYDGB21W20'],
    ['LLOYDS BANK PLC Croydon Central (773008)', '773008', 'LOYDGB21W30'],
    ['LLOYDS BANK PLC High Street, Sutton 773009', '773009', 'LOYDGB21W31'],
    ['LLOYDS BANK PLC Epsom  (773010)', '773010', 'LOYDGB21W32'],
    ['LLOYDS BANK PLC Kingston upon Thames 773011', '773011', 'LOYDGB21W33'],
    ['LLOYDS BANK PLC Redhill  (773012)', '773012', 'LOYDGB21W34'],
    ["LLOYDS BANK PLC St George's Road (773013)", '773013', 'LOYDGB21W35'],
    ['LLOYDS BANK PLC Walton-on-Thames (773014)', '773014', 'LOYDGB21W36'],
    ['LLOYDS BANK PLC Richmond', '773015', 'LOYDGB21W37'],
    ['LLOYDS BANK PLC Station Ave Caterham', '773017', 'LOYDGB21W29'],
    ['TSB BANK PLC TSB', '773018', ''],
    ['LLOYDS BANK PLC High Street, Sutton 773019', '773019', 'LOYDGB21W22'],
    ['LLOYDS BANK PLC High Street, Sutton 773020', '773020', 'LOYDGB21Y08'],
    ['LLOYDS BANK PLC Station Ave Caterham', '773021', 'LOYDGB21W23'],
    ['TSB BANK PLC Bops (773022)', '773022', ''],
    ['LLOYDS BANK PLC Kingston upon Thames 773024', '773024', 'LOYDGB21W24'],
    ['LLOYDS BANK PLC Redhill  (773025)', '773025', 'LOYDGB21W25'],
    ['LLOYDS BANK PLC New Addington', '773028', 'LOYDGB21W26'],
    ['TSB BANK PLC Customer Services', '773029', ''],
    ['TSB BANK PLC International Service Centr', '773032', ''],
    ['TSB BANK PLC TSB (773033)', '773033', ''],
    ['TSB BANK PLC TSB (773037)', '773037', ''],
    ['TSB BANK PLC TSB (773038)', '773038', ''],
    ['TSB BANK PLC Sme Recoveries', '773039', ''],
    ['TSB BANK PLC Telephone Banking Cs TSB', '773040', ''],
    ['TSB BANK PLC Credit Cards', '773041', ''],
    ['TSB BANK PLC Loans Cs TSB', '773042', ''],
    ['TSB BANK PLC Savings Cs TSB', '773043', ''],
    ['TSB BANK PLC Bops (773044)', '773044', ''],
    ['TSB BANK PLC Bops (773045)', '773045', ''],
    ['TSB BANK PLC Bops (773046)', '773046', ''],
    ['TSB BANK PLC Bops (773047)', '773047', ''],
    ['TSB BANK PLC TSB (773054)', '773054', ''],
    ['TSB BANK PLC Manchester Mrkt St(773060)', '773060', ''],
    ['TSB BANK PLC TSB (773070)', '773070', ''],
    ['TSB BANK PLC TSB (773080)', '773080', ''],
    ['TSB BANK PLC TSB (773090)', '773090', ''],
    ['TSB BANK PLC TSB', '773101', ''],
    ['LLOYDS BANK PLC Darlaston (773102)', '773102', 'LOYDGB21W28'],
    ['LLOYDS BANK PLC Wednesbury   Walsall St', '773103', 'LOYDGB21A19'],
    ['TSB BANK PLC West Bromwich', '773104', ''],
    ['LLOYDS BANK PLC Lichfield', '773106', 'LOYDGB21A21'],
    ['LLOYDS BANK PLC Cannock (773107)', '773107', 'LOYDGB21A22'],
    ['LLOYDS BANK PLC Bloxwich Walsall (773108)', '773108', 'LOYDGB21A23'],
    ['LLOYDS BANK PLC Walsall  Aldridge (No 16)', '773109', 'LOYDGB21A24'],
    ['LLOYDS BANK PLC Rugeley  (77-31-10)', '773110', 'LOYDGB21A25'],
    ['LLOYDS BANK PLC Tamworth (773111)', '773111', 'LOYDGB21A26'],
    ['LLOYDS BANK PLC Market St Wigan (773201)', '773201', 'LOYDGB21A18'],
    ['TSB BANK PLC Ashton-in-Makerfield', '773202', ''],
    ['LLOYDS BANK PLC Market St Wigan (773204)', '773204', 'LOYDGB21A11'],
    ['TSB BANK PLC Wigan, Pemberton', '773205', ''],
    ['LLOYDS BANK PLC Market St Wigan (773208)', '773208', 'LOYDGB21A13'],
    ['LLOYDS BANK PLC Hardshaw St St Helens773211', '773211', 'LOYDGB21A14'],
    ['TSB BANK PLC St Helens', '773212', ''],
    ['LLOYDS BANK PLC Southport  London Street', '773213', 'LOYDGB21A16'],
    ['LLOYDS BANK PLC Churchtown (T)', '773216', 'LOYDGB21A17'],
    ['LLOYDS BANK PLC Ainsdale (T)', '773218', 'LOYDGB21A27'],
    ['LLOYDS BANK PLC Liverpool  Formby', '773219', 'LOYDGB21A37'],
    ['LLOYDS BANK PLC Hardshaw St St Helens773220', '773220', 'LOYDGB21A38'],
    ['LLOYDS BANK PLC Queen Sq Wolverhamptn773301', '773301', 'LOYDGB21A39'],
    ['LLOYDS BANK PLC Bilston (773302)', '773302', 'LOYDGB21A40'],
    ['TSB BANK PLC TSB Wolverhampton (773303)', '773303', ''],
    ['LLOYDS BANK PLC Dudley (773304)', '773304', 'LOYDGB21A42'],
    ['LLOYDS BANK PLC Dudley  (773305)', '773305', 'LOYDGB21A43'],
    ['LLOYDS BANK PLC Stourbridge  55 High Street', '773307', 'LOYDGB21A44'],
    ['LLOYDS BANK PLC Wolverhampton  Three Tuns', '773308', 'LOYDGB21A36'],
    ['LLOYDS BANK PLC Wolverhampton  Wednesfield', '773309', 'LOYDGB21A28'],
    ['TSB BANK PLC TSB Wolverhampton (773310)', '773310', ''],
    ['LLOYDS BANK PLC Willenhall  New Road', '773311', 'LOYDGB21A30'],
    ['LLOYDS BANK PLC Kingswinford (773312)', '773312', 'LOYDGB21A31'],
    ['LLOYDS BANK PLC Dudley  (773313)', '773313', 'LOYDGB21A32'],
    ['LLOYDS BANK PLC Sedgley Dudley', '773315', 'LOYDGB21A33'],
    ['LLOYDS BANK PLC Bilston  (773317)', '773317', 'LOYDGB21A34'],
    ['LLOYDS BANK PLC Dudley (773319)', '773319', 'LOYDGB21A35'],
    ['LLOYDS BANK PLC Queen Sq Wolverhamptn773320', '773320', 'LOYDGB21B91'],
    ['TSB BANK PLC Tsa Epayments (773475)', '773475', ''],
    ['TSB BANK PLC Tsa Epayments (773476)', '773476', ''],
    ['LLOYDS BANK PLC Leeds City Centre(773951)', '773951', 'LOYDGB21Y89'],
    ['LLOYDS BANK PLC Mobile Branch 773952', '773952', 'LOYDGB21F65'],
    ['LLOYDS BANK PLC Lloydscontingency4 (773954)', '773954', 'LOYDGB21F55'],
    ['LLOYDS BANK PLC Parliament St (773958)', '773958', 'LOYDGB21Y86'],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '773960', ''],
    ['LLOYDS BANK PLC Mobile Branch 773973', '773973', 'LOYDGB21F67'],
    ['LLOYDS BANK PLC Mobile Branch 773974', '773974', 'LOYDGB21F68'],
    ['LLOYDS BANK PLC United Dominions Trust Ltd', '774001', ''],
    ['LLOYDS BANK PLC Petersfield Hub (774002)', '774002', 'LOYDGB21F33'],
    ['LLOYDS BANK PLC Udt', '774003', ''],
    ['LLOYDS BANK PLC Sleaford Hub (774004)', '774004', 'LOYDGB21F34'],
    ['LLOYDS BANK PLC Udt Ltd (Consumer Finance)', '774005', ''],
    ['LLOYDS BANK PLC Mortgage Express', '774006', ''],
    ['LLOYDS BANK PLC Pendeford (77-40-07)', '774007', ''],
    ['LLOYDS BANK PLC Endeavour Personal Finance', '774010', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774011', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774012', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774013', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774014', ''],
    ['LLOYDS BANK PLC Lloyds Bank Direct', '774015', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774016', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774017', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774018', ''],
    ['LLOYDS BANK PLC Lloyds Bank', '774019', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774020', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774024', ''],
    ['LLOYDS BANK PLC Lloyds Bank Direct', '774025', ''],
    ['LLOYDS BANK PLC Udt-Choice Personal Fin', '774026', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774030', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774033', ''],
    ['LLOYDS BANK PLC Collection A/C (Udtfs/Epf)', '774035', ''],
    ['LLOYDS BANK PLC Mobile Branch 774039', '774039', 'LOYDGB21F69'],
    ['LLOYDS BANK PLC Mobile Branch 774040', '774040', 'LOYDGB21F70'],
    ['LLOYDS BANK PLC Digital Sales', '774042', 'LOYDGB21Z99'],
    ['LLOYDS BANK PLC Credit Operations', '774045', ''],
    ['LLOYDS BANK PLC Internation Service Centre', '774049', ''],
    ['LLOYDS BANK PLC Lloyds Udt', '774050', ''],
    ['LLOYDS BANK PLC Lloyds Bank Direct', '774055', ''],
    ['LLOYDS BANK PLC Lloydscontingency2 (774057)', '774057', 'LOYDGB21F29'],
    ['LLOYDS BANK PLC Glasgow Ph Epayments', '774059', ''],
    ['LLOYDS BANK PLC Credit Operations', '774061', ''],
    ['LLOYDS BANK PLC Halifax (Ex Udt)', '774099', ''],
    ['LLOYDS BANK PLC Telephony - 77-42-55', '774255', ''],
    ['LLOYDS BANK PLC Credit Operations', '774264', ''],
    ['LLOYDS BANK PLC B&S B A 774267', '774267', ''],
    ['LLOYDS BANK PLC Glasgow Ph Epayments', '774281', ''],
    ['LLOYDS BANK PLC Glasgow Ph Epay (774282)', '774282', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '774296', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '774297', ''],
    ['LLOYDS BANK PLC Telephony - 77-43-25', '774325', ''],
    ['TSB BANK PLC Test L&U (774336)', '774336', ''],
    ['LLOYDS BANK PLC Banking Operations', '774370', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '774371', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '774372', ''],
    ['LLOYDS BANK PLC Banking Operations', '774373', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 31', '774374', ''],
    ['LLOYDS BANK PLC Gops Oppi 32(774375', '774375', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Channel Islands',
      '774700',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) St Helier (774701)',
      '774701',
      'LOYDJES1002'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Jersey   St Brelade',
      '774702',
      'LOYDJES1003'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Five Oaks (774703)',
      '774703',
      'LOYDJES1004'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Jersey, Five Oaks',
      '774704',
      'LOYDJES1005'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Guernsey High Street',
      '774705',
      'LOYDGGS1002'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Guernsey St Sampsons',
      '774706',
      'LOYDGGS1003'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Guernsey St Martins',
      '774707',
      'LOYDGGS1004'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Alderney',
      '774708',
      'LOYDGGS1005'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Overseas Department-Jersey',
      '774709',
      'LOYDJES1007'
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Jersey Commercial Branch',
      '774710',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Channel Islands Csc',
      '774711',
      ''
    ],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '774714', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '774715', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '774717', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '774718', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 29', '774720', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 30', '774721', ''],
    ['LLOYDS BANK PLC Savings Lloyds', '774722', ''],
    ['LLOYDS BANK PLC Pca Lloyds', '774723', ''],
    ['LLOYDS BANK PLC Credit Cards Lloyds', '774724', ''],
    ['LLOYDS BANK PLC Leeds Normanton 1', '774725', ''],
    ['LLOYDS BANK PLC Group Customer Services', '774726', ''],
    ['LLOYDS BANK PLC Loans Lloyds', '774729', ''],
    ['LLOYDS BANK PLC Comm Bank Lloyds', '774730', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '774741', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '774742', ''],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Broad St Jersey Pb5 3',
      '774751',
      ''
    ],
    [
      'LLOYDS BANK INTERNATIONAL (TRADING NAME OF LLOYDS BANK CORP MKTS PLC) Iom Intl Centre (774752)',
      '774752',
      ''
    ],
    ['LLOYDS BANK PLC Foregate St Chester 774801', '774801', 'LOYDGB21A09'],
    ['TSB BANK PLC Wrexham', '774802', ''],
    ['TSB BANK PLC Frodsham', '774803', ''],
    ['LLOYDS BANK PLC Knutsford', '774804', 'LOYDGB21W85'],
    ['LLOYDS BANK PLC Hold (774805)', '774805', 'LOYDGB21W86'],
    ['TSB BANK PLC Nantwich', '774806', ''],
    ['LLOYDS BANK PLC Sandbach', '774807', 'LOYDGB21W88'],
    ['TSB BANK PLC Congleton', '774808', ''],
    ['LLOYDS BANK PLC Northwich (774809)', '774809', 'LOYDGB21W90'],
    ['LLOYDS BANK PLC Foregate St Chester 774810', '774810', 'LOYDGB21W82'],
    ['LLOYDS BANK PLC Shotton (774813)', '774813', 'LOYDGB21W74'],
    ['TSB BANK PLC Winsford', '774814', ''],
    ['LLOYDS BANK PLC Rhyl 10 Queen St (774815)', '774815', 'LOYDGB21W76'],
    ['TSB BANK PLC Flint', '774816', ''],
    ['LLOYDS BANK PLC Crewe  Victoria Street', '774817', 'LOYDGB21W78'],
    ['TSB BANK PLC Prestatyn', '774818', ''],
    ['LLOYDS BANK PLC Llandudno (774819)', '774819', 'LOYDGB21W80'],
    ['LLOYDS BANK PLC Bangor (774820)', '774820', 'LOYDGB21W81'],
    ['LLOYDS BANK PLC Llandudno  Victoria Centre', '774821', 'LOYDGB21W91'],
    ['LLOYDS BANK PLC Hold (774822)', '774822', 'LOYDGB21A01'],
    ['LLOYDS BANK PLC Ellesmere Port  (774823)', '774823', 'LOYDGB21A02'],
    ['LLOYDS BANK PLC Caernarfon (774824)', '774824', 'LOYDGB21A03'],
    ['LLOYDS BANK PLC Foregate St Chester 774825', '774825', 'LOYDGB21A04'],
    ['LLOYDS BANK PLC Bangor (774826)', '774826', 'LOYDGB21A05'],
    ['TSB BANK PLC Wrexham', '774827', ''],
    ['LLOYDS BANK PLC Rhyl 10 Queen St (774828)', '774828', 'LOYDGB21A07'],
    ['TSB BANK PLC Card Ops 774831', '774831', ''],
    ['TSB BANK PLC Pl Agency 774832', '774832', ''],
    ['LLOYDS BANK PLC Broad Street Reading', '774901', 'LOYDGB21A08'],
    ['LLOYDS BANK PLC Broad Street Reading', '774904', 'LOYDGB21A00'],
    ['LLOYDS BANK PLC Aldershot (774905)', '774905', 'LOYDGB21W92'],
    ['LLOYDS BANK PLC Festival Place (774906)', '774906', 'LOYDGB21W93'],
    ['LLOYDS BANK PLC Bracknell (774907)', '774907', 'LOYDGB21W94'],
    ['LLOYDS BANK PLC Camberley (77-49-08)', '774908', 'LOYDGB21W95'],
    ['LLOYDS BANK PLC Didcot (774909)', '774909', 'LOYDGB21W96'],
    ['LLOYDS BANK PLC Staines (774910)', '774910', 'LOYDGB21W97'],
    ['LLOYDS BANK PLC Farnham (774911)', '774911', 'LOYDGB21W98'],
    ['LLOYDS BANK PLC Queensmead Farnborough', '774912', 'LOYDGB21W99'],
    ['LLOYDS BANK PLC Broad St Reading (774913)', '774913', 'LOYDGB21G99'],
    ['LLOYDS BANK PLC Maidenhead (774914)', '774914', 'LOYDGB21H00'],
    ['LLOYDS BANK PLC Maidenhead (774915)', '774915', 'LOYDGB21H01'],
    ['LLOYDS BANK PLC Slough High St (774917)', '774917', 'LOYDGB21H02'],
    ['LLOYDS BANK PLC Staines (774918)', '774918', 'LOYDGB21H03'],
    ['LLOYDS BANK PLC Windsor & Eton (774919)', '774919', 'LOYDGB21H04'],
    ['LLOYDS BANK PLC Woking (774920)', '774920', 'LOYDGB21H05'],
    ['LLOYDS BANK PLC Wokingham', '774921', 'LOYDGB21H06'],
    ['LLOYDS BANK PLC Queensmead Farnborough', '774922', 'LOYDGB21H07'],
    ['LLOYDS BANK PLC Woodley', '774923', 'LOYDGB21G90'],
    ['LLOYDS BANK PLC Fleet Lloyds Bank', '774925', 'LOYDGB21Y10'],
    ['LLOYDS BANK PLC Lloyds Bank Digital Sales', '774926', 'LOYDGB21F46'],
    ['TSB BANK PLC TSB (774927)', '774927', ''],
    ['LLOYDS BANK PLC Woodley (774930)', '774930', 'LOYDGB21B92'],
    ['LLOYDS BANK PLC Broad Street', '774932', 'LOYDGB21Y11'],
    ['LLOYDS BANK PLC Exeter Region', '775000', ''],
    ['LLOYDS BANK PLC Taunton (Colo 309845)', '775001', 'LOYDGB21G92'],
    ['LLOYDS BANK PLC Wells (775002)', '775002', 'LOYDGB21G93'],
    ['TSB BANK PLC Bristol, Bedminster', '775003', ''],
    ['LLOYDS BANK PLC Trowbridge (775004)', '775004', 'LOYDGB21G95'],
    ['LLOYDS BANK PLC Devizes (775005)', '775005', 'LOYDGB21G96'],
    ['LLOYDS BANK PLC Warminster   High St', '775007', 'LOYDGB21G97'],
    ['LLOYDS BANK PLC Yeovil (775008)', '775008', 'LOYDGB21G98'],
    ['LLOYDS BANK PLC Bridgwater    High St', '775009', 'LOYDGB21H08'],
    ['LLOYDS BANK PLC Weston-super-Mare (775010)', '775010', 'LOYDGB21H18'],
    ['TSB BANK PLC Swindon, Commercial Road', '775011', ''],
    ['LLOYDS BANK PLC Salisbury  (775012)', '775012', 'LOYDGB21H20'],
    ['LLOYDS BANK PLC Street (775013)', '775013', 'LOYDGB21H21'],
    ['LLOYDS BANK PLC Chippenham 775014', '775014', 'LOYDGB21H22'],
    ['LLOYDS BANK PLC Chard 42 Fore St (775015)', '775015', 'LOYDGB21H23'],
    ['LLOYDS BANK PLC Broad Street Reading', '775016', 'LOYDGB21C02'],
    ['LLOYDS BANK PLC Bournemouth   Winton', '775022', 'LOYDGB21H24'],
    ['TSB BANK PLC Boscombe', '775023', ''],
    ['LLOYDS BANK PLC Parkstone Poole (775024)', '775024', 'LOYDGB21H26'],
    ['LLOYDS BANK PLC Poole High St (775025)', '775025', 'LOYDGB21H09'],
    ['LLOYDS BANK PLC Christchurch (775026)', '775026', 'LOYDGB21H10'],
    ['TSB BANK PLC Wimbourne', '775027', ''],
    ['LLOYDS BANK PLC Bournemouth (775028)', '775028', 'LOYDGB21H12'],
    ['LLOYDS BANK PLC Weymouth (775029)', '775029', 'LOYDGB21H13'],
    ['LLOYDS BANK PLC Dorchester (77-50-30)', '775030', 'LOYDGB21H14'],
    ['LLOYDS BANK PLC Ferndown (775031)', '775031', 'LOYDGB21H15'],
    ['LLOYDS BANK PLC Christchurch (775032)', '775032', 'LOYDGB21H16'],
    ['LLOYDS BANK PLC New Milton (775033)', '775033', 'LOYDGB21H17'],
    ['LLOYDS BANK PLC Poole High St (775034)', '775034', 'LOYDGB21G89'],
    ['TSB BANK PLC Bridport', '775035', ''],
    ['LLOYDS BANK PLC Ferndown (775036)', '775036', 'LOYDGB21G62'],
    ['LLOYDS BANK PLC Horsemarket Street (775101)', '775101', 'LOYDGB21G64'],
    ['LLOYDS BANK PLC Horsemarket Street (775104)', '775104', 'LOYDGB21G65'],
    ['LLOYDS BANK PLC Horsemarket Street (775105)', '775105', 'LOYDGB21G66'],
    ['TSB BANK PLC Manchester, Irlam', '775106', ''],
    ['LLOYDS BANK PLC Earlestown', '775107', 'LOYDGB21G68'],
    ['LLOYDS BANK PLC Horsemarket Street (775108)', '775108', 'LOYDGB21G69'],
    ['LLOYDS BANK PLC Runcorn  (775109)', '775109', 'LOYDGB21G52'],
    ['LLOYDS BANK PLC Widnes (775112)', '775112', 'LOYDGB21G53'],
    ['LLOYDS BANK PLC Widnes (775113)', '775113', 'LOYDGB21G54'],
    ['TSB BANK PLC Warrington, Orford', '775114', ''],
    ['TSB BANK PLC Warrington, Penketh', '775116', ''],
    ['TSB BANK PLC Manchester, Irlam', '775118', ''],
    ['LLOYDS BANK PLC Runcorn  (775119)', '775119', 'LOYDGB21G58'],
    ['LLOYDS BANK PLC Bancass Lbos', '775201', ''],
    ['LLOYDS BANK PLC Wealth Lloyds', '775202', ''],
    ['LLOYDS BANK PLC Customer Financial Assistan', '775203', ''],
    ['LLOYDS BANK PLC Banking Operations', '775204', ''],
    ['LLOYDS BANK PLC Banking Operations', '775205', ''],
    ['LLOYDS BANK PLC Banking Operations', '775227', ''],
    ['LLOYDS BANK PLC Tewkesbury Hub (775297)', '775297', 'LOYDGB21F59'],
    ['LLOYDS BANK PLC Comm Sme Lloyds', '775298', ''],
    ['LLOYDS BANK PLC Mortgages Lloyds', '775302', ''],
    ['LLOYDS BANK PLC Credit Decisioning', '775303', ''],
    ['LLOYDS BANK PLC Banking Operations', '775561', ''],
    ["LLOYDS BANK PLC St Paul's", '775564', ''],
    ['LLOYDS BANK PLC Banking Operations', '775584', ''],
    ['LLOYDS BANK PLC Llanelli Hub (775586)', '775586', 'LOYDGB21F58'],
    ['LLOYDS BANK PLC Carlisle (775601)', '775601', 'LOYDGB21G59'],
    ['LLOYDS BANK PLC Penrith      41 King St', '775602', 'LOYDGB21G60'],
    ['TSB BANK PLC Wigton', '775603', ''],
    ['LLOYDS BANK PLC Brampton (C010 775601)', '775604', 'LOYDGB21G80'],
    ['LLOYDS BANK PLC Bch Tran Self Serv 775605', '775605', ''],
    ['TSB BANK PLC Maryport', '775606', ''],
    ['LLOYDS BANK PLC Workington', '775607', 'LOYDGB21G82'],
    ['LLOYDS BANK PLC Workington (775609)', '775609', 'LOYDGB21G83'],
    ['LLOYDS BANK PLC Kendal (775610)', '775610', 'LOYDGB21G84'],
    ['LLOYDS BANK PLC Penrith (775611)', '775611', 'LOYDGB21G85'],
    ['TSB BANK PLC Whitehaven', '775612', ''],
    ['LLOYDS BANK PLC Lancaster (775613)', '775613', 'LOYDGB21G87'],
    ['LLOYDS BANK PLC Ulverston', '775614', 'LOYDGB21G88'],
    ['LLOYDS BANK PLC Kendal (775615)', '775615', 'LOYDGB21G71'],
    ['LLOYDS BANK PLC Carlisle (775616)', '775616', 'LOYDGB21G72'],
    ['LLOYDS BANK PLC Pendeford (77-56-68)', '775668', ''],
    ['TSB BANK PLC Test L&U (775670)', '775670', ''],
    ['LLOYDS BANK PLC Beccles Hub (772776)', '775676', 'LOYDGB21F32'],
    ['LLOYDS BANK PLC Pendeford Lcb (775685)', '775685', ''],
    ['LLOYDS BANK PLC Bch Tran Self Serv 775695', '775695', ''],
    ['TSB BANK PLC Ormskirk', '775701', ''],
    ['TSB BANK PLC Ormskirk', '775702', ''],
    ['TSB BANK PLC Liverpool, Maghull', '775703', ''],
    ['TSB BANK PLC Skelmersdale', '775704', ''],
    ['LLOYDS BANK PLC Hardshaw St St Helens775705', '775705', 'LOYDGB21G77'],
    ['TSB BANK PLC Hanley', '775801', ''],
    ['LLOYDS BANK PLC Lloyds Bank plc', '775802', 'LOYDGB21G79'],
    ['LLOYDS BANK PLC Fountain Square Hanley (775', '775803', 'LOYDGB21H74'],
    ['LLOYDS BANK PLC Tunstall (775804)', '775804', 'LOYDGB21H75'],
    ['LLOYDS BANK PLC Newcastle-U-Lyme 775805', '775805', 'LOYDGB21H76'],
    ['LLOYDS BANK PLC Longton    The Strand', '775806', 'LOYDGB21H77'],
    ['LLOYDS BANK PLC Meir (T)', '775807', 'LOYDGB21H78'],
    ['LLOYDS BANK PLC Newcastle-U-Lyme 775808', '775808', 'LOYDGB21H79'],
    ['TSB BANK PLC Biddulph', '775809', ''],
    ['LLOYDS BANK PLC Tunstall (775810)', '775810', 'LOYDGB21H81'],
    ['LLOYDS BANK PLC Stone  (775811)', '775811', 'LOYDGB21H82'],
    ['LLOYDS BANK PLC Cheadle Staffordshire', '775812', 'LOYDGB21H65'],
    ['TSB BANK PLC Hanley', '775813', ''],
    ['TSB BANK PLC Leek', '775815', ''],
    ['LLOYDS BANK PLC Leigh (775901)', '775901', 'LOYDGB21H68'],
    ['LLOYDS BANK PLC Atherton', '775902', 'LOYDGB21H69'],
    ['LLOYDS BANK PLC Leigh (775903)', '775903', 'LOYDGB21H70'],
    ['TSB BANK PLC Ashton-in-Makerfield', '775904', ''],
    ['LLOYDS BANK PLC Leigh (775906)', '775906', 'LOYDGB21H72'],
    ['LLOYDS BANK PLC Church St Liverpl', '776120', 'LOYDGB21Y83'],
    ['LLOYDS BANK PLC Mobile Branch 776122', '776122', 'LOYDGB21F66'],
    ['LLOYDS BANK PLC Et Digital Uat (776123)', '776123', 'LOYDGB21Z83'],
    ['LLOYDS BANK PLC Yeovil', '776127', 'LOYDGB21Y95'],
    ['LLOYDS BANK PLC Et Digital Uat (776129)', '776129', 'LOYDGB21Z85'],
    ['LLOYDS BANK PLC 31 Queen St Cardiff', '776201', 'LOYDGB21H73'],
    ['TSB BANK PLC Newport, Commercial Street', '776202', ''],
    ['LLOYDS BANK PLC Wyndham St Bridgend  776203', '776203', 'LOYDGB21H93'],
    ['LLOYDS BANK PLC Maindee Newport (776204)', '776204', 'LOYDGB21B97'],
    ['TSB BANK PLC Cardiff, Clifton Street', '776205', ''],
    ['LLOYDS BANK PLC Barry Docks (776206)', '776206', 'LOYDGB21H95'],
    ['LLOYDS BANK PLC Ebbw Vale (776207)', '776207', 'LOYDGB21H96'],
    ['LLOYDS BANK PLC Aberdare (776208)', '776208', 'LOYDGB21H97'],
    ['LLOYDS BANK PLC Roath Park Cardiff (776209)', '776209', 'LOYDGB21H98'],
    ['LLOYDS BANK PLC Victoria Park Cardiff', '776210', 'LOYDGB21H99'],
    ['LLOYDS BANK PLC Maindee Newport (776211)', '776211', 'LOYDGB21J00'],
    ['TSB BANK PLC Cwmbran, General Rees Sq', '776212', ''],
    ['LLOYDS BANK PLC Abergavenny', '776213', 'LOYDGB21H84'],
    ['LLOYDS BANK PLC Merthyr Tydfil  (776214)', '776214', 'LOYDGB21H85'],
    ['LLOYDS BANK PLC 1 Gwent Sq Cwmbran 776215', '776215', 'LOYDGB21H86'],
    ['LLOYDS BANK PLC Pontypridd Taff St (309672)', '776216', 'LOYDGB21H87'],
    ['LLOYDS BANK PLC Caerphilly   (776217)', '776217', 'LOYDGB21H88'],
    ['LLOYDS BANK PLC Chepstow   Beaufort Sq', '776218', 'LOYDGB21H89'],
    ['TSB BANK PLC Brighton, West Street', '776301', ''],
    ['LLOYDS BANK PLC Hove (776302)', '776302', 'LOYDGB21H91'],
    ['LLOYDS BANK PLC South St Worthing (776303)', '776303', 'LOYDGB21H92'],
    ["LLOYDS BANK PLC Terminus Rd E'bourne776304", '776304', 'LOYDGB21H64'],
    ['LLOYDS BANK PLC Cliffe Lewes (776305)', '776305', 'LOYDGB21H36'],
    ['LLOYDS BANK PLC Ashford   Bank St', '776306', 'LOYDGB21H37'],
    ['LLOYDS BANK PLC Cecil Square Margate 776307', '776307', 'LOYDGB21H38'],
    ['LLOYDS BANK PLC Canterbury (77-63-08)', '776308', 'LOYDGB21H39'],
    ['LLOYDS BANK PLC Hastings (776309)', '776309', 'LOYDGB21H40'],
    ['LLOYDS BANK PLC Ramsgate  (776310)', '776310', 'LOYDGB21H41'],
    ['LLOYDS BANK PLC Deal  (776311)', '776311', 'LOYDGB21H42'],
    ['LLOYDS BANK PLC Horsham (776312)', '776312', 'LOYDGB21H43'],
    ['LLOYDS BANK PLC Hastings (776313)', '776313', 'LOYDGB21H44'],
    ['LLOYDS BANK PLC Uckfield (776314', '776314', 'LOYDGB21H27'],
    ['LLOYDS BANK PLC Whitstable (776315)', '776315', 'LOYDGB21H28'],
    ['LLOYDS BANK PLC Tunbridge Wells (776316)', '776316', 'LOYDGB21H29'],
    ['LLOYDS BANK PLC D/Shire Rd Bexhill 776317', '776317', 'LOYDGB21H30'],
    ['LLOYDS BANK PLC The Boulevard Crawley', '776318', 'LOYDGB21H31'],
    ['LLOYDS BANK PLC Folkestone  110 Sandgate Rd', '776319', 'LOYDGB21H32'],
    ['LLOYDS BANK PLC Dover  Castle St', '776320', 'LOYDGB21H33'],
    ['LLOYDS BANK PLC Burgess Hill (776323)', '776323', 'LOYDGB21H34'],
    ['LLOYDS BANK PLC Herne Bay   162/164 High St', '776325', 'LOYDGB21H35'],
    ['LLOYDS BANK PLC North St Brighton 776326', '776326', 'LOYDGB21H45'],
    ['LLOYDS BANK PLC Hove (776327)', '776327', 'LOYDGB21H55'],
    ['LLOYDS BANK PLC Tonbridge  117 High St', '776328', 'LOYDGB21H56'],
    ['LLOYDS BANK PLC East Grinstead   High St', '776329', 'LOYDGB21H57'],
    ['LLOYDS BANK PLC Haywards Heath 776330', '776330', 'LOYDGB21H58'],
    ['LLOYDS BANK PLC Sevenoaks (776331)', '776331', 'LOYDGB21H59'],
    ['LLOYDS BANK PLC Ramsgate (776332)', '776332', 'LOYDGB21H60'],
    ['TSB BANK PLC Bluewater Park', '776333', ''],
    ['TSB BANK PLC TSB (776493)', '776493', ''],
    ['TSB BANK PLC TSB (776499)', '776499', ''],
    ['TSB BANK PLC TSB (776501)', '776501', ''],
    ['TSB BANK PLC TSB (776502)', '776502', ''],
    ['LLOYDS BANK PLC Ammanford    19 Quay St', '776503', 'LOYDGB21H63'],
    ['LLOYDS BANK PLC Cardigan', '776504', 'LOYDGB21H46'],
    ['TSB BANK PLC TSB (776505)', '776505', ''],
    ['LLOYDS BANK PLC Gorskinon (776506)', '776506', 'LOYDGB21H48'],
    ['TSB BANK PLC TSB (776507)', '776507', ''],
    ['LLOYDS BANK PLC Haverfordwest (776508)', '776508', 'LOYDGB21H50'],
    ['LLOYDS BANK PLC Llanelli', '776510', 'LOYDGB21H51'],
    ['LLOYDS BANK PLC Morriston (776511)', '776511', 'LOYDGB21H52'],
    ['LLOYDS BANK PLC Windsor Road Neath (776512)', '776512', 'LOYDGB21H53'],
    ['LLOYDS BANK PLC Haverfordwest (776513)', '776513', 'LOYDGB21H54'],
    ['LLOYDS BANK PLC Swansea E-Prise Park 776514', '776514', 'LOYDGB21C03'],
    ['LLOYDS BANK PLC Port Talbot (776515)', '776515', 'LOYDGB21G51'],
    ['TSB BANK PLC TSB (776517)', '776517', ''],
    ['TSB BANK PLC TSB (776518)', '776518', ''],
    ['TSB BANK PLC TSB (776548)', '776548', ''],
    ['TSB BANK PLC TSB (776557)', '776557', ''],
    ['TSB BANK PLC TSB (776569)', '776569', ''],
    ['TSB BANK PLC TSB (776576)', '776576', ''],
    ['TSB BANK PLC TSB  (776580)', '776580', ''],
    ['TSB BANK PLC TSB (776582)', '776582', ''],
    ['TSB BANK PLC TSB (776582)', '776587', ''],
    ['TSB BANK PLC TSB (776591)', '776591', ''],
    ['TSB BANK PLC TSB (776594)', '776594', ''],
    ['TSB BANK PLC TSB (776595)', '776595', ''],
    ['TSB BANK PLC TSB (776596)', '776596', ''],
    ['TSB BANK PLC TSB (776597)', '776597', ''],
    ['TSB BANK PLC TSB (776598)', '776598', ''],
    ['TSB BANK PLC TSB (776599)', '776599', ''],
    ["LLOYDS BANK PLC Gentleman's Walk Nor 776601", '776601', 'LOYDGB21E50'],
    ['TSB BANK PLC TSB (776602)', '776602', ''],
    ['TSB BANK PLC TSB (776603)', '776603', ''],
    ['LLOYDS BANK PLC Great Yarmouth (776604)', '776604', 'LOYDGB21E53'],
    ['LLOYDS BANK PLC Lowestoft   161 London Rd N', '776605', 'LOYDGB21E54'],
    ['LLOYDS BANK PLC Kings Lynn  High St', '776606', 'LOYDGB21E55'],
    ['LLOYDS BANK PLC North Walsham (776607)', '776607', 'LOYDGB21E56'],
    ['LLOYDS BANK PLC Gorleston on Sea  776608', '776608', 'LOYDGB21E39'],
    ['LLOYDS BANK PLC Dereham (776609)', '776609', 'LOYDGB21E40'],
    ['TSB BANK PLC TSB (776610)', '776610', ''],
    ['LLOYDS BANK PLC Cornhill North Ipswich', '776611', 'LOYDGB21E42'],
    ['LLOYDS BANK PLC Diss (776612)', '776612', 'LOYDGB21E43'],
    ['LLOYDS BANK PLC Beccles (776613)', '776613', 'LOYDGB21E44'],
    ['LLOYDS BANK PLC Wymondham', '776614', 'LOYDGB21E45'],
    ['LLOYDS BANK PLC High St Colchester', '776615', 'LOYDGB21E46'],
    ['LLOYDS BANK PLC Diss (776616)', '776616', 'LOYDGB21E47'],
    ['LLOYDS BANK PLC Bank St Braintree 776617', '776617', 'LOYDGB21E57'],
    ['LLOYDS BANK PLC Cromer West St (776618)', '776618', 'LOYDGB21E67'],
    ['LLOYDS BANK PLC Downham Market  Bridge St', '776619', 'LOYDGB21E68'],
    ['LLOYDS BANK PLC Lloyds Bank plc', '776620', 'LOYDGB21E69'],
    ['LLOYDS BANK PLC Bank St Braintree (776621)', '776621', 'LOYDGB21E70'],
    ['LLOYDS BANK PLC Cromer West St (776622)', '776622', 'LOYDGB21E71'],
    ['LLOYDS BANK PLC Cromer West St (776623)', '776623', 'LOYDGB21E72'],
    ['LLOYDS BANK PLC Fakenham 776624', '776624', 'LOYDGB21E73'],
    ['TSB BANK PLC TSB (776625)', '776625', ''],
    ['LLOYDS BANK PLC Buttermkt Bury St Ed 776626', '776626', 'LOYDGB21E75'],
    ['LLOYDS BANK PLC Woodbridge 776627', '776627', 'LOYDGB21E58'],
    ['LLOYDS BANK PLC West Avenue Clacton-on-Sea', '776628', 'LOYDGB21E59'],
    ['TSB BANK PLC TSB (776629)', '776629', ''],
    ['TSB BANK PLC TSB (776630)', '776630', ''],
    ["LLOYDS BANK PLC Gentleman's Walk Nor 776631", '776631', 'LOYDGB21E62'],
    ['LLOYDS BANK PLC Witham (776632)', '776632', 'LOYDGB21E63'],
    ['LLOYDS BANK PLC West Avenue Clacton-on-Sea', '776633', 'LOYDGB21E64'],
    ['LLOYDS BANK PLC Cornhill North Ipswich', '776634', 'LOYDGB21E65'],
    ['LLOYDS BANK PLC Sudbury 776635', '776635', 'LOYDGB21E66'],
    ['LLOYDS BANK PLC Hunstanton (77-66-36)', '776636', 'LOYDGB21E38'],
    ['TSB BANK PLC TSB (776638)', '776638', ''],
    ['LLOYDS BANK PLC Thetford (776639)', '776639', 'LOYDGB21E11'],
    ['LLOYDS BANK PLC Newmarket (776640)', '776640', 'LOYDGB21E12'],
    ['LLOYDS BANK PLC High St Colchester', '776641', 'LOYDGB21E13'],
    ['LLOYDS BANK PLC Lowestoft Kirkley (T)', '776642', 'LOYDGB21E14'],
    ["LLOYDS BANK PLC Gentleman's Walk Nor 776643", '776643', 'LOYDGB21E15'],
    ['TSB BANK PLC TSB (776644)', '776644', ''],
    ['LLOYDS BANK PLC Cornhill North Ipswich', '776645', 'LOYDGB21E17'],
    ['LLOYDS BANK PLC Great Yarmouth (776646)', '776646', 'LOYDGB21E18'],
    ['LLOYDS BANK PLC High St Colchester', '776647', 'LOYDGB21E01'],
    ['LLOYDS BANK PLC High St Colchester (776649)', '776649', 'LOYDGB21E02'],
    ['LLOYDS BANK PLC Cornhill North Ipswich', '776650', 'LOYDGB21E03'],
    ['TSB BANK PLC TSB (776652)', '776652', ''],
    ['LLOYDS BANK PLC Heartsease Norwich (776653)', '776653', 'LOYDGB21E05'],
    ['LLOYDS BANK PLC Credit Operations', '776654', ''],
    ['LLOYDS BANK PLC Credit Operations', '776655', ''],
    ['LLOYDS BANK PLC Credit Operations', '776656', ''],
    ['LLOYDS BANK PLC Credit Operations', '776657', ''],
    ['LLOYDS BANK PLC Credit Operations', '776658', ''],
    ['LLOYDS BANK PLC Credit Operations', '776659', ''],
    ['LLOYDS BANK PLC Credit Operations', '776660', ''],
    ['LLOYDS BANK PLC Credit Operations', '776661', ''],
    ['TSB BANK PLC Post Office Count', '776662', ''],
    ['TSB BANK PLC London, West End', '776663', ''],
    ['TSB BANK PLC London, Finchley', '776664', ''],
    ['TSB BANK PLC Ilford', '776665', ''],
    ['TSB BANK PLC Sutton', '776668', ''],
    ['TSB BANK PLC Manchester, Market Street', '776669', ''],
    ['TSB BANK PLC Guildford', '776671', ''],
    ['TSB BANK PLC Oxford', '776672', ''],
    ['TSB BANK PLC TSB Reading (776673)', '776673', ''],
    ['TSB BANK PLC Shrewsbury', '776674', ''],
    ['LLOYDS BANK PLC Stockport (776702)', '776702', 'LOYDGB21E06'],
    ['LLOYDS BANK PLC Stockport (776703)', '776703', 'LOYDGB21E07'],
    ['LLOYDS BANK PLC Stockport (776704)', '776704', 'LOYDGB21E08'],
    ['LLOYDS BANK PLC Reddish', '776705', 'LOYDGB21E09'],
    ['LLOYDS BANK PLC Stockport (776706)', '776706', 'LOYDGB21E19'],
    ['LLOYDS BANK PLC Hazel Grove', '776707', 'LOYDGB21E29'],
    ['LLOYDS BANK PLC Macclesfield', '776708', 'LOYDGB21E30'],
    ['TSB BANK PLC Buxton', '776709', ''],
    ['LLOYDS BANK PLC New Mills', '776710', 'LOYDGB21E32'],
    ['LLOYDS BANK PLC Stockport (776711)', '776711', 'LOYDGB21E33'],
    ['LLOYDS BANK PLC Stockport (776712)', '776712', 'LOYDGB21E34'],
    ['TSB BANK PLC Wilmslow', '776713', ''],
    ['LLOYDS BANK PLC Poynton (Colo 776707)', '776714', 'LOYDGB21E36'],
    ['TSB BANK PLC Buxton', '776715', ''],
    ['TSB BANK PLC Wilmslow', '776716', ''],
    ['TSB BANK PLC Wilmslow', '776717', ''],
    ['LLOYDS BANK PLC Stockport (776718)', '776718', 'LOYDGB21E22'],
    ["TSB BANK PLC High St S'hampton (776719)", '776719', ''],
    ['TSB BANK PLC Cardiff, Working Street', '776803', ''],
    ['TSB BANK PLC Taunton', '776805', ''],
    ['TSB BANK PLC Worcester', '776806', ''],
    ['TSB BANK PLC TSB Yeovil (776813)', '776813', ''],
    ['TSB BANK PLC Stroud', '776815', ''],
    ['TSB BANK PLC London, Sidcup', '776817', ''],
    ['TSB BANK PLC Leicester, Granby Street', '776821', ''],
    ['TSB BANK PLC Norwich, Guildhall Hill', '776822', ''],
    ['TSB BANK PLC Solihull', '776826', ''],
    ['TSB BANK PLC Coventry, High Street', '776829', ''],
    ['TSB BANK PLC Devizes', '776830', ''],
    ['TSB BANK PLC Leeds, Headrow', '776831', ''],
    ['TSB BANK PLC Hitchin', '776832', ''],
    ['TSB BANK PLC Tewkesbury', '776833', ''],
    ['TSB BANK PLC Carmarthen, Dark Gate', '776834', ''],
    ['TSB BANK PLC Liverpool, Lord Street', '776835', ''],
    ['TSB BANK PLC Ipswich, Buttermarket', '776836', ''],
    ['TSB BANK PLC Cheltenham, Bath Road', '776837', ''],
    ["TSB BANK PLC Sheffield W'seats (776838)", '776838', ''],
    ['TSB BANK PLC Newcastle, Ridley Place', '776839', ''],
    ['TSB BANK PLC Ross on Wye', '776841', ''],
    ['TSB BANK PLC Nottingham, Beast Market', '776844', ''],
    ['TSB BANK PLC Plymouth, Armada Way', '776845', ''],
    ['TSB BANK PLC Witney', '776846', ''],
    ['TSB BANK PLC Chippenham', '776848', ''],
    ['TSB BANK PLC Southend on Sea', '776849', ''],
    ['TSB BANK PLC Stourbridge', '776850', ''],
    ['TSB BANK PLC Malvern', '776851', ''],
    ['TSB BANK PLC Bishops Cleeve', '776852', ''],
    ['TSB BANK PLC Newport (Gwent)', '776853', ''],
    ['TSB BANK PLC Lymington', '776854', ''],
    ['TSB BANK PLC TSB Yeovil (776855)', '776855', ''],
    ['TSB BANK PLC Moreton in Marsh', '776856', ''],
    ['TSB BANK PLC Okehampton', '776857', ''],
    ['TSB BANK PLC Wells', '776858', ''],
    ['TSB BANK PLC Dursley', '776859', ''],
    ['TSB BANK PLC St Marychurch Torquay', '776862', ''],
    ['TSB BANK PLC Hucclecote', '776863', ''],
    ['TSB BANK PLC Wootton Bassett', '776864', ''],
    ['LLOYDS BANK PLC Headofthevalleyhub776867', '776867', 'LOYDGB21F57'],
    ['LLOYDS BANK PLC Marylebone', '776872', 'LOYDGB21F08'],
    ['LLOYDS BANK PLC Clapham Junction', '776873', 'LOYDGB21F11'],
    ['TSB BANK PLC Croydon', '776874', ''],
    ['TSB BANK PLC Chipping Norton', '776875', ''],
    ['TSB BANK PLC Hull, 51 Jameson Street', '776876', ''],
    ['TSB BANK PLC Gillingham', '776877', ''],
    ['TSB BANK PLC Pershore', '776878', ''],
    ['TSB BANK PLC Cowley', '776879', ''],
    ['TSB BANK PLC Andover', '776880', ''],
    ['TSB BANK PLC Portsmouth', '776881', ''],
    ['TSB BANK PLC Truro', '776882', ''],
    ['TSB BANK PLC Dorchester', '776886', ''],
    ['TSB BANK PLC Durham', '776888', ''],
    ['TSB BANK PLC Wolverhampton, Queen Street', '776889', ''],
    ['TSB BANK PLC Leamington Spa', '776893', ''],
    ['TSB BANK PLC Northampton, Abington St', '776920', ''],
    ['TSB BANK PLC Derby, East Street', '776937', ''],
    ['TSB BANK PLC Slough', '776938', ''],
    ['TSB BANK PLC Cinderford', '776939', ''],
    ['TSB BANK PLC Ledbury', '776950', ''],
    ['TSB BANK PLC Wotton under Edge', '776966', ''],
    ['TSB BANK PLC Swindon, The Parade', '776967', ''],
    ['TSB BANK PLC Thornbury', '776968', ''],
    ['TSB BANK PLC Luton', '776969', ''],
    ['TSB BANK PLC Weston Super Mare', '776995', ''],
    ['TSB BANK PLC Swansea, Union Street', '776996', ''],
    ['TSB BANK PLC Worthing', '776997', ''],
    ['TSB BANK PLC Brighton, Western Road', '776998', ''],
    ['TSB BANK PLC Quedgeley', '776999', ''],
    ['TSB BANK PLC Cheddar', '777017', ''],
    ['TSB BANK PLC High Wycombe', '777029', ''],
    ['TSB BANK PLC Eastbourne', '777043', ''],
    ['TSB BANK PLC Bromley', '777048', ''],
    ['TSB BANK PLC St Albans', '777050', ''],
    ['TSB BANK PLC Stratford-upon-Avon', '777058', ''],
    ['TSB BANK PLC Waltham Abbey', '777059', ''],
    ['TSB BANK PLC Ware', '777060', ''],
    ['TSB BANK PLC Watford', '777061', ''],
    ['TSB BANK PLC Newbury', '777064', ''],
    ['TSB BANK PLC Kingston-upon-Thames', '777067', ''],
    ['TSB BANK PLC Colchester', '777068', ''],
    ['TSB BANK PLC London, London Wall', '777069', ''],
    ['TSB BANK PLC Ashford', '777070', ''],
    ['TSB BANK PLC Harrow, Station Road', '777072', ''],
    ['TSB BANK PLC Winchester', '777073', ''],
    ['TSB BANK PLC Bury St Edmunds', '777074', ''],
    ['TSB BANK PLC Bishops Stortford', '777075', ''],
    ['TSB BANK PLC Poole', '777076', ''],
    ['TSB BANK PLC Bedford', '777077', ''],
    ['TSB BANK PLC London, Holborn', '777095', ''],
    ['TSB BANK PLC Canterbury', '777096', ''],
    ['LLOYDS BANK PLC Scottish Widows (777100)', '777100', ''],
    ["TSB BANK PLC York, St Helen's Square", '777101', ''],
    ['LLOYDS BANK PLC Pavement York (777102)', '777102', 'LOYDGB21E24'],
    ['LLOYDS BANK PLC Cheapside  Barnsley', '777103', 'LOYDGB21E25'],
    ['TSB BANK PLC Salisbury', '777104', ''],
    ['TSB BANK PLC Beverley', '777105', ''],
    ['TSB BANK PLC Shipley', '777106', ''],
    ["TSB BANK PLC London, St James's Park", '777107', ''],
    ['TSB BANK PLC Horsham', '777110', ''],
    ['TSB BANK PLC Bradford', '777112', ''],
    ['TSB BANK PLC Bradford', '777113', ''],
    ['TSB BANK PLC Bridlington', '777114', ''],
    ['LLOYDS BANK PLC Castleford', '777115', 'LOYDGB21G25'],
    ['TSB BANK PLC Doncaster', '777116', ''],
    ['LLOYDS BANK PLC Doncaster Bentley (T)', '777117', 'LOYDGB21G27'],
    ['LLOYDS BANK PLC Doncaster Woodlands', '777118', 'LOYDGB21G28'],
    ['TSB BANK PLC Doncaster', '777119', ''],
    ['LLOYDS BANK PLC Driffield (777121)', '777121', 'LOYDGB21G30'],
    ['LLOYDS BANK PLC Thirsk (777122)', '777122', 'LOYDGB21G31'],
    ['LLOYDS BANK PLC Pontefract (777123)', '777123', 'LOYDGB21G14'],
    ['LLOYDS BANK PLC Scarborough (777124)', '777124', 'LOYDGB21G15'],
    ['TSB BANK PLC Gainsborough', '777125', ''],
    ['LLOYDS BANK PLC Grantham (777127)', '777127', 'LOYDGB21B85'],
    ['LLOYDS BANK PLC Grantham (777127)', '777128', 'LOYDGB21G17'],
    ['LLOYDS BANK PLC Guisborough  37 Westgate', '777129', 'LOYDGB21G18'],
    ['LLOYDS BANK PLC Halifax 777130', '777130', 'LOYDGB21G19'],
    ['LLOYDS BANK PLC Harrogate (777131)', '777131', 'LOYDGB21G20'],
    ['LLOYDS BANK PLC Pavement York (777132)', '777132', 'LOYDGB21G21'],
    ['TSB BANK PLC Redhill', '777133', ''],
    ['TSB BANK PLC Milton Keynes', '777135', ''],
    ['TSB BANK PLC Keighley', '777136', ''],
    ['LLOYDS BANK PLC Harrogate (777138)', '777138', 'LOYDGB21G32'],
    ['LLOYDS BANK PLC Pontefract  (777139)', '777139', 'LOYDGB21G42'],
    ['TSB BANK PLC Chelmsford, 1 High Street', '777140', ''],
    ['TSB BANK PLC Malton', '777141', ''],
    ['LLOYDS BANK PLC Pavement York (777142)', '777142', 'LOYDGB21G44'],
    ['LLOYDS BANK PLC Middlesbrough (777143)', '777143', 'LOYDGB21G45'],
    ['LLOYDS BANK PLC Middlesbrough (777144)', '777144', 'LOYDGB21G46'],
    ['LLOYDS BANK PLC Newark  Stodman Street', '777145', 'LOYDGB21G47'],
    ['TSB BANK PLC Normanton', '777146', ''],
    ['TSB BANK PLC Northallerton', '777147', ''],
    ['TSB BANK PLC Ossett', '777148', ''],
    ['TSB BANK PLC Ilkley (777149)', '777149', ''],
    ['TSB BANK PLC Malton', '777150', ''],
    ['LLOYDS BANK PLC Pavement York (777151)', '777151', 'LOYDGB21G35'],
    ['LLOYDS BANK PLC Pontefract (777152)', '777152', 'LOYDGB21G36'],
    ['TSB BANK PLC Redcar', '777153', ''],
    ['TSB BANK PLC Retford', '777154', ''],
    ['TSB BANK PLC Richmond', '777155', ''],
    ['LLOYDS BANK PLC Harrogate (777156)', '777156', 'LOYDGB21G40'],
    ['TSB BANK PLC Redcar', '777158', ''],
    ['LLOYDS BANK PLC Scarborough (777159)', '777159', 'LOYDGB21G13'],
    ['LLOYDS BANK PLC Selby (777161)', '777161', 'LOYDGB21E85'],
    ['TSB BANK PLC Shipley', '777162', ''],
    ['TSB BANK PLC Skipton', '777163', ''],
    ['LLOYDS BANK PLC Middlesbrough (777164)', '777164', 'LOYDGB21E88'],
    ['LLOYDS BANK PLC South Elmsall', '777165', 'LOYDGB21E89'],
    ['LLOYDS BANK PLC Halifax (777167)', '777167', 'LOYDGB21E90'],
    ['LLOYDS BANK PLC Thirsk (777168)', '777168', 'LOYDGB21E91'],
    ['TSB BANK PLC Chingford', '777169', ''],
    ['TSB BANK PLC Thorne', '777170', ''],
    ['TSB BANK PLC Northgate Wakefield', '777171', ''],
    ['LLOYDS BANK PLC Wetherby (777172)', '777172', 'LOYDGB21E76'],
    ['TSB BANK PLC Whitby', '777173', ''],
    ['LLOYDS BANK PLC Wombwell (T)', '777174', 'LOYDGB21E78'],
    ['LLOYDS BANK PLC Worksop (777175)', '777175', 'LOYDGB21E79'],
    ['LLOYDS BANK PLC Stockton-on-Tees (777176)', '777176', 'LOYDGB21E80'],
    ['LLOYDS BANK PLC Barnsley Easibank', '777177', 'LOYDGB21C04'],
    ['LLOYDS BANK PLC Boston  (777201)', '777201', 'LOYDGB21E81'],
    ['TSB BANK PLC Louth', '777202', ''],
    ['TSB BANK PLC Enfield, 49 Church Street', '777203', ''],
    ['LLOYDS BANK PLC Bourne (777204)', '777204', 'LOYDGB21E83'],
    ['LLOYDS BANK PLC Ely (777205)', '777205', 'LOYDGB21E84'],
    ['LLOYDS BANK PLC Ely (777206)', '777206', 'LOYDGB21E94'],
    ['LLOYDS BANK PLC Spalding (777207)', '777207', 'LOYDGB21G04'],
    ['LLOYDS BANK PLC Horncastle 777208', '777208', 'LOYDGB21G05'],
    ['LLOYDS BANK PLC Huntingdon (777209)', '777209', 'LOYDGB21G06'],
    ['LLOYDS BANK PLC Ely (777210)', '777210', 'LOYDGB21G07'],
    ['TSB BANK PLC Long Sutton', '777211', ''],
    ['TSB BANK PLC Louth', '777212', ''],
    ['LLOYDS BANK PLC Mercer Row Louth (777213)', '777213', 'LOYDGB21G10'],
    ['LLOYDS BANK PLC March  56 High Street', '777214', 'LOYDGB21G11'],
    ['LLOYDS BANK PLC Oundle (T)', '777215', 'LOYDGB21G12'],
    ['LLOYDS BANK PLC Peterborough  Westgate', '777216', 'LOYDGB21E95'],
    ['TSB BANK PLC Peterborough, Millfield', '777217', ''],
    ['TSB BANK PLC Ramsey', '777218', ''],
    ['LLOYDS BANK PLC St Ives, Cambridge (777219)', '777219', 'LOYDGB21E98'],
    ['LLOYDS BANK PLC St Neotts (777220)', '777220', 'LOYDGB21E99'],
    ['TSB BANK PLC Skegness', '777221', ''],
    ['LLOYDS BANK PLC Sleaford (777222)', '777222', 'LOYDGB21G01'],
    ['LLOYDS BANK PLC Ely (777223)', '777223', 'LOYDGB21G02'],
    ['TSB BANK PLC TSB High Holborn', '777224', ''],
    ['LLOYDS BANK PLC Spalding (777225)', '777225', 'LOYDGB21G03'],
    ['LLOYDS BANK PLC Lumley Rd Skegness 777226', '777226', 'LOYDGB21L00'],
    ['LLOYDS BANK PLC Stamford  Ironmonger Street', '777227', 'LOYDGB21L01'],
    ['LLOYDS BANK PLC Westgate Peterborough', '777230', 'LOYDGB21L02'],
    ['LLOYDS BANK PLC Wisbech  (777231)', '777231', 'LOYDGB21L03'],
    ['LLOYDS BANK PLC Westgate Peterborough', '777233', 'LOYDGB21L04'],
    ['TSB BANK PLC Twickenham', '777234', ''],
    ['LLOYDS BANK PLC Market Deeping (T)', '777235', 'LOYDGB21L05'],
    ['LLOYDS BANK PLC Peterborough  Bretton', '777237', 'LOYDGB21L06'],
    ['TSB BANK PLC Crawley', '777239', ''],
    ['TSB BANK PLC London, Putney', '777241', ''],
    ['TSB BANK PLC Fareham', '777243', ''],
    ['TSB BANK PLC Newport (Iow)', '777258', ''],
    ['TSB BANK PLC Petersfield', '777260', ''],
    ['TSB BANK PLC Ealing', '777261', ''],
    ['TSB BANK PLC London, Hammersmith', '777262', ''],
    ['TSB BANK PLC Sutton Coldfield', '777263', ''],
    ['TSB BANK PLC Wythenshawe (777264)', '777264', ''],
    ['TSB BANK PLC Bolton, Hotel Street', '777265', ''],
    ['TSB BANK PLC Preston, Fishergate', '777266', ''],
    ['TSB BANK PLC Camberley', '777267', ''],
    ['TSB BANK PLC Harrogate', '777268', ''],
    ['TSB BANK PLC Romford, Stewards Walk', '777269', ''],
    ['TSB BANK PLC Chester', '777270', ''],
    ['TSB BANK PLC Maidstone', '777272', ''],
    ['TSB BANK PLC Burgess Hill', '777273', ''],
    ['TSB BANK PLC Peterboro, 40 Long Causeway', '777275', ''],
    ['TSB BANK PLC Uxbridge', '777276', ''],
    ['TSB BANK PLC York, Parliament Street', '777277', ''],
    ['LLOYDS BANK PLC Bristol City Ctr (777301)', '777301', 'LOYDGB21L07'],
    ['LLOYDS BANK PLC Bristol City Ctr (777302)', '777302', 'LOYDGB21L08'],
    ['LLOYDS BANK PLC Bristol Gloucester Road', '777303', 'LOYDGB21K91'],
    ['LLOYDS BANK PLC Bristol City Ctr (777304)', '777304', 'LOYDGB21K92'],
    ['LLOYDS BANK PLC Westbury on Trym 777305', '777305', 'LOYDGB21K93'],
    ['LLOYDS BANK PLC Milsom Street Bath (777306)', '777306', 'LOYDGB21K94'],
    ["LLOYDS BANK PLC Bristol  Downend Rd K'wood", '777307', 'LOYDGB21K95'],
    ['LLOYDS BANK PLC Bristol City Ctr (777309)', '777309', 'LOYDGB21K96'],
    ['TSB BANK PLC Keynsham', '777311', ''],
    ['LLOYDS BANK PLC Yate (777312)', '777312', 'LOYDGB21K98'],
    ['LLOYDS BANK PLC High St Sheffield (777401)', '777401', 'LOYDGB21K99'],
    ['TSB BANK PLC Sheffield, Meadowhall', '777402', ''],
    ['LLOYDS BANK PLC High St Sheffield (777403)', '777403', 'LOYDGB21L19'],
    ['LLOYDS BANK PLC High St Sheffield (777405)', '777405', 'LOYDGB21L20'],
    ["TSB BANK PLC Sheffield W'seats (777406)", '777406', ''],
    ['LLOYDS BANK PLC Sheffield Crookes (T)', '777407', 'LOYDGB21L22'],
    ['LLOYDS BANK PLC High St Sheffield', '777408', 'LOYDGB21L23'],
    ['LLOYDS BANK PLC Sheffield Shiregreen (T)', '777409', 'LOYDGB21L24'],
    ['LLOYDS BANK PLC Sheffield Intake (777410)', '777410', 'LOYDGB21L25'],
    ['LLOYDS BANK PLC Sheffield  Woodhouse', '777411', 'LOYDGB21L26'],
    ['LLOYDS BANK PLC Sheffield  Hillsborough', '777413', 'LOYDGB21L27'],
    ['LLOYDS BANK PLC Sheffield  Firth Park', '777414', 'LOYDGB21L10'],
    ['TSB BANK PLC Sheffield, Darnall', '777415', ''],
    ['LLOYDS BANK PLC Sheffield  Chapeltown', '777416', 'LOYDGB21L12'],
    ['LLOYDS BANK PLC Rose Hill Chesterfield', '777417', 'LOYDGB21L13'],
    ['LLOYDS BANK PLC Rose Hill Chesterfield', '777418', 'LOYDGB21L14'],
    ['LLOYDS BANK PLC Hexborough (777419)', '777419', 'LOYDGB21L15'],
    ['LLOYDS BANK PLC Ecclesfield (T)', '777420', 'LOYDGB21L16'],
    ['LLOYDS BANK PLC Sheffield  Eckington', '777421', 'LOYDGB21L17'],
    ['TSB BANK PLC Maltby', '777422', ''],
    ['LLOYDS BANK PLC Mexborough (777423)', '777423', 'LOYDGB21K90'],
    ['LLOYDS BANK PLC Rotherham (777424)', '777424', 'LOYDGB21K62'],
    ['LLOYDS BANK PLC Rotherham (777425)', '777425', 'LOYDGB21K63'],
    ['LLOYDS BANK PLC Rose Hill Chesterfield', '777426', 'LOYDGB21K64'],
    ['LLOYDS BANK PLC Stocksbridge (T)', '777427', 'LOYDGB21K65'],
    ['LLOYDS BANK PLC Hexborough (777428)', '777428', 'LOYDGB21K66'],
    ['LLOYDS BANK PLC Sheffield Intake (777430)', '777430', 'LOYDGB21K67'],
    ['LLOYDS BANK PLC Sheffield Intake (777432)', '777432', 'LOYDGB21K68'],
    ['LLOYDS BANK PLC High St Sheffield (777433)', '777433', 'LOYDGB21K69'],
    ['LLOYDS BANK PLC High St Sheffield (777434)', '777434', 'LOYDGB21K70'],
    ['LLOYDS BANK PLC High St Sheffield (777435)', '777435', 'LOYDGB21K53'],
    ['TSB BANK PLC Sheffield, Woodseats', '777436', ''],
    ['TSB BANK PLC Sheffield, Darnall', '777438', ''],
    ['TSB BANK PLC Rugby', '777439', ''],
    ['TSB BANK PLC Warwick', '777459', ''],
    ['LLOYDS BANK PLC York Region', '777500', ''],
    ['LLOYDS BANK PLC Credit Operations', '777504', ''],
    ['TSB BANK PLC Lichfield', '777528', ''],
    ['LLOYDS BANK PLC Clitheroe', '777601', 'LOYDGB21K57'],
    ['TSB BANK PLC Rawtenstall', '777602', ''],
    ['TSB BANK PLC Nelson', '777603', ''],
    ['LLOYDS BANK PLC Burnley (777604)', '777604', 'LOYDGB21K60'],
    ['LLOYDS BANK PLC Accrington (777605)', '777605', 'LOYDGB21K61'],
    ['LLOYDS BANK PLC Burnley (777606)', '777606', 'LOYDGB21K71'],
    ['TSB BANK PLC Rawtenstall', '777607', ''],
    ['TSB BANK PLC Nelson', '777608', ''],
    ['LLOYDS BANK PLC Darwen Market St (777609)', '777609', 'LOYDGB21K83'],
    ['LLOYDS BANK PLC Accrington (777612)', '777612', 'LOYDGB21K84'],
    ['TSB BANK PLC Haslingden', '777613', ''],
    ['TSB BANK PLC Cannock', '777615', ''],
    ['LLOYDS BANK PLC Accrington (777616)', '777616', 'LOYDGB21K86'],
    ['LLOYDS BANK PLC Burnley (777617)', '777617', 'LOYDGB21K87'],
    ['TSB BANK PLC Todmorden', '777618', ''],
    ['TSB BANK PLC Old Hill', '777619', ''],
    ['LLOYDS BANK PLC Accrington (777622)', '777622', 'LOYDGB21K89'],
    ['TSB BANK PLC Bastwell Blackburn', '777624', ''],
    ['LLOYDS BANK PLC Blackburn  Lord West Street', '777625', 'LOYDGB21K73'],
    ['LLOYDS BANK PLC Church St Blackburn', '777626', 'LOYDGB21K74'],
    ['TSB BANK PLC Aylesbury', '777627', ''],
    ['TSB BANK PLC Redditch', '777628', ''],
    ['TSB BANK PLC Walsall, Bridge Street', '777635', ''],
    ['TSB BANK PLC Aldridge', '777668', ''],
    ['TSB BANK PLC Woking', '777678', ''],
    ['TSB BANK PLC Doncaster', '777681', ''],
    ['LLOYDS BANK PLC Personal Loan', '777702', 'LOYDGB21F00'],
    ['LLOYDS BANK PLC Customer Supp Unit (777704)', '777704', ''],
    ['LLOYDS BANK PLC Ipsl - Cri', '777706', ''],
    ['LLOYDS BANK PLC Internet Banking 777751', '777751', 'LOYDGB21F89'],
    ['LLOYDS BANK PLC Internet Banking 777752', '777752', 'LOYDGB21F90'],
    ['LLOYDS BANK PLC Andover Operations (777768)', '777768', ''],
    ['LLOYDS BANK PLC Clp Agency Sort Code', '777790', ''],
    ['LLOYDS BANK PLC Andover Operations (777793)', '777793', ''],
    ['LLOYDS BANK PLC Hayes (777797)', '777797', 'LOYDGB21F77'],
    ['LLOYDS BANK PLC Cent Rtrns in Closed', '777804', ''],
    ['LLOYDS BANK PLC Market St Manchester 777830', '777830', 'LOYDGB21C05'],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '777835', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '777836', ''],
    ['LLOYDS BANK PLC Banking Operations', '777855', ''],
    ['TSB BANK PLC Warrington, Buttermarket St', '777903', ''],
    ['TSB BANK PLC Grimsby, Victoria Street', '777910', ''],
    ['TSB BANK PLC Cloth Hall St Huddersfield', '777912', ''],
    ['TSB BANK PLC Bristol, Merchant Street', '777913', ''],
    ['TSB BANK PLC Lincoln, High Street', '777916', ''],
    ['LLOYDS BANK PLC Andover Bba 4 777932', '777932', 'LOYDGB21Z90'],
    ['LLOYDS BANK PLC Andover Bba 3 (777933)', '777933', 'LOYDGB21Z91'],
    ['LLOYDS BANK PLC Halifax 777935', '777935', 'LOYDGB21Y87'],
    ['LLOYDS BANK PLC Oxford St Swansea', '777936', 'LOYDGB21Y93'],
    ['LLOYDS BANK PLC Scottish Widows Bank plc 1', '777951', ''],
    ['LLOYDS BANK PLC Peterborough Osc 7', '777964', 'LOYDGB21F01'],
    ['LLOYDS BANK PLC Telephony-Solent Tsc777965', '777965', ''],
    ['LLOYDS BANK PLC 2nd Floor Whm (777975)', '777975', ''],
    ['LLOYDS BANK PLC Andover Bba 4 (777988)', '777988', 'LOYDGB21Z92'],
    ['LLOYDS BANK PLC Andover Bba 2 (777992)', '777992', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '777994', ''],
    ['LLOYDS BANK PLC Andover Bba 3 (777996)', '777996', 'LOYDGB21Z93'],
    ['LLOYDS BANK PLC Portland Street4 (778103)', '778103', ''],
    ['LLOYDS BANK PLC Csc1 Thorpe Wood P/Borough', '778108', 'LOYDGB21K80'],
    ['TSB BANK PLC Birmingham Sc (778111)', '778111', ''],
    ['LLOYDS BANK PLC 2nd Floor Whm (778112)', '778112', 'LOYDGB21F02'],
    ['LLOYDS BANK PLC Haywards Heath Cashiering', '778127', ''],
    ['LLOYDS BANK PLC Grp Recs 778141', '778141', 'LOYDGB21L78'],
    ['LLOYDS BANK PLC Andover Pca - (778157)', '778157', 'LOYDGB21F03'],
    ['TSB BANK PLC Birmingham Sc 8 (778241)', '778241', ''],
    ['TSB BANK PLC Birmingham Sc (778272)', '778272', ''],
    ['LLOYDS BANK PLC Banking Operations', '778292', ''],
    ['LLOYDS BANK PLC Southern H O', '778400', ''],
    ['LLOYDS BANK PLC Birmingham Region', '778500', ''],
    ['LLOYDS BANK PLC Bhah Temple Row (778501)', '778501', 'LOYDGB21L58'],
    ['LLOYDS BANK PLC Bham Acocks Green (778502)', '778502', 'LOYDGB21L59'],
    ['TSB BANK PLC Smethwick, Bearwood', '778507', ''],
    ['TSB BANK PLC Birmingham, Perry Barr', '778509', ''],
    ["LLOYDS BANK PLC B'ham  Bordesley Green", '778510', 'LOYDGB21L62'],
    ['TSB BANK PLC Birmingham, Stirchley', '778511', ''],
    ['LLOYDS BANK PLC Bham Temple Row (778512)', '778512', 'LOYDGB21L64'],
    ['LLOYDS BANK PLC Cotteridge Birmingham', '778514', 'LOYDGB21L47'],
    ["LLOYDS BANK PLC B'ham 224 High St Erdington", '778516', 'LOYDGB21L48'],
    ['TSB BANK PLC Halesowen', '778518', ''],
    ['LLOYDS BANK PLC Handsworth (77-85-20)', '778520', 'LOYDGB21L50'],
    ['LLOYDS BANK PLC Harborne (77-85-21)', '778521', 'LOYDGB21L51'],
    ['LLOYDS BANK PLC Bham Edgbaston (778523)', '778523', 'LOYDGB21L52'],
    ['LLOYDS BANK PLC Bham Kings Heath (778525)', '778525', 'LOYDGB21L53'],
    ['LLOYDS BANK PLC Birmingham  Kingstanding', '778526', 'LOYDGB21L54'],
    ['LLOYDS BANK PLC Birmingham  Kitts Green', '778527', 'LOYDGB21L55'],
    ['LLOYDS BANK PLC Bham Edgbaston (778528)', '778528', 'LOYDGB21K52'],
    ['TSB BANK PLC Birmingham, Longbridge', '778530', ''],
    ['LLOYDS BANK PLC Birmingham  Maypole', '778532', 'LOYDGB21J50'],
    ['LLOYDS BANK PLC Bham Kings Heath (778533)', '778533', 'LOYDGB21J51'],
    ["LLOYDS BANK PLC B'ham  701 Bristol Road Sth", '778536', 'LOYDGB21J52'],
    ['TSB BANK PLC Oldbury', '778537', ''],
    ['TSB BANK PLC Birmingham, Kingstanding', '778539', ''],
    ['TSB BANK PLC Halesowen', '778540', ''],
    ['TSB BANK PLC Birmingham, Pype Hayes', '778541', ''],
    ['TSB BANK PLC Birmingham, Longbridge', '778544', ''],
    ['TSB BANK PLC Saltley Birmingham 778545', '778545', ''],
    ['LLOYDS BANK PLC Bham Castle Bromwich', '778550', 'LOYDGB21J41'],
    ['LLOYDS BANK PLC Shirley West Mid (778552)', '778552', 'LOYDGB21J42'],
    ['LLOYDS BANK PLC Birmingham Small Heath (T)', '778554', 'LOYDGB21J43'],
    ['TSB BANK PLC Smethwick, Cape Hill', '778555', ''],
    ['LLOYDS BANK PLC Poplar Rd Solihull (778556)', '778556', 'LOYDGB21J45'],
    ['LLOYDS BANK PLC Birmingham Sparkhill (T)', '778558', 'LOYDGB21J46'],
    ['LLOYDS BANK PLC Birmingham Springfield', '778559', 'LOYDGB21J47'],
    ['LLOYDS BANK PLC Direct Accounts', '778560', 'LOYDGB21J48'],
    ['LLOYDS BANK PLC Sutton Coldfield (778563)', '778563', 'LOYDGB21J58'],
    ['LLOYDS BANK PLC Birmingham  Ward End', '778565', 'LOYDGB21J68'],
    ['TSB BANK PLC Birmingham, Sheldon', '778566', ''],
    ['LLOYDS BANK PLC Birmingham  Weoley Castle', '778567', 'LOYDGB21J70'],
    ['TSB BANK PLC TSB Birmingham New Street', '778568', ''],
    ['LLOYDS BANK PLC Bham Yardley (778570)', '778570', 'LOYDGB21J72'],
    ['TSB BANK PLC Birmingham, Great Barr', '778571', ''],
    ['LLOYDS BANK PLC Bham Temple Row (778572)', '778572', 'LOYDGB21J74'],
    ['LLOYDS BANK PLC Birmingham Bartley Green(T)', '778574', 'LOYDGB21J75'],
    ['LLOYDS BANK PLC Birmingham Chelmsley Wood', '778576', 'LOYDGB21J76'],
    ['LLOYDS BANK PLC Sutton Coldfield (778577)', '778577', 'LOYDGB21J59'],
    ['LLOYDS BANK PLC Sutton Coldfield (778579)', '778579', 'LOYDGB21J60'],
    ['LLOYDS BANK PLC New St Birmingham', '778588', 'LOYDGB21Y12'],
    ['TSB BANK PLC Birmingham, Great Barr', '778589', ''],
    ['LLOYDS BANK PLC Banking Operations', '778707', ''],
    ['TSB BANK PLC Post Office Branch 2', '778712', ''],
    ['LLOYDS BANK PLC Banking Operations', '778715', ''],
    ['LLOYDS BANK PLC Banking Operations', '778718', ''],
    ['LLOYDS BANK PLC Onboarding Test 778721', '778721', ''],
    ['LLOYDS BANK PLC Banking Operations', '778728', ''],
    ['LLOYDS BANK PLC Isc Chaps', '778730', ''],
    ['LLOYDS BANK PLC Andover Saga 2', '778750', 'LOYDGB21Y13'],
    ['LLOYDS BANK PLC Banking Operations', '778806', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 1', '778810', ''],
    ['LLOYDS BANK PLC Banking Operations', '778816', ''],
    ['LLOYDS BANK PLC Banking Operations', '778817', ''],
    ['LLOYDS BANK PLC Banking Operations', '778824', ''],
    ['LLOYDS BANK PLC Pc Tprs', '778829', ''],
    ['LLOYDS BANK PLC Banking Operations', '778830', ''],
    ['LLOYDS BANK PLC Banking & Treasury Wood St', '778851', 'LOYDGB21J62'],
    ['LLOYDS BANK PLC Hs Bank Banking & Treasury', '778852', ''],
    ['LLOYDS BANK PLC Onboarding Test 778854', '778854', ''],
    ['LLOYDS BANK PLC Salisbury', '778855', 'LOYDGB21Y96'],
    ['LLOYDS BANK PLC Credit Operations', '778860', ''],
    ['LLOYDS BANK PLC Gtc (Non Ics Trans)', '778862', ''],
    ['LLOYDS BANK PLC Banking Ops Offshore Team', '778867', ''],
    ['LLOYDS BANK PLC Gops Oppi Server 8', '778868', ''],
    ['LLOYDS BANK PLC Banking Operations', '778891', ''],
    ['LLOYDS BANK PLC Collection A/C Hspf-Mfc', '778892', ''],
    ['LLOYDS BANK PLC Collection A/C Hspf-Hspf', '778893', ''],
    ['LLOYDS BANK PLC Collection A/C Hspf-Rmfc', '778894', ''],
    ['LLOYDS BANK PLC Process Design & Improvemen', '778920', ''],
    ['LLOYDS BANK PLC Customer Supp Unit (778922)', '778922', ''],
    ['LLOYDS BANK PLC Credit Operations', '778924', ''],
    ['LLOYDS BANK PLC Research & Adjment', '778930', ''],
    ['LLOYDS BANK PLC Ipsl - Northfield', '778932', ''],
    ['LLOYDS BANK PLC Credit Operations', '778936', ''],
    ['LLOYDS BANK PLC Cannon Street (779102)', '779102', 'LOYDGB21J64'],
    ['LLOYDS BANK PLC Islington (779103)', '779103', 'LOYDGB21J65'],
    ['LLOYDS BANK PLC Holborn Circus', '779104', 'LOYDGB21J66'],
    ['LLOYDS BANK PLC Victoria (779105)', '779105', 'LOYDGB21J67'],
    ['LLOYDS BANK PLC Cannon Street (779107)', '779107', 'LOYDGB21J39'],
    ['LLOYDS BANK PLC Brixton (779109)', '779109', 'LOYDGB21J11'],
    ['LLOYDS BANK PLC Wood Green', '779110', 'LOYDGB21J12'],
    ['TSB BANK PLC London, Leytonstone', '779111', ''],
    ['LLOYDS BANK PLC Hammersmith (779112)', '779112', 'LOYDGB21J14'],
    ['LLOYDS BANK PLC Kilburn (779113)', '779113', 'LOYDGB21J15'],
    ['TSB BANK PLC Enfield, 22-24 Church St', '779114', ''],
    ['LLOYDS BANK PLC Putney (779115)', '779115', 'LOYDGB21J17'],
    ['LLOYDS BANK PLC Wembley 779116', '779116', 'LOYDGB21J18'],
    ['LLOYDS BANK PLC Camden Town (77-91-17)', '779117', 'LOYDGB21J19'],
    ['LLOYDS BANK PLC Bromley (779118)', '779118', 'LOYDGB21J02'],
    ['TSB BANK PLC London, Eltham', '779119', ''],
    ['LLOYDS BANK PLC Twickenham (779120)', '779120', 'LOYDGB21J04'],
    ["TSB BANK PLC St Ann's Road Harrow", '779121', ''],
    ['LLOYDS BANK PLC Broadway Stratford (779122)', '779122', 'LOYDGB21J06'],
    ['LLOYDS BANK PLC Golders Green (779123)', '779123', 'LOYDGB21J07'],
    ['LLOYDS BANK PLC Fulham (779124)', '779124', 'LOYDGB21J08'],
    ['LLOYDS BANK PLC Chiswick 779126', '779126', 'LOYDGB21J09'],
    ['TSB BANK PLC London, Harlesden', '779127', ''],
    ['LLOYDS BANK PLC Victoria (779128)', '779128', 'LOYDGB21J20'],
    ['LLOYDS BANK PLC Lewisham (779129)', '779129', 'LOYDGB21J30'],
    ['LLOYDS BANK PLC Streatham (779130)', '779130', 'LOYDGB21J31'],
    ['LLOYDS BANK PLC Kilburn (779131)', '779131', 'LOYDGB21J32'],
    ['LLOYDS BANK PLC Camden Town (77-91-32)', '779132', 'LOYDGB21J33'],
    ['LLOYDS BANK PLC Ealing (779133)', '779133', 'LOYDGB21J34'],
    ['TSB BANK PLC London, Peckham', '779134', ''],
    ['LLOYDS BANK PLC North Finchley (779136)', '779136', 'LOYDGB21J36'],
    ['TSB BANK PLC London, Bethnal Green', '779138', ''],
    ['LLOYDS BANK PLC Kilburn (779139)', '779139', 'LOYDGB21J38'],
    ['TSB BANK PLC London, Bermondsey', '779140', ''],
    ['LLOYDS BANK PLC Bromley (779141)', '779141', 'LOYDGB21J22'],
    ['TSB BANK PLC London, Tottenham', '779142', ''],
    ['LLOYDS BANK PLC Holborn Circus', '779143', 'LOYDGB21J24'],
    ['LLOYDS BANK PLC Bexleyheath (779144)', '779144', 'LOYDGB21J25'],
    ['LLOYDS BANK PLC Southall (779145)', '779145', 'LOYDGB21J26'],
    ['LLOYDS BANK PLC Hayes  (779146)', '779146', 'LOYDGB21J27'],
    ['TSB BANK PLC Enfield, 22-24 Church St', '779147', ''],
    ['LLOYDS BANK PLC Orpington (779148)', '779148', 'LOYDGB21J29'],
    ['TSB BANK PLC London, Acton', '779149', ''],
    ['TSB BANK PLC Hounslow', '779150', ''],
    ['LLOYDS BANK PLC Bexleyheath (779151)', '779151', 'LOYDGB21K26'],
    ['LLOYDS BANK PLC Woolwich (779152)', '779152', 'LOYDGB21K27'],
    ['TSB BANK PLC London, Wandsworth', '779153', ''],
    ['TSB BANK PLC Hounslow', '779154', ''],
    ['LLOYDS BANK PLC Watford (779155)', '779155', 'LOYDGB21K30'],
    ['LLOYDS BANK PLC Walthamstow (779156)', '779156', 'LOYDGB21K31'],
    ['LLOYDS BANK PLC Ruislip (779157)', '779157', 'LOYDGB21K32'],
    ['TSB BANK PLC Burnt Oak', '779158', ''],
    ['LLOYDS BANK PLC Broadway Stratford (779160)', '779160', 'LOYDGB21K16'],
    ['LLOYDS BANK PLC Lewisham (779161(', '779161', 'LOYDGB21K17'],
    ["TSB BANK PLC Harrow, St Ann's Road", '779163', ''],
    ['TSB BANK PLC Greenford', '779164', ''],
    ['LLOYDS BANK PLC Cannon Street (779165)', '779165', 'LOYDGB21K20'],
    ['LLOYDS BANK PLC 399 Oxford Street 779166', '779166', 'LOYDGB21K21'],
    ['LLOYDS BANK PLC Edmonton', '779168', 'LOYDGB21K23'],
    ['TSB BANK PLC Barnet', '779169', ''],
    ['LLOYDS BANK PLC 399 Oxford Street', '779171', 'LOYDGB21K43'],
    ['LLOYDS BANK PLC Collection A/C (Tl)', '779180', ''],
    ['LLOYDS BANK PLC Collection A/C (Nfm)', '779181', ''],
    ['LLOYDS BANK PLC H Off-Finance(Ag1)', '779300', 'LOYDGB21K45'],
    ['LLOYDS BANK PLC Rtl Lab-Tr1 Testing779301', '779301', ''],
    ['LLOYDS BANK PLC Bankcard Support Unit', '779302', ''],
    ['LLOYDS BANK PLC Conu', '779304', ''],
    ['LLOYDS BANK PLC Collections Centre 779307', '779307', ''],
    ['LLOYDS BANK PLC Liverpool Cash Centre', '779309', ''],
    ['LLOYDS BANK PLC Customer Financial Assistan', '779311', ''],
    ['LLOYDS BANK PLC Lloyds Bank Phonebank', '779314', 'LOYDGB21K35'],
    ['LLOYDS BANK PLC Grp Recs 779331', '779331', ''],
    ['LLOYDS BANK PLC Grp Recs 779332', '779332', ''],
    ['LLOYDS BANK PLC Head Office - Finance (Ag2)', '779400', ''],
    ['LLOYDS BANK PLC Test Branch (Wmh 779401)', '779401', 'LOYDGB21Z82'],
    ['LLOYDS BANK PLC Credit Operations', '779406', ''],
    ['LLOYDS BANK PLC Test Branch Csc', '779409', ''],
    ['LLOYDS BANK PLC Collections Supp (779411)', '779411', ''],
    ['LLOYDS BANK PLC Loyd Homeloans Rms E&W', '779413', ''],
    ['LLOYDS BANK PLC Lloyds Bank Phonebank', '779414', 'LOYDGB21K14'],
    ['LLOYDS BANK PLC London Region', '779500', ''],
    ['LLOYDS BANK PLC Bedford High St 779501', '779501', 'LOYDGB21J86'],
    ['LLOYDS BANK PLC Guildford (779502)', '779502', 'LOYDGB21J87'],
    ['TSB BANK PLC Chatham', '779503', ''],
    ['LLOYDS BANK PLC Above Bar St Southampton', '779504', 'LOYDGB21J89'],
    ['LLOYDS BANK PLC Newbury 779505', '779505', 'LOYDGB21J90'],
    ['LLOYDS BANK PLC Gravesend (779506)', '779506', 'LOYDGB21J91'],
    ['LLOYDS BANK PLC Whhtsthighwycombe', '779507', 'LOYDGB21J92'],
    ['LLOYDS BANK PLC High St Gillingham (779508)', '779508', 'LOYDGB21J93'],
    ['LLOYDS BANK PLC Soton-Shirley (779509)', '779509', 'LOYDGB21J94'],
    ['LLOYDS BANK PLC Southend-on-Sea (779510)', '779510', 'LOYDGB21J77'],
    ['LLOYDS BANK PLC George St Luton (779511)', '779511', 'LOYDGB21J78'],
    ['LLOYDS BANK PLC Dunstable (779512)', '779512', 'LOYDGB21J79'],
    ['TSB BANK PLC Chatham', '779513', ''],
    ['LLOYDS BANK PLC Bicester (779514)', '779514', 'LOYDGB21J81'],
    ['LLOYDS BANK PLC Maidstone Earl St (779515)', '779515', 'LOYDGB21J82'],
    ['LLOYDS BANK PLC Market Sq Aylesbury(779516)', '779516', 'LOYDGB21J83'],
    ['LLOYDS BANK PLC Andover High St 779517', '779517', 'LOYDGB21J84'],
    ['LLOYDS BANK PLC Dartford (779518)', '779518', 'LOYDGB21J85'],
    ['LLOYDS BANK PLC Hadleigh ( 779519)', '779519', 'LOYDGB21J95'],
    ['LLOYDS BANK PLC St Albans (779520)', '779520', 'LOYDGB21K05'],
    ['LLOYDS BANK PLC Godalming  (779521)', '779521', 'LOYDGB21K06'],
    ['LLOYDS BANK PLC Whhtsthighwycombe', '779522', 'LOYDGB21K07'],
    ['LLOYDS BANK PLC 120-124 H St Dorking 779523', '779523', 'LOYDGB21K08'],
    ['LLOYDS BANK PLC Watford (779525)', '779525', 'LOYDGB21K09'],
    ['LLOYDS BANK PLC Hemel Hempstead  Marlowes', '779526', 'LOYDGB21K10'],
    ['LLOYDS BANK PLC Strood (779527)', '779527', 'LOYDGB21K11'],
    ['LLOYDS BANK PLC Soton Bitterne (779528)', '779528', 'LOYDGB21K12'],
    ['LLOYDS BANK PLC Rayleigh 779529', '779529', 'LOYDGB21K13'],
    ['LLOYDS BANK PLC Sittingbourne', '779530', 'LOYDGB21J96'],
    ['LLOYDS BANK PLC Maidstone Earl St (779531)', '779531', 'LOYDGB21J97'],
    ['LLOYDS BANK PLC Hemel Hempstead Marlowes', '779533', 'LOYDGB21J99'],
    ['LLOYDS BANK PLC Above Bar St Southampton', '779534', 'LOYDGB21K00'],
    ['LLOYDS BANK PLC Above Bar St Southampton', '779535', 'LOYDGB21K01'],
    ['LLOYDS BANK PLC Watford (779551)', '779551', 'LOYDGB21B90'],
    ['LLOYDS BANK PLC Lloyds Bank Auddis Dummy', '779713', ''],
    ['Bank of Scotland plc Edinburgh New Issues', '800107', 'BOFSGB21235'],
    [
      'Bank of Scotland plc Credit Plan Collect Centre',
      '800210',
      'BOFSGB21234'
    ],
    ['Bank of Scotland plc Edinburgh Msb Centre', '800211', ''],
    ['Bank of Scotland plc Personal Banking Branch 2', '800214', 'BOFSGB21233'],
    ['Bank of Scotland plc A A Scheme', '800215', ''],
    ['Bank of Scotland plc Banking Direct SA Centre', '800216', 'BOFSGB21232'],
    ['Bank of Scotland plc Edinburgh Barnton', '800219', 'BOFSGB21218'],
    ['Bank of Scotland plc Edinburgh Comely Bank', '800222', 'BOFSGB21217'],
    ['Bank of Scotland plc Edinburgh   Royal Mile', '800224', 'BOFSGB21216'],
    [
      'Bank of Scotland plc Edinburgh Corstorphine West',
      '800227',
      'BOFSGB21215'
    ],
    ['Bank of Scotland plc Edinburgh Shandwick800228', '800228', 'BOFSGB21214'],
    ['Bank of Scotland plc Edinburgh Royal Mile', '800229', 'BOFSGB21219'],
    ['Bank of Scotland plc Edinburgh, Newkirkgate', '800232', 'BOFSGB21224'],
    ['Bank of Scotland plc Edinburgh Comely Bank', '800233', 'BOFSGB21223'],
    ["Bank of Scotland plc E'burgh George St 800234", '800234', 'BOFSGB21222'],
    ['Bank of Scotland plc Edinburgh Victoria House', '800238', 'BOFSGB21221'],
    ['Bank of Scotland plc Branch Banking Phoneline', '800250', 'BOFSGBS1PHO'],
    ['Bank of Scotland plc Central Admin UK Banking', '800252', 'BOFSGB21225'],
    [
      'Bank of Scotland plc B of S Product Dev UK Bank',
      '800253',
      'BOFSGB21220'
    ],
    ['Bank of Scotland plc Cashflow Finance', '800254', 'BOFSGBS1CFE'],
    ['Bank of Scotland plc Specialist Function Unit', '800255', 'BOFSGBS1SFU'],
    ['Bank of Scotland plc I C Branch', '800260', 'BOFSGB21INC'],
    ['Bank of Scotland plc Edinburgh, Newkirkgate', '800271', 'BOFSGB21284'],
    ['Bank of Scotland plc Cameron Toll 800273', '800273', 'BOFSGB21349'],
    ['Bank of Scotland plc Edinburgh Morningside', '800277', 'BOFSGB21348'],
    ['Bank of Scotland plc Edinburgh Shandwick', '800278', 'BOFSGB21347'],
    ['Bank of Scotland plc Edinburgh  South Clerk St', '800283', 'BOFSGB21346'],
    ['Bank of Scotland plc Edinburgh Morningside', '800285', 'BOFSGB21345'],
    [
      'Bank of Scotland plc Edinburgh Portobello 800287',
      '800287',
      'BOFSGB21344'
    ],
    ['Bank of Scotland plc Edinburgh Morningside', '800288', 'BOFSGB21355'],
    ['Bank of Scotland plc Aberdeen Castle St (Ssf1)', '800510', 'BOFSGB21CAS'],
    ['Bank of Scotland plc Aberdeen Bridge of Don', '800511', 'BOFSGB21354'],
    ["Bank of Scotland plc Aberdeen Queen's Cross", '800514', 'BOFSGB21353'],
    ['Bank of Scotland plc Aberdeen Upperkirkgat', '800516', 'BOFSGB21352'],
    ['Bank of Scotland plc Aberdeen,Upperkirkgate', '800517', 'BOFSGB21351'],
    [
      "Bank of Scotland plc Aberdeen Queen's Cross (R)",
      '800519',
      'BOFSGB21350'
    ],
    ['Bank of Scotland plc Aberdeen 201 Union St', '800521', 'BOFSGB21337'],
    ['Bank of Scotland plc Pitlochry (R)', '800522', 'BOFSGB21336'],
    ['Bank of Scotland plc Elgin High St (800525)', '800525', 'BOFSGB21335'],
    [
      'Bank of Scotland plc Airdrie,South Bridge Street',
      '800526',
      'BOFSGB21334'
    ],
    ['Bank of Scotland plc Alexandria', '800527', 'BOFSGB21333'],
    ['Bank of Scotland plc Alloa', '800528', 'BOFSGB21332'],
    ['Bank of Scotland plc Alness', '800530', 'BOFSGB21343'],
    ['Bank of Scotland plc Annan', '800532', 'BOFSGB21342'],
    ['Bank of Scotland plc Perth (800537)', '800537', 'BOFSGB21341'],
    ['Bank of Scotland plc Aviemore (800540)', '800540', 'BOFSGB21340'],
    ['Bank of Scotland plc Ballater', '800551', 'BOFSGB21339'],
    ['Bank of Scotland plc Bowmore Islay', '800552', 'BOFSGB21338'],
    ['Bank of Scotland plc Barrhead', '800554', 'BOFSGB21377'],
    ['Bank of Scotland plc Bathgate', '800556', 'BOFSGB21368'],
    ['Bank of Scotland plc Bearsden Roman Road', '800557', 'BOFSGB21372'],
    ['Bank of Scotland plc Benbecula', '800560', 'BOFSGB21371'],
    ['Bank of Scotland plc Inverness City (800561)', '800561', 'BOFSGB21370'],
    ['Bank of Scotland plc Johnstone (800562)', '800562', 'BOFSGB21369'],
    ['Bank of Scotland plc Bellshill', '800563', 'BOFSGB21376'],
    ['Bank of Scotland plc Bishopbriggs', '800564', 'BOFSGB21375'],
    ['Bank of Scotland plc Blairgowrie', '800568', 'BOFSGB21374'],
    ['Bank of Scotland plc Bo-Ness', '800573', ''],
    ['Bank of Scotland plc Bonnyrigg', '800575', ''],
    ['Bank of Scotland plc Broughty Ferry', '800580', 'BOFSGB21373'],
    ['Bank of Scotland plc Stirling Port St', '800581', 'BOFSGB21361'],
    ['Bank of Scotland plc Bridge of Weir', '800582', 'BOFSGB21360'],
    ['Bank of Scotland plc Kyle (800583)', '800583', 'BOFSGB21359'],
    ['Bank of Scotland plc Brodick', '800584', 'BOFSGB21358'],
    ['Bank of Scotland plc Buckie', '800587', 'BOFSGB21357'],
    ['Bank of Scotland plc Callander', '800591', 'BOFSGB21356'],
    ['Bank of Scotland plc Campbeltown', '800593', 'BOFSGB21367'],
    ['Bank of Scotland plc Castle Douglas (800611)', '800611', 'BOFSGB21366'],
    ['Bank of Scotland plc Clydebank', '800614', 'BOFSGB21365'],
    ['Bank of Scotland plc Blairgowrie (800618)', '800618', 'BOFSGB21364'],
    ['Bank of Scotland plc Cults', '800619', 'BOFSGB21363'],
    ['Bank of Scotland plc Edinburgh Gyle Centre', '800620', 'BOFSGB21362'],
    ['Bank of Scotland plc Crieff', '800621', 'BOFSGB21331'],
    ['Bank of Scotland plc Cumnock', '800624', 'BOFSGB21301'],
    ['Bank of Scotland plc Cupar', '800626', 'BOFSGB21300'],
    ['Bank of Scotland plc Dalkeith', '800629', 'BOFSGB21299'],
    ['Bank of Scotland plc Falkirk High St 800633', '800633', 'BOFSGB21298'],
    ['Bank of Scotland plc Dingwall', '800635', 'BOFSGB21297'],
    ['Bank of Scotland plc Tain (800636)', '800636', 'BOFSGB21302'],
    ['Bank of Scotland plc Dumbarton', '800641', 'BOFSGB21307'],
    ['Bank of Scotland plc Dumfries,Irish Street', '800643', ''],
    ['Bank of Scotland plc Dunblane', '800647', 'BOFSGB21306'],
    ['Bank of Scotland plc Dunfermline', '800655', 'BOFSGB21305'],
    ['Bank of Scotland plc Dunkeld', '800657', 'BOFSGB21304'],
    ['Bank of Scotland plc Dyce', '800660', 'BOFSGB21303'],
    [
      'Bank of Scotland plc E Kilbride 41 Princes Mall',
      '800664',
      'BOFSGB21296'
    ],
    ['Bank of Scotland plc Elgin High St (800666)', '800666', 'BOFSGB21289'],
    ['Bank of Scotland plc Ellon (800671)', '800671', 'BOFSGB21288'],
    ['Bank of Scotland plc Falkirk High Street', '800674', 'BOFSGB21287'],
    ['Bank of Scotland plc Elgin High St (800676)', '800676', 'BOFSGB21286'],
    ['Bank of Scotland plc Forfar', '800677', 'BOFSGB21285'],
    ['Bank of Scotland plc Forres', '800681', 'BOFSGB21290'],
    ['Bank of Scotland plc Inverness City (800683)', '800683', 'BOFSGB21295'],
    ['Bank of Scotland plc Fort William', '800684', 'BOFSGB21294'],
    ['Bank of Scotland plc Fraserburgh', '800686', 'BOFSGB21293'],
    ['Bank of Scotland plc Gairloch', '800687', 'BOFSGB21292'],
    ['Bank of Scotland plc Galashiels (800688)', '800688', 'BOFSGB21291'],
    ['Bank of Scotland plc Kilmarnock (800691)', '800691', 'BOFSGB21324'],
    ['Bank of Scotland plc Clarkston', '800695', 'BOFSGB21323'],
    ['Bank of Scotland plc Girvan', '800696', 'BOFSGB21322'],
    ['Bank of Scotland plc Glasgow The Forge(800712)', '800712', 'BOFSGB21321'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (800714)',
      '800714',
      'BOFSGB21320'
    ],
    ['Bank of Scotland plc Glasgow Anniesland', '800715', 'BOFSGB21325'],
    ['Bank of Scotland plc Glasgow Victoria Road (R)', '800717', 'BOFSGB21330'],
    [
      'Bank of Scotland plc Glasgow Byres Road (800724)',
      '800724',
      'BOFSGB21329'
    ],
    ['Bank of Scotland plc Glasgow Victoria Road', '800734', 'BOFSGB21328'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (800736)',
      '800736',
      'BOFSGB21327'
    ],
    ['Bank of Scotland plc Glasgow,Govan', '800743', 'BOFSGB21326'],
    ['Bank of Scotland plc Glasgow Cardonald', '800746', 'BOFSGB21319'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (800748)',
      '800748',
      'BOFSGB21312'
    ],
    [
      'Bank of Scotland plc Glasgow Byres Road (800752)',
      '800752',
      'BOFSGB21311'
    ],
    ['Bank of Scotland plc Glasgow Govan', '800753', 'BOFSGB21310'],
    ['Bank of Scotland plc Glasgow Partick (800755)', '800755', 'BOFSGB21309'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (800760)',
      '800760',
      'BOFSGB21308'
    ],
    ['Bank of Scotland plc Glasgow Argyle St 800761', '800761', 'BOFSGB21313'],
    ['Bank of Scotland plc Glasgow Partick (800765)', '800765', 'BOFSGB21318'],
    ['Bank of Scotland plc Glasgow,236 Albert Drive', '800767', 'BOFSGB21317'],
    ['Bank of Scotland plc Glasgow Partick (800773)', '800773', 'BOFSGB21316'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (800774)',
      '800774',
      'BOFSGB21315'
    ],
    [
      'Bank of Scotland plc Glasgow Anniesland (800775)',
      '800775',
      'BOFSGB21314'
    ],
    ['Bank of Scotland plc Glasgow Shawlands', '800776', 'BOFSGB21190'],
    ['Bank of Scotland plc Glasgow The Forge(800778)', '800778', 'BOFSGB21064'],
    ['Bank of Scotland plc Glasgow The Forge(800785)', '800785', 'BOFSGB21063'],
    ['Bank of Scotland plc Glenrothes', '800809', 'BOFSGB21062'],
    ['Bank of Scotland plc Falkirk,Bainsford', '800813', ''],
    ['Bank of Scotland plc Aviemore (800816)', '800816', 'BOFSGB21061'],
    [
      'Bank of Scotland plc Greenock,West Blackhall St.',
      '800821',
      'BOFSGB21060'
    ],
    ['Bank of Scotland plc North Berwick (800822)', '800822', 'BOFSGB21065'],
    ['Bank of Scotland plc Haddington', '800823', 'BOFSGB21070'],
    ['Bank of Scotland plc Hamilton New Cross', '800824', 'BOFSGB21069'],
    ['Bank of Scotland plc Hamilton,Cadzow Street', '800825', 'BOFSGB21068'],
    ['Bank of Scotland plc Helensburgh,Colquhoun Sq.', '800831', 'BOFSGB21067'],
    ['Bank of Scotland plc Inverurie (800833)', '800833', 'BOFSGB21066'],
    ['Bank of Scotland plc Inverurie (800841)', '800841', 'BOFSGB21059'],
    ['Bank of Scotland plc Irvine', '800842', 'BOFSGB21052'],
    ['Bank of Scotland plc Buckie (800845)', '800845', 'BOFSGB21051'],
    ['Bank of Scotland plc Kilmarnock Bank Street', '800853', 'BOFSGB21050'],
    ['Bank of Scotland plc Kirkcudbright', '800863', 'BOFSGB21049'],
    ['Bank of Scotland plc Kirkintilloch', '800864', 'BOFSGB21048'],
    ['Bank of Scotland plc Kirkwall', '800866', 'BOFSGB21053'],
    ['Bank of Scotland plc Kyle', '800871', 'BOFSGB21058'],
    ['Bank of Scotland plc Larkhall', '800877', 'BOFSGB21057'],
    ['Bank of Scotland plc Livingston (800880)', '800880', 'BOFSGB21056'],
    ['Bank of Scotland plc Lerwick', '800882', 'BOFSGB21055'],
    ['Bank of Scotland plc Kyle (800884)', '800884', 'BOFSGB21054'],
    ['Bank of Scotland plc Cowdenbeath 800885', '800885', 'BOFSGB21071'],
    ['Bank of Scotland plc Lochgilphead', '800886', 'BOFSGB21088'],
    ['Bank of Scotland plc Lochmaddy', '800887', ''],
    ['Bank of Scotland plc Lockerbie', '800888', 'BOFSGB21087'],
    ['Bank of Scotland plc Elgin High St (800891)', '800891', 'BOFSGB21086'],
    ['Bank of Scotland plc Banff (800892)', '800892', 'BOFSGB21085'],
    ['Bank of Scotland plc Mallaig', '800894', 'BOFSGB21084'],
    ['Bank of Scotland plc Milngavie', '800898', 'BOFSGB21089'],
    ['Bank of Scotland plc Moffat', '800911', 'BOFSGB21094'],
    ['Bank of Scotland plc Motherwell,Brandon Street', '800915', 'BOFSGB21093'],
    ['Bank of Scotland plc Nairn', '800917', 'BOFSGB21092'],
    ['Bank of Scotland plc New Pitsligo', '800921', ''],
    ['Bank of Scotland plc Newton Mearns', '800922', 'BOFSGB21091'],
    [
      'Bank of Scotland plc Paisley The Cross (800929)',
      '800929',
      'BOFSGB21090'
    ],
    ['Bank of Scotland plc Paisley,Glasgow Road', '800931', ''],
    ['Bank of Scotland plc Paisley The Cross', '800932', 'BOFSGB21083'],
    ['Bank of Scotland plc Peebles,70 High Street', '800933', 'BOFSGB21076'],
    ['Bank of Scotland plc Peterhead', '800938', 'BOFSGB21075'],
    ['Bank of Scotland plc Penicuik', '800939', 'BOFSGB21074'],
    ['Bank of Scotland plc Pitlochry', '800941', 'BOFSGB21073'],
    ['Bank of Scotland plc Port Glasgow', '800943', 'BOFSGB21072'],
    ['Bank of Scotland plc Portree', '800947', 'BOFSGB21077'],
    ['Bank of Scotland plc Ayr High Street (800948)', '800948', 'BOFSGB21082'],
    ['Bank of Scotland plc Rutherglen', '800951', 'BOFSGB21081'],
    ['Bank of Scotland plc St.Andrews,Queens Gardens', '800955', 'BOFSGB21080'],
    ['Bank of Scotland plc Saltcoats', '800958', 'BOFSGB21079'],
    ['Bank of Scotland plc Stewarton', '800965', ''],
    ['Bank of Scotland plc Stonehaven', '800968', 'BOFSGB21078'],
    ['Bank of Scotland plc East Kilbride (800972)', '800972', 'BOFSGB21017'],
    ['Bank of Scotland plc Stornoway', '800973', 'BOFSGB21016'],
    ['Bank of Scotland plc Strathaven', '800975', 'BOFSGB21015'],
    ['Bank of Scotland plc Tarbert Argyll', '800982', 'BOFSGB21014'],
    ['Bank of Scotland plc Tarbert Harris', '800983', 'BOFSGB21013'],
    ['Bank of Scotland plc Thurso', '800986', 'BOFSGB21018'],
    ['Bank of Scotland plc Troon', '800988', 'BOFSGB21023'],
    ['Bank of Scotland plc Banff (800992)', '800992', 'BOFSGB21022'],
    ['Bank of Scotland plc Uddington,the Cross', '800993', 'BOFSGB21021'],
    ['Bank of Scotland plc West Linton', '800994', ''],
    ['Bank of Scotland plc Wick', '800996', 'BOFSGB21020'],
    [
      'Bank of Scotland plc Bofs St Andrew Sq Bhscentre',
      '801090',
      'BOFSGB21019'
    ],
    ['Bank of Scotland plc Edinburgh George St', '801100', 'BOFSGB21012'],
    ['Bank of Scotland plc London - Oxford Street', '801102', ''],
    ['Bank of Scotland plc Edinburgh Comely Bank', '801105', 'BOFSGB21005'],
    ['Bank of Scotland plc Edinburgh George St', '801110', 'BOFSGB21004'],
    ['Bank of Scotland plc Edinburgh Morningside', '801114', 'BOFSGB21003'],
    ['Bank of Scotland plc Edinburgh Shandwick', '801120', 'BOFSGB21002'],
    ['Bank of Scotland plc Morningside (801130)', '801130', 'BOFSGB21001'],
    [
      'Bank of Scotland plc Customer Financial Assistan',
      '801135',
      'BOFSGB21006'
    ],
    ['Bank of Scotland plc Head Office Syndications', '801145', 'BOFSGB21011'],
    ['Bank of Scotland plc B of S Edin Cpu', '801154', ''],
    ['Bank of Scotland plc Grp Recs 801158', '801158', 'BOFSGBS1SSD'],
    ['Bank of Scotland plc B of S Aberdeen Cpu', '801159', ''],
    ['Bank of Scotland plc Dumfries', '801160', 'BOFSGB21010'],
    ['Bank of Scotland plc B of S Hihglands Cpu', '801162', ''],
    [
      'Bank of Scotland plc Mortgage Processing Centre',
      '801166',
      'BOFSGBS1MPS'
    ],
    ['Bank of Scotland plc Ams Edinburgh', '801168', 'BOFSGBS1OPE'],
    ['Bank of Scotland plc Banking Direct Osu', '801169', 'BOFSGBS1SUP'],
    ['Bank of Scotland plc I R C U', '801171', ''],
    ['Bank of Scotland plc Op Support Papm Coe', '801172', 'BOFSGBS1SSO'],
    ['Bank of Scotland plc Business Banking Ams', '801174', 'BOFSGBS1BBA'],
    ['Bank of Scotland plc Castle Terrace No.3 Bch', '801175', ''],
    ['Bank of Scotland plc Client Bkng Support-Corp', '801177', 'BOFSGBS1CLB'],
    ['Bank of Scotland plc Mortgage Admin Centre', '801178', 'BOFSGBS1MAC'],
    ['Bank of Scotland plc Glasgow Argyle St 801180', '801180', 'BOFSGB21009'],
    ['Bank of Scotland plc Libor Unit', '801193', ''],
    ['Bank of Scotland plc Morningside (801194)', '801194', 'BOFSGB21008'],
    ['Bank of Scotland plc London - Haymarket', '801205', ''],
    ['Bank of Scotland plc Aberdeen Upperkirkgate', '801206', 'BOFSGB21007'],
    ['Bank of Scotland plc Aberdeen 201 Union St', '801208', 'BOFSGB21024'],
    ['Bank of Scotland plc Aberdeen, Upperkirkgate', '801215', 'BOFSGB21041'],
    ['Bank of Scotland plc Arbroath,Brothock Bridge', '801235', 'BOFSGB21040'],
    ['Bank of Scotland plc Ayr, High Street', '801239', 'BOFSGB21039'],
    ['Bank of Scotland plc Milngavie Station 801248', '801248', 'BOFSGB21038'],
    ['Bank of Scotland plc Erskine (801262)', '801262', 'BOFSGB21037'],
    ['Bank of Scotland plc Brechin', '801266', 'BOFSGB21042'],
    ['Bank of Scotland plc Broxburn', '801271', 'BOFSGB21047'],
    ['Bank of Scotland plc Rutherglen (801275)', '801275', 'BOFSGB21046'],
    ['Bank of Scotland plc Carluke', '801279', 'BOFSGB21045'],
    ['Bank of Scotland plc Private Banking Edinburgh', '801280', 'BOFSGBS1PBS'],
    ['Bank of Scotland plc Clarkston (801288)', '801288', 'BOFSGB21044'],
    ['Bank of Scotland plc Coatbridge', '801297', 'BOFSGB21043'],
    ['Bank of Scotland plc Kelso (801302)', '801302', 'BOFSGB21036'],
    ['Bank of Scotland plc Cumbernauld', '801313', 'BOFSGB21029'],
    ['Bank of Scotland plc Clydebank (801315)', '801315', 'BOFSGB21028'],
    ['Bank of Scotland plc Glasgow,Drumchapel', '801322', ''],
    ['LLOYDS BANK PLC Fps Live Proving Test', '801323', ''],
    ['Bank of Scotland plc Dunbar', '801328', 'BOFSGB21027'],
    ['Bank of Scotland plc Bos Nethergate Dundee', '801333', ''],
    ['Bank of Scotland plc Dunoon', '801346', 'BOFSGB21026'],
    ['Bank of Scotland plc Duns', '801351', 'BOFSGB21025'],
    ['Bank of Scotland plc Erskine', '801353', 'BOFSGB21030'],
    ['Bank of Scotland plc Eyemouth', '801357', 'BOFSGB21035'],
    ['Bank of Scotland plc Hbos Group Admin Centre', '801370', 'BOFSGB21GAC'],
    [
      'Bank of Scotland plc Bank of Scotland (Ireland)',
      '801371',
      'BOFSGB21IRL'
    ],
    ['Bank of Scotland plc Garrowhill', '801395', 'BOFSGB21034'],
    ['Bank of Scotland plc Glasgow,Halfway', '801426', 'BOFSGB21033'],
    ['Bank of Scotland plc Glasgow The Forge(801439)', '801439', 'BOFSGB21032'],
    ['Bank of Scotland plc Glasgow Gordon Street', '801450', ''],
    [
      'Bank of Scotland plc Glasgow Byres Road (801462)',
      '801462',
      'BOFSGB21031'
    ],
    ['Bank of Scotland plc Glasgow, Shawlands', '801497', 'BOFSGB21095'],
    ['Bank of Scotland plc Clarkston (801506)', '801506', 'BOFSGB21159'],
    ['Bank of Scotland plc Glasgow Partick (804709)', '801515', 'BOFSGB21412'],
    ['Bank of Scotland plc Glasgow,Riddrie', '801528', 'BOFSGB21413'],
    ['Bank of Scotland plc Glasgow,Shawlands', '801551', 'BOFSGB21158'],
    ['Bank of Scotland plc Glasgow,Strathbungo', '801559', 'BOFSGB21157'],
    ['Bank of Scotland plc Golspie', '801582', 'BOFSGB21156'],
    ['Bank of Scotland plc Greenock', '801586', 'BOFSGB21155'],
    ['Bank of Scotland plc Grangemouth', '801595', 'BOFSGB21160'],
    ['Bank of Scotland plc Hawick', '801622', 'BOFSGB21165'],
    ['Bank of Scotland plc Jedburgh', '801648', 'BOFSGB21164'],
    ['Bank of Scotland plc Johnstone (801653)', '801653', 'BOFSGB21163'],
    ['Bank of Scotland plc Kelso', '801657', 'BOFSGB21162'],
    ['Bank of Scotland plc Perth (801679)', '801679', 'BOFSGB21161'],
    ['Bank of Scotland plc Kirkcaldy,114 High Street', '801684', 'BOFSGB21154'],
    ['Bank of Scotland plc Lanark', '801702', 'BOFSGB21147'],
    ['Bank of Scotland plc Langholm', '801706', 'BOFSGB21146'],
    ['Bank of Scotland plc Largs', '801711', 'BOFSGB21145'],
    ['Bank of Scotland plc Lesmahagow', '801728', 'BOFSGB21144'],
    ['Bank of Scotland plc Leven', '801733', 'BOFSGB21143'],
    ['Bank of Scotland plc Linlithgow', '801737', 'BOFSGB21148'],
    ['Bank of Scotland plc Dalkeith High St (801742)', '801742', 'BOFSGB21153'],
    ['Bank of Scotland plc Montrose', '801759', 'BOFSGB21152'],
    ['Bank of Scotland plc Musselburgh', '801768', 'BOFSGB21151'],
    ['Bank of Scotland plc Newton Stewart (801786)', '801786', 'BOFSGB21150'],
    ['Bank of Scotland plc Galashiels (801791)', '801791', 'BOFSGB21149'],
    ['Bank of Scotland plc North Berwick (801795)', '801795', 'BOFSGB21166'],
    ['Bank of Scotland plc Oban,Station Road', '801799', 'BOFSGB21183'],
    ['Bank of Scotland plc Falkirk High Street', '801826', 'BOFSGB21182'],
    ['Bank of Scotland plc Edinburgh Portobello', '801831', 'BOFSGB21181'],
    ['Bank of Scotland plc Renfrew', '801844', 'BOFSGB21180'],
    ['Bank of Scotland plc Rothesay', '801853', 'BOFSGB21179'],
    ['Bank of Scotland plc Sanquhar', '801862', 'BOFSGB21184'],
    ['Bank of Scotland plc Selkirk', '801866', 'BOFSGB21189'],
    ['Bank of Scotland plc Shotts', '801871', 'BOFSGB21188'],
    ['Bank of Scotland plc Stranraer', '801893', 'BOFSGB21187'],
    ['Bank of Scotland plc Thornhill', '801902', 'BOFSGB21186'],
    ['Bank of Scotland plc Newton Stewart (801933)', '801933', 'BOFSGB21185'],
    ['Bank of Scotland plc Wishaw', '801942', 'BOFSGB21178'],
    ['Bank of Scotland plc Financial Solutions No 1', '801990', 'BOFSGB21171'],
    ['Bank of Scotland plc Financial Solutions No 2', '801992', 'BOFSGB21170'],
    ['Bank of Scotland plc Financial Solutions No 3', '801993', 'BOFSGB21169'],
    ['Bank of Scotland plc Edinburgh   Royal Mile', '802000', 'BOFSGB21168'],
    ['Bank of Scotland plc Chief Accountants Dept.', '802001', 'BOFSGB21167'],
    ['Bank of Scotland plc Specialist Mortgages Serv', '802003', 'BOFSGB21172'],
    ['Bank of Scotland plc Head Office Cashiers Dept', '802004', 'BOFSGB21177'],
    ['Bank of Scotland plc British Linen Bank', '802005', 'BLLIGB21XXX'],
    ['Bank of Scotland plc P.I.S Euro', '802006', 'BOFSGBS1EUG'],
    ['Bank of Scotland plc Mortgagesaver Centre', '802007', 'BOFSGB21MSC'],
    ['Bank of Scotland plc Ccs Personal Loan Centre', '802008', 'BOFSGB21176'],
    [
      'Bank of Scotland plc Head Offce,Savings Bk Loans',
      '802009',
      'BOFSGB21175'
    ],
    ['Bank of Scotland plc Edinburgh Intl Div Loans', '802011', 'BOFSGB21414'],
    [
      'Bank of Scotland plc Payment & International Ser',
      '802013',
      'BOFSGB2SXXX'
    ],
    ['Bank of Scotland plc Bos Shared App Mortgs 2', '802015', 'BOFSGB21415'],
    ['Bank of Scotland plc Bos Shared App Mortgs 3', '802016', 'BOFSGB21416'],
    [
      'Bank of Scotland plc B of S Secured Home Annuit',
      '802017',
      'BOFSGB21174'
    ],
    ['Bank of Scotland plc Mortgage Service Centre', '802018', 'BOFSGB21173'],
    ['Bank of Scotland plc Specialist Property Fin', '802019', 'BOFSGB21112'],
    ['Bank of Scotland plc Timeshare Centre', '802021', 'BOFSGB21111'],
    ['Bank of Scotland plc Saye Centre (No2)', '802022', 'BOFSGBS1SAY'],
    ['Bank of Scotland plc Treasury Division Glasgow', '802025', ''],
    ['Bank of Scotland plc Edinburgh P F S', '802030', ''],
    ['Bank of Scotland plc Central Securities Dept', '802031', ''],
    ['Bank of Scotland plc State Street Trustees', '802032', 'BOFSGB21109'],
    ['Bank of Scotland plc Insurance Services', '802033', 'BOFSGB21108'],
    ['Bank of Scotland plc Investment Services', '802034', 'BOFSGB21113'],
    ['Bank of Scotland plc Personal Equity Plans', '802035', ''],
    ['Bank of Scotland plc Mazars', '802036', 'BOFSGB21117'],
    ['Bank of Scotland plc Mazars', '802037', 'BOFSGB21116'],
    [
      'Bank of Scotland plc Bank of Scotland Visa Card',
      '802040',
      'BOFSGBS1VIS'
    ],
    ['Bank of Scotland plc Merchant Services', '802041', 'BOFSGB21115'],
    ['Bank of Scotland plc Visa Centre', '802042', 'BOFSGB21114'],
    ['Bank of Scotland plc C C C Branch', '802043', 'BOFSGB21107'],
    ['Bank of Scotland plc Visa Centre Bank Group', '802044', 'BOFSGB21100'],
    ['Bank of Scotland plc Visa Settlement', '802045', 'BOFSGB21099'],
    ['Bank of Scotland plc Business Visa Card', '802046', 'BOFSGB21098'],
    ['Bank of Scotland plc Mastercard Affinity', '802047', 'BOFSGB21097'],
    ['Bank of Scotland plc Mastercard', '802048', 'BOFSGBS1RBM'],
    ['Bank of Scotland plc Mastercard Affinity Gold', '802049', 'BOFSGBS1MAG'],
    ['Bank of Scotland plc Nws Trust', '802054', ''],
    ['Bank of Scotland plc Financial Solutions', '802060', 'BOFSGB21096'],
    ['Bank of Scotland plc Cbs Shl No 5', '802064', ''],
    ['Bank of Scotland plc Cbs Residential Mortgages', '802066', 'BOFSGB21101'],
    ['Bank of Scotland plc Bos Shared Mort Appr No 1', '802067', 'BOFSGB21419'],
    ['Bank of Scotland plc Bos Shared Mort Appr No 2', '802068', 'BOFSGB21420'],
    ['Bank of Scotland plc Bos Shared Mort Appr No 3', '802069', 'BOFSGB21421'],
    [
      'Bank of Scotland plc Shared Appreciation Morts 3',
      '802070',
      'BOFSGB21422'
    ],
    [
      'Bank of Scotland plc Shared Appreciation Mort 4',
      '802071',
      'BOFSGB21423'
    ],
    [
      'Bank of Scotland plc Bos Sharedapp Mort(Scot)No2',
      '802072',
      'BOFSGB21424'
    ],
    ['Bank of Scotland plc Bos Sam No5 plc', '802073', 'BOFSGB21425'],
    ['Bank of Scotland plc Bos Sam No 6 plc', '802074', 'BOFSGB21426'],
    ['Bank of Scotland plc Bos Sam Scotland No 3 Ltd', '802075', 'BOFSGB21427'],
    [
      'Bank of Scotland plc Bos Sharedapp Mort(Scot)No4',
      '802076',
      'BOFSGBS1NO4'
    ],
    ['Bank of Scotland plc Bank Direct Bossam No 7', '802077', 'BOFSGBS1NO7'],
    ['Bank of Scotland plc Bank Direct Bossam No 8', '802078', 'BOFSGBS1NO8'],
    ['Bank of Scotland plc Mort Admin Centre 4', '802081', 'BOFSGBS1PBM'],
    ['Bank of Scotland plc Cbs Libor Linked Mort Sch', '802090', ''],
    ['Bank of Scotland plc Cbs Com Loans Low Start Sch', '802091', ''],
    ['Bank of Scotland plc Residential Morts Comm Loan', '802097', ''],
    ['Bank of Scotland plc Hcc Visa', '802124', ''],
    ['Bank of Scotland plc Hcc Visa', '802125', 'BOFSGB41XXX'],
    [
      'Bank of Scotland plc Abb Nat Standard Master Cd',
      '802131',
      'BOFSGBS1PCS'
    ],
    ['Bank of Scotland plc Abb Nat Mastercard Gold', '802132', ''],
    ['Bank of Scotland plc Abb Nat Mastercard Gts', '802134', ''],
    ['Bank of Scotland plc Non Status', '802160', ''],
    ['Bank of Scotland plc Self Certification', '802161', ''],
    ['Bank of Scotland plc Standard Product', '802162', ''],
    ['Bank of Scotland plc Nws Staff', '802163', ''],
    ['Bank of Scotland plc Joint Venture Companies', '802164', ''],
    ['Bank of Scotland plc Wilsons', '802165', ''],
    ['Bank of Scotland plc Nws Branch Network', '802166', ''],
    ['Bank of Scotland plc Std Cap Rate', '802169', ''],
    ['Bank of Scotland plc Stab Cap Rate', '802170', ''],
    ['Bank of Scotland plc Deferred Interest', '802171', ''],
    ['Bank of Scotland plc Bb Cal B Loans', '802179', 'BOFSGB21136'],
    ['Bank of Scotland plc Brewin Dolphin Ltd (S)', '802202', 'BOFSGB21134'],
    ['Bank of Scotland plc Arthur Anderson', '802204', 'BOFSGB21432'],
    ['Bank of Scotland plc The Direct Business Bank', '802260', 'BOFSGBS1SDP'],
    ['Bank of Scotland plc Edinburgh Saye Centre', '802270', 'BOFSGB21132'],
    [
      'Bank of Scotland plc Bos Affinities Branch Tnfs',
      '802277',
      'BOFSGBS1AFF'
    ],
    ['Bank of Scotland plc B of S Internet Banking', '802278', 'BOFSGBS1INT'],
    ['Bank of Scotland plc Banking Direct Branch N02', '802279', 'BOFSGB21433'],
    ['Bank of Scotland plc Central Deposits Centre', '802280', 'BOFSGBS1CDP'],
    ['Bank of Scotland plc Royal Sun Alliance Isa', '802281', 'BOFSGBS1RSA'],
    ['Bank of Scotland plc Banking Direct Scotland', '802282', 'BOFSGB21434'],
    ['Bank of Scotland plc Personal Banking Branch 1', '802290', 'BOFSGB21137'],
    [
      'Bank of Scotland plc Bos Third Party Set Master',
      '802401',
      'BOFSGB21142'
    ],
    ['Bank of Scotland plc Private Banking Edinburgh', '802442', 'BOFSGBS1NBH'],
    ['Bank of Scotland plc Private Banking Glasgow', '802461', 'BOFSGBS1PSG'],
    ['Bank of Scotland plc Private Banking Aberdeen', '802462', 'BOFSGBS1PSA'],
    ['Bank of Scotland plc Cbs Pilot Branch Scotland', '802463', 'BOFSGB21COR'],
    ['Bank of Scotland plc Grp Recs 802465', '802465', ''],
    ['Bank of Scotland plc Chaps Routing', '802466', 'BOFSGB3EXXX'],
    ['Bank of Scotland plc Hbos Registrar Services', '802468', 'BOFSGBS1HBS'],
    ['Bank of Scotland plc Private Banking Inverness', '802476', 'BOFSGBS1IPI'],
    ['Bank of Scotland plc Pb Direct Leeds (802477)', '802477', 'BOFSGBS1IPP'],
    [
      'Bank of Scotland plc Halfax Credit Card Cheques',
      '802478',
      'BOFSGB41002'
    ],
    ['Bank of Scotland plc Smartfinance', '802479', 'BOFSGBS1SMF'],
    [
      'Bank of Scotland plc Zurich Personal Finance Ltd',
      '802486',
      'BOFSGBS1ZPF'
    ],
    [
      'Bank of Scotland plc Zurich Personal Finance Ltd',
      '802487',
      'BOFSGBS1ZF2'
    ],
    ['Bank of Scotland plc Glasgow, Buchanan Street', '802488', 'BOFSGB21465'],
    ['Bank of Scotland plc Turcan Connell', '802602', 'BOFSGB21436'],
    ['Bank of Scotland plc Miller Hendry', '802603', 'BOFSGB21437'],
    ['Bank of Scotland plc Edinburgh Portfolio Mngrs', '802620', 'BOFSGB21459'],
    ['Bank of Scotland plc Lindsays Ws', '802621', 'BOFSGB21460'],
    ['Bank of Scotland plc Condies', '802622', 'BOFSGB21461'],
    [
      'Bank of Scotland plc Maclay Murray Spens Bk Dir',
      '802623',
      'BOFSGB21462'
    ],
    ['Bank of Scotland plc Innes Johnston', '802624', ''],
    ['Bank of Scotland plc Drummond Miller', '802625', 'BOFSGB21455'],
    ['Bank of Scotland plc Shepherd & Wedderburn LLP', '802626', 'BOFSGB21456'],
    ['Bank of Scotland plc Pagan Osborne Branch', '802627', 'BOFSGB21457'],
    ['Bank of Scotland plc Robson McLean', '802628', 'BOFSGBS1RML'],
    ['Bank of Scotland plc Cica', '802629', 'BOFSGBS1CIC'],
    ['Bank of Scotland plc Scottish Frindly Assetmg', '802630', 'BOFSGBS1SFA'],
    ['Bank of Scotland plc Gillespie Macandrew Ws', '802631', 'BOFSGBS1GMW'],
    ['Bank of Scotland plc Banking Direct Ctp', '802632', 'BOFSGBS1CBU'],
    ['Bank of Scotland plc Macleod & Maccallum', '802633', 'BOFSGBS1MLM'],
    ['Bank of Scotland plc Speirs & Jeffrey Limited', '802634', 'BOFSGBS1SPJ'],
    ['Bank of Scotland plc Cavanagh Financial Mgmt', '802636', 'BOFSGBS1EYG'],
    ['Bank of Scotland plc Grand Central Savings', '802638', 'BOFSGBS1GCS'],
    ['Bank of Scotland plc McClure Naismith LLP', '802640', 'BOFSGBS1MCN'],
    ['Bank of Scotland plc Anderson Strathern LLP', '802641', 'BOFSGB21ASW'],
    ['Bank of Scotland plc Morton Fraser LLP', '802642', 'BOFSGBS1SEW'],
    ['Bank of Scotland plc Infrastructure Finance', '802643', 'BOFSGBS1CBG'],
    ['Bank of Scotland plc Ledingham Chalmers', '802644', 'BOFSGBS1LED'],
    ['Bank of Scotland plc Community Banking Branch', '802645', 'BOFSGB21COM'],
    ['Bank of Scotland plc Stronachs LLP', '802646', 'BOFSGB21464'],
    ['Bank of Scotland plc Montague Evans', '802647', 'BOFSGBS1MEV'],
    ['Bank of Scotland plc Blackadders LLP', '802648', 'BOFSGBS1BLA'],
    ['Bank of Scotland plc Wm.Thomson & Son', '802649', 'BOFSGBS1THO'],
    ['Bank of Scotland plc Lindley Group', '802650', 'BOFSGBS1LIN'],
    ['Bank of Scotland plc Isite Demo', '802652', 'BOFSGBS1BSP'],
    ['Bank of Scotland plc Invocas Group plc', '802653', 'BOFSGBS1VOC'],
    ['Bank of Scotland plc Maclay Murray & Spens 2', '802654', 'BOFSGBS1MMS'],
    ['Bank of Scotland plc Synergy Insurance', '802655', 'BOFSGBS1DAL'],
    ['Bank of Scotland plc Tods Murray LLP', '802656', 'BOFSGBS1TOD'],
    ['Bank of Scotland plc Private Client Banking', '802657', 'BOFSGBS1ALF'],
    ['Bank of Scotland plc Brodies LLP', '802658', ''],
    ['Bank of Scotland plc Ckd Galbraith', '802659', 'BOFSGBS1CKD'],
    ['Bank of Scotland plc C & P Pension Trustee Ltd', '802660', 'BOFSGBS1CPT'],
    ['Bank of Scotland plc Westerby Trustee Services', '802661', ''],
    [
      'Bank of Scotland plc Computershare Plan Managers',
      '802662',
      'BOFSGBS1EES'
    ],
    ['Bank of Scotland plc Suffolk Life Trustees', '802663', 'BOFSGBS1TPT'],
    ['Bank of Scotland plc Torquil Clark', '802664', 'BOFSGBS1TOC'],
    ['Bank of Scotland plc Semple Fraser', '802665', 'BOFSGBS1SFR'],
    ['Bank of Scotland plc Baker Tilly', '802666', 'BOFSGBS1TRC'],
    ['Bank of Scotland plc Bos Argyle Street', '802670', 'BOFSGBS1ARG'],
    ['Bank of Scotland plc Carrington Dean Group', '802680', 'BOFSGBS1CDG'],
    ['Bank of Scotland plc Yorsipp', '802681', 'BOFSGBS1YSP'],
    ['Bank of Scotland plc Lambert Smith Hampton', '802682', 'BOFSGBS1LSH'],
    [
      'Bank of Scotland plc Menzies Corporate Recovery',
      '802683',
      'BOFSGBS1MCR'
    ],
    ['Bank of Scotland plc Dickson Minto', '802684', 'BOFSGBS1DMT'],
    ['Bank of Scotland plc Pannone LLP', '802685', 'BOFSGBS1PLL'],
    ['Bank of Scotland plc Moretons', '802686', 'BOFSGBS1MRT'],
    ['Bank of Scotland plc Shield & Kyd', '802687', 'BOFSGBS1SAK'],
    ['Bank of Scotland plc Sippchoice Ltd', '802688', 'BOFSGBS1WAB'],
    ['Bank of Scotland plc Slp Cofunds Pension', '802689', 'BOFSGBS1BMK'],
    ['Bank of Scotland plc P.A.C.', '802690', 'BOFSGBS1PAC'],
    ['Bank of Scotland plc Trust Property Mgmt Ltd', '802691', 'BOFSGBS1TPM'],
    ['Bank of Scotland plc Barnett Waddingham', '802692', 'BOFSGBS1BWD'],
    ['Bank of Scotland plc The Best Sipp Branch', '802693', 'BOFSGBS1CGG'],
    ['Bank of Scotland plc The Lints Partnership', '802694', 'BOFSGBS1TLP'],
    ['Bank of Scotland plc Howard De Walden Estates', '802695', 'BOFSGBS1HDW'],
    ['Bank of Scotland plc Healthfund Ltd', '802696', 'BOFSGBS1HFL'],
    ['Bank of Scotland plc Pkf (UK) LLP', '802697', 'BOFSGBS1PKF'],
    ['Bank of Scotland plc Rsb MacDonald', '802698', 'BOFSGBS1RBD'],
    ['Bank of Scotland plc Rendall & Rittner Ltd', '802699', 'BOFSGBS1RRL'],
    ['Bank of Scotland plc Mound Trustees Ltd 1', '802702', 'BOFSGBS1MT1'],
    ['Bank of Scotland plc Mound Trustees Ltd 3', '802703', 'BOFSGBS1MT3'],
    ['Bank of Scotland plc Mound Trustees Ltd 2', '802706', 'BOFSGBS1MT2'],
    ['Bank of Scotland plc Mound Trustees Ltd 4', '802707', 'BOFSGBS1MT4'],
    ['Bank of Scotland plc Corp Banking Mound Edin', '802900', 'BOFSGBS1CBK'],
    [
      'Bank of Scotland plc Corporate Banking Aberdeen',
      '802901',
      'BOFSGBS1CB1'
    ],
    ['Bank of Scotland plc Agriculture Branch', '802903', 'BOFSGBS1AGB'],
    ['Bank of Scotland plc Comdisco Osu', '802904', 'BOFSGBS1NFU'],
    ['Bank of Scotland plc Nfu Mutual Finance Ltd', '802905', 'BOFSGBS1MFL'],
    ['Bank of Scotland plc Corp Bk St Andrew Square', '802907', 'BOFSGBS1CB2'],
    ['Bank of Scotland plc Corporate Bk Glasgow City', '802910', 'BOFSGBS1CB3'],
    [
      'Bank of Scotland plc Pointon York Sipp Solutions',
      '802921',
      'BOFSGBS1PYS'
    ],
    [
      'Bank of Scotland plc Computershare I S plc - Ees',
      '802922',
      'BOFSGBS1CIS'
    ],
    [
      'Bank of Scotland plc Raeburn Christie Clark Wall',
      '802923',
      'BOFSGBS1RCW'
    ],
    [
      'Bank of Scotland plc Archibald Campbell & Harley',
      '802924',
      'BOFSGBS1ACH'
    ],
    ['Bank of Scotland plc Client Banking UK (12)', '802925', 'BOFSGBS1C81'],
    ['Bank of Scotland plc City Trustees', '802926', 'BOFSGBS1226'],
    ['Bank of Scotland plc Berkeley Burke', '802927', 'BOFSGBS1227'],
    ['Bank of Scotland plc Chantrey Vellacott Dfk', '802928', 'BOFSGBS1228'],
    ['Bank of Scotland plc Client Banking UK (80)', '802930', 'BOFSGBS1C80'],
    [
      'Bank of Scotland plc England & Wales Solicitors',
      '802931',
      'BOFSGBS1C82'
    ],
    ['Bank of Scotland plc Edinburgh George St', '803120', 'BOFSGB21141'],
    ['Bank of Scotland plc Colleague Banking', '803150', 'BOFSGB21140'],
    ['Bank of Scotland plc Onboarding Test 803558', '803558', 'BOFSGBS1LT9'],
    ['Bank of Scotland plc Rtl Lab-Tr1 Testing803638', '803638', ''],
    ['Bank of Scotland plc Pendeford', '803658', ''],
    ['Bank of Scotland plc Credit Operations', '803661', ''],
    ['Bank of Scotland plc Glasgow Ph Epayments', '803662', ''],
    ['Bank of Scotland plc Epayments', '803663', ''],
    ['Bank of Scotland plc Epayments', '803664', ''],
    ['Bank of Scotland plc Epayments', '803665', ''],
    ['Bank of Scotland plc Birmingham Epay', '803736', ''],
    ['Bank of Scotland plc Birmingham Epay', '803737', ''],
    ['Bank of Scotland plc Birmingham Epay', '803738', ''],
    ['Bank of Scotland plc Birmingham Epay', '803739', ''],
    ['Bank of Scotland plc Birmingham Epay', '803740', ''],
    ['Bank of Scotland plc Birmingham BP Epay', '803741', ''],
    ['Bank of Scotland plc Estuary House 1', '803746', ''],
    ['Bank of Scotland plc Customer Financial Assistan', '803747', ''],
    ['Bank of Scotland plc Edinburgh George St', '804121', 'BOFSGB21139'],
    ['Bank of Scotland plc Test Branch (Wmh 804255)', '804255', 'BOFSGBS1400'],
    [
      'Bank of Scotland plc Test Brnch L&U Bristol Ag16',
      '804256',
      'BOFSGBS1401'
    ],
    ['Bank of Scotland plc Inverurie Hub (804261)', '804261', 'BOFSGBS1406'],
    ['Bank of Scotland plc Tain Hub (804262)', '804262', 'BOFSGBS1407'],
    [
      'Bank of Scotland plc Invernesslongmanhub(804263)',
      '804263',
      'BOFSGBS1408'
    ],
    [
      'Bank of Scotland plc Castle Douglas Hub (804244)',
      '804264',
      'BOFSGBS1409'
    ],
    ['Bank of Scotland plc Kelso Hub (804265)', '804265', 'BOFSGBS1410'],
    ['Bank of Scotland plc Mob Brch804266 Bos Contin', '804266', 'BOFSGBS1411'],
    [
      'Bank of Scotland plc Bos Contingency 2 (804267)',
      '804267',
      'BOFSGBS1427'
    ],
    ['Bank of Scotland plc Bank of Scotland plc', '804268', 'BOFSGBS1428'],
    ['Bank of Scotland plc Bank of Scotland plc', '804269', 'BOFSGBS1429'],
    ['Bank of Scotland plc Biab Test (804276)', '804276', ''],
    ['Bank of Scotland plc Perth Hub (804277)', '804277', 'BOFSGBS1404'],
    ['Bank of Scotland plc Elgin Hub (804278)', '804278', 'BOFSGBS1405'],
    ['Bank of Scotland plc Mobile Branch (804279)', '804279', 'BOFSGBS1412'],
    ['Bank of Scotland plc Mobile Branch (804281)', '804281', 'BOFSGBS1413'],
    ['Bank of Scotland plc Mobile Branch (804282)', '804282', 'BOFSGBS1414'],
    ['Bank of Scotland plc Mobile Branch (804283)', '804283', 'BOFSGBS1415'],
    ['Bank of Scotland plc Mobile Branch (804284)', '804284', 'BOFSGBS1416'],
    ['Bank of Scotland plc Mobile Branch (804285)', '804285', 'BOFSGBS1417'],
    ['Bank of Scotland plc Mobile Branch (804286)', '804286', 'BOFSGBS1418'],
    ['Bank of Scotland plc Stirling Hub (804287)', '804287', 'BOFSGBS1419'],
    ['Bank of Scotland plc Mobile Branch (804288)', '804288', 'BOFSGBS1420'],
    ['Bank of Scotland plc Mobile Branch (804289)', '804289', 'BOFSGBS1421'],
    ['Bank of Scotland plc Mobile Branch (804291)', '804291', 'BOFSGBS1422'],
    ['Bank of Scotland plc Mobile Branch (804299)', '804299', 'BOFSGBS1423'],
    ['Bank of Scotland plc Mobile Branch (804305)', '804305', 'BOFSGBS1424'],
    ['Bank of Scotland plc Mobile Branch (804333)', '804333', 'BOFSGBS1425'],
    ['Bank of Scotland plc Mobile Branch (804335)', '804335', 'BOFSGBS1426'],
    [
      'Bank of Scotland plc Aberdeen Bridge of Don (R)',
      '804502',
      'BOFSGBS1027'
    ],
    ['Bank of Scotland plc Aberdeen Upperkirkgat', '804504', 'BOFSGBS1028'],
    [
      "Bank of Scotland plc Aberdeen Queen's Cross (R)",
      '804505',
      'BOFSGBS1029'
    ],
    [
      "Bank of Scotland plc Aberdeen Queen's Cross (R)",
      '804506',
      'BOFSGBS1030'
    ],
    ['Bank of Scotland plc Aberdeen 201 Union St', '804508', 'BOFSGBS1031'],
    ['Bank of Scotland plc Aberdeen Upperkirkgate', '804509', 'BOFSGBS1032'],
    ['Bank of Scotland plc Aberdeen 201 Union St', '804511', 'BOFSGBS1341'],
    ['Bank of Scotland plc Aberdeen, Upperkirkgate', '804512', 'BOFSGBS1033'],
    ['Bank of Scotland plc Aberdeen West End (R)', '804513', 'BOFSGBS1034'],
    ['Bank of Scotland plc Pitlochry (R)', '804514', 'BOFSGBS1340'],
    ['Bank of Scotland plc Aberfoyle', '804515', 'BOFSGBS1339'],
    ['Bank of Scotland plc Elgin High St (804516)', '804516', 'BOFSGBS1035'],
    ['Bank of Scotland plc Airdrie', '804517', 'BOFSGBS1338'],
    ['Bank of Scotland plc Alexandria (R)', '804518', 'BOFSGBS1036'],
    ['Bank of Scotland plc Alloa (R)', '804519', 'BOFSGBS1037'],
    ['Bank of Scotland plc Alness (R)', '804521', 'BOFSGBS1038'],
    ['Bank of Scotland plc Alloa Mar St (804522)', '804522', 'BOFSGBS1337'],
    ['Bank of Scotland plc Annan', '804523', 'BOFSGBS1336'],
    ['Bank of Scotland plc Arbroath (R)', '804524', 'BOFSGBS1039'],
    ['Bank of Scotland plc Perth (804525)', '804525', 'BOFSGBS1040'],
    ['Bank of Scotland plc Aviemore (804527)', '804527', 'BOFSGBS1041'],
    ['Bank of Scotland plc Ayr High Street (R)', '804528', 'BOFSGBS1042'],
    ['Bank of Scotland plc Milngavie Station 804531', '804531', 'BOFSGBS1043'],
    ['Bank of Scotland plc Ballater (R)', '804532', 'BOFSGBS1044'],
    ['Bank of Scotland plc Banchory (804533)', '804533', 'BOFSGBS1335'],
    ['Bank of Scotland plc Banff', '804534', 'BOFSGBS1334'],
    ['Bank of Scotland plc Barrhead (R)', '804535', 'BOFSGBS1045'],
    ['Bank of Scotland plc Bathgate (R)', '804536', 'BOFSGBS1046'],
    ['Bank of Scotland plc Bearsden (R)', '804537', 'BOFSGBS1047'],
    ['Bank of Scotland plc Inverness City (804538)', '804538', 'BOFSGBS1048'],
    ['Bank of Scotland plc Johnstone (804539)', '804539', 'BOFSGBS1049'],
    ['Bank of Scotland plc Bellshill (R)', '804540', 'BOFSGBS1050'],
    ['Bank of Scotland plc Benbecula (R)', '804541', 'BOFSGBS1051'],
    ['Bank of Scotland plc Bishopbriggs (R)', '804544', 'BOFSGBS1052'],
    ['Bank of Scotland plc Erskine (804545)', '804545', 'BOFSGBS1053'],
    ['Bank of Scotland plc Blairgowrie (R)', '804547', 'BOFSGBS1054'],
    ['Bank of Scotland plc Linlithgow (804548)', '804548', 'BOFSGBS1333'],
    ['Bank of Scotland plc Tain (804549)', '804549', 'BOFSGBS1332'],
    ['Bank of Scotland plc Bonnyrigg', '804551', 'BOFSGBS1331'],
    ['Bank of Scotland plc Uddingston (804552)', '804552', 'BOFSGBS1330'],
    ['Bank of Scotland plc Bowmore (R)', '804553', 'BOFSGBS1055'],
    ['Bank of Scotland plc Braemar', '804554', 'BOFSGBS1329'],
    ['Bank of Scotland plc Brechin (R)', '804555', 'BOFSGBS1056'],
    ['Bank of Scotland plc Stirling Port St', '804556', 'BOFSGBS1057'],
    ['Bank of Scotland plc Bridge of Weir (R)', '804557', 'BOFSGBS1058'],
    ['Bank of Scotland plc Kyle (804560)', '804560', 'BOFSGBS1328'],
    ['Bank of Scotland plc Brodick (R)', '804561', 'BOFSGBS1059'],
    ['Bank of Scotland plc Broughty Ferry', '804562', 'BOFSGBS1327'],
    ['Bank of Scotland plc Broxburn (R)', '804563', 'BOFSGBS1060'],
    ['Bank of Scotland plc Buckie', '804564', 'BOFSGBS1326'],
    ['Bank of Scotland plc Elgin High St (804565)', '804565', 'BOFSGBS1325'],
    ['Bank of Scotland plc Rutherglen (804566)', '804566', 'BOFSGBS1061'],
    ['Bank of Scotland plc Callander (R)', '804567', 'BOFSGBS1062'],
    ['Bank of Scotland plc Campbeltown (R)', '804568', 'BOFSGBS1063'],
    ['Bank of Scotland plc Carluke (R)', '804571', 'BOFSGBS1064'],
    ['Bank of Scotland plc Arbroath (804572)', '804572', 'BOFSGBS1324'],
    ['Bank of Scotland plc Castle Douglas (804573)', '804573', 'BOFSGBS1065'],
    ['Bank of Scotland plc Clarkston (804576)', '804576', 'BOFSGBS1066'],
    ['Bank of Scotland plc Clydebank (R)', '804577', 'BOFSGBS1067'],
    ['Bank of Scotland plc Coatbridge (R)', '804578', 'BOFSGBS1068'],
    ['Bank of Scotland plc Kelso (804579)', '804579', 'BOFSGBS1069'],
    ['Bank of Scotland plc Blairgowrie (804581)', '804581', 'BOFSGBS1323'],
    ['Bank of Scotland plc Cowdenbeath', '804583', 'BOFSGBS1322'],
    ['Bank of Scotland plc Crieff (R)', '804584', 'BOFSGBS1070'],
    ['Bank of Scotland plc Buckie (804585)', '804585', 'BOFSGBS1321'],
    ['Bank of Scotland plc Cults (R)', '804586', 'BOFSGBS1071'],
    ['Bank of Scotland plc Cumbernauld (R)', '804587', 'BOFSGBS1072'],
    ['Bank of Scotland plc Cumnock (R)', '804588', 'BOFSGBS1073'],
    ['Bank of Scotland plc Cupar (R)', '804589', 'BOFSGBS1074'],
    ['Bank of Scotland plc Edinburgh Gyle Centre', '804591', 'BOFSGBS1320'],
    ['Bank of Scotland plc Castle Douglas (804592)', '804592', 'BOFSGBS1319'],
    ['Bank of Scotland plc Dalkeith High St', '804594', 'BOFSGBS1075'],
    ['Bank of Scotland plc Clydebank (804595)', '804595', 'BOFSGBS1318'],
    ['Bank of Scotland plc Castle Douglas (804596)', '804596', 'BOFSGBS1317'],
    ['Bank of Scotland plc Falkirk High St 804597', '804597', 'BOFSGBS1076'],
    ['Bank of Scotland plc Dingwall (R)', '804598', 'BOFSGBS1077'],
    ['Bank of Scotland plc Tain (804599)', '804599', 'BOFSGBS1078'],
    ['Bank of Scotland plc Doune', '804601', 'BOFSGBS1316'],
    ['Bank of Scotland plc Inverness City (804602)', '804602', 'BOFSGBS1315'],
    ['Bank of Scotland plc Dumbarton (R)', '804603', 'BOFSGBS1079'],
    ['Bank of Scotland plc Dumfries (R)', '804604', 'BOFSGBS1080'],
    ['Bank of Scotland plc Dunbar (R)', '804605', 'BOFSGBS1081'],
    ['Bank of Scotland plc Dunblane (R)', '804606', 'BOFSGBS1082'],
    ['Bank of Scotland plc Dundee Fairmuir (804607)', '804607', 'BOFSGBS1083'],
    ['Bank of Scotland plc Dundee Fairmuir', '804608', 'BOFSGBS1314'],
    ['Bank of Scotland plc Dundee Murraygate', '804609', 'BOFSGBS1313'],
    [
      'Bank of Scotland plc Dunfermline Bothwell Street',
      '804613',
      'BOFSGBS1312'
    ],
    ['Bank of Scotland plc Dunfermline East Port (R)', '804614', 'BOFSGBS1084'],
    ['Bank of Scotland plc Dunkeld (R)', '804615', 'BOFSGBS1085'],
    ['Bank of Scotland plc Dunoon (R)', '804616', 'BOFSGBS1086'],
    ['Bank of Scotland plc Duns (R)', '804617', 'BOFSGBS1087'],
    ['Bank of Scotland plc Dyce (R)', '804618', 'BOFSGBS1088'],
    ['Bank of Scotland plc East Kilbridge (R)', '804619', 'BOFSGBS1089'],
    ['Bank of Scotland plc Edinburgh Barnton', '804622', 'BOFSGBS1311'],
    ['Bank of Scotland plc Edinburgh Comely Bank', '804623', 'BOFSGBS1310'],
    ['Bank of Scotland plc Edinburgh Royal Mile', '804624', 'BOFSGBS1090'],
    ['Bank of Scotland plc Morningside (804625)', '804625', 'BOFSGBS1091'],
    ['Bank of Scotland plc Edinburgh Royal Mile', '804626', 'BOFSGBS1092'],
    ['Bank of Scotland plc Edinburgh Comely Bank', '804627', 'BOFSGBS1093'],
    [
      'Bank of Scotland plc Edinburgh Corstophine West',
      '804628',
      'BOFSGBS1309'
    ],
    [
      'Bank of Scotland plc Edinburgh Corstophine West',
      '804629',
      'BOFSGBS1094'
    ],
    ['Bank of Scotland plc Edinburgh Shandwick804631', '804631', 'BOFSGBS1308'],
    ['Bank of Scotland plc Edinburgh Morningside', '804632', 'BOFSGBS1307'],
    ['Bank of Scotland plc Edinburgh Gyle Centre', '804633', 'BOFSGBS1306'],
    ['Bank of Scotland plc Edinburgh George St', '804634', 'BOFSGBS1305'],
    ['Bank of Scotland plc Edinburgh George St', '804635', 'BOFSGBS1095'],
    ['Bank of Scotland plc Edinburgh Comely Bank', '804636', 'BOFSGBS1304'],
    ["Bank of Scotland plc E'burgh George St 804637", '804637', 'BOFSGBS1303'],
    ['Bank of Scotland plc Edinburgh Royal Mile', '804638', 'BOFSGBS1096'],
    [
      'Bank of Scotland plc Edinburgh Leith (North) (R)',
      '804642',
      'BOFSGBS1097'
    ],
    ['Bank of Scotland plc Edinburgh Leith Vic House', '804643', 'BOFSGBS1098'],
    ['Bank of Scotland plc Cameron Toll 804644', '804644', 'BOFSGBS1099'],
    ['Bank of Scotland plc Edinburgh Cameron Toll', '804645', 'BOFSGBS1302'],
    ['Bank of Scotland plc Edinburgh Morningside', '804647', 'BOFSGBS1100'],
    ['Bank of Scotland plc Edinburgh Shandwick', '804648', 'BOFSGBS1301'],
    ['Bank of Scotland plc Edinburgh Newington', '804649', 'BOFSGBS1300'],
    ['Bank of Scotland plc Edinburgh Morningside', '804651', 'BOFSGBS1299'],
    [
      'Bank of Scotland plc Edinburgh Portobello 804652',
      '804652',
      'BOFSGBS1298'
    ],
    ['Bank of Scotland plc Edinburgh Portobello', '804653', 'BOFSGBS1297'],
    ['Bank of Scotland plc Edinburgh Rose Street (R)', '804654', 'BOFSGBS1101'],
    ['Bank of Scotland plc Edinburgh Shandwick', '804655', 'BOFSGBS1102'],
    ['Bank of Scotland plc Edinburgh Wester Hailes', '804656', 'BOFSGBS1296'],
    ['Bank of Scotland plc Edinburgh George St)', '804657', 'BOFSGBS1103'],
    [
      'Bank of Scotland plc Edinburgh Staff Banking (R)',
      '804658',
      'BOFSGBS1104'
    ],
    ['Bank of Scotland plc Edinburgh Comely Bank', '804659', 'BOFSGBS1295'],
    ['Bank of Scotland plc Morningside 804661', '804661', 'BOFSGBS1105'],
    [
      'Bank of Scotland plc Edinburgh Newington 804662',
      '804662',
      'BOFSGBS1294'
    ],
    ['Bank of Scotland plc Brechin (804663)', '804663', 'BOFSGBS1293'],
    ['Bank of Scotland plc Elgin High St (804664)', '804664', 'BOFSGBS1292'],
    ['Bank of Scotland plc Ellon (R)', '804665', 'BOFSGBS1106'],
    ['Bank of Scotland plc Erskine', '804667', 'BOFSGBS1291'],
    ['Bank of Scotland plc Eyemouth (R)', '804669', 'BOFSGBS1107'],
    ['Bank of Scotland plc Falkirk High Street', '804671', 'BOFSGBS1108'],
    ['Bank of Scotland plc Falkirk High Street', '804672', 'BOFSGBS1290'],
    ['Bank of Scotland plc Falkland', '804673', 'BOFSGBS1289'],
    ['Bank of Scotland plc Elgin High St (804674)', '804674', 'BOFSGBS1109'],
    ['Bank of Scotland plc Forfar (R)', '804676', 'BOFSGBS1110'],
    ['Bank of Scotland plc Forres', '804677', 'BOFSGBS1288'],
    ['Bank of Scotland plc Fort Augustus', '804678', 'BOFSGBS1287'],
    ['Bank of Scotland plc Fort William (R)', '804679', 'BOFSGBS1111'],
    ['Bank of Scotland plc Inverness City (804681)', '804681', 'BOFSGBS1112'],
    ['Bank of Scotland plc Fraserburgh (R)', '804682', 'BOFSGBS1113'],
    ['Bank of Scotland plc Gairloch', '804683', 'BOFSGBS1114'],
    ['Bank of Scotland plc Galashiels (804684)', '804684', 'BOFSGBS1115'],
    ['Bank of Scotland plc Kilmarnock (804686)', '804686', 'BOFSGBS1286'],
    ['Bank of Scotland plc Garrowhill (R)', '804687', 'BOFSGBS1116'],
    ['Bank of Scotland plc Castle Douglas (804688)', '804688', 'BOFSGBS1285'],
    ['Bank of Scotland plc Clarkston', '804689', 'BOFSGBS1117'],
    ['Bank of Scotland plc Girvan (R)', '804690', 'BOFSGBS1118'],
    ['Bank of Scotland plc Glasgow Anniesland (R)', '804691', 'BOFSGBS1119'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (804692)',
      '804692',
      'BOFSGBS1120'
    ],
    ['Bank of Scotland plc Glasgow Partick (804694)', '804694', 'BOFSGBS1284'],
    [
      'Bank of Scotland plc Glasgow Byres Road (804695)',
      '804695',
      'BOFSGBS1121'
    ],
    ['Bank of Scotland plc Glasgow Cardonald (R)', '804696', 'BOFSGBS1122'],
    ['Bank of Scotland plc Glasgow Argyle St', '804697', 'BOFSGBS1283'],
    ['Bank of Scotland plc Glasgow Argyle St 804699', '804699', 'BOFSGBS1123'],
    ['Bank of Scotland plc Glasgow Govan (R)', '804700', 'BOFSGBS1124'],
    [
      'Bank of Scotland plc Glasgow Byres Road (804701)',
      '804701',
      'BOFSGBS1125'
    ],
    ['Bank of Scotland plc Glasgow Hyndland (R)', '804702', 'BOFSGBS1126'],
    ['Bank of Scotland plc Glasgow Govan', '804703', 'BOFSGBS1127'],
    ['Bank of Scotland plc Glasgow Argyle St 804701', '804704', 'BOFSGBS1128'],
    ['Bank of Scotland plc Glasgow Victoria Road (R)', '804705', 'BOFSGBS1129'],
    ['Bank of Scotland plc Clarkston (804706)', '804706', 'BOFSGBS1130'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (804708)',
      '804708',
      'BOFSGBS1131'
    ],
    ['Bank of Scotland plc Glasgow Partick (804709)', '804709', 'BOFSGBS1132'],
    ['Bank of Scotland plc Glasgow Pollokshields (R)', '804711', 'BOFSGBS1133'],
    ['Bank of Scotland plc Glasgow Argyle St 804712', '804712', 'BOFSGBS1134'],
    ['Bank of Scotland plc Glasgow Riddrie (R)', '804713', 'BOFSGBS1135'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (804714)',
      '804714',
      'BOFSGBS1136'
    ],
    [
      'Bank of Scotland plc Glasgow Anniesland (804715)',
      '804715',
      'BOFSGBS1137'
    ],
    ['Bank of Scotland plc Glasgow Shawlands', '804716', 'BOFSGBS1282'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (804718)',
      '804718',
      'BOFSGBS1138'
    ],
    ['Bank of Scotland plc Glasgow The Forge(804719)', '804719', 'BOFSGBS1139'],
    ['Bank of Scotland plc Glasgow Victoria Road (R)', '804721', 'BOFSGBS1140'],
    ['Bank of Scotland plc Glenrothes (R)', '804722', 'BOFSGBS1141'],
    ['Bank of Scotland plc Golspie (R)', '804723', 'BOFSGBS1142'],
    ['Bank of Scotland plc Dalkeith High St', '804724', 'BOFSGBS1281'],
    ['Bank of Scotland plc Greenock', '804725', 'BOFSGBS1143'],
    ['Bank of Scotland plc Grangemouth (R)', '804726', 'BOFSGBS1144'],
    ['Bank of Scotland plc Aviemore (804727)', '804727', 'BOFSGBS1145'],
    ['Bank of Scotland plc Greenock (R)', '804728', 'BOFSGBS1146'],
    ['Bank of Scotland plc North Berwick (804732)', '804732', 'BOFSGBS1147'],
    ['Bank of Scotland plc Haddington (R)', '804733', 'BOFSGBS1148'],
    ['Bank of Scotland plc Hamilton (804734)', '804734', ''],
    ['Bank of Scotland plc Hamilton Newcross (R)', '804735', 'BOFSGBS1149'],
    ['Bank of Scotland plc Hawick (R)', '804736', 'BOFSGBS1150'],
    ['Bank of Scotland plc Helensburgh (R)', '804737', 'BOFSGBS1151'],
    ['Bank of Scotland plc Golspie (804738)', '804738', 'BOFSGBS1280'],
    ['Bank of Scotland plc Inverurie (804741)', '804741', 'BOFSGBS1152'],
    ['Bank of Scotland plc Innerleithen', '804742', 'BOFSGBS1279'],
    ['Bank of Scotland plc Lochgilphead (804743)', '804743', 'BOFSGBS1278'],
    ['Bank of Scotland plc Inverness City (804745)', '804745', 'BOFSGBS1153'],
    ['Bank of Scotland plc Inverness Longman', '804747', 'BOFSGBS1277'],
    ['Bank of Scotland plc Inverurie (804748)', '804748', 'BOFSGBS1154'],
    ['Bank of Scotland plc Irvine (R)', '804750', 'BOFSGBS1155'],
    ['Bank of Scotland plc Jedburgh (R)', '804752', 'BOFSGBS1156'],
    ['Bank of Scotland plc Johnstone (804755)', '804755', 'BOFSGBS1157'],
    ['Bank of Scotland plc Buckie (804756)', '804756', 'BOFSGBS1276'],
    ['Bank of Scotland plc Kelso', '804757', 'BOFSGBS1275'],
    ['Bank of Scotland plc Kilcreggan', '804758', 'BOFSGBS1274'],
    ['Bank of Scotland plc Milngavie Station 804759', '804759', 'BOFSGBS1273'],
    ['Bank of Scotland plc Callandar (804760)', '804760', 'BOFSGBS1272'],
    ['Bank of Scotland plc Kilmarnock (R)', '804761', 'BOFSGBS1158'],
    [
      'Bank of Scotland plc Kirkcaldy High St (804764)',
      '804764',
      'BOFSGBS1271'
    ],
    ['Bank of Scotland plc Aviemore (804765)', '804765', 'BOFSGBS1270'],
    ['Bank of Scotland plc Perth (804766)', '804766', 'BOFSGBS1159'],
    ['Bank of Scotland plc Kirkcaldy High St', '804767', 'BOFSGBS1269'],
    ['Bank of Scotland plc Kirkcaldy Mitchelston (R)', '804768', 'BOFSGBS1160'],
    ['Bank of Scotland plc Kirkcudbright', '804770', 'BOFSGBS1268'],
    ['Bank of Scotland plc Kirkintilloch (R)', '804771', 'BOFSGBS1161'],
    ['Bank of Scotland plc Kirkwall (R)', '804772', 'BOFSGBS1162'],
    ['Bank of Scotland plc Forfar (804773)', '804773', 'BOFSGBS1267'],
    ['Bank of Scotland plc Kyle (R)', '804774', 'BOFSGBS1163'],
    ['Bank of Scotland plc Golspie (804775)', '804775', 'BOFSGBS1266'],
    ['Bank of Scotland plc Lanark (R)', '804776', 'BOFSGBS1164'],
    ['Bank of Scotland plc Langholm (R)', '804777', 'BOFSGBS1165'],
    ['Bank of Scotland plc Largs (R)', '804778', 'BOFSGBS1166'],
    ['Bank of Scotland plc Larkhall (R)', '804779', 'BOFSGBS1167'],
    ['Bank of Scotland plc Galashiels (804780)', '804780', 'BOFSGBS1265'],
    ['Bank of Scotland plc Lerwick (R)', '804783', 'BOFSGBS1168'],
    ['Bank of Scotland plc Lesmahagow', '804784', 'BOFSGBS1264'],
    ['Bank of Scotland plc Leven (R)', '804785', 'BOFSGBS1169'],
    ['Bank of Scotland plc Linlithgow (R)', '804787', 'BOFSGBS1170'],
    ['Bank of Scotland plc Livingston (804789)', '804789', 'BOFSGBS1171'],
    ['Bank of Scotland plc Dalkeith High St (804790)', '804790', 'BOFSGBS1172'],
    ['Bank of Scotland plc Kyle (804791)', '804791', 'BOFSGBS1263'],
    ['Bank of Scotland plc Cowdenbeath (804792)', '804792', 'BOFSGBS1173'],
    ['Bank of Scotland plc Lochgilphead (R)', '804793', 'BOFSGBS1174'],
    ['Bank of Scotland plc Lochmaddy', '804794', 'BOFSGBS1262'],
    ['Bank of Scotland plc Lockerbie (R)', '804796', 'BOFSGBS1175'],
    ['Bank of Scotland plc Elgin High St (804799)', '804799', 'BOFSGBS1261'],
    ['Bank of Scotland plc Banff (804801)', '804801', 'BOFSGBS1176'],
    ['Bank of Scotland plc Mallaig (R)', '804802', 'BOFSGBS1177'],
    ['Bank of Scotland plc Ayr High Street', '804804', 'BOFSGBS1260'],
    ['Bank of Scotland plc Galashiels (804805)', '804805', 'BOFSGBS1259'],
    ['Bank of Scotland plc Millport', '804806', 'BOFSGBS1258'],
    ['Bank of Scotland plc Milngavie Station Road', '804807', 'BOFSGBS1257'],
    ['Bank of Scotland plc Moffat', '804808', 'BOFSGBS1256'],
    ['Bank of Scotland plc Montrose (R)', '804809', 'BOFSGBS1178'],
    ['Bank of Scotland plc Motherwell (R)', '804810', 'BOFSGBS1179'],
    ['Bank of Scotland plc Musselburgh (R)', '804811', 'BOFSGBS1180'],
    ['Bank of Scotland plc Nairn (R)', '804812', 'BOFSGBS1181'],
    ['Bank of Scotland plc Fraserburgh', '804813', 'BOFSGBS1255'],
    ['Bank of Scotland plc Newcastleton', '804815', 'BOFSGBS1254'],
    ['Bank of Scotland plc Newton Mearns (R)', '804816', 'BOFSGBS1182'],
    ['Bank of Scotland plc Newton Stewart (804817)', '804817', 'BOFSGBS1253'],
    ['Bank of Scotland plc Galashiels (804818)', '804818', 'BOFSGBS1183'],
    ['Bank of Scotland plc North Berwick (804819)', '804819', 'BOFSGBS1184'],
    ['Bank of Scotland plc Oban (R)', '804822', 'BOFSGBS1185'],
    ['Bank of Scotland plc Paisley The Cross', '804824', 'BOFSGBS1252'],
    [
      'Bank of Scotland plc Paisley The Cross (804826)',
      '804826',
      'BOFSGBS1186'
    ],
    ['Bank of Scotland plc Paisley The Cross', '804827', 'BOFSGBS1251'],
    ['Bank of Scotland plc Peebles', '804828', 'BOFSGBS1250'],
    ['Bank of Scotland plc Penicuik (804829)', '804829', 'BOFSGBS1249'],
    ['Bank of Scotland plc Perth St John Street (R)', '804831', 'BOFSGBS1187'],
    ['Bank of Scotland plc Perth (804832)', '804832', 'BOFSGBS1248'],
    ['Bank of Scotland plc Peterhead (R)', '804833', 'BOFSGBS1188'],
    ['Bank of Scotland plc Pitlochry (R)', '804834', 'BOFSGBS1189'],
    ['Bank of Scotland plc Falkirk High Street', '804835', 'BOFSGBS1190'],
    ['Bank of Scotland plc Port Glasgow (R)', '804836', 'BOFSGBS1191'],
    ['Bank of Scotland plc Newton Stewart (804837)', '804837', 'BOFSGBS1247'],
    ['Bank of Scotland plc Portree (R)', '804838', 'BOFSGBS1192'],
    ['Bank of Scotland plc Buckie (804839)', '804839', 'BOFSGBS1246'],
    ['Bank of Scotland plc Ayr High Street (804840)', '804840', 'BOFSGBS1245'],
    ['Bank of Scotland plc Renfrew (R)', '804842', 'BOFSGBS1193'],
    ['Bank of Scotland plc Rothesay', '804843', 'BOFSGBS1244'],
    ['Bank of Scotland plc Rutherglen (R)', '804844', 'BOFSGBS1194'],
    ['Bank of Scotland plc Saltcoats (R)', '804845', 'BOFSGBS1195'],
    ['Bank of Scotland plc Sanquhar (R)', '804847', 'BOFSGBS1196'],
    ['Bank of Scotland plc Perth (804848)', '804848', 'BOFSGBS1243'],
    ['Bank of Scotland plc Selkirk (R)', '804849', 'BOFSGBS1197'],
    ['Bank of Scotland plc Shotts (R)', '804851', 'BOFSGBS1198'],
    ['Bank of Scotland plc St Andrews (R)', '804853', 'BOFSGBS1199'],
    ['Bank of Scotland plc Kilmarnock (804855)', '804855', 'BOFSGBS1242'],
    ['Bank of Scotland plc Stirling Craigs House (R)', '804856', 'BOFSGBS1200'],
    ['Bank of Scotland plc Stirling Port St 804858', '804858', 'BOFSGBS1241'],
    ['Bank of Scotland plc Stirling University', '804859', 'BOFSGBS1240'],
    ['Bank of Scotland plc Stonehaven (R)', '804860', 'BOFSGBS1201'],
    ['Bank of Scotland plc East Kilbride (804861)', '804861', 'BOFSGBS1202'],
    ['Bank of Scotland plc Stornoway', '804862', 'BOFSGBS1203'],
    ['Bank of Scotland plc Stranraer (R)', '804863', 'BOFSGBS1204'],
    ['Bank of Scotland plc Strathaven', '804864', 'BOFSGBS1239'],
    ['Bank of Scotland plc Stromness', '804865', 'BOFSGBS1238'],
    ['Bank of Scotland plc Tain (804866)', '804866', 'BOFSGBS1237'],
    ['Bank of Scotland plc Tarbert (Argyle)', '804867', 'BOFSGBS1236'],
    ['Bank of Scotland plc Tarbert (Isle of  Harris)', '804868', 'BOFSGBS1235'],
    ['Bank of Scotland plc Thornhill', '804870', 'BOFSGBS1234'],
    ['Bank of Scotland plc Thurso (R)', '804871', 'BOFSGBS1205'],
    ['Bank of Scotland plc Troon (R)', '804872', 'BOFSGBS1206'],
    ['Bank of Scotland plc Banff (804873)', '804873', 'BOFSGBS1207'],
    ['Bank of Scotland plc Uddingston (R)', '804874', 'BOFSGBS1208'],
    ['Bank of Scotland plc Ullapool', '804875', 'BOFSGBS1233'],
    ['Bank of Scotland plc Penicuik (804877)', '804877', 'BOFSGBS1232'],
    ['Bank of Scotland plc Wick (R)', '804879', 'BOFSGBS1209'],
    ['Bank of Scotland plc Newton Stewart (804880)', '804880', 'BOFSGBS1210'],
    ['Bank of Scotland plc Wishaw Main St', '804881', 'BOFSGBS1231'],
    ['Bank of Scotland plc Berwick upon Tweed', '804882', 'BOFSGBS1342'],
    ['Bank of Scotland plc Leith (804884)', '804884', 'BOFSGBS1230'],
    ['Bank of Scotland plc Aberdeen 52/54 Union St', '804886', 'BOFSGB21H18'],
    ['Bank of Scotland plc Edinburgh Cameron Toll', '804887', 'BOFSGBS1CTL'],
    ['Bank of Scotland plc Edinburgh Wester Hailes', '804888', 'BOFSGBS1WPZ'],
    ['Bank of Scotland plc Glasgow The Forge(804889)', '804889', 'BOFSGBS1DNS'],
    ['Bank of Scotland plc Livingston', '804891', 'BOFSGBS1LVT'],
    ['Bank of Scotland plc Glasgow Pollok', '804892', 'BOFSGBS1PLK'],
    ['Bank of Scotland plc Bos Mortgages (R)', '804897', 'BOFSGB21MTG'],
    ['Bank of Scotland plc Pcs-Bacs', '804910', ''],
    ['Bank of Scotland plc Private Bkng Channel Sup', '804920', 'BOFSGB21PCS'],
    ['Bank of Scotland plc Private Banking Edinburgh', '804927', 'BOFSGB21EDN'],
    ['Bank of Scotland plc Private Banking Aberdeen', '804928', 'BOFSGB21ABD'],
    ['Bank of Scotland plc Pb Direct Leeds (804929)', '804929', 'BOFSGB21PER'],
    ['Bank of Scotland plc Private Banking Glasgow', '804930', 'BOFSGB21GLA'],
    ['Bank of Scotland plc Leith 4 Leith Walk', '804941', 'BOFSGBS1LWK'],
    ['Bank of Scotland plc Bos Direct Savings', '804943', 'BOFSGBS1BDS'],
    ['Bank of Scotland plc Private Banking Inverness', '804944', 'BOFSGBS1INV'],
    ['Bank of Scotland plc Private Banking Edinburgh', '804955', 'BOFSGBS1UBR'],
    ['Bank of Scotland plc Glasgow Argyle St 804957', '804957', 'BOFSGBS1ARY'],
    [
      'Bank of Scotland plc Telephone Banking (804960)',
      '804960',
      'BOFSGBS1TTO'
    ],
    ['Bank of Scotland plc Test Branch 804963', '804963', 'BOFSGBS1TTS'],
    ['Bank of Scotland plc Et Digital Uat (804971)', '804971', 'BOFSGBS1TT5'],
    ['Bank of Scotland plc Test Branch (Wmh 804973)', '804973', 'BOFSGBS1TT7'],
    ['Bank of Scotland plc Rtl Lab-Tr1 Testing804975', '804975', 'BOFSGBS1TT8'],
    [
      'Bank of Scotland plc Rba - Shared Svcs (804976)',
      '804976',
      'BOFSGBS1TT9'
    ],
    ['Bank of Scotland plc Lt Bristol Ag16', '804977', 'BOFSGBS1T10'],
    ['Bank of Scotland plc Copley Atm Test 804979', '804979', 'BOFSGBS1T12'],
    ['Bank of Scotland plc Berwick upon Tweed', '804988', 'BOFSGBS1343'],
    ['Bank of Scotland plc Rdp / Offshore', '805011', ''],
    ['Bank of Scotland plc Epayments', '805015', ''],
    ['Bank of Scotland plc Client Asset Mgt (805022)', '805022', ''],
    ['Bank of Scotland plc 25 Gresham (805023)', '805023', ''],
    ['Bank of Scotland plc 25 Gresham (805024)', '805024', ''],
    [
      'Bank of Scotland plc Edinburgh St James Quarter',
      '805030',
      'BOFSGBS1430'
    ],
    ['Bank of Scotland plc L&U Bristol (805060)', '805060', ''],
    ['Bank of Scotland plc Birmingham:Civic', '805063', ''],
    ["Bank of Scotland plc Birm'ham Civic Hse Cs", '805065', ''],
    ['Bank of Scotland plc Banking Operations', '805073', ''],
    ['Bank of Scotland plc Andover Sc (805077)', '805077', ''],
    ['Bank of Scotland plc Banking Operations', '805083', ''],
    ['Bank of Scotland plc Bank of Scotland Redress', '805092', ''],
    ['Bank of Scotland plc Portland Street4 (805098)', '805098', ''],
    ['Bank of Scotland plc Banking Operations', '805101', ''],
    ['Bank of Scotland plc Banking Operations', '805102', ''],
    ['Bank of Scotland plc Banking Operations', '805103', ''],
    ['Bank of Scotland plc Banking Operations', '805104', ''],
    ['Bank of Scotland plc Banking Operations', '805105', ''],
    ['Bank of Scotland plc Banking Operations', '805107', ''],
    ['Bank of Scotland plc Credit Operations', '805109', ''],
    ['Bank of Scotland plc Bos Direct Savings', '805112', 'BOFSGBS1344'],
    ['Bank of Scotland plc Citymark Edinburgh', '805118', ''],
    ["Bank of Scotland plc Vincent St G'gow 1", '805125', ''],
    ["Bank of Scotland plc Vincent St G'gow 2", '805126', ''],
    ["Bank of Scotland plc Vincent St G'gow 2", '805127', ''],
    ['Bank of Scotland plc Credit Operations', '805143', ''],
    ['Bank of Scotland plc Credit Operations', '805144', ''],
    ['Bank of Scotland plc Edinburgh Ettrick', '805145', ''],
    ['Bank of Scotland plc Credit Operations', '805146', ''],
    ['Bank of Scotland plc Credit Operations', '805147', ''],
    ['Bank of Scotland plc Rfu - Non-P Halifax 1', '805156', ''],
    ['Bank of Scotland plc Rba - Shared Svcs (805160)', '805160', ''],
    ['Bank of Scotland plc Rba - Shared Svcs (805163)', '805163', ''],
    ['Bank of Scotland plc Ccfd Bos 805173', '805173', ''],
    ['Bank of Scotland plc Fraud Operations', '805175', ''],
    ['Bank of Scotland plc Credit Operations', '805184', ''],
    ['Bank of Scotland plc Rfu - Non-P Halifax 2', '805218', ''],
    ['Bank of Scotland plc Rfu - Non-P Halifax 3', '805219', ''],
    ['Bank of Scotland plc Group Reconciliations 805', '805224', ''],
    ['Bank of Scotland plc Clearings Scotland', '805230', ''],
    ['Bank of Scotland plc City Office Gillingham', '805312', ''],
    ['Bank of Scotland plc Gtc (Non Ics Trans)', '805331', ''],
    ['Bank of Scotland plc Gtc (Non Ics Trans)', '805332', ''],
    ['Bank of Scotland plc Gtc (Non Ics Trans)', '805334', ''],
    ['Bank of Scotland plc Ipsl - Cri', '805346', ''],
    ['Bank of Scotland plc Gops Oppi Server 24', '805392', ''],
    ["Bank of Scotland plc Int'l Payments", '805394', ''],
    ['Bank of Scotland plc Glasgow Argyle St', '805401', 'BOFSGB21138'],
    ['Bank of Scotland plc Telephony-Support (805407)', '805407', ''],
    ['Bank of Scotland plc Gtc (Non Ics Trans)', '805415', ''],
    ['Bank of Scotland plc Gtc (Non Ics Trans)', '805417', ''],
    ['Bank of Scotland plc Banking Operations', '805425', ''],
    ['Bank of Scotland plc Private Banking Ops', '805426', ''],
    ['Bank of Scotland plc Ipsl - R&A', '805439', ''],
    ['Bank of Scotland plc Ipsl - R&A', '805440', ''],
    ['Bank of Scotland plc Banking Operations', '805444', ''],
    ['Bank of Scotland plc Banking Operations', '805446', ''],
    ['Bank of Scotland plc Banking Operations', '805447', ''],
    ['Bank of Scotland plc Banking Operations', '805454', ''],
    ['Bank of Scotland plc Banking Operations', '805468', ''],
    ['Bank of Scotland plc Birmingham Epay', '805471', ''],
    ['Bank of Scotland plc Banking Operations', '805475', ''],
    ['Bank of Scotland plc Rbb Colmore Row Coserv 1', '805480', ''],
    ['Bank of Scotland plc Rbb Colmore Row Coserv 2', '805481', ''],
    ['Bank of Scotland plc Rbb Colmore Row Coserv 3', '805482', ''],
    ['Bank of Scotland plc Rbb Colmore Row Coserv 4', '805483', ''],
    ['Bank of Scotland plc Rbb Teviot House Co Serv', '805496', ''],
    ['Bank of Scotland plc Rbb Teviot House Co Serv', '805498', ''],
    ['Bank of Scotland plc Rbb Teviot House Co Serv', '805709', ''],
    ['Bank of Scotland plc Rbb Teviot House Co Serv', '805710', ''],
    ['Bank of Scotland plc Cards Finance (Chicago)', '805816', ''],
    ['Bank of Scotland plc Crc - Bos Cheque', '805825', ''],
    ['Bank of Scotland plc Aberdeen Upperkirkgat', '807330', 'BOFSGB21131'],
    ['Bank of Scotland plc Dundee Fairmuir (807331)', '807331', 'BOFSGB21124'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (808333)',
      '808333',
      'BOFSGB21123'
    ],
    ['Bank of Scotland plc Glasgow Argyle St 808344', '808344', 'BOFSGB21122'],
    [
      'Bank of Scotland plc Glasgow 54 Sauchie (808388)',
      '808388',
      'BOFSGB21121'
    ],
    ['Bank of Scotland plc Greenock,Cathart Street', '809125', 'BOFSGB21120'],
    ['Bank of Scotland plc Inverness 9 High Street', '809126', 'BOFSGB21125'],
    ['Bank of Scotland plc Paisley The Cross', '809127', 'BOFSGB21130'],
    ['Bank of Scotland plc Perth St John Street', '809128', 'BOFSGB21129'],
    ['Bank of Scotland plc Stirling 54 King Street', '809129', 'BOFSGB21128'],
    ['Bank of Scotland plc Nat Bk of Kuwait Card Serv', '809503', ''],
    ['Bank of Scotland plc Client Banking Demo', '809974', ''],
    ['CLYDESDALE BANK PLC London Gresham Street', '820403', 'CLYDGB21647'],
    [
      'CLYDESDALE BANK PLC London Piccadilly Circus Cb',
      '821107',
      'CLYDGB21943'
    ],
    ["CLYDESDALE BANK PLC London St James's Street", '821208', 'CLYDGB21842'],
    ['CLYDESDALE BANK PLC London Victoria', '821303', 'CLYDGB2174L'],
    ['CLYDESDALE BANK PLC Ashton under Lyne', '821850', 'CLYDGB21850'],
    ['CLYDESDALE BANK PLC Barnsley Peel Square', '821851', ''],
    ['CLYDESDALE BANK PLC Beverley Cb', '821852', 'CLYDGB21852'],
    ['CLYDESDALE BANK PLC Blackburn', '821853', ''],
    ['CLYDESDALE BANK PLC Blackpool Abingdon St', '821854', ''],
    ['CLYDESDALE BANK PLC Bolton Cb', '821855', 'CLYDGB21855'],
    ['CLYDESDALE BANK PLC Bradford Broadway', '821856', ''],
    ['CLYDESDALE BANK PLC Burnley', '821857', ''],
    ['CLYDESDALE BANK PLC Bury Cb', '821858', ''],
    ['CLYDESDALE BANK PLC Castleford', '821859', ''],
    ['CLYDESDALE BANK PLC Chesterfield', '821860', ''],
    ['CLYDESDALE BANK PLC Cleckheaton', '821862', ''],
    ['CLYDESDALE BANK PLC Coventry', '821863', 'CLYDGB21863'],
    ['CLYDESDALE BANK PLC Darlington Cb', '821864', 'CLYDGB21864'],
    ['CLYDESDALE BANK PLC Derby', '821865', 'CLYDGB21865'],
    ['CLYDESDALE BANK PLC Dewsbury Cb', '821866', ''],
    ['CLYDESDALE BANK PLC Doncaster', '821867', ''],
    ['CLYDESDALE BANK PLC Eston', '821868', ''],
    ['CLYDESDALE BANK PLC Grantham', '821869', ''],
    ['CLYDESDALE BANK PLC Grimsby', '821870', ''],
    ['CLYDESDALE BANK PLC Halifax', '821871', ''],
    ['CLYDESDALE BANK PLC Harrogate', '821872', ''],
    ['CLYDESDALE BANK PLC Hartlepool', '821873', 'CLYDGB21873'],
    ['CLYDESDALE BANK PLC Huddersfield', '821874', ''],
    ['CLYDESDALE BANK PLC Hull Holderness Road', '821875', ''],
    ['CLYDESDALE BANK PLC Keighley', '821876', ''],
    ['CLYDESDALE BANK PLC Kendall', '821877', ''],
    ['CLYDESDALE BANK PLC Lancaster', '821878', 'CLYDGB21878'],
    ['CLYDESDALE BANK PLC Leeds City Cb', '821879', ''],
    ['CLYDESDALE BANK PLC Leeds, Crossgates', '821880', 'CLYDGB21880'],
    ['CLYDESDALE BANK PLC Leeds, Horsforth', '821881', 'CLYDGB21881'],
    ['CLYDESDALE BANK PLC Leeds Hunslet Cb', '821882', ''],
    ['CLYDESDALE BANK PLC Leeds, Moortown', '821883', 'CLYDGB21883'],
    ['CLYDESDALE BANK PLC White Rose Shop Ctr', '821884', 'CLYDGB21884'],
    ['CLYDESDALE BANK PLC Lincoln', '821885', ''],
    ['CLYDESDALE BANK PLC Macclesfield Cb', '821886', ''],
    ['CLYDESDALE BANK PLC Malton Cb', '821887', ''],
    ['CLYDESDALE BANK PLC Mansfield', '821888', ''],
    ['CLYDESDALE BANK PLC Mexborough', '821889', ''],
    ['CLYDESDALE BANK PLC Middlesbrough Central', '821890', 'CLYDGB21890'],
    ['CLYDESDALE BANK PLC Nelson', '821891', ''],
    ['CLYDESDALE BANK PLC Newcastle upon Tyne', '821892', ''],
    ['CLYDESDALE BANK PLC Northallerton', '821893', ''],
    ['CLYDESDALE BANK PLC Northampton', '821894', ''],
    ['CLYDESDALE BANK PLC Nottingham Cb', '821895', 'CLYDGB21895'],
    ['CLYDESDALE BANK PLC Nuneaton', '821896', 'CLYDGB21896'],
    ['CLYDESDALE BANK PLC Oldham Cb', '821897', 'CLYDGB21897'],
    ['CLYDESDALE BANK PLC Otley Cb', '821898', ''],
    ['CLYDESDALE BANK PLC Preston', '821899', 'CLYDGB21899'],
    ['CLYDESDALE BANK PLC Pudsey Cb', '821900', 'CLYDGB21900'],
    ['CLYDESDALE BANK PLC Rochdale', '821901', ''],
    ['CLYDESDALE BANK PLC Rotherham Cb', '821902', ''],
    ['CLYDESDALE BANK PLC Scarborough Cb', '821903', ''],
    ['CLYDESDALE BANK PLC Scunthorpe', '821904', ''],
    ['CLYDESDALE BANK PLC Selby Cb', '821905', ''],
    ['CLYDESDALE BANK PLC Sheffield Hillsborough', '821906', ''],
    ['CLYDESDALE BANK PLC Sheffield Meadowhall', '821907', ''],
    ['CLYDESDALE BANK PLC Sheffield Woodseats', '821908', ''],
    ['CLYDESDALE BANK PLC Shipley Cb', '821909', ''],
    ['CLYDESDALE BANK PLC St Helens', '821910', ''],
    ['CLYDESDALE BANK PLC Stockport', '821911', ''],
    ['CLYDESDALE BANK PLC Stockton', '821912', 'CLYDGB21912'],
    ['CLYDESDALE BANK PLC Thornaby', '821913', ''],
    ['CLYDESDALE BANK PLC Wakefield Northgate', '821914', ''],
    ['CLYDESDALE BANK PLC Whitby Cb', '821915', ''],
    ['CLYDESDALE BANK PLC Wigan Cb', '821916', ''],
    ['CLYDESDALE BANK PLC York', '821917', ''],
    ['CLYDESDALE BANK PLC Glasgow (Lounge)', '821918', 'CLYDGB21918'],
    ['CLYDESDALE BANK PLC Golders Green Cb', '821919', ''],
    ['CLYDESDALE BANK PLC London Haymarket Cb', '821920', ''],
    ['CLYDESDALE BANK PLC Hull', '821921', 'CLYDGB21921'],
    ['CLYDESDALE BANK PLC Wolverhampton', '821922', ''],
    ['CLYDESDALE BANK PLC Leicester', '821923', ''],
    ['CLYDESDALE BANK PLC Peterborough', '821924', ''],
    ['CLYDESDALE BANK PLC Norwich Lounge Cb', '821925', ''],
    ['CLYDESDALE BANK PLC Oxford Cb', '821926', ''],
    ['CLYDESDALE BANK PLC Sheffield Lounge', '821927', ''],
    ['CLYDESDALE BANK PLC Cardiff (Lounge) Cb', '821928', ''],
    ['CLYDESDALE BANK PLC Brighton Cb', '821929', ''],
    ['CLYDESDALE BANK PLC Liverpool Cb', '821930', ''],
    ['CLYDESDALE BANK PLC Newcastle Northumberland Cb', '821931', ''],
    ['CLYDESDALE BANK PLC Cramlington Cb', '821932', ''],
    ['CLYDESDALE BANK PLC Gosforth Centre Cb', '821933', ''],
    ['CLYDESDALE BANK PLC Newcastle  The Street Cb', '821934', ''],
    ['CLYDESDALE BANK PLC Sunderland Fawcett St', '821935', ''],
    ['CLYDESDALE BANK PLC South Shields Fowler St Cb', '821936', ''],
    ['CLYDESDALE BANK PLC Cambridge Cb', '821937', ''],
    ['CLYDESDALE BANK PLC Southampton Cb', '821938', ''],
    ['CLYDESDALE BANK PLC Hexham Cb', '821939', ''],
    ['CLYDESDALE BANK PLC Bromley Cb', '821940', ''],
    ['CLYDESDALE BANK PLC Croydon', '821941', ''],
    ['CLYDESDALE BANK PLC Guildford Cb', '821942', ''],
    ['CLYDESDALE BANK PLC Kingston Cb', '821943', ''],
    ['CLYDESDALE BANK PLC Chester Cb', '821944', ''],
    ['CLYDESDALE BANK PLC Bristol Cb', '821945', ''],
    ['CLYDESDALE BANK PLC Cheltenham', '821946', ''],
    ['CLYDESDALE BANK PLC Swindon Cb', '821947', ''],
    ['CLYDESDALE BANK PLC Chelmsford Cb', '821948', ''],
    ['CLYDESDALE BANK PLC Enfield Cb', '821949', ''],
    ['CLYDESDALE BANK PLC Harrow Cb', '821950', ''],
    ['CLYDESDALE BANK PLC St Albans Cb', '821951', ''],
    ['CLYDESDALE BANK PLC Milton Keynes Cb', '821952', ''],
    ['CLYDESDALE BANK PLC Reading Cb', '821953', ''],
    ['CLYDESDALE BANK PLC Durham Cb', '821954', ''],
    ['CLYDESDALE BANK PLC Washington Cb', '821955', ''],
    ['CLYDESDALE BANK PLC Bournemouth Cb', '821956', ''],
    ['CLYDESDALE BANK PLC Exeter Cb', '821957', ''],
    ['CLYDESDALE BANK PLC Belfast Cb', '821958', ''],
    ['CLYDESDALE BANK PLC Manchester', '821959', ''],
    ['CLYDESDALE BANK PLC Glasgow Head Office', '822000', 'CLYDGB21450'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 1', '823000', 'CLYDGB21000'],
    ['CLYDESDALE BANK PLC Aberdeen St Nicholas', '824000', 'CLYDGB21250'],
    ['CLYDESDALE BANK PLC Aberdeen West End', '824101', 'CLYDGB21946'],
    ['CLYDESDALE BANK PLC Ayr 81 High Street', '824202', 'CLYDGB21849'],
    ['CLYDESDALE BANK PLC Carlisle', '824303', 'CLYDGB2174S'],
    ['CLYDESDALE BANK PLC Dundee Chief Office', '824404', 'CLYDGB21D46'],
    ['CLYDESDALE BANK PLC Edinburgh', '824505', 'CLYDGB21545'],
    ['CLYDESDALE BANK PLC Glasgow Anderston', '824606', 'CLYDGB21444'],
    ['CLYDESDALE BANK PLC Glasgow 120 Bath Street', '824707', 'CLYDGB21343'],
    ['CLYDESDALE BANK PLC Glasgow Bothwell Street', '824808', 'CLYDGB21242'],
    ['CLYDESDALE BANK PLC Ams Glasgow', '824909', 'CLYDGB21GSS'],
    ['CLYDESDALE BANK PLC Glasgow St Vincent Street', '825000', 'CLYDGB21V50'],
    ['CLYDESDALE BANK PLC Glasgow St Enoch Square', '825101', 'CLYDGB21949'],
    [
      'CLYDESDALE BANK PLC Clydesdale Bank Post Office',
      '825202',
      'CLYDGB21520'
    ],
    ['CLYDESDALE BANK PLC Glasgow Sauchiehall St', '825303', 'CLYDGB21747'],
    ['CLYDESDALE BANK PLC Paisley Dunn Square', '825404', 'CLYDGB21646'],
    ['CLYDESDALE BANK PLC Direct Banking 1', '826000', 'CLYDGB21060'],
    ['CLYDESDALE BANK PLC Aberdeen George Street', '826001', 'CLYDGB21B01'],
    [
      'CLYDESDALE BANK PLC Aberdeen Great Western Road',
      '826002',
      'CLYDGB21B02'
    ],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 2', '826003', 'CLYDGB21003'],
    ['CLYDESDALE BANK PLC Clydesdale Bft 3', '826004', 'CLYDGB21004'],
    ['CLYDESDALE BANK PLC Aberdeen Mastrick', '826007', 'CLYDGB21B07'],
    ['CLYDESDALE BANK PLC Aberdeen Mile End', '826008', 'CLYDGB21B08'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 4', '826009', 'CLYDGB21009'],
    ['CLYDESDALE BANK PLC Aberdeen Riverside', '826010', 'CLYDGB21B10'],
    ['CLYDESDALE BANK PLC Aberdeen Principal Branch', '826011', 'CLYDGB21350'],
    ['CLYDESDALE BANK PLC Aberdeen Rosemount', '826012', 'CLYDGB2112A'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 5', '826013', 'CLYDGB21013'],
    ['CLYDESDALE BANK PLC Aberdeen Woodside', '826015', 'CLYDGB21015'],
    ['CLYDESDALE BANK PLC Aberlour', '826016', 'CLYDGB21B16'],
    ['CLYDESDALE BANK PLC Aboyne', '826017', 'CLYDGB210S7'],
    ['CLYDESDALE BANK PLC Airdrie', '826018', 'CLYDGB21B18'],
    ['CLYDESDALE BANK PLC Alexandria', '826019', 'CLYDGB21B19'],
    ['CLYDESDALE BANK PLC Alford', '826020', 'CLYDGB21B20'],
    ['CLYDESDALE BANK PLC Alloa', '826021', 'CLYDGB21B21'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 6', '826022', 'CLYDGB21022'],
    ['CLYDESDALE BANK PLC Anstruther', '826023', 'CLYDGB2102S'],
    ['CLYDESDALE BANK PLC Arbroath', '826024', 'CLYDGB21B24'],
    ['CLYDESDALE BANK PLC Direct Banking 2', '826025', 'CLYDGB21025'],
    ['CLYDESDALE BANK PLC Ardrossan', '826026', 'CLYDGB21B26'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 7', '826027', 'CLYDGB21027'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 8', '826028', 'CLYDGB21028'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 9', '826029', 'CLYDGB21029'],
    ['CLYDESDALE BANK PLC Ayr Alloway Street', '826030', 'CLYDGB21B30'],
    ['CLYDESDALE BANK PLC Ayr Tams Brig', '826031', 'CLYDGB21B31'],
    ['CLYDESDALE BANK PLC Baillieston', '826032', 'CLYDGB21B32'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 10', '826033', 'CLYDGB21033'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 11', '826034', 'CLYDGB21034'],
    ['CLYDESDALE BANK PLC Clydesdale Card Services', '826081', 'CLYDGB21081'],
    ['CLYDESDALE BANK PLC Visa', '826091', ''],
    ['CLYDESDALE BANK PLC Visa Chargecard', '826092', ''],
    ['CLYDESDALE BANK PLC Clydesdale Mastercard', '826098', ''],
    ['CLYDESDALE BANK PLC Banchory', '826100', 'CLYDGB21100'],
    ['CLYDESDALE BANK PLC Banff', '826102', 'CLYDGB21102'],
    ['CLYDESDALE BANK PLC Beith', '826103', 'CLYDGB21103'],
    ['CLYDESDALE BANK PLC Bellshill', '826104', 'CLYDGB21104'],
    ['CLYDESDALE BANK PLC Blairgowrie', '826105', 'CLYDGB21105'],
    ['CLYDESDALE BANK PLC Blantyre', '826106', 'CLYDGB21106'],
    ['CLYDESDALE BANK PLC Boness', '826107', 'CLYDGB21107'],
    ['CLYDESDALE BANK PLC Bothwell', '826108', 'CLYDGB21108'],
    ['CLYDESDALE BANK PLC Brechin', '826109', 'CLYDGB21109'],
    ['CLYDESDALE BANK PLC Bridge of Allan', '826110', 'CLYDGB21110'],
    ['CLYDESDALE BANK PLC Bridge of Weir', '826111', 'CLYDGB21111'],
    ['CLYDESDALE BANK PLC Brora', '826112', 'CLYDGB21112'],
    ['CLYDESDALE BANK PLC Broughty Ferry', '826113', 'CLYDGB21113'],
    ['CLYDESDALE BANK PLC Buckie', '826114', 'CLYDGB21114'],
    ['CLYDESDALE BANK PLC Clydesdalebank Bft 12', '826115', 'CLYDGB21115'],
    ['CLYDESDALE BANK PLC Bucksburn', '826116', 'CLYDGB21116'],
    ['CLYDESDALE BANK PLC Burnbank', '826117', 'CLYDGB21117'],
    ['CLYDESDALE BANK PLC Cambuslang', '826118', 'CLYDGB21118'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 13', '826119', 'CLYDGB21119'],
    ['CLYDESDALE BANK PLC Campbeltown', '826120', 'CLYDGB21120'],
    ['CLYDESDALE BANK PLC Carnoustie', '826121', 'CLYDGB21121'],
    ['CLYDESDALE BANK PLC Castle Douglas', '826122', 'CLYDGB21122'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 14', '826123', 'CLYDGB21123'],
    ['CLYDESDALE BANK PLC Chryston', '826124', 'CLYDGB21124'],
    ['CLYDESDALE BANK PLC Clydebank', '826125', 'CLYDGB21125'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 15', '826126', 'CLYDGB21126'],
    ['CLYDESDALE BANK PLC Coatbridge', '826127', 'CLYDGB21127'],
    ['CLYDESDALE BANK PLC Comrie', '826128', 'CLYDGB21128'],
    ['CLYDESDALE BANK PLC Crieff', '826129', 'CLYDGB21129'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 16', '826130', 'CLYDGB21130'],
    ['CLYDESDALE BANK PLC Cullen', '826131', 'CLYDGB21131'],
    ['CLYDESDALE BANK PLC Cults', '826132', 'CLYDGB21132'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 17', '826133', 'CLYDGB21133'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 18', '826134', 'CLYDGB21134'],
    ['CLYDESDALE BANK PLC Contact Centres', '826136', 'CLYDGB21136'],
    ['CLYDESDALE BANK PLC Business Banking', '826137', 'CLYDGB21137'],
    ['CLYDESDALE BANK PLC Pca Accounts', '826138', 'CLYDGB21138'],
    ['CLYDESDALE BANK PLC On Line Pca Accounts', '826139', 'CLYDGB21139'],
    ['CLYDESDALE BANK PLC Cumnock', '826200', 'CLYDGB21200'],
    ['CLYDESDALE BANK PLC Cupar Fife', '826201', 'CLYDGB21201'],
    ['CLYDESDALE BANK PLC Dalbeattie', '826202', 'CLYDGB21202'],
    ['CLYDESDALE BANK PLC Dalkeith', '826203', 'CLYDGB21203'],
    ['CLYDESDALE BANK PLC Dalry Ayrshire', '826204', 'CLYDGB21204'],
    ['CLYDESDALE BANK PLC Darvel', '826205', 'CLYDGB21205'],
    ['CLYDESDALE BANK PLC Denny', '826206', 'CLYDGB21206'],
    ['CLYDESDALE BANK PLC Dingwall', '826207', 'CLYDGB21207'],
    ['CLYDESDALE BANK PLC Dollar', '826208', 'CLYDGB21208'],
    ['CLYDESDALE BANK PLC Dornoch', '826209', 'CLYDGB21209'],
    ['CLYDESDALE BANK PLC Dufftown', '826210', 'CLYDGB21210'],
    ['CLYDESDALE BANK PLC Dumbarton', '826211', 'CLYDGB21211'],
    ['CLYDESDALE BANK PLC Dumfries Buccleuch Street', '826212', 'CLYDGB21212'],
    ['CLYDESDALE BANK PLC Dumfries Bank Street', '826213', 'CLYDGB21213'],
    ['CLYDESDALE BANK PLC Dundee Albert Square', '826214', 'CLYDGB21214'],
    ['CLYDESDALE BANK PLC Dundee West End', '826215', 'CLYDGB21215'],
    ['CLYDESDALE BANK PLC Dundee Downfield', '826216', 'CLYDGB21216'],
    ['CLYDESDALE BANK PLC Dunfermline', '826219', 'CLYDGB21219'],
    ['CLYDESDALE BANK PLC Dunlop', '826220', 'CLYDGB21220'],
    ['CLYDESDALE BANK PLC Dunoon', '826221', 'CLYDGB21221'],
    ['CLYDESDALE BANK PLC Dyce', '826222', 'CLYDGB21222'],
    ['CLYDESDALE BANK PLC Eaglesham', '826223', 'CLYDGB21223'],
    ['CLYDESDALE BANK PLC East Kilbride 19 Stuart St', '826224', 'CLYDGB21224'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 19', '826225', 'CLYDGB21225'],
    ['CLYDESDALE BANK PLC Edinburgh Charlotte Square', '826226', 'CLYDGB21226'],
    ['CLYDESDALE BANK PLC Edinburgh Clydesdale Plaza', '826227', 'CLYDGB21227'],
    ['CLYDESDALE BANK PLC Edinburgh Easter Road', '826228', 'CLYDGB21218'],
    ['CLYDESDALE BANK PLC Edinburgh Gorgie', '826229', 'CLYDGB21229'],
    ['CLYDESDALE BANK PLC Edinburgh Haymarket', '826230', 'CLYDGB21230'],
    ['CLYDESDALE BANK PLC Edinburgh Leith Walk', '826231', 'CLYDGB21231'],
    ['CLYDESDALE BANK PLC Edinburgh Morningside Road', '826233', 'CLYDGB21233'],
    ['CLYDESDALE BANK PLC Edinburgh Newington', '826234', 'CLYDGB21234'],
    ['CLYDESDALE BANK PLC Edinburgh North Bridge', '826300', 'CLYDGB21300'],
    ['CLYDESDALE BANK PLC Edinburgh Executive Bnk St', '826301', 'CLYDGB21301'],
    ['CLYDESDALE BANK PLC Edinburgh Hanover Street', '826302', 'CLYDGB21302'],
    ['CLYDESDALE BANK PLC Edinburgh Sighthill', '826303', 'CLYDGB21303'],
    ['CLYDESDALE BANK PLC Edinburgh Tollcross', '826304', 'CLYDGB21304'],
    ['CLYDESDALE BANK PLC Edinburgh West End', '826305', 'CLYDGB21305'],
    ['CLYDESDALE BANK PLC Elgin', '826307', 'CLYDGB21307'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 20', '826309', 'CLYDGB21309'],
    ['CLYDESDALE BANK PLC Ellon', '826310', 'CLYDGB21310'],
    ['CLYDESDALE BANK PLC Falkirk', '826312', 'CLYDGB21312'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 21', '826313', 'CLYDGB21313'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 22', '826314', 'CLYDGB21314'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft', '826315', 'CLYDGB21315'],
    ['CLYDESDALE BANK PLC Fochabers', '826317', 'CLYDGB21317'],
    ['CLYDESDALE BANK PLC Forfar', '826318', 'CLYDGB21318'],
    ['CLYDESDALE BANK PLC Forres', '826319', 'CLYDGB21319'],
    ['CLYDESDALE BANK PLC Fraserburgh', '826320', 'CLYDGB21320'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 23', '826321', 'CLYDGB21321'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 24', '826322', 'CLYDGB21322'],
    ['CLYDESDALE BANK PLC Galashiels', '826323', 'CLYDGB21323'],
    ['CLYDESDALE BANK PLC Glasgow Direct Tpd/Savings', '826324', 'CLYDGB21324'],
    ['CLYDESDALE BANK PLC Giffnock', '826325', 'CLYDGB21325'],
    ['CLYDESDALE BANK PLC Glasgow Aikenhead Road', '826326', 'CLYDGB21326'],
    ['CLYDESDALE BANK PLC Glasgow Anniesland', '826327', 'CLYDGB21327'],
    ['CLYDESDALE BANK PLC Glasgow Calton & Bridgeton', '826329', 'CLYDGB21329'],
    ['CLYDESDALE BANK PLC Glasgow Cardonald', '826330', 'CLYDGB21330'],
    ['CLYDESDALE BANK PLC Glasgow Castlemilk Road', '826331', 'CLYDGB21331'],
    ['CLYDESDALE BANK PLC Glasgow Cathcart', '826332', 'CLYDGB21332'],
    ['CLYDESDALE BANK PLC Cly Bank Mortgage Services', '826333', ''],
    ['CLYDESDALE BANK PLC Glasgow Central', '826400', 'CLYDGB21400'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 25', '826402', 'CLYDGB21402'],
    ['CLYDESDALE BANK PLC Glasgow Dennistoun', '826404', 'CLYDGB21404'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 26', '826405', 'CLYDGB21405'],
    ['CLYDESDALE BANK PLC Glasgow Govan', '826406', 'CLYDGB21406'],
    ['CLYDESDALE BANK PLC Glasgow Govanhill', '826407', 'CLYDGB21407'],
    ['CLYDESDALE BANK PLC Glasgow Hillhead', '826408', 'CLYDGB21408'],
    ['CLYDESDALE BANK PLC Glasgow Hyndland', '826411', 'CLYDGB21411'],
    ['CLYDESDALE BANK PLC Glasgow Ingram Street', '826412', 'CLYDGB21412'],
    ['CLYDESDALE BANK PLC Glasgow Laurieston', '826413', 'CLYDGB21413'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 27', '826415', 'CLYDGB21415'],
    ['CLYDESDALE BANK PLC Glasgow Merrylee Park', '826416', 'CLYDGB21416'],
    ['CLYDESDALE BANK PLC Glasgow Mount Florida', '826417', 'CLYDGB21417'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 28', '826419', 'CLYDGB21419'],
    ['CLYDESDALE BANK PLC Glasgow Parkhead', '826420', 'CLYDGB21757'],
    ['CLYDESDALE BANK PLC Glasgow Partick', '826421', 'CLYDGB21421'],
    ['CLYDESDALE BANK PLC Glasgow Pollokshaws', '826422', 'CLYDGB21422'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 29', '826423', 'CLYDGB21423'],
    ['CLYDESDALE BANK PLC Glasgow St Georges Cross', '826424', 'CLYDGB21424'],
    ['CLYDESDALE BANK PLC Glasgow St Rollox', '826426', 'CLYDGB21426'],
    ['CLYDESDALE BANK PLC Glasgow Shawlands', '826427', 'CLYDGB21427'],
    ['CLYDESDALE BANK PLC Glasgow Shettleston', '826428', 'CLYDGB21428'],
    ['CLYDESDALE BANK PLC Glasgow Springburn', '826429', 'CLYDGB21429'],
    ['CLYDESDALE BANK PLC Glasgow Tollcross', '826430', 'CLYDGB21430'],
    ['CLYDESDALE BANK PLC Glasgow Trongate', '826431', 'CLYDGB21431'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 30', '826433', 'CLYDGB21433'],
    ['CLYDESDALE BANK PLC Glasgow Charing Cross', '826434', 'CLYDGB21434'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 31', '826500', 'CLYDGB21500'],
    ['CLYDESDALE BANK PLC Golspie', '826501', 'CLYDGB21501'],
    ['CLYDESDALE BANK PLC Gourock', '826502', 'CLYDGB21502'],
    ['CLYDESDALE BANK PLC Grangemouth', '826503', 'CLYDGB21503'],
    ['CLYDESDALE BANK PLC Greenock   Cathcart Square', '826504', 'CLYDGB21504'],
    ['CLYDESDALE BANK PLC Greenock West End', '826506', 'CLYDGB21506'],
    ['CLYDESDALE BANK PLC Hamilton Cadzow Street', '826507', 'CLYDGB21507'],
    ['CLYDESDALE BANK PLC Helensburgh', '826508', 'CLYDGB21508'],
    ['CLYDESDALE BANK PLC Holytown', '826509', 'CLYDGB21509'],
    ['CLYDESDALE BANK PLC Hopeman', '826510', 'CLYDGB21510'],
    ['CLYDESDALE BANK PLC Huntly', '826511', 'CLYDGB21511'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 32', '826512', 'CLYDGB21512'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 33', '826513', 'CLYDGB21513'],
    ['CLYDESDALE BANK PLC Insch', '826514', 'CLYDGB21514'],
    ['CLYDESDALE BANK PLC Inverbervie', '826515', 'CLYDGB21515'],
    ['CLYDESDALE BANK PLC Invergordon', '826516', 'CLYDGB21516'],
    ['CLYDESDALE BANK PLC Inverkeithing', '826517', 'CLYDGB21517'],
    ['CLYDESDALE BANK PLC Inverness Academy Street', '826518', 'CLYDGB21518'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 34', '826519', 'CLYDGB21519'],
    ['CLYDESDALE BANK PLC Inverurie', '826521', 'CLYDGB21521'],
    ['CLYDESDALE BANK PLC Irvine', '826522', 'CLYDGB21522'],
    ['CLYDESDALE BANK PLC Johnstone', '826523', 'CLYDGB21523'],
    ['CLYDESDALE BANK PLC Keith', '826524', 'CLYDGB21524'],
    ['CLYDESDALE BANK PLC Kelso', '826525', 'CLYDGB21525'],
    ['CLYDESDALE BANK PLC Kemnay', '826526', 'CLYDGB21526'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 35', '826527', 'CLYDGB21527'],
    ['CLYDESDALE BANK PLC Kilbirnie', '826528', 'CLYDGB21528'],
    ['CLYDESDALE BANK PLC Kilmarnock The Foregate', '826530', 'CLYDGB21530'],
    ['CLYDESDALE BANK PLC Glencairn Sq. Kilmarnock', '826531', 'CLYDGB21531'],
    ['CLYDESDALE BANK PLC Kilwinning', '826532', 'CLYDGB21532'],
    ['CLYDESDALE BANK PLC Kincardine on Forth', '826533', 'CLYDGB21533'],
    ['CLYDESDALE BANK PLC Kinross', '826534', 'CLYDGB21534'],
    ['CLYDESDALE BANK PLC Kintore', '826600', 'CLYDGB21600'],
    ['CLYDESDALE BANK PLC Kirkcaldy', '826601', 'CLYDGB21601'],
    ['CLYDESDALE BANK PLC Kirkwall', '826602', 'CLYDGB21602'],
    ['CLYDESDALE BANK PLC Lanark', '826603', 'CLYDGB21603'],
    ['CLYDESDALE BANK PLC Larbert', '826604', 'CLYDGB21604'],
    ['CLYDESDALE BANK PLC Largs', '826605', 'CLYDGB21605'],
    ['CLYDESDALE BANK PLC Laurencekirk', '826606', 'CLYDGB21606'],
    ['CLYDESDALE BANK PLC Edinburgh Leith', '826607', 'CLYDGB21607'],
    ['CLYDESDALE BANK PLC Lerwick', '826608', 'CLYDGB21608'],
    ['CLYDESDALE BANK PLC Leven', '826609', 'CLYDGB21609'],
    ['CLYDESDALE BANK PLC Lochee', '826610', 'CLYDGB21610'],
    ['CLYDESDALE BANK PLC Lochgilphead', '826611', 'CLYDGB21611'],
    ['CLYDESDALE BANK PLC Lockerbie', '826612', 'CLYDGB21612'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 36', '826613', 'CLYDGB21613'],
    ['CLYDESDALE BANK PLC Lossiemouth', '826615', 'CLYDGB21614'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 37', '826617', 'CLYDGB21617'],
    ['CLYDESDALE BANK PLC Macduff', '826618', 'CLYDGB21618'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 38', '826619', 'CLYDGB21619'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 39', '826620', 'CLYDGB21620'],
    ['CLYDESDALE BANK PLC Mid Calder', '826622', 'CLYDGB21622'],
    [
      'CLYDESDALE BANK PLC - GUERNSEY BRANCH Intg Fin Sols Int Guernsey',
      '826623',
      'CLYDGGSTXXX'
    ],
    ['CLYDESDALE BANK PLC Mintlaw', '826624', 'CLYDGB21624'],
    ['CLYDESDALE BANK PLC Montrose', '826625', 'CLYDGB21625'],
    ['CLYDESDALE BANK PLC Motherwell', '826626', 'CLYDGB21626'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 40', '826627', 'CLYDGB21627'],
    ['CLYDESDALE BANK PLC Nairn', '826628', 'CLYDGB21628'],
    ['CLYDESDALE BANK PLC Neilston', '826629', 'CLYDGB21629'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 41', '826631', 'CLYDGB21631'],
    ['CLYDESDALE BANK PLC New Deer', '826632', 'CLYDGB21632'],
    ['CLYDESDALE BANK PLC New Galloway', '826633', 'CLYDGB21633'],
    ['CLYDESDALE BANK PLC Newmains', '826634', 'CLYDGB21634'],
    ['CLYDESDALE BANK PLC Newmilns', '826700', 'CLYDGB21700'],
    ['CLYDESDALE BANK PLC Newport on Tay', '826701', 'CLYDGB21701'],
    ['CLYDESDALE BANK PLC Newton Stewart', '826702', 'CLYDGB21702'],
    ['CLYDESDALE BANK PLC North Berwick', '826703', 'CLYDGB21703'],
    ['CLYDESDALE BANK PLC Oban', '826704', 'CLYDGB21704'],
    ['CLYDESDALE BANK PLC Oldmeldrum', '826706', 'CLYDGB21706'],
    ['CLYDESDALE BANK PLC Direct Banking 3', '826707', 'CLYDGB21707'],
    ['CLYDESDALE BANK PLC Penicuik', '826708', 'CLYDGB21708'],
    ['CLYDESDALE BANK PLC South Methven Street Perth', '826709', 'CLYDGB21709'],
    ['CLYDESDALE BANK PLC Perth St John Street', '826711', 'CLYDGB21711'],
    ['CLYDESDALE BANK PLC Peterhead', '826712', 'CLYDGB21712'],
    ['CLYDESDALE BANK PLC Direct Banking 6', '826714', 'CLYDGB21714'],
    ['CLYDESDALE BANK PLC Port Glasgow', '826715', 'CLYDGB21715'],
    ['CLYDESDALE BANK PLC Edinburgh Portobello', '826717', 'CLYDGB21717'],
    ['CLYDESDALE BANK PLC Portree', '826718', 'CLYDGB21718'],
    ['CLYDESDALE BANK PLC Portsoy', '826719', 'CLYDGB21719'],
    ['CLYDESDALE BANK PLC Prestwick Main Street', '826720', 'CLYDGB21720'],
    ['CLYDESDALE BANK PLC C&Cp Customer Delivery', '826721', ''],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 42', '826724', 'CLYDGB21724'],
    ['CLYDESDALE BANK PLC Rosyth', '826725', 'CLYDGB21725'],
    ['CLYDESDALE BANK PLC Rothes', '826726', 'CLYDGB21726'],
    ['CLYDESDALE BANK PLC Rothesay', '826727', 'CLYDGB21727'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 43', '826728', 'CLYDGB21728'],
    ['CLYDESDALE BANK PLC Rutherglen', '826729', 'CLYDGB21729'],
    ['CLYDESDALE BANK PLC St Andrews', '826730', 'CLYDGB21730'],
    ['CLYDESDALE BANK PLC Cardtronics', '826731', 'CLYDGB21731'],
    ['CLYDESDALE BANK PLC Saltcoats', '826732', 'CLYDGB21732'],
    ['CLYDESDALE BANK PLC South Queensferry', '826800', 'CLYDGB21800'],
    ['CLYDESDALE BANK PLC Stenhousemuir', '826801', 'CLYDGB21801'],
    ['CLYDESDALE BANK PLC Stevenston', '826802', 'CLYDGB21802'],
    ['CLYDESDALE BANK PLC Stewarton', '826803', 'CLYDGB21803'],
    ['CLYDESDALE BANK PLC Direct Banking 4', '826804', 'CLYDGB21804'],
    ['CLYDESDALE BANK PLC Stirling', '826805', 'CLYDGB21805'],
    ['CLYDESDALE BANK PLC Direct Banking 5', '826806', 'CLYDGB21806'],
    ['CLYDESDALE BANK PLC Stonehaven', '826807', 'CLYDGB21807'],
    ['CLYDESDALE BANK PLC Stranraer', '826808', 'CLYDGB21808'],
    [
      'CLYDESDALE BANK PLC Direct Banking Mortgages Cb',
      '826810',
      'CLYDGB21810'
    ],
    ['CLYDESDALE BANK PLC Tain', '826811', 'CLYDGB21811'],
    ['CLYDESDALE BANK PLC Tarbert Argyll', '826812', 'CLYDGB21812'],
    ['CLYDESDALE BANK PLC Cash Post Office', '826813', 'CLYDGB21813'],
    ['CLYDESDALE BANK PLC Direct Banking Cb', '826814', 'CLYDGB21814'],
    ['CLYDESDALE BANK PLC Thurso', '826816', 'CLYDGB21816'],
    ['CLYDESDALE BANK PLC Tillicoultry', '826817', 'CLYDGB21817'],
    ['CLYDESDALE BANK PLC Tobermory', '826818', 'CLYDGB21818'],
    ['CLYDESDALE BANK PLC Troon', '826821', 'CLYDGB21821'],
    ['CLYDESDALE BANK PLC Turriff', '826822', 'CLYDGB21822'],
    ['CLYDESDALE BANK PLC West Kilbride', '826824', 'CLYDGB21824'],
    ['CLYDESDALE BANK PLC Whitehaven', '826825', 'CLYDGB21825'],
    ['CLYDESDALE BANK PLC Whithorn', '826826', 'CLYDGB21826'],
    ['CLYDESDALE BANK PLC Wick', '826827', 'CLYDGB21827'],
    ['CLYDESDALE BANK PLC Wishaw', '826828', 'CLYDGB21828'],
    ['CLYDESDALE BANK PLC Workington', '826829', 'CLYDGB21829'],
    ['CLYDESDALE BANK PLC Bathgate', '826830', 'CLYDGB21830'],
    ['CLYDESDALE BANK PLC Glenrothes', '826831', 'CLYDGB21831'],
    ['CLYDESDALE BANK PLC Newton Mearns Broomburn Dr', '826832', 'CLYDGB21832'],
    ['CLYDESDALE BANK PLC Customer Banking Cc 1', '826833', 'CLYDGB21833'],
    ['CLYDESDALE BANK PLC Customer Banking Cc 2', '826834', 'CLYDGB21834'],
    ['CLYDESDALE BANK PLC Customer Banking Cc 3', '826835', 'CLYDGB21835'],
    ['CLYDESDALE BANK PLC Customer Banking Cc 4', '826836', 'CLYDGB21836'],
    ['CLYDESDALE BANK PLC Direct Banking 5', '826837', 'CLYDGB21837'],
    ['CLYDESDALE BANK PLC Direct Banking 6', '826838', 'CLYDGB21838'],
    ['CLYDESDALE BANK PLC Direct Banking 7', '826839', 'CLYDGB21839'],
    ['CLYDESDALE BANK PLC Direct Banking 8', '826842', ''],
    ['CLYDESDALE BANK PLC Bishopbriggs', '826901', 'CLYDGB21901'],
    ['CLYDESDALE BANK PLC Glasgow India Street', '826902', 'CLYDGB21902'],
    ['CLYDESDALE BANK PLC East Kilbride Princes Sq', '826903', 'CLYDGB21903'],
    ['CLYDESDALE BANK PLC Hawick', '826904', 'CLYDGB21904'],
    ['CLYDESDALE BANK PLC Clarkston', '826905', 'CLYDGB21905'],
    ['CLYDESDALE BANK PLC Glasgow   Bearsden', '826907', 'CLYDGB21907'],
    ['CLYDESDALE BANK PLC Dreghorn', '826908', 'CLYDGB21908'],
    ['CLYDESDALE BANK PLC Cumbernauld  Centre', '826909', 'CLYDGB21909'],
    ['CLYDESDALE BANK PLC Glasgow Pollokshields', '826911', 'CLYDGB21911'],
    ['CLYDESDALE BANK PLC Kirkintilloch', '826913', 'CLYDGB21K93'],
    ['CLYDESDALE BANK PLC Aberdeen University', '826914', 'CLYDGB21914'],
    [
      'CLYDESDALE BANK PLC Direct Banking Mrtgages B2b',
      '826916',
      'CLYDGB21916'
    ],
    ['CLYDESDALE BANK PLC Auchterarder', '826917', 'CLYDGB21917'],
    ['CLYDESDALE BANK PLC Payment Operations', '826918', 'CLYDGB2SXXX'],
    ['CLYDESDALE BANK PLC Edinburgh Corstorphine', '826919', 'CLYDGB21919'],
    ['CLYDESDALE BANK PLC Paisley Gauze Street', '826920', 'CLYDGB21920'],
    ['CLYDESDALE BANK PLC Kilmacolm', '826921', 'CLYDGB21921'],
    ['CLYDESDALE BANK PLC Glasgow Mearns Cross', '826922', 'CLYDGB21922'],
    ['CLYDESDALE BANK PLC Aberdeen Bridge of Don', '826923', 'CLYDGB21923'],
    ['CLYDESDALE BANK PLC Stornoway', '826925', 'CLYDGB21925'],
    ['CLYDESDALE BANK PLC Fort William', '826926', 'CLYDGB21926'],
    ['CLYDESDALE BANK PLC Aberdeen Tullos', '826927', 'CLYDGB21927'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Broker', '826928', 'CLYDGB21928'],
    ['CLYDESDALE BANK PLC Strathaven', '826931', 'CLYDGB21931'],
    ['CLYDESDALE BANK PLC Milngavie', '826932', 'CLYDGB21932'],
    ['CLYDESDALE BANK PLC Edinburgh University', '826933', 'CLYDGB21933'],
    ['CLYDESDALE BANK PLC Livingston Centre', '826934', 'CLYDGB21934'],
    ['CLYDESDALE BANK PLC Linlithgow', '827000', 'CLYDGB21150'],
    ['CLYDESDALE BANK PLC Renfrew', '827001', 'CLYDGB21151'],
    ['CLYDESDALE BANK PLC Douglas Isle of Man', '827002', 'CLYDIMD1XXX'],
    ['CLYDESDALE BANK PLC Hamilton Almada Street', '827003', 'CLYDGB21153'],
    ['CLYDESDALE BANK PLC Girvan', '827004', 'CLYDGB21154'],
    ['CLYDESDALE BANK PLC Whitburn', '827005', 'CLYDGB21155'],
    ['CLYDESDALE BANK PLC Clearing Default', '827007', ''],
    ['CLYDESDALE BANK PLC Musselburgh', '827009', 'CLYDGB21159'],
    ['CLYDESDALE BANK PLC University of Glasgow', '827012', 'CLYDGB21162'],
    ['CLYDESDALE BANK PLC Longman Inverness', '827013', 'CLYDGB21163'],
    ['CLYDESDALE BANK PLC Aberdeen Holburn', '827015', 'CLYDGB21165'],
    ['CLYDESDALE BANK PLC Edinburgh Davidsons Mains', '827016', 'CLYDGB21166'],
    [
      'CLYDESDALE BANK PLC Direct Banking Online Ac Cb',
      '827018',
      'CLYDGB21018'
    ],
    ['CLYDESDALE BANK PLC Customer Delivery Glasgow', '827019', 'CLYDGB21019'],
    ['CLYDESDALE BANK PLC Annan', '827020', 'CLYDGB21170'],
    ['CLYDESDALE BANK PLC Haddington', '827021', 'CLYDGB21171'],
    ['CLYDESDALE BANK PLC Croydon', '827022', 'CLYDGB21152'],
    ['CLYDESDALE BANK PLC Clydesdale Bank Bft 44', '827024', 'CLYDGB21S19'],
    ['CLYDESDALE BANK PLC Treasury Operations Cb100', '828100', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb01', '828101', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb02', '828102', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb03', '828103', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb04', '828104', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb05', '828105', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb06', '828106', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb07', '828107', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb08', '828108', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb09', '828109', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb10', '828110', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb11', '828111', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb12', '828112', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb13', '828113', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb14', '828114', ''],
    ['CLYDESDALE BANK PLC Treasury Operations Cb15', '828115', ''],
    ['CLYDESDALE BANK PLC Chaps Repair   Chaps Only', '829191', 'CLYDGBCHXXX'],
    ['CLYDESDALE BANK PLC Treasury Setts Int Ops Glas', '829207', ''],
    ['CLYDESDALE BANK PLC Cly Business Card Services', '829829', ''],
    ['CLYDESDALE BANK PLC Vaultex', '829903', 'CLYDGB21999'],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office', '830001', 'RBOSGB2102K'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC H.O. Staff Lending Unit',
      '830020',
      'RBOSGB2102L'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Scotland Corporate Svc Ctr',
      '830031',
      'RBOSGB2102M'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC H/O West Register',
      '830033',
      'RBOSGB2106V'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Treasury Reserve Centre',
      '830036',
      'RBOSGB2108M'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Cust Serv Ctr',
      '830040',
      'RBOSGB2102Y'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow City Branch (F)',
      '830050',
      'RBOSGB2103N'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Cus Svc Ctr (Tpf)',
      '830060',
      'RBOSGB2105E'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Group Tax -Rbs plc',
      '830075',
      'RBOSGB2107C'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ees Corporate Trustees Ltd',
      '830080',
      'RBOSGB2111T'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ees Corporate Trustees Ltd', '830081', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ees Trustees Ltd', '830082', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ees Trustees Ltd', '830083', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Group Central Items',
      '830090',
      'RBOSGB2106P'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 29A(Wales)', '830110', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Staff Lending Unit',
      '830120',
      'RBOSGB2106Q'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Royal Bank Intermediary Lns',
      '830220',
      'RBOSGB2102P'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Debt Management Operations',
      '830221',
      'RBOSGB2102Q'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ryl Bk Intermed Loans C&I',
      '830223',
      'RBOSGB2108F'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Card Centre',
      '830225',
      'RBOSGB2102R'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Fixed Rate Bonds (Rw)',
      '830271',
      'RBOSGB2111A'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Bad Debt & Rec (Rbs Wales)',
      '830284',
      'RBOSGB2111C'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Bm Support, Brighton (Rbs)',
      '830287',
      'RBOSGB2111Q'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 10', '830310', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 11', '830311', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 12', '830312', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 13', '830313', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 14', '830314', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 15', '830315', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 16', '830316', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 76 Cao', '830317', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 85 Cao', '830318', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 20', '830320', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 21', '830321', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 22', '830322', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 23', '830323', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 24', '830324', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 25', '830325', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 26', '830326', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 27', '830327', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 28', '830328', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 29', '830329', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 30', '830330', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 31', '830331', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 32', '830332', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 10A', '830334', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 20A', '830335', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 21A', '830336', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 22A', '830337', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 29A', '830338', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 32A', '830339', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 40', '830340', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 41', '830341', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 42', '830342', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 43', '830343', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 44', '830344', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 45', '830345', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 46', '830346', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 47', '830347', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 48', '830348', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 49', '830349', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 50', '830350', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 51', '830351', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 52', '830352', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 53', '830353', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 54', '830354', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 55', '830355', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 56', '830356', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 57', '830357', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 58', '830358', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 59', '830359', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 60', '830360', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 61', '830361', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 62', '830362', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 63', '830363', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 64', '830364', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 65', '830365', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 66', '830366', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 67', '830367', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 68', '830368', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 69', '830369', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 70',
      '830370',
      'RBOSGB2104T'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 71',
      '830371',
      'RBOSGB2104U'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 72', '830372', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 73', '830373', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 74', '830374', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 75', '830375', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 76', '830376', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking 1 Loans', '830378', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking 2 Loans', '830379', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 80', '830380', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Direct Banking 3 Loans', '830381', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 40A', '830382', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 41A', '830383', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 76A', '830384', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 85', '830385', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 86', '830386', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 85A', '830387', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 85A', '830388', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 90A', '830389', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 90', '830390', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 10 Cao', '830391', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 95',
      '830395',
      'RBOSGB2104V'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 96',
      '830396',
      'RBOSGB2104W'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 97', '830397', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc 98', '830398', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 85 Cao (R)', '830400', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Direct Saver (R)',
      '830401',
      'RBOSGB2109L'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Cent Corp Banking (R)',
      '830402',
      'RBOSGB2109M'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 10 (R)', '830403', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 76 (R)', '830406', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 20 A (R)', '830407', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 21 (R)', '830409', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 10A (R)', '830411', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 40 A (R)', '830412', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 20 (R)', '830413', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 76 A (R)', '830414', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Business Deposits (R)',
      '830415',
      'RBOSGB2109N'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Direct Saver Plus (R)',
      '830417',
      'RBOSGB2109P'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Direct Banking (R)',
      '830418',
      'RBOSGB2190Q'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Fixed Rate Bonds',
      '830420',
      'RBOSGB2102T'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Tessa Sect Premium A/C Cntr',
      '830421',
      ''
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 85 A (R)', '830422', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Direct Business Banking',
      '830425',
      'RBOSGB2105G'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Personal Loan (R)', '830428', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 21 A (R)', '830429', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 90 (R)', '830436', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 10 Cao (R)', '830437', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Savings Direct (R)',
      '830438',
      'RBOSGB2109R'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 21 (Rw)', '830440', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Fixed Rate Bonds (R)',
      '830441',
      'RBOSGB2109K'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 85 Cao (Rw)', '830442', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 85A (Rw)', '830443', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 10 (Rw)', '830445', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 76 (Rw)', '830448', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 20A (Rw)', '830449', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 40A (Rw)', '830454', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 20 (Rw)', '830455', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 22 (R)', '830457', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 76A (Rw)', '830461', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 76 Cao (R)', '830462', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 10 Cao (Rw)', '830463', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 10A (Rw)', '830474', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Personal Loans (Rw)', '830475', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 21A (Rw)', '830476', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Direct Banking Central A/C',
      '830479',
      'RBOSGB2105N'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Home Improvmnt Loan (R)',
      '830483',
      ''
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 29A (R)', '830499', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Mortgage Centre', '830500', 'RBOSGB2102Z'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dale House', '830502', 'RBOSGB2102V'],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Home Improve Loan (Rw)', '830529', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 22 (Rw)', '830534', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Current A/C Mortgage Unit',
      '830550',
      'RBOSGB2105K'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 76 Cao (Rw)', '830576', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Loans Team 90 (Rw)', '830581', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh St Andrew Square',
      '830608',
      'RBOSGB21552'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Direct Banking (Rw)',
      '830614',
      'RBOSGB2110Y'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow City Branch',
      '830706',
      'RBOSGB21426'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '830802', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Computershare Inv Serv plc',
      '830804',
      'RBOSGB2102U'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Csc', '830805', 'RBOSGB2106B'],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '830813', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Branch Collection Account', '830823', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '830826', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '830833', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '830837', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '830842', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Style Promotional Cheques', '830850', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow International Div',
      '831400',
      'RBOSGB2SXXX'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Trustee & Depositary Serv', '831420', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Scotland Cash & Coin Centre',
      '831501',
      'RBOSGB2106S'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Cash Centre', '831502', 'RBOSGB21422'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dunfermline (E)', '831508', 'RBOSGB21369'],
    ['THE ROYAL BANK OF SCOTLAND PLC Blairgowrie (D)', '831509', 'RBOSGB21368'],
    ['THE ROYAL BANK OF SCOTLAND PLC Lanark (A)', '831510', 'RBOSGB21376'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Coatbridge Main Street (A)',
      '831511',
      'RBOSGB21382'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Dumbarton (A)', '831513', 'RBOSGB21380'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Aberdeen Queens Cross (D)',
      '831514',
      'RBOSGB21379'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Stirling (D)', '831515', 'RBOSGB21378'],
    ['THE ROYAL BANK OF SCOTLAND PLC Alness', '831517', 'RBOSGB21377'],
    ['THE ROYAL BANK OF SCOTLAND PLC Stirling (E)', '831518', 'RBOSGB21357'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dumfries High Street (E)',
      '831519',
      'RBOSGB21355'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC St Andrews (B)', '831521', 'RBOSGB21354'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Arbroath Brothock Bridge',
      '831522',
      'RBOSGB21353'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Bathgate (A)', '831524', 'RBOSGB21352'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ayr Chief Office',
      '831526',
      'RBOSGB21365'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Haddington (C)', '831528', 'RBOSGB21364'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Aberdeen Queens Cross',
      '831531',
      'RBOSGB21371'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Blairgowrie (B)', '831535', 'RBOSGB21356'],
    ['THE ROYAL BANK OF SCOTLAND PLC Fraserburgh (A)', '831602', 'RBOSGB21360'],
    ['THE ROYAL BANK OF SCOTLAND PLC Stirling (F)', '831603', 'RBOSGB21385'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Paisley, Chief Office (A)',
      '831604',
      'RBOSGB21409'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Bathgate', '831605', 'RBOSGB21403'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dingwall (A)', '831607', 'RBOSGB21411'],
    ['THE ROYAL BANK OF SCOTLAND PLC Motherwell (A)', '831608', 'RBOSGB21418'],
    ['THE ROYAL BANK OF SCOTLAND PLC Biggar', '831611', 'RBOSGB21415'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Kirkintilloch (B)',
      '831613',
      'RBOSGB21413'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC East Kilbride (A)',
      '831615',
      'RBOSGB21387'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Falkirk (A)', '831616', 'RBOSGB21401'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dalkeith (D)', '831617', 'RBOSGB21400'],
    ['THE ROYAL BANK OF SCOTLAND PLC Arbroath (B)', '831619', 'RBOSGB21395'],
    ['THE ROYAL BANK OF SCOTLAND PLC Bowmore Islay', '831620', 'RBOSGB21398'],
    ['THE ROYAL BANK OF SCOTLAND PLC Newbridge (A)', '831621', 'RBOSGB21300'],
    ['THE ROYAL BANK OF SCOTLAND PLC Kirkcaldy (A)', '831623', 'RBOSGB21306'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow, Rutherglen Wst (A)',
      '831625',
      'RBOSGB21449'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Oban (A)', '831627', 'RBOSGB21288'],
    ['THE ROYAL BANK OF SCOTLAND PLC Wishaw (B)', '831628', 'RBOSGB21289'],
    ['THE ROYAL BANK OF SCOTLAND PLC Blairgowrie', '831633', 'RBOSGB21388'],
    ['THE ROYAL BANK OF SCOTLAND PLC Falkirk (D)', '831634', 'RBOSGB21386'],
    ['THE ROYAL BANK OF SCOTLAND PLC Brodick', '831637', 'RBOSGB21303'],
    ['THE ROYAL BANK OF SCOTLAND PLC Elgin (B)', '831640', 'RBOSGB21299'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Stirling Pitt Terrace (C )',
      '831641',
      'RBOSGB21308'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Kirkcaldy (B)', '831644', 'RBOSGB21286'],
    ['THE ROYAL BANK OF SCOTLAND PLC Kilmarnock (D)', '831646', 'RBOSGB21293'],
    ['THE ROYAL BANK OF SCOTLAND PLC Peterhead (A)', '831647', 'RBOSGB2108T'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Inverness Chief Office (A)',
      '831648',
      'RBOSGB2108X'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dundee Broughty Ferry (A)',
      '831701',
      'RBOSGB21297'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Lanark (B)', '831702', 'RBOSGB21296'],
    ['THE ROYAL BANK OF SCOTLAND PLC Castlebay', '831703', 'RBOSGB21294'],
    ['THE ROYAL BANK OF SCOTLAND PLC Castle Douglas', '831705', 'RBOSGB21295'],
    ['THE ROYAL BANK OF SCOTLAND PLC Wishaw (A)', '831709', 'RBOSGB21335'],
    ['THE ROYAL BANK OF SCOTLAND PLC Clydebank', '831710', 'RBOSGB21333'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Coatbridge Main Street',
      '831713',
      'RBOSGB21341'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Perth Chief Office (A)',
      '831715',
      'RBOSGB21347'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Blairgowrie A', '831716', 'RBOSGB21344'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dunfermline (A)', '831717', 'RBOSGB21342'],
    ['THE ROYAL BANK OF SCOTLAND PLC St Andrews (A)', '831719', 'RBOSGB21323'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Perth Chief Office (B)',
      '831720',
      'RBOSGB21321'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Kilmarnock (E)', '831721', 'RBOSGB21317'],
    ['THE ROYAL BANK OF SCOTLAND PLC Glenrothes (A)', '831723', 'RBOSGB21316'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Castle Douglas (A)',
      '831724',
      'RBOSGB21324'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Dalkeith', '831726', 'RBOSGB21331'],
    ['THE ROYAL BANK OF SCOTLAND PLC Irvine (C)', '831727', 'RBOSGB21329'],
    ['THE ROYAL BANK OF SCOTLAND PLC Falkirk (B)', '831728', 'RBOSGB21420'],
    ['THE ROYAL BANK OF SCOTLAND PLC Cumbernauld', '831731', 'RBOSGB21318'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ayr, Chief Office (B)',
      '831736',
      'RBOSGB21330'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Milngavie (A)', '831739', 'RBOSGB21516'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Centralised Remote Atm Unit',
      '831790',
      'RBOSGB2109H'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Centralised Remote Atm Unt',
      '831791',
      'RBOSGB2106U'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Dingwall', '831801', 'RBOSGB21512'],
    ['THE ROYAL BANK OF SCOTLAND PLC Lanark (E)', '831802', 'RBOSGB21509'],
    ['THE ROYAL BANK OF SCOTLAND PLC Inverurie (C)', '831803', 'RBOSGB21523'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dumbarton', '831805', 'RBOSGB21522'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dumfries High Street',
      '831806',
      'RBOSGB21521'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dumfries, High Street (A)',
      '831807',
      'RBOSGB21520'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Haddington (A)', '831808', 'RBOSGB21519'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Stirling Pitt Terrace (B)',
      '831809',
      'RBOSGB21518'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dundee Kingsway Circus',
      '831812',
      'RBOSGB21496'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dundee Chief Office(E)',
      '831814',
      'RBOSGB21493'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Dunoon', '831817', 'RBOSGB21499'],
    ['THE ROYAL BANK OF SCOTLAND PLC Galashiels (E)', '831820', 'RBOSGB21505'],
    ['THE ROYAL BANK OF SCOTLAND PLC Kirkcaldy (D)', '831822', 'RBOSGB21503'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Corstorphine (E)',
      '831823',
      'RBOSGB21582'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh,St. Andrew Sq (C)',
      '831824',
      'RBOSGB21525'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Bruntsfield',
      '831825',
      'RBOSGB21550'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Corstorphine (F)',
      '831828',
      'RBOSGB21548'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dundee Chief Office (C)',
      '831832',
      'RBOSGB21494'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dundee Broughty Ferry',
      '831833',
      'RBOSGB21498'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dundee Chief Office (A)',
      '831834',
      'RBOSGB21495'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dundee Chief Office B',
      '831838',
      'RBOSGB21492'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Kelso (A)', '831840', 'RBOSGB21507'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Bruntsfield (B)',
      '831843',
      'RBOSGB21583'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, West End (C)',
      '831844',
      'RBOSGB21549'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Corstorphine (A)',
      '831846',
      'RBOSGB21584'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Corstorphine (D)',
      '831847',
      'RBOSGB21544'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Corstorphine (C)',
      '831901',
      'RBOSGB21547'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Corstorphine',
      '831904',
      'RBOSGB21545'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Bruntsfield (C)',
      '831908',
      'RBOSGB21543'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Nicolson St (B)',
      '831909',
      'RBOSGB21531'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Stockbridge (A)',
      '831910',
      'RBOSGB21559'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Corstorphine (G)',
      '831911',
      'RBOSGB21585'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Corstorphine (B)',
      '831915',
      'RBOSGB21558'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Bruntsfield (A)',
      '831916',
      'RBOSGB21546'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Nicolson Street',
      '831919',
      'RBOSGB21556'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh West End (A)',
      '831920',
      'RBOSGB21586'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Musselburgh (A)', '831923', 'RBOSGB21554'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Gogarburn',
      '831924',
      'RBOSGB2108H'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Private Bank Edinburgh',
      '831928',
      'RBOSGB2107M'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, Corstorphine (H)',
      '831929',
      'RBOSGB21553'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow, Parkhead (B)',
      '831934',
      'RBOSGB21452'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Dalkeith (C)', '831936', 'RBOSGB21551'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh St Andrew Sq (A)',
      '831942',
      'RBOSGB21587'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Direct Saver Plus (Rw)',
      '831966',
      'RBOSGB2110X'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Direct Saver (Rf)',
      '831971',
      'RBOSGB2111F'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Direct Saver (Rw)',
      '831992',
      'RBOSGB2110U'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Direct Saver',
      '832000',
      'RBOSGB2104X'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Stockbridge',
      '832002',
      'RBOSGB21533'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh, West End (B)',
      '832003',
      'RBOSGB21532'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Elgin', '832006', 'RBOSGB21529'],
    ['THE ROYAL BANK OF SCOTLAND PLC Forfar', '832013', 'RBOSGB21537'],
    ['THE ROYAL BANK OF SCOTLAND PLC Elgin (C)', '832014', 'RBOSGB21535'],
    ['THE ROYAL BANK OF SCOTLAND PLC Lanark (C)', '832015', 'RBOSGB21445'],
    ['THE ROYAL BANK OF SCOTLAND PLC Fort William', '832016', 'RBOSGB21490'],
    ['THE ROYAL BANK OF SCOTLAND PLC Fraserburgh', '832017', 'RBOSGB21444'],
    ['THE ROYAL BANK OF SCOTLAND PLC Galashiels', '832019', 'RBOSGB21442'],
    ['THE ROYAL BANK OF SCOTLAND PLC Giffnock', '832022', 'RBOSGB21440'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ayr Chief Office (C)',
      '832023',
      'RBOSGB21439'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow City Branch (C)',
      '832027',
      'RBOSGB21454'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Haddington (B)', '832030', 'RBOSGB21534'],
    ['THE ROYAL BANK OF SCOTLAND PLC Falkirk', '832032', 'RBOSGB21542'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Byres Road (D)',
      '832104',
      'RBOSGB21588'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Byres Road',
      '832105',
      'RBOSGB21450'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Cardonald',
      '832106',
      'RBOSGB21448'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Giffnock (A)', '832107', 'RBOSGB21447'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Charing Cross West',
      '832108',
      'RBOSGB21427'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow City Branch (D)',
      '832110',
      'RBOSGB21425'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Retain Branch (Mo A)',
      '832114',
      'RBOSGB2110F'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Cardonald (B)',
      '832116',
      'RBOSGB21423'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow, Cardonald (A)',
      '832126',
      'RBOSGB21429'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Parkhead', '832127', 'RBOSGB21437'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow, Byres Road (B)',
      '832128',
      'RBOSGB21436'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Byres Road (C)',
      '832133',
      'RBOSGB21446'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow, Dowanhill',
      '832137',
      'RBOSGB21424'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Kirkintilloch (D)',
      '832142',
      'RBOSGB21421'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow City Branch (E)',
      '832146',
      'RBOSGB21589'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Rbs Direct Loans', '832200', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Travel Money Services', '832204', ''],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Global Travel Money Srvs',
      '832205',
      'RBOSGB21601'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow, Shawlands',
      '832210',
      'RBOSGB21431'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Inverness Chief Office (B)',
      '832219',
      'RBOSGB21476'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Greenock Cathcart Street',
      '832220',
      'RBOSGB21474'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Haddington', '832225', 'RBOSGB21488'],
    ['THE ROYAL BANK OF SCOTLAND PLC Hamilton (D)', '832226', 'RBOSGB21485'],
    ['THE ROYAL BANK OF SCOTLAND PLC Hamilton', '832227', 'RBOSGB21484'],
    ['THE ROYAL BANK OF SCOTLAND PLC Bathgate (D)', '832228', 'RBOSGB21461'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Kirkintilloch (C)',
      '832229',
      'RBOSGB21435'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow, Parkhead (C)',
      '832230',
      'RBOSGB21438'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow, Parkhead (A)',
      '832231',
      'RBOSGB21434'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Glenrothes', '832237', 'RBOSGB21480'],
    ['THE ROYAL BANK OF SCOTLAND PLC Falkirk (C)', '832239', 'RBOSGB21477'],
    ['THE ROYAL BANK OF SCOTLAND PLC Galashiels (C)', '832301', 'RBOSGB21459'],
    ['THE ROYAL BANK OF SCOTLAND PLC Helensburgh', '832303', 'RBOSGB21456'],
    ['THE ROYAL BANK OF SCOTLAND PLC Inverurie (A)', '832305', 'RBOSGB21465'],
    ['THE ROYAL BANK OF SCOTLAND PLC Oban (B)', '832307', 'RBOSGB21093'],
    ['THE ROYAL BANK OF SCOTLAND PLC Alness (A)', '832308', 'RBOSGB21092'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Inverness Chief Office',
      '832310',
      'RBOSGB21091'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Inverurie', '832312', 'RBOSGB21090'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Irvine High Street',
      '832313',
      'RBOSGB21088'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Kelso (B)', '832314', 'RBOSGB21096'],
    ['THE ROYAL BANK OF SCOTLAND PLC Johnstone', '832316', 'RBOSGB21104'],
    ['THE ROYAL BANK OF SCOTLAND PLC Inverurie (B)', '832317', 'RBOSGB21103'],
    ['THE ROYAL BANK OF SCOTLAND PLC Kelso', '832318', 'RBOSGB21102'],
    ['THE ROYAL BANK OF SCOTLAND PLC Johnstone (B)', '832319', 'RBOSGB21098'],
    ['THE ROYAL BANK OF SCOTLAND PLC Johnstone (A)', '832320', 'RBOSGB21097'],
    ['THE ROYAL BANK OF SCOTLAND PLC Cumbernauld (A)', '832323', 'RBOSGB21077'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Kirkcaldy Central',
      '832325',
      'RBOSGB21073'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Dunfermline (B)', '832339', 'RBOSGB21101'],
    ['THE ROYAL BANK OF SCOTLAND PLC Irvine (B)', '832344', 'RBOSGB21076'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dunfermline (F)', '832347', 'RBOSGB21074'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Castle Douglas (B)',
      '832402',
      'RBOSGB21072'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Kirkintilloch 116 Cowgate',
      '832405',
      'RBOSGB21079'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Kirkwall', '832407', 'RBOSGB21087'],
    ['THE ROYAL BANK OF SCOTLAND PLC Forfar (A)', '832408', 'RBOSGB21086'],
    ['THE ROYAL BANK OF SCOTLAND PLC Portree (A)', '832409', 'RBOSGB21084'],
    ['THE ROYAL BANK OF SCOTLAND PLC Glenrothes (B)', '832410', 'RBOSGB21083'],
    ['THE ROYAL BANK OF SCOTLAND PLC Lanark', '832412', 'RBOSGB21082'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dumfries High Street (B)',
      '832413',
      'RBOSGB21080'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Largs', '832415', 'RBOSGB21105'],
    ['THE ROYAL BANK OF SCOTLAND PLC Arbroath (C)', '832417', 'RBOSGB21129'],
    ['THE ROYAL BANK OF SCOTLAND PLC Edinburgh Leith', '832418', 'RBOSGB21557'],
    ['THE ROYAL BANK OF SCOTLAND PLC Lerwick', '832422', 'RBOSGB21139'],
    ['THE ROYAL BANK OF SCOTLAND PLC Kirkcaldy (C)', '832424', 'RBOSGB21137'],
    ['THE ROYAL BANK OF SCOTLAND PLC Bathgate (F)', '832425', 'RBOSGB21134'],
    ['THE ROYAL BANK OF SCOTLAND PLC Lochboisdale', '832426', 'RBOSGB21112'],
    ['THE ROYAL BANK OF SCOTLAND PLC Ullapool (A)', '832428', 'RBOSGB21110'],
    ['THE ROYAL BANK OF SCOTLAND PLC Hamilton (A)', '832436', 'RBOSGB21130'],
    ['THE ROYAL BANK OF SCOTLAND PLC Lanark (D)', '832438', 'RBOSGB21138'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dumfries High Street (D)',
      '832501',
      'RBOSGB21109'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dumfries High Street (C)',
      '832503',
      'RBOSGB21107'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Elgin (A)', '832504', 'RBOSGB21006'],
    ['THE ROYAL BANK OF SCOTLAND PLC Thurso (B)', '832506', 'RBOSGB21003'],
    ['THE ROYAL BANK OF SCOTLAND PLC Kilmarnock (C)', '832509', 'RBOSGB21064'],
    ['THE ROYAL BANK OF SCOTLAND PLC Maybole', '832511', 'RBOSGB21063'],
    ['THE ROYAL BANK OF SCOTLAND PLC Galashiels (D)', '832512', 'RBOSGB21043'],
    ['THE ROYAL BANK OF SCOTLAND PLC Milngavie', '832515', 'RBOSGB21040'],
    ['THE ROYAL BANK OF SCOTLAND PLC Arbroath (A)', '832516', 'RBOSGB21038'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dingwall (B)', '832520', 'RBOSGB21044'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Inverness Chief Office (C)',
      '832523',
      'RBOSGB21051'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Giffnock (B)', '832524', 'RBOSGB21049'],
    ['THE ROYAL BANK OF SCOTLAND PLC Glenrothes (C)', '832525', 'RBOSGB21046'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dalkeith (B)', '832527', 'RBOSGB21231'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Musselburgh Bridge Street',
      '832543',
      'RBOSGB21052'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Motherwell The Cross',
      '832545',
      'RBOSGB21036'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Stranraer (B)', '832601', 'RBOSGB21232'],
    ['THE ROYAL BANK OF SCOTLAND PLC Haddington (D)', '832602', 'RBOSGB21229'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Oban George Street',
      '832604',
      'RBOSGB21242'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Peebles', '832609', 'RBOSGB21216'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Bruntsfield (D)',
      '832610',
      'RBOSGB21214'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Peterhead', '832616', 'RBOSGB21219'],
    ['THE ROYAL BANK OF SCOTLAND PLC Blairgowrie (C)', '832617', 'RBOSGB21227'],
    ['THE ROYAL BANK OF SCOTLAND PLC Portree', '832619', 'RBOSGB21224'],
    ['THE ROYAL BANK OF SCOTLAND PLC Tranent (A)', '832620', 'RBOSGB21268'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Paisley Chief Office (B)',
      '832622',
      'RBOSGB21263'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Rutherglen West', '832624', 'RBOSGB21433'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rutherglen West (B)',
      '832626',
      'RBOSGB21451'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC St Andrews South Street',
      '832628',
      'RBOSGB21159'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Kilmarnock Central (A)',
      '832632',
      'RBOSGB21234'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Galashiels (A)', '832634', 'RBOSGB21230'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Ayr Chief Office (D)',
      '832643',
      'RBOSGB21267'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Greenock Cathcart Street(A)',
      '832644',
      'RBOSGB21275'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Kilmarnock (F)', '832648', 'RBOSGB21249'],
    ['THE ROYAL BANK OF SCOTLAND PLC Irvine (D)', '832702', 'RBOSGB21251'],
    ['THE ROYAL BANK OF SCOTLAND PLC Scarinish', '832703', 'RBOSGB21247'],
    ['THE ROYAL BANK OF SCOTLAND PLC Galashiels (B)', '832705', 'RBOSGB21254'],
    ['THE ROYAL BANK OF SCOTLAND PLC Bathgate (E)', '832707', 'RBOSGB21257'],
    ['THE ROYAL BANK OF SCOTLAND PLC Falkirk (E)', '832708', 'RBOSGB21172'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Stirling Pitt Terrace',
      '832709',
      'RBOSGB21168'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Aberdeen Queens Cross (B)',
      '832710',
      'RBOSGB21147'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Stornoway Isle of Lewis',
      '832712',
      'RBOSGB21145'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Stranraer', '832714', 'RBOSGB21144'],
    ['THE ROYAL BANK OF SCOTLAND PLC Kirkwall (A)', '832718', 'RBOSGB21142'],
    ['THE ROYAL BANK OF SCOTLAND PLC Thurso', '832723', 'RBOSGB21199'],
    ['THE ROYAL BANK OF SCOTLAND PLC Thurso (C)', '832724', 'RBOSGB21197'],
    ['THE ROYAL BANK OF SCOTLAND PLC Tranent', '832725', 'RBOSGB21195'],
    ['THE ROYAL BANK OF SCOTLAND PLC Irvine (A)', '832726', 'RBOSGB21194'],
    ['THE ROYAL BANK OF SCOTLAND PLC Inverurie (D)', '832728', 'RBOSGB21201'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Kirkintilloch (E)',
      '832730',
      'RBOSGB21171'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Kilmarnock (B)', '832731', 'RBOSGB21170'],
    ['THE ROYAL BANK OF SCOTLAND PLC Hamilton (C)', '832734', 'RBOSGB21143'],
    ['THE ROYAL BANK OF SCOTLAND PLC Alness(B)', '832735', 'RBOSGB21151'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dunoon (A)', '832736', 'RBOSGB21198'],
    ['THE ROYAL BANK OF SCOTLAND PLC Ullapool', '832801', 'RBOSGB21209'],
    ['THE ROYAL BANK OF SCOTLAND PLC Livingston (A)', '832803', 'RBOSGB21183'],
    ['THE ROYAL BANK OF SCOTLAND PLC Irvine (E)', '832804', 'RBOSGB21182'],
    ['THE ROYAL BANK OF SCOTLAND PLC Bathgate (B)', '832805', 'RBOSGB21180'],
    ['THE ROYAL BANK OF SCOTLAND PLC Stranraer (A)', '832806', 'RBOSGB21179'],
    ['THE ROYAL BANK OF SCOTLAND PLC Thurso (A)', '832807', 'RBOSGB21178'],
    ['THE ROYAL BANK OF SCOTLAND PLC Wishaw', '832808', 'RBOSGB21191'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dalkieth (A)', '832811', 'RBOSGB21591'],
    ['THE ROYAL BANK OF SCOTLAND PLC East Kilbride', '832813', 'RBOSGB21504'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Kirkintilloch (A)',
      '832820',
      'RBOSGB21131'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Bathgate (C)', '832821', 'RBOSGB21393'],
    ['THE ROYAL BANK OF SCOTLAND PLC Dunfermline (D)', '832829', 'RBOSGB21592'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dumfries High Street (F)',
      '832833',
      'RBOSGB21473'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Newbridge', '832837', 'RBOSGB21047'],
    ['THE ROYAL BANK OF SCOTLAND PLC Hamilton (B)', '832838', 'RBOSGB21146'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dundee Chief Office (D)',
      '832839',
      'RBOSGB21517'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Aberdeen Queens Cross (C)',
      '832840',
      'RBOSGB21181'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Aberdeen Queens Cross (E)',
      '832841',
      'RBOSGB21506'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Aberdeen St Nicholas (B)',
      '832842',
      'RBOSGB21372'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Livingston', '832845', 'RBOSGB21132'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Aberdeen Queens Cross (A)',
      '832846',
      'RBOSGB21361'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Dunfermline (C)', '832901', 'RBOSGB21332'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Aberdeen St Nicholas',
      '833000',
      'RBOSGB21370'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dunfermline Central',
      '833300',
      'RBOSGB21491'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh Nicolson St (A)',
      '833400',
      'RBOSGB21555'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow City Branch (B)',
      '834100',
      'RBOSGB21430'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Clearing Control Unit-Scot',
      '834200',
      'RBOSGB2105H'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow City Branch (A)',
      '834400',
      'RBOSGB21593'
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Kilmarnock', '834500', 'RBOSGB21078'],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Paisley Chief Office',
      '834600',
      'RBOSGB21237'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Perth Chief Office',
      '834700',
      'RBOSGB21212'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Stirling Pitt Terrace (A)',
      '834800',
      'RBOSGB21169'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Aberdeen, St Nicholas (A)',
      '834940',
      'RBOSGB21373'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Dundee Chief Office',
      '835000',
      'RBOSGB21497'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edinburgh West End',
      '835100',
      'RBOSGB21530'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Sauchiehall Street',
      '835200',
      'RBOSGB21432'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Sauchiehall St (A)',
      '835460',
      'RBOSGB21428'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Retail Support-Test Br Rbs',
      '836100',
      'RBOSGB2111Z'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Centrally Mngd A C',
      '836111',
      'RBOSGB2105L'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Post Office Counters',
      '836112',
      'RBOSGB2112C'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Rbs Retail Test Branch 2',
      '836116',
      'RBOSGB2112D'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Chaps Only Stg Mny Mkt-Edin',
      '837000',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Clg Cont Unit - Scot Euro',
      '837550',
      'RBOSGB21572'
    ],
    [
      'ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) 22 Charlotte Sq Edin Euro',
      '837552',
      'ADAGGB2SXXX'
    ],
    [
      'ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) London Euro',
      '837553',
      ''
    ],
    [
      'ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) Glasgow Euro',
      '837554',
      ''
    ],
    [
      'ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) Manchester Euro',
      '837555',
      ''
    ],
    [
      'ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) Aberdeen Euro',
      '837556',
      ''
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Int & Whsle Pyt Glw Euro',
      '837560',
      'RBOSGB21573'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Edin Srv Cent Euros',
      '837570',
      'RBOSGB21574'
    ],
    [
      'THE ROYAL BANK OF SCOTLAND PLC Glasgow Srv Cent Euros',
      '837580',
      'RBOSGB21575'
    ],
    ['AIB Group (UK) Plc Aib Group (UK) plc', '839105', ''],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Glasgow Business Centre',
      '839106',
      ''
    ],
    ['BANK OF IRELAND (UK) PLC Glasgow', '839112', ''],
    ['BANK OF IRELAND (UK) PLC Edinburgh Business Centre', '839121', ''],
    ['THE CO-OPERATIVE BANK PLC Glasgow', '839125', 'CPBKGB21GLA'],
    ['THE CO-OPERATIVE BANK PLC Edinburgh', '839126', 'CPBKGB21EDE'],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Edinburgh Business Centre',
      '839130',
      ''
    ],
    [
      'AIB GROUP (UK) PLC T/A ALLIED IRISH BANK (GB) Charing Cross Glasgow',
      '839131',
      ''
    ],
    [
      'ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) 22 Charlotte Sq Edinburgh',
      '839135',
      ''
    ],
    [
      'ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) London (Chaps Payments)',
      '839136',
      ''
    ],
    ['ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) Glasgow', '839137', ''],
    ['ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) London', '839138', ''],
    ['ADAM & COMPANY PLC (A TRADING NAME OF RBS PLC) Aberdeen', '839144', ''],
    ['CAF BANK LIMITED Caf Bank Ltd', '839146', ''],
    ['HANDELSBANKEN PLC London', '839154', ''],
    ['HAMPDEN & CO. PLC Hampden & Co plc', '839155', ''],
    ['CLYDEBANK MUNICIPAL BANK LIMITED District Council Offices', '839205', ''],
    [
      'NATIONWIDE BS T/A DUNFERMLINE BS Dunfermline B/S Type 1 A/Cs',
      '839206',
      ''
    ],
    [
      'NATIONWIDE BS T/A DUNFERMLINE BS Dunfermline B/S Other A/Cs',
      '839207',
      ''
    ],
    ['THE ROYAL BANK OF SCOTLAND PLC Style Convenience Chq Cntr', '839208', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '840651', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '840759', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Collection Account', '841069', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Branch Collection Account', '841556', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '841755', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Branch Collection Account', '842654', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '843451', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H O Collection Account', '843553', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Branch Collection Account', '843834', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection Acc', '843955', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '844855', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Branch Collection Account', '846551', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Branch Collection Account', '847558', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Ho Collection Account', '847559', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC H.O. Collection Account', '847655', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection Acc', '847854', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Branch Collection Account', '847937', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Head Office Collection A/C', '849659', ''],
    ['THE ROYAL BANK OF SCOTLAND PLC Branch Collection Account', '849832', ''],
    ['TSB BANK PLC TSB (872639)', '872639', ''],
    ['TSB BANK PLC TSB (872644)', '872644', ''],
    ['TSB BANK PLC Customer Services', '872645', ''],
    ['TSB BANK PLC Bops (872646)', '872646', ''],
    ['TSB BANK PLC Bops (872647)', '872647', ''],
    ['TSB BANK PLC Bops (872648)', '872648', ''],
    ['TSB BANK PLC Customer Services', '872649', ''],
    ['TSB BANK PLC Bops (872693)', '872693', ''],
    ['TSB BANK PLC Bops (872694)', '872694', ''],
    ['TSB BANK PLC Bops (872695)', '872695', ''],
    ['TSB BANK PLC Bops (872696)', '872696', ''],
    ['TSB BANK PLC Bops (872697)', '872697', ''],
    ['TSB BANK PLC TSB (872743)', '872743', ''],
    ['TSB BANK PLC TSB Treasury', '872749', ''],
    ['TSB BANK PLC Retail Lt (872773)', '872773', ''],
    ['TSB BANK PLC Cass', '872792', ''],
    ['TSB BANK PLC TSB (873400)', '873400', ''],
    ['TSB BANK PLC TSB St Nicholas Street', '873401', ''],
    ['TSB BANK PLC TSB St Nicholas Street', '873402', ''],
    ['TSB BANK PLC TSB St Nicholas Street', '873403', ''],
    ['TSB BANK PLC Aberdeen, Holburn Street', '873404', ''],
    ['TSB BANK PLC TSB St Nicholas Street', '873405', ''],
    ['TSB BANK PLC Aberdeen, Torry', '873406', ''],
    ['TSB BANK PLC Rosehill Aberdeen (873407)', '873407', ''],
    ['TSB BANK PLC Aberdeen, Holburn Street', '873408', ''],
    ['TSB BANK PLC TSB (873410)', '873410', ''],
    ['TSB BANK PLC TSB (873411)', '873411', ''],
    ['TSB BANK PLC TSB (873412)', '873412', ''],
    ['TSB BANK PLC TSB (873413)', '873413', ''],
    ['TSB BANK PLC TSB (873414)', '873414', ''],
    ['TSB BANK PLC TSB (873415)', '873415', ''],
    ['TSB BANK PLC TSB (873416)', '873416', ''],
    ['TSB BANK PLC TSB (873417)', '873417', ''],
    ['TSB BANK PLC TSB (873418)', '873418', ''],
    ['TSB BANK PLC TSB (873419)', '873419', ''],
    ['TSB BANK PLC TSB (873420)', '873420', ''],
    ['TSB BANK PLC TSB (873421)', '873421', ''],
    ['TSB BANK PLC TSB (873422)', '873422', ''],
    ['TSB BANK PLC TSB (873423)', '873423', ''],
    ['TSB BANK PLC TSB (873424)', '873424', ''],
    ['TSB BANK PLC TSB (873425)', '873425', ''],
    ['TSB BANK PLC TSB (873427)', '873427', ''],
    ['TSB BANK PLC TSB (873428)', '873428', ''],
    ['TSB BANK PLC TSB (873429)', '873429', ''],
    ['TSB BANK PLC TSB (873430)', '873430', ''],
    ['TSB BANK PLC TSB (873432)', '873432', ''],
    ['TSB BANK PLC TSB (873433)', '873433', ''],
    ['TSB BANK PLC TSB (873434)', '873434', ''],
    ['TSB BANK PLC TSB (873435)', '873435', ''],
    ['TSB BANK PLC TSB (873436)', '873436', ''],
    ['TSB BANK PLC TSB (873437)', '873437', ''],
    ['TSB BANK PLC TSB (873438)', '873438', ''],
    ['TSB BANK PLC TSB (873439)', '873439', ''],
    ['TSB BANK PLC TSB (873440)', '873440', ''],
    ['TSB BANK PLC TSB (873441)', '873441', ''],
    ['TSB BANK PLC TSB (873443)', '873443', ''],
    ['TSB BANK PLC TSB (873444)', '873444', ''],
    ['TSB BANK PLC TSB (873445)', '873445', ''],
    ['TSB BANK PLC TSB (873446)', '873446', ''],
    ['TSB BANK PLC TSB (873447)', '873447', ''],
    ['TSB BANK PLC TSB (873448)', '873448', ''],
    ['TSB BANK PLC TSB (873449)', '873449', ''],
    ['TSB BANK PLC TSB (873450)', '873450', ''],
    ['TSB BANK PLC TSB (873451)', '873451', ''],
    ['TSB BANK PLC TSB (873452)', '873452', ''],
    ['TSB BANK PLC TSB (873453)', '873453', ''],
    ['TSB BANK PLC TSB (873454)', '873454', ''],
    ['TSB BANK PLC TSB (873455)', '873455', ''],
    ['TSB BANK PLC TSB (873466)', '873466', ''],
    ['TSB BANK PLC TSB (873467)', '873467', ''],
    ['TSB BANK PLC TSB (873701)', '873701', ''],
    ['TSB BANK PLC TSB (873705)', '873705', ''],
    ['TSB BANK PLC TSB (873706)', '873706', ''],
    ['TSB BANK PLC TSB (873707)', '873707', ''],
    ['TSB BANK PLC TSB (873708)', '873708', ''],
    ['TSB BANK PLC TSB (873710)', '873710', ''],
    ['TSB BANK PLC TSB (873711)', '873711', ''],
    ['TSB BANK PLC TSB (873712)', '873712', ''],
    ['TSB BANK PLC TSB (873713)', '873713', ''],
    ['TSB BANK PLC TSB (873714)', '873714', ''],
    ['TSB BANK PLC TSB (873715)', '873715', ''],
    ['TSB BANK PLC TSB (873716)', '873716', ''],
    ['TSB BANK PLC TSB (873717)', '873717', ''],
    ['TSB BANK PLC TSB (873718)', '873718', ''],
    ['TSB BANK PLC TSB (873719)', '873719', ''],
    ['TSB BANK PLC TSB (873720)', '873720', ''],
    ['TSB BANK PLC TSB (873721)', '873721', ''],
    ['TSB BANK PLC TSB (873722)', '873722', ''],
    ['TSB BANK PLC TSB (873725)', '873725', ''],
    ['TSB BANK PLC TSB (873726)', '873726', ''],
    ['TSB BANK PLC TSB (873727)', '873727', ''],
    ['TSB BANK PLC TSB (873728)', '873728', ''],
    ['TSB BANK PLC TSB (873729)', '873729', ''],
    ['TSB BANK PLC TSB (873731)', '873731', ''],
    ['TSB BANK PLC TSB (873732)', '873732', ''],
    ['TSB BANK PLC TSB (873733)', '873733', ''],
    ['TSB BANK PLC TSB (873734)', '873734', ''],
    ['TSB BANK PLC TSB (873735)', '873735', ''],
    ['TSB BANK PLC TSB (873736)', '873736', ''],
    ['TSB BANK PLC TSB (873737)', '873737', ''],
    ['TSB BANK PLC TSB (873738)', '873738', ''],
    ['TSB BANK PLC TSB (873739)', '873739', ''],
    ['TSB BANK PLC TSB (873740)', '873740', ''],
    ['TSB BANK PLC TSB (873741)', '873741', ''],
    ['TSB BANK PLC TSB (873742)', '873742', ''],
    ['TSB BANK PLC TSB (873743)', '873743', ''],
    ['TSB BANK PLC TSB (873744)', '873744', ''],
    ['TSB BANK PLC TSB (873745)', '873745', ''],
    ['TSB BANK PLC TSB (873746)', '873746', ''],
    ['TSB BANK PLC TSB (873747)', '873747', ''],
    ['TSB BANK PLC TSB (873748)', '873748', ''],
    ['TSB BANK PLC TSB (873749)', '873749', ''],
    ['TSB BANK PLC TSB (873750)', '873750', ''],
    ['TSB BANK PLC TSB (873751)', '873751', ''],
    ['TSB BANK PLC TSB (873752)', '873752', ''],
    ['TSB BANK PLC TSB (873753)', '873753', ''],
    ['TSB BANK PLC TSB (873754)', '873754', ''],
    ['TSB BANK PLC TSB (873755)', '873755', ''],
    ['TSB BANK PLC TSB (873756)', '873756', ''],
    ['TSB BANK PLC TSB (873757)', '873757', ''],
    ['TSB BANK PLC TSB (873759)', '873759', ''],
    ['TSB BANK PLC TSB (873760)', '873760', ''],
    ['TSB BANK PLC TSB (873763)', '873763', ''],
    ['TSB BANK PLC TSB (873764)', '873764', ''],
    ['TSB BANK PLC TSB (873765)', '873765', ''],
    ['TSB BANK PLC TSB (873766)', '873766', ''],
    ['TSB BANK PLC TSB (873767)', '873767', ''],
    ['TSB BANK PLC TSB (873768)', '873768', ''],
    ['TSB BANK PLC TSB (873769)', '873769', ''],
    ['TSB BANK PLC TSB (873770)', '873770', ''],
    ['TSB BANK PLC TSB (873771)', '873771', ''],
    ['TSB BANK PLC TSB (873772)', '873772', ''],
    ['TSB BANK PLC TSB (873774)', '873774', ''],
    ['TSB BANK PLC TSB (873775)', '873775', ''],
    ['TSB BANK PLC TSB (873776)', '873776', ''],
    ['TSB BANK PLC TSB (873777)', '873777', ''],
    ['TSB BANK PLC Glasgow Mortgages', '873790', ''],
    ['TSB BANK PLC TSB (873799)', '873799', ''],
    ['TSB BANK PLC TSB (873901)', '873901', ''],
    ['TSB BANK PLC TSB (873902)', '873902', ''],
    ['TSB BANK PLC TSB (873903)', '873903', ''],
    ['TSB BANK PLC TSB (873904)', '873904', ''],
    ['TSB BANK PLC TSB (873905)', '873905', ''],
    ['TSB BANK PLC TSB (873906)', '873906', ''],
    ['TSB BANK PLC Inverness', '873907', ''],
    ['TSB BANK PLC TSB (873953)', '873953', ''],
    ['TSB BANK PLC B A B&S Ag09 (874016)', '874016', ''],
    ['TSB BANK PLC TSB (874017)', '874017', ''],
    ['TSB BANK PLC Test L&U (874018)', '874018', ''],
    ['TSB BANK PLC TSB (874020)', '874020', ''],
    ['TSB BANK PLC Npf Indems', '874234', ''],
    ['TSB BANK PLC TSB (874301)', '874301', ''],
    ['TSB BANK PLC TSB (874302)', '874302', ''],
    ['TSB BANK PLC TSB (874303)', '874303', ''],
    ['TSB BANK PLC TSB (874304)', '874304', ''],
    ['TSB BANK PLC TSB (874305)', '874305', ''],
    ['TSB BANK PLC TSB (874306)', '874306', ''],
    ['TSB BANK PLC TSB (874307)', '874307', ''],
    ['TSB BANK PLC TSB (874308)', '874308', ''],
    ['TSB BANK PLC TSB (874309)', '874309', ''],
    ['TSB BANK PLC TSB (874310)', '874310', ''],
    ['TSB BANK PLC TSB (874311)', '874311', ''],
    ['TSB BANK PLC TSB (874312)', '874312', ''],
    ['TSB BANK PLC TSB (874313)', '874313', ''],
    ['TSB BANK PLC TSB (874316)', '874316', ''],
    ['TSB BANK PLC TSB (874350)', '874350', ''],
    ['TSB BANK PLC TSB (874351)', '874351', ''],
    ['TSB BANK PLC TSB (874356)', '874356', ''],
    ['TSB BANK PLC TSB (874358)', '874358', ''],
    ['TSB BANK PLC TSB (874360)', '874360', ''],
    ['TSB BANK PLC TSB (874362)', '874362', ''],
    ['TSB BANK PLC TSB (874401)', '874401', ''],
    ['TSB BANK PLC TSB (874404)', '874404', ''],
    ['TSB BANK PLC TSB (874405)', '874405', ''],
    ['TSB BANK PLC TSB (874406)', '874406', ''],
    ['TSB BANK PLC TSB (874407)', '874407', ''],
    ['TSB BANK PLC TSB (874408)', '874408', ''],
    ['TSB BANK PLC TSB (874409)', '874409', ''],
    ['TSB BANK PLC TSB (874411)', '874411', ''],
    ['TSB BANK PLC TSB (874414)', '874414', ''],
    ['TSB BANK PLC TSB (874415)', '874415', ''],
    ['TSB BANK PLC TSB (874431)', '874431', ''],
    ['TSB BANK PLC Pca', '874644', ''],
    ['TSB BANK PLC Community Bank Cs', '874645', ''],
    ['TSB BANK PLC TSB (876401)', '876401', ''],
    ['TSB BANK PLC TSB (876402)', '876402', ''],
    ['TSB BANK PLC TSB (876404)', '876404', ''],
    ['TSB BANK PLC TSB (876405)', '876405', ''],
    ['TSB BANK PLC TSB (876406)', '876406', ''],
    ['TSB BANK PLC Grangemouth', '876408', ''],
    ['TSB BANK PLC TSB (876488)', '876488', ''],
    ['TSB BANK PLC TSB (876489)', '876489', ''],
    ['TSB BANK PLC TSB (876491)', '876491', ''],
    ['TSB BANK PLC TSB (876801)', '876801', ''],
    ['TSB BANK PLC TSB(876804)', '876804', ''],
    ['TSB BANK PLC TSB (876807)', '876807', ''],
    ['TSB BANK PLC TSB (876808)', '876808', ''],
    ['TSB BANK PLC TSB (876810)', '876810', ''],
    ['TSB BANK PLC TSB (876811)', '876811', ''],
    ['TSB BANK PLC TSB (876812)', '876812', ''],
    ['TSB BANK PLC TSB (876814)', '876814', ''],
    ['TSB BANK PLC TSB (876816)', '876816', ''],
    ['TSB BANK PLC TSB (876818)', '876818', ''],
    ['TSB BANK PLC TSB (876819)', '876819', ''],
    ['TSB BANK PLC TSB (876820)', '876820', ''],
    ['TSB BANK PLC TSB (876823)', '876823', ''],
    ['TSB BANK PLC TSB (876824)', '876824', ''],
    ['TSB BANK PLC TSB (876825)', '876825', ''],
    ['TSB BANK PLC TSB (876827)', '876827', ''],
    ['TSB BANK PLC TSB (876828)', '876828', ''],
    ['TSB BANK PLC TSB (876840)', '876840', ''],
    ['TSB BANK PLC TSB (876842)', '876842', ''],
    ['TSB BANK PLC TSB (876843)', '876843', ''],
    ['TSB BANK PLC TSB (876847)', '876847', ''],
    ['TSB BANK PLC TSB (876860)', '876860', ''],
    ['TSB BANK PLC TSB (876861)', '876861', ''],
    ['TSB BANK PLC TSB (876883)', '876883', ''],
    ['TSB BANK PLC TSB (876884)', '876884', ''],
    ['TSB BANK PLC TSB (876885)', '876885', ''],
    ['TSB BANK PLC TSB (876887)', '876887', ''],
    ['TSB BANK PLC TSB (876890)', '876890', ''],
    ['TSB BANK PLC TSB (876891)', '876891', ''],
    ['TSB BANK PLC TSB (876892)', '876892', ''],
    ['TSB BANK PLC TSB (876894)', '876894', ''],
    ['TSB BANK PLC TSB (876895)', '876895', ''],
    ['TSB BANK PLC TSB (876896)', '876896', ''],
    ['TSB BANK PLC TSB (876919)', '876919', ''],
    ['TSB BANK PLC TSB (876924)', '876924', ''],
    ['TSB BANK PLC TSB (876925)', '876925', ''],
    ['TSB BANK PLC TSB (876931)', '876931', ''],
    ['TSB BANK PLC TSB (876932)', '876932', ''],
    ['TSB BANK PLC TSB (876935)', '876935', ''],
    ['TSB BANK PLC TSB (876957)', '876957', ''],
    ['TSB BANK PLC TSB (877001)', '877001', ''],
    ['TSB BANK PLC TSB (877002)', '877002', ''],
    ['TSB BANK PLC TSB (877003)', '877003', ''],
    ['TSB BANK PLC TSB (877004)', '877004', ''],
    ['TSB BANK PLC TSB (877005)', '877005', ''],
    ['TSB BANK PLC TSB (877006)', '877006', ''],
    ['TSB BANK PLC TSB (87007)', '877007', ''],
    ['TSB BANK PLC TSB (877008)', '877008', ''],
    ['TSB BANK PLC TSB (877009)', '877009', ''],
    ['TSB BANK PLC TSB (877010)', '877010', ''],
    ['TSB BANK PLC TSB (877011)', '877011', ''],
    ['TSB BANK PLC TSB (877012)', '877012', ''],
    ['TSB BANK PLC TSB (877014)', '877014', ''],
    ['TSB BANK PLC TSB (877015)', '877015', ''],
    ['TSB BANK PLC TSB (877016)', '877016', ''],
    ['TSB BANK PLC TSB (877018)', '877018', ''],
    ['TSB BANK PLC TSB (877019)', '877019', ''],
    ['TSB BANK PLC TSB (877020)', '877020', ''],
    ['TSB BANK PLC TSB (877021)', '877021', ''],
    ['TSB BANK PLC TSB (877022)', '877022', ''],
    ['TSB BANK PLC TSB (877023)', '877023', ''],
    ['TSB BANK PLC TSB (877024)', '877024', ''],
    ['TSB BANK PLC TSB (877025)', '877025', ''],
    ['TSB BANK PLC TSB (877026)', '877026', ''],
    ['TSB BANK PLC TSB (877027)', '877027', ''],
    ['TSB BANK PLC TSB (877028)', '877028', ''],
    ['TSB BANK PLC TSB (877030)', '877030', ''],
    ['TSB BANK PLC TSB (877031)', '877031', ''],
    ['TSB BANK PLC TSB (877032)', '877032', ''],
    ['TSB BANK PLC TSB (877033)', '877033', ''],
    ['TSB BANK PLC TSB (877034)', '877034', ''],
    ['TSB BANK PLC TSB (877035)', '877035', ''],
    ['TSB BANK PLC TSB (877037)', '877037', ''],
    ['TSB BANK PLC TSB (877039)', '877039', ''],
    ['TSB BANK PLC TSB (877041)', '877041', ''],
    ['TSB BANK PLC TSB (877042)', '877042', ''],
    ['TSB BANK PLC TSB (877044)', '877044', ''],
    ['TSB BANK PLC TSB (877045)', '877045', ''],
    ['TSB BANK PLC TSB (877046)', '877046', ''],
    ['TSB BANK PLC TSB (877047)', '877047', ''],
    ['TSB BANK PLC TSB (877052)', '877052', ''],
    ['TSB BANK PLC TSB (877053)', '877053', ''],
    ['TSB BANK PLC TSB (877054)', '877054', ''],
    ['TSB BANK PLC TSB (877055)', '877055', ''],
    ['TSB BANK PLC TSB (877056)', '877056', ''],
    ['TSB BANK PLC TSB (877057)', '877057', ''],
    ['TSB BANK PLC TSB (877062)', '877062', ''],
    ['TSB BANK PLC TSB (877063)', '877063', ''],
    ['TSB BANK PLC TSB (877066)', '877066', ''],
    ['TSB BANK PLC Vts Test Branch', '877790', ''],
    ['TSB BANK PLC TSB (877791)', '877791', ''],
    ['TSB BANK PLC TSB (877901)', '877901', ''],
    ['TSB BANK PLC TSB (877902)', '877902', ''],
    ['TSB BANK PLC TSB (877904)', '877904', ''],
    ['TSB BANK PLC TSB (877905)', '877905', ''],
    ['TSB BANK PLC TSB (877907)', '877907', ''],
    ['TSB BANK PLC TSB (877908)', '877908', ''],
    ['TSB BANK PLC TSB (877909)', '877909', ''],
    ['TSB BANK PLC TSB (877911)', '877911', ''],
    ['TSB BANK PLC TSB (877914)', '877914', ''],
    ["TSB BANK PLC Bo'ness (877915)", '877915', ''],
    ['TSB BANK PLC TSB (877931)', '877931', ''],
    ['TSB BANK PLC TSB (878811)', '878811', ''],
    ['TSB BANK PLC TSB (878853)', '878853', ''],
    ['TSB BANK PLC Scotland Fraud', '878858', ''],
    ['Santander UK plc Santander 890000', '890000', ''],
    ['Santander UK plc Santander 890001', '890001', ''],
    ['Santander UK plc Santander 890002', '890002', ''],
    ['Santander UK plc Santander 890003', '890003', ''],
    ['Santander UK plc Santander 890004', '890004', ''],
    ['Santander UK plc Santander 890005', '890005', ''],
    ['Santander UK plc Santander 890006', '890006', ''],
    ['Santander UK plc Santander', '890100', ''],
    ['Santander UK plc Santander 890344', '890344', ''],
    ['Santander UK plc Santander 890346', '890346', ''],
    ['Santander UK plc Santander 890354', '890354', ''],
    ['Santander UK plc Santander 890356', '890356', ''],
    ['Santander UK plc Santander 890600', '890600', ''],
    ['Santander UK plc Santander 890601', '890601', ''],
    ['Santander UK plc Santander 890602', '890602', ''],
    ['Santander UK plc Santander 890603', '890603', ''],
    ['Santander UK plc Santander 890604', '890604', ''],
    ['Santander UK plc Santander 890605', '890605', ''],
    ['Santander UK plc Santander 890606', '890606', ''],
    ['Santander UK plc Santander 890610', '890610', ''],
    ['Santander UK plc Santander 890611', '890611', ''],
    ['Santander UK plc Santander 890612', '890612', ''],
    ['Santander UK plc Santander 890613', '890613', ''],
    ['Santander UK plc Santander 890614', '890614', ''],
    ['Santander UK plc Santander 890615', '890615', ''],
    ['Santander UK plc Santander 890616', '890616', ''],
    ['Santander UK plc Santander 891000', '891000', ''],
    ['Santander UK plc Santander 891001', '891001', ''],
    ['Santander UK plc Santander 891002', '891002', ''],
    ['Santander UK plc Santander 891003', '891003', ''],
    ['Santander UK plc Santander 891004', '891004', ''],
    ['Santander UK plc Santander 891005', '891005', ''],
    ['Santander UK plc Santander 891006', '891006', ''],
    ['Santander UK plc Santander 891611', '891611', ''],
    ['Santander UK plc Santander 891612', '891612', ''],
    ['Santander UK plc Santander 891613', '891613', ''],
    ['Santander UK plc Santander 891614', '891614', ''],
    ['Santander UK plc Santander 891615', '891615', ''],
    ['Santander UK plc Santander 891616', '891616', ''],
    ['Santander UK plc Santander 892000', '892000', ''],
    ['Santander UK plc Santander 892001', '892001', ''],
    ['Santander UK plc Santander 892002', '892002', ''],
    ['Santander UK plc Santander 892003', '892003', ''],
    ['Santander UK plc Santander 892004', '892004', ''],
    ['Santander UK plc Santander 892005', '892005', ''],
    ['Santander UK plc Santander 892006', '892006', ''],
    ['Santander UK plc Santander 892611', '892611', ''],
    ['Santander UK plc Santander 892612', '892612', ''],
    ['Santander UK plc Santander 892613', '892613', ''],
    ['Santander UK plc Santander 892614', '892614', ''],
    ['Santander UK plc Santander 892615', '892615', ''],
    ['Santander UK plc Santander 892616', '892616', ''],
    ['BANK OF IRELAND (UK) PLC University Branch', '900295', ''],
    ['BANK OF IRELAND (UK) PLC Banbridge', '900770', ''],
    ['BANK OF IRELAND (UK) PLC Bank of Ireland Access Dept', '901546', ''],
    ['BANK OF IRELAND (UK) PLC Armagh', '902047', ''],
    ['BANK OF IRELAND (UK) PLC Ballymena', '902055', ''],
    ['BANK OF IRELAND (UK) PLC Main Street Bangor', '902063', ''],
    ['BANK OF IRELAND (UK) PLC Bangor', '902071', ''],
    ['BANK OF IRELAND (UK) PLC Castlereagh', '902100', ''],
    ['BANK OF IRELAND (UK) PLC Belfast City Branch', '902127', ''],
    ['BANK OF IRELAND (UK) PLC Gt Victoria St Belfast', '902151', ''],
    ['BANK OF IRELAND (UK) PLC High St Belfast', '902178', ''],
    ['BANK OF IRELAND (UK) PLC Knock Sub-Office', '902186', ''],
    ['BANK OF IRELAND (UK) PLC Lisburn', '902194', ''],
    ['BANK OF IRELAND (UK) PLC Lisburn Rd Belfast', '902207', ''],
    ['BANK OF IRELAND (UK) PLC Branch Services Centre', '902215', ''],
    ['BANK OF IRELAND (UK) PLC Ormeau Road Branch', '902223', ''],
    ['BANK OF IRELAND (UK) PLC Royal Avenue Belfast', '902258', ''],
    ['BANK OF IRELAND (UK) PLC Carrickfergus', '902274', ''],
    ['BANK OF IRELAND (UK) PLC Crossmaglen', '902282', ''],
    ['BANK OF IRELAND (UK) PLC Keody Ni', '902290', ''],
    ['BANK OF IRELAND (UK) PLC Larne (Northern Ireland)', '902303', ''],
    ['BANK OF IRELAND (UK) PLC Lurgan', '902311', ''],
    ['BANK OF IRELAND (UK) PLC Newry (Ni)', '902338', ''],
    ['BANK OF IRELAND (UK) PLC Newtownards Co Down', '902346', ''],
    ['BANK OF IRELAND (UK) PLC Portadown', '902354', ''],
    ['BANK OF IRELAND (UK) PLC Rathfriland', '902362', ''],
    ['BANK OF IRELAND (UK) PLC Antrim', '902389', ''],
    [
      'BANK OF IRELAND (UK) PLC International Dept Belfast',
      '902397',
      'BOFIGB2BXXX'
    ],
    ['BANK OF IRELAND (UK) PLC Glengormley', '902426', ''],
    ['BANK OF IRELAND (UK) PLC Andersontown (N)', '902442', ''],
    ['BANK OF IRELAND (UK) PLC Down Patrick (Ni)', '902450', ''],
    ['BANK OF IRELAND (UK) PLC Culmore Road Branch', '902653', ''],
    ['BANK OF IRELAND (UK) PLC Donegall Square South', '903103', ''],
    ['BANK OF IRELAND (UK) PLC Donegall Square South', '903111', ''],
    ['BANK OF IRELAND (UK) PLC Aa Financial Services', '903138', ''],
    ['BANK OF IRELAND (UK) PLC City Hall Branch', '903154', ''],
    ['BANK OF IRELAND (UK) PLC Post Office Credit Card', '903920', ''],
    ['BANK OF IRELAND (UK) PLC Aa Credit Card', '904616', ''],
    ['BANK OF IRELAND (UK) PLC Castlederg', '904798', ''],
    ['BANK OF IRELAND (UK) PLC Coleraine', '904800', ''],
    ['BANK OF IRELAND (UK) PLC Cookstown Ni', '904819', ''],
    ['BANK OF IRELAND (UK) PLC Draperstown', '904843', ''],
    ['BANK OF IRELAND (UK) PLC Dungannon (Ni)', '904851', ''],
    ['BANK OF IRELAND (UK) PLC Enniskillen', '904886', ''],
    ['BANK OF IRELAND (UK) PLC Shipquay Street Derry - Ni', '904958', ''],
    ['BANK OF IRELAND (UK) PLC Strand Road Derry Ni', '904974', ''],
    ['BANK OF IRELAND (UK) PLC Magherafelt', '904982', ''],
    ['BANK OF IRELAND (UK) PLC Omagh', '905002', ''],
    ['BANK OF IRELAND (UK) PLC Strathbane', '905010', ''],
    ['BANK OF IRELAND (UK) PLC Waterside, Derry - Ni', '905037', ''],
    ['BANK OF IRELAND (UK) PLC Beleek', '905045', ''],
    ['BANK OF IRELAND (UK) PLC Lisnaskea', '905053', ''],
    ['BANK OF IRELAND (UK) PLC Kilrea', '905061', ''],
    ['BANK OF IRELAND (UK) PLC Limavaby', '905096', ''],
    ['BANK OF IRELAND (UK) PLC Post Office Financial Serv', '907868', ''],
    ['BANK OF IRELAND (UK) PLC T & I Trade Services Euro', '908839', ''],
    ['BANK OF IRELAND (UK) PLC Kilkeel (Ni)', '909118', ''],
    ['BANK OF IRELAND (UK) PLC Maghera', '909126', ''],
    ['BANK OF IRELAND (UK) PLC Ballymoney', '909142', ''],
    ['BANK OF IRELAND (UK) PLC Great Victoria St Belfast', '909150', ''],
    ['BANK OF IRELAND (UK) PLC Ballelare', '909169', ''],
    ['BANK OF IRELAND (UK) PLC Banking 365', '909492', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Branch Services Cen',
      '938009',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Ballymena Wellington Street',
      '938017',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Londonderry Meadowbank',
      '938025',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Newcastle', '938033', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Omagh', '938041', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Dungannon', '938068', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Enniskillen', '938076', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast University Road',
      '938092',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Lurgan', '938114', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Omagh', '938130', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Ballymena Wellington Street',
      '938157',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Dungannon Scotch Street',
      '938165',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Ballymena', '938173', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Newry', '938181', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Bangor', '938203', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Dungannon', '938238', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Coleraine', '938246', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Londonderry Meadowbank',
      '938262',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Donegal Square Nrth',
      '938270',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Dungannon Scotch Street',
      '938289',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Enniskillen', '938297', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) University Road Branch',
      '938300',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Glengormley', '938319', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Kilkeel', '938327', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Lisburn', '938335', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Donegal Square Nrth',
      '938343',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Newcastle', '938351', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Newry', '938378', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast University Road',
      '938394',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Lurgan', '938408', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Royal Avenue',
      '938416',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast University Road',
      '938424',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Londonderry Meadowbank',
      '938440',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Newry', '938467', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) University Road Belfast',
      '938475',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Londonderry Meadowbank',
      '938483',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Glengormley', '938491', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Ballymena', '938505', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Coleraine', '938513', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast University Road',
      '938521',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Mortgage Business Unit',
      '938548',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Central Deposits',
      '938556',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Network Services 2',
      '938564',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Nama Operations',
      '938572',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast, University Road',
      '938580',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Card Services', '938599', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast University Road',
      '938600',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Wellington Place',
      '938601',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Mountpottinger',
      '938602',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Donegall Sq North',
      '938603',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Donegall Sq North',
      '938604',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Lisburn Market Square',
      '938605',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Armagh Victoria Street',
      '938606',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Coleraine 32 The Diamond',
      '938607',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Portadown Market Street',
      '938608',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast University Road',
      '938609',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Londonderry Meadowbank',
      '938610',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Donegall Sq North',
      '938611',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Glengormley', '938612', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Ballymena Brian Street',
      '938613',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Bangor 85 Main Street',
      '938614',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Lurgan', '938615', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Dungannon Market Square',
      '938616',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Dungannon Scotch Street',
      '938617',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Coleraine', '938618', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Newry 72 Hill Street',
      '938619',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Donegal Square Nrth',
      '938620',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Bangor', '938621', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Omagh Market Street',
      '938622',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Bangor', '938623', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Lisburn Rd Finaghy',
      '938624',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Glengormley', '938625', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Glengormley', '938626', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Londonderry Meadowbank',
      '938627',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Banbridge 2 Ratheriland St',
      '938628',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Lisburn', '938629', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Antrim 26-32 High Street',
      '938630',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Donegal Square Nth',
      '938631',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Strabane Castle Street',
      '938632',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Ballymena Wellington Street',
      '938633',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast, Andersonstown',
      '938634',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast 254 Ormeau Road',
      '938635',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Downpatrick 13 Market St',
      '938636',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Dundonald',
      '938637',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Glengormley', '938638', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast, Crumlin Road',
      '938639',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Head Office - Admin',
      '938640',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast, Dunmurry',
      '938641',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Londonderry-Waterside',
      '938642',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Coleraine', '938643', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Ballyholme', '938644', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Donegal Square Nrth',
      '938645',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Kilkeel Bridge Street',
      '938646',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast, Queens Island',
      '938647',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Coleraine', '938648', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast University Road',
      '938649',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Enniskillen East Bridge Rd',
      '938650',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Lisburn', '938651', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Newcastle Shopping Ctr Main',
      '938652',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast, Ulsterville',
      '938653',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Bangor', '938654', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Donegall Sq North',
      '938655',
      ''
    ],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Magherafelt', '938656', ''],
    ['AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Coleraine', '938657', ''],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Clearing Dept',
      '938661',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Financial Control',
      '938688',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Retail Banking Operations',
      '938696',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Current Accounts',
      '938697',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Staff Business Unit',
      '938734',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Ballymena Wellington Street',
      '938793',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Currency Services',
      '938807',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Head Office',
      '938815',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Intl Banking',
      '938831',
      'FTBKGB2BXXX'
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Mortgage Unit - Csuk',
      '938874',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Ni Personal Loans',
      '938904',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Banking Support Services',
      '938912',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Specialised Lending Service',
      '938920',
      ''
    ],
    [
      'AIB GROUP (UK) PLC (TRADING NAME AIB (NI)) Belfast Ni Euro Branch',
      '938998',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Head Office Belfast',
      '950001',
      'DABAGB2B001'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 003',
      '950003',
      'DABAGB2BXXX'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Donegall Square North',
      '950004',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Victoria Street',
      '950005',
      'DABAGB2B005'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 007', '950007', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Clearing 009',
      '950009',
      'DABAGB2B009'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 033',
      '950033',
      'DABAGB2B033'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 041', '950041', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 042',
      '950042',
      'DABAGB2B042'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 043',
      '950043',
      'DABAGB2B043'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 044',
      '950044',
      'DABAGB2B044'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 045',
      '950045',
      'DABAGB2B045'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 046',
      '950046',
      'DABAGB2B046'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Executor & Trustee Comp Ltd',
      '950100',
      'DABAGB2B100'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Antrim Road',
      '950101',
      'DABAGB2B101'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Kennedy Centre',
      '950103',
      'DABAGB2B103'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballynafeigh',
      '950106',
      'DABAGB2B106'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Belfast Boucher Road',
      '950109',
      'DABAGB2B109'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Castlereagh Rd.Belfast',
      '950110',
      'DABAGB2B110'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Connswater',
      '950111',
      'DABAGB2B111'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Cregagh', '950114', 'DABAGB2B114'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Dargan Road Belfast',
      '950118',
      'DABAGB2B118'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Belfast Donegall Sq West',
      '950121',
      'DABAGB2B121'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Finaghy', '950122', 'DABAGB2B122'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Belfast Dundonald',
      '950125',
      'DABAGB2B125'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Falls', '950126', 'DABAGB2B126'],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Knock', '950128', 'DABAGB2B128'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK London Corporate Cards',
      '950131',
      'DABAGB2B131'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Lisburn Road',
      '950132',
      'DABAGB2B132'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Forestside',
      '950134',
      'DABAGB2B134'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Royal Avenue', '950136', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Shaftesbury Square',
      '950141',
      'DABAGB2B141'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Shankill Road',
      '950145',
      'DABAGB2B145'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballyhackamore',
      '950147',
      'DABAGB2B147'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK University Road',
      '950149',
      'DABAGB2B149'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Windsor', '950156', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Antrim', '950202', 'DABAGB2B202'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Armagh Scotch Street',
      '950206',
      'DABAGB2B206'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Aughnacloy',
      '950216',
      'DABAGB2B216'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballycastle',
      '950221',
      'DABAGB2B221'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballygawley',
      '950224',
      'DABAGB2B224'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballyclare, 18 The Square',
      '950227',
      'DABAGB2B227'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballyholme Bangor',
      '950229',
      'DABAGB2B229'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballymena Broadway',
      '950231',
      'DABAGB2B231'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballymoney',
      '950237',
      'DABAGB2B237'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballynahinch',
      '950241',
      'DABAGB2B241'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Banbridge',
      '950246',
      'DABAGB2B246'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Bangor,Bloomfield',
      '950251',
      'DABAGB2B251'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Bangor', '950252', 'DABAGB2B252'],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Beragh', '950256', 'DABAGB2B256'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Carryduff',
      '950258',
      'DABAGB2B258'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Bushmills',
      '950259',
      'DABAGB2B259'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Carrickfergus High Street',
      '950261',
      'DABAGB2B261'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Castlederg',
      '950264',
      'DABAGB2B264'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Castlewellan',
      '950266',
      'DABAGB2B266'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Coleraine The Diamond',
      '950277',
      'DABAGB2B277'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Coleraine Railway Road',
      '950278',
      ''
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Comber', '950281', 'DABAGB2B281'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Cookstown',
      '950282',
      'DABAGB2B282'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Crossgar', '950284', 'DABAGB2B284'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Crossmaglen',
      '950285',
      'DABAGB2B285'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Cushendall',
      '950286',
      'DABAGB2B286'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Donaghadee,Co.Down',
      '950289',
      'DABAGB2B289'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Downpatrick',
      '950291',
      'DABAGB2B291'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Dromore', '950296', 'DABAGB2B296'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Dungannon',
      '950302',
      'DABAGB2B302'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Dungiven', '950306', 'DABAGB2B306'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Enniskillen',
      '950309',
      'DABAGB2B309'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Fintona', '950316', 'DABAGB2B316'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Fivemiletown',
      '950321',
      'DABAGB2B321'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Carnlough',
      '950323',
      'DABAGB2B323'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Glengormley, 298 Antrim Rd',
      '950325',
      'DABAGB2B325'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Hillsborough',
      '950326',
      'DABAGB2B326'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Holywood', '950332', 'DABAGB2B332'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Irvinestown',
      '950336',
      'DABAGB2B336'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Garvagh', '950338', 'DABAGB2B338'],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Keady', '950341', 'DABAGB2B341'],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Kesh', '950342', 'DABAGB2B342'],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Kilkeel', '950344', 'DABAGB2B344'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Killinchy',
      '950345',
      'DABAGB2B345'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Kilrea', '950346', 'DABAGB2B346'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Kircubbin',
      '950348',
      'DABAGB2B348'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Larne', '950351', 'DABAGB2B351'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Limavady Catherine Street',
      '950356',
      'DABAGB2B356'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Lisburn Bow Street',
      '950361',
      'DABAGB2B361'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Market Square,Lisburn',
      '950362',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Londonderry Shipquay Place',
      '950366',
      'DABAGB2B366'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Lurgan', '950371', 'DABAGB2B371'],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Mallusk', '950373', 'DABAGB2B373'],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Maghera', '950376', 'DABAGB2B376'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Magherafelt Broad Street',
      '950381',
      'DABAGB2B381'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Foyle', '950383', 'DABAGB2B383'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Markethill',
      '950384',
      'DABAGB2B384'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Newcastle',
      '950386',
      'DABAGB2B386'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Newry, Hill Street',
      '950392',
      'DABAGB2B392'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Newtownabbey , Abbeycentre',
      '950394',
      'DABAGB2B394'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Newtownabbey, Cloughfern',
      '950395',
      'DABAGB2B395'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Newtownards High Street',
      '950396',
      'DABAGB2B396'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Newtownards, Regent Street',
      '950397',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Lisnaskea',
      '950398',
      'DABAGB2B398'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Newtownstewart',
      '950401',
      'DABAGB2B401'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Omagh', '950407', 'DABAGB2B407'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Portadown High Street',
      '950411',
      'DABAGB2B411'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Portaferry',
      '950417',
      'DABAGB2B417'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 418', '950418', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Portglenone',
      '950421',
      'DABAGB2B421'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 426', '950426', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Portrush', '950427', 'DABAGB2B427'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Portstewart',
      '950428',
      'DABAGB2B428'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Randalstown',
      '950431',
      'DABAGB2B431'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Rathfriland',
      '950432',
      'DABAGB2B432'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Saintfield',
      '950434',
      'DABAGB2B434'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Strabane', '950437', 'DABAGB2B437'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Tandragee',
      '950438',
      'DABAGB2B438'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Warrenpoint',
      '950440',
      'DABAGB2B440'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Whitehead',
      '950441',
      'DABAGB2B441'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 442', '950442', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Trade Finance447',
      '950447',
      'DABAGB2B447'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 448', '950448', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 449',
      '950449',
      'DABAGB2B449'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 450', '950450', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 451', '950451', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK P B & Wealth',
      '950455',
      'DABAGB2B455'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 456',
      '950456',
      'DABAGB2B456'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 457',
      '950457',
      'DABAGB2B457'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 465', '950465', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 466',
      '950466',
      'DABAGB2B466'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK C&B Credit Distressed',
      '950467',
      'DABAGB2B467'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Treasury Risk',
      '950468',
      'DABAGB2B468'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 469', '950469', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 470',
      '950470',
      'DABAGB2B470'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 471', '950471', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 472',
      '950472',
      'DABAGB2B472'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 474', '950474', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 475', '950475', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 477', '950477', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 478', '950478', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 480',
      '950480',
      'DABAGB2B480'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 481', '950481', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 482', '950482', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 483', '950483', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 484', '950484', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 485', '950485', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 486', '950486', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 487', '950487', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 488', '950488', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 489', '950489', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 490', '950490', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 491', '950491', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 492', '950492', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 493', '950493', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 494', '950494', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 495', '950495', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 496', '950496', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 497', '950497', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 498', '950498', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 499', '950499', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Customer Direct Portadown',
      '950500',
      'DABAGB2B500'
    ],
    ['DANSKE BANK A/S Danske Bank 036 Branch', '950510', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK London Branch',
      '950529',
      'DABAGB2B529'
    ],
    ['DANSKE BANK A/S Virtual Accounts London', '950530', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 600',
      '950600',
      'DABAGB2B600'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 601',
      '950601',
      'DABAGB2B601'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Finance Tax Nb T/A Db',
      '950602',
      'DABAGB2B602'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Business Acc Open Team',
      '950603',
      'DABAGB2B603'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 604',
      '950604',
      'DABAGB2B604'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 605',
      '950605',
      'DABAGB2B605'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 606',
      '950606',
      'DABAGB2B606'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 607',
      '950607',
      'DABAGB2B607'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 608',
      '950608',
      'DABAGB2B608'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 609',
      '950609',
      'DABAGB2B609'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Dm 610',
      '950610',
      'DABAGB2B610'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Belfast Business Centre',
      '950611',
      'DABAGB2B611'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Central Branch Spare',
      '950612',
      'DABAGB2B612'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK South Business Centre',
      '950613',
      'DABAGB2B613'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK North Business Centre',
      '950614',
      'DABAGB2B614'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Cpc North West',
      '950615',
      'DABAGB2B615'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 616',
      '950616',
      'DABAGB2B616'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 617',
      '950617',
      'DABAGB2B617'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 618',
      '950618',
      'DABAGB2B618'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 619',
      '950619',
      'DABAGB2B619'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 620',
      '950620',
      'DABAGB2B620'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 621',
      '950621',
      'DABAGB2B621'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 622',
      '950622',
      'DABAGB2B622'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 623', '950623', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Customer Experience',
      '950624',
      'DABAGB2B624'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 625',
      '950625',
      'DABAGB2B625'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ccl Db 626',
      '950626',
      'DABAGB2B626'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbcll Db 627',
      '950627',
      'DABAGB2B627'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbell Db 628',
      '950628',
      'DABAGB2B628'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbill Db 629',
      '950629',
      'DABAGB2B629'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbdcl Db 630',
      '950630',
      'DABAGB2B630'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbfsl Db 631',
      '950631',
      'DABAGB2B631'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nordev Db 632',
      '950632',
      'DABAGB2B632'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbnl Db 633',
      '950633',
      'DABAGB2B633'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbptl Db 634',
      '950634',
      'DABAGB2B634'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbfl Db 635',
      '950635',
      'DABAGB2B635'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbcll Db 636', '950636', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbell Db 637', '950637', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbill Db 638', '950638', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 639', '950639', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 640',
      '950640',
      'DABAGB2B640'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 641',
      '950641',
      'DABAGB2B641'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 642', '950642', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 643', '950643', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 644', '950644', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Corporate Banking GB',
      '950645',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 646',
      '950646',
      'DABAGB2B646'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK London Business',
      '950647',
      'DABAGB2B647'
    ],
    ['DANSKE BANK A/S Chaps Return', '950648', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 649',
      '950649',
      'DABAGB2B649'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 650',
      '950650',
      'DABAGB2B650'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 651', '950651', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 652', '950652', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ccl Db 653',
      '950653',
      'DABAGB2B653'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nafl Db 654',
      '950654',
      'DABAGB2B654'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 655',
      '950655',
      'DABAGB2B655'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 656',
      '950656',
      'DABAGB2B656'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Danske Mortgage Bank',
      '950661',
      'DABAGB2B661'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 662',
      '950662',
      'DABAGB2B662'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 663',
      '950663',
      'DABAGB2B663'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 664',
      '950664',
      'DABAGB2B664'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 665',
      '950665',
      'DABAGB2B665'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Int 666',
      '950666',
      'DABAGB2B666'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Private Banking',
      '950668',
      'DABAGB2B668'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 669',
      '950669',
      'DABAGB2B669'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 670',
      '950670',
      'DABAGB2B670'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 671',
      '950671',
      'DABAGB2B671'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 672',
      '950672',
      'DABAGB2B672'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Business Customer Support',
      '950673',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 674',
      '950674',
      'DABAGB2B674'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 675', '950675', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Derry/Londonderry Bus Centr',
      '950676',
      'DABAGB2B676'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 677',
      '950677',
      'DABAGB2B677'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Day in The Life',
      '950678',
      'DABAGB2B678'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Branch Business',
      '950679',
      'DABAGB2B679'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 680',
      '950680',
      'DABAGB2B680'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 681',
      '950681',
      'DABAGB2B681'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 682',
      '950682',
      'DABAGB2B682'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 683',
      '950683',
      'DABAGB2B683'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 684',
      '950684',
      'DABAGB2B684'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db Loro 685',
      '950685',
      'DABAGB2B685'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Western Business Centre',
      '950686',
      'DABAGB2B686'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Agri Business',
      '950687',
      'DABAGB2B687'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 688',
      '950688',
      'DABAGB2B688'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Small Business',
      '950689',
      'DABAGB2B689'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 690',
      '950690',
      'DABAGB2B690'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 691',
      '950691',
      'DABAGB2B691'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Money Market 692',
      '950692',
      'DABAGB2B692'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 693',
      '950693',
      'DABAGB2B693'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 694',
      '950694',
      'DABAGB2B694'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 695',
      '950695',
      'DABAGB2B695'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 696',
      '950696',
      'DABAGB2B696'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 697',
      '950697',
      'DABAGB2B697'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 698',
      '950698',
      'DABAGB2B698'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 699',
      '950699',
      'DABAGB2B699'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 700',
      '950700',
      'DABAGB2B700'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 701',
      '950701',
      'DABAGB2B701'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 702',
      '950702',
      'DABAGB2B702'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 703',
      '950703',
      'DABAGB2B703'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 704',
      '950704',
      'DABAGB2B704'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Credit Risk Analytics UK',
      '950705',
      'DABAGB2B705'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Mortgage Intermediary Unit',
      '950706',
      'DABAGB2B706'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 707',
      '950707',
      'DABAGB2B707'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Operations, Icc',
      '950708',
      'DABAGB2B708'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 709',
      '950709',
      'DABAGB2B709'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 710',
      '950710',
      'DABAGB2B710'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 711',
      '950711',
      'DABAGB2B711'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 712',
      '950712',
      'DABAGB2B712'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 713',
      '950713',
      'DABAGB2B713'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 714',
      '950714',
      'DABAGB2B714'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 715',
      '950715',
      'DABAGB2B715'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 716',
      '950716',
      'DABAGB2B716'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Branch Services',
      '950717',
      'DABAGB2B717'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 718',
      '950718',
      'DABAGB2B718'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Debt Management',
      '950719',
      'DABAGB2B719'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 720',
      '950720',
      'DABAGB2B720'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 721',
      '950721',
      'DABAGB2B721'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 722',
      '950722',
      'DABAGB2B722'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 723',
      '950723',
      'DABAGB2B723'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 724',
      '950724',
      'DABAGB2B724'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 725',
      '950725',
      'DABAGB2B725'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 726',
      '950726',
      'DABAGB2B726'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 727',
      '950727',
      'DABAGB2B727'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 728',
      '950728',
      'DABAGB2B728'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 729',
      '950729',
      'DABAGB2B729'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 730',
      '950730',
      'DABAGB2B730'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Trade and Export Finance731',
      '950731',
      'DABAGB2B731'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 732',
      '950732',
      'DABAGB2B732'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 733',
      '950733',
      'DABAGB2B733'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 734',
      '950734',
      'DABAGB2B734'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Ex-Pats', '950735', 'DABAGB2B735'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 736',
      '950736',
      'DABAGB2B736'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 737',
      '950737',
      'DABAGB2B737'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 738',
      '950738',
      'DABAGB2B738'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 739',
      '950739',
      'DABAGB2B739'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 740',
      '950740',
      'DABAGB2B740'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 741',
      '950741',
      'DABAGB2B741'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 742',
      '950742',
      'DABAGB2B742'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 743',
      '950743',
      'DABAGB2B743'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 744',
      '950744',
      'DABAGB2B744'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 745',
      '950745',
      'DABAGB2B745'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 746',
      '950746',
      'DABAGB2B746'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 747',
      '950747',
      'DABAGB2B747'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 748',
      '950748',
      'DABAGB2B748'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 749',
      '950749',
      'DABAGB2B749'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 750',
      '950750',
      'DABAGB2B750'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 751',
      '950751',
      'DABAGB2B751'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 752',
      '950752',
      'DABAGB2B752'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 753',
      '950753',
      'DABAGB2B753'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 754',
      '950754',
      'DABAGB2B754'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 755',
      '950755',
      'DABAGB2B755'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 756',
      '950756',
      'DABAGB2B756'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 757',
      '950757',
      'DABAGB2B757'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 758',
      '950758',
      'DABAGB2B758'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 759',
      '950759',
      'DABAGB2B759'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 760',
      '950760',
      'DABAGB2B760'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbet Db 761',
      '950761',
      'DABAGB2B761'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbet Db 762',
      '950762',
      'DABAGB2B762'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbfsl Db 763',
      '950763',
      'DABAGB2B763'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nordev Db 764',
      '950764',
      'DABAGB2B764'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbfsl Db 765',
      '950765',
      'DABAGB2B765'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 766',
      '950766',
      'DABAGB2B766'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbcll Db 767',
      '950767',
      'DABAGB2B767'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbell Db 768',
      '950768',
      'DABAGB2B768'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbill Db 769',
      '950769',
      'DABAGB2B769'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 770',
      '950770',
      'DABAGB2B770'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nafl Db 771',
      '950771',
      'DABAGB2B771'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Northern Bank (Iom) Ltd.',
      '950772',
      'DABAGB2B772'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbdcl Db 773',
      '950773',
      'DABAGB2B773'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbis Db 774',
      '950774',
      'DABAGB2B774'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbis Db 775',
      '950775',
      'DABAGB2B775'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 776',
      '950776',
      'DABAGB2B776'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbnl Db 777',
      '950777',
      'DABAGB2B777'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbptl Db 778',
      '950778',
      'DABAGB2B778'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbfl Db 779',
      '950779',
      'DABAGB2B779'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbfsl Db 780',
      '950780',
      'DABAGB2B780'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbfsl Db 781',
      '950781',
      'DABAGB2B781'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 782',
      '950782',
      'DABAGB2B782'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 783',
      '950783',
      'DABAGB2B783'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 784',
      '950784',
      'DABAGB2B784'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 785',
      '950785',
      'DABAGB2B785'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 786',
      '950786',
      'DABAGB2B786'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Wealth', '950787', 'DABAGB2B787'],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 788',
      '950788',
      'DABAGB2B788'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 789',
      '950789',
      'DABAGB2B789'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 790',
      '950790',
      'DABAGB2B790'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 791',
      '950791',
      'DABAGB2B791'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 792',
      '950792',
      'DABAGB2B792'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nafl Db 793',
      '950793',
      'DABAGB2B793'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 794',
      '950794',
      'DABAGB2B794'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Customer Protection Centre',
      '950795',
      'DABAGB2B795'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 796',
      '950796',
      'DABAGB2B796'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Corporate Banking',
      '950797',
      'DABAGB2B797'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 798',
      '950798',
      'DABAGB2B798'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 799',
      '950799',
      'DABAGB2B799'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 800',
      '950800',
      'DABAGB2B800'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 801',
      '950801',
      'DABAGB2B801'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 802',
      '950802',
      'DABAGB2B802'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 803',
      '950803',
      'DABAGB2B803'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Central Branch/Credit',
      '950804',
      'DABAGB2B804'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 805',
      '950805',
      'DABAGB2B805'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Personal Banking UK',
      '950806',
      'DABAGB2B806'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 807',
      '950807',
      'DABAGB2B807'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 808',
      '950808',
      'DABAGB2B808'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 809',
      '950809',
      'DABAGB2B809'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Pb Credit Assessment',
      '950810',
      'DABAGB2B810'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Nbl T/A Db 811',
      '950811',
      'DABAGB2B811'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Operational Risk', '950812', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Risk Operations', '950813', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Chaps Return',
      '950814',
      'DABAGB2BRTN'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Supply Chain Finance UK',
      '950815',
      'DABAGB2B815'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Distressed Lending',
      '950816',
      'DABAGB2B816'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Managing Director Corporate',
      '950817',
      'DABAGB2B817'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Manag Dir Pers & Small Bus',
      '950818',
      'DABAGB2B818'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK C & B Credit Assessment',
      '950819',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Virtual Accounts Nb',
      '950820',
      'DABAGB2B820'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Invoice Finance Jba Reversa',
      '950821',
      'DABAGB2B821'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Invoice Finance Sage Adjust',
      '950822',
      'DABAGB2B822'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Cm Loan and Bridge Book UK',
      '950823',
      'DABAGB2B823'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Afs New Nb',
      '950824',
      'DABAGB2B824'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Payments Originating O/Seas',
      '950825',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK A2a - Account To Account',
      '950826',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Small Business Branch Based',
      '950827',
      'DABAGB2B827'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK C&B Credit Recoveries',
      '950828',
      'DABAGB2B828'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Credit Risk Oversight',
      '950829',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK GB Mortgages',
      '950830',
      'DABAGB2B830'
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Trustee Directors',
      '950831',
      'DABAGB2B831'
    ],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK GB Personal Other', '950832', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Syndicated Lending', '950833', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK GB Bus & Corp Other', '950834', ''],
    ['NORTHERN BANK LIMITED T/A DANSKE BANK Sustainability', '950835', ''],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK GB & Corporate Strategy',
      '950836',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Cust Insights & Digital Rel',
      '950837',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Antrim Credit Union Limited',
      '959100',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballymena Credit Union Ltd',
      '959101',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Dromara & Dromgooland Cr Un',
      '959102',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Coalisland Credit Union Ltd',
      '959103',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Crossmaglen Credit Union Lt',
      '959104',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Waterside Credit Union Ltd',
      '959105',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK S.A.G Credit Union Ltd',
      '959106',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Newington Credit Union',
      '959107',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Omagh Credit Union Limited',
      '959108',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Portaferry Credit Union Lim',
      '959111',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Slieve Gullion Credit Union',
      '959112',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Kilkeel Credit Union Ltd',
      '959113',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballinascreen Credit Union',
      '959114',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Kilrea Rasharkin Dunloy Cu',
      '959115',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Lisburn Credit Union',
      '959116',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Pennyburn Credit Union Ltd',
      '959117',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Clonard Credit Union Limite',
      '959118',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK W.B.R. Credit Union Limited',
      '959119',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ardboe Credit Union Ltd',
      '959120',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Ballinderry Bridge Cu',
      '959121',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Enniskiillen Cr Union Ltd',
      '959122',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Carnglen Credit Union',
      '959123',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Cookstown Credit Union',
      '959124',
      ''
    ],
    [
      'NORTHERN BANK LIMITED T/A DANSKE BANK Dungannon Credit Union',
      '959125',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Head Office Belfast',
      '980000',
      'ULSBGB2BXXX'
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Clearing Depart,Belfast',
      '980003',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Payment & Int Banking Serv',
      '980005',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Payment Intl Banking Servs',
      '980006',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Internatl Clg Ser, Belfast',
      '980007',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Bcs Cash Centre Mallusk',
      '980009',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Belfast City Office 2',
      '980010',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK York Street (A)',
      '980011',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Belfast Airport',
      '980013',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Andersonstown',
      '980015',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Andersonstown (A)',
      '980017',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Belfast, City Office (C)',
      '980020',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Connswater', '980030', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Belfast City Office 1 (A)',
      '980040',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Belfast, City Office (B)',
      '980050',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ormeau Road (A)',
      '980055',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Belfast City Office 1',
      '980060',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Belfast City Office 3',
      '980070',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Mountpottinger',
      '980080',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ubn Mobile Data Capture',
      '980081',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ormeau Road', '980083', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK University Road (A)',
      '980090',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Shankill', '980110', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK York Street', '980120', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Kings Road', '980125', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Connswater (A)',
      '980130',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK University Road (B)',
      '980140',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ormeau Road (B)',
      '980145',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Dunmurry (A)',
      '980150',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK University Road',
      '980155',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Connswater (B)',
      '980160',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Antrim', '980180', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Armagh', '980190', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Clogher (A)', '980210', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ballycastle', '980270', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Carrickfergus (B)',
      '980280',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ballymena', '980290', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ballymena (A)',
      '980295',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ballymoney', '980310', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ballynahinch',
      '980320',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Banbridge', '980330', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Springhill Office (A)',
      '980340',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK High Street Bangor',
      '980350',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Springhill Bangor',
      '980360',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Carrickfergus',
      '980400',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Strabane (A)',
      '980410',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Castlewellan',
      '980420',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Clogher', '980430', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK The Diamond Coleraine',
      '980440',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK West End Coleraine',
      '980441',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Comber', '980445', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Cookstown', '980450', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Crumlin', '980460', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Springhill Office (B)',
      '980530',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Downpatrick', '980540', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Magherafelt (A)',
      '980550',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Lisburn (C)', '980560', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Omagh (A)', '980570', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Kings Road (A)',
      '980577',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Dungannon', '980580', ''],
    [
      "NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK L'derry-Waterside (A)",
      '980583',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Dunmurry', '980590', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Darling St Enniskillen',
      '980650',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Markets Enniskillen',
      '980655',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Limavady (A)',
      '980720',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Omagh (B)', '980730', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Lisburn (B)', '980750', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Holywood', '980760', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Darling St Enniskillen(A)',
      '980790',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Carrickfergus (A)',
      '980820',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Downpatrick (A)',
      '980860',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Newtownards (A)',
      '980870',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Larne', '980940', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Limavady', '980950', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Lisburn', '980960', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Lisburn (A)', '980965', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Lisnaskea', '980970', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Culmore Road (A)',
      '980975',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Culmore Road',
      '980980',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Culmore Road (B)',
      '980981',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Londonderry Waterside',
      '980985',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Lurgan', '980990', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Maghera', '981060', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Magherafelt', '981070', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Lisburn (D)', '981075', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Glengormley (B)',
      '981080',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Castlewellan (A)',
      '981130',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Newry', '981140', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Glengormley (A)',
      '981143',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Newtownabbey Glengormley',
      '981145',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Newtownards', '981150', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Newry (A)', '981160', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Omagh', '981230', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Portadown', '981290', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Coleraine (A)',
      '981320',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Coleraine (B)',
      '981326',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Antrim (A)', '981345', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Newry (B)', '981360', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Cookstown (A)',
      '981430',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Strabane', '981440', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Portadown (A)',
      '981450',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Warrenpoint', '981490', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Aps Personal Loans',
      '981540',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Aps Car Loans',
      '981541',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Aps Home Improvements',
      '981542',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Aps Business Loans',
      '981543',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Direct Loans Telephone',
      '981545',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Direct Loans Internet',
      '981546',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Retained Mortgages Ni',
      '981552',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Direct Loans Tel Home Impr',
      '981560',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Direct Loans Tel/Int Trans',
      '981561',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Keying Sortcode Internet',
      '981563',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Direct Loans Unit',
      '981565',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Centrally Managed Acc Ub Ni',
      '981566',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ubn Post Office',
      '981567',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Vpc Danesfort',
      '982050',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Cmau Ni', '982053', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Lending Support Unit Ni',
      '982060',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ubn Retail Test Branch 2',
      '982075',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Rbs Ccu London',
      '982500',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ulster Cards McI',
      '983001',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ulster Cards Visa',
      '983002',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Streamline Harrogate',
      '983010',
      ''
    ],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Atm Operations N.I.',
      '983020',
      ''
    ],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Anytime', '984000', ''],
    ['NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Ub Greenock', '984800', ''],
    [
      'NATIONAL WESTMINSTER BANK PLC T/A ULSTER BANK Clearing Dept, Dublin',
      '985000',
      ''
    ]
  ];
  var COUNTRY_CODE_UPPERCASE = 'UK';
  var BANK_CODE_LENGHTS = '6';
  var ACCOUNT_NUMBER_LENGTHS = '8';
  /**
   * BANK_DATA is an array of sort_code, name, branch, bic1, bic2
   * Source: Raisenow bought this file and we generate it over a gulp task. File should respect the .tsv format
   */
  exports.data = BANK_DATA;
  exports.bankCodeLength = COUNTRY_CODE_UPPERCASE;
  exports.bankCodeLength = BANK_CODE_LENGHTS;
  exports.accountNumberLength = ACCOUNT_NUMBER_LENGTHS;
});

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports'], factory);
  } else if (
    typeof exports === 'object' &&
    typeof exports.nodeName !== 'string'
  ) {
    // CommonJS
    factory(exports);
  } else {
    // Browser globals
    factory((root.ukWeightTable = {}));
  }
})(this, function(exports) {
  var WeightTable_UK = [
    {
      sortCodeRange: { start: '010004', end: '016715' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040003', end: '040003' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040004', end: '040004' },
      algorithm: 'DBLAL',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040005', end: '040005' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040006', end: '040006' },
      algorithm: 'DBLAL',
      weight: {
        u: 0,
        v: 3,
        w: 0,
        x: 0,
        y: 0,
        z: 3,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040008', end: '040008' },
      algorithm: 'DBLAL',
      weight: {
        u: 0,
        v: 3,
        w: 0,
        x: 0,
        y: 0,
        z: 4,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040010', end: '040014' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040010', end: '040014' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040015', end: '040015' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040020', end: '040023' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 2,
        w: 0,
        x: 0,
        y: 9,
        z: 1,
        a: 2,
        b: 8,
        c: 4,
        d: 3,
        e: 7,
        f: 5,
        g: 6,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040024', end: '040039' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040024', end: '040039' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040040', end: '040059' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 2,
        w: 0,
        x: 0,
        y: 9,
        z: 1,
        a: 2,
        b: 8,
        c: 4,
        d: 3,
        e: 7,
        f: 5,
        g: 6,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040072', end: '040073' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040074', end: '040075' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040082', end: '040082' },
      algorithm: 'MOD10',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 0,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040083', end: '040085' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040086', end: '040086' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040300', end: '040329' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040330', end: '040334' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040330', end: '040334' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040335', end: '040339' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040335', end: '040339' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040340', end: '040340' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040344', end: '040344' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040350', end: '040379' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040390', end: '040393' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '040400', end: '041311' },
      algorithm: 'DBLAL',
      weight: {
        u: 1,
        v: 3,
        w: 4,
        x: 3,
        y: 9,
        z: 3,
        a: 1,
        b: 7,
        c: 5,
        d: 5,
        e: 4,
        f: 5,
        g: 2,
        h: 4
      },
      exception: null
    },
    {
      sortCodeRange: { start: '041312', end: '041312' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '041313', end: '041316' },
      algorithm: 'DBLAL',
      weight: {
        u: 1,
        v: 3,
        w: 4,
        x: 3,
        y: 9,
        z: 3,
        a: 1,
        b: 7,
        c: 5,
        d: 5,
        e: 4,
        f: 5,
        g: 2,
        h: 4
      },
      exception: null
    },
    {
      sortCodeRange: { start: '041317', end: '041319' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '041320', end: '041399' },
      algorithm: 'DBLAL',
      weight: {
        u: 1,
        v: 3,
        w: 4,
        x: 3,
        y: 9,
        z: 3,
        a: 1,
        b: 7,
        c: 5,
        d: 5,
        e: 4,
        f: 5,
        g: 2,
        h: 4
      },
      exception: null
    },
    {
      sortCodeRange: { start: '041400', end: '041449' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 2,
        w: 0,
        x: 0,
        y: 9,
        z: 1,
        a: 2,
        b: 8,
        c: 4,
        d: 3,
        e: 7,
        f: 5,
        g: 6,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '041900', end: '042099' },
      algorithm: 'MOD10',
      weight: {
        u: 1,
        v: 3,
        w: 4,
        x: 3,
        y: 9,
        z: 3,
        a: 1,
        b: 7,
        c: 5,
        d: 5,
        e: 4,
        f: 5,
        g: 2,
        h: 4
      },
      exception: null
    },
    {
      sortCodeRange: { start: '042100', end: '042899' },
      algorithm: 'MOD11',
      weight: {
        u: 1,
        v: 3,
        w: 4,
        x: 3,
        y: 9,
        z: 3,
        a: 1,
        b: 7,
        c: 5,
        d: 5,
        e: 4,
        f: 5,
        g: 2,
        h: 4
      },
      exception: null
    },
    {
      sortCodeRange: { start: '042900', end: '042909' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '042927', end: '042956' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '042957', end: '042957' },
      algorithm: 'MOD11',
      weight: {
        u: 6,
        v: 5,
        w: 4,
        x: 3,
        y: 2,
        z: 1,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '043926', end: '043935' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '043926', end: '043935' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '044001', end: '044001' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 2,
        w: 1,
        x: 2,
        y: 0,
        z: 7,
        a: 1,
        b: 1,
        c: 0,
        d: 3,
        e: 8,
        f: 1,
        g: 9,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '050000', end: '050020' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 1,
        c: 7,
        d: 5,
        e: 8,
        f: 2,
        g: 4,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '050022', end: '050094' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 1,
        c: 7,
        d: 5,
        e: 8,
        f: 2,
        g: 4,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '050096', end: '058999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 1,
        c: 7,
        d: 5,
        e: 8,
        f: 2,
        g: 4,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '070030', end: '070030' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '070040', end: '070040' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '070055', end: '070055' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '070066', end: '070066' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '070116', end: '070116' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: '12'
    },
    {
      sortCodeRange: { start: '070116', end: '070116' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 3,
        w: 2,
        x: 4,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: '13'
    },
    {
      sortCodeRange: { start: '070246', end: '070246' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '070436', end: '070436' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '070806', end: '070806' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '070976', end: '070976' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071040', end: '071040' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071096', end: '071096' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071120', end: '071120' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071226', end: '071226' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071306', end: '071306' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071310', end: '071310' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071350', end: '071350' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071490', end: '071490' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071520', end: '071520' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071660', end: '071660' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '071986', end: '071986' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '074456', end: '074456' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 7,
        x: 6,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: '12'
    },
    {
      sortCodeRange: { start: '074456', end: '074456' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 3,
        w: 2,
        x: 4,
        y: 5,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: '13'
    },
    {
      sortCodeRange: { start: '080211', end: '080211' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '080228', end: '080228' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '086001', end: '086001' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '086020', end: '086020' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '086086', end: '086086' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 8,
        a: 9,
        b: 4,
        c: 5,
        d: 6,
        e: 7,
        f: 8,
        g: 9,
        h: -1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '086090', end: '086090' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: '8'
    },
    {
      sortCodeRange: { start: '086119', end: '086119' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '12'
    },
    {
      sortCodeRange: { start: '086119', end: '086119' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 3,
        c: 1,
        d: 0,
        e: 5,
        f: 2,
        g: 6,
        h: 1
      },
      exception: '13'
    },
    {
      sortCodeRange: { start: '089000', end: '089999' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090013', end: '090013' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090105', end: '090105' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090118', end: '090118' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090120', end: '090125' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090126', end: '090129' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090130', end: '090130' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090131', end: '090136' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090150', end: '090156' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090180', end: '090185' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090190', end: '090196' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090204', end: '090204' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090222', end: '090222' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090356', end: '090356' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090500', end: '090599' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090704', end: '090704' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090705', end: '090705' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090710', end: '090710' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090715', end: '090715' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090720', end: '090726' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090736', end: '090739' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '090790', end: '090790' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '091600', end: '091600' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 1,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '091601', end: '091601' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 7,
        y: 1,
        z: 3,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '091740', end: '091743' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 1,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '091800', end: '091809' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 1,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '091811', end: '091865' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 1,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '100000', end: '101099' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '101101', end: '101498' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '101500', end: '101999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '102400', end: '107999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '108000', end: '108079' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '108080', end: '108099' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '108100', end: '109999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '110000', end: '119280' },
      algorithm: 'DBLAL',
      weight: {
        u: 0,
        v: 0,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '1'
    },
    {
      sortCodeRange: { start: '119282', end: '119283' },
      algorithm: 'DBLAL',
      weight: {
        u: 0,
        v: 0,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '1'
    },
    {
      sortCodeRange: { start: '119285', end: '119999' },
      algorithm: 'DBLAL',
      weight: {
        u: 0,
        v: 0,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '1'
    },
    {
      sortCodeRange: { start: '120000', end: '120961' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '120963', end: '122009' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '122011', end: '122101' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '122103', end: '122129' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '122131', end: '122135' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '122213', end: '122299' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '122400', end: '122999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '124000', end: '124999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '133000', end: '133999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 10,
        a: 7,
        b: 8,
        c: 4,
        d: 6,
        e: 3,
        f: 5,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '134012', end: '134020' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 7,
        y: 5,
        z: 9,
        a: 8,
        b: 4,
        c: 6,
        d: 3,
        e: 5,
        f: 2,
        g: 0,
        h: 0
      },
      exception: '4'
    },
    {
      sortCodeRange: { start: '134121', end: '134121' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 1,
        y: 0,
        z: 0,
        a: 8,
        b: 4,
        c: 6,
        d: 3,
        e: 5,
        f: 2,
        g: 0,
        h: 0
      },
      exception: '4'
    },
    {
      sortCodeRange: { start: '150000', end: '158000' },
      algorithm: 'MOD11',
      weight: {
        u: 4,
        v: 3,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '159800', end: '159800' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '159900', end: '159900' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '159910', end: '159910' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '160000', end: '161027' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161029', end: '161029' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161030', end: '161041' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161050', end: '161050' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161055', end: '161055' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161060', end: '161060' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161065', end: '161065' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161070', end: '161070' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161075', end: '161075' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161080', end: '161080' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161085', end: '161085' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161090', end: '161090' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '161100', end: '162028' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '162030', end: '164300' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '165901', end: '166001' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '166050', end: '167600' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 6,
        x: 5,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '168600', end: '168600' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '180002', end: '180002' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '180005', end: '180005' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '180009', end: '180009' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '180036', end: '180036' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '180038', end: '180038' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '180091', end: '180092' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '180104', end: '180104' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '180109', end: '180110' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '180156', end: '180156' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '185001', end: '185001' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '14'
    },
    {
      sortCodeRange: { start: '185003', end: '185003' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '185004', end: '185004' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '185005', end: '185009' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '185011', end: '185025' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '185027', end: '185099' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '200000', end: '200002' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200000', end: '200002' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200004', end: '200004' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200004', end: '200004' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200026', end: '200026' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200026', end: '200026' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200051', end: '200077' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200051', end: '200077' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200079', end: '200097' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200079', end: '200097' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200099', end: '200156' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200099', end: '200156' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200158', end: '200387' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200158', end: '200387' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200403', end: '200405' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200403', end: '200405' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200407', end: '200407' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200407', end: '200407' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200411', end: '200412' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200411', end: '200412' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200414', end: '200423' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200414', end: '200423' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200425', end: '200899' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200425', end: '200899' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200901', end: '201159' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '200901', end: '201159' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '201161', end: '201177' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '201161', end: '201177' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '201179', end: '201351' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '201179', end: '201351' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '201353', end: '202698' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '201353', end: '202698' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '202700', end: '203239' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '202700', end: '203239' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '203241', end: '203255' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '203241', end: '203255' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '203259', end: '203519' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '203259', end: '203519' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '203521', end: '204476' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '203521', end: '204476' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '204478', end: '205475' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '204478', end: '205475' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '205477', end: '205954' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '205477', end: '205954' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '205956', end: '206124' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '205956', end: '206124' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206126', end: '206157' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206126', end: '206157' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206159', end: '206390' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206159', end: '206390' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206392', end: '206799' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206392', end: '206799' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206802', end: '206874' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206802', end: '206874' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206876', end: '207170' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '206876', end: '207170' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '207173', end: '208092' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '207173', end: '208092' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '208094', end: '208721' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '208094', end: '208721' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '208723', end: '209034' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '208723', end: '209034' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '209036', end: '209128' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '209036', end: '209128' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '209130', end: '209999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '209130', end: '209999' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '6'
    },
    {
      sortCodeRange: { start: '230088', end: '230088' },
      algorithm: 'MOD10',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 7,
        c: 4,
        d: 5,
        e: 6,
        f: 3,
        g: 8,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230120', end: '230120' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 7,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230121', end: '230121' },
      algorithm: 'MOD11',
      weight: {
        u: 8,
        v: 7,
        w: 1,
        x: 5,
        y: 8,
        z: 6,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 5,
        f: 4,
        g: 9,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230122', end: '230122' },
      algorithm: 'MOD11',
      weight: {
        u: 8,
        v: 7,
        w: 1,
        x: 5,
        y: 8,
        z: 6,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 5,
        f: 4,
        g: 9,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230338', end: '230338' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230338', end: '230338' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230363', end: '230363' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230364', end: '230364' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230365', end: '230365' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230366', end: '230366' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230367', end: '230367' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230505', end: '230505' },
      algorithm: 'MOD11',
      weight: {
        u: 9,
        v: 8,
        w: 7,
        x: 6,
        y: 5,
        z: 4,
        a: 9,
        b: 8,
        c: 7,
        d: 6,
        e: 5,
        f: 4,
        g: 3,
        h: 2
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230580', end: '230580' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '12'
    },
    {
      sortCodeRange: { start: '230580', end: '230580' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '13'
    },
    {
      sortCodeRange: { start: '230614', end: '230614' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230614', end: '230614' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230709', end: '230709' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230709', end: '230709' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230872', end: '230872' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230872', end: '230872' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230933', end: '230933' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '230933', end: '230933' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231018', end: '231018' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231018', end: '231018' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231185', end: '231185' },
      algorithm: 'MOD11',
      weight: {
        u: 4,
        v: 8,
        w: 1,
        x: 6,
        y: 5,
        z: 8,
        a: 1,
        b: 9,
        c: 5,
        d: 1,
        e: 8,
        f: 1,
        g: 7,
        h: 3
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231213', end: '231213' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231213', end: '231213' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231228', end: '231228' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231228', end: '231228' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231354', end: '231354' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231354', end: '231354' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231469', end: '231469' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231469', end: '231469' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231470', end: '231470' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 20,
        x: 18,
        y: 1,
        z: 14,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        f: 0,
        g: 0,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231536', end: '231536' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231536', end: '231536' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231558', end: '231558' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231558', end: '231558' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231618', end: '231618' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231618', end: '231618' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231679', end: '231679' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231679', end: '231679' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231843', end: '231843' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231843', end: '231843' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231985', end: '231985' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '231985', end: '231985' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232130', end: '232130' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232130', end: '232130' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232279', end: '232279' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232279', end: '232279' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232283', end: '232283' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232283', end: '232283' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232290', end: '232290' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232445', end: '232445' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232445', end: '232445' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232507', end: '232507' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232571', end: '232571' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232571', end: '232571' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232636', end: '232636' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232636', end: '232636' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232704', end: '232704' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232704', end: '232704' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232725', end: '232725' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232725', end: '232725' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232813', end: '232813' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232813', end: '232813' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232939', end: '232939' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '232939', end: '232939' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233080', end: '233080' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233080', end: '233080' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233135', end: '233135' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233135', end: '233135' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233142', end: '233142' },
      algorithm: 'MOD10',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 30,
        b: 36,
        c: 24,
        d: 20,
        e: 16,
        f: 12,
        g: 8,
        h: 4
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233171', end: '233171' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233171', end: '233171' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233188', end: '233188' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233188', end: '233188' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233231', end: '233231' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233231', end: '233231' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233344', end: '233344' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233344', end: '233344' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233438', end: '233438' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233438', end: '233438' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233456', end: '233456' },
      algorithm: 'MOD10',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 0,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233483', end: '233483' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233556', end: '233556' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233556', end: '233556' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233658', end: '233658' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233658', end: '233658' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233693', end: '233693' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233693', end: '233693' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233752', end: '233752' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '233752', end: '233752' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234035', end: '234035' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234035', end: '234035' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234036', end: '234036' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234036', end: '234036' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234037', end: '234037' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234037', end: '234037' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234081', end: '234081' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234081', end: '234081' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234193', end: '234193' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234193', end: '234193' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234252', end: '234252' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234252', end: '234252' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234321', end: '234321' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234321', end: '234321' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234377', end: '234377' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234377', end: '234377' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234570', end: '234570' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234570', end: '234570' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234666', end: '234666' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234666', end: '234666' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234779', end: '234779' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234779', end: '234779' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234828', end: '234828' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234828', end: '234828' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234985', end: '234985' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '234985', end: '234985' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235054', end: '235054' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235054', end: '235054' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235164', end: '235164' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235164', end: '235164' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235262', end: '235262' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235262', end: '235262' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235323', end: '235323' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235323', end: '235323' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235451', end: '235451' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235459', end: '235459' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235459', end: '235459' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235519', end: '235519' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235519', end: '235519' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235676', end: '235676' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235676', end: '235676' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235711', end: '235711' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235711', end: '235711' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235756', end: '235756' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235756', end: '235756' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235889', end: '235889' },
      algorithm: 'MOD10',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 0,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235945', end: '235945' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '235945', end: '235945' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236006', end: '236006' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236006', end: '236006' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236119', end: '236119' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236119', end: '236119' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236233', end: '236233' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236233', end: '236233' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236247', end: '236247' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236293', end: '236293' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236293', end: '236293' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236422', end: '236422' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236422', end: '236422' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236527', end: '236527' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236527', end: '236527' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236538', end: '236538' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236538', end: '236538' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236643', end: '236643' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236643', end: '236643' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236761', end: '236761' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236761', end: '236761' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236802', end: '236802' },
      algorithm: 'MOD11',
      weight: {
        u: 9,
        v: 8,
        w: 7,
        x: 6,
        y: 5,
        z: 4,
        a: 9,
        b: 8,
        c: 7,
        d: 6,
        e: 5,
        f: 4,
        g: 3,
        h: 2
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236907', end: '236907' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236907', end: '236907' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '236972', end: '236972' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237130', end: '237130' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237130', end: '237130' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237265', end: '237265' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237265', end: '237265' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237355', end: '237355' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237355', end: '237355' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237423', end: '237423' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237423', end: '237423' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237427', end: '237427' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237427', end: '237427' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237563', end: '237563' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237563', end: '237563' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237622', end: '237622' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237622', end: '237622' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237728', end: '237728' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237728', end: '237728' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237873', end: '237873' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '237873', end: '237873' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238020', end: '238020' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238020', end: '238020' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238043', end: '238043' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238043', end: '238043' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238051', end: '238051' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238051', end: '238051' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238175', end: '238175' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238175', end: '238175' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238257', end: '238257' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238257', end: '238257' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238392', end: '238431' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238392', end: '238431' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238432', end: '238432' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238432', end: '238432' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238433', end: '238583' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238433', end: '238583' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238585', end: '238590' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238585', end: '238590' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238599', end: '238599' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238599', end: '238599' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238613', end: '238613' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238613', end: '238613' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238672', end: '238672' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238672', end: '238672' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238717', end: '238717' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238717', end: '238717' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238890', end: '238899' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 4,
        z: 3,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238908', end: '238908' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '238908', end: '238908' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239071', end: '239071' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239071', end: '239071' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239126', end: '239126' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239126', end: '239126' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239136', end: '239140' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239136', end: '239140' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239143', end: '239144' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239143', end: '239144' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239282', end: '239283' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239282', end: '239283' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239285', end: '239294' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239285', end: '239294' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239295', end: '239295' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239295', end: '239295' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239296', end: '239318' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239296', end: '239318' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239360', end: '239360' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239360', end: '239360' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239380', end: '239380' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239380', end: '239380' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239435', end: '239435' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239435', end: '239435' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239525', end: '239525' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239525', end: '239525' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239642', end: '239642' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239642', end: '239642' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239751', end: '239751' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '239751', end: '239751' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '300000', end: '300006' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '300000', end: '300006' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '300008', end: '300009' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '300008', end: '300009' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '300050', end: '300051' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '300134', end: '300138' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '300134', end: '300138' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '300161', end: '300161' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '300176', end: '300176' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301001', end: '301001' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301001', end: '301001' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301004', end: '301004' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301004', end: '301004' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301007', end: '301007' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301007', end: '301007' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301012', end: '301012' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301012', end: '301012' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301022', end: '301022' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301027', end: '301027' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301047', end: '301047' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301047', end: '301047' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301049', end: '301049' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301049', end: '301049' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301052', end: '301052' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301052', end: '301052' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301075', end: '301076' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301075', end: '301076' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301108', end: '301108' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301108', end: '301108' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301112', end: '301112' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301112', end: '301112' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301127', end: '301127' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301127', end: '301127' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301137', end: '301137' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301142', end: '301142' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301148', end: '301148' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301148', end: '301148' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301154', end: '301155' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301161', end: '301161' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301161', end: '301161' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301166', end: '301166' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301170', end: '301170' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301174', end: '301175' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301174', end: '301175' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301191', end: '301191' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301191', end: '301191' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301194', end: '301195' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301194', end: '301195' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301204', end: '301205' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301204', end: '301205' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301209', end: '301210' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301209', end: '301210' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301215', end: '301215' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301215', end: '301215' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301218', end: '301218' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301218', end: '301218' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301220', end: '301221' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301220', end: '301221' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301234', end: '301234' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301234', end: '301234' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301251', end: '301251' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301251', end: '301251' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301259', end: '301259' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301259', end: '301259' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301274', end: '301274' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301274', end: '301274' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301280', end: '301280' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301280', end: '301280' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301286', end: '301286' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301286', end: '301286' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301295', end: '301296' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301295', end: '301296' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301299', end: '301299' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301299', end: '301299' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301301', end: '301301' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301301', end: '301301' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301305', end: '301305' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301305', end: '301305' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301318', end: '301318' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301318', end: '301318' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301330', end: '301330' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301330', end: '301330' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301332', end: '301332' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301332', end: '301332' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301335', end: '301335' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301335', end: '301335' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301342', end: '301342' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301342', end: '301342' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301350', end: '301355' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301350', end: '301355' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301364', end: '301364' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301364', end: '301364' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301368', end: '301368' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301368', end: '301368' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301376', end: '301376' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301376', end: '301376' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301380', end: '301380' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301380', end: '301380' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301388', end: '301388' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301388', end: '301388' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301390', end: '301390' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301390', end: '301390' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301395', end: '301395' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301395', end: '301395' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301400', end: '301400' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301400', end: '301400' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301424', end: '301424' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301424', end: '301424' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301432', end: '301432' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301432', end: '301432' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301433', end: '301433' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301435', end: '301435' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301437', end: '301437' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301437', end: '301437' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301439', end: '301439' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301440', end: '301440' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301440', end: '301440' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301443', end: '301443' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301444', end: '301444' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301444', end: '301444' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301447', end: '301447' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301447', end: '301447' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301451', end: '301451' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301451', end: '301451' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301456', end: '301456' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301456', end: '301456' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301458', end: '301458' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301460', end: '301460' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301460', end: '301460' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301463', end: '301463' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301464', end: '301464' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301464', end: '301464' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301466', end: '301466' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301469', end: '301469' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301469', end: '301469' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301471', end: '301471' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301471', end: '301471' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301474', end: '301474' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301477', end: '301477' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301477', end: '301477' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301482', end: '301482' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301483', end: '301483' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301483', end: '301483' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301485', end: '301485' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301487', end: '301487' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301504', end: '301504' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301504', end: '301504' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301510', end: '301510' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301514', end: '301514' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301517', end: '301517' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301525', end: '301525' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301539', end: '301539' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301539', end: '301539' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301542', end: '301542' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301542', end: '301542' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301552', end: '301553' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301552', end: '301553' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301557', end: '301557' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301557', end: '301557' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301573', end: '301573' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301593', end: '301593' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301593', end: '301593' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301595', end: '301595' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301595', end: '301595' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301597', end: '301597' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301597', end: '301597' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301599', end: '301599' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301599', end: '301599' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301607', end: '301607' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301609', end: '301609' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301609', end: '301609' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301611', end: '301611' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301611', end: '301611' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301620', end: '301620' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301620', end: '301620' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301628', end: '301628' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301628', end: '301628' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301634', end: '301634' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301634', end: '301634' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301641', end: '301642' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301641', end: '301642' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301653', end: '301653' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301653', end: '301653' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301657', end: '301657' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301662', end: '301662' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301662', end: '301662' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301664', end: '301664' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301664', end: '301664' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301670', end: '301670' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301670', end: '301670' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301674', end: '301674' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301674', end: '301674' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301684', end: '301684' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301684', end: '301684' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301695', end: '301696' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301695', end: '301696' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301700', end: '301702' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301700', end: '301702' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301705', end: '301705' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '301712', end: '301712' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301712', end: '301712' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301716', end: '301716' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301716', end: '301716' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301748', end: '301748' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301748', end: '301748' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301773', end: '301773' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301773', end: '301773' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301777', end: '301777' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301777', end: '301777' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301780', end: '301780' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301780', end: '301780' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301785', end: '301785' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301785', end: '301785' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301803', end: '301803' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301803', end: '301803' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301805', end: '301805' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301805', end: '301805' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301806', end: '301806' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301806', end: '301806' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301816', end: '301816' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301816', end: '301816' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301825', end: '301825' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301825', end: '301825' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301830', end: '301830' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301830', end: '301830' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301834', end: '301834' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301834', end: '301834' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301843', end: '301843' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301843', end: '301843' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301845', end: '301845' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301845', end: '301845' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301855', end: '301856' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301855', end: '301856' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301864', end: '301864' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301864', end: '301864' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301868', end: '301869' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301868', end: '301869' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301883', end: '301883' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301883', end: '301883' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301886', end: '301888' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301886', end: '301888' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301898', end: '301898' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301898', end: '301898' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '301914', end: '301996' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '301914', end: '301996' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '302500', end: '302500' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '302500', end: '302500' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '302556', end: '302556' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '302556', end: '302556' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '302579', end: '302580' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '302579', end: '302580' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '302880', end: '302880' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '303460', end: '303461' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '303460', end: '303461' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '303996', end: '303996' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '304065', end: '304067' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '305907', end: '305939' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '305907', end: '305939' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '305941', end: '305960' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '305941', end: '305960' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '305971', end: '305971' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '305971', end: '305971' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '305974', end: '305974' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '305974', end: '305974' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '305978', end: '305978' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '305978', end: '305978' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '305982', end: '305982' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '305982', end: '305982' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '305984', end: '305988' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '305984', end: '305988' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '305990', end: '305993' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '305990', end: '305993' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306017', end: '306018' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306017', end: '306018' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306020', end: '306020' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306020', end: '306020' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306028', end: '306028' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306028', end: '306028' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306038', end: '306038' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306038', end: '306038' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306150', end: '306151' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306150', end: '306151' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306154', end: '306155' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306154', end: '306155' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306228', end: '306228' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306228', end: '306228' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306229', end: '306229' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306229', end: '306229' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306232', end: '306232' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306232', end: '306232' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306242', end: '306242' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306242', end: '306242' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306245', end: '306245' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306245', end: '306245' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306249', end: '306249' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306249', end: '306249' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306255', end: '306255' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306255', end: '306255' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306259', end: '306263' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306259', end: '306263' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306272', end: '306279' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306272', end: '306279' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306281', end: '306281' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306281', end: '306281' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306289', end: '306289' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306289', end: '306289' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306296', end: '306296' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306296', end: '306296' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306299', end: '306299' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306299', end: '306299' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306300', end: '306300' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306300', end: '306300' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306347', end: '306347' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306347', end: '306347' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306354', end: '306355' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306354', end: '306355' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306357', end: '306357' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306357', end: '306357' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306359', end: '306359' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306359', end: '306359' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306364', end: '306364' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306364', end: '306364' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306394', end: '306394' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306394', end: '306394' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306397', end: '306397' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306397', end: '306397' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306410', end: '306410' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306410', end: '306410' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306412', end: '306412' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306412', end: '306412' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306414', end: '306415' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306414', end: '306415' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306418', end: '306419' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306418', end: '306419' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306422', end: '306422' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306422', end: '306422' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306434', end: '306434' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306434', end: '306434' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306437', end: '306438' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306437', end: '306438' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306442', end: '306444' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306442', end: '306444' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306457', end: '306457' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306457', end: '306457' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306472', end: '306472' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306472', end: '306472' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306479', end: '306479' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306479', end: '306479' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306497', end: '306497' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306497', end: '306497' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306521', end: '306522' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306521', end: '306522' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306537', end: '306539' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306537', end: '306539' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306541', end: '306541' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306541', end: '306541' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306549', end: '306549' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306549', end: '306549' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306562', end: '306565' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306562', end: '306565' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306572', end: '306572' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306572', end: '306572' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306585', end: '306586' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306585', end: '306586' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306592', end: '306593' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306592', end: '306593' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306675', end: '306677' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306675', end: '306677' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306689', end: '306689' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306689', end: '306689' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306695', end: '306696' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306695', end: '306696' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306733', end: '306735' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306733', end: '306735' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306747', end: '306749' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306747', end: '306749' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306753', end: '306753' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306753', end: '306753' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306756', end: '306756' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306756', end: '306756' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306759', end: '306759' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306759', end: '306759' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306762', end: '306762' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306762', end: '306762' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306764', end: '306764' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306764', end: '306764' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306766', end: '306767' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306766', end: '306767' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306769', end: '306769' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306769', end: '306769' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306772', end: '306772' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306772', end: '306772' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306775', end: '306776' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306775', end: '306776' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306779', end: '306779' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306779', end: '306779' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306782', end: '306782' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306782', end: '306782' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306788', end: '306789' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306788', end: '306789' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '306799', end: '306799' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '306799', end: '306799' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '307184', end: '307184' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '307184', end: '307184' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '307188', end: '307190' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '307188', end: '307190' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '307198', end: '307198' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '307198', end: '307198' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '307271', end: '307271' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '307271', end: '307271' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '307274', end: '307274' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '307274', end: '307274' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '307654', end: '307654' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '307654', end: '307654' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '307779', end: '307779' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '307779', end: '307779' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '307788', end: '307789' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '307788', end: '307789' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '307809', end: '307809' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '307809', end: '307809' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308012', end: '308012' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308012', end: '308012' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308016', end: '308016' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308016', end: '308016' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308026', end: '308027' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308026', end: '308027' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308033', end: '308034' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308033', end: '308034' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308037', end: '308037' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308037', end: '308037' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308042', end: '308042' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308042', end: '308042' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308045', end: '308045' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308045', end: '308045' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308048', end: '308049' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308048', end: '308049' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308054', end: '308055' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308054', end: '308055' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308063', end: '308063' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308063', end: '308063' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308076', end: '308077' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308076', end: '308077' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308082', end: '308083' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308082', end: '308083' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308085', end: '308085' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308085', end: '308085' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308087', end: '308089' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308087', end: '308089' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308095', end: '308097' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308095', end: '308097' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308404', end: '308404' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308404', end: '308404' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308412', end: '308412' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308412', end: '308412' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308420', end: '308427' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308420', end: '308427' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308433', end: '308434' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308433', end: '308434' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308441', end: '308446' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308441', end: '308446' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308448', end: '308448' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308448', end: '308448' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308451', end: '308454' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308451', end: '308454' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308457', end: '308459' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308457', end: '308459' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308462', end: '308463' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308462', end: '308463' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308467', end: '308469' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308467', end: '308469' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308472', end: '308473' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308472', end: '308473' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308475', end: '308477' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308475', end: '308477' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308479', end: '308479' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308479', end: '308479' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308482', end: '308482' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308482', end: '308482' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308484', end: '308487' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308484', end: '308487' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308784', end: '308784' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308784', end: '308784' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308804', end: '308804' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308804', end: '308804' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308822', end: '308822' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308822', end: '308822' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '308952', end: '308952' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '308952', end: '308952' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '309001', end: '309633' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '309001', end: '309633' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '309634', end: '309634' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '309635', end: '309746' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '309635', end: '309746' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '309748', end: '309871' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '309748', end: '309871' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '309873', end: '309915' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '309873', end: '309915' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '309917', end: '309999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 5,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '2'
    },
    {
      sortCodeRange: { start: '309917', end: '309999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 3,
        x: 2,
        y: 9,
        z: 8,
        a: 1,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '9'
    },
    {
      sortCodeRange: { start: '400000', end: '400193' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '400000', end: '400193' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '400194', end: '400195' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '400194', end: '400195' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '400196', end: '400514' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '400196', end: '400514' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '400515', end: '400515' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 5,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '400516', end: '401054' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '400516', end: '401054' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401055', end: '401055' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 5,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401056', end: '401198' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401056', end: '401198' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401199', end: '401199' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 5,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401200', end: '401265' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401200', end: '401265' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401266', end: '401266' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 5,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401267', end: '401275' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401267', end: '401275' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401276', end: '401279' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 5,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401280', end: '401899' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401280', end: '401899' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401900', end: '401900' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 5,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401901', end: '401949' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401901', end: '401949' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401950', end: '401950' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 5,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401951', end: '404374' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '401951', end: '404374' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '404375', end: '404384' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 5,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '404385', end: '404799' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '404385', end: '404799' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '406420', end: '406420' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '406453', end: '406453' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '406466', end: '406466' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '500000', end: '501029' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '502101', end: '560070' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '600000', end: '600108' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '600110', end: '600124' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '600127', end: '600142' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '600144', end: '600149' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '600180', end: '600304' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '600307', end: '600312' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '600314', end: '600355' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '600357', end: '600851' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '600901', end: '601360' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '601403', end: '608028' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '608301', end: '608301' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '608316', end: '608316' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '608370', end: '608370' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '608371', end: '608371' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 2,
        b: 8,
        c: 4,
        d: 3,
        e: 7,
        f: 5,
        g: 6,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '608384', end: '608384' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 9,
        z: 8,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '608385', end: '608385' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '608387', end: '608389' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '608400', end: '608400' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '608410', end: '608410' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '609593', end: '609593' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 1,
        c: 3,
        d: 7,
        e: 1,
        f: 3,
        g: 7,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '609599', end: '609599' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 5,
        c: 7,
        d: 5,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '640001', end: '640001' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '720000', end: '720249' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '720251', end: '724443' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '725000', end: '725251' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '725253', end: '725616' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '726000', end: '726616' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '728990', end: '728999' },
      algorithm: 'MOD10',
      weight: {
        u: 3,
        v: 1,
        w: 7,
        x: 3,
        y: 1,
        z: 7,
        a: 3,
        b: 1,
        c: 7,
        d: 3,
        e: 1,
        f: 7,
        g: 3,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '770100', end: '771799' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '771877', end: '771877' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '771900', end: '772799' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '772813', end: '772817' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '772901', end: '773999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '774100', end: '774599' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '774700', end: '774830' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '774832', end: '777789' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '777791', end: '777999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '778001', end: '778001' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '778300', end: '778799' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '778855', end: '778855' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '778900', end: '779174' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '779414', end: '779999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '7'
    },
    {
      sortCodeRange: { start: '800000', end: '802005' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '802007', end: '802042' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '802044', end: '802065' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '802067', end: '802109' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '802111', end: '802114' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '802116', end: '802123' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '802151', end: '802154' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '802156', end: '802179' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '802181', end: '803599' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '803609', end: '819999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 8,
        y: 2,
        z: 6,
        a: 3,
        b: 7,
        c: 9,
        d: 5,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '820000', end: '826097' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 0,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '820000', end: '826097' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '3'
    },
    {
      sortCodeRange: { start: '826099', end: '826917' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 0,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '826099', end: '826917' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '3'
    },
    {
      sortCodeRange: { start: '826919', end: '827999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 0,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '826919', end: '827999' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '3'
    },
    {
      sortCodeRange: { start: '829000', end: '829999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 0,
        c: 7,
        d: 3,
        e: 4,
        f: 9,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '829000', end: '829999' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: '3'
    },
    {
      sortCodeRange: { start: '830000', end: '835700' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836500', end: '836501' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836505', end: '836506' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836510', end: '836510' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836515', end: '836515' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836530', end: '836530' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836535', end: '836535' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836540', end: '836540' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836560', end: '836560' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836565', end: '836565' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836570', end: '836570' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836585', end: '836585' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836590', end: '836590' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836595', end: '836595' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836620', end: '836620' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836625', end: '836625' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '836630', end: '836630' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '837550', end: '837550' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '837560', end: '837560' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '837570', end: '837570' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '837580', end: '837580' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 4,
        x: 3,
        y: 2,
        z: 7,
        a: 2,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '839105', end: '839106' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '839105', end: '839106' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '839130', end: '839131' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '839130', end: '839131' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '839147', end: '839147' },
      algorithm: 'MOD10',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 5,
        c: 7,
        d: 5,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '870000', end: '872791' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '870000', end: '872791' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '872793', end: '876899' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '872793', end: '876899' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '876919', end: '876919' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '876919', end: '876919' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '876921', end: '876923' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '876921', end: '876923' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '876925', end: '876932' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '876925', end: '876932' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '876935', end: '876935' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '876935', end: '876935' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '876951', end: '876951' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '876951', end: '876951' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '876953', end: '876955' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '876953', end: '876955' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '876957', end: '876957' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '876957', end: '876957' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '876961', end: '876965' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '876961', end: '876965' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '877000', end: '877070' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '877000', end: '877070' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '877071', end: '877071' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '877071', end: '877071' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '877078', end: '877078' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '877078', end: '877078' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '877088', end: '877088' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '877088', end: '877088' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '877090', end: '877090' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '877090', end: '877090' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '877098', end: '877098' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '877098', end: '877098' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '877099', end: '879999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 1,
        x: 2,
        y: 5,
        z: 3,
        a: 6,
        b: 4,
        c: 8,
        d: 7,
        e: 10,
        f: 9,
        g: 3,
        h: 1
      },
      exception: '10'
    },
    {
      sortCodeRange: { start: '877099', end: '879999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 5,
        x: 10,
        y: 9,
        z: 8,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: '11'
    },
    {
      sortCodeRange: { start: '890000', end: '890699' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '891000', end: '891616' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '892000', end: '892616' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 9,
        a: 8,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '900000', end: '902396' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '900000', end: '902396' },
      algorithm: 'MOD11',
      weight: {
        u: 32,
        v: 16,
        w: 8,
        x: 4,
        y: 2,
        z: 1,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        f: 0,
        g: 0,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '902398', end: '909999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 128,
        b: 64,
        c: 32,
        d: 16,
        e: 8,
        f: 4,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '902398', end: '909999' },
      algorithm: 'MOD11',
      weight: {
        u: 32,
        v: 16,
        w: 8,
        x: 4,
        y: 2,
        z: 1,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        f: 0,
        g: 0,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '938000', end: '938696' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 0,
        h: 0
      },
      exception: '5'
    },
    {
      sortCodeRange: { start: '938000', end: '938696' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 0
      },
      exception: '5'
    },
    {
      sortCodeRange: { start: '938698', end: '938999' },
      algorithm: 'MOD11',
      weight: {
        u: 7,
        v: 6,
        w: 5,
        x: 4,
        y: 3,
        z: 2,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 0,
        h: 0
      },
      exception: '5'
    },
    {
      sortCodeRange: { start: '938698', end: '938999' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 0
      },
      exception: '5'
    },
    {
      sortCodeRange: { start: '950000', end: '950002' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '950000', end: '950002' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '950004', end: '950479' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '950004', end: '950479' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '950500', end: '959999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 0,
        b: 7,
        c: 6,
        d: 5,
        e: 4,
        f: 3,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '950500', end: '959999' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '980000', end: '980004' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '980000', end: '980004' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '980006', end: '983000' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '980006', end: '983000' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '983003', end: '987000' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '983003', end: '987000' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    },
    {
      sortCodeRange: { start: '987004', end: '989999' },
      algorithm: 'MOD11',
      weight: {
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        a: 7,
        b: 6,
        c: 5,
        d: 4,
        e: 3,
        f: 2,
        g: 1,
        h: 0
      },
      exception: null
    },
    {
      sortCodeRange: { start: '987004', end: '989999' },
      algorithm: 'DBLAL',
      weight: {
        u: 2,
        v: 1,
        w: 2,
        x: 1,
        y: 2,
        z: 1,
        a: 2,
        b: 1,
        c: 2,
        d: 1,
        e: 2,
        f: 1,
        g: 2,
        h: 1
      },
      exception: null
    }
  ];
  exports.data = WeightTable_UK;
});

function Modcheck(accountNumber, sortCode) {
  this.accountNumber = accountNumber;
  this.sortCode = sortCode;
  this.weightTable = ukWeightTable.data;
  this.currentCheck = null;

  if (typeof this.sortCode === 'string' || this.sortCode instanceof String) {
    this.sortCode = this.sortCode.replace(/-/g, '');
  }

  this.getChecksFromWeightTable = function() {
    return this.weightTable.filter(function(check) {
      var sortCode = parseInt(this.sortCode),
        start = parseInt(check.sortCodeRange.start),
        end = parseInt(check.sortCodeRange.end);

      return sortCode >= start && sortCode <= end;
    }, this);
  };

  this.sortCodeExists = function() {
    return this.getChecksFromWeightTable().length > 0 ? true : false;
  };

  this.isValid = function() {
    if (
      parseInt(this.accountNumber) === 0 ||
      isNaN(parseInt(this.accountNumber)) ||
      parseInt(this.sortCode) === 0 ||
      isNaN(parseInt(this.sortCode))
    ) {
      return false;
    }

    var results = [];

    var checks = this.getChecksFromWeightTable();

    // if sortcode is not found in weighttable just consider the account valid
    if (checks.length === 0) {
      return true;
    }

    checks.forEach(function(check) {
      // implement exception 2 / 9 according to https://github.com/ball-hayden/uk_account_validator/blob/master/lib/uk_account_validator/exceptions/exception_2_9.rb
      if (
        this.currentCheck &&
        this.currentCheck.exception == '2' &&
        this.currentResult == true &&
        check.exception == '9'
      ) {
        return;
      }

      if (check.exception == '2') {
        if (check.weight[6] != 0 && check.weight[12] != 9) {
          check.weight = [0, 0, 1, 2, 5, 3, 6, 4, 8, 7, 10, 9, 3, 1];
        } else if (check.weight[6] != 0 && check.weight[12] == 9) {
          check.weight = [0, 0, 0, 0, 0, 0, 0, 0, 8, 7, 10, 9, 3, 1];
        }
      }

      this.currentCheck = check;

      this.weight = Object.keys(check.weight).map(function(k) {
        return check.weight[k];
      });

      switch (check.algorithm) {
        case 'DBLAL':
          results.push(this.dblAlCheck());
          break;
        case 'MOD10':
          results.push(this.mod10Check());
          break;
        case 'MOD11':
          var result = this.mod11Check();
          results.push(result);
          if (check.exception == '2') this.currentResult = result;
          break;
      }
    }, this);

    // if check 2 passes in a 2 / 9 exeption combination the bank account is considered valid
    if (
      checks[0]['exception'] == '2' &&
      checks[1]['exception'] == '9' &&
      results.length > 1 &&
      results[0] == false
    )
      return results[1];

    // if exception 12 / 13 (Nationwide) only one check has to succeed
    if (checks[0]['exception'] == '12' && checks[1]['exception'] == '13')
      return results[0] || results[1];

    // if exception 10 / 11 only one check has to succeed
    if (checks[0]['exception'] == '10' && checks[1]['exception'] == '11')
      return results[0] || results[1];

    // One fail causes entire check to fail
    var passed = results.reduce(function(prev, cur) {
      return cur !== true ? false : prev;
    }, true);

    return passed;
  };

  this.dblAlCheck = function() {
    var account = this.sortCode + this.accountNumber;
    var weightedAccount = [];

    for (var i = 0; i < 14; i++) {
      weightedAccount[i] = parseInt(account[i]) * parseInt(this.weight[i]);
    }

    weightedAccount = weightedAccount.join('').split('');

    var sum = weightedAccount.reduce(function(a, b) {
      return parseInt(a) + parseInt(b);
    });

    if (this.currentCheck.exception == 1) {
      sum += 27;
    }

    return sum % 10 === 0;
  };

  this.mod10Check = function() {
    var account = this.sortCode + this.accountNumber;
    var weightedAccount = [];

    for (var i = 0; i < 14; i++) {
      weightedAccount[i] = parseInt(account[i]) * parseInt(this.weight[i]);
    }

    var sum = weightedAccount.reduce(function(a, b) {
      return parseInt(a) + parseInt(b);
    });

    return sum % 10 === 0;
  };

  this.mod11Check = function() {
    var account = this.sortCode + this.accountNumber;
    var weightedAccount = [];

    for (var i = 0; i < 14; i++) {
      weightedAccount[i] = parseInt(account[i]) * parseInt(this.weight[i]);
    }

    var sum = weightedAccount.reduce(function(a, b) {
      return parseInt(a) + parseInt(b);
    });

    return sum % 11 === 0;
  };
}

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports'], factory);
  } else if (
    typeof exports === 'object' &&
    typeof exports.nodeName !== 'string'
  ) {
    // CommonJS
    factory(exports);
  } else {
    // Browser globals
    factory((root.UK_SortCode = {}));
  }
})(this, function(exports) {
  /**
   * An object containing all the functions.
   */
  exports.formatUkSortCode = formatUkSortCode;
  exports.validateUkSortCodeExists = validateUkSortCodeExists;
  exports.validateUkAccountNumber = validateUkAccountNumber;
  exports.getBankNameBySortCode = getBankNameBySortCode;

  ///////////////

  var bankData = ukBankData;

  function formatUkSortCode(sortCode) {
    var formattedSortCode;

    sortCode = sortCode || '';
    sortCode = sortCode.replace(/[^0-9]/g, '');
    sortCode = sortCode.toUpperCase();

    var mask = '00-00-00';
    var maskPatterns = { '0': /[0-9]/ };

    if (sortCode.length > 0) {
      formattedSortCode = BankData_Utils.maskString(
        sortCode,
        mask,
        maskPatterns
      );
    }

    return formattedSortCode;
  }

  //i.e. 40-47-84
  function validateUkSortCodeExists(sortCode) {
    if (!sortCode) {
      return false;
    }
    if (!(typeof sortCode === 'string' || sortCode instanceof String)) {
      return false;
    }
    if (sortCode === '') {
      return false;
    }
    if (sortCode === true) {
      return false;
    }
    if (sortCode === false) {
      return false;
    }
    if (sortCode === undefined) {
      return false;
    }
    if (typeof sortCode === 'function' || typeof sortCode === 'object') {
      return false;
    }

    //format
    sortCode = sortCode.toString().replace(/-/g, '');

    //invalid type
    if (isNaN(parseInt(sortCode))) {
      return false;
    }

    //invalid length
    if (sortCode.length !== 6) {
      sortCode = BankData_Utils.padDigits(sortCode, 6);
    }

    return getBankNameBySortCode(sortCode) !== '';
  }

  //i.e. 70872490, 40-47-84
  function validateUkAccountNumber(accountNumber, sortCode) {
    if (!sortCode) {
      return false;
    }
    if (sortCode === '') {
      return false;
    }
    if (sortCode === true) {
      return false;
    }
    if (sortCode === false) {
      return false;
    }
    if (sortCode === undefined) {
      return false;
    }
    if (!(typeof sortCode === 'string' || sortCode instanceof String)) {
      return false;
    }
    if (typeof sortCode === 'function' || typeof sortCode === 'object') {
      return false;
    }

    //format
    sortCode = sortCode.toString().replace(/-/g, '');

    //invalid type
    if (isNaN(parseInt(sortCode))) {
      return false;
    }

    //invalid length
    //if(sortCode.length !== 6 ) { return false }

    var modcheck = new Modcheck(accountNumber, sortCode);

    return modcheck.isValid();
  }

  function getBankNameBySortCode(sortCode) {
    var bankName = '';
    if (!sortCode) {
      return bankName;
    }

    var bankDataArray = bankData.data;

    //format sort code
    sortCode = sortCode
      .split(' ')
      .join('')
      .split('-')
      .join('');

    for (var i = 0; i < bankDataArray.length; i++) {
      if (bankDataArray[i][1] == sortCode) {
        bankName = bankDataArray[i][0];
      }
    }

    return bankName;
  }
});

isUkAccountNumberValidDir.$inject = ['pcaPredictResource'];
function isUkAccountNumberValidDir(pcaPredictResource) {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isUkAccountNumberValidDir';
      var sortCodeFieldName = 'isUkAccountNumberValidSortCode';
      var errorName = 'ukAccountNumberValid';

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      var isActive = false;
      var sortCodeWatch;
      var dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = value;
            if (isActive) {
              activateDir();
            } else {
              deactivateDir();
            }
          }
        }
      );

      function activateDir() {
        //watch sourceCode attribute which changes dynamically
        sortCodeWatch = scope.$watch(
          function() {
            return getFormFieldValue(sortCodeFieldName);
          },
          function(sortCode) {
            validateInput(ngModel.$modelValue, sortCode);
          }
        );
      }

      function deactivateDir() {
        if (typeof sortCodeWatch === 'function') {
          sortCodeWatch();
        }
      }

      /////////////////////////////////////////////////

      //For DOM -> model validation
      ngModel.$parsers.unshift(function(accountNumberValue) {
        validateInput(accountNumberValue, getFormFieldValue(sortCodeFieldName));
        return accountNumberValue;
      });

      //For model -> DOM validation
      ngModel.$formatters.unshift(function(accountNumberValue) {
        validateInput(accountNumberValue, getFormFieldValue(sortCodeFieldName));
        return accountNumberValue;
      });

      /////////////////////////////////////////////////

      function validateInput(accountNumber, sortCode) {
        ngModel.$setValidity(errorName, true);
        accountNumber = (accountNumber + '').replace(/\D/g, '');
        // directive not active
        if (!isActive) {
          return;
        }

        var isValid = false;
        if ((accountNumber + '').length !== 8) {
          ngModel.$setValidity(errorName, isValid);
          return;
        }

        // try pca-predict api validation
        pcaPredictResource
          .validateAccountAndSortCode({
            accountNumber: accountNumber,
            sortCode: sortCode
          })
          .then(function(result) {
            if (
              'data' in result &&
              'Items' in result.data &&
              result.data.Items.length > 0 &&
              'IsCorrect' in result.data.Items[0]
            ) {
              isValid = result.data.Items[0].IsCorrect;
            } else {
              isValid = UK_SortCode.validateUkAccountNumber(
                accountNumber,
                sortCode
              );
            }
            ngModel.$setValidity(errorName, isValid);
          })
          .catch(
            // if api broken or offline use offline validation
            function() {
              isValid = UK_SortCode.validateUkAccountNumber(
                accountNumber,
                sortCode
              );
              ngModel.$setValidity(errorName, isValid);
            }
          );
      }

      ////////////////////////////////////////////

      function getFormFieldValue(fieldName) {
        var value = '';
        if (attrs[fieldName] && form[attrs[fieldName]]) {
          value =
            form[attrs[fieldName]].$viewValue ||
            form[attrs[fieldName]].$modelValue ||
            '';
        }
        return value;
      }
    }
  };
}

angular
  .module('isUkAccountNumberValid-dir', [])
  .directive('isUkAccountNumberValidDir', isUkAccountNumberValidDir);

function isUkSortCodeExistingValidDir() {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isUkSortCodeExistingValidDir';

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      var isActive = false;
      var dirActiveWatch;

      dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = value;
          }
        }
      );

      //For DOM -> model validation
      ngModel.$parsers.unshift(function(ukSortCodeValue) {
        validateInput(ukSortCodeValue);
        return ukSortCodeValue;
      });

      ////////////////////////////////////////////

      function validateInput(ukSortCodeValue) {
        var isExisting = true;
        var errorNameExists = 'ukSortCodeExisting';

        ngModel.$setValidity(errorNameExists, true);

        if (isActive) {
          isExisting = UK_SortCode.validateUkSortCodeExists(ukSortCodeValue);

          if (!isExisting) {
            ngModel.$setValidity(errorNameExists, false);
          }
        }

        return isExisting;
      }
    }
  };
}

angular
  .module('isUkSortCodeExistingValid-dir', [])
  .directive('isUkSortCodeExistingValidDir', isUkSortCodeExistingValidDir);

formatUkSortCodeDir.$inject = ['formHelperFactory'];
function formatUkSortCodeDir(formHelperFactory) {
  return {
    require: ['ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'formatUkSortCodeDir';

      var ngModel = controllers[0];

      var isActive;
      var dirActiveWatch;

      //For DOM -> model validation
      ngModel.$parsers.unshift(parseFunction);

      ////////

      dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value, prevVal) {
          //detect change
          if (isActive !== value) {
            isActive = !!value;
          }
        }
      );

      ////////////////////////////////////////////

      //For DOM -> model validation
      function parseFunction(formattedUkSortCode) {
        if (isActive) {
          var transformedInput = UK_SortCode.formatUkSortCode(
            formattedUkSortCode
          );

          //check for changes
          if (transformedInput !== formattedUkSortCode) {
            ngModel.$setViewValue(transformedInput);
            ngModel.$render();
            /*cursor jump bugfix*/
            formHelperFactory.focusEnd(attrs.id);
            return transformedInput;
          }
        }

        return formattedUkSortCode;
      }
    }
  };
}

angular
  .module('formatUkSortCode-dir', [])
  .directive('formatUkSortCodeDir', formatUkSortCodeDir);

function isSortCodeCountryValidDir() {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isSortCodeCountryValidDir';
      var paymentMethodFieldName = 'isSortCodeCountryValidPaymentMethod';

      var watchers = [];

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      //
      var isActive = false;
      var dirActiveWatch;

      dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = !!value;
            activateDir();
          } else {
            deactivateDir();
          }
        }
      );

      //For DOM -> model validation
      ngModel.$parsers.unshift(function(countryValue) {
        validateInput(countryValue);
        return countryValue;
      });

      ////////////////////////////////////////////

      function activateDir() {
        //watch country attribute which changes dynamically
        watchers.push(
          scope.$watch(getPaymentMethodValue, function(paymentMethod) {
            var countryValue = ngModel.$modelValue || ngModel.$viewValue;
            validateInput(countryValue);
          })
        );
      }

      function deactivateDir() {
        for (var i = 0; i < watchers.length; i++) {
          if (typeof watchers[i] === 'function') {
            watchers[i]();
          }
        }
      }

      function getPaymentMethodValue() {
        var paymentMethod = '';
        if (
          attrs[paymentMethodFieldName] &&
          form[attrs[paymentMethodFieldName]] &&
          '$modelValue' in form[attrs[paymentMethodFieldName]] &&
          form[attrs[paymentMethodFieldName]]
        ) {
          var paymentMethodControl = form[attrs[paymentMethodFieldName]];
          paymentMethod =
            paymentMethodControl.$modelValue ||
            paymentMethodControl.$viewValue ||
            '';
        }
        return paymentMethod;
      }

      function validateInput(countryValue) {
        countryValue =
          typeof countryValue === 'string' || countryValue instanceof String
            ? countryValue.toString().toUpperCase()
            : '';

        var isValid = true;
        var errorNameExists = 'sortCodeCountryPaymentMethod';
        ngModel.$setValidity(errorNameExists, true);

        var paymentMethod = getPaymentMethodValue().toString();

        if (isActive && paymentMethod === 'donut-uk-sort-codes') {
          isValid = countryValue === 'GB';
          if (!isValid) {
            ngModel.$setValidity(errorNameExists, false);
          }
        }

        return isValid;
      }
    }
  };
}

angular
  .module('isSortCodeCountryValid-dir', [])
  .directive('isSortCodeCountryValidDir', isSortCodeCountryValidDir);

angular.module('bank-account.uk-sort-code', [
  'formatUkSortCode-dir',
  'isUkAccountNumberValid-dir',
  'isUkSortCodeExistingValid-dir',
  'isSortCodeCountryValid-dir'
]);

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports'], factory);
  } else if (
    typeof exports === 'object' &&
    typeof exports.nodeName !== 'string'
  ) {
    // CommonJS
    factory(exports);
  } else {
    // Browser globals
    factory((root.CH_Postfinance = {}));
  }
})(this, function(exports) {
  /**
   * An object containing all the functions.
   */

  //SWIFT Code / BIC PostFinance
  var POST_ACCOUNT_CODE = 'POFICHBEXXX';
  //Postkontonummer
  var CLEARING_NUMBER = '09000';

  exports.POST_ACCOUNT_CODE = POST_ACCOUNT_CODE;
  exports.CLEARING_NUMBER = CLEARING_NUMBER;
  exports.validateClearingNumber = validateClearingNumber;
  exports.validatePostAccountCode = validatePostAccountCode;
  exports.formatPostAccountNumber = formatPostAccountNumber;
  exports.getBankNameByClearingNumber = getBankNameByClearingNumber;

  ///////////////

  function validatePostAccountCode(postAccountCode) {
    if (!postAccountCode) {
      return false;
    }
    //if (postFinanceCode === '') { return false }
    if (postAccountCode === true) {
      return false;
    }
    //if (postFinanceCode === false) { return false }
    //if (postFinanceCode === undefined) { return false }
    if (
      typeof postAccountCode === 'function' ||
      typeof postAccountCode === 'object'
    ) {
      return false;
    }

    //format
    postAccountCode = postAccountCode.toString().replace(/\D[^\.]/g, '');
    isValid = false;

    if (postAccountCode.length >= 7 && postAccountCode.length <= 9) {
      isValid = true;
    }

    return isValid;
  }

  function validateClearingNumber(postFinanceBankCode) {
    if (!postFinanceBankCode) {
      return false;
    }
    //if (postFinanceCode === '') { return false }
    if (postFinanceBankCode === true) {
      return false;
    }
    //if (postFinanceCode === false) { return false }
    //if (postFinanceCode === undefined) { return false }
    if (
      typeof postFinanceBankCode === 'function' ||
      typeof postFinanceBankCode === 'object'
    ) {
      return false;
    }

    //format
    postFinanceBankCode = postFinanceBankCode.replace(/[^0-9]/g, '');
    //pad with 0
    for (var i = 0; i < CLEARING_NUMBER.length; i++) {
      if (postFinanceBankCode.length < CLEARING_NUMBER.length) {
        postFinanceBankCode = postFinanceBankCode + '0';
      }
    }

    isValid = postFinanceBankCode === CLEARING_NUMBER;

    return isValid;
  }

  function formatPostAccountNumber(accountNumber) {
    if (!accountNumber) {
      return accountNumber;
    }

    //remove formatting
    var formattedAccountNumber = accountNumber
      .split(' ')
      .join('')
      .split('-')
      .join('');

    var maskPatterns = { '0': /[0-9]/ };
    var mask = '00-0000-0';

    if (formattedAccountNumber.length === 8) {
      mask = '00-00000-0';
    } else if (formattedAccountNumber.length > 8) {
      mask = '00-000000-0';
    }

    formattedAccountNumber = BankData_Utils.maskString(
      formattedAccountNumber,
      mask,
      maskPatterns
    );
    return formattedAccountNumber;
  }

  function getBankNameByClearingNumber(clearingNumber) {
    var bankName = '';
    var bankObj = BankData_Utils.getBankDataByCountry('CH');

    if (!bankObj) {
      return '';
    }

    var bankData = bankObj.data;

    if (clearingNumber == '') {
      return bankName;
    }

    for (var i = 0; i < bankData.length; i++) {
      if (bankData[i][1] == clearingNumber) {
        bankName = bankData[i][0];
        return bankName;
      }
    }

    return bankName;
  }
});

formatChPostAccountNumberDir.$inject = ['formHelperFactory'];
function formatChPostAccountNumberDir(formHelperFactory) {
  return {
    require: ['ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'formatChPostAccountNumberDir';

      var ngModel = controllers[0];

      var isActive;
      var dirActiveWatch;

      //For cDOM -> model validation
      ngModel.$parsers.unshift(parseFunction);

      ////////

      dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value, prevVal) {
          //detect change
          if (isActive !== value) {
            isActive = !!value;
          }
        }
      );

      ////////////////////////////////////////////

      //For DOM -> model validation
      function parseFunction(postFinancePostAccountNumber) {
        if (isActive) {
          var transformedInput = CH_Postfinance.formatPostAccountNumber(
            postFinancePostAccountNumber
          );

          //check for changes
          if (transformedInput !== postFinancePostAccountNumber) {
            ngModel.$setViewValue(transformedInput);
            ngModel.$render();
            /*cursor jump bugfix*/
            formHelperFactory.focusEnd(attrs.id);
            return transformedInput;
          }
        }

        return postFinancePostAccountNumber;
      }
    }
  };
}

angular
  .module('formatChPostAccountNumber-dir', [])
  .directive('formatChPostAccountNumberDir', formatChPostAccountNumberDir);

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports'], factory);
  } else if (
    typeof exports === 'object' &&
    typeof exports.nodeName !== 'string'
  ) {
    // CommonJS
    factory(exports);
  } else {
    // Browser globals
    factory((root.Banks_CH = {}));
  }
})(this, function(exports) {
  var BANK_CODE_LENGHTS = 5;
  var BIC_NUMBER_LENGTHS = 12;
  var COUNTRY_CODE_UPPERCASE = 'CH';

  /**
   * bankData in an array of bank name, clearing number, bic
   * Source: http://www.six-interbank-clearing.com/de/home/bank-master-data/download-bc-bank-master.html
   */
  var bankData = [
    ['Schweizerische Nationalbank', '00100', 'SNBZCHZZXXX'],
    ['Schweizerische Nationalbank', '00110', 'SNBZCHZZXXX'],
    ['Schweizerische Nationalbank', '00115', 'SNBZCHZZXXX'],
    ['SECB Swiss Euro Clearing Bank', '00193', 'SECGDEFFXXX'],
    ['UBS Switzerland AG', '00202', 'UBSWCHZH86N'],
    ['UBS Switzerland AG', '00203', 'UBSWCHZH93A'],
    ['UBS Switzerland AG', '00204', 'UBSWCHZH70E'],
    ['UBS Switzerland AG', '00206', 'UBSWCHZH80V'],
    ['UBS Switzerland AG', '00207', 'UBSWCHZH81M'],
    ['UBS Switzerland AG', '00208', 'UBSWCHZH70A'],
    ['UBS Switzerland AG', '00209', 'UBSWCHZH72D'],
    ['UBS Switzerland AG', '00210', 'UBSWCHZH52A'],
    ['UBS Switzerland AG', '00211', 'UBSWCHZH31A'],
    ['UBS Switzerland AG', '00213', 'UBSWCHZH94N'],
    ['UBS Switzerland AG', '00214', 'UBSWCHZH88A'],
    ['UBS Switzerland AG', '00215', 'UBSWCHZH12C'],
    ['UBS Switzerland AG', '00216', 'UBSWCHZH88B'],
    ['UBS Switzerland AG', '00217', 'UBSWCHZH82P'],
    ['UBS Switzerland AG', '00219', 'UBSWCHZH85B'],
    ['UBS Switzerland AG', '00220', 'UBSWCHZH94P'],
    ['UBS Switzerland AG', '00221', 'UBSWCHZH75A'],
    ['UBS Switzerland AG', '00222', 'UBSWCHZH83B'],
    ['UBS Switzerland AG', '00223', 'UBSWCHZH89D'],
    ['UBS Switzerland AG', '00225', 'UBSWCHZH80G'],
    ['UBS Switzerland AG', '00226', 'UBSWCHZH29A'],
    ['UBS Switzerland AG', '00227', 'UBSWCHZH36A'],
    ['UBS Switzerland AG', '00228', 'UBSWCHZH12T'],
    ['UBS Switzerland AG', '00230', 'UBSWCHZH80A'],
    ['UBS Switzerland AG', '00231', 'UBSWCHZH50A'],
    ['UBS Switzerland AG', '00232', 'UBSWCHZH54A'],
    ['UBS Switzerland AG', '00233', 'UBSWCHZH40A'],
    ['UBS Switzerland AG', '00234', 'UBSWCHZH65A'],
    ['UBS Switzerland AG', '00235', 'UBSWCHZH30A'],
    ['UBS Switzerland AG', '00236', 'UBSWCHZH68B'],
    ['UBS Switzerland AG', '00238', 'UBSWCHZH87C'],
    ['UBS Switzerland AG', '00239', 'UBSWCHZH85A'],
    ['UBS Switzerland AG', '00240', 'UBSWCHZH12A'],
    ['UBS Switzerland AG', '00241', 'UBSWCHZH38A'],
    ['UBS Switzerland AG', '00242', 'UBSWCHZH23A'],
    ['UBS Switzerland AG', '00243', 'UBSWCHZH10A'],
    ['UBS Switzerland AG', '00244', 'UBSWCHZH96C'],
    ['UBS Switzerland AG', '00245', 'UBSWCHZH44A'],
    ['UBS Switzerland AG', '00246', 'UBSWCHZH66A'],
    ['UBS Switzerland AG', '00247', 'UBSWCHZH69A'],
    ['UBS Switzerland AG', '00248', 'UBSWCHZH60A'],
    ['UBS Switzerland AG', '00249', 'UBSWCHZH18D'],
    ['UBS Switzerland AG', '00250', 'UBSWCHZH86M'],
    ['UBS Switzerland AG', '00251', 'UBSWCHZH80H'],
    ['UBS Switzerland AG', '00252', 'UBSWCHZH94A'],
    ['UBS Switzerland AG', '00253', 'UBSWCHZH86P'],
    ['UBS Switzerland AG', '00254', 'UBSWCHZH90A'],
    ['UBS Switzerland AG', '00255', 'UBSWCHZH18A'],
    ['UBS Switzerland AG', '00256', 'UBSWCHZH95A'],
    ['UBS Switzerland AG', '00257', 'UBSWCHZH84A'],
    ['UBS Switzerland AG', '00258', 'UBSWCHZH56B'],
    ['UBS Switzerland AG', '00259', 'UBSWCHZH87B'],
    ['UBS Switzerland AG', '00260', 'UBSWCHZH17A'],
    ['UBS Switzerland AG', '00261', 'UBSWCHZH46A'],
    ['UBS Switzerland AG', '00262', 'UBSWCHZH45A'],
    ['UBS Switzerland AG', '00263', 'UBSWCHZH39A'],
    ['UBS Switzerland AG', '00264', 'UBSWCHZH19B'],
    ['UBS Switzerland AG', '00265', 'UBSWCHZH19E'],
    ['UBS Switzerland AG', '00266', 'UBSWCHZH16C'],
    ['UBS Switzerland AG', '00267', 'UBSWCHZH80K'],
    ['UBS Switzerland AG', '00268', 'UBSWCHZH39L'],
    ['UBS Switzerland AG', '00269', 'UBSWCHZH80M'],
    ['UBS Switzerland AG', '00270', 'UBSWCHZH80N'],
    ['UBS Switzerland AG', '00271', 'UBSWCHZH80A'],
    ['UBS Switzerland AG', '00272', 'UBSWCHZH25A'],
    ['UBS Switzerland AG', '00273', 'UBSWCHZH63A'],
    ['UBS Switzerland AG', '00274', 'UBSWCHZH80Q'],
    ['UBS Switzerland AG', '00275', 'UBSWCHZH80R'],
    ['UBS Switzerland AG', '00276', 'UBSWCHZH89E'],
    ['UBS Switzerland AG', '00277', 'UBSWCHZH18L'],
    ['UBS Switzerland AG', '00278', 'UBSWCHZH83A'],
    ['UBS Switzerland AG', '00279', 'UBSWCHZH12B'],
    ['UBS Switzerland AG', '00283', 'UBSWCHZH81H'],
    ['UBS Switzerland AG', '00284', 'UBSWCHZH87D'],
    ['UBS Switzerland AG', '00285', 'UBSWCHZH80P'],
    ['UBS Switzerland AG', '00286', 'UBSWCHZH80A'],
    ['UBS Switzerland AG', '00287', 'UBSWCHZH82A'],
    ['UBS Switzerland AG', '00288', 'UBSWCHZH60G'],
    ['UBS Switzerland AG', '00290', 'UBSWCHZH20A'],
    ['UBS Switzerland AG', '00291', 'UBSWCHZH81A'],
    ['UBS Switzerland AG', '00292', 'UBSWCHZH40M'],
    ['UBS Switzerland AG', '00293', 'UBSWCHZH88C'],
    ['UBS Switzerland AG', '00294', 'UBSWCHZH39G'],
    ['UBS Switzerland AG', '00295', 'UBSWCHZH94F'],
    ['UBS Switzerland AG', '00296', 'UBSWCHZH86Q'],
    ['UBS Switzerland AG', '00297', 'UBSWCHZH14A'],
    ['UBS Switzerland AG', '00298', 'UBSWCHZH80A'],
    ['UBS AG', '00315', 'UBSBCHZZXXX'],
    ['Zürcher Kantonalbank', '00700', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00702', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00703', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00704', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00705', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00706', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00708', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00709', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00710', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00711', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00712', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00713', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00714', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00715', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00716', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00717', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00718', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00719', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00720', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00721', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00722', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00723', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00724', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00725', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00726', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00727', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00728', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00729', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00730', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00731', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00732', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00733', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00734', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00735', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00736', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00737', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00738', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00739', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00740', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00741', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00742', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00743', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00744', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00745', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00746', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00747', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00748', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00749', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00750', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00751', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00752', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00753', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00754', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00755', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00756', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00757', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00758', 'ZKBKCHZZ80A'],
    ['Zürcher Kantonalbank', '00759', 'ZKBKCHZZ80A'],
    ['Aargauische Kantonalbank', '00761', 'KBAGCH22XXX'],
    ['Appenzeller Kantonalbank', '00763', 'AIKACH22XXX'],
    ['Banca dello Stato del Cantone Ticino', '00764', 'BSCTCH22XXX'],
    ['Banque Cantonale du Valais', '00765', 'BCVSCH2LXXX'],
    ['Banque Cantonale Neuchâteloise', '00766', 'BCNNCH22XXX'],
    ['Banque Cantonale Vaudoise', '00767', 'BCVLCH2LXXX'],
    ['Banque Cantonale de Fribourg', '00768', 'BEFRCH22XXX'],
    ['Basellandschaftliche Kantonalbank', '00769', 'BLKBCH22XXX'],
    ['Basler Kantonalbank', '00770', 'BKBBCHBBXXX'],
    ['Glarner Kantonalbank', '00773', 'GLKBCH22XXX'],
    ['Graubündner Kantonalbank', '00774', 'GRKBCH2270A'],
    ['Schwyzer Kantonalbank', '00777', 'KBSZCH22XXX'],
    ['Luzerner Kantonalbank AG', '00778', 'LUKBCH2260A'],
    ['Nidwaldner Kantonalbank', '00779', 'NIKACH22XXX'],
    ['Obwaldner Kantonalbank', '00780', 'OBWKCH22XXX'],
    ['St. Galler Kantonalbank AG', '00781', 'KBSGCH22XXX'],
    ['Schaffhauser Kantonalbank', '00782', 'SHKBCH2SXXX'],
    ['Thurgauer Kantonalbank', '00784', 'KBTGCH22XXX'],
    ['Thurgauer Kantonalbank', '00784', 'KBTGCH2282M'],
    ['Thurgauer Kantonalbank', '00784', 'KBTGCH2282P'],
    ['Thurgauer Kantonalbank', '00784', 'KBTGCH2283L'],
    ['Thurgauer Kantonalbank', '00784', 'KBTGCH2285A'],
    ['Thurgauer Kantonalbank', '00784', 'KBTGCH2285D'],
    ['Thurgauer Kantonalbank', '00784', 'KBTGCH2285F'],
    ['Thurgauer Kantonalbank', '00784', 'KBTGCH2292B'],
    ['Thurgauer Kantonalbank', '00784', 'KBTGCH2293B'],
    ['Urner Kantonalbank', '00785', 'URKNCH22XXX'],
    ['Zuger Kantonalbank', '00787', 'KBZGCH22XXX'],
    ['Banque Cantonale de Genève', '00788', 'BCGECHGGXXX'],
    ['Banque Cantonale du Jura SA', '00789', 'BCJUCH22XXX'],
    ['Berner Kantonalbank AG', '00790', 'KBBECH22XXX'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZXXX'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ10A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ10P'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ10S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ11R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ12A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ12B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ12D'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ12E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ12I'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ12N'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ12P'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ12T'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ12V'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ14A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ15C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ16A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ16B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ16C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ17A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ18A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ18L'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ18S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ19D'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ19R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ19S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ20R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ23R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ25A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ25S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ26B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ27F'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ27M'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ27N'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ27O'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ28A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ29A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ30A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ30B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ30E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ30F'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ30N'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ30T'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ31A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ32A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ32B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ34R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ36A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ37L'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ38A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ39A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ39E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ39G'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ39L'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ39N'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ40A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ40C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ40E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ41A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ41D'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ41E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ41G'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ41R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ41S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ42A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ43A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ44A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ44F'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ45A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ46A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ47A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ48A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ49R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ50A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ50B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ54A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ60A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ60F'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ60K'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ60M'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ60P'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ60S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ61A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ62R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ63A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ63N'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ63S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ64A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ64E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ64K'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ65B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ65C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ65G'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ65R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ65V'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ66A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ66C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ66D'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ66E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ66F'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ67A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ67B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ68B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ68S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ68T'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ69A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ69R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ69S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ70A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ70C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ70E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ72B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ72D'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ73C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ75A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ75B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ75C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ75H'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ75L'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80D'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80F'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80G'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80H'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80J'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80L'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80M'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80N'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80P'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80Q'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80T'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80V'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ80X'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ81E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ81M'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ82A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ82D'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ82P'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ83C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ83D'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ83R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ84C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ84R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ85A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ85C'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ85F'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ86A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ86B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ86E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ86M'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ86S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ87A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ87B'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ87F'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ87L'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ87R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ88F'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ88G'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ88H'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ88L'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ88M'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ88R'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ89A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ89E'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ90A'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ92D'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ94N'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ94P'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ94S'],
    ['Credit Suisse (Schweiz) AG', '04835', 'CRESCHZZ95R'],
    ['Credit Suisse AG', '04866', 'CRESCHZHXXX'],
    ['Crédit Mutuel de la Vallée SA', '06180', 'RBABCH22180'],
    [
      "Caisse d'Epargne de Cossonay société coopérative",
      '06182',
      'RBABCH22182'
    ],
    ['Sparkasse Sense', '06186', 'RBABCH22186'],
    ["Caisse d'Epargne Courtelary SA", '06240', 'RBABCH22240'],
    ['Valiant Bank AG (ex Valiant Privatbank AG)', '06300', 'VBBECH22XXX'],
    ['Valiant Bank AG', '06300', 'VABECH22XXX'],
    ['Bernerland Bank AG', '06313', 'RBABCH22313'],
    ['Clientis AG', '06336', 'RBABCH22CLI'],
    ['SB Saanen Bank AG', '06342', 'RBABCH22342'],
    ['Bank SLM AG', '06363', 'RBABCH22363'],
    ['Spar+Leihkasse Riggisberg AG', '06374', 'RBABCH22374'],
    ['Burgerliche Ersparniskasse Bern Genossenschaft', '06382', 'RBABCH22382'],
    ['Ersparniskasse Affoltern i. E. AG', '06387', 'RBABCH22387'],
    ['Entris Banking AG', '06395', 'RBABCH22XXX'],
    ['Clientis Sparkasse Oftringen Genossenschaft', '06428', 'RBABCH22428'],
    ['Clientis Bank im Thal AG', '06434', 'RBABCH22434'],
    ['Bank Oberaargau AG', '06450', 'RBABCH22450'],
    ['Clientis Bank Aareland AG', '06575', 'RBABCH22575'],
    ['Bank Leerau Genossenschaft', '06588', 'RBABCH22588'],
    ['Sparkasse Schwyz AG', '06633', 'RBABCH22633'],
    ['Clientis EB Entlebucher Bank AG', '06670', 'RBABCH22670'],
    ['GRB Glarner Regionalbank Genossenschaft', '06807', 'RBABCH22807'],
    ['Sparhafen Bank AG', '06808', 'BSZHCHZZXXX'],
    ['Sparcassa 1816 Genossenschaft', '06814', 'RBABCH22814'],
    ['BANK ZIMMERBERG AG', '06824', 'RBABCH22824'],
    ['Regiobank Männedorf AG', '06828', 'RBABCH22828'],
    ['Lienhardt & Partner Privatbank Zürich AG', '06830', 'RBABCH22830'],
    ['Ersparniskasse Schaffhausen AG', '06835', 'RBABCH22835'],
    ['Bank Avera Genossenschaft', '06850', 'RBABCH22850'],
    ['BS Bank Schaffhausen AG', '06858', 'RBABCH22858'],
    ['Spar- und Leihkasse Thayngen AG', '06866', 'RBABCH22866'],
    ['Leihkasse Stammheim AG', '06875', 'RBABCH22875'],
    ['Zürcher Landbank AG', '06877', 'RBABCH22877'],
    ['Bank BSU Genossenschaft', '06888', 'RBABCH22888'],
    ['acrevis Bank AG', '06900', 'ACRGCH22XXX'],
    ['Alpha RHEINTAL Bank AG', '06920', 'ARBHCH22XXX'],
    ['Clientis Bank Oberuzwil AG', '06935', 'RBABCH22935'],
    ['Clientis Bank Toggenburg AG', '06955', 'RBABCH22955'],
    ['Bank in Zuzwil', '06964', 'RBABCH22964'],
    ['Clientis Bank Thur Genossenschaft', '06977', 'RBABCH22977'],
    ['Biene Bank im Rheintal Genossenschaft', '06980', 'RBABCH22980'],
    ['Banco Santander International SA', '08235', 'BSCHCHGGXXX'],
    ['Credit Europe Bank (Suisse) SA', '08236', 'FSUICHGGXXX'],
    ['Investec Bank (Switzerland) AG', '08238', 'IVESCHZZXXX'],
    ['Crédit Agricole next Bank (Suisse) SA', '08243', 'AGRICHGXXXX'],
    ['Kaleido Privatbank AG', '08245', 'ANPRCH22XXX'],
    ['BANQUE PROFIL DE GESTION SA', '08246', 'BPDGCHGGXXX'],
    ['One Swiss Bank SA', '08246', 'BQBHCHGGXXX'],
    ['F. van Lanschot Bankiers (Schweiz) AG', '08248', 'FVLBCHZZXXX'],
    ['Banca Popolare di Sondrio (Suisse) SA', '08252', 'POSOCH22XXX'],
    ['Banque Internationale à Luxembourg (Suisse) SA', '08268', 'BILSCHZZXXX'],
    ['BBVA SA', '08270', 'BBVACHZZXXX'],
    ['Goldman Sachs Bank AG', '08278', 'GOLDCHZZXXX'],
    ['Frankfurter Bankgesellschaft (Schweiz) AG', '08288', 'FBGSCHZZXXX'],
    ['ODDO BHF (Schweiz) AG', '08289', 'LANBCH2LXXX'],
    ['ODDO BHF (Schweiz) AG', '08289', 'BHFBCHZZXXX'],
    ['Cembra Money Bank AG', '08296', 'CMBNCHZHXXX'],
    ['Spar- und Leihkasse Wynigen AG', '08300', 'SLWYCH21XXX'],
    ['Hypothekarbank Lenzburg AG', '08307', 'HYPLCH22XXX'],
    ['Banque SYZ SA', '08309', 'SYCOCHGGXXX'],
    ["Caisse d'Epargne de Nyon société coopérative", '08326', 'CAGYCH21XXX'],
    ["Caisse d'Epargne d'Aubonne Sociéte coopérative", '08327', 'RBABCH22CEA'],
    ['Ersparniskasse Speicher', '08329', 'ERSCCH21XXX'],
    ['Baloise Bank AG', '08334', 'KBSOCH22XXX'],
    ['LGT Bank (Schweiz) AG', '08335', 'BLFLCHBBXXX'],
    ['Banque Algérienne du Commerce Extérieur SA', '08346', 'AEXTCHZZXXX'],
    [
      "Caisse d'Epargne et de Crédit Mutuel de Chermignon",
      '08348',
      'CDEMCH21XXX'
    ],
    ["Caisse d'Epargne Riviera, société coopérative", '08349', 'CDDVCH21XXX'],
    ['Loomis Schweiz AG', '08350', 'SEPOCH22XXX'],
    ['Loomis Schweiz SA', '08352', 'VIMMCHZZXXX'],
    [
      'China Construction Bank Corporation, Beijing, Swiss Branch',
      '08373',
      'PCBCCHZZXXX'
    ],
    [
      'CACEIS Bank, Montrouge, succursale de Nyon / Suisse',
      '08374',
      'ISAECH2NXXX'
    ],
    ['Vontobel Swiss Financial Advisers AG', '08377', 'VSFACHZZXXX'],
    ['NPB Neue Privat Bank AG', '08378', 'NEPICHZZXXX'],
    ['QNB (Suisse) SA', '08379', 'QNBACHGGXXX'],
    ['J.P. Morgan (Suisse) SA', '08380', 'MGTCCHGGXXX'],
    ['Intesa Sanpaolo Private Bank (Suisse) Morval SA', '08384', 'SPCHCHGGXXX'],
    ['Reyl & Cie S.A.', '08384', 'REYLCHGGXXX'],
    ['NBK Banque Privée (Suisse) SA', '08385', 'NBOKCHGGXXX'],
    [
      'ING Bank N.V., Amsterdam, succursale de Lancy/Genève',
      '08387',
      'BBRUCHGTXXX'
    ],
    ['Banque Eric Sturdza SA', '08388', 'BABRCHGGXXX'],
    ['Bezirks-Sparkasse Dielsdorf Genossenschaft', '08389', 'BZSDCH22XXX'],
    ['Alternative Bank Schweiz AG', '08390', 'ABSOCH22XXX'],
    ['WIR Bank Genossenschaft', '08391', 'WIRBCHBBXXX'],
    ['Freie Gemeinschaftsbank Genossenschaft', '08392', 'FRGGCHB1XXX'],
    ['Bank EKI Genossenschaft', '08393', 'EKIICH22XXX'],
    ['Bank EEK AG', '08394', 'EEKBCH22XXX'],
    ['Spar- und Leihkasse Bucheggberg', '08395', 'SLBUCH22XXX'],
    ['Spar- und Leihkasse Gürbetal AG', '08396', 'SLGUCH2MXXX'],
    ['DC Bank Deposito-Cassa der Stadt Bern', '08397', 'RBABCH22DCB'],
    ['VZ Depotbank AG', '08398', 'VZDBCHZZXXX'],
    ['Migros Bank AG', '08401', 'MIGRCHZZXXX'],
    ['Migros Bank AG', '08401', 'MIGRCHZZ80A'],
    ['Bank Cler AG', '08440', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08441', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08442', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08443', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08444', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08445', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08446', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08447', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08448', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08449', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08450', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08451', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08452', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08453', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08454', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08455', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08456', 'BCLRCHBBXXX'],
    ['Bank Cler AG', '08457', 'BCLRCHBBXXX'],
    ['Cornèr Banca SA', '08490', 'CBLUCH22XXX'],
    ['Banca Credinvest SA', '08495', 'BCRECH22XXX'],
    ['Banca Aletti & C. (Suisse) SA', '08496', 'VRBPCH22XXX'],
    ['SAXO BANK (SCHWEIZ) AG', '08497', 'SAXOCHZHXXX'],
    ['Axion SWISS Bank SA', '08498', 'UNCECH22XXX'],
    ['FAB Private Bank (Suisse) SA', '08499', 'NBPSCHGGXXX'],
    ['Mercantil Bank (Schweiz) AG', '08509', 'BAMRCHZZXXX'],
    ['Banca Julius Baer & Cie S.A.', '08515', 'BAERCHZZXXX'],
    ['Bank Gantrisch Genossenschaft', '08518', 'BGAGCH22XXX'],
    ['Ersparniskasse Rüeggisberg Genossenschaft', '08519', 'EKRUCH21XXX'],
    ['BBO Bank Brienz Oberhasli AG', '08521', 'BBOBCH22XXX'],
    ['Società Bancaria Ticinese', '08522', 'SBTICH22XXX'],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft, Innsbruck',
      '08525',
      'BTVACH22XXX'
    ],
    ['Privatbank IHAG Zürich AG', '08528', 'IHZUCHZZXXX'],
    ['SELVI & Cie SA', '08533', 'SELVCHGGXXX'],
    ['VP Bank (Schweiz) AG', '08534', 'VPBVCHZHXXX'],
    [
      'Banque Internationale de Commerce-BRED (Suisse) SA',
      '08537',
      'BICFCHGGXXX'
    ],
    ['Bergos AG', '08539', 'BEGOCHZZXXX'],
    ['BANQUE BANORIENT (SUISSE) SA', '08540', 'BLOMCHGGXXX'],
    ['Scobag Privatbank AG', '08543', 'SCOPCHBBXXX'],
    ['UNITED MIZRAHI BANK (Schweiz) AG', '08548', 'UMBLCHZZXXX'],
    ['Hyposwiss Private Bank Genève SA', '08548', 'CCIECHGGXXX'],
    ['BZ Bank Aktiengesellschaft', '08553', 'BZBKCH2WXXX'],
    ['Privatbank Von Graffenried AG', '08564', 'GRAFCH22XXX'],
    ['Dreyfus Söhne & Cie AG, Banquiers', '08565', 'DREYCHBBXXX'],
    ['Banque Bonhôte & Cie SA', '08570', 'BONHCH22XXX'],
    ['Edmond de Rothschild (Suisse) S.A', '08571', 'PRIBCHGGXXX'],
    ['Trafina Privatbank AG', '08572', 'TRAPCHBBXXX'],
    ['Maerki, Baumann & Co. AG', '08573', 'MAEBCHZZXXX'],
    ['BANKMED SUISSE SA', '08574', 'MEDSCHGGXXX'],
    ['Société Générale Private Banking (Suisse) SA', '08582', 'RUEGCHZZXXX'],
    ['Banca del Ceresio SA', '08584', 'BACECH22XXX'],
    ['Banca Zarattini & Co. SA', '08609', 'EUBACH22XXX'],
    ['DZ PRIVATBANK (Schweiz) AG', '08615', 'GENOCHZZXXX'],
    ['EFG Bank European Financial Group SA', '08616', 'EFGBCHGGXXX'],
    ['Nomura Bank (Switzerland) Ltd', '08619', 'NBSZCHZZXXX'],
    [
      'Banque Havilland (Liechtenstein) AG, Vaduz, Zweig. Zürich',
      '08623',
      'BPGECHGGXXX'
    ],
    ['Banque Audi (Suisse) SA', '08624', 'AUDSCHGGXXX'],
    ['Banque de Commerce et de Placements SA', '08629', 'BPCPCHGGXXX'],
    ['Schroder & Co Bank AG', '08634', 'BJHSCHZZXXX'],
    [
      'JPMorgan Chase Bank, National Association, Columbus',
      '08635',
      'CHASCHGXXXX'
    ],
    ['Citibank (Switzerland) AG', '08638', 'CBSWCHZZXXX'],
    ['UNION BANCAIRE PRIVEE, UBP SA', '08657', 'UBPGCHZZXXX'],
    ['Union Bancaire Privée, UBP SA', '08657', 'UBPGCHGGXXX'],
    ['UNION BANCAIRE PRIVEE, UBP SA', '08657', 'UBPGCHGGLUG'],
    ['Deutsche Bank (Suisse) S.A.', '08659', 'DEUTCHGGXXX'],
    ['GPB (Schweiz) AG', '08660', 'RKBZCHZZXXX'],
    ['Rothschild & Co Bank AG', '08661', 'BKPBCHGGXXX'],
    ['Rothschild & Co Bank AG', '08661', 'ROTACHZZXXX'],
    ['PKB PRIVATE BANK SA', '08663', 'PKBSCH22XXX'],
    ['PKB PRIVATBANK AG', '08664', 'PKBSCH2269A'],
    ['PKB PRIVATBANK SA', '08665', 'PKBSCH2212A'],
    ['Banca del Sempione SA', '08666', 'BASECH22XXX'],
    ['Banca del Sempione SA', '08666', 'BASECH2265A'],
    ['Banca del Sempione SA', '08666', 'BASECH2266A'],
    ['Banca del Sempione SA', '08666', 'BASECH2268B'],
    ['EFG Bank AG', '08667', 'EFGBCHZZXXX'],
    ['BNP Paribas (Suisse) SA', '08686', 'BPPBCHGGXXX'],
    ['HSBC Private Bank (Suisse) SA', '08689', 'BLICCHGGXXX'],
    ['Citibank N.A., Sioux Falls', '08700', 'CITICHZZXXX'],
    [
      'HSBC Bank plc, London, Zweigniederlassung Zürich',
      '08701',
      'HSBCCHZZXXX'
    ],
    ['Leonteq Securities AG', '08702', 'EFGFCHZZXXX'],
    ['bank zweiplus ag', '08703', 'BZPLCHZZXXX'],
    ['AEK BANK 1826 Genossenschaft', '08704', 'AEKTCH22XXX'],
    ['Société Générale', '08705', 'SGABCHZZXXX'],
    ['Banque CIC (Suisse) SA', '08710', 'CIALCHBBXXX'],
    ['Arab Bank (Switzerland) Ltd.', '08719', 'ARBSCHZZXXX'],
    ['Gonet & Cie SA', '08721', 'GONECHGGXXX'],
    [
      'Gonet & Cie SA (ex Banque Degroof Petercam (Suisse) SA)',
      '08721',
      'PEPSCHGGXXX'
    ],
    [
      'Bank of America Europe DAC, Dublin, Zurich Branch',
      '08726',
      'BOFACH2XXXX'
    ],
    ['Banque Cramer & Cie SA', '08727', 'CRAMCHGGXXX'],
    ['Bank Linth LLB AG', '08731', 'LINSCH23XXX'],
    ['Bank Thalwil Genossenschaft', '08733', 'BKTHCH22XXX'],
    ['Barclays Bank (Suisse) SA', '08735', 'BARCCHGGXXX'],
    ['Banque Thaler SA', '08737', 'THALCHGGXXX'],
    ['CA Indosuez (Switzerland) SA', '08740', 'AGRICHGGXXX'],
    ['CA Indosuez (Switzerland) SA', '08741', 'AGRICHGGXXX'],
    ['CA Indosuez (Switzerland) SA', '08742', 'AGRICHGGXXX'],
    ['CA Indosuez (Switzerland) SA', '08743', 'AGRICHGGXXX'],
    ['Bank J. Safra Sarasin AG', '08750', 'SARACHBBXXX'],
    ['Bank J. Safra Sarasin AG', '08751', 'SARACHBBXXX'],
    ['Bank J. Safra Sarasin AG', '08752', 'SARACHBBXXX'],
    ['Banque Pictet & Cie SA', '08755', 'PICTCHGGXXX'],
    ['Baumann & Cie KmG', '08756', 'BAUMCHBBXXX'],
    ['Bank Vontobel AG', '08757', 'VONTCHZZXXX'],
    ['Banque Lombard Odier & Cie SA', '08760', 'LOCYCHGGXXX'],
    ['Reichmuth & Co.', '08761', 'REICCH22XXX'],
    ['CBH - Compagnie Bancaire Helvétique SA', '08762', 'BSSACHGGXXX'],
    ['Bordier & Cie', '08767', 'BORDCHGGXXX'],
    ['Mirabaud & Cie SA', '08770', 'MIRACHGGXXX'],
    ['E. Gutzwiller & Cie Banquiers', '08775', 'GUTZCHBBXXX'],
    ['Piguet Galland & Cie SA', '08777', 'PIGUCH22XXX'],
    ['Rahn+Bodmer Co.', '08779', 'RAHNCHZZXXX'],
    ['Deutsche Bank AG Zürich Branch', '08780', 'DEUTCHZZXXX'],
    ['Swissquote Bank SA', '08781', 'SWQBCHZZXXX'],
    ['Spar- und Leihkasse Frutigen AG', '08784', 'SLFFCH22XXX'],
    ['Regiobank Solothurn AG', '08785', 'RSOSCH22XXX'],
    ['BANQUE HERITAGE SA', '08788', 'HFTCCHGGXXX'],
    ['Habib Bank AG Zürich', '08789', 'HBZUCHZZXXX'],
    ['BNP PARIBAS, Paris, succursale de Zurich', '08792', 'PARBCHZZXXX'],
    ['IBKR Financial Services AG', '08797', 'TMBECH22XXX'],
    ['InCore Bank AG', '08799', 'INCOCHZZXXX'],
    [
      'Liechtensteinische Landesbank Aktiengesellschaft',
      '08800',
      'LILALI2XXXX'
    ],
    ['Neue Bank AG', '08802', 'NBANLI22XXX'],
    ['BENDURA BANK AG', '08803', 'HYIBLI22XXX'],
    ['VP Bank AG', '08805', 'VPBVLI2XXXX'],
    ['Kaiser Partner Privatbank AG', '08806', 'SERBLI22XXX'],
    ['LGT Bank AG', '08810', 'BLFLLI2XXXX'],
    ['Bank Frick & Co. AG', '08811', 'BFRILI22XXX'],
    ['SIGMA Bank AG', '08812', 'VOAGLI22XXX'],
    ['Banque Havilland (Liechtenstein) AG', '08814', 'SFBALI22XXX'],
    ['Tellco AG', '08820', 'DOMICHZZXXX'],
    [
      'Hypo Vorarlberg Bank AG, Bregenz, Zweigniederl., St. Gallen',
      '08821',
      'RBABCH22VLH'
    ],
    ['CIM Banque SA', '08822', 'CIMMCHGGXXX'],
    ['TradeXBank AG', '08825', 'SLBZCHZZXXX'],
    ['S.P. Hinduja Banque Privée SA', '08827', 'ABSGCHGGXXX'],
    ['ABANCA CORPORACION BANCARIA S.A., BETANZOS', '08831', 'CAGLCHGGXXX'],
    ['COMMERZBANK AG FF, Zweigniederlassung Zürich', '08836', 'COBACHZHXXX'],
    ['Banque du Léman SA', '08838', 'BLEMCHGGXXX'],
    ['Quilvest (Switzerland) Ltd.', '08839', 'QVCHCHZHXXX'],
    ['Banco Itaú (Suisse) SA', '08841', 'ITAUCHZZXXX'],
    ['Globalance Bank AG', '08842', 'GLBNCHZZXXX'],
    ['Dukascopy Bank SA', '08843', 'DUBACHGGXXX'],
    ['Helvetische Bank AG', '08845', 'SFBFCH22XXX'],
    ['Aquila AG', '08846', 'AQULCHZZXXX'],
    ['IG Bank S.A.', '08848', 'IGBKCHGGXXX'],
    ['compenswiss (Fonds de compensation AVS/AI/APG)', '08850', 'FAVSCHGGXXX'],
    ['SUVA', '08851', 'SUAACH22XXX'],
    ['Swiss Life AG (KV, geb. Vermögen)', '08852', 'SLAMCHZZKV0'],
    [
      'Schweizerische Rückversicherungs-Gesellschaft AG',
      '08853',
      'SWRECHZHXXX'
    ],
    ['AXA Leben AG', '08854', 'AXIPCHZZXXX'],
    ['Zurich Insurance Company Ltd', '08855', 'ZURICHZFXXX'],
    ['Baloise Leben AG', '08856', 'BAAMCHBBXXX'],
    ['Baloise Versicherung AG', '08858', 'BAAMCHBBBVG'],
    ['CACEIS Investor Services Bank S.A.', '08863', 'FETACHZZXXX'],
    ['Remaco Asset Management AG', '08865', 'RMCOCHBBXXX'],
    ['LF Finance (Suisse) SA', '08866', 'LFFSCHGGXXX'],
    [
      'State Street Bank International GmbH, München, ZN Zürich',
      '08867',
      'SSBECHZZXXX'
    ],
    ['Delen (Suisse) SA', '08868', 'DELECHG2XXX'],
    ['Zähringer Privatbank AG', '08871', 'ZAPRCH22XXX'],
    ['SIX SIS AG', '08880', 'INSECHZZXXX'],
    ['SIX SIS AG', '08881', 'INSECHZZXXX'],
    ['EUREX Clearing AG', '08883', 'EUXCDEFZXXX'],
    ['SIX SIS AG', '08887', 'INSECHZZXXX'],
    ['Sparkasse Bundespersonal', '08890', 'SKBPCH22XXX'],
    ['BIZ Bank für Internationalen Zahlungsausgleich', '08899', 'BISBCHBBXXX'],
    ['CLS Bank International', '08910', 'CLSBUS33XXX'],
    ['PostFinance AG', '09000', 'POFICHBEXXX'],
    ['PostFinance AG', '30000', 'POFICHBEXXX'],
    ['ODDO BHF (Schweiz) AG', '30002', 'LANBCH2LXXX'],
    ['ODDO BHF (Schweiz) AG', '30002', 'BHFBCHZZXXX'],
    [
      'Zürich Lebensvers.-Ges. AG Individual Life geb. Vermögen',
      '30003',
      'ZURICHZFZLT'
    ],
    [
      'Zürich Lebensvers.-Ges. AG Group Life geb. Vermögen',
      '30004',
      'ZURICHZFZKT'
    ],
    ['UBS Switzerland AG', '30005', 'UBSWCHZH80A'],
    [
      'Zürich Versicherungs.-Ges. AG gebundenes Vermögen',
      '30006',
      'ZURICHZFZNT'
    ],
    ['SWISS4.0 SA', '30015', 'IWSSCHGGXXX'],
    ['Crédit Mutuel de la Vallée SA', '30020', 'RBABCH22180'],
    [
      "Caisse d'Epargne de Cossonay société coopérative",
      '30021',
      'RBABCH22182'
    ],
    ['Sparkasse Sense', '30022', 'RBABCH22186'],
    ["Caisse d'Epargne Courtelary SA", '30023', 'RBABCH22240'],
    ['Valiant Bank AG (ex Valiant Privatbank AG)', '30024', 'VBBECH22XXX'],
    ['Valiant Bank AG', '30024', 'VABECH22XXX'],
    ['Bernerland Bank AG', '30026', 'RBABCH22313'],
    ['SB Saanen Bank AG', '30027', 'RBABCH22342'],
    ['Bank SLM AG', '30028', 'RBABCH22363'],
    ['Spar+Leihkasse Riggisberg AG', '30029', 'RBABCH22374'],
    ['Burgerliche Ersparniskasse Bern Genossenschaft', '30030', 'RBABCH22382'],
    ['Ersparniskasse Affoltern i. E. AG', '30031', 'RBABCH22387'],
    ['Clientis Sparkasse Oftringen Genossenschaft', '30033', 'RBABCH22428'],
    ['Clientis Bank im Thal AG', '30034', 'RBABCH22434'],
    ['Bank Oberaargau AG', '30035', 'RBABCH22450'],
    ['Clientis Bank Aareland AG', '30036', 'RBABCH22575'],
    ['Bank Leerau Genossenschaft', '30037', 'RBABCH22588'],
    ['Sparkasse Schwyz AG', '30038', 'RBABCH22633'],
    ['Clientis EB Entlebucher Bank AG', '30040', 'RBABCH22670'],
    ['GRB Glarner Regionalbank Genossenschaft', '30042', 'RBABCH22807'],
    ['Sparhafen Bank AG', '30043', 'BSZHCHZZXXX'],
    ['Sparcassa 1816 Genossenschaft', '30044', 'RBABCH22814'],
    ['BANK ZIMMERBERG AG', '30045', 'RBABCH22824'],
    ['Regiobank Männedorf AG', '30046', 'RBABCH22828'],
    ['Lienhardt & Partner Privatbank Zürich AG', '30047', 'RBABCH22830'],
    ['Ersparniskasse Schaffhausen AG', '30048', 'RBABCH22835'],
    ['Bank Avera Genossenschaft', '30049', 'RBABCH22850'],
    ['BS Bank Schaffhausen AG', '30050', 'RBABCH22858'],
    ['Spar- und Leihkasse Thayngen AG', '30051', 'RBABCH22866'],
    ['Leihkasse Stammheim AG', '30053', 'RBABCH22875'],
    ['Zürcher Landbank AG', '30054', 'RBABCH22877'],
    ['Bank BSU Genossenschaft', '30055', 'RBABCH22888'],
    ['acrevis Bank AG', '30056', 'ACRGCH22XXX'],
    ['Alpha RHEINTAL Bank AG', '30057', 'ARBHCH22XXX'],
    ['Clientis Bank Oberuzwil AG', '30058', 'RBABCH22935'],
    ['Clientis Bank Toggenburg AG', '30060', 'RBABCH22955'],
    ['Bank in Zuzwil', '30061', 'RBABCH22964'],
    ['Clientis Bank Thur Genossenschaft', '30062', 'RBABCH22977'],
    ['Biene Bank im Rheintal Genossenschaft', '30063', 'RBABCH22980'],
    ['Banca Popolare di Sondrio (Suisse) SA', '30110', 'POSOCH22XXX'],
    ['Crédit Agricole next Bank (Suisse) SA', '30113', 'AGRICHGXXXX'],
    ['Cembra Money Bank AG', '30114', 'CMBNCHZHXXX'],
    ['Spar- und Leihkasse Wynigen AG', '30115', 'SLWYCH21XXX'],
    ['Hypothekarbank Lenzburg AG', '30116', 'HYPLCH22XXX'],
    ['Ersparniskasse Speicher', '30117', 'ERSCCH21XXX'],
    ['Baloise Bank AG', '30118', 'KBSOCH22XXX'],
    ['LGT Bank (Schweiz) AG', '30119', 'BLFLCHBBXXX'],
    ["Caisse d'Epargne Riviera, société coopérative", '30121', 'CDDVCH21XXX'],
    ['Bezirks-Sparkasse Dielsdorf Genossenschaft', '30122', 'BZSDCH22XXX'],
    ['Alternative Bank Schweiz AG', '30123', 'ABSOCH22XXX'],
    ['WIR Bank Genossenschaft', '30124', 'WIRBCHBBXXX'],
    ['Freie Gemeinschaftsbank Genossenschaft', '30125', 'FRGGCHB1XXX'],
    ['Bank EKI Genossenschaft', '30126', 'EKIICH22XXX'],
    ['Bank EEK AG', '30127', 'EEKBCH22XXX'],
    ['Spar- und Leihkasse Bucheggberg', '30128', 'SLBUCH22XXX'],
    ['Spar- und Leihkasse Gürbetal AG', '30129', 'SLGUCH2MXXX'],
    ['DC Bank Deposito-Cassa der Stadt Bern', '30130', 'RBABCH22DCB'],
    ['VZ Depotbank AG', '30131', 'VZDBCHZZXXX'],
    ['Migros Bank AG', '30132', 'MIGRCHZZXXX'],
    ['Bank Cler AG', '30133', 'BCLRCHBBXXX'],
    ['Cornèr Banca SA', '30141', 'CBLUCH22XXX'],
    ['Bank Gantrisch Genossenschaft', '30143', 'BGAGCH22XXX'],
    ['Ersparniskasse Rüeggisberg Genossenschaft', '30144', 'EKRUCH21XXX'],
    ['BBO Bank Brienz Oberhasli AG', '30145', 'BBOBCH22XXX'],
    ['Società Bancaria Ticinese', '30146', 'SBTICH22XXX'],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft, Innsbruck',
      '30147',
      'BTVACH22XXX'
    ],
    ['Privatbank IHAG Zürich AG', '30148', 'IHZUCHZZXXX'],
    ['BZ Bank Aktiengesellschaft', '30151', 'BZBKCH2WXXX'],
    [
      'JPMorgan Chase Bank, National Association, Columbus',
      '30152',
      'CHASCHGXXXX'
    ],
    ['Banca del Sempione SA', '30153', 'BASECH22XXX'],
    ['BNP Paribas (Suisse) SA', '30154', 'BPPBCHGGXXX'],
    [
      'HSBC Bank plc, London, Zweigniederlassung Zürich',
      '30156',
      'HSBCCHZZXXX'
    ],
    ['AEK BANK 1826 Genossenschaft', '30157', 'AEKTCH22XXX'],
    ['Bank CIC (SCHWEIZ) AG', '30159', 'CIALCHBBXXX'],
    ['Bank Linth LLB AG', '30162', 'LINSCH23XXX'],
    ['Bank Thalwil Genossenschaft', '30163', 'BKTHCH22XXX'],
    ['CA Indosuez (Switzerland) SA', '30164', 'AGRICHGGXXX'],
    ['Bank J. Safra Sarasin AG', '30165', 'SARACHBBXXX'],
    ['CBH - Compagnie Bancaire Helvétique SA', '30166', 'BSSACHGGXXX'],
    ['Deutsche Bank AG Zürich Branch', '30167', 'DEUTCHZZXXX'],
    ['Swissquote Bank SA', '30168', 'SWQBCHZZXXX'],
    ['Spar- und Leihkasse Frutigen AG', '30169', 'SLFFCH22XXX'],
    ['Regiobank Solothurn AG', '30170', 'RSOSCH22XXX'],
    [
      'Liechtensteinische Landesbank Aktiengesellschaft',
      '30172',
      'LILALI2XXXX'
    ],
    ['Neue Bank AG', '30173', 'NBANLI22XXX'],
    ['VP Bank AG', '30174', 'VPBVLI2XXXX'],
    ['LGT Bank AG', '30175', 'BLFLLI2XXXX'],
    [
      'Hypo Vorarlberg Bank AG, Bregenz, Zweigniederl., St. Gallen',
      '30176',
      'RBABCH22VLH'
    ],
    ['COMMERZBANK AG FF, Zweigniederlassung Zürich', '30177', 'COBACHZHXXX'],
    ['Sparkasse Bundespersonal', '30178', 'SKBPCH22XXX'],
    ['Banco Santander International SA', '30180', 'BSCHCHGGXXX'],
    ['Credit Europe Bank (Suisse) SA', '30182', 'FSUICHGGXXX'],
    ['Investec Bank (Switzerland) AG', '30184', 'IVESCHZZXXX'],
    ['Kaleido Privatbank AG', '30186', 'ANPRCH22XXX'],
    ['BANQUE PROFIL DE GESTION SA', '30187', 'BPDGCHGGXXX'],
    ['One Swiss Bank SA', '30187', 'BQBHCHGGXXX'],
    ['F. van Lanschot Bankiers (Schweiz) AG', '30188', 'FVLBCHZZXXX'],
    ['Banque Internationale à Luxembourg (Suisse) SA', '30189', 'BILSCHZZXXX'],
    ['BBVA SA', '30190', 'BBVACHZZXXX'],
    ['Frankfurter Bankgesellschaft (Schweiz) AG', '30194', 'FBGSCHZZXXX'],
    ['Bank von Roll AG', '30196', 'VRLLCHZZXXX'],
    ['Banque SYZ SA', '30197', 'SYCOCHGGXXX'],
    ["Caisse d'Epargne de Nyon société coopérative", '30198', 'CAGYCH21XXX'],
    ["Caisse d'Epargne d'Aubonne Sociéte coopérative", '30199', 'RBABCH22CEA'],
    ['Banque Algérienne du Commerce Extérieur SA', '30201', 'AEXTCHZZXXX'],
    [
      "Caisse d'Epargne et de Crédit Mutuel de Chermignon",
      '30202',
      'CDEMCH21XXX'
    ],
    [
      'CACEIS Bank, Montrouge, succursale de Nyon / Suisse',
      '30203',
      'ISAECH2NXXX'
    ],
    ['NPB Neue Privat Bank AG', '30206', 'NEPICHZZXXX'],
    ['QNB (Suisse) SA', '30207', 'QNBACHGGXXX'],
    ['J.P. Morgan (Suisse) SA', '30208', 'MGTCCHGGXXX'],
    ['Intesa Sanpaolo Private Bank (Suisse) Morval SA', '30209', 'SPCHCHGGXXX'],
    ['Reyl & Cie S.A.', '30209', 'REYLCHGGXXX'],
    ['NBK Banque Privée (Suisse) SA', '30210', 'NBOKCHGGXXX'],
    [
      'ING Bank N.V., Amsterdam, succursale de Lancy/Genève',
      '30211',
      'BBRUCHGTXXX'
    ],
    ['Banque Eric Sturdza SA', '30212', 'BABRCHGGXXX'],
    ['Banca Credinvest SA', '30213', 'BCRECH22XXX'],
    ['Banca Aletti & C. (Suisse) SA', '30214', 'VRBPCH22XXX'],
    ['Axion SWISS Bank SA', '30216', 'UNCECH22XXX'],
    ['FAB Private Bank (Suisse) SA', '30217', 'NBPSCHGGXXX'],
    ['Mercantil Bank (Schweiz) AG', '30218', 'BAMRCHZZXXX'],
    ['VP Bank (Schweiz) AG', '30220', 'VPBVCHZHXXX'],
    ['SELVI & Cie SA', '30221', 'SELVCHGGXXX'],
    ['Bergos AG', '30223', 'BEGOCHZZXXX'],
    ['BANQUE BANORIENT (SUISSE) SA', '30224', 'BLOMCHGGXXX'],
    ['Scobag Privatbank AG', '30225', 'SCOPCHBBXXX'],
    ['UNITED MIZRAHI BANK (Schweiz) AG', '30226', 'UMBLCHZZXXX'],
    ['Hyposwiss Private Bank Genève SA', '30226', 'CCIECHGGXXX'],
    ['Privatbank Von Graffenried AG', '30228', 'GRAFCH22XXX'],
    ['Dreyfus Söhne & Cie AG, Banquiers', '30229', 'DREYCHBBXXX'],
    ['Banque Bonhôte & Cie SA', '30230', 'BONHCH22XXX'],
    ['Edmond de Rothschild (Suisse) S.A', '30231', 'PRIBCHGGXXX'],
    ['Trafina Privatbank AG', '30232', 'TRAPCHBBXXX'],
    ['Maerki, Baumann & Co. AG', '30233', 'MAEBCHZZXXX'],
    ['BANKMED SUISSE SA', '30234', 'MEDSCHGGXXX'],
    ['Société Générale Private Banking (Suisse) SA', '30236', 'RUEGCHZZXXX'],
    ['Banca del Ceresio SA', '30237', 'BACECH22XXX'],
    ['Banca Zarattini & Co. SA', '30238', 'EUBACH22XXX'],
    ['DZ PRIVATBANK (Schweiz) AG', '30239', 'GENOCHZZXXX'],
    ['EFG Bank European Financial Group SA', '30240', 'EFGBCHGGXXX'],
    ['Nomura Bank (Switzerland) Ltd', '30241', 'NBSZCHZZXXX'],
    [
      'Banque Havilland (Liechtenstein) AG, Vaduz, Zweig. Zürich',
      '30242',
      'BPGECHGGXXX'
    ],
    ['Banque Audi (Suisse) SA', '30243', 'AUDSCHGGXXX'],
    ['Banque de Commerce et de Placements SA', '30244', 'BPCPCHGGXXX'],
    ['Schroder & Co Bank AG', '30245', 'BJHSCHZZXXX'],
    ['Citibank (Switzerland) AG', '30246', 'CBSWCHZZXXX'],
    ['UNION BANCAIRE PRIVEE, UBP SA', '30248', 'UBPGCHGGXXX'],
    ['UBP SA (ex-Banque Privee BCP SA)', '30248', 'MILBCHGGXXX'],
    ['Deutsche Bank (Suisse) S.A.', '30249', 'DEUTCHGGXXX'],
    ['GPB (Schweiz) AG', '30250', 'RKBZCHZZXXX'],
    ['Rothschild & Co Bank AG', '30251', 'ROTACHZZXXX'],
    ['PKB PRIVATE BANK SA', '30252', 'PKBSCH22XXX'],
    ['EFG Bank AG', '30253', 'EFGBCHZZXXX'],
    ['HSBC Private Bank (Suisse) SA', '30254', 'BLICCHGGXXX'],
    ['Citibank N.A., Sioux Falls', '30256', 'CITICHZZXXX'],
    ['Société Générale', '30259', 'SGABCHZZXXX'],
    ['Arab Bank (Switzerland) Ltd.', '30260', 'ARBSCHZZXXX'],
    ['Gonet & Cie SA', '30261', 'GONECHGGXXX'],
    [
      'Gonet & Cie SA (ex Banque Degroof Petercam (Suisse) SA)',
      '30261',
      'PEPSCHGGXXX'
    ],
    [
      'Bank of America Europe DAC, Dublin, Zurich Branch',
      '30262',
      'BOFACH2XXXX'
    ],
    ['Banque Cramer & Cie SA', '30263', 'CRAMCHGGXXX'],
    ['Barclays Bank (Suisse) SA', '30264', 'BARCCHGGXXX'],
    ['Baumann & Cie KmG', '30269', 'BAUMCHBBXXX'],
    ['Bank Vontobel AG', '30270', 'VONTCHZZXXX'],
    ['Banque Lombard Odier & Cie SA', '30271', 'LOCYCHGGXXX'],
    ['Reichmuth & Co.', '30272', 'REICCH22XXX'],
    ['Bordier & Cie SCmA', '30273', 'BORDCHGGXXX'],
    ['Mirabaud & Cie SA', '30275', 'MIRACHGGXXX'],
    ['Piguet Galland & Cie SA', '30277', 'PIGUCH22XXX'],
    ['Habib Bank AG Zürich', '30283', 'HBZUCHZZXXX'],
    ['IBKR Financial Services AG', '30285', 'TMBECH22XXX'],
    ['InCore Bank AG', '30286', 'INCOCHZZXXX'],
    ['BENDURA BANK AG', '30288', 'HYIBLI22XXX'],
    ['Kaiser Partner Privatbank AG', '30289', 'SERBLI22XXX'],
    ['Bank Frick & Co. AG', '30290', 'BFRILI22XXX'],
    ['SIGMA Bank AG', '30291', 'VOAGLI22XXX'],
    ['Banque Havilland (Liechtenstein) AG', '30293', 'SFBALI22XXX'],
    ['Tellco AG', '30295', 'DOMICHZZXXX'],
    ['CIM Banque SA', '30296', 'CIMMCHGGXXX'],
    ['TradeXBank AG', '30297', 'SLBZCHZZXXX'],
    ['S.P. Hinduja Banque Privée SA', '30299', 'ABSGCHGGXXX'],
    ['ABANCA CORPORACION BANCARIA S.A., BETANZOS', '30311', 'CAGLCHGGXXX'],
    ['Banque du Léman SA', '30313', 'BLEMCHGGXXX'],
    ['Quilvest (Switzerland) Ltd.', '30314', 'QVCHCHZHXXX'],
    ['Banco Itaú (Suisse) SA', '30315', 'ITAUCHZZXXX'],
    ['Globalance Bank AG', '30316', 'GLBNCHZZXXX'],
    ['Dukascopy Bank SA', '30317', 'DUBACHGGXXX'],
    ['Helvetische Bank AG', '30318', 'SFBFCH22XXX'],
    ['Aquila AG', '30319', 'AQULCHZZXXX'],
    ['Sygnum Bank AG', '30321', 'SYGNCHZZXXX'],
    ['SEBA Bank AG', '30322', 'SCRYCH22XXX'],
    ['CACEIS Investor Services Bank S.A.', '30323', 'FETACHZZXXX'],
    ['Remaco Asset Management AG', '30324', 'RMCOCHBBXXX'],
    ['LF Finance (Suisse) SA', '30325', 'LFFSCHGGXXX'],
    [
      'State Street Bank International GmbH, München, ZN Zürich',
      '30326',
      'SSBECHZZXXX'
    ],
    ['Zähringer Privatbank AG', '30327', 'ZAPRCH22XXX'],
    ['BIZ Bank für Internationalen Zahlungsausgleich', '30328', 'BISBCHBBXXX'],
    ['Industrial and Commercial Bank of China Limited', '30329', 'ICBKCHZZXXX'],
    ['JL Securities SA', '30330', 'JLSECHGGXXX'],
    ['Fidurhône SA', '30331', 'FIDHCHGGXXX'],
    ['Privatbank Bellerive AG', '30333', 'PRBECHZZXXX'],
    ['Citibank N.A.', '30520', 'CITIGB2LXXX'],
    ['Zürcher Kantonalbank', '30700', 'ZKBKCHZZ80A'],
    ['Aargauische Kantonalbank', '30761', 'KBAGCH22XXX'],
    ['Appenzeller Kantonalbank', '30763', 'AIKACH22XXX'],
    ['Banca dello Stato del Cantone Ticino', '30764', 'BSCTCH22XXX'],
    ['Banque Cantonale du Valais', '30765', 'BCVSCH2LXXX'],
    ['Banque Cantonale Neuchâteloise', '30766', 'BCNNCH22XXX'],
    ['Banque Cantonale Vaudoise', '30767', 'BCVLCH2LXXX'],
    ['Banque Cantonale de Fribourg', '30768', 'BEFRCH22XXX'],
    ['Basellandschaftliche Kantonalbank', '30769', 'BLKBCH22XXX'],
    ['Basler Kantonalbank', '30770', 'BKBBCHBBXXX'],
    ['Glarner Kantonalbank', '30773', 'GLKBCH22XXX'],
    ['Graubündner Kantonalbank', '30774', 'GRKBCH2270A'],
    ['Schwyzer Kantonalbank', '30777', 'KBSZCH22XXX'],
    ['Luzerner Kantonalbank AG', '30778', 'LUKBCH2260A'],
    ['Nidwaldner Kantonalbank', '30779', 'NIKACH22XXX'],
    ['Obwaldner Kantonalbank', '30780', 'OBWKCH22XXX'],
    ['St. Galler Kantonalbank AG', '30781', 'KBSGCH22XXX'],
    ['Schaffhauser Kantonalbank', '30782', 'SHKBCH2SXXX'],
    ['Thurgauer Kantonalbank', '30784', 'KBTGCH22XXX'],
    ['Urner Kantonalbank', '30785', 'URKNCH22XXX'],
    ['Zuger Kantonalbank', '30787', 'KBZGCH22XXX'],
    ['Banque Cantonale de Genève', '30788', 'BCGECHGGXXX'],
    ['Banque Cantonale du Jura SA', '30789', 'BCJUCH22XXX'],
    ['Berner Kantonalbank AG', '30790', 'KBBECH22XXX'],
    ['Raiffeisen', '30808', 'RAIFCH22XXX'],
    ['Credit Suisse (Schweiz) AG', '31000', 'CRESCHZZ80A'],
    ['radicant bank ag', '31100', 'RDCTCHZHXXX'],
    ['Credit Suisse AG', '31866', 'CRESCHZHXXX'],
    ['Sparkasse Sense', '61861', 'RBABCH22186'],
    ["Caisse d'Epargne Courtelary SA", '62401', 'RBABCH22240'],
    ["Caisse d'Epargne Courtelary SA", '62402', 'RBABCH22240'],
    ["Caisse d'Epargne Courtelary SA", '62403', 'RBABCH22240'],
    ['Clientis EB Entlebucher Bank AG', '66701', 'RBABCH22670'],
    ['Clientis EB Entlebucher Bank AG', '66702', 'RBABCH22670'],
    ['Sparcassa 1816 Genossenschaft', '68141', 'RBABCH22814'],
    ['Sparcassa 1816 Genossenschaft', '68142', 'RBABCH22814'],
    ['Sparcassa 1816 Genossenschaft', '68143', 'RBABCH22814'],
    ['Sparcassa 1816 Genossenschaft', '68144', 'RBABCH22814'],
    ['BANK ZIMMERBERG AG', '68241', 'RBABCH22824'],
    ['BANK ZIMMERBERG AG', '68242', 'RBABCH22824'],
    ['Banca dello Stato del Cantone Ticino', '76410', 'BSCTCH22LUG'],
    ['Banca dello Stato del Cantone Ticino', '76420', 'BSCTCH22LOC'],
    ['Banca dello Stato del Cantone Ticino', '76430', 'BSCTCH22CHI'],
    ['Banque Cantonale Vaudoise', '76711', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76712', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76713', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76714', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76715', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76716', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76717', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76718', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76719', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76720', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76721', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76722', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76723', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76724', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76725', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76726', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76727', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76728', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76729', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76730', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76731', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76732', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76733', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76734', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76735', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76736', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76737', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76738', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76739', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76740', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76741', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76742', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76743', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76744', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76745', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76746', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76747', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76748', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76749', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76750', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76751', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76752', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76753', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76754', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76755', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76756', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76758', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76761', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76762', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76763', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76764', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76765', 'BCVLCH2LXXX'],
    ['Banque Cantonale Vaudoise', '76766', 'BCVLCH2LXXX'],
    ['Banque Cantonale de Fribourg', '76822', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76823', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76824', 'BEFRCH22XXX'],
    ['Freiburger Kantonalbank', '76825', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76826', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76827', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76828', 'BEFRCH22XXX'],
    ['Freiburger Kantonalbank', '76829', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76830', 'BEFRCH22XXX'],
    ['Freiburger Kantonalbank', '76831', 'BEFRCH22XXX'],
    ['Freiburger Kantonalbank', '76832', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76833', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76834', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76835', 'BEFRCH22XXX'],
    ['Freiburger Kantonalbank', '76836', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76837', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76838', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76839', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76840', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76841', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76842', 'BEFRCH22XXX'],
    ['Banque Cantonale de Fribourg', '76843', 'BEFRCH22XXX'],
    ['Schwyzer Kantonalbank', '77711', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77712', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77713', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77714', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77715', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77716', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77717', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77718', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77719', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77720', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77721', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77722', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77723', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77724', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77725', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77726', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77727', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77728', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77729', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77730', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77731', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77732', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77733', 'KBSZCH22XXX'],
    ['Schwyzer Kantonalbank', '77734', 'KBSZCH22XXX'],
    ['Luzerner Kantonalbank AG', '77811', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77812', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77813', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77814', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77815', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77816', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77817', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77818', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77819', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77820', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77821', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77823', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77824', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77825', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77828', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77829', 'LUKBCH2260A'],
    ['Luzerner Kantonalbank AG', '77860', 'LUKBCH2260A'],
    ['St. Galler Kantonalbank AG', '78102', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78103', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78111', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78112', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78113', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78114', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78115', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78116', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78117', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78118', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78119', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78120', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78121', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78122', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78123', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78124', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78125', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78126', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78127', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78128', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78129', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78130', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78131', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78132', 'KBSGCH22XXX'],
    ['St. Galler Kantonalbank AG', '78158', 'KBSGCH22XXX'],
    ['Schaffhauser Kantonalbank', '78212', 'SHKBCH2SXXX'],
    ['Schaffhauser Kantonalbank', '78213', 'SHKBCH2SXXX'],
    ['Banque Cantonale du Jura SA', '78910', 'BCJUCH22XXX'],
    ['Banque Cantonale du Jura SA', '78920', 'BCJUCH22XXX'],
    ['Raiffeisen Schweiz', '80000', 'RAIFCH22XXX'],
    ['Banque Raiffeisen Région Delémont', '80002', 'RAIFCH22002'],
    ['Raiffeisenbank St.Gallen', '80005', 'RAIFCH22005'],
    ['Banque Raiffeisen  Ajoie', '80027', 'RAIFCH22027'],
    ['Banque Raiffeisen Clos du Doubs et Haute-Ajoie', '80037', 'RAIFCH22037'],
    ['Banque Raiffeisen Pierre Pertuis', '80051', 'RAIFCH22051'],
    ['Banque Raiffeisen Franches-Montagnes', '80059', 'RAIFCH22059'],
    ['Banque Raiffeisen du Val-Terbi', '80073', 'RAIFCH22073'],
    ['Raiffeisenbank Worblen-Emmental', '80094', 'RAIFCH22094'],
    ['Raiffeisenbank Laufental-Thierstein', '80097', 'RAIFCH22097'],
    ['Raiffeisenbank Gürbe', '80098', 'RAIFCH22098'],
    ['Banque Raiffeisen Sarine-Ouest', '80102', 'RAIFCH22102'],
    ['Banque Raiffeisen Région Marly-Cousimbert', '80105', 'RAIFCH22105'],
    ['Banque Raiffeisen Moléson', '80129', 'RAIFCH22129'],
    ['Banque Raiffeisen Broye Vully Lac', '80139', 'RAIFCH22139'],
    ['Banque Raiffeisen Fribourg-Ouest', '80159', 'RAIFCH22159'],
    ['Banque Raiffeisen Région Genève Rhône', '80181', 'RAIFCH22181'],
    ['Banque Raiffeisen Genève Rive Gauche', '80187', 'RAIFCH22187'],
    ["Banque Raiffeisen d'Arve et Lac", '80187', 'RAIFCH22188'],
    ['Banque Raiffeisen Villes et Vignobles genevois', '80210', 'RAIFCH22210'],
    ['Banque Raiffeisen de la Versoix', '80215', 'RAIFCH22215'],
    ['Banque Raiffeisen des Montagnes Neuchâteloises', '80237', 'RAIFCH22237'],
    ['Banque Raiffeisen Neuchâtel et Vallées', '80241', 'RAIFCH22241'],
    ['Banca Raiffeisen del Basso Mendrisiotto', '80272', 'RAIFCH22272'],
    ['Banca Raiffeisen Piano di Magadino', '80280', 'RAIFCH22280'],
    ['Banca Raiffeisen del Vedeggio', '80283', 'RAIFCH22283'],
    ['Banca Raiffeisen Basso Ceresio', '80287', 'RAIFCH22287'],
    ['Banca Raiffeisen Morbio-Vacallo', '80290', 'RAIFCH22290'],
    ['Banca Raiffeisen Malcantonese', '80317', 'RAIFCH22307'],
    ['Banca Raiffeisen del Malcantone', '80317', 'RAIFCH22317'],
    ['Banca Raiffeisen Losone Pedemonte Vallemaggia', '80333', 'RAIFCH22333'],
    ['Banca Raiffeisen Mendrisio e Valle di Muggio', '80340', 'RAIFCH22340'],
    ['Banca Raiffeisen del Camoghè', '80344', 'RAIFCH22344'],
    ['Banca Raiffeisen Tre Valli', '80350', 'RAIFCH22350'],
    ['Banca Raiffeisen Colline del Ceresio', '80362', 'RAIFCH22362'],
    ['Banca Raiffeisen della Campagnadorna', '80365', 'RAIFCH22365'],
    ['Banca Raiffeisen del Cassarate', '80366', 'RAIFCH22366'],
    ['Banca Raiffeisen Lugano', '80375', 'RAIFCH22375'],
    ['Banca Raiffeisen Locarno', '80379', 'RAIFCH22379'],
    ['Banca Raiffeisen Bellinzonese e Visagno', '80387', 'RAIFCH22387'],
    ['Banque Raiffeisen Mont-Aubert Orbe', '80401', 'RAIFCH22401'],
    ['Banque Raiffeisen Talent-Menthue', '80414', 'RAIFCH22403'],
    ["Banque Raiffeisen d'Assens-Talent", '80414', 'RAIFCH22414'],
    [
      'Banque Raiffeisen Alpes Riviera Chablais Vaudois',
      '80430',
      'RAIFCH22430'
    ],
    [
      'Banque Raiffeisen Alpes Riviera Chablais Vaudois',
      '80430',
      'RAIFCH22447'
    ],
    ['Banque Raiffeisen du Gros-de-Vaud', '80434', 'RAIFCH22434'],
    ['Banque Raiffeisen Plateau du Jorat-Molondin', '80434', 'RAIFCH22465'],
    ['Banque Raiffeisen Nyon-La Vallée', '80442', 'RAIFCH22442'],
    ['Banque Raiffeisen du Mont-Tendre', '80445', 'RAIFCH22445'],
    ['Banque Raiffeisen Lausanne Haute-Broye-Jorat', '80451', 'RAIFCH22451'],
    ['Banque Raiffeisen de Lavaux', '80454', 'RAIFCH22454'],
    ['Banque Raiffeisen Morges Venoge', '80460', 'RAIFCH22460'],
    ["Banque Raiffeisen d'Yverdon-les-Bains", '80472', 'RAIFCH22472'],
    ['Banque Raiffeisen de la Broye', '80479', 'RAIFCH22479'],
    ['Banque Raiffeisen de Gimel', '80485', 'RAIFCH22485'],
    ['Raiffeisenbank Mischabel-Matterhorn', '80496', 'RAIFCH22496'],
    ['Raiffeisenbank Gampel-Raron', '80521', 'RAIFCH22521'],
    ['Raiffeisenbank Region Leuk', '80527', 'RAIFCH22527'],
    ['Raiffeisenbank Belalp-Simplon', '80532', 'RAIFCH22532'],
    ['Raiffeisenbank Aletsch-Goms', '80539', 'RAIFCH22539'],
    ['Raiffeisenbank Region Visp', '80553', 'RAIFCH22553'],
    ['Banque Raiffeisen Sion et Région', '80572', 'RAIFCH22572'],
    ['Banque Raiffeisen Entremont', '80581', 'RAIFCH22581'],
    ['Banque Raiffeisen du Haut-Léman', '80588', 'RAIFCH22588'],
    ['Banque Raiffeisen Martigny et Région', '80595', 'RAIFCH22595'],
    ['Banque Raiffeisen de Sierre & Région', '80598', 'RAIFCH22598'],
    [
      'Banque Raiffeisen de Massongex-St-Maurice-Vérossaz',
      '80606',
      'RAIFCH22606'
    ],
    ['Banque Raiffeisen de Monthey', '80611', 'RAIFCH22611'],
    ['Banque Raiffeisen des Communes du Haut Plateau', '80615', 'RAIFCH22615'],
    ['Banque Raiffeisen de Troistorrents-Morgins', '80626', 'RAIFCH22626'],
    ["Banque Raiffeisen de Val-d'Illiez-Champéry", '80627', 'RAIFCH22627'],
    ['Raiffeisenbank Böttstein', '80652', 'RAIFCH22652'],
    ['Raiffeisenbank an der Limmat', '80666', 'RAIFCH22666'],
    ['Raiffeisenbank Mutschellen-Reppischtal', '80673', 'RAIFCH22673'],
    ['Raiffeisenbank Wasserschloss', '80690', 'RAIFCH22690'],
    ['Raiffeisenbank Mettauertal', '80691', 'RAIFCH22689'],
    ['Raiffeisenbank Regio Frick-Mettauertal', '80691', 'RAIFCH22691'],
    ['Raiffeisenbank Regio Laufenburg', '80696', 'RAIFCH22696'],
    ['Raiffeisenbank Aarau-Lenzburg', '80698', 'RAIFCH22698'],
    ['Raiffeisenbank Surbtal-Wehntal', '80700', 'RAIFCH22700'],
    ['Raiffeisenbank Aare-Rhein', '80701', 'RAIFCH22701'],
    ['Raiffeisenbank Kelleramt-Albis', '80702', 'RAIFCH22702'],
    ['Raiffeisenbank Aare-Reuss', '80704', 'RAIFCH22704'],
    ['Raiffeisenbank am Lindenberg', '80705', 'RAIFCH22669'],
    ['Raiffeisenbank Reuss-Lindenberg', '80705', 'RAIFCH22705'],
    ['Raiffeisenbank Möhlin', '80706', 'RAIFCH22706'],
    ['Raiffeisenbank Rohrdorferberg-Fislisbach', '80719', 'RAIFCH22719'],
    ['Raiffeisenbank Region Zofingen', '80721', 'RAIFCH22721'],
    ['Raiffeisenbank Reitnau-Rued', '80723', 'RAIFCH22723'],
    ['Raiffeisenbank Oberfreiamt', '80728', 'RAIFCH22728'],
    ['Raiffeisenbank Villmergen', '80736', 'RAIFCH22736'],
    ['Raiffeisenbank Lägern-Baregg', '80740', 'RAIFCH22740'],
    ['Raiffeisenbank Wohlen', '80744', 'RAIFCH22744'],
    ['Raiffeisenbank Siggenthal-Würenlingen', '80746', 'RAIFCH22746'],
    ['Raiffeisenbank Würenlos', '80747', 'RAIFCH22747'],
    ['Raiffeisenbank Wegenstettertal', '80748', 'RAIFCH22748'],
    ['Raiffeisenbank Zufikon', '80749', 'RAIFCH22749'],
    ['Raiffeisenbank Liestal-Oberbaselbiet', '80773', 'RAIFCH22773'],
    ['Raiffeisenbank Leimental', '80774', 'RAIFCH22744'],
    ['Raiffeisenbank Leimental', '80774', 'RAIFCH22774'],
    ['Raiffeisenbank Therwil Ettingen', '80774', 'RAIFCH22784'],
    ['Raiffeisenbank Allschwil-Schönenbuch', '80775', 'RAIFCH22775'],
    ['Raiffeisenbank Regio Muttenz', '80776', 'RAIFCH22776'],
    ['Raiffeisenbank Aesch-Pfeffingen', '80779', 'RAIFCH22779'],
    ['Raiffeisenbank Reinach BL', '80780', 'RAIFCH22780'],
    ['Raiffeisen', '80808', 'RAIFCH22XXX'],
    ['Raiffeisenbank Kiesental', '80811', 'RAIFCH22811'],
    ['Raiffeisenbank Niedersimmental', '80816', 'RAIFCH22816'],
    ['Raiffeisenbank Steffisburg', '80817', 'RAIFCH22817'],
    ['Raiffeisenbank Grauholz', '80819', 'RAIFCH22819'],
    ['Raiffeisenbank Frutigland', '80820', 'RAIFCH22820'],
    ['Raiffeisenbank Jungfrau', '80842', 'RAIFCH22842'],
    ['Raiffeisenbank Region Haslital-Brienz', '80843', 'RAIFCH22843'],
    ['Raiffeisenbank Obersimmental-Saanenland', '80856', 'RAIFCH22856'],
    ['Raiffeisenbank Schwarzwasser', '80860', 'RAIFCH22860'],
    ['Raiffeisenbank Bielersee', '80862', 'RAIFCH22833'],
    ['Raiffeisenbank Seeland', '80862', 'RAIFCH22862'],
    ['Raiffeisenbank Thunersee', '80867', 'RAIFCH22867'],
    ['Raiffeisenbank Unteremmental', '80875', 'RAIFCH22875'],
    ['Raiffeisenbank Oberes Emmental', '80882', 'RAIFCH22882'],
    ['Raiffeisenbank Region Burgdorf', '80888', 'RAIFCH22888'],
    ['Raiffeisenbank Sense-Oberland', '80895', 'RAIFCH22895'],
    ['Raiffeisenbank See-Lac', '80896', 'RAIFCH22896'],
    ['Raiffeisenbank Freiburg Ost / Fribourg Est', '80901', 'RAIFCH22901'],
    ['Raiffeisenbank Sensetal', '80905', 'RAIFCH22905'],
    ['Raiffeisenbank Dulliken-Starrkirch', '80911', 'RAIFCH22911'],
    ['Raiffeisenbank Gäu-Bipperamt', '80912', 'RAIFCH22912'],
    ['Raiffeisenbank Aare-Langete', '80914', 'RAIFCH22914'],
    ['Raiffeisenbank Mittelgösgen-Staffelegg', '80918', 'RAIFCH22918'],
    ['Raiffeisenbank Balsthal-Laupersdorf', '80930', 'RAIFCH22930'],
    ['Raiffeisenbank Wasseramt-Buchsi', '80938', 'RAIFCH22938'],
    ['Raiffeisenbank Dornach', '80939', 'RAIFCH22939'],
    ['Raiffeisenbank Untergäu', '80947', 'RAIFCH22947'],
    ['Raiffeisenbank Dünnerntal-Guldental', '80962', 'RAIFCH22962'],
    ['Raiffeisenbank Däniken-Gretzenbach', '80965', 'RAIFCH22936'],
    ['Raiffeisenbank am Eppenberg', '80965', 'RAIFCH22965'],
    ['Raiffeisenbank Olten', '80970', 'RAIFCH22970'],
    ['Raiffeisenbank Untere Emme', '80971', 'RAIFCH22971'],
    ['Raiffeisenbank Weissenstein', '80976', 'RAIFCH22976'],
    ['Raiffeisenbank Appenzeller Hinterland', '81011', 'RAIFCH22A11'],
    ['Raiffeisenbank Heiden', '81012', 'RAIFCH22A12'],
    ['Raiffeisenbank Appenzell', '81023', 'RAIFCH22A23'],
    ['Raiffeisenbank Glarnerland', '81031', 'RAIFCH22A31'],
    ['Raiffeisenbank Bündner Rheintal', '81045', 'RAIFCH22A45'],
    ['Banca Raiffeisen del Moesano', '81054', 'RAIFCH22A54'],
    ['Raiffeisenbank Mittelbünden', '81063', 'RAIFCH22A63'],
    ['Raiffeisenbank Cadi', '81072', 'RAIFCH22A72'],
    ['Raiffeisenbank Surselva', '81073', 'RAIFCH22A73'],
    ['Raiffeisenbank Prättigau-Davos', '81084', 'RAIFCH22A84'],
    ['Banca Raiffeisen Valposchiavo', '81103', 'RAIFCH22B03'],
    ['Raiffeisenbank Engiadina Val Müstair', '81144', 'RAIFCH22B44'],
    ['Raiffeisenbank Pilatus', '81165', 'RAIFCH22B65'],
    ['Raiffeisenbank Adligenswil-Udligenswil-Meggen', '81168', 'RAIFCH22B68'],
    ['Raiffeisenbank Hitzkirchertal', '81169', 'RAIFCH22B69'],
    ['Raiffeisenbank Beromünster', '81170', 'RAIFCH22B70'],
    ['Raiffeisenbank Emmen', '81177', 'RAIFCH22B77'],
    ['Raiffeisenbank im Entlebuch', '81179', 'RAIFCH22B79'],
    ['Raiffeisenbank Horw', '81186', 'RAIFCH22B86'],
    ['Raiffeisenbank Oberseetal', '81187', 'RAIFCH22B87'],
    ['Raiffeisenbank Menznau-Wolhusen', '81194', 'RAIFCH22B94'],
    ['Raiffeisenbank Buttisholz-Ruswil', '81196', 'RAIFCH22B72'],
    ['Raiffeisenbank Sempachersee-Rottal Süd', '81196', 'RAIFCH22B96'],
    ['Raiffeisenbank Luzern', '81203', 'RAIFCH22C03'],
    ['Raiffeisenbank Rothenburg', '81204', 'RAIFCH22C04'],
    ['Raiffeisenbank Luzerner Hinterland', '81211', 'RAIFCH22C11'],
    ['Raiffeisenbank Ettiswil', '81212', 'RAIFCH22C12'],
    ['Raiffeisenbank Luzerner Landschaft Nordwest', '81214', 'RAIFCH22C14'],
    ['Raiffeisenbank Nidwalden', '81223', 'RAIFCH22C23'],
    ['Raiffeisenbank Obwalden', '81232', 'RAIFCH22C32'],
    ['Raiffeisenbank Regio St. Gallen West', '81241', 'RAIFCH22C41'],
    ['Raiffeisenbank Werdenberg', '81251', 'RAIFCH22C51'],
    ['Raiffeisenbank Benken', '81256', 'RAIFCH22C56'],
    ['Raiffeisenbank Bernhardzell', '81259', 'RAIFCH22C59'],
    [
      'Raiffeisenbank Regio Unteres Toggenburg & Neckertal',
      '81261',
      'RAIFCH22C61'
    ],
    ['Raiffeisenbank Neckertal', '81261', 'RAIFCH22D00'],
    ['Raiffeisenbank Diepoldsau-Schmitter', '81262', 'RAIFCH22C62'],
    ['Raiffeisenbank Gossau-Andwil-Niederwil', '81271', 'RAIFCH22C71'],
    ['Raiffeisenbank Rapperswil-Jona', '81274', 'RAIFCH22C74'],
    ['Raiffeisenbank Sarganserland', '81281', 'RAIFCH22C81'],
    ['Raiffeisenbank Mörschwil', '81284', 'RAIFCH22C84'],
    ['Raiffeisenbank Obertoggenburg', '81287', 'RAIFCH22C87'],
    ['Raiffeisenbank Niederhelfenschwil', '81289', 'RAIFCH22C89'],
    ['Raiffeisenbank Regio Uzwil', '81291', 'RAIFCH22C91'],
    ['Raiffeisenbank Berneck-Au', '81295', 'RAIFCH22C58'],
    ['Raiffeisenbank Unteres Rheintal', '81295', 'RAIFCH22C95'],
    ['Raiffeisenbank Region Rorschach', '81296', 'RAIFCH22C96'],
    ['Raiffeisenbank Oberes Rheintal', '81297', 'RAIFCH22C97'],
    ['Raiffeisenbank am Ricken', '81298', 'RAIFCH22C98'],
    ['Raiffeisenbank Schänis-Amden', '81302', 'RAIFCH22D02'],
    ['Raiffeisenbank Sennwald', '81304', 'RAIFCH22D04'],
    ['Raiffeisenbank Regio Arbon', '81307', 'RAIFCH22D07'],
    ['Raiffeisenbank Waldkirch', '81313', 'RAIFCH22D13'],
    ['Raiffeisenbank Walenstadt', '81314', 'RAIFCH22D14'],
    ['Raiffeisenbank Mittleres Toggenburg', '81317', 'RAIFCH22D17'],
    ['Raiffeisenbank Mittelrheintal', '81319', 'RAIFCH22D19'],
    ['Raiffeisenbank Wil und Umgebung', '81320', 'RAIFCH22D20'],
    ['Raiffeisenbank Wittenbach-Häggenschwil', '81323', 'RAIFCH22D23'],
    ['Raiffeisenbank Marbach-Rebstein', '81324', 'RAIFCH22D24'],
    [
      'Raiffeisenbank Flawil-Degersheim-Mogelsberg-Oberuzwil',
      '81325',
      'RAIFCH22D25'
    ],
    ['Raiffeisenbank Schaffhausen', '81344', 'RAIFCH22D44'],
    ['Raiffeisenbank Rigi', '81351', 'RAIFCH22D51'],
    ['Raiffeisenbank Region linker Zürichsee', '81356', 'RAIFCH22D56'],
    ['Raiffeisenbank Muotathal', '81360', 'RAIFCH22D60'],
    ['Raiffeisenbank Einsiedeln-Ybrig', '81361', 'RAIFCH22D61'],
    ['Raiffeisenbank Yberg', '81361', 'RAIFCH22D64'],
    ['Raiffeisenbank Regio Altnau', '81371', 'RAIFCH22D71'],
    ['Raiffeisenbank Aadorf', '81377', 'RAIFCH22D77'],
    ['Raiffeisenbank am Bichelsee', '81378', 'RAIFCH22D78'],
    ['Raiffeisenbank Mittelthurgau', '81380', 'RAIFCH22D80'],
    ['Raiffeisenbank Untersee-Rhein', '81382', 'RAIFCH22D82'],
    ['Raiffeisenbank Frauenfeld', '81384', 'RAIFCH22D84'],
    ['Raiffeisenbank Neukirch-Romanshorn', '81398', 'RAIFCH22D98'],
    ['Raiffeisenbank Seerücken', '81401', 'RAIFCH22E01'],
    ['Raiffeisenbank Regio Sirnach', '81402', 'RAIFCH22E02'],
    ['Raiffeisenbank Tägerwilen', '81412', 'RAIFCH22E12'],
    ['Raiffeisenbank Münchwilen-Tobel', '81414', 'RAIFCH22E14'],
    ['Raiffeisenbank Wängi-Matzingen', '81416', 'RAIFCH22E16'],
    ['Raiffeisenbank Amriswil-Dozwil-Sommeri', '81417', 'RAIFCH22D73'],
    ['Raiffeisenbank Amriswil Bischofszell', '81417', 'RAIFCH22E17'],
    ['Raiffeisenbank Urnerland', '81431', 'RAIFCH22E31'],
    ['Raiffeisenbank Schächental', '81432', 'RAIFCH22E32'],
    ['Raiffeisenbank Zug', '81454', 'RAIFCH22E54'],
    ['Raiffeisenbank Cham-Steinhausen', '81455', 'RAIFCH22E55'],
    ['Raiffeisenbank Hünenberg-Risch', '81456', 'RAIFCH22E56'],
    ['Raiffeisenbank Risch-Rotkreuz', '81456', 'RAIFCH22E60'],
    ['Raiffeisenbank Menzingen-Neuheim', '81457', 'RAIFCH22E57'],
    ['Raiffeisenbank Region Ägerital-Sattel', '81459', 'RAIFCH22E59'],
    ['Raiffeisenbank Zürcher Oberland', '81471', 'RAIFCH22E71'],
    ['Raiffeisenbank Zürich Flughafen', '81474', 'RAIFCH22E74'],
    ['Raiffeisenbank Züri-Unterland', '81475', 'RAIFCH22E75'],
    ['Raiffeisenbank Region Glatt', '81477', 'RAIFCH22E77'],
    ['Raiffeisenbank Weinland', '81479', 'RAIFCH22E79'],
    ['Raiffeisenbank rechter Zürichsee', '81481', 'RAIFCH22E81'],
    ['Raiffeisenbank Winterthur', '81485', 'RAIFCH22E85'],
    ['Raiffeisenbank Basel', '81486', 'RAIFCH22E86'],
    ['Raiffeisenbank Zürich', '81487', 'RAIFCH22E87'],
    ['Raiffeisenbank Bern', '81488', 'RAIFCH22E88'],
    ['Raiffeisenbank Thalwil', '81490', 'RAIFCH22E90'],
    ['RAIFFEISEN SCHWEIZ, FIRMENKUNDEN', '81491', 'RAIFCH22E91'],
    ['Union Bancaire Privée (Europe) SA', '82007', 'DBSALULLFND'],
    ['Mirabaud & Cie (Europe) SA', '82008', 'MIRALULLXXX'],
    ['Monzo Bank Limited', '82016', 'MONZGB2LXXX'],
    ['Starling Bank Limited', '82018', 'SRLGGB3LXXX'],
    ['Cembra Money Bank SA', '82961', 'CMBNCHZHXXX'],
    ['Cembra Money Bank SA', '82962', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82963', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82964', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82965', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82966', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82967', 'CMBNCHZHXXX'],
    ['Cembra Money Bank SA', '82968', 'CMBNCHZHXXX'],
    ['Cembra Money Bank SA', '82969', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82971', 'CMBNCHZHXXX'],
    ['Cembra Money Bank SA', '82972', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82973', 'CMBNCHZHXXX'],
    ['Cembra Money Bank SA', '82975', 'CMBNCHZHXXX'],
    ['Cembra Money Bank SA', '82976', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82977', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82979', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82981', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82983', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82984', 'CMBNCHZHXXX'],
    ['Cembra Money Bank SA', '82986', 'CMBNCHZHXXX'],
    ['Cembra Money Bank AG', '82998', 'CMBNCHZHXXX'],
    ['Bank von Roll AG', '83002', 'VRLLCHZZXXX'],
    ['SIX x-clear AG', '83003', 'CLRXCHZZXXX'],
    ['Industrial and Commercial Bank of China Limited', '83006', 'ICBKCHZZXXX'],
    ['JL Securities SA', '83007', 'JLSECHGGXXX'],
    ['Fidurhône SA', '83008', 'FIDHCHGGXXX'],
    [
      'Helvetia Schweizerische Versicherungsgesellschaft AG',
      '83009',
      'HELNCH22XXX'
    ],
    ['Privatbank Bellerive AG', '83011', 'PRBECHZZXXX'],
    ['LGT Bank AG, Zweigniederlassung Österreich', '83012', 'BLFLATWWXXX'],
    ['Hottinger AG', '83013', 'JHOTCHZZXXX'],
    ['Sygnum Bank AG', '83014', 'SYGNCHZZXXX'],
    ['MBaer Merchant Bank AG', '83015', 'MBMECHZZXXX'],
    ['AXA Versicherungen AG', '83016', 'AXIPCHZZWIN'],
    ['SIX Digital Exchange AG', '83017', 'SDXXCHZHXXX'],
    ['SEBA Bank AG', '83018', 'SCRYCH22XXX'],
    ['Yapeal AG', '83019', 'YAPECHZ2XXX'],
    ['Cité Gestion SA', '83021', 'CITECHGGXXX'],
    ['ISP Securities Ltd', '83022', 'PLESCHZZXXX'],
    ['Swiss Life AG (EV, geb. Vermögen)', '83024', 'SLAMCHZZEV0'],
    ['Swiss Life AG (freies Vermögen)', '83025', 'SLAMCHZZNZ0'],
    ['AXA Leben AG (Einzel, geb. Vermögen)', '83026', 'AXIPCHZZWLE'],
    ['Baloise Leben AG (Kollektiv, geb. Vermögen)', '83027', 'BAAMCHBBBKL'],
    ['Baloise Leben AG (Einzel, geb. Vermögen)', '83028', 'BAAMCHBBBEL'],
    ['Flowbank SA', '83029', 'FLOKCHGGXXX'],
    ['Swisscanto Fondsleitung AG', '83031', 'SWIPCHZHXXX'],
    [
      'Zürich Versicherungs.-Ges. AG gebundenes Vermögen',
      '83032',
      'ZURICHZFZNT'
    ],
    [
      'Zürich Lebensvers.-Ges. AG Group Life geb. Vermögen',
      '83033',
      'ZURICHZFZKT'
    ],
    [
      'Zürich Lebensvers.-Ges. AG Individual Life geb. Vermögen',
      '83034',
      'ZURICHZFZLT'
    ],
    ['Taurus SA', '83035', 'TARSCHGGXXX'],
    ['radicant bank ag', '83036', 'RDCTCHZHXXX'],
    ['Alpian SA', '83039', 'APNACHGGXXX'],
    ['Kendra Securities House SA', '83040', 'KSEHCHGGXXX'],
    ['Klarpay AG', '83041', 'KLARCH22XXX'],
    ['Private Client Bank AG', '83043', 'PICLCHZ2XXX'],
    ['Bank of China Limited, GenevaBranch', '83045', 'BKCHCHGEXXX'],
    ['SWISS4.0 SA', '83048', 'IWSSCHGGXXX'],
    ['Bank CIC (SCHWEIZ) AG', '87105', 'CIALCHBBXXX'],
    ['Deutsche Bank AG Zürich Branch', '87801', 'DEUTCHZZXXX'],
    ['Deutsche Bank AG Zürich Branch', '87802', 'DEUTCHZZXXX'],
    ['B. Metzler seel. Sohn & Co. AG', '89002', 'METZDEFFXXX'],
    ['Bayerische Landesbank', '89012', 'BYLADEMMXXX'],
    ['COMMERZBANK AG', '89018', 'COBADEFFXXX'],
    ['Deutsche Bank AG', '89022', 'DEUTDEFFXXX'],
    ['DZ Bank AG', '89025', 'GENODEFFXXX'],
    ['Hamburger Sparkasse AG', '89030', 'HASPDEHHXXX'],
    ['Landesbank Hessen-Thüringen', '89034', 'HELADEFFXXX'],
    [
      'Merck Finck, a Quintet Private Bank (Europe) S.A. branch',
      '89042',
      'MEFIDEMM501'
    ],
    ['Morgan Stanley Bank AG', '89045', 'MSFFDEFXXXX'],
    ['Landesbank Baden-Württemberg', '89058', 'SOLADESTXXX'],
    ['HSBC Continental Europe S.A., Germany', '89059', 'TUBDDEDDXXX'],
    ['Banco Santander, SA, Filiale Frankfurt', '89067', 'BSCHDEFFXXX'],
    ['Goldman Sachs Bank Europe SE', '89070', 'GOLDDEFAXXX'],
    ['NatWest Markets plc', '89079', 'RBOSGB2RTCM'],
    ['Dornbirner Sparkasse Bank AG', '89081', 'DOSPAT2DXXX'],
    ['Skandinaviska Enskilda Banken AB', '89088', 'ESSESESSXXX'],
    ['DZ PRIVATBANK S.A.', '89091', 'GENOLULLXXX'],
    ['Barclays Capital', '89092', 'BARCGB33XXX'],
    ['Volksbank Dreiländereck EG', '89093', 'VOLODE66XXX'],
    ['Citibank N.A.', '89095', 'CITIGB2LXXX'],
    ['Allgemeine Sparkasse Oberösterreich Bank AG', '89098', 'ASPKAT2LXXX'],
    ['Erste Group Bank AG', '89099', 'GIBAATWGXXX'],
    ['Coöperatieve Rabobank U.A.', '89102', 'RABONL2UXXX'],
    ['NORD/LB Luxembourg S.A.', '89105', 'NOLALULLXXX'],
    ['Raiffeisenlandesbank Oberösterreich AG', '89109', 'RZOOAT2LXXX'],
    ['Clearstream Banking S.A.', '89115', 'CEDELULLXXX'],
    ['Hypo Vorarlberg Bank AG', '89117', 'HYPVAT2BXXX'],
    ['Intesa Sanpaolo S.P.A.', '89128', 'BCITITMMXXX'],
    [
      'Bank für Tirol und Vorarlberg Aktiengesellschaft',
      '89129',
      'BTVAAT22XXX'
    ],
    ['Banque Internationale à Luxembourg SA', '89136', 'BILLLULLXXX'],
    ['Raiffeisen Bank International AG', '89139', 'RZBAATWWXXX'],
    ['European Investment Bank', '89142', 'BEILLULLXXX'],
    ['ING Bank N.V.', '89151', 'INGBNL2AXXX'],
    ['Sparkasse der Stadt Feldkirch', '89152', 'SPFKAT2BXXX'],
    ['HYPO TIROL BANK AG', '89157', 'HYPTAT22XXX'],
    ['RBC Europe Limited', '89158', 'ROYCGB22XXX'],
    ['Danske Bank A/S', '89162', 'DABADKKKXXX'],
    [
      'Raiffeisen Landesbank Vorarlberg mit Revisionsverband eGen',
      '89165',
      'RVVGAT2BXXX'
    ],
    ['CACEIS Bank S.A., Germany Branch', '89169', 'FMBKDEMMXXX'],
    ['Raiffeisenlandesbank Niederösterreich-Wien AG', '89171', 'RLNWATWWXXX'],
    ['Raiffeisenverband Salzburg eGen', '89176', 'RVSAAT2SXXX'],
    ['Goldman Sachs International', '89191', 'GSILGB2XXXX'],
    [
      'HYPO NOE Landesbank für Niederösterreich und Wien AG',
      '89193',
      'HYPNATWWXXX'
    ],
    ['IKB Deutsche Industriebank AG', '89195', 'IKBDDEDDXXX'],
    ['Santander UK plc', '89196', 'ANTSGB2LXXX'],
    ['Nordea Bank Abp', '89197', 'NDEAFIHHXXX'],
    ['Volksbank Hochrhein eG', '89199', 'GENODE61WT1'],
    ['Sparkasse Hochrhein', '89202', 'SKHRDE6WXXX'],
    ['Cboe Clear Europe N.V.', '89206', 'EMCFNL2AXXX'],
    ['Oesterreichische Kontrollbank AG', '89207', 'OEKOATWWXXX'],
    ['Volksbank Rhein-Wehra eG', '89208', 'GENODE61BSK'],
    ['Banque de Luxembourg S.A.', '89213', 'BLUXLULLXXX'],
    ['Sparkasse Lörrach-Rheinfelden', '89214', 'SKLODE66XXX'],
    ['LCH Limited', '89215', 'LCHLGB2LXXX'],
    ['ABN AMRO Clearing Bank N.V.', '89216', 'ABNCNL2AXXX']
  ];

  /**
   * An object containing all the AT bank data.
   */
  exports.data = bankData;
  exports.bankCodeLength = BANK_CODE_LENGHTS;
  exports.bicNumberLength = BIC_NUMBER_LENGTHS;
  exports.countryCode = COUNTRY_CODE_UPPERCASE;
});

function isPostfinanceCountryValidDir() {
  return {
    require: ['^form', 'ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isPostfinanceCountryValidDir';
      var paymentMethodFieldName = 'isPostfinanceCountryValidPaymentMethod';

      var watchers = [];

      //
      var form = controllers[0];
      var ngModel = controllers[1];

      //
      var isActive = false;
      var dirActiveWatch;

      dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = !!value;
            activateDir();
          } else {
            deactivateDir();
          }
        }
      );

      //For DOM -> model validation
      ngModel.$parsers.unshift(function(countryValue) {
        validateInput(countryValue);
        return countryValue;
      });

      ////////////////////////////////////////////

      function activateDir() {
        //watch country attribute which changes dynamically
        watchers.push(
          scope.$watch(getPaymentMethodValue, function(paymentMethod) {
            var countryValue = ngModel.$modelValue || ngModel.$viewValue;
            validateInput(countryValue);
          })
        );
      }

      function deactivateDir() {
        for (var i = 0; i < watchers.length; i++) {
          if (typeof watchers[i] === 'function') {
            watchers[i]();
          }
        }
      }

      function getPaymentMethodValue() {
        var paymentMethod = '';
        if (
          attrs[paymentMethodFieldName] &&
          form[attrs[paymentMethodFieldName]] &&
          '$modelValue' in form[attrs[paymentMethodFieldName]] &&
          form[attrs[paymentMethodFieldName]]
        ) {
          var paymentMethodControl = form[attrs[paymentMethodFieldName]];
          paymentMethod =
            paymentMethodControl.$modelValue || paymentMethodControl.$viewValue;
        }
        return paymentMethod;
      }

      function validateInput(countryValue) {
        countryValue =
          typeof countryValue === 'string' || countryValue instanceof String
            ? countryValue.toString().toUpperCase()
            : '';

        var isValid = true;
        var errorNameExists = 'postfinanceCountryPaymentMethod';
        ngModel.$setValidity(errorNameExists, true);

        var paymentMethodValue = getPaymentMethodValue();
        if (paymentMethodValue) {
          var paymentMethod = paymentMethodValue.toString();
        } else {
          isValid = false;
        }
        if (isActive && paymentMethod === 'donut-post-finance') {
          isValid = countryValue === 'CH';
          if (!isValid) {
            ngModel.$setValidity(errorNameExists, false);
          }
        }

        return isValid;
      }
    }
  };
}

angular
  .module('isPostfinanceCountryValid-dir', [])
  .directive('isPostfinanceCountryValidDir', isPostfinanceCountryValidDir);

function isPostAccountNumberValidDir() {
  return {
    require: ['ngModel'],
    link: function(scope, element, attrs, controllers) {
      //config section
      var dirName = 'isPostAccountNumberValidDir';

      //
      var ngModel = controllers[0];

      var isActive = false;

      //For DOM -> model validation
      ngModel.$parsers.unshift(parseFunction);

      //For model -> DOM validation
      ngModel.$formatters.unshift(formatterFunction);

      var dirActiveWatch = scope.$watch(
        function() {
          return !!parseInt(attrs[dirName]);
        },
        function(value) {
          //check for change
          if (isActive !== value) {
            isActive = value;
          }
        }
      );

      ////////////////////////////////////////////

      function parseFunction(accountNumberValue) {
        validateInput(accountNumberValue);
        return accountNumberValue;
      }

      function formatterFunction(accountNumberValue) {
        validateInput(accountNumberValue);
        return accountNumberValue;
      }

      function validateInput(accountNumberValue) {
        var valid = true;
        var errorName = 'postAccountNumberValid';

        ngModel.$setValidity(errorName, valid);

        //perform check only if there is no country error present on this field
        if (isActive && accountNumberValue) {
          valid = CH_Postfinance.validatePostAccountCode(accountNumberValue);
          ngModel.$setValidity(errorName, valid);
        }

        return valid;
      }
    }
  };
}

angular
  .module('isPostAccountNumberValid-dir', [])
  .directive('isPostAccountNumberValidDir', isPostAccountNumberValidDir);

angular.module('bank-account.ch-post-finance', [
  'formatChPostAccountNumber-dir',
  'isPostfinanceCountryValid-dir',
  'isPostAccountNumberValid-dir'
]);
